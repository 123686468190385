import React from "react";
import {
  Div,
  H6,
  Col,
  Row,
  Text,
  Avatar,
  Paper,
  Image,
} from "qdm-component-library";
import "./style.css";
import { getImgUrl, makeName, getCalculatedAge } from "../../../utils";
import IdentificationDocuments from "../../../assets/img/svg/icons8-identification-documents.svg"
import PhoneIcon from "../../../assets/img/svg/icons8-phone.svg"
import patientCardIcon from "../../../assets/img/svg/Group 90503.svg";
import addressIcon from "../../../assets/img/svg/icons8-address.svg"


const PatientListView = (props) => {

  const { parent_id } = props;

  const {
    Persondata,
    imgUrl = getImgUrl(
      props?.Persondata?.personInfo?.[0]?.photo?.[0]?.fileid ||
        props?.Persondata?.photo?.[0]?.fileid
    ),
    //  address, contact,
    letter = props.Persondata?.name?.[0]?.text?.[0] ||
      (props.Persondata?.name?.[0]?.given?.[0] ?? "") ||
      (props.personvisitedDataNames?.first_name?.[0] ?? ""),
    noright,
    //  personvisitedDataNames, details
  } = props;

  return (
    <Paper
      id={`${parent_id}_patientlist_view_parent_paper`}
      className="rv-pl-root"
    >
      <Row id={`${parent_id}_patientlist_view_parent_row`}>
        <Col
          id={`${parent_id}_patientlist_view_parent_col`}
          md={12}
          lg={12}
          sm={12}
          xs={12}
          inLineStyles={{ padding: 0 }}
          className="pl-detail-root"
        >
          <Div
            id={`${parent_id}_patientlist_view_parent_div`}
            className={
              !noright
                ? "pl-details1"
                : imgUrl || letter
                ? "pl-details"
                : "pl-detail"
            }
          >
            {(imgUrl || letter) && (
              <Avatar
                className="pl-details-avatar"
                src={imgUrl}
                letter={letter}
                variant="rounded"
              />
            )}
            <div style={{ display: "flex" }}>
              <div>
                <Div
                  id={`${parent_id}_patientlist_view_sub_div`}
                  className="pl-details-names"
                >
                  <H6
                    id={`${parent_id}_patientlist_view_h6`}
                    style={{ width: "max-content", margin: 0 }}
                  >
                    {/* {this.props.walkin ?
                                    <>{(personvisitedDataNames?.first_name ?? "") + ' ' + (personvisitedDataNames?.middle_name ?? "") + ' ' +
                                        (personvisitedDataNames?.last_name ?? "")}
                                    </> :
                                    <> */}
                 
                    {makeName(props.Persondata?.name?.[0])}
                    <Text
                      id={`${parent_id}_patientlist_view_text`}
                      style={{ fontSize: 12 }}
                    >
                      &nbsp;&nbsp;•&nbsp;&nbsp;
                      {/* {getCalculatedAge(props.Persondata?.birthDate)} */}
                      {getCalculatedAge(props.Persondata?.birthdate)}
                      ,&nbsp;
                      {props.Persondata?.gender?.display}
                    </Text>
                  </H6>

                  <Div
                    id={`${parent_id}_patientlist_view_image_div`}
                    className="flexCenter"
                  >
                    <Div
                      id={`${parent_id}_patientlist_view_image_sub_div`}
                      className="pl-secondary-text flexCenter"
                    >
                      <Div
                        id={`${parent_id}_patientlist_view_doc_image_div`}
                        className="p1-secondary-img"
                      >
                        <Image
                          id={`${parent_id}_patientlist_view_doc_image`}
                          className="pl-secondary-text-icons"
                          src={IdentificationDocuments}
                          alt="id"
                        />
                      </Div>
                      <Div id={`${parent_id}_patientlist_view_persondata_div`}>
                        <Text
                          id={`${parent_id}_patientlist_view_persondata_text`}
                        >
                          {/* {Persondata?.alias} */}
                          {Persondata?.patmrn}
                        </Text>
                      </Div>
                    </Div>
                    <Div
                      id={`${parent_id}_patientlist_view_group_div`}
                      className="pl-secondary-text flexCenter"
                    >
                      <Div
                        id={`${parent_id}_patientlist_view_group_image_div`}
                        className="p1-secondary-img"
                      >
                        <Image
                          id={`${parent_id}_patientlist_view_group_image`}
                          className="pl-secondary-text-icons"
                          src={patientCardIcon}
                          alt="group"
                        />
                      </Div>
                      <Div
                        id={`${parent_id}_patientlist_view_persondata_id_div`}
                        style={{ width: "100px" }}
                      >
                        <Text
                          id={`${parent_id}_patientlist_view_persondata_id_text`}
                        >
                          {/* {Persondata?._id} */}
                          {props?.patientId}
                        </Text>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </div>

              {!noright && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Div
                    id={`${parent_id}_patientlist_view_noright_parent_div`}
                    className="pl-details-locations"
                  >
                    {/* {noright &&  */}
                    <Div
                      id={`${parent_id}_patientlist_view_noright_div`}
                      className="pl-secondary-text flexCenter"
                    >
                      <Div
                        id={`${parent_id}_patientlist_view_noright_image_div`}
                        className="p1-secondary-img"
                      >
                        <Image
                          id={`${parent_id}_patientlist_view_noright_doc_image`}
                          className="pl-secondary-text-icons"
                          src={IdentificationDocuments}
                          alt="id"
                        />
                      </Div>
                      <Div
                        id={`${parent_id}_patientlist_view_noright_alias_div`}
                      >
                        <Text
                          id={`${parent_id}_patientlist_view_noright_alias_text`}
                        >
                          {Persondata?.alias}
                        </Text>
                      </Div>
                    </Div>
                    {/* } */}
                    <Div
                      id={`${parent_id}_patientlist_view_noright_phone_div`}
                      className="pl-secondary-text flexCenter"
                    >
                      <Div
                        id={`${parent_id}_patientlist_view_noright_phone_sub_div`}
                        className="p1-secondary-img"
                      >
                        <Image
                          id={`${parent_id}_patientlist_view_noright_phone_image`}
                          className="pl-secondary-text-icons"
                          src={PhoneIcon}
                          alt="id"
                        />
                      </Div>
                      <Div
                        id={`${parent_id}_patientlist_view_noright_mobile_div`}
                      >
                        <Text
                          id={`${parent_id}_patientlist_view_noright_mobile_text`}
                        >
                          {/* {(!this.props.walkin ? (
                                    this.props.Persondata?.telecom?.[0]?.value ?? "XXXXXXXX"
                                ) : ((contact?.mode?.value === "Phone") ?
                                    ("+91" + contact?.mobile ?? '') : "XXXXXXXX"))} */}
                          {props.Persondata?.telecom?.[0]?.value ?? "XXXXXXXX"}
                        </Text>
                      </Div>
                    </Div>
                    <Div
                      id={`${parent_id}_patientlist_view_noright_address_div`}
                      className="pl-secondary-text flexCenter"
                      style={{ width: "600px" }}
                    >
                      {Persondata?.address?.length > 0 && (
                        <Div className="p1-secondary-img">
                          <Image
                            id={`${parent_id}_patientlist_view_noright_address_image`}
                            className="pl-secondary-text-icons"
                            src={addressIcon}
                            alt="id"
                          />
                        </Div>
                      )}
                      <Div
                        id={`${parent_id}_patientlist_view_noright_address_title_div`}
                      >
                        <Text
                          id={`${parent_id}_patientlist_view_noright_address_title_text`}
                        >
                          {/* {this.props.walkin ?
                                        <>
                                            {address?.addressLine1 ?? ''}&nbsp;
                                            {address?.addressLine2 ?? ''}&nbsp;
                                            {address?.addressLine3 ?? ''}&nbsp;
                                            {address?.city?.value ?? ''}&nbsp;
                                            {address?.state?.value ?? ''}&nbsp;
                                            {address?.district?.value ?? ''}&nbsp;
                                            {address?.country?.value ?? ''}&nbsp;
                                            {address?.pincode ?? ''}
                                        </> : <> */}
                          {Persondata?.address?.[0]?.text ?? ""}&nbsp;
                          {Persondata?.address?.[0]?.line ?? ""}&nbsp;
                          {Persondata?.address?.[0]?.line2 ?? ""}&nbsp;
                          {Persondata?.address?.[0]?.city?.geogLevelName ?? ""}
                          &nbsp;
                          {Persondata?.address?.[0]?.state?.geogLevelName ?? ""}
                          &nbsp;
                          {Persondata?.address?.[0]?.district?.geogLevelName ??
                            ""}
                          &nbsp;
                          {Persondata?.address?.[0]?.country?.geogLevelName ??
                            ""}
                          &nbsp;
                          {Persondata?.address?.[0]?.postalCode
                            ?.geogLevelName ?? ""}
                          {/* </>} */}
                        </Text>
                      </Div>
                    </Div>
                  </Div>
                </div>
              )}
            </div>
          </Div>
        </Col>
      </Row>
    </Paper>
  );
};

export default PatientListView;
