import React from "react";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import Popover from "@material-ui/core/Popover";
import { useStyles } from "./style";
import PropTypes from "prop-types";

function StyledPopover({ renderHoverPopover, ...props }) {
  const classes = useStyles(props);
  return (
    <>
      {renderHoverPopover ? (
        <HoverPopover
          classes={classes}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          {...props}
        />
      ) : (
        <Popover
          classes={classes}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          {...props}
        />
      )}
    </>
  );
}

export default StyledPopover;

StyledPopover.defaultProps = {
  minWidth: 260,
  maxWidth: 260,
  renderHoverPopover: false,
};

StyledPopover.propTypes = {
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  renderHoverPopover: PropTypes.bool,
};
