import * as React from "react";
import PropTypes from "prop-types";

const LocationIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.269" height="12.584" viewBox="0 0 12.269 12.584">
  <path id="icons8-address" d="M12,5A4.008,4.008,0,0,0,8,9a6.763,6.763,0,0,0,1.247,3.172,30.878,30.878,0,0,0,1.914,2.743,1.075,1.075,0,0,0,1.678,0,30.878,30.878,0,0,0,1.914-2.743A6.763,6.763,0,0,0,16,9,4.008,4.008,0,0,0,12,5Zm0,1a2.993,2.993,0,0,1,3,3,7.013,7.013,0,0,1-1.1,2.652A30.142,30.142,0,0,1,12.05,14.3c-.033.043-.064.043-.1,0a30.141,30.141,0,0,1-1.849-2.65A7.013,7.013,0,0,1,9,9,2.993,2.993,0,0,1,12,6Zm0,2a1,1,0,1,0,1,1A1,1,0,0,0,12,8ZM7.909,13.334a1.168,1.168,0,0,0-1.066.693L6.1,15.693a1.166,1.166,0,0,0,1.066,1.641h9.665A1.167,1.167,0,0,0,17.9,15.693l-.74-1.667a1.168,1.168,0,0,0-1.066-.693H15.008q-.319.483-.691,1h1.777a.167.167,0,0,1,.152.1l.74,1.667a.166.166,0,0,1-.152.234H7.168a.167.167,0,0,1-.152-.234l.741-1.667a.167.167,0,0,1,.152-.1H9.685q-.374-.518-.691-1Z" transform="translate(-5.866 -4.875)"
   fill={props?.color} stroke="#01205c" stroke-width="0.25"/>
</svg>
);

export default LocationIcon;

LocationIcon.defaultProps = {
  color: "black",
};

LocationIcon.propTypes = {
  color: PropTypes.string,
};