import React, { useState } from "react";
import { actions } from "primarycare-binder";
import {
  Container,
  Col,
  Chip,
  Div,
  H6,
  TapSelect,
  Icons,
  Divider,
  Text,
} from "qdm-component-library";
import { UIColor } from "../../../themes/theme";
import { useStyles } from "./style";
import { connect } from "react-redux";

export const handleSymptomsData = (data, props) => {
  if (data.type === "Speciality") {
    const newData = [...props?.values?.select_spccialities, data];
    handleSymptomChange(newData, data.type, props);
  } else if (data?.type === "Doctor") {
    // const newData = [...props?.values?.select_doctors,data];
    handleSymptomChange([data], data.type, props); //single select
  } else {
    const newData = [...props?.values?.select_symptoms, data];
    handleSymptomChange(newData, data.type, props);
  }
  const searchData = [...props?.values?.select_symptomsSearch, data.value];
  props?.handlesearchActives &&
    props.handlesearchActives("select_symptomsSearch", searchData);
};

const handleSymptomChange = async (arr, type, props) => {
  if (type === "Speciality") {
    props.handlesearchActives("select_spccialities", arr);
  } else if (type === "Doctor") {
    props.handlesearchActives("select_doctors", arr);
  } else {
    const arrVal = arr.map((a) => a.label);
    if (arrVal.length > 0) {
      let spec = props?.values?.symptomsData.filter((v) => {
        return v?.Type === "Symptoms";
      });
      spec = spec[0]?.Speciality;

      // await props.GET_SPECIALITY({ speciality: arrVal });
      const notSpecialities = (props?.values?.allSymptomData || []).filter(
        (val) => {
          return val.type !== "Speciality";
        }
      );
      const newSpecialities = props?.values?.select_spccialities?.filter?.(
        (d) => {
          const index = spec?.payload?.data?.findIndex?.(
            (dd) => dd.value === d.value
          );
          return index !== -1;
        }
      );
      const newSymptoms = [...(spec?.payload?.data || []), ...notSpecialities];
      props.handlesearchActives("select_spccialities", [...newSpecialities]);
      props.changeState("allSymptomData", newSymptoms);
      // props.changeState("specialtyData", symptomsSpeciality);
      // props.setState((prevstate) => ({
      //   ...prevstate,
      //   // allSymptomData: newSymptoms,
      //   specialtyData: symptomsSpeciality,
      // }));
    } else {
      props.handlesearchActives("select_spccialities", []);
      props.GET_ALL_SPECIALITY({ speciality: arrVal });
    }

    props.handlesearchActives("select_symptoms", arr);
  }
};

function SearchBySpeciality(props) {
  const [state, setState] = useState({
    data: false,
  });

  const classes = useStyles();

  const changeState = (key, value) => {
    setState({
      [key]: value,
    });
  };

  const handleSymptomremoval = (ele) => {
    props.ondeleteSpecialtySymptomsDiseaseDoctor(ele);
  };

  const giveMeBackground = (type) => {
    switch (type?.toLowerCase()) {
      case "symptom":
        return "#38C20A1A";
      case "doctor":
        return "#F58B001A";
      case "speciality":
        return "#F0F0F0";
      default:
        return "#38C20A1A";
    }
  };

  const giveMeColor = (type) => {
    switch (type?.toLowerCase()) {
      case "symptom":
        return "#258007";
      case "doctor":
        return "#F58B00";
      case "speciality":
        return "#6F6F6F";
      default:
        return "#258007";
    }
  };
  const handleSpecialityBasedOnSymptom = (symptom) => {
    if (symptom?.length !== 0) {
      let symptomsSpeciality = symptom.map((v) => v?.Speciality).flat();
      props?.specialityupdtae(symptomsSpeciality);
    } else {
      props?.specialityupdtae(
        props?.values?.symptom_speciality_doctor_all_data.Specialty
      );
    }

    // console.log({ specality });
    // let map1 = new Map(props?.values?.symptomsData.map((v) => [v?.label, v]));
    // console.log(
    //   "new",
    //   symptom.filter((v) => map1.has(v?.label))
    // );
  };

  const {
    handlesearchActives,
    ondeleteallSpecialtySymptomsDiseaseDoctor,
    values,
    parent_id,
  } = props;
  console.log("searchBySpeciality", values?.symptomsDataState?.length);
  return (
    <div id={`${parent_id}_search_speciality_parent_div`}>
      {values?.symptomsSearch?.length > 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            ref={props?.refs}
            id={`${parent_id}_search_speciality_no_data_div`}
            className="search_symptoms_input"
            style={{
              boxShadow: "0px 8px 23px #0000001A",
              borderRadius: 8,
              backgroundColor: "#fff",
              width: 384,
              padding: "10px",
              maxHeight: 400,
              overflow: "auto",
            }}
          >
            {values?.symptomsDataState?.length > 0 ? (
              values?.symptomsDataState?.map((_, i) => {
                return (
                  <Div
                    id={`${parent_id}_${i}_search_speciality_sysmptoms_div`}
                    key={_?.value}
                    className="search_symptoms_input_options"
                  >
                    <Div
                      id={`${parent_id}_${i}_search_speciality_sysmptoms_sub_div`}
                      onClick={() => handleSymptomsData(_, props)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 5,
                        cursor: "pointer",
                      }}
                    >
                      <H6
                        id={`${parent_id}_${i}_search_speciality_h6`}
                        className="pc_regular"
                        style={{
                          margin: 0,
                          flex: 1,
                          color: "#000000",
                          fontSize: 14,
                          letterSpacing: 0,
                        }}
                      >{`${_?.label} ${
                        _?.speciality ? ` | ${_?.speciality}` : ""
                      }`}</H6>
                      <Div
                        id={`${parent_id}_${i}_search_speciality_give_div`}
                        style={{
                          borderRadius: 4,
                          backgroundColor: giveMeBackground(_?.type),
                          padding: "5px 10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          id={`${parent_id}_${i}_search_speciality_give_text`}
                          className="pc_medium"
                          style={{
                            color: giveMeColor(_?.type),
                            fontSize: 10,
                            letterSpacing: 0,
                          }}
                        >
                          {_?.type}
                        </Text>
                      </Div>
                    </Div>
                    {!(i === values?.symptomsDataState?.length - 1) && (
                      <Divider
                        id={`${parent_id}_${i}_search_speciality_divider`}
                        style={{
                          margin: 0,
                          border: "1px solid #F9F9F9",
                        }}
                      />
                    )}
                  </Div>
                );
              })
            ) : (
              <Div id={`${parent_id}_search_speciality_data_found_div`}>
                <H6
                  style={{
                    margin: 0,
                    textAlign: "center",
                    color: "#000000",
                    fontSize: 14,
                    fontWeight: "600",
                  }}
                >
                  No Data Found
                </H6>
              </Div>
            )}
          </div>
        </div>
      ) : (
        <Container
          id={`${parent_id}_search_speciality_parent_container`}
          key={"0"}
          name="container"
          fluid={false}
        >
          <div
            id={`${parent_id}_search_speciality_QUICK_sub_div`}
            style={{
              padding: "16px",
              backgroundColor: "#fff",
              borderRadius: "8px",
              maxHeight: 400,
              overflow: "auto",
            }}
            ref={props?.refs}
          >
            {[
              ...values.select_symptoms,
              ...values.select_spccialities,
              ...values.select_doctors,
            ]?.length !== 0 && (
              <Col
                id={`${parent_id}_search_speciality_QUICK_col`}
                key={"0"}
                name="qdm-col-12"
                alignItems="stretch"
                direction="row"
                justify="flex-start"
                textAlign="left"
                lg="12"
                xs="12"
                md="12"
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "",
                  paddingTop: "",
                  padding: "0px",
                  marginBottom: 16,
                  flexWrap: "wrap",
                }}
              >
                {/* QUICK SUGGESTION selected datas */}
                {[
                  ...values.select_symptoms,
                  ...values.select_spccialities,
                  ...values.select_doctors,
                ]?.map((_c, i) => {
                  return (
                    <Chip
                      id={`${parent_id}_${i}_search_speciality_QUICK_chip`}
                      className="pc_regular"
                      onClick={() => handleSymptomremoval(_c)}
                      key={i}
                      variant="circle"
                      name={_c?.label}
                      style={{
                        borderColor: UIColor.differentiationBackground.color,
                        color: UIColor.primaryText.color,
                        borderRadius: "8px",
                        fontSize: 14,
                        padding: "2px 12px",
                        marginRight: 8,
                        marginBottom: 8,
                        backgroundColor:
                          UIColor.differentiationBackground.color,
                      }}
                      closeIcon={
                        <Icons
                          id={`${parent_id}_${i}_search_speciality_QUICK_close_icons`}
                          key={"1"}
                          fontIcon="close"
                          ariaHidden="true"
                          className=""
                          size="small"
                          style={{
                            fontWeight: 100,
                            "-webkitTextStroke": "1px white",
                            transform: "scale(1) translate(0px, 0px)",
                          }}
                        ></Icons>
                      }
                    ></Chip>
                  );
                })}

                {/* Clear All text */}
                {[
                  ...values.select_symptoms,
                  ...values.select_spccialities,
                  ...values.select_doctors,
                ]?.length > 0 && (
                  <Div
                    id={`${parent_id}_search_speciality_Clear_div`}
                    key={"2"}
                    onClick={() => {
                      ondeleteallSpecialtySymptomsDiseaseDoctor();
                    }}
                  >
                    <H6
                      id={`${parent_id}_search_speciality_Clear_h6`}
                      className="pc_regular"
                      key={"0"}
                      name="Clear All"
                      style={{
                        color: UIColor.secondaryColor.color,
                        fontSize: "14px",
                        cursor: "pointer",
                        letterSpacing: 0,
                        marginLeft: 15,
                        marginBottom: 8,
                      }}
                    ></H6>
                  </Div>
                )}
                {/* end */}
              </Col>
            )}
            <H6
              id={`${parent_id}_search_speciality_QUICK_h6`}
              className="pc_medium"
              key={"1"}
              name="QUICK SUGGESTION"
              style={{
                color: "#6F6F6F",
                fontSize: "14px",
                letterSpacing: 0,
                lineHeight: 1,
              }}
            ></H6>

            <H6
              id={`${parent_id}_search_speciality_SYMPTOMS_h6`}
              className="pc_regular"
              key={"2"}
              name="SYMPTOMS"
              style={{
                color: "#6F6F6F",
                fontSize: "12px",
                letterSpacing: 0,
                lineHeight: 1,
                margin: "16px 0px 16px",
              }}
            ></H6>
            <TapSelect
              id={`${parent_id}_search_speciality_SYMPTOMS_tapselect`}
              defaultSelected={values.select_symptoms}
              key={"3"}
              type="multiple"
              fullWidth={false}
              selectedBorderColor={UIColor.secondaryButtonColor.color}
              selectedBackgroundColor={UIColor.secondaryButtonColor.color}
              selectedTextColor="#ffffff"
              textSize="16"
              iconHeight="20"
              iconWidth="20"
              textColor="#6f6f6f"
              label=""
              onChange={(arr) => {
                handleSymptomChange(arr, "symptom", props);
                handleSpecialityBasedOnSymptom(arr);
              }}
              options={props?.values?.symptomsData || []}
              // inLineStyles={{ borderColor: "#E0E0E0", color: "#6f6f6f", fontFamily: "'pc_regular' !important" }}
              className={classes.tapSelect}
            ></TapSelect>

            <H6
              id={`${parent_id}_search_speciality_SPECIALITIES_h6`}
              key={"4"}
              className="pc_regular"
              name={
                true || props.speciality?.data.length > 0 ? "SPECIALTIES" : ""
              }
              style={{
                color: "#6F6F6F",
                fontSize: "12px",
                letterSpacing: 0,
                lineHeight: 1,
                margin: "8px 0px 16px",
              }}
            ></H6>
            <TapSelect
              id={`${parent_id}_search_speciality_SPECIALITIES_tapselect`}
              key={"5"}
              defaultSelected={values.select_spccialities}
              type="multiple"
              fullWidth={false}
              selectedBorderColor={UIColor.secondaryButtonColor.color}
              selectedBackgroundColor={UIColor.secondaryButtonColor.color}
              selectedTextColor="#ffffff"
              textSize="16"
              iconHeight="20"
              iconWidth="20"
              textColor="#6f6f6f"
              label=""
              onChange={(arr) => handleSymptomChange(arr, "Speciality", props)}
              options={props?.values?.specialtyData || []}
              // inLineStyles={{ borderColor: "#E0E0E0", color: "#6f6f6f", fontFamily: "'pc_regular' !important" }}
              className={classes.tapSelect}
            ></TapSelect>
          </div>
        </Container>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  speciality: state?.searhcApiSlice?.speciality,
  symptom_speciality_doctor_data:
    state?.searchHospital?.symptom_speciality_doctor_data,
});

export default connect(mapStateToProps, actions)(SearchBySpeciality);
