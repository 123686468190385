import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  dbName,
  defaultReject,
  defaultState,
  fetchData,
  organizationRequest,
  query_ids,
  urls,
} from "../../utils";

// const { getPatient, getReferral, getFinancial } = organizationRequest;

const ORGANIZATION_FILTER_BY_PATIENT = createAsyncThunk(
  `OrganizationRegisterPatientApiSlice/getpatient`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { patientid="", tenantid="", facilityid="" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["getOrgByPatient"],
        filter: { 
          patientid: patientid,
          tenantid: tenantid,
          facilityid: facilityid
       },                    
      }
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_REFERRAL = createAsyncThunk(
  `OrganizationRegisterPatientApiSlice/getreferral`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patientid = "", reffId = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["getreferral"],
        filter: { 
          patientid: patientid,
          refferalid: reffId
       },                    
      }
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        returnData = data;
        const info = {
          referralLetter: [
            {
              referralLetterNo: returnData?.[0]?.RefSourceLetRef,
              referralDate: returnData?.[0]?.RefSourceLetDate,
              receivedDate: returnData?.[0]?.RefLetReceivedDate,
            },
          ],
          referralFrom: [
            {
              from: returnData?.[0]?.basedOn,
              facilityType: returnData?.[0]?.RequestorOrganizationType,
              facilityName: returnData?.[0]?.HealthCareServiceRequested,
              specialty: returnData?.[0]?.RequestorSpecialty,
              practitionerType: returnData?.[0]?.RequestorPractionerType,
              practitioner: returnData?.[0]?.RequestorPractioner,
            },
          ],
          referralTo: [
            {
              specialty: returnData?.[0]?.recipientSpecialty,
              location: returnData?.[0]?.RequestorLocationName,
              practitionerType: returnData?.[0]?.recipientPractType,
              practitioner: returnData?.[0]?.recipientPract,
            },
          ],
          referralDetails: [
            {
              intentType: returnData?.[0]?.intent,
              for: returnData?.[0]?.subject,
            },
          ],
        };
        returnData = {
          //...returnData[0],
          ...info,

        };
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_FINANCIAL = createAsyncThunk(
  `OrganizationRegisterPatientApiSlice/getfinancial`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patientid = "", tenantid="", facilityid="" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["getfinancial"],
        filter: { 
          patientid: patientid,
	        tenantid: tenantid,
	        facilityid: facilityid
       },                    
      }
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      
      let returnData = [];
      if (Array.isArray(data)) {
        returnData = data;
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const OrganizationRegisterPatientApiSlice = createSlice({
  name: "OrganizationRegisterPatientApiSlice",
  initialState: {
    oraganizationfilterbypatient: {
      ...defaultState.Info,
    },
    getreferral: {
      ...defaultState.Info,
    },
    getfinancial: {
      ...defaultState.Info,
    },
  },
  extraReducers: {
    // ORGANIZATION_FILTER_BY_PATIENT
    [ORGANIZATION_FILTER_BY_PATIENT.fulfilled]: (state, action) => {
      state.oraganizationfilterbypatient = action?.payload ?? {};
    },
    [ORGANIZATION_FILTER_BY_PATIENT.pending]: (state, action) => {
      state.oraganizationfilterbypatient.loading = true;
    },
    [ORGANIZATION_FILTER_BY_PATIENT.rejected]: (state, action) => {
      state.oraganizationfilterbypatient = action?.payload ?? {};
    },
    /* GET_REFERRAL */
    [GET_REFERRAL.fulfilled]: (state, action) => {
      state.getreferral = action?.payload ?? {};
    },
    [GET_REFERRAL.pending]: (state, action) => {
      state.getreferral.loading = true;
    },
    [GET_REFERRAL.rejected]: (state, action) => {
      state.getreferral = action?.payload ?? {};
    },
    /* GET_FINANCIAL */
    [GET_FINANCIAL.fulfilled]: (state, action) => {
      state.getfinancial = action?.payload ?? {};
    },
    [GET_FINANCIAL.pending]: (state, action) => {
      state.getfinancial.loading = true;
    },
    [GET_FINANCIAL.rejected]: (state, action) => {
      state.getfinancial = action?.payload ?? {};
    },
  },
});


export const oraganizationRegisterPatientActions = {
  ORGANIZATION_FILTER_BY_PATIENT,
  GET_REFERRAL,
  GET_FINANCIAL,
};

export default OrganizationRegisterPatientApiSlice;
