import React, { useState } from "react";
import {
  Div,
  Paper,
  Checkbox,
  H6,
  Col,
  Row,
  Select,
  Text,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../hoc/withAllContexts";
// import Chip from '@material-ui/core/Chip';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { UIColor } from "../../themes/theme";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  chips: {
    "& .MuiChip-root": {
      backgroundColor: UIColor.lineBorderFill.color,
      borderRadius: 8,
    },
  },
});

const Communication = (props) => {
  let styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
  };

  const [fixedOptions, setFixedOptions] = useState([]);

  let { communication, statusDetails } = props?.patientData;
  const { labelStyle, borderStyle } = styles;
  const { parent_id } = props;
  const { classes } = props;

  return (
    <Div id={`${parent_id}_communication_parent_div`} className="pi-root">
      <Paper
        id={`${parent_id}_communication_parent_paper`}
        className="pi-paper"
      >
        <Div id={`${parent_id}_communication_label_div`}>
          <H6
            id={`${parent_id}_communication_label_communication_h6`}
            className="pi-title"
          >
            COMMUNICATION
          </H6>
        </Div>
        <Row
          id={`${parent_id}_communication_parent_row`}
          className="pi-actions-root"
          style={{ paddingTop: "0px" }}
        >
          <Col
            id={`${parent_id}_communication_language_col`}
            md={6}
            lg={6}
            sm={6}
            xs={12}
            className="no-padding-left"
          >
            <Text
              id={`${parent_id}_communication_language_label_text`}
              style={{
                color: "#6F6F6F",
                fontSize: "12px",
                marginBottom: "6px",
                fontFamily: "pc_regular",
              }}
            >
              Communication Languages
            </Text>
            <Autocomplete
              id={`${parent_id}_communication_language_autocomplete`}
              multiple
              fullWidth
              size="small"
              getOptionLabel={(option) => option.label}
              value={communication?.clanguage ?? []}
              onChange={(e, v) => this.setDetailslanguage("clanguage", v)}
              options={this.FlterOptions("LANGUAGE")}
              className={classes.chips}
              disabled={
                statusDetails?.status1 === "Inactive" ||
                statusDetails?.status === "Deceased"
                  ? true
                  : false
              }
              renderInput={(params) => (
                <TextField
                  id={`${parent_id}_communication_select_label_textfield`}
                  style={{ height: "20px" }}
                  {...params}
                  label={false}
                  variant="outlined"
                  placeholder="select..."
                  size="small"
                />
              )}
            />
          </Col>
          <Col
            id={`${parent_id}_communication_preferred_col`}
            md={3}
            lg={3}
            sm={6}
            xs={12}
            className="no-padding-left"
          >
            <Select
              id={`${parent_id}_communication_preferred_select`}
              options={communication?.clanguage ?? []}
              disabled={
                !communication?.clanguage ||
                statusDetails?.status1 === "Inactive" ||
                statusDetails?.status === "Deceased"
              }
              value={communication?.planguage?.value ?? null}
              onChangeValue={(e) => this.setDetails("planguage", e)}
              showArrow
              label="Preferred Language"
              placeholder="select..."
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor={UIColor.primaryColor.color}
            />
          </Col>
          <Col
            id={`${parent_id}_communication_interpreter_col`}
            md={3}
            lg={3}
            sm={6}
            xs={12}
            className="no-padding-left"
          >
            <H6
              id={`${parent_id}_communication_interpreter_label_h6`}
              className="default-size pc_regular"
            >
              Interpreter Required
            </H6>
            <Checkbox
              style={{ marginTop: "2%" }}
              id={`${parent_id}_communication_interpreter_checkbox`}
              disabled={
                statusDetails?.status1 === "Inactive" ||
                statusDetails?.status === "Deceased"
                  ? true
                  : false
              }
              name=""
              checked={communication?.isinterpreter}
              onChange={(e) =>
                this.setDetails("isinterpreter", !communication?.isinterpreter)
              }
              color={UIColor.secondaryButtonColor.color}
            />
          </Col>
        </Row>
      </Paper>
    </Div>
  );
};

export default withStyles(styles)(withAllContexts(Communication)); //withAllcontext here

// export default withAllContexts(PatientCommunication);
