import React, { useState } from 'react';
import { Div, H6, Text, Image, Icons } from 'qdm-component-library';
import { Popover, makeStyles } from '@material-ui/core';
import { CalendarIcon } from '../';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const useStyles = makeStyles({

});

export const RecurrentList = (props) => {
    const classes = useStyles(props);

    const styles = {
        container: {
            border: '1px solid #E0E0E0',
            borderRadius: 8,
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            padding: '7px 10px',
            marginBottom: 10
        },
        trash: {
            color: 'red',
            marginRight: 10,
            cursor: 'pointer'
        },
        recurrentText: {
            fontSize: 12,
            color: '#323232',
            margin: 0
        },
        dot: {
            width: 5,
            height: 5,
            borderRadius: '50%',
            backgroundColor: '#6B6B6B',
            margin: '0px 5px',
            display: 'inline-block'
        },
        flexCenter: {
            display: 'flex',
            alignItems: 'center'
        },
        time: {
            width: 16,
            height: 16,
            margin: '0px 5px'
        },
        title: {
            fontSize: 14,
            color: '#323232',
            margin: 0,
            marginBottom: 10
        },
    };

    // const { recurrentList } = props?.recurrentList;

    const [anchorEl, setAnchorEl] = useState(null);
    const [ix, setix] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (val, event, i) => {
        setix(i)
        setAnchorEl(event?.currentTarget)
        setDate(val)
    }

    const deleteRecurrentList = (val, i) => {
        const filteredData = props?.recurrentList?.filter(_ => _?.showid !== val?.showid);
        props?.onChangeState && props.onChangeState(filteredData)
    }

    const [date, setDate] = useState('');

    const handleDate = (val) => {
        props.recurrentList[ix].date = moment(val).format('YYYY-MM-DD,hh:mm A')
        const changedRecurrentData = props?.recurrentList?.map(_ => {
            if (_?.showid === date?.showid) {
                _.date = val
            }
            return _
        })
        props?.onChangeState && props.onChangeState(changedRecurrentData)
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const { parent_id } = props;

    return (
        <div
            id={`${parent_id}_recurrentlist_parent_div`}
            style={{
                padding: props?.padding,
                width: props?.width
            }
            }>
            <H6
                id={`${parent_id}_recurrentlist_${props?.title.replaceAll(" ", "_")}_h6`}
                className="pc_medium" style={styles.title}>{props?.recurrentList?.endsAfter?.endsAfter && props?.recurrentList?.endsAfter?.endsAfter}&nbsp;{props?.title}</H6>
            {
                (Array.isArray(props?.recurrentList) && props?.recurrentList) && props?.recurrentList?.map((_, i) => {
                    return (
                        <Div
                            id={`${parent_id}_recurrentlist_${i}_recurrentList_div`}
                            style={{
                                ...styles.container,
                                marginBottom: i >= props?.recurrentList?.length - 1 ? 0 : 10
                            }}>
                            <Div
                                id={`${parent_id}_recurrentlist_${i}_recurrentText_date_div`}
                                style={{ ...styles.flexCenter, flex: 1 }}>
                                {_?.date && <H6
                                    id={`${parent_id}_recurrentlist_${i}_recurrentText_date_h6`}
                                    className="pc_semibold" style={styles.recurrentText}>{_?.date}</H6>}
                                {
                                    _?.id &&
                                    <Text
                                        id={`${parent_id}_recurrentlist_${i}_recurrentText_id_text`}
                                        className="pc_regular" style={{ ...styles.recurrentText, ...styles.flexCenter }}>
                                        <Div
                                            id={`${parent_id}_recurrentlist_${i}_recurrentText_id_div`}
                                            style={styles.dot} />
                                        {_?.id}
                                    </Text>
                                }
                                {
                                    _?.status &&
                                    <Text
                                        id={`${parent_id}_recurrentlist_${i}_status_text`}
                                        className="pc_regular" style={{ ...styles.recurrentText, ...styles.flexCenter }}>
                                        <Image
                                            id={`${parent_id}_recurrentlist_${i}_status_time_image`}
                                            src="images/icons8-time.svg" alt="time" style={styles.time} />
                                        {_?.status}
                                    </Text>
                                }
                            </Div>
                            <Div
                                id={`${parent_id}_recurrentlist_${i}_trash_div`}
                                style={styles.flexCenter}>
                                <Icons
                                    id={`${parent_id}_recurrentlist_${i}_trash_icons`}
                                    fontIcon="trash" style={styles.trash} onClick={() => deleteRecurrentList(_, i)} />
                                <Div
                                    id={`${parent_id}_recurrentlist_${i}_CalendarIcon_div`}
                                    aria-describedby={id} style={{ cursor: 'pointer', display: 'flex' }} onClick={(event) => handleClick(_, event, i)}>
                                    <CalendarIcon
                                        id={`${parent_id}_recurrentlist_${i}_CalendarIcon`}
                                        color={"#B6B6B6"} width={"12"} height={"12"} />
                                </Div>
                            </Div>
                        </Div>
                    )
                })
            }
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Div
                    id={`${parent_id}_recurrentlist_Calendar_div`}
                >
                    <DatePicker
                        id={`${parent_id}_recurrentlist_Calendar_DatePicker`}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                        // selected={this.state.dob.value}
                        selected={new Date()}
                        // showPopperArrow={false}
                        // showYearDropdown
                        inline
                        onChange={date => {
                            handleDate(moment(date).format('YYYY-MM-DD,hh:mm A'))
                            // handleRecurrentData('startsOn','dateTime',moment(date).format('DD-MM-YYYY,hh:mm A'))
                        }}
                    // placeholderText="DD-MM-YYYY"
                    // maxDate={new Date()}
                    // className={"pc_regular"}
                    />
                </Div>
            </Popover>
        </div>
    )
}

RecurrentList.defaultProps = {
    title: 'Recurrent',
    recurrentList: [],
    padding: 0,
    width: '100%',
    onChangeState: null
}