import React from 'react';
import { Div, Divider, H6, Text, Icons } from 'qdm-component-library';
import { UIColor } from '../../themes/theme';
export const BillingDetails = (props) => {

    const styles = {
        header: {
            padding: 10
        },
        h6: {
            fontSize: 12,
            color: UIColor.primaryText.color,
            margin: 0
        },
        status: {
            fontSize: 10,
            color: UIColor.secondaryText.color,
        },
        divider: {
            margin: 0
        },
        flexCenterBetween: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        flexCenter: {
            display: 'flex',
            alignItems: 'center',
        }
    }
    const { parent_id } = props;
    return (
        <div
            id={`${parent_id}_billing_details_parent_div`}
        >
            <Div
                id={`${parent_id}_billing_details_title_div`}
                style={styles.header}>
                <H6
                    id={`${parent_id}_billing_details_${props?.title}_h6`}
                    style={styles.h6} className="pc_semibold">{props?.title}</H6>
            </Div>
            <Divider
                id={`${parent_id}_billing_details_bottom_divider`}
                style={styles.divider} />
            <Div
                id={`${parent_id}_billing_details_name_payment_div`}
                style={styles.header}>
                <Div
                    id={`${parent_id}_billing_details_name_payment_sub_div`}
                    style={{ ...styles.flexCenterBetween, marginBottom: 5 }}>
                    {props?.name && <H6
                        id={`${parent_id}_billing_details_${props?.name}_h6`}
                        style={styles.h6} className="pc_semibold">{props?.name}</H6>}
                    {props?.payment && <Text
                        id={`${parent_id}_billing_details_${props?.payment}_text`}
                        style={styles.status} className="pc_regular">Payment&nbsp;:&nbsp;{props?.payment}</Text>}
                </Div>
                <Div
                    id={`${parent_id}_billing_details_amount_div`}
                    style={styles.flexCenterBetween}>
                    {props?.amount &&
                        <Text
                            id={`${parent_id}_billing_details_amount_text`}
                        >
                            <Text
                                id={`${parent_id}_billing_details_${props?.amount.replaceAll(" ", "_")}_text`}
                                style={{ ...styles.status, color: UIColor.secondaryColor.color }} className="pc_regular">{props?.amount}</Text>
                            {props?.status && <Text
                                id={`${parent_id}_billing_details_${props?.status}_text`}
                                style={styles.status} className="pc_regular">&nbsp;•&nbsp;{props?.status}</Text>}
                        </Text>
                    }
                    {props?.date && <Text
                        id={`${parent_id}_billing_details_${props?.date}_text`}
                        style={{ ...styles.status, color: UIColor.primaryText.color }} className="pc_medium">{props?.date}</Text>}
                </Div>
                <Divider
                    id={`${parent_id}_billing_details_divider`}
                    style={{ margin: '7px 0px' }} />
                {
                    props?.pharmacyName &&
                    <Text
                        id={`${parent_id}_billing_details_pharmacyName_text`}
                    >
                        <Icons
                            id={`${parent_id}_billing_details_pharmacyName_map-marker_icons`}
                            fontIcon="map-marker" style={{ marginRight: 5 }} />
                        <Text
                            id={`${parent_id}_billing_details_${props?.pharmacyName}_text`}
                            style={styles.status} className="pc_regular">{props?.pharmacyName}</Text>
                    </Text>
                }
                {props?.locationCode && <Text
                    id={`${parent_id}_billing_details_${props?.locationCode}_text`}
                    style={styles.status} className="pc_regular">&nbsp;•&nbsp;{props?.locationCode}</Text>}
            </Div>
        </div>
    )
}

BillingDetails.defaultProps = {
    title: 'Billing Details',
    name: '',
    payment: '',
    amount: '',
    status: '',
    date: '',
    pharmacyName: '',
    locationCode: ''
}