import React from "react";
import MuiInputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withStyles, makeStyles } from "@material-ui/core/styles";
// import Autocomplete from "./autocomplete";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const InputBase = withStyles((theme) => ({
  root: {
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
    padding: "10px 6px",
    // background: "white",
    borderRadius: "8px",
    "& .App1-MuiInputBase-input": {
      minWidth: 20,
    },
  },
  input: {
    padding: 0,
    "&:focus": {
      backgroundColor: "unset",
    },
    color: theme.palette.text.primary,

    "&.Mui-disabled": {
      color: theme.palette.text.disabled,
    },
  },
}))(MuiInputBase);

function Autocomplete(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <MuiAutocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      disabled={props.disabled}
      PaperComponent={({ children }) => (
        <Paper className={props.classes.dropdown}>{children}</Paper>
      )}
      renderInput={(params) => (
        <InputBase
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          placeholder={props.placeholder}
          disabled={props.disabled}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={handleOpen}
                disabled={props.disabled}
                style={{ ...(open && { transform: "rotate(180deg)" }) }}
              >
                <ExpandMoreIcon
                  htmlColor="#6A7888"
                  style={{ fontSize: "1.425rem" }}
                />
              </IconButton>
            </InputAdornment>
          }
        />
      )}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root1: {
    display: "flex",
    borderRadius: 8,
    border: `1px solid ${theme?.palette?.border?.textField}`,
    overflow: "hidden",
    background: `${theme?.palette?.background?.table} !important`,
    "& .Mui-disabled": {
      backgroundColor: '#F1F6FC !important'
    }
  },
  input: {
    background: `${theme?.palette?.background?.table} !important`,
    paddingLeft: "12px",
    // color: theme.palette.text.primary,
  },
  divider: {
    backgroundColor: theme?.palette?.border?.textField,
  },
  dropdown: {
    background: `${theme?.palette?.background?.table} !important`,
    "&:input": {
      color: theme?.palette?.text?.primary,
    },
  },
}));

export function TextfieldWithSelect(props) {
  const classes = useStyles();
  const { InputProps = {}, AutocompleteProps = {} } = props;
  return (
    <Paper elevation={0} className={classes.root1}>
      <InputBase className={classes.input} {...InputProps} />
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <Autocomplete {...AutocompleteProps} classes={classes} />
    </Paper>
  );
}

export default TextfieldWithSelect;
