import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../../themes/theme";

export const useStyles = makeStyles((theme) => ({
  weekcalendar: {
    "& span": {
      fontSize: 14,
      "& i": {
        fontSize: "24px !important",
      },
    },
    "& .month": {
      fontSize: 12,
      fontFamily: "pc_regular",
      backgroundColor: UIColor.lineBorderFill.color,
      color: "#6A7888",
    },
    "& .fadedDay": {
      color: "#B6B6B6",
      fontSize: 12,
      fontFamily: "pc_regular",
    },
    "& .activeDay": {
      color: "#000000",
      fontSize: 12,
      fontFamily: "pc_regular",
    },
    "& .fadedDate": {
      color: "#B6B6B6",
      // fontSize:16,
      fontFamily: "pc_medium",
    },
    "& .activeDate": {
      // color:'#000000',
      // fontSize:16,
      fontFamily: "pc_medium",
    },
  },
  TapSelect: {
    "& div": {
      padding: "2px 6px",
      "& span": {
        fontSize: 13,
        fontWeight: "600",
        letterSpacing: "0.9px",
      },
    },
  },
  hrTag: {
    margin: "0 !important",
  },

  monthCalendar: {
    "& strong": {
      fontSize: 13,
    },
    "& span": {
      fontSize: 13,
    },
    backgroundColor: "#fff",
  },
  clear: {
    backgroundColor: "transparent",
    border: "none",
    padding: "9px 12px",
    cursor: "pointer",
    color: "#6F6F6F",
    fontSize: 14,
  },
  apply: {
    backgroundColor: "transparent",
    border: "none",
    padding: "9px 12px",
    cursor: "pointer",
    color: "#0071F2",
    fontSize: 14,
  },
  divider: {
    minWidth: "0px",
    marginRight: "10px",
    marginLeft: "10px",
    color: "#B6B6B6",
    marginBottom: 0,
    marginTop: 0,
  },
  tabs: {
    "& .inActiveChildHead": {
      fontSize: 14,
      fontFamily: "pc_regular",
    },
    "& .inActiveChildSubHead": {
      fontFamily: "pc_regular",
    },
    "& .activeChildSubHead": {
      fontFamily: "pc_regular",
    },
    "& .activeChildHead": {
      fontSize: 14,
      fontFamily: "pc_medium",
    },
  },
  earliestInput: {
    fontFamily: "pc_regular",
    fontSize: 14,
    "&::placeholder": {
      fontFamily: "pc_regular",
      fontSize: 14,
      color: "#b6b6b6",
    },
  },
  //  Tap Component Styles
  selectedTab: {
    backgroundColor: "#2A60BC !important",
    color: "#FFFFFF !important",
    fontSize: 12,
    // fontWeight: 600,
    // marginTop: "8%",
    textTransform: "none",
  },
  selecteTab: {
    // marginTop: "4px",
    fontSize: 12,
    color: "#101010",
    textTransform: "none",
  },
  icons: {
    display: "flex",
    alignItems: "center",
    marginTop: "6px",
    justifyContent: "space-around",
    marginBottom: "16%",
  },
  textSize: {
    // marginTop: 4,
    fontSize: 10,
  },
  typeslot: {
    display: "flex",
    justifyContent: "space-between",
    // marginBottom: "2%",
  },
  text: {
    marginTop: 4,
    fontFamily: "pc_medium",
    fontSize: 12,
  },
}));
