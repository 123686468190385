import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: (props) => ({
    height: props?.size,
    width: props?.size,
    minWidth: props?.size,
    minHeight: props?.size,
    backgroundColor: props?.backgroundColor,
    borderRadius: "50%",
    display: "inline-block",
    marginLeft: props?.space,
    marginRight: props?.space,
  }),
});

function Dot(props) {
  const classes = useStyles(props);
  return <div className={classes.root}></div>;
}

export default Dot;

Dot.defaultProps = {
  size: 4,
  backgroundColor: "#bbb",
  space: 2
};

Dot.propTypes = {
  size: PropTypes.number,
  backgroundColor: PropTypes.string,
};