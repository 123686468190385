import React from "react";
import { Div, Avatar, H6, Card, Text, Image } from "qdm-component-library";
import { withStyles } from "@material-ui/core";
import clockIcon from "../../../assets/img/svg/icons8-clock-black.svg"
import readIcon from "../../../assets/img/svg/icons8-reading.svg"
import { UIColor } from "../../../themes/theme";


const materialStyles = (theme) => ({
  avatarCls: {
    '& img': {
      width: '30px !important',
      height: '30px !important',
      objectFit: 'contain !important'
    }
  }
});

const PatientStatusCard = (props) => {
//   let props ={
//     "classes": {
//         "avatarCls": "PatientStatusCard-avatarCls-25"
//     },
//     "parent_id": "Financial_Registration",
//     "EncounterDetails": [
//         {
//             "properties": {
//                 "type": "update",
//                 "doc": {
//                     "_key": "12270",
//                     "_id": "Encounter/12270",
//                     "_rev": "_gr7mLjS---",
//                     "files": [],
//                     "identifier": [
//                         "IdentifierMaster/15989"
//                     ],
//                     "status": "CodingMaster/10725",
//                     "id": 2281,
//                     "relationship": "",
//                     "mode": "direct",
//                     "visit_type": "First Visit",
//                     "health_of_service": [
//                         {
//                             "category": "",
//                             "name": "",
//                             "type": ""
//                         }
//                     ],
//                     "device_details": [
//                         {
//                             "category": "",
//                             "type": "",
//                             "name": ""
//                         }
//                     ],
//                     "practitioner_details": [
//                         {
//                             "speciality": "CodingMaster/10456",
//                             "type": "",
//                             "id_name": "FrontDesk SIT Primarycare ",
//                             "clinic": "HEMODIALYSIS",
//                             "role": "Front desk",
//                             "period": ""
//                         }
//                     ],
//                     "statushistory": [
//                         {
//                             "status": "CodingMaster/10725",
//                             "period": [
//                                 "periodMaster/73131"
//                             ],
//                             "reasoncode": ""
//                         }
//                     ],
//                     "encounterdate": 1695965261,
//                     "followupDur": 0,
//                     "followupUOM": "",
//                     "eSignature": "",
//                     "encounter_no": "",
//                     "end_type": "",
//                     "accompanied_person": [
//                         {
//                             "id_type": "",
//                             "id": "",
//                             "name": "",
//                             "relationship": ""
//                         }
//                     ],
//                     "cancel_date": 0,
//                     "revision_date": 0,
//                     "reasonCode": "",
//                     "class": "CodingMaster/10049",
//                     "classhistory": [
//                         {
//                             "class": "",
//                             "period": ""
//                         }
//                     ],
//                     "type": [
//                         "CodingMaster/10264"
//                     ],
//                     "serviceType": "First Visit",
//                     "priority": "",
//                     "participant": [
//                         {
//                             "type": [
//                                 "CodingMaster/12338"
//                             ],
//                             "period": "",
//                             "Practitioner_id": 9,
//                             "PractitionerRole_id": 386,
//                             "RelatedPerson_id": [
//                                 {
//                                     "relatedpersonid": "",
//                                     "relationship": ""
//                                 }
//                             ],
//                             "specialty": ""
//                         }
//                     ],
//                     "appointmentId": [
//                         345
//                     ],
//                     "period": "",
//                     "length": [
//                         {
//                             "value": 0,
//                             "comparator": "",
//                             "unit": "",
//                             "system": "",
//                             "code": ""
//                         }
//                     ],
//                     "reasonReference": "CodingMaster/10063",
//                     "priorityreason": "",
//                     "followupReqd": false,
//                     "encounterenddate": 1696012199,
//                     "diagnosis": [
//                         {
//                             "condition": "",
//                             "use": "",
//                             "rank": ""
//                         }
//                     ],
//                     "account": [
//                         {
//                             "accountid": "",
//                             "ChangeSeqNum": 0
//                         }
//                     ],
//                     "hospitalization": [
//                         {
//                             "preAdmissionIdentifier": "",
//                             "origin": "",
//                             "admitSource": "",
//                             "reAdmission": "",
//                             "dietPreference": [],
//                             "specialCourtesy": [],
//                             "specialArrangement": [],
//                             "destination": "",
//                             "dischargeDisposition": ""
//                         }
//                     ],
//                     "location": [
//                         {
//                             "location_id": "",
//                             "status": "",
//                             "period": "",
//                             "physicalType": ""
//                         }
//                     ],
//                     "Organization_id": 138,
//                     "patient_id": 4,
//                     "episodeOfCare_id": 0,
//                     "ServiceRequest_id": 0,
//                     "participantHistory": [
//                         {
//                             "participantType": "",
//                             "practroleId": "",
//                             "practitionerId": "",
//                             "specialty": "",
//                             "relatedPersonId": "",
//                             "relationship": "",
//                             "userId": "",
//                             "startTime": 0,
//                             "endTime": 0,
//                             "reasonCode": ""
//                         }
//                     ],
//                     "source": "",
//                     "plannedStartDate": 0,
//                     "plannedEndDate": 0,
//                     "subject": "",
//                     "basedOn": "",
//                     "partOf": "",
//                     "createdby": "",
//                     "createddate": 1695954477,
//                     "updatedby": "",
//                     "updatedate": 1695965263,
//                     "activestatus": true
//                 }
//             },
//             "db_name": "pc_dialysis",
//             "collection_name": "Encounter"
//         }
//     ],
//     "pic": "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
//     "titles": [
//         "Encounter/12270",
//         "Outpatient",
//         "First Visit",
//         "Nephrology"
//     ],
//     "date": "September 29th 2023, 10:57 am",
//     "languages": "",
//     "padding": true,
//     "name": ""
// }
  const {
    classes,
    parent_id,
    pic,
    name,
    titles,
    date,
    languages
  } = props;
  const  styles = {
    rowFlex: {
        display: 'flex',
        alignItems: 'center'
    },
    columnFlex: {
        display: 'flex',
        flexDirection: 'column'
    },
    h6: {
        fontSize: 13,
        color: UIColor.primaryColor.color,
        margin: 0
    },
    span: {
        fontSize: 12,
        color: UIColor.tertiaryText.color,
        margin: 0
    },
    span_lag: {
        fontSize: 12,
        color: UIColor.tertiaryText.color,
        margin: 0,
        // width: 'calc(65%)',
        whiteSpace: "nowrap",
        overflow: "hidden",
        // textOverflow: "ellipsis"
    },
    cardStyle: {
        // boxShadow: '0px 10px 25px #0000000A',
        boxShadow: "none",
        borderRadius: 8,
        marginLeft: "5px",
        padding: 10
        // ...props.padding ? {padding: 10} : {padding: 0}
    },
    avatar: {
        margin: 0,
        marginRight: 15,
        width: 48,
        height: 48,
        borderRadius: 8,
        backgroundColor: "#F0F0FA"
    },
    img: {
        width: 16,
        height: 16,
        marginRight: 10
    }
}



  return (
    <Card
      id={`${parent_id}_patientstatus_parent_card`}
      style={styles.cardStyle}
    >
      <Div
        id={`${parent_id}_patientstatus_parent_div`}
        style={{ ...styles.rowFlex }}
      >
        {pic ? (
          <Avatar
            id={`${parent_id}_patientstatus_${name?.[0]}_avatar`}
            src={pic}
            variant="rounded"
            className={classes.avatarCls}
            inLineStyles={styles.avatar}
          />
        ) : (
          <Avatar
            id={`${parent_id}_patientstatus_${name?.[0]}_avatar`}
            src={pic}
            variant="rounded"
            // className={classes.avatarCls}
            inLineStyles={styles.avatar}
          >
            {name?.[0]}
          </Avatar>
        )}
        <Div
          id={`${parent_id}_patientstatus_sub_parent_div`}
          style={styles.columnFlex}
        >
          <Text
            id={`${parent_id}_patientstatus_text`}
            style={styles.rowFlex}
          >
            {titles?.length > 0 &&
              titles.map((val, i) => {
                if (!val) {
                  return <React.Fragment key={i} />;
                }
                return (
                  <>
                    <H6
                      id={`${parent_id}_${i}_patientstatus_h6`}
                      className="pc_semibold"
                      style={styles.h6}
                      key={i}
                    >
                      {val}
                    </H6>
                    {i + 1 !== titles.length && (titles[i + 1] || titles[i + 1] === "") && (
                      <Text
                        id={`${parent_id}_${i}_patientstatus_dot_text`}
                        className="pc_regular"
                        style={{ ...styles.span, margin: '0px 5px' }}
                      >
                        •
                      </Text>
                    )}
                  </>
                );
              })}
          </Text>
          <Div
            id={`${parent_id}_patientstatus_image_div`}
            style={{ ...styles.rowFlex, marginTop: 7 }}
          >
            {date && (
              <Div
                id={`${parent_id}_patientstatus_image_div`}
                style={{ ...styles.rowFlex, marginRight: 15, minWidth: "120px" }}
              >
                <Image
                  id={`${parent_id}_patientstatus_black_image`}
                  style={styles.img}
                  src={clockIcon}
                  alt="id"
                />
                <Text
                  id={`${parent_id}_patientstatus_on_text`}
                  className="pc_regular"
                  style={styles.span}
                >
                  On {date}
                </Text>
              </Div>
            )}
            {languages?.length > 0 && (
              <Div
                id={`${parent_id}_patientstatus_reading_div`}
                style={styles.rowFlex}
              >
                <Image
                  id={`${parent_id}_patientstatus_reading_image`}
                  style={styles.img}
                  src={readIcon}
                  alt="id"
                />
                <Text
                  id={`${parent_id}_patientstatus_language_text`}
                  className="pc_regular"
                  style={styles.span_lag}
                >
                  {languages}
                </Text>
              </Div>
            )}
          </Div>
        </Div>
      </Div>
    </Card>
  );
};

PatientStatusCard.defaultProps = {
  pic: null,
  name: "",
  titles: [],
  date: null,
  languages: null
};

export default withStyles(materialStyles)(PatientStatusCard);







