import { uploadImage, AlertProps } from "../../utils";
import moment from "moment";

// Below methods belongs to path - /components/patientIdentification.js
export const setData = (props, name, value, index, limit) => {
  if (limit) {
    if (limit < value.length) {
      return false;
    }
  }
  let patientData = props[props.saveto];
  if (name === "id_type") {
    patientData.identification[index]["id_number"] = "";
  }
  patientData.identification[index][name] = value;
  if (patientData.identification[index].error) {
    patientData.identification[index].error[name] = false;
  }
  patientData[props.setTo]({
    ...patientData,
  });
};

export const setDataUpload = async (props, name, value, index) => {
  var UploadedFiles = [];
  let patientData = props[props.saveto];
  UploadedFiles = await uploadImage([value]);
  const dat = UploadedFiles?.map((v) => {
    return {
      fileid: v.fileid,
      filetype: v.filetype,
      objectid: v.objectid,
      filename: v.fileName,
    };
  });
  patientData.identification[index][name] = dat[0];
  if (patientData.identification[index].error) {
    patientData.identification[index].error[name] = false;
  }
  patientData[props.setTo]({
    ...patientData,
  });
};

export const newRowAdd = (props) => {
  let patientData = props[props.saveto];
  patientData.identification.push({});
  patientData[props.setTo]({
    ...patientData,
  });
};

export const getSize = (size) => {
  let fileSize = size / 1024 / 1024;
  if (fileSize < 1) {
    return `${parseInt(size / 1024) + 1} KB`;
  } else {
    return `${parseInt(fileSize)} MB`;
  }
};

export const handleDelete = (props, i) => {
  let patientData = props[props.saveto];
  if (patientData.identification.length <= 1) {
    props.alert.setSnack({
      open: true,
      severity: AlertProps.severity.error,
      msg: "Atleast one identification should be entered",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
  } else {
    patientData.identification.splice(i, 1);
    patientData[props.setTo]({
      ...patientData,
    });
  }
};

export const FlterOptions = (props, type) => {
  const { allMasters } = props;
  var lista = [];
  const data = allMasters?.[type];
  for (let index in data) {
    var datatemp = {
      value: data[index]?.coding?.display,
      code: data[index]?.coding?.code,
      dis: data[index]?.Type,
      _id: data[index]?.coding?._id,
    };
    lista.push(datatemp);
  }
  return lista;
};

// below methods belongs to path - /components/patientNames.js
export const editStatusOpen = (e, state) => {
  if (state.editStatus === null) {
    //   this.setState({ ...state, editStatus: e.currentTarget });
    return { ...state, editStatus: e.currentTarget };
  } else {
    return { ...state, editStatus: null };
  }
};

export const handlepopoverclose = (state) => {
  return { ...state, editStatus: null };
};

export const onChangeState = (state, key, value) => {
  return {
    ...state,
    [key]: value,
  };
};

export const setDetails = (props, name, value, next, limit) => {
  if (limit) {
    if (limit < value.length) {
      return false;
    }
  }
  let patientData = props[props.saveto];
  patientData.details[name] = value;
  if (patientData.details?.error) {
    patientData.details.error[name] = false;
  }
  if (next === "age") {
    const age = moment().diff(moment(value, "YYYY-MM-DD"), "years");
    if (age > 200 || moment().unix() < moment(value, "YYYY-MM-DD").unix()) {
      if (patientData.details?.error) {
        patientData.details.error[name] = true;
      } else {
        patientData.details.error = {};
        patientData.details.error[name] = true;
      }
    } else {
      // ;
      let days = moment().diff(moment(value, "YYYY-MM-DD"), "days");
      let months = moment().diff(moment(value, "YYYY-MM-DD"), "months");
      let years = moment().diff(moment(value, "YYYY-MM-DD"), "years");
      if (days <= 90) {
        patientData.details[next] = days;
        patientData.details.ageType = { value: "Days", label: "Days" };
      } else if (days > 90 && days < 365) {
        patientData.details[next] = months;
        patientData.details.ageType = { value: "Months", label: "Months" };
      } else if (days >= 365) {
        patientData.details[next] = years;
        patientData.details.ageType = { value: "Years", label: "Years" };
      }
      // patientData.details[next] = age;
      // let ageTypeOption = this.FlterOptions('VITALSUOM')
      // let option = ageTypeOption.filter(val => val.label.toLocaleLowerCase() === "years")[0];
      // patientData.details.ageType = option ? option : { value: 'Years', label: 'Years' };
    }
  }
  if (name === "ageType" || name === "age" || name === "entered_age") {
    const ageType = patientData?.details?.ageType?.value;
    const age = patientData?.details?.age;
    const enteredAge = patientData?.details?.entered_age;
    if (ageType && age && enteredAge) {
      const newDate = moment()
        .subtract(Number(age), ageType.toLowerCase())
        .format("YYYY-MM-DD");
      patientData.details.dob = newDate;
    }
  }
  patientData[props.setTo]({
    ...patientData,
  });
};

export const checkAge = (val) => {
  let years = moment().diff(val, "years");
  let days = moment().diff(val, "days");
  if (!(years < 200 && days > 0)) {
    return true;
  }
  return false;
};

export const checkAgeError = (details) => {
  if (!details?.dob && details?.error && details?.error?.["dob"]) {
    return "";
  } else if (
    details?.error?.["dob"] &&
    details?.dob &&
    checkAge(details?.dob)
  ) {
    return "Age should be less than 200 years";
  } else {
    return "";
  }
};

export const enterAge = (props, val, setDetails) => {
  if (val && parseInt(val) <= 200) {
    setDetails(props, "age", val, null, 3);
  } else {
    setDetails(props, "age", "", null, 3);
  }
};

export const handleSave = (props, n, d) => {
  let patientData = props[props.saveto];
  patientData[n] = d;
  patientData[props.setTo]({
    ...patientData,
  });
};

export const getUseOptions = (props, value) => {
  let option = FlterOptions(props, "USE");
  const { names } = props[props.saveto];
  if (Array.isArray(names) && names.length > 0) {
    let useList = names.map((val) => val?.use?.value)?.filter((val) => val);
    if (useList) {
      option = option.filter(
        (val) => useList.indexOf(val.value) === -1 || val.value === value
      );
    }
  }
  let sortedOption = option?.sort(function (a, b) {
    var textA = a?.value?.toUpperCase();
    var textB = b?.value?.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  return sortedOption;
};

export const label = (props, index) => {
  let { contact } = props[props.saveto];

  if (contact[index]?.mode?.value?.toLowerCase?.() === "phone") {
    return "Phone";
  } else if (contact[index]?.mode?.value?.toLowerCase?.() === "fax") {
    return "Fax";
  } else if (
    contact[index]?.mode?.value?.toLowerCase?.() === "email" ||
    contact[index]?.mode?.value?.toLowerCase?.() === "e-mail"
  ) {
    return "Email";
  } else if (contact[index]?.mode?.value?.toLowerCase() === "url") {
    return "Url";
  } else if (contact[index]?.mode?.value?.toLowerCase() === "sms") {
    return "SMS";
  } else if (contact[index]?.mode?.value?.toLowerCase() === "other") {
    return "Other";
  } else if (contact[index]?.mode?.value?.toLowerCase() === "pager") {
    return "Pager";
  } else {
    return "Mobile";
  }
};

export const contactModeHelperText = (props, contact, i) => {
  if (contact[i]?.error && contact?.error["mobile"]) {
    if (contact[i].mobile && label(props, i) === "Phone") {
      return "Enter valid mobile number";
    } else if (
      (contact[i].mobile && label(props, i) === "Email") ||
      (contact[i].mobile && label(props, i) === "E-mail")
    ) {
      return "Enter valid Email ID";
    } else {
      return "";
    }
  }
};
