import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../../themes/theme";
import { AlertContext } from "../../../context";
import { AlertProps } from "../../../utils";
import { useSelector } from "react-redux";
import Mui5ReactHkFormAutocomplete from "../../../components/MuihookformAutocomplete";
// import { DevTool } from "@hookform/devtools";
const useStyles = makeStyles((theme) => ({
  autocompleteBorder: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
        borderRadius: 8,
      },
    },
  },
  Autocomplete: {
    "& .MuiAutocomplete-inputRoot": {
      borderRadius: 8,
      border: "1px  #E0E0E0",
      fontSize: "16px",
    },
  },
  datePicker: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 8,
      },
    },
  },
}));

const FinanceInputs = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const alert = useContext(AlertContext);
  const [payerTypedd, setPayerTypedd] = useState([]);
  const [plandd, setPlandd] = useState([]);
  const [payerdd, setPayerdd] = useState([]);
  const [guarantorTypedd, setGuarantorTypedd] = useState([]);
  const [guarantordd, setGuarantordd] = useState([]);
  const [sponserdd, setSponserdd] = useState([]);
  const [isSelfPay, setIsSelfPay] = useState(false);
  const [isDependent, setIsDependent] = useState(false);
  const [isReldisabled, setIsRelDisabled] = useState(false);

  const PayerTypeLoading = useSelector(
    (state) => state?.registration?.payerType?.loading
  );
  const guarantorIdLoading = useSelector(
    (state) => state?.registration?.guarantorid?.loading
  );
  const guarantorTypeLoading = useSelector(
    (state) => state?.registration?.guarantortype?.loading
  );
  const getPlanLoading = useSelector(
    (state) => state?.registration?.plan?.loading
  );
  const getPayerLoading = useSelector(
    (state) => state?.registration?.payer?.loading
  );
  const getsponserLoading = useSelector(
    (state) => state?.CommonSlice?.someMasters?.loading
  );
  const MasterLoading = useSelector(
    (state) => state?.CommonSlice?.someMasters?.loading
  );
  const screenDefaultLoading = useSelector(
    (state) => state?.CommonSlice?.screenDefault?.loading
  );

  const form = useForm({
    defaultValues: {
      payertype: null,
      payer: null,
      guarantorType: null,
      guarantor: null,
      policyPlan: null,
      policyStart: "",
      policyEnd: "",
      membershipId: "",
      membershipType: null,
      sponserName: null,
      memberRelationship: null,
      primeMemberid: "",
      glNumber: "",
      glIssue: "",
      serviceStart: "",
      serviceEnd: "",
      finClasstype: null,
      finClassVal: null,
    },
  });
  const { handleSubmit, formState, control, setValue, reset } = form;
  const { errors } = formState;

  async function getAllData() {
    const payerResponse = await dispatch(actions.PAYER_TYPE(""));
    setPayerTypedd(payerResponse?.payload?.data);
    const sponserResponse = await dispatch(
      actions.GET_SPONSER_NAME({ patientId: location?.state?.patientId })
    );
    setSponserdd(sponserResponse?.payload?.data);
  }

  const Get_Plan = async (planValue) => {
    const response = await dispatch(actions.Get_Plan({ payerId: planValue }));
    setPlandd(response?.payload?.data);
  };
  const GET_PAYER = async (payerType) => {
    const response = await dispatch(
      actions.GET_PAYER({ payerType: payerType })
    );
    setPayerdd(response?.payload?.data);
    return response?.payload?.data;
  };
  const GET_GuarantorType = async (guarantorType) => {
    const response = await dispatch(
      actions.GET_GUARANTOR_TYPE({ payerType: guarantorType })
    );
    setGuarantorTypedd(response?.payload?.data);
    return response?.payload?.data;
  };
  const GET_GuarantorId = async (guarantorId) => {
    const response = await dispatch(
      actions.GET_GUARANTOR_ID({ payerType: guarantorId })
    );
    setGuarantordd(response?.payload?.data);
    return response?.payload?.data;
  };

  async function PayerTypeDependencyOperations(payertype, touch) {
    if (process.env.REACT_APP_SELF_PAYER_TYPE === payertype?._id) {
      setIsSelfPay(true);
    } else {
      setIsSelfPay(false);
    }
    GET_GuarantorType(payertype?._id);
    GET_GuarantorId(payertype?._id);
    let payer = await GET_PAYER(payertype?._id);
    Get_Plan(payer?.[0]?._id);
    if (touch) {
      setValue("payer", null);
      setValue("guarantorType", null);
      setValue("guarantor", null);
      setValue("policyPlan", null);
    }
  }
  const debouncedCall = debounce((fn, data, touched) => {
    data && fn(data, touched);
  }, 600);
  const payervalidation = (payerData, currentPayerData) => {
    let currentData = currentPayerData?.payertype?._id
      ?.trim()
      .concat(
        currentPayerData?.payer?._id?.trim(),
        currentPayerData?.policyPlan?._id?.trim()
      );
    return payerData.some((v) => {
      let loopData = v?.payertype?._id
        ?.trim()
        .concat(v?.payer?._id?.trim(), v?.policyPlan?._id?.trim());
      return currentData === loopData;
    });
  };
  const onSubmit = (data) => {
    if (editData?.id) {
      let filterCurrentDataFromTotalPay = TotalPay.filter(
        (v) => editData?.id !== v?.id
      );
      let error = payervalidation(filterCurrentDataFromTotalPay, data);
      if (error) {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.warning,
          msg: "This combination already exists",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else {
        Handlesave(data, editData?.id,editData?.mode);
        reset();
        setIsSelfPay(false);
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: editData?.id
            ? "payer edited successfully! "
            : "payer added successfully !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
    } else {
      let error = payervalidation(TotalPay, data);
      if (!error) {
        data = {
          id: uuidv4(),
          is_deleted: false,
          deleteIcon: true,
          editIcon: true,
          isDraggable: true,
          isNew: true,
          mode:"add",
          ...data,
        };
        Handlesave(data);
        reset();
        setIsSelfPay(false);
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "payer  added !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.warning,
          msg: "This combination already exists",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
    }
  };
  function sponserDependecyOperation(sponser) {
    let data = sponserdd.find((v) => v._id === sponser?._id);
    if (data) {
      setValue("memberRelationship", {
        _id: data?.relatedperson?._id,
        value: data?.relatedperson?.display,
      });
      setIsRelDisabled(true);
    } else {
      setIsRelDisabled(false);
    }
  }
  function membershipTypeDepedencyOperation(data, touched) {
    if (process.env.REACT_APP_MEMBERSHIP_TYPE_DEPENDENT === data?._id) {
      setIsDependent(true);
    } else {
      if (touched) {
        setValue("sponserName", null);
        setValue("memberRelationship", null);
        setValue("primeMemberid", "");
      }

      setIsDependent(false);
    }
  }

  const {
    Handlesave = () => false,
    editData = {},
    defData = {},
    allMasters = {},
    TotalPay = [],
  } = props;

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    if (editData?.id) {
      [
        "payertype",
        "payer",
        "guarantorType",
        "guarantor",
        "policyPlan",
        "policyStart",
        "policyEnd",
        "membershipId",
        "membershipType",
        "sponserName",
        "memberRelationship",
        "primeMemberid",
        "glNumber",
        "glIssue",
        "serviceStart",
        "serviceEnd",
        "finClasstype",
        "finClassVal",
      ].forEach((v) => {
        setValue(v, editData[v], { shouldTouch: true, shouldDirty: true });
        if (
          v === "payertype" &&
          editData?.[v]?._id === process.env.REACT_APP_SELF_PAYER_TYPE
        ) {
          setIsSelfPay(true);
        }
      });
      PayerTypeDependencyOperations(editData?.payertype);
      membershipTypeDepedencyOperation(editData?.membershipType);
      sponserDependecyOperation(editData?.sponserName);
    }
  }, [editData?.id]);
  useEffect(() => {
    if (defData?.payertype?._id) {
      Object.keys(defData).forEach((v) => {
        setValue(v, defData[v] || null);
      });
      PayerTypeDependencyOperations(defData?.payertype);
      membershipTypeDepedencyOperation(defData?.membershipType);
    }
  }, [defData?.payertype?._id]);

  const isViewMode = location.state?.IsViewMode;
  return (
    <div className="pv-root">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className="pv-root" style={{ margin: 1 }}>
          <Grid
            container
            lg={12}
            md={12}
            item
            spacing={1}
            style={{ padding: 12 }}
          >
            <Grid item lg={12}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  fontFamily: "pc_medium !important",
                  fontSize: 12,
                  color: UIColor.primaryColor.color,
                }}
                id="financial_applicable_label_h6"
              >
                {" "}
                PAYER DETAILS
              </Typography>
            </Grid>
            <Grid item>
              {/* <Mui5ReactHkFormAutocomplete
              name="payertype"
              control={control}
              label="Payer Type"
              options={payerTypedd}
              loading={screenDefaultLoading ||PayerTypeLoading}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.longdesc}
              customfn={(data)=> debouncedCall(PayerTypeDependencyOperations, data, true)}
              rules={{ required: 'Payer Type is required' }}
            /> */}
              <Controller
                name={"payertype"}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "this field is required",
                  },
                  validate: {},
                }}
                render={({
                  field: { ref, onChange, ...restField },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...restField}
                    size="small"
                    id="payertype"
                    options={payerTypedd || []}
                    isOptionEqualToValue={(Option, value) =>
                      Option?._id === value?._id
                    }
                    disabled={isViewMode}
                    getOptionLabel={(v) => v?.longdesc || ""}
                    sx={{ minWidth: 330 }}
                    fullWidth
                    className={classes.Autocomplete}
                    onChange={(_, data) => {
                      onChange(data);
                      debouncedCall(PayerTypeDependencyOperations, data, true);
                    }}
                    loading={screenDefaultLoading || PayerTypeLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        label="Payer Type"
                        error={error?.payertype}
                        helperText={error?.payertype?.message}
                        autoFocus
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {PayerTypeLoading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"payer"}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "this field is required",
                  },
                  validate: {},
                }}
                render={({
                  field: { ref, onChange, ...restField },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...restField}
                    size="small"
                    id="payer"
                    options={payerdd || []}
                    isOptionEqualToValue={(Option, value) =>
                      Option?._id === value?._id
                    }
                    disabled={isViewMode}
                    getOptionLabel={(options) => options?.longdesc || ""}
                    sx={{ minWidth: 340 }}
                    fullWidth
                    className={classes.Autocomplete}
                    onChange={(_, data) => {
                      onChange(data);
                      debouncedCall(Get_Plan, data?._id);
                    }}
                    loading={screenDefaultLoading || getPayerLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        label="Payer"
                        error={error?.payer}
                        helperText={error?.payer?.message}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {getPayerLoading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"guarantorType"}
                control={control}
                render={({
                  field: { ref, onChange, ...restField },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...restField}
                    onChange={(_, data) => {
                      onChange(data);
                    }}
                    size="small"
                    disabled={isSelfPay || isViewMode}
                    id="GuarantorType"
                    options={guarantorTypedd}
                    isOptionEqualToValue={(Option, value) =>
                      Option?._id === value?._id
                    }
                    getOptionLabel={(options) => options?.payercode || ""}
                    sx={{ minWidth: 300 }}
                    fullWidth
                    className={classes.Autocomplete}
                    loading={screenDefaultLoading || guarantorTypeLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Guarantor Type"
                        error={errors?.guarantorType}
                        helperText={errors?.guarantorType?.message}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {guarantorTypeLoading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"guarantor"}
                control={control}
                render={({
                  field: { ref, onChange, ...restField },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...restField}
                    onChange={(_, data) => {
                      onChange(data);
                    }}
                    size="small"
                    id="Guarantor"
                    disabled={isSelfPay || isViewMode}
                    options={guarantordd}
                    isOptionEqualToValue={(Option, value) =>
                      Option?._id === value?._id
                    }
                    getOptionLabel={(options) => options?.longdesc || ""}
                    sx={{ minWidth: 320 }}
                    fullWidth
                    className={classes.Autocomplete}
                    loading={screenDefaultLoading || guarantorIdLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Guarantor"
                        error={errors?.guarantor}
                        helperText={errors?.guarantor?.message}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {guarantorIdLoading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"policyPlan"}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "this field is required",
                  },
                  validate: {},
                }}
                render={({
                  field: { ref, onChange, ...restField },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...restField}
                    loading={screenDefaultLoading || getPlanLoading}
                    size="small"
                    id="policyPlan"
                    options={plandd || []}
                    isOptionEqualToValue={(Option, value) =>
                      Option?._id === value?._id
                    }
                    getOptionLabel={(v) => v?.longdesc || ""}
                    sx={{ minWidth: 300 }}
                    fullWidth
                    className={classes.Autocomplete}
                    onChange={(_, data) => {
                      onChange(data);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {getPlanLoading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        required={true}
                        label="Policy/Plan"
                        error={error?.policyPlan}
                        helperText={error?.policyPlan?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"policyStart"}
                control={control}
                rules={{
                  required: {
                    value: isSelfPay ? false : true,
                    message: "this field is required",
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    sx={{ height: "20px !important", minWidth: "200px" }}
                    disableFuture
                    label="Policy Start"
                    onChange={onChange}
                    disabled={isSelfPay || isViewMode}
                    className={classes.datePicker}
                    value={value || null}
                    slotProps={{
                      textField: {
                        helperText: error?.message,
                        required: isSelfPay ? false : true,
                        error: error,
                        size: "small",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"policyEnd"}
                control={control}
                rules={{
                  required: {
                    value: isSelfPay ? false : true,
                    message: "this field is required",
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    label="Policy End"
                    disablePast
                    disabled={isSelfPay || isViewMode}
                    onChange={onChange}
                    className={classes.datePicker}
                    value={value || null}
                    sx={{ height: "20px !important", minWidth: "200px" }}
                    slotProps={{
                      textField: {
                        helperText: error?.message,
                        required: isSelfPay ? false : true,
                        error: error,
                        size: "small",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={1} style={{ padding: 12 }}>
            <Grid item lg={12}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  fontFamily: "pc_medium !important",
                  fontSize: 12,
                  color: UIColor.primaryColor.color,
                }}
                id="financial_applicable_label_h6"
              >
                {" "}
                MEMBERSHIP DETAILS
              </Typography>
            </Grid>
            <Grid item>
              <Controller
                name={"membershipId"}
                control={control}
                rules={{
                  required: {
                    value: isSelfPay ? false : true,
                    message: "this field is required",
                  },
                }}
                render={({ field: { value, ...restField } }) => (
                  <TextField
                    {...restField}
                    value={value}
                    disabled={isSelfPay || isViewMode}
                    size="small"
                    required={isSelfPay ? false : true}
                    label="Membership Id"
                    error={errors?.membershipId}
                    helperText={errors?.membershipId?.message}
                    id="membershipId"
                    sx={{ minWidth: 230 }}
                    fullWidth
                    className={classes.datePicker}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"membershipType"}
                control={control}
                rules={{
                  required: {
                    value: isSelfPay ? false : true,
                    message: "this field is required",
                  },
                  validate: {},
                }}
                render={({ field: { ref, onChange, ...restField } }) => (
                  <Autocomplete
                    disabled={isSelfPay || isViewMode}
                    {...restField}
                    size="small"
                    id="membershipType"
                    options={allMasters?.MEMBERTYPE || []}
                    isOptionEqualToValue={(Option, value) =>
                      Option?._id === value?._id
                    }
                    getOptionLabel={(options) => options?.value || ""}
                    sx={{ minWidth: 230 }}
                    fullWidth
                    className={classes.Autocomplete}
                    onChange={(_, data) => {
                      onChange(data);
                      membershipTypeDepedencyOperation(data, true);
                    }}
                    loading={screenDefaultLoading || MasterLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={isSelfPay ? false : true}
                        label="Membership Type"
                        error={errors?.membershipType}
                        helperText={errors?.membershipType?.message}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {MasterLoading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"sponserName"}
                control={control}
                rules={{
                  required: {
                    // value: true,
                    // message: "this field is required",
                  },
                  validate: {},
                }}
                render={({ field: { ref, onChange, ...restField } }) => (
                  <Autocomplete
                    {...restField}
                    disabled={isSelfPay || !isDependent || isViewMode}
                    size="small"
                    id="sponserName"
                    options={sponserdd}
                    isOptionEqualToValue={(Option, value) =>
                      Option?._id === value?._id
                    }
                    onChange={(_, data) => {
                      onChange(data);
                      sponserDependecyOperation(data);
                    }}
                    getOptionLabel={(options) => options?.name || ""}
                    sx={{ minWidth: 320 }}
                    fullWidth
                    className={classes.Autocomplete}
                    loading={screenDefaultLoading || getsponserLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {getsponserLoading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        //required={true}
                        label="Sponser Name"
                        error={errors?.sponserNamedd}
                        helperText={errors?.sponserNamedd?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"memberRelationship"}
                control={control}
                // rules={{
                //   required: {
                //     value: true,
                //     message: "this field is required",
                //   },
                //   validate: {},
                // }}
                render={({
                  field: { ref, onChange, ...restField },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...restField}
                    onChange={(_, data) => {
                      onChange(data);
                    }}
                    disabled={
                      isSelfPay || !isDependent || isReldisabled || isViewMode
                    }
                    size="small"
                    id="memberRelationship"
                    options={allMasters?.RELATIONSHIP || []}
                    isOptionEqualToValue={(Option, value) =>
                      Option?._id === value?._id
                    }
                    getOptionLabel={(options) => options?.value || ""}
                    sx={{ minWidth: 250 }}
                    fullWidth
                    className={classes.Autocomplete}
                    loading={screenDefaultLoading || MasterLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Member Relationship"
                        error={errors?.memberRelationship}
                        helperText={errors?.memberRelationship?.message}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {MasterLoading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"primeMemberid"}
                control={control}
                render={({ field: { value, ...restField } }) => (
                  <TextField
                    {...restField}
                    value={value}
                    disabled={isSelfPay || !isDependent || isViewMode}
                    sx={{ width: 230 }}
                    id="primeMemberid"
                    size="small"
                    label="Prime Member Id"
                    className={classes.datePicker}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={1} style={{ padding: 12 }}>
            <Grid item lg={12}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  fontFamily: "pc_medium !important",
                  fontSize: 12,
                  color: UIColor.primaryColor.color,
                }}
                id="financial_applicable_label_h6"
              >
                GUARANTEE LETTER DETAILS
              </Typography>
            </Grid>
            <Grid item>
              <Controller
                name={"glNumber"}
                control={control}
                render={({ field: { value, ...restField } }) => (
                  <TextField
                    {...restField}
                    value={value}
                    disabled={isSelfPay || isViewMode}
                    size="small"
                    label="Gl Number"
                    error={errors?.glNumber}
                    helperText={errors?.glNumber?.message}
                    id="glNumber"
                    sx={{ width: 230 }}
                    className={classes.datePicker}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Controller
                name={"glIssue"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    disabled={isSelfPay || isViewMode}
                    sx={{ height: "20px !important", minWidth: "200px" }}
                    value={value || null}
                    onChange={onChange}
                    label="Gl Issue"
                    className={classes.datePicker}
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"serviceStart"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    disabled={isSelfPay || isViewMode}
                    label="Service Start"
                    disableFuture
                    value={value || null}
                    sx={{ height: "20px !important", minWidth: "200px" }}
                    onChange={onChange}
                    className={classes.datePicker}
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"serviceEnd"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    disabled={isSelfPay || isViewMode}
                    label="Service End"
                    disablePast
                    value={value || null}
                    onChange={onChange}
                    sx={{ height: "20px !important", minWidth: "200px" }}
                    className={classes.datePicker}
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={1} style={{ padding: 12 }}>
            <Grid item lg={12}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  fontFamily: "pc_medium !important",
                  fontSize: 12,
                  color: UIColor.primaryColor.color,
                }}
                id="financial_applicable_label_h6"
              >
                {" "}
                FINANCIAL CLASS
              </Typography>
            </Grid>
            <Grid item>
              <Controller
                name={"finClasstype"}
                control={control}
                render={({
                  field: { ref, onChange, ...restField },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    {...restField}
                    disabled={isSelfPay || isViewMode}
                    size="small"
                    id="finClasstype"
                    options={allMasters?.FINANCIALCLASSTYPE || []}
                    isOptionEqualToValue={(Option, value) =>
                      Option?._id === value?._id
                    }
                    getOptionLabel={(options) => options?.value || ""}
                    sx={{ minWidth: 290 }}
                    fullWidth
                    className={classes.Autocomplete}
                    onChange={(_, data) => {
                      onChange(data);
                    }}
                    loading={screenDefaultLoading || MasterLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Financial Class Type"
                        error={errors?.finClasstype}
                        helperText={errors?.finClasstype?.message}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {MasterLoading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"finClassVal"}
                control={control}
                render={({ field: { ref, onChange, ...restField } }) => (
                  <Autocomplete
                    {...restField}
                    disabled={isSelfPay || isViewMode}
                    size="small"
                    id="finClassVal"
                    options={allMasters?.FINANCIALCLASS || []}
                    isOptionEqualToValue={(Option, value) =>
                      Option?._id === value?._id
                    }
                    getOptionLabel={(options) => options?.value || ""}
                    sx={{ minWidth: 340 }}
                    fullWidth
                    className={classes.Autocomplete}
                    onChange={(_, data) => {
                      onChange(data);
                    }}
                    loading={screenDefaultLoading || MasterLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Financial Class Value"
                        error={errors?.finClassVal}
                        helperText={errors?.finClassVal?.message}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {MasterLoading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item justifyContent={"center"}>
            <Button
              type="submit"
              variant="contained"
              style={{
                color: "#fff",
                background: UIColor.primaryColor.color,
                fontWeight: 500,
                margin: "10px",
                borderRadius: "8px",
              }}
            >
              Submit
            </Button>
            <Button
              onClick={() => reset()}
              variant="contained"
              style={{
                color: "#fff",
                background: UIColor.secondaryButtonColor.color,
                fontWeight: 500,
                paddingLeft: "12px",
                margin: "10px",
                borderRadius: "8px",
                paddingRight: "12px",
              }}
            >
              clear
            </Button>
          </Grid>
        </Grid>
      </form>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default FinanceInputs;
