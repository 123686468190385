import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  urls,
  dbName,
  query_ids,
  queries,
} from "../../utils";


    const Get_Referral_From_Facility = createAsyncThunk(
        `ReferalRegOutApiSlice/get_referalfromfacility`,
        async (payload = {}, { rejectWithValue }) => {
        try {
            const { orgId } = payload;
            const body = {
                db_name: dbName,
                filter: {
                    orgid: orgId,
                    tenantid: ""
                },
                queryid: query_ids.getReferalFromFacility
            };
            const data = await fetchData(
            {
                body: JSON.stringify(body),
            },
            urls.readQdmQueries
            );
            // let returnData = [];
            // if (Array.isArray(data)) {
            // data?.map((val, i) => {
            //     returnData.push({
            //     value: val?.value,
            //     label: val?.value,
            //     _id: val?._id,
            //     });
            // });
            // }
            return {
            ...defaultState.Info,
            data: data,
            };
        } catch (err) {
            return rejectWithValue({
            ...defaultReject,
            message: err.message,
            });
        }
        }
    );

    const Get_Referral_From_Doctor = createAsyncThunk(
        `ReferalRegOutApiSlice/get_referalfromdoctor`,
        async (payload = {}, { rejectWithValue }) => {
        try {
            const { facilityId="" } = payload;
            const body = {
                db_name: dbName,
                filter: {
                    // practitionerid: practId,
                    tenantid: "",
                    facilityid: facilityId
                },
                queryid: query_ids.getReferalFromDoctor
            };
            const data = await fetchData(
            {
                body: JSON.stringify(body),
            },
            urls.readQdmQueries
            );
            // let returnData = [];
            // if (Array.isArray(data)) {
            // data?.map((val, i) => {
            //     returnData.push({
            //     value: val?.textfield,
            //     label: val?.textfield,
            //     _id: val?.valuefield,
            //     });
            // });
            // }
            return {
            ...defaultState.Info,
            data: data,
            };
        } catch (err) {
            return rejectWithValue({
            ...defaultReject,
            message: err.message,
            });
        }
        }
    );

    const Get_Referral_To_Facility = createAsyncThunk(
        `ReferalRegOutApiSlice/get_referaltofacility`,
        async (payload = {}, { rejectWithValue }) => {
        try {
            const { orgId } = payload;
            const body = {
                db_name: dbName,
                filter: {
                    tenantid: ""
                },
                queryid: query_ids.getReferalToFacility
            };
            const data = await fetchData(
            {
                body: JSON.stringify(body),
            },
            urls.readQdmQueries
            );
            // let returnData = [];
            // if (Array.isArray(data)) {
            // data?.map((val, i) => {
            //     returnData.push({
            //     value: val?.textfield,
            //     label: val?.textfield,
            //     _id: val?.valuefield,
            //     });
            // });
            // }
            return {
            ...defaultState.Info,
            data: data,
            };
        } catch (err) {
            return rejectWithValue({
            ...defaultReject,
            message: err.message,
            });
        }
        }
    );

    const Get_ViralStatus = createAsyncThunk(
        `ReferalRegOutApiSlice/get_viralstatus`,
        async (payload = {}, { rejectWithValue }) => {
        try {
            const { orgId, type } = payload;
            const body = {
                db_name: dbName,
                filter: {
                    type:[type],
                    tenantid: "",
                    facilityid: "",
                    lang: "en"
                },
                queryid: query_ids.getViralStatus
            };
            const data = await fetchData(
            {
                body: JSON.stringify(body),
            },
            urls.readQdmQueries
            );
            // let returnData = [];
            // if (Array.isArray(data)) {
            // data?.map((val, i) => {
            //     returnData.push({
            //     value: val?.textfield,
            //     label: val?.textfield,
            //     _id: val?.valuefield,
            //     });
            // });
            // }
            return {
            ...defaultState.Info,
            data: data?.[0]?.[type]?.Value,
            };
        } catch (err) {
            return rejectWithValue({
            ...defaultReject,
            message: err.message,
            });
        }
        }
    );

    const Get_Reason_For_Referal = createAsyncThunk(
        `ReferalRegOutApiSlice/get_reasonforreferal`,
        async (payload = {}, { rejectWithValue }) => {
        try {
            const { orgId , type} = payload;
            const body = {
                db_name: dbName,
                filter: {
                    type:[type],
                    tenantid: "",
                    facilityid: "",
                    lang: "en"
                },
                queryid: query_ids.getReasonForReferal
            };
            const data = await fetchData(
            {
                body: JSON.stringify(body),
            },
            urls.readQdmQueries
            );
            // let returnData = [];
            // if (Array.isArray(data)) {
            // data?.map((val, i) => {
            //     returnData.push({
            //     value: val?.textfield,
            //     label: val?.textfield,
            //     _id: val?.valuefield,
            //     });
            // });
            // }
            return {
            ...defaultState.Info,
            data: data?.[0]?.[type]?.Value,
            };
        } catch (err) {
            return rejectWithValue({
            ...defaultReject,
            message: err.message,
            });
        }
        }
    );

    // const Get_Other_Medical_comorbid = createAsyncThunk(
    //     `ReferalRegOutApiSlice/get_othermedicalcomorbid`,
    //     async (payload = {}, { rejectWithValue }) => {
    //     try {
    //         const { patientId } = payload;
    //         const body = {
    //             db_name: dbName,
    //             filter: {
    //                 patientid: patientId,
    //                 tenantid: "",
    //                 facilityid: ""
    //             },
    //             queryid: query_ids.getOtherMedicalComorbid
    //         };
    //         const data = await fetchData(
    //         {
    //             body: JSON.stringify(body),
    //         },
    //         urls.readQdmQueries
    //         );
    //         let returnData = [];
    //         if (Array.isArray(data)) {
    //         data?.map((val, i) => {
    //             returnData.push({
    //             value: val?.textfield,
    //             label: val?.textfield,
    //             _id: val?.valuefield,
    //             });
    //         });
    //         }
    //         return {
    //         ...defaultState.Info,
    //         data: returnData,
    //         };
    //     } catch (err) {
    //         return rejectWithValue({
    //         ...defaultReject,
    //         message: err.message,
    //         });
    //     }
    //     }
    // );

    // const Get_Medication_History = createAsyncThunk(
    //     `ReferalRegOutApiSlice/get_medicationhistory`,
    //     async (payload = {}, { rejectWithValue }) => {
    //     try {
    //         const { patientId } = payload;
    //         const body = {
    //             db_name: dbName,
    //             filter: {
    //                 patientid: patientId,
    //                 tenantid: "",
    //                 facilityid: ""
    //             },
    //             queryid: query_ids.getMedicationHistory
    //         };
            
    //         const data = await fetchData(
    //         {
    //             body: JSON.stringify(body),
    //         },
    //         urls.readQdmQueries
    //         );
    //         console.log(data,"bodybody")
    //         let returnData = [];
    //         if (Array.isArray(data)) {
    //         data?.map((val, i) => {
    //             returnData.push({
    //             value: val?.textfield,
    //             label: val?.textfield,
    //             _id: val?.valuefield,
    //             });
    //         });
    //         }
    //         return {
    //         ...defaultState.Info,
    //         data: returnData,
    //         };
    //     } catch (err) {
    //         return rejectWithValue({
    //         ...defaultReject,
    //         message: err.message,
    //         });
    //     }
    //     }
    // );

    // const Get_Target_Dry_Hdweight_Kg = createAsyncThunk(
    //     `ReferalRegOutApiSlice/get_targetdryhdweight`,
    //     async (payload = {}, { rejectWithValue }) => {
    //     try {
    //         const { patientId } = payload;
    //         const body = {
    //             db_name: dbName,
    //             filter: {
    //                 patientid: patientId,
    //                 tenantid: "",
    //                 facilityid: ""
    //             },
    //             queryid: query_ids.getTargetDryHdWeightKg
    //         };
    //         const data = await fetchData(
    //         {
    //             body: JSON.stringify(body),
    //         },
    //         urls.readQdmQueries
    //         );
    //         let returnData = [];
    //         if (Array.isArray(data)) {
    //         data?.map((val, i) => {
    //             returnData.push({
    //             value: val?.textfield,
    //             label: val?.textfield,
    //             _id: val?.valuefield,
    //             });
    //         });
    //         }
    //         return {
    //         ...defaultState.Info,
    //         data: returnData,
    //         };
    //     } catch (err) {
    //         return rejectWithValue({
    //         ...defaultReject,
    //         message: err.message,
    //         });
    //     }
    //     }
    // );

    // const Get_Current_Vasscular_Access = createAsyncThunk(
    //     `ReferalRegOutApiSlice/get_currentvasscularaccess`,
    //     async (payload = {}, { rejectWithValue }) => {
    //     try {
    //         const { patientId } = payload;
    //         const tenantId = ""
    //         const facilityId = ""
    //         const body = {
    //             db_name: dbName,
    //             filter: {
    //                 patientid: patientId,
    //                 tenantid: tenantId,
    //                 facilityid: facilityId
    //             },
    //             queryid: query_ids.getCurrentVasscularAccess
    //         };
    //         const data = await fetchData(
    //         {
    //             body: JSON.stringify(body),
    //         },
    //         urls.readQdmQueries
    //         );
    //         let returnData = [];
    //         if (Array.isArray(data)) {
    //         data?.map((val, i) => {
    //             returnData.push({
    //             value: val?.textfield,
    //             label: val?.textfield,
    //             _id: val?.valuefield,
    //             });
    //         });
    //         }
    //         return {
    //         ...defaultState.Info,
    //         data: returnData,
    //         };
    //     } catch (err) {
    //         return rejectWithValue({
    //         ...defaultReject,
    //         message: err.message,
    //         });
    //     }
    //     }
    // );

    // const Get_Refferal_From_Contact_Number = createAsyncThunk(
    //     `ReferalRegOutApiSlice/get_refferalfromcontactnumber`,
    //     async (payload = {}, { rejectWithValue }) => {
    //     try {
    //         const { orgId } = payload;
    //         const body = {
    //             db_name: dbName,
    //             filter: {
    //                 orgid: orgId,
    //                 tenantid: ""
    //             },
    //             queryid: query_ids.getRefferalFromContactNumber
    //         };
    //         const data = await fetchData(
    //         {
    //             body: JSON.stringify(body),
    //         },
    //         urls.readQdmQueries
    //         );
    //         let returnData = [];
    //         if (Array.isArray(data)) {
    //         data?.map((val, i) => {
    //             returnData.push({
    //             value: val?.textfield,
    //             label: val?.textfield,
    //             _id: val?.valuefield,
    //             });
    //         });
    //         }
    //         return {
    //         ...defaultState.Info,
    //         data: returnData,
    //         };
    //     } catch (err) {
    //         return rejectWithValue({
    //         ...defaultReject,
    //         message: err.message,
    //         });
    //     }
    //     }
    // );

    const Get_User = createAsyncThunk(
        `ReferalRegOutApiSlice/get_user`,
        async (payload = {}, { rejectWithValue }) => {
        try {
            const { facilityId } = payload;
            const body = {
                db_name: dbName,
                filter: {
                    practitionerid: "",
                    tenantid: "",
                    facilityid: facilityId
                },
                queryid: query_ids.getUser
            };
            const data = await fetchData(
            {
                body: JSON.stringify(body),
            },
            urls.readQdmQueries
            );
            let returnData = [];
            if (Array.isArray(data)) {
            data?.map((val, i) => {
                returnData.push({
                value: val?.value,
                label: val?.value,
                _id: val?._id,
                });
            });
            }
            return {
            ...defaultState.Info,
            data: returnData,
            };
        } catch (err) {
            return rejectWithValue({
            ...defaultReject,
            message: err.message,
            });
        }
        }
    );

    const Referal_Reg_Out_Save = createAsyncThunk(
        `ReferalRegOutApiSlice/get_referalregoutsave`,
        async (payload = {}, { rejectWithValue }) => {
          try {
            const { doc } = payload;
            const body = doc;
            const data = await fetchData(
              { body: JSON.stringify(body) },
              urls.referalRegistrationOut
            );
      
            return {
              ...defaultState.List,
              data: data,
            };
          } catch (error) {
            return rejectWithValue({
              ...defaultReject,
              message: error.message,
            });
          }
        }
      );
    const Read_Referal_Reg_Out = createAsyncThunk(
    `ReferalRegOutApiSlice/get_readreferalregout`,
    async (payload = {}, { rejectWithValue }) => {
        try {
            const { patientId, encounterId } = payload;
            const body = {
                db_name: dbName,
                filter: {
                    patientid: patientId,
                    encounterid: encounterId,
                    tenantid: "",
                    facilityid:"",
  
                },
                queryid: query_ids.readRefRegOut
            };
            const data = await fetchData(
            {
                body: JSON.stringify(body),
            },
            urls.readQdmQueries
            );
            return {
            ...defaultState.Info,
            data: data,
            };
        } catch (error) {
        return rejectWithValue({
            ...defaultReject,
            message: error.message,
        });
        }
    }
    );

  const ReferalRegOutApiSlice = createSlice({
    name: "ReferalRegOutApiSlice",
    initialState: {
        get_referalfromfacility: {
        ...defaultState.List,
      },
      get_referalfromDoctor: {
        ...defaultState.List,
      },
      get_referaltofacility: {
        ...defaultState.List,
      },
      get_viralstatus: {
        ...defaultState.List,
      },
      get_reasonforreferal: {
        ...defaultState.List,
      },
    //   get_currentvasscularaccess: {
    //     ...defaultState.List,
    //   },
    //   get_refferalfromcontactnumber: {
    //     ...defaultState.List,
    //   },
      get_user: {
        ...defaultState.List,
      },
      get_referalregoutsave: {
        ...defaultState.List,
      },
      get_readreferalregout: {
        ...defaultState.List,
      },
    },
    extraReducers: {
      // Referral_From_Facility
      [Get_Referral_From_Facility.fulfilled]: (state, action) => {
        state.get_referalfromfacility = action?.payload ?? [];
      },
      [Get_Referral_From_Facility.pending]: (state, action) => {
        state.get_referalfromfacility.loading = true;
      },
      [Get_Referral_From_Facility.rejected]: (state, action) => {
        state.get_referalfromfacility = action?.payload ?? [];
      },

      // Referral_From_Doctor
      [Get_Referral_From_Doctor.fulfilled]: (state, action) => {
        state.get_referalfromDoctor = action?.payload ?? [];
      },
      [Get_Referral_From_Doctor.pending]: (state, action) => {
        state.get_referalfromDoctor.loading = true;
      },
      [Get_Referral_From_Doctor.rejected]: (state, action) => {
        state.get_referalfromDoctor = action?.payload ?? [];
      },

      // Referal To Facility
      [Get_Referral_To_Facility.fulfilled]: (state, action) => {
        state.get_referaltofacility = action?.payload ?? [];
      },
      [Get_Referral_To_Facility.pending]: (state, action) => {
        state.get_referaltofacility.loading = true;
      },
      [Get_Referral_To_Facility.rejected]: (state, action) => {
        state.get_referaltofacility = action?.payload ?? [];
      },

       // Viral Status
       [Get_ViralStatus.fulfilled]: (state, action) => {
        state.get_viralstatus = action?.payload ?? [];
      },
      [Get_ViralStatus.pending]: (state, action) => {
        state.get_viralstatus.loading = true;
      },
      [Get_ViralStatus.rejected]: (state, action) => {
        state.get_viralstatus = action?.payload ?? [];
      },

      // Reason for Referal
      [Get_Reason_For_Referal.fulfilled]: (state, action) => {
        state.get_reasonforreferal = action?.payload ?? [];
      },
      [Get_Reason_For_Referal.pending]: (state, action) => {
        state.get_reasonforreferal.loading = true;
      },
      [Get_Reason_For_Referal.rejected]: (state, action) => {
        state.get_reasonforreferal = action?.payload ?? [];
      },

       // Referal Reg Out Save
       [Referal_Reg_Out_Save.fulfilled]: (state, action) => {
        state.get_referalregoutsave = action?.payload ?? [];
      },
      [Referal_Reg_Out_Save.pending]: (state, action) => {
        state.get_referalregoutsave.loading = true;
      },
      [Referal_Reg_Out_Save.rejected]: (state, action) => {
        state.get_referalregoutsave = action?.payload ?? [];
      },

      // Read Referal Reg Out
      [Read_Referal_Reg_Out.fulfilled]: (state, action) => {
        state.get_readreferalregout = action?.payload ?? [];
      },
      [Read_Referal_Reg_Out.pending]: (state, action) => {
        state.get_readreferalregout.loading = true;
      },
      [Read_Referal_Reg_Out.rejected]: (state, action) => {
        state.get_readreferalregout = action?.payload ?? [];
      },

    //   // Current Vascullar Access
    //   [Get_Current_Vasscular_Access.fulfilled]: (state, action) => {
    //     state.get_currentvasscularaccess = action?.payload ?? [];
    //   },
    //   [Get_Current_Vasscular_Access.pending]: (state, action) => {
    //     state.get_currentvasscularaccess.loading = true;
    //   },
    //   [Get_Current_Vasscular_Access.rejected]: (state, action) => {
    //     state.get_currentvasscularaccess = action?.payload ?? [];
    //   },

    //   // Refferal From Contact Number
    //   [Get_Refferal_From_Contact_Number.fulfilled]: (state, action) => {
    //     state.get_refferalfromcontactnumber = action?.payload ?? [];
    //   },
    //   [Get_Refferal_From_Contact_Number.pending]: (state, action) => {
    //     state.get_refferalfromcontactnumber.loading = true;
    //   },
    //   [Get_Refferal_From_Contact_Number.rejected]: (state, action) => {
    //     state.get_refferalfromcontactnumber = action?.payload ?? [];
    //   },

      // User
      [Get_User.fulfilled]: (state, action) => {
        state.get_user = action?.payload ?? [];
      },
      [Get_User.pending]: (state, action) => {
        state.get_user.loading = true;
      },
      [Get_User.rejected]: (state, action) => {
        state.get_user = action?.payload ?? [];
      },
    },
  });

  export const ReferalRegOutAction = {
   
    Get_Referral_From_Facility,
    Get_Referral_From_Doctor,
    Get_Referral_To_Facility,
    Get_ViralStatus,
    Get_Reason_For_Referal,
    Referal_Reg_Out_Save,
    Read_Referal_Reg_Out,
    // Get_Other_Medical_comorbid,
    // Get_Target_Dry_Hdweight_Kg,
    // Get_Medication_History,
    // Get_Current_Vasscular_Access,
    // Get_Refferal_From_Contact_Number,
    Get_User,
   
  };
  export default ReferalRegOutApiSlice;