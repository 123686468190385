import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

function FieldLabel({ required, ...props }) {
  return (
    <Typography {...props}>
      {props.children}&nbsp;
      {required && <span style={{ color: "red" }}>*</span>}
    </Typography>
  );
}

export default FieldLabel;

FieldLabel.propTypes = {
  required: PropTypes.bool,
};

FieldLabel.defaultProps = {
  required: false,
};
