import React from "react";
import { Div, H6, Col, Row, Text, Avatar, Image } from "qdm-component-library";
// import "./style.css"
import { UIColor } from "../../../themes/theme";
import editIcon from "../../../assets/img/svg/icons8-edit1.svg";
import trashIcon from "../../../assets/img/svg/icons8-trash1.svg";
import { Typography } from "@material-ui/core";

const ShowList = (props) => {
  console.log("showloist", props);
  let classfortypo = {
    color: UIColor.tertiaryText.color,
    fontSize: 12,
    display: "inline-block",
  };
  const {
    imgUrl,
    letter,
    title,
    description,
    ondelete,
    onedit,
    parent_ids,
    idtype,
    idnumber,
    relationship,
    occupation,
    contactmode,
    countrycode,
    phone,
  } = props;

  if (title?.length > 0) {
    return (
      <Row
        id={`${parent_ids}_parent_row`}
        // className="sl-root"
        style={{
          border: `1px solid ${UIColor.lineBorderFill.color}`,
          borderRadius: "8px",
          background: UIColor.greyBackground.color,
        }}
      >
        <Col
          id={`${parent_ids}_parent_col`}
          md={11}
          lg={11}
          sm={6}
          xs={12}
          className="sl-detail-root"
          inLineStyles={{ paddingLeft: "0px" }}
        >
          <Div id={`${parent_ids}_sub_div`} className="sl-count">
            <Text
              id={`${parent_ids}_text`}
              className="pc_medium"
              style={{
                color: UIColor.secondaryText.color,
                margin: "auto",
                marginLeft: 0,
                padding: "6px 10px 6px 7px",
                background: UIColor.lineBorderFill.color,
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              {props?.index ? props?.index : 1}
            </Text>
          </Div>
          <Div
            id={`${parent_ids}_image_div`}
            className={imgUrl || letter ? "sl-details" : "sl-detail"}
          >
            {(imgUrl || letter) && (
              <Avatar
                id={`${parent_ids}_image_avatar`}
                className="sl-details-avatar"
                src={imgUrl}
                letter={letter}
              />
            )}
            <Div
              id={`${parent_ids}_description_div`}
              className="sl-details-names"
              style={{ display: "inline-block" }}
            >
              <H6
                id={`${parent_ids}_description_h6`}
                style={{
                  color: UIColor.secondaryText.color,
                  fontSize: "14px",
                  margin: 0,
                  marginBottom: "3px",
                  lineHeight: 1,
                  fontFamily: "pc_medium !important",
                }}
              >
                {title ? title : "-------"}
              </H6>
              <Text
                id={`${parent_ids}_description_text`}
                style={{
                  color: UIColor.tertiaryText.color,
                  fontSize: 12,
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp; {description ? description : "--------"}
                &nbsp;&nbsp;
              </Text>
              {idtype && (
                <>
                  <div
                    style={{
                      width: "1.5px",
                      height: "12px",
                      backgroundColor: UIColor.tertiaryText.color,
                      display: "inline-block",
                      marginBottom: "-2px",
                    }}
                  />

                  <Typography style={classfortypo}>
                    &nbsp;&nbsp;{idtype}&nbsp;&nbsp;
                  </Typography>
                </>
              )}
              {idnumber && (
                <>
                  <div
                    style={{
                      width: "1.5px",
                      height: "12px",
                      backgroundColor: UIColor.tertiaryText.color,
                      display: "inline-block",
                      marginBottom: "-2px",
                    }}
                  />

                  <Typography style={classfortypo}>
                    &nbsp;&nbsp;{idnumber}&nbsp;&nbsp;
                  </Typography>
                </>
              )}
              {relationship && (
                <>
                  <div
                    style={{
                      width: "1.5px",
                      height: "12px",
                      backgroundColor: UIColor.tertiaryText.color,
                      display: "inline-block",
                      marginBottom: "-2px",
                    }}
                  />

                  <Typography style={classfortypo}>
                    &nbsp;&nbsp;{relationship}&nbsp;&nbsp;
                  </Typography>
                </>
              )}
              {occupation && (
                <>
                  <div
                    style={{
                      width: "1.5px",
                      height: "12px",
                      backgroundColor: UIColor.tertiaryText.color,
                      display: "inline-block",
                      marginBottom: "-2px",
                    }}
                  />

                  <Typography style={classfortypo}>
                    &nbsp;&nbsp;{occupation}&nbsp;&nbsp;
                  </Typography>
                </>
              )}
              {contactmode && (
                <>
                  <div
                    style={{
                      width: "1.5px",
                      height: "12px",
                      backgroundColor: UIColor.tertiaryText.color,
                      display: "inline-block",
                      marginBottom: "-2px",
                    }}
                  />

                  <Typography style={classfortypo}>
                    &nbsp;&nbsp;{contactmode}&nbsp;&nbsp;
                  </Typography>
                </>
              )}{" "}
              {countrycode && (
                <>
                  <div
                    style={{
                      width: "1.5px",
                      height: "12px",
                      backgroundColor: UIColor.tertiaryText.color,
                      display: "inline-block",
                      marginBottom: "-2px",
                    }}
                  />

                  <Typography style={classfortypo}>
                    &nbsp;&nbsp;{countrycode}&nbsp;&nbsp;
                  </Typography>
                </>
              )}
              {phone && (
                <>
                  <div
                    style={{
                      width: "1.5px",
                      height: "12px",
                      backgroundColor: UIColor.tertiaryText.color,
                      display: "inline-block",
                      marginBottom: "-2px",
                    }}
                  />

                  <Typography style={classfortypo}>
                    &nbsp;&nbsp;{phone}&nbsp;&nbsp;
                  </Typography>
                </>
              )}
            </Div>
          </Div>
        </Col>
        <Col
          id={`${parent_ids}_image_col`}
          md={1}
          lg={1}
          sm={6}
          xs={12}
          className="sl-action-root"
        >
          <Div
            id={`${parent_ids}_image_div`}
            style={{ display: "flex", alignItems: "center" }}
          >
            {!props.isViewMode && onedit && (
              <Image
                id={`${parent_ids}_image_edit_image`}
                onClick={() => onedit()}
                style={{ cursor: "pointer" }}
                src={editIcon}
                alt="edit"
              />
            )}
            {!props.isViewMode && ondelete && (
              <Image
                id={`${parent_ids}_image_trash_image`}
                onClick={() => ondelete()}
                style={{ cursor: "pointer", marginLeft: 15 }}
                src={trashIcon}
                alt="delete"
              />
            )}
          </Div>
        </Col>
      </Row>
    );
  }
};

export default ShowList;
