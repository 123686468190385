import React, { useState, useEffect } from "react";
import { Div, Select, Col, Row, TextInput } from "qdm-component-library";
import { Button } from "@material-ui/core";
import "./style.css";
import withAllContexts from "../../../hoc/withAllContexts";
import { UIColor } from "../../../themes/theme";
import { connect } from "react-redux";
import { actions } from "primarycare-binder";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { FlterOptions2, FlterOptions3, FlterOptionsAddress } from "./handlers";
import { AxiosCall } from "../../../utils";

const AddAddress = (props) => {
  let styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorBorderStyle: {
      borderRadius: 8,
    },
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
      marginRight: 10,
    },
    containedButton: {
      backgroundColor: UIColor.secondaryButtonColor.color,
      color: "#fff",
      borderColor: UIColor.secondaryButtonColor.color,
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
    },
    select: {
      width: "200px", // Set the desired width
      maxHeight: "50px", // Set the desired max height
      overflowY: "auto", // Enable vertical scrollbar if needed
    },
  };

  const [state, setState] = useState({
    use: null,
    type: null,
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: null,
    district: null,
    state: null,
    country: null,
    pincode: null,
    errorList: {},
  });

  const { onclose, parent_id } = props;
  const { errorList } = state;
  const {
    labelStyle,
    borderStyle,
    errorBorderStyle,
    outlinedButton,
    containedButton,
    select,
  } = styles;

  const checkError = () => {};

  const FlterOptions = (type) => {};
  const [patientData, setPatientData] = useState({ ...props?.patientData });

  useEffect(() => {
    if (props?.editClick) {
      const { editData } = props;

      if (editData) {
        setState({ ...editData });
      } else {
        Clear();
      }
      setPatientData({ ...patientData });
    }
  }, [props?.editClick]);

  useEffect(() => {
    if(props?.loadDefaultAddressdata && !props?.editClick){
      setState({
        type: props?.loadDefaultAddressdata?.type,
        use: props?.loadDefaultAddressdata?.use,
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: null,
        district: null,
        state: null,
        country: null,
        pincode: null,
        errorList: {},
      });
    }
  }, [props?.loadDefaultAddressdata]);
  useEffect(() => {
    if (props?.loadDefaultAddressNok && !props?.editClick) {
      setState({
        type: props?.loadDefaultAddressNok?.type,
        use: props?.loadDefaultAddressNok?.use,
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: null,
        district: null,
        state: null,
        country: null,
        pincode: null,
        errorList: {},
      });
    }
  }, [props?.loadDefaultAddressNok]);

  const getAdressData = async (id, queryId) => {
    let params = {
      db_name: process.env.REACT_APP_DB,
      filter: {
        _id: `${id}`,
        tenantid: "",
        facilityid: "",
        lang: "en",
      },
      queryid: `${queryId}`,
    };
    let res = await AxiosCall(
      "post",
      `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
      params
    );

    return res;
  };

  const getState = async (id) => {
    let params = {
      db_name: process.env.REACT_APP_DB,
      filter: {
        _id: "SMGeographicMaster/10081",
        tenantid: "",
        facilityid: "",
        lang: "en",
      },
      queryid: "1242cdfa-cb20-47ec-83a8-54d49900cecb",
    };
    let res = await AxiosCall(
      "post",
      `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
      params
    );

    console.log("district", res);
  };

  const autoFillLocations = async (val) => {
    let stateVal = { ...state };
    console.log("ver", val);
    let district;
    let stateAdd;
    let country;
    let pincode;

    if (val?.parentGeogLevelCode) {
      district = await getAdressData(
        val?.parentGeogLevelCode,
        "7cb0f69c-ddd1-482f-8b60-6cf05d7a60c6"
      );
    }
    if (district?.[0]?.geogLevelName) {
      stateAdd = await getAdressData(
        district?.[0]?.parentGeogLevelCode,
        "1242cdfa-cb20-47ec-83a8-54d49900cecb"
      );
    }
    if (stateAdd?.[0]?.geogLevelName) {
      country = await getAdressData(
        stateAdd?.[0]?.parentGeogLevelCode,
        "56b0cfa3-c2c0-4267-88fe-2795f72f1259"
      );
    }
    if (val?._id) {
      pincode = await getAdressData(
        val?._id,
        "5afb6789-1bce-4302-9387-91dcba3a8d1b"
      );
    }

    console.log("district", district, stateAdd, country, pincode);

    setState({
      ...stateVal,
      city: val,
      district: district,
      pincode: pincode,
      state: stateAdd,
      country: country,
    });

    console.log("setState", state);
  };

  const onChangeState = (key, val, limit) => {
    console.log("val", val);
    const stateValue = { ...state };

    if (key === "state" && stateValue.state?.label !== val?.value) {
      // stateValue["country"] = null;
      if (val?._id) {
        // this.props.GET_____COUNTRY(value?.parentId)
      }
    }
    if (key === "district" && stateValue.district?.label !== val?.value) {
      // stateValue["state"] = null;
      // stateValue["country"] = null;
      if (val?._id) {
        // this.props.GET_____STATE(value?.parentId)
      }
    }
    if (key === "City" && stateValue.city?.label !== val?.value) {
      // stateValue["district"] = null;
      // stateValue["state"] = null;
      // stateValue["country"] = null;
      // stateValue["pincode"] = null;

      autoFillLocations(val);
    }
    if (limit) {
      if (limit < val.length) {
        return false;
      }
    }
    stateValue.errorList[key] = false;
    setState({
      ...stateValue,
      [key]: val,
    });
    // console.log(stateValue, "stateValue stateValue stateValue")
  };

  const Clear = () => {
    setState({
      use: null,
      type: null,
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: null,
      district: null,
      state: null,
      country: null,
      pincode: null,
    });
  };

  const saveAddress = () => {
    const { onclose, index, handleClear } = props;
    let patientData = props?.patientData;
    // let iserror = this.checkError()
    // if (iserror) {

    //     this.props.alert.setSnack({
    //         open: true,
    //         severity: AlertProps.severity.error,
    //         msg: "Please fill all the mandatory fields",
    //         vertical: AlertProps.vertical.top,
    //         horizontal: AlertProps.horizontal.center,
    //         tone: true,
    //     });
    //     return false;
    // }
    if (index !== null) {
      patientData.address[index] = state;
    } else {
      patientData.address.push(state);
    }
    // patientData[this.props.setTo]({
    //   ...patientData,
    // });
    setPatientData({ ...patientData });
    props?.setPatientData({ ...patientData });
    handleClear();
    onclose();
    Clear();
  };

  console.log("address237", state);
  console.log("propsCity", props, props?.City);

  return (
    <Div
      id={`${parent_id}_addaddress_parent_div`}
      style={{
        padding: 14,
      }}
    >
      {/* <Paper className="pi-paper"> */}
      <div className="titleDiv">
        <span className="titleAddress">ADD NEW ADDRESS</span>
        <IconButton
          aria-label="delete"
          onClick={(e) => {
            onclose(e);
            Clear();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Row
        id={`${parent_id}_addaddress_parent_row`}
        className="pi-actions-root"
        style={{ padding: 0 }}
      >
        <Col
          id={`${parent_id}_addaddress_use_label_col`}
          md={2}
          lg={2}
          sm={6}
          xs={12}
          inLineStyles={{ padding: 5 }}
        >
          <Select
            id={`${parent_id}_addaddress_use_label_select`}
            showArrow
            label="Use"
            placeholder="use"
            labelStyle={labelStyle}
            inLineStyles={errorList?.use ? errorBorderStyle : borderStyle}
            // isRequired={true}
            hoverColor={UIColor.primaryColor.color}
            options={FlterOptions3(props?.allMasters, "ADDRESSUSE")}
            onChangeValue={(value) => onChangeState("use", value)}
            value={state?.use?.value}
            error={errorList?.use ? true : false}
            // helperText={(errorList.use && errorList.use) ? 'Is required' : ''}
          />
          {/* <Typography
                          style={{
                          fontSize: "12px",
                          paddingBottom: 4,
                          Fontfamily: "pc_regular",
                          color: "#6F6F6F",
                          }}
                          variant="body1"
                      >
                          Use <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <SelectBox
                          id={`${parent_id}_addaddress_use_label_select`}
                          list={this.FlterOptions('ADDRESSUSE') ?? []}
                          placeholder={"select..."}
                          value={this.state.use ?? ""}
                          onchange={(e,value) => this.onChangeState('use', value)}
                      /> */}
        </Col>
        <Col
          id={`${parent_id}_addaddress_type_label_col`}
          md={2}
          lg={2}
          sm={6}
          xs={12}
          inLineStyles={{ padding: 5 }}
        >
          <Select
            id={`${parent_id}_addaddress_type_label_select`}
            showArrow
            label="Type"
            placeholder="type"
            labelStyle={labelStyle}
            inLineStyles={errorList?.type ? errorBorderStyle : borderStyle}
            error={errorList?.type ? true : false}
            // helperText={(errorList.type && errorList.type) ? 'Is required' : ''}
            hoverColor={UIColor.primaryColor.color}
            isRequired={false}
            options={FlterOptions3(props?.allMasters, "ADDRESSTYPE")}
            onChangeValue={(value) => onChangeState("type", value)}
            value={state?.type?.value}
          />
          {/* <Typography
                          style={{
                          fontSize: "12px",
                          paddingBottom: 4,
                          Fontfamily: "pc_regular",
                          color: "#6F6F6F",
                          }}
                          variant="body1"
                      >
                          Type <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <SelectBox
                          id={`${parent_id}_addaddress_type_label_select`}
                          list={this.FlterOptions('ADDRESSTYPE') ?? []}
                          placeholder={"select..."}
                          value={this.state.type ?? ""}
                          onchange={(e,value) => this.onChangeState('type', value)}
                      /> */}
        </Col>
        <Col
          id={`${parent_id}_addaddress_addressline1_label_col`}
          md={4}
          lg={4}
          sm={6}
          xs={12}
          inLineStyles={{ padding: 5 }}
        >
          {/* <TextInput
            isRequired={false}
            id={`${parent_id}_addaddress_addressline1_label_textinput`}
            placeholder="addressLine1"
            onChange={(e) => onChangeState("addressLine1", e.target.value, 30)}
            value={state?.addressLine1 ? state?.addressLine1 : ""}
            label="Address Line 1"
            error={errorList?.addressLine1 ? true : false}
            // helperText={(errorList.addressLine1 && errorList.addressLine1) ? 'Is required' : ''}
            style={errorList?.addressLine1 ? errorBorderStyle : borderStyle}
            labelStyle={labelStyle}
            // style={borderStyle}
            hoverColor={UIColor.primaryColor.color}
          /> */}
          <TextInput
            isRequired={false}
            id={`${parent_id}_addaddress_addressline1_label_textinput`}
            placeholder="Address Line 1"
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length <= 60) {
                onChangeState("addressLine1", inputValue);
              } else {
                onChangeState("addressLine1", inputValue.slice(0, 60));
              }
            }}
            value={state?.addressLine1 ? state?.addressLine1 : ""}
            label="Address Line 1"
            error={errorList?.addressLine1 ? true : false}
            style={errorList?.addressLine1 ? errorBorderStyle : borderStyle}
            labelStyle={labelStyle}
            hoverColor={UIColor.primaryColor.color}
          />
        </Col>
        <Col
          id={`${parent_id}_addaddress_addressline2_label_col`}
          md={4}
          lg={4}
          sm={6}
          xs={12}
          inLineStyles={{ padding: 5 }}
        >
          <TextInput
            id={`${parent_id}_addaddress_addressline2_label_textinput`}
            placeholder="addressLine2"
            // onChange={(e) => onChangeState("addressLine2", e.target.value, 30)}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length <= 60) {
                onChangeState("addressLine2", inputValue);
              } else {
                onChangeState("addressLine2", inputValue.slice(0, 60));
              }
            }}
            value={state?.addressLine2}
            label="Address Line 2"
            labelStyle={labelStyle}
            error={errorList?.addressLine2 ? true : false}
            // helperText={(errorList.addressLine2 && errorList.addressLine2) ? 'Is required' : ''}
            style={errorList?.addressLine2 ? errorBorderStyle : borderStyle}
            hoverColor={UIColor.primaryColor.color}
          />
        </Col>
        {/* </Row> */}
        {/* <Row className="pi-actions-root"> */}
        <Col
          id={`${parent_id}_addaddress_addressline3_label_col`}
          md={4}
          lg={4}
          sm={6}
          xs={12}
          inLineStyles={{ padding: 5 }}
        >
          <TextInput
            id={`${parent_id}_addaddress_addressline3_label_textinput`}
            placeholder="addressLine3"
            //onChange={(e) => onChangeState("addressLine3", e.target.value, 30)}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length <= 60) {
                onChangeState("addressLine3", inputValue);
              } else {
                onChangeState("addressLine3", inputValue.slice(0, 60));
              }
            }}
            value={state?.addressLine3}
            label="Address Line 3"
            labelStyle={labelStyle}
            error={errorList?.addressLine3 ? true : false}
            // helperText={(errorList.addressLine3 && errorList.addressLine3) ? 'Is required' : ''}
            style={errorList?.addressLine3 ? errorBorderStyle : borderStyle}
            hoverColor={UIColor.primaryColor.color}
          />
        </Col>
        <Col
          id={`${parent_id}_addaddress_city_label_col`}
          md={4}
          lg={4}
          sm={6}
          xs={12}
          inLineStyles={{ padding: 5 }}
        >
          <Select
            isRequired={false}
            id={`${parent_id}_addaddress_city_label_select`}
            //options={props?.city?.data || []}
            options={FlterOptionsAddress(props?.City, "CITY")}
            onChangeValue={(value) => onChangeState("City", value)}
            value={state?.city?.value}
            showArrow
            label="City"
            placeholder="select..."
            // labelStyle={labelStyle}
            labelStyle={select}
            inLineStyles={errorList?.city ? errorBorderStyle : borderStyle}
            error={errorList?.city ? true : false}
            // helperText={(errorList.city && errorList.city) ? 'Is required' : ''}
            hoverColor={UIColor.primaryColor.color}
          />
          {/* <Typography
                          style={{
                          fontSize: "12px",
                          paddingBottom: 4,
                          Fontfamily: "pc_regular",
                          color: "#6F6F6F",
                          }}
                          variant="body1"
                      >
                          City <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <SelectBox
                          id={`${parent_id}_addaddress_city_label_select`}
                          list={this.props.City?.city ?? []}
                          placeholder={"select..."}
                          value={this.state.city ?? ""}
                          onchange={(e,value) => this.onChangeState('city', value)}
                      /> */}
        </Col>
        <Col
          id={`${parent_id}_addaddress_district_label_col`}
          md={6}
          lg={2}
          sm={2}
          xs={12}
          inLineStyles={{ padding: 5 }}
        >
          <Select
            // isRequired={true}
            id={`${parent_id}_addaddress_district_label_select`}
            options={state?.city ? props?.district || [] : []}
            // options={this.FlterOptions('DISTRICT')}
            onChangeValue={(value) => onChangeState("district", value)}
            value={state?.district?.[0]?.geogLevelName}
            showArrow
            label="District"
            placeholder="select..."
            labelStyle={labelStyle}
            inLineStyles={errorList?.district ? errorBorderStyle : borderStyle}
            error={errorList?.district ? true : false}
            // helperText={(errorList.district && errorList.district) ? 'Is required' : ''}
            hoverColor={UIColor.primaryColor.color}
          />
          {/* <Typography
                          style={{
                          fontSize: "12px",
                          paddingBottom: 4,
                          Fontfamily: "pc_regular",
                          color: "#6F6F6F",
                          }}
                          variant="body1"
                      >
                          District <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <SelectBox
                          id={`${parent_id}_addaddress_district_label_select`}
                          list={this.props.City?.disVal ?? []}
                          placeholder={"select..."}
                          value={this.state.district ?? ""}
                          onchange={(e,value) => this.onChangeState('district', value)}
                      /> */}
        </Col>
        {/* {JSON.stringify(this.props?.City?.disVal)} */}
        <Col
          id={`${parent_id}_addaddress_state_label_col`}
          md={6}
          lg={2}
          sm={2}
          xs={12}
          inLineStyles={{ padding: 5 }}
        >
          <Select
            isRequired={false}
            id={`${parent_id}_addaddress_state_label_select`}
            // options={this.FlterOptions('State')}
            options={state?.district ? props?.state?.data || [] : []}
            onChangeValue={(value) => onChangeState("state", value)}
            value={state?.state?.[0]?.geogLevelName}
            showArrow
            label="State"
            placeholder="select..."
            labelStyle={labelStyle}
            inLineStyles={errorList?.state ? errorBorderStyle : borderStyle}
            error={errorList?.state ? true : false}
            // helperText={(errorList.state && errorList.state) ? 'Is required' : ''}
            hoverColor={UIColor.primaryColor.color}
          />
          {/* <Typography
                          style={{
                          fontSize: "12px",
                          paddingBottom: 4,
                          Fontfamily: "pc_regular",
                          color: "#6F6F6F",
                          }}
                          variant="body1"
                      >
                          State <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <SelectBox
                          id={`${parent_id}_addaddress_state_label_select`}
                          list={this.props.City?.state ?? []}
                          placeholder={"select..."}
                          value={this.state.state ?? ""}
                          onchange={(e,value) => this.onChangeState('state', value)}
                      /> */}
        </Col>
        {/* </Row>
          <Row className="pi-actions-root"> */}
        <Col
          id={`${parent_id}_addaddress_country_label_col`}
          md={4}
          lg={2}
          sm={6}
          xs={12}
          inLineStyles={{ padding: 5 }}
        >
          <Select
            isRequired={false}
            id={`${parent_id}_addaddress_country_label_select`}
            options={state?.state ? props?.country?.data || [] : []}
            // options={this.FlterOptions('Country')}
            onChangeValue={(value) => onChangeState("country", value)}
            value={state?.country?.[0]?.geogLevelName}
            showArrow
            label="Country"
            placeholder="select..."
            labelStyle={labelStyle}
            inLineStyles={errorList?.country ? errorBorderStyle : borderStyle}
            error={errorList?.country ? true : false}
            // helperText={(errorList.country && errorList.country) ? 'Is required' : ''}
            hoverColor={UIColor?.primaryColor.color}
          />
          {/* <Typography
                          style={{
                          fontSize: "12px",
                          paddingBottom: 4,
                          Fontfamily: "pc_regular",
                          color: "#6F6F6F",
                          }}
                          variant="body1"
                      >
                          Country <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <SelectBox
                          id={`${parent_id}_addaddress_country_label_select`}
                          list={this.props.City?.country ?? []}
                          placeholder={"select..."}
                          value={this.state.country ?? ""}
                          onchange={(e,value) => this.onChangeState('country', value)}
                      /> */}
        </Col>
        {/* {JSON.stringify(this.props.City)} */}
        <Col
          id={`${parent_id}_addaddress_pincode_label_col`}
          md={4}
          lg={2}
          sm={6}
          xs={12}
          inLineStyles={{ padding: 5 }}
        >
          {/* <Select showArrow label="pincode" placeholder="select..." labelStyle={labelStyle} inLineStyles={borderStyle} hoverColor={UIColor.primaryColor.color} /> */}
          {/* <TextInput
                      id={`${parent_id}_addaddress_pincode_label_textinput`}
                      placeholder="pincode"
                      maxLength={6}
                      type="number" onChange={(e) => this.onChangeState('pincode', e.target.value)}
                      value={this.state.pincode} label="pincode" labelStyle={labelStyle} style={borderStyle} hoverColor={UIColor.primaryColor.color} /> */}
          <Select
            id={`${parent_id}_addaddress_pincode_label_select`}
            isRequired={false}
            options={state?.city ? props?.pincode?.data || [] : []}
            onChangeValue={(value) => onChangeState("pincode", value)}
            value={state?.pincode?.[0]?.geogLevelName}
            showArrow
            label="Post Code"
            placeholder="select..."
            labelStyle={labelStyle}
            inLineStyles={errorList?.pincode ? errorBorderStyle : borderStyle}
            error={errorList?.pincode ? true : false}
            // helperText={(errorList.pincode && errorList.pincode) ? 'Is required' : ''}
            hoverColor={UIColor?.primaryColor.color}
          />
          {/* <Typography
                          style={{
                          fontSize: "12px",
                          paddingBottom: 4,
                          Fontfamily: "pc_regular",
                          color: "#6F6F6F",
                          }}
                          variant="body1"
                      >
                          Pincode <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <SelectBox
                          id={`${parent_id}_addaddress_pincode_label_select`}
                          list={this.props.City?.pin ?? []}
                          placeholder={"select..."}
                          value={this.state.pincode ?? ""}
                          onchange={(e,value) => this.onChangeState('pincode', value)}
                      /> */}
        </Col>
      </Row>
      <Row id={`${parent_id}_addaddress_button_row`}>
        <Col
          id={`${parent_id}_addaddress_button_col`}
          className="actionDiv"
          md={12}
          lg={12}
          sm={12}
          xs={12}
        >
          {/* <Button
                      id={`${parent_id}_addaddress_cancel_button`}
                      className="aa-actions-btn" variant="outlined" style={outlinedButton} onClick={() => {
                          onclose()
                          this.Clear()
                      }}>Cancel</Button> */}
          <Button
            id={`${parent_id}_addaddress_save_button`}
            className="aa-actions-btn"
            variant="outlined"
            backgroundColor={UIColor.secondaryButtonColor.color}
            style={containedButton}
            onClick={() => saveAddress()}
          >
            Save
          </Button>
        </Col>
      </Row>
      {/* </Paper> */}
    </Div>
  );
};

const mapStateToProps = (state) => ({
  city: state?.geoLocationSlice?.getCity,
  district: state?.geoLocationSlice?.getDistrict,
  pincode: state?.geoLocationSlice?.getPincode,
  state: state?.geoLocationSlice?.getState,
  country: state?.geoLocationSlice?.getCountry,
});

export default connect(mapStateToProps, actions)(withAllContexts(AddAddress));
