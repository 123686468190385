import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  checkBoxStyle: {
    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
      "& .MuiCheckbox-root": {
        padding: 0,
      },
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#0071F2",
      },
      "& svg": {
        width: 18,
        height: 18,
      },
    },
  },
}));
