import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  urls,
  defaultState,
  defaultReject,
  fetchData,
  query_ids,
  dbName,
  personRequests,
  registerQuery,
} from "../../utils";
import moment from "moment";

const GET_ENCOUNTER_MASTER_TYPE = createAsyncThunk(
  "searchApiSlice/masterEncounterType",
  async (payload = {}, { rejectWithValue }) => {
    try {
      let returnData = [];
      // const encounterTypeBody = {
      //         db_name: dbName,
      //         entity: "CodingMaster",
      //         filter: "CodingMaster.Type == 'ENCOUNTERTYPE' && CodingMaster.status == true && CodingMaster.activestatus==true ",
      //         return_fields: "KEEP(CodingMaster,'_id','id','_key','Type','status','activestatus','shortdesc','display','code')",
      //         sort: "document(AMPatientClass.encTypeDesc).display"
      //     }
      const encounterTypeBody = {
        db_name: dbName,
        queryid: "decbe72f-920c-48e8-8641-425f49f7f9c9",
      };

      const encounterType = await fetchData(
        {
          body: JSON.stringify(encounterTypeBody),
        },
        urls.readQdmQueries
      );

      returnData = encounterType?.map((v) => {
        return {
          _id: v?._id,
          value: v?.display,
        };
      });
      return {
        ...defaultState.List,
        data: returnData,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_ENCOUNTER_MASTER_CLASS = createAsyncThunk(
  "searhcApiSlice/masterEncounterClass",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { encType = "" } = payload;
      let returnData = [];

      const encounterClassBody = {
        db_name: dbName,
        entity: "AMPatientClass",
        filter: `AMPatientClass.encType=='${encType}' && AMPatientClass.status==true && AMPatientClass.activestatus==true`,
        return_fields: "{encClsByTypes:AMPatientClass.encClsByTypes}",
      };
      const encounterClass = await fetchData(
        {
          body: JSON.stringify(encounterClassBody),
        },
        urls.readDocuments
      );
      returnData = encounterClass?.result?.[0]?.encClsByTypes;
      return {
        ...defaultState.List,
        data: returnData,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const ORGANIZATION_VISIT_REGISTRATION = createAsyncThunk(
  `visitRegisterPatientApiSlice/Encounter`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { payloaddata, enId, encounterId, jwtToken } = payload;
      var body;
      if (enId) {
        body = [
          {
            db_name: dbName,
            entity: "Encounter",
            is_metadata: true,
            filter: {
              _id: `${enId}`,
            },
            metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
            metadataId: process.env.REACT_APP_METADATAID,
            ...payloaddata,
          },
        ];
      } else if (encounterId) {
        body = [
          {
            db_name: dbName,
            entity: "Encounter",
            is_metadata: true,
            filter: {
              _key: `${encounterId}`,
            },
            metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
            metadataId: process.env.REACT_APP_METADATAID,
            ...payloaddata,
          },
        ];
      } else {
        body = {
          db_name: dbName,
          entity: "Encounter",
          is_metadata: true,
          metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
          metadataId: process.env.REACT_APP_METADATAID,
          ...payloaddata,
        };
      }

      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.saveVisit,
        {
          jwtToken: jwtToken,
        }
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_ENCOUNTER_STATUS = createAsyncThunk(
  `visitRegisterPatientApiSlice/getEncounterStatus`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(registerQuery.statusRead()),
        },
        urls.readDocuments
      );
      return {
        ...defaultState.Info,
        data: data.result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const APPOINTMENT_READ_VISIT = createAsyncThunk(
  `visitRegisterPatientApiSlice/readAppointment`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { appointmentId = "" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          appointmentid: appointmentId,
          tenantid: "",
          facilityid: "",
        },
        queryid: query_ids.readAppointment,
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data || {},
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const ORGANIZATION_FILTER_BY_PATIENT = createAsyncThunk(
  `visitRegisterPatientApiSlice/getpatient`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { patientid } = payload;
      const body = {
        db_name: dbName,
        filter: {
          patientid: [patientid],
        },
        queryid: "2f9f73a3-1ef4-4065-8981-fc187d2ff694",
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_PRACTITIONERS = createAsyncThunk(
  `visitRegisterPatientApiSlice/getpractitioners`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityid = "" } = payload;
      const body = {
        db_name: dbName,

        filter: {
          type: ["SPECIALTY", "RESOURCETYPE", "PRACTROLE"],
          tenantid: process.env.REACT_APP_TENANTID,
          facilityid: facilityid,
          lang: "en",
        },
        queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      // let returnData = [];
      // if (Array.isArray(data.result)) {
      //   returnData = data.result;
      // }
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_PRACTITIONERS_BYSPECIALITY = createAsyncThunk(
  `visitRegisterPatientApiSlice/getpractitionersspeciality`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityid = "", specialityid = "", practtype = "" } = payload;
      const body = {
        db_name: dbName,

        filter: {
          tenantid: process.env.REACT_APP_TENANTID,
          facilityid: facilityid,
          //"specialtyid":specialityid,
          practtype,

          //"role": ""
        },
        queryid: "bcdb2ade-b9f4-45a4-875f-02f176f040c8",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      // let returnData = [];
      // if (Array.isArray(data.result)) {
      //   returnData = data.result;
      // }
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_PRACTITIONERS_BYLOACTION = createAsyncThunk(
  `visitRegisterPatientApiSlice/getpractlocation`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityid = "", loctype = "", appid = "" } = payload;
      const body = {
        db_name: dbName,

        filter: {
          facilityid: facilityid,
          loctype: loctype,
          appid: appid,
          start: Math.floor(
            moment()
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .valueOf() / 1000
          ),
        },
        queryid: "bce8c57a-fb7d-4051-a651-41e653e06647",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      // let returnData = [];
      // if (Array.isArray(data.result)) {
      //   returnData = data.result;
      // }
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_RESOURCE_SUB_TYPE = createAsyncThunk(
  `visitRegisterPatientApiSlice/getresourcesubtype`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { resourceid = "" } = payload;
      const body = {
        db_name: dbName,

        filter: {
          resourcetype: resourceid,
        },
        queryid: "db1c4dff-b3c1-4383-a223-93b61f2eb645",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_SERVICE_ID = createAsyncThunk(
  `visitRegisterPatientApiSlice/getserviceid`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { servicetype = "" } = payload;
      const body = {
        db_name: dbName,

        filter: {
          servicetype: servicetype,
        },
        queryid: "e2c22d8d-25f6-43f8-b565-8ade5e46b0ad",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const VISIT_READ = createAsyncThunk(
  `visitRegisterPatientApiSlice/visitread`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { encounter_id } = payload;

      const encValue = {
        db_name: dbName,
        filter: {
          encounterid: encounter_id,
          tenantid: "",
          facilityid: "",
        },
        queryid: "b1d918b4-a5f2-438d-b2f2-d831c3e1707b",
      };

      const data = await fetchData(
        {
          body: JSON.stringify(encValue),
        },
        urls.readQdmQueries
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const visitRegistrationSlice = createSlice({
  name: "visitRegisterPatientApiSlice",
  initialState: {
    getEncounterType: {
      ...defaultState.Info,
    },
    getEncounterClass: {
      ...defaultState.Info,
    },
    oraganizationvisitRegister: {
      ...defaultState.Info,
    },
    getEncounterStatus: {
      ...defaultState.Info,
    },
    readAppointment: {
      ...defaultState.Info,
    },
    oraganizationfilterbypatient: {
      ...defaultState.Info,
    },
    practitioners: {
      ...defaultState.Info,
    },
    practitionersspeciality: {
      ...defaultState.Info,
    },
    getresourcesubtype: {
      ...defaultState.Info,
    },
    getpractlocation: {
      ...defaultState.Info,
    },
    getserviceid: {
      ...defaultState.Info,
    },
    visitread: {
      ...defaultState.Info,
    },
  },
  extraReducers: {
    /* GET ENCOUNTER MASTER TYPE */
    [GET_ENCOUNTER_MASTER_TYPE.fulfilled]: (state, action) => {
      state.getEncounterType = action?.payload ?? [];
    },
    [GET_ENCOUNTER_MASTER_TYPE.pending]: (state, action) => {
      state.getEncounterType.loading = true;
    },
    [GET_ENCOUNTER_MASTER_TYPE.rejected]: (state, action) => {
      state.getEncounterType = action?.payload ?? [];
    },
    /* GET ENCOUNTER MASTER TYPE */
    [GET_ENCOUNTER_MASTER_CLASS.fulfilled]: (state, action) => {
      state.getEncounterClass = action?.payload ?? [];
    },
    [GET_ENCOUNTER_MASTER_CLASS.pending]: (state, action) => {
      state.getEncounterClass.loading = true;
    },
    [GET_ENCOUNTER_MASTER_CLASS.rejected]: (state, action) => {
      state.getEncounterClass = action?.payload ?? [];
    },
    // ORGANIZATION_VISIT_REGISTRATION
    [ORGANIZATION_VISIT_REGISTRATION.fulfilled]: (state, action) => {
      state.oraganizationvisitRegister = action?.payload ?? {};
    },
    [ORGANIZATION_VISIT_REGISTRATION.pending]: (state, action) => {
      state.oraganizationvisitRegister.loading = true;
    },
    [ORGANIZATION_VISIT_REGISTRATION.rejected]: (state, action) => {
      state.oraganizationvisitRegister = action?.payload ?? {};
    },

    /* GET_ENCOUNTER_STATUS */
    [GET_ENCOUNTER_STATUS.fulfilled]: (state, action) => {
      state.getEncounterStatus = action?.payload ?? {};
    },
    [GET_ENCOUNTER_STATUS.pending]: (state, action) => {
      state.getEncounterStatus.loading = true;
    },
    [GET_ENCOUNTER_STATUS.rejected]: (state, action) => {
      state.getEncounterStatus = action?.payload ?? {};
    },
    /* APPOINTMENT_READ */
    [APPOINTMENT_READ_VISIT.fulfilled]: (state, action) => {
      state.readAppointment = action?.payload ?? {};
    },
    [APPOINTMENT_READ_VISIT.pending]: (state, action) => {
      state.readAppointment.loading = true;
    },
    [APPOINTMENT_READ_VISIT.rejected]: (state, action) => {
      state.readAppointment = action?.payload ?? {};
    },

    // ORGANIZATION_FILTER_BY_PATIENT
    [ORGANIZATION_FILTER_BY_PATIENT.fulfilled]: (state, action) => {
      state.oraganizationfilterbypatient = action?.payload ?? {};
    },
    [ORGANIZATION_FILTER_BY_PATIENT.pending]: (state, action) => {
      state.oraganizationfilterbypatient.loading = true;
    },
    [ORGANIZATION_FILTER_BY_PATIENT.rejected]: (state, action) => {
      state.oraganizationfilterbypatient = action?.payload ?? {};
    },

    /* GET_PRACTITIONERS */
    [GET_PRACTITIONERS.fulfilled]: (state, action) => {
      state.practitioners = action?.payload ?? {};
    },
    [GET_PRACTITIONERS.pending]: (state, action) => {
      state.practitioners.loading = true;
    },
    [GET_PRACTITIONERS.rejected]: (state, action) => {
      state.practitioners = action?.payload ?? {};
    },
    /* GET_PRACTITIONERS_BYSPECIALITY */
    [GET_PRACTITIONERS_BYSPECIALITY.fulfilled]: (state, action) => {
      state.practitionersspeciality = action?.payload ?? {};
    },
    [GET_PRACTITIONERS_BYSPECIALITY.pending]: (state, action) => {
      state.practitionersspeciality.loading = true;
    },
    [GET_PRACTITIONERS_BYSPECIALITY.rejected]: (state, action) => {
      state.practitionersspeciality = action?.payload ?? {};
    },
    /* GET_PRACTITIONERS_BYSPECIALITY */
    [GET_RESOURCE_SUB_TYPE.fulfilled]: (state, action) => {
      state.getresourcesubtype = action?.payload ?? {};
    },
    [GET_RESOURCE_SUB_TYPE.pending]: (state, action) => {
      state.getresourcesubtype.loading = true;
    },
    [GET_RESOURCE_SUB_TYPE.rejected]: (state, action) => {
      state.getresourcesubtype = action?.payload ?? {};
    },
    /* GET_PRACTITIONERS_BYSPECIALITY */
    [GET_PRACTITIONERS_BYLOACTION.fulfilled]: (state, action) => {
      state.getpractlocation = action?.payload ?? {};
    },
    [GET_PRACTITIONERS_BYLOACTION.pending]: (state, action) => {
      state.getpractlocation.loading = true;
    },
    [GET_PRACTITIONERS_BYLOACTION.rejected]: (state, action) => {
      state.getpractlocation = action?.payload ?? {};
    },
    /* GET_SERVICE_ID */
    [GET_SERVICE_ID.fulfilled]: (state, action) => {
      state.getserviceid = action?.payload ?? {};
    },
    [GET_SERVICE_ID.pending]: (state, action) => {
      state.getserviceid.loading = true;
    },
    [GET_SERVICE_ID.rejected]: (state, action) => {
      state.getserviceid = action?.payload ?? {};
    },
    /* GET_SERVICE_ID */
    [VISIT_READ.fulfilled]: (state, action) => {
      state.visitread = action?.payload ?? {};
    },
    [VISIT_READ.pending]: (state, action) => {
      state.visitread.loading = true;
    },
    [VISIT_READ.rejected]: (state, action) => {
      state.visitread = action?.payload ?? {};
    },
  },
});

export const visitRegistrationActions = {
  GET_ENCOUNTER_MASTER_TYPE,
  GET_ENCOUNTER_MASTER_CLASS,
  ORGANIZATION_VISIT_REGISTRATION,
  GET_ENCOUNTER_STATUS,
  APPOINTMENT_READ_VISIT,
  ORGANIZATION_FILTER_BY_PATIENT,
  GET_PRACTITIONERS,
  GET_PRACTITIONERS_BYSPECIALITY,
  GET_RESOURCE_SUB_TYPE,
  GET_PRACTITIONERS_BYLOACTION,
  GET_SERVICE_ID,
  VISIT_READ,
};

export default visitRegistrationSlice;
