import { useState } from "react";
import { Div } from "qdm-component-library";
import { Button } from "@material-ui/core";
import { PatientCategory, BirthDetails } from "./components";
import styles from "./styles";

const AdditionalDetails = (props) => {
  const [activeId, setActiveId] = useState(1);
  const [city, setCity] = useState({});

  return (
    <Div
      id="additionaldetails_parent_div"
      style={{
        padding: "5px 15px",
        height: "calc(100vh - 56px)",
        overflow: "auto",
      }}
    >
      <PatientCategory
        parent_id={"Register_Patinet_additionaldetails"}
        allMasters={props.allMasters}
        saveto={"dataPayload"}
        setTo={"setadditnaldata"}
        patientData={props?.additionalDetails}
        setPatientData={props?.setAdditionalDetails}
        patientCategoryList={props?.patientCategoryList}
        isViewMode={props?.isViewMode}
      />

      {/* <BirthDetails
        parent_id={"Register_Patinet_additionaldetails"}
        allMasters={props.allMasters}
        saveto={"dataPayload"}
        setTo={"setadditnaldata"}
        City={city}
        patientData={props?.additionalDetails}
        setPatientData={props?.setAdditionalDetails}
      /> */}

      <Div
        id="Register_Patinet_additionaldetails_button_div"
        className="p-continue-action"
      >
        <Button
          id="Register_Patinet_additionaldetails__goback_button"
          onClick={() =>
            //this.setState({ activeId: 2 })
            props?.setActiveId(2)
          }
          className="nextKing-btn"
          style={{
            ...styles.outlinedButton,
            backgroundColor: "transparent",
          }}
        >
          Go Back
        </Button>
      </Div>
    </Div>
  );
};

export default AdditionalDetails;
