import React from "react";
import { UIColor } from "../../../themes/theme";
export const DiagnosisIcon1 = (props) => {
  let width = props.width ? props.width : "46.499";
  let height = props.height ? props.height : "39.794";
  let color = props.color ? props.color : UIColor.primaryColor.color;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 46.499 39.794"
    >
      <g id="diagnosis" transform="translate(-232.77 -79.623)">
        <g
          id="Group_90520"
          data-name="Group 90520"
          transform="translate(230.77 70.57)"
        >
          <path
            id="Path_92820"
            data-name="Path 92820"
            d="M67.955,33.8h2.393a.327.327,0,0,0,.335-.335V30.883h2.585a.327.327,0,0,0,.335-.335V28.155a.327.327,0,0,0-.335-.335H70.683V25.235a.327.327,0,0,0-.335-.335H67.955a.327.327,0,0,0-.335.335V27.82H65.035a.327.327,0,0,0-.335.335v2.393a.327.327,0,0,0,.335.335H67.62v2.585A.327.327,0,0,0,67.955,33.8Z"
            transform="translate(-32.429 -7.999)"
            fill="#001c3c"
            stroke="#fff"
            stroke-width="1"
          />
          <path
            id="Path_92821"
            data-name="Path 92821"
            d="M47.827,19.246a11.633,11.633,0,0,0-5.121-7.85A11.906,11.906,0,0,0,27.1,14.22l-.239.335-.526-.67A11.561,11.561,0,0,0,9.249,12.832,11.779,11.779,0,0,0,7.334,26.856H4.223a1.723,1.723,0,1,0,0,3.446h12.4a1.69,1.69,0,0,0,1.484-.862l1.675-3.015,4.882,8.663a1.708,1.708,0,0,0,2.968,0l2.68-4.786H31.84a1.723,1.723,0,1,0,0-3.446H29.3a1.69,1.69,0,0,0-1.484.862l-1.675,3.015-4.882-8.711a1.746,1.746,0,0,0-2.968,0l-2.68,4.786H11.45l-.287-.287a8.408,8.408,0,0,1,.431-11.248,8.2,8.2,0,0,1,5.7-2.3h.526a8.047,8.047,0,0,1,5.839,3.063l1.388,1.723a2.416,2.416,0,0,0,3.638,0l1.1-1.388a8.441,8.441,0,0,1,11.056-2.106,8.2,8.2,0,0,1,3.638,5.552A8.032,8.032,0,0,1,42.8,26.186l-14.359,18a2.044,2.044,0,0,1-3.111,0l-8.28-10.339H12.695l9.956,12.445a5.448,5.448,0,0,0,4.212,2.058,5.17,5.17,0,0,0,4.212-2.058l14.359-18A11.432,11.432,0,0,0,47.827,19.246Z"
            fill="#001c3c"
            stroke="#fff"
            stroke-width="1"
          />
        </g>
        <g
          id="Group_90521"
          data-name="Group 90521"
          transform="translate(230.77 70.949)"
        >
          <path
            id="Path_92821-2"
            data-name="Path 92821"
            d="M7.334,26.856H4.223a1.723,1.723,0,1,0,0,3.446h12.4a1.69,1.69,0,0,0,1.484-.862l1.675-3.015,4.882,8.663a1.708,1.708,0,0,0,2.968,0l2.68-4.786H31.84a1.723,1.723,0,1,0,0-3.446H29.3a1.69,1.69,0,0,0-1.484.862l-1.675,3.015-4.882-8.711a1.746,1.746,0,0,0-2.968,0l-2.68,4.786C15.607,26.817,7.334,26.856,7.334,26.856Z"
            fill="#ec6a49"
            stroke="#fff"
            stroke-width="0.8"
          />
        </g>
      </g>
    </svg>
  );
};
