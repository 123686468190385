import React, { useState, useContext, useEffect } from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { AppRoutes } from "../../router/routes";
import { useNavigate } from "react-router-dom";
import { Button, Paper, H6, TextInput } from "qdm-component-library";
import { AlertContext } from "../../context";
import { checkError, AlertProps, fetchQueueData } from "../../utils";
import withAllContexts from "../../hoc/withAllContexts";
// import Logo from "../../assets/img/Logo_W.svg";
import useStyles from "./styles";
import WithUnsecurePage from "../../hoc/withUnsecurePage";

function ResetPassword(props) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const { setSnack } = useContext(AlertContext);
  const classes = useStyles(props);

  const changeState = (key, value) => {
    if (key === "password") {
      setPassword(value);
    } else if (key === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleResetPassword = async () => {
    if (password && confirmPassword) {
      setError("");

      const payloadlogin = {
        password: password,
        confirmPassword: confirmPassword,
      };

      // const status = checkError(this.props.oraganizationSignin);
      // if (status.isError) {
      //   setSnack({
      //     open: true,
      //     severity: AlertProps.severity.error,
      //     msg: status.errMsg,
      //     vertical: AlertProps.vertical.top,
      //     horizontal: AlertProps.horizontal.right,
      //   });
      //   setError(status.errMsg);
      // } else {
      //   await fetchQueueData();
      //   localStorage.setItem("token", res?.payload?.data?.access_token);
      //   navigate(AppRoutes.dashboard);
      //   });
      // }
    } else {
      const errorMessage = "Please fill all the mandatory fields !";
      setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: errorMessage,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      setError(errorMessage);
    }
  };

  return (
    <Paper
      key={"0"}
      children="Paper"
      variant="elevation"
      elevation="1"
      style={{
        width: "350px",
        backgroundColor: "#ffffff",
        borderRadius: "16px",
        padding: "32px",
        boxShadow: "0px 8px 69px #0000001A",
        display: "",
      }}
    >
      <H6
        key={"0"}
        name="Reset Password"
        style={{
          fontSize: "24px",
          fontWeight: "500",
          color: "#101010",
        }}
        inLineStyles={{ fontFamily: "pc_semibold !important" }}
      />

      <H6
        className="pc_regular"
        key={"1"}
        name="Your new password must be different from previously used passwords."
        style={{
          fontSize: "12px",
          color: "#6F6F6F",
          textAlign: "left",
          marginBottom: "30px",
          marginTop: "10px",
        }}
      />

      <TextInput
        isRequired={true}
        type="password"
        label="Password"
        placeholder=""
        labelStyle={classes.labelStyle}
        style={{
          borderRadius: 8,
          borderColor: "#E0E0E0",
          marginBottom: 10,
        }}
        hoverColor="#0071F2"
        value={password}
        onChange={(e) => changeState("password", e.target.value)}
      />

      <TextInput
        isRequired={true}
        type="password"
        label="Confirm Password"
        placeholder=""
        labelStyle={classes.labelStyle}
        style={{
          borderRadius: 8,
          borderColor: "#E0E0E0",
          marginBottom: 10,
        }}
        hoverColor="#0071F2"
        value={confirmPassword}
        onChange={(e) => changeState("confirmPassword", e.target.value)}
      />
      {error && (
        <H6
          name={error}
          style={{
            fontSize: "12px",
            color: "red",
            textAlign: "left",
          }}
        />
      )}
      <Button
        className="pc_medium"
        key={"3"}
        onClick={handleResetPassword}
        disabled={props.oraganizationSignin?.loading}
        type="button"
        variant="outlined"
        name={"Reset Password"}
        style={{
          margin: "",
          marginTop: "25px",
          width: "100%",
          backgroundColor: "#0071F2",
          borderColor: "#0071F2",
          borderRadius: "8px",
          fontSize: "14px",
          height: "40px",
        }}
      />
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  oraganizationSignin: state?.OrganizationAuthApiSlice?.oraganizationSignin,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
});

// export default connect(
//   mapStateToProps,
//   actions
// )(withAllContexts(ResetPassword));
export default withAllContexts(WithUnsecurePage((ResetPassword)));
