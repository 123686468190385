import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#179e8e",
      light: "#e7f5f3"
    },
    secondary: {
      main: "#EC6A49",
      light: "#fbe1da"

    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif", // Customize default font family
    // Add other typography customizations here
  },
});

export default theme;
