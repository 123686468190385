import React from "react";
import { Div, Col, Row, Paper, H6, TextInput } from "qdm-component-library";
import "./style.css";
import  withAllContexts  from "../../../hoc/withAllContexts";
import moment from "moment";
import { UIColor } from "../../../themes/theme";
import CustomInputDatePicker from "../../../components/customDatePicker/inputDatePicker";

function RefLetter(props) {
	const styles = {
		labelStyle: {
			color: "#6F6F6F",
			fontSize: "12px",
			marginBottom: "6px",
			fontFamily: `pc_regular !important`,
		},
		inputBorderStyle: {
			borderRadius: 8,
			border: "1px solid #E0E0E0",
		},
		errorinputBorderStyle: {
			borderRadius: 8,
		},
	};

	const { letter } = props.referralData;

	const setDetails = (name, value, limit) => {
		if (limit) {
			if (limit < value.length) {
				return false;
			}
		}

		let patientData = props.referralData;

		patientData.letter[name] = value;

		if (patientData.letter.error) {
			patientData.letter.error[name] = false;
		}
		patientData.setReferralDetails({
			...patientData,
		});
	};

	const { labelStyle, inputBorderStyle, errorinputBorderStyle } = styles;
	const { Persondata, parent_id } = props;

	return (
		<Div id={`${parent_id}_refletter_parent_div`} className="pv-root">
			<Paper id={`${parent_id}_refletter_parent_paper`} className="pl-root">
				<Row
					id={`${parent_id}_refletter_parent_row`}
					className="en-actions-root"
				>
					<Col
						id={`${parent_id}_refletter_parent_col`}
						md={8}
						lg={8}
						sm={12}
						xs={12}
						className="no-padding-left"
					>
						<Div id={`${parent_id}_refletter_title_label_col`}>
							<H6
								id={`${parent_id}_refletter_title_label_h6`}
								className="en-title space-padding-bottom"
							>
								REFERRAL LETTER
							</H6>
						</Div>
						<Row
							id={`${parent_id}_refletter_sub_parent_row`}
							className="en-actions-root"
						>
							<Col
								id={`${parent_id}_refletter_sub_parent_col`}
								md={3}
								lg={3}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<TextInput
									id={`${parent_id}_refletter_no_label_textinput`}
									value={letter.letterNo}
									onChange={(e) => setDetails("letterNo", e.target.value, 20)}
									label="Referral letter No."
									isRequired
									placeholder="Referral letter no"
									// helperText={
									// 	letter?.error && letter?.error["letterNo"]
									// 		? "Is required"
									// 		: ""
									// }
									error={
										letter?.error && letter?.error["letterNo"] ? true : false
									}
									labelStyle={labelStyle}
									style={letter?.error && letter?.error["letterNo"] ? errorinputBorderStyle : inputBorderStyle}
									hoverColor={UIColor.primaryColor.color}
								/>
							</Col>
							<Col
								id={`${parent_id}_refletter_calender_col`}
								md={3}
								lg={3}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								{/* <TextInput
									id={`${parent_id}_refletter_referral_date_textinput`}
									isRequired
									// helperText={
									// 	letter?.error && letter?.error["referralDate"]
									// 		? "Is required"
									// 		: ""
									// }
									error={
										letter?.error && letter?.error["referralDate"]
											? true
											: false
									}
									max={moment(new Date()).format("YYYY-MM-DD")}
									value={letter.referralDate}
									onChange={(e) => setDetails("referralDate", e.target.value)}
									label="Referral Date "
									type="date"
									dateIcon="images/icons8-calendar-grey.svg"
									labelStyle={labelStyle}
									style={letter?.error && letter?.error["referralDate"] ? errorinputBorderStyle : inputBorderStyle}
									hoverColor={UIColor.primaryColor.color}
								/> */}
								<CustomInputDatePicker
									disabled = {false}
									placeholderText = "dd/mm/yyyy" 
									maxDate = {new Date()}
									handleChange = {(value) => setDetails("referralDate", value ? moment(value).format("YYYY-MM-DD") : null, "validity")}
									selectedDate = {letter.referralDate ? new Date(letter.referralDate) : ''}
									inputField = {
										<TextInput
											id={`${parent_id}_refletter_referral_date_textinput`}
											label = "Referral Date"
											placeholder = "dd/mm/yyyy"
											dateIcon="images/icons8-calendar-grey.svg" 
											labelStyle={labelStyle}
											isRequired
											error={
												letter?.error && letter?.error["referralDate"] ? true : false
											}
											style={
												letter?.error && letter?.error["referralDate"] ?
											 errorinputBorderStyle 
											 : inputBorderStyle
											} 
											hoverColor={UIColor.primaryColor.color}
											value={letter.referralDate ? moment(new Date(letter.referralDate)).format("DD/MM/YYYY") : ''}
										/>
									}
								/>
							</Col>
							<Col
								id={`${parent_id}_refletter_reciveddate_col`}
								md={3}
								lg={3}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								{/* <TextInput
									id={`${parent_id}_refletter_reciveddate_textinput`}
									isRequired
									// helperText={
									// 	letter?.error && letter?.error["recivedDate"]
									// 		? "Is required"
									// 		: ""
									// }
									error={
										letter?.error && letter?.error["recivedDate"] ? true : false
									}
									//max={moment(new Date()).format("YYYY-MM-DD")}
									min={moment(letter.referralDate).format("YYYY-MM-DD")}
									value={letter.recivedDate}
									onChange={(e) => setDetails("recivedDate", e.target.value)}
									label="Received Date"
									type="date"
									dateIcon="images/icons8-calendar-grey.svg"
									labelStyle={labelStyle}
									style={letter?.error && letter?.error["recivedDate"] ? errorinputBorderStyle : inputBorderStyle}
									hoverColor={UIColor.primaryColor.color}
								/> */}
								<CustomInputDatePicker
									disabled = {false}
									placeholderText = "dd/mm/yyyy" 
									minDate = {new Date(letter.referralDate)}
									maxDate = {new Date()}
									handleChange = {(value) => setDetails("recivedDate", value ? moment(value).format("YYYY-MM-DD") : null , "validity")}
									selectedDate = {letter.recivedDate ? new Date(letter.recivedDate) : new Date()}
									inputField = {
										<TextInput
											id={`${parent_id}_refletter_reciveddate_textinput`}
											label = "Received Date"
											placeholder = "dd/mm/yyyy"
											dateIcon="images/icons8-calendar-grey.svg" 
											labelStyle={labelStyle}
											isRequired
											// error={
											// 	letter?.error && letter?.error["recivedDate"] ? true : false
											// }
											style={letter?.error && letter?.error["recivedDate"] ? errorinputBorderStyle : inputBorderStyle} 
											hoverColor={UIColor.primaryColor.color}
											value={letter.recivedDate ? moment(new Date(letter.recivedDate)).format("DD/MM/YYYY") : moment(new Date()).format("DD/MM/YYYY")}
										/>
									}
								/>
							</Col>
						</Row>
					</Col>
					<Col
						id={`${parent_id}_refletter_referralid_col`}
						lg={4}
						md={4}
						sm={4}
						xs={4}
						className="no-padding-left no-padding-right"
					>
						{/* <Row
              id={`${parent_id}_refletter_referralid_row`}
              className="en-encounter-right"
            >
              <Col
                id={`${parent_id}_refletter_referralid_sub_col`}
                md={6}
                lg={6}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_refletter_referralid_textinput`}
                  value={letter.referralId}
                  onChange={(e) => setDetails("referralId", e.target.value)}
                  disabled
                  label="Referral ID"
                  placeholder="Referral id"
                  labelStyle={labelStyle}
                  style={{ ...inputBorderStyle, background: "#F0F0F0" }}
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
              <Col
                id={`${parent_id}_refletter_status_col`}
                md={6}
                lg={6}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_refletter_status_textinput`}
                  // value={letter.status}
                  // value={Persondata?.[0]?.active ? 'Active' : 'Inactive'}
                  value={"Active"}
                  onChange={(e) => setDetails("status", e.target.value)}
                  disabled
                  label="Status"
                  placeholder="Status"
                  labelStyle={labelStyle}
                  style={{ ...inputBorderStyle, background: "#F0F0F0" }}
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
            </Row> */}
					</Col>
				</Row>
			</Paper>
		</Div>
	);
}

export default withAllContexts(RefLetter);
