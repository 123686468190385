import React, { useState, useEffect } from "react";
import { Row, Col, Text, Div, Icons, Image } from "qdm-component-library";
import {
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { UIColor } from "../../../themes/theme";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/actions";
import { refreg } from "../vitalsJson";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useContext } from "react";
import { AlertContext, BackdropContext, DialogContext } from "../../../context";
import { v4 as uuidv4 } from "uuid";
import withAppBar from "../../../hoc/withAppBar";
import {
  AlertProps,
  axiosCall,
  epochToDatetime,
  formatDateFromEpoch,
  urls,
} from "../../../utils";
import { Pagination } from "@material-ui/lab";
import HistoryAccordion from "../historyAccordion";
import Addicon from "../../../assets/img/icons8-add.svg";
import icons8edit from "../../../assets/img/svg/icons8-edit_.svg";
import printIcon from "../../../assets/img/svg/print-icon-noframe.svg"
import axios from "axios";
import ReferalRegOutForm from "./referalRegOutForm";
import jwtDecode from "jwt-decode";

const styles = {
  vitalsHead: {
    padding: "6px 14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
    paddind: 10,
  },
  vitailsCard: {
    //border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    marginBottom: 0,
    background: "#fff",
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "red",
  },
  inputStyle: {
    padding: 5,
    marginBottom: 20,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  listVitals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tagsStyle: {
    padding: "4px 12px",
    borderRadius: "50px",
    border: "1px solid #0071F2",
    color: "#0071F2",
    background: "#F5FAFF",
    fontSize: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  tapSelectStyle: {
    padding: "4px 8px",
    borderColor: UIColor.lineBorderFill.color,
    margin: "4px 10px 6px 0px",
    borderRadius: "8px",
    minWidth: "auto",
    "& span": {
      fontSize: "12px",
    },
  },
  TapSelectcol: {
    display: "flex",
    margin: "auto 0",
    marginBottom: "12px",
    paddingLeft: "4px",
  },
  CalendarStyle: {
    padding: 5,
    marginBottom: 10,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  CalendarStyleIcon: {
    position: "absolute",
    right: "14px",
    bottom: "8px",
    cursor: "pointer",
  },
  editAllergyIcon: {
    padding: "6px 10px",
    background: UIColor.greyBackground.color,
    borderRadius: "50px",
    cursor: "pointer",
  },
};
const fieldsToDisplay = [
  "vascularaccess",
  "buffer",
  "dialysate",
  "dryweight",
  "frequency",
  "heparin",
  "needlesize",
  "qd",
  "qb",
  "shift",
  "hdduration",
];

const ReferalRegOut = (props) => {
  const dispatch = useDispatch();
  const [ReferalOutData, setReferalOutData] = React.useState(
    {
      _id:"",
      referralinfoid:"",
      RefLetterNo:"",
      RefDate:0,
      RefToFacility:{},
      RefFromFacility:{},
      RefFromDoctor:{},
      RefToDoctor:"",
      Esrf:0,
      OtherMedicComorbid:"",
      MedicHistory:"",
      TargetDryWeight:"",
      CurrentVascularAccess:"",
      ViralStatus:{},
      ViralStatusDate:0,
      HbsAg:{},
      Anti_HCV:{},
      Anti_HIV:{},
      RefReason:[],
      SymptomSince:0,
      SymptomDate:0,
      RefFromContactNum:0,
      User:{},
      ReasonTxt:"",
    }
  );
  const referalRegOutRead = async () => {
      const ReadReferalRegOut = await dispatch(
        actions.Read_Referal_Reg_Out({
          patientId: props?.location?.state?.patientId || props?.location?.state?.pId,//"ReferralTrx/11569"//
          encounterId: props?.encData?.encId || props?.location?.state?.encounterId || props?.location?.state?.encounter_id
        })
      );
      let RefReadData = ReadReferalRegOut?.payload?.data[0]
      let refDateString = RefReadData?.RefSourceLetDate ? new Date(RefReadData?.RefSourceLetDate * 1000).toISOString().split('T')[0] : "";
      let EsrfString =RefReadData?.esrfhemostart ?  new Date(RefReadData?.esrfhemostart * 1000).toISOString().split('T')[0] : "";  
      let symptomdateString = RefReadData?.symptomdate ? new Date(RefReadData?.symptomdate * 1000).toISOString().split('T')[0] : "";
      let ViralStatusDateString =RefReadData?.viraldate ?  new Date(RefReadData?.viraldate * 1000).toISOString().split('T')[0] : "";
        let RefReasonList = []
        let reasonTxt = "";
        RefReadData?.reason?.map((v,i)=>{
          let obj = {
            _id:v?.reasonid?._id,
            ReasonDesc:v?.reasonid?.value
          }
          reasonTxt = v?.reasonid?._id === process.env.REACT_APP_REASON_FOR_REFERAL_OTHERS_VALUE ? v?.reasontxt : "";
          RefReasonList.push(obj);
        })
        if(RefReadData !== undefined){
          await setReferalOutData({
              _id: RefReadData?._id,
              referralinfoid: RefReadData?.referralinfoid,
              RefLetterNo: RefReadData?.RefSourceLetRef,
              RefDate: refDateString,
              RefToFacility: RefReadData?.receiptfacility,
              RefFromFacility: RefReadData?.RequestorLocationCode,
              RefFromDoctor: RefReadData?.RequestorPractioner,
              RefToDoctor: RefReadData?.recipientPract,
              Esrf: EsrfString,
              OtherMedicComorbid: RefReadData?.othermedhistory,
              MedicHistory: RefReadData?.medicationhistory,
              TargetDryWeight: RefReadData?.targetdryweight,
              CurrentVascularAccess: RefReadData?.curvasaccess,
              ViralStatus: RefReadData?.viralstatus,
              ViralStatusDate: ViralStatusDateString,
              HbsAg: RefReadData?.hbsag, 
              Anti_HCV: RefReadData?.antihcv,
              Anti_HIV: RefReadData?.antihivl2,
              RefReason: RefReasonList,
              SymptomSince: RefReadData?.symptomdays,
              SymptomDate: symptomdateString,
              RefFromContactNum: RefReadData?.refercontactinfo,
              User: RefReadData?.referuser,
              ReasonTxt:reasonTxt,
          })
        }
  };
  const alert = useContext(AlertContext);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [dailogComp, setDailogComp] = useState(<></>);
  const [dropdownval,setdropdownval] = useState({})

  const getDefaultValAndDropDown = async () => {
    const userInfo = await localStorage.getItem("UserInfo");
    const decodedUserinfo = await jwtDecode(userInfo);
    const practitionerid = decodedUserinfo?.practitionerid;
    const facilityId = decodedUserinfo?.facilityid
    const getRefFacility = await dispatch(
      actions.Get_Referral_From_Facility({
       orgId: facilityId,
      })
    );
    const getRefDoctor = await dispatch(
      actions.Get_Referral_From_Doctor({
       facilityId: facilityId
      })
    );
    const getRefToFacility = await dispatch(
      actions.Get_Referral_To_Facility()
    );
    const getViralStatus = await dispatch(
      actions.Get_ViralStatus({
       type:"POSITIVENEGATIVE"
      })
    );
    const getViralStatusChild = await dispatch(
      actions.Get_ViralStatus({
       type:"YESNO"
      })
    );
    const getReasonForReferal = await dispatch(
      actions.Get_Reason_For_Referal({
       type:"REFERRALOUTREASON"
      })
    );
    const getUser = await dispatch(
      actions.Get_User({
        facilityId:facilityId
      })
    );
    setdropdownval({
      getRefFacilityList:getRefFacility?.payload?.data,
      getRefDoctorList:getRefDoctor?.payload?.data,
      getRefToFacilityList:getRefToFacility?.payload?.data,
      getViralStatusList:getViralStatus?.payload?.data,
      getViralStatusChildList:getViralStatusChild?.payload?.data,
      getReasonForReferalList:getReasonForReferal?.payload?.data,
      getUserList:getUser?.payload?.data,
    })

  };
  useEffect(() => {
    referalRegOutRead();
    getDefaultValAndDropDown();
  }, []);

  const handleDialog = (edit) => {
    setOpen(true);
    setDailogComp(
      <ReferalRegOutForm
        handlecancel={handleClose}
        patientid={
          props?.location?.state?.patientId ??
          props?.patientId ??
          props?.location?.state?.pId
        }
        encounterid={props?.location?.state?.encounter_id}
        dropdownval={dropdownval}
        edit={edit}
        referalRegOutRead={referalRegOutRead}
        ReadRefData={ReferalOutData}
        setOpen={setOpen}
        patientId={props?.location?.state?.patientId || props?.location?.state?.pId}
        referalTrxId={props?.referralId}
        encounterId={props?.encData?.encId || props?.location?.state?.encounterId || props?.location?.state?.encounter_id}
      />
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const printChart = async () => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = {
      "Content-Type": "application/json",
      JwtToken: JwtToken 
    };
    let payload = {
      "reportid": "5963285c-979c-4ff2-bb1b-78f90765896f",
      "inputparams": {
        "@refid": ReferalOutData?._id,
        "@facilityid": "",
        "@tenantid": ""

      },
      "result": []
    };
    try{
      let Response = await axios({
        method: "POST",
        url: urls?.jasperReport,
        headers: header,
        data: JSON.stringify(payload),
      });
      if(Response?.status == 200){
        const response = await axios.get(Response?.data?.downloadUrl, {
          method: "GET",
          responseType: "blob",
        });
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });
        const pdfDataUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfDataUrl, "_blank");
      } 
    }catch (error) {
        if(error?.response?.status == 400) {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "No Documents !!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        } else {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please Contact Admin",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        }
      }
  }
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <Row inLineStyles={{ ...styles.vitailsCard }}>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.vitalsHead}
          >
            <Text
              inLineStyles={{
                fontWeight: 600,
                fontSize: "14px",
                color: UIColor.primaryColor.color,
                margin: "10px 5px 10px 5px",
              }}
            >
              {"Referral Registration Out"}
            </Text>
            <Grid>
              <Grid container style={{flexDirection:"row", justifyItems:"center"}}>
              {ReferalOutData?._id  ? (
                <Grid item>
                  <Text
                    inLineStyles={{
                      padding: "6px 10px",
                      background: UIColor.greyBackground.color,
                      borderRadius: "50px",
                      cursor: "pointer",
                      marginLeft: 10,
                    }}
                    onClick={(e) =>
                      printChart()
                    }
                    style={{
                      display:
                       "block",//ReferalOutData  ? "none" :
                    }}
                  >
                    <Image
                      src={printIcon}
                      alt="edit_icon"
                      style={{ paddingTop: "3px", height:"18px",width:"20px"}}
                    />
                  </Text>
                </Grid>
              ) : null}
                {ReferalOutData?._id !== "" ? (
                  <Grid item>
                  <Text
                    inLineStyles={{
                      padding: "6px 10px",
                      background: UIColor.greyBackground.color,
                      borderRadius: "50px",
                      cursor: "pointer",
                      marginLeft: 10,
                    }}
                    onClick={() =>
                      handleDialog(true)
                    }
                    style={{
                      display:
                       "block",//ReferalOutData ? "none" :
                    }}
                  >
                    <Image
                      src={icons8edit}
                      alt="edit_icon"
                      style={{ paddingTop: "3px" }}
                    />
                  </Text>
                </Grid>
                ) : ( 
                  <Grid item>
                    <Text
                        inLineStyles={{
                        padding: "6px 10px",
                        background: UIColor.greyBackground.color,
                        borderRadius: "50px",
                        cursor: "pointer",
                        marginLeft: 10,
                        }}
                        onClick={(e) =>
                            handleDialog()
                        }
                        style={{
                        display:
                         "block",//ReferalOutData? "none" :
                        }}
                    >
                        <Image
                            src={Addicon}
                            alt="Add Icon"
                            style={{ paddingTop: "3px", height:"18px",width:"20px"}}
                            />
                        {/* <img src={Addicon} alt="Add" /> */}
                    </Text>
                </Grid>
                )}
              </Grid>
            </Grid>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={{ padding: "0px 8px 10 8px" }}
          >
            {loading ? (
              <Div
                id={`vitals_CircularProgress_div`}
                style={{ display: "flex", height: "100px" }}
              >
                <CircularProgress
                  id={`vitals_CircularProgress`}
                  style={{ margin: "auto" }}
                  size={23}
                />
              </Div>
            ) : (
              <div style={{ width: "100%" }}>
                {ReferalOutData?._id !== "" ? (
                    <div  style={{ width: "100%" }}>
                      <div style={{ margin: "10px 5px" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <Grid
                            container
                            xs={12}
                            spacing={12}
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Referal Letter No
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.RefLetterNo}
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Referal Date
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.RefDate}
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Referal From Facility
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.RefFromFacility?.value}
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Referal From Doctor
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.RefFromDoctor?.value}
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Referal To Facility
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.RefToFacility?.value}
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Referal To Doctor
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.RefToDoctor}
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Esrf
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.Esrf}
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Other Medical Comorbid
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.OtherMedicComorbid}
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Medication History
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.MedicHistory}
                                  ...
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Current Vascular Access
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.CurrentVascularAccess}{" "}
                                  MIN
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Target Dry Weight
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.TargetDryWeight}
                                
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Viral Status
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.ViralStatus?.display}
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Viral Status Date
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.ViralStatusDate}
                                  
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Symptoms Since
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.SymptomSince}
                                  
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Symptoms Date
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.SymptomDate}
                                  
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Refering From Contact
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.RefFromContactNum}
                                  
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  User
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {ReferalOutData?.User?.value}
                                  
                                </Text>
                              </Grid>
                            </Grid>
                        
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  {/* BUFFER*/}
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {/*{e.buffer}*/}
                                </Text>
                              </Grid>
                            </Grid>
                          </Grid>
                          <span style={{ marginRight: "30px" }}></span>
                        </div>
                      </div>
                    </div>
                ) : (
                  <Typography
                    variant="caption"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "15px 0px",
                      display: "block",
                    }}
                  >
                    No data available
                  </Typography>
                )}
              </div>
            )}
          </Col>
        </Row>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={open}
          // onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          {dailogComp}
        </Dialog>
      </div>
    </>
  );
};

export default ReferalRegOut;
