/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React from "react";
import {
  Row,
  Col,
  Text,
  Avatar,
  Div,
  Select,
  TapSelect,
  TextInput,
  TextArea,
  Button,
} from "qdm-component-library";
import { Popover } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { CalendarIcon, SelectBox } from "../../../components";
import CustomDatePicker from "../../../components/customDatePicker";
import FormHeader from "./formHeader";
import InfoStatus from "./infoStatus";
import withAllContexts from "../../../hoc/withAllContexts";
import { AlertProps, makeName, isIntegerValue } from "../../../utils";
import { UIColor } from "../../../themes/theme";
import actions from "../../../redux/actions";

const onserOption = [
  { value: "Day", label: "Day" },
  { value: "Month", label: "Month" },
  { value: "Year", label: "Year" },
];
const assertedOption = [
  { value: "Self", label: "Self" },
  { value: "Others", label: "Others" },
];

const CheifComplaintsForm = (props) => {
  // const [openCalender, setOpenCalender] = React.useState(false);
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state?.chiefCompilenceMastersSlice);

  let cheifComplaints = props.cheifComplaints;

  let editIndex = cheifComplaints.editIndex;


  React.useEffect(() => {
    setState(cheifComplaints.list[editIndex]);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const [openStatus, setOpenstatus] = React.useState(null);
  const [statusType, setStatusType] = React.useState(true);

  const handleStatusInactive = () => {
    setStatusType(false);
  };

  const handleStatusActive = () => {
    setStatusType(true);
  };

  const handleStausClose = () => {
    setOpenstatus(null);
  };

  const openStausInfo = (e) => {
    setOpenstatus(e?.currentTarget ?? null);
  };

  const handleClick = (event, type) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event, type) => {
    setAnchorEl1(event.currentTarget);
  };
  const [state, setState] = React.useState(
    cheifComplaints.editIndex !== undefined &&
      cheifComplaints.editIndex !== null
      ? cheifComplaints.list[cheifComplaints.editIndex]
      : props?.formComponet?.id ?? {
          onset: {
            day: "",
            freq: "",
            currentDate: "",
            isCalenderEnabled: false,
            calenderIconEnabled: true,
          },
          abatement: {
            day: "",
            freq: "",
            currentDate: "",
            isCalenderEnabled: false,
            calenderIconEnabled: true,
          },
        }
  );

  const styles = {
    userPic: {
      width: 34,
      height: 34,
      marginLeft: 12,
      fontSize: 14,
      borderRadius: 4,
    },
    errorborderStyle: {
      borderRadius: 8,
      borderColor: "red",
    },
    subText: {
      fontSize: 12,
      color: UIColor.secondaryText.color,
    },
    text: {
      fontSize: 14,
    },
    headerText: {
      fontSize: 16,
    },
    flexEnd: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    flexStart: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    backIcon: {
      background: UIColor.lineBorderFill.color,
      padding: "9px 13px 9px 11px",
      borderRadius: "50px",
      display: "flex",
      marginRight: 14,
      cursor: "pointer",
    },
    headBorder: {
      padding: "6px 0px",
      background: "#fff",
      borderRadius: "10px",
    },
    labelStyle: {
      color: UIColor.tertiaryText.color,
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: UIColor.lineBorderFill.color,
    },
    colPadding: {
      padding: "7px",
    },
    tapSelectStyle: {
      padding: "4px 8px",
      borderColor: UIColor.lineBorderFill.color,
      margin: "4px 10px 6px 0px",
      borderRadius: "8px",
      minWidth: "auto",
      fontSize: "20px !important",
    },
    buttonTextStyle: {
      color: UIColor.primaryColor.color,
      fontWeight: 600,
      padding: "4px",
    },
    muiPopovers: {
      boxShadow: "0px 10px 75px #0000003D",
      borderRadius: 8,
    },
    flexBetween: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cancelBtn: {
      padding: "8px",
      fontSize: 14,
      marginRight: 10,
      borderColor: UIColor.lineBorderFill.color,
      color: UIColor.primaryColor.color,
      borderRadius: 8,
    },
    filledBtn: {
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
      padding: "8px",
      fontSize: 14,
      borderRadius: 8,
    },
    requiredColor: {
      color: UIColor.error.color,
      fontSize: "12px",
    },
    calenderIconstyles: {
      padding: "2px 7px",
      margin: "3px 0px 5px 0px",
      border: `1px solid ${UIColor.lineBorderFill.color}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      cursor: "pointer",
    },
  };

  const goBack = () => {
    props.onCancel();
  };

  const handleInputChange = (selector, val) => {
    setState({
      ...state,
      [selector]: val,
    });
  };

  const handleClose = (get) => {
    if (get) {
      setAnchorEl(null);
      setAnchorEl1(null);
    }
  };
  // const openCalenderHandel = (val) => {
  //     setOpenCalender(val?.currentTarget ? val.currentTarget : null)
  // }

  const calenderDataClear = () => {
    state.onset["isCalenderEnabled"] = false;
    setState({
      ...state,
    });
  };

  const calenderDataClear1 = () => {
    state.abatement["isCalenderEnabled"] = false;
    setState({
      ...state,
    });
  };

  const calenderChange = (val) => {
    state.onset["currentDate"] = moment(val).format("DD MMM, YYYY");
    state.onset["isCalenderEnabled"] = true;
    setState({
      ...state,
    });
    handleClose(true);
  };

  const calenderChange1 = (val) => {
    state.abatement["currentDate"] = moment(val).format("DD MMM, YYYY");
    state.abatement["isCalenderEnabled"] = true;
    setState({
      ...state,
    });
    handleClose(true);
  };

  const [isErr, setIsErr] = React.useState(false);
  const UpdateCC = async () => {
    // cheifComplaints.list[cheifComplaints.editIndex] = state;
    if(state?.complaint?.length === 0){
      setIsErr(true);
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else if (
      state.name !== null &&
      state.severity.label &&
      state.clinicalStatus !== null &&
      state?.assertedBy &&
      state?.assertedBy?.label?.toLocaleLowerCase() === "others"
        ? state?.relationName?.label
        : true &&
          Object.keys(state.name).length !== 0 &&
          // && Object.keys(state.diagnosis).name !== 0
          Object.keys(state.clinicalStatus).name !== 0
    ) {
      await props.saveCheifComp([state], cheifComplaints.editIndex);
      goBack();
    } else {
      setIsErr(true);
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
    // cheifComplaints.setCheifComplaints({ ...cheifComplaints, editIndex: null });
  };
  console.log("chief", state);

  const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;
  const open1 = Boolean(anchorEl1);
  // const id1 = open1 ? "simple-popover" : undefined;
  const { parent_id } = props;
  return (
    <Div
      id={`${parent_id}_cheif_form_parent_div`}
      // inLineStyles={{paddingTop: 10}}
    >
      {/* Header */}
      <div style={styles.headBorder}>
        <FormHeader
          parent_id={"cheif_complaints"}
          date={state?.createdAt + " - Created"}
          name={makeName(state?.createdName || {}) + " (You)"}
          title={state?.name?.label ?? ""}
          goBack={goBack}
          currentStatus={state?.clinicalStatus?.label}
          openStausInfo={openStausInfo}
        />
      </div>
      {/* Main content */}
      <div
        id={`${parent_id}_cheif_form_parent_sub_div`}
        style={{
          height: "calc(100vh - 296px)",
          overflow: "auto",
          background: "#fff",
          borderRadius: "10px",
          margin: "10px 0px",
        }}
      >
        <Row
          id={`${parent_id}_cheif_form_parent_sub_row`}
          style={{ padding: 7 }}
        >
          <Col
            id={`${parent_id}_cheif_form_Patient_Complaint_col`}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
          >
            {/* Patient Complaint * */}
            <TextInput
              id={`${parent_id}_cheif_form_Patient_Complaint_textinput`}
              label={"Patient Complaint"}
              name={"complaint"}
              placeholder="Patient Complaint"
              isRequired
              labelStyle={styles.labelStyle}
              hoverColor={UIColor.lineBorderFill.color}
              style={
                isErr && !state?.complaint
                  ? styles.errorborderStyle
                  : styles.borderStyle
              }
              value={state?.complaint}
              onChange={(event) =>
                handleInputChange(event.target.name, event.target.value)
              }
              error={isErr && !state?.complaint ? true : false}
              // helperText={(isErr && !state?.complaint) ? 'Is required' : ''}
            />
          </Col>
          <Col
            id={`${parent_id}_cheif_form_Sign_Symptoms_col`}
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            inLineStyles={styles.colPadding}
          >
            <SelectBox
              parent_id={"cheif_complaints"}
              serachIcon
              requireStart
              top_title={"Sign and Symptoms Code & Description"}
              list={storeData?.cc_master_data?.data ?? []}
              placeholder={"Search here..."}
              value={state?.name}
              onchange={(e, value) => handleInputChange("name", value)}
              error={isErr && !state?.name ? true : false}
            />
          </Col>
          <Col
            id={`${parent_id}_cheif_form_Body_site_col`}
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            inLineStyles={styles.colPadding}
          >
            <SelectBox
              parent_id={"cheif_complaints"}
              top_title={"Body Site"}
              list={storeData?.bodysite_masters?.data ?? []}
              placeholder={"Search here..."}
              value={state?.bodySite}
              onchange={(e, value) => handleInputChange("bodySite", value)}
            />
          </Col>
          <Col
            id={`${parent_id}_cheif_form_Severity_col`}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
          >
            <Text
              id={`${parent_id}_cheif_form_Severity_text`}
              inLineStyles={styles.labelStyle}
            >
              {"Severity "}
            </Text>
            <Text
              id={`${parent_id}_cheif_form_Severity_star_text`}
              inLineStyles={styles.requiredColor}
            >
              {"*"}
            </Text>
            <TapSelect
              id={`${parent_id}_cheif_form_Severity_tapselect`}
              label={""}
              inLineStyles={styles.tapSelectStyle}
              selectedBorderColor={UIColor.secondaryButtonColor.color}
              selectedBackgroundColor={UIColor.secondaryButtonColor.color}
              selectedTextColor={"#fff"}
              options={storeData?.Severity_masters?.data ?? []}
              value={state?.severity}
              defaultSelected={state?.severity}
              onChange={(value) => handleInputChange("severity", value)}
            />
            {isErr && !state?.severity?.label && (
              <Text style={{ color: "red", fontSize: 12 }}>
                {"Is required"}
              </Text>
            )}
          </Col>
          <Col
            id={`${parent_id}_cheif_form_assertedBy_col`}
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            inLineStyles={styles.colPadding}
            style={{ display: "flex" }}
          >
            <div id={`${parent_id}_cheif_form_assertedBy_div`}>
              <Text
                id={`${parent_id}_cheif_form_assertedBy_text`}
                inLineStyles={styles.labelStyle}
              >
                {"Asserted By"}
              </Text>
              <TapSelect
                id={`${parent_id}_cheif_form_assertedBy_tapselect`}
                label={""}
                inLineStyles={styles.tapSelectStyle}
                selectedBorderColor={UIColor.secondaryButtonColor.color}
                selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                selectedTextColor={"#fff"}
                options={assertedOption}
                defaultSelected={state?.assertedBy}
                value={state?.assertedBy}
                onChange={(value) => handleInputChange("assertedBy", value)}
              />
            </div>
            <div
              id={`${parent_id}_cheif_form_Relationship_div`}
              style={{ width: "170px" }}
            >
              {state?.assertedBy &&
                (state?.assertedBy?.label ?? "").toLocaleLowerCase() ===
                  "others" && (
                  <SelectBox
                    parent_id={"cheif_complaints"}
                    top_title={"Relationship"}
                    list={storeData?.relationship_masters?.data ?? []}
                    placeholder={"Search here..."}
                    value={state?.relationName}
                    onchange={(e, value) =>
                      handleInputChange("relationName", value)
                    }
                    requireStart
                    error={isErr && !state?.relationName?.label}
                  />
                )}
            </div>
          </Col>

          {/* Date components */}
          {state.onset?.isCalenderEnabled === true ? (
            <Col
              id={`${parent_id}_cheif_form_date_col`}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              inLineStyles={styles.colPadding}
            >
              <Row id={`${parent_id}_cheif_form_date_row`}>
                <Col
                  id={`${parent_id}_cheif_form_Onset_date_col`}
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  xl={9}
                  style={{ padding: 0 }}
                >
                  <Text
                    id={`${parent_id}_cheif_form_Onset_text`}
                    inLineStyles={styles.labelStyle}
                  >
                    {"Onset"}
                  </Text>
                  <TextInput
                    id={`${parent_id}_cheif_form_Onset_textinput`}
                    label={""}
                    variant={"outlined"}
                    hoverColor={UIColor.lineBorderFill.color}
                    style={{ width: "218px" }}
                    placeholder=""
                    value={state?.onset?.currentDate}
                    disabled
                  />
                </Col>
                <Col
                  id={`${parent_id}_cheif_form_calenderDataClear_col`}
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  style={{
                    padding: "0px",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <div id={`${parent_id}_cheif_form_calenderDataClear_div`}>
                    <Avatar
                      id={`${parent_id}_cheif_form_calenderDataClear_avatar`}
                      style={{ width: "40px", height: "40px", margin: 0 }}
                      onClick={() => calenderDataClear()}
                    >
                      <CloseIcon id={`${parent_id}_cheif_form_CloseIcon`} />
                    </Avatar>
                  </div>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col
              id={`${parent_id}_cheif_form_calenderIconEnabled_col`}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              inLineStyles={styles.colPadding}
            >
              <Text
                id={`${parent_id}_cheif_form_calenderIconEnabled_Onset_text`}
                inLineStyles={styles.labelStyle}
              >
                {"Onset"}
              </Text>
              <div
                id={`${parent_id}_cheif_form_calenderIconEnabled_Onset_div`}
                style={{ display: "flex" }}
              >
                <div
                  id={`${parent_id}_cheif_form_calenderIconEnabled_sub_div`}
                  style={{ width: 40, marginRight: 8 }}
                >
                  <TextInput
                    id={`${parent_id}_cheif_form_calenderIconEnabled_day_textinput`}
                    label={""}
                    variant={"standard"}
                    placeholder="05"
                    maxLength={2}
                    hoverColor={UIColor.lineBorderFill.color}
                    value={state?.onset?.day}
                    onChange={(event) => {
                      if (!isIntegerValue(event.target.value)) return;
                      state.onset["day"] = event.target.value;
                      setState({
                        ...state,
                      });
                    }}
                  />
                </div>
                <TapSelect
                  id={`${parent_id}_cheif_form_calenderIconEnabled_tapselect`}
                  label={""}
                  inLineStyles={styles.tapSelectStyle}
                  selectedBorderColor={UIColor.secondaryButtonColor.color}
                  selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                  selectedTextColor={"#fff"}
                  options={onserOption}
                  defaultSelected={state?.onset?.freq}
                  onChange={(value) => {
                    state.onset["freq"] = value;
                    state.onset["calenderIconEnabled"] = value?.value
                      ? false
                      : true;
                    console.log(state);
                    setState({
                      ...state,
                    });
                  }}
                />
                {state.onset["calenderIconEnabled"] && (
                  <div
                    id={`${parent_id}_cheif_form_calenderIcon_div`}
                    style={styles.calenderIconstyles}
                    onClick={handleClick}
                  >
                    <Text id={`${parent_id}_cheif_form_calenderIcon_text`}>
                      <CalendarIcon
                        id={`${parent_id}_cheif_form_calenderIcon`}
                        style={{ marginTop: 4 }}
                      />
                    </Text>
                  </div>
                )}
              </div>
            </Col>
          )}
          {state.abatement?.isCalenderEnabled === true ? (
            <Col
              id={`${parent_id}_cheif_form_Abatement_col`}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              inLineStyles={styles.colPadding}
            >
              <Row id={`${parent_id}_cheif_form_Abatement_row`}>
                <Col
                  id={`${parent_id}_cheif_form_Abatement_sub_col`}
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  xl={9}
                  style={{ padding: 0 }}
                >
                  <Text
                    id={`${parent_id}_cheif_form_Abatement_text`}
                    inLineStyles={styles.labelStyle}
                  >
                    {"Abatement"}
                  </Text>
                  <TextInput
                    id={`${parent_id}_cheif_form_Abatement_textinput`}
                    label={""}
                    variant={"outlined"}
                    hoverColor={UIColor.lineBorderFill.color}
                    style={{ width: "218px" }}
                    placeholder=""
                    value={state?.abatement?.currentDate}
                    disabled
                  />
                </Col>
                <Col
                  id={`${parent_id}_cheif_form_Abatement_avatar_col`}
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  style={{
                    padding: "0px",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <div id={`${parent_id}_cheif_form_Abatement_avatar_div`}>
                    <Avatar
                      id={`${parent_id}_cheif_form_Abatement_avatar`}
                      style={{ width: "40px", height: "40px", margin: 0 }}
                      onClick={() => calenderDataClear1()}
                    >
                      <CloseIcon
                        id={`${parent_id}_cheif_form_Abatement_CloseIcon`}
                      />
                    </Avatar>
                  </div>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col
              id={`${parent_id}_cheif_form_Abatement_calenderIconEnabled_col`}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              inLineStyles={styles.colPadding}
            >
              <Text
                id={`${parent_id}_cheif_form_Abatement_calenderIconEnabled_text`}
                inLineStyles={styles.labelStyle}
              >
                {"Abatement"}
              </Text>
              <div
                id={`${parent_id}_cheif_form_Abatement_div`}
                style={{ display: "flex" }}
              >
                <div style={{ width: 40, marginRight: 8 }}>
                  <TextInput
                    id={`${parent_id}_cheif_form_Abatement_textinput`}
                    label={""}
                    variant={"standard"}
                    placeholder="05"
                    maxLength={2}
                    hoverColor={UIColor.lineBorderFill.color}
                    value={state?.abatement?.day}
                    onChange={(event) => {
                      if (!isIntegerValue(event.target.value)) return;
                      state.abatement["day"] = event.target.value;
                      setState({
                        ...state,
                      });
                    }}
                  />
                </div>
                <TapSelect
                  id={`${parent_id}_cheif_form_Abatement_calenderIconEnabled_tapselect`}
                  label={""}
                  inLineStyles={styles.tapSelectStyle}
                  selectedBorderColor={UIColor.secondaryButtonColor.color}
                  selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                  selectedTextColor={"#fff"}
                  options={onserOption}
                  defaultSelected={state?.abatement?.freq}
                  onChange={(value) => {
                    state.abatement["freq"] = value;
                    state.abatement["calenderIconEnabled"] = value?.value
                      ? false
                      : true;
                    setState({
                      ...state,
                    });
                  }}
                />
                {state.abatement["calenderIconEnabled"] && (
                  <div
                    id={`${parent_id}_cheif_form_CalendarIcon_div`}
                    style={styles.calenderIconstyles}
                    onClick={handleClick1}
                  >
                    <Text id={`${parent_id}_cheif_form_CalendarIcon_text`}>
                      <CalendarIcon
                        id={`${parent_id}_cheif_form_CalendarIcon`}
                        style={{ marginTop: 4 }}
                      />
                    </Text>
                  </div>
                )}
              </div>
            </Col>
          )}
          {/* Date components */}
          <Col
            id={`${parent_id}_cheif_form_modifying_factor_col`}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
          >
            <TextInput
              id={`${parent_id}_cheif_form_modifying_factor_textinput`}
              label={" Modifying Factor"}
              name={"modifying_factor"}
              placeholder=""
              labelStyle={styles.labelStyle}
              style={styles.borderStyle}
              hoverColor={UIColor.lineBorderFill.color}
              value={state?.modifying_factor}
              onChange={(event) =>
                handleInputChange(event.target.name, event.target.value)
              }
            />
          </Col>

          <Col
            id={`${parent_id}_cheif_form_status_verification_col`}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
          >
            <SelectBox
              parent_id={"cheif_conplaints"}
              top_title={"Verification Status"}
              list={storeData?.Verification_masters?.data ?? []}
              placeholder={"Search here..."}
              value={state?.status_verification}
              onchange={(e, value) =>
                handleInputChange("status_verification", value)
              }
            />
          </Col>

          <Col
            id={`${parent_id}_cheif_form_clinicalStatus_col`}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
          >
            <SelectBox
              parent_id={"cheif_conplaints"}
              top_title={"Clinical Status"}
              requireStart
              list={storeData?.clinicalStatus_masters?.data ?? []}
              placeholder={"Search here..."}
              value={state?.clinicalStatus}
              onchange={(e, value) =>
                handleInputChange("clinicalStatus", value)
              }
              error={isErr && !state?.clinicalStatus ? true : false}
            />
            {/* <Select
                            showArrow
                            label={"Clinical Status"}
                            isRequired
                            placeholder={"Select..."}
                            options={storeData?.clinicalStatus_masters?.data ?? []}
                            labelStyle={styles.labelStyle}
                            inLineStyles={styles.borderStyle}
                             hoverColor={UIColor.lineBorderFill.color}
                            value={state?.clinicalStatus}
                            onChangeValue={(value) => handleInputChange("clinicalStatus", value?.value)}
                        /> */}
          </Col>

          <Col
            id={`${parent_id}_cheif_form_notes_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.colPadding}
          >
            <TextArea
              id={`${parent_id}_cheif_form_notes_textarea`}
              label={"Notes"}
              name={"notes"}
              placeholder={"Add Notes"}
              labelStyle={styles.labelStyle}
              style={styles.borderStyle}
              hoverColor={UIColor.lineBorderFill.color}
              value={state?.notes}
              onChange={(event) =>
                handleInputChange(event.target.name, event.target.value)
              }
              maxLength=""
            />
          </Col>
        </Row>
      </div>

      {/* footer  */}
      <Row
        id={`${parent_id}_cheif_form_button_row`}
        inLineStyles={{ background: "#fff", borderRadius: "10px" }}
      >
        <Col
          id={`${parent_id}_cheif_form_button_col`}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ margin: "10px 0px" }}
        >
          <Div
            id={`${parent_id}_cheif_form_buttons_div`}
            inLineStyles={styles.flexEnd}
          >
            <Button
              id={`${parent_id}_cheif_form_Cancel_button`}
              onClick={() => goBack()}
              variant={"text"}
              inLineStyles={styles.cancelBtn}
            >
              Cancel
            </Button>
            <Button
              id={`${parent_id}_cheif_form_Update_button`}
              variant={"outlined"}
              inLineStyles={styles.filledBtn}
              onClick={() => UpdateCC()}
            >
              Update
            </Button>
          </Div>
        </Col>
      </Row>
      {/* for status info */}
      <Popover
        open={Boolean(openStatus)}
        anchorEl={openStatus}
        onClose={handleStausClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: styles.muiPopovers }}
      >
        <InfoStatus
          parent_id={"cheif_complaints"}
          infoStatusprops={openStatus}
          openStausInfo={openStausInfo}
          handleStatusInactive={handleStatusInactive}
          handleStatusActive={handleStatusActive}
          state={state}
          statusList={storeData?.clinicalStatus_masters?.data ?? []}
          currentStatus={state?.clinicalStatus?.label}
          handleChange={handleInputChange}
        />
      </Popover>
      {/* calender UI */}
      <Popover
        id={Boolean(open) ? "simple-popover" : undefined}
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* <Calendar
                    id={`${parent_id}_cheif_form_Calendar`}
                    maxDate={new Date()}
                    allowPartialRange
                    onClickMonth={(value, event) => {
                        handleClose(false);
                    }}
                    onClickYear={(value, event) => {
                        handleClose(false);
                    }}
                    onClickWeekNumber={(value, event) => {
                        handleClose(false);
                    }}
                    onChange={(value, event) => {
                        calenderChange(value);
                    }}
                    className={"pc_regular"}
                /> */}
        <CustomDatePicker
          maxDate={new Date()}
          handleChange={(value) => calenderChange(value)}
        />
      </Popover>

      <Popover
        id={Boolean(open1) ? "simple-popover" : undefined}
        open={Boolean(open1)}
        anchorEl={anchorEl1}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* <Calendar
                    id={`${parent_id}_cheif_form_Calendar`}
                    maxDate={new Date()}
                    allowPartialRange
                    onClickMonth={(value, event) => {
                        handleClose(false);
                    }}
                    onClickYear={(value, event) => {
                        handleClose(false);
                    }}
                    onClickWeekNumber={(value, event) => {
                        handleClose(false);
                    }}
                    onChange={(value, event) => {
                        calenderChange1(value);
                    }}
                    className={"pc_regular"}
                /> */}
        <CustomDatePicker
          maxDate={new Date()}
          handleChange={(value) => calenderChange1(value)}
        />
      </Popover>
      {/* calender ui end */}
    </Div>
  );
};

const optionsList = {
  name: [{ value: "87803-Swelling", label: "87803-Swelling" }],
};

export default withAllContexts(CheifComplaintsForm);
