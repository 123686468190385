import React, { useContext, useEffect, useState } from "react";
import {
  Div,
  Paper,
  Select,
  TextInput,
  Upload,
  H6,
  Col,
  Row,
  Text,
  Icons,
  Image,
  Button,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../../hoc/withAllContexts";
// import { uploadImage } from "../../../utils";
import {
  AlertProps,
  uploadImage,
  readFileAsBase64,
  ToBase64,
  getDaysInMonth,
} from "../../../utils";
import moment from "moment";
import { UIColor } from "../../../themes/theme";
import CustomInputDatePicker from "../../../components/customDatePicker/inputDatePicker";
import { setData, getSize, handleDelete, FlterOptions } from "./handlers";
import trashIcon from "../../../assets/img/svg/icons8-trash.svg";
import uploadIcon from "../../../assets/img/svg/uploadBlue.svg";
import FileUploadIcon from "../../../assets/img/svg/icons8-file.svg";
import FileViewer from "react-file-viewer";
import { Grid, Typography, Dialog, makeStyles } from "@material-ui/core";
import { AlertContext } from "../../../context";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundColor: "transparent",
  },
  dialogRoot: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
    zIndex: "999999 !important",
  },
}));

const PatientIdentification = (props) => {
  const classes = useStyles();
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorborderStyle: {
      borderRadius: 8,
      borderColor: "red",
    },
    borderStyleDisable: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
      background: "#F0F0F0",
      // paddingBottom: 10,
    },
    denseBottom: {
      paddingBottom: 10,
    },
  };

  const {
    labelStyle,
    borderStyle,
    errorborderStyle,
    borderStyleDisable,
    denseBottom,
  } = styles;

  const [limited, setLimited] = useState(false);
  const [parent_id, SetParent_id] = useState(props);
  const [patientData, setPatientData] = useState({ ...props?.patientData });

  const [selectedIds, setSelectedIds] = useState([]);

  const { identification, statusDetails } = props.patientData; //props?.[props?.saveto];
  const [fileUpload, setFileUpload] = useState("");
  const [fileRead, setFileRead] = useState({});
  const alert = useContext(AlertContext);
  const [idenDefaultVal, setidenDefaultVal] = useState({});
  // useEffect(() => {
  //   setPatientData({ ...props?.patientData });
  // },[props.editClick]);

  const handleLimit = () => {
    setLimited(!limited);
  };
  const FlterOptions2 = (props, type) => {
    //let filteredArray = props.filter(obj => obj.hasOwnProperty('ALTERNATEIDTYPE'));
    let list = [];
    // if (props.Type === type) {
    let data = props.Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?.display,
        _id: data[index]?._id,
      };

      list.push(datatemp);
    }
    //}

    return list;
  };
  // paneer bro code
  // const FlterOptions3 = (props, type) => {
  //   let filteredArray = props.filter((obj) => obj.hasOwnProperty([type]));
  //   let list = [];
  //   let data = filteredArray[0]?.[type].Value;
  //   for (let index in data) {
  //     var datatemp = {
  //       value: data[index]?.display,
  //       _id: data[index]?._id,
  //     };

  //     list.push(datatemp);
  //   }

  //   return list;
  // };
  ///

  const FlterOptions3 = (props, type, currentIndex) => {
    let filteredArray = props?.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      if (
        currentIndex !== undefined &&
        selectedIds[currentIndex] === data[index]._id
      ) {
        var datatemp = {
          value: data[index]?.display,
          _id: data[index]?._id,
        };
        list.push(datatemp);
      } else if (!selectedIds.includes(data[index]._id)) {
        var datatemp = {
          value: data[index]?.display,
          _id: data[index]?._id,
        };
        list.push(datatemp);
      }
    }
    return list;
  };

  // export const newRowAdd = (props) => {
  //   let patientData = props[props.saveto];
  //   patientData.identification.push({});
  //   patientData[props.setTo]({
  //     ...patientData,
  //   });
  // };

  const newRowAdd = (props) => {
    let patientD = props;
    patientD?.identification.push({});
    setPatientData({ ...patientD });
    props?.setPatientData({ ...patientD });
    // patientData[props.setTo]({
    //   ...patientData,
    // });
  };
  const handleDelete2 = (patientDataprops, i) => {
    let patientD = patientDataprops;
    if (patientD?.identification?.length <= 1) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Atleast one identification should be entered",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else {
      patientD?.identification.splice(i, 1);
      setPatientData({ ...patientD });
      props?.setPatientData({ ...patientD });
    }
  };

  const setDataInCloseDoc = (name, value, index, limit) => {
    if (limit) {
      if (limit < value?.length) {
        return false;
      }
    }
    if (name === "doc") {
      patientData.identification[index][name] = "";
    }
    patientData.identification[index][name] = value;
    if (patientData.identification[index].error) {
      patientData.identification[index].error[name] = false;
    }
    setPatientData({
      ...patientData,
    });
  };

  const nricvalidation = (value, newData, index, name) => {
    //yymmdd
    //yy validation "00" and "99,"
    let err = false;
    if (value?.length <= newData?.identification?.[index]?.[name]?.length) {
      return { value, err };
    } else {
      let msg = "";
      if (/^[0-9-]+$/.test(value)) {
        err = false;
        if (value?.length >= 0 && value.length <= 2) {
          err = false;
          if (/^([0-9]{1,2})$/.test(value.substring(0, 2))) {
            err = false;
          } else {
            err = true;
            value = value.substring(0, value.length - 1);
            msg = "YY should between 00 and 99";
          }
        }
        if (value.length === 4) {
          if (/^(0[1-9]|1[0-2]{1,2})/.test(value.substring(2, 4))) {
            err = false;
          } else {
            err = true;
            value = value.substring(0, 2);
            msg = "mm should between 01 and 12";
          }
        }
        if (value.length === 6) {
          let nodays = getDaysInMonth(2023, Number(value.substring(2, 4)) - 1);
          if (value.substring(4) <= nodays) {
            err = false;
            // return value;
          } else {
            err = true;
            value = value.substring(0, 4);
            msg = `dd for  should be less than ${nodays}`;
          }
        }
        if (value?.length >= 8 && value?.length <= 9) {
          if (/^[0-9]{1,2}$/.test(value.substring(7))) {
            err = false;
          } else {
            err = true;
            value = value.substring(0, value?.length - 1);
            msg = `Numbers only allowed for next ${9 - value?.length} places`;
          }
        }
        if (value?.length >= 10 && value?.length <= 14) {
          if (/^[0-9]{1,4}$/.test(value.substring(10))) {
            err = false;
          } else {
            err = true;
            value = value.substring(0, value?.length - 1);
            msg = `numbers only allowed for next ${14 - value?.length} places`;
          }
        }
      } else {
        err = true;
        value = newData?.identification?.[index]?.[name] || "";
        msg = `numbers only allowed`;
      }
      // adding hypen
      [6, 9].forEach((v) => {
        if (value?.length === v) {
          value = value + "-";
        }
      });

      // length should be 14
      if (value?.length > 14) {
        value = newData.identification[index][name];
      }
      if (msg) {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: msg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
      return { value, err };
    }
  };
  const setData = (props, name, value, index, limit) => {
    setPatientData((prevData) => {
      let newData = { ...prevData };
      let id_number =
        name === "id_number" &&
        identification?.[0]?.id_type?._id ===
          process.env.REACT_APP_PATIENT_ID_TYPE_NRIC;
      let err = false;
      if (name === "id_type") {
        const updatedSelectedIds = [...selectedIds];
        updatedSelectedIds[index] = value._id;
        setSelectedIds(updatedSelectedIds);
      }
      if (id_number) {
        let data = nricvalidation(value, newData, index, name);
        value = data?.value;
        err = data?.err;
      }
      newData.identification[index][name] = value;
      if (newData.identification[index].error) {
        newData.identification[index].error[name] = false;
      }
      if (id_number && err) {
        let indentifi = newData.identification?.[index];
        newData.identification[index] = {
          ...indentifi,
          error: {
            id_number: true,
          },
        };
      }
      return newData;
    });
    setidenDefaultVal({});
    // setPatientData({ ...patientData });
    props?.setPatientData({ ...patientData });
  };

  const setDataUpload = async (props, name, value, index) => {
    if (value.size > 10 * 1024 * 1024) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "The uploaded file is larger than 1MB. Please select a smaller file.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
    if (value) {
      const allowedTypes = [
        "application/pdf",
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedTypes.includes(value.type)) {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Only PDF, JPG, and Word doc files are allowed.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return;
      }
    }
    var UploadedFiles = [];
    let patientData = props;
    setFileUpload(value);
    let base64string = await readFileAsBase64(value);
    UploadedFiles = await uploadImage([value]);
    const dat = UploadedFiles?.map((v) => {
      return {
        fileid: v?.fileid,
        filetype: v?.filetype,
        objectid: v?.objectid,
        filename: v?.fileName,
        base64string: base64string,
      };
    });
    patientData.identification[index][name] = dat[0];
    if (patientData.identification[index].error) {
      patientData.identification[index].error[name] = false;
    }
    // patientData[props.setTo]({
    //   ...patientData,
    // });
    setPatientData({ ...patientData });
    props?.setPatientData({ ...patientData });
  };
  // useEffect(() => {
  //   setPatientData({...props?.patientData})
  // }, [props?.cleared]);

  // useEffect(() => {
  //   setPatientData({...props?.patientData})
  // }, [props?.clear]);
  // useEffect(()=>{
  //   setPatientData({...props?.patientData})
  // },[props?.states])

  // useEffect(() => {
  //   setidenDefaultVal({});
  //   if (!props?.isModify && !props?.isGoBack) {
  //     var datatemp;
  //     props?.allMasters?.map((val) => {
  //       if (val?.ALTERNATEIDTYPE?.Type == "ALTERNATEIDTYPE") {
  //         let data = val?.ALTERNATEIDTYPE?.Value;
  //         data?.map((v) => {
  //           if (v?._id === "CodingMaster/10524") {
  //             datatemp = {
  //               value: v?.display,
  //               _id: v?._id,
  //             };
  //             setidenDefaultVal(datatemp);
  //             setPatientData((prevData) => {
  //               let newData = { ...prevData };
  //               const updatedSelectedIds = [...selectedIds];
  //               updatedSelectedIds[0] = datatemp?._id;
  //               setSelectedIds(updatedSelectedIds);
  //               newData.identification[0]["id_type"] = datatemp;
  //             });
  //           }
  //         });
  //       }
  //     });
  //     setPatientData({ ...patientData });
  //   }
  // }, [props]);

  useEffect(() => {
    if (props?.patientData !== patientData) {
      setPatientData(props?.patientData);
      let fileuploadList =[]
      props?.patientData?.identification?.map((v,i)=>{
          let url = v?.doc?.url;
          fileuploadList.push(v?.doc);
      })
      setFileUpload(fileuploadList);
    }
  }, [props?.patientData]);
  const createFile = async (url, name, type) => {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type,
    };
    const file = new File([data], name, metadata);
    return {
      file,
      size: data.size,
    };
  };

const viewFile = async (index) => {
    let data = "";
    if(props?.isModify || props?.isViewMode){
      fileUpload?.map((v,i)=>{
        if(index === i){
         setFileRead({
          openFile: v?.url,
          viewFileName: v?.filename,
          fileType: v?.filetype,//fileUpload.type.split("/")[1],
          open: true,
        });
        }
      })
    }
    else {
    data = await ToBase64(fileUpload);
    setFileRead({
      openFile: data,
      viewFileName: fileUpload?.name,
      fileType: fileUpload?.type.split("/")[1],
      open: true,
    });
    }
   
  };
  const handleClose = () => {
    setFileRead({
      openFile: null,
      fileType: null,
      open: false,
    });
  };

  return (
    <Div
      id={`${parent_id}_patientidentification_parent_div`}
      className="pi-root"
    >
      <Paper
        id={`${parent_id}_patientidentification_parent_paper`}
        className="pi-paper"
      >
        <Div id={`${parent_id}_patientidentification_header_label_div`}>
          <H6
            id={`${parent_id}_patientidentification_label_h6`}
            className="pi-title"
          >
            {props?.saveto === "nextOfKin"
              ? "NEXT OF KIN IDENTIFICATION"
              : "PATIENT IDENTIFICATION"}
          </H6>
          {!props?.isViewMode && (
            <Text
              id={`${parent_id}_patientidentification_addnew_label_text`}
              onClick={() => newRowAdd(patientData)}
              className="pi-addbtn"
            >
              + Add New
            </Text>
          )}
        </Div>
        {identification
          ?.slice(0, limited ? identification?.length : 1)
          ?.map((val, i) => (
            <Row id={`${parent_id}_${i}_patientidentification_map_row`}>
              <Col
                id={`${parent_id}_${i}_patientidentification_ip_type_col`}
                md={2}
                lg={2}
                sm={6}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <Select
                  id={`${parent_id}_${i}_patientidentification_id_type_select`}
                  //value={val?.id_type?.value ?? null}
                  // value={val?.id_type?.value ? val?.id_type?.value: idenDefaultVal?.value }
                  //value={i == 0 && !props?.isModify ? idenDefaultVal?.value : (val?.id_type?.value ? val?.id_type?.value : "") }
                  value={
                    (i == 0 && !props?.isModify) || !props?.isViewMode
                      ? idenDefaultVal?.value
                        ? idenDefaultVal?.value
                        : val?.id_type?.value
                      : val?.id_type?.value
                      ? val?.id_type?.value
                      : ""
                  }
                  onChangeValue={(e) => setData(patientData, "id_type", e, i)}
                  isRequired={true}
                  // helperText={(val?.error && val?.error['id_type']) ? 'Is required' : ''}
                  disabled={
                    props?.isViewMode ||
                    statusDetails?.status1 === "Inactive" ||
                    statusDetails?.status === "Deceased"
                      ? true
                      : false
                  }
                  error={val?.error && val?.error["id_type"] ? true : false}
                  options={FlterOptions3(
                    props?.allMasters,
                    "ALTERNATEIDTYPE",
                    i
                  )}
                  label="ID Type"
                  placeholder="select..."
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={
                    val?.error && val?.error["id_type"]
                      ? errorborderStyle
                      : borderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
              <Col
                id={`${parent_id}_${i}_patientidentification_id_number_col`}
                md={2}
                lg={2}
                sm={6}
                xs={12}
                inLineStyles={{ padding: 10 }}
              >
                <TextInput
                  id={`${parent_id}_${i}_patientidentification_id_number_textinput`}
                  // helperText={(val?.error && val?.error['id_number']) ? 'Is required' : ''}
                  error={val?.error && val?.error["id_number"] ? true : false}
                  value={val?.id_number ? val?.id_number : ""}
                  onChange={(e) => {
                    let pattern = /^[0-9]+$/i;
                    let result = pattern.test(e.target.value);
                    if (
                      identification?.[0]?.id_type?._id ===
                      process.env.REACT_APP_PATIENT_ID_TYPE_NRIC
                    ) {
                      setData(patientData, "id_number", e.target.value, i, 14);
                    } else {
                      if (result) {
                        setData(
                          patientData,
                          "id_number",
                          e.target.value,
                          i,
                          20
                        );
                      } else if (e.target.value === "") {
                        setData(patientData, "id_number", "", i, 20);
                      }
                    }
                  }}
                  label="ID Number"
                  isRequired={true}
                  disabled={
                    props?.isViewMode || val?.id_type?.value ? false : true
                    //false
                    //!val?.id_type?.value ||
                    //statusDetails?.status1 === "Inactive" ||
                    //statusDetails?.status === "Deceased"
                  }
                  // isRequired={val?.id_type ? true : false}
                  placeholder={"Id number"}
                  labelStyle={labelStyle}
                  style={
                    !val?.id_type?.value
                      ? borderStyleDisable
                      : val?.error && val?.error["id_number"]
                      ? errorborderStyle
                      : borderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
              <Col
                id={`${parent_id}_${i}_patientidentification_expirydate_col`}
                md={2}
                lg={2}
                sm={6}
                xs={12}
                inLineStyles={{ padding: 6 }}
              >
                {/* <TextInput
                          id={`${parent_id}_${i}_patientidentification_expirydate_calender_textinput`}
                          max={moment().startOf("day").add(100, "years").format("YYYY-MM-DD")}
                          min={moment().startOf("day").format("YYYY-MM-DD")}
                          // onKeyPress={function (event) {
                          //     event.preventDefault();
                          //     return
                          // }} 
                          helperText={'Invalid Expiry Date'}
                          disabled={(!val?.id_type?.value || (statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased"))}
                          error={val.expiry_date && ((moment(val.expiry_date, "YYYY-MM-DD").diff(moment().startOf("day"), 'years') > 100) || (moment().startOf("day").unix() > moment(val.expiry_date, "YYYY-MM-DD").unix()))}
                          value={val.expiry_date ? val.expiry_date : ""}
                          onChange={(e) => this.setData("expiry_date", e.target.value, i)}
                          label="Expiry Date"
                          type="date"
                          dateIcon="images/icons8-calendar-grey.svg"
                          labelStyle={labelStyle} 
                          style={ !val?.id_type?.value ? borderStyleDisable : borderStyle }
                          hoverColor={UIColor.primaryColor.color}
                      />  */}
                <CustomInputDatePicker
                  disabled={
                    !val?.id_type?.value ||
                    statusDetails?.status1 === "Inactive" ||
                    statusDetails?.status === "Deceased"
                  }
                  placeholderText="dd/mm/yyyy"
                  // minDate={moment().startOf("day").format("YYYY-MM-DD")}
                  // maxDate={moment().startOf("day").add(100, "years").format("YYYY-MM-DD")}
                  minDate={new Date()}
                  maxDate={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() + 100)
                    )
                  }
                  handleChange={(value) =>
                    setData(patientData, "expiry_date", value, i)
                  }
                  selectedDate={
                    val.expiry_date ? new Date(val.expiry_date) : ""
                  }
                  // className={denseBottom}
                  inputField={
                    <TextInput
                      id={`${parent_id}_${i}_patientidentification_expirydate_calender_textinput`}
                      label="Expiry Date"
                      isRequired={false}
                      placeholder="dd/mm/yyyy"
                      dateIcon="images/icons8-calendar-grey.svg"
                      labelStyle={labelStyle}
                      value={
                        val.expiry_date
                          ? moment(new Date(val.expiry_date)).format(
                              "DD/MM/YYYY"
                            )
                          : ""
                      }
                      style={
                        !val?.id_type?.value
                          ? borderStyleDisable
                          : val?.error && val?.error?.expiry_date
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                      helperText={"Invalid Expiry Date"}
                      error={
                        val?.error && val?.error?.expiry_date ? true : false
                      }
                      // error={true}
                      disabled={
                        !val?.id_type?.value ||
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased" ||
                        props?.isViewMode
                      }
                    />
                  }
                />
              </Col>

              {/* {props.saveto !== "nextOfKin" && (
                  <Col
                    id={`${parent_id}_${i}_patientidentification_id_source_col`}
                    md={2}
                    lg={2}
                    sm={6}
                    xs={12}
                    inLineStyles={{ padding: 10 }}
                  >
                    <Select
                      id={`${parent_id}_${i}_patientidentification_id_source_select`}
                      value={val?.id_source?.value ?? null}
                      // helperText={(val?.error && val?.error['id_source']) ? 'Is required' : ''}
                      error={val?.error && val?.error["id_source"] ? true : false}
                      onChangeValue={(e) =>
                        setData(patientData, "id_source", e, i)
                      }
                      options={FlterOptions3(props?.allMasters, "RELATIONSHIP")}
                      showArrow
                      isRequired={true}
                      disabled={
                        !val?.id_type?.value ||
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                      }
                      //  isRequired={val?.id_type ? true : false}
                      label="ID Source"
                      placeholder="select..."
                      labelStyle={labelStyle}
                      inLineStyles={
                        val?.error && val?.error["id_source"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                    />
                  </Col>
                )} */}
              <Col
                id={`${parent_id}_${i}_patientidentification_sub_parent_col`}
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="pi-upload-root"
                inLineStyles={{
                  padding: 20,
                  alignItems: !(val?.doc && val.doc?._id + "" != "")
                    ? null
                    : "baseline",
                }}
              >
                {!(val?.doc && val.doc?._id + "" != "") ? (
                  <Div
                    id={`${parent_id}_${i}_patientidentification_document_div`}
                    style={{
                      display: "flex",
                      alignItems: "end",
                      marginBottom: "1%",
                    }}
                  >
                    {!props.isViewMode && (
                      <Upload
                        id={`${parent_id}_${i}_patientidentification_document_upload`}
                        label="Upload Document"
                        className="pi-upload-btn"
                        handleChange={(e, value) =>
                          setDataUpload(patientData, "doc", value, i)
                        }
                        labelColor={UIColor.secondaryButtonColor.color}
                        iconColor={UIColor.secondaryButtonColor.color}
                        iconSize={14}
                        imgUrl={uploadIcon}
                        labelStyle={{
                          fontSize: 14,
                          fontFamily: "pc_medium !important",
                        }}
                        disabled={
                          statusDetails?.status1 === "Inactive" ||
                          statusDetails?.status === "Deceased"
                            ? true
                            : false
                        }
                      />
                    )}
                    <Div
                      id={`${parent_id}_${i}_patientidentification_document_upload_div`}
                      style={{
                        width: 15,
                        cursor: "pointer",
                      }}
                    >
                      {!props.isViewMode && (
                        <Image
                          id={`${parent_id}_${i}_patientidentification_delete_image`}
                          src={trashIcon}
                          alt="delete"
                          onClick={() => handleDelete2(patientData, i)}
                        />
                      )}
                    </Div>
                  </Div>
                ) : (
                  <Div
                    id={`${parent_id}_${i}_patientidentification_upload_doc_div`}
                    className="pi-upload-doc"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <Image
                        id={`${parent_id}_${i}_patientidentification_upload_file_image`}
                        className="pi-upload-doc-img"
                        src={FileUploadIcon}
                        alt="file"
                      />

                      <Text
                        id={`${parent_id}_${i}_patientidentification_upload_doc_name_text`}
                        className="pi-upload-doc-name"
                      >
                        {(val?.doc?.filename?.length > 15
                          ? `${val?.doc?.filename?.slice(0, 15)}...`
                          : val?.doc?.filename) ?? ""}
                      </Text>
                    </div>
                    <div style={{ display: "flex" }}>
                      &nbsp;
                      {/* <Text className="pi-upload-doc-size">{this.getSize(val?.doc?.size)}</Text> */}
                      <Button
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginLeft: "2px",
                          marginRight: "4px",
                        }}
                        onClick={() => viewFile(i)}
                      >
                        view
                      </Button>
                      <Dialog
                        open={fileRead?.open}
                        onClose={handleClose}
                        className={classes.dialogRoot}
                        id="UMHit_13"
                        fullScreen
                        PaperProps={{ classes: { root: classes.dialogPaper } }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "100vh",
                            margin: "auto",
                          }}
                        >
                          <Grid container style={{ background: "transparent" }}>
                            <Grid item style={{ width: "50%" }}>
                              <Grid>
                                <Typography
                                  id="UMHit_14"
                                  style={{
                                    color: "black",
                                    marginTop: "9px",
                                    visibility: "hidden",
                                  }}
                                >
                                  {fileRead?.viewFileName}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                width: "50%",
                                paddingRight: "30px",
                                paddingTop: "16px",
                              }}
                            >
                              <div onClick={() => handleClose()}>
                                <Typography
                                  style={{
                                    color: "#fff",
                                    cursor: "pointer",
                                    fontSize: "10px",
                                    fontWeight: 600,
                                  }}
                                >
                                  CLOSE X
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid id="UMHit_17" className="openFile-parent">
                            <FileViewer
                              id="UMHit_16"
                              fileType={fileRead?.fileType}
                              filePath={fileRead?.openFile}
                            />
                          </Grid>
                        </div>
                      </Dialog>
                      {!props.isViewMode && (
                        <Icons
                        id={`${parent_id}_${i}_patientidentification_upload_doc_close_icons`}
                        key={"1"}
                        className="pi-upload-doc-close"
                        fontIcon={"close"}
                        ariaHidden={"true"}
                        style={{
                          marginLeft: "2px",
                          marginRight: "4px",
                        }}
                        // onClick={() => setDataDelete(props, "doc", i)}
                        onClick={() => setDataInCloseDoc("doc", null, i)}
                        size={"small"}
                      ></Icons>
                      )}
                    </div>
                  </Div>
                )}

                {!(val?.doc && val.doc?._id + "" != "") ? null : (
                  <Div
                    id={`${parent_id}_${i}_patientidentification_trash_div`}
                    style={{
                      width: 15,
                      cursor: "pointer",
                      marginLeft: "10px",
                    }}
                  >
                   {!props.isViewMode && (
                    <Image
                      id={`${parent_id}_${i}_patientidentification_trash_image`}
                      src={trashIcon}
                      alt="delete"
                      onClick={() => handleDelete2(patientData, i)}
                    />
                  )}
                  </Div>
                )}

                {identification?.length > 1 && i === 0 && (
                  <Text
                    id={`${parent_id}_${i}_patientidentification_show_showless_label_text`}
                    className="pc_medium"
                    style={{
                      color: "#F58B00",
                      marginLeft: 15,
                      cursor: "pointer",
                      fontSize: "14px",
                      marginTop: 20,
                    }}
                    onClick={() => handleLimit()}
                  >
                    {limited
                      ? "Show Less"
                      : `Show +${identification?.length - 1}`}
                  </Text>
                )}
              </Col>
            </Row>
          ))}
      </Paper>
    </Div>
  );
};

export default withAllContexts(PatientIdentification); //here withAllContexts
