import * as React from "react";
// import { makeStyles } from "@material-ui/core";

// const useStyle = makeStyles((theme) => ({
//   cls1: (props) => ({
//     fill: props.htmlColor,
//   }),
// }));

export const DirectConsultation = (props) => {
  // const classes = useStyle(props);
  return (
    // <SvgIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ? props.size : "12"}
      height={props.size ? props.size : "12"}
      viewBox="0 0 12 12"
    >
      <path
        id="icons8-person"
        class="cls-1"
        fill={props.color ? props.color : "#6f6f6f"}
        d="M9,3a2.667,2.667,0,1,0,2.667,2.667A2.667,2.667,0,0,0,9,3ZM6.874,10.6A13.817,13.817,0,0,0,3,12.333V15H15V12.333A13.816,13.816,0,0,0,11.126,10.6a2.645,2.645,0,0,1-4.253,0Z"
        transform="translate(-3 -3)"
      />
    </svg>
    // </SvgIcon>
  );
};
