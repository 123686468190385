import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import qdmstyles from "./styles1.json";
import { withRouter } from "react-router-dom";
import { AlertContext } from "../../../context";
import {
  Container,
  Div,
  H6,
  Divider,
  Upload,
  TextInput,
  TapSelect,
  Text,
  // DateTimepicker,
  MobileNumberWithCode,
  Button,
  TermsAndConditions,
  MonthCalendar,
  Popover,
  Icons,
} from "qdm-component-library";
import {
  checkError,
  AlertProps,
  ValidateEmail,
  getMobileNumber,
  getUserInfo,
  upsertPerson,
  mobileNumberValidation,
} from "../../../utils";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { UIColor } from "../../../themes/theme";

function AppointmentDrawerSigningPage(props) {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dob: {
      val: moment().format("DD-MM-YYYY"),
      milli: moment().valueOf(),
    },
    relation: {},
    emailId: "",
    mobileNo: {},
    opencalendar: false,
    loadingReadPerson: false,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  useEffect(() => {
    (async function () {
      await props.GET_COUNTRY_CODE_LIST();
      if (props.signUpData) {
        const data = props.signUpData;
        await setState({
          mobileNo: props.signUpData.mobileNo,
          firstName: data?.firstName,
          lastName: data?.lastName,
          middleName: data?.middleName,
          gender: data?.gender,
          dob: {
            val: props.signUpData.dob,
            milli: moment().valueOf(),
          },
          emailId: data?.emailId,
        });
      }
    })();
  }, []);

  const handleSignUp = async () => {
    if (
      // state?.mobileNo?.mobileNo?.length > 9 &&
      // state?.mobileNo?.countryData?.callingCodes &&
      state?.firstName &&
      state?.gender &&
      // state?.lastName &&
      (state.emailId ? ValidateEmail(state?.emailId) : true)
    ) {
      let res__ = mobileNumberValidation(
        state?.mobileNo?.mobileNo,
        state?.mobileNo?.countryData?.callingCodes
      );
      //  ;
      if (res__ === "Invalid Country code") {
        // props.alert.setSnack({
        // 	open: true,
        // 	severity: AlertProps.severity.error,
        // 	msg: res__,
        // 	vertical: AlertProps.vertical.top,
        // 	horizontal: AlertProps.horizontal.right,
        // });
        return;
      } else if (res__ === false) {
        // props.alert.setSnack({
        // 	open: true,
        // 	severity: AlertProps.severity.error,
        // 	msg: "Please enter valid mobile number",
        // 	vertical: AlertProps.vertical.top,
        // 	horizontal: AlertProps.horizontal.right,
        // });
        return;
      }

      const {
        firstName,
        lastName,
        middleName,
        dob,
        gender,
        emailId,
        mobileNo,
      } = state;
      if (!props.addNewMember) {
        await props.REGISTER_USERS({
          mobileNo: `+${state.mobileNo?.countryData?.callingCodes ?? "00"}${
            state.mobileNo.mobileNo
          }`,
          firstName,
          lastName,
          emailId,
        });
        const status = checkError(props.registerUsers);
        if (status.isError) {
          setState({
            error: status.errorMessage,
          });
          // context.setSnack({
          //   open: true,
          //   severity: AlertProps.severity.error,
          //   msg: status.errMsg,
          //   vertical: AlertProps.vertical.top,
          //   horizontal: AlertProps.horizontal.center,
          //   tone: true
          // })
        } else {
          const signUpData = {
            mobileNo,
            firstName,
            lastName,
            middleName,
            dob: dob.val,
            gender,
            emailId,
          };
          props.nextSection("addmemberotp", signUpData);
        }
      } else {
        const guestBookerAdd = {
          phone: getMobileNumber(state.mobileNo),
          dob: dob.val,
          firstName,
          lastName,
          gender,
          email: state.emailId,
        };
        const { data: res, error } = await upsertPerson(
          props.READ_PERSON,
          props.UPSERT_PERSON,
          guestBookerAdd
        );
        if (error) {
          // context.setSnack({
          //   open: true,
          //   severity: AlertProps.severity.error,
          //   msg: res,
          //   vertical: AlertProps.vertical.top,
          //   horizontal: AlertProps.horizontal.center,
          //   tone: true,
          // });
        } else {
          let personId = isNaN(Number(localStorage.getItem("idval")))
            ? 0
            : Number(localStorage.getItem("idval"));
          if (!personId) {
            const userInfo = getUserInfo();
            let mobileNo = userInfo.mobile;
            const persondata = await props.READ_PERSON({
              phone: mobileNo,
            });
            personId = persondata?.payload?.data?.id;
          }
          const relatedPersonInput = {
            ...guestBookerAdd,
            relationType: state.relation || "",
            relationPersonId: [personId || 0],
            personId: res.payload?.data?.id || 0,
          };
          await props.ADD_RELATED_PERSON(relatedPersonInput);
          // context.setSnack({
          //   open: true,
          //   severity: AlertProps.severity.success,
          //   msg: "Member Added Successfully",
          //   vertical: AlertProps.vertical.top,
          //   horizontal: AlertProps.horizontal.center,
          //   tone: true
          // })
          if (props.changeSideBar) {
            props.changeSideBar();
          }
          // const signUpData = {
          //   mobileNo,
          //   firstName,
          //   lastName,
          //   middleName,
          //   dob: dob.val,
          //   gender,
          //   emailId
          // }
          // props.nextSection('addmemberotp', signUpData)
          // alert('User Added')
        }
      }
    } else {
      setState({
        ...state,
        error: {
          mobileNo: state?.mobileNo?.mobileNo?.length > 9 ? false : true,
          callingCodes: state?.mobileNo?.countryData?.callingCodes
            ? false
            : true,
          firstName: state?.firstName?.length > 0 ? false : true,
          gender: state?.gender ? false : true,
          lastName: false,
          emailId: state?.emailId
            ? ValidateEmail(state?.emailId)
              ? false
              : true
            : false,
        },
      });
      // context.setSnack({
      //   open: true,
      //   severity: AlertProps.severity.error,
      //   msg: 'Please fill all the mandatory fields',
      //   vertical: AlertProps.vertical.top,
      //   horizontal: AlertProps.horizontal.center,
      //   tone: true
      // })
    }
  };
  const handlepapover = (e) => {
    if (state.opencalendar) {
      handlepapoverclose();
    } else {
      setState({ opencalendar: e.currentTarget });
    }
  };
  const handlepapoverclose = () => {
    setState({ ...state, opencalendar: null });
  };
  const { addNewMember, parent_id } = props;
  return (
    <div id={`${parent_id}_addmember_form_parent_div`}>
      <Container
        id={`${parent_id}_addmember_form_parent_container`}
        key={"0"}
        name={"container"}
        fluid={true}
        style={qdmstyles.Bc}
      >
        <Div
          id={`${parent_id}_addmember_form_title_div`}
          key={"0"}
          className={"qdm"}
          style={qdmstyles.de_da__c_cd}
        >
          <Div id={`${parent_id}_addmember_form_signup_div`} key={"0"}>
            <H6
              id={`${parent_id}_addmember_form_signup_h6`}
              key={"0"}
              name={addNewMember ? "Add Member" : "Sign Up as New Member"}
              style={qdmstyles.a_c_c_aed_fbeabbf}
            ></H6>
          </Div>

          <Div id={`${parent_id}_addmember_form_back_div`} key={"1"}>
            <Button
              id={`${parent_id}_addmember_form_back_buttom`}
              key={"0"}
              name={"Back"}
              style={qdmstyles.b__ae_ba_fcfdfc}
              onClick={() => props.nextSection("back")}
            ></Button>
          </Div>
        </Div>

        <Divider
          id={`${parent_id}_addmember_form_bottom_divider`}
          key={"1"}
        ></Divider>

        <Div
          id={`${parent_id}_addmember_form_upload_div`}
          style={{
            background: "#E0E0E0",
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Upload
            id={`${parent_id}_addmember_form_image_upload`}
            key={"2"}
            label={"Img"}
            variant={"classic"}
            showIcon={false}
            labelSize={"small"}
            labelColor={"#000"}
            iconColor={"#000"}
            iconSize={"16"}
            inLineStyles={qdmstyles.zRf}
          ></Upload>
          <div
            id={`${parent_id}_addmember_form_icon_div`}
            style={{
              position: "absolute",
              bottom: "-1px",
              right: "11px",
              padding: "3px 7px",
              background: "#fff",
              borderRadius: "50%",
              width: "28px",
              height: "28px",
            }}
          >
            <Icons
              id={`${parent_id}_addmember_form_camera_icons`}
              key={"0"}
              fontIcon="camera"
              size="small"
              style={{ backgroundColor: "#fff", color: "#6F6F6F" }}
            ></Icons>
          </div>
        </Div>

        <Div
          id={`${parent_id}_addmember_form_names_div`}
          key={"3"}
          className={"qdm"}
          style={qdmstyles.aa_cbc__a_eddc}
        >
          <TextInput
            id={`${parent_id}_addmember_form_first_label_textinput`}
            key={"0"}
            type={"text"}
            name={"text"}
            variant={"outlined"}
            helperTextType={"error"}
            maxLength={20}
            label={
              <div
                id={`${parent_id}_addmember_form_first_label_div`}
                style={{ fontSize: "13px", marginBottom: "-5px" }}
              >
                First name{" "}
                <span
                  id={`${parent_id}_addmember_form_first_star_span`}
                  style={{ color: "red" }}
                >
                  *
                </span>
              </div>
            }
            autoFocus={false}
            autoComplete={"off"}
            search={false}
            borderColor={"#9b9b9b"}
            elevation={"0"}
            placeholder={"Sudharsan"}
            size={"medium"}
            value={state.firstName}
            onChange={(e) => changeState("firstName", e.target.value)}
            style={qdmstyles.vQU}
            error={state?.error?.firstName}
          ></TextInput>

          <TextInput
            id={`${parent_id}_addmember_form_middle_label_textinput`}
            key={"1"}
            type={"text"}
            name={"text"}
            variant={"outlined"}
            maxLength={20}
            helperTextType={"error"}
            label={
              <div
                id={`${parent_id}_addmember_form_middle_label_div`}
                style={{ fontSize: "13px", marginBottom: "-5px" }}
              >
                Middle name
              </div>
            }
            autoFocus={false}
            autoComplete={"off"}
            search={false}
            borderColor={"#9b9b9b"}
            elevation={"0"}
            placeholder={"Narasimhan"}
            size={"medium"}
            value={state.middleName}
            onChange={(e) => changeState("middleName", e.target.value)}
            style={qdmstyles.BAIIv}
          ></TextInput>

          <TextInput
            id={`${parent_id}_addmember_form_last_label_textinput`}
            key={"2"}
            type={"text"}
            name={"text"}
            maxLength={20}
            variant={"outlined"}
            helperTextType={"error"}
            label={
              <div
                id={`${parent_id}_addmember_form_last_label_div`}
                style={{ fontSize: "13px", marginBottom: "-5px" }}
              >
                Last name
                {/* <span style={{ color: 'red' }}>*</span> */}
              </div>
            }
            autoFocus={false}
            autoComplete={"off"}
            search={false}
            borderColor={"#9b9b9b"}
            elevation={"0"}
            placeholder={"Pv"}
            size={"medium"}
            value={state.lastName}
            onChange={(e) => changeState("lastName", e.target.value)}
            style={qdmstyles.pyYgb}
            error={state?.error?.lastName}
          ></TextInput>
        </Div>
        <div
          id={`${parent_id}_addmember_form_gender_label_div`}
          style={qdmstyles.dda_e_dc_a_afcccbd}
        >
          Gender{" "}
          <span
            id={`${parent_id}_addmember_form_gender_start_span`}
            style={{ color: "red" }}
          >
            *
          </span>
        </div>
        <TapSelect
          id={`${parent_id}_addmember_form_gender_tapselect`}
          key={"4"}
          fullWidth={false}
          label={false}
          selectedBorderColor="#0071F2"
          selectedBackgroundColor="#0071F2"
          selectedTextColor="#fff"
          textSize="16"
          iconHeight="20"
          iconWidth="20"
          textColor="#000"
          options={[
            { value: "Male", label: "Male" },
            { value: "Female", label: "Female" },
            { value: "Others", label: "Others" },
          ]}
          value={{ value: state.gender, label: state.gender }}
          onChange={(e) => changeState("gender", e.value)}
          style={{ marginTop: "", paddingTop: "" }}
          defaultSelected={{
            value: state.gender,
            label: state.gender,
          }}
          inLineStyles={{
            borderColor: "#E0E0E0",
            fontSize: "14px",
            fontFamily: "'pc_regular' !important",
          }}
          // style={qdmstyles.SREcn}
        ></TapSelect>
        {state.error?.gender && (
          <div id={`${parent_id}_addmember_form_pleaseselect_label_div`}>
            <Text
              id={`${parent_id}_addmember_form_pleaseselect_label_text`}
              style={{ color: "red", fontSize: 14 }}
            >
              {"Please select the gender"}
            </Text>
          </div>
        )}
        {/* <Text
            key={'5'}
            name={
              <div style={{ fontSize: '13px',marginBottom:"-6px" }}>
                DOB <span style={{ color: 'red' }}>*</span>
              </div>
            }
            style={qdmstyles.dda_e_dc_a_afcccbd}
          ></Text>

          <DateTimepicker
            key={'6'}
            type={'date'}
            placeholder={'please select'}
            style={qdmstyles.usYVQ}
            value={state.dob}
            onChangedate={e => changeState('dob', e.target.value)}
          ></DateTimepicker> */}
        <Text
          id={`${parent_id}_addmember_form_dob_label_text`}
          style={{ fontSize: "12px", color: "#6f6f6f" }}
        >
          DOB <span style={{ color: "red" }}>*</span>
        </Text>
        <Div
          id={`${parent_id}_addmember_form_calender_div`}
          style={{
            border: "1px solid #E0E0E0",
            padding: "10px",
            height: "40px",
            width: "100%",
            fontSize: "14px",
            borderRadius: "4px",
            background: "transparent",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            marginBottom: "8px",
          }}
        >
          <Text
            id={`${parent_id}_addmember_form_calender_val_text`}
            style={{ color: "#000" }}
          >
            {state.dob.val}
          </Text>
          <img
            id={`${parent_id}_addmember_form_calender_image`}
            alt="loding"
            src="/images/icons8-calendar.svg"
            onClick={(e) => handlepapover(e)}
            style={{
              backgroundColor: "",
              color: "",
              cursor: "pointer",
              borderLeft: "1px solid #E0E0E0",
              paddingLeft: "10px",
            }}
          />
          <Popover
            style={{
              transform: "unset",
              position: "absolute",
              width: "320px",
              top: "30%",
              zIndex: "10",
            }}
            open={state.opencalendar}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={() => handlepapoverclose()}
          >
            <MonthCalendar
              id={`${parent_id}_addmember_form_calender_mothcalender`}
              style={{ color: "#000" }}
              onChange={(e) => {
                const milli = e.getTime();
                const oldVal = state.dob.milli;
                const oldDate = moment(oldVal).format("DD");
                const newDate = moment(milli).format("DD");
                const date = {
                  val: moment(e).format("DD-MM-YYYY"),
                  milli,
                };
                changeState("dob", date);
                if (oldDate !== newDate || milli === oldVal) {
                  handlepapoverclose();
                }
              }}
            />
          </Popover>
        </Div>
        <Text
          id={`${parent_id}_addmember_form_mobile_number_text`}
          key={"7"}
          name={
            <span
              id={`${parent_id}_addmember_form_mobile_number_span`}
              style={{ fontSize: "13px" }}
            >
              Enter your Mobile number{" "}
              <span
                id={`${parent_id}_addmember_form_mobile_star_span`}
                style={{ color: "red" }}
              >
                *
              </span>
            </span>
          }
          style={qdmstyles.dda_e_dc_a_afcccbd}
        ></Text>

        <MobileNumberWithCode
          id={`${parent_id}_addmember_form_mobilenumberwithcode`}
          key={"8"}
          type={"number"}
          label={"Mobile Number"}
          borderColor={"#CACACA"}
          maxLength={30}
          borderRadius={"6"}
          disabled={false}
          size={"medium"}
          style={qdmstyles.a__ef__bad}
          value={state.mobileNo.mobileNo}
          defaultValue={state.mobileNo.mobileNo}
          dropdown={state.mobileNo.countryData}
          // dropdownData={[
          //   {
          //     name: 'India',
          //     flag:
          //       'https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg',
          //     altSpellings: 'IND',
          //     callingCodes: '91'
          //   }
          // ]}
          dropdownData={this?.props?.CountryCode?.data}
          onChange={(e) => changeState("mobileNo", e)}
          error={
            state.error?.mobileNo || state.error?.countryData ? true : false
          }
        ></MobileNumberWithCode>
        {(state.error?.mobileNo || state.error?.callingCodes) && (
          <>
            {state.error?.mobileNo && (
              <Text
                id={`${parent_id}_addmember_form_enter_the_mobile_text`}
                style={{ color: "red", fontSize: 14 }}
              >
                {"Please enter the mobile number"}
              </Text>
            )}
            {state.error?.callingCodes && !state.error?.mobileNo && (
              <Text
                id={`${parent_id}_addmember_form_countrycode_text`}
                style={{ color: "red", fontSize: 14 }}
              >
                {"Please select the country code"}
              </Text>
            )}
          </>
        )}
        <TermsAndConditions
          id={`${parent_id}_addmember_form_send_appointmnet_terms`}
          value={true}
          style={{ fontSize: "1px" }}
          checkboxTickColor={"#fff"}
          activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
          checkboxBorderColor={UIColor.lineBorderFill.color}
          checkboxBackgroundColor={UIColor.differentiationBackground.color}
          activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
          disabled
          lable={
            <span
              id={`${parent_id}_addmember_form_send_appointmnet_span`}
              style={{ fontSize: "12px" }}
            >
              Send appointment update and notification to this Mobile number
            </span>
          }
        />
        <Text
          id={`${parent_id}_addmember_form_mail_id_text`}
          key={"13"}
          // name={'Mail Id (optional)'}
          name={
            <span
              id={`${parent_id}_addmember_form_mail_id_span`}
              style={{ fontSize: "13px" }}
            >
              Mail Id
              {/* <span style={{ color: 'red' }}>*</span> */}
            </span>
          }
          style={qdmstyles.dda_e_dc_a_afcccbd}
        ></Text>
        <TextInput
          id={`${parent_id}_addmember_form_mail_id_textinput`}
          key={"9"}
          type={"text"}
          name={""}
          variant={"outlined"}
          helperTextType={"error"}
          label={""}
          autoFocus={false}
          autoComplete={"off"}
          search={false}
          borderColor={"#9b9b9b"}
          elevation={"0"}
          placeholder={"example@gmail.com"}
          size={"medium"}
          style={qdmstyles.ed_db_d_f_e}
          value={state.emailId}
          onChange={(e) => changeState("emailId", e.target.value)}
          error={state?.error?.emailId}
        ></TextInput>
        {state.emailId && state.error?.emailId && (
          <div>
            <Text
              id={`${parent_id}_addmember_form_mail_id_error_text`}
              style={{ color: "red", fontSize: 14 }}
            >
              {"Please Enter valid Email Id"}
            </Text>
          </div>
        )}
        <TermsAndConditions
          id={`${parent_id}_addmember_form_appointment_mail_id_terms`}
          inLineStyles={{
            fontFamily: "'pc_light' !important",
            maginBottom: "10px",
          }}
          checkboxTickColor={"#fff"}
          activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
          checkboxBorderColor={UIColor.lineBorderFill.color}
          checkboxBackgroundColor={UIColor.differentiationBackground.color}
          activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
          lable={
            <span
              id={`${parent_id}_addmember_form_appointment_mail_id_span`}
              style={{ fontSize: "12px" }}
            >
              Send appointment update and notification to this mail id
            </span>
          }
        />
        {addNewMember && (
          <>
            <br />{" "}
            <Text
              id={`${parent_id}_addmember_form_relationship_text`}
              key={"10"}
              name={
                <span id={`${parent_id}_addmember_form_relationship_span`}>
                  What's your relationship with Sudharsan Narasimhan ?{" "}
                  <span
                    id={`${parent_id}_addmember_form_relationship_star_span`}
                    style={{ color: "red" }}
                  >
                    *
                  </span>
                </span>
              }
              style={qdmstyles.efc_d__a_eea}
            ></Text>
            <TapSelect
              id={`${parent_id}_addmember_form_relationship_tapselect`}
              key={"11"}
              label={false}
              fullWidth={false}
              selectedBorderColor="#1890ff"
              selectedBackgroundColor="#1890ff"
              selectedTextColor="#fff"
              textSize="16"
              iconHeight="20"
              iconWidth="20"
              textColor="#000"
              title={false}
              name={false}
              inLineStyles={{
                borderColor: "#E0E0E0",
                fontSize: "14px",
                fontFamily: "'pc_regular' !important",
              }}
              options={[
                { value: "Father", label: "Father" },
                { value: "Mother", label: "Mother" },
                { value: "Brother", label: "Brother" },
                { value: "Sister", label: "Sister" },
                { value: "Others", label: "Others" },
              ]}
              value={state.relation}
              onChange={(e) => changeState("relation", e.value)}
              iconPosition={"left"}
            ></TapSelect>{" "}
          </>
        )}

        <Button
          id={`${parent_id}_addmember_form_Send_OTP_Sign_Up_buttom`}
          key={"12"}
          type={"button"}
          disabled={props?.registerUsers?.loading}
          variant={"outlined"}
          name={
            props?.registerUsers?.loading
              ? "Signing Up..."
              : addNewMember
              ? props?.addMember?.loading || props?.readPerson?.loading
                ? "Adding..."
                : "Add Member"
              : state.loadingReadPerson
              ? "Signing Up..."
              : "Send OTP & Sign Up"
          }
          style={qdmstyles.ee_ce_fe_aa_ecdaf}
          onClick={handleSignUp}
        ></Button>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  registerUsers: state?.authSlice?.registerUsers,
  addMember: state?.personApiSlice?.upsertPerson,
  readPerson: state?.personApiSlice?.readPerson,
  CountryCode: state?.authSlice?.country_code_list,
});

export default connect(mapStateToProps, actions)(AppointmentDrawerSigningPage);
