import React, {useState , useEffect} from "react";
import actions from "../../redux/actions";
import { connect } from "react-redux";
//import { withRouter } from "react-router-dom";
import  withAllContexts  from "../../hoc/withAllContexts";
import { Div, Card, Divider, H6 } from "qdm-component-library";
import { Header, Body } from "./components";
import { getUserInfo } from "../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Allergies = (props) =>  {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     open: false,
  //     statusCheck: "All",
  //     loading: true,
  //   };
  // }
  const dispatch = useDispatch()

  const [open , setOpen] = useState(false)
  const [loading , setloading] = useState(true)
  const [statusCheck , setstatusCheck] = useState("All")

  const searchPractioner = useSelector(state => state?.personApiSlice?.searchPractioner)
  const readPerson = useSelector(state => state?.personApiSlice?.readPerson)
  const encounterData = useSelector(state => state?.chiefCompilenceMastersSlice?.encounter_Details)
  
 const changeState = (key, value) => {
  setOpen(value)
  };

  useEffect(() => {
    if (props.isCC) {
      getAllMasters();
    } else {
      setloading(false)
    }
  },[])

  

  const getAllMasters = async () => {
    const info = getUserInfo();
    // await dispatch(actions.SEARCH_PRACTIONER({ email: info.email }));
    // await dispatch(actions.READ_PERSON({ phone: info.mobile }));
    // await dispatch(actions.RELATIONSHIP_MASTERS());
    // await dispatch(actions.SYMPTOMS_MASTERS());
    // await dispatch(actions.SEVERITY_MASTERS());
    // await dispatch(actions.BODYSITE_MASTERS());
    // await dispatch(actions.VERIFICATIONS_MASTERS());
    // await dispatch(actions.CLINIC_STATUS_MASTERS());
    // await dispatch(actions.CC_MASTERS());
    // const { patientId, pId } = props?.propsvalue;

    // let data = await dispatch(actions.PATIENTID_BASED_CC({ patientId: pId }));
    // let cheifComplaints = props.cheifComplaints;
    // cheifComplaints.setCheifComplaints({
    //   ...cheifComplaints,
    //   list: JSON.parse(JSON.stringify(data.payload.data)),
    // });
    // //Diagnosis integration
    // // await this.props.DIAGNOSIS_MASTERS();
    // await dispatch(actions.USE_MASTERS());
    // await dispatch(actions.VERIFICATIONS_MASTERS_DIAGNOSIS());
    // await dispatch(actions.DIAGNOSIS_RANK_MASTERS());
    // await dispatch(actions.CLINICAL_MASTERS());
    // await dispatch(actions.MANIFESTATION_MASTERS());
    // await dispatch(actions.GRADE_TYPE_MASTERS());
    // await dispatch(actions.ASSESMENT_MASTERS());
    // await dispatch(actions.GRADE_CODE_MASTERS());

    // let Diagnosisdata = await dispatch(actions.DIAGNOSIS_PATIENT_READ({
    //   patient_id: pId,
    // }));
    // let diagnosis = props.Diagnosis;
    // diagnosis.setDiagnosis({
    //   ...diagnosis,
    //   list: JSON.parse(JSON.stringify(Diagnosisdata.payload.data)),
    // });

    setloading(false)
  }

  const styles = {
    card: {
      boxShadow: "none",
    },
    divider: {
      margin: 0,
      height: "0.1px",
    },
  };

  const editList = (data, index) => {
    // let cheifComplaints = this.props.cheifComplaints
    // cheifComplaints.editIndex = index;
    // cheifComplaints.setCheifComplaints({
    //   ...cheifComplaints
    // })

    props.editWidget(data, props.componentId, index);
  };

  const handleStatusActive = (e) => {
    // this.setState({
    //   statusCheck: "Active",
    // });
    setstatusCheck("Active")
  };

  const handleStatusInActive = (e) => {
    setstatusCheck("InActive")
  };

  const handleStatusResolved = (e) => {
    // this.setState({
    //   statusCheck: "Resolved",
    // });
    setstatusCheck("Resolved")
  };

  const handleStatusAll = (e) => {
    setstatusCheck("All")
  };

    const {
      title = "",
      data = [],
      icon = "",
      iconBackground = "",
      parent_id,
    } = props;
   
    const filteredData = props?.data?.filter(
      (item) => item.status === statusCheck
    );
    // console.log("filteredData", filteredData?.length);
    // console.log("data recived", this.props.data);

    return (
      <div id={`${parent_id}_parent_div`}>
        <Card id={`${parent_id}_parent_card`} style={styles.card}>
          <Header
            parent_id={"allergies"}
            icon={icon}
            iconBackground={iconBackground}
            title={title}
            open={open}
            toggle={() => changeState("open", !open)}
            handleStatusActive={handleStatusActive}
            handleStatusInActive={handleStatusInActive}
            handleStatusResolved={handleStatusResolved}
            handleStatusAll={handleStatusAll}
          />
          <Divider id={`${parent_id}_bottom_divider`} style={styles.divider} />
          {loading && (
            <Div
              id={`${parent_id}_CircularProgress_div`}
              style={{ display: "flex", height: "100px" }}
            >
              <CircularProgress
                id={`${parent_id}_CircularProgress`}
                style={{ margin: "auto" }}
                size={23}
              />
            </Div>
          )}
          {!loading && (
            <Div id={`${parent_id}_filter_parent_div`} style={{ padding: 10 }}>
              {filteredData && filteredData?.length < 1
                ? data &&
                  data?.length > 0 &&
                  data
                    .filter(
                      (val) =>
                        statusCheck === "All" || val.new_status === statusCheck
                    )
                    .map((l, i) => {
                      return (
                        <Body
                          parent_id={"allergies" + i}
                          editList={editList}
                          index={i}
                          id={l?.id}
                          bulletColor={l?.bulletColor}
                          hoverColor={l?.hoverColor}
                          value={l?.value}
                          isNew={l?.isNew}
                          isModified={l?.isModified}
                          showDivider={i < data?.length - 1 ? true : false}
                          subData={l?.subData}
                          showSubData={open}
                          status={l?.status}
                          handledStatus={statusCheck}
                          old_status={l?.old_status}
                          new_status={l?.new_status}
                        />
                      );
                    })
                : filteredData &&
                  filteredData?.length > 0 &&
                  filteredData.map((l, i) => {
                    return (
                      <Body
                        parent_id={"allergies" + i}
                        editList={editList}
                        id={l?.id}
                        index={i}
                        bulletColor={l?.bulletColor}
                        hoverColor={l?.hoverColor}
                        value={l?.value}
                        isNew={l?.isNew}
                        isModified={l?.isModified}
                        showDivider={
                          i < filteredData?.length - 1 ? true : false
                        }
                        subData={l?.subData}
                        showSubData={open}
                        status={l?.status}
                        handledStatus={statusCheck}
                        old_status={l?.old_status}
                        new_status={l?.new_status}
                      />
                    );
                  })}
              {(data?.filter(
                (val) => statusCheck === "All" || val.new_status === statusCheck
              )?.length === 0 ||
                data?.length === 0) && (
                <H6
                  id={`${parent_id}_No_data_h6`}
                  style={{ textAlign: "center", fontSize: "15px" }}
                >
                  No data Found
                </H6>
              )}
            </Div>
          )}
        </Card>
      </div>
    );
  }


// const mapStateToProps = (state) => ({
 
// });

export default withAllContexts(Allergies);
