import React, { useState, useEffect } from "react";
import { Row, Col, Text, Div, Icons, Image } from "qdm-component-library";
import {
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { UIColor } from "../../../themes/theme";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/actions";
import { treatmentPlan } from "../vitalsJson";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useContext } from "react";
import { AlertContext, BackdropContext, DialogContext } from "../../../context";
import { v4 as uuidv4 } from "uuid";
import withAppBar from "../../../hoc/withAppBar";
import TreatmentPlanForm from "./treatmentPlanForm";
import {
  AlertProps,
  axiosCall,
  epochToDatetime,
  formatDateFromEpoch,
  urls,
} from "../../../utils";
import { Pagination } from "@material-ui/lab";
import HistoryAccordion from "../historyAccordion";
import Addicon from "../../../assets/img/icons8-add.svg";
import icons8edit from "../../../assets/img/svg/icons8-edit_.svg";
import printIcon from "../../../assets/img/svg/print-icon-noframe.svg"
import axios from "axios";

function jsonconverter(data = []) {
  function removeSpaces(str = "") {
    return str.replace(/\s/g, "");
  }
  let result = {};
  data.map((v) => {
    result[removeSpaces(v?.vitalscode)] = {
      obsinternalid: v?._id,
      obscode: removeSpaces(v?.vitalscode),
      obsvalue: "",
      shortdesc: v?.shortdesc,
      isabnormal: true,
      abnormalmsg: '"Min"',
      type: v?.type,
      obsuom: v?.units_allowed?.[0]?._id,
      time: Date.now(),
      dropdownvalues: v?.dropdownvalues,
      groupname: v?.groupname,
      mandatory: v?.mandatory,
      validation_max: v?.VitalsCodesReferenceRanges?.[0]?.validation_max,
      validation_min: v?.VitalsCodesReferenceRanges?.[0]?.validation_min,
      default_unit: v?.default_unit,
    };
  });
  return result;
}
const styles = {
  vitalsHead: {
    padding: "6px 14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
    paddind: 10,
  },
  vitailsCard: {
    //border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    marginBottom: 0,
    background: "#fff",
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "red",
  },
  inputStyle: {
    padding: 5,
    marginBottom: 20,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  listVitals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tagsStyle: {
    padding: "4px 12px",
    borderRadius: "50px",
    border: "1px solid #0071F2",
    color: "#0071F2",
    background: "#F5FAFF",
    fontSize: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  tapSelectStyle: {
    padding: "4px 8px",
    borderColor: UIColor.lineBorderFill.color,
    margin: "4px 10px 6px 0px",
    borderRadius: "8px",
    minWidth: "auto",
    "& span": {
      fontSize: "12px",
    },
  },
  TapSelectcol: {
    display: "flex",
    margin: "auto 0",
    marginBottom: "12px",
    paddingLeft: "4px",
  },
  CalendarStyle: {
    padding: 5,
    marginBottom: 10,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  CalendarStyleIcon: {
    position: "absolute",
    right: "14px",
    bottom: "8px",
    cursor: "pointer",
  },
  editAllergyIcon: {
    padding: "6px 10px",
    background: UIColor.greyBackground.color,
    borderRadius: "50px",
    cursor: "pointer",
  },
};
const fieldsToDisplay = [
  "vascularaccess",
  "buffer",
  "dialysate",
  "dryweight",
  "frequency",
  "heparin",
  "needlesize",
  "qd",
  "qb",
  "shift",
  "hdduration",
];

const TreatmentPlan = (props) => {
  const dispatch = useDispatch();
  const dialog = useContext(DialogContext);
  const [treatmentPlanDatas, setTreatmentPlanDatas] = React.useState([]);
  const [treatmentPlanTemp, setTreatmentPlanTemp] = React.useState();
  const [treatmentPlanHistory, setTreatmentPlanHistory] = React.useState([]);
  const selector = useSelector((state) => state.VitalsApiSlice);
  // const treatmentplan = useSelector(
  //   (state) => state?.patientDetails?.treatmentplanread
  // );
  const treatmentplanFun = async () => {
    const treatmentplan = await dispatch(
      actions.TRAEMENT_PLAN_READ({
        patientid: props?.location?.state?.patientId ?? props?.patientId,
      })
    );
    setTreatmentPlanTemp(treatmentplan?.payload?.data);
  };

  const treatmentPlanData = treatmentPlanTemp ?? treatmentPlanDatas ?? [];
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  const [isErr, setisErr] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [dailogComp, setDailogComp] = useState(<></>);
  var treatmentPlanJSON = jsonconverter(
    treatmentPlan?.results ? treatmentPlan?.results : []
  );
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update current page when page is changed
  };

  // Assuming treatmentPlanHistory and pageSize are defined somewhere in your component's scope
  const pageSize = 2; // Number of items per page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleItems = treatmentPlanHistory?.slice(startIndex, endIndex);

  const getHistory = async () => {
    const tpHistory = await dispatch(
      actions.GET_TREATEMENTPLAN_HISTORY({
        patientId: props?.location?.state?.patientId ?? props?.patientId,
      })
    );

    setTreatmentPlanHistory(tpHistory?.payload?.data);
    console.log(tpHistory?.payload?.data, "treatmentplan123");
  };
  useEffect(() => {
    treatmentplanFun();
  }, [treatmentPlanDatas]);

  useEffect(() => {
    getHistory();
  }, []);

  const handleDialog = (json, statekey, edit, editstate) => {
    setOpen(true);
    setDailogComp(
      <TreatmentPlanForm
        data={json}
        statekey={statekey}
        handlecancel={handleClose}
        edit={edit}
        editstate={editstate}
        isErr={isErr}
        errorField={errorField}
        setErrorFields={setErrorFields}
        practid={props?.patientgetData?.practitioner_details?.[0]?.resouceid}
        setOpen={setOpen}
        setTreatmentPlanDatas={setTreatmentPlanDatas}
        treatmentPlanData={treatmentPlanData}
        patientid={
          props?.location?.state?.patientId ??
          props?.patientId ??
          props?.location?.state?.pId
        }
        encounterid={props?.location?.state?.encounter_id}
        ticketId={props?.location?.state?.ticketId}
        ids={props}
        setTreatmentplan={props?.setTreatmentplan}
      />
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const printChart = async () => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = {
      "Content-Type": "application/json",
      JwtToken: JwtToken 
    };
    let payload = {
      "reportid": "afe578b3-4226-4f9e-a7eb-c31a3e9e03d7",
      "inputparams": {
          "@patientid": props?.location?.state?.patientId ?? props?.patientId,
          "@encounterid": props?.location?.state?.encounter_id
      },
      "result": []
    };
    try{
      let Response = await axios({
        method: "POST",
        url: urls?.jasperReport,
        headers: header,
        data: JSON.stringify(payload),
      });
      if(Response?.status == 200){
        const response = await axios.get(Response?.data?.downloadUrl, {
          method: "GET",
          responseType: "blob",
        });
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });
        const pdfDataUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfDataUrl, "_blank");
      } 
    }catch (error) {
        if(error?.response?.status == 400) {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "No Documents !!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        } else {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please Contact Admin",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        }
      }
  }
  const [errorField, setErrorFields] = React.useState({});

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <Row inLineStyles={{ ...styles.vitailsCard }}>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.vitalsHead}
          >
            <Text
              inLineStyles={{
                fontWeight: 600,
                fontSize: "14px",
                color: UIColor.primaryColor.color,
                margin: "10px 5px 10px 5px",
              }}
            >
              {"Treatment Plan"}
            </Text>
            <Grid>
            <Grid container style={{flexDirection:"row", justifyItems:"center"}}>
                <Grid item>
                {!props?.location?.state?.IsViewMode && (
                  <Text
                    inLineStyles={{
                      padding: "6px 10px",
                      background: UIColor.greyBackground.color,
                      borderRadius: "50px",
                      cursor: "pointer",
                      marginLeft: 10,
                    }}
                    onClick={(e) =>
                      printChart()
                    }
                    style={{
                      display:
                        treatmentPlanData.length === 0 ? "none" : "block",
                    }}
                  >
                    <Image
                      src={printIcon}
                      alt="edit_icon"
                      style={{ paddingTop: "3px", height:"18px",width:"20px"}}
                    />
                  </Text>
              )}
                </Grid>
                <Grid item>
                {!props?.location?.state?.IsViewMode && (
                <IconButton
                  onClick={() =>
                    handleDialog(treatmentPlanJSON, "treatmentPlan")
                  }
                  style={{
                    display: treatmentPlanData.length > 0 ? "none" : "block",
                  }}
                >
                  <img src={Addicon} alt="Add" />
                </IconButton>
              )}
                </Grid>
                <Grid item>
                {!props?.location?.state?.IsViewMode &&
                treatmentPlanData.map((e, i) => (
                  <Text
                    inLineStyles={{
                      padding: "6px 10px",
                      background: UIColor.greyBackground.color,
                      borderRadius: "50px",
                      cursor: "pointer",
                      marginLeft: 10,
                    }}
                    onClick={() =>
                      handleDialog(treatmentPlanJSON, "treatmentPlan", true, e)
                    }
                    style={{
                      display:
                        treatmentPlanData.length === 0 ? "none" : "block",
                    }}
                  >
                    <Image
                      src={icons8edit}
                      alt="edit_icon"
                      style={{ paddingTop: "3px" }}
                    />
                  </Text>
                ))}
                </Grid>
             
               
              </Grid>
            </Grid>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={{ padding: "0px 8px 10 8px" }}
          >
            {loading ? (
              <Div
                id={`vitals_CircularProgress_div`}
                style={{ display: "flex", height: "100px" }}
              >
                <CircularProgress
                  id={`vitals_CircularProgress`}
                  style={{ margin: "auto" }}
                  size={23}
                />
              </Div>
            ) : (
              <div style={{ width: "100%" }}>
                {treatmentPlanData.length > 0 ? (
                  treatmentPlanData.map((e, i) => (
                    <div key={e?.id} style={{ width: "100%" }}>
                      <div style={{ margin: "10px 5px" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <Grid
                            container
                            xs={12}
                            spacing={12}
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  Vascular Access
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {e.vascularaccess}
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  DRY WEIGHT
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {e.dryweight} KG
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  NEEDLE SIZE
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {e.needlesize?.display || e?.needlesize}
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  DURATION
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {e.hdduration} HOURS
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  FREQUENCY
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {e?.frequency?.display}/WEEK
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  SHIFT
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {e.shift?.display || e.shift}
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  QB
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {e.qb || e.qb?.display} MLS/MIN
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  QD
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {e.qd} MLS/MIN
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  HEPARIN
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {e?.heparin?.display?.slice(0, 27) ||
                                    e?.heparin?.slice(0, 27)}
                                  ...
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  DIALYZER
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {e.dialyzertype?.display || e?.dialyzertype}{" "}
                                  MIN
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  DIALSATE
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {e.dialysate?.display || e.dialysate}
                                  mmoL/I
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              container
                              justifyContent="space-around"
                              style={{ margin: "5px 0px" }}
                            >
                              <Grid item xs={5}>
                                <Text
                                  style={{
                                    color: UIColor.secondaryButtonColor.color,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "pc_regular",
                                  }}
                                >
                                  {/* BUFFER*/}
                                </Text>
                              </Grid>
                              <Grid item xs={7}>
                                <Text
                                  style={{
                                    fontFamily: "pc_regular",
                                    fontSize: 12,
                                    color: UIColor.tertiaryText.color,
                                  }}
                                >
                                  {/*{e.buffer}*/}
                                </Text>
                              </Grid>
                            </Grid>
                          </Grid>
                          <span style={{ marginRight: "30px" }}></span>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <Typography
                    variant="caption"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "15px 0px",
                      display: "block",
                    }}
                  >
                    No data available
                  </Typography>
                )}
              </div>
            )}
          </Col>
          {
            props?.history && (
              <HistoryAccordion>
              <div style={{ width: "100%" }}>
                 {visibleItems.length > 0 ? (
              visibleItems.map((e, i) => (
                <div key={e?.id} style={{ width: "100%" }}>
                <div style={{ margin: "10px 5px" }}>
                <div style={{display: "flex", justifyContent: "flex-start",marginBottom: "10px"}}>
                <Text style={{
                  fontWeight: 600,
                  fontSize: "12px",
                  cursor: "pointer",
                  color: UIColor.primaryColor.color,
                  alignItems: "center",
                }}
                >
               {formatDateFromEpoch(e.createddate)}
                </Text>
                </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Grid
                      container
                      xs={12}
                      spacing={12}
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        xs={4}
                        container
                        justifyContent="space-around"
                        style={{ margin: "5px 0px" }}
                      >
                        <Grid item xs={5}>
                          <Text
                            style={{
                              color: UIColor.secondaryButtonColor.color,
                              fontSize: "14px",
                              fontWeight: 500,
                              fontFamily: "pc_regular",
                            }}
                          >
                            Vascular Access
                          </Text>
                        </Grid>
                        <Grid item xs={7}>
                          <Text
                            style={{
                              fontFamily: "pc_regular",
                              fontSize: 12,
                              color: UIColor.tertiaryText.color,
                            }}
                          >
                       {e.vascularaccess} BCF
                          </Text>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        container
                        justifyContent="space-around"
                        style={{ margin: "5px 0px" }}
                      >
                        <Grid item xs={5}>
                          <Text
                            style={{
                              color: UIColor.secondaryButtonColor.color,
                              fontSize: "14px",
                              fontWeight: 500,
                              fontFamily: "pc_regular",
                            }}
                          >
                          DRY WEIGHT
                          </Text>
                        </Grid>
                        <Grid item xs={7}>
                          <Text
                            style={{
                              fontFamily: "pc_regular",
                              fontSize: 12,
                              color: UIColor.tertiaryText.color,
                            }}
                          >
                     {e.dryweight}KG
                          </Text>
                        </Grid>
                      </Grid>
                      <Grid
                      item
                      xs={4}
                      container
                      justifyContent="space-around"
                      style={{ margin: "5px 0px" }}
                    >
                      <Grid item xs={5}>
                        <Text
                          style={{
                            color: UIColor.secondaryButtonColor.color,
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "pc_regular",
                          }}
                        >
                        NEEDLE SIZE
                        </Text>
                      </Grid>
                      <Grid item xs={7}>
                        <Text
                          style={{
                            fontFamily: "pc_regular",
                            fontSize: 12,
                            color: UIColor.tertiaryText.color,
                          }}
                        >
                  {e.needlesize?.display || e?.needlesize}
                        </Text>
                      </Grid>
                    </Grid>
                    <Grid
                    item
                    xs={4}
                    container
                    justifyContent="space-around"
                    style={{ margin: "5px 0px" }}
                  >
                    <Grid item xs={5}>
                      <Text
                        style={{
                          color: UIColor.secondaryButtonColor.color,
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "pc_regular",
                        }}
                      >
                      DURATION
                      </Text>
                    </Grid>
                    <Grid item xs={7}>
                      <Text
                        style={{
                          fontFamily: "pc_regular",
                          fontSize: 12,
                          color: UIColor.tertiaryText.color,
                        }}
                      >
                  {e.hdduration} HOURS
                      </Text>
                    </Grid>
                  </Grid>
                  <Grid
                  item
                  xs={4}
                  container
                  justifyContent="space-around"
                  style={{ margin: "5px 0px" }}
                >
                  <Grid item xs={5}>
                    <Text
                      style={{
                        color: UIColor.secondaryButtonColor.color,
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "pc_regular",
                      }}
                    >
                    FREQUENCY
                    </Text>
                  </Grid>
                  <Grid item xs={7}>
                    <Text
                      style={{
                        fontFamily: "pc_regular",
                        fontSize: 12,
                        color: UIColor.tertiaryText.color,
                      }}
                    >
                    {e.frequency}/WEEK
                    </Text>
                  </Grid>
                </Grid>
                <Grid
                item
                xs={4}
                container
                justifyContent="space-around"
                style={{ margin: "5px 0px" }}
              >
                <Grid item xs={5}>
                  <Text
                    style={{
                      color: UIColor.secondaryButtonColor.color,
                      fontSize: "14px",
                      fontWeight: 500,
                      fontFamily: "pc_regular",
                    }}
                  >
                  SHIFT
                  </Text>
                </Grid>
                <Grid item xs={7}>
                  <Text
                    style={{
                      fontFamily: "pc_regular",
                      fontSize: 12,
                      color: UIColor.tertiaryText.color,
                    }}
                  >
                {e.shift?.display || e.shift}s
                  </Text>
                </Grid>
              </Grid>
              <Grid
              item
              xs={4}
              container
              justifyContent="space-around"
              style={{ margin: "5px 0px" }}
            >
              <Grid item xs={5}>
                <Text
                  style={{
                    color: UIColor.secondaryButtonColor.color,
                    fontSize: "14px",
                    fontWeight: 500,
                    fontFamily: "pc_regular",
                  }}
                >
                QB
                </Text>
              </Grid>
              <Grid item xs={7}>
                <Text
                  style={{
                    fontFamily: "pc_regular",
                    fontSize: 12,
                    color: UIColor.tertiaryText.color,
                  }}
                >
                {e.qb || e.qb?.display} MLS/MIN
                </Text>
              </Grid>
            </Grid>
            <Grid
            item
            xs={4}
            container
            justifyContent="space-around"
            style={{ margin: "5px 0px" }}
          >
            <Grid item xs={5}>
              <Text
                style={{
                  color: UIColor.secondaryButtonColor.color,
                  fontSize: "14px",
                  fontWeight: 500,
                  fontFamily: "pc_regular",
                }}
              >
              QD
              </Text>
            </Grid>
            <Grid item xs={7}>
              <Text
                style={{
                  fontFamily: "pc_regular",
                  fontSize: 12,
                  color: UIColor.tertiaryText.color,
                }}
              >
              {e.qd} MLS/MIN
              </Text>
            </Grid>
          </Grid>
          <Grid
          item
          xs={4}
          container
          justifyContent="space-around"
          style={{ margin: "5px 0px" }}
        >
          <Grid item xs={5}>
            <Text
              style={{
                color: UIColor.secondaryButtonColor.color,
                fontSize: "14px",
                fontWeight: 500,
                fontFamily: "pc_regular",
              }}
            >
            HEPARIN
            </Text>
          </Grid>
          <Grid item xs={7}>
            <Text
              style={{
                fontFamily: "pc_regular",
                fontSize: 12,
                color: UIColor.tertiaryText.color,
              }}
            >
            {e.heparin?.display?.slice(0,27) || e?.heparin?.slice(0,27)}...MIN
            </Text>
          </Grid>
        </Grid>
        <Grid
        item
        xs={4}
        container
        justifyContent="space-around"
        style={{ margin: "5px 0px" }}
      >
        <Grid item xs={5}>
          <Text
            style={{
              color: UIColor.secondaryButtonColor.color,
              fontSize: "14px",
              fontWeight: 500,
              fontFamily: "pc_regular",
            }}
          >
          DIALYZER
          </Text>
        </Grid>
        <Grid item xs={7}>
          <Text
            style={{
              fontFamily: "pc_regular",
              fontSize: 12,
              color: UIColor.tertiaryText.color,
            }}
          >
    {e.dialyzertype?.display || e?.dialyzertype}{" "} MIN
          </Text>
        </Grid>
      </Grid>
      <Grid
      item
      xs={4}
      container
      justifyContent="space-around"
      style={{ margin: "5px 0px" }}
    >
      <Grid item xs={5}>
        <Text
          style={{
            color: UIColor.secondaryButtonColor.color,
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "pc_regular",
          }}
        >
        DIALSATE
        </Text>
      </Grid>
      <Grid item xs={7}>
        <Text
          style={{
            fontFamily: "pc_regular",
            fontSize: 12,
            color: UIColor.tertiaryText.color,
          }}
        >
       {e.dialysate?.display || e.dialysate}
                            mmoL/I
        </Text>
      </Grid>
    </Grid>
    <Grid
    item
    xs={4}
    container
    justifyContent="space-around"
    style={{ margin: "5px 0px" }}
    >
    <Grid item xs={5}>
      <Text
        style={{
          color: UIColor.secondaryButtonColor.color,
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "pc_regular",
        }}
      >
      { /* BUFFER*/}
      </Text>
    </Grid>
    <Grid item xs={7}>
      <Text
        style={{
          fontFamily: "pc_regular",
          fontSize: 12,
          color: UIColor.tertiaryText.color,
        }}
      >
      {/*{formatDateFromEpoch(e?.hdstartdate)}*/}
    { /*{e.buffer}*/}
   
      </Text>
     
    </Grid>
    </Grid>
                   
                    </Grid>
                    <span style={{ marginRight: "30px" }}>
                     
                    </span>
                  </div>
                  {treatmentPlanHistory.length > 1 && (
                    <>
                      {treatmentPlanHistory.length === 2 && (
                        <Divider orientation="horizontal" variant="fullWidth" style={{ margin: "15px 0" }} />
                      )}
                    </>
                  )}
                </div>
             
              </div>
                  ))
                ) : (
                  <Typography
                    variant="caption"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "5px",
                      display: "block",
                    }}
                  >
                    No data available
                  </Typography>
                )}
              </div>
             {treatmentPlanHistory.length > 2 && (
             
              <div style={{ display: "flex", justifyContent: "flex-end" ,marginTop:"10px"}}>
              <Pagination count={Math.ceil(treatmentPlanHistory.length / pageSize)} page={currentPage} onChange={handlePageChange} />
              </div>
            )
            }
            </HistoryAccordion>
            )
          }
        </Row>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={open}
          // onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          {dailogComp}
        </Dialog>
      </div>
    </>
  );
};

export default TreatmentPlan;
