import React from "react";
import { Div, Row, Icons, Col } from "qdm-component-library";
import { Button } from "@material-ui/core"

function BillNav(props) {
    const { btnList = [], activeid = 1, parent_id } = props;
    return <Div
        id={`${parent_id}_billnav_parent_div`}
    >
        <Row
            id={`${parent_id}_billnav_parent_row`}
            style={{ paddingBottom: 20 }}>
            <Col
                id={`${parent_id}_billnav_buttom_col`}
                lg={8} md={8} sm={12} xs={12} inLineStyles={{ padding: 0 }}>
                {btnList.map((val, i) => {
                    return < Button
                        id={`${parent_id}_${i}_billnav_buttom`}
                        variant="outlined"
                        style={{
                            borderRadius: "20px",
                            marginRight: "10px",
                            border: "1.5px solid",
                            fontWeight: 600,
                            color: activeid === val.id ? '#0071F2' : '#101010',
                            borderColor: activeid === val.id ? '#0071F2' : '#707070',
                            textTransform: 'capitalize',
                            padding: '3px 20px',
                            minWidth: '90px'
                        }}
                    // color={activeid === val.id ? "primary" : ""}
                    >{val.label}</Button>
                })
                }
            </Col>
            <Col
                id={`${parent_id}_billnav_parent_col`}
                lg={4} md={4} sm={12} xs={12} inLineStyles={{ padding: 0 }}>
                <Div
                    id={`${parent_id}_billnav_icon_div`}
                    style={{
                        border: "1px solid #E0E0E0",
                        padding: "4px 10px",
                        borderRadius: "4px",
                        color: "#B6B6B6",
                        backgroundColor: "#fff",
                        display: "inline-block",
                        float: "right"
                    }}
                >
                    <Icons
                        id={`${parent_id}_billnav_filter_div`}
                        key={"2"}
                        fontIcon="filter"
                        ariaHidden="true"
                        className=""
                        size="medium"
                        style={{ color: "#B6B6B6", fontSize: "12px" }}
                    ></Icons>
                </Div>
                <Div
                    id={`${parent_id}_billnav_search_icon_div`}
                    style={{
                        border: "1px solid #E0E0E0",
                        padding: "4px 10px",
                        borderRadius: "4px",
                        color: "#B6B6B6",
                        marginRight: "8px",
                        backgroundColor: "#fff",
                        display: "inline-block",
                        float: "right"
                    }}
                >
                    <Icons
                        id={`${parent_id}_billnav_search_icons`}
                        key={"2"}
                        fontIcon="search"
                        ariaHidden="true"
                        className=""
                        size="medium"
                        inLineStyles={{
                            color: "#B6B6B6",
                            fontSize: "12px",
                        }}
                    ></Icons>
                </Div>
            </Col>
        </Row>
    </Div >
}

export default BillNav;