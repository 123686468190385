import React, { useEffect, useState } from "react";
import "./style.css";
import { useDispatch } from "react-redux";
import { UIColor } from "../../themes/theme";
import { AxiosCall, urls, AlertProps, __tenantid__ } from "../../utils";
import withAllContexts from "../../hoc/withAllContexts";
import actions from "../../redux/actions";
import jwtDecode from "jwt-decode";
import axios from "axios";
import {
  Stack,
  Chip,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  Dialog,
  Autocomplete,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { AlertContext } from "../../context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useMemo } from "react";
import AppHeader from "../../components/layout/appHeader";
import CloseIcon from "@material-ui/icons/Close";

var debouncing;
const CashierCollectionSummary = (props) => {
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [cashierdatas, setCashierdatas] = useState({
    facility: [],
    startdate: "",
    enddate: "",
    user: [],
  });
  const [facilityNameData, setFacilityNameData] = useState([]);
  const [practitionerNameData, setPractitionerNameData] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [error, setError] = useState({});
  const { parent_id } = props;

  const setData =  (name, value) => {
    if(name==="startdate" || name === "enddate"){
      let updatedData = { ...cashierdatas };
      updatedData[name] = value.$d.toISOString();
      setCashierdatas(updatedData); 
      return;
    }
    let updatedData = { ...cashierdatas };
    updatedData[name] = value;
    setCashierdatas(updatedData); 
  };
 
  const validation = () => {
    let data = {
      facility: [],
      startdate: "",
      enddate: "",
    };
    let validation = {
      error: false,
    };

    Object.keys(data).forEach((v) => {

      if (v === "startdate" || v === "enddate") {
        if (cashierdatas[v] == null && cashierdatas[v] == undefined) {
          validation.error = true;
          validation[v] = true;
        } else {
          if (!(dayjs(cashierdatas[v]).isValid())) {
            validation.error = true;
            validation[v] = true;
          } else {
            validation[v] = false;
          }
        }
      }
     
      else if (Array.isArray(data[v])) {
        if (!cashierdatas[v]?.length) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation[v] = false;
        }
      } 
    });
    setError(validation);
    return validation;
  };
  const dailogclose = () =>{
    setOpen(false)
    navigate(AppRoutes.dashboard)
  }
  const HandleChangeForSearch = async (e, v) => {
    setCashierdatas({ ...cashierdatas, facility: v || [] });
     if (v.length > 0){
      const response = await dispatch(
        actions.CASHCOLLECTION_PRACTITIONER_DROPDOWN({
          facilityid:  v?.map(item => item._id)
        })
      );
      setPractitionerNameData(response?.payload?.data);
    }
  };
  const HandleChangeForUser = (e, v) => {
    setCashierdatas({ ...cashierdatas, user: v || [] });
  };
  const ServcieSearch = (value) => {
    if (value) {
      clearTimeout(debouncing);
      debouncing = setTimeout(() => {
        dispatch(actions.REPORTS_FACILITY_DROPDOWN(value));
      }, 700);
    }
  };
  const UserSearch = (value) => {
    if (value) {
      clearTimeout(debouncing);
      debouncing = setTimeout(() => {
        dispatch(actions.CASHCOLLECTION_PRACTITIONER_DROPDOWN(value));
      }, 700);
    }
  };
  const GetFacilityId = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        value: item.name,
        _id: item._id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const facilityName = async () => {
    const response = await dispatch(
      actions.REPORTS_FACILITY_DROPDOWN({
        facilityid: decodedUserinfo?.facilityid || "",
        tenantid: decodedUserinfo?.tenantid,
      })
    );
    setFacilityNameData(response?.payload?.data);
  };
  const loadDefaultVal = async ()=>{
    try {
      const userInfo = await localStorage.getItem("UserInfo");
      const decodedUserinfo = await jwtDecode(userInfo);
      const practitionerid = decodedUserinfo?.practitionerid;
      const facilityId = decodedUserinfo?.facilityid
      const stateValues = {};
      let payload = {
        screenid: process.env.REACT_APP_CASH_COLLECTION_REPORT_SCREEN_ID,
        tenantid: __tenantid__,
        filtervalues:{
            practitionerid : practitionerid,
            orgid : facilityId
        }
      }
      let token = localStorage.getItem("JwtToken");
        let register = await axios({
          method: "POST",
          url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
          headers: {
            "Content-Type": "application/json",
            jwtToken: `${token}`,
          },
          data: JSON.stringify(payload),
        }).then((res) => {
          if (res?.data?.response?.screenId === process.env.REACT_APP_CASH_COLLECTION_REPORT_SCREEN_ID) {
            const defaultValues = res?.data?.response?.defaultValues;
            const stateNames = [
              "startDate",
              "endDate",
              "Facility",
              "Practitioner",
            ]
            defaultValues.forEach((s) => {
              if (stateNames.includes(s.stateName)) {
                stateValues[s.stateName] = s.value;
              }
            });
          }})
          await setCashierdatas({
            ...cashierdatas,
            startdate:new Date(stateValues?.startDate * 1000),
            enddate:new Date(stateValues?.endDate * 1000),
            facility:[stateValues?.Facility],
            user:[stateValues?.Practitioner]
          })
    } catch (e){
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
    }
  }
  useEffect(() => {
    loadDefaultVal();
    facilityName();
  }, []);

  function dateToEpoch(dateString) {
    const date = dayjs(dateString);
    date.startOf('day');
    const epochTime = date.valueOf();
    const epochTimeInSeconds = Math.floor(epochTime / 1000);
    return epochTimeInSeconds;
  }


  const generateAndOpenJasperReport = async () => {
    const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };

    const startDate = new Date(cashierdatas?.startdate);
    startDate.setHours(0, 0, 0, 0);
    const startEpochTime = getEpochTime(startDate);

    const endDate = new Date(cashierdatas?.enddate);
    endDate.setHours(23, 59, 59, 999);
    const endEpochTime = getEpochTime(endDate);

    const startdate = dayjs(cashierdatas?.startdate);
    const enddate = dayjs(cashierdatas?.enddate);
    if ( enddate < startdate) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "End date should be after the start date",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
    let valid = validation()
    if (valid.error){
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Please fill mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    } else {
      const payload = {
        reportid: "056a7312-4fa8-45c8-86a3-58f890950ad0",
        inputparams: {
          "@facilityid": `[${cashierdatas?.facility?.map(item => `'${item._id}'`)}]` ?? [],
          "@startdate": startEpochTime,//dateEpochStart,
          "@enddate": endEpochTime,//dateEpochEnd,
          "@practitionerid": `[${cashierdatas?.user.map(item => `'${item._id}'`)}]` ?? [],
        },
  
        result: [],
      };
      AxiosCall("post", urls.jasperReport, payload, header).then(async (res) => {
        if (res?.downloadUrl) {
          const response = await axios.get(res?.downloadUrl, {
            method: "GET",
            responseType: "blob",
          });
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfDataUrl = URL.createObjectURL(pdfBlob);
          window.open(pdfDataUrl, "_blank");
          props?.handleModalClose();
        } else {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "There is no report available.",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          props?.handleModalClose();
        }
      });
    }
   setOpen(false)
   navigate(AppRoutes.dashboard)
  };
  useEffect(() => {
    if (downloadUrl) {
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.target = "_blank";
      downloadLink.download = "report.pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [downloadUrl]);
  const helperTextMsg = "This field is required!"
  const errorMessage = React.useMemo(() => {
    if (error?.startdate || error?.enddate) {
       return 'Please select a date';
    }
  }, [error]);
  return (
    <div>
      {/* <Dialog
        maxWidth="xl"
        fullWidth
        open={open}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DialogTitle>Cash Collection Summary Report</DialogTitle>
        <DialogContent dividers> */}
          <div
            style={{
              backgroundColor: "#fff",
              outline: "none",
              width: 500,
              padding: "10px",
              paddingLeft: "15px",
            }}
          >
            <IconButton
            aria-label="close"
            onClick={props?.handleModalClose}
            style={{ position: "absolute", right: 0, top: 0 }}
          >
            <CloseIcon />
          </IconButton>
            <Grid container spacing={2}>
            <Grid
                item
                id={`${parent_id}referral_parent_Referring_from_Facility_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <Typography variant="h5">Cash Collection Summary Report</Typography>
              </Grid>
              <Grid
                item
                id={`${parent_id}referral_parent_Referring_from_Facility_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <div>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={GetFacilityId(facilityNameData) ?? []}
                    getOptionLabel={(option) => option?.value ?? ""}
                    limitTags={2}
                    hoverColor={UIColor.primaryColor.color}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.value}
                          {...getTagProps({ index })}
                          size="small"
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        required={true}
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="search facility"
                        label="Facility"
                        hoverColor={UIColor.primaryColor.color}
                        sx={{
                          "& .MuiAutocomplete-inputRoot": {
                            borderRadius: "8px",
                            border: "1px  #E0E0E0",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={error?.facility}
                        helperText={error?.facility ? helperTextMsg : ""}
                      />
                    )}
                    onChange={(e, v) => {
                      HandleChangeForSearch(e, v);
                    }}
                    value={cashierdatas?.facility || []}
                    onInputChange={(e) => {
                      ServcieSearch(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid
                item
                container
                spacing={2}
                id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start Date"
                      value={
                        cashierdatas?.startdate
                          ? dayjs(cashierdatas?.startdate)
                          : cashierdatas?.startdate || null
                      }
                      onChange={(e, v) => setData("startdate", e)}
                      onError={error?.startdate}
                      slotProps={{
                        textField: {
                          helperText: errorMessage,
                          error: error?.startdate ? true : false,
                          size: "small",
                          required: true,
                          fullWidth: true,
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="End Date"
                      value={
                        cashierdatas?.enddate
                          ? dayjs(cashierdatas?.enddate)
                          : cashierdatas?.enddate || null
                      }
                      onChange={(e) => setData("enddate", e)}
                      onError={error?.enddate}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                      slotProps={{
                        textField: {
                          helperText: errorMessage,
                          error: error?.enddate ? true : false,
                          size: "small",
                          required: true,
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid
                item
                id={`${parent_id}referral_parent_Referring_from_Facility_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <div>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={practitionerNameData ?? []}
                    getOptionLabel={(option) => option?.value ?? ""}
                    limitTags={2}
                    hoverColor={UIColor.primaryColor.color}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.value}
                          {...getTagProps({ index })}
                          size="small"
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="search practitioner"
                        hoverColor={UIColor.primaryColor.color}
                        style={{ paddingBottom: "20px" }}
                        sx={{
                          "& .MuiAutocomplete-inputRoot": {
                            borderRadius: "8px",
                            border: "1px  #E0E0E0",
                          },
                        }}
                        label="Practitioner"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={error?.user}
                        helperText={error?.user ? helperTextMsg : ""}
                      />
                    )}
                    onChange={(e, v) => {
                      HandleChangeForUser(e, v);
                    }}
                    value={cashierdatas?.user || []}
                    onInputChange={(e) => {
                      UserSearch(e.target.value);
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <Stack
              container
              md={12}
              lg={12}
              spacing={3}
              direction="row"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                size="medium"
                style={{
                  color: UIColor.primaryColor.color,
                  borderColor: UIColor.primaryColor.color,
                }}
                onClick={props?.handleModalClose}
              >
                Cancel
              </Button>

              <Button
                size="medium"
                style={{
                  color: "#fff",
                  background: UIColor.primaryColor.color,
                }}
                variant="contained"
                onClick={() => generateAndOpenJasperReport()}
              >
                Submit
              </Button>
            </Stack>
          </div>
        {/* </DialogContent>
      </Dialog> */}
    </div>
  );
};

export default withAllContexts(CashierCollectionSummary);
