import React, { useContext, useEffect, useState } from "react";
import TableComponent from "../../cashierBillingDetails/components/tableComponent";
import actions from "../../../redux/actions";
import { AlertProps, AxiosCall, dbName, urls } from "../../../utils";
import { useDispatch } from "react-redux";
import { AlertContext } from "../../../context";
import { Button, H6, TextInput } from "qdm-component-library";
import { UIColor } from "../../../themes/theme";
import axios from "axios";
import jwtDecode from "jwt-decode";
import DataGridComponent from "./tableComponent";
import { Typography } from "@mui/material";

const dataType = [
  // { name: "checked", type: "CHECKBOX", header: "All" },
  // { name: "s.no", type: "INCREMENT", header: "S.No" },
  { name: "receiptno", type: "TEXT", header: "scroll", width: 200 },
  {
    name: "receiptdate",
    type: "EPOCH_DATE_DISPLAY",
    header: "scroll",
    width: 200,
  },
  // { name: "advanceid", type: "TEXT", header: "scroll",width:250  },
  { name: "advanceamount", type: "TEXT", header: "scroll", width: 200 },
  { name: "", type: "ACTION", header: "scroll", width: 200 },
];

const AdvanceViewBill = (props) => {
  const userInfo = localStorage.getItem("UserInfo");
  const alertContext = useContext(AlertContext);
  const decodedUserinfo = jwtDecode(userInfo);
  const practitionerId = decodedUserinfo?.practitionerid;
  const Header = [
    // "All",
    // "S.No",
    "Receipt No.",
    "Receipt Date",
    // "Advance Id",
    "Advance Amount ",
    "Action"  
  ];
  const [advanceData, setAdvanceData] = useState([]);

  const advanceDatas = async (_id) => {
    const patientId = _id;
      const data = await dispatch(
        actions.ADVANCE_PAYMENT_READ({ patientId })
      );
      if(data?.payload?.data?.length === 0){
        alertContext?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "No Data Found",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        setTimeout(() => {
          props?.setAdvanceOpen(false)
        }, 1000);
      }
      setAdvanceData(data?.payload?.data)
  };
  
  const rowData = advanceData?.map((patient, index) => {
    return {
      id: `${index + 1}`,
      receiptno: patient?.receiptno,
      receiptdate: patient?.receiptdate,
      advanceid: patient?.advanceid,
      advanceamount: patient?.advanceamount
    };
  });
 const dispatch = useDispatch();

  const alert = useContext(AlertContext);
  const handleSelectedPrint = (e, value, index) => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const payload = {
      reportid: process.env.REACT_APP_ADVANCE_RECEIPT_REPORT_ID,
      inputparams: {
        "@advanceid": value?.advanceid,
        "@loginpract": practitionerId,
      },

      result: [],
    };
    AxiosCall("post", urls.jasperReport, payload, header)
      .then(async (res) => {
        const response = await axios.get(res?.downloadUrl, {
          method: "GET",
          responseType: "blob",
        });
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });
        const pdfDataUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfDataUrl, "_blank");
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: "No data found for the given inputs",
          severity: "error",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      });
  };
  React.useEffect(() => {
    if(props?.patientDetails?._id){
    advanceDatas(props?.patientDetails?._id);
    }
  }, [props?.patientDetails]);
  return (
    <div
      style={{
        padding: "0px 10px 10px 0px",
        width: "100%",
        margin: "auto",
        position: "relative",
      }}
    >
      <div style={{ maxHeight: "45vh", overflow: "auto" }}>
        <Typography variant="h5" style={{ padding: 10 }}>
          Advance Receipt
        </Typography>
      </div>
      <DataGridComponent
        tableData={rowData}
        Header={Header}
        dataType={dataType}
        // handleCheckBox={handleCheckBox}
        // handleHeaderCheckBox={handleHeaderCheckBox}
        // allChecked={allcheck}
        handleSelectedPrint={handleSelectedPrint}
      />
      {/* <Button
        name={"Cancel Bill"}
        type="button"
        className="pc_medium"
        variant="outlined"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          marginTop: "20px",
          fontSize: "14px",
          padding: "8px 16px",
          marginBottom: "10px",
          backgroundColor: UIColor?.primaryColor?.color,
          border: "none",
          borderRadius: 8,
        }}
        onClick={handlesubmit}
      ></Button> */}
    </div>
  );
};

export default AdvanceViewBill;
