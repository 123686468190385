import React, { useState } from "react";
import {
  Div,
  Paper,
  Select,
  H6,
  Col,
  Row,
  TextInput,
  Text,
} from "qdm-component-library";
// import "./style.css";
import withAllContexts from "../../hoc/withAllContexts";
import { ValidateEmail } from "../../utils";
import { UIColor } from "../../themes/theme";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Typography } from "@material-ui/core";
import { contactModeHelperText, label } from "./handlers";
// class PatientCotactPoint extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   setDetails = (name, value, index, limit) => {
//     if (limit) {
//       if (limit < value.length) {
//         return false;
//       }
//     }
//     let patientData = this.props[this.props.saveto];
//     let contactss = patientData.contact;

//     if (name === "mode") {
//       contactss[index]["mobile"] = "";
//     }

//     if (patientData.contact?.error) {
//       if (name === "mobile") {
//         if (this.label(index) === "Phone") {
//           contactss[index].error[name] = value.length === 10 ? false : true;
//         } else if (
//           this.label(index) === "E-mail" ||
//           this.label(index) === "Email"
//         ) {
//           ValidateEmail(value)
//             ? (contactss[index].error[name] = false)
//             : (contactss[index].error[name] = true);
//         } else {
//           contactss[index].error[name] = false;
//         }
//       } else {
//         contactss[index].error[name] = false;
//       }
//     }
//     contactss[index][name] = value;
//     patientData[this.props.setTo]({
//       ...patientData,
//     });
//   };

//   newRowAdd = () => {
//     let patientData = this.props[this.props.saveto];

//     let value;

//     if (patientData?.contact?.length > 0) {
//       value = {
//         value: "",

//         _id: "",
//       };
//     } else {
//       value = {
//         mode: {
//           value: "Phone",

//           _id: "CodingMaster/10473",
//         },
//       };
//     }

//     patientData.contact.push(value);

//     patientData[this.props.setTo]({
//       ...patientData,
//     });
//   };

//   styles = {
//     labelStyle: {
//       color: "#6F6F6F",
//       fontSize: "12px",
//       marginBottom: "6px",
//       fontFamily: `pc_regular !important`,
//     },
//     borderStyle: {
//       borderRadius: 8,
//       borderColor: "#E0E0E0",
//     },
//     errorborderStyle: {
//       borderRadius: 8,
//       borderColor: "red",
//     },
//   };

//   FlterOptions = (type, sort, i) => {
//     const { allMasters } = this.props;
//     var lista = [];
//     const data = allMasters?.[type];
//     for (let index in data) {
//       if (i != 0 && data[index]?.coding?.display != "Phone") {
//         var datatemp = {
//           value: data[index]?.coding?.display,
//           _id: data[index]?.coding?._id,
//         };
//         lista.push(datatemp);
//       }
//     }
//     if (sort)
//       lista.sort((a, b) =>
//         sort === "asc" ? b.value - a.value : a.value - b.value
//       );
//     return lista;
//   };

//   label = (index) => {
//     let { contact } = this.props[this.props.saveto];

//     if (contact[index]?.mode?.value?.toLowerCase?.() === "phone") {
//       return "Phone";
//     } else if (contact[index]?.mode?.value?.toLowerCase?.() === "fax") {
//       return "Fax";
//     } else if (
//       contact[index]?.mode?.value?.toLowerCase?.() === "email" ||
//       contact[index]?.mode?.value?.toLowerCase?.() === "e-mail"
//     ) {
//       return "Email";
//     } else if (contact[index]?.mode?.value?.toLowerCase() === "url") {
//       return "Url";
//     } else if (contact[index]?.mode?.value?.toLowerCase() === "sms") {
//       return "SMS";
//     } else if (contact[index]?.mode?.value?.toLowerCase() === "other") {
//       return "Other";
//     } else if (contact[index]?.mode?.value?.toLowerCase() === "pager") {
//       return "Pager";
//     } else {
//       return "Mobile";
//     }
//   };

//   contactModeHelperText = (contact, i) => {
//     if (contact[i]?.error && contact?.error["mobile"]) {
//       if (contact[i].mobile && this.label(i) === "Phone") {
//         return "Enter valid mobile number";
//       } else if (
//         (contact[i].mobile && this.label(i) === "Email") ||
//         (contact[i].mobile && this.label(i) === "E-mail")
//       ) {
//         return "Enter valid Email ID";
//       } else {
//         return "";
//       }
//     }
//   };

//   render() {
//     let { contact, statusDetails } = this.props[this.props.saveto];
//     const { labelStyle, borderStyle, errorborderStyle } = this.styles;
//     const { parent_id } = this.props;
//     console.log("contact", contact);
//     return (
//       <Div id={`${parent_id}_contact_point_parent_div`} className="pi-root">
//         <Paper
//           id={`${parent_id}_contact_point_parent_paper`}
//           className="pi-paper"
//         >
//           <Div id={`${parent_id}_contact_point_title_label_div`}>
//             <H6
//               id={`${parent_id}_contact_point_title_label_h6`}
//               className="pi-title"
//             >
//               CONTACT POINT
//             </H6>

//             <Text
//               id={`${parent_id}_patient_name_label_addnew_text`}
//               onClick={() => this.newRowAdd()}
//               className="pi-addbtn"
//             >
//               + Add New
//             </Text>
//           </Div>
//           {contact?.length > 0 &&
//             contact?.map((val, i) => {
//               console.log("i", i);
//               return (
//                 <div>
//                   <Row
//                     id={`${parent_id}_contact_point_parent_row`}
//                     className="pi-actions-root"
//                   >
//                     <Col
//                       id={`${parent_id}_contact_point_contact_mode_col`}
//                       md={3}
//                       lg={3}
//                       sm={6}
//                       xs={12}
//                       className="no-padding-left"
//                     >
//                       <Select
//                         id={`${parent_id}_contact_point_contact_mode_label_select`}
//                         showArrow
//                         label="Contact Mode"
//                         placeholder="select..."
//                         labelStyle={labelStyle}
//                         inLineStyles={
//                           val?.error && contact?.error["mode"]
//                             ? errorborderStyle
//                             : borderStyle
//                         }
//                         hoverColor={UIColor.primaryColor.color}
//                         isRequired
//                         options={this.FlterOptions("CONTACTSYSTEM", false, i)}
//                         disabled={i === 0 ? true : false}
//                         // options={[
//                         //     { value: 'Phone', label: 'Phone' },
//                         //     { value: 'Fax', label: 'Fax' },
//                         //     { value: 'E-mail', label: 'E-mail' },
//                         //     { value: 'url', label: 'url' },
//                         //     { value: 'SMS', label: 'SMS' },
//                         //     { value: 'other', label: 'other' },
//                         // ]}
//                         value={val?.mode?.value ?? null}
//                         onChangeValue={(e) => this.setDetails("mode", e, i)}
//                         // helperText={
//                         // 	contact?.error && contact?.error["mode"] ? "Is required" : ""
//                         // }
//                         error={
//                           contact?.error && contact?.error["mode"]
//                             ? true
//                             : false
//                         }
//                       />
//                     </Col>
//                     {this.label(i) === "Phone" && (
//                       <Col
//                         id={`${parent_id}_contact_point_contact_number_col`}
//                         md={2}
//                         lg={2}
//                         sm={6}
//                         xs={12}
//                         className="no-padding-left"
//                       >
//                         <Typography
//                           className="pc_regular"
//                           style={{ ...labelStyle, margin: "0px 0px 5px 0px" }}
//                         >
//                           Country Code <span style={{ color: "red" }}>*</span>
//                         </Typography>
//                         <Autocomplete
//                           id="combo-box-demo"
//                           options={this.props.Sbi}
//                           getOptionLabel={(option) => option.value}
//                           size={"small"}
//                           isRequired={true}
//                           disabled={
//                             statusDetails?.status1 === "Inactive" ||
//                             statusDetails?.status === "Deceased"
//                               ? true
//                               : false
//                           }
//                           renderInput={(params) => (
//                             <TextField
//                               {...params}
//                               error={
//                                 val?.error &&
//                                 (val?.error["mobile_code"] ||
//                                   val?.error["code_err"])
//                                   ? true
//                                   : false
//                               }
//                               style={
//                                 val?.error &&
//                                 (val?.error["mobile_code"] ||
//                                   val?.error["code_err"])
//                                   ? errorborderStyle
//                                   : borderStyle
//                               }
//                               variant="outlined"
//                             />
//                           )}
//                           onChange={(e, value) =>
//                             this.setDetails("mobile_code", value, i)
//                           }
//                           value={val.mobile_code ? val.mobile_code : ""}
//                         />
//                         {/* <Select
// 								id={`${parent_id}_contact_point_contact_number_select`}
// 								value={contact?.mobilecode?.value ?? null}
// 								options={this.props.Sbi}
// 								// options={[
// 								//     { value: '+91', label: '+91' },
// 								// ]}
// 								onChangeValue={(e) => this.setDetails("mobilecode", e)}
// 								placeholder={this.label()}
// 								abelStyle={labelStyle} style={borderStyle}
// 								showArrow
// 								label="Country Code"
// 								placeholder="select..."
// 								labelStyle={labelStyle}
// 								inLineStyles={borderStyle}
// 								hoverColor={UIColor.primaryColor.color}
// 							/>
// 						</Col>}
// 						<Col
// 							id={`${parent_id}_contact_point_mobile_number_col`}
// 							md={3} lg={3} sm={6} xs={12} className="no-padding-left">
// 							<TextInput
// 								id={`${parent_id}_contact_point_mobile_number_textinput`}
// 								type={((this.label() === 'Mobile') || (this.label() === 'Phone')) ? 'number' : 'type'}
// 								// helperText={(contact?.error && contact?.error['mobile']) ? 'Is required' : ''}
// 								helperText={this.contactModeHelperText(contact)}
// 								value={contact.mobile ? contact.mobile : ""}
// 								onChange={(e) => this.setDetails("mobile", e.target.value, ((this.label() === 'Mobile') || (this.label() === 'Phone')) ? 10 : 15)}
// 								error={(contact?.error && contact?.error['mobile']) ? true : false}
// 								label={this.label()} labelStyle={labelStyle} style={borderStyle}
// 								placeholder={this.label()}
// 								isRequired={true}
// 								// disabled={contact.mode ? false : true}
// 								hoverColor={UIColor.primaryColor.color} />

// 						</Col>
// 						<Col
// 							id={`${parent_id}_contact_point_use_label_col`}
// 							md={1} lg={1} sm={6} xs={12} className="no-padding-left">
// 							<Select
// 								id={`${parent_id}_contact_point_use_label_select`}
// 								showArrow
// 								label="Use"
// 								placeholder="select..."
// 								labelStyle={labelStyle}
// 								inLineStyles={borderStyle}
// 								hoverColor={UIColor.primaryColor.color}
// 								options={this.FlterOptions('ADDRESSUSE')}
// 								// options={[
// 								//     { value: 'Home', label: 'Home' },
// 								//     { value: 'Work', label: 'Work' },
// 								//     { value: 'Temp', label: 'Temp' },
// 								//     { value: 'Old', label: 'Old' },
// 								// ]}
// 								value={contact?.use?.value ?? null}
// 								onChangeValue={(e) => this.setDetails("use", e)}
// 							/>
// 						</Col>
// 						<Col
// 							id={`${parent_id}_contact_point_priority_label_col`}
// 							md={1} lg={1} sm={6} xs={12} className="no-padding-left">
// 							<Select
// 								id={`${parent_id}_contact_point_priority_label_select`}
// 								showArrow
// 								label="Priority"
// 								placeholder="select..."
// 								labelStyle={labelStyle}
// 								inLineStyles={borderStyle}
// 								hoverColor={UIColor.primaryColor.color}
// 								options={this.FlterOptions('PRIORITY')}
// 								// options={[
// 								//     { value: '1', label: '1' },
// 								//     { value: '2', label: '2' },
// 								//     { value: '3', label: '3' },
// 								//     { value: '4', label: '4' },
// 								//     { value: '5', label: '5' },
// 								//     { value: '6', label: '6' },
// 								// ]}
// 								value={contact?.priority?.value ?? null}
// 								onChangeValue={(e) => this.setDetails("priority", e)}
// 							/>
// 						</Col>
// 					</Row>
// 				</Paper>
// 			</Div>

// 		}
// 							/> */}
//                       </Col>
//                     )}
//                     <Col
//                       id={`${parent_id}_contact_point_mobile_number_col`}
//                       md={3}
//                       lg={3}
//                       sm={6}
//                       xs={12}
//                       className="no-padding-left"
//                     >
//                       <TextInput
//                         id={`${parent_id}_contact_point_mobile_number_textinput`}
//                         type={
//                           this.label(i) === "Mobile" ||
//                           this.label(i) === "Phone"
//                             ? "number"
//                             : "type"
//                         }
//                         // helperText={(contact?.error && contact?.error['mobile']) ? 'Is required' : ''}
//                         disabled={
//                           statusDetails?.status1 === "Inactive" ||
//                           statusDetails?.status === "Deceased"
//                             ? true
//                             : false
//                         }
//                         helperText={this.contactModeHelperText(contact, i)}
//                         value={val.mobile ? val.mobile : ""}
//                         onChange={(e) =>
//                           this.setDetails(
//                             "mobile",
//                             e.target.value,
//                             i,
//                             this.label(i) === "Mobile" ||
//                               this.label(i) === "Phone"
//                               ? 20
//                               : 40
//                           )
//                         }
//                         error={
//                           val?.error && val?.error["mobile"] ? true : false
//                         }
//                         label={this.label(i)}
//                         labelStyle={labelStyle}
//                         style={
//                           contact?.error &&
//                           (contact?.error["mobile"] ||
//                             contact?.error["number_err"])
//                             ? errorborderStyle
//                             : borderStyle
//                         }
//                         placeholder={this.label(i)}
//                         isRequired={true}
//                         // disabled={contact.mode ? false : true}
//                         hoverColor={UIColor.primaryColor.color}
//                       />
//                     </Col>
//                     <Col
//                       id={`${parent_id}_contact_point_use_label_col`}
//                       md={1}
//                       lg={1}
//                       sm={6}
//                       xs={12}
//                       className="no-padding-left"
//                     >
//                       <Select
//                         id={`${parent_id}_contact_point_use_label_select`}
//                         showArrow
//                         label="Use"
//                         placeholder="select..."
//                         labelStyle={labelStyle}
//                         inLineStyles={borderStyle}
//                         hoverColor={UIColor.primaryColor.color}
//                         options={this.FlterOptions("ADDRESSUSE")}
//                         // options={[
//                         //     { value: 'Home', label: 'Home' },
//                         //     { value: 'Work', label: 'Work' },
//                         //     { value: 'Temp', label: 'Temp' },
//                         //     { value: 'Old', label: 'Old' },
//                         // ]}
//                         value={val?.use?.value ?? null}
//                         onChangeValue={(e) => this.setDetails("use", e, i)}
//                         disabled={
//                           statusDetails?.status1 === "Inactive" ||
//                           statusDetails?.status === "Deceased"
//                             ? true
//                             : false
//                         }
//                       />
//                     </Col>
//                     <Col
//                       id={`${parent_id}_contact_point_priority_label_col`}
//                       md={1}
//                       lg={1}
//                       sm={6}
//                       xs={12}
//                       className="no-padding-left"
//                     >
//                       <Select
//                         id={`${parent_id}_contact_point_priority_label_select`}
//                         showArrow
//                         label="Priority"
//                         placeholder="select..."
//                         labelStyle={labelStyle}
//                         inLineStyles={borderStyle}
//                         hoverColor={UIColor.primaryColor.color}
//                         options={this.FlterOptions("PRIORITY", "asc")}
//                         // options={[
//                         //     { value: '1', label: '1' },
//                         //     { value: '2', label: '2' },
//                         //     { value: '3', label: '3' },
//                         //     { value: '4', label: '4' },
//                         //     { value: '5', label: '5' },
//                         //     { value: '6', label: '6' },
//                         // ]}
//                         value={val?.priority?.value ?? null}
//                         onChangeValue={(e) => this.setDetails("priority", e, i)}
//                         disabled={
//                           statusDetails?.status1 === "Inactive" ||
//                           statusDetails?.status === "Deceased"
//                             ? true
//                             : false
//                         }
//                       />
//                     </Col>
//                   </Row>
//                 </div>
//               );
//             })}
//         </Paper>
//       </Div>
//     );
//   }
// }

const ContactPoint = (props) => {
  let styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorborderStyle: {
      borderRadius: 8,
      borderColor: "red",
    },
  };
  const { parent_id } = props;
  let { contact, statusDetails } = props[props.saveto];
  const [state, setState] = useState({});
  const { labelStyle, borderStyle, errorborderStyle } = styles;

  const setDetails = (name, value, index, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let patientData = props[props.saveto];
    let contactss = patientData.contact;

    if (name === "mode") {
      contactss[index]["mobile"] = "";
    }

    if (patientData.contact?.error) {
      if (name === "mobile") {
        if (label(props, index) === "Phone") {
          contactss[index].error[name] = value.length === 10 ? false : true;
        } else if (
          label(props, index) === "E-mail" ||
          label(props, index) === "Email"
        ) {
          ValidateEmail(value)
            ? (contactss[index].error[name] = false)
            : (contactss[index].error[name] = true);
        } else {
          contactss[index].error[name] = false;
        }
      } else {
        contactss[index].error[name] = false;
      }
    }
    contactss[index][name] = value;
    patientData[props.setTo]({
      ...patientData,
    });
  };

  const newRowAdd = () => {
    let patientData = props[props.saveto];

    let value;

    if (patientData?.contact?.length > 0) {
      value = {
        value: "",

        _id: "",
      };
    } else {
      value = {
        mode: {
          value: "Phone",

          _id: "CodingMaster/10473",
        },
      };
    }

    patientData.contact.push(value);

    patientData[props.setTo]({
      ...patientData,
    });
  };

  const FlterOptions = (type, sort, i) => {
    const { allMasters } = props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      if (i != 0 && data[index]?.coding?.display != "Phone") {
        var datatemp = {
          value: data[index]?.coding?.display,
          _id: data[index]?.coding?._id,
        };
        lista.push(datatemp);
      }
    }
    if (sort)
      lista.sort((a, b) =>
        sort === "asc" ? b.value - a.value : a.value - b.value
      );
    return lista;
  };

  return (
    <Div id={`${parent_id}_contact_point_parent_div`} className="pi-root">
      <Paper
        id={`${parent_id}_contact_point_parent_paper`}
        className="pi-paper"
      >
        <Div id={`${parent_id}_contact_point_title_label_div`}>
          <H6
            id={`${parent_id}_contact_point_title_label_h6`}
            className="pi-title"
          >
            CONTACT POINT
          </H6>

          <Text
            id={`${parent_id}_patient_name_label_addnew_text`}
            onClick={() => newRowAdd()}
            className="pi-addbtn"
          >
            + Add New
          </Text>
        </Div>
        {contact?.length > 0 &&
          contact?.map((val, i) => {
            console.log("i", i);
            return (
              <div>
                <Row
                  id={`${parent_id}_contact_point_parent_row`}
                  className="pi-actions-root"
                >
                  <Col
                    id={`${parent_id}_contact_point_contact_mode_col`}
                    md={3}
                    lg={3}
                    sm={6}
                    xs={12}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_contact_point_contact_mode_label_select`}
                      showArrow
                      label="Contact Mode"
                      placeholder="select..."
                      labelStyle={labelStyle}
                      inLineStyles={
                        val?.error && contact?.error["mode"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                      isRequired
                      options={FlterOptions("CONTACTSYSTEM", false, i)}
                      disabled={i === 0 ? true : false}
                      // options={[
                      //     { value: 'Phone', label: 'Phone' },
                      //     { value: 'Fax', label: 'Fax' },
                      //     { value: 'E-mail', label: 'E-mail' },
                      //     { value: 'url', label: 'url' },
                      //     { value: 'SMS', label: 'SMS' },
                      //     { value: 'other', label: 'other' },
                      // ]}
                      value={val?.mode?.value ?? null}
                      onChangeValue={(e) => setDetails("mode", e, i)}
                      // helperText={
                      // 	contact?.error && contact?.error["mode"] ? "Is required" : ""
                      // }
                      error={
                        contact?.error && contact?.error["mode"] ? true : false
                      }
                    />
                  </Col>
                  {label(props, i) === "Phone" && (
                    <Col
                      id={`${parent_id}_contact_point_contact_number_col`}
                      md={2}
                      lg={2}
                      sm={6}
                      xs={12}
                      className="no-padding-left"
                    >
                      <Typography
                        className="pc_regular"
                        style={{ ...labelStyle, margin: "0px 0px 5px 0px" }}
                      >
                        Country Code <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <Autocomplete
                        id="combo-box-demo"
                        options={this.props.Sbi}
                        getOptionLabel={(option) => option.value}
                        size={"small"}
                        isRequired={true}
                        disabled={
                          statusDetails?.status1 === "Inactive" ||
                          statusDetails?.status === "Deceased"
                            ? true
                            : false
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              val?.error &&
                              (val?.error["mobile_code"] ||
                                val?.error["code_err"])
                                ? true
                                : false
                            }
                            style={
                              val?.error &&
                              (val?.error["mobile_code"] ||
                                val?.error["code_err"])
                                ? errorborderStyle
                                : borderStyle
                            }
                            variant="outlined"
                          />
                        )}
                        onChange={(e, value) =>
                          setDetails("mobile_code", value, i)
                        }
                        value={val.mobile_code ? val.mobile_code : ""}
                      />
                      {/* <Select
								id={`${parent_id}_contact_point_contact_number_select`}
								value={contact?.mobilecode?.value ?? null}
								options={props.Sbi}
								// options={[
								//     { value: '+91', label: '+91' },
								// ]}
								onChangeValue={(e) => this.setDetails("mobilecode", e)}
								placeholder={label(props)}
								abelStyle={labelStyle} style={borderStyle}
								showArrow
								label="Country Code"
								placeholder="select..."
								labelStyle={labelStyle}
								inLineStyles={borderStyle}
								hoverColor={UIColor.primaryColor.color}
							/>
						</Col>}
						<Col
							id={`${parent_id}_contact_point_mobile_number_col`}
							md={3} lg={3} sm={6} xs={12} className="no-padding-left">
							<TextInput
								id={`${parent_id}_contact_point_mobile_number_textinput`}
								type={((label(props) === 'Mobile') || (label(props) === 'Phone')) ? 'number' : 'type'}
								// helperText={(contact?.error && contact?.error['mobile']) ? 'Is required' : ''}
								helperText={this.contactModeHelperText(contact)}
								value={contact.mobile ? contact.mobile : ""}
								onChange={(e) => setDetails("mobile", e.target.value, ((label(props) === 'Mobile') || (label(props) === 'Phone')) ? 10 : 15)}
								error={(contact?.error && contact?.error['mobile']) ? true : false}
								label={label(props)} labelStyle={labelStyle} style={borderStyle}
								placeholder={label(props)}
								isRequired={true}
								// disabled={contact.mode ? false : true}
								hoverColor={UIColor.primaryColor.color} />
	
						</Col>
						<Col
							id={`${parent_id}_contact_point_use_label_col`}
							md={1} lg={1} sm={6} xs={12} className="no-padding-left">
							<Select
								id={`${parent_id}_contact_point_use_label_select`}
								showArrow
								label="Use"
								placeholder="select..."
								labelStyle={labelStyle}
								inLineStyles={borderStyle}
								hoverColor={UIColor.primaryColor.color}
								options={FlterOptions('ADDRESSUSE')}
								// options={[
								//     { value: 'Home', label: 'Home' },
								//     { value: 'Work', label: 'Work' },
								//     { value: 'Temp', label: 'Temp' },
								//     { value: 'Old', label: 'Old' },
								// ]}
								value={contact?.use?.value ?? null}
								onChangeValue={(e) => setDetails("use", e)}
							/>
						</Col>
						<Col
							id={`${parent_id}_contact_point_priority_label_col`}
							md={1} lg={1} sm={6} xs={12} className="no-padding-left">
							<Select
								id={`${parent_id}_contact_point_priority_label_select`}
								showArrow
								label="Priority"
								placeholder="select..."
								labelStyle={labelStyle}
								inLineStyles={borderStyle}
								hoverColor={UIColor.primaryColor.color}
								options={FlterOptions('PRIORITY')}
								// options={[
								//     { value: '1', label: '1' },
								//     { value: '2', label: '2' },
								//     { value: '3', label: '3' },
								//     { value: '4', label: '4' },
								//     { value: '5', label: '5' },
								//     { value: '6', label: '6' },
								// ]}
								value={contact?.priority?.value ?? null}
								onChangeValue={(e) => setDetails("priority", e)}
							/>
						</Col>
					</Row>
				</Paper>
			</Div>
	
		}
							/> */}
                    </Col>
                  )}
                  <Col
                    id={`${parent_id}_contact_point_mobile_number_col`}
                    md={3}
                    lg={3}
                    sm={6}
                    xs={12}
                    className="no-padding-left"
                  >
                    <TextInput
                      id={`${parent_id}_contact_point_mobile_number_textinput`}
                      type={
                        label(props, i) === "Mobile" ||
                        label(props, i) === "Phone"
                          ? "number"
                          : "type"
                      }
                      // helperText={(contact?.error && contact?.error['mobile']) ? 'Is required' : ''}
                      disabled={
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                      helperText={this.contactModeHelperText(contact, i)}
                      value={val.mobile ? val.mobile : ""}
                      onChange={(e) =>
                        setDetails(
                          "mobile",
                          e.target.value,
                          i,
                          label(props, i) === "Mobile" ||
                            label(props, i) === "Phone"
                            ? 20
                            : 40
                        )
                      }
                      error={val?.error && val?.error["mobile"] ? true : false}
                      label={label(props, i)}
                      labelStyle={labelStyle}
                      style={
                        contact?.error &&
                        (contact?.error["mobile"] ||
                          contact?.error["number_err"])
                          ? errorborderStyle
                          : borderStyle
                      }
                      placeholder={label(props, i)}
                      isRequired={true}
                      // disabled={contact.mode ? false : true}
                      hoverColor={UIColor.primaryColor.color}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_contact_point_use_label_col`}
                    md={1}
                    lg={1}
                    sm={6}
                    xs={12}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_contact_point_use_label_select`}
                      showArrow
                      label="Use"
                      placeholder="select..."
                      labelStyle={labelStyle}
                      inLineStyles={borderStyle}
                      hoverColor={UIColor.primaryColor.color}
                      options={FlterOptions("ADDRESSUSE")}
                      // options={[
                      //     { value: 'Home', label: 'Home' },
                      //     { value: 'Work', label: 'Work' },
                      //     { value: 'Temp', label: 'Temp' },
                      //     { value: 'Old', label: 'Old' },
                      // ]}
                      value={val?.use?.value ?? null}
                      onChangeValue={(e) => setDetails("use", e, i)}
                      disabled={
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_contact_point_priority_label_col`}
                    md={1}
                    lg={1}
                    sm={6}
                    xs={12}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_contact_point_priority_label_select`}
                      showArrow
                      label="Priority"
                      placeholder="select..."
                      labelStyle={labelStyle}
                      inLineStyles={borderStyle}
                      hoverColor={UIColor.primaryColor.color}
                      options={FlterOptions("PRIORITY", "asc")}
                      // options={[
                      //     { value: '1', label: '1' },
                      //     { value: '2', label: '2' },
                      //     { value: '3', label: '3' },
                      //     { value: '4', label: '4' },
                      //     { value: '5', label: '5' },
                      //     { value: '6', label: '6' },
                      // ]}
                      value={val?.priority?.value ?? null}
                      onChangeValue={(e) => setDetails("priority", e, i)}
                      disabled={
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                    />
                  </Col>
                </Row>
              </div>
            );
          })}
      </Paper>
    </Div>
  );
};

export default withAllContexts(ContactPoint); // withallcontext here
