import React from "react";
 
export const InsightsIcon = (props) => {
  let width = props.width ? props.width : "24";
  let height = props.height ? props.height : "24";
  let color = props.color ? props.color : "#707070";
  return (
    <svg
    id="InsightsIcon_svg"
    xmlns="http://www.w3.org/2000/svg" width={width} height={height} style={props?.style} viewBox="0 0 24 24">
        <g id="icons8-insights" transform="translate(0 -0.012)">
            <g id="Group_90474" data-name="Group 90474" transform="translate(10.52 3.363)">
            <g id="Group_90473" data-name="Group 90473">
                <path id="Path_92788" data-name="Path 92788" d="M241.823,59.99a.838.838,0,0,0,0,1.676,5.035,5.035,0,0,1,5.029,5.029.838.838,0,1,0,1.676,0A6.713,6.713,0,0,0,241.823,59.99Z" transform="translate(-240.985 -59.99)" fill={color}/>
            </g>
            </g>
            <g id="Group_90476" data-name="Group 90476" transform="translate(3.442 0.012)">
            <g id="Group_90475" data-name="Group 90475">
                <path id="Path_92789" data-name="Path 92789" d="M82.729.2a8.454,8.454,0,0,0-6.523,6.412,8.352,8.352,0,0,0,1.963,7.435,5.435,5.435,0,0,1,1.406,3.59v1.406a2.116,2.116,0,0,0,1.478,2.006,3.565,3.565,0,0,0,7.028,0,2.116,2.116,0,0,0,1.478-2.006V17.637A5.508,5.508,0,0,1,91.008,14a8.351,8.351,0,0,0,2.119-5.552A8.537,8.537,0,0,0,82.729.2Zm1.838,22.405a2.18,2.18,0,0,1-2.018-1.458h4.035A2.18,2.18,0,0,1,84.568,22.606Zm3.566-3.563a.709.709,0,0,1-.713.7H81.715a.709.709,0,0,1-.713-.7v-.7h7.132Zm1.8-5.969a7,7,0,0,0-1.762,3.86h-7.21a6.9,6.9,0,0,0-1.727-3.821A6.972,6.972,0,0,1,77.6,6.9,7.146,7.146,0,0,1,91.7,8.449,6.957,6.957,0,0,1,89.935,13.073Z" transform="translate(-76.01 -0.012)" fill={color}/>
            </g>
            </g>
            <g id="Group_90478" data-name="Group 90478" transform="translate(0 8.348)">
            <g id="Group_90477" data-name="Group 90477">
                <path id="Path_92790" data-name="Path 92790" d="M2.514,179.99H.838a.838.838,0,0,0,0,1.676H2.514a.838.838,0,0,0,0-1.676Z" transform="translate(0 -179.99)" fill={color}/>
            </g>
            </g>
            <g id="Group_90480" data-name="Group 90480" transform="translate(0.245 3.653)">
            <g id="Group_90479" data-name="Group 90479">
                <path id="Path_92791" data-name="Path 92791" d="M7.009,80.814,5.824,79.629a.838.838,0,0,0-1.185,1.185L5.824,82a.838.838,0,1,0,1.185-1.185Z" transform="translate(-4.394 -79.383)" fill={color}/>
            </g>
            </g>
            <g id="Group_90482" data-name="Group 90482" transform="translate(0.245 11.911)">
            <g id="Group_90481" data-name="Group 90481">
                <path id="Path_92792" data-name="Path 92792" d="M7.009,259.629a.838.838,0,0,0-1.185,0l-1.185,1.185A.838.838,0,0,0,5.824,262l1.185-1.185A.838.838,0,0,0,7.009,259.629Z" transform="translate(-4.393 -259.383)" fill={color}/>
            </g>
            </g>
            <g id="Group_90484" data-name="Group 90484" transform="translate(20.648 8.348)">
            <g id="Group_90483" data-name="Group 90483">
                <path id="Path_92793" data-name="Path 92793" d="M454.514,179.99h-1.676a.838.838,0,1,0,0,1.676h1.676a.838.838,0,0,0,0-1.676Z" transform="translate(-452 -179.99)" fill={color}/>
            </g>
            </g>
            <g id="Group_90486" data-name="Group 90486" transform="translate(20.893 3.653)">
            <g id="Group_90485" data-name="Group 90485">
                <path id="Path_92794" data-name="Path 92794" d="M459.009,79.629a.838.838,0,0,0-1.185,0l-1.185,1.185A.838.838,0,0,0,457.824,82l1.185-1.185A.838.838,0,0,0,459.009,79.629Z" transform="translate(-456.393 -79.383)" fill={color}/>
            </g>
            </g>
            <g id="Group_90488" data-name="Group 90488" transform="translate(20.893 11.911)">
            <g id="Group_90487" data-name="Group 90487">
                <path id="Path_92795" data-name="Path 92795" d="M459.009,260.814l-1.185-1.185a.838.838,0,0,0-1.185,1.185L457.824,262a.838.838,0,0,0,1.185-1.185Z" transform="translate(-456.393 -259.383)" fill={color}/>
            </g>
            </g>
        </g>
    </svg>
  );
};