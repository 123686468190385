import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  hospitalRequest,
  urls,
} from "../../utils";

const SYMPTOM_SPECIALITY_DOCTOR_SEARCH_CALL = createAsyncThunk(
  `searchHospitalSlice/symptom_speciality_doctor_data`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.appointment_search_symptom_speciality_doctor
      );
      let result = data?.response?.Response;
      console.log(data);
      return {
        ...defaultState.Info,
        data: result,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const HOSPITAL_SEARCH_CALL = createAsyncThunk(
  `searchHospitalSlice/hospital_data`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.appointment_search_hospital
      );

      let result = data?.response?.Response;

      return {
        ...defaultState.Info,
        data: result ?? [],
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const TypeOfBookAppmntButton = createAsyncThunk(
  `searchHospitalSlice/typeOfbookapmnt`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      
      const data = await fetchData(
        {
          body: JSON.stringify(hospitalRequest.typeofbkapmntcall()),
        },
        urls.readQdmQueries
      );

      let result = data?.[0].APPTYPE?.Value;

      return {
        ...defaultState.Info,
        data: result ?? [],
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const searchHospitalSlice = createSlice({
  name: "searchHospitalSlice",
  initialState: {
    symptom_speciality_doctor_data: {
      ...defaultState.List,
    },
    hospital_data: {
      ...defaultState.List,
    },
    typeOfbookapmnt: { ...defaultState.List },
  },
  extraReducers: {
    /*  */
    [SYMPTOM_SPECIALITY_DOCTOR_SEARCH_CALL.fulfilled]: (state, action) => {
      state.symptom_speciality_doctor_data = action?.payload ?? [];
    },
    [SYMPTOM_SPECIALITY_DOCTOR_SEARCH_CALL.pending]: (state, action) => {
      state.symptom_speciality_doctor_data.loading = true;
    },
    [SYMPTOM_SPECIALITY_DOCTOR_SEARCH_CALL.rejected]: (state, action) => {
      state.symptom_speciality_doctor_data = action?.payload ?? [];
    },
    /*  */
    [HOSPITAL_SEARCH_CALL.fulfilled]: (state, action) => {
      state.hospital_data = action?.payload ?? [];
    },
    [HOSPITAL_SEARCH_CALL.pending]: (state, action) => {
      state.hospital_data.loading = true;
    },
    [HOSPITAL_SEARCH_CALL.rejected]: (state, action) => {
      state.hospital_data = action?.payload ?? [];
    },
    [TypeOfBookAppmntButton.fulfilled]: (state, action) => {
      state.typeOfbookapmnt = action?.payload ?? [];
    },
    [TypeOfBookAppmntButton.pending]: (state, action) => {
      state.typeOfbookapmnt.loading = true;
    },
    [TypeOfBookAppmntButton.rejected]: (state, action) => {
      state.typeOfbookapmnt = action?.payload ?? [];
    },
  },
});
// end

//  ACTION LISTS
const searchHospitalActions = {
  SYMPTOM_SPECIALITY_DOCTOR_SEARCH_CALL,
  HOSPITAL_SEARCH_CALL,
  TypeOfBookAppmntButton,
};
// end

export { searchHospitalActions };

export default searchHospitalSlice;
