import React from "react";

export const TemperatureIcon = (props) => {
  let width = props.width ? props.width : "8";
  let height = props.height ? props.height : "19";
//   let color = props.color ? props.color : "#000000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 8 19">
    <g id="icons8-temperature" transform="translate(-12 -1)">
         <path id="Path_92639" data-name="Path 92639" d="M17.75,11.857a2.253,2.253,0,0,1-2.25-2.25V3.75a2.25,2.25,0,0,1,4.5,0V9.607A2.252,2.252,0,0,1,17.75,11.857Z" transform="translate(-1.75 -0.25)" fill="#dff0fe"/>
        <path id="Path_92640" data-name="Path 92640" d="M17.5,1.5a2,2,0,0,1,2,2V9.357a2,2,0,0,1-4,0V3.5a2,2,0,0,1,2-2m0-.5A2.507,2.507,0,0,0,15,3.5V9.357a2.5,2.5,0,0,0,5,0V3.5A2.507,2.507,0,0,0,17.5,1Z" transform="translate(-1.5)" fill="#4788c7"/>
        <path id="Path_92641" data-name="Path 92641" d="M16.25,27a3.748,3.748,0,0,1-2.344-6.675L14,20.25V15.5h4.5v4.75l.094.075A3.748,3.748,0,0,1,16.25,27Z" transform="translate(-0.25 -7.25)" fill="#98ccfd"/>
        <path id="Path_92642" data-name="Path 92642" d="M18,15.5v4.62l.187.15a3.5,3.5,0,1,1-4.374,0L14,20.12V15.5h4m.5-.5h-5v4.88a4,4,0,1,0,5,0V15Z" transform="translate(0 -7)" fill="#4788c7"/>
        <path id="Path_92643" data-name="Path 92643" d="M20.25,13H22.5v.5H20.25a.25.25,0,0,1-.25-.25h0A.25.25,0,0,1,20.25,13Zm0,2H22.5v.5H20.25a.25.25,0,0,1-.25-.25h0A.25.25,0,0,1,20.25,15Zm0-6H22.5v.5H20.25A.25.25,0,0,1,20,9.25h0A.25.25,0,0,1,20.25,9Zm0-2H22.5v.5H20.25A.25.25,0,0,1,20,7.25h0A.25.25,0,0,1,20.25,7Z" transform="translate(-4 -3)" fill="#4788c7"/>
    </g>
    </svg>
    );
};