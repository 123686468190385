import React from "react";

export const CalendarIcon = (props) => {
  let width = props.width ? props.width : "20";
  let height = props.height ? props.height : "20";
  let color = props.color ? props.color : "#000000";
  return (
    <svg
    id="CalendarIcon_svg"
    xmlns="http://www.w3.org/2000/svg" style={props?.style} width={width} height={height} viewBox="0 0 20 20">
        <path class="a" fill={color} d="M9.611,6A3.624,3.624,0,0,0,6,9.611V22.389A3.624,3.624,0,0,0,9.611,26H22.389A3.624,3.624,0,0,0,26,22.389V9.611A3.624,3.624,0,0,0,22.389,6Zm0,1.667H22.389a1.932,1.932,0,0,1,1.944,1.944v.833H7.667V9.611A1.932,1.932,0,0,1,9.611,7.667ZM7.667,12.111H24.333V22.389a1.932,1.932,0,0,1-1.944,1.944H9.611a1.932,1.932,0,0,1-1.944-1.944Zm3.611,2.222a1.389,1.389,0,1,0,1.389,1.389A1.389,1.389,0,0,0,11.278,14.333Zm4.722,0a1.389,1.389,0,1,0,1.389,1.389A1.389,1.389,0,0,0,16,14.333Zm4.722,0a1.389,1.389,0,1,0,1.389,1.389A1.389,1.389,0,0,0,20.722,14.333Zm-9.444,5a1.389,1.389,0,1,0,1.389,1.389A1.389,1.389,0,0,0,11.278,19.333Zm4.722,0a1.389,1.389,0,1,0,1.389,1.389A1.389,1.389,0,0,0,16,19.333Z" transform="translate(-6 -6)"/></svg>
    );
};