export const countryCode_ = [
    {
        "countryName": "Afghanistan",
        "dial_code": "+93",
        "code": "AF"
    },
    {
        "countryName": "Albania",
        "dial_code": "+355",
        "code": "AL"
    },
    {
        "countryName": "Algeria",
        "dial_code": "+213",
        "code": "DZ"
    },
    {
        "countryName": "Andorra",
        "dial_code": "+376",
        "code": "AD"
    },
    {
        "countryName": "Angola",
        "dial_code": "+244",
        "code": "AO"
    },
    {
        "countryName": "Antarctica",
        "dial_code": "+672",
        "code": "AQ"
    },
    {
        "countryName": "Antigua & Barbuda",
        "dial_code": "+1268",
        "code": "AG"
    },
    {
        "countryName": "Argentina",
        "dial_code": "+54",
        "code": "AR"
    },
    {
        "countryName": "Armenia",
        "dial_code": "+374",
        "code": "AM"
    },
    {
        "countryName": "Aruba",
        "dial_code": "+297",
        "code": "AW"
    },
    {
        "countryName": "Australia",
        "dial_code": "+61",
        "code": "AU"
    },
    {
        "countryName": "Austria",
        "dial_code": "+43",
        "code": "AT"
    },
    {
        "countryName": "Azerbaijan",
        "dial_code": "+994",
        "code": "AZ"
    },
    {
        "countryName": "Bahrain",
        "dial_code": "+973",
        "code": "BH"
    },
    {
        "countryName": "Bangladesh",
        "dial_code": "+880",
        "code": "BD"
    },
    {
        "countryName": "Belarus",
        "dial_code": "+375",
        "code": "BY"
    },
    {
        "countryName": "Belgium",
        "dial_code": "+32",
        "code": "BE"
    },
    {
        "countryName": "Belize",
        "dial_code": "+501",
        "code": "BZ"
    },
    {
        "countryName": "Benin",
        "dial_code": "+229",
        "code": "BJ"
    },
    {
        "countryName": "Bhutan",
        "dial_code": "+975",
        "code": "BT"
    },
    {
        "countryName": "Bolivia",
        "dial_code": "+591",
        "code": "BO"
    },
    {
        "countryName": "Bosnia & Herzegovina",
        "dial_code": "+387",
        "code": "BA"
    },
    {
        "countryName": "Botswana",
        "dial_code": "+267",
        "code": "BW"
    },
    {
        "countryName": "Brazil",
        "dial_code": "+55",
        "code": "BR"
    },
    {
        "countryName": "British IOT",
        "dial_code": "+246",
        "code": "IO"
    },
    {
        "countryName": "Brunei Darussalam",
        "dial_code": "+673",
        "code": "BN"
    },
    {
        "countryName": "Bulgaria",
        "dial_code": "+359",
        "code": "BG"
    },
    {
        "countryName": "Burkina Faso",
        "dial_code": "+226",
        "code": "BF"
    },
    {
        "countryName": "Burundi",
        "dial_code": "+257",
        "code": "BI"
    },
    {
        "countryName": "Cambodia",
        "dial_code": "+855",
        "code": "KH"
    },
    {
        "countryName": "Cameroon",
        "dial_code": "+237",
        "code": "CM"
    },
    {
        "countryName": "Cape Verde",
        "dial_code": "+238",
        "code": "CV"
    },
    {
        "countryName": "Cayman Islands",
        "dial_code": "+345",
        "code": "KY"
    },
    {
        "countryName": "Central Africa",
        "dial_code": "+236",
        "code": "CF"
    },
    {
        "countryName": "Chad",
        "dial_code": "+235",
        "code": "TD"
    },
    {
        "countryName": "Chile",
        "dial_code": "+56",
        "code": "CL"
    },
    {
        "countryName": "China",
        "dial_code": "+86",
        "code": "CN"
    },
    {
        "countryName": "Colombia",
        "dial_code": "+57",
        "code": "CO"
    },
    {
        "countryName": "Comoros",
        "dial_code": "+269",
        "code": "KM"
    },
    {
        "countryName": "Congo",
        "dial_code": "+242",
        "code": "CG"
    },
    {
        "countryName": "Congo",
        "dial_code": "+243",
        "code": "CD"
    },
    {
        "countryName": "Cook Islands",
        "dial_code": "+682",
        "code": "CK"
    },
    {
        "countryName": "Costa Rica",
        "dial_code": "+506",
        "code": "CR"
    },
    {
        "countryName": "Cote d'Ivoire",
        "dial_code": "+225",
        "code": "CI"
    },
    {
        "countryName": "Croatia",
        "dial_code": "+385",
        "code": "HR"
    },
    {
        "countryName": "Cuba",
        "dial_code": "+53",
        "code": "CU"
    },
    {
        "countryName": "Cyprus",
        "dial_code": "+537",
        "code": "CY"
    },
    {
        "countryName": "Czech Republic",
        "dial_code": "+420",
        "code": "CZ"
    },
    {
        "countryName": "Denmark",
        "dial_code": "+45",
        "code": "DK"
    },
    {
        "countryName": "Djibouti",
        "dial_code": "+253",
        "code": "DJ"
    },
    {
        "countryName": "Ecuador",
        "dial_code": "+593",
        "code": "EC"
    },
    {
        "countryName": "Egypt",
        "dial_code": "+20",
        "code": "EG"
    },
    {
        "countryName": "El Salvador",
        "dial_code": "+503",
        "code": "SV"
    },
    {
        "countryName": "Equatorial Guinea",
        "dial_code": "+240",
        "code": "GQ"
    },
    {
        "countryName": "Eritrea",
        "dial_code": "+291",
        "code": "ER"
    },
    {
        "countryName": "Estonia",
        "dial_code": "+372",
        "code": "EE"
    },
    {
        "countryName": "Ethiopia",
        "dial_code": "+251",
        "code": "ET"
    },
    {
        "countryName": "Faroe Islands",
        "dial_code": "+298",
        "code": "FO"
    },
    {
        "countryName": "Fiji",
        "dial_code": "+679",
        "code": "FJ"
    },
    {
        "countryName": "Finland",
        "dial_code": "+358",
        "code": "FI"
    },
    {
        "countryName": "France",
        "dial_code": "+33",
        "code": "FR"
    },
    {
        "countryName": "French Guiana",
        "dial_code": "+594",
        "code": "GF"
    },
    {
        "countryName": "French Polynesia",
        "dial_code": "+689",
        "code": "PF"
    },
    {
        "countryName": "Gabon",
        "dial_code": "+241",
        "code": "GA"
    },
    {
        "countryName": "Gambia",
        "dial_code": "+220",
        "code": "GM"
    },
    {
        "countryName": "Georgia",
        "dial_code": "+995",
        "code": "GE"
    },
    {
        "countryName": "Germany",
        "dial_code": "+49",
        "code": "DE"
    },
    {
        "countryName": "Ghana",
        "dial_code": "+233",
        "code": "GH"
    },
    {
        "countryName": "Gibraltar",
        "dial_code": "+350",
        "code": "GI"
    },
    {
        "countryName": "Greece",
        "dial_code": "+30",
        "code": "GR"
    },
    {
        "countryName": "Greenland",
        "dial_code": "+299",
        "code": "GL"
    },
    {
        "countryName": "Guadeloupe",
        "dial_code": "+590",
        "code": "GP"
    },
    {
        "countryName": "Guatemala",
        "dial_code": "+502",
        "code": "GT"
    },
    {
        "countryName": "Guinea",
        "dial_code": "+224",
        "code": "GN"
    },
    {
        "countryName": "Guinea-Bissau",
        "dial_code": "+245",
        "code": "GW"
    },
    {
        "countryName": "Haiti",
        "dial_code": "+509",
        "code": "HT"
    },
    {
        "countryName": "Holy See",
        "dial_code": "+379",
        "code": "VA"
    },
    {
        "countryName": "Honduras",
        "dial_code": "+504",
        "code": "HN"
    },
    {
        "countryName": "Hong Kong",
        "dial_code": "+852",
        "code": "HK"
    },
    {
        "countryName": "Hungary",
        "dial_code": "+36",
        "code": "HU"
    },
    {
        "countryName": "Iceland",
        "dial_code": "+354",
        "code": "IS"
    },
    {
        "countryName": "India",
        "dial_code": "+91",
        "code": "IN"
    },
    {
        "countryName": "Indonesia",
        "dial_code": "+62",
        "code": "ID"
    },
    {
        "countryName": "Iran",
        "dial_code": "+98",
        "code": "IR"
    },
    {
        "countryName": "Iraq",
        "dial_code": "+964",
        "code": "IQ"
    },
    {
        "countryName": "Ireland",
        "dial_code": "+353",
        "code": "IE"
    },
    {
        "countryName": "Israel",
        "dial_code": "+972",
        "code": "IL"
    },
    {
        "countryName": "Italy",
        "dial_code": "+39",
        "code": "IT"
    },
    {
        "countryName": "Japan",
        "dial_code": "+81",
        "code": "JP"
    },
    {
        "countryName": "Jordan",
        "dial_code": "+962",
        "code": "JO"
    },
    {
        "countryName": "Kenya",
        "dial_code": "+254",
        "code": "KE"
    },
    {
        "countryName": "Kiribati",
        "dial_code": "+686",
        "code": "KI"
    },
    {
        "countryName": "Korea",
        "dial_code": "+82",
        "code": "KR"
    },
    {
        "countryName": "Korea",
        "dial_code": "+850",
        "code": "KP"
    },
    {
        "countryName": "Kuwait",
        "dial_code": "+965",
        "code": "KW"
    },
    {
        "countryName": "Kyrgyzstan",
        "dial_code": "+996",
        "code": "KG"
    },
    {
        "countryName": "Lao",
        "dial_code": "+856",
        "code": "LA"
    },
    {
        "countryName": "Latvia",
        "dial_code": "+371",
        "code": "LV"
    },
    {
        "countryName": "Lebanon",
        "dial_code": "+961",
        "code": "LB"
    },
    {
        "countryName": "Lesotho",
        "dial_code": "+266",
        "code": "LS"
    },
    {
        "countryName": "Liberia",
        "dial_code": "+231",
        "code": "LR"
    },
    {
        "countryName": "Libyan Arab Jamahiriya",
        "dial_code": "+218",
        "code": "LY"
    },
    {
        "countryName": "Liechtenstein",
        "dial_code": "+423",
        "code": "LI"
    },
    {
        "countryName": "Lithuania",
        "dial_code": "+370",
        "code": "LT"
    },
    {
        "countryName": "Luxembourg",
        "dial_code": "+352",
        "code": "LU"
    },
    {
        "countryName": "Macao",
        "dial_code": "+853",
        "code": "MO"
    },
    {
        "countryName": "Macedonia",
        "dial_code": "+389",
        "code": "MK"
    },
    {
        "countryName": "Madagascar",
        "dial_code": "+261",
        "code": "MG"
    },
    {
        "countryName": "Malawi",
        "dial_code": "+265",
        "code": "MW"
    },
    {
        "countryName": "Malaysia",
        "dial_code": "+60",
        "code": "MY"
    },
    {
        "countryName": "Maldives",
        "dial_code": "+960",
        "code": "MV"
    },
    {
        "countryName": "Mali",
        "dial_code": "+223",
        "code": "ML"
    },
    {
        "countryName": "Malta",
        "dial_code": "+356",
        "code": "MT"
    },
    {
        "countryName": "Marshall Islands",
        "dial_code": "+692",
        "code": "MH"
    },
    {
        "countryName": "Martinique",
        "dial_code": "+596",
        "code": "MQ"
    },
    {
        "countryName": "Mauritania",
        "dial_code": "+222",
        "code": "MR"
    },
    {
        "countryName": "Mauritius",
        "dial_code": "+230",
        "code": "MU"
    },
    {
        "countryName": "Mayotte",
        "dial_code": "+262",
        "code": "YT"
    },
    {
        "countryName": "Mexico",
        "dial_code": "+52",
        "code": "MX"
    },
    {
        "countryName": "Micronesia",
        "dial_code": "+691",
        "code": "FM"
    },
    {
        "countryName": "Moldova",
        "dial_code": "+373",
        "code": "MD"
    },
    {
        "countryName": "Monaco",
        "dial_code": "+377",
        "code": "MC"
    },
    {
        "countryName": "Mongolia",
        "dial_code": "+976",
        "code": "MN"
    },
    {
        "countryName": "Montenegro",
        "dial_code": "+382",
        "code": "ME"
    },
    {
        "countryName": "Montserrat",
        "dial_code": "+1664",
        "code": "MS"
    },
    {
        "countryName": "Morocco",
        "dial_code": "+212",
        "code": "MA"
    },
    {
        "countryName": "Mozambique",
        "dial_code": "+258",
        "code": "MZ"
    },
    {
        "countryName": "Myanmar",
        "dial_code": "+95",
        "code": "MM"
    },
    {
        "countryName": "Namibia",
        "dial_code": "+264",
        "code": "NA"
    },
    {
        "countryName": "Nauru",
        "dial_code": "+674",
        "code": "NR"
    },
    {
        "countryName": "Nepal",
        "dial_code": "+977",
        "code": "NP"
    },
    {
        "countryName": "Netherlands",
        "dial_code": "+31",
        "code": "NL"
    },
    {
        "countryName": "Netherlands Antilles",
        "dial_code": "+599",
        "code": "AN"
    },
    {
        "countryName": "New Caledonia",
        "dial_code": "+687",
        "code": "NC"
    },
    {
        "countryName": "New Zealand",
        "dial_code": "+64",
        "code": "NZ"
    },
    {
        "countryName": "Nicaragua",
        "dial_code": "+505",
        "code": "NI"
    },
    {
        "countryName": "Niger",
        "dial_code": "+227",
        "code": "NE"
    },
    {
        "countryName": "Nigeria",
        "dial_code": "+234",
        "code": "NG"
    },
    {
        "countryName": "Niue",
        "dial_code": "+683",
        "code": "NU"
    },
    {
        "countryName": "Norway",
        "dial_code": "+47",
        "code": "NO"
    },
    {
        "countryName": "Oman",
        "dial_code": "+968",
        "code": "OM"
    },
    {
        "countryName": "Pakistan",
        "dial_code": "+92",
        "code": "PK"
    },
    {
        "countryName": "Palau",
        "dial_code": "+680",
        "code": "PW"
    },
    {
        "countryName": "Palestinia",
        "dial_code": "+970",
        "code": "PS"
    },
    {
        "countryName": "Panama",
        "dial_code": "+507",
        "code": "PA"
    },
    {
        "countryName": "Papua New Guinea",
        "dial_code": "+675",
        "code": "PG"
    },
    {
        "countryName": "Paraguay",
        "dial_code": "+595",
        "code": "PY"
    },
    {
        "countryName": "Peru",
        "dial_code": "+51",
        "code": "PE"
    },
    {
        "countryName": "Philippines",
        "dial_code": "+63",
        "code": "PH"
    },
    {
        "countryName": "Pitcairn",
        "dial_code": "+872",
        "code": "PN"
    },
    {
        "countryName": "Poland",
        "dial_code": "+48",
        "code": "PL"
    },
    {
        "countryName": "Portugal",
        "dial_code": "+351",
        "code": "PT"
    },
    {
        "countryName": "Qatar",
        "dial_code": "+974",
        "code": "QA"
    },
    {
        "countryName": "Romania",
        "dial_code": "+40",
        "code": "RO"
    },
    {
        "countryName": "Russia",
        "dial_code": "+7",
        "code": "RU"
    },
    {
        "countryName": "Rwanda",
        "dial_code": "+250",
        "code": "RW"
    },
    {
        "countryName": "Saint Helena",
        "dial_code": "+290",
        "code": "SH"
    },
    {
        "countryName": "Saint Pierre",
        "dial_code": "+508",
        "code": "PM"
    },
    {
        "countryName": "Samoa",
        "dial_code": "+685",
        "code": "WS"
    },
    {
        "countryName": "San Marino",
        "dial_code": "+378",
        "code": "SM"
    },
    {
        "countryName": "Sao Tome",
        "dial_code": "+239",
        "code": "ST"
    },
    {
        "countryName": "Saudi Arabia",
        "dial_code": "+966",
        "code": "SA"
    },
    {
        "countryName": "Senegal",
        "dial_code": "+221",
        "code": "SN"
    },
    {
        "countryName": "Serbia",
        "dial_code": "+381",
        "code": "RS"
    },
    {
        "countryName": "Seychelles",
        "dial_code": "+248",
        "code": "SC"
    },
    {
        "countryName": "Sierra Leone",
        "dial_code": "+232",
        "code": "SL"
    },
    {
        "countryName": "Singapore",
        "dial_code": "+65",
        "code": "SG"
    },
    {
        "countryName": "Slovakia",
        "dial_code": "+421",
        "code": "SK"
    },
    {
        "countryName": "Slovenia",
        "dial_code": "+386",
        "code": "SI"
    },
    {
        "countryName": "Solomon Islands",
        "dial_code": "+677",
        "code": "SB"
    },
    {
        "countryName": "Somalia",
        "dial_code": "+252",
        "code": "SO"
    },
    {
        "countryName": "South Africa",
        "dial_code": "+27",
        "code": "ZA"
    },
    {
        "countryName": "South Georgia",
        "dial_code": "+500",
        "code": "GS"
    },
    {
        "countryName": "Spain",
        "dial_code": "+34",
        "code": "ES"
    },
    {
        "countryName": "Sri Lanka",
        "dial_code": "+94",
        "code": "LK"
    },
    {
        "countryName": "Sudan",
        "dial_code": "+249",
        "code": "SD"
    },
    {
        "countryName": "Suriname",
        "dial_code": "+597",
        "code": "SR"
    },
    {
        "countryName": "Swaziland",
        "dial_code": "+268",
        "code": "SZ"
    },
    {
        "countryName": "Sweden",
        "dial_code": "+46",
        "code": "SE"
    },
    {
        "countryName": "Switzerland",
        "dial_code": "+41",
        "code": "CH"
    },
    {
        "countryName": "Syria",
        "dial_code": "+963",
        "code": "SY"
    },
    {
        "countryName": "Taiwan",
        "dial_code": "+886",
        "code": "TW"
    },
    {
        "countryName": "Tajikistan",
        "dial_code": "+992",
        "code": "TJ"
    },
    {
        "countryName": "Tanzania",
        "dial_code": "+255",
        "code": "TZ"
    },
    {
        "countryName": "Thailand",
        "dial_code": "+66",
        "code": "TH"
    },
    {
        "countryName": "Timor-Leste",
        "dial_code": "+670",
        "code": "TL"
    },
    {
        "countryName": "Togo",
        "dial_code": "+228",
        "code": "TG"
    },
    {
        "countryName": "Tokelau",
        "dial_code": "+690",
        "code": "TK"
    },
    {
        "countryName": "Tonga",
        "dial_code": "+676",
        "code": "TO"
    },
    {
        "countryName": "Tunisia",
        "dial_code": "+216",
        "code": "TN"
    },
    {
        "countryName": "Turkey",
        "dial_code": "+90",
        "code": "TR"
    },
    {
        "countryName": "Turkmenistan",
        "dial_code": "+993",
        "code": "TM"
    },
    {
        "countryName": "Tuvalu",
        "dial_code": "+688",
        "code": "TV"
    },
    {
        "countryName": "UAE",
        "dial_code": "+971",
        "code": "AE"
    },
    {
        "countryName": "Uganda",
        "dial_code": "+256",
        "code": "UG"
    },
    {
        "countryName": "Ukraine",
        "dial_code": "+380",
        "code": "UA"
    },
    {
        "countryName": "United Kingdom",
        "dial_code": "+44",
        "code": "GB"
    },
    {
        "countryName": "United States",
        "dial_code": "+1",
        "code": "US"
    },
    {
        "countryName": "Uruguay",
        "dial_code": "+598",
        "code": "UY"
    },
    {
        "countryName": "Uzbekistan",
        "dial_code": "+998",
        "code": "UZ"
    },
    {
        "countryName": "Vanuatu",
        "dial_code": "+678",
        "code": "VU"
    },
    {
        "countryName": "Venezuela",
        "dial_code": "+58",
        "code": "VE"
    },
    {
        "countryName": "Viet Nam",
        "dial_code": "+84",
        "code": "VN"
    },
    {
        "countryName": "Wallis & Futuna",
        "dial_code": "+681",
        "code": "WF"
    },
    {
        "countryName": "Yemen",
        "dial_code": "+967",
        "code": "YE"
    },
    {
        "countryName": "Zambia",
        "dial_code": "+260",
        "code": "ZM"
    },
    {
        "countryName": "Zimbabwe",
        "dial_code": "+263",
        "code": "ZW"
    }
  ]