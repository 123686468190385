import { makeStyles } from "@material-ui/core/styles";

export const useDialogStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 600,
    borderRadius: 8,
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
  },
  dialogContentStylesTitle:{
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
  }
}));

export const useDialogContentStyles = makeStyles((theme) => ({
  dividers: {
    padding: 16,
    // borderColor: "rgba(222, 229, 236, 1)",
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
  },
}));