import { Typography } from "@material-ui/core";
import React from "react";
import { billingInfoJson, patientInfoJson } from "./dummyJson";
import { useStyles } from "./styles";
import {epochToDatetime} from "../../utils"

const BillingInfo = (props) => {
  const { dataPass } = props;
  const classes = useStyles();
 
  
  return (
    <div>
      <div
        className={classes.alignSpacebetween}
        style={{ paddingBottom: "12px" }}
      >
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Bill No :</Typography>  
          <Typography className={classes.labelValue}>
            {dataPass?.[0]?.billno}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Bill Date : </Typography>
          <Typography className={classes.labelValue}>
            {epochToDatetime(dataPass?.[0]?.billdate)}
          </Typography>
        </div>
        {props?.forInsurance && (
          <>
            <div className={classes.alignItems}>
              <Typography className={classes.label}>GL No :</Typography>
              <Typography className={classes.labelValue}>
                {dataPass?.[0]?.glNo ? dataPass?.[0]?.glNo : "-"}
              </Typography>
            </div>
            <div className={classes.alignItems}>
              <Typography className={classes.label}>GL Date :</Typography>
              <Typography className={classes.labelValue}>
                {"-"}
              </Typography>
            </div>{" "}
          </>
        )}
        {!props?.forInsurance && (
          <>
            <div className={classes.alignItems}>
              <Typography className={classes.label}>Payer :</Typography>
              <Typography className={classes.labelValue}>
                {dataPass?.[0]?.patient?.name ? dataPass?.[0]?.patient?.name :"-"}
              </Typography>
            </div>
          </>
        )}
      </div>

      {props?.forInsurance && (
        <div className={classes.alignSpacebetween}>
          <div className={classes.alignItems}>
            <Typography className={classes.label}>Payer :</Typography>
            <Typography className={classes.labelValue}>
              {dataPass?.[0]?.payer?.shortdesc ? dataPass?.[0]?.payer?.shortdesc: "-"}
            </Typography>
          </div>
          <div className={classes.alignItems}>
            <Typography className={classes.label}>Guarantor : </Typography>
            <Typography className={classes.labelValue}>
              {dataPass?.[0]?.guarantor ? dataPass?.[0]?.guarantor :"-"}
            </Typography>
          </div>
          <div className={classes.alignItems}>
            <Typography className={classes.label}>Plan :</Typography>
            <Typography className={classes.labelValue}>
              {dataPass?.[0]?.plan ? dataPass?.[0]?.plan:"-"}
            </Typography>
          </div>
          <div className={classes.alignItems}>
            <Typography className={classes.label}>Sponsor :</Typography>
            <Typography className={classes.labelValue}>
              {dataPass?.[0]?.sponsor ? dataPass?.[0]?.sponsor :'-'}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingInfo;
