import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  getDisctanceFromLatLonInKm,
  getCurrentPosition,
  getLatLong,
  makeName,
  searchRequest,
  urls,
} from "../../utils";

const sleep = (time) => {
  return new Promise((res) => {
    setTimeout(res, time);
  });
};

const {
  getSymptom,
  getAllSpeciality,
  getSpeciality,
  specialityAgainstPractitioner,
  getHospitals,
  practitionerAgainstOrganization,
} = searchRequest;

const GET_CITIES = createAsyncThunk(
  `searhcApiSlice/getCityFromHospital`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      // const body = {
      //   db_name: dbName,
      //   entity: "hospital_master",
      //   return_fields: {
      //     city: "city",
      //     hospital_id: "id",
      //   },
      // };
      // const _data = await fetchData(urls.get_cities, { body });
      await sleep(2500);
      const data = [
        {
          title: "Chennai",
          image: "https://i.ibb.co/yYjKLD4/Group-1516-2x.png",
        },
        {
          title: "Nagercoil",
          image: "https://i.ibb.co/C6qfRkM/Group-1711-2x.png",
        },
        {
          title: "Pondicherry",
          image: "https://i.ibb.co/d7q9HHM/Group-1558-2x.png",
        },
        {
          title: "Coimbatore",
          image: "https://i.ibb.co/MZdVWG5/Group-1591-2x.png",
        },
        {
          title: "Thirunelveli",
          image: "https://i.ibb.co/6ggymYq/Group-1656-2x.png",
        },
      ];

      return {
        ...defaultState.Info,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_HOSPITALS_LOCALITY = createAsyncThunk(
  `searhcApiSlice/getHospitals`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { city = "", location = {} } = payload;

      const data = await fetchData(
        getHospitals(location, city),
        urls.readQdmQueries
      );
      const { lat, long } = await getCurrentPosition();
      const returnData = [];
      if (Array.isArray(data)) {
        data.forEach((hospInfo) => {
          if (hospInfo.id && hospInfo.name) {
            let distance = "";
            if (lat && long) {
              distance =
                getDisctanceFromLatLonInKm(
                  lat,
                  long,
                  location.latitude,
                  location.longitude
                ) + " KM";
            }
            returnData.push({
              value: hospInfo.id,
              label: hospInfo.name,
              lat: hospInfo?.address?.latitude,
              long: hospInfo?.address?.longitude,
              distance,
            });
          }
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_SYMPTOMS = createAsyncThunk(
  `searhcApiSlice/getSymptoms`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { speciality = "" } = payload;

      const data = await fetchData(getSymptom(), urls.readQdmQueries);
      const returnData = [];
      if (Array.isArray(data)) {
        data.forEach((spec) => {
          returnData.push({
            value: spec._id,
            label: spec.SymptomsName,
            type: "Symptom",
          });
        });
      }
      return {
        ...defaultState.List,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_SPECIALITY = createAsyncThunk(
  `searhcApiSlice/getSpeciality`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { speciality = "" } = payload;

      const data = await fetchData(
        getSpeciality(speciality),
        urls.readQdmQueries
      );
      const returnData = [];
      const idSet = new Set();
      if (Array.isArray(data)) {
        data.forEach((symptom) => {
          symptom?.Specialty?.forEach((spec) => {
            if (!idSet.has(spec.id) && spec?.coding?.[0]?.display) {
              idSet.add(spec.id);
              returnData.push({
                value: spec.id,
                label: spec?.coding?.[0]?.display,
                type: "Speciality",
              });
            }
          });
        });
      }
      return {
        ...defaultState.List,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_ALL_SPECIALITY = createAsyncThunk(
  `searhcApiSlice/getAllSpeciality`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(getAllSpeciality, urls.readQdmQueries);
      const returnData = [];
      if (Array.isArray(data)) {
        data.forEach((spec) => {
          if (spec?.coding?.[0]?.display) {
            returnData.push({
              value: spec.id,
              label: spec?.coding?.[0]?.display,
              type: "Speciality",
            });
          }
        });
      }
      return {
        ...defaultState.List,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const SPECIALITY_AGAINST_PRACTITIONER = createAsyncThunk(
  `searhcApiSlice/specialityAgainstPractitioner`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        specialityAgainstPractitioner(),
        urls.readDocuments
      );
      const returnData = [];
      if (Array.isArray(data?.result)) {
        data?.result.forEach((spec) => {
          returnData.push({
            label: makeName(spec.name?.[0]),
            value: spec.id,
            type: "Doctor",
            speciality: spec?.SPECIALTY?.[0]?.coding?.[0]?.display,
          });
        });
      }
      return {
        ...defaultState.List,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const getLocationInfo = async (hospInfo = {}, obj = {}) => {
  const city = hospInfo?.address?.[0]?.city?.geogLevelName;
  if (!obj[city]) {
    const info = await getLatLong(city);
    obj[city] = info;
  }
};

const PRACTITIONER_AGAINST_ORGANIZATION = createAsyncThunk(
  `searhcApiSlice/practitionerAgainstOrganization`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { practitionerId = "" } = payload;
      const { lat: myLat, long: myLong } = await getCurrentPosition();

      const data = await fetchData(
        practitionerAgainstOrganization(practitionerId),
        urls.readDocuments
      );
      const returnData = [];
      if (Array.isArray(data?.result)) {
        const cityMap = {};
        const promArr = data?.result?.map((hospInfo) =>
          getLocationInfo(hospInfo, cityMap)
        );
        await Promise.all(promArr);
        data?.result?.forEach((hospInfo) => {
          const city = hospInfo?.address?.[0]?.city?.geogLevelName;
          const { lat, long } = cityMap[city] || {};
          let distance = "";
          if (myLat && myLong && lat && long) {
            distance =
              getDisctanceFromLatLonInKm(lat, long, myLat, myLong) + " KM";
          }
          returnData.push({
            value: hospInfo.OrgID,
            label: hospInfo.OrganizationName,
            lat: 0,
            long: 0,
            distance,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_ALL_SPECIALITY_2 = createAsyncThunk(
  `searhcApiSlice/getAllSpeciality_2`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(getAllSpeciality, urls.readQdmQueries);
      const returnData = [];
      if (Array.isArray(data)) {
        data.forEach((spec) => {
          if (spec?.coding?.[0]?.display) {
            returnData.push({
              value: spec.id,
              label: spec?.coding?.[0]?.display,
              type: "Speciality",
            });
          }
        });
      }
      return {
        ...defaultState.List,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const initialState = {
  cities: { ...defaultState.List },
  hospitals: { ...defaultState.List },
  symptoms: { ...defaultState.List },
  speciality: { ...defaultState.List },
  allSpeciality: { ...defaultState.List },
  getSpecialityAgainstPractitioner: { ...defaultState.Info },
  getPractitionerAgainstOrganization: { ...defaultState.Info },
};
const searhcApiSlice = createSlice({
  name: "searhcApiSlice",
  initialState,
  extraReducers: {
    /* GET_CITIES */
    [GET_CITIES.fulfilled]: (state, action) => {
      state.cities = action?.payload ?? [];
    },
    [GET_CITIES.pending]: (state, action) => {
      state.cities.loading = true;
    },
    [GET_CITIES.rejected]: (state, action) => {
      state.cities = action?.payload ?? [];
    },

    /* GET_HOSPITALS_LOCALITY */
    [GET_HOSPITALS_LOCALITY.fulfilled]: (state, action) => {
      state.hospitals = action?.payload ?? {};
    },
    [GET_HOSPITALS_LOCALITY.pending]: (state, action) => {
      state.hospitals.loading = true;
    },
    [GET_HOSPITALS_LOCALITY.rejected]: (state, action) => {
      state.hospitals = action?.payload ?? {};
    },

    /* GET_SYMPTOMS */
    [GET_SYMPTOMS.fulfilled]: (state, action) => {
      state.symptoms = action?.payload ?? {};
    },
    [GET_SYMPTOMS.pending]: (state, action) => {
      state.symptoms.loading = true;
    },
    [GET_SYMPTOMS.rejected]: (state, action) => {
      state.symptoms = action?.payload ?? {};
    },

    /* GET_SPECIALITY */
    [GET_SPECIALITY.fulfilled]: (state, action) => {
      state.speciality = action?.payload ?? {};
    },
    [GET_SPECIALITY.pending]: (state, action) => {
      state.speciality.loading = true;
    },
    [GET_SPECIALITY.rejected]: (state, action) => {
      state.speciality = action?.payload ?? {};
    },

    /* GET_ALL_SPECIALITY */
    [GET_ALL_SPECIALITY.fulfilled]: (state, action) => {
      state.speciality = action?.payload ?? {};
    },
    [GET_ALL_SPECIALITY.pending]: (state, action) => {
      state.speciality.loading = true;
    },
    [GET_ALL_SPECIALITY.rejected]: (state, action) => {
      state.speciality = action?.payload ?? {};
    },

    /* SPECIALITY AGAINST PRACTITIONER */
    [SPECIALITY_AGAINST_PRACTITIONER.fulfilled]: (state, action) => {
      state.getSpecialityAgainstPractitioner = action?.payload ?? [];
    },
    [SPECIALITY_AGAINST_PRACTITIONER.pending]: (state, action) => {
      state.getSpecialityAgainstPractitioner.loading = true;
    },
    [SPECIALITY_AGAINST_PRACTITIONER.rejected]: (state, action) => {
      state.getSpecialityAgainstPractitioner = action?.payload ?? [];
    },

    /* SPECIALITY AGAINST PRACTITIONER */
    [PRACTITIONER_AGAINST_ORGANIZATION.fulfilled]: (state, action) => {
      state.getPractitionerAgainstOrganization = action?.payload ?? [];
    },
    [PRACTITIONER_AGAINST_ORGANIZATION.pending]: (state, action) => {
      state.getPractitionerAgainstOrganization.loading = true;
    },
    [PRACTITIONER_AGAINST_ORGANIZATION.rejected]: (state, action) => {
      state.getPractitionerAgainstOrganization = action?.payload ?? [];
    },

    /* GET_ALL_SPECIALITY_2 */
    [GET_ALL_SPECIALITY_2.fulfilled]: (state, action) => {
      state.allSpeciality = action?.payload ?? {};
    },
    [GET_ALL_SPECIALITY_2.pending]: (state, action) => {
      state.allSpeciality.loading = true;
    },
    [GET_ALL_SPECIALITY_2.rejected]: (state, action) => {
      state.allSpeciality = action?.payload ?? {};
    },
  },
});

export const searchApiActions = {
  GET_CITIES,
  GET_HOSPITALS_LOCALITY,
  GET_SYMPTOMS,
  GET_SPECIALITY,
  GET_ALL_SPECIALITY,
  SPECIALITY_AGAINST_PRACTITIONER,
  PRACTITIONER_AGAINST_ORGANIZATION,
  GET_ALL_SPECIALITY_2,
};

export default searhcApiSlice;
