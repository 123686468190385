import React from "react";
import {
  Div,
  Col,
  Row,
  Paper,
  H6,
  TextInput,
  Select,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../../hoc/withAllContexts";
import withQueue from "../../../hoc/withQueue";
import actions from "../../../redux/actions";
import { connect } from "react-redux";
import { AlertContext } from "../../../context";
import { useDispatch } from "react-redux";
//import { withRouter } from "react-router-dom";
import { makeName } from "../../../utils";
import jwtDecode from "jwt-decode";

function Pactitioner(props) {
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
      // background: '#F0F0F0'
    },
    errorBorder: {
      borderRadius: 8,
      border: "1px solid #f334466",
    },
  };

  const dispatch = useDispatch();
  const { practitioner } = props.visitData;
  const [practitioner_name, setpractitioner_name] = React.useState([]);
  const [subtype, setSubtype] = React.useState([]);
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [practitioner_role, setpractitioner_role] = React.useState([]);
  const [practitioner_speciality, setpractitioner_speciality] = React.useState(
    []
  );
  const [getpractitioner_name_Role_data, setpractitioner_name_Role_data] =
    React.useState([]);
  const [practdata, setpractdata] = React.useState([]);

  const setDetails = async (name, value) => {
    let patientData = props.visitData;

    const { appointmentData } = props;
    if (name === "Resourcetype") {
      const resSubType = await dispatch(
        actions.GET_RESOURCE_SUB_TYPE({
          resourceid: value?.label,
        })
      );

      let resSub = [];

      resSubType?.payload?.data.forEach((datasss) => {
        let obj = {
          label: datasss._id,
          value: datasss.display,
        };

        resSub.push(obj);
      });
      setSubtype(resSub);

      if (
        patientData.practitioner[name]?.value !== value?.value &&
        !props?.isEdited
      ) {
        // patientData.practitioner["type"] = {
        //   value: "",
        //   label: "",
        // };
        patientData.practitioner["id_name"] = {
          value: "",
          label: "",
        };
        patientData.practitioner["speciality"] = {
          value: "",
          label: "",
        };
        patientData.practitioner["role"] = {
          value: "",
          label: "",
        };
      }
    }
    if (name === "type") {
      const info = localStorage.getItem("UserInfo");
      let infoss = jwtDecode(info);

      if (patientData?.practitioner?.Resourcetype?.value === "Practitioner") {
        const practitioner_name_dataa = await dispatch(
          actions.GET_PRACTITIONERS_BYSPECIALITY({
            facilityid: infoss?.facilityid,
            practtype: value?.label,
            //specialityid: practitioner?.speciality?.label
          })
        );

        let name = [];
        practitioner_name_dataa?.payload?.data.forEach((v) => {
          let obj = {
            value: `${v?.name}-${v?.practitionerid}`,
            label: v?._id,
          };
          name.push(obj);
          setpractitioner_name(name);
        });
      } else if (
        patientData?.practitioner?.Resourcetype?.value === "Location"
      ) {
        const practitioner_name_dataa = await dispatch(
          actions.GET_PRACTITIONERS_BYLOACTION({
            facilityid: infoss?.facilityid,
            loctype: value?.label,
            appid: appointmentData?.[0]?._id,
          })
        );

        let name = [];
        practitioner_name_dataa?.payload?.data.forEach((v) => {
          let obj = {
            value: `${v?.longdesc}-${v?.locationID}`,
            label: v?._id,
          };
          name.push(obj);
          setpractitioner_name(name);
        });
      } else {
        setpractitioner_name([]);
      }
    }
    if (
      name === "id_name" &&
      patientData?.practitioner?.Resourcetype?.value === "Practitioner"
    ) {
      let spe_rol_data = practdata.filter((li) => li._id === value?.label);
      let specialityArr = spe_rol_data[0]?.["SpecialtyID"];
      let roleArr = spe_rol_data[0]?.["resourcerole"];
      let Role = [];
      if (roleArr?.length > 0) {
        roleArr.forEach((li) => {
          let obj = {
            label: li._id,
            value: li.display,
          };
          Role.push(obj);
        });
      }

      //Role.push(obj)
      setpractitioner_name_Role_data(Role);

      if (Role?.length === 1) {
        patientData.practitioner["role"] = {
          value: Role?.[0]?.value,
          label: Role?.[0]?.label,
        };
      }
      let Speciality = [];
      if (specialityArr?.length > 0) {
        specialityArr.forEach((li) => {
          let obj = {
            label: li._id,
            value: li.display,
          };
          Speciality.push(obj);
        });
      }
      setpractitioner_speciality(Speciality);

      if (Speciality?.length === 1) {
        patientData.practitioner["speciality"] = {
          value: Speciality?.[0]?.value,
          label: Speciality?.[0]?.label,
        };
      }
    }
    if (patientData.practitioner.error) {
      patientData.practitioner.error[name] = false;
    }
    patientData.practitioner[name] = value;
    console.log(patientData, "patientData");
    patientData.setVisitDetails({
      ...patientData,
    });
    forceUpdate();
  };

  const { labelStyle, borderStyle, errorBorder } = styles;

  const { walkin } = props;
  const getData = async () => {
    const { appointmentData, walkin } = props;
    // if (walkin) {
    // if (appointmentData?.Practioner) {
    let patientData = props.visitData;
    patientData.practitioner["clinic"] = {
      value: appointmentData?.orgid?.name,
    };
    patientData.setVisitDetails({
      ...patientData,
    });
    // }
    // }

    if (appointmentData) {
      // if (appointmentData?.Practioner) {
      let patientData = props.visitData;
      let practrole = appointmentData?.[0]?.resourcerole?.display || "";
      patientData.practitioner["role"] = {
        value: practrole,
        label: appointmentData?.[0]?.resourcerole?._id,
      };
      patientData.setVisitDetails({
        ...patientData,
      });
      // }
    }

    if (appointmentData) {
      // if (appointmentData?.Practioner) {
      let patientData = props.visitData;
      let pracType = appointmentData?.[0]?.resourcetype?.display || "";
      patientData.practitioner["Resourcetype"] = {
        value: pracType,
        label: appointmentData?.[0]?.resourcetype?._id,
      };
      patientData.setVisitDetails({
        ...patientData,
      });

      if (patientData?.practitioner?.Resourcetype?.value) {
        const resSubType = await dispatch(
          actions.GET_RESOURCE_SUB_TYPE({
            resourceid: patientData?.practitioner?.Resourcetype?.label,
          })
        );

        let resSub = [];
        resSubType?.payload?.data.forEach((datasss) => {
          let obj = {
            label: datasss._id,
            value: datasss.display,
          };
          resSub.push(obj);
        });
        setSubtype(resSub);
        let practSubtype = appointmentData?.[0]?.ressubtype?.display || "";
        patientData.practitioner["type"] = {
          value: practSubtype,
          label: appointmentData?.[0]?.ressubtype?._id,
        };
        patientData.setVisitDetails({
          ...patientData,
        });
      }
    }

    if (appointmentData) {
      let patientData = props.visitData;
      patientData.practitioner["speciality"] = {
        value: appointmentData?.[0]?.SpecialtyID?.[0]?.display,
        label: appointmentData?.[0]?.SpecialtyID?.[0]?._id,
        coding_id: appointmentData?.[0]?.SpecialtyID?.[0]?._id,
      };
      patientData.setVisitDetails({
        ...patientData,
      });
      const info = localStorage.getItem("UserInfo");
      let infoss = jwtDecode(info);
      const practitioner_name = await dispatch(
        actions.GET_PRACTITIONERS({
          facilityid: infoss?.facilityid,
        })
      );
      let Type = [];
      practitioner_name?.payload?.data?.[1]?.RESOURCETYPE?.Value.forEach(
        (li) => {
          let obj = {
            label: li._id,
            value: li.display,
          };
          Type.push(obj);
        }
      );
      const practitioner_name_dataa = await dispatch(
        actions.GET_PRACTITIONERS_BYSPECIALITY({
          facilityid: infoss?.facilityid,
        })
      );
      setpractdata(practitioner_name_dataa?.payload?.data);
      setpractitioner_role(Type);
      if (patientData?.practitioner?.Resourcetype?.value == "Practitioner") {
        let name = [];
        practitioner_name_dataa?.payload?.data.forEach((v) => {
          let obj = {
            value: `${v?.name}-${v?.practitionerid}`,
            label: v?._id,
          };
          name.push(obj);
          setpractitioner_name(name);
        });
        patientData.practitioner["id_name"] = {
          value: makeName(appointmentData?.[0]?.idandname?.name?.[0]),
          label: appointmentData?.[0]?.idandname?._id,
        };
        patientData.setVisitDetails({
          ...patientData,
        });

        let role = practitioner_name_dataa?.payload?.data.filter(
          (li) => li._id == patientData?.practitioner?.id_name?.label
        );
        let specialityArr = role?.[0]?.["SpecialtyID"];
        let roleArr = role?.[0]?.["resourcerole"];
        let Role = [];
        if (roleArr?.length > 0) {
          roleArr.forEach((li) => {
            let obj = {
              label: li._id,
              value: li.display,
            };
            Role.push(obj);
          });
        }
        setpractitioner_name_Role_data(Role);
        let Speciality = [];
        if (specialityArr?.length > 0) {
          specialityArr.forEach((li) => {
            let obj = {
              label: li._id,
              value: li.display,
            };
            Speciality.push(obj);
          });
        }
        setpractitioner_speciality(Speciality);
      } else if (
        patientData?.practitioner?.Resourcetype?.value === "Location"
      ) {
        const practitioner_name_dataa = await dispatch(
          actions.GET_PRACTITIONERS_BYLOACTION({
            facilityid: infoss?.facilityid,
            loctype: appointmentData?.[0]?.resourcetype?._id,
            appid: appointmentData?.[0]?._id,
          })
        );

        let name = [];
        practitioner_name_dataa?.payload?.data.forEach((v) => {
          let obj = {
            value: `${v?.longdesc}-${v?.locationID}`,
            label: v?._id,
          };
          name.push(obj);
          setpractitioner_name(name);
        });
      }
    }
  };
  // React.useEffect(() => {
  //   if (props?.isEdited) {
  //     setDetails("Resourcetype",props?.locationData?.practitioner?.Resourcetype)
  //     setDetails("type",props?.locationData?.practitioner?.type)
  //     setDetails("role",props?.locationData?.practitioner?.role)
  //     setDetails("id_name",props?.locationData?.practitioner?.id_name)
  //     setDetails("speciality",props?.locationData?.practitioner?.speciality)

  //   }
  // }, [props?.isEdited])
  React.useEffect(() => {
    if (
      props?.isEdited &&
      !props?.isEdit &&
      !props?.isRevise &&
      props?.locationData?.practitioner
    ) {
      setDetails(
        "Resourcetype",
        props?.locationData?.practitioner?.Resourcetype
      );
      //setDetails("type",props?.locationData?.practitioner?.type)
      // setDetails("role",props?.locationData?.practitioner?.role)
      //setDetails("id_name",props?.locationData?.practitioner?.id_name)
      // setDetails("speciality",props?.locationData?.practitioner?.speciality)
      getEditdata1();
    }
  }, [props?.isEdited, props?.locationData?.practitioner]);
  React.useEffect(() => {
    if (!props?.isEdit && !props?.isRevise && !props?.isEdited) {
      getData();
      setDetails(
        "Resourcetype",
        props?.loadDefaultResourcetype ??
          props?.locationData?.practitioner?.Resourcetype
      );
      setDetails(
        "type",
        props?.loadDefaultLocationType ??
          props?.locationData?.practitioner?.type
      );
      setDetails("role", props?.locationData?.practitioner?.role);
      setDetails("id_name", props?.locationData?.practitioner?.id_name);
      setDetails("speciality", props?.locationData?.practitioner?.speciality);
    } else {
      getEditdata();
    }
  }, [props.appointmentData]);
  const getEditdata1 = async () => {
    const { appointmentData, walkin } = props;

    let patientData = props.visitData;
    // patientData.practitioner["Resourcetype"] = props?.locationData?.practitioner?.Resourcetype;
    if (patientData?.practitioner?.Resourcetype?.value) {
      const resSubType = await dispatch(
        actions.GET_RESOURCE_SUB_TYPE({
          resourceid: patientData?.practitioner?.Resourcetype?.label,
        })
      );

      let resSub = [];
      resSubType?.payload?.data.forEach((datasss) => {
        let obj = {
          label: datasss._id,
          value: datasss.display,
        };
        resSub.push(obj);
      });
      setSubtype(resSub);
    }
    patientData.practitioner["type"] = props?.locationData?.practitioner?.type;
    patientData.practitioner["id_name"] =
      props?.locationData?.practitioner?.id_name;
    //  {
    //   value: makeName(props?.encount?.participants?.[0]?.name?.[0]),
    //   label: props?.encount?.participants?.[0]?.resouceid,
    // };
    patientData.practitioner["speciality"] =
      props?.locationData?.practitioner?.speciality;
    // {
    //   value: props?.locationData?.practitioner?.speciality?.value,
    //   label: props?.locationData?.practitioner?.speciality?.label,
    // };
    patientData.practitioner["role"] = props?.locationData?.practitioner?.role;
    // {
    //   value: props?.encount?.participants?.[0]?.resrole?.display,
    //   label: props?.encount?.participants?.[0]?.resrole?._id,
    // };
    patientData.setVisitDetails({
      ...patientData,
    });

    const info = localStorage.getItem("UserInfo");
    let infoss = jwtDecode(info);
    const practitioner_name = await dispatch(
      actions.GET_PRACTITIONERS({
        facilityid: infoss?.facilityid,
      })
    );
    let Type = [];
    practitioner_name?.payload?.data?.[1]?.RESOURCETYPE?.Value.forEach((li) => {
      let obj = {
        label: li._id,
        value: li.display,
      };
      Type.push(obj);
    });
    setpractitioner_role(Type);
    if (patientData?.practitioner?.Resourcetype?.value === "Practitioner") {
      const practitioner_name_dataa = await dispatch(
        actions.GET_PRACTITIONERS_BYSPECIALITY({
          facilityid: infoss?.facilityid,
        })
      );
      setpractdata(practitioner_name_dataa?.payload?.data);

      let name = [];
      practitioner_name_dataa?.payload?.data.forEach((v) => {
        let obj = {
          value: `${v?.name}-${v?.practitionerid}`,
          label: v?._id,
        };
        name.push(obj);
        setpractitioner_name(name);
      });

      let role = practitioner_name_dataa?.payload?.data.filter(
        (li) => li._id == patientData?.practitioner?.id_name?.label
      );
      let specialityArr = role?.[0]?.["SpecialtyID"];
      let roleArr = role?.[0]?.["resourcerole"];
      let Role = [];
      if (roleArr?.length > 0) {
        roleArr.forEach((li) => {
          let obj = {
            label: li._id,
            value: li.display,
          };
          Role.push(obj);
        });
      }
      setpractitioner_name_Role_data(Role);
      let Speciality = [];
      if (specialityArr?.length > 0) {
        specialityArr.forEach((li) => {
          let obj = {
            label: li._id,
            value: li.display,
          };
          Speciality.push(obj);
        });
      }
      setpractitioner_speciality(Speciality);
    } else if (patientData?.practitioner?.Resourcetype?.value == "Location") {
      const practitioner_name_dataa = await dispatch(
        actions.GET_PRACTITIONERS_BYLOACTION({
          facilityid: infoss?.facilityid,
          loctype: appointmentData?.[0]?.resourcetype?._id,
          appid: appointmentData?.[0]?._id,
        })
      );

      let name = [];
      practitioner_name_dataa?.payload?.data.forEach((v) => {
        let obj = {
          value: `${v?.longdesc}-${v?.locationID}`,
          label: v?._id,
        };
        name.push(obj);
        setpractitioner_name(name);
      });
    }
  };
  const getEditdata = async () => {
    const { appointmentData, walkin } = props;
    let patientData = props.visitData;
    patientData.practitioner["Resourcetype"] = {
      value: props?.encount?.participants?.[0]?.resoucetype?.display,
      label: props?.encount?.participants?.[0]?.resoucetype?._id,
    };
    if (patientData?.practitioner?.Resourcetype?.value) {
      const resSubType = await dispatch(
        actions.GET_RESOURCE_SUB_TYPE({
          resourceid: patientData?.practitioner?.Resourcetype?.label,
        })
      );

      let resSub = [];
      resSubType?.payload?.data.forEach((datasss) => {
        let obj = {
          label: datasss._id,
          value: datasss.display,
        };
        resSub.push(obj);
      });
      setSubtype(resSub);
    }
    patientData.practitioner["type"] = {
      value: props?.encount?.participants?.[0]?.ressubtype?.display,
      label: props?.encount?.participants?.[0]?.ressubtype?._id,
    };
    patientData.practitioner["id_name"] = {
      value: props?.encount?.participants?.[0]?.resouceid?.longdesc,
      label: props?.encount?.participants?.[0]?.resouceid?._id,
    };
    patientData.practitioner["speciality"] = {
      value: props?.encount?.participants?.[0]?.speciality?.display,
      label: props?.encount?.participants?.[0]?.speciality?._id,
    };
    patientData.practitioner["role"] = {
      value: props?.encount?.participants?.[0]?.resrole?.display,
      label: props?.encount?.participants?.[0]?.resrole?._id,
    };
    patientData.setVisitDetails({
      ...patientData,
    });

    const info = localStorage.getItem("UserInfo");
    let infoss = jwtDecode(info);
    const practitioner_name = await dispatch(
      actions.GET_PRACTITIONERS({
        facilityid: infoss?.facilityid,
      })
    );
    let Type = [];
    practitioner_name?.payload?.data?.[1]?.RESOURCETYPE?.Value.forEach((li) => {
      let obj = {
        label: li._id,
        value: li.display,
      };
      Type.push(obj);
    });
    setpractitioner_role(Type);
    if (patientData?.practitioner?.Resourcetype?.value === "Practitioner") {
      const practitioner_name_dataa = await dispatch(
        actions.GET_PRACTITIONERS_BYSPECIALITY({
          facilityid: infoss?.facilityid,
        })
      );
      setpractdata(practitioner_name_dataa?.payload?.data);

      let name = [];
      practitioner_name_dataa?.payload?.data.forEach((v) => {
        let obj = {
          value: `${v?.name}-${v?.practitionerid}`,
          label: v?._id,
        };
        name.push(obj);
        setpractitioner_name(name);
      });

      let role = practitioner_name_dataa?.payload?.data.filter(
        (li) => li._id === patientData?.practitioner?.id_name?.label
      );
      let specialityArr = role?.[0]?.["SpecialtyID"];
      let roleArr = role?.[0]?.["resourcerole"];
      let Role = [];
      if (roleArr?.length > 0) {
        roleArr.forEach((li) => {
          let obj = {
            label: li._id,
            value: li.display,
          };
          Role.push(obj);
        });
      }
      setpractitioner_name_Role_data(Role);
      let Speciality = [];
      if (specialityArr?.length > 0) {
        specialityArr.forEach((li) => {
          let obj = {
            label: li._id,
            value: li.display,
          };
          Speciality.push(obj);
        });
      }
      setpractitioner_speciality(Speciality);
    } else if (patientData?.practitioner?.Resourcetype?.value === "Location") {
      const practitioner_name_dataa = await dispatch(
        actions.GET_PRACTITIONERS_BYLOACTION({
          facilityid: infoss?.facilityid,
          loctype: props?.encount?.participants?.[0]?.ressubtype?._id, //FIXME -
          appid: appointmentData?.[0]?._id,
        })
      );

      let name = [];
      practitioner_name_dataa?.payload?.data.forEach((v) => {
        let obj = {
          value: `${v?.longdesc}-${v?.locationID}`,
          label: v?._id,
        };
        name.push(obj);
        setpractitioner_name(name);
      });
    }
  };

  const { parent_id } = props;
  return (
    <Div id={`${parent_id}_practitioner_parent_div`} className="pv-root">
      <Paper id={`${parent_id}_practitioner_parent_paper`} className="pl-root">
        <Div id={`${parent_id}_practitioner_title_div`}>
          <H6
            id={`${parent_id}_practitioner_details_label_h6`}
            className="en-title space-padding-bottom"
          >
            PRACTITIONER INFO
          </H6>
        </Div>
        <Row
          id={`${parent_id}_practitioner_parent_row`}
          className="en-actions-root"
        >
          {/* {walkin &&
                    <Col md={4} lg={4} sm={12} xs={12} className="no-padding-left space-padding-bottom space-padding-top">
                        <TextInput placeholder='Speciality/ Practitioner/ Location' label="Speciality/Practitioner/Location" labelStyle={labelStyle} style={{ ...borderStyle, background: 'transparent' }} hoverColor="#0071F2" />
                    </Col>
                } */}
          <Col
            id={`${parent_id}_practitioner_parent_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Row
              id={`${parent_id}_practitioner_parent_sub_row`}
              className="en-actions-root"
            >
              <Col
                id={`${parent_id}_practitioner_type_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_practitioner_type_select`}
                  search
                  // disabled={!walkin ? false : true}
                  disabled={
                    props.isEdit
                      ? true
                      : walkin
                      ? false
                      : props.type === "cancel" || props.type === "modify"
                      ? true
                      : props?.isEdit
                      ? true
                      : false
                  }
                  options={practitioner_role}
                  placeholder="select..."
                  value={practitioner?.Resourcetype?.value ?? null}
                  onChangeValue={(e) => setDetails("Resourcetype", e)}
                  label="Resource Type"
                  labelStyle={labelStyle}
                  inLineStyles={
                    practitioner?.error && practitioner?.error["Resourcetype"]
                      ? errorBorder
                      : borderStyle
                  }
                  hoverColor="#0071F2"
                  isRequired={true}
                  error={
                    practitioner?.error && practitioner?.error["Resourcetype"]
                      ? true
                      : false
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_practitioner_type_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_practitioner_type_select`}
                  search
                  // disabled={!walkin ? false : true}
                  disabled={
                    props.isEdit
                      ? true
                      : walkin
                      ? false
                      : props.type === "cancel" || props.type === "modify"
                      ? true
                      : false
                  }
                  options={subtype}
                  placeholder="select..."
                  value={practitioner?.type?.value ?? null}
                  onChangeValue={(e) => setDetails("type", e)}
                  label={`${practitioner?.Resourcetype?.value || ""} Type`}
                  labelStyle={labelStyle}
                  inLineStyles={
                    practitioner?.error && practitioner?.error["Resourcetype"]
                      ? errorBorder
                      : borderStyle
                  }
                  hoverColor="#0071F2"
                  isRequired={true}
                  error={
                    practitioner?.error &&
                    practitioner?.error["Resourcesubtype"]
                      ? true
                      : false
                  }
                />
              </Col>

              <Col
                id={`${parent_id}_practitioner_id_name_col`}
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_practitioner_id_name_select`}
                  placeholder="select..."
                  search
                  // disabled={!walkin ? false : true}
                  disabled={
                    props.isEdit
                      ? true
                      : walkin
                      ? false
                      : props.type === "cancel" || props.type === "modify"
                      ? true
                      : false
                  }
                  options={practitioner_name}
                  value={practitioner?.id_name?.value ?? null}
                  onChangeValue={(e) => setDetails("id_name", e)}
                  label={`${practitioner?.Resourcetype?.value || ""} ID & Name`}
                  labelStyle={labelStyle}
                  inLineStyles={
                    practitioner?.error && practitioner?.error["id_name"]
                      ? errorBorder
                      : borderStyle
                  }
                  hoverColor="#0071F2"
                  isRequired={true}
                  error={
                    practitioner?.error && practitioner?.error["id_name"]
                      ? true
                      : false
                  }
                />
              </Col>
              {practitioner?.Resourcetype?.value === "Practitioner" && (
                <>
                  <Col
                    id={`${parent_id}_practitioner_speciality_col`}
                    md={2}
                    lg={2}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_practitioner_speciality_select`}
                      search
                      disabled={
                        props.isEdit
                          ? true
                          : walkin
                          ? false
                          : props.type === "cancel" || props.type === "modify"
                          ? true
                          : false
                      }
                      options={practitioner_speciality}
                      placeholder="select..."
                      value={practitioner?.speciality?.value ?? null}
                      onChangeValue={(e) => setDetails("speciality", e)}
                      label="Specialty"
                      labelStyle={labelStyle}
                      inLineStyles={
                        practitioner?.error && practitioner?.error["speciality"]
                          ? errorBorder
                          : borderStyle
                      }
                      hoverColor="#0071F2"
                      // helperText={
                      // 	practitioner?.error && practitioner?.error["speciality"]
                      // 		? "Is required"
                      // 		: ""
                      // }
                      error={
                        practitioner?.error && practitioner?.error["speciality"]
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_practitioner_role_col`}
                    md={2}
                    lg={2}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_practitioner_role_select`}
                      search
                      // disabled={!walkin ? false : true}
                      disabled={
                        props.isEdit
                          ? true
                          : walkin
                          ? false
                          : props.type === "cancel" || props.type === "modify"
                          ? true
                          : false
                      }
                      options={getpractitioner_name_Role_data}
                      placeholder="select..."
                      value={practitioner?.role?.value ?? null}
                      onChangeValue={(e) => setDetails("role", e)}
                      label="Role"
                      labelStyle={labelStyle}
                      inLineStyles={borderStyle}
                      hoverColor="#0071F2"
                    />
                  </Col>
                </>
              )}
              {/* <Col
								id={`${parent_id}_practitioner_clinic_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_practitioner_clinic_select`}
									search
									//disabled={!walkin ? false : true}
									disabled={
										props.view
											? true
											: walkin
											? false
											: props.type === "cancel" || props.type === "modify"
											? true
											: false
									}
									options={[{ value: "Apollo Vadapalani" }]}
									placeholder="select..."
									value={practitioner?.clinic?.value ?? null}
									onChangeValue={(e) => setDetails("clinic", e)}
									label="Clinic"
									labelStyle={labelStyle}
									inLineStyles={borderStyle}
									hoverColor="#0071F2"
								/>
							</Col> */}
            </Row>
          </Col>
        </Row>
      </Paper>
    </Div>
  );
}

//Pactitioner.contextType = AlertContext;
// const mapStateToProps = (state) => ({
// 	oraganizationRegisterPatient:
// 		state?.OrganizationRegisterPatientApiSlice?.oraganizationRegisterPatient,
// });
export default withAllContexts(Pactitioner);
