import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = (props) => {
  return (
    <DatePicker
      minDate={props?.minDate}
      maxDate={props?.maxDate}
      timeFormat={props?.timeFormat}
      showTimeSelectOnly={props?.showTimeSelectOnly || false}
      showTimeSelect={props?.showTimeSelect || false}
      timeIntervals={props?.timeIntervals}
      selected={props.selectedDate}
      minTime={props?.minTime}
      maxTime={props?.maxTime}
      // monthsShown={1}
      inline
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      calendarStartDay={1}
      onChange={(value, e) => {
        props.handleChange(value, e);
      }}
    />
  );
};

export default CustomDatePicker;
