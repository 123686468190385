import React from "react";
import { Div, Select, Col, Row, Paper, H6, Text } from 'qdm-component-library'
import "./style.css"
import  withAllContexts  from "../../../hoc/withAllContexts";
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { UIColor } from "../../../themes/theme";

function RefDetails(props) {

    const styles = {
        labelStyle: {
            color: '#6F6F6F',
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        selectBorderStyle: {
            borderRadius: 8,
            borderColor: '#E0E0E0'
        },
        errorselectBorderStyle: {
            borderRadius: 8
        },
    }

    const { details } = props.referralData;

    const setDetails = (name, value) => {
        let patientData = props.referralData
        patientData.details[name] = value;
        if (patientData.details.error) {
            patientData.details.error[name] = false;
        }
        patientData.setReferralDetails({
            ...patientData,
        })
    }

    // const FlterOptions = (type) => {
    //     const { allMasters } = props;
    //     var lista = []
    //     const data = allMasters?.[type]
    //     for (let index in data) {
    //         var datatemp = {
    //             "value": data[index]?.coding?.display,
    //         };
    //         lista.push(datatemp);
    //     }
    //     return lista
    // }

    const { labelStyle, selectBorderStyle, errorselectBorderStyle } = styles;
    const { parent_id } = props

    return < Div
        id={`${parent_id}_refdetails_parent_div`}
        className="pv-root" >
        <Paper
            id={`${parent_id}_refdetails_parent_paper`}
            className="pl-root">
            <Row
                id={`${parent_id}_refdetails_parent_row`}
                className="en-actions-root">
                <Col
                    id={`${parent_id}_refdetails_parent_col`}
                    md={12} lg={12} sm={12} xs={12} className="no-padding-left">
                    <Div
                        id={`${parent_id}_refdetails_title_div`}
                    >
                        <H6
                            id={`${parent_id}_refdetails_label_h6`}
                            className="en-title space-padding-bottom">REFERRAL DETAILS</H6>
                    </Div>
                    <Row
                        id={`${parent_id}_refdetails_sub_row`}
                        className="en-actions-root">
                        <Col
                            id={`${parent_id}_refdetails_intent_type_col`}
                            md={1} lg={1} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_refdetails_intent_type_select`}
                                // isRequired
                                placeholder='select...'
                                options={[
                                    { value: "Proposal" },
                                    { value: "Plan" },
                                    { value: "Order" },
                                ]}
                                // helperText={(details?.error && details?.error['intent_type']) ? 'Is required' : ''}
                                error={
                                    // (details?.error && details?.error['intent_type']) ? true :
                                 false}
                                value={details.intent_type?.value} onChangeValue={e => setDetails("intent_type", e)} label="Intent Type" showArrow labelStyle={labelStyle}
                                inLineStyles={
                                    // (details?.error && details?.error['intent_type']) ? errorselectBorderStyle :
                                 selectBorderStyle}
                                hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_refdetails_for_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_refdetails_for_select`}
                                // isRequired
                                options={[
                                    { value: "Consult" },
                                    { value: "Admission" },
                                    { value: "Care plan" },
                                    { value: "Procedure" },
                                ]}
                                // helperText={(details?.error && details?.error['for']) ? 'Is required' : ''}
                                error={
                                    // (details?.error && details?.error['for']) ? true :
                                     false}
                                placeholder='select...' value={details.for?.value} onChangeValue={e => setDetails("for", e)} label="For" showArrow labelStyle={labelStyle}
                                inLineStyles={
                                    // (details?.error && details?.error['for']) ? errorselectBorderStyle :
                                     selectBorderStyle}
                                hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_refdetails_priority_col`}
                            md={1} lg={1} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_refdetails_priority_select`}
                                // isRequired
                                options={[
                                    { value: "Routene" },
                                    { value: "Urgent" },
                                    { value: "STAT" },
                                    { value: "ASAP" },
                                ]}
                                // helperText={(details?.error && details?.error['priority']) ? 'Is required' : ''}
                                error={
                                    // (details?.error && details?.error['priority']) ? true :
                                     false}
                                placeholder='select...' value={details.priority?.value} onChangeValue={e => setDetails("priority", e)} label="Priority" showArrow labelStyle={labelStyle}
                                inLineStyles={
                                    // (details?.error && details?.error['priority']) ? errorselectBorderStyle :
                                     selectBorderStyle}
                                hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_refdetails_request_reason_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_refdetails_request_reason_select`}
                                options={[
                                    { label: "RS001", value: "For Consultation with Practitioner" },
                                    { label: "RS002", value: "For further Treatment" },
                                    { label: "RS003", value: "For Procedure" },
                                    { label: "RS004", value: "For Dressing" },
                                    { label: "RS005", value: "For Vaccination" },
                                    { label: "RS006", value: "For Lab Test" },
                                    { label: "RS007", value: "For Radiology Examination" },
                                    { label: "RS008", value: "For Appointment" },
                                    { label: "RS009", value: "For Admission" },
                                ]} placeholder='select...'

                                value={details.request_reason?.value} onChangeValue={e => setDetails("request_reason", e)} label="Request Reason" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_refdetails_service_requested_col`}
                            md={6} lg={6} sm={12} xs={12} className="no-padding-left">
                            <Text
                                id={`${parent_id}_refdetails_service_requested_text`}
                                style={{
                                    color: '#6F6F6F',
                                    fontSize: '12px',
                                    marginBottom: '6px',
                                    fontFamily: 'pc_regular'
                                }}>Service Requested<span style={{marginLeft: "5px", color: UIColor.error.color}}>*</span>
                            </Text>
                            <Autocomplete
                                id={`${parent_id}_refdetails_service_requested_autocomplete`}
                                // isRequired
                                multiple
                                fullWidth
                                value={details.service_requested ?? []}
                                onChange={(e, v) => setDetails("service_requested", v)}
                                options={[
                                    { label: "SR001", value: "Occupational therapy" },
                                    { label: "SR002", value: "Treatment" },
                                    { label: "SR003", value: "Assessment" },
                                    { label: "SR004", value: "Physiotherapy" },
                                    { label: "SR005", value: "Acupuncture" },
                                    { label: "SR006", value: "Consultation" },
                                    { label: "SR007", value: "Immunization" },
                                    { label: "SR008", value: "Dressing" },
                                    { label: "SR009", value: "Oral Medicine" },
                                    { label: "SR010", value: "Drug and/or alcohol counselling" },
                                    { label: "SR011", value: "Plan Management" },
                                    { label: "SR012", value: "X-Ray" },
                                    { label: "SR013", value: "CT" },
                                    { label: "SR014", value: "MRI" },
                                    { label: "SR015", value: "Ambulance" },
                                    { label: "SR016", value: "Yoga" },
                                    { label: "SR017", value: "Blood Donation" },
                                    { label: "SR018", value: "Surgery" },
                                    { label: "SR019", value: "Chemotherapy" },
                                    { label: "SR020", value: "Oral Medicine" },
                                    { label: "SR021", value: "Scaling" },
                                    { label: "SR022", value: "Root Canal Treatment" },
                                    { label: "SR023", value: "Anaesthesia" },
                                    { label: "SR024", value: "Dialysis" },
                                    { label: "SR025", value: "Birth Control" },
                                    { label: "SR026", value: "Depression Control" },
                                    { label: "SR027", value: "Detoxification" },
                                    { label: "SR028", value: "Exercise" },
                                    { label: "SR029", value: "Home Visit" },
                                    { label: "SR030", value: "Pathology - Clinical Chemistry" },
                                    { label: "SR031", value: "Pathology - Haematology" },
                                    { label: "SR032", value: "Pathology - Immunology" },
                                    { label: "SR033", value: "Pathology - Microbiology" },
                                ]}
                                getOptionLabel={(option) => option.value}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            id={`${parent_id}_${index}_refdetails_service_requested_chip`}
                                            style={{ height: "22px", borderRadius: 8 }}
                                            label={option.value}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        id={`${parent_id}_refdetails_service_requested_error_txtfield`}
                                        // helperText={(details?.error && details?.error['service_requested']) ? 'Is required' : ''}
                                        error={
                                            // (details?.error && details?.error['service_requested']) ? true :
                                             false}
                                        {...params} label={false} variant="outlined" placeholder="select..." size='small' />
                                )}
                            />
                            {/* <MultiSelect
                                options={[
                                    { label: "SR001", value: "Occupational therapy" },
                                    { label: "SR002", value: "Treatment" },
                                    { label: "SR003", value: "Assessment" },
                                    { label: "SR004", value: "Physiotherapy" },
                                    { label: "SR005", value: "Acupuncture" },
                                    { label: "SR006", value: "Consultation" },
                                    { label: "SR007", value: "Immunization" },
                                    { label: "SR008", value: "Dressing" },
                                    { label: "SR009", value: "Oral Medicine" },
                                    { label: "SR010", value: "Drug and/or alcohol counselling" },
                                    { label: "SR011", value: "Plan Management" },
                                    { label: "SR012", value: "X-Ray" },
                                    { label: "SR013", value: "CT" },
                                    { label: "SR014", value: "MRI" },
                                    { label: "SR015", value: "Ambulance" },
                                    { label: "SR016", value: "Yoga" },
                                    { label: "SR017", value: "Blood Donation" },
                                    { label: "SR018", value: "Surgery" },
                                    { label: "SR019", value: "Chemotherapy" },
                                    { label: "SR020", value: "Oral Medicine" },
                                    { label: "SR021", value: "Scaling" },
                                    { label: "SR022", value: "Root Canal Treatment" },
                                    { label: "SR023", value: "Anaesthesia" },
                                    { label: "SR024", value: "Dialysis" },
                                    { label: "SR025", value: "Birth Control" },
                                    { label: "SR026", value: "Depression Control" },
                                    { label: "SR027", value: "Detoxification" },
                                    { label: "SR028", value: "Exercise" },
                                    { label: "SR029", value: "Home Visit" },
                                    { label: "SR030", value: "Pathology - Clinical Chemistry" },
                                    { label: "SR031", value: "Pathology - Haematology" },
                                    { label: "SR032", value: "Pathology - Immunology" },
                                    { label: "SR033", value: "Pathology - Microbiology" },
                                ]} placeholder='select...' 
                                value={details.service_requested?.value}
                                 onChangeValue={e => setDetails("service_requested", e)} label="Service Requested" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} /> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Paper>
    </Div >
}

export default withAllContexts(RefDetails);