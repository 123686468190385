import React from "react";

export const ValetParking = (props) => {
  let width = props.width ? props.width : "14";
  let height = props.height ? props.height : "15.556";
  let color = props.color ? props.color : "#101010";
  return (
    <svg 
    {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 15.556"
    >
        <defs>
            <style>{`.cls-7{fill:${color};}}`}</style>
        </defs>
      <path xmlns="http://www.w3.org/2000/svg" id="icons8-valet-parking" class="cls-7" d="M16.305,3a.583.583,0,0,0,0,1.167h2.722a.583.583,0,1,0,0-1.167ZM6.583,4.556a.583.583,0,1,0,0,1.167h3.1a.966.966,0,0,1,.946.747l.656,2.753H6.583a.583.583,0,1,0,0,1.167h4.861v4.083a.186.186,0,0,1-.194.194H6.583a.583.583,0,1,0,0,1.167h3.694v1.944a.74.74,0,0,0,.778.778h.778a.74.74,0,0,0,.778-.778V14.667h-.02a1.317,1.317,0,0,0,.02-.194V9.863A.583.583,0,0,0,12.6,9.7q0-.014-.006-.028L11.768,6.2h0A2.145,2.145,0,0,0,9.687,4.556Zm9.528.374v.015a1.556,1.556,0,0,0,3.111,0V4.929a1.065,1.065,0,0,1-.194.015H16.305A1.065,1.065,0,0,1,16.111,4.929Zm.475,2.543a2.139,2.139,0,0,0-1.252,1.944v3.306a.581.581,0,0,0,.389.548v4.7a.583.583,0,1,0,1.167,0l.778-4.861.778,4.861a.583.583,0,1,0,1.167,0v-4.7A.581.581,0,0,0,20,12.722V9.417a2.139,2.139,0,0,0-1.252-1.944L17.667,10Zm-6.5,4.278a.778.778,0,1,0,.778.778A.778.778,0,0,0,10.083,11.75Z" transform="translate(-6 -3)"/>
      </svg>
  );
};