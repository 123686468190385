import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Avatar,
  Popover,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { Div, Row, Col, Button, Card } from "qdm-component-library";
import { FormHeader } from "../../../components";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SliderCom } from "./silder";
import { DoccModel } from "./model";
import { FormRender } from "./formRender";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import actions from "../../../redux/actions";

import withAllContexts from "../../../hoc/withAllContexts";
import { AlertProps, makeName } from "../../../utils";
import { UIColor } from "../../../themes/theme";

import { connect } from "react-redux";
import noundocument from "../../../assets/img/png/noun_Document_3574392.png";
import menu2z from "../../../assets/img/png/icons8-menu@2x.png";

//import { ViewForm } from "form-configurator";

const list = [
  {
    title: "Clinical Assessment",
    count: 2,
    child: [
      {
        title: "Emergency Registration",
        user: [
          {
            title: "Docter",
            date: "26 Mar 04:30 PM",
            name: "Dr.Justin Brewer (You)",
          },
          {
            title: "Docter",
            date: "26 Mar 04:30 PM",
            name: "Dr.Justin Brewer",
          },
        ],
      },
      {
        title: "Emergency Registration",
        user: [
          {
            title: "Docter",
            date: "26 Mar 04:30 PM",
            name: "Dr.Justin Brewer (You)",
          },
          {
            title: "Docter",
            date: "26 Mar 04:30 PM",
            name: "Dr.Justin Brewer (You)",
          },
        ],
      },
    ],
  },
  {
    title: "Clinical Assessment",
    count: 2,
    child: [
      {
        title: "Emergency Registration",
        user: [
          {
            title: "Docter",
            date: "26 Mar 04:30 PM",
            name: "Dr.Justin Brewer (You)",
          },
        ],
      },
      {
        title: "Emergency Registration",
        user: [
          {
            title: "Docter",
            date: "26 Mar 04:30 PM",
            name: "Dr.Justin Brewer",
          },
        ],
      },
    ],
  },
];

const useStyles = makeStyles({
  root: {
    radio: {
      "& .checked": {
        color: UIColor.primaryColor.color,
      },
    },
    checked: {},
  },
  navlink: {
    display: "flex",
    width: "100%",
    border: "1px solid #fff",
    borderRadius: "10px",
    marginBottom: "10px",
    background: "#fff",
  },
  leftavatar: {
    cursor: "pointer",
    padding: "11px",
    background: UIColor.lineBorderFill.color,
    "& img": {
      objectFit: "scale-down",
    },
  },
  muiPopovers: {
    boxShadow: "0px 1px 10px #00000029 !important",
    borderRadius: "10px !important",
  },
  cancel: {
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,0.12)",
    color: "#000",
  },
  radio: {
    "& .checked": {
      color: UIColor.primaryColor.color,
    },
  },
  checked: {},
});

const initialState = {
  selectCatagory: null,
  selectDoccument: {},
  selectFilterCategory: null,
  headerShow: true,
  error: false,
  parentIndex: null,
  childIndex: null,
  userdetails: [],
  formData: { mobileStatus: "" },
  saveMode: "Draft",
  status: false,
};

const DoccForm = (props) => {
  let person_details = props.personDetails;

  const dispatch = useDispatch();
  const storeData = useSelector((state) => state?.DoccSlice);

  let singleRead = storeData?.docc_single_read?.data;

  const [stateDoc, setDocc] = useState({
    ...initialState,
    headerShow: true,
  });
  const [filterData, setFilterData] = React.useState([]);

  const [loading, setLoading] = React.useState(true);
  const [selectedForm, SetSelectedform] = React.useState("");
  const reason = useSelector(
    (state) => state?.DoccSlice?.docc_cat_read?.REASON
  );
  const [practitioner, setPractitioner] = React.useState([]);

  //to get master Data
  useEffect(() => {
    getData();

    //to get edit data based on category
    if (props.editData.id !== undefined) {
      let categoryID = props.editData.id.data.doccategory._id;
      getCategoryDoc(categoryID);
      stateDoc["selectFilterCategory"] = {
        label: props.editData.id.data.doccategory.display,
        value: props.editData.id.data.doccategory._id,
        _id: props.editData.id.data.doccategory._id,
        __id: props.editData.id.data.doccategory._id,
      };
      setDocc({
        ...stateDoc,
      });

      handleClick();
    }
  }, []);

  //master data
  const getData = async () => {
    const patient_data = props.patientID;
    const encounterID = props.encounterID;

    await dispatch(
      actions.DOCC_PRACTITIONER({
        patient_id: patient_data,
        encounter_id: encounterID,
      })
    );

    await dispatch(actions.DOCC_ENCOUNTER({ id: patient_data }));

    //let data = storeData?.docc_single_read?.data;

    //getPractitioner(data);
  };

  //to load the appropriate form
  React.useEffect(() => {
    console.log({ singleRead });

    setDocc({
      ...stateDoc,
      ...singleRead,
    });
    SetSelectedform(singleRead?._key);
    let Category = singleRead?.OldselectCatagory?._id;
    let docType = singleRead?.OldselectDoccument?._id;
    getPractitioner(Category, docType);
  }, [singleRead]);

  //to get practitoner who filled form
  const getPractitioner = async (cate, doc_Type) => {
    const patient_data = props.patientgetData;

    let prac = await dispatch(
      actions.PRACTITIONER_AGAINST_CATEGORY({
        patient_id: patient_data.pID,
        doc_Category: cate,
        doc_Type: doc_Type,
      })
    );

    let data = prac.payload.data;

    if (!prac.payload.error) {
      setPractitioner(data);
    }
  };

  // view slected form from model
  const handleEdit = async (v) => {
    handleClose();
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Fetching Docc..",
    });
    SetSelectedform(v._key);
    await dispatch(actions.DOCC_SINGLE_READ({ key: v._id }));
    setLoading(false);
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  };

  // getting form against category from model
  const getCategoryDoc = async (categoryID) => {
    let CategoryData = await dispatch(
      actions.DOCC_AGAINST_CATEGORY({
        category: categoryID,
        patient_id: props?.pID,
      })
    );
    let data = CategoryData?.payload?.data;

    if (!CategoryData.payload.error) {
      setFilterData(data);
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [openhead, setopen] = useState(true);
  const [update, setUpdate] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(true);
    if (props.editData.id === undefined) {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  // input handleChange
  const handleInputChange = async (e, name) => {
    if (name === "selectCatagory") {
      await dispatch(actions.DOCC_DOCC_READ({ id: e?.__id }));
    } else if (name === "selectFilterCategory") {
      //filter against category
      setDocc({ ...stateDoc, childIndex: null, parentIndex: null });
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: true,
        message: "Filtering Docc...",
      });
      let CategoryData = await dispatch(
        actions.DOCC_AGAINST_CATEGORY({
          category: e?._id,
          speciality: null,
          encoumter: null,
          practitioner: null,
          patient_id: props?.pID,
        })
      );
      let data = CategoryData?.payload?.data;
      setFilterData(data);
      SetSelectedform("");
      stateDoc.userdetails = [];
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: false,
        message: "",
      });
    } else if (name === "selectSpeciality") {
      //filter against speciality
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: true,
        message: "Filtering Docc...",
      });
      let CategoryData = await dispatch(
        actions.DOCC_AGAINST_CATEGORY({
          category: stateDoc?.selectFilterCategory?.value,
          speciality: e?._id,
          encoumter: null,
          practitioner: null,
          patient_id: props?.pID,
        })
      );
      let data = CategoryData?.payload?.data;
      setFilterData(data);
      SetSelectedform("");
      stateDoc.userdetails = [];
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: false,
        message: "",
      });
    } else if (name === "selectEncoumter") {
      //filter against encounter
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: true,
        message: "Filtering Docc...",
      });
      let CategoryData = await dispatch(
        actions.DOCC_AGAINST_CATEGORY({
          category: stateDoc?.selectFilterCategory?.value,
          speciality: stateDoc?.selectSpeciality?.value,
          encoumter: e?._id,
          practitioner: null,
          patient_id: props?.pID,
        })
      );
      let data = CategoryData?.payload?.data;
      setFilterData(data);
      SetSelectedform("");
      stateDoc.userdetails = [];
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: false,
        message: "",
      });
    } else if (name === "selectPractitioner") {
      //filter against practitioner
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: true,
        message: "Filtering Doc...",
      });
      let CategoryData = await dispatch(
        actions.DOCC_AGAINST_CATEGORY({
          category: stateDoc?.selectFilterCategory?.value,
          speciality: stateDoc?.selectSpeciality?.value,
          encoumter: stateDoc?.selectEncoumter?.value,
          practitioner: e?._id,
          patient_id: props?.pID,
        })
      );
      let data = CategoryData?.payload?.data;
      setFilterData(data);
      SetSelectedform("");
      stateDoc.userdetails = [];
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: false,
        message: "",
      });
    } else if (name === "saveMode" && e === "Final") {
      // handleClickOpen();
    }

    if (name === "formData" && !stateDoc.status) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.info,
        msg: "Form Submitted Please Click Update",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      setUpdate(true);
    } else if (name === "formData" && stateDoc.status) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.info,
        msg: "Form Already Submitted",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
    setDocc({
      ...stateDoc,
      [name]: e,
    });
  };

  const styles = {
    // cancelBtn: {
    // 	padding: "4px 10px",
    // 	fontSize: 14,
    // 	marginRight: 10,
    // 	borderColor: UIColor.lineBorderFill.color,
    // 	borderRadius: 8,
    // },
    // filledBtn: {
    // 	backgroundColor: UIColor.secondaryButtonColor.color,
    // 	borderColor: UIColor.lineBorderFill.color,
    // 	padding: "4px 10px",
    // 	fontSize: 14,
    // 	borderRadius: 8,
    // },
    cancelBtn: {
      padding: "8px",
      fontSize: 14,
      marginRight: 10,
      borderColor: UIColor.lineBorderFill.color,
      color: UIColor.primaryColor.color,
      borderRadius: 8,
    },
    filledBtn: {
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
      padding: "8px",
      fontSize: 14,
      borderRadius: 8,
    },
    flexEnd: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  };

  const classes = useStyles(props);

  const goBack = async () => {
    await props.Doccument.setDoccument({
      ...props.Doccument.Doccument,
      child: {},
    });
    props.onCancel();
  };

  //update the form
  const handleUpdate = async () => {
    if (update) {
      await props.saveDoccumentation([stateDoc], "update");
      goBack();
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please click Save before Update",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  const saveDocModal = async () => {
    handleClose();
    stateDoc.saveMode = "Draft";
    stateDoc.status = false;
    var editDoc = JSON.parse(JSON.stringify(stateDoc));
    editDoc.editId = null;

    await props.saveDoccumentation([editDoc], "Add");
    setLoading(false);
  };

  const onChangeState = async (key, value, ts) => {};

  const handleSelectList = (e, name, i, n) => {
    const val = e === stateDoc.parentIndex ? null : e;
    setDocc({
      ...stateDoc,
      [name]: val,
      childIndex: null,
      userdetails: [],
    });
  };

  const handleSelectListchild = (e, name, i, n) => {
    const a = stateDoc[n] === i ? null : i;
    setDocc({
      ...stateDoc,
      [name]: e,
      [n]: a,
    });
  };
  const handlehead = () => {
    setopen(!openhead);
  };

  const [modal, setModal] = React.useState(false);

  const handleClickOpen = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const FlterOptions = (type) => {
    var lista = [];
    const data = reason?.[type];

    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
        label: data[index]?.coding?.display,
        _id: data[index]?._id,
      };
      lista.push(datatemp);
    }
    return lista;
  };

  const { parent_id } = props;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  console.log("currrentSTateDocc", stateDoc);
  console.log("currrentSTateDocc2", storeData);

  return (
    <>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <Div className={classes.navlink}>
          <Div style={{ margin: 8 }}>
            <Avatar
              src={menu2z}
              className={classes.leftavatar}
              onClick={(e) => handleClick(e)}
            />
          </Div>
          <Div style={{ width: "100%" }}>
            <FormHeader
              parent_id={"doccumentation"}
              Docc={true}
              //statusData={"Active"}
              stateData={stateDoc}
              // status={true}
              statusDataList={stateDoc.statusData}
              onChangeState={onChangeState}
              // statusData={
              // 	stateDoc?.statushistory?.length > 0
              // 		? stateDoc?.statushistory?.[
              // 				stateDoc?.statushistory?.length - 1
              // 		  ]?.new_status
              // 		: "Active"
              // }
              headUrl={noundocument}
              //statusDataList={stateDoc.statusData}
              statushistory={stateDoc?.statushistory ?? []}
              patientDetails={{
                name: makeName(stateDoc?.practitionerid?.[0]?.name?.[0] || {}),
                isYou:
                  stateDoc?.practitionerid?.[0]?._id ===
                  person_details?.practitioner[0]?._id
                    ? true
                    : false,
                date: stateDoc.createdOn,
                profile_pic:
                  "https://thumbs.dreamstime.com/b/portrait-charming-imposing-kid-private-school-boy-feel-glad-ready-work-worker-want-be-cool-professional-look-hear-wear-blazer-152393107.jpg",
                status: "created",
              }}
              {...props}
            />
          </Div>
          <Div style={{ margin: 8 }}>
            <Avatar
              onClick={() => handlehead()}
              style={{
                background: UIColor.lineBorderFill.color,
                color: "#000",
              }}
            >
              {openhead ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Avatar>
          </Div>
        </Div>
        {props.editData.id === undefined || !loading ? (
          <>
            <div
              style={{
                height: "calc(100vh - 220px)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {/* <Div
								style={{
									borderBottom: `1px solid ${UIColor.lineBorderFill.color}`, paddingBottom: "10px"
								}}
							>
								{openhead && (
									<SliderCom
										onClickPractitoner={handleEdit}
										selectedForm={selectedForm}
										currentPractioner={person_details.practitioner[0]._id}
										isYou={
											stateDoc?.practitionerid?.[0]?._id ===
											person_details?.practitioner[0]?._id
												? true
												: false
										}
										data={practitioner ?? []}
									/>
								)}
							</Div> */}
              <Card inLineStyles={{ boxShadow: "none !important" }}>
                {/* {stateDoc.status === false ?  */}
                <FormRender
                  answer={stateDoc?.formData?.answer}
                  formId={stateDoc?.formID ? stateDoc?.formID : null}
                  //"036ac248-b684-4971-9bd5-01a558ac316c"
                  saveForm={(data) => handleInputChange(data, "formData")}
                />
                {/* <ViewForm answer={stateDoc?.formData?.answer} formName={stateDoc?.formData?.name} form={stateDoc?.formData?.form} />} */}{" "}
              </Card>
              {/* {JSON.stringify(stateDoc?.formData?.answer)} */}
              {/* {JSON.stringify(
                ` ${
                  stateDoc?.practitionerid?.[0]?._id +
                  " " +
                  person_details?.practitioner[0]?._id
                }`
              )} */}
            </div>

            {/* Footer */}
            <Row
              id={`${parent_id}_docc_procedure_buttons_row`}
              inLineStyles={{
                borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
                backgroundColor: "#fff",
                marginTop: "10px !important",
                borderRadius: "10px",
              }}
            >
              <Col
                id={`${parent_id}_docc_procedure_buttons_col`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  margin: "10px 0px",
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Div>
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="gender1"
                    value={stateDoc.saveMode}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "saveMode")
                    }
                  >
                    <FormControlLabel
                      value="Draft"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Draft"
                    />
                    <FormControlLabel
                      value="Final"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Final"
                    />
                  </RadioGroup>
                </Div>
                <Div
                  id={`${parent_id}_docc_procedure_buttons_div`}
                  inLineStyles={styles.flexEnd}
                >
                  <Button
                    id={`${parent_id}_laborder_Cancel_button`}
                    onClick={() => goBack()}
                    variant={"text"}
                    inLineStyles={styles.cancelBtn}
                  >
                    Cancel
                  </Button>
                  {!stateDoc.status ? (
                    <Button
                      id={`${parent_id}_laborder_Update_button`}
                      variant={"outlined"}
                      inLineStyles={styles.filledBtn}
                      onClick={() => handleUpdate()}
                    >
                      Update
                    </Button>
                  ) : null}
                </Div>
              </Col>
            </Row>
          </>
        ) : null}
        <Dialog
          open={modal}
          onClose={handleCloseModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Are you sure you want to edit this?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Reason for edit</DialogContentText>
            <Autocomplete
              className={classes.autocomplete}
              size="small"
              options={FlterOptions("Reason")}
              disableClearable
              style={{ width: "100%" }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Reason"
                  style={{ width: "98%", fontSize: 14 }}
                />
              )}
              renderOption={(option) => (
                <Typography
                  className="pc_regular"
                  style={{
                    width: "98%",
                    fontSize: 14,
                    color: UIColor.primaryText.color,
                  }}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              classes={{ popper: "muiPopperDropdown" }}
              value={stateDoc.selectReason}
              onChange={(e, newValue) =>
                handleInputChange(newValue, "selectReason")
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} className={classes.cancel}>
              Cancel
            </Button>

            <Button onClick={handleCloseModal}>Confirm</Button>
          </DialogActions>
        </Dialog>

        <Popover
          id={id}
          open={open}
          // open={true}
          anchorEl={anchorEl}
          onClose={handleClose}
          // anchorOrigin={{
          //   vertical: "top",
          //   horizontal: "center",
          // }}
          // transformOrigin={{
          //   vertical: "top",
          //   horizontal: "center",
          // }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          classes={{ paper: classes.muiPopovers }}
        >
          <DoccModel
            document={storeData?.docc_docc_read?.data ?? []}
            category={storeData.docc_cat_read.data?.DOCCATEGORY ?? []}
            speciality={storeData.docc_cat_read.data?.SPECIALTY ?? []}
            encounter={storeData.doc_encounter.data ?? []}
            practitioner={storeData.doc_practitioner.data ?? []}
            handleInputChange={handleInputChange}
            handleSelectList={handleSelectList}
            handleSelectListchild={handleSelectListchild}
            stateDoc={stateDoc}
            //data={storeData.doc_agnst_category.data ?? []}
            data={filterData}
            saveDoc={saveDocModal}
            loading={loading}
            handleEdit={handleEdit}
          />
        </Popover>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  singleRead: state?.DoccSlice.docc_single_read,
});

export default connect(mapStateToProps, actions)(withAllContexts(DoccForm));
