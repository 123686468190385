import * as React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const MonthYearPicker = (props) => {
  const { selectedDate, handleDateChange, name} = props;

  const handleChange = (date) => {
    handleDateChange({ target: { name, value: date } });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {props.name === "fetchDate"  ? (
      <DatePicker
        {...props}
        value={selectedDate}
        onChange={handleChange}
        views={["year", "month"]} // Only show year and month views
        openTo="month"// Open the picker to the year view
        maxDate={dayjs(props?.maxDate)}
        sx={{
          '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                padding:"7.5px 10px"
          },
          width:190
        }}
        // disableFuture
      />) : (<DatePicker
        {...props}
        value={selectedDate}
        onChange={handleChange}
        views={["year", "month"]} // Only show year and month views
        openTo="month"// Open the picker to the year view
        minDate={dayjs(props?.minDate)}
        sx={{
          '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                padding:"7.5px 10px"
          },
          width:190
        }}
        // disablePast
      />)}
    </LocalizationProvider>
  );
};

export default MonthYearPicker;