import React, { useState } from "react";
import {
    Div,
    Icons,
    Text,
    H6,
    Divider,
    TextInput,
} from "qdm-component-library";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
//import { DocumentIcon } from "../../../components";

function InfoStatus(props) {
    const styles = {
        h6: {
            fontSize: 14,
            margin: "16px 16px 5px 16px",
            fontWeight: 600,
        },
        labelStyle: {
            color: "#6F6F6F",
            fontSize: "12px",
            marginBottom: "6px",
            fontFamily: `pc_regular !important`,
        },
        subSection: {
            margin: "8px 16px 8px 16px",
        },
        subHeader: {
            fontSize: 12,
        },
        active: {
            margin: "0 0px 0 5px",
            fontSize: "12px",
        },
        flexStart: {
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            marginRight: "6px",
            padding: "4px 10px",
            cursor: "pointer",
        },
        bullet: {
            width: 6.5,
            height: 6.5,
            borderRadius: "50%",
            //   backgroundColor: "#f58b00",
        },
        historyHeader: {
            backgroundColor: "#f0f0f0",
            padding: "12px 16px 6px 16px",
            display: "flex",
        },
        iconSize: {
            width: 15,
            height: 15,
            cursor: "pointer",
        },
        historySectionText: {
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
        },
        lastSection: {
            display: "flex",
            marginTop: "4px",
        },
        forwardIcon: {
            fontSize: "14px",
            color: "#6F6F6F",
        },
        lastSectionText: {
            fontSize: "12px",
            color: "#6F6F6F",
            marginLeft: "8px",
            paddingRight: "32px",
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: "#E0E0E0",
        },
    };
    const [open, setOpen] = useState(false);
    const [showHistory, setShowHistory] = useState(true);

    const handleShowHistory = () => {
        setShowHistory(!showHistory);
    };

    const handleStatus = (item) => {
        // setOpen(true)
        props.handleChange('clinicalStatus', item)
    }


    const { statusList, currentStatus, state, parent_id } = props;


    return (
        <div
            id={`${parent_id}_infostatus_parent_div`}
        >
            <Div
                id={`${parent_id}_infostatus_Status_info_div`}
                style={{
                    borderBottom: "1px solid #e0e0e0",
                }}
            >
                <H6
                    id={`${parent_id}_infostatus_Status_info_h6`}
                    inLineStyles={styles.h6}>Status info</H6>
            </Div>

            <div
                id={`${parent_id}_infostatus_Change_status_div`}
                style={styles.subSection}>
                <H6
                    id={`${parent_id}_infostatus_Change_status_h6`}
                    inLineStyles={styles.subHeader}>Change status to</H6>
                <div
                    id={`${parent_id}_infostatus_Change_status_div`}
                    className={"status-selector"} style={{ display: "flex", padding: "6px 0px" }}>
                    {
                        statusList.slice(0, 3).map((item, i) => {
                            const currentItem = item?.label?.toLowerCase() ?? item.toLowerCase();
                            return <div
                                id={`${parent_id}_infostatus_${i}_${item?.label}_div`}
                                className={currentItem === currentStatus ? `selected selected-${currentItem}` : currentItem}
                                style={styles.flexStart}
                                onClick={() => handleStatus(item)}
                            >
                                <Div
                                    id={`${parent_id}_infostatus_${i}_${item?.label}_sub_div`}
                                    className={"bullet"}
                                    style={styles.bullet}
                                />
                                <Text
                                    id={`${parent_id}_infostatus_${i}_${item?.label}_text`}
                                    className={"text"} style={styles.active}>
                                    {item?.label}
                                </Text>
                            </div>
                        })
                    }
                </div>
                <div
                    id={`${parent_id}_infostatus_div`}
                    className={"status-selector"} style={{ display: "flex", padding: "6px 0px" }}>
                    {
                        statusList.slice(3, 6).map((item, i) => {
                            const currentItem = item?.label?.toLowerCase() ?? item.toLowerCase();
                            return <div
                                id={`${parent_id}_infostatus_${i}_${item?.label}_div`}
                                className={currentItem === currentStatus ? `selected selected-${currentItem}` : currentItem}
                                style={styles.flexStart}
                                onClick={() => handleStatus(item)}
                            >
                                <Div
                                    id={`${parent_id}_infostatus_${i}_${item?.label}_sub_div`}
                                    className={"bullet"}
                                    style={styles.bullet}
                                />
                                <Text
                                    id={`${parent_id}_infostatus_${i}_${item?.label}_text`}
                                    className={"text"} style={styles.active}>
                                    {item?.label}
                                </Text>
                            </div>
                        })
                    }
                </div>
                {open ? (
                    <div
                        id={`${parent_id}_infostatus_Add_Reason_div`}
                    >
                        <TextInput
                            id={`${parent_id}_infostatus_Add_Reason_textinput`}
                            label={"Reason"}
                            placeholder="Add Reason"
                            labelStyle={styles.labelStyle}
                            style={styles.borderStyle}
                            hoverColor="#0071F2"
                        />
                    </div>
                ) : null}
            </div>

            <div
                id={`${parent_id}_infostatus_Show_History_div`}
                style={styles.historyHeader}>
                <Text
                    id={`${parent_id}_infostatus_Show_History_text`}
                    style={{ fontSize: "12px", fontWeight: 500 }}>Show History</Text>
                <div style={{ flexGrow: 1 }}></div>
                <Icons
                    id={`${parent_id}_infostatus_Show_History_icons`}
                    fontIcon={showHistory ? "chevron-up" : "chevron-down"}
                    style={styles.iconSize}
                    onClick={handleShowHistory}
                />
            </div>
            <div
                id={`${parent_id}_infostatus_personname_div`}
                style={{ width: "300px" }}>
                {showHistory ? (
                    <React.Fragment>
                        {
                            state.clicnicalstatushistory?.map((val, i) => {
                                return <div
                                    id={`${parent_id}_infostatus_${i}_personname_div`}
                                    style={{ padding: "6px 16px 0px" }}>
                                    <div
                                        id={`${parent_id}_infostatus_${i}_personname_sub_div`}
                                        style={{ display: "flex" }}>
                                        <Text
                                            id={`${parent_id}_infostatus_${i}_${val?.personName}_text`}
                                            style={{
                                                paddingRight: "35px",
                                                fontWeight: 600,
                                                fontSize: "14px",
                                            }}
                                        >
                                            {val?.personName}
                                        </Text>
                                        <div
                                            id={`${parent_id}_infostatus_${i}_${val?.personName}_div`}
                                            style={{ flexGrow: 1 }}></div>
                                        <Text
                                            id={`${parent_id}_infostatus_${i}_Practitioner_text`}
                                            inLineStyles={styles.historySectionText}>Practitioner</Text>
                                    </div>

                                    <div
                                        id={`${parent_id}_infostatus_${i}_Practitioner_div`}
                                        style={styles.lastSection}>
                                        {val?.old_status && <Text
                                            id={`${parent_id}_infostatus_${i}_${val?.old_status}_text`}
                                            inLineStyles={{
                                                color: "#F58B00",
                                                ...styles.historySectionText,
                                            }}
                                        >
                                            {val?.old_status}
                                        </Text>}
                                        {val?.old_status && <ArrowForwardIcon
                                            id={`${parent_id}_infostatus_${i}_ArrowForwardIcon`}
                                            style={styles.forwardIcon} />}
                                        <Text
                                            id={`${parent_id}_infostatus_${i}_${val?.new_status}_text`}
                                            inLineStyles={{
                                                color: "#37C009",
                                                ...styles.historySectionText,
                                            }}
                                        >
                                            {val?.new_status}
                                        </Text>
                                        <div style={{ flexGrow: 1 }}></div>
                                        <Text
                                            id={`${parent_id}_infostatus_${i}_${val?.createdAt}_text`}
                                            inLineStyles={{
                                                color: "#323232",
                                                fontWeight: 500,
                                                ...styles.historySectionText,
                                            }}
                                        >
                                            {val?.createdAt}
                                        </Text>
                                    </div>
                                    <Divider
                                        id={`${parent_id}_infostatus_${i}_divider`}
                                        style={{ margin: "8px 0px" }}></Divider>
                                </div>
                            })
                        }
                    </React.Fragment>
                ) : null}

                {/* <div style={{ padding: "0px 16px 16px", ...styles.lastSection }}>
                    <DocumentIcon width="12px" height="12px" />
                    <Text inLineStyles={styles.lastSectionText}>
                        Allergy symptoms found for itching
                    </Text>
                </div> */}
            </div>
        </div>
    );
}

export default InfoStatus;
