import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  body: {
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
  },
  TapSelect: {
    "& div": {
      "& span": {
        fontSize: 13,
        fontWeight: "600",
        letterSpacing: "0.9px",
      },
    },
  },
}));
