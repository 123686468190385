import React from "react";

export const WSTCM = (props) => {
  let width = props.width ? props.width : "13";
  let height = props.height ? props.height : "15";
  //   let color = props.color ? props.color : "#000000";
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={width}
      height={height}
    >
      <path d="M 7.8007812 4.4003906 L 6.1992188 5.5996094 C 6.1992188 5.5996094 9 9.3333333 9 15 C 9 16.055556 8.1203759 17.77292 7.1054688 19.802734 C 6.0905616 21.832549 5 24.233333 5 27 L 7 27 C 7 25.682019 7.3523939 24.424843 7.8144531 23.199219 C 8.4676293 23.290519 9.5177293 23.393931 11.183594 23.949219 C 12.245348 24.303137 13.282445 24.777406 13.980469 25.320312 C 14.678492 25.863221 15 26.375 15 27 L 17 27 C 17 26.375 17.321508 25.86322 18.019531 25.320312 C 18.717555 24.777406 19.754652 24.303137 20.816406 23.949219 C 22.48227 23.393931 23.532371 23.29052 24.185547 23.199219 C 24.647606 24.424843 25 25.682019 25 27 L 27 27 C 27 24.233333 25.909438 21.832549 24.894531 19.802734 C 23.879624 17.77292 23 16.055556 23 15 C 23 9.3333333 25.800781 5.5996094 25.800781 5.5996094 L 24.199219 4.4003906 C 24.199219 4.4003906 21 8.6666667 21 15 C 21 15.684626 21.257475 16.340252 21.480469 17 L 10.519531 17 C 10.742525 16.340252 11 15.684626 11 15 C 11 8.6666667 7.8007812 4.4003906 7.8007812 4.4003906 z M 15 5 L 15 7 L 17 7 L 17 5 L 15 5 z M 15 9 L 15 11 L 17 11 L 17 9 L 15 9 z M 15 13 L 15 15 L 17 15 L 17 13 L 15 13 z M 9.7382812 19 L 22.261719 19 C 22.545222 19.561085 22.81296 20.112248 23.105469 20.697266 C 23.209161 20.90465 23.281772 21.129593 23.382812 21.339844 C 22.527981 21.469895 21.799537 21.512134 20.183594 22.050781 C 18.995348 22.446863 17.782445 22.972594 16.792969 23.742188 C 16.483534 23.982858 16.254083 24.313722 16 24.619141 C 15.745917 24.313722 15.516466 23.982859 15.207031 23.742188 C 14.217555 22.972594 13.004652 22.446863 11.816406 22.050781 C 10.200463 21.512134 9.47202 21.469895 8.6171875 21.339844 C 8.7182286 21.129593 8.7908393 20.90465 8.8945312 20.697266 C 9.18704 20.112248 9.4547781 19.561085 9.7382812 19 z" />
    </svg>
  );
};
