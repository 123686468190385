import React from "react";

export const CancelAppointment = (props) => {
  let width = props.width ? props.width : "12.667";
  let height = props.height ? props.height : "14";
  let color = props.color ? props.color : "#6f6f6f";
  return (
    <svg 
    {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12.667 14"
    >
        <defs>
            <style>{`.cls-7{fill:${color};}}`}</style>
        </defs>
        <path xmlns="http://www.w3.org/2000/svg" class="cls-7" d="M9.5,4A1.5,1.5,0,0,0,8,5.5V15.833a1.5,1.5,0,0,0,1.5,1.5h4.378a4.34,4.34,0,0,1-.719-1H9.5a.5.5,0,0,1-.5-.5V5.5A.5.5,0,0,1,9.5,5h3.833V7.833a1.5,1.5,0,0,0,1.5,1.5h2.833v.723a4.309,4.309,0,0,1,1,.276v-1.5a.5.5,0,0,0-.146-.353L14.187,4.146A.5.5,0,0,0,13.833,4Zm4.833,1.707,2.626,2.626H14.833a.5.5,0,0,1-.5-.5ZM17,10.667a3.667,3.667,0,1,0,3.667,3.667A3.667,3.667,0,0,0,17,10.667ZM11.167,11a.5.5,0,1,0,0,1h2.186a4.353,4.353,0,0,1,.88-1Zm4.167,1.333a.332.332,0,0,1,.236.1L17,13.862l1.431-1.431a.333.333,0,1,1,.471.471l-1.431,1.431L18.9,15.764a.333.333,0,1,1-.471.471L17,14.8l-1.431,1.431a.333.333,0,1,1-.471-.471l1.431-1.431L15.1,12.9a.333.333,0,0,1,.236-.569Zm-4.167,1a.5.5,0,0,0,0,1h1.5a4.338,4.338,0,0,1,.12-1Z" transform="translate(-8 -4)"/>
      </svg>
  );
};
