import React from "react";
import { PatientDetailsContext, SetPatientDetailsContext } from ".";
import { useState } from "react";

function Appstatecontext(props) {
  const [patientDetails, setPatientDetails] = useState({
    bookFlag: {
      modifyBook: false,
      multiBook: false,
      singleBook: false, // while setting the flag.dont spread the flag values.keys mentioned here are for to know what is keyname
    },
    orgId: "",
    specialityId: [],
    practionerId: "",
    patientId: "",
    patientData: {},
    nextOfKinData: {},
    selectedUser: {},
    slotId: "",
    location: {},
    selectedSlot: {},
  });
  return (
    <PatientDetailsContext.Provider value={patientDetails}>
      <SetPatientDetailsContext.Provider value={setPatientDetails}>
        {props?.children}
      </SetPatientDetailsContext.Provider>
    </PatientDetailsContext.Provider>
  );
}

export default Appstatecontext;
