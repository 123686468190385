import React from "react";
import {
  Div,
  Paper,
  Select,
  TextInput,
  H6,
  Col,
  Row,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../hoc/withAllContexts";

const PatientCategory = (props) => {
  let styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
  };

  const setData = (name, value, index) => {
    // let patientData = props[props.saveto]
    // if(name === "patient_importance"){
    //     patientData.additnaldata["special_courtesy"] = "";
    //     patientData.additnaldata["special_arrangement"] = "";
    // }
    // patientData.additnaldata[name] = value;
    // patientData[this.props.setTo]({
    //     ...patientData,
    // })
  };

  const FlterOptions = (type) => {
    // const { allMasters } = props;
    // var lista = []
    // const data = allMasters?.[type]
    // for (let index in data) {
    //     var datatemp = {
    //         ...data[index],
    //         "value": data[index]?.coding?.display,
    //         "_id": data[index]?.coding?._id,
    //     };
    //     lista.push(datatemp);
    // }
    // return lista
  };

  let patientData = props[props.saveto]?.additnaldata;
  const { labelStyle, borderStyle } = styles;
  const { parent_id } = props;

  return (
    <Div id={`${parent_id}_patientcategory_parent_div`} className="pi-root">
      <Paper
        id={`${parent_id}_patientcategory_parent_paper`}
        className="pi-paper"
      >
        <Div id={`${parent_id}_patientcategory_label_div`}>
          <H6 id={`${parent_id}_patientcategory_label_h6`} className="pi-title">
            PATIENT CATEGORY
          </H6>
        </Div>
        <Row id={`${parent_id}_patientcategory_parent_row`}>
          <Col
            id={`${parent_id}_patientcategory_patienttype_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}_patientcategory_patienttype_select`}
              options={FlterOptions("Patient Type")}
              onChangeValue={(e) => setData("patient_type", e)}
              value={patientData?.patient_type?.value}
              label="Patient Type"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
            />
          </Col>
          <Col
            id={`${parent_id}_patientcategory_patient_importance_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}_patientcategory_patient_importance_select`}
              options={FlterOptions("PATIENTIMPORTANCETYPE")}
              onChangeValue={(e) => setData("patient_importance", e)}
              value={patientData?.patient_importance?.value}
              label="Patient's Importance"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
            />
          </Col>
          {patientData?.patient_importance?.coding?.gmconfigvalues?.[
            "Spl Courtesy"
          ] && (
            <Col
              id={`${parent_id}_patientcategory_specialcourtesy_col`}
              md={4}
              lg={4}
              sm={6}
              xs={12}
              className="no-padding-left"
              inLineStyles={{ padding: 10 }}
            >
              {/* <TextInput
                            disabled={patientData?.patient_importance?.coding?.gmconfigvalues?.["Spl Courtesy"]?false:true}
                            id={`${parent_id}_patientcategory_specialcourtesy_label_textinput`}
                            onChange={(e) => this.setData('special_courtesy', e.target.value)}
                            value={patientData?.special_courtesy}
                            label="Special Courtesy" 
                            placeholder="special courtesy" 
                            labelStyle={labelStyle} 
                            style={borderStyle} 
                            hoverColor="#0071F2" 
                        /> */}
              <Select
                id={`${parent_id}_patientcategory_special_courtesy_select`}
                options={FlterOptions("SPLCOURTESY")}
                onChangeValue={(e) => setData("special_courtesy", e)}
                value={patientData?.special_courtesy?.value}
                label="Special Courtesy"
                placeholder="select..."
                showArrow
                labelStyle={labelStyle}
                inLineStyles={borderStyle}
                hoverColor="#0071F2"
              />
            </Col>
          )}
          {patientData?.patient_importance?.coding?.gmconfigvalues?.[
            "Spl Arrangement"
          ] && (
            <Col
              id={`${parent_id}_patientcategory_specialarrangement_col`}
              md={4}
              lg={4}
              sm={6}
              xs={12}
              className="no-padding-left"
              inLineStyles={{ padding: 10 }}
            >
              {/* <TextInput
                            disabled={patientData?.patient_importance?.coding?.gmconfigvalues?.["Spl Arrangement"]?false:true}
                            id={`${parent_id}_patientcategory_specialarrangement_label_textinput`}
                            onChange={(e) => this.setData('special_arrangement', e.target.value)}
                            value={patientData?.special_arrangement}
                            label="Special Arrangement" placeholder="special arrangement" labelStyle={labelStyle} style={borderStyle} hoverColor="#0071F2" 
                        /> */}
              <Select
                id={`${parent_id}_patientcategory_specialarrangement_label_textinput`}
                options={FlterOptions("SPLARRANGEMENTS")}
                onChangeValue={(e) => setData("special_arrangement", e)}
                value={patientData?.special_arrangement?.value}
                label="Special Arrangement"
                placeholder="select..."
                showArrow
                labelStyle={labelStyle}
                inLineStyles={borderStyle}
                hoverColor="#0071F2"
              />
            </Col>
          )}
          <Col
            id={`${parent_id}_patientcategory_race_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}_patientcategory_race_select`}
              onChangeValue={(e) => setData("race", e)}
              value={patientData?.race?.value}
              options={FlterOptions("Race")}
              label="Race"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
            />
          </Col>
          <Col
            id={`${parent_id}_patientcategory_ethnicity_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}_patientcategory_ethnicity_select`}
              onChangeValue={(e) => setData("ethnicity", e)}
              value={patientData?.ethnicity?.value}
              options={FlterOptions("Ethnicity")}
              label="Ethnicity"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
            />
          </Col>
          <Col
            id={`${parent_id}_patientcategory_religion_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}_patientcategory_religion_select`}
              onChangeValue={(e) => setData("religion", e)}
              value={patientData?.religion?.value}
              options={FlterOptions("Religion")}
              label="Religion"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
            />
          </Col>
          <Col
            id={`${parent_id}_patientcategory_bloodgroup_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}_patientcategory_bloodgroup_select`}
              onChangeValue={(e) => setData("blood_group", e)}
              value={patientData?.blood_group?.value}
              options={FlterOptions("Blood Group")}
              label="Blood Group"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
            />
          </Col>
        </Row>
      </Paper>
    </Div>
  );
};

export default withAllContexts(PatientCategory);
