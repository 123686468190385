import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    // border: `1px solid #DEE5EC`,
    boxShadow: `#0000000A 0px 10px 25px`,
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
  },
}));
