import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../../../themes/theme";

export const useStyles = makeStyles((theme) => ({
  
  root: {
    overflow: "auto",
    zIndex: 99999999,
    backgroundColor: UIColor.differentiationBackground.color,
  },
  search: {
    background: "#0071f2",
    boxShadow: "none",
    color: "#fff",
  },
  clear: {
    marginTop: "10px",
    width: "100%",
    borderRadius: 8,
    color: "#6F6F6F",
    fontSize: 13,
    border: "1px solid #e0e0e0",
    boxShadow: "none",
    background: "#fff",

    height: "38px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
    cursor: "pointer",
  },
  title: {
    padding: "16px 0px 16px",
    fontWeight: 600,
    fontSize: 20,
    fontFamily: "pc_semibold !important",
    margin: "inherit !important",
  },
  mbl: {
    "& .bcDEQH": {
      background: "#fff",
    },
  },
  searchOptions: {
    height: "50px",
    borderTop: "1px solid #f1f1f1",
    borderBottom: "1px solid #f1f1f1",
    color: "#0071f2",
    display: "flex",
    // margin: "20px 0px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    cursor: "pointer",
  },
  pd_0: {
    padding: "4px 8px !important",
  },
  dis: {
    color: "#101010",
    fontSize: 15,
    fontFamily: "pc_medium !important",
  },
  err: {
    textAlign: "center",
    color: "red",
    fontSize: "14px",
    marginTop: "4px",
  },
  root1: {
    //zIndex: 10,
    position: "relative",
    background: "#fff",
    boxShadow: "none !important",
    border: "1px solid #E0E0E0",
    borderRadius: 9,
    marginBottom: 10,
  },
  pDiv: {
    padding: 10,
  },
  img: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  show: {
    width: "30px",
    height: "30px",
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  list: {
    display: "flex",
    color: "#6F6F6F",
    fontSize: "12px",
    padding: "8px 4px 0px",
    "& span": {
      fontSize: "12px",
    },
  },
  overlap: {
    position: "absolute",
    right: 0,
    bottom: 34,
    zIndex: -1,
  },
  icons: {
    "&:before": {
      fontSize: "20px !important",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "10px",

    borderTop: "1px solid #E0E0E0",
  },
  modifyDivline: {
    borderRight: "1px solid #E0E0E0",
  },
  modifyDiv: {
    padding: "5px",
    color: "#0071F2",
    cursor: "pointer",
    flex: 1,
  },
  cancelDiv: {
    padding: "5px",
    color: "#FF4D4A",
    cursor: "pointer",
    flex: 1,
  },
  appointname: {
    fontSize: "14px",
    fontFamily: "pc_semibold",
  },
  time: {
    fontSize: "12px",
    color: "#6F6F6F",
    fontFamily: "pc_regular",
    marginBottom: "10px",
  },
  DiVP: {
    display: "flex",
    justifyContent: "space-between",
  },
  labelStyle: {
    color: UIColor.primaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
}));
