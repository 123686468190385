import * as React from "react";
import PropTypes from "prop-types";

const PillDark = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.661" height="13.677" viewBox="0 0 13.661 13.677">
  <path id="icons8-pharmacy-shop" d="M19.569,11a4.4,4.4,0,0,0-3.045,1.254q-.033.032-.063.066c.077-.088-.071.075-.212.218s-.337.34-.567.571c-.459.462-1.058,1.065-1.659,1.667s-1.2,1.205-1.667,1.673l-.578.584c-.126.128-.181.182-.26.269l0,0a4.322,4.322,0,0,0,3.064,7.371,4.4,4.4,0,0,0,3.047-1.254l.014-.014,5.016-5.033h0a4.326,4.326,0,0,0-.008-6.113l-.007-.007A4.429,4.429,0,0,0,19.569,11Zm0,2.16a2.223,2.223,0,0,1,1.568.647,2.129,2.129,0,0,1-.011,3.045h0L18.8,19.188l-3.066-3.066c.535-.536,1.073-1.075,1.485-1.49l.572-.578c.145-.148.179-.174.3-.312l-.063.067A2.223,2.223,0,0,1,19.569,13.159Z" transform="translate(-10.256 -11)"
   fill={props?.color}/>
</svg>
);

export default PillDark;

PillDark.defaultProps = {
  color: "black",
};

PillDark.propTypes = {
  color: PropTypes.string,
};