import React from "react";

export const WeightKgIcon = (props) => {
  let width = props.width ? props.width : "17";
  let height = props.height ? props.height : "15";
//   let color = props.color ? props.color : "#000000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 15">
    <g id="icons8-weight-kg" transform="translate(-3 -4)">
        <path id="Path_92652" data-name="Path 92652" d="M19.851,4A2.851,2.851,0,1,0,22.7,6.851,2.851,2.851,0,0,0,19.851,4Zm0,4.481a1.629,1.629,0,1,1,1.629-1.629A1.629,1.629,0,0,1,19.851,8.481Z" transform="translate(-8.351)" fill="#7986cb"/>
        <path id="Path_92653" data-name="Path 92653" d="M18.286,14.591A.825.825,0,0,0,17.478,14H5.523a.825.825,0,0,0-.808.591L3.026,23.957a.793.793,0,0,0,.808.97H19.167a.793.793,0,0,0,.808-.97Z" transform="translate(0 -5.926)" fill="#9fa8da"/>
        <path id="Path_92654" data-name="Path 92654" d="M17.408,24.684l-.482.517v1.28H16V22h.927v2.031l.408-.557L18.48,22h1.14l-1.6,1.991,1.643,2.49h-1.1Zm2.258.16a2.33,2.33,0,0,1,.088-.66,1.566,1.566,0,0,1,.251-.513,1.136,1.136,0,0,1,.4-.332,1.154,1.154,0,0,1,.528-.118,1.067,1.067,0,0,1,.468.094.941.941,0,0,1,.33.264l.035-.3h.755v3.065a1.439,1.439,0,0,1-.11.578,1.2,1.2,0,0,1-.307.427,1.32,1.32,0,0,1-.473.264,1.968,1.968,0,0,1-.61.089,1.711,1.711,0,0,1-.306-.029,1.756,1.756,0,0,1-.319-.091,1.573,1.573,0,0,1-.3-.156.986.986,0,0,1-.24-.223l.369-.516a1.09,1.09,0,0,0,.336.261.927.927,0,0,0,.42.094.707.707,0,0,0,.516-.18.684.684,0,0,0,.185-.512v-.17a.96.96,0,0,1-.326.245,1.02,1.02,0,0,1-.433.087,1.108,1.108,0,0,1-.523-.122,1.183,1.183,0,0,1-.4-.336,1.58,1.58,0,0,1-.251-.509,2.224,2.224,0,0,1-.088-.64Zm.833.061a1.649,1.649,0,0,0,.037.361.909.909,0,0,0,.117.292.6.6,0,0,0,.2.2.543.543,0,0,0,.284.072.665.665,0,0,0,.333-.076.552.552,0,0,0,.212-.214V24.2a.567.567,0,0,0-.212-.215.655.655,0,0,0-.327-.075.54.54,0,0,0-.284.072.609.609,0,0,0-.2.2.929.929,0,0,0-.12.3,1.632,1.632,0,0,0-.039.367Z" transform="translate(-7.758 -11.027)" fill="#3949ab"/>
    </g>
    </svg>
    );
};