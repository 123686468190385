import React from "react";
import { Div, Avatar, H6, Card, Text, Image } from "qdm-component-library";
import { withStyles } from "@material-ui/core";
import clockIcon from "../../../assets/img/svg/icons8-clock-black.svg"
import readIcon from "../../../assets/img/svg/icons8-reading.svg"
import { UIColor } from "../../../themes/theme";


const materialStyles = (theme) => ({
  avatarCls: {
    '& img': {
      width: '30px !important',
      height: '30px !important',
      objectFit: 'contain !important'
    }
  }
});

const PatientStatusCard = (props) => {
  const {
    classes,
    parent_id,
    pic,
    name,
    titles,
    date,
    languages
  } = props;

  const styles = {
    rowFlex: {
      display: 'flex',
      alignItems: 'center'
    },
    columnFlex: {
      display: 'flex',
      flexDirection: 'column'
    },
    h6: {
      fontSize: 13,
      color: UIColor.primaryColor.color,
      margin: 0
    },
    span: {
      fontSize: 12,
      color: UIColor.tertiaryText.color,
      margin: 0
    },
    span_lag: {
      fontSize: 12,
      color: UIColor.tertiaryText.color,
      margin: 0,
      // width: 'calc(65%)',
      whiteSpace: "nowrap",
      overflow: "hidden",
      // textOverflow: "ellipsis"
    },
    cardStyle: {
      // boxShadow: '0px 10px 25px #0000000A',
      boxShadow: "none",
      borderRadius: 8,
      marginLeft: "5px",
      padding: 10
      // ...props.padding ? {padding: 10} : {padding: 0}
    },
    avatar: {
      margin: 0,
      marginRight: 15,
      width: 48,
      height: 48,
      borderRadius: 8,
      backgroundColor: "#F0F0FA"
    },
    img: {
      width: 16,
      height: 16,
      marginRight: 10
    }
  }

  return (
    <Card
      id={`${parent_id}_patientstatus_parent_card`}
      style={styles.cardStyle}
    >
      <Div
        id={`${parent_id}_patientstatus_parent_div`}
        style={{ ...styles.rowFlex }}
      >
        {pic ? (
          <Avatar
            id={`${parent_id}_patientstatus_${name?.[0]}_avatar`}
            src={pic}
            variant="rounded"
            className={classes.avatarCls}
            inLineStyles={styles.avatar}
          />
        ) : (
          <Avatar
            id={`${parent_id}_patientstatus_${name?.[0]}_avatar`}
            src={pic}
            variant="rounded"
            // className={classes.avatarCls}
            inLineStyles={styles.avatar}
          >
            {name?.[0]}
          </Avatar>
        )}
        <Div
          id={`${parent_id}_patientstatus_sub_parent_div`}
          style={styles.columnFlex}
        >
          <Text
            id={`${parent_id}_patientstatus_text`}
            style={styles.rowFlex}
          >
            {titles?.length > 0 &&
              titles.map((val, i) => {
                if (!val) {
                  return <React.Fragment key={i} />;
                }
                return (
                  <>
                    <H6
                      id={`${parent_id}_${i}_patientstatus_h6`}
                      className="pc_semibold"
                      style={styles.h6}
                      key={i}
                    >
                      {val}
                    </H6>
                    {i + 1 !== titles.length && (titles[i + 1] || titles[i + 1] === "") && (
                      <Text
                        id={`${parent_id}_${i}_patientstatus_dot_text`}
                        className="pc_regular"
                        style={{ ...styles.span, margin: '0px 5px' }}
                      >
                        •
                      </Text>
                    )}
                  </>
                );
              })}
          </Text>
          <Div
            id={`${parent_id}_patientstatus_image_div`}
            style={{ ...styles.rowFlex, marginTop: 7 }}
          >
            {date && (
              <Div
                id={`${parent_id}_patientstatus_image_div`}
                style={{ ...styles.rowFlex, marginRight: 15, minWidth: "120px" }}
              >
                <Image
                  id={`${parent_id}_patientstatus_black_image`}
                  style={styles.img}
                  src={clockIcon}
                  alt="id"
                />
                <Text
                  id={`${parent_id}_patientstatus_on_text`}
                  className="pc_regular"
                  style={styles.span}
                >
                  On {date}
                </Text>
              </Div>
            )}
            {languages?.length > 0 && (
              <Div
                id={`${parent_id}_patientstatus_reading_div`}
                style={styles.rowFlex}
              >
                <Image
                  id={`${parent_id}_patientstatus_reading_image`}
                  style={styles.img}
                  src={readIcon}
                  alt="id"
                />
                <Text
                  id={`${parent_id}_patientstatus_language_text`}
                  className="pc_regular"
                  style={styles.span_lag}
                >
                  {languages}
                </Text>
              </Div>
            )}
          </Div>
        </Div>
      </Div>
    </Card>
  );
};

PatientStatusCard.defaultProps = {
  pic: null,
  name: "",
  titles: [],
  date: null,
  languages: null
};

export default withStyles(materialStyles)(PatientStatusCard);







