import React from 'react';
import { Div, H6, Text, Divider, Icons, Avatar } from 'qdm-component-library';
import { UIColor } from '../../../themes/theme';
export const RequestorDetails = (props) => {

    const styles = {
        label: {
            fontSize: 14,
            color: UIColor.tertiaryText.color
        },
        span: {
            fontSize: 12,
            color: UIColor.secondaryText.color
        },
        divider: {
            margin: 0,
            height: 0.5
        },
        avatar: {
            width: 40,
            height: 40
        }
    }
    const { parent_id } = props;
    return (
        <div
            id={`${parent_id}_requestordetails_parent_div`}
        >
            <Div
                id={`${parent_id}_requestordetails_title_div`}
                style={{ padding: 10 }}>
                <H6
                    id={`${parent_id}_requestordetails_${props?.title.replaceAll(" ", "_")}_div`}
                    className="pc_semibold" style={styles.label}>{props?.title}</H6>
            </Div>
            <Divider
                id={`${parent_id}_requestordetails_bottom_divider`}
                style={styles.divider} />
            <Div
                id={`${parent_id}_requestordetails_title_parent_div`}
                style={{ padding: 10 }}>
                <Div
                    id={`${parent_id}_requestordetails_title_sub_div`}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Div
                        id={`${parent_id}_requestordetails_title_div`}
                    >
                        {props?.name && <H6
                            id={`${parent_id}_requestordetails_${props?.name.replaceAll(" ", "_")}_h6`}
                            className="pc_semibold" style={styles.label}>{props?.name}</H6>}
                        {props?.pidNumber && <Text
                            id={`${parent_id}_requestordetails_${props?.pidNumber.replaceAll(" ", "_")}_text`}
                            className="className" style={styles.span}>{props?.pidNumber}</Text>}
                        {props?.role && <Text
                            id={`${parent_id}_requestordetails_${props?.role.replaceAll(" ", "_")}_text`}
                            className="className" style={styles.span}>&nbsp;{'•'}&nbsp;{props?.role}</Text>}
                    </Div>
                    {
                        props?.profile_pic &&
                        <Avatar
                            id={`${parent_id}_requestordetails_profile_avatar`}
                            variant="square"
                            letter="Avatar"
                            src={props?.profile_pic}
                            alt="Image"
                            style={styles.avatar}
                        />
                    }
                </Div>
                <Divider
                    id={`${parent_id}_requestordetails_profile_divider`}
                    style={{ margin: '5px 0px' }} />
                {props?.hospital && <Text
                    id={`${parent_id}_requestordetails_${props?.hospital.replaceAll(" ", "_")}_text`}
                    className="className" style={styles.span}><Icons fontIcon="map-marker" style={{ marginRight: 5 }} />{props?.hospital}</Text>}
            </Div>
        </div>
    )
}

RequestorDetails.defaultProps = {
    title: 'Requestor details',
    name: ' ',
    pidNumber: '',
    role: '',
    hospital: '',
    profile_pic: ''
}