import { useEffect, useState } from "react";
import { Div } from "qdm-component-library";
import { Button } from "@material-ui/core";
import {
  PatientIdentification,
  PatientNames,
  Address,
  Communication,
  ContactPoint,
} from "./components";
import { useLocation } from "react-router-dom";
import { getImgUrl } from "../../utils";
import styles from "./styles";

const PatientDetails = (props) => {

  // const classes = useStyles(props);
  //const [allMasters, setAllMasters] = useState({});
  const [city, setCity] = useState({});
  const [statusData, setStatusData] = useState({});
  const [patientState, setPatientState] = useState({});
  const [Nas, setNas] = useState([]);

  const [loading, setLoading] = useState(true);
  const [btnName, setBtnName] = useState(false);
  const [personInfo, setPersonInfo] = useState({});
  const [kadOpen, setKadOpen] = useState(false);
  const [isPersonUpdate, setIsPersonUpdate] = useState(false);
  const [containerStyle, setContainerStyle] = useState({
    overflow: "auto",
    padding: "5px 15px",
    display: "block",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 128px)",
  });

  const location = useLocation();
  const { outlinedButton, containedButton } = styles;

  const nextofKin = (id) => {
    props?.setActiveIdFunc(id);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setContainerStyle({
      ...containerStyle,
      overflow: "auto",
      padding: "5px 15px",
      display: "block",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100vh - 128px)",
    });
  };

  useEffect(() => {
    setPatientState({
      ...props?.patientDetails,
    });
  }, [props]);
  useEffect(() => {
    return () => {
      setContainerStyle({
        overflow: "auto",
        padding: "5px 15px",
        display: "block",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 128px)",
      });
    };
  }, [props?.activeId]);
  return (
    <Div id="patient_details_parent_div" style={containerStyle}>
      <PatientIdentification
        parent_id={
          //props.parent_edit_id
          // ? props.parent_edit_id
          //:
          "Register_Patient_patientdetails"
        }
        allMasters={props.allMasters}
        saveto={"patientData"}
        setTo={"setPatientData"}
        patientData={props?.patientDetails}
        setPatientData={props?.setPatientDetails}
        parent_edit_id={props?.parent_edit_id}
        City={city}
        cleared={props?.cleared}
        isModify={props?.isModify}
        isGoBack={props?.isGoBack}
        isViewMode={props?.isViewMode}
      />

      <PatientNames
        parent_id={
          // location?.state?.idedit
          //  ? "Edit_Patient_patientdetails"
          //:
          "Register_Patient_patientdetails"
        }
        saveto={"patientData"}
        setTo={"setPatientData"}
        // prefix={props?.prefix}
        // gender={props?.gender}
        // age={props?.age}
        // maritalStatus={props?.maritalStatus}
        // race={props?.race}
        patientData={props?.patientDetails}
        setPatientData={props?.setPatientDetails}
        statusData={() => statusData}
        parent_edit_id={location?.state?.idedit}
        mrn={
          (props?.patmrn?.patmrn ?? "") ||
          (patientState?.details?.personId ?? "")
        }
        Nas={Nas}
        City={city}
        allMasters={props.allMasters}
        personImg={""}
        cleared={props.cleared}
        isModify={props?.isModify}
        Nationality={props?.Nationality}
        isViewMode={props?.isViewMode}
        isGoBack={props?.isGoBack}
      />

      <Communication
        parent_id={"Register_Patient_patientdetails"}
        allMasters={props.allMasters}
        language={props?.language}
        patientData={props?.patientDetails}
        setPatientData={props?.setPatientDetails}
        cleared={props.cleared}
        isModify={props?.isModify}
        isViewMode={props?.isViewMode}
      />
      <ContactPoint
        parent_id={"Register_Patient_patientdetails"}
        allMasters={props.allMasters}
        saveto={"patientData"}
        setTo={"setPatientData"}
        Sbi={props?.Sbi}
        City={city}
        useOptions={props?.useOptions}
        PersonUpdate={isPersonUpdate}
        patientData={props?.patientDetails}
        setPatientData={props?.setPatientDetails}
        // contact={props?.contact}
        cleared={props.cleared}
        isModify={props?.isModify}
        isViewMode={props?.isViewMode}
        isGoBack={props?.isGoBack}
      />

      <Address
        parent_id={"Register_Patient_patientdetails"}
        saveto={"patientData"}
        setTo={"setPatientData"}
        allMasters={props.allMasters}
        City={props?.city}
        // addressUse={props?.addressUse}
        // addressType={props?.addressType}
        patientData={props?.patientDetails}
        setPatientData={props?.setPatientDetails}
        cleared={props.cleared}
        isModify={props?.isModify}
        isViewMode={props?.isViewMode}
        loadDefaultAddressdata={props?.loadDefaultAddressdata}
      />

      <Div id="patient_details_button_div" className="p-continue-action">
        <Button
          id="patient_details_contiune_to_next_button"
          onClick={() => nextofKin(2)}
          style={containedButton}
          type="submit"
          disabled={
            props.patientDetails?.statusDetails?.status1 === "Inactive" ||
            props.patientDetails?.statusDetails?.status === "Deceased"
          }
        >
          Continue to Next Of Kin
        </Button>
      </Div>
    </Div>
  );
};

export default PatientDetails;
