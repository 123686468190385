// import
import React from "react";

export const searchList = [
  {
    header: "Speciality",
    action: "SPECIALTY_DROPDOWN",
    image: "images/searchSpeciality.svg",
    icons: "1",
    Type: "Select Speciality",
    types: [],
  },
  {
    header: "Location",
    image: "images/searchAdd.svg",
    action: "SPECIALTY_DROPDOWN",
    icons: "2",
    Type: "Select Location",
    types: [],
  },
  {
    header: "Diagnosis",
    icons: "3",
    Type: "Select Diagnosis",
    action: "SPECIALTY_DROPDOWN",
    image: "images/maskGroup38.svg",
    types: [],
  },
  {
    header: "Practitioner",
    icons: "4",
    action: "SPECIALTY_DROPDOWN",
    Type: "Select Practitioner",
    image: "images/maskGroup43.svg",
    types: [],
  },
];

export const orderListNav = [
  "Pre Operative Order Set",
  "Cardiothoracic",
  "Medication",
  "Doctor",
  "Nurse",
  "Failure",
  "Breakage",
];
