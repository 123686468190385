import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import {
  CustomButton,
  FieldContainer,
  FieldLabel,
  StyledPaper,
  Autocomplete,
  InputBase,
} from "../components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Collapse, useTheme } from "@material-ui/core";
import AdditiveItem from "./component/additiveItem";
import { useSelector } from "react-redux";
import { AlertProps } from "../../../../../utils";
import withAllContexts from "../../../../../hoc/withAllContexts";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "12px 16px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 12,
    fontWeight: 500,
  },
  action: {
    display: "flex",
    gap: 12,
    alignItems: "center",
  },
  popper: {
    zIndex: theme.zIndex.modal,
    // width: "100%",
  },
  popperRoot: {
    padding: "24px 16px",
    width: 650,
    boxShadow: "#0000003D 0px 10px 75px",
    borderRadius: 8,
    background: "white",
    // width: "100%",
  },
  dropdown: {
    background: `${theme.palette.background.table} !important`,
    "&:input": {
      color: theme.palette.text.primary,
    },
  },
  dosageField: {
    display: "flex",
    gap: 12,
    alignItems: "center",
  },
  actionButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 8,
  },
  additiveItemContainer: {
    marginBottom: 12,
  },
}));

const initialState = {
  addrugcode: null,
  addose: "",
  addoseuom: null,
};

function Additive(props) {
  //<----------------------- Props ------------------------------->
  const {
    onInputChange,
    additive,
    // saveAdditive,
    handleSaveAdditiveItem,
    handleUpdateAdditiveItem,
    handleDeleteAdditiveItem,
    additiveItems = [],
  } = props;
  // <------------------Hooks--------------->
  const classes = useStyles(props);
  const theme = useTheme();
  const addButtonRef = useRef(null);

  // <---------------Store States----------->
  const options = useSelector((state) => state?.MedicationMastersSlice);

  // <-------------Component States--------->
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [state, setState] = React.useState({
    ...initialState,
  });
  const [editItemIndex, setEditItemIndex] = React.useState(-1);

  const isEdit = editItemIndex > -1;

  // <-----------------Handlers------------->
  const handleAddNew = (event) => {
    const { currentTarget } = event;
    setPopperAnchorEl(currentTarget);
  };

  const handlePopperClose = () => {
    setPopperAnchorEl(null);
    setState({ ...initialState });
    setEditItemIndex(-1);
  };

  const validate = () => {
    const errors = [];

    if (!state?.addose) {
      errors.push("Dosage");
    }

    if (!state?.addoseuom) {
      errors.push("Dosage UOM");
    }

    if (errors?.length > 0) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: `Please fill the mandatory fields: ${errors.join(", ")}`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }

    return errors?.length > 0;
  };

  const handleSave = (shouldClose) => {
    // Validate
    if (validate()) {
      return;
    }

    const data = { ...state };

    setState({ ...initialState });

    // Do Save and add another logic
    if (isEdit) {
      handleUpdateAdditiveItem(data, editItemIndex);
    } else {
      handleSaveAdditiveItem(data);
    }

    setEditItemIndex(-1);

    // Close the popper
    shouldClose && handlePopperClose();
  };

  //onchage
  const onChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditClick = (index) => {
    setEditItemIndex(index);

    const item = additiveItems[index];

    setState({
      addrugcode: item.addrugcode,
      addose: item.addose,
      addoseuom: item.addoseuom,
    });

    addButtonRef.current.click();
  };

  // <-----------------Effects-------------->

  // <-----------------Others--------------->

  // <-----------------Render--------------->
  return (
    <StyledPaper
      className={classes.root}
      style={{
        gap: isCollapsed ? 12 : 0,
      }}
    >
      <div className={classes.header}>
        <Typography className={classes.title}>ADDITIVE DETAILS</Typography>
        <div className={classes.action}>
          <CustomButton
            color={theme.palette.primary.main}
            onClick={handleAddNew}
            ref={addButtonRef}
          >
            + Add New
          </CustomButton>
          {additiveItems?.length > 0 && (
            <IconButton
              size="small"
              onClick={() => setIsCollapsed((prev) => !prev)}
            >
              {isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </div>
      </div>
      <Collapse in={isCollapsed}>
        {additiveItems?.map((item, index) => (
          <div className={classes.additiveItemContainer}>
            <AdditiveItem
              index={index}
              data={item}
              onEditClick={() => handleEditClick(index)}
              onDeleteClick={() => handleDeleteAdditiveItem(index)}
            />
          </div>
        ))}
      </Collapse>

      <Popper
        className={classes.popper}
        open={Boolean(popperAnchorEl)}
        anchorEl={popperAnchorEl}
        placement="bottom-end"
        disablePortal={true}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
            // boundariesElement: "scrollParent",
          },
        }}
      >
        <div className={classes.popperRoot}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <FieldContainer>
                <FieldLabel variant={"body1"}>
                  Drug Code & Description
                </FieldLabel>
                <Autocomplete
                  className={classes.dropdown}
                  PaperComponent={({ children }) => (
                    <Paper className={classes.dropdown}>{children}</Paper>
                  )}
                  id={"drugCode"}
                  getOptionLabel={(option) => option?.["label"] ?? ""}
                  options={options?.drugMaster_masters?.data ?? []}
                  size={"small"}
                  placeholder=""
                  value={state?.addrugcode ?? {}}
                  onChange={(event, newValue) =>
                    onChange("addrugcode", newValue)
                  }
                  inputValue={additive}
                  onInputChange={(event, newInputValue) =>
                    onInputChange(event, newInputValue)
                  }
                />
              </FieldContainer>
            </Grid>
            <Grid item xs={4}>
              <FieldContainer>
                <FieldLabel variant={"body1"} required>
                  Dosage
                </FieldLabel>
                <div className={classes.dosageField}>
                  <InputBase
                    id={"dosage"}
                    label={""}
                    placeholder={""}
                    type={"text"}
                    variant={"outlined"}
                    size={"small"}
                    fullWidth={true}
                    value={state?.addose}
                    onChange={(event) => {
                      onChange("addose", Number(event.target.value));
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  ></InputBase>
                  <Autocomplete
                    className={classes.dropdown}
                    PaperComponent={({ children }) => (
                      <Paper className={classes.dropdown}>{children}</Paper>
                    )}
                    id={"drugCode"}
                    // onLoad={() =>
                    //   changeState("drugCode", this.state?.drugCode ?? "")
                    // }
                    getOptionLabel={(option) => option?.["label"] ?? ""}
                    options={options?.strengthUom_masters?.data ?? []}
                    size={"small"}
                    placeholder=""
                    value={state?.addoseuom ?? {}}
                    onChange={(event, newValue) => {
                      onChange("addoseuom", newValue);
                    }}
                    // inputValue={""}
                    // onInputChange={(event, newInputValue) => {}}
                  />
                </div>
              </FieldContainer>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.actionButtonContainer}>
                <CustomButton variant="outlined" onClick={handlePopperClose}>
                  Cancel
                </CustomButton>
                <CustomButton
                  variant="contained"
                  onClick={() => handleSave(false)}
                >
                  {isEdit ? "Update & Add another" : "Save & Add another"}
                </CustomButton>
                <CustomButton
                  variant="contained"
                  onClick={() => handleSave(true)}
                >
                  {isEdit ? "Update" : "Save"}
                </CustomButton>
              </div>
            </Grid>
          </Grid>
        </div>
      </Popper>
    </StyledPaper>
  );
}

export default withAllContexts(Additive);

Additive.defaultProps = {};

Additive.propTypes = {};
