import * as React from "react";
import PropTypes from "prop-types";

const DocumentBlack = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.541" height="14.426" viewBox="0 0 11.541 14.426">
    <path id="icons8-document" d="M9.623,4A1.631,1.631,0,0,0,8,5.623V16.8a1.631,1.631,0,0,0,1.623,1.623h8.3A1.631,1.631,0,0,0,19.541,16.8V9.23a.541.541,0,0,0-.159-.382l-.006-.006L14.694,4.158A.541.541,0,0,0,14.311,4Zm0,1.082h4.148V8.148a1.631,1.631,0,0,0,1.623,1.623h3.066V16.8a.533.533,0,0,1-.541.541h-8.3a.533.533,0,0,1-.541-.541V5.623A.533.533,0,0,1,9.623,5.082Zm5.23.765,2.842,2.842h-2.3a.533.533,0,0,1-.541-.541Zm-3.426,5.727a.541.541,0,1,0,0,1.082h4.689a.541.541,0,1,0,0-1.082Zm0,2.525a.541.541,0,1,0,0,1.082h3.246a.541.541,0,1,0,0-1.082Z" transform="translate(-8 -4)"
    fill={props.color} />
  </svg>
);

export default DocumentBlack;

DocumentBlack.defaultProps = {
  color: "black",
};

DocumentBlack.propTypes = {
  color: PropTypes.string,
};