import React, {useState , useEffect} from "react";
import actions from "../../redux/actions";
import { connect } from "react-redux";
//import { withRouter } from "react-router-dom";
import  withAllContexts  from "../../hoc/withAllContexts";
import { Div, Card, Divider, H6 } from "qdm-component-library";
import { Header, Body } from "./components";
import { getUserInfo } from "../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Grid, Paper, Typography } from "@material-ui/core";
import { BloodSamplesIcon, HeartPluseIcon, TemperatureIcon, StartTime} from "../svg";
import { UIColor } from "../../themes/theme";

const TreatmentProcedure = (props) =>  {

  const dispatch = useDispatch()

  const [open , setOpen] = useState(false)
  const [loading , setloading] = useState(true)
  const [statusCheck , setstatusCheck] = useState("All")

  const searchPractioner = useSelector(state => state?.personApiSlice?.searchPractioner)
  const readPerson = useSelector(state => state?.personApiSlice?.readPerson)
  const encounterData = useSelector(state => state?.chiefCompilenceMastersSlice?.encounter_Details)
  
 const changeState = (key, value) => {
  setOpen(value)
  };

  useEffect(() => {
    if (props.isCC) {
      getAllMasters();
    } else {
      setloading(false)
    }
  },[])

  

  const getAllMasters = async () => {
    const info = getUserInfo();
    // await dispatch(actions.SEARCH_PRACTIONER({ email: info.email }));
    // await dispatch(actions.READ_PERSON({ phone: info.mobile }));
    // await dispatch(actions.RELATIONSHIP_MASTERS());
    // await dispatch(actions.SYMPTOMS_MASTERS());
    // await dispatch(actions.SEVERITY_MASTERS());
    // await dispatch(actions.BODYSITE_MASTERS());
    // await dispatch(actions.VERIFICATIONS_MASTERS());
    // await dispatch(actions.CLINIC_STATUS_MASTERS());
    // await dispatch(actions.CC_MASTERS());
    // const { patientId, pId } = props?.propsvalue;

    // let data = await dispatch(actions.PATIENTID_BASED_CC({ patientId: pId }));
    // let cheifComplaints = props.cheifComplaints;
    // cheifComplaints.setCheifComplaints({
    //   ...cheifComplaints,
    //   list: JSON.parse(JSON.stringify(data.payload.data)),
    // });
    // //Diagnosis integration
    // // await this.props.DIAGNOSIS_MASTERS();
    // await dispatch(actions.USE_MASTERS());
    // await dispatch(actions.VERIFICATIONS_MASTERS_DIAGNOSIS());
    // await dispatch(actions.DIAGNOSIS_RANK_MASTERS());
    // await dispatch(actions.CLINICAL_MASTERS());
    // await dispatch(actions.MANIFESTATION_MASTERS());
    // await dispatch(actions.GRADE_TYPE_MASTERS());
    // await dispatch(actions.ASSESMENT_MASTERS());
    // await dispatch(actions.GRADE_CODE_MASTERS());

    // let Diagnosisdata = await dispatch(actions.DIAGNOSIS_PATIENT_READ({
    //   patient_id: pId,
    // }));
    // let diagnosis = props.Diagnosis;
    // diagnosis.setDiagnosis({
    //   ...diagnosis,
    //   list: JSON.parse(JSON.stringify(Diagnosisdata.payload.data)),
    // });

    setloading(false)
  }

  const styles = {
    card: {
      boxShadow: "none",
    },
    divider: {
      margin: 0,
      height: "0.1px",
    },
  };

  const editList = (data, index) => {
    // let cheifComplaints = this.props.cheifComplaints
    // cheifComplaints.editIndex = index;
    // cheifComplaints.setCheifComplaints({
    //   ...cheifComplaints
    // })

    props.editWidget(data, props.componentId, index);
  };

  const handleStatusActive = (e) => {
    // this.setState({
    //   statusCheck: "Active",
    // });
    setstatusCheck("Active")
  };

  const handleStatusInActive = (e) => {
    setstatusCheck("InActive")
  };

  const handleStatusResolved = (e) => {
    // this.setState({
    //   statusCheck: "Resolved",
    // });
    setstatusCheck("Resolved")
  };

  const handleStatusAll = (e) => {
    setstatusCheck("All")
  };

    const {
      title = "",
      data = [],
      icon = "",
      iconBackground = "",
      parent_id,
    } = props;

    return (
      <div id={`${parent_id}_parent_div`}>
        <Card id={`${parent_id}_parent_card`} style={styles.card}>
          <Header
            parent_id={"allergies"}
            icon={icon}
            iconBackground={iconBackground}
            title={title}
            open={open}
            toggle={() => changeState("open", !open)}
            handleStatusActive={handleStatusActive}
            handleStatusInActive={handleStatusInActive}
            handleStatusResolved={handleStatusResolved}
            handleStatusAll={handleStatusAll}
          />
          <Divider id={`${parent_id}_bottom_divider`} style={styles.divider} />
          {loading && (
            <Div
              id={`${parent_id}_CircularProgress_div`}
              style={{ display: "flex", height: "100px" }}
            >
              <CircularProgress
                id={`${parent_id}_CircularProgress`}
                style={{ margin: "auto" }}
                size={23}
              />
            </Div>
          )}
          {!loading && (
            <>
              {data?.[0]?.component?.[0]?.intradialysis.length > 0 ? (
                <>
                  <Div
                    id={`${parent_id}_filter_parent_div`}
                    style={{ padding: 10 }}
                  >
                    <Grid
                      container
                      style={{
                        marginBottom: "10px",
                        color: "#01205C",
                        backgroundColor: "#F1F6FC",
                        padding: "5px 0",
                        margin: "10px 0",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{ marginLeft: "10px" }}
                      >
                        Predialysis
                      </Typography>
                    </Grid>
                    <Grid container style={{ margin: "10px 0 0 0" }}>
                      <StartTime width={"30"} height={"20"} />
                      <Typography variant="body2" style={{ width: "50px" }}>
                        {
                          data?.[0]?.component?.[0]?.predialysis?.[0]?.TimeStart
                            ?.obsvalue
                        }
                      </Typography>

                      <BloodSamplesIcon width={"30"} height={"20"} />
                      <Typography variant="body2" style={{ width: "65px" }}>
                        {
                          data?.[0]?.component?.[0]?.predialysis?.[0]?.BP
                            ?.obsvalue
                        }
                      </Typography>

                      <HeartPluseIcon width={"30"} height={"20"} />
                      <Typography variant="body2" style={{ width: "30px" }}>
                        {
                          data?.[0]?.component?.[0]?.predialysis?.[0]?.PULSE
                            ?.obsvalue
                        }
                      </Typography>

                      <TemperatureIcon width={"30"} height={"20"} />
                      <Typography variant="body2" style={{ width: "50px" }}>
                        {
                          data?.[0]?.component?.[0]?.predialysis?.[0]?.TEMP
                            ?.obsvalue
                        }
                      </Typography>
                    </Grid>
                  </Div>
                </>
              ) : (
                <H6
                  id={`${parent_id}_No_data_h6`}
                  style={{ textAlign: "center", fontSize: "15px" }}
                >
                  No data Found
                </H6>
              )}

              {open && (
                <Div
                  id={`${parent_id}_filter_parent_div`}
                  style={{ padding: 10 }}
                >
                  <Grid>
                    {data?.[0]?.component?.[0]?.intradialysis.length > 0 ? (
                      <>
                        {data?.[0]?.component?.[0]?.intradialysis?.map(
                          (v, i) => {
                            return (
                              <>
                                <Grid
                                  container
                                  style={{
                                    marginBottom: "10px",
                                    color: "#01205C",
                                    backgroundColor: "#F1F6FC",
                                    padding: "5px 0",
                                    margin: "10px 0 20px 0",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    {v?.Type?.obsvalue}
                                  </Typography>
                                </Grid>
                                <Grid container style={{ margin: "20px 0" }}>
                                  <StartTime width={"30"} height={"20"} />
                                  <Typography
                                    variant="body2"
                                    style={{ width: "50px" }}
                                  >
                                    {v?.Time?.obsvalue}
                                  </Typography>

                                  <BloodSamplesIcon
                                    width={"30"}
                                    height={"20"}
                                  />
                                  <Typography
                                    variant="body2"
                                    style={{ width: "65px" }}
                                  >
                                    {v?.BP?.obsvalue}
                                  </Typography>

                                  <HeartPluseIcon width={"30"} height={"20"} />
                                  <Typography
                                    variant="body2"
                                    style={{ width: "30px" }}
                                  >
                                    {v?.PULSE?.obsvalue}
                                  </Typography>

                                  <TemperatureIcon width={"30"} height={"20"} />
                                  <Typography
                                    variant="body2"
                                    style={{ width: "50px" }}
                                  >
                                    {v?.TEMP?.obsvalue}
                                  </Typography>
                                </Grid>
                                <Grid style={{backgroundColor: "#F1F6FC",padding:"10px"}}>
                                <Grid container>
                                  <Typography
                                    variant="body2"
                                    style={{
                                      width: "100px",
                                      color: "#001C3C",
                                      padding: "0px 5px",
                                    }}
                                  >
                                    Complication
                                  </Typography>
                                </Grid>
                                {v?.COMPLICATION?.map((j, i) => {
                                  return (
                                    <Paper
                                      elevation={0}
                                      variant="outlined"
                                      style={{
                                        margin : "5px ",
                                        padding : "10px ",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <Grid container>
                                        <Grid
                                          item
                                          container
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Grid item>
                                            <Typography
                                              variant="body2"
                                              style={{
                                                marginBottom: "10px",
                                                color: "#01205C",
                                              }}
                                            >
                                              Complication :
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            style={{ display: "flex" }}
                                          >
                                            <StartTime
                                              width={"30"}
                                              height={"20"}
                                            />
                                            <Typography
                                              variant="body2"
                                              style={{ color: "#6A7888",marginLeft:"3px" }}
                                            >
                                              {j?.time?.obsvalue}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                        <Grid item container>
                                          <Typography
                                            variant="body2"
                                            style={{
                                              color: "#6A7888",
                                              width: "98%",
                                              overflowWrap: "break-word",
                                              margin: "5px 0",
                                            }}
                                          >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            {j?.complication?.obsvalue}
                                          </Typography>
                                        </Grid>
                                        <Grid item container>
                                          <Typography
                                            variant="body2"
                                            style={{
                                              marginBottom: "10px",
                                              color: "#01205C",
                                            }}
                                          >
                                            Action :
                                          </Typography>
                                        </Grid>
                                        <Grid container>
                                          <Typography
                                            variant="body2"
                                            style={{
                                              color: "#6A7888",
                                              width: "98%",
                                              overflowWrap: "break-word",
                                              margin: "5px 0",
                                            }}
                                          >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            {j?.action?.obsvalue}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  );
                                })}
                                </Grid>
                              </>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <H6
                        id={`${parent_id}_No_data_h6`}
                        style={{ textAlign: "center", fontSize: "15px" }}
                      >
                        No data Found
                      </H6>
                    )}

                    {data?.[0]?.component?.[0]?.postdialysis.length > 0 ? (
                      <>
                        <Grid
                          container
                          style={{
                            marginBottom: "10px",
                            color: "#01205C",
                            backgroundColor: "#F1F6FC",
                            padding: "5px 0",
                            margin: "20px 0",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{ marginLeft: "10px" }}
                          >
                            Postdialysis
                          </Typography>
                        </Grid>
                        <Grid container style={{ margin: "10px 0" }}>
                          <StartTime width={"30"} height={"20"} />
                          <Typography variant="body2" style={{ width: "50px" }}>
                            {
                              data?.[0]?.component?.[0]?.postdialysis?.[0]
                                ?.EndTime?.obsvalue
                            }
                          </Typography>

                          <BloodSamplesIcon width={"30"} height={"20"} />
                          <Typography variant="body2" style={{ width: "65px" }}>
                            {
                              data?.[0]?.component?.[0]?.postdialysis?.[0]
                                ?.BPSit?.obsvalue
                            }
                          </Typography>

                          <HeartPluseIcon width={"30"} height={"20"} />
                          <Typography variant="body2" style={{ width: "30px" }}>
                            {
                              data?.[0]?.component?.[0]?.postdialysis?.[0]
                                ?.PULSESIT?.obsvalue
                            }
                          </Typography>

                          <TemperatureIcon width={"30"} height={"20"} />
                          <Typography variant="body2" style={{ width: "50px" }}>
                            {
                              data?.[0]?.component?.[0]?.postdialysis?.[0]?.TEMP
                                ?.obsvalue
                            }
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <H6
                        id={`${parent_id}_No_data_h6`}
                        style={{ textAlign: "center", fontSize: "15px" }}
                      >
                        No data Found
                      </H6>
                    )}
                  </Grid>
                </Div>
              )}
            </>
          )}
        </Card>
      </div>
    );
  }


// const mapStateToProps = (state) => ({
 
// });

export default withAllContexts(TreatmentProcedure);
