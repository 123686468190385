import React, { useState } from "react";
import { actions } from "../../../../../../redux/actions/index";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import { Text, Div, Divider, Button } from "qdm-component-library";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Close from "../../../../../../assets/img/svg/close.svg";
import { convertTime } from "../../../../../../utils";
import Edit from "../../../../../../assets/img/svg/edit.svg";
import { UIColor } from "../../../../../../themes/theme";
import { useStyles } from "./style";
function CollapseAppoinmentCard(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    expanded: props?.open ?? false,
  });

  const handleExpandClick = () => {
    setState({
      ...state,
      expanded: !state.expanded,
    });
  };

  const { parent_id } = props;
  const {
    index = "",
    date = "",
    time = "",
    slot = "",
    showEdit = false,
    showDelete = false,
    isError = false,
    body = "",
    editFunction = null,
    deleteFunction = null,
  } = props;

  return (
    <Card
      id={`${parent_id}_collapsecard_parent_card`}
      style={{
        marginBottom: 10,
        border: isError ? "0.5px solid #FF4D4A" : "0.5px solid #E0E0E0",
        boxShadow: "none",
      }}
    >
      <CardActions
        id={`${parent_id}_collapsecard_parent_cardactions`}
        onClick={handleExpandClick}
        disableSpacing
        style={{
          cursor: "pointer",
          padding: "10px 0px",
          justifyContent: "space-between",
        }}
      >
        {index && <Div className={classes.indexStyle}>{index}</Div>}
        {date && (
          <Text
            id={`${parent_id}_collapsecard_date_text`}
            name={convertTime(date, "unix", "DD MMM YY ( ddd )")}
            style={{ fontSize: "14px", fontWeight: "600", color: "#101010" }}
            className={"pc_regular"}
          />
        )}

        <Divider
          id={`${parent_id}_collapsecard_date_divider`}
          orientation="vertical"
          inLineStyles={{ height: 17 }}
        />

        {time && (
          <Text
            id={`${parent_id}_collapsecard_time_text`}
            name={time}
            style={{ fontSize: "14px", fontWeight: "600", color: "#101010" }}
            className={"pc_regular"}
          />
        )}

        <Divider
          id={`${parent_id}_collapsecard_time_divider`}
          orientation="vertical"
          inLineStyles={{ height: 17 }}
        />

        {slot && (
          <Text
            id={`${parent_id}_collapsecard_slot_text`}
            name={slot}
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: isError ? UIColor.error.color : UIColor.success.color,
            }}
            className={"pc_regular"}
          />
        )}

        <Div
          id={`${parent_id}_collapsecard_shiwedit_div`}
          style={{ marginRight: "3%", display: "flex", alignItems: "center" }}
        >
          {
          //showEdit 
          false
          && (
            <Button
              id={`${parent_id}_collapsecard_edit_button`}
              inLineStyles={{
                backgroundColor: "transparent",
                border: 0,
                padding: 0,
                boxShadow: "none !important",
              }}
              // onClick={handleExpandClick}
            >
              <img src={Edit} alt="Edit" />
              {/* <Icons
                  fontIcon={"edit"}
                  ariaHidden={"true"}
                  size={"medium"}
                  
                ></Icons> */}
              &nbsp; &nbsp; &nbsp;
            </Button>
          )}
          {showDelete && (
            <Button
              id={`${parent_id}_collapsecard_Close_button`}
              inLineStyles={{
                backgroundColor: "transparent",
                border: 0,
                padding: 0,
                boxShadow: "none !important",
              }}
              onClick={(e) => {
                deleteFunction && deleteFunction(props?.date);
                e.stopPropagation();
              }}
            >
              <img src={Close} alt="Close" />
            </Button>
          )}
        </Div>
      </CardActions>
      <Collapse
        id={`${parent_id}_collapsecard_parent_collapse`}
        className={classes.body}
        in={state.expanded}
        timeout="auto"
        unmountOnExit
      >
        {isError && (
          <Div
            id={`${parent_id}_collapsecard_error_div`}
            className={classes.flexRow}
          >
            <InfoOutlinedIcon
              id={`${parent_id}_collapsecard_infooutlinedicon`}
              className={classes.infoIcon}
            />
            <Div
              id={`${parent_id}_collapsecard_Slot_Unavailable_div`}
              className="flexColumn"
            >
              <Text
                id={`${parent_id}_collapsecard_Slot_Unavailable_text`}
                inLineStyles={{
                  color: "#EA1601",
                  fontSize: "12px",
                  fontWeight: "600",
                  marginBottom: "5px",
                }}
                className={"pc_regular"}
              >
                {props.slot === "Slot Unavailable"
                  ? `${props.time} slot unavailable.`
                  : `There are no slots available on ${convertTime(
                      date,
                      "unix",
                      "DD MMM YY"
                    )}.`}
              </Text>
              <Text
                id={`${parent_id}_collapsecard_appointment_text`}
                inLineStyles={{
                  color: "#EA1601",
                  fontSize: "11px",
                  fontWeight: "600",
                }}
                className={"pc_regular"}
              >
                Please choose another slot for this particular appointment.
              </Text>
            </Div>
          </Div>
        )}
        {body && body}
      </Collapse>
    </Card>
  );
}

export default CollapseAppoinmentCard;
