import React from "react";
import {
  Div,
  Select,
  Col,
  Row,
  Paper,
  H6,
  TextInput,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../../hoc/withAllContexts";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
function DeviceDetails(props) {
  console.log("DeviceDetails", props);
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    selectBorderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    inputBorderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
      background: "#F0F0F0",
    },
    errorborderStyle: {
      borderRadius: 8,
      borderColor: "red",
    },
  };

  const { device, helthcare, accompained } = props.visitData;

  const dispatch = useDispatch();

  const [serviceName, setServiceName] = React.useState([]);

  const setDetails = (name, value, saveto, limit) => {
    // console.log(helthcare, "setDetails123")

    if (limit) {
      if (value?.length > limit) {
        return false;
      }
    }
    //RESTRICT space, decimal and special characters
    if (name === "id") {
      const expression = /^[a-zA-Z0-9]*$/;
      const regex = new RegExp(expression);
      if (!value.match(regex)) {
        return false;
      }
    }

    let patientData = props.visitData;
    patientData[saveto][name] = value;
    if (patientData[saveto].error) {
      patientData[saveto].error[name] = false;
    }
    patientData.setVisitDetails({
      ...patientData,
    });
  };
  const FlterOptions = (type) => {
    const { allMasters } = props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      if (type === "ALTERNATEIDTYPE") {
        var datatemp = {
          value: data[index]?.coding?.display,
          id: data[index]?.coding?._id,
        };
      } else {
        var datatemp = {
          value: data[index]?.coding?.display,
          id: data[index]?.coding?._id,
        };
      }
      lista.push(datatemp);
    }
    return lista;
  };
  const { labelStyle, selectBorderStyle, inputBorderStyle, errorborderStyle } =
    styles;
  const { walkin, isEdit } = props;

  React.useEffect(() => {
    const { appointmentData, walkin } = props;
    if (walkin) {
      if (appointmentData?.Practioner) {
        let patientData = props.visitData;
        patientData.device["category"] = { value: "" };
        patientData.setVisitDetails({
          ...patientData,
        });
      }
    }

    if (walkin) {
      if (appointmentData?.Practioner) {
        let patientData = props.visitData;
        // patientData.device['type'] = { value: appointmentData?.Practioner?.[0]?.roles?.[0]?.healthcareservices?.[0]?.type?.[0]?.text ?? '' };
        // patientData.device["type"] = { value: "Scanner" };
        patientData.device["type"] = { value: "" };
        patientData.setVisitDetails({
          ...patientData,
        });
      }
    }

    if (walkin) {
      if (appointmentData?.Practioner) {
        let patientData = props.visitData;
        // patientData.device["name"] = { value: "CT" };
        patientData.device["name"] = { value: "" };
        patientData.setVisitDetails({
          ...patientData,
        });
      }
    }
    if (!props?.isRevise) {
      getData();
    } else {
      getEditData();
    }
  }, [props.appointmentData]);

  const getEditData = async () => {
    const data = await dispatch(
      actions.GET_SERVICE_ID({
        servicetype: props?.appointmentData?.[0]?.appointmentTypeID?.[0] ?? "",
      })
    );

    let name = [];
    data?.payload?.data.map((li) => {
      let obj = {
        label: li?._id,
        value: `${li?.longdesc}-${li?.chargecode}`,
      };
      name.push(obj);
    });

    setServiceName(name);
    let patientData = props.visitData;
    patientData.helthcare["name"] = {
      value: props?.encount?.servicedtls?.[0]?.serviceid?.shortdesc,
      label: props?.encount?.servicedtls?.[0]?.serviceid?._id,
    };
    patientData.setVisitDetails({
      ...patientData,
    });
  };

  const getData = async () => {
    const data = await dispatch(
      actions.GET_SERVICE_ID({
        servicetype: props?.appointmentData?.[0]?.appointmentTypeID?.[0] ?? "",
      })
    );

    let name = [];
    data?.payload?.data.map((li) => {
      let obj = {
        label: li?._id,
        value: `${li?.longdesc}-${li?.chargecode}`,
      };
      name.push(obj);
    });

    let patientData = props.visitData;
    patientData.helthcare["name"] = {
      value: name?.[0]?.value,
      label: name?.[0]?.label,
    };
    setServiceName(name);
    patientData.setVisitDetails({
      ...patientData,
    });
  };

  const { parent_id } = props;
  //console.log("helthcare?.category", helthcare?.category)
  return (
    <Div id={`${parent_id}_device_details_parent_div`} className="pv-root">
      <Paper
        id={`${parent_id}_device_details_healthcare_parent_div`}
        className="pl-root vr-device-paper"
      >
        <Div id={`${parent_id}_device_details_healthcare_title_div`}>
          <H6
            id={`${parent_id}_device_details_healthcare_label_h6`}
            className="en-title space-padding-bottom"
          >
            HEALTHCARE SERVICE
          </H6>
        </Div>
        <Row
          id={`${parent_id}_device_details_healthcare_parent_div`}
          className="en-actions-root"
        >
          <Col
            id={`${parent_id}_device_details_healthcare_parent_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Row
              id={`${parent_id}_device_details_healthcare_sub_div`}
              className="en-actions-root"
            >
              <Col
                id={`${parent_id}_device_details_healthcare_category_col`}
                md={3}
                lg={3}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_device_details_healthcare_category_select`}
                  options={FlterOptions("SERVICECATEGORY")}
                  // helperText={
                  // 	helthcare?.error && helthcare?.error["category"]
                  // 		? "Is required"
                  // 		: ""
                  // }
                  error={
                    helthcare?.error && helthcare?.error["category"]
                      ? true
                      : false
                  }
                  value={helthcare?.category?.value ?? null}
                  onChangeValue={(e) => setDetails("category", e, "helthcare")}
                  // isRequired={true}
                  label="Category"
                  placeholder="select..."
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={
                    helthcare?.error && helthcare?.error["category"]
                      ? errorborderStyle
                      : selectBorderStyle
                  }
                  hoverColor="#0071F2"
                  disabled={true}
                  // disabled={
                  // 	props.view
                  // 		? true
                  // 		: walkin
                  // 			? false
                  // 			: props.type === "cancel" || props.type === "modify"
                  // 				? true
                  // 				: false
                  // }
                />
              </Col>
              <Col
                id={`${parent_id}_device_details_healthcare_type_col`}
                md={3}
                lg={3}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_device_details_healthcare_type_select`}
                  options={FlterOptions("SERVICETYPE")}
                  // helperText={
                  // 	helthcare?.error && helthcare?.error["type"]
                  // 		? "Is required"
                  // 		: ""
                  // }
                  error={
                    helthcare?.error && helthcare?.error["type"] ? true : false
                  }
                  value={helthcare?.type?.value ?? null}
                  onChangeValue={(e) => setDetails("type", e, "helthcare")}
                  // isRequired={true}
                  label="Type"
                  placeholder="select..."
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={
                    helthcare?.error && helthcare?.error["type"]
                      ? errorborderStyle
                      : selectBorderStyle
                  }
                  hoverColor="#0071F2"
                  disabled={true}
                  // disabled={
                  // 	props.view
                  // 		? true
                  // 		: walkin
                  // 			? false
                  // 			: props.type === "cancel" || props.type === "modify"
                  // 				? true
                  // 				: false
                  // }
                />
              </Col>
              <Col
                id={`${parent_id}_device_details_healthcare_name_col`}
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                {/* <TextInput
									id={`${parent_id}_device_details_healthcare_name_select`}
									value={helthcare?.name?.value ?? null}
									onChange={(e) =>
										setDetails("name", e.target.value, "helthcare")
									}
									label="Name"
									type="number"
									placeholder="Name"
									labelStyle={labelStyle}
									inLineStyles={helthcare?.error && helthcare?.error["name"] ? errorborderStyle : selectBorderStyle}
									style={{ ...inputBorderStyle, background: "transparent" }}
									hoverColor="#0071F2"
									disabled={
										props.view
											? true
											: walkin
												? false
												: props.type === "cancel" || props.type === "modify"
													? true
													: false
									}
								/> */}

                <Select
                  isRequired={true}
                  id={`${parent_id}_device_details_healthcare_name_select`}
                  // helperText={
                  // 	helthcare?.error && helthcare?.error["name"]
                  // 		? "Is required"
                  // 		: ""
                  // }
                  error={
                    helthcare?.error && helthcare?.error["name"] ? true : false
                  }
                  options={serviceName}
                  value={helthcare?.name?.value ?? null}
                  onChangeValue={(e) => setDetails("name", e, "helthcare")}
                  // isRequired={true}
                  label="Name"
                  placeholder="select..."
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={
                    helthcare?.error && helthcare?.error["name"]
                      ? errorborderStyle
                      : selectBorderStyle
                  }
                  hoverColor="#0071F2"
                  disabled={
                    isEdit
                      ? true
                      : walkin
                      ? false
                      : props.type === "cancel" || props.type === "modify"
                      ? true
                      : false
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Paper>
      {/* <Paper
				id={`${parent_id}_device_details_accompanied_parent_paper`}
				className="pl-root vr-device-paper"
			>
				<Div id={`${parent_id}_device_details_accompanied_title_div`}>
					<H6
						id={`${parent_id}_device_details_accompanied_label_h6`}
						className="en-title space-padding-bottom"
					>
						ACCOMPANIED PERSON
					</H6>
				</Div>
				<Row className="en-actions-root">
					<Col
						id={`${parent_id}_device_details_accompanied_parent_col`}
						md={12}
						lg={12}
						sm={12}
						xs={12}
						className="no-padding-left"
					>
						<Row
							id={`${parent_id}_device_details_accompanied_sub_row`}
							className="en-actions-root"
						>
							<Col
								id={`${parent_id}_device_details_accompanied_idtype_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_device_details_accompanied_idtype_select`}
									options={FlterOptions("ALTERNATEIDTYPE")}
									value={accompained?.id_type?.value ?? null}
									onChangeValue={(e) => setDetails("id_type", e, "accompained")}
									label="Id Type"
									placeholder="select..."
									showArrow
									labelStyle={labelStyle}
									inLineStyles={selectBorderStyle}
									hoverColor="#0071F2"
									disabled={
										props.view
											? true
											: walkin
												? false
												: props.type === "cancel"
													? true
													: false
									}
								/>
							</Col>
							<Col
								id={`${parent_id}_device_details_accompanied_id_col`}
								md={3}
								lg={3}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<TextInput
									id={`${parent_id}_device_details_accompanied_id_textinput`}
									value={accompained?.id ?? null}
									onChange={(e) =>
										setDetails("id", e.target.value, "accompained", 30)
									}
									label="ID"
									placeholder="ID"
									labelStyle={labelStyle}
									style={{ ...inputBorderStyle, background: "transparent" }}
									hoverColor="#0071F2"
									disabled={
										props.view
											? true
											: walkin
												? false
												: props.type === "cancel"
													? true
													: false
									}
								/>
							</Col>
							<Col
								id={`${parent_id}_device_details_accompanied_name_col`}
								md={4}
								lg={4}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<TextInput
									id={`${parent_id}_device_details_accompanied_name_textinput`}
									value={accompained?.name ?? null}
									onChange={(e) =>
										setDetails("name", e.target.value, "accompained", 40)
									}
									label="Name"
									placeholder="Name"
									labelStyle={labelStyle}
									style={{ ...inputBorderStyle, background: "transparent" }}
									hoverColor="#0071F2"
									disabled={
										props.view
											? true
											: walkin
												? false
												: props.type === "cancel"
													? true
													: false
									}
								/>
								
							</Col>
							<Col
								id={`${parent_id}_device_details_accompanied_relationship_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_device_details_accompanied_relationship_select`}
									options={FlterOptions("RELATIONSHIP")}
									value={accompained?.relationship?.value ?? null}
									onChangeValue={(e) =>
										setDetails("relationship", e, "accompained")
									}
									label="Relationship"
									placeholder="select..."
									showArrow
									labelStyle={labelStyle}
									inLineStyles={selectBorderStyle}
									hoverColor="#0071F2"
									disabled={
										props.view
											? true
											: walkin
												? false
												: props.type === "cancel"
													? true
													: false
									}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Paper> */}
    </Div>
  );
}

export default withAllContexts(DeviceDetails);
