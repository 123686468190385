import React from "react";

export const RadiologyIcon2 = (props) => {
  let width = props.width ? props.width : "28.805";
  let height = props.height ? props.height : "40";
  let color = props.color ? props.color : "#252525";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28.805 40"
    >
      <g id="radiology1" transform="translate(-4.978 -0.499)">
        <path
          id="Path_92712"
          data-name="Path 92712"
          d="M17.93,17.194c-4.305,0-7.308-4.839-7.308-9.183A7.17,7.17,0,0,1,17.93.5a7.168,7.168,0,0,1,7.308,7.513C25.236,12.355,22.236,17.194,17.93,17.194Zm0-14.863c-3.274,0-5.474,2.283-5.474,5.681,0,3.776,2.66,7.349,5.474,7.349s5.476-3.571,5.476-7.349C23.406,4.614,21.2,2.332,17.93,2.332Z"
          transform="translate(1.411)"
          fill="#2a60bc"
        />
        <path
          id="Path_92713"
          data-name="Path 92713"
          d="M8.218,18.482v-.271c0-2.446,1.419-3.684,4.216-3.684.263,0,.489.013.659.026a8.112,8.112,0,0,0,5.936,2.871,8.114,8.114,0,0,0,5.936-2.871c.17-.015.4-.026.659-.026,2.8,0,4.216,1.238,4.216,3.684v.271h1.833v-.271c0-3.455-2.263-5.518-6.049-5.518a8.21,8.21,0,0,0-1.281.09l-.371.061-.22.3a6.409,6.409,0,0,1-4.723,2.446,6.476,6.476,0,0,1-4.723-2.446l-.221-.3-.37-.061a8.286,8.286,0,0,0-1.281-.09c-3.786,0-6.049,2.063-6.049,5.518v.271Z"
          transform="translate(0.352 3.049)"
          fill="#2a60bc"
        />
        <path
          id="Path_92714"
          data-name="Path 92714"
          d="M32.038,18.121H6.723a1.747,1.747,0,0,0-1.745,1.748v14.48a1.745,1.745,0,0,0,1.745,1.745H32.038a1.745,1.745,0,0,0,1.745-1.745V19.87A1.747,1.747,0,0,0,32.038,18.121ZM26.821,29.579a12.407,12.407,0,0,0-6.615-2.2V29.85a10.765,10.765,0,0,1,5.683,1.875c.784.579.025,1.9-.769,1.319a9.47,9.47,0,0,0-4.914-1.66v2.808a.764.764,0,0,1-1.528,0V31.376a9.509,9.509,0,0,0-5.036,1.668c-.794.584-1.553-.74-.769-1.319a10.831,10.831,0,0,1,5.806-1.883V27.376a12.442,12.442,0,0,0-6.739,2.2c-.794.586-1.554-.74-.771-1.319a13.754,13.754,0,0,1,7.51-2.415V23.422a13.038,13.038,0,0,0-5.586,1.426c-.861.479-1.633-.843-.771-1.319A14.621,14.621,0,0,1,18.679,21.9V20.046a.764.764,0,0,1,1.528,0V21.9a14.611,14.611,0,0,1,6.356,1.626c.861.476.091,1.8-.77,1.319a13.051,13.051,0,0,0-5.586-1.426v2.426a13.694,13.694,0,0,1,7.386,2.41C28.374,28.839,27.614,30.165,26.821,29.579Z"
          transform="translate(0 4.405)"
          fill="#ec6a49"
        />
      </g>
    </svg>
  );
};
