import React, { useState } from "react";
import { Div, Card, Image, Button } from "qdm-component-library";
import { Popover, Typography, makeStyles } from "@material-ui/core";
import SelectCard from "./selectCard";
import downArrowIcon from "../../../assets/img/svg/Group -1.svg";
import EncounterShowingCard from "./encounterShowCard";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { UIColor } from "../../../themes/theme";

const useStyles = makeStyles((theme) => ({
  muiPopovers: {
    boxShadow: "0px 25px 50px #00000029",
    borderRadius: 8,
    width: "32%",
    width: "48%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  circle: {
    border: `1px solid ${UIColor.tertiaryText.color}`,
    borderRadius: "50%",
    width: "21px",
    height: "21px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding:"20px"
  },
  circlecount: {
    border: `2px solid`,
    borderColor:"#17a99e",
    borderRadius: "20%",
    width: "5px",
    height: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding:"10px"
  },
}));

const EncounterListCard = (props) => {
  const [open, setOpen] = useState(null);
  const classes = useStyles();

  const styles = {
    rowFlex: {
      display: "flex",
      alignItems: "center",
    },
    cardStyle: {
      boxShadow: "none",
      borderRadius: 8,
      marginLeft: "5px",
    },
  };

  const openPopover = (e) => {
    setOpen(e?.currentTarget ?? null);
  };

  const closePopover = () => {
    setOpen(null);
  };

  const handleEncounterSelect = (val) => {
    props?.handleEncounterSelect && props.handleEncounterSelect(val);
    closePopover();
  };

  const { selected = {}, encounterList = [], parent_id, padding } = props;

  return (
    <>
      <Card
        id={`${parent_id}_patienttype_parent_card`}
        style={{
          ...styles.cardStyle,
          cursor: encounterList?.length > 1 ? "pointer" : "auto",
          height: "100%",
          padding: padding ? 10 : 0,
        }}
        onClick={(e) => (encounterList?.length > 1 ? openPopover(e) : null)}
      >
        <Div id={`${parent_id}_patienttype_parent_div`} style={styles.rowFlex}>
          {selected && (
            <EncounterShowingCard
              parent_id={"patient_care"}
              moreopen={true}
              pic={selected?.pic}
              title_name={selected?.title_name}
              encounterName={selected?.encounterName}
              encounterEnd={selected?.encounterEnd}
              encounterStart={selected?.encounterStart}
              type={selected?.type}
              speciality={selected?.speciality}
              doctorName={selected?.doctorName}
              noOfIteams={encounterList?.length}
              encstatus={selected?.encstatus}
            />
          )}
          {encounterList?.length > 1 && (
            <Div
              id={`${parent_id}_patienttype_div`}
              style={{
                ...styles.rowFlex,
                position: "relative",
                width: 24,
                height: 24,
              }}
            >
              <Image
                id={`${parent_id}_patienttype_group_downarrow_image`}
                src={downArrowIcon}
                alt="downArrow"
                style={{
                  position: "absolute",
                  right: -30
                }}
              />
            </Div>
          )}
        </Div>
      </Card>
      <Popover
        anchorEl={open}
        open={Boolean(open)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={closePopover}
        classes={classes.muiPopovers}
      >
        <Div id={`${parent_id}_patienttype_title_div`} style={{ padding: 5 }}>
          {encounterList.map((val, i) => (
            <Div
              id={`${parent_id}_${i}_patienttype_title_name_div`}
              style={{
                ...styles.rowFlex,
                padding: "5px 10px",
                paddingBottom: 10,
                cursor: "pointer",
              }}
              onClick={() => handleEncounterSelect(val)}
              key={i}
            >
              <EncounterShowingCard
                parent_id={"patient_care"}
                pic={val?.pic}
                title_name={val?.title_name}
                encounterName={val?.encounterName}
                encounterEnd={val?.encounterEnd}
                encounterStart={val?.encounterStart}
                type={val?.type}
                speciality={val?.speciality}
                doctorName={val?.doctorName}
                noOfIteams={encounterList?.length}
                encstatus={val?.encstatus}
              />
            </Div>
          ))}
        </Div>
        <div className={classes.alignItems} style={{ gap: "6px", display:"flex", justifyContent:"flex-end", padding:"10px"}}>
            <Button className={classes.circle}backgroundColor="#17a99e" disabled={props?.Isdisableleft} onClick={props?.getPreviousSet}>
              <ChevronLeftIcon className={classes.arrow} />
            </Button>

            <div className={classes.circlecount} style={{display:"flex", alignItems:"center", textSize:"5px"}}>
              <Typography variant="caption">
                {props?.count}
              </Typography>
            </div>
           
            <Button className={classes.circle} backgroundColor="#17a99e" disabled={props?.Isdisableright} onClick={props?.getNextSet}>
              <ChevronRightIcon className={classes.arrow} />
            </Button>
          </div>
      </Popover>
    </>
  );
};

EncounterListCard.defaultProps = {
  encounterList: [],
};

export default EncounterListCard;
