import {
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Div, TextInput } from "qdm-component-library";
import React from "react";
import { useSelector } from "react-redux";
import { UIColor } from "../../../../themes/theme.js";
// import Select from "../../common/Select";
import {
  CustomButton,
  // Autocomplete
} from "./components";
import DatePicker from "react-datepicker";
import moment from "moment";
import TrashIcon from "../../../../assets/img/svg/trashIcon.js";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "12px 16px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 12,
    fontWeight: 500,
  },
  action: {
    display: "flex",
    gap: 12,
    alignItems: "center",
  },
  popper: {
    zIndex: theme.zIndex.modal,
    // width: "100%",
  },
  popperRoot: {
    padding: "24px 16px",
    width: 650,
    boxShadow: "#0000003D 0px 10px 75px",
    borderRadius: 8,
    background: "white",
    // width: "100%",
  },
  dropdown: {
    background: `${theme.palette.background.table} !important`,
    "&:input": {
      color: theme.palette.text.primary,
    },
  },
  dosageField: {
    display: "flex",
    gap: 12,
    alignItems: "center",
  },
  actionButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 8,
  },
  additiveItemContainer: {
    marginBottom: 12,
  },
}));

function ScheduleLine(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const base_frequency_dropdown = useSelector(
    (state) =>
      state?.EditNewFrequency_orgbase_frequency_dropdownReducer
        ?.base_frequency_dropdown?.data
  );
  const scheduleLine_day_dropdown = useSelector(
    (state) => state?.schedule_line_day_dropdownReducer?.schedule_line_day?.data
  );

  const onchange = (name, value, index) => {
    props.handleScheduleLineChange(name, value, index);
  };

  const handleAddNew = () => {
    props.handleScheduleLineChange("addNew");
  };
  const { allDisabled } = props;

  const resFrq = (val) => {
    if (val?.baseFreq?._id) {
      return {
        code: val?.baseFreq?._id ?? val?.baseFreq?.code ?? "",
        val:
          (val?.baseFreq?.freqcode ?? "") ||
          // + ' ' + (val?.baseFreq?.shortdesc ?? '')
          (val?.baseFreq?.val ?? ""),
      };
    } else if (val?.basefreq?.code) {
      return val?.basefreq;
    } else if (typeof val?.baseFreq === "string") {
      return {
        code: val?.baseFreq ?? "",
        val: val?.baseFreq ?? "",
      };
    } else if (
      val?.basefreq?._id ||
      val?.basefreq?.code ||
      val?.basefreq?.freqcode ||
      val?.basefreq?.val
    ) {
      return {
        code: val?.basefreq?._id ?? val?.basefreq?.code ?? "",
        val:
          (val?.basefreq?.freqcode ?? "") ||
          // + ' ' + (val?.basefreq?.shortdesc ?? '')
          (val?.basefreq?.val ?? ""),
      };
    } else if (val?._id || val?.code || val?.freqcode || val?.val) {
      return {
        code: val?._id ?? val?.code ?? "",
        val:
          (val?.freqcode ?? "") ||
          //  + ' ' + (val?.shortdesc ?? '')
          (val?.val ?? ""),
      };
    } else {
      return {};
    }
  };
  return (
    <div
      style={{
        padding: "20px",
        minWidth: "200px",
        border: "1px solid #dee5ec",
        borderRadius: "8px",
        maxHeight: "400px",
        overflow: "auto",
      }}
    >
      {/* <Typography
        style={{ fontSize: "12px", fontWeight: 600, marginBottom: "15px" }}
      >
        SCHEDULE LINES
      </Typography> */}

      <div className={classes.header}>
        <Typography className={classes.title}> SCHEDULE LINES</Typography>
        {!allDisabled && props?.type === "User Defined" && (
          <div className={classes.action}>
            <CustomButton
              color={theme.palette.primary.main}
              onClick={() => handleAddNew()}
            >
              + Add New
            </CustomButton>
          </div>
        )}
      </div>

      {props?.type === "Detail" &&
        props?.data?.frequency?.map((val, index) => (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Div style={props?.styles.labelStyle}>Time</Div>
              <DatePicker
                id={`DatePicker`}
                selected={val?.time ? new Date(moment(val?.time, "HH:mm")) : ""}
                // selected={val?.time}
                onChange={(value) =>
                  onchange("time", moment(value).format("HH:mm"), index)
                }
                // onChange={(value) => onchange("time", value, index)}
                timeFormat={"HH:mm"}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={5}
                timeCaption="Time"
                dateFormat={"HH:mm"}
                placeholderText="Time"
                disabled={allDisabled}
              />
            </Grid>
            <Grid item xs={4}>
              <Div style={props?.styles.labelStyleScheduleLine}>Doses</Div>
              <TextInput
                // id={`${parent_id}_medication_notes_textinput`}
                label={""}
                // placeholder="type here...."
                hoverColor={UIColor.lineBorderFill.color}
                value={val?.dose ?? ""}
                style={{ borderColor: UIColor.lineBorderFill.color }}
                disabled={allDisabled}
                onChange={(e) => {
                  onchange("dose", e.target.value, index);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Div style={props?.styles.labelStyle}>Remarks</Div>
              <TextInput
                // id={`${parent_id}_medication_notes_textinput`}
                label={""}
                // placeholder="type here...."
                hoverColor={UIColor.lineBorderFill.color}
                value={val?.remarks}
                style={{ borderColor: UIColor.lineBorderFill.color }}
                onChange={(e) => {
                  onchange("remarks", e.target.value, index);
                }}
                disabled={allDisabled}
              />
            </Grid>
          </Grid>
        ))}

      {(props?.type === "Interval" || props?.type === "Sequential") &&
        props?.data?.frequency?.map((val, index) => (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Div style={props?.styles.labelStyle}> Base Frequency</Div>
              <MuiAutocomplete
                id={"fW4qT"}
                getOptionLabel={(option) => option?.val ?? ""}
                disabled={allDisabled}
                value={resFrq(val) ?? {}}
                options={base_frequency_dropdown?.result?.map((v) => {
                  return {
                    val: v?.freqcode,
                    code: v?._id,
                  };
                })}
                onChange={(e, newvalue) =>
                  onchange("basefreq", newvalue, index)
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" margin="dense" />
                )}
              />
            </Grid>
            {props?.type !== "Interval" && (
              <Grid item xs={3}>
                <Div style={props?.styles.labelStyleScheduleLine}>Doses</Div>
                <TextInput
                  // id={`${parent_id}_medication_notes_textinput`}
                  label={""}
                  // placeholder="type here...."
                  hoverColor={UIColor.lineBorderFill.color}
                  value={val?.dose ?? ""}
                  style={{ borderColor: UIColor.lineBorderFill.color }}
                  disabled={allDisabled}
                  onChange={(e) => {
                    onchange("dose", e.target.value, index);
                  }}
                />
              </Grid>
            )}
            <Grid item xs={3}>
              <Div style={props?.styles.labelStyleScheduleLine}>Remarks</Div>
              <TextInput
                label={""}
                hoverColor={UIColor.lineBorderFill.color}
                value={val?.remarks}
                style={{ borderColor: UIColor.lineBorderFill.color }}
                disabled={allDisabled}
                onChange={(e) => {
                  onchange("remarks", e.target.value, index);
                }}
              />
            </Grid>
          </Grid>
        ))}

      {props?.type === "Day Based" &&
        props?.data?.frequency?.map((val, index) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Div style={props?.styles.labelStyle}>Day</Div>
                <MuiAutocomplete
                  getOptionLabel={(option) => option?.val ?? ""}
                  value={
                    val?.day?.val
                      ? val?.day
                      : {
                          code: val?.day?._id ?? "",
                          val: val?.day?.display ?? "",
                        }
                  }
                  disabled={allDisabled}
                  options={scheduleLine_day_dropdown?.result?.map((v) => {
                    return {
                      val: v?.shortdesc,
                      code: v?._id,
                    };
                  })}
                  onChange={(e, newvalue) => onchange("day", newvalue, index)}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" margin="dense" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Div style={props?.styles.labelStyle}> Base Frequency</Div>
                <MuiAutocomplete
                  getOptionLabel={(option) => option?.val ?? ""}
                  disabled={allDisabled}
                  value={resFrq(val) ?? {}}
                  options={base_frequency_dropdown?.result?.map((v) => {
                    return {
                      val: v?.freqcode,
                      //  + " " + v?.shortdesc,
                      code: v?._id,
                    };
                  })}
                  onChange={(e, newvalue) =>
                    onchange("basefreq", newvalue, index)
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" margin="dense" />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Div style={props?.styles.labelStyleScheduleLine}>Doses</Div>
                <TextInput
                  // id={`${parent_id}_medication_notes_textinput`}
                  label={""}
                  // placeholder="type here...."
                  hoverColor={UIColor.lineBorderFill.color}
                  value={val?.dose ?? ""}
                  style={{ borderColor: UIColor.lineBorderFill.color }}
                  disabled={allDisabled}
                  onChange={(e) => {
                    onchange("dose", e.target.value, index);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Div style={props?.styles.labelStyleScheduleLine}>Remarks</Div>
                <TextInput
                  label={""}
                  hoverColor={UIColor.lineBorderFill.color}
                  value={val?.remarks}
                  style={{ borderColor: UIColor.lineBorderFill.color }}
                  disabled={allDisabled}
                  onChange={(e) => {
                    onchange("remarks", e.target.value, index);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        ))}

      {props?.type === "User Defined" &&
        props?.data?.frequency?.map((val, index) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Div style={props?.styles.labelStyleScheduleLine}>From Day</Div>
                <TextInput
                  // id={`${parent_id}_medication_notes_textinput`}
                  label={""}
                  hoverColor={UIColor.lineBorderFill.color}
                  value={val?.fromDay ?? ""}
                  disabled={allDisabled}
                  style={{ borderColor: UIColor.lineBorderFill.color }}
                  onChange={(e) => {
                    onchange("fromDay", e.target.value, index);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Div style={props?.styles.labelStyleScheduleLine}>To Day</Div>
                <TextInput
                  // id={`${parent_id}_medication_notes_textinput`}
                  label={""}
                  // placeholder="type here...."
                  hoverColor={UIColor.lineBorderFill.color}
                  value={val?.toDay ?? ""}
                  style={{ borderColor: UIColor.lineBorderFill.color }}
                  disabled={allDisabled}
                  onChange={(e) => {
                    onchange("toDay", e.target.value, index);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Div style={props?.styles.labelStyle}> Base Frequency</Div>
                <MuiAutocomplete
                  disabled={allDisabled}
                  getOptionLabel={(option) => option?.val ?? ""}
                  value={resFrq(val) ?? {}}
                  options={
                    base_frequency_dropdown?.result?.length > 0
                      ? base_frequency_dropdown?.result?.map((v) => {
                          return {
                            val: v?.freqcode,
                            // + " " + v?.shortdesc,
                            code: v?._id,
                          };
                        })
                      : []
                  }
                  onChange={(e, newvalue) =>
                    onchange("basefreq", newvalue, index)
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" margin="dense" />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Div style={props?.styles.labelStyleScheduleLine}>Doses</Div>
                <TextInput
                  // id={`${parent_id}_medication_notes_textinput`}
                  label={""}
                  // placeholder="type here...."
                  hoverColor={UIColor.lineBorderFill.color}
                  value={val?.dose ?? ""}
                  style={{ borderColor: UIColor.lineBorderFill.color }}
                  disabled={allDisabled}
                  onChange={(e) => {
                    onchange("dose", e.target.value, index);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Div style={props?.styles.labelStyleScheduleLine}>Remarks</Div>
                <TextInput
                  // id={`${parent_id}_medication_notes_textinput`}
                  label={""}
                  // placeholder="type here...."
                  hoverColor={UIColor.lineBorderFill.color}
                  disabled={allDisabled}
                  value={val?.remarks ?? ""}
                  style={{ borderColor: UIColor.lineBorderFill.color }}
                  onChange={(e) => {
                    onchange("remarks", e.target.value, index);
                  }}
                />
              </Grid>
              {/* {props?.data?.frequency?.length > 2 &&  */}
              {!allDisabled && (
                <Grid item xs={1}>
                  <IconButton
                    size="small"
                    onClick={() => props?.handleScheduleDelete(index)}
                    style={{ marginTop: "36px" }}
                  >
                    <TrashIcon />
                  </IconButton>
                </Grid>
              )}
              {/* } */}
            </Grid>
          </div>
        ))}

      {props?.type === "" && (
        <Div style={props?.styles.labelStyle}>The Type is not defined</Div>
      )}
    </div>
  );
}

export default ScheduleLine;
