import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../../../themes/theme";

export const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: 16,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  body: {
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
  TapSelect: {
    "& div": {
      justifyContent: "center !important",
      "& span": {
        fontSize: 12,
        fontWeight: "600",
        letterSpacing: "0.9px",
        fontFamily: "pc_regular !important",
      },
    },
  },
  imgage: {
    width: 40,
    height: 40,
    borderRadius: "50%",
  },
}));
