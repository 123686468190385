import React from "react";

export const DashboardIcon = (props) => {
  let width = props.width ? props.width : "17.583";
  let height = props.height ? props.height : "17.583";
  let color = props.color ? props.color : "#101010";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17.583 17.583"
      {...props}
    >
      <path id="icons8-dashboard-layout_7_" data-name="icons8-dashboard-layout (7)" d="M8.2,6A2.209,2.209,0,0,0,6,8.2V9.663a2.209,2.209,0,0,0,2.2,2.2h3.907a2.209,2.209,0,0,0,2.2-2.2V8.2a2.209,2.209,0,0,0-2.2-2.2Zm9.28,0a2.209,2.209,0,0,0-2.2,2.2v6.349a2.209,2.209,0,0,0,2.2,2.2h3.907a2.209,2.209,0,0,0,2.2-2.2V8.2a2.209,2.209,0,0,0-2.2-2.2ZM8.2,7.465h3.907a.722.722,0,0,1,.733.733V9.663a.722.722,0,0,1-.733.733H8.2a.722.722,0,0,1-.733-.733V8.2A.722.722,0,0,1,8.2,7.465Zm9.28,0h3.907a.722.722,0,0,1,.733.733v6.349a.722.722,0,0,1-.733.733H17.478a.722.722,0,0,1-.733-.733V8.2A.722.722,0,0,1,17.478,7.465ZM8.2,12.838a2.209,2.209,0,0,0-2.2,2.2v6.349a2.209,2.209,0,0,0,2.2,2.2h3.907a2.209,2.209,0,0,0,2.2-2.2V15.036a2.209,2.209,0,0,0-2.2-2.2Zm0,1.465h3.907a.722.722,0,0,1,.733.733v6.349a.722.722,0,0,1-.733.733H8.2a.722.722,0,0,1-.733-.733V15.036A.722.722,0,0,1,8.2,14.3Zm9.28,3.419a2.209,2.209,0,0,0-2.2,2.2v1.465a2.209,2.209,0,0,0,2.2,2.2h3.907a2.209,2.209,0,0,0,2.2-2.2V19.92a2.209,2.209,0,0,0-2.2-2.2Zm0,1.465h3.907a.722.722,0,0,1,.733.733v1.465a.722.722,0,0,1-.733.733H17.478a.722.722,0,0,1-.733-.733V19.92A.722.722,0,0,1,17.478,19.187Z" 
        transform="translate(-6 -6)"
        fill={color}
      />
    </svg>
  );
};
