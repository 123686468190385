/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 
 * @modify date 
 * @desc Providing the BackdropContext from /src/context which is used in /src/App.js
 */

import React from "react";
import { BackdropContext } from "./context"
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles, Grid } from "@material-ui/core";
import Lottie from "react-lottie";
import animationData from "./assets/animations/loading.json";
const styles = (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

class AppBackDrop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            message: '',
            setBackDrop: () => null
        };
    }
     

    close = () => {
        this.setState({
            open: false,
            message: ""
        });
    };

    set = (props) => {
        this.setState({ ...props });
    };

    render() {

        const { classes } = this.props;
        const {
            open,
            message
        } = this.state;

        return (
            <BackdropContext.Provider
                value={{
                    ...this.state,
                    setBackDrop: this.set,
                }}
            >
                {this.props.children}
                <Backdrop className={classes.backdrop} open={open} onClick={(event) => event.stopPropagation()}>
                    <Grid container direction="column" alignItems="center" justify="center">
                        <Grid item>
                           <Lottie options={defaultOptions} height={200} width={200} />
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" color="inherit" >{message}</Typography>
                        </Grid>
                    </Grid>
                </Backdrop>
            </BackdropContext.Provider>
        );
    }
}

export default withStyles(styles)(AppBackDrop);