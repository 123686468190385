import React from "react";
import { useController } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import PropTypes from "prop-types";

function Mui5ReactHkFormAutocomplete({
  control,
  name,
  rules,
  label,
  options,
  getOptionLabel,
  error,
  helperText,
  sx,
  ...rest
}) {
  const {
    field: { onChange, ...restField },
    fieldState: { error: fieldError },
  } = useController({
    name,
    control,
    rules
  });

  const hasError = error || fieldError; // Combine errors from both sources

  return (
    <Autocomplete
      {...restField}
      {...rest}
      // Use onChange directly from useController for form updates
      onChange={onChange}
      size="small"
      disablePortal
      options={options}
      getOptionLabel={getOptionLabel}
      fullWidth
      sx={sx}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={hasError}
          helperText={hasError ? (error || fieldError)?.message : ""}
         
        />
      )}
    />
  );
}

Mui5ReactHkFormAutocomplete.defaultProps = {};

Mui5ReactHkFormAutocomplete.propTypes = {
  control: PropTypes.object.isRequired, // Required `control` object from react-hook-form
  name: PropTypes.string.isRequired, // Required field name
  label: PropTypes.string.isRequired, // Required label for the input
  options: PropTypes.arrayOf(PropTypes.object).isRequired, // Required array of options
  getOptionLabel: PropTypes.func, // Optional function to get option label
  error: PropTypes.string, // Optional error message (string)
  helperText: PropTypes.string, // Optional helper text (string)
  // ... other optional props (if applicable)
};

export default Mui5ReactHkFormAutocomplete;
