import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EventIcon from '@material-ui/icons/Event';
import AccessTime from '@material-ui/icons/AccessTime';

const  CustomInputDatePicker = (props) =>  {
 
    return (
      <DatePicker
        popperPlacement={props?.popperPlacement}
        minDate={props?.minDate}
        maxDate={props?.maxDate}
        selected={props.selectedDate}
        timeFormat={props?.timeFormat }
        showTimeSelectOnly={props?.showTimeSelectOnly || false}
        showTimeSelect={props?.showTimeSelect || false}
        timeIntervals={props?.timeIntervals}
        // monthsShown={1}
        customInput={
          <div style={{ position: "relative" }}>
            {props.inputField}
            {props?.showTimeSelect && props?.showTimeSelectOnly ? 
              <AccessTime style={{ position: "absolute", top: props?.customInput?.top ?? 32, right: props?.customInput?.right ?? 10 }}/> :
              <EventIcon style={{ position: "absolute", top: props?.customInput?.top ?? 33, right: props?.customInput?.right ?? 10 }}/>}
          </div>
        }
        disabled={props.disabled}
        readOnly={props.readOnly}
        placeholderText={props.placeholderText}
        // className={this.props.className}
        dateFormat="dd/MM/yyyy"
        // inline
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        calendarStartDay={1}
        onChange={(value) => {
          props.handleChange(value) ;
        }}
      />
    );
  
}

export default CustomInputDatePicker;
