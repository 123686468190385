import React from "react";

export const TickSelectde = (props) => {
  let width = props.width ? props.width : "40.8";
  let height = props.height ? props.height : "40.8";
  let color = props.color ? props.color : "#252525";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g id="Group_93389" data-name="Group 93389" transform="translate(-882 -11)">
      <g id="Rectangle_47039" data-name="Rectangle 47039" transform="translate(882 11)" fill="none" stroke={color} stroke-width="1">
        <rect width="14" height="14" rx="7" stroke="none"/>
        <rect x="0.5" y="0.5" width="13" height="13" rx="6.5" fill="none"/>
      </g>
      <path id="icons8-checked-checkbox" d="M15.367,10.438a.437.437,0,0,0-.3.132l-3.772,3.772L9.854,12.9a.437.437,0,1,0-.618.618l1.749,1.749a.437.437,0,0,0,.618,0l4.082-4.082a.437.437,0,0,0-.318-.75Z" transform="translate(876.541 5.082)" fill={color}/>
    </g>
  </svg>
  );
};