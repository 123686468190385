import React, { useEffect } from "react";
import SearchFab from "./components/searchFab";
import { Image, SideMenu, Div } from "qdm-component-library";
import { UIColor } from "../../../themes/theme";
import { connect } from "react-redux";
import  actions  from "../../../redux/actions";
// import BookAppointment from "../bookAppointment";
import { checkWithCasbin, fetchUserInfo } from "../../../utils";
import SearchIcon from "../../../assets/img/svg/icons8-search.svg";
import SearchForm from "./components/searchForm";
import jwtDecode from "jwt-decode";
import { FastForward } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../router/routes";
import plusIcon from "../../../assets/img/svg/ic_outline-plus.svg";
import { TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
const casbinjs = require("casbin.js");

const SearchPatient = (props) => {
  const dispatch = useDispatch();
  const [withSearchState, setWithSearchState] = React.useState({
    searchable: false,
    bookAppointment: false,
    groupedOptions: [],
    hidden: false,
    callcen: false,
    isCashier: false,
  });
  const navigate = useNavigate();
  const handleActionOpen = () => {
    // this.setState({
    // 	searchable: true,
    // });
    setWithSearchState({ ...withSearchState, searchable: true });
  };
  const handleDrawerOpen = () => {
    // this.setState({
    // 	bookAppointment: true,
    // });
    setWithSearchState({ ...withSearchState, bookAppointment: true });
  };
  const handleActionOpen2 = async() => {
    //const element = document.documentElement;
    // if (element.requestFullscreen) {
    //   element.requestFullscreen();
    // } else if (element.mozRequestFullScreen) {
    //   element.mozRequestFullScreen();
    // } else if (element.webkitRequestFullscreen) {
    //   element.webkitRequestFullscreen();
    // } else if (element.msRequestFullscreen) {
    //   element.msRequestFullscreen();
    // }
    navigate(AppRoutes.patientRegistration);
  //   await dispatch(actions.SEVERITY_MASTERS());
  //   await dispatch(actions.BODYSITE_MASTERS());
  //   await dispatch(actions.VERIFICATIONS_MASTERS());
  //   await dispatch(actions.CC_MASTERS());
  //   await dispatch(actions.CLINIC_STATUS_MASTERS());
  //   await dispatch(actions.RELATIONSHIP_MASTERS());
    
    

  //  const a = await dispatch(actions.GET_ENCOUNTER({ encounter_id: "Encounter/11999" }));
  //  await dispatch(actions.ENC_BASED_CC({encounter_id: a?.payload?.data[0]?._id })); 
  //  navigate(AppRoutes.clinicalInfo,{
  //     state: {
  //       encData:a?.payload?.data[0],
  //     }});
  };
  const handleDrawerClose = () => {
    // this.setState({
    // 	bookAppointment: false,
    // });
    setWithSearchState({ ...withSearchState, bookAppointment: false });
  };
  const handleActionClose = () => {
    // this.setState({
    // 	searchable: false,
    // });
    setWithSearchState({ ...withSearchState, searchable: false });
  };
  useEffect(() => {
    let data = withSearchState;
    const asyncfunction = async () => {
      let permission_ = await checkWithCasbin(["dashboard"]);
      const isCashier = (await permission_?.write?.indexOf("cashierIcon")) > -1;
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      var unique = [...permission_.read, ...permission_.write].filter(
        onlyUnique
      );
      if (unique.length > 1) {
        let isBookAppointmentIcon = Boolean(
          unique.find((val) => {
            return val === "bookAppointmentIcon";
          })
        );
        data.callcen = isBookAppointmentIcon;
      }

      if (isCashier) {
        data.searchable = true;
        data.isCashier = true;
      }

      const Arr = localStorage.getItem("RoleData");
      const getVal = jwtDecode(Arr);
      // const arr1 =
      //   getVal[localStorage.getItem("RoleName").toLowerCase()].access;
      const arr1 =
        getVal?.access;
      const permission = { callcenter: arr1 };
      const authorizer = new casbinjs.Authorizer("manual", permission);
      authorizer.setPermission(permission);
      const result = await authorizer.can("callcenter", "4");
      data.callcen = result;
      const userData_ = await fetchUserInfo();
      if (
        userData_?.internalRole === "doctor" ||
        userData_?.internalRole === "nurse"
      ) {
        data.hidden = true;
      }
      setWithSearchState({ ...withSearchState, data });
    };
    asyncfunction();
  }, []);
  const { noNewPatient } = props;
  return (
    <React.Fragment>
     
      {withSearchState?.searchable && (
        <Div
          inLineStyles={{
            zIndex: 999,
            width: "25%",
            right: 0,
            position: "absolute",
            background: UIColor.differentiationBackground.color,
            height: "calc(100vh - 10.8vh)",
            overflow: "auto",
            borderLeft:
              localStorage.getItem("role_name") === "biller and cashier"
                ? "1px solid #DEE5EC"
                : "none",
          }}
        >
          <SearchForm
            handleActionClose={handleActionClose}
            parent_id={`${props?.parent_id}`}
            noNewPatient={props?.noNewPatient}
            isCashier={withSearchState?.isCashier}
          />
        </Div>
      )}
      {/* </SideMenu> */}
      {!withSearchState?.searchable ? (
        <React.Fragment>
          {!withSearchState?.bookAppointment && (
            <div
              id={`${props.parent_id}_div`}
              style={{ display: withSearchState?.hidden ? "none" : "block" }}
            >
              <div>
                {withSearchState?.callcen && (
                  <SearchFab
                    bottom
                    parent_id={"search"}
                    icon={
                      <Image
                        id={`${props?.parent_id}_image`}
                        src="images/calender_clock_1.svg"
                        alt="search"
                        style={{ width: 22, height: 22 }}
                      />
                    }
                    handleAction={() => handleDrawerOpen()}
                  />
                )}
              </div>
              <SearchFab
                parent_id={"search"}
                icon={
                  <Image
                    id={`${props?.parent_id}_image`}
                    src={SearchIcon}
                    alt="search"
                    style={{ width: 15, height: 15 }}
                  />
                }
                icon2={
                  <Image
                    id={`${props?.parent_id}_image`}
                    src={plusIcon}
                    alt="search"
                    style={{ width: 25, height: 25 }}
                  />
                }
                handleAction2={() => handleActionOpen2()}
                handleAction={() => handleActionOpen()}
              />
            </div>
          )}
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default SearchPatient;
