import React, { useState } from "react";
import { Div, Card, Image } from "qdm-component-library";
import { withStyles, Popover } from "@material-ui/core";
import SelectCard from "./selectCard";
import downArrow from "../../../assets/img/svg/Group -1.svg";

const materialStyles = (theme) => ({
  muiPopovers: {
    boxShadow: "0px 25px 50px #00000029",
    borderRadius: 8,
    width: "32%",
    width: "48%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
});

const PatientCareTypeCard = (props) => {
  const [state, setState] = useState({
    open: null,
  });

  const styles = {
    rowFlex: {
      display: "flex",
      alignItems: "center",
    },
    cardStyle: {
      // boxShadow: "0px 10px 25px #0000000A",
      boxShadow: "none",
      borderRadius: 8,
      marginLeft: "5px",
      // padding: 10,
    },
  };

  const openPopover = (e) => {
    setState({
      open: e?.currentTarget ?? null,
    });
  };

  const closePopover = (e) => {
    setState({
      open: null,
    });
  };

  const handleChange = (val) => {
    props?.onChange && props.onChange(val);
    closePopover();
  };

  let { open } = state;
  const { classes, selected = {}, careList = [], parent_id, padding } = props;

  return (
    <React.Fragment>
      <Card
        id={`${parent_id}_patienttype_parent_card`}
        style={{
          ...styles.cardStyle,
          cursor: careList?.length > 1 ? "pointer" : "auto",
          height: "100%",
          padding: padding ? 10 : 0,
        }}
        onClick={(e) => (careList?.length > 1 ? openPopover(e) : null)}
      >
        <Div id={`${parent_id}_patienttype_parent_div`} style={styles.rowFlex}>
          {selected && (
            <SelectCard
              parent_id={"patient_care"}
              moreopen={true}
              pic={selected?.pic}
              title_name={selected?.title_name}
              care_type={selected?.care_type}
              noOfIteams={careList?.length}
              isDetailed={props?.isDetailed}
              policy={selected?.policy}
            />
          )}
          {careList?.length > 1 && (
            <Div
              id={`${parent_id}_patienttype_div`}
              style={{ ...styles.rowFlex, width: 24, height: 24 }}
            >
              <Image
                id={`${parent_id}_patienttype_group_downarrow_image`}
                src={downArrow}
                alt="downArrow"
              />
            </Div>
          )}
        </Div>
      </Card>
      <Popover
        anchorEl={open}
        open={Boolean(open)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={closePopover}
        classes={{ paper: classes.muiPopovers }}
      >
        <Div id={`${parent_id}_patienttype_title_div`} style={{ padding: 5 }}>
          {careList.map((val, i) => {
            return (
              <Div
                id={`${parent_id}_${i}_patienttype_title_name_div`}
                style={{
                  ...styles.rowFlex,
                  padding: "5px 10px",
                  paddingBottom: 10,
                  cursor: "pointer",
                }}
                onClick={() => handleChange(val)}
              >
                <SelectCard
                  parent_id={"patient_care_" + i}
                  pic={val.pic}
                  title_name={val?.title_name}
                  care_type={val?.care_type}
                  showUpArrow={i === 0 ? true : false}
                  showDivider={i < careList.length - 1 ? true : false}
                  policyexpiry={val?.policyexpiry}
                  priority={val?.priority?.display}
                  policy={val?.policy}
                  isDetailed={props?.isDetailed}
                />
              </Div>
            );
          })}
        </Div>
      </Popover>
    </React.Fragment>
  );
};

PatientCareTypeCard.defaultProps = {
  careList: [],
};

export default withStyles(materialStyles)(PatientCareTypeCard);
