import React from "react";
import { Col, TextInput, Select, Text, H6, Image } from "qdm-component-library";
import { MaterialAutoComplete } from "../../../../components";
import {
  QuantityRangePicker,
  InputGroup,
  AdditiveDetails,
} from "../components";
import { makeStyles, Switch, Popover } from "@material-ui/core";
import { Button } from "qdm-component-library";
import { useSelector, useDispatch } from "react-redux";
import { UIColor } from "../../../../themes/theme";
// import { actions } from "primarycare-binder";
import actions from "../../../../redux/actions"
const useStyles = makeStyles((theme) => ({
  switch: {
    "& .Mui-checked": {
      color: "#fff",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: UIColor.primaryColor.color,
      opacity: 1,
    },
  },
}));

const styles_ = {
  cancelBtn: {
    padding: "4px",
    fontSize: 12,
    marginRight: 5,
    borderColor: UIColor.lineBorderFill.color,
    backgroundColor: "#fff",
    color: UIColor.primaryColor.color,
    borderRadius: 8,
    float: "right",
  },
  filledBtn: {
    backgroundColor: UIColor.primaryColor.color,
    borderColor: UIColor.primaryColor.color,
    padding: "4px",
    fontSize: 12,
    borderRadius: 8,
    float: "right",
  },
};

const IVForm = (props) => {
  const { styles, data, stateList, isErr } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [masters, setMasters] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [reason, setReason] = React.useState("");
  const state = useSelector((state) => state?.MedicationMastersSlice);
  const handleInputChange = (selector, value) => {
    props.handleInputChange(selector, value, "ivForm");
  };
  const getDiscription = () => {
    let dis = data?.orderDescription;
    if (data.additiveDetails && data.additiveDetails.length > 0) {
      data.additiveDetails.map((val) => {
        dis += " +" + val.description?.label;
      });
    }
    return dis;
  };
  const handleMedication = async (v) => {
    if (v?.length > 3) {
      let ccMaster = await dispatch(
        actions.DRUGMASTER_MASTER({ input_text: v })
      );

      if (
        Array.isArray(ccMaster?.payload?.data) &&
        ccMaster?.payload?.data.length > 0
      ) {
        setMasters(ccMaster?.payload?.data);
      } else {
        setMasters([]);
      }
    }
    // else {
    // 	setMasters([]);
    // }
  };
  console.log("ivData", data);
  return (
    <>
      {/* Order Description START */}
      <Col
        xs={12}
        sm={12}
        md={5}
        lg={8}
        xl={8}
        inLineStyles={styles.colPadding}
      >
        <TextInput
          id={"mf-orderDescription"}
          label={"Order Description"}
          placeholder=""
          // isRequired
          labelStyle={styles.labelStyle}
          style={{ ...styles.borderStyle, ...styles.inputStyle }}
          value={getDiscription()}
          hoverColor={UIColor.lineBorderFill.color}
          error={isErr && !data?.orderDescription ? true : false}
        />
      </Col>
      {/* Order Description End */}
      {/* route type START */}
      <Col
        xs={12}
        sm={12}
        md={3}
        lg={2}
        xl={2}
        inLineStyles={styles.colPadding}
      >
        <Select
          getOptionLabel="label"
          showArrow
          id={"mf-route"}
          label={"Route"}
          isRequired
          placeholder={""}
          options={state?.DrugRoutes_masters?.data}
          labelStyle={styles.labelStyle}
          inLineStyles={
            isErr && !data?.route?.label
              ? styles.errorborderStyle
              : styles.borderStyle
          }
          hoverColor={UIColor.lineBorderFill.color}
          value={data?.route?.label}
          onChangeValue={(value) => handleInputChange("route", value)}
        />
      </Col>
      {/* route type END */}
      {/* base solution START */}
      <Col
        xs={12}
        sm={12}
        md={6}
        lg={8}
        xl={8}
        inLineStyles={{ paddingTop: 5 }}
      >
        <MaterialAutoComplete
          handleMedication={handleMedication}
          // optionsList={stateList?.drugMaster_masters?.data}
          optionsList={masters}
          handleInputChange={handleInputChange}
          value={data?.baseSolution}
          placeholder={"search.."}
          title={"Base Solution"}
          keyCode={"baseSolution"}
          isRequired
          error={isErr && !data?.baseSolution ? true : false}
        />
      </Col>
      {/* base solution END */}
      {/* strength END */}
      <Col
        xs={12}
        sm={12}
        md={3}
        lg={2}
        xl={2}
        inLineStyles={styles.colPadding}
      >
        <TextInput
          className={"textInput"}
          id={`mf-stregth`}
          label={"Strength"}
          placeholder=""
          // isRequired
          labelStyle={styles.labelStyle}
          style={{ ...styles.borderStyle, ...styles.inputStyle }}
          value={data?.strength}
          hoverColor={UIColor.lineBorderFill.color}
          onChange={(event) =>
            handleInputChange("strength", event.target.value)
          }
          disabled={true}
        />
      </Col>
      {/* strength END */}
      {/* volume END */}
      <Col
        xs={12}
        sm={12}
        md={3}
        lg={2}
        xl={2}
        inLineStyles={styles.colPadding}
      >
        <Text inLineStyles={styles.label}>
          volume{<span style={styles.required}>*</span>}
        </Text>
        <InputGroup
          title="volume"
          isRequired={true}
          options={state?.strengthUom_masters?.data}
          id="volume"
          handleChange={handleInputChange}
          data={data?.volume}
          disabled={false}
          type={null}
          error={
            isErr && !data?.volume?.quantity && !data?.volume?.unit?.value
              ? true
              : false
          }
        />
      </Col>
      {/* volume END */}
      {/* additive details START */}
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        inLineStyles={styles.colPadding}
      >
        <AdditiveDetails
          additiveDetails={data?.additiveDetails}
          title="Additive Details"
          action="+ Add New"
          optionsList={{
            description: [],
            quantityUnitsList: [],
          }}
          actionButton={(data) => handleInputChange("additiveDetails", data)}
          styles={styles}
        />
      </Col>
      {/* additive details END */}
      {/* infustion rate START */}
      <Col
        xs={12}
        sm={12}
        md={4}
        lg={3}
        xl={3}
        inLineStyles={styles.colPadding}
      >
        <Text inLineStyles={styles.label}>
          Infustion Rate
          {/* {<span style={styles.required}>*</span>} */}
        </Text>
        <InputGroup
          title="Infustion Rate"
          // isRequired={true}
          options={state?.InfusionRateUoM_masters?.data}
          id="infusionRate"
          handleChange={handleInputChange}
          data={data?.infusionRate}
          disabled={false}
          type={null}
        />
      </Col>
      {/* infusion rate END */}
      {/* Duration START */}
      <Col
        xs={12}
        sm={12}
        md={4}
        lg={3}
        xl={3}
        inLineStyles={styles.colPadding}
      >
        <Text inLineStyles={styles.label}>
          Duration{<span style={styles.required}>*</span>}
        </Text>
        <InputGroup
          title="Duration"
          isRequired={true}
          options={state?.DurationType_masters?.data}
          id="duration"
          handleChange={handleInputChange}
          data={data?.duration}
          disabled={false}
          type={null}
          error={
            isErr && !data?.duration?.quantity && !data?.duration?.unit?.value
              ? true
              : false
          }
        />
      </Col>
      {/* Duration END */}
      {/* Total Volume START */}
      <Col
        xs={12}
        sm={12}
        md={4}
        lg={3}
        xl={3}
        inLineStyles={styles.colPadding}
      >
        <Text inLineStyles={styles.label}>
          Total Volume
          {/* {<span style={styles.required}>*</span>} */}
        </Text>
        <InputGroup
          title="Total Volume"
          // isRequired={true}
          options={state?.strengthVolumnUom_masters?.data}
          id="totalVolume"
          handleChange={handleInputChange}
          data={data?.totalVolume}
          disabled={false}
          type={null}
        />
      </Col>
      {/* Total Volume */}
      {/* Prn Start */}
      <Col
        xs={12}
        sm={12}
        md={4}
        lg={3}
        xl={3}
        inLineStyles={styles.colPadding}
      >
        <div>
          <Text inLineStyles={styles.label} style={{ marginLeft: 10 }}>
            PRN
            {/* {<span style={styles.required}>*</span>} */}
          </Text>
        </div>
        <div>
          <Text
            onClick={(e) => {
              if (!data?.prn) {
                setAnchorEl(e?.currentTarget);
              }
            }}
          >
            <Switch
              checked={data?.prn}
              onChange={(event) => {
                handleInputChange("prn", !data?.prn);
                setReason(data?.prnCommnts);
              }}
              className={classes.switch}
            />
          </Text>
          {data?.prnCommnts && data?.prn && (
            <Image
              onClick={(e) => {
                setReason(data.prnCommnts);
                setAnchorEl(e?.currentTarget);
              }}
              style={{ width: "20px", height: "20px", marginLeft: "33px" }}
              src={"images/icons8-file.svg"}
              alt="file"
            />
          )}
          <Popover
            id={"calender-popover"}
            open={anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            classes={{ paper: styles.muiPopovers }}
          >
            <div style={{ width: "305px", padding: "15px 8px" }}>
              <div>
                <H6
                  style={{
                    float: "left",
                    fontSize: "16px",
                    margin: "0px 0px 0px 4px",
                  }}
                >
                  Add Remarks
                </H6>
                <Button
                  inLineStyles={styles_.filledBtn}
                  onClick={() => {
                    handleInputChange("prnCommnts", reason);
                    setReason("");
                    setAnchorEl(null);
                  }}
                >
                  Save
                </Button>
                <Button
                  inLineStyles={styles_.cancelBtn}
                  onClick={() => setAnchorEl(null)}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <TextInput
                  label={"Reason"}
                  placeholder="Add Reason"
                  labelStyle={styles.labelStyle_}
                  style={{
                    ...styles.borderStyle,
                    height: "86px",
                    overflow: "auto",
                  }}
                  hoverColor={UIColor.lineBorderFill.color}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
            </div>
          </Popover>
        </div>
      </Col>
      {/* prn END */}
    </>
  );
};

export default IVForm;
