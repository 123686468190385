import moment from "moment";

export const generateTapparing = (stateList, tappering) => {
    let arr = []
    tappering.map(val => {
        arr.push([
            {
                value: val.adminFrDay,
                type: 'quantity',
                typeName: "fromday"
            },
            {
                value: val.adminToDay,
                type: 'quantity',
                typeName: "today"
            },
            {
                value: moment.unix(val.adminFrDate).format("DD-MM-YYYY,h:mm a"),
                type: 'datetime',
                typeName: "startday"
            },
            {
                value: moment.unix(val.adminToDate).format("DD-MM-YYYY,h:mm a"),
                type: 'datetime',
                typeName: "endday"
            },

            {
                value: val.adminDosageFreqCode ? stateList.FrequencyMaster_masters?.data.filter(value => value.value === val.adminDosageFreqCode)[0] : '',
                type: 'select',
                optionsName: 'schedule',
                typeName: "schedule"
            },
            {
                value: val.adminDosageStrength,
                type: 'quantity',
                valueType: 'quantity',
                typeName: "dose",
                unit: val.adminDosageStrengthUOM ? stateList.DosageUOM_masters?.data.filter(value => value.value === val.adminDosageStrengthUOM)[0] : ""
            }
        ])
    });
    return arr;
}

export const generateDose = (stateList, doseData) => {
    let arr = []
    if (doseData && doseData.length > 0) {
        doseData.map((val, i) => {
            arr.push([
                {
                    value: i === 0 ? 'Thursday' : 'Friday',
                    type: 'label'
                },
                {
                    value: val.adminDosageFreqCode ? stateList.FrequencyMaster_masters?.data.filter(value => value.value === val.adminDosageFreqCode)[0] : null,
                    type: 'select',
                    optionsName: 'schedule'
                },
                {
                    value: val.adminDosageQty,
                    type: 'quantity',
                    valueType: 'quantity',
                    typeName: "dose",
                    unit: val.adminDosageUOM ? stateList.DosageUOM_masters?.data.filter(value => value.value === val.adminDosageUOM)[0] : ""
                }
            ])
        });
    } else {
        arr = [
            [
                {
                    value: 'Thursday',
                    type: 'label'
                },
                {
                    value: null,
                    type: 'select',
                    optionsName: 'schedule'
                },
                {
                    value: null,
                    type: 'quantity',
                    valueType: 'quantity',
                    typeName: "dose",
                    unit: null
                }
            ],
            [
                {
                    value: 'Friday',
                    type: 'label'
                },
                {
                    value: null,
                    type: 'select',
                    optionsName: 'schedule'
                },
                {
                    value: null,
                    type: 'quantity',
                    valueType: 'quantity',
                    typeName: "dose",
                    unit: null
                }
            ]
        ]
    }
    return arr;
}

export const TwiceDaily = (stateList, doseData) => {
    let arr = []
    if (doseData && doseData.length > 0) {
        doseData.map((val, i) => {

            arr.push([
                {
                    value: val.adminTime,
                    type: 'time'
                },
                {
                    value: val.adminDosageQty,
                    type: 'quantity',
                    valueType: 'quantity',
                    typeName: "dose",
                    unit: val.adminDosageUOM ? stateList.DosageUOM_masters?.data.filter(value => value.value === val.adminDosageUOM)[0] : ""
                }
            ])
        });
    } else {
        arr = [
            [
                {
                    value: "07:30",
                    type: 'time'
                },
                {
                    value: null,
                    type: 'quantity',
                    valueType: 'quantity',
                    typeName: "dose",
                    unit: null
                }
            ],
            [
                {
                    value: "07:30",
                    type: 'time'
                },
                {
                    value: null,
                    type: 'quantity',
                    valueType: 'quantity',
                    typeName: "dose",
                    unit: null
                }
            ],
        ]
    }
    return arr;
}


export const getErrorValidation = (state) => {
    let iserror = false;
    if (state.formType === "IV") {
        if (!state.ivForm.baseSolution || !state.ivForm.route) {
            iserror = true
        }
        if (!state.ivForm.volume.unit || !state.ivForm.volume.quantity) {
            iserror = true
        }
        if (!state.ivForm.duration.unit || !state.ivForm.duration.quantity) {
            iserror = true
        }
    } else {
        if (!state.unitDose.route) {
            iserror = true
        }
        if (!state.unitDose.dosage.min.unit || !state.unitDose.dosage.min.quantity) {
            iserror = true
        }
    }

    if (!state.frequency) {
        iserror = true
    }
    if (!state.frequencyDuration.quantity || !state.frequencyDuration.unit) {
        iserror = true
    }
    if (!state.startDate || (!state.selectDiagnosis || state.selectDiagnosis.length === 0) || !state.indication) {
        iserror = true
    }
    if (!state.priority || !state.drugCategory || !state.nature || !state.mode || !state.dispensingPharmacy) {
        iserror = true
    }

    return iserror
}