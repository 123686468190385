import React from "react";

export const Lmp = (props) => {
  let width = props.width ? props.width : "13";
  let height = props.height ? props.height : "15";
  //   let color = props.color ? props.color : "#000000";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20.12 20.121"
    >
      <path
        id="icons8-lmp"
        d="M18.091,3a5.014,5.014,0,0,0-3.557,1.474c-1.314,1.314-2.312,4.395-3.989,6.072s-4.758,2.676-6.072,3.989a5.03,5.03,0,0,0,7.113,7.113c1.314-1.313,2.312-4.395,3.989-6.072s4.758-2.676,6.072-3.989A5.03,5.03,0,0,0,18.091,3Zm-6.656.843a3.106,3.106,0,0,0-2.152.888c-.912.912-.473,1.351-1.836,2.718s-1.813.924-2.725,1.842a3.341,3.341,0,0,0-.688,3.429,20.077,20.077,0,0,1,2.328-1.4,13.728,13.728,0,0,0,3-1.957,13.759,13.759,0,0,0,1.957-3,20.3,20.3,0,0,1,1.4-2.33A3.77,3.77,0,0,0,11.435,3.843Zm4.141,2.51a.838.838,0,1,1-.838.838A.839.839,0,0,1,15.576,6.354Zm3.354,0a.838.838,0,1,1-.838.838A.839.839,0,0,1,18.929,6.354ZM15.576,9.707a.838.838,0,1,1-.838.838A.839.839,0,0,1,15.576,9.707Zm3.354,0a.838.838,0,1,1-.838.838A.839.839,0,0,1,18.929,9.707Zm-5.869,2.515a.838.838,0,1,1-.838.838A.839.839,0,0,1,13.061,12.222ZM22.088,13.4a20.3,20.3,0,0,1-2.33,1.4,13.759,13.759,0,0,0-3,1.957,13.728,13.728,0,0,0-1.957,3,20.077,20.077,0,0,1-1.4,2.328A3.341,3.341,0,0,0,16.83,21.4c.918-.912.475-1.357,1.842-2.725s1.806-.923,2.718-1.836A3.362,3.362,0,0,0,22.088,13.4Zm-14.9,1.338a.838.838,0,1,1-.838.838A.839.839,0,0,1,7.192,14.737Zm3.354,0a.838.838,0,1,1-.838.838A.839.839,0,0,1,10.546,14.737ZM7.192,18.091a.838.838,0,1,1-.838.838A.839.839,0,0,1,7.192,18.091Zm3.354,0a.838.838,0,1,1-.838.838A.839.839,0,0,1,10.546,18.091Z"
        transform="translate(-3.001 -3)"
        fill="#14cae2"
      />
    </svg>
  );
};
