import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { StyledAvatar } from "../commonComponents";
import { Dot } from "../";
import { makeNameForPract } from "../../../../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: 9,
  },
  personTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
  },
  personId: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  ternaryText: {
    fontSize: 12,
  },
}));

const getText = (value = "", filler = "-") =>
  value?.toString() ? value : filler;

const getPractitionerData = (telecomData = []) => {
  const email = telecomData?.filter(
    (item) => item?.system?.code === "email"
  )?.[0];
  const phone = telecomData?.filter(
    (item) => item?.system?.code === "phone"
  )?.[0];

  return [
    email?.value ? email?.value : "NA",
    phone?.value
      ? `${phone?.valueprefix ? `${phone?.valueprefix} ` : ""}${phone?.value}`
      : "NA",
  ]?.filter((x) => x);
};

function BasicDetail({ data }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <StyledAvatar>{data?.name?.[0]?.text?.[0]}</StyledAvatar>

      <div className={classes.personTextContainer}>
        <Typography className={classes.name}>
          {makeNameForPract(data?.name?.[0])}
        </Typography>
        <div className={classes.personId}>
          <Typography className={classes.ternaryText}>
            {getText(data?.practitioner_id)}
          </Typography>
          {getPractitionerData(data?.telecom).map((item) => (
            <>
              <Dot />
              <Typography className={classes.ternaryText}>
                {getText(item)}
              </Typography>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BasicDetail;
