import { UIColor } from "../../themes/theme"
export const styles = {
    flexRow:{
        display:'flex',
        alignItems:'center',
    },
    flexCenterJustify:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
    },
    title:{
        fontSize:14,
        color: UIColor.primaryText.color,
        margin:0
    },
    deleteButton:{
        color: UIColor.error.color,
        fontSize:14,
        fontWeight: 'bold',
        marginRight:10,
        cursor:'pointer'
    },
    save:{
        color: UIColor.primaryColor.color,
        fontSize:14,
        fontWeight: 'bold',
        cursor:'pointer'
    },
    span:{
        color: UIColor.tertiaryText.color,
        fontSize:12
    },
    star:{
        color: UIColor.error.color,
        marginLeft: "5px"
    },
    startsOn: {
        color: "#101010",
        padding: "5px",
        borderRadius: "4px",
        border: `1px solid ${UIColor.lineBorderFill.color}`,
        display: "flex",
        alignItems: "center"
      },
      occurence:{
          border: `1px solid ${UIColor.lineBorderFill.color}`,
          borderRadius:4,
          display:'flex',
          alignItems:'center',
          minWidth:100,
          justifyContent:'space-around',
          padding:5
      },
      divider:{
          fontSize:12,
          color: UIColor.secondaryText.color,

      },
      endsAfter:{
          boxShadow:'0px 3px 16px #00000014',
          border: `1px solid ${UIColor.lineBorderFill.color}`,
          backgroundColor: UIColor.greyBackground.color,
          padding:10,
      },
      popoverOccurence:{
        border:`1px solid ${UIColor.lineBorderFill.color}`,
        borderRadius:4,
        display:'flex',
        alignItems:'center',
        width:105,
        justifyContent:'space-around',
        padding:5,
        backgroundColor:'#fff',

    },
}

export const qdmstyles = {
    "aaf_c__aba_bdc": {
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "backgroundColor": "",
        "padding": "20px"
    },
    "PTki": {
        "fontSize": "10px",
        "color": "#101010",
        "fontWeight": "600",
        "letterSpacing": "1px"
    },

}
