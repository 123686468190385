import React, { useEffect, useState } from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Div, Text } from "qdm-component-library";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import { Selection } from "../../../../components/select";
import { UIColor } from "../../../../themes/theme";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useStyles } from "./style";

function AppointmentReason(props) {
  const {
    handleChange,
    options,
    title,
    label,
    img,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    expanded: true,
    values: [],
    allMasters: [],
    appointmentReason: [],
  });

  const handleExpandClick = () => {
    setState({
      ...state,
      expanded: !state.expanded,
    });
  };

  const getallmasters = async () => {
    const data = await dispatch(
      actions.MASTER_SEARCH({
        type: [
          "Level Of Care",
          "Department",
          "VISITTYPE",
          "Occupation",
          "NAMEPREFIX",
          "NAMESUFFIX",
          "Patient Type",
          "Race",
          "Ethnicity",
          "Religion",
          "RELATIONSHIP",
          "MARITALSTATUS",
          "LANGUAGE",
          "Town",
          "District",
          "State",
          "Country",
          "ALTERNATEIDTYPE",
          "Blood Group",
          "SPECIALTY",
          "Document Type",
          "Reason",
        ],
      })
    );
    setState({
      ...state,
      allMasters: data?.payload?.data,
    });
  };

  useEffect(() => {
    if (props?.masterSearch) {
      getallmasters();
    }
  }, []);

  const handleChip = (event, newValue) => {
    setState({
      ...state,
      appointmentReason: newValue,
    });
  };

  const handleDeleteChip = (chipToDelete) => () => {
    const data = state.appointmentReason.filter(
      (item) => item?.value !== chipToDelete?.value
    );
    setState({
      ...state,
      appointmentReason: data,
    });
  };

  const { parent_id } = props;
  return (
    <Div
      id="doctor_listing_ReasonforAppointment_div"
      style={{ marginBottom: "8px" }}
    >
      <Card
        id={`${parent_id}_collapse_parent_card`}
        style={{
          boxShadow: "none",
          border: "1.5px solid #E0E0E0",
          borderRadius: 8,
        }}
      >
        <CardActions
          id={`${parent_id}_collapse_parent_cardactions`}
          onClick={handleExpandClick}
          disableSpacing
          style={{
            padding: 0,
            paddingLeft: 16,
            paddingBottom: 0,
            cursor: "pointer",
          }}
        >
          <img
            id={`${parent_id}_collapse_Reasonforappointment_img`}
            alt="Reasonforappointment"
            src={props?.img}
            style={{ marginRight: 12 }}
          />
          <Text
            id={`${parent_id}_collapse_Reason_for_appointment_text`}
            name={props?.title}
            style={{ fontSize: "16px", color: "#001C3C", letterSpacing: 0 }}
            className={"pc_semibold"}
          />

          <IconButton
            id={`${parent_id}_collapse_show_more_iconbutton`}
            className={clsx(classes.expand, {
              [classes.expandOpen]: state.expanded,
            })}
            aria-expanded={state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon id={`${parent_id}_collapse_ExpandMoreIcon`} />
          </IconButton>
        </CardActions>
        <Collapse
          id={`${parent_id}_collapse_parent_collapse`}
          className={classes.body}
          in={state.expanded}
          timeout="auto"
          unmountOnExit
        >
          <>
          <Autocomplete
              id={`${parent_id}_collapse_body_autocomplete`}
              size="small"
              multiple
              options={options || []}
              getOptionLabel={(option) => option.title}
              value={state.appointmentReason}
              onChange={handleChip}
              renderTags={() => null}  
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={props.label ?? ""}
                  style={{
                    backgroundColor: "#F1F6FC",
                    color: "#6A7888",
                    fontSize: 14,
                    border: "1px solid #F1F6FC",
                  }}
                  className={"pc_regular"}
                />
                )}
              style={{
                borderColor: "#F1F6FC",
                border: "none",
                fontSize: 14,
                marginBottom: 8,
              }}
              className={"pc_regular"}
            />
            {state.appointmentReason.map((option, index) => (
              <Chip
                key={`${parent_id}_chip_${index}`}
                label={option.title}
                onDelete={handleDeleteChip(option)}
                color="primary"
                variant="outlined"
                style={{
                  marginRight: 6,
                  marginTop: 6,
                  color: "#01205C",
                  border: "none",
                  backgroundColor: "#DEE5EC",
                  borderRadius: 8,
                }}
                />
                 ))}
          </>
        </Collapse>
      </Card>
    </Div>
  );
}

export default AppointmentReason;
