import { AppRoutes } from "../router/routes";

const patientRegisterTitle = "Register Patient";

const patientRegister = [
  {
    id: 1,
    link: AppRoutes.assemblePatioentRegister,
    label: "Patient Details",
  },
  {
    id: 2,
    link: AppRoutes.assembleNextOfKin,
    label: "Next of Kin Details",
  },
  {
    id: 3,
    link: AppRoutes.assemblePatioentRegister,
    label: "Additional Details",
  },
];

const patientVisitTitle = "Register Visit";
const patientModifyTitle = "Revise Visit";
const patientCancelTitle = "Cancel Visit";
const patientReferralTitle = "Referral Registration";

const patientVisit = [
  {
    id: 1,
    link: AppRoutes.patientVisitDetails,
    label: "Visit Details",
  },
];

const patientReferral = [
  {
    id: 2,
    link: AppRoutes.patientReferral,
    label: "Referral",
  },
];

const financialTitle = "Financial Registration";

const referralRegisterTitle = "Referral In Registration";


const collectionTitle = "Collection";

export {
  patientRegister,
  patientVisit,
  patientRegisterTitle,
  patientVisitTitle,
  patientModifyTitle,
  patientCancelTitle,
  financialTitle,
  collectionTitle,
  patientReferralTitle,
  patientReferral,
  referralRegisterTitle,
};
