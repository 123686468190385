import * as React from "react";
import PropTypes from "prop-types";

const TaskIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.8" height="12" viewBox="0 0 10.8 12">
  <path id="icons8-task" d="M12.15,2.05H9.64a1.789,1.789,0,0,0-3.379,0H3.75a1.2,1.2,0,0,0-1.2,1.2v8.4a1.2,1.2,0,0,0,1.2,1.2h8.4a1.2,1.2,0,0,0,1.2-1.2V3.25A1.2,1.2,0,0,0,12.15,2.05Zm-4.2,0a.6.6,0,1,1-.6.6A.6.6,0,0,1,7.95,2.05Zm-1.8,8.4H4.95V9.25L8.638,5.562l1.2,1.2Zm4.712-4.712-.6.6-1.2-1.2.6-.6a.3.3,0,0,1,.424,0l.776.776A.3.3,0,0,1,10.862,5.738ZM12.15,11.65H3.75V3.25h8.4Z" transform="translate(-2.55 -0.85)" 
  fill={props?.color}/>
</svg>
);

export default TaskIcon;

TaskIcon.defaultProps = {
  color: "black",
};

TaskIcon.propTypes = {
  color: PropTypes.string,
};