let styles = {
  outlinedButton: {
    borderColor: "#0071F2",
    color: "#0071F2",
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: "8px 15px",
    minWidth: 100,
    fontFamily: "pc_medium !important",
    fontSize: 14,
  },
  containedButton: {
    backgroundColor: "#0071F2",
    borderColor: "#0071F2",
    borderRadius: 8,
    padding: "8px 15px",
    minWidth: 100,
    fontFamily: "pc_medium !important",
    fontSize: 14,
  },
};

export default styles;
