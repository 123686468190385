import React, { useState } from "react";
import {
	Div,
	H6,
	Icons,
	Text,
	Select,
	Row,
	Col,
	Button,
	Image,
} from "qdm-component-library";
import { TextField, makeStyles, Popover } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import  actions  from "../../redux/actions";
import { AlertProps } from "../../utils";
import { UIColor } from "../../themes/theme";
import { LabOrder_Json_Generator } from "../../utils/consultJsonGenerate";
import  withAllContexts  from "../../hoc/withAllContexts";
import icons8trash from "../../assets/img/svg/icons8-trash_.svg";
import icons8edit from "../../assets/img/svg/icons8-edit_.svg";
const useStyles = makeStyles({
	autocomplete: {
		"& .MuiInputBase-formControl": {
			height: 40,
			backgroundColor: UIColor.lineBorderFill.color,
			borderRadius: 8,
		},
		"& input": {
			fontFamily: "pc_regular",
			fontSize: 14,
			color: UIColor.secondaryText.color,
			"&::placeholder": {
				fontSize: 14,
				fontFamily: "pc_regular !important",
			},
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: 0,
		},
	},
	// muiPopovers: {
	// 	boxShadow: "0px 4px 24px #00000029",
	// 	borderRadius: 8,
	// 	padding: 10,
	// },
	muiPopovers_: {
		boxShadow: "0px 4px 24px #00000029",
		border: `1px solid ${UIColor?.lineBorderFill?.color}`,
		borderRadius: 8,
		backgroundColor: "#fff",
		minWidth: 240,
		overflow: "visible",
	  },
});

const PerformerDetails = (props) => {
	const classes = useStyles(props);
	const dispatch = useDispatch();
	const LabOrderApiSlice = useSelector((state) => state.LabOrderApiSlice);
	const rulesvalidatedData = useSelector((state) => state.validateRulesSlice);
	let radmaster = useSelector((states) => states?.RadiologySlice);
	const storeData = useSelector((state) => state?.TreatmentSlice);
	const styles = {
		flexCenterJustify: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},
		title: {
			color: UIColor.primaryText.color,
			fontSize: 12,
			margin: 0,
		},
		wrapper: {
			border: `1px solid ${UIColor.lineBorderFill.color}`,
			borderRadius: 8,
			padding: 10,
			backgroundColor: UIColor.greyBackground.color,
		},
		actionText: {
			fontSize: 12,
			color: UIColor.secondaryButtonColor.color,
			margin: 0,
			cursor: "pointer",
		},
		filledBtn: {
			fontSize: 12,
			color: UIColor.primaryColor.color,
			margin: 0,
			cursor: "pointer",
			backgroundColor: UIColor.primaryColor.color,
			borderColor: UIColor.primaryColor.color,
			borderRadius: 8,
			color: "#fff",
			padding: "4px 10px"
		},
		cancelBtn: {
			fontSize: 12,
			margin: 0,
			cursor: "pointer",
			marginRight: "15px",
			padding: "8px",
			borderRadius: 8,
			border: `1px solid ${UIColor.primaryColor.color}`,
			color: UIColor.primaryColor.color,
			padding: "4px 10px"
		},
		specimenText: {
			color: UIColor.tertiaryText.color,
			fontSize: 10,
		},
		span: {
			color: UIColor.secondaryText.color,
			fontSize: 10,
		},
		errorBorder: {
			borderRadius: 8,
			borderColor: UIColor.error.color,
		},
		h6: {
			fontSize: 12,
			margin: 0,
			ccolor: UIColor.tertiaryText.color,
		},
		flexRow: {
			display: "flex",
			alignItems: "center",
		},
		icon: {
			cursor: "pointer",
			marginRight: 15,
		},
		labelStyle: {
			color: UIColor.tertiaryText.color,
			fontSize: "12px",
			marginBottom: "6px",
			fontFamily: `pc_regular !important`,
		},
		borderStyle: {
			borderRadius: 8,
			borderColor: UIColor.lineBorderFill.color,
		},
		colPadding: {
			padding: "7px",
		},
		flexEnd: {
			display: "flex",
			justifyContent: "flex-end",
			alignItems: "center",
		},
		cancelBtn_: {
			padding: "4px 10px",
			fontSize: 14,
			marginRight: 10,
			borderColor: "#DEDEDE",
			borderRadius: 8,
		},
		filledBtn_: {
			backgroundColor: "#0271F2",
			borderColor: "#0271F2",
			padding: "4px 10px",
			fontSize: 14,
			borderRadius: 8,
		},
		showDetails: {
			border: `1px solid ${UIColor.lineBorderFill.color}`,
			borderRadius: 8,
			padding: "10px 0px",
			backgroundColor: "#fff",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			marginTop: 10,
		},
		indexStyle: {
			backgroundColor: UIColor.lineBorderFill.color,
			padding: 10,
			borderRadius: "0px 8px 8px 0px",
			fontSize: 12,
			position: "relative",
			left: "-1px",
			minHeight: 30,
		},
		star: {
			color: UIColor.error.color,
			fontSize: 12,
			marginLeft: "5px",
		},
	};

	// {
	//     organization: props?.performerDetails?.organization,
	//     practitionerRole: props?.performerDetails?.practitionerRole,
	//     performedBy: props?.performerDetails?.performedBy,
	//     practitionerId: props?.performerDetails?.practitionerId,
	//     location: props?.performerDetails?.location,
	//     locationCode: props?.performerDetails?.locationCode,
	//     status: props?.performerDetails?.status,
	// }
	const practioner_role_options = useSelector((state) => state.LabOrderApiSlice?.practioner_role?.data || []);
	const [state, setState] = useState({
		location: {},
		locationCode: "",
		organization: {},
		performedBy: {},
		practitionerId: props.fetchUser?.practioner_id,
		practitionerRole: {},
		status: {},
	});
	const [stateData, setstateData] = useState({
		data: [],
		index: null,
	});


	React.useEffect(() => {
		
		setstateData({
			...stateData,
			data: props.performerDetails,
		});
		
		if(props.performerDetails?.[0]?.organization?.value)
		{
			getData(props?.performerDetails?.[0])
		}
	}, [props?.performerDetails]);


	const getData = async (performerDetails) => {
		if(performerDetails?.organization?.value)
		{
			await dispatch(
				actions.ORDER_TYPE_REQ_DETAILS_ROLE({
					id: performerDetails?.organization?.label,
				})
			);
		}

		if(performerDetails?.practitionerRole?.value)
		{
			await dispatch(
				actions.ORDER_TYPE_REQ_DETAILS_LAB({
					role_id: performerDetails?.practitionerRole?._id,
					orgId: performerDetails?.organization?.label,
				})
			);
		}
	}
	const onChangeState = async (key, value) => {
		if (key === "organization" && value?.label && value?.label !== state?.organization?.id) {
			await dispatch(
				actions.ORDER_TYPE_REQ_DETAILS_ROLE({
					id: value?.label,
				})
			);
		}
		if (key === "practitionerRole") {
			await dispatch(
				actions.ORDER_TYPE_REQ_DETAILS_LAB({
					role_id: value?._id,
					orgId: state?.organization?.label,
				})
			);
		}
		if (key === "location") {
			state.locationCode = value.locationID;
		}

		setState({ ...state, [key]: value });
	};

	const [isErr, setIsErr] = useState(false)

	const save = async () => {
		if (
			state?.organization?.label &&
			state?.practitionerRole?.label &&
			state?.performedBy?.label &&
			state?.location?.label &&
			state?.status?.label
		) {
			if (stateData.index >= 0 && stateData.index !== null) {
				stateData.data[stateData.index] = state;
				setstateData({
					...stateData,
				});
				(await props?.actionButton) && props.actionButton(stateData.data);
				handleClose();
				setState({
					location: {},
					locationCode: "",
					organization: {},
					performedBy: {},
					practitionerId: "Patient/10170",
					practitionerRole: {},
					status: {},
				});
				return;
			}
			var a = [];
			a = stateData.data;
			a.push(state);
			setstateData({
				...stateData,
				data: a,
			});

			setState({
				location: {},
				locationCode: "",
				organization: {},
				performedBy: {},
				practitionerId: "Patient/10170",
				practitionerRole: {},
				status: {},
			});
			(await props?.actionButton) && props.actionButton(stateData.data);
			setIsErr(false)
			handleClose();
		} else {
			props.alert.setSnack({
				open: true,
				severity: AlertProps.severity.error,
				// msg: `Please fill the ${err[Object.keys(state?.error)?.[0]]} !`,
				msg: "Please fill all the mandatory fields",
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
			setIsErr(true)
		}
	};

	const cancel = () => {
		props?.actionButton && props.actionButton(props?.performerDetails);
		handleClose();
		stateData.index = null;
		setstateData({ ...stateData });
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	let fetchUserOrgId = props?.fetchUser?.org_id ?? null
	React.useEffect(() => {
		if (fetchUserOrgId && open) {
			let data = LabOrder_Json_Generator(
				props.labDetails,
				{},
				{},
				{}
			);
			data[0].Oltype = props.Oltype;
			dispatch(actions.FETCHPERFORMINGLOCATION({ reqData: data[0], orgId: fetchUserOrgId }))
		}
	}, [open])
	const handleClick = (event, v, i) => {
		if (i >= 0) {
			stateData.index = i;
			setState({ ...v });
			setstateData({ ...stateData });
		} else {
			setState({
				location: {},
				locationCode: "",
				organization: {},
				performedBy: {},
				practitionerId: "Patient/10170",
				practitionerRole: {},
				status: {},
			});
		}
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		stateData.index = null;
		setstateData({ ...stateData });
		setAnchorEl(null);
	};

	const ondelete = (i) => {
		stateData.data.splice(i, 1);
		setstateData({ ...stateData });
	};
console.log("state" , state)

	const id = open ? "simple-popover" : undefined;
	const { parent_id } = props;
	return (
		
		<Div id={`${parent_id}_performerdetails_parent_div`} style={styles.wrapper}>
			<Div
				id={`${parent_id}_performerdetails_parent_sub_div`}
				style={{ width: "100%" }}
			>
				<Div
					id={`${parent_id}_performerdetails_title_div`}
					style={styles.flexCenterJustify}
				>
					<H6
						id={`${parent_id}_performerdetails_${props?.title?.replaceAll(
							" ",
							"_"
						)}_h6`}
						className="pc_regular"
						style={styles.title}
					>
						{props?.title}
					</H6>
					{
						<H6
							id={`${parent_id}_performerdetails_${props?.action?.replaceAll(
								" ",
								"_"
							)}_h6`}
							className="pc_regular"
							style={styles.actionText}
							onClick={(e) => handleClick(e)}
						>
							{props?.action}
						</H6>
					}
				</Div>
				{stateData?.data?.length > 0
					? stateData?.data?.map((v, i) => {
						
						return (
							<Div
								id={`${parent_id}_performerdetails_${i}_staus_div`}
								style={styles.showDetails}
							>
								<Div
									id={`${parent_id}_performerdetails_${i}_number_div`}
									style={styles.indexStyle}
								>
									{i + 1}
								</Div>
								<Div
									id={`${parent_id}_performerdetails_${i}_title_div`}
									style={{ flex: 1, marginLeft: 15 }}
								>
									{v?.status?.value && (
										
										<H6
											id={`${parent_id}_performerdetails_${i}_${v?.status?.label?.replaceAll(
												" ",
												"_"
											)}_h6`}
											style={styles.h6}
											className="pc_medium"
										>
											{v?.status?.value}
										</H6>
									)}
									<Text id={`${parent_id}_performerdetails_${i}_parent_text`}>
										{v?.performedBy?.label && (
											<Text
												id={`${parent_id}_performerdetails_${i}_${v?.performedBy?.label?.replaceAll(
													" ",
													"_"
												)}_text`}
												style={styles.span}
												className="pc_regular"
											>
												{v?.performedBy?.label}
											</Text>
										)}
										{v?.practitionerRole?.label && (
											<Text
												id={`${parent_id}_performerdetails_${i}_${v?.practitionerRole?.label?.replaceAll(
													" ",
													"_"
												)}_text`}
												style={styles.span}
												className="pc_regular"
											>
												&nbsp;•&nbsp;{v?.practitionerRole?.label}
											</Text>
										)}
										{v?.location?.value && (
											<Text
												id={`${parent_id}_performerdetails_${i}_${v?.location?.label?.replaceAll(
													" ",
													"_"
												)}_text`}
												style={styles.span}
												className="pc_regular"
											>
												&nbsp;•&nbsp;{v?.location?.value}
											</Text>
										)}
										{v?.locationCode && (
											<Text
												id={`${parent_id}_performerdetails_${i}_${v?.locationCode?.replaceAll(
													" ",
													"_"
												)}_text`}
												style={styles.span}
												className="pc_regular"
											>
												&nbsp;•&nbsp;{v?.locationCode}
											</Text>
										)}
									</Text>
								</Div>
								<Div
									id={`${parent_id}_performerdetails_${i}_delete_edit_div`}
									style={styles.flexRow}
								>
									<Image
										id={`${parent_id}_performerdetails_${i}_edit_image`}
										onClick={(e) => handleClick(e, v, i)}
										style={styles.icon}
										src={icons8edit}
										alt="edit"
									/>
									<Image
										id={`${parent_id}_performerdetails_${i}_delete_image`}
										onClick={() => ondelete(i)}
										style={styles.icon}
										src={icons8trash}
										alt="delete"
									/>
								</Div>
							</Div>
						);
					})
					: ""}
			</Div>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				classes={{ paper: classes.muiPopovers_ }}
			>
				<Row id={`${parent_id}_performerdetails_parent_row`}>
					<Col
						id={`${parent_id}_performerdetails_organization_col`}
						xs={12}
						sm={12}
						md={3}
						lg={3}
						xl={3}
						inLineStyles={styles.colPadding}
					>
						<Select
							id={`${parent_id}_performerdetails_organization_select`}
							showArrow
							label={"Organization"}
							isRequired
							placeholder={"Select Organization"}
							labelStyle={styles.labelStyle}
							inLineStyles={isErr && !state?.organization?.label ? styles.errorBorder : styles.borderStyle}
							hoverColor={UIColor.lineBorderFill.color}
							value={state?.organization?.value}
							onChangeValue={(e) => onChangeState("organization", e)}
							options={props?.organizationOption?.org ?? []}
						/>
					</Col>
					<Col
						id={`${parent_id}_performerdetails_practitionerRole_col`}
						xs={12}
						sm={12}
						md={3}
						lg={3}
						xl={3}
						inLineStyles={styles.colPadding}
					>
						<Select
							id={`${parent_id}_performerdetails_practitionerRole_select`}
							showArrow
							label={"Practitioner Role"}
							isRequired
							placeholder={"Select Practitioner Role"}
							labelStyle={styles.labelStyle}
							inLineStyles={isErr && !state?.practitionerRole?.label ? styles.errorBorder : styles.borderStyle}
							hoverColor={UIColor.lineBorderFill.color}
							value={state?.practitionerRole?.value}
							onChangeValue={(e) => onChangeState("practitionerRole", e)}
							options={practioner_role_options ?? []}
						/>
					</Col>
					<Col
						id={`${parent_id}_performerdetails_performedBy_col`}
						xs={12}
						sm={12}
						md={3}
						lg={3}
						xl={3}
						inLineStyles={styles.colPadding}
					>
						<Select
							id={`${parent_id}_performerdetails_performedBy_select`}
							showArrow
							label={"Performed By"}
							isRequired
							placeholder={"Select Performed By"}
							labelStyle={styles.labelStyle}
							inLineStyles={isErr && !state?.performedBy?.label ? styles.errorBorder : styles.borderStyle}
							hoverColor={UIColor.lineBorderFill.color}
							getOptionLabel="label"
							value={state?.performedBy?.label}
							onChangeValue={(e) => onChangeState("performedBy", e)}
							// options={props?.organizationOption?.orderby ?? []}
							options={
								LabOrderApiSlice?.order_type_req_details?.data?.length > 0 ?
									LabOrderApiSlice?.order_type_req_details?.data
									: []
							}
						/>
					</Col>
					<Col
						id={`${parent_id}_performerdetails_Practitioner_ID_col`}
						xs={12}
						sm={12}
						md={3}
						lg={3}
						xl={3}
						inLineStyles={styles.colPadding}
					>
						<Div
							id={`${parent_id}_performerdetails_Practitioner_ID_div`}
							style={styles.labelStyle}
							className="pc_regular"
						>
							Practitioner ID
							<Text
								id={`${parent_id}_performerdetails_Practitioner_ID_star_text`}
								style={styles.star}
							>
								*
							</Text>
						</Div>
						<TextField
							id={`${parent_id}_performerdetails_Practitioner_ID_textfield`}
							className={classes.autocomplete}
							value={state?.performedBy?.practitioner_id}
							onChange={(e) => onChangeState("practitionerId", e.target.value)}
							// placeholder={"Start Date"}
							InputProps={{
								classes: { input: classes.input },
							}}
							style={{
								border: isErr && !state?.performedBy?.practitioner_id ? `1px solid ${UIColor.error.color}` : 0,
								borderRadius: isErr && !state?.performedBy?.practitioner_id ? "8px" : 0
							}}
							variant="outlined"
							disabled={true}
						/>
					</Col>
					<Col
						id={`${parent_id}_performerdetails_Location_col`}
						xs={12}
						sm={12}
						md={3}
						lg={3}
						xl={3}
						inLineStyles={styles.colPadding}
					>
						<Select
							id={`${parent_id}_performerdetails_Location_select`}
							showArrow
							label={"Location"}
							isRequired
							placeholder={"Select Location"}
							labelStyle={styles.labelStyle}
							inLineStyles={isErr && !state?.location?.label ? styles.errorBorder : styles.borderStyle}
							hoverColor={UIColor.lineBorderFill.color}
							value={state?.location?.value}
							onChangeValue={(e) => onChangeState("location", e)}
							options={LabOrderApiSlice?.get_location?.data ?? []}
						/>
					</Col>
					<Col
						id={`${parent_id}_performerdetails_Locationcode_col`}
						xs={12}
						sm={12}
						md={3}
						lg={3}
						xl={3}
						inLineStyles={styles.colPadding}
					>
						<Div
							id={`${parent_id}_performerdetails_Locationcode_div`}
							style={styles.labelStyle}
							className="pc_regular"
						>
							Location Code
							<Text
								id={`${parent_id}_performerdetails_Locationcode_star_text`}
								style={styles.star}
							>
								*
							</Text>
						</Div>
						<TextField
							id={`${parent_id}_performerdetails_Locationcode_textfield`}
							className={classes.autocomplete}
							value={state?.locationCode}
							onChange={(e) => onChangeState("locationCode", e.target.value)}
							// placeholder={"Start Date"}
							InputProps={{
								classes: { input: classes.input },
							}}
							style={{
								border: isErr && !state?.locationCode ? `1px solid ${UIColor.error.color}` : 0,
								borderRadius: isErr && !state?.locationCode ? "8px" : 0
							}}
							variant="outlined"
							disabled={true}
						/>
					</Col>
					<Col
						id={`${parent_id}_performerdetails_status_col`}
						xs={12}
						sm={12}
						md={3}
						lg={3}
						xl={3}
						inLineStyles={styles.colPadding}
					>
						<Select
							id={`${parent_id}_performerdetails_status_select`}
							showArrow
							label={"Status"}
							isRequired
							placeholder={"Select Status"}
							labelStyle={styles.labelStyle}
							inLineStyles={isErr && !state?.status?.label ? styles.errorBorder : styles.borderStyle}
							hoverColor={UIColor.lineBorderFill.color}
							value={state?.status?.value}
							onChangeValue={(e) => onChangeState("status", e)}
							options={props?.Status ?? []}
						/>
					</Col>
				</Row>
				<Row id={`${parent_id}_performerdetails_buttons_row`}>
					<Col
						id={`${parent_id}_performerdetails_buttons_col`}
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						style={{ margin: "10px 0px" }}
					>
						<Div
							id={`${parent_id}_performerdetails_buttons_div`}
							inLineStyles={styles.flexEnd}
						>
							<Button
								id={`${parent_id}_performerdetails_Cancel_button`}
								onClick={() => cancel()}
								variant={"text"}
								inLineStyles={styles.cancelBtn}
							>
								Cancel
							</Button>
							<Button
								id={`${parent_id}_performerdetails_Save_button`}
								variant={"outlined"}
								inLineStyles={styles.filledBtn}
								onClick={() => save()}
							>
								Save
							</Button>
						</Div>
					</Col>
				</Row>
			</Popover>
		</Div>
	);
};

PerformerDetails.defaultProps = {
	title: "",
	action: "",
	actionButton: null,
	performerDetails: {},
	onChangeState: null,
	organizationOption: [],
};

export default withAllContexts(PerformerDetails);