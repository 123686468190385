import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../themes/theme";

const useStyles = makeStyles((theme) => ({
  labelStyle: {
    color: UIColor.primaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
    marginBottom: 10,
  },
}));

export default useStyles;
