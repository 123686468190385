import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
  Divider,
  Paper
} from "@material-ui/core";
import { UIColor } from "../../../themes/theme";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import { AlertContext } from "../../../context";
import { AlertProps, __tenantid__, getFacilityId } from "../../../utils";
import actions from "../../../redux/actions";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  textfield:{
        '& .MuiInputBase-root': { // Target the root input element
                color: UIColor.secondaryText.color,
                borderRadius: 8,
              
            },
         '& .MuiInputBase-input': { // Target the input field itself
            //  border: `1px solid ${UIColor.lineBorderFill.color} !important`,
             borderRadius: 8,
             
         },
  },
  autocomplete: {
    "& .MuiInputBase-input": {
      // color: UIColor.secondaryText.color,
      // fontSize: "12px !important",
      // fontFamily: "pc_regular!important",
      borderRadius: 8,
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
      borderRadius: 8,
    },
    // "& .MuiOutlinedInput-root": {
    //   "& fieldset": {
    //     border: `1px solid ${UIColor.lineBorderFill.color} !important`,
    //     borderRadius: 8,
    //   },
    //   "&:hover fieldset": {
    //     border: `1px solid ${UIColor.lineBorderFill.color} !important`,
    //   },
    //   "&.Mui-focused fieldset": {
    //     borderWidth: 1,
    //     border: `1px solid ${UIColor.lineBorderFill.color} !important`,
    //   },
    // },
    // "& .MuiAutocomplete-popupIndicator": {
    //   transform: "none !important",
    // },
  },
  datePicker: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 8,
      },
    },
  },
}));

const ReferalRegOutForm = (props) => {
  const {
    handlecancel,
    edit,
  } = props;
  const dispatch = useDispatch();
  const [errorsField, setErrorsFields] = React.useState(false);
  const [EnableReasonTxt,setEnableReasonTxt] = useState(false);
  const today = new Date().toISOString().split('T')[0];
  const [refRegOutVal, setrefRegOutVal] = useState(  {
    _id:"",
    referralinfoid:"",
    RefLetterNo:"",
    RefDate:0,
    RefToFacility:{},
    RefFromFacility:{},
    RefFromDoctor:{},
    RefToDoctor:"",
    Esrf:0,
    OtherMedicComorbid:"",
    MedicHistory:"",
    TargetDryWeight:"",
    CurrentVascularAccess:"",
    ViralStatus:{},
    ViralStatusDate:0,
    HbsAg:{},
    Anti_HCV:{},
    Anti_HIV:{},
    RefReason:[],
    SymptomSince:0,
    SymptomDate:0,
    RefFromContactNum:0,
    User:{} ,
    ReasonTxt:"",
  });


  const alert = useContext(AlertContext);
  const classes = useStyles();
  const loadDefaultVal = async ()=>{
    try {
      const userInfo = await localStorage.getItem("UserInfo");
      const decodedUserinfo = await jwtDecode(userInfo);
      const practitionerid = decodedUserinfo?.practitionerid;
      const facilityId = decodedUserinfo?.facilityid
      let esrfDate =0;
      let ReferalDate ="";
      const stateValues = {};
      let payload = {
        screenid: process.env.REACT_APP_REFERAL_REGISTRATION_OUT_SCREEN_ID,
        tenantid: __tenantid__,
        filtervalues:{
            patientid : props?.patientId,
            tenantid : "",
            facilityid : "",
            practitionerid : practitionerid,
            orgid : facilityId
        }
      }
      let token = localStorage.getItem("JwtToken");
        let register = await axios({
          method: "POST",
          url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
          headers: {
            "Content-Type": "application/json",
            jwtToken: `${token}`,
          },
          data: JSON.stringify(payload),
        }).then((res) => {
          if (res?.data?.response?.screenId === process.env.REACT_APP_REFERAL_REGISTRATION_OUT_SCREEN_ID) {
            const defaultValues = res?.data?.response?.defaultValues;
            const stateNames = [
              "date",
              "other_medical",
              "targer_dry_weight",
              "refer_from_contact_no",
              "esrf_since",
              "refer_from_facility",
              "referer_user",
              "refer_from_doctor",
              "current_vascular_access",
              "medical_history"
            ]
            defaultValues.forEach((s) => {
              if (stateNames.includes(s.stateName)) {
                stateValues[s.stateName] = s.value;
              }
            });
            esrfDate =stateValues?.esrf_since ? new Date(stateValues?.esrf_since * 1000).toISOString().split('T')[0] : "";
            ReferalDate =stateValues?.date ? new Date(stateValues?.date * 1000) : "";//stateValues?.date ? new Date(stateValues?.date * 1000).toISOString().split('T')[0] : "";
          }})
          await  setrefRegOutVal(prevState => ({
            ...prevState,
            // RefLetterNo:"",
            RefDate:ReferalDate,//stateValues?.date,
            // RefToFacility:{},
            RefFromFacility:stateValues?.refer_from_facility,
            RefFromDoctor:stateValues?.refer_from_doctor,
            // RefToDoctor:"",
            Esrf:esrfDate,//stateValues?.esrf_since,
            OtherMedicComorbid:stateValues?.other_medical,
            MedicHistory:stateValues?.medical_history,
            TargetDryWeight:stateValues?.targer_dry_weight,
            CurrentVascularAccess:stateValues?.current_vascular_access,
            // ViralStatus:{},
            // ViralStatusDate:0,
            // HbsAg:{},
            // Anti_HCV:{},
            // Anti_HIV:{},
            // RefReason:[],
            // SymptomSince:0,
            // SymptomDate:0,
            RefFromContactNum:stateValues?.refer_from_contact_no,
            User:stateValues?.referer_user 
          }));
    } catch (e){
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
    }
  }
  useEffect(() => {
    if (edit) {
      props?.ReadRefData?.RefReason?.map((v,i)=>{  
        if(v?._id === process.env.REACT_APP_REASON_FOR_REFERAL_OTHERS_VALUE){
          setEnableReasonTxt(true)
        }
      })
      setrefRegOutVal(props?.ReadRefData)
    }else{
      loadDefaultVal()
    }
  }, [edit]);
  const handlesaved = async (statekey, value) => {
    try {
      let esrfDate =  Math.floor(new Date(refRegOutVal?.Esrf).getTime() / 1000)
      let ReferalDate = Math.floor(new Date(refRegOutVal?.RefDate).getTime() / 1000)
      let viralStatusDate = Math.floor(new Date(refRegOutVal?.ViralStatusDate).getTime() / 1000)
      let SympDate = Math.floor(new Date(refRegOutVal?.SymptomDate).getTime() / 1000)
      const userInfo = localStorage.getItem("UserInfo");
      const decodedUserinfo = jwtDecode(userInfo);
      const facilityId = getFacilityId();
      if(!refRegOutVal?.RefDate ||
        !refRegOutVal?.RefFromFacility ||
        !refRegOutVal?.RefToFacility ||
        !refRegOutVal?.Esrf ||
        !refRegOutVal?.OtherMedicComorbid ||
        !refRegOutVal?.MedicHistory ||
        !refRegOutVal?.TargetDryWeight ||
        !refRegOutVal?.CurrentVascularAccess ||
        !refRegOutVal?.ViralStatus ||
        !refRegOutVal?.RefReason ||
        !refRegOutVal?.SymptomSince ||
        !refRegOutVal?.SymptomDate ||
        !refRegOutVal?.RefFromContactNum ||
        !refRegOutVal?.User
      ) {
        setErrorsFields(true);
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill the mandatory field",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return false;
      }
    //   else if (refRegOutVal?.RefFromContactNum.length < 10) {
    //     setErrorsFields(true);
    //     alert.setSnack({
    //       open: true,
    //       severity: AlertProps.severity.error,
    //       msg: "contact cannot be less than 10",
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.right,
    //     });
    //   return false;
    // }  
      else {
        let RefReasonList = []
        refRegOutVal?.RefReason?.map((v,i)=>{
          let obj = {
            reasonid:v?._id,
            reasontxt:v?._id === process.env.REACT_APP_REASON_FOR_REFERAL_OTHERS_VALUE ? refRegOutVal?.ReasonTxt : "",
          }
          RefReasonList.push(obj);
        })
        let doc = {
          _id: refRegOutVal?._id,
          referralinfoid: refRegOutVal?.referralinfoid,
          patientId: props?.patientid,
          RequestorEncounter:props?.encounterId,
          refstatus: "CodingMaster/11452",
          tenantid: "1234",
          facilityid: facilityId,
          refferaltype: "CodingMaster/12693",
          RefSourceLetRef: refRegOutVal?.RefLetterNo ?? "",
          RequestorLocationCode: refRegOutVal?.RefFromFacility?._id ?? "",
          RefSourceLetDate: ReferalDate,
          RequestorPractioner: refRegOutVal?.RefFromDoctor?._id ?? "",
          receiptfacility: refRegOutVal?.RefToFacility?._id ?? "",
          recipientPract: refRegOutVal?.RefToDoctor ?? "",
          esrfhemostart: esrfDate,
          othermedhistory: refRegOutVal?.OtherMedicComorbid ?? "",
          medicationhistory: refRegOutVal?.MedicHistory ?? "",
          targetdryweight: refRegOutVal?.TargetDryWeight ?? "",
          curvasaccess: refRegOutVal?.CurrentVascularAccess ?? "",
          viralstatus:refRegOutVal?.ViralStatus?._id ?? "",
          viraldate: viralStatusDate,
          hbsag: refRegOutVal?.HbsAg?._id ?? "",
          antihcv: refRegOutVal?.Anti_HCV?._id ?? "",
          antihivl2: refRegOutVal?.Anti_HIV?._id ?? "",
          symptomdays: refRegOutVal?.SymptomSince ?? 0,
          symptomdate: SympDate,
          reason: RefReasonList ?? [],
          refercontactinfo: refRegOutVal?.RefFromContactNum ?? "",
          referuser: refRegOutVal?.User._id ?? ""
        };
        const response = await dispatch(actions.Referal_Reg_Out_Save({ doc: doc }));
        if(response?.payload?.data?.error === false){
          props?.referalRegOutRead();
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: edit ? "Referral Registration Out Saved Successfully" : "Referral Registration Out Updated Successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        } else {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something went Wrong!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        }
        props?.setOpen(false);
      }
    } catch(e){
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: e.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
    }
  };
  const handlechange = async (key,value) => {
    try {
      let isallow = false;
      if(key==="RefReason"){
        if(value?.length > 0) {
          value?.map((v,i)=>{
            if(v?._id === process.env.REACT_APP_REASON_FOR_REFERAL_OTHERS_VALUE){
              setEnableReasonTxt(true)
              isallow = true;
            } else if (isallow === false){
              setEnableReasonTxt(false)
            }
          })
        } else {
          setEnableReasonTxt(false); 
        }
        if (refRegOutVal?.RefReason?.length > 0) {
          const seen = new Set();
          const filteredArr = value.filter((el) => {
            const duplicate = seen.has(el.ReasonDesc);
            seen.add(el.ReasonDesc);
            return !duplicate;
          });
          // filteredArr.map((v)=>{
          //   v?._id === process.env.REACT_APP_REASON_FOR_REFERAL_OTHERS_VALUE ? setEnableReasonTxt(true) : setEnableReasonTxt(false); 
          // })
          await setrefRegOutVal(prevState => ({
            ...prevState,
            [key]: filteredArr   
          }));
          return;
        }
      } else if (key==="SymptomSince"){
          let currentDate = new Date();
          currentDate.setDate(currentDate.getDate("yyyy-MM-dd") - value);
          let formattedDate = currentDate.toISOString().split('T')[0];//currentDate.toLocaleDateString('en-GB');
          setrefRegOutVal(prevState => ({
            ...prevState,
            "SymptomDate": formattedDate, //.replace(/\//g, "-"),
            [key]: value 
          }));
          return;
      } else if (key==="SymptomDate"){
        let oldDate = new Date(value);
        let currentDate = new Date();
        let differenceInMilliseconds = currentDate - oldDate;
        let differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        differenceInDays = differenceInDays < 0 ? 0 : differenceInDays
        setrefRegOutVal(prevState => ({
          ...prevState,
          "SymptomSince": differenceInDays, //.replace(/\//g, "-"),
          [key]: value 
        }));
        return;

      } else if (key==="RefDate"){ 
        setrefRegOutVal(prevState => ({
          ...prevState,
          [key]: value.$d.toISOString(),
        }));
        return;

      }
        setrefRegOutVal(prevState => ({
          ...prevState,
          [key]: value   
        }));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
    <Grid container justifyContent="space-between" alignItems="center" style={{padding:"5px 25px"}}>
      <Grid item>
      <Typography component="h2" variant="h6" style={{fontWeight:"600",color:"#01205C"}} >Referral Registration Out</Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={handlecancel}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
    <Grid>
      <Divider variant="fullWidth" />
    </Grid>
    <Grid container direction="column" >
      {/* Referral Letter */}
      <Grid item style={{marginLeft:"24px", marginTop:"20px", marginBottom:"20px"}}>
          <Grid style={{margin:"5px 0 20px 0"}}><Typography variant="button" style={{fontWeight:600}} gutterBottom>Referral Letter</Typography></Grid>
          <Grid container  spacing={2} >
            <Grid item>
              <TextField  className={classes.textfield} style={{height:"10px"}} size="small" variant="outlined"
                onChange={(e) => {
                  let pattern = /^[a-zA-Z0-9\s]+$/i;
                  let result = pattern.test(e.target.value);
                  if (result || e.target.value === "") {
                    handlechange("RefLetterNo",e.target.value);
                  }
                }}
                label={"Referral Letter No "}
                value={refRegOutVal?.RefLetterNo}
                //disabled={edit && refRegOutVal?.RefLetterNo? true : false}
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker  label={"Referral Date "}
                // value={cashierdatas?.enddate ? dayjs(cashierdatas?.enddate) : cashierdatas?.enddate || null}
                // onChange={(e) => setData("enddate", e)}
                onChange={(e) => {
                  handlechange("RefDate",e);
                }}
                value={refRegOutVal?.RefDate ? dayjs(refRegOutVal?.RefDate) : refRegOutVal?.RefDate || null}
                size="small"
                fullWidth
                className={classes.datePicker}
                slotProps={{
                  textField:{
                    // helperText: "errorMessage",
                    error: !refRegOutVal?.RefDate && errorsField ? true : false,
                    size: 'small',
                    required: true,
                }}}
              />

              </LocalizationProvider>
              {/* <TextField className={!refRegOutVal?.RefDate && errorsField ? classes.textfielderr : classes.textfield}  style={{ width: 200 }} size="small" variant="outlined" type="date"
                onChange={(e) => {
                  handlechange("RefDate",e.target.value);
                }}
                value={refRegOutVal?.RefDate}
                label={"Referal Date "}
                InputLabelProps={{ shrink: true }}
              /> */}
            </Grid>
          </Grid>
      </Grid>
      {/* Referral From */}
      <Grid item style={{marginLeft:"24px", marginBottom:"20px"}}>
          <Grid style={{margin:"5px 0 20px 0"}}><Typography variant="button" style={{fontWeight:600}} gutterBottom>Referral From</Typography></Grid>
          <Grid container  spacing={2} >
            <Grid item>
              <Autocomplete
                id="combo-box-demo"
                options={props?.dropdownval?.getRefFacilityList}
                size={"small"}
                getOptionLabel={(option) => option.value}
                style={{ width: 225 }}
                className={classes.autocomplete}
                value={refRegOutVal?.RefFromFacility}
                renderInput={(params) => <TextField required={true} error={!refRegOutVal?.RefFromFacility?.value && errorsField ? true : false} {...params} label="Referral From Facility " variant="outlined" />}
                onChange={(e, newValue) => {
                  handlechange("RefFromFacility",newValue);
                }}
                disabled={edit && refRegOutVal?.RefFromFacility ? true : false}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                id="combo-box-demo"
                options={props?.dropdownval?.getRefDoctorList}
                size={"small"}
                getOptionLabel={(option) => option.value}
                style={{ width: 225 }}
                className={classes.autocomplete}
                renderInput={(params) => <TextField {...params} label="Referral From Doctor " variant="outlined" />}
                onChange={(e, newValue) => {
                  handlechange("RefFromDoctor",newValue);
                }}
                value={refRegOutVal?.RefFromDoctor}
              />
            </Grid>
          </Grid>
      </Grid>
      {/* Referral To */}
      <Grid item style={{marginLeft:"24px", marginBottom:"20px"}}>
          <Grid style={{margin:"5px 0 20px 0"}}><Typography variant="button" style={{fontWeight:600}} gutterBottom>Referral To </Typography></Grid>
          <Grid container  spacing={2} >
            <Grid item>
              <Autocomplete
                id="combo-box-demo"
                options={props?.dropdownval?.getRefToFacilityList}
                size={"small"}
                getOptionLabel={(option) => option.value}
                style={{ width: 225 }}
                onChange={(e, newValue) => {
                  handlechange("RefToFacility",newValue);
                }}
                value={refRegOutVal?.RefToFacility}
                className={classes.autocomplete}
                renderInput={(params) => <TextField required={true} error={!refRegOutVal?.RefToFacility?.value && errorsField ? true : false} {...params} label="Referral To Facility" variant="outlined" />}
                
              />
            </Grid>
            <Grid item>
              <TextField className={classes.textfield} style={{ width: 225 }} size="small" variant="outlined"
                onChange={(e, newValue) => {
                  let pattern = /^[a-zA-Z\s]+$/i;
                  let result = pattern.test(e.target.value);
                  if (result || e.target.value === "") {
                    handlechange("RefToDoctor",e.target.value);
                  }
                }}
                value={refRegOutVal?.RefToDoctor}
                label={" Referral To Doctor"}
              />
            </Grid>
          </Grid>
      </Grid>
      {/*Medical History*/}
      <Grid item style={{marginLeft:"24px", marginBottom:"20px"}}>
       <Grid style={{margin:"12px 0"}}><Typography variant="button" style={{fontWeight:600}} gutterBottom>MEDICAL HISTORY</Typography></Grid>
          <Grid container  spacing={2} >
            <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker  label={"ESRF and on hemodialysis since "}
                // value={cashierdatas?.enddate ? dayjs(cashierdatas?.enddate) : cashierdatas?.enddate || null}
                // onChange={(e) => setData("enddate", e)}
                onChange={(e) => {
                  handlechange("Esrf",e);
                }}
                value={refRegOutVal?.Esrf ? dayjs(refRegOutVal?.Esrf) : refRegOutVal?.Esrf || null}
                size="small"
                fullWidth
                onError={true}
                className={classes.datePicker}
                slotProps={{
                  textField:{
                    // helperText: "errorMessage",
                    error: !refRegOutVal?.Esrf && errorsField ? true : false,
                    size: 'small',
                    required: true,
                }}}
              />

              </LocalizationProvider>
              {/* <TextField className={!refRegOutVal?.Esrf && errorsField ? classes.textfielderr : classes.textfield} style={{ width: 200 }} size="small" variant="outlined" type="date"
                onChange={(e, newValue) => {
                  handlechange("Esrf",e.target.value);
                }}
                value={refRegOutVal?.Esrf}
                disabled={edit && refRegOutVal?.Esrf ? true : false}
                label={"ESRF and on hemodialysis since"}
              /> */}
            </Grid>
            <Grid item>
              <TextField className={classes.textfield} style={{ width: 225 }} size="small" variant="outlined"
                onChange={(e, newValue) => {
                  let pattern = /^[a-zA-Z0-9\s]+$/i;
                  let result = pattern.test(e.target.value);
                  if (result || e.target.value === "") {
                    handlechange("OtherMedicComorbid",e.target.value);
                  }
                }}
                value={refRegOutVal?.OtherMedicComorbid}
                label={" Other Medical Comorbid: "}
                error={!refRegOutVal?.OtherMedicComorbid && errorsField ? true : false}
                required={true}
              />
            </Grid>
            <Grid item>
              <TextField className={classes.textfield} style={{ width: 225 }} size="small" variant="outlined"
                onChange={(e, newValue) => {
                  let pattern = /^[a-zA-Z0-9\s]+$/i;
                  let result = pattern.test(e.target.value);
                  if (result || e.target.value === "") {
                    handlechange("MedicHistory",e.target.value);
                  }
                }}
                value={refRegOutVal?.MedicHistory}
                label={"Medication History"}
                required={true}
                error={!refRegOutVal?.MedicHistory && errorsField ? true : false}
              />
            </Grid>
            <Grid item>
              <TextField className={classes.textfield} style={{ width: 225 }} size="small" variant="outlined"
                onChange={(e, newValue) => {
                  let pattern = /^[0-9\s]+$/i;
                  let result = pattern.test(e.target.value);
                  if (result || e.target.value === "") {
                    handlechange("TargetDryWeight",parseInt(e.target.value));
                  }
                }}
                value={refRegOutVal?.TargetDryWeight}
                disabled={edit && refRegOutVal?.TargetDryWeight?true:false}
                label={"Target Dry Weight in Kg "}
                required={true}
                error={!refRegOutVal?.TargetDryWeight && errorsField ? true : false}
              />
            </Grid>
            <Grid item>
              <TextField className={classes.textfield} style={{ width: 225 }} size="small" variant="outlined"
                onChange={(e, newValue) => {
                  let pattern = /^[a-zA-Z0-9\s]+$/i;
                  let result = pattern.test(e.target.value);
                  if (result || e.target.value === "") {
                    handlechange("CurrentVascularAccess",e.target.value);
                  }
                }}
                value={refRegOutVal?.CurrentVascularAccess}
                disabled={edit && refRegOutVal?.CurrentVascularAccess?true:false}
                label={"Current Vascular Access "}
                required={true}
                error={!refRegOutVal?.CurrentVascularAccess && errorsField ? true : false}
              />
            </Grid>
            <Grid item>
            <Autocomplete
                id="combo-box-demo"
                options={props?.dropdownval?.getViralStatusList}
                size={"small"}
                getOptionLabel={(option) => option.display || option.value}
                style={{ width: 225 }}
                className={classes.autocomplete}
                renderInput={(params) => <TextField {...params} required={true} error={!refRegOutVal?.ViralStatus?.value && errorsField ? true : false} label="Viral Status" variant="outlined" />}
                onChange={(e, newValue) => {
                  handlechange("ViralStatus",newValue);
                }}
                value={refRegOutVal?.ViralStatus}
              />
            </Grid>
            <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker  label={"Viral Status Date "}
                // value={cashierdatas?.enddate ? dayjs(cashierdatas?.enddate) : cashierdatas?.enddate || null}
                // onChange={(e) => setData("enddate", e)}
                onChange={(e) => {
                  handlechange("ViralStatusDate",e);
                }}
                value={refRegOutVal?.ViralStatusDate ? dayjs(refRegOutVal?.ViralStatusDate) : refRegOutVal?.ViralStatusDate || null}
                size="small"
                fullWidth
                className={classes.datePicker}
                slotProps={{
                  textField:{
                    // helperText: "errorMessage",
                    error: !refRegOutVal?.ViralStatusDate && errorsField ? true : false,
                    size: 'small',
                    required: true,
               
                }}}
              />

              </LocalizationProvider>
                {/* <Typography  variant="body1" color="textSecondary" gutterBottom style={{display:"flex", gap:"3px"}}> Viral Status Date: <Typography color="error">*</Typography></Typography>
                  <TextField className={!refRegOutVal?.ViralStatusDate && errorsField ? classes.textfielderr : classes.textfield}  style={{ width: 200 }} size="small" variant="outlined" type="date"
                    onChange={(e, newValue) => {
                      handlechange("ViralStatusDate",e.target.value);
                    }}
                    value={refRegOutVal?.ViralStatusDate}
                  /> */}
                </Grid>
            {refRegOutVal?.ViralStatus?._id === process.env.REACT_APP_VIRAL_STATUS_POSITIVE && (
              <>
                <Grid item>
                <Autocomplete
                    id="combo-box-demo"
                    options={props?.dropdownval?.getViralStatusChildList}
                    size={"small"}
                    getOptionLabel={(option) => option.display || option.value}
                    style={{ width: 225 }}
                    className={classes.autocomplete}
                    renderInput={(params) => <TextField {...params} label="HbsAg " variant="outlined" />}
                    onChange={(e, newValue) => {
                      handlechange("HbsAg",newValue);
                    }}
                    value={refRegOutVal?.HbsAg}
                    label={""}
                  />
                </Grid>
                <Grid item>
                <Autocomplete
                    id="combo-box-demo"
                    options={props?.dropdownval?.getViralStatusChildList}
                    size={"small"}
                    getOptionLabel={(option) => option.display || option.value}
                    style={{ width: 225 }}
                    className={classes.autocomplete}
                    renderInput={(params) => <TextField {...params} label="Anti-HCV" variant="outlined" />}
                    onChange={(e, newValue) => {
                      handlechange("Anti_HCV",newValue);
                    }}
                    value={refRegOutVal?.Anti_HCV}
                    label={""}
                  />
                </Grid>
                <Grid item>
                <Autocomplete
                    id="combo-box-demo"
                    options={props?.dropdownval?.getViralStatusChildList}
                    size={"small"}
                    getOptionLabel={(option) => option.display || option.value}
                    style={{ width: 225 }}
                    className={classes.autocomplete}
                    renderInput={(params) => <TextField {...params} label="Anti-HlV L& 2" variant="outlined" />}
                    onChange={(e, newValue) => {
                      handlechange("Anti_HIV",newValue);
                    }}
                    value={refRegOutVal?.Anti_HIV}
                    label={""}
                  />
                </Grid>
              </>
            )}
          </Grid>
      </Grid>
      {/*Reason*/}
      <Grid item style={{marginLeft:"24px", marginBottom:"20px"}}>
        <Grid style={{margin:"12px 0"}}><Typography variant="button" style={{fontWeight:600}} gutterBottom>REASON</Typography></Grid>
          <Grid container  spacing={2} >
            <Grid item>
              <Autocomplete
                    id={`_OtherHealthCondition_autocomplete`}
                    multiple
                    fullWidth
                    options={props?.dropdownval?.getReasonForReferalList}
                    style={{
                      marginLeft: "3px",
                      width:"410px",
                    }}
                    className={classes.autocomplete}
                    size="small"
                    getOptionLabel={(option) => option.ReasonDesc ||option.value }
                    value={refRegOutVal?.RefReason}
                    label={"Reason For Referral "}
                    // onChange={(e, v) => setData("Other_Health_Condition", v)}
                    // className={
                    //   // props?.isError &&
                    //   // (referralRegistrationdatas?.Other_Health_Condition?.length ===
                    //   //   0 ||
                    //   //   referralRegistrationdatas?.Other_Health_Condition ===
                    //   //     undefined)
                    //   //   ? classes.autocompleteBorder
                    //   //   : classes.chips
                    // }
                    hoverColor="#0071F2"
                    renderInput={(params) => (
                      <TextField
                        id={`_communication_select_label_textfield`}
                        style={{ borderRadius: "8px" }}
                        {...params}
                        variant="outlined"
                        placeholder="select..."
                        size="small"
                        label ={"Reason For Referral "}
                        required={true}
                        error={refRegOutVal?.RefReason?.length === 0 && errorsField ? true : false}
                        // required={true}
                      />
                    )}
                    onChange={(e, newValue) => {
                      handlechange("RefReason",newValue);
                    }}
                  />
            </Grid>
            {EnableReasonTxt && (
              <Grid item>
                <TextField className={classes.textfield} style={{ width: 225 }}  size="small" variant="outlined" type="text"
                  onChange={(e, newValue) => {
                    let pattern = /^[a-zA-Z0-9\s]+$/i;
                    let result = pattern.test(e.target.value);
                    if (result) {
                      handlechange("ReasonTxt",e.target.value);
                    } else if (e.target.value === ""){
                      handlechange("ReasonTxt","");
                    }
                  }}
                  value={refRegOutVal?.ReasonTxt}
                  label={"Reason Text"}
                  required={true}
                  error={!refRegOutVal?.ReasonTxt && errorsField ? true : false}
                />
              </Grid>   
            )}
          </Grid>
      </Grid>
      {/*Symptoms*/}
      <Grid item style={{marginLeft:"24px", marginBottom:"20px"}}>
        <Grid style={{margin:"12px 0"}}><Typography variant="button" style={{fontWeight:600}} gutterBottom>Symptoms</Typography></Grid>
          <Grid container  spacing={2} >
            <Grid item>
              <TextField className={classes.textfield} style={{ width: 225 }}  size="small" variant="outlined" type="text"
                onChange={(e, newValue) => {
                  let pattern = /^[a-z0-9\s]+$/i;
                  let result = pattern.test(e.target.value);
                  if (result) {
                    handlechange("SymptomSince",parseInt(e.target.value));
                  } else if (e.target.value === ""){
                    handlechange("SymptomSince",parseInt(0));
                  }
                }}
                label={"Symptom since (In Days)"}
                value={refRegOutVal?.SymptomSince}
                required={true}
                error={!refRegOutVal?.SymptomSince && errorsField ? true : false}
              />
            </Grid>
            <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker  label={"Symptom Date "}
                // value={cashierdatas?.enddate ? dayjs(cashierdatas?.enddate) : cashierdatas?.enddate || null}
                // onChange={(e) => setData("enddate", e)}
                onChange={(e) => {
                  handlechange("SymptomDate",e);
                }}
                value={refRegOutVal?.SymptomDate ? dayjs(refRegOutVal?.SymptomDate) : refRegOutVal?.SymptomDate || null}
                size="small"
                fullWidth
     
                className={classes.datePicker}
                slotProps={{
                  textField:{
                    // helperText: "errorMessage",
                    error: !refRegOutVal?.SymptomDate  && errorsField ? true : false,
                    size: 'small',
                    required: true
               
                }}}
                inputProps={{
                  max: today
                }}
              />

              </LocalizationProvider>
              {/* <Typography  variant="body1" color="textSecondary" gutterBottom style={{display:"flex", gap:"3px"}}> Symptom Date <Typography color="error">*</Typography></Typography>
              <TextField className={!refRegOutVal?.SymptomDate && errorsField ? classes.textfielderr : classes.textfield} style={{ width: 200 }}  size="small" variant="outlined" type="date"
                onChange={(e, newValue) => {
                  handlechange("SymptomDate",e.target.value);
                }}
                value={refRegOutVal?.SymptomDate}
                inputProps={{
                  max: today
                }}
              /> */}
            </Grid>
          </Grid>
      </Grid>
      {/*Contact Details*/}
      <Grid item style={{marginLeft:"24px", marginBottom:"20px", marginTop:"20px"}}>
        <Grid style={{margin:""}}><Typography variant="button" style={{fontWeight:600}} gutterBottom>Contact Details</Typography></Grid>
          <Grid container  spacing={2} style={{marginTop:"10px"}}>
            <Grid item> 
              <TextField style={{ width: 250 }}  size="small" variant="outlined" type="text"
                onChange={(e, newValue) => {
                  let pattern = /^[0-9\s]+$/i;
                  let result = pattern.test(e.target.value);
                  if (result || e.target.value === "") {
                   handlechange("RefFromContactNum",e.target.value);
                  }
                }}
                className={classes.textfield}
                value={refRegOutVal?.RefFromContactNum}
                label={"Referring From Contact Number"}
                required={true}
                error={!refRegOutVal?.RefFromContactNum && errorsField ? true : false}
              />
            </Grid>
            <Grid item>
            <Autocomplete
                id="combo-box-demo"
                options={props?.dropdownval?.getUserList}
                size={"small"}
                getOptionLabel={(option) => option.value}
                style={{ width: 225 }}
                className={classes.autocomplete}
                renderInput={(params) => <TextField error={!refRegOutVal?.User?.value && errorsField ? true : false } required={true} {...params} label="User" variant="outlined" />}
                onChange={(e, newValue) => {
                  handlechange("User",newValue);
                }}
                value={refRegOutVal?.User}
                label={"User"}
              />
            </Grid>
          </Grid>

      </Grid>
    </Grid>
    <Grid container justifyContent="flex-end" style={{padding:"10px 30px",gap:"20px"}}>
      <Grid item>
          <Button
            onClick={handlecancel}
            color="primary"
            fullWidth
          >
            cancel
          </Button>{" "}
      </Grid>
      <Grid item>
          <Button
            variant="contained"
            onClick={() => {
              handlesaved();
            }}
            color="primary"
            fullWidth
          >
            {edit ? "UPDATE" : "SAVE"}
          </Button>
      </Grid>
    </Grid>
      </>
  );
};

export default ReferalRegOutForm;
