import React from "react";

export const PickUpDrop = (props) => {
  let width = props.width ? props.width : "14";
  let height = props.height ? props.height : "13.611";
  let color = props.color ? props.color : "#101010";
  return (
    <svg 
    {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 13.611"
    >
         <defs>
            <style>{`.cls-7{fill:${color};}}`}</style>
        </defs>
      <path xmlns="http://www.w3.org/2000/svg" id="icons8-car_1_" data-name="icons8-car (1)" class="cls-7"  d="M10.019,6A2.533,2.533,0,0,0,7.562,7.934L7.277,9.111H6.583a.583.583,0,1,0,0,1.167H7l-.167.692A1.728,1.728,0,0,0,6,12.417v6.417a.778.778,0,0,0,.778.778h.778a.778.778,0,0,0,.778-.778v-.778h9.333v.778a.778.778,0,0,0,.778.778h.778A.778.778,0,0,0,20,18.833V12.417a1.728,1.728,0,0,0-.829-1.447L19,10.278h.412a.583.583,0,1,0,0-1.167h-.694l-.285-1.177A2.533,2.533,0,0,0,15.98,6Zm0,1.167H15.98A1.356,1.356,0,0,1,17.3,8.208l.594,2.459H8.1L8.7,8.208A1.356,1.356,0,0,1,10.019,7.167ZM7.75,11.833h10.5a.575.575,0,0,1,.583.583v4.472H7.167V12.417A.575.575,0,0,1,7.75,11.833ZM9.111,13a.778.778,0,1,0,.778.778A.778.778,0,0,0,9.111,13Zm7.778,0a.778.778,0,1,0,.778.778A.778.778,0,0,0,16.889,13Zm-5.25,1.556a.583.583,0,1,0,0,1.167h2.722a.583.583,0,1,0,0-1.167Z" transform="translate(-6 -6)"/>
      </svg>
  );
};