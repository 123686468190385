import React from "react";
// import jsPDF from 'jspdf';
// import Success from "../../assets/img/success.png";
import Logo from "../../assets/img/png/AINQA.png";
import { PatientDetails } from "../../screens/confirmBooking/patientDetails";
import { Div, Divider, H6, Image, Row, Col } from "qdm-component-library";
import instaVerificationBadge from "../../assets/img/svg/icons8-instagram-verification-badge.svg";


// const base64 = (url) => {
//    return new Promise((resolve, reject) => {
//       var xhr = new XMLHttpRequest();
//       xhr.onload = function () {
//         var reader = new FileReader();
//         reader.onloadend = function () {
//             resolve(reader.result);
//         };
//         reader.readAsDataURL(xhr.response);
//       };
//       xhr.open("GET", url);
//       xhr.responseType = "blob";
//       xhr.send();
//     })
//   }

export const AppointmentSummaryPDF = React.forwardRef((props, ref) => {
  const styles = {
    appointmentBooking: {
      color: "#38C20A",
      fontSize: 18,
      marginBottom: 10,
      textAlign: "center",
    },
    tickSvg: {
      width: 32,
      height: 32,
      margin: "auto auto 10px",
    },
    confirmation: {
      color: "#6F6F6F",
      fontSize: 12,
      textAlign: "center",
    },
    wrapper: {
      border: "2px dashed rgb(239 239 239)",
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      margin: "30px 0px",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      padding: 10,
      alignItems: "center",
      minWidth: 300,
    },
    flexRow: {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
    },
    barCode: {
      width: 100,
      height: 100,
      border: "2px dashed rgb(239 239 239)",
      padding: 5,
      marginBottom: 10,
      borderRadius: 8,
    },
    h6: {
      color: "#101010",
      fontSize: 14,
      margin: 0,
    },
    span: {
      color: "#6F6F6F",
      fontSize: 12,
      margin: 0,
    },
    divider: {
      margin: "10px",
      opacity: 0.5,
    },
    dot: {
      width: 6,
      height: 6,
      backgroundColor: "#E0E0E0",
      borderRadius: "50%",
      marginRight: 10,
    },
    flexEnd: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    apptSum: {
      color: "#B6B6B6",
      fontSize: 12,
      textAlign: "center",
      margin: 0,
      marginBottom: 15,
    },
    li: {
      flex: 1,
      margin: 0,
      color: "#6F6F6F",
      fontSize: 12,
    },
  };

  const {
    personDetails = {},
    hospitalDetails = {},
    doctorDetails = {},
    appointmentDetails = {},
    generalInstructions = [],
  } = props?.data;

  return (
    <div style={{ display: "none" }} id="pdf">
      <div className="print-container" ref={ref}>
        {appointmentDetails?.length > 0 &&
          appointmentDetails?.map((_, index) => {
            return (
              <>
                <div className="page-break" />
                <Div style={{ padding: 30 }}>
                  <Image
                    src={Logo}
                    alt="logo"
                    style={{ width: 100, height: 35 }}
                  />
                </Div>
                <Divider style={{ margin: 0 }} />
                <Div style={{ padding: 30 }}>
                  <H6 className="pc_medium" style={styles.apptSum}>
                    APPOINTMENT SUMMARY
                  </H6>
                  <Div style={styles.tickSvg}>
                    <Image
                      //src="images/icons8-instagram-verification-badge.svg"
                      src={instaVerificationBadge}
                      alt="tick"
                    />
                  </Div>
                  <H6 className="pc_semibold" style={styles.appointmentBooking}>
                    Your appointment has been booked !
                  </H6>
                  <H6 className="pc_regular" style={styles.confirmation}>
                    Confirmation has been sent to your {personDetails?.phoneNo}
                    <br />
                    {personDetails?.emailId
                      ? `& ${personDetails?.emailId}`
                      : ""}
                  </H6>
                  <Div style={styles.wrapper}>
                    <Div style={styles.flexColumn}>
                      <Div style={styles.barCode}>
                        <Image
                          alt="Image"
                          // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                          src={`https://api.qrserver.com/v1/create-qr-code/?size=170x170&data=${_?.appointmentId}`}
                          //   src={barcodeImg}
                        />
                      </Div>
                      <H6 className="pc_medium" style={styles.h6}>
                        {
                          // `${appointmentDetails?.date}  ${appointmentDetails?.time ? `| ${appointmentDetails?.time}` : ''}`
                          _?.dateTimeConverted
                        }
                      </H6>
                      <H6
                        className="pc_regular"
                        style={{ ...styles.span, margin: "5px 0px" }}
                      >
                        {_?.appointmentId}
                      </H6>
                    </Div>
                    <Div
                      style={{
                        borderLeft: "1px dashed #E0E0E0",
                        flex: 1,
                      }}
                    >
                      <PatientDetails
                        url={doctorDetails?.profile_pic}
                        name={doctorDetails?.doctorName}
                        title={doctorDetails?.speciality}
                        subTitle={doctorDetails?.address}
                      />
                      <Divider style={styles.divider} />
                      <PatientDetails
                        url={personDetails?.profile_pic}
                        name={personDetails?.patientName}
                        title={`${personDetails?.age} ${
                          personDetails?.gender
                            ? `| ${personDetails?.gender}`
                            : ""
                        }`}
                        subTitle={personDetails?.phoneNo}
                      />
                    </Div>
                  </Div>
                  <Divider style={{ margin: "10px 0px" }} />
                  <Div style={{ marginTop: 20 }}>
                    <H6
                      style={{ ...styles.span, marginBottom: 10 }}
                      className="pc_medium"
                    >
                      GENERAL INSTRUCTION:
                    </H6>
                    {////genInstruction.payload.data[0].generalInstruction[0].description
                      generalInstructions.map((g, j) => (
                        <Div key={j} style={styles.flexRow}>
                          <Div style={styles.dot} />
                          <H6 className="pc_regular" style={styles.li}>
                            {g?.description}
                          </H6>
                        </Div>
                      ))
                    }
                  </Div>
                </Div>
                <Divider style={{ margin: "0px" }} />
                <Div style={{ padding: 30 }}>
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <H6 className="pc_semibold" style={styles.h6}>
                        {hospitalDetails?.hospitalName}
                      </H6>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Div style={styles.flexEnd}>
                        <H6 style={{...styles.span, textAlign: "right"}} className="pc_medium">
                          {hospitalDetails?.hospitalAddress?.street}
                        </H6>
                        <H6
                          style={{...styles.span, textAlign: "right"}}
                          className="pc_medium"
                        >
                          {`${hospitalDetails?.hospitalAddress?.city},`}
                          <br />
                          {hospitalDetails?.hospitalAddress?.state}
                          <br />
                          {hospitalDetails?.hospitalAddress?.pincode}
                        </H6>
                        {
                          hospitalDetails?.hospitalAddress?.phoneNo && (
                            <H6
                              style={{...styles.span, textAlign: "right"}}
                              className="pc_medium"
                            >{`Contact: ${hospitalDetails?.hospitalAddress?.phoneNo}`}</H6>
                          )
                        }
                      </Div>
                    </Col>
                  </Row>
                </Div>
                {index < appointmentDetails?.length - 1 && (
                  <div class="html2pdf__page-break"></div>
                )}
              </>
            );
          })}
      </div>
    </div>
  );
});

// export const AppointmentSummaryPDF = async (props) =>{

//     console.log(props);

//     const {
//         personDetails={},
//         hospitalDetails={},
//         doctorDetails={},
//         appointmentDetails={}
//     } = props;

//     // savePdf('https://api.qrserver.com/v1/create-qr-code/?size=170x170&data=1')
//     let logoImg = await base64(Logo);
//     let successImg = await base64(Success);
//     let barcodeImg = 'https://api.qrserver.com/v1/create-qr-code/?size=170x170&data=1';
//     let personImg = await base64(personDetails?.profile_pic);
//     let doctorImg = await base64(doctorDetails?.profile_pic);

//     var doc = new jsPDF({ orientation: "portrait", unit: "mm" });
//     var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
//     var widthCenter = pageWidth/2;

//     doc.addImage(logoImg, 'JPEG', 10, 5, 30, 10);

//     doc.setDrawColor(224,224,224);
//     doc.line(0, 22, 210, 22);

//     doc.setTextColor('#B6B6B6');
//     doc.setFontSize(12);
//     doc.setFont("pc_medium");
//     doc.text("APPOINTMENT SUMMARY", widthCenter, 35, "center");

//     doc.addImage(successImg, 'JPEG', widthCenter-5, 42, 10, 10);

//     doc.setFontSize(20);
//     doc.setTextColor('#38C20A');
//     doc.setFont("pc_semibold");
//     doc.text('Your appointment has been booked !', widthCenter, 65,'center');

//     doc.setFontSize(12);
//     doc.setTextColor('#6F6F6F');
//     doc.setFont("pc_regular");
//     personDetails?.phoneNo && doc.text(`Confirmation has been sent to your ${personDetails?.phoneNo}`, widthCenter, 75,'center');
//     personDetails?.emailId && doc.text(`& ${personDetails?.emailId}`, widthCenter, 82,'center');

//     doc.setDrawColor(224,224,224);
//     doc.rect(20,92,170,87);

//     doc.rect(30,100,50,50);
//     doc.addImage(barcodeImg, 'JPEG', 33, 103, 44, 44);

//     doc.setFontSize(16);
//     doc.setTextColor('#101010');
//     doc.setFont("pc_medium");
//     appointmentDetails?.date && doc.text(`${appointmentDetails?.date}  ${appointmentDetails?.time ? `| ${appointmentDetails?.time}` : ''}`,25,160);

//     doc.setFontSize(14);
//     doc.setTextColor('#6F6F6F');
//     doc.setFont("pc_regular");
//     appointmentDetails?.appointmentId && doc.text(`${appointmentDetails?.appointmentId}`,43,170);

//     doc.line(90, 92, 90, 179);

//     // doc.circle(105,115,8);
//     doc.addImage(doctorImg, 'JPEG', 100, 100, 15, 15);

//     doc.setFontSize(16);
//     doc.setTextColor('#101010');
//     doc.setFont("pc_medium");

//     let doctorSplitText = doc.splitTextToSize(props?.doctorDetails?.doctorName, 60);
//     let doctorSplitTextHeight = doc.getTextDimensions(doctorSplitText)?.h;
//     doctorDetails?.doctorName && doc.text(doctorSplitText,120,105,"left", "middle");
//     // doctorDetails?.doctorName && doc.text(`${doctorDetails?.doctorName}`,120,105);

//     doc.setFontSize(12);
//     doc.setTextColor('#6F6F6F');
//     doc.setFont("pc_regular");
//     let specialityHeight = 105+doctorSplitTextHeight;
//     doctorDetails?.speciality && doc.text(doctorDetails?.speciality,120,specialityHeight);

//     doc.setFontSize(12);
//     doc.setTextColor('#101010');
//     doc.setFont("pc_regular");
//     doctorDetails?.address && doc.text(doctorDetails?.address,120,120);

//     doc.line(95, 128, 185, 128);

//     // doc.circle(105,115,8);
//     doc.addImage(personImg, 'JPEG', 100, 135, 15, 15);

//     doc.setFontSize(16);
//     doc.setTextColor('#101010');
//     doc.setFont("pc_medium");
//
//     personDetails?.patientName && doc.text(`${personDetails?.patientName}`,120,140);

//     doc.setFontSize(12);
//     doc.setTextColor('#6F6F6F');
//     doc.setFont("pc_regular");
//     personDetails?.age && doc.text(`${personDetails?.age} ${personDetails?.gender ? `| ${personDetails?.gender}` : ''}`,120,148);

//     doc.setFontSize(12);
//     doc.setTextColor('#101010');
//     doc.setFont("pc_regular");
//     personDetails?.phoneNo && doc.text(`${personDetails?.phoneNo}`,120,156);

//     doc.line(20, 190, 190, 190);

//     doc.setFontSize(10);
//     doc.setTextColor('#6F6F6F');
//     doc.setFont("pc_medium");
//     doc.text('GENERAL INSTRUCTION:',20,200);

//     doc.setFillColor(224, 224, 224);
//     doc.circle(20,209,1,'F');
//     doc.setFontSize(12);
//     doc.setTextColor('#B6B6B6');
//     doc.setFont("pc_regular");
//     doc.text('Nothing to eat or drink after midnight if your procedure is scheduled between 7am and noon.',25,210);

//     doc.setFillColor(224, 224, 224);
//     doc.circle(20,219,1,'F');
//     doc.setFontSize(12);
//     doc.setTextColor('#B6B6B6');
//     doc.setFont("pc_regular");
//     doc.text('Nothing to eat or drink after 7:00 AM if your procedure is scheduled between noon and 4pm.',25,220);

//     doc.setFillColor(224, 224, 224);
//     doc.circle(20,229,1,'F');
//     doc.setFontSize(12);
//     doc.setTextColor('#B6B6B6');
//     doc.setFont("pc_regular");
//     doc.text('If you have diabetes, gastric reflux, hiatal hernia, or any disease of your stomach, ',25,230);
//     doc.text('DO NOT drink any liquids for 6 hours before you are told to arrive at the clinic.',25,237);

//     doc.line(0, 245, 210, 245);

//     doc.setFontSize(14);
//     doc.setTextColor('#101010');
//     doc.setFont("pc_semibold");
//     hospitalDetails?.hospitalName && doc.text(`${hospitalDetails?.hospitalName}`,20,260,{maxWidth:100});

//     doc.setFontSize(10);
//     doc.setTextColor('#B6B6B6');
//     doc.setFont("pc_regular");
//     hospitalDetails?.hospitalAddress?.street && doc.text(`${hospitalDetails?.hospitalAddress?.street}`,130,260);
//     hospitalDetails?.hospitalAddress?.city && doc.text(`${hospitalDetails?.hospitalAddress?.city},${hospitalDetails?.hospitalAddress?.state} ${hospitalDetails?.hospitalAddress?.pincode}`,130,266);
//     hospitalDetails?.hospitalAddress?.phoneNo && doc.text(`Ph No: ${hospitalDetails?.hospitalAddress?.phoneNo}`,130,272);
//     doc.save("a1.pdf");
// }
