import React, { useState } from "react";
import { Text, Div, Card, Image, H2, SideMenu } from "qdm-component-library";
import classes from "./styles.json";
import { actionButtonClick, GetQueueData } from "../../../utils";
import {
  QueueManagementContext,
  DrawerMeetingContext,
  VisitDetails,
} from "../../../context";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { actions } from "primarycare-binder";
import { AppRoutes } from "../../../router/routes";
import CancelBookingPage from "../../../components/queueCard/cancelBooking";
import { Grid } from "@material-ui/core";
 
const UpNext = (props) => {
  const navigate = useNavigate();
  const drawerMeetingContext = React.useContext(DrawerMeetingContext);
  const { setVisitDetails } = React.useContext(VisitDetails);
  const dispatch = useDispatch();
  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
 
  const {
    allData,
    displayData,
    previousData,
    loader,
    setAllData,
    setDisplayData,
    setPreviousData,
    setloader,
  } = React.useContext(QueueManagementContext);
  const [state, setState] = useState({
    openDialog: null,
    cancelbookingreason: "",
    cancelData: {},
  });
  const { parent_id, markHighPriority } = props;
 
  const attendPatient = async (
    personId,
    patientId,
    appointmentId,
    encounterId,
    type,
    pId,
    encounter_id,
    ticketId,
    app
  ) => {
    let status;
    let isCompleted;
    if (app[0]?.type === "nurse") {
      status = "inprogress_nurse";
      isCompleted = false;
    } else if (app[0]?.type === "doctor") {
      status = "doctor_inprogress";
      isCompleted = false;
    }
 
    // await dispatch(
    //   actions.UPDATE_APPOINTMENT_STATUS({
    //     appointmentId,
    //     status,
    //     isCompleted,
    //   })
    // );
 
    navigate(AppRoutes.patientDetail, {
      state: {
        encounter_id,
        personId,
        patientId,
        appointmentId,
        encounterId,
        type,
        pId,
        app_id: app?.info?._id,
        ticketId,
        allCurrentData: app,
      },
    });
  };
 
  const registerPatient = (
    personId,
    patientId,
    appointmentId,
    isPatient,
    mobileNo,
    action,
    ticketid
  ) => {
    setVisitDetails({
      encounter: {
        mode: "direct",
      },
      practitioner: {},
      device: {},
      helthcare: {},
      accompained: {},
    });
    if (isPatient) {
      navigate(AppRoutes.visitRegistration, {
        state: {
          personId,
          from: 1,
          patientId,
          appointmentId,
          Ispatient: true,
          isedit: false,
          isRevise: false,
          patientData: mobileNo,
          stateAction: action?.action,
          stateEndPoint: action?.apiendpoint,
          stateBackEndCall: action?.backEndCall,
          stateTicketid: ticketid,
        },
      });
    } else {
      navigate(AppRoutes.assemblePatioentRegister, {
        state: {
          nextRoute: AppRoutes.visitRegistration,
          personId,
          patientId,
          from: 1,
          appointmentId,
          patientData: mobileNo,
          Ispatient: false,
          idedit: false,
          fromQueue: true,
          stateAction: action?.action,
          stateEndPoint: action?.apiendpoint,
          stateBackEndCall: action?.backEndCall,
          stateTicketid: ticketid,
        },
      });
    }
  };
 
  const actionButtonClickEvent = async (
    action,
    apiendpoint,
    backEndCall,
    ticketid
  ) => {
    if (action === "CANCEL") {
      setState({
        openDialog: true,
        cancelData: {
          action: action,
          apiEndPoint: apiendpoint,
          backendCall: backEndCall,
          ticketId: ticketid,
        },
      });
    } else {
      setloader(true);
      let fetchVal = await actionButtonClick(
        action,
        apiendpoint,
        backEndCall,
        ticketid
      );
 
      if (!fetchVal.error) {
        let data = await GetQueueData();
        setAllData(data.everyData);
        setDisplayData(data.getDisplayData);
        setPreviousData(data.getPreviousData);
      }
      setloader(false);
    }
  };
  const handlesidebarCancelbooking = (id) => {
    if (id === "closing") {
      setState(() => ({ openDialog: false }));
    }
  };
 
  const handlecahngeCancelbooking = async () => {
    setState(() => ({ openDialog: false }));
 
    let fetchVal = await actionButtonClick(
      state?.cancelData?.action,
      state?.cancelData?.apiEndPoint,
      state?.cancelData?.backendCall,
      state?.cancelData?.ticketId,
      state?.cancelbookingreason?._id
    );
 
    if (!fetchVal.error) {
      setloader(true);
      let data = await GetQueueData();
      setAllData(data.everyData);
      setDisplayData(data.getDisplayData);
      setPreviousData(data.getPreviousData);
      setloader(false);
    }
  };
 
  const handleSelectCancelbooking = (v) => {
    setState((prevState) => ({
      ...prevState,
      cancelbookingreason: v,
    }));
  };
  return (
<Div
      id={`${parent_id}${props.index}_queue_next_parent_div`}
      style={classes.wrapper}
      key={props.index}
>
<Div
        id={`${parent_id}${props.index}_queue_next_parent_sub_div`}
        style={classes.header}
>
<Grid container>
          {props.item?.title && (
<H2
              id={`${parent_id}${props.index}_queue_next_title_h2`}
              className="pc_semibold"
              style={classes.headerTitle}
>
              {props.item?.title}
</H2>
          )}
</Grid>
<Grid container justifyContent="space-around">
<Grid item>
            {props.item?.token && (
<Div
                id={`${parent_id}${props.index}_queue_next_token_number_div`}
                className="pc_medium"
                style={classes.tokenNumber}
>
                {props.item?.token}
</Div>
            )}
</Grid>
 
          <Grid item>
            {props?.item?.totalData?.info?.appno && (
<Div
                id={`${parent_id}${props.index}_queue_next_token_number_div`}
                className="pc_medium"
                style={classes.appNumber}
>
                {props?.item?.totalData?.info?.appno}
</Div>
            )}
</Grid>
</Grid>
</Div>
<Card
        id={`${parent_id}${props.index}_queue_next_parent_card`}
        style={classes.cardStyle}
>
<Div
          id={`${parent_id}${props.index}_queue_next_previousnextbody_div`}
          style={classes.previousNextBody}
>
          {props.item?.profile_pic && (
<Div
              id={`${parent_id}${props.index}_queue_next_profile_div`}
              style={classes.previousNextBodyAvatar}
>
<Image
                id={`${parent_id}${props.index}_queue_next_profile_image`}
                src={props.item?.profile_pic}
                alt={props.item?.name ?? "profilePic"}
                style={{
                  border: props.item?.isEmergency ? "1px dashed #FF4D4A" : "0",
                }}
              />
              {props.item?.isEmergency && (
<Div
                  id={`${parent_id}${props.index}_queue_next_emergency_div`}
                  style={classes.emergencyDiv}
>
<Text
                    id={`${parent_id}${props.index}_queue_next_emergency_text`}
                    className="pc_medium"
                    style={classes.emergencyText}
>
                    EMERGENCY
</Text>
</Div>
              )}
</Div>
          )}
<Div
            id={`${parent_id}${props.index}_queue_next_sub_div`}
            style={{ textAlign: "left" }}
>
            {props.item?.name && (
<H2
                id={`${parent_id}${props.index}_queue_next_previousNextBodyName_h2`}
                className="pc_semibold"
                style={classes.previousNextBodyName}
>
                {props.item?.name}
</H2>
            )}
 
            {props.item?.role && (
<Text
                id={`${parent_id}${props.index}_queue_next_previousNextBodyRole_h2`}
                className="pc_regular"
                style={classes.previousNextBodyRole}
>
                {props.item?.role}
</Text>
            )}
 
            <br />
            {props.item?.start && (
<Text
                id={`${parent_id}${props.index}_queue_next_previousNextBodyRole_start_h2`}
                className="pc_regular"
                style={classes.previousNextBodyRole}
>
                {props.item?.start}
</Text>
            )}
</Div>
          {props.item?.action.map((l, i) => {
            let svg = l.icon;
            let blob = new Blob([svg], { type: "image/svg+xml" });
            let url = URL.createObjectURL(blob);
 
            return (
<Div
                className={"reassign"}
                style={{ marginLeft: "auto" }}
                onClick={
                  () => {
                    if (
                      l.action == "VISIT REGISTER" ||
                      l.action == "NURSE SIGNOFF" ||
                      l.action == "DOCTOR SIGNOFF"
                    ) {
                      if (
                        props.item?.totalData?.consultationMode ===
                          "Video Consultation" &&
                        !drawerMeetingContext?.open
                      ) {
                        navigate(AppRoutes.videoCall, {
                          state: {
                            appointmentId: props.item?.totalData.info?._key,
                            name: loggedUserInfo?.data?.name?.text,
                            role: loggedUserInfo?.data?.role,
                            data: props.item?.totalData,
                          },
                        });
                      } else {
                        if (
                          props.item?.totalData?.type === "nurse" ||
                          props.item?.totalData?.type === "doctor"
                        ) {
                          attendPatient(
                            props.item?.totalData.personId,
                            props.item?.totalData.patientId,
                            props.item?.totalData.token,
                            props.item?.totalData.encounterId,
                            props.item?.totalData?.type,
                            props.item?.totalData.pId,
                            props.item?.totalData.encounter_id,
                            props.item?.totalData?.info?.ticketid,
                            props.item?.totalData
                          );
                        } else {
                          registerPatient(
                            props.item?.totalData.personId,
                            props.item?.totalData.patientId,
                            props.item?.totalData?.info?._id,
                            props.item?.totalData?.isPatient,
                            props.item?.totalData?.mobileNo,
                            props.item?.totalData?.info.action.find(
                              (x) => x.action === "VISIT REGISTER"
                            ),
                            props.item?.totalData?.info?.ticketid
                          );
                        }
                      }
                    } else {
                      actionButtonClickEvent(
                        l.action,
                        l.apiendpoint,
                        l.backEndCall,
                        props.item?.ticketId
                      );
                    }
                  }
                  // props?.assignButtonAction
                  //   ? () => props.assignButtonAction()
                  //   : null
                }
>
<div
                  style={{
                    cursor: "pointer",
                    borderRadius: "50%",
                    background: "#ebebeb",
                    height: "35px",
                    width: "35px",
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                  // dangerouslySetInnerHTML={{ __html: l.icon }}
>
<Image
                    title={l.action}
                    inLineStyles={{
                      height: "17px",
                      width: "16px",
                      marginLeft: "10px",
                    }}
                    src={url}
                    alt={l.action.substring(0, 1)}
                  />
</div>
</Div>
            );
          })}
          {/* {!markHighPriority && (
<Div
              style={{
                backgroundColor:
                  props.item?.patientPriority?.priority?.value === "High"
                    ? "#FF4D4A"
                    : "#F9E6E6",
                padding: "5px 8px 3px",
                borderRadius: "50%",
                cursor: "pointer",
                marginLeft: "30px",
              }}
              // onClick={(event) =>
              //     handleHighAlertPop(
              //         event,
              //         props.item?.title,
              //         props.item?.encounter,
              //         props.item?.patientPriority,
              //         props.item?.totalData,
              //         props.item?.role_
              //     )
              // }
>
</Div>
          )} */}
</Div>
</Card>
      {state?.openDialog ? (
<SideMenu
          id="patient_dashboard_cancel_sidemenu"
          open={state?.openDialog}
          direction="right"
          width={440}
          color="default"
          onClose={() => handlesidebarCancelbooking("closing")}
          style={{ padding: 0 }}
>
<CancelBookingPage
            parent_id={"patient_dashboard"}
            cancelbookingreason={state?.cancelbookingreason?.value}
            cancelbookings={"4"}
            appointmentId={props?.totalData?.info?._id}
            handleSelectCancelbooking={handleSelectCancelbooking}
            handlecahngeCancelbooking={handlecahngeCancelbooking}
          />
</SideMenu>
      ) : null}
</Div>
  );
};
 
export default UpNext;