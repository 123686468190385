import React, { useState, useRef } from "react";
import {
  Div,
  Paper,
  Select,
  TextInput,
  H6,
  Col,
  Row,
  Text,
  Avatar,
  Divider,
  Checkbox,
  Upload,
  Image,
  Popover,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../hoc/withAllContexts";
import { UIColor } from "../../themes/theme";
import moment from "moment";
// import { AlertProps } from "../../../utils";
// import ModifyStatus from "./modifyStatus";
import { EditIcon } from "../svg/components/editIcon";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";
import RegUploadIcon from "../../assets/img/png/RegUploadIcon.png";
import CustomInputDatePicker from "../customDatePicker";
import {
  setDetails,
  editStatusOpen,
  newRowAdd,
  getUseOptions,
  FlterOptions,
  handleDelete,
  enterAge,
} from "./handlers";
import useStyles from "./styles";

const PatientNames = (props) => {
  
  const styles = useStyles();

  const myRef = useRef(null);
  const [state, setState] = useState({
    Showfilter: props?.[props.saveto]?.names,
    limited: false,
    editStatus: null,
    statusValue: "Inactive",
  });

  const { parent_id } = props;
  const { names, details, statusDetails } = props?.[props?.saveto];

  const {
    labelStyle,
    borderStyle,
    statusBackground,
    status,
    dot,
    cameraIcon,
    idSpace,
    onUpload,
    inactiveDot,
    errorborderStyle,
  } = styles;

  const setData = (name, value, index, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }

    let patientData = props?.[props?.saveto];
    patientData.names[index][name] = value;
    if (patientData.names[index].error) {
      patientData.names[index].error[name] = false;
    }
    patientData[props?.setTo]({
      ...patientData,
    });
  };

  const handleLimit = () => {
    setState({
      ...state,
      limited: !state.limited,
    });
  };

  return (
    <Div id={`${parent_id}_patient_name_parent_div`} className="pi-root">
      <Row
        id={`${parent_id}_patient_name_parent_row`}
        className={"pn-row-root"}
      >
        <Col
          id={`${parent_id}_patient_name_upload_col`}
          className="pn-image-root"
        >
          <Paper
            id={`${parent_id}_patient_name_upload_paper`}
            className="pi-paper pn-image-paper-root"
            style={{ padding: 8 }}
          >
            <Div
              id={`${parent_id}_patient_name_upload_avatar_div`}
              style={{ position: "relative" }}
            >
              <Avatar
                id={`${parent_id}_patient_name_upload_avatar`}
                variant="square"
                src={details?.image?.url || props.personImg}
                className={"pn-image"}
              />
              {true && (
                <>
                  <div
                    ref={myRef}
                    id={`${parent_id}_edit_patient_name_upload_position`}
                    style={onUpload}
                  >
                    <Upload
                      id={`${parent_id}_edit_patient_name_upload`}
                      handleChange={(e, value) =>
                        setDetails(props, "image", { url: e, file: value })
                      }
                      label=""
                      accept="image/*"
                      labelStyle={{
                        fontSize: 14,
                        fontFamily: "pc_medium !important",
                      }}
                      className={"pn-upload-actions-btn"}
                      // list={}
                      showIcon={false}
                    />
                  </div>
                  <Div
                    id={`${parent_id}_image_camera_image`}
                    style={cameraIcon}
                  >
                    <img
                      src={RegUploadIcon}
                      style={{ width: 22, height: 22, zIndex: 99 }}
                      alt="camera"
                      onClick={() => {
                        const current = myRef?.current;
                        if (current) {
                          const btn =
                            current?.querySelectorAll?.(
                              "input[type='file']"
                            )?.[0];
                          if (btn) {
                            btn.click();
                          }
                        }
                      }}
                    />
                  </Div>
                  {props.parent_edit_id && (
                    <>
                      <Div
                        id={`${parent_id}_background_status`}
                        style={statusBackground}
                      ></Div>
                      <div id={`${parent_id}_status_image`} style={status}>
                        <Div
                          id={`${parent_id}_status_dot`}
                          style={
                            statusDetails?.status === "Deceased" ||
                            statusDetails?.status === "Revoke"
                              ? inactiveDot
                              : statusDetails?.status1 === "Active"
                              ? dot
                              : inactiveDot
                          }
                        ></Div>
                        {/* {JSON.stringify(statusDetails?.reason1)} */}
                        <Tooltip
                          title={
                            statusDetails?.reason?.value
                              ? statusDetails?.reason?.value
                              : statusDetails?.reason1?.value
                              ? statusDetails?.reason1?.value
                              : ""
                          }
                        >
                          <Typography
                            id={`${parent_id}_status_text`}
                            className="pc_regular"
                          >
                            {statusDetails?.status ||
                              statusDetails?.status1 ||
                              "Active"}
                          </Typography>
                        </Tooltip>
                        <div style={{ flexGrow: 1 }}></div>
                        <div
                          id={`${parent_id}_status_text`}
                          style={{
                            cursor: "pointer",
                            width: "20px",
                            textAlign: "end",
                          }}
                          onClick={(e) => setState(editStatusOpen(e, state))}
                        >
                          <EditIcon color="#fff" width="18px" height="13px" />
                        </div>
                      </div>
                    </>
                  )}

                  <Popover
                    id={`${parent_id}_status_popOver`}
                    open={state.editStatus}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    style={{ zIndex: "100", width: "670px", top: "200px" }}
                  >
                    {/* <ModifyStatus
                                            parent_id={parent_id}
                                            handlepopoverclose={() => this.handlepopoverclose()}
                                            handleSave={this.handleSave}
                                            statusDetails={statusDetails}
                                            allMasters={this.props?.allMasters}
                                        /> */}
                  </Popover>
                </>
              )}
            </Div>

            <Div
              id={`${parent_id}_patient_name_upload_sub_div`}
              className="pn-upload-actions"
              style={{ padding: "9px 0px" }}
            >
              {/* <Button className="pn-upload-actions-btn" name="Upload Image" /> */}
              {
                props.parent_edit_id ? (
                  <Div id={`${parent_id}_mrn_id_div`} style={idSpace}>
                    <H6
                      id={`${parent_id}_mrn_id_div`}
                      style={{
                        paddingBottom: "4px",
                        color: UIColor.secondaryButtonColor.color,
                      }}
                      className="pi-title"
                    >
                      {props?.mrn}
                    </H6>
                  </Div>
                ) : (
                  <Div
                    style={{
                      padding: 4,
                      lineHeight: 1,
                      textAlign: "center",
                      color: UIColor.secondaryButtonColor.color,
                      backgroundColor: UIColor.differentiationBackground.color,
                    }}
                  >
                    MRN
                  </Div>
                )
                // <Upload
                //     id={`${parent_id}_patient_name_upload`}
                //     handleChange={(e, value) => {
                //         if (value.type === "image/png" || value.type === "image/jpg" || value.type === "image/jpeg") {
                //             this.setDetails("image", { url: e, file: value })
                //         } else {
                //             this.props.alert.setSnack({
                //                 open: true,
                //                 severity: AlertProps.severity.error,
                //                 msg: "Please Select an Image file.",
                //                 vertical: AlertProps.vertical.top,
                //                 horizontal: AlertProps.horizontal.center,
                //                 tone: true,
                //             });
                //         }
                //     }}
                //     label="Upload Image"
                //     labelStyle={{ fontSize: 14, fontFamily: 'pc_medium !important' }}
                //     className={"pn-upload-actions-btn"}
                //     showIcon={false}
                //     accept="image/*"
                // />
              }
            </Div>
          </Paper>
        </Col>
        <Col id={`${parent_id}_patient_name_sub_col`} className="pn-names-root">
          <Paper
            id={`${parent_id}_patient_name_sub_paper`}
            className="pi-paper"
            inLineStyles={{ height: "96% !important" }}
          >
            <Div id={`${parent_id}_patient_name_label_div`}>
              <H6
                id={`${parent_id}_patient_name_label_paitient_h6`}
                className="pi-title"
              >
                {props.saveto === "nextOfKin"
                  ? "NEXT OF KIN NAME"
                  : "PATIENT NAMES"}
              </H6>
              {props.saveto !== "nextOfKin" && (
                <Text
                  id={`${parent_id}_patient_name_label_addnew_text`}
                  onClick={() => newRowAdd(props)}
                  className="pi-addbtn"
                >
                  + Add New
                </Text>
              )}
            </Div>

            {names
              ?.slice(0, state.limited ? names.length : 1)
              ?.map((val, i) => (
                <Row
                  id={`${parent_id}_${i}_patient_name_map_row`}
                  className="pi-actions-root pi-names-root"
                >
                  <Col
                    id={`${parent_id}_${i}_patient_name_col`}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_${i}_patient_name_use_label_select`}
                      value={val?.use?.value ?? null}
                      // helperText={(val?.error && val?.error['use']) ? 'Is required' : ''}
                      isRequired={false}
                      options={getUseOptions(props, val?.use?.value ?? null)}
                      disabled={
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                      // options={USE}
                      // options={[
                      //     { value: 'usual', label: 'US001' },
                      //     { value: 'official', label: 'US002' },
                      //     { value: 'temp', label: 'US003' },
                      //     { value: 'nickname', label: 'US004' },
                      //     { value: 'anonymous', label: 'US005' },
                      //     { value: 'old', label: 'US006' },
                      //     { value: 'maidan', label: 'US007' },
                      // ]}
                      error={val?.error && val?.error["use"] ? true : false}
                      onChangeValue={(e) => setData("use", e, i)}
                      label="Use"
                      showArrow
                      placeholder="use"
                      labelStyle={labelStyle}
                      inLineStyles={
                        val?.error && val?.error["use"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_${i}_patient_name_prefix_col`}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_${i}_patient_name_prefix_label_select`}
                      value={val?.prefix?.value ?? null}
                      onChangeValue={(e) => setData("prefix", e, i)}
                      options={FlterOptions(props, "NAMEPREFIX")}
                      // helperText={(val?.error && val?.error['prefix']) ? 'Is required' : ''}
                      disabled={
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                      isRequired={false}
                      error={val?.error && val?.error["prefix"] ? true : false}
                      label="Prefix"
                      showArrow
                      placeholder="prefix"
                      labelStyle={labelStyle}
                      inLineStyles={
                        val?.error && val?.error["prefix"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_${i}_patient_name_firstname_col`}
                    className="no-padding-left"
                  >
                    <TextInput
                      autoFocus={true}
                      id={`${parent_id}_${i}_patient_name_firstname_label_textinput`}
                      // helperText={(val?.error && val?.error['first_name']) ? 'Is required' : ''}
                      value={val.first_name ? val.first_name : ""}
                      onChange={(e) =>
                        setData("first_name", e.target.value, i, 60)
                      }
                      isRequired={true}
                      error={
                        val?.error && val?.error["first_name"] ? true : false
                      }
                      label="First Name"
                      labelStyle={labelStyle}
                      style={
                        val?.error && val?.error["first_name"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      placeholder="first name"
                      // onKeyPress={function (e) {
                      //     var key = e.which;
                      //     if ((key >= 65 && key <= 90) || (key >= 95 && key <= 122)) {
                      //         return
                      //     } else {
                      //         e.preventDefault()
                      //     }
                      // }}
                      hoverColor={UIColor.primaryColor.color}
                      disabled={
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_${i}_patient_name_middlename_col`}
                    className="no-padding-left"
                  >
                    <TextInput
                      id={`${parent_id}_${i}_patient_name_middlename_textinput`}
                      value={val.middle_name ? val.middle_name : ""}
                      onChange={(e) =>
                        setData("middle_name", e.target.value, i, 60)
                      }
                      // helperText={(val?.error && val?.error['middle_name']) ? 'Is required' : ''}
                      disabled={
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                      // isRequired={true}
                      error={
                        val?.error && val?.error["middle_name"] ? true : false
                      }
                      placeholder="middle name"
                      // onKeyPress={function (e) {
                      //     var key = e.which;
                      //     if ((key >= 65 && key <= 90) || (key >= 95 && key <= 122)) {
                      //         return
                      //     } else {
                      //         e.preventDefault()
                      //     }
                      // }}
                      label="Middle Name"
                      labelStyle={labelStyle}
                      style={
                        val?.error && val?.error["middle_name"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_${i}_patient_name_lastname_col`}
                    className="no-padding-left"
                  >
                    <TextInput
                      id={`${parent_id}_${i}_patient_name_lastname_label_textinput`}
                      value={val.last_name ? val.last_name : ""}
                      onChange={(e) =>
                        setData("last_name", e.target.value, i, 60)
                      }
                      // helperText={(val?.error && val?.error['last_name']) ? 'Is required' : ''}
                      disabled={
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                      isRequired={false}
                      // onKeyPress={function (e) {
                      //     var key = e.which;
                      //     if ((key >= 65 && key <= 90) || (key >= 95 && key <= 122)) {
                      //         return
                      //     } else {
                      //         e.preventDefault()
                      //     }
                      // }}
                      error={
                        val?.error && val?.error["last_name"] ? true : false
                      }
                      placeholder="last name"
                      label="Last Name"
                      labelStyle={labelStyle}
                      style={
                        val?.error && val?.error["last_name"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_${i}_patient_name_suffix_col`}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_${i}_patient_name_suffix_label_select`}
                      value={val?.suffix?.value ?? null}
                      onChangeValue={(e) => this.setData("suffix", e, i)}
                      options={FlterOptions(props, "NAMESUFFIX")}
                      // helperText={(val?.error && val?.error['suffix']) ? 'Is required' : ''}
                      disabled={
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                      // isRequired={true}
                      error={val?.error && val?.error["suffix"] ? true : false}
                      label="Suffix"
                      showArrow
                      placeholder="suffix"
                      labelStyle={labelStyle}
                      inLineStyles={
                        val?.error && val?.error["suffix"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                    />
                  </Col>
                  {this.props.saveto !== "nextOfKin" && (
                    <Col
                      id={`${parent_id}_${i}_patient_name_show_col`}
                      style={{
                        margin: "auto",
                        padding: 0,
                      }}
                    >
                      <Div
                        id={`${parent_id}_${i}_patient_name_show_div`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 18,
                          width: "max-content",
                        }}
                      >
                        {/* <i className="fa fa-trash-o" style={{color:'#FF4D4A',cursor:'pointer'}} aria-hidden="true"></i> */}
                        <Div
                          id={`${parent_id}_${i}_patient_name_delete_div`}
                          style={{
                            width: 15,
                            height: 18,
                            cursor: "pointer",
                          }}
                        >
                          <Image
                            id={`${parent_id}_${i}_patient_name_delete_trash_image`}
                            src="images/icons8-trash1.svg"
                            alt="delete"
                            onClick={() => handleDelete(props, i)}
                          />
                        </Div>
                        {names?.length > 1 && i === 0 && (
                          <Text
                            id={`${parent_id}_${i}_patient_name_show_showless_label_text`}
                            className="pc_medium"
                            style={{
                              color: "#F58B00",
                              marginLeft: 15,
                              cursor: "pointer",
                              fontSize: "14px",
                            }}
                            onClick={() => handleLimit()}
                          >
                            {state.limited
                              ? "Show Less"
                              : `Show +${names?.length}`}
                          </Text>
                        )}
                      </Div>
                    </Col>
                  )}
                </Row>
              ))}
            <Divider
              id={`${parent_id}_patient_name_bottom_divider`}
              className="p-dashed-divider "
              inLineStyles={{ margin: "5px 0px" }}
            />
            <Row
              id={`${parent_id}_patient_name_parent_row`}
              className="pi-basic-details pi-actions-root"
            >
              <Col
                id={`${parent_id}_patient_name_gender_col`}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_patient_name_gender_select`}
                  isRequired={true}
                  showArrow
                  label="Gender"
                  options={FlterOptions(props, "GENDER")}
                  // options={[
                  //     { value: 'Male', label: 'Male' },
                  //     { value: 'Female', label: 'Female' },
                  //     { value: 'Unknown', label: 'Unknown' },
                  // ]}
                  value={details?.gender?.value ?? null}
                  onChangeValue={(e) => setDetails(props, "gender", e)}
                  placeholder="select..."
                  labelStyle={labelStyle}
                  inLineStyles={
                    details?.error && details?.error["gender"]
                      ? errorborderStyle
                      : borderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  // helperText={(details?.error && details?.error['gender']) ? 'Is required' : ''}
                  disabled={
                    statusDetails?.status1 === "Inactive" ||
                    statusDetails?.status === "Deceased"
                      ? true
                      : false
                  }
                  error={
                    details?.error && details?.error["gender"] ? true : false
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_patient_name_dateformat_col`}
                className="no-padding-left"
              >
                {/* <TextInput
                                    id={`${parent_id}_patient_name_dateformat_textinput`}
                                    value={details.dob ? details.dob : ''}
                                    helperText={this.checkAgeError(details)}
                                    helperTextType="error"
                                    error={(details?.error && details?.error['dob']) ? true : false}
                                    max={moment(new Date()).format('YYYY-MM-DD')}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                    // onKeyPress={function (event) {
                                    //     event.preventDefault();
                                    //     return
                                    // }}
                                    onChange={(e) => this.setDetails("dob", e.target.value, "age")}
                                    minDate={(moment().subtract(200, 'years')).format('YYYY-MM-DD')}
                                    isRequired={true}
                                    dateIcon="images/icons8-calendar-grey.svg"
                                    label="Date of Birth"
                                    type="date"
                                    labelStyle={labelStyle}
                                    style={(details?.error && details?.error['dob']) ? errorborderStyle : borderStyle}
                                    hoverColor={UIColor.primaryColor.color}
                                    className="dateInput"
                                /> */}
                <CustomInputDatePicker
                  disabled={
                    statusDetails?.status1 === "Inactive" ||
                    statusDetails?.status === "Deceased"
                      ? true
                      : false
                  }
                  placeholderText="dd/mm/yy"
                  // minDate = {(moment().subtract(200, 'years')).format('YYYY-MM-DD')}
                  // maxDate = {moment(new Date()).format('YYYY-MM-DD')}
                  maxDate={new Date()}
                  minDate={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() - 200)
                    )
                  }
                  handleChange={(value) =>
                    setDetails(
                      props,
                      "dob",
                      value ? moment(value).format("YYYY-MM-DD") : null,
                      "age"
                    )
                  }
                  selectedDate={details.dob ? new Date(details.dob) : null}
                  inputField={
                    <TextInput
                      id={`${parent_id}_patient_name_dateformat_textinput`}
                      label="Date of Birth"
                      placeholder="dd/mm/yyyy"
                      dateIcon="images/icons8-calendar-grey.svg"
                      labelStyle={labelStyle}
                      isRequired={true}
                      // onChange={() => alert("Hi")}
                      value={
                        details.dob
                          ? moment(new Date(details.dob)).format("DD/MM/YYYY")
                          : ""
                      }
                      style={
                        details?.error && details?.error["dob"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                      helperText={"Invalid Date of Birth"}
                      disabled={
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                    />
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_patient_name_age_col`}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_patient_name_age_textinput`}
                  value={details?.age ?? ""}
                  onChange={(e) => enterAge(props, e.target.value)}
                  label="Age"
                  labelStyle={labelStyle}
                  style={borderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  placeholder="age"
                  type="number"
                  disabled={
                    statusDetails?.status1 === "Inactive" ||
                    statusDetails?.status === "Deceased"
                      ? true
                      : false
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_patient_name_age_type_col`}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_patient_name_age_type_select`}
                  isRequired={true}
                  showArrow
                  label="Age Type"
                  placeholder="select..."
                  labelStyle={labelStyle}
                  inLineStyles={
                    details?.error && details?.error["ageType"]
                      ? errorborderStyle
                      : borderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  // style={{width:"400px"}}
                  options={FlterOptions(props, "VITALSUOM")}
                  // options={[
                  //     { value: 'Years', label: 'Years' },
                  //     { value: 'Months', label: 'Months' },
                  //     { value: 'Days', label: 'Days' },
                  // ]}
                  value={details?.ageType?.value ?? null}
                  onChangeValue={(e) => setDetails(props, "ageType", e)}
                  // helperText={(details?.error && details?.error['ageType']) ? 'Is required' : ''}
                  disabled={
                    statusDetails?.status1 === "Inactive" ||
                    statusDetails?.status === "Deceased"
                      ? true
                      : false
                  }
                  error={
                    details?.error && details?.error["ageType"] ? true : false
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_patient_name_aged_check_col`}
                className="no-padding-left"
              >
                <H6
                  id={`${parent_id}_patient_name_entered_age_h6`}
                  className="default-size pc_regular"
                >
                  Entered Age
                </H6>
                <Checkbox
                  id={`${parent_id}_patient_name_entered_age_checkbox`}
                  checked={details?.entered_age}
                  onChange={(e) =>
                    setDetails(props, "entered_age", !details?.entered_age)
                  }
                  name=""
                  color={UIColor.secondaryButtonColor.color}
                  style={{ marginTop: "8%" }}
                  disabled={
                    statusDetails?.status1 === "Inactive" ||
                    statusDetails?.status === "Deceased"
                      ? true
                      : false
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_patient_name_marital_col`}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_patient_name_maritalstatus_label_select`}
                  options={FlterOptions(props, "MARITALSTATUS")}
                  disabled={
                    statusDetails?.status1 === "Inactive" ||
                    statusDetails?.status === "Deceased"
                      ? true
                      : false
                  }
                  label="Marital Status"
                  value={details?.marital_status?.value ?? null}
                  onChangeValue={(e) => setDetails(props, "marital_status", e)}
                  showArrow
                  placeholder="select..."
                  labelStyle={labelStyle}
                  inLineStyles={borderStyle}
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
              <Col
                id={`${parent_id}_patient_name_nationality_col`}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_patient_name_nationality_select`}
                  isRequired={true}
                  showArrow
                  label="Nationality"
                  // options={FlterOptions(props,'COUNTRY')}
                  options={props.Nas}
                  // options={[
                  //     { value: "Indian" },
                  //     { value: "Russian" },
                  //     { value: "German" },
                  // ]}
                  // helperText={(details?.error && details?.error['nationality']) ? 'Is required' : ''}
                  disabled={
                    statusDetails?.status1 === "Inactive" ||
                    statusDetails?.status === "Deceased"
                      ? true
                      : false
                  }
                  error={
                    details?.error && details?.error["nationality"]
                      ? true
                      : false
                  }
                  value={details?.nationality?.value ?? null}
                  onChangeValue={(e) => setDetails(props, "nationality", e)}
                  placeholder="select..."
                  labelStyle={labelStyle}
                  inLineStyles={
                    details?.error && details?.error["nationality"]
                      ? errorborderStyle
                      : borderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
            </Row>
          </Paper>
        </Col>
      </Row>
    </Div>
  );
};

export default withAllContexts(PatientNames); //withAllContexts here
