import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { actions } from "primarycare-binder";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import BlockOutlinedIcon from "@material-ui/icons/BlockOutlined";
import {
  Container,
  Row,
  Col,
  Div,
  Icons,
  Text,
  Divider,
  Button,
  BackgroundImage,
  Paper,
} from "qdm-component-library";
import { Grid, Typography } from "@material-ui/core";
import { AlertContext } from "../../context";
import { checkError, AlertProps } from "../../utils";
import withAllContexts from "../../hoc/withAllContexts";
import WithUnsecurePage from "../../hoc/withUnsecurePage";

const LinkExpired = (props) => {
  //   const [userId, setUserId] = useState("");
  //   const [error, setError] = useState("");
  //   const [btnDisabled, setBtnDisabled] = useState(true);
  //   const [newPassword, setNewPassword] = useState("");
  //   const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const context = useContext(AlertContext);

  //   useEffect(() => {
  //     props.backDrop.setBackDrop({
  //       ...props.backDrop,
  //       open: false,
  //       message: "loading...",
  //     });
  //   }, []);

  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      marginBottom: 10,
    },
  };

  // const changeState = (key, value) => {
  //     setState({
  //       [key]: value,
  //     });
  //   };

  //   const handleRecover = async () => {
  //   }

  return (
    <Paper
      key={"0"}
      children="Paper"
      variant="elevation"
      elevation="1"
      style={{
        width: "62%",
        textAlign: "center",
        backgroundColor: "#ffffff",
        borderRadius: "16px",
        padding: "32px",
        boxShadow: "0px 8px 69px #0000001A",
        display: "inline",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        style={{ display: "inline" }}
      >
        <>
          <Grid item xs={12} style={{ marginBottom: 25 }}>
            <BlockOutlinedIcon
              htmlColor="#f5bca0"
              style={{ marginBottom: 20, width: 100, height: 100 }}
            />
            <Typography
              variant="h2"
              component="h2"
              style={{ fontSize: "1.5rem", fontWeight: "bold" }}
            >
              Link Expired !
            </Typography>
            <Typography
              variant="body1"
              component="p"
              style={{
                marginTop: 4,
                marginBottom: 30,
                color: "rgba(146, 151, 165, 1)",
                fontSize: "1rem",
              }}
            >
              This link is expired since it is not used for more than 24 hours.{" "}
              <br /> Please contact our support team for further help.
            </Typography>
          </Grid>
          <Grid container item xs={12} justify="center">
            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  id="sendCodeButton"
                  onClick={() => navigate(AppRoutes.signIn)}
                  style={{ marginTop: 0, width: "90%" }}
                >
                  Back to Sign In
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      </Grid>
    </Paper>
  );
};

LinkExpired.contextType = AlertContext;

const mapStateToProps = (state) => ({
  forgotPassword: state?.authSlice?.forgotPassword,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
});

// export default connect(mapStateToProps, actions)(withRouter(withAllContexts(ExpiryPassword)));
export default withAllContexts((WithUnsecurePage(LinkExpired)));
