/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Collection of all validation function
 */

import moment from "moment";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";
import { countryCode_ } from "./countryCode";

//Function to validate email
export let ValidateEmail = (email) => {
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // let re = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$';
  return Boolean(re.test(email));
};

//Function to check wheather the 'e' is number key event or not based on 'isNumber' key
export let IsNumberKey = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= 10) {
      if (!(e.which >= 48 && e.which <= 57)) e.preventDefault();
    }
  }
};

//Function to check wheather the 'e' is number key event and is down or not.
export let IsNumberKeyDown = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= 10) {
      if (e.which === 38 || e.which === 40) e.preventDefault();
    }
  }
};

//Function to check wheather the 'e' is text key event or not.
export let IsTextKeyDown = (event, isOnlyText) => {
  var inputValue = event.which;

  if (isOnlyText) {
    if (
      !(
        (inputValue >= 65 && inputValue <= 90) ||
        (inputValue >= 97 && inputValue <= 122)
      ) &&
      inputValue !== 32 &&
      inputValue !== 0
    ) {
      event.preventDefault();
    }
  }
};

// export const calculateAge = (birthday) => {
//   // birthday is a date
//   var ageDifMs = Date.now() - birthday.getTime();
//   var ageDate = new Date(ageDifMs); // miliseconds from epoch
//   return Math.abs(ageDate.getUTCFullYear() - 1970);
// };
export const calculateAge = (birthday) => {
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // milliseconds from epoch
  var years = ageDate.getUTCFullYear() - 1970;
  var months = ageDate.getUTCMonth();

  if (years === 0) {
    if (months === 0) {
      var days = ageDate.getUTCDate() - 1;
      return `${days} ${days === 1 ? 'day' : 'days'}`;
    } else {
      return `${months} ${months === 1 ? 'month' : 'months'}`;
    }
  } else {
    return `${years} ${years === 1 ? 'year' : 'years'}`;
  }
};


export const calculateAgeInDays = (birthday) => {
  let date = (new Date(birthday)).getTime();
let today = (new Date()).getTime();
let msDay = 24 * 60 * 60 * 1000; // milliseconds per day

let days = Math.floor((today - date) / msDay);
return days
};

export const getData = (date) => {
  let da = date ? new Date(date) : new Date();
  let d = new Date(da).toDateString().split(" ");
  return `${d[2]} ${d[1]}, ${d[3]}`;
};

// export const getUtcTime = (date) => {
//   let a = null
//   if (date) {
//     a = new Date(moment(date))
//   } else {
//     a = new Date(date)
//   }
//   a = a.toUTCString()
//   return new Date(a).getTime()
// }

// export const utcTOLocal = (date, format) => {
//   let Ndate = new Date(date);
//   let fmt = format ? format : "DD-MM-YYYY"
//   if (format) {
//     return moment(Ndate).format(fmt);
//   } else {
//     return moment(Ndate)
//   }
// };

export const getUtcTime = (date) => {
  if (date) {
    return moment.utc(date).unix();
  } else {
    return moment.utc().unix();
  }
};

export const mobileNumberValidation = (mobileNumber, countryCode) => {
  let c_code = countryCode;
  if (countryCode) {
    if (countryCode?.includes("+")) {
      c_code = countryCode;
    } else {
      c_code = "+".concat("", countryCode);
    }
  } else {
    c_code = "+00";
  }
  const code = countryCode_?.filter((cc) => cc.dial_code === c_code.toString());
  if (code.length > 0) {
    return isValidPhoneNumber(mobileNumber?.toString(), code?.[0]?.code);
  } else {
    return "Invalid Country code";
  }
};

export const getUnixTime = (date) => {
  if (date) {
    const actualDate = date.replace(",", " ");
    return moment(actualDate, "YYYY-MM-DD hh:mm A").unix();
  } else {
    return moment.utc().unix();
  }
};

export const utcTOLocal = (date, format) => {
  let Ndate = new Date();
  if (typeof date === "number") {
    Ndate = moment.unix(date);
  } else {
    Ndate = moment(date);
  }

  let fmt = format ? format : "DD-MM-YYYY";
  if (format) {
    return moment.utc(Ndate).local().format(fmt);
  } else {
    return moment.utc(Ndate).local();
  }
};

export const isDecimal = (value) => {
  let re = /^\d{1,4}(\.\d{0,2})?$/;
  return Boolean(re.test(value));
};

export function isEmptyObject(value) {
  let res = Object.keys(value).length === 0 && value.constructor === Object;
  return res;
}
