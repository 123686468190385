import React, { useState, useEffect } from "react";
import { Div, Paper, H6, Text, Image } from "qdm-component-library";
import "./style.css";
import ShowList from "./showList";
//PatientAddAddress
import { Popover, withStyles } from "@material-ui/core";
import withAllContexts from "../../../hoc/withAllContexts";
import AddAddress from "./addAddress";
// import { UIColor } from "../../../themes/theme";
import downIcon from "../../../assets/img/svg/Group 3873.svg";
import upIcon from "../../../assets/img/svg/Group -1.svg";
import addressIcon from "../../../assets/img/svg/icons8-ok.svg";

const styles = (theme) => ({
  muiPopovers: {
    boxShadow: "0px 10px 75px #0000003D",
    borderRadius: 8,
  },
});

const Address = (props) => {
  const [state, setState] = useState({
    addAddress: null,
    addCollapse: false,
    editadd: {},
    editindex: null,
    popUp: false,
  });
  const [editClick, setEditClick] = useState(false);

  useEffect(() => {
    props?.setPatientData({ ...props?.patientData });
  }, [props.clear]);
  useEffect(() => {
    props?.setPatientData({ ...props?.patientData });
  }, [props?.cleared]);
  useEffect(() => {
    props?.setPatientData({ ...props?.patientData });
  }, [props?.states]);
  let { addAddress, addCollapse } = state;
  const { classes, parent_id } = props;
  // const { address = [], statusDetails } = props?.[props?.saveto];

  let { address, statusDetails } = props?.patientData;
  const OpenAddress = async (e, statusDetails) => {
    setState({
      ...state,
      editindex: null,

      popUp: !state.popUp,
      addAddress: !state.popUp ? e.currentTarget ?? null : null,
    });
  };
  const handleClear = () => {
    setState({
      ...state,
      editadd: {},
      editindex: null,
    });
    setEditClick(false);
  };

  const adCollapse = (e) => {
    setState({
      addCollapse: !state.addCollapse,
    });
  };

  const onedit = async (v, i, e, statusDetails) => {
    setEditClick(true);
    setState({
      ...state,
      editadd: { ...v },
      editindex: i,
      popUp: !state.popUp,
      addAddress: !state.popUp ? e?.currentTarget ?? null : null,
    });
  };

  const ondelete = (v, i, statusDetails) => {
    let patientData = props?.patientData;
    patientData.address.splice(i, 1);
    // patientData[this.props.setTo]({
    //   ...patientData,
    // });

    props?.setPatientData({ ...patientData });
  };

  return (
    <Div id={`${parent_id}_address_parent_div`} className="pi-root">
      <Paper
        id={`${parent_id}_address_parent_paper`}
        className="pi-paper"
        style={{ padding: "12px 10px 12px 16px" }}
      >
        <Div
          id={`${parent_id}_address_sub_parent_div`}
          style={{ display: "flex", alignItems: "center" }}
        >
          <H6
            id={`${parent_id}_address_title_address_label_h6`}
            className="pi-title"
            style={{
              display: "flex",
              alignItems: "center",
              margin: 0,
              flex: 1,
            }}
          >
            ADDRESS{" "}
            {address?.length > 0 && (
              <Image
                style={{ width: 16, height: 16, marginLeft: 5 }}
                src={addressIcon}
                alt="tick"
              />
            )}
          </H6>
          <Text
            id={`${parent_id}_address_title_addnew_text`}
            inLineStyles={{
              color: "#0071F2",
              fontSize: 12,
              display: "flex",
              alignItems: "center",
            }}
            className="pi-addbtn"
          >
            {!props.isViewMode && (
              <Text
                className="pi-addbtn1"
                style={{ marginRight: 10 }}
                onClick={(e) => {
                  handleClear();
                  OpenAddress(e, statusDetails);
                }}
              >
                + Add New
              </Text>
            )}
            {/* {address?.length > 0 && (
              <React.Fragment>
                {addCollapse ? (
                  //  <i onClick={(e) => this.adCollapse(e)} className="fa fa-angle-down ad-arrow" aria-hidden="true" /> : <i className="fa fa-angle-up ad-arrow" aria-hidden="true" onClick={(e) => this.adCollapse(e)} />}
                  <Image
                    id={`${parent_id}_address_group_downarrow_image`}
                    src={downIcon}
                    style={{ width: 20, height: 20 }}
                    alt="downArrow"
                    onClick={(e) => adCollapse(e)}
                  />
                ) : (
                  <Image
                    id={`${parent_id}_address_group_uparrow_image`}
                    style={{ width: 20, height: 20 }}
                    src={upIcon}
                    alt="upArrow"
                    onClick={(e) => adCollapse(e)}
                  />
                )}
              </React.Fragment>
            )} */}
          </Text>
        </Div>
        {/* {addCollapse && ( */}
        <Div id={`${parent_id}_address_showlist_div`} className="pa-showList">
          {address?.map((val, i) => {
            return (
              <div
                id={`${parent_id}_${i}_address_showlist_div`}
                style={{ marginBottom: 12 }}
              >
                <ShowList
                  id={`${parent_id}_${i}_address_showlist`}
                  index={i + 1}
                  title={val?.use?.value ?? ""}
                  description={
                    (val?.addressLine1 ?? "") +
                    (val?.addressLine1 && val?.addressLine2 ? ", " : "") +
                    (val?.addressLine2 ?? "") +
                    (val?.addressLine2 && val?.addressLine3 ? ", " : "") +
                    (val?.addressLine3 ?? "") +
                    (val.city ? ", " + val.city.value : "") +
                    (val?.district
                      ? ", " + val?.district?.[0]?.geogLevelName
                      : "") +
                    (val?.state ? ", " + val?.state?.[0]?.geogLevelName : "") +
                    (val?.country
                      ? ", " + val?.country?.[0]?.geogLevelName
                      : "") +
                    (val?.pincode
                      ? ", " + val?.pincode?.[0]?.geogLevelName
                      : "")
                  }
                  ondelete={() => ondelete(val, i, statusDetails)}
                  onedit={(e) => onedit(val, i, e, statusDetails)}
                  isViewMode={props?.isViewMode}
                />
              </div>
            );
          })}
        </Div>
        {/* )} */}
      </Paper>
      <Popover
        anchorEl={addAddress}
        open={state.popUp} //{Boolean(addAddress)}
        // anchorOrigin={{
        //   vertical: "top",
        //   horizontal: "center",
        // }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        // className={classes.muiPopovers}
        classes={{ paper: classes.muiPopovers }}
      >
        <div style={{ width: "100%", height: "430px" }}>
          <AddAddress
            parent_id={"Register_Patinet_patientdetails_patientaddress"}
            allMasters={props?.allMasters}
            handleClear={handleClear}
            index={state.editindex}
            editData={state.editadd}
            addressType={props?.addressType}
            patientData={props?.patientData}
            setPatientData={props?.setPatientData}
            setTo={props?.setTo}
            saveto={props?.saveto}
            City={props?.City}
            onclose={() => OpenAddress(null)}
            adCollapse={adCollapse}
            editClick={editClick}
            loadDefaultAddressdata={props?.loadDefaultAddressdata}
            loadDefaultAddressNok={props?.loadDefaultAddressNok}
          />
        </div>
      </Popover>
    </Div>
  );
};

export default withStyles(styles)(withAllContexts(Address)); //withAllContexts here
