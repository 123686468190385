import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { BasicDetail } from ".";
import InfoDisplay from "../infoDisplay";
import CreateIcon from "../../../../../../assets/img/svg/createIcon";
import LocationIcon from "../../../../../../assets/img/svg/LocationIcon";
import Speciality from "../../../../../../assets/img/svg/Speciality";

import CustomButton from "../customButton";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 8,
    border: "1px solid #DEE5EC",
    padding: 12,
  },
  root: {
    display: "flex",
    gap: 30,
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
  },
}));

const getText = (value = "", filler = "-") =>
  value?.toString() ? value : filler;

function PractitionerInfoCard({
  data,
  clinicName,
  specialityName,
  showEditButton,
  handleEditClick = () => {},
  ...props
}) {
  const theme = useTheme();

  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        {/* <Typography variant="h6" className={classes.title}>
        PRACTITIONER INFO
      </Typography> */}
        <BasicDetail data={data} />
        <InfoDisplay
          icon={<Speciality color={theme.palette.primary.main} />}
          label="Speciality Name"
          value={getText(specialityName)}
        />
        <InfoDisplay
          icon={<LocationIcon color={theme.palette.primary.main} />}
          label="Clinic"
          value={getText(clinicName)}
        />
      </div>
      {showEditButton && (
        <div>
          {/* startIcon={<DeleteIcon />} */}
          <CustomButton
            variant="text"
            color="#9d2e42"
            startIcon={<CreateIcon color={theme.palette.primary.main} />}
            onClick={handleEditClick}
          >
            Edit
          </CustomButton>
        </div>
      )}
    </div>
  );
}

export default PractitionerInfoCard;
