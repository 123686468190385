import * as React from "react";
import PropTypes from "prop-types";

const TrashIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15.2} height={16} {...props}>
    <path
      d="M7.6 0a2.809 2.809 0 0 0-2.768 2.4H2.1a.539.539 0 0 0-.192 0H.6a.6.6 0 1 0 0 1.2h.855l1.007 10.412A2.208 2.208 0 0 0 4.652 16h5.895a2.207 2.207 0 0 0 2.19-1.988L13.744 3.6h.856a.6.6 0 1 0 0-1.2h-1.3a.6.6 0 0 0-.192 0h-2.74A2.809 2.809 0 0 0 7.6 0Zm0 1.2a1.585 1.585 0 0 1 1.545 1.2h-3.09A1.585 1.585 0 0 1 7.6 1.2ZM2.66 3.6h9.879l-1 10.3a.992.992 0 0 1-1 .9H4.652a.993.993 0 0 1-1-.9Zm3.53 1.991a.6.6 0 0 0-.591.609v6a.6.6 0 1 0 1.2 0v-6a.6.6 0 0 0-.609-.609Zm2.8 0a.6.6 0 0 0-.591.609v6a.6.6 0 1 0 1.2 0v-6a.6.6 0 0 0-.609-.609Z"
      fill={props.color}
    />
  </svg>
);

export default TrashIcon;

TrashIcon.defaultProps = {
  color: "#ff4d4a",
};

TrashIcon.propTypes = {
  color: PropTypes.string,
};
