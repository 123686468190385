import * as React from "react";
// import { makeStyles } from "@material-ui/core";

// const useStyle = makeStyles((theme) => ({
//   cls1: (props) => ({
//     fill: props.htmlColor,
//   }),
// }));

export const Calendar = (props) => {
  return (
    // <SvgIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path
        fill={props.color ? props.color : "#6f6f6f"}
        class="a"
        d="M8.528,6A2.537,2.537,0,0,0,6,8.528v8.944A2.537,2.537,0,0,0,8.528,20h8.944A2.537,2.537,0,0,0,20,17.472V8.528A2.537,2.537,0,0,0,17.472,6Zm0,1.167h8.944a1.353,1.353,0,0,1,1.361,1.361v.583H7.167V8.528A1.353,1.353,0,0,1,8.528,7.167ZM7.167,10.278H18.833v7.194a1.353,1.353,0,0,1-1.361,1.361H8.528a1.353,1.353,0,0,1-1.361-1.361Zm2.528,1.556a.972.972,0,1,0,.972.972A.972.972,0,0,0,9.694,11.833Zm3.306,0a.972.972,0,1,0,.972.972A.972.972,0,0,0,13,11.833Zm3.306,0a.972.972,0,1,0,.972.972A.972.972,0,0,0,16.306,11.833Zm-6.611,3.5a.972.972,0,1,0,.972.972A.972.972,0,0,0,9.694,15.333Zm3.306,0a.972.972,0,1,0,.972.972A.972.972,0,0,0,13,15.333Z"
        transform="translate(-6 -6)"
      />
    </svg>
    // </SvgIcon>
  );
};
