import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dot from "./components/dot/index";
import { makeName } from "../../../../utils";
import moment from "moment";
import { Grid, IconButton } from "@material-ui/core";
import { Icons } from "qdm-component-library";

const useStyles = makeStyles((theme) => ({
  mian: {
    border: "1px solid #E0E0E0",
    background: "#F1F6FC",
  },
  root: {
    // border: "1px solid #E0E0E0",
    // background: "#F1F6FC",
    position: "relative",
    display: "flex",
    // gap: 4,
    alignItems: "center",
    // padding: 10,
    // borderRadius: 8,
    // margin: "-16px",
    borderTop: "none",
  },
  name: {
    fontSize: 13,
    fontWeight: 400,
    marginRight: 6,
    color: "#6A7888",
    display: "flex",
  },
  names: {
    fontSize: 13,
    fontWeight: 600,
    marginRight: 6,
    color: "#000",
  },
  pad: {
    padding: "0 16px",
  },
}));

function AddMultiOrderBanner(props) {
  console.log("Addprops", props);
  // <-----------------Props---------------->
  const { patientInfoData, encounterInfoData } = props;

  // <------------------Hooks--------------->
  const classes = useStyles(props);

  const getGender = (value) => {
    switch (value) {
      case "male":
        return "M";
      case "female":
        return "F";
      default:
        return "";
    }
  };

  return (
    <Grid
      container
      // spacing={2}
      className={classes.pad}
      justifyContent="space-between"
      alignItems="center"
      // className={classes.mian}
    >
      <Grid item>
        <Typography
          variant="h5"
          //className={classes.name}
        >
          Medication order edit
        </Typography>
      </Grid>
      <Grid item>
        {" "}
        <span>
          <IconButton
            size="small"
            onClick={() => {
              props?.close();
            }}
          >
            {" "}
            <Icons
              id={`addmultiorder_modal_close_icons`}
              fontIcon="close"
              style={{ color: "#a2a2a2" }}
            ></Icons>
          </IconButton>
        </span>
      </Grid>

      {/* <Grid item xs={6} style={{ borderRight: "1px solid #E0E0E0" }}>
        <div className={classes.root}>
          <Typography className={classes.names} noWrap>
            {makeName(patientInfoData?.name?.[0] || {})}
          </Typography>

          {(patientInfoData?.age ||
            getGender(patientInfoData?.gender?.display?.toLowerCase())) && (
            <Dot space={4} size={8} />
          )}
          <Typography className={classes.name}>
            {`${patientInfoData?.age ?? ""}${getGender(
              patientInfoData?.gender?.display?.toLowerCase()
            )}`}
          </Typography>
          {patientInfoData?.bloodGroup?.display && <Dot size={8} space={4} />}

          {patientInfoData?.bloodGroup?.display && (
            <Typography className={classes.name}>
              {patientInfoData?.bloodGroup?.display ?? ""}{" "}
            </Typography>
          )}

          {patientInfoData?.alias && (
            <Typography className={classes.name}>
              <img
                style={{ width: "16px", margin: "-3px", marginRight: 2 }}
                src={
                  "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316316455"
                }
              />
              {patientInfoData?.alias ?? ""}
            </Typography>
          )}

          {patientInfoData?.telecom?.[0]?.value && (
            <Typography className={classes.name}>
              <img
                style={{
                  width: "10px",
                  margin: "-3px",
                  marginRight: 2,
                  marginLeft: 2,
                }}
                src={
                  "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316338442"
                }
              />
              {patientInfoData?.telecom?.[0]?.value ?? ""}
            </Typography>
          )}
        </div>
      </Grid>

      <Grid item xs={6}>
        <div className={classes.root}>
          <Typography className={classes.names}>{`EN-${
            encounterInfoData?._key ?? ""
          }`}</Typography>
          {encounterInfoData?.type && <Dot space={4} size={8} />}
          {encounterInfoData?.type && (
            <Typography className={classes.names}>
              {encounterInfoData?.type ?? ""}{" "}
            </Typography>
          )}
          {encounterInfoData?.visit_type && <Dot space={4} size={8} />}{" "}
          <Typography className={classes.names}>
            {encounterInfoData?.visit_type ?? ""}{" "}
          </Typography>
          {encounterInfoData?.speciality?.[0] && <Dot space={4} size={8} />}{" "}
          <Typography className={classes.name}>
            {encounterInfoData?.speciality?.[0] ?? ""}{" "}
          </Typography>
          {encounterInfoData?.encounterdate !== 0 && (
            <Typography className={classes.name}>
              <img
                style={{ width: "12px", margin: "-3px", marginRight: 2 }}
                src={
                  "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/316382595"
                }
              />
              {`On-${moment
                .unix(encounterInfoData?.encounterdate)
                .format("MM,dd,YYYY")}`}
            </Typography>
          )}
           {encounterInfoData?.location && (
            <Typography className={classes.name}>
              <img
                style={{ width: "12px", margin: "-3px", marginRight: 2 }}
                src={
                  "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317076717"
                }  />
             
              {encounterInfoData?.location ?? ""}
            </Typography>
          )} 
          {encounterInfoData?.Practitioner_id[0]?.name?.[0] && (
            <Dot space={4} size={8} />
          )}
          {encounterInfoData?.Practitioner_id[0]?.name?.[0] && (
            <Typography className={classes.name}>
              {makeName(encounterInfoData?.Practitioner_id[0]?.name?.[0] || {})}
            </Typography>
          )}
        </div>
      </Grid> */}
    </Grid>
  );
}

export default AddMultiOrderBanner;

AddMultiOrderBanner.defaultProps = {};

AddMultiOrderBanner.propTypes = {};
