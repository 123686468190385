import React from "react";

export const DocIcon2 = (props) => {
  let width = props.width ? props.width : "27.799";
  let height = props.height ? props.height : "40";
  let color = props.color ? props.color : "#252525";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22.126 28.754"
    >
      <g id="docs1" transform="translate(-8.427 -8.623)">
        <g id="noun_Document_3574392" transform="translate(2.427 5.623)">
          <g
            id="Document_File"
            data-name="Document File"
            transform="translate(6 3)"
          >
            <path
              id="Path_92982"
              data-name="Path 92982"
              d="M11.033,24H23.087a1.013,1.013,0,1,0,0-2H11.033a1.013,1.013,0,1,0,0,2Z"
              transform="translate(-6 -0.82)"
              fill="#ec6a49"
            />
            <path
              id="Path_92983"
              data-name="Path 92983"
              d="M10.86,20h4.28a1,1,0,1,0,0-2H10.86a1,1,0,1,0,0,2Z"
              transform="translate(-5.404 -1.279)"
              fill="#ec6a49"
            />
            <path
              id="Path_92984"
              data-name="Path 92984"
              d="M28.118,10.675a1.316,1.316,0,0,0,0-.288.4.4,0,0,0,0-.1,1.106,1.106,0,0,0-.188-.3h0L21.405,3.354A1.106,1.106,0,0,0,20.6,3H7.106A1.106,1.106,0,0,0,6,4.106V30.648a1.106,1.106,0,0,0,1.106,1.106h4.888a1.106,1.106,0,0,0,0-2.212H8.212V5.212H19.271v5.53a1.106,1.106,0,0,0,1.106,1.106h5.53V29.542H16.141a1.106,1.106,0,0,0,0,2.212H27.012a1.106,1.106,0,0,0,1.106-1.106V10.675Z"
              transform="translate(-6 -3)"
              fill="#2a60bc"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
