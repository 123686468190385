import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    defaultReject,
    defaultState,
    fetchData,
    urls,
    dbName,
    query_ids,
    queries,
    makeName,
    getImgUrl,
    __tenantid__,
    getFacilityId,
  } from "../../utils";
  import { childJSON } from "./orders/child";
import { ccGenerateJSON } from "./orders/saveJson";
import { generateResJson,ccgenerateResJson } from "./orders/generateresponse";
import { json } from "react-router-dom";

const RELATIONSHIP_MASTERS = createAsyncThunk(
    `chiefCompilenceMastersSlice/relationship_masters`,
    async (payload = {}, { rejectWithValue }) => {
      try {
        const { getFltr } = payload
        const body = {
          db_name: dbName,
 
          filter: {
            type: ["RELATIONSHIP"],
            tenantid: __tenantid__,
            facilityid: getFacilityId(),
            lang: "en",
          },
          queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
        };
        const data = await fetchData(
          { body: JSON.stringify(body) },
          urls.readQdmQueries
        );
       
        let arr = [];
        data[0]?.RELATIONSHIP?.Value?.map((val) => {
          arr.push({
            value: val?._id ?? "",
            label: val?.display ?? "",
          });
        });
        console.log(arr,"???????????")
        return {
          ...defaultState.List,
          data: arr,
        };
      } catch (error) {
        return rejectWithValue({
          ...defaultReject,
          message: error.message,
        });
      }
    }
  );
 
  const SYMPTOMS_MASTERS = createAsyncThunk(
    `chiefCompilenceMastersSlice/symptoms`,
    async (payload = {}, { rejectWithValue }) => {
      try {
        const body = {
          db_name: dbName,
          filter: {
            tenantid: __tenantid__,
            facilityid: getFacilityId()
          },
          queryid: "056f642c-df9c-40c0-93b2-099ce23a54b1",
         
        };
        const data = await fetchData(
          { body: JSON.stringify(body)},
          urls.readQdmQueries
        );
        let arr = [];
        data?.map((val) => {
          arr.push({
            value: val.id,
            label: val?.SymptomsName,
          });
        });
        return {
          ...defaultState.List,
          data: arr,
        };
      } catch (error) {
        return rejectWithValue({
          ...defaultReject,
          message: error.message,
        });
      }
    }
  );
 
  const SEVERITY_MASTERS = createAsyncThunk(
    `chiefCompilenceMastersSlice/severity`,
    async (payload = {}, { rejectWithValue }) => {
      try {
        const body = {
            db_name: dbName,
   
            filter: {
              type: ["CCSEVERITY"],
              tenantid: __tenantid__,
              facilityid: getFacilityId(),
              lang: "en",
            },
            queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
          };
          const data = await fetchData(
            { body: JSON.stringify(body) },
            urls.readQdmQueries
          );
        let arr = [];
       
        data[0]?.CCSEVERITY?.Value.map((val) => {
          arr.push({
            value: val._id,
            label: val?.display ?? "",
            masterId: val?._id,
          });
        });
        return {
          ...defaultState.List,
          data: arr,
        };
      } catch (error) {
        return rejectWithValue({
          ...defaultReject,
          message: error.message,
        });
      }
    }
  );
  //..
 
  const BODYSITE_MASTERS = createAsyncThunk(
    `chiefCompilenceMastersSlice/bodysite`,
    async (payload = {}, { rejectWithValue }) => {
      try {
        const body = {
          db_name: dbName,
 
          filter: {
            type: ["CCBODYSITE"],
            tenantid: __tenantid__,
            facilityid: getFacilityId(),
            lang: "en",
          },
          queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
        };
        const data = await fetchData(
          { body: JSON.stringify(body) },
          urls.readQdmQueries
        );
       
        let arr = [];
        data[0]?.CCBODYSITE?.Value.map((val) => {
          arr.push({
            value: val?._id,
            label: val?.display ?? "",
            masterId: val?._id,
          });
        });
        console.log(data,"{}{}")
        return {
          ...defaultState.List,
          data: arr,
        };
      } catch (error) {
        return rejectWithValue({
          ...defaultReject,
          message: error.message,
        });
      }
    }
  );
 
  const VERIFICATIONS_MASTERS = createAsyncThunk(
    `chiefCompilenceMastersSlice/verifications`,
    async (payload = {}, { rejectWithValue }) => {
      try {
        const body = {
          db_name: dbName,
 
          filter: {
            type: ["VERIFICATIONSTATUS"],
            tenantid: __tenantid__,
            facilityid: getFacilityId(),
            lang: "en",
          },
          queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
        };
        const data = await fetchData(
          { body: JSON.stringify(body) },
          urls.readQdmQueries
        );
        let arr = [];
        data[0]?.VERIFICATIONSTATUS?.Value.map((val) => {
          arr.push({
            value: val._id,
            label: val?.display ?? "",
          });
        });
        return {
          ...defaultState.List,
          data: arr,
        };
      } catch (error) {
        return rejectWithValue({
          ...defaultReject,
          message: error.message,
        });
      }
    }
  );
 
  const CLINIC_STATUS_MASTERS = createAsyncThunk(
    `chiefCompilenceMastersSlice/clinicStatus`,
    async (payload = {}, { rejectWithValue }) => {
      try {
        const body = {
          db_name: dbName,
 
          filter: {
            type: ["ALCLINICALSTATUS"],
            tenantid: __tenantid__,
            facilityid: getFacilityId(),
            lang: "en",
          },
          queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
        };
        const data = await fetchData(
          { body: JSON.stringify(body) },
          urls.readQdmQueries
        );
        console.log(data,"clinicalmaster")
        let arr = [];
        data[0]?.ALCLINICALSTATUS?.Value.map((val) => {
          arr.push({
            value: val?._id,
            label: val?.display ?? "",
            masterId: val?._id,
          });
        });
        return {
          ...defaultState.List,
          data: arr,
        };
      } catch (error) {
        return rejectWithValue({
          ...defaultReject,
          message: error.message,
        });
      }
    }
  );
 
  const CC_MASTERS = createAsyncThunk(
    `chiefCompilenceMastersSlice/ccMasters`,
    async (payload = {}, { rejectWithValue }) => {
      try {
        const body = {
          db_name: dbName,
          queryid: "978aca30-3bbe-4f61-84d8-a89aa6f8a60e"
        }
        const data = await fetchData(
          { body: JSON.stringify(body) },
          urls.readQdmQueries
        );
        let arr = [];
        data.map((val) => {
          arr.push({
            value: val._id,
            label: `${val?.code} - ${val.shortdesc}`,
          });
        });
        return {
          ...defaultState.List,
          data: arr,
        };
      } catch (error) {
        return rejectWithValue({
          ...defaultReject,
          message: error.message,
        });
      }
    }
  );
  //....
 
  const ENC_BASED_CC = createAsyncThunk(
    `chiefCompilenceMastersSlice/enBasedCC`,
    async (payload = {}, { rejectWithValue, getState }) => {
      try {
        let state = getState();
        let ccState = state.chiefCompilenceMastersSlice;
        const { encounter_id } = payload;
        const body = {
          db_name: dbName,
          filter: {
            encounterid: encounter_id,
            tenantid: "",
            facilityid: "",
          },
          queryid: "4831d841-33aa-424e-ae78-086134623271", //queryid: "4831d841-33aa-424e-ae78-086134623271",
        };
        const data = await fetchData(
          { body: JSON.stringify(body) },
          urls.readQdmQueries
        );
        let res = ccgenerateResJson(data, ccState);
        console.log(res,"ccresjson_________")
        return {
          ...defaultState.List,
          data: res,
        };
      } catch (error) {
        return rejectWithValue({
          ...defaultReject,
          message: error.message,
        });
      }
    }
  );
 
  // const ENCOUNTER_BASED_CC = createAsyncThunk(
  //   `chiefCompilenceMastersSlice/encounterBasedCC`,
  //   async (payload = {}, { rejectWithValue, getState }) => {
  //     try {
  //       const { encounter_id } = payload;
  //       let state = getState();
  //       let ccState = state.chiefCompilenceMastersSlice;
  //       const data = await fetchData(
  //         { body: JSON.stringify(queries.encounterBasedCC(encounter_id)) },
  //         urls.readDocuments
  //       );
  //       let res = generateResJson(data.result, ccState);
  //       return {
  //         ...defaultState.List,
  //         data: res,
  //       };
  //     } catch (error) {
  //       return rejectWithValue({
  //         ...defaultReject,
  //         message: error.message,
  //       });
  //     }
  //   }
  // );
 
  const PATIENTID_BASED_CC = createAsyncThunk(
    `chiefCompilenceMastersSlice/patientIdBasedCC`,
    async (payload = {}, { rejectWithValue, getState }) => {
      try {
        const { patientId } = payload;
        let state = getState();
        let ccState = state.chiefCompilenceMastersSlice;
        const body = {
          db_name: dbName,
          filter: {
            patientid: patientId,
            tenantid: __tenantid__,
            facilityid: getFacilityId()
          },
          queryid: "0a935a09-f0b1-4b67-887d-4e059e2c8a0c"
         
        };
        const data = await fetchData(
          { body: JSON.stringify(body) },
          urls.readQdmQueries
        );
 
        let res = generateResJson(data, ccState);
        return {
          ...defaultState.List,
          data: res,
        };
      } catch (error) {
        return rejectWithValue({
          ...defaultReject,
          message: error.message,
        });
      }
    }
  );
 
  const SAVE_CHEIF_COMPAILANTS = createAsyncThunk(
    `chiefCompilenceMastersSlice/saveCC`,
    async (payload = {}, { rejectWithValue }) => {
      try {
        const { list, editIndex } = payload;
        let body = ccGenerateJSON(list,editIndex);
        console.log(body,"***")
        const data = await fetchData(
          { body: JSON.stringify(body) },
          urls.saveChiefComplaints
        );
        console.log(data,"***")
        return {
          ...defaultState.List,
          data: data,
        };
      } catch (error) {
        return rejectWithValue({
          ...defaultReject,
          message: error.message,
        });
      }
    }
  );
  //..
 
  const DELETE_CC = createAsyncThunk(
    `chiefCompilenceMastersSlice/deletecc`,
    async (payload = {}, { rejectWithValue }) => {
      try {
        const { id } = payload;
        const body = {
          "db_name": `${dbName}`,
          "entity": "CA_ChiefComplaints",
          "filter": `CA_ChiefComplaints._id =='${id}'`
      };
        const data = await fetchData(
          { body: JSON.stringify(body) },
          urls.delete_document
        );
        let arr = data;
        return {
          ...defaultState.List,
          data: arr,
        };
      } catch (error) {
        return rejectWithValue({
          ...defaultReject,
          message: error.message,
        });
      }
    }
  );
  //..
 
//   const GET_ENCOUNTER = createAsyncThunk(
//     `chiefCompilenceMastersSlice/encounter`,
//     async (payload = {}, { rejectWithValue }) => {
//       try {
//         const { id } = payload;
//         const body = {
//           db_name: dbName,
//           filter: {
//             encounterid: id,
//             tenantid: __tenantid__,
//             facilityid: getFacilityId()
//           },
//           queryid: "4831d841-33aa-424e-ae78-086134623271",
         
//         };
//         const data = await fetchData(
//           { body: JSON.stringify(body) },
//           urls.readQdmQueries
//         );
//         let arr = [];
//         data?.map((val) => {
//           let lang = val?.Patient?.[0]?.communicationLanguage?.map(
//             (val) => val.display
//           );
//           lang = lang ? lang : null;
//           arr.push({
//             ...val,
//             Organization_id: val.facilityid,
//             id: "EN-" + val?.encounter_id?.split("/")?.[1],
//             eId: val?.encounter_id,
//             type: val?.visit_type,
//             // speciality: val.practitioner_details?.[0]?.speciality?.coding?.[0]?.display || "",
//             speciality: val.practitioner_details?.[0]?.speciality?.display || "",
//             createdAt: val.createddate,
//             // name: val?.Patient?.[0]?.name?.[0]?.text ?? "",
//             name: makeName(val?.Patient?.name?.[0]),
//             pID: val?.Patient?.[0]?._id,
//             patientid: val?.Patient?.[0]?.id,
//             mrn: val?.Patient?.[0]?.patmrn,
//             gender: val?.clicnicalstatushistory?.[0]?.PersonID[0]?.gender,
//             dob: val?.clicnicalstatushistory?.[0]?.PersonID[0]?.birthdate,
//             pidentifier: val?.clicnicalstatushistory?.[0]?.PersonID[0]?.identifier[0]?.value ?? "",
//             lang: lang?.join(", "),
//             img: getImgUrl(val?.clicnicalstatushistory?.[0]?.PersonID[0]?.photo?.[0]?.fileid)
//               || getImgUrl(val?.Patient?.[0]?.photo?.[0]?.fileid),
//             facilityName: val.practitioner_details?.[0]?.clinic ?? "",
//           });
//         });
//         return {
//           ...defaultState.List,
//           data: arr[0] ?? {},
//         };
//       } catch (error) {
//         alert(error.message);
//         return rejectWithValue({
//           ...defaultReject,
//           message: error.message,
//         });
//       }
//     }
//   );
 
  const chiefCompilenceMastersSlice = createSlice({
    name: "chiefCompilenceMastersApiSlice",
    initialState: {
      relationship_masters: {
        ...defaultState.Info,
      },
      symptoms_masters: {
        ...defaultState.List,
      },
      Severity_masters: {
        ...defaultState.List,
      },
      bodysite_masters: {
        ...defaultState.List,
      },
      Verification_masters: {
        ...defaultState.List,
      },
      clinicalStatus_masters: {
        ...defaultState.List,
      },
      encBasedCC: {
        ...defaultState.List,
      },
      save_cc: {
        ...defaultState.Info,
      },
      cc_master_data: {
        ...defaultState.List,
      },
      delete_cc: {
        ...defaultState.Info,
      },
    //   encounter_Details: {
    //     ...defaultState.Info,
    //   },
      patientIdBasedCC: {
        ...defaultState.Info,
      },
    },
    extraReducers: {
      /* ENCOUNTER_BASED_CC */
      [ENC_BASED_CC.fulfilled]: (state, action) => {
          state.encBasedCC = action?.payload ?? {};
      },
      [ENC_BASED_CC.pending]: (state, action) => {
       state.encBasedCC.loading = true;
      },
      [ENC_BASED_CC.rejected]: (state, action) => {
       state.encBasedCC = action?.payload ?? {};
      },
 
      /* RELATIONSHIP_MASTERS */
      [RELATIONSHIP_MASTERS.fulfilled]: (state, action) => {
          state.relationship_masters = action?.payload ?? {};
      },
      [RELATIONSHIP_MASTERS.pending]: (state, action) => {
          state.relationship_masters.loading = true;
      },
      [RELATIONSHIP_MASTERS.rejected]: (state, action) => {
          state.relationship_masters = action?.payload ?? {};
      },
 
      /* SYMPTOMS_MASTERS */
      [SYMPTOMS_MASTERS.fulfilled]: (state, action) => {
        state.symptoms_masters = action?.payload ?? {};
      },
      [SYMPTOMS_MASTERS.pending]: (state, action) => {
        state.symptoms_masters.loading = true;
      },
      [SYMPTOMS_MASTERS.rejected]: (state, action) => {
        state.symptoms_masters = action?.payload ?? {};
      },
 
      /* SEVERITY_MASTERS */
      [SEVERITY_MASTERS.fulfilled]: (state, action) => {
        state.Severity_masters = action?.payload ?? {};
      },
      [SEVERITY_MASTERS.pending]: (state, action) => {
        state.Severity_masters.loading = true;
      },
      [SEVERITY_MASTERS.rejected]: (state, action) => {
        state.Severity_masters = action?.payload ?? {};
      },
 
      /* BODYSITE_MASTERS */
      [BODYSITE_MASTERS.fulfilled]: (state, action) => {
        state.bodysite_masters = action?.payload ?? {};
      },
      [BODYSITE_MASTERS.pending]: (state, action) => {
        state.bodysite_masters.loading = true;
      },
      [BODYSITE_MASTERS.rejected]: (state, action) => {
        state.bodysite_masters = action?.payload ?? {};
      },
 
      /* VERIFICATIONS_MASTERS */
      [VERIFICATIONS_MASTERS.fulfilled]: (state, action) => {
        state.Verification_masters = action?.payload ?? {};
      },
      [VERIFICATIONS_MASTERS.pending]: (state, action) => {
        state.Verification_masters.loading = true;
      },
      [VERIFICATIONS_MASTERS.rejected]: (state, action) => {
        state.Verification_masters = action?.payload ?? {};
      },
 
      /* CLINIC_STATUS_MASTERS */
      [CLINIC_STATUS_MASTERS.fulfilled]: (state, action) => {
        state.clinicalStatus_masters = action?.payload ?? {};
      },
      [CLINIC_STATUS_MASTERS.pending]: (state, action) => {
        state.clinicalStatus_masters.loading = true;
      },
      [CLINIC_STATUS_MASTERS.rejected]: (state, action) => {
        state.clinicalStatus_masters = action?.payload ?? {};
      },
 
      /* SAVE_CHEIF_COMPAILANTS */
      [SAVE_CHEIF_COMPAILANTS.fulfilled]: (state, action) => {
        state.save_cc = action?.payload ?? {};
      },
      [SAVE_CHEIF_COMPAILANTS.pending]: (state, action) => {
        state.save_cc.loading = true;
      },
      [SAVE_CHEIF_COMPAILANTS.rejected]: (state, action) => {
        state.save_cc = action?.payload ?? {};
      },
 
      /* CC_MASTERS */
      [CC_MASTERS.fulfilled]: (state, action) => {
        state.cc_master_data = action?.payload ?? {};
      },
      [CC_MASTERS.pending]: (state, action) => {
        state.cc_master_data.loading = true;
      },
      [CC_MASTERS.rejected]: (state, action) => {
        state.cc_master_data = action?.payload ?? {};
      },
 
      /* DELETE_CC */
      [DELETE_CC.fulfilled]: (state, action) => {
        state.delete_cc = action?.payload ?? {};
      },
      [DELETE_CC.pending]: (state, action) => {
        state.delete_cc.loading = true;
      },
      [DELETE_CC.rejected]: (state, action) => {
        state.delete_cc = action?.payload ?? {};
      },
 
    //   /* GET_ENCOUNTER */
    //   [GET_ENCOUNTER.fulfilled]: (state, action) => {
    //     state.encounter_Details = action?.payload ?? {};
    //   },
    //   [GET_ENCOUNTER.pending]: (state, action) => {
    //     state.encounter_Details.loading = true;
    //   },
    //   [GET_ENCOUNTER.rejected]: (state, action) => {
    //     state.encounter_Details = action?.payload ?? {};
    //   },
 
      /* PATIENTID_BASED_CC */
      [PATIENTID_BASED_CC.fulfilled]: (state, action) => {
        state.patientIdBasedCC = action?.payload ?? {};
      },
      [PATIENTID_BASED_CC.pending]: (state, action) => {
        state.patientIdBasedCC.loading = true;
      },
      [PATIENTID_BASED_CC.rejected]: (state, action) => {
        state.patientIdBasedCC = action?.payload ?? {};
      },
    },
  });
 
  const chiefCompilenceMastersActions = {
    RELATIONSHIP_MASTERS,
    SYMPTOMS_MASTERS,
    SEVERITY_MASTERS,
    BODYSITE_MASTERS,
    VERIFICATIONS_MASTERS,
    CLINIC_STATUS_MASTERS,
    ENC_BASED_CC,
    SAVE_CHEIF_COMPAILANTS,
    CC_MASTERS,
    DELETE_CC,
    //GET_ENCOUNTER,
    PATIENTID_BASED_CC,
  };
 
  export { chiefCompilenceMastersActions };
 
  export default chiefCompilenceMastersSlice;