import React from "react";
import { withStyles, Grid, Button } from "@material-ui/core";
import { Card, Avatar, H6, Text, Icons, Div } from "qdm-component-library";
// import { _calculateAge } from "../../utils";
import { UIColor } from "../../../../themes/theme";
import {useStyles} from "./styles";
import IdentificationDocuments from "../../../../assets/img/svg/icons8-identification-documents.svg";
import PhoneIcon from "../../../../assets/img/svg/icons8-phone.svg";
import UserIcon from "../../../../assets/img/svg/icons8-user.svg";
import mailIcon from "../../../../assets/img/svg/icons8-mail.svg";

const ENC_STATUS = {
  planned: "CodingMaster/10729",
  arrived: "CodingMaster/10723",
};

// const styles = (theme) => ({
//   root: {
//     //zIndex: 10,
//     position: "relative",
//     background: "#fff",
//     boxShadow: "none !important",
//     border: "1px solid #E0E0E0",
//     borderRadius: 9,
//     marginBottom: 10,
//   },
//   pDiv: {
//     padding: 10,
//   },
//   img: {
//     width: 16,
//     height: 16,
//     marginRight: 10,
//   },
//   show: {
//     width: "30px",
//     height: "30px",
//     border: "1px solid #E0E0E0",
//     borderRadius: "10px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     cursor: "pointer",
//   },
//   list: {
//     display: "flex",
//     color: "#6F6F6F",
//     fontSize: "12px",
//     padding: "8px 4px 0px",
//     "& span": {
//       fontSize: "12px",
//     },
//   },
//   overlap: {
//     position: "absolute",
//     right: 0,
//     bottom: 34,
//     zIndex: -1,
//   },
//   icons: {
//     "&:before": {
//       fontSize: "20px !important",
//     },
//   },
//   footer: {
//     display: "flex",
//     justifyContent: "space-around",
//     marginTop: "10px",

//     borderTop: "1px solid #E0E0E0",
//   },
//   modifyDivline: {
//     borderRight: "1px solid #E0E0E0",
//   },
//   modifyDiv: {
//     padding: "5px",
//     color: "#0071F2",
//     cursor: "pointer",
//     flex: 1,
//   },
//   cancelDiv: {
//     padding: "5px",
//     color: "#FF4D4A",
//     cursor: "pointer",
//     flex: 1,
//   },
//   appointname: {
//     fontSize: "14px",
//     fontFamily: "pc_semibold",
//   },
//   time: {
//     fontSize: "12px",
//     color: "#6F6F6F",
//     fontFamily: "pc_regular",
//     marginBottom: "10px",
//   },
//   DiVP: {
//     display: "flex",
//     justifyContent: "space-between",
//   },
// });

const SearchPatientList = (props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     allMasters: [],
  //   };

  // }
  const [allMasters, setAllMasters] = React.useState([]);
  // componentDidMount() { }
  const classes = useStyles(props);
  // render() {
  const {
    parent_id,
    name = "#---",
    letter = "A",
    pid = "#---",
    gendar = "#---",
    email = "#---@.co",
    mobile = "#---xx",
    imgUrl = "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
    appointmentName,
    date,
    status,
    appStatus,
  } = props;

  const canModify =
    status &&
    (appStatus?._id
      ? appStatus?._id === ENC_STATUS.arrived ||
        appStatus?._id === ENC_STATUS.planned
      : true);

  return (
    <Card className={classes.root1} id={`${parent_id}_member_list`}>
      <Div className={classes.pDiv}>
        {appointmentName && (
          <Div className={classes.DiVP}>
            <Div>
              <Div className={classes.appointname}>{appointmentName}</Div>
              <Div className={classes.time}>{date}</Div>
            </Div>
            <Div
              id={`${parent_id}_view`}
              className={classes.show}
              onClick={() => props.openView()}
            >
              <Icons
                className={classes.icons}
                id={`${parent_id}_referabanner_eye_icons`}
                inLineStyles={{
                  color: UIColor.primaryText.color,
                  fontSize: "20px !important",
                }}
                fontIcon={"eye"}
                ariaHidden={"true"}
                size={"large"}
              />
            </Div>
          </Div>
        )}
        <img className={classes.overlap} src={UserIcon} />
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          id={`${parent_id}_row`}
          spacing={2}
        >
          <Grid item xs={2}>
            <Avatar
              id={`${parent_id}_image`}
              style={{ padding: 10 }}
              className="pl-details-avatar"
              src={imgUrl}
              letter={letter}
              variant="rounded"
            />{" "}
          </Grid>
          <Grid item xs={8}>
            <H6
              inLineStyles={{
                fontSize: 15,
                marginBottom: -4,
                marginLeft: 12,
                fontFamily: "pc_medium !important",
              }}
            >
              {name}
            </H6>
            <Text style={{ fontSize: 12, color: "#6F6F6F" }}>
              {gendar && " "}
            </Text>
          </Grid>
          {!appointmentName && (
            <Grid item xs={2}>
              <Div
                id={`${parent_id}_view`}
                className={classes.show}
                onClick={() => props.handleMoveList()}
              >
                <Icons
                  className={classes.icons}
                  id={`${parent_id}_referabanner_eye_icons`}
                  inLineStyles={{
                    color: "black",
                    fontSize: "20px !important",
                  }}
                  fontIcon={"eye"}
                  ariaHidden={"true"}
                  size={"large"}
                />
              </Div>
            </Grid>
          )}
        </Grid>
        <Div id={`${parent_id}_pid_div`} className={classes.list}>
          <img
            id={`${parent_id}_pid`}
            className={classes.img}
            src={IdentificationDocuments}
            alt="id"
          />
          <Text id={`${parent_id}_text`} className="pc_regular">
            {pid}
          </Text>
        </Div>

        <Div id={`${parent_id}__mobile_div`} className={classes.list}>
          <img
            id={`${parent_id}_mobile`}
            className={classes.img}
            src={PhoneIcon}
            alt="id"
          />
          <Text id={`${parent_id}_text`} className="pc_regular">
            {mobile}
          </Text>
        </Div>
        {email ? (
          <Div id={`${parent_id}__mobile_div`} className={classes.list}>
            <img
              id={`${parent_id}_mobile`}
              className={classes.img}
              src= {mailIcon}
              alt="id"
            />
            <Text id={`${parent_id}_text`} className="pc_regular">
              {email}
            </Text>
          </Div>
        ) : (
          ""
        )}
      </Div>
      {appointmentName && (
        <Div className={classes.footer}>
          <Button
            className={classes.modifyDiv}
            onClick={() => props.handleModify()}
            disabled={!canModify}
          >
            Modify
          </Button>
          <Div className={classes.modifyDivline}></Div>
          <Button
            className={classes.cancelDiv}
            onClick={() => props.handleCancel()}
            disabled={!canModify}
          >
            Cancel
          </Button>
        </Div>
      )}
    </Card>
  );
  // }
};

export default SearchPatientList;
