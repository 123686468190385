import React from "react";

export const ReAssignPractitioner = (props) => {
  let width = props.width ? props.width : "16";
  let height = props.height ? props.height : "17.067";
  let color = props.color ? props.color : "#5f84c0";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 17.067"
      {...props}
    >
      <path id="icons8-repeat" d="M17.836,3.984a.632.632,0,0,0-.585.4.646.646,0,0,0,.145.7L18.844,6.55H7.316A2.335,2.335,0,0,0,5,8.9V15.78L6.263,14.5V8.9A1.06,1.06,0,0,1,7.316,7.83H18.844L17.4,9.3a.645.645,0,0,0,0,.9.626.626,0,0,0,.893,0l2.526-2.559a.646.646,0,0,0,0-.9L18.289,4.178a.628.628,0,0,0-.453-.194ZM21,9.262l-1.263,1.279v5.6a1.06,1.06,0,0,1-1.053,1.066H7.156L8.6,15.746a.646.646,0,0,0,.141-.708.627.627,0,0,0-1.034-.2L5.185,17.4a.646.646,0,0,0,0,.9l2.526,2.559a.626.626,0,0,0,.893,0,.646.646,0,0,0,0-.9L7.156,18.492H18.684A2.335,2.335,0,0,0,21,16.146Z"
        transform="translate(-5 -3.984)"
        fill={color}
    />
    </svg>
  );
};

