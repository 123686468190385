import moment from "moment";

import jwtDecode from "jwt-decode";

export const createVitalsJson = (props, searchPractioner, encounter) => {
    // const { patientid, orgid, encounterid } = props;
    //let personDetails = searchPractioner?.data?.[0];
    let infos = jwtDecode(localStorage.getItem("UserInfo"))

    // let searchPract = personDetails?.practitioner?.[0];
    return {
        // "orgentitytype": "string",
        "orgid":infos?.facilityid,
        "patientid": encounter?.patient_id || encounter?.patientId,
        "confirmedby": infos?.practitionerid,
        "tenantid":process.env.REACT_APP_TENANTID,
        "facilityid":infos?.facilityid,
        "encountertype": "CodingMaster/10164",
        "encounterid": encounter?._id || encounter?.encounterID,

    }
}