import React, { useState, useEffect } from "react";

import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";

import { Col, Div, Row, TextInput } from "qdm-component-library";

import {
  AlertProps,
  AxiosCall,
  calculateAge,
  checkWithCasbin,
  getCalculatedAge,
  getDateAndTime,
  getUnixCodeDate,
  makeName,
  urls,
  utcTOLocal,
} from "../../utils";

import { useDispatch, useSelector } from "react-redux";

import actions from "../../redux/actions";
import { useStyles } from "./styles";

import { UIColor } from "../../themes/theme";

import { useNavigate, useLocation } from "react-router-dom";

import withAllContexts from "../../hoc/withAllContexts";

import withAppBar from "../../hoc/withAppBar";

import { AppRoutes } from "../../router/routes";

import dolarIcon from "../../assets/img/svg/Group 90507.svg";

import axios from "axios";

import jwtDecode from "jwt-decode";
import {
  displayTable1,
  displayTable2,
  validate,
} from "../cashierBillingDetails/components/constructJson";
import TableComponent from "./components/payment";
import CollectionSummary from "./components/collections";
import moment from "moment";
import { PatientCareTypeCard } from "../../components";
import PatientCard from "../patientDashboard/components/patientCard";
import dollarIcon from "../../assets/img/svg/Group 90507.svg";
import numberToWords from 'number-to-words';
const casbinjs = require("casbin.js");

const steps = ["Patient Order Details", "Generate Bill & Capture Settlement"];

const PaymentCollectionDetails = (props) => {

  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const practitionerId = decodedUserinfo?.practitionerid;

  const [loading, setLoading] = useState(false);
  const [totCollectAmt, setTotCollectAmt] = useState(0);
  const [patientNames, setPatentNames] = useState(0);
  const [cashierdatas, setCashierdatas] = useState({
    savedata: [],
  });
  const [isPaymentEqual, setIsPaymentEqual] = useState(false);
  const [collectionsData, setCollectionsData] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [state, setState] = useState({});
  const getFinanceData = async () => {
    const patientId = LoadpatientData?.state?.data?.patientId;
    const chequeNo = await dispatch(actions.RECIEPT_NO());

    setReceipt({
      ...receipt,

      receiptNo: chequeNo?.payload?.data?.Result?.receiptno,

      date: getDateAndTime(new Date(new Date()) / 1000),
    });
    // const fin_data = await dispatch(actions.GET_FINANCIAL({ patientId }));

    // console.log(fin_data, "findata");

    // if (fin_data?.payload?.data) {
    //   const fin_DATA = fin_data?.payload?.data?.map((v) => {
    //     const setdata = v?.payertype;

    //     return {
    //       pic: { dolarIcon },

    //       name: null,

    //       care_type: setdata?.guarantorid,

    //       //title_name: setdata?.receivertype + ", " + setdata?.policyid,

    //       title_name: setdata?.longdesc + ", " + setdata?.shortdesc,
    //     };
    //   });

    //   setSelectOption(fin_DATA[0]);

    //   setFinData(fin_DATA);

    //   // this.setState({

    //   //   selectOption: fin_DATA[0],

    //   //   fin_data: fin_DATA,

    //   // });
    // }
  };

  async function getPatientInfo() {
    let permission_ = await checkWithCasbin(["patient_dashboard"]);
    let roleData = localStorage.getItem("RoleData");
    let decodedRoleData = jwtDecode(roleData);
    const accessObj = decodedRoleData?.access || [];
    const permission = { callcenter: accessObj };
    const authorizer = new casbinjs.Authorizer("manual", permission);
    authorizer.setPermission(permission);
    const result = await authorizer.can("callcenter", "4");
    let dataList = await checkWithCasbin(["patient_dashboard"]);
    const patientInfo = await dispatch(
      actions.PATIENT_INFO({
        patientid: location?.state?.patientId || location?.state?.patientid,
        tenantid: "",
        facilityid: "",
        lang: "",
      })
    );

    //FIXME -   fetchInfo();

    const fin_data = patientInfo;
    if (fin_data?.payload?.data) {
      const fin_DATA = fin_data?.payload?.data?.financialdetails?.map((v) => {
        //const setdata = v?.payersprioritycoverage?.[0];
        return {
          pic: dollarIcon,
          name: null,
          care_type: v?.payer?.longdesc,
          title_name: v?.payertype?.longdesc,
          priority: v?.priority,
          policyexpiry: v?.policyexpiry? utcTOLocal(v?.policyexpiry,"DD-MM-YYYY") : "",
          policy:v?.policyid?.longdesc,
          payer: v?.payer?._id
        };
      });
      const findata = fin_DATA//fin_DATA?.reverse();

      // oustanding data
      const Outstanding = await dispatch(
        actions.GET_OUTSTANDING_DATA(
          location?.state?.patientId || location?.state?.patientid
        )
      );
      const personDetails = await dispatch(
        actions.PATIENT_DETAILS_READ({
          key: patientInfo?.payload?.data?.patientdetails?.personid,
        })
      );
      setState((prevState) => ({
        ...prevState,
        permissionData: permission_,
        callcen: result,
        permission: dataList,
        selectOption: fin_DATA?.[0] || {},
        fin_data: findata || [],
        newPatientDetails: patientInfo?.payload?.data?.patientdetails,
        loading: false,
        allPatientDetails: patientInfo?.payload?.data,
        OutstandingData: 0,
        advanceAmount: Outstanding?.payload?.data?.[0]?.advanceamount,
        //           id="standard-full-width"
        personDetails: personDetails?.payload?.data?.[0],
      }));
    }
  }

  useEffect(() => {
    getTableData();
    getPatientInfo();
    getFinanceData();
  }, []);

  const location = useLocation();

  const LoadpatientData = "";

  // const encounter_id = LoadpatientData?.state?.data?.encounter[counter]?._id;

  const encounterData = null;

  const classes = useStyles();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [encounterNo, setEncounterNo] = useState("");

  const [activeStep, setActiveStep] = useState(0);

  const [addNewSwitch, setAddNewSwitch] = useState(false);

  const [displayOrders, setDisplayOrders] = useState([]);
  const [unBilledCheckBox, setUnBilledCheckBox] = useState(false);

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [paymentOrders, setPaymentOrders] = useState([]);

  const [allChecked, setAllChecked] = useState(false);

  const [newAddedOrder, setNewlyAdded] = useState([]);

  const [counter, setCounter] = useState(0);

  const [codeSearch, setCodeSearch] = useState("");

  const [standardTableorders, setStandardTableorders] = useState([]);

  const [billNo, setBillNo] = useState({
    billNum: null,

    date: "",
  });

  const [calculationResultData, setCalculationResultData] = useState({
    calculationResult: [],
  });

  const [sharePercent, setSharePercent] = useState({
    discountAmnt: 0,

    patientShare: 100,
  });

  const [payer, setPayer] = useState("");

  const [paymentDetails, setPaymentDetails] = useState({
    paymentType: "",

    settleNow: false,

    settleNowValue: "",

    balanceDue: 0.0,

    docRefNo: "",

    docRefDate: 0,

    remarks: "",

    card: "",

    expiry: "",
  });

  const [receipt, setReceipt] = useState({
    receiptNo: null,

    date: "",
  });
  const [roundOffAmnt, setRoundOffAmnt] = useState({
    patientShareFinal: 0.0,

    roundOff: 0.0,
  });

  const [selectOption, setSelectOption] = useState([]);

  const [finData, setFinData] = useState([]);

  const [allMasters, setAllMasters] = useState([]);

  const [chargeDropDwn, setChargeDropDwn] = useState([]);

  const [uomDropDwn, setUomDropDwn] = useState([]);
  const [isExecuted, setIsExecuted] = useState(false);
  // const [isDisable, setIsDisable] = useState(false);
  // Handle Payment Details
  const handlePayementDetails = async (e, type) => {
    if (type === "settleNowValue") {
      const balance = calculationResultData?.patientShare - e;

      setPaymentDetails({
        ...paymentDetails,

        [type]: e,

        balanceDue: balance,

        settleNowValue: calculationResultData?.patientShare,
      });
    } else if (type === "docRefDate" || type === "expiry") {
      if (type === "expiry") {
        const mon = e.getMonth();

        const year = e.getFullYear();

        const expiryDate = new Date(
          new Date(year, mon + 1, 0).setHours(23, 59, 59, 999)
        );

        const date = new Date(expiryDate) / 1000;

        setPaymentDetails({ ...paymentDetails, [type]: Math.floor(date) });
      } else {
        const date = new Date(e) / 1000;

        setPaymentDetails({ ...paymentDetails, [type]: date });
      }
    } else {
      setPaymentDetails({ ...paymentDetails, [type]: e });
    }

    if (type === "settleNow" && e === true && receipt?.receiptNo === null) {
      const chequeNo = await dispatch(actions.RECIEPT_NO());

      setReceipt({
        ...receipt,

        receiptNo: chequeNo?.payload?.data?.Result?.receiptno,

        date: getDateAndTime(new Date(new Date()) / 1000),
      });
    }
  };

  const onIncrement = async () => {
    const currentCount = counter;

    if (LoadpatientData?.state?.data?.encounter.length - 1 > currentCount) {
      const setCount = currentCount + 1;

      const patientId = LoadpatientData?.state?.data?.patientId;

      const encounterId =
        LoadpatientData?.state?.data?.encounter[setCount]?._id ?? "";

      setCounter(setCount);

      const data = await dispatch(
        actions.GET_COLLECTIONS_TABLE_DATA({
          patientId: location?.state?.patientid,
        })
      );

      const tableData = displayTable1(data?.payload?.data ?? []);

      setStandardTableorders(tableData);

      setDisplayOrders(tableData);
    }
  };

  const onDecrement = async () => {
    const currentCount = counter;

    if (currentCount > 0) {
      const setCount = currentCount - 1;

      const patientId = LoadpatientData?.state?.data?.patientId;

      const encounterId =
        LoadpatientData?.state?.data?.encounter[setCount]?._id ?? "";

      setCounter(setCount);

      const data = await dispatch(
        actions.GET_COLLECTIONS_TABLE_DATA({
          patientId: location?.state?.patientid,
        })
      );

      const tableData = displayTable1(data?.payload?.data ?? []);

      setStandardTableorders(tableData);

      setDisplayOrders(tableData);
    }
  };

  React.useEffect(() => {
    const newSelectedOrders = displayOrders.filter(
      (val, i) => val?.checked === true
    );

    if (newAddedOrder.length > 0) {
      newAddedOrder.map((val, i) => newSelectedOrders.push(val));

      setSelectedOrders(newSelectedOrders);
    } else if (codeSearch.length > 0 && newSelectedOrders?.length > 0) {
      setSelectedOrders(newSelectedOrders);
    } else if (codeSearch.length > 0) {
      console.log("nothing is happened");
    } else {
      setSelectedOrders(newSelectedOrders);
    }
  }, [codeSearch.length, displayOrders, newAddedOrder]);

  React.useEffect(() => {
    getAllMasters();

    ChangeCodeDropDown();

    UomCodeDropDown();
  }, []);

  const getAllMasters = async () => {
    const response = await dispatch(actions.GET_ALL_MASTERS(""));

    setAllMasters(response?.payload?.data);
  };

  const ChangeCodeDropDown = async () => {
    const chargeDropDwn = await dispatch(actions.CHARGE_CODE_DROPDOWN());

    setChargeDropDwn(chargeDropDwn?.payload?.data);
  };

  const UomCodeDropDown = async () => {
    const uomDropDown = await dispatch(actions.UOM_DROPDOWN());

    setUomDropDwn(uomDropDown?.payload?.data);
  };

  // initail Table Date

  const getTableData = async () => {
    const patientId = LoadpatientData?.state?.data?.patientId;

    const encounterId =
      LoadpatientData?.state?.data?.encounter[counter]?._id ?? "";

    setEncounterNo(encounterId);

    if (location?.state?.isAdvancePayment) {
      const data = await dispatch(
        actions.GET_ADVANCE_AMOUNT_DATA({
          patientAdvance: [location?.state?._id],
        })
      );
      const tableData = displayTable2(data?.payload?.data ?? []);
      setSelectedOrders(tableData);
      // setDisplayOrders(tableData);

      setStandardTableorders(tableData);
    } else {
      const data = await dispatch(
        actions.GET_COLLECTIONS_TABLE_DATA({
          patientId: location?.state?.patientid,
        })
      );
      const tableData = displayTable1(data?.payload?.data ?? []);
      setSelectedOrders(tableData);
      // setDisplayOrders(tableData);

      setStandardTableorders(tableData);
    }
  };

  const setTheTableData = useSelector(
    (state) => state?.billSettlementSlice?.table_data_read
  );

  // Handling the unbilled charges checkBox

  const handleUnBilled = (e) => {
    setUnBilledCheckBox(e.target.checked);

    const unBilledOrders = displayOrders.filter(
      (val, i) => val.billed === false
    );

    const standardUnBilled = standardTableorders.filter(
      (val, i) => val.billed === false
    );

    if (!e.target.checked) {
      let displayTableData = displayTable1(setTheTableData?.data?.result ?? []);

      setDisplayOrders(displayTableData);

      setStandardTableorders(displayTableData);
    } else {
      setDisplayOrders(unBilledOrders);

      setStandardTableorders(standardUnBilled);
    }
  };

  const [roundOffval, setRoundOff] = useState(0.0);

  // Proceed Button

  const handleProceed = async () => {
    if (true) {
      setActiveStep(activeStep + 1);

      const orgId = localStorage.getItem("nameOfOrg");

      // const payerDropDwn = await dispatch(actions.PAYER_DROPDOWN());

      // const paymentDropDown = await dispatch(actions.PAYMENT_DROPDOWN());

      const roundOffValue = await dispatch(actions.BILL_ROUND_OFF({ orgId }));

      setRoundOff(roundOffValue?.payload?.data?.[0]?.parametervalue);

      if (billNo?.billNum === null) {
        const billNom = await dispatch(actions.BILL_NO());

        setBillNo({
          ...billNo,

          billNum: billNom?.payload?.data?.Result?.billno ?? null,

          date: getDateAndTime(new Date(new Date()) / 1000),
        });
      }
    } else {
      props.alert.setSnack({
        open: true,

        severity: AlertProps.severity.error,

        msg: "Please select atleast one order",

        vertical: AlertProps.vertical.top,

        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  // Previous Button

  const handlePrevious = () => {
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1);

      setAddNewSwitch(false);

      const oldSelectedOrder = selectedOrders.filter(
        (val, i) => val?.isNew === undefined
      );

      const isChecked = oldSelectedOrder.filter(
        (val, i) => val?.checked === true
      );

      const unBilledDisplayOrder = displayOrders.filter(
        (val, i) => val?.billed === false
      );

      if (isChecked.length === unBilledDisplayOrder.length) {
        setAllChecked(true);
      } else {
        setAllChecked(false);
      }
    }
  };

  // Add new Button

  const handleAddnew = () => {
    setAddNewSwitch(true);
  };

  // Add New order

  const handleAddNewOrder = (data) => {
    const addNewOrder = JSON.parse(JSON.stringify(selectedOrders));

    addNewOrder.push(data);

    const newlyAdded = addNewOrder.filter((val, i) => val?.isNew === true);

    setNewlyAdded(newlyAdded);

    setSelectedOrders(addNewOrder);

    setAddNewSwitch(false);
  };

  // Close New Order Bar

  const handleRemoveAddnew = () => {
    setAddNewSwitch(false);
  };

  // handle Check Box
  const handleCheckBox = (e, row, rowIndex) => {
    let newOrders = JSON.parse(JSON.stringify(selectedOrders));
    if (!e.target.checked) {
      let sumVal = newOrders.reduce(
        (a, v) => (a = a + Number(v.CollectionAmount)),
        0
      );
      sumVal = sumVal - Number(row.CollectionAmount);
      const totalPayment = collectionsData.reduce(
        (total, item) => total + Number(item.totalPayAmount),
        0
      );
      if (totalPayment > sumVal) {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Collection amount should not greater then selected bill amount.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return;
      }
    }

    let standardTableOrdersData = JSON.parse(
      JSON.stringify(standardTableorders)
    );
    const standardIndex = standardTableOrdersData.findIndex(
      (v) => v?.patientid === row?.patientid
    );

    if (location?.state?.isAdvancePayment) {
      if (
        row?.advamount !== undefined &&
        row?.advamount !== null &&
        row?.advamount !== 0
      ) {
        newOrders[rowIndex].CollectionAmount = e.target.checked
          ? row?.advamount
          : 0;
        standardTableOrdersData[standardIndex].CollectionAmount = e.target
          .checked
          ? row?.advamount
          : 0;
      }
    } else {
      if (
        row?.balanceamount !== undefined &&
        row?.balanceamount !== null &&
        row?.balanceamount !== 0
      ) {
        newOrders[rowIndex].CollectionAmount = e.target.checked
          ? row?.balanceamount
          : 0;
        standardTableOrdersData[standardIndex].CollectionAmount = e.target
          .checked
          ? row?.balanceamount
          : 0;
      }
    }

    newOrders[rowIndex].checked = e.target.checked;
    standardTableOrdersData[standardIndex].checked = e.target.checked;

    const total = newOrders.reduce(
      (a, v) => (a = a + Number(v.CollectionAmount)),
      0
    );
    const names = [...new Set(newOrders.map((item) => item.patientname))];
    setPatentNames(names.join());
    setTotCollectAmt(total);
    setSelectedOrders(newOrders);
    setPaymentOrders(newOrders);
    setStandardTableorders(standardTableOrdersData);
  };

  // calculated selectedOrders

  const handleCalculations = (results, rowIndex) => {
    if (
      typeof results === "object" &&
      !Array.isArray(results) &&
      results !== null
    ) {
      const editedText = JSON.parse(JSON.stringify(selectedOrders));

      editedText.splice(rowIndex, 1, results);

      setSelectedOrders(editedText);
    } else {
      setSelectedOrders(results);
    }
  };

  // select all unbilled orders

  const handleHeaderCheckBox = (e) => {
    let newOrders = JSON.parse(JSON.stringify(selectedOrders));
    if (!e.target.checked) {
      const totalPayment = collectionsData.reduce(
        (total, item) => total + Number(item.totalPayAmount),
        0
      );

      if (totalPayment > 0) {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Collection amount should not greater then selected bill amount.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return;
      }
    }
    let standardTableOrdersData = JSON.parse(
      JSON.stringify(standardTableorders)
    );
    newOrders.map((val, i) => {
      if (val.billed === false) {
        return (val.checked = e.target.checked);
      } else {
        return (val.checked = false);
      }
    });

    if (location?.state?.isAdvancePayment) {
      newOrders.map((s, i) => {
        if (
          s?.advamount !== undefined &&
          s?.advamount !== null &&
          s?.advamount !== 0
        ) {
          newOrders[i].CollectionAmount = s.checked ? s.advamount : 0;
          standardTableOrdersData[i].CollectionAmount = s.checked
            ? s.advamount
            : 0;
        } else {
          return (s.checked = false);
        }
      });
    } else {
      newOrders.map((s, i) => {
        if (
          s?.balanceamount !== undefined &&
          s?.balanceamount !== null &&
          s?.balanceamount !== 0
        ) {
          newOrders[i].CollectionAmount = s.checked ? s.balanceamount : 0;
          standardTableOrdersData[i].CollectionAmount = s.checked
            ? s.balanceamount
            : 0;
        } else {
          return (s.checked = false);
        }
      });
    }

    setAllChecked(e.target.checked);

    const total = newOrders.reduce(
      (a, v) => (a = a + Number(v.CollectionAmount)),
      0
    );
    const names = [...new Set(newOrders.map((item) => item.patientname))];
    setPatentNames(names.join());
    setTotCollectAmt(total);
    setSelectedOrders(newOrders);
    setPaymentOrders(newOrders);
    setStandardTableorders(newOrders);
  };

  // deleting the selected orders

  const handleDeleteSelected = (row, rowIndex) => {
    const selectedOrder = displayOrders?.filter((val, i) => val.id === row.id);

    const selectedIndex = displayOrders?.indexOf(selectedOrder[0]);

    if (selectedIndex > -1) {
      let newOrders = JSON.parse(JSON.stringify(displayOrders));

      newOrders[selectedIndex].checked = false;

      setDisplayOrders(newOrders);

      setStandardTableorders(newOrders);
    } else {
      const newOrder = JSON.parse(JSON.stringify(selectedOrders));

      newOrder.splice(rowIndex, 1);

      setSelectedOrders(newOrder);
    }
  };

  // Onchange of the discount and the patient Share

  //   const handleTableTextChange = (e, row, rowIndex, type) => {
  //     console.log('====================================');
  //     console.log(e,"e", row,"row", rowIndex,"i", type);
  //     console.log('====================================');
  //     const isNumber = Number.isNaN(row?.creditShare);

  //     const discounted = Number.isNaN(row?.dicount);

  //     const totalCounted = Number.isNaN(row?.CollectionAmount);

  //     const total =
  //       (discounted ? 0 : row?.dicount) + (totalCounted ? 0 : row?.patientShare);

  //     const patientShareValue = () => {
  //       if (
  //         Number(e.target.value) > Number(row?.net) &&
  //         type === "patientShare"
  //       ) {
  //         return false;
  //       } else if (
  //         type === "dicount" &&
  //         Number(row?.gross) <= Number(row?.patientShare)
  //       ) {
  //         return true;
  //       } else if (
  //         type === "dicount" &&
  //         Number(row?.gross) <= Number(e.target.value)
  //       ) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     };

  //     if (
  //       e.target.value >= 0 &&
  //       e.target.value !== "-" &&
  //       total <= Number(row?.gross) &&
  //       patientShareValue()
  //     ) {
  //       if (type === "dicount") {
  //         const editOrders = JSON.parse(JSON.stringify(selectedOrders));

  //         editOrders[rowIndex][type] = parseFloat(e.target.value);

  //         editOrders[rowIndex]["patientShare"] =
  //           e.target.value > 0
  //             ? ((row?.gross - e.target.value) / 100) * sharePercent?.patientShare
  //             : row?.gross - e.target.value;

  //         editOrders[rowIndex] = { ...editOrders[rowIndex], isEdited: true };

  //         setSelectedOrders(editOrders);
  //       } else {
  //         const editOrders = JSON.parse(JSON.stringify(selectedOrders));

  //         editOrders[rowIndex][type] = parseFloat(e.target.value);

  //         editOrders[rowIndex] = { ...editOrders[rowIndex], isEdited: true };

  //         setSelectedOrders(editOrders);
  //       }
  //     } else {
  //       props.alert.setSnack({
  //         open: true,

  //         severity: AlertProps.severity.error,

  //         msg: "The credit share cant be a negative value",

  //         vertical: AlertProps.vertical.top,

  //         horizontal: AlertProps.horizontal.right,
  //       });
  //     }
  //   };

  // const handleTableTextChange = (e, row, rowIndex, type) => {
  //   const editOrders = JSON.parse(JSON.stringify(selectedOrders));
  //   editOrders[rowIndex][type] = parseFloat(e.target.value);
  //   editOrders[rowIndex] = { ...editOrders[rowIndex], isEdited: true };
  //   setPaymentOrders(editOrders);
  //   setSelectedOrders(editOrders);
  // };
  const handleTableTextChange = (e, row, rowIndex, type) => {
    const inputValue = parseFloat(e.target.value);
    if (inputValue > row?.balanceamount) {
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "please give less than balance amount.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    }

    const editOrders = JSON.parse(JSON.stringify(selectedOrders));
    editOrders[rowIndex][type] = inputValue;
    editOrders[rowIndex] = { ...editOrders[rowIndex], isEdited: true };

    const total = editOrders.reduce(
      (a, v) => (a = a + Number(v.CollectionAmount)),
      0
    );
    setTotCollectAmt(total);
    setPaymentOrders(editOrders);
    setSelectedOrders(editOrders);
  };

  // The Billing component Switch

  //   const getStepperComponents = (activeStep) => {
  //     switch (activeStep) {
  //       case 0:
  //         return (
  //           <ViewBilling
  //             displayOrders={displayOrders}
  //             handleCheckBox={handleCheckBox}
  //             handleHeaderCheckBox={handleHeaderCheckBox}
  //             allChecked={allChecked}
  //             codeSearch={codeSearch}
  //             standardTableorders={standardTableorders}
  //           />
  //         );

  //       case 1:
  //         return (
  //           <BillingSettlement
  //             addNewSwitch={addNewSwitch}
  //             setSharePercent={setSharePercent}
  //             sharePercent={sharePercent}
  //             handleRemoveAddnew={handleRemoveAddnew}
  //             selectedOrders={selectedOrders}
  //             handleCalculations={handleCalculations}
  //             handleAddNewOrder={handleAddNewOrder}
  //             handleDeleteSelected={handleDeleteSelected}
  //             handleTableTextChange={handleTableTextChange}
  //             billNo={billNo}
  //             setPaymentDetails={setPaymentDetails}
  //             setCalculationResultData={setCalculationResultData}
  //             calculationResultData={calculationResultData}
  //             handlePayer={handlePayer}
  //             payer={payer}
  //             handlePayementDetails={handlePayementDetails}
  //             paymentDetails={paymentDetails}
  //             receipt={receipt}
  //             roundOffval={roundOffval}
  //             setRoundOffAmnt={setRoundOffAmnt}
  //             roundOffAmnt={roundOffAmnt}
  //             allMasters={allMasters}
  //             chargeDropDwn={chargeDropDwn}
  //             uomDropDwn={uomDropDwn}
  //             encounterId={encounterNo}
  //           />
  //         );

  //       default:
  //         return "Unknown step";
  //     }
  //   };

  // cancel the billing Process

  const handleCancel = () => {
    navigate(AppRoutes.dashboard);
  };

  // Search code and description in the display table

  const handleCodeSearch = async (value) => {
    const patientId = LoadpatientData?.state?.data?.patientId;

    const encounterId =
      LoadpatientData?.state?.data?.encounter[counter]?._id ?? "";

    setCodeSearch(value);

    const data = await dispatch(
      actions.SEARCH_CODE_DESC({ patientId, encounterId, value })
    );

    const constructedData = displayTable1(data?.payload?.data);

    const newData = constructedData.map(
      (val) =>
        standardTableorders.find((v) => v.patientid === val.patientid) || val
    );

    setDisplayOrders(newData);
  };

  //handle Payer

  const handlePayer = (e) => {
    setPayer(e);
  };

  const billingDetails = selectedOrders?.map((val, i) => {
    if (typeof val === "object" && !Array.isArray(val) && val !== null) {
      const billrefno = {
        billrefno: " ",
      };

      return { ...val, ...billrefno };
    } else {
      return val;
    }
  });

  const billDetailSave = billingDetails.map((val) => {
    return {
      _id: "",

      dispenseid: val?.orderdispenseid ? val?.orderdispenseid : " ",

      patientid: LoadpatientData?.state?.data?.patientId,

      encounterid: encounterNo,

      billrefno: "",

      patchargetrxid: val?.id ? val?.id : " ",

      orderrefid: val?.orderrefid ? val?.orderrefid : " ",

      grossamt: Number(val?.gross),

      discamt: Number(val?.dicount),

      netamt: Number(val?.net),

      patientshare: Number(val?.patientShare),

      payershare: Number(val?.creditShare),

      billdstatus: true,

      chargecodeid: val?.chargecodeid,

      billqty: parseInt(val?.qty),

      billrate: val?.rate,
    };
  });

  const successfun2 = async () => {
    let payload = {
      ticketId: location?.state?.ticketId,

      userAction: location?.state?.actionName,
    };

    let token = localStorage.getItem("JwtToken");

    let register = await axios({
      method: "POST",

      headers: {
        "Content-Type": "application/json",

        jwtToken: `${token}`,
      },

      url: process.env.REACT_APP_PERFORMUSERACTION_API,

      data: payload,
    })
      .then((res) => {
        // if (res.status === 200 && !res.data.error) {
        // props?.alert?.setSnack({
        //   open: true,
        //   severity: AlertProps.severity.success,
        //   msg: "Approved successfully",
        //   vertical: AlertProps.vertical.top,
        //   horizontal: AlertProps.horizontal.center,
        //   tone: true,
        // });
        // } else {
        //   props?.alert?.setSnack({
        //     open: true,
        //     severity: AlertProps.severity.error,
        //     msg: res.data.message,
        //     vertical: AlertProps.vertical.top,
        //     horizontal: AlertProps.horizontal.center,
        //     tone: true,
        //   });
        // }
      })

      .catch((error) => {
        // props?.alert?.setSnack({
        //   open: true,
        //   severity: AlertProps.severity.error,
        //   msg: "Please contact to admin",
        //   vertical: AlertProps.vertical.top,
        //   horizontal: AlertProps.horizontal.center,
        //   tone: true,
        // });
      });

    // }
  };
  const generateAndOpenJasperReport = async (Id) => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const reportId =
      location?.state?.IsFrom === "Outstanding"
        ? process.env.REACT_APP_OUTSTANDING_RECEIPT_REPORT_ID
        : process.env.REACT_APP_RECEIPT_PAYMENT_REPORT_ID;
    const payload = {
      reportid: reportId,
      inputparams:  {
        "@receiptid": Id,
        "@loginpract": practitionerId
      },
      result: [],
    };
    AxiosCall("post", urls.jasperReport, payload, header)
      .then(async (res) => {
        if (res?.downloadUrl) {
          const response = await axios.get(res?.downloadUrl, {
            method: "GET",
            responseType: "blob",
          });
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfDataUrl = URL.createObjectURL(pdfBlob);
          window.open(pdfDataUrl, "_blank");
        } else {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please contact admin",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }
      })
      .catch((err) => {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "something went wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      });

    navigate(AppRoutes.dashboard);
  };
  const generateAdvanceReceipt = async (Id) => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const payload = {
      reportid: process.env.REACT_APP_ADVANCE_RECEIPT_REPORT_ID,
      inputparams: {
        "@advanceid": Id,
        "@loginpract": practitionerId,
      },
      result: [],
    };
    AxiosCall("post", urls.jasperReport, payload, header)
      .then(async (res) => {
        if (res?.downloadUrl) {
          const response = await axios.get(res?.downloadUrl, {
            method: "GET",
            responseType: "blob",
          });
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfDataUrl = URL.createObjectURL(pdfBlob);
          window.open(pdfDataUrl, "_blank");
        } else {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please contact admin",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }
      })
      .catch((err) => {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "something went wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      });

    navigate(AppRoutes.dashboard);
  };

  const handleSave = async () => {
    setLoading(true);
    const billHeaderSave = {
      _id: "",

      billno: billNo?.billNum ?? "",

      billdate: Math.floor(new Date(new Date()) / 1000),

      tenantid: "1234",

      facilityid: decodedUserinfo?.facilityid,

      // patientid: location?.state?.data?._id,

      // encounterid: location?.state?.data?.encounter[counter]?._id,

      patientid: LoadpatientData?.state?.data?.patientId,

      encounterid: encounterNo,

      totgrossamt: calculationResultData?.gross,

      totdiscamt: calculationResultData?.dicount,

      totnetamt: calculationResultData?.net,

      totpatientshare: Number(
        (
          calculationResultData?.patientShare + Number(roundOffAmnt?.roundOff)
        ).toFixed(2)
      ),

      totpayershare: calculationResultData?.creditShare,

      billhstatus: true,

      payerid: payer?._id,

      RCM_billdtls: billDetailSave,

      RCM_Paymentdtls: [
        {
          _id: "",

          patientid: LoadpatientData?.state?.data?.patientId,

          encounterid: encounterNo,

          billhdrid: "",

          receiptno: receipt?.receiptNo,

          receiptdate: getUnixCodeDate(receipt?.date),

          billamt: Number(
            (
              Number(calculationResultData?.patientShare ?? 0) +
              Number(roundOffAmnt?.roundOff ?? 0)
            ).toFixed(2)
          ),

          paidamt: Number(
            (
              Number(paymentDetails?.settleNowValue ?? 0) +
              Number(roundOffAmnt?.roundOff ?? 0)
            ).toFixed(2)
          ),

          balamt: paymentDetails?.balanceDue,

          paymentdtls: [
            {
              paymentmode: paymentDetails?.paymentType?._id,

              docrefno: paymentDetails?.docRefNo,

              docrefdate: paymentDetails?.docRefDate,

              remarks: paymentDetails?.remarks,

              cardno: paymentDetails?.card,

              expdate:
                paymentDetails?.expiry?.length === 0
                  ? 0
                  : paymentDetails?.expiry,

              // cardtype: "RUPAY",
            },
          ],

          settlenow: paymentDetails?.settleNow,

          roundoffamt: parseFloat(roundOffAmnt?.roundOff),
        },
      ],
    };

    const validation = validate(billHeaderSave, paymentDetails);

    if (validation?.status) {
      let token = localStorage.getItem("JwtToken");

      let register = await axios({
        method: "POST",

        url: process.env.REACT_APP_SAVEBILL,

        headers: {
          "Content-Type": "application/json",

          jwtToken: `${token}`,
        },

        data: JSON.stringify([billHeaderSave]),
      })
        .then((res) => {
          if (res.status === 200 && !res.data.error) {
            setLoading(false);
            props?.alert?.setSnack({
              open: true,

              severity: AlertProps.severity.success,

              msg: "save successfully",

              vertical: AlertProps.vertical.top,

              horizontal: AlertProps.horizontal.center,

              tone: true,
            });

            const patientCardDetails = LoadpatientData?.state?.data;

            const encounterDetails =
              LoadpatientData?.state?.data?.encounter ?? "";

            const billHrdNo = res.data.response[0]._id;

            successfun2();

            // generateAndOpenJasperReport();

            // navigate(AppRoutes.billingSummary, {

            //   // encounterNo: `EN - ${location?.state?.data?.encounter[counter]?._key}`,

            //   // demoRecieptDetails: "demoRecieptDetails",

            //   state: {

            //     billHrdNo: billHrdNo,

            //     payload: LoadpatientData?.state,

            //   },

            // });
          } else {
            props?.alert?.setSnack({
              open: true,

              severity: AlertProps.severity.error,

              msg: res.data.message,

              vertical: AlertProps.vertical.top,

              horizontal: AlertProps.horizontal.center,

              tone: true,
            });
          }
        })

        .catch((error) => {
          props?.alert?.setSnack({
            open: true,

            severity: AlertProps.severity.error,

            msg: error,

            vertical: AlertProps.vertical.top,

            horizontal: AlertProps.horizontal.center,

            tone: true,
          });
        });

      // const billHeaderId =billHeaderResult?.payload?.data?.Result[0]?.properties?.doc?._id;

      // if (billHeaderId) {

      //   const billingDetails = selectedOrders?.map((val, i) => {

      //     if (typeof val === "object" && !Array.isArray(val) && val !== null) {

      //       const billrefno = {

      //         billrefno: billHeaderId,

      //       };

      //       return { ...val, ...billrefno };

      //     } else {

      //       return val;

      //     }

      //   });

      //   const billDetailsSave = billingDetails.map((val) => {

      //     return {

      //       dispenseid: val?.orderdispenseid,

      //       patientid: props?.location?.state?.data?._id,

      //       encounterid: props?.location?.state?.data?.encounter[counter]?._id,

      //       billrefno: billHeaderId,

      //       patchargetrxid: val?.id,

      //       orderrefid: val?.orderrefid,

      //       grossamt: Number(val?.gross),

      //       discamt: Number(val?.dicount),

      //       netamt: Number(val?.net),

      //       patientshare: Number(val?.patientShare),

      //       payershare: Number(val?.creditShare),

      //       billdstatus: true,

      //       chargecodeid: val?.chargecodeid,

      //       billqty: parseInt(val?.qty),

      //       billuom: val?.uomId,

      //       billrate: val?.rate,

      //     };

      //   });

      //   const billDetailsResult = await dispatch(

      //     actions.BILL_DETAILS_SAVE({ billDetailsSave })

      //   );

      //   const paymentPayload = {

      //     patientid: props?.location?.state?.data?._id,

      //     encounterid: props?.location?.state?.data?.encounter[counter]?._id,

      //     billhdrid: billHeaderId,

      //     receiptno: receipt?.receiptNo,

      //     receiptdate: getUnixCodeDate(receipt?.date),

      //     billamt: (

      //       calculationResultData?.patientShare + Number(roundOffAmnt?.roundOff)

      //     ).toFixed(2),

      //     paidamt: (

      //       Number(paymentDetails?.settleNowValue) +

      //       Number(roundOffAmnt?.roundOff)

      //     ).toFixed(2),

      //     balamt: paymentDetails?.balanceDue,

      //     roundoffamt: roundOffAmnt?.roundOff,

      //     paymentdtls: [

      //       {

      //         paymentmode: paymentDetails?.paymentType?.label,

      //         docrefno: paymentDetails?.docRefNo,

      //         docrefdate: paymentDetails?.docRefDate,

      //         remarks: paymentDetails?.remarks,

      //         cardno: Number(paymentDetails?.card),

      //         expdate:

      //           paymentDetails?.expiry?.length === 0

      //             ? 0

      //             : paymentDetails?.expiry,

      //       },

      //     ],

      //     settlenow: paymentDetails?.settleNow,

      //   };

      //   const paymentResult = await dispatch(

      //     actions.PAYMENT_SAVE({ paymentPayload })

      //   );

      //   const billIds = selectedOrders.map((val, i) => {

      //     return val?.id;

      //   });

      //   // const billStatusResult = await dispatch(

      //   //   actions.BILL_STATUS_SAVE({ billIds })

      //   // );

      //   // const demoRecieptDetails = await dispatch(

      //   //   actions.BILL_RECEIPT_READ({ billHeaderId })

      //   // );

      // }
    } else {
      props.alert.setSnack({
        open: true,

        severity: AlertProps.severity.error,

        msg: validation?.msg,

        vertical: AlertProps.vertical.top,

        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  function dateToEpoch(dateStr) {
    var dateObj = new Date(dateStr);
    var epochTime = Math.floor(dateObj.getTime() / 1000);
    return epochTime;
  }
  function dateToEpochInSeconds(dateString) {
    // var parts = dateString.split(/[\/\s:]/);
    // var dateObj = new Date(
    //   parseInt(parts[2]),
    //   parseInt(parts[1]) - 1,
    //   parseInt(parts[0]),
    //   parseInt(parts[3]),
    //   parseInt(parts[4])
    // );

    var epochTimeInSeconds = Math.floor(
      moment(dateString, "DD/MM/YYYY HH:mm").valueOf() / 1000
    );

    return epochTimeInSeconds;
  }
  function transformData(savedata) {
    // const totalPayment = savedata.reduce((total, item) => total + Number(item.CollectionAmount), 0);

    return savedata?.map((item) => ({
      billhdrid: item._id,
      patientbillamt: item.billamount,
      patientshare: item.patientshare,
      payershare: item.payershare,
      paidamt: Number(item.CollectionAmount),
      balamt: item.balanceamount - Number(item.CollectionAmount),
      encounterid: item?.encounterid,
    }));
  }
  function transformData1(savedata) {
    return savedata?.map((item) => ({
      paymentmode:
        item?.cash?._id ??
        item?.debit_card?._id ??
        item?.credit_card?._id ??
        item?.cheque?._id ??
        item?.online_transfer?._id ??
        item?.advance?._id,
      docrefno: item?.Bank_And_Cash_Account ?? "",
      docrefdate: dateToEpoch(item?.date),
      cardtype:
        item?.cash?.value ??
        item?.debit_card?.value ??
        item?.credit_card?.value ??
        item?.cheque?.value ??
        item?.online_transfer?.value ??
        item?.advance?.value,
      cardno: item?.instrument_Number ? item.instrument_Number : "0",
      expdate: item?.expdate ?? 0,
      batchno: item?.batchno ?? 0,
      invoiceno: "",
      approvalcode: item?.approvalcode ?? 0,
      remarks: item?.Comments ?? "",
      payerid: item?.payer_Name ?? "",
      amount: Number(item?.totalPayAmount),
    }));
  }

  const checkedPaymentOrder = paymentOrders.filter((s) => s.checked === true);
  const collectionSaveData = checkedPaymentOrder
    .filter((payment) => payment.CollectionAmount > 0)
    .map((payment) => {
      if (location?.state?.isAdvancePayment) {
        return {
          patientid: payment?.patientid,
          patientname: payment?.patientname,
          advanceno: payment?.advanceno,
          advdate: payment?.advdate,
          advamount: payment?.advamount,
          checked: payment?.checked,
          CollectionAmount: payment?.CollectionAmount,
          _id: payment?.id,
          remarks: payment?.remarks,
          amtineng:payment?.amtineng,
          amtinlocal: payment?.amtinlocal,
        };
      } else {
        return {
          patientid: payment?.patientid,
          encounterid: payment?.encounterid,
          patientname: payment?.patientname,
          billno: payment?.billno,
          billdate: payment?.billdate,
          billamount: payment?.billamount,
          balanceamount: payment?.balanceamount,
          checked: payment?.checked,
          billed: payment?.billed,
          CollectionAmount: payment?.CollectionAmount,
          payershare: payment?.payershare,
          patientshare: payment?.patientshare,
          _id: payment?.id,
          amtineng:payment?.amtineng,
          amtinlocal: payment?.amtinlocal,
        };
      }
    });
    const units = ['', 'SATU', 'DUA', 'TIGA', 'EMPAT', 'LIMA', 'ENAM', 'TUJUH', 'LAPAN', 'SEMBILAN'];
const teens = ['', 'SEPULUH', 'SEBELAS', 'DUA BELAS', 'TIGA BELAS', 'EMPAT BELAS', 'LIMA BELAS', 'ENAM BELAS', 'TUJUH BELAS', 'LAPAN BELAS', 'SEMBILAN BELAS'];
const tens = ['', 'SEPULUH', 'DUA PULUH', 'TIGA PULUH', 'EMPAT PULUH', 'LIMA PULUH', 'ENAM PULUH', 'TUJUH PULUH', 'LAPAN PULUH', 'SEMBILAN PULUH'];

const convertToWords = (number, malay = false) => {
    if (malay) {
        return convertToMalay(number);
    } else {
      let numToWords = numberToWords.toWords(number);
        return numToWords.toUpperCase();
    }
};

const convertToMalay = (number) => {
    if (number < 10) {
        return units[number];
    } else if (number < 20) {
        return teens[number - 10];
    } else if (number < 100) {
        return tens[Math.floor(number / 10)] + ' ' + units[number % 10];
    } else if (number < 1000) {
        return units[Math.floor(number / 100)] + ' RATUS ' + convertToMalay(number % 100);
    } else if (number < 1000000) {
        return convertToMalay(Math.floor(number / 1000)) + ' RIBU ' + convertToMalay(number % 1000);
    } else if (number < 1000000000) {
        return convertToMalay(Math.floor(number / 1000000)) + ' JUTA ' + convertToMalay(number % 1000000);
    } else {
        return 'Number out of range';
    }
};
  function savePaymentDataFun() {
    const collectedPayment = collectionsData.reduce(
      (total, item) => total + Number(item.totalPayAmount),
      0
    );
    return {
      _id: "",
      patientid: location?.state?.patientid,
      // encounterid: location?.state?.encounterid,
      receiptno: receipt?.receiptNo,
      receiptdate: dateToEpochInSeconds(receipt?.date),
      paymentdtls: transformData1(collectionsData),
      //  [
      //   {
      //     paymentmode:
      //       cashierdatas?.cash?._id ??
      //       cashierdatas?.debit_card?._id ??
      //       cashierdatas?.credit_card?._id ??
      //       cashierdatas?.cheque?._id ??
      //       cashierdatas?.online_transfer?._id??
      //       cashierdatas?.advance?._id,
      //     docrefno: cashierdatas?.Bank_And_Cash_Account ?? "",
      //     docrefdate: dateToEpoch(cashierdatas?.date),
      //     cardtype:
      //       cashierdatas?.cash?.value ??
      //       cashierdatas?.debit_card?.value ??
      //       cashierdatas?.credit_card?.value ??
      //       cashierdatas?.cheque?.value ??
      //       cashierdatas?.online_transfer?.value??
      //       cashierdatas?.advance?.value,
      //     cardno: cashierdatas?.instrument_Number
      //       ? Number(cashierdatas.instrument_Number)
      //       : 0,
      //     expdate: cashierdatas?.expdate ?? 0,
      //     batchno: cashierdatas?.batchno ?? 0,
      //     invoiceno: "",
      //     approvalcode: cashierdatas?.approvalcode ?? 0,
      //     remarks: cashierdatas?.Comments??"",
      //     payerid: cashierdatas?.payer_Name ?? "",
      //     amount: cashierdatas?.CollectionAmount,
      //   },
      // ],
      SourceSystemID: "",
      SourceSystemRefId: "",
      settlenow: true,
      roundoffamt: 1709285763,
      tenantid: decodedUserinfo?.tenantid,
      facilityid: decodedUserinfo?.facilityid,
      billdetails: transformData(collectionSaveData),
      //  [
      //   {
      //     billhdrid: "",
      //     billamt: 170,
      //     patientshare: 30,
      //     payershare: 140,
      //     paidamt: 100,
      //     balamt: 70,
      //   },
      // ],
      receiptstatus: "CodingMaster/11797",
      receiptamt: Number(collectedPayment), //Number(cashierdatas?.totalPayAmount),
      cancelreason: "",
      amtineng:convertToWords(Number(collectedPayment)),
      amtinlocal:convertToWords(Number(collectedPayment), true)

    };
  }

  function saveAdvancePaymentDataFun() {
    let advamount = 0;
    let remarks = "";
    let amtineng = "";
    let amtinlocal = "";
    let advdate = 0;
    let id = "";
    const checkedPaymentOrder = paymentOrders.filter((s) => s.checked === true);
    if (checkedPaymentOrder.length > 0) {
      advamount = checkedPaymentOrder[0].advamount;
      remarks = checkedPaymentOrder[0].remarks;
      amtineng = checkedPaymentOrder[0].amtineng;
      amtinlocal = checkedPaymentOrder[0].amtinlocal;
      advdate = dateToEpochInSeconds(checkedPaymentOrder[0].advdate);
      id = checkedPaymentOrder[0].id;
    }
    let advancepaymentdtls = collectionsData?.map((s) => {
      return {
        paymentmode:
          s?.cash?._id ??
          s?.debit_card?._id ??
          s?.credit_card?._id ??
          s?.cheque?._id ??
          s?.online_transfer?._id,
        docrefno: s?.Bank_And_Cash_Account ?? "",
        docrefdate: dateToEpoch(s?.date),
        cardtype:
          s?.cash?.value ??
          s?.debit_card?.value ??
          s?.credit_card?.value ??
          s?.cheque?.value ??
          s?.online_transfer?.value,
        cardno: s?.instrument_Number ? s.instrument_Number : "0",
        expdate: s?.expdate ?? 0,
        batchno: s?.batchno ?? 0,
        approvalcode: s?.approvalcode ?? 0,
        amount: Number(s?.totalPayAmount),
        remarks: s?.Comments ?? "",
      };
    });
    const advData = [];
    advData.push({
      _id: id,
      advdate: advdate,
      patientid: location?.state?.patientid,
      amount: advamount,
      remarks: remarks,
      advancepaymentdtls: advancepaymentdtls,
      amtineng: amtineng,
      amtinlocal: amtinlocal,
    });
    return advData;
  }
  // [
  //   {
  //     _id: "",
  //     advdate: 1710936698,
  //     patientid: "Patient/13197",
  //     amount: 7889.38,
  //     remarks: "Thru Advance API",
  //     advancepaymentdtls: [
  //       {
  //         paymentmode: "CodingMaster/11293",
  //         docrefno: "88",
  //         docrefdate: 1709285763,
  //         cardtype: "VISA",
  //         cardno: 87,
  //         expdate: 1709285763,
  //         batchno: 33,
  //         approvalcode: 33,
  //         remarks: "",
  //         amount: 9000,
  //       },
  //     ],
  //   },
  // ];
  const validated = () => {
    let errNames = false;
    const totalPayment = paymentOrders.reduce(
      (total, item) => total + Number(item.CollectionAmount),
      0
    );
    const collectedPayment = collectionsData.reduce(
      (total, item) => total + Number(item.totalPayAmount),
      0
    );
    if (totalPayment !== collectedPayment) {
      // setIsPaymentEqual(true);
      errNames = true;
    } else {
      errNames = false;
    }
    return {
      errNames,
    };
  };
  const successfun = async () => {
    let patientData = cashierdatas;
    let { errNames } = validated();
    // if (patientData?.savedata.length === 0) {
    if (collectionsData?.length === 0) {
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "No Bill Selected. Please select the Bills to be paid",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else if (errNames) {
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Total payment amount does not match collected payment amount.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else {
      setIsSubmit(true);
      if (location?.state?.isAdvancePayment) {
        let token = localStorage.getItem("JwtToken");
        let register = await axios({
          method: "POST",
          url: process.env.REACT_APP_ADVANCE_PAYMENT_DETAILS,
          headers: {
            "Content-Type": "application/json",
            jwtToken: `${token}`,
          },
          data: JSON.stringify(saveAdvancePaymentDataFun()),
        })
          .then((res) => {
            if (res.status === 200 && !res.data.error) {
              props?.alert?.setSnack({
                open: true,
                severity: AlertProps.severity.success,
                msg: "Successfully Advance Payment details saved!",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              generateAdvanceReceipt(
                res?.data?.response?.[0]?.PatientAdvance?._id
              );
              navigate(AppRoutes.dashboard);
              setIsSubmit(false);
              setCashierdatas({});
            } else {
              props?.alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: res.data.message,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
            }
          })
          .catch((error) => {
            props?.alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Please contact to admin",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
          });
      } else {
        let token = localStorage.getItem("JwtToken");
        let register = await axios({
          method: "POST",
          url: process.env.REACT_APP_INSERT_PAYMENT_DETAILS,
          headers: {
            "Content-Type": "application/json",
            jwtToken: `${token}`,
          },
          data: JSON.stringify(savePaymentDataFun()),
        })
          .then((res) => {
            if (res.status === 200 && !res.data.error) {
              props?.alert?.setSnack({
                open: true,
                severity: AlertProps.severity.success,
                msg: "Successfully saved Payment details!",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              generateAndOpenJasperReport(
                res?.data?.response?.Result?.[0]?.properties?.doc?._id
              );
              // navigate(AppRoutes.dashboard);
              setIsSubmit(false);
              setCashierdatas({});
            } else {
              props?.alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: res.data.message,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
            }
          })
          .catch((error) => {
            props?.alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Please contact to admin",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
          });
      }
    }
  };

  // useEffect(() => {
  //   if (!isExecuted && selectedOrders && standardTableorders) {
  //     handleHeaderCheckBox({ target: { checked: true } });
  //     setTimeout(() => {
  //       setIsExecuted(true);
  //     }, 1000);
  //   }
  // }, [selectedOrders, standardTableorders, isExecuted]);
  return (
    // <div style={{ backgroundColor: "#F4F5F8", paddingBottom: "1px" }}>
    //   {/* ------------------------------------------------------ Top Layer Cards ----------------------------------------------------- */}

    //   {/* ---------------------------------------------------------------------------------------------------------------------------- */}

    //   <div className={classes.contents}>
    //     <Grid
    //       container
    //       style={{
    //         borderBottom: "1px solid #DFE5EB",

    //         paddingRight: "20px",

    //         alignItems: "center",
    //       }}
    //     >
    //       <Grid item xs={6}>
    //         <Grid container>
    //           <Grid item xs={2} style={{ padding: "10px 10px 10px 22px" }}>
    //             <Typography className={classes.header}>Billing</Typography>
    //           </Grid>

    //           <Grid item xs={10}>
    //             <Stepper
    //               className={classes.stepper}
    //               activeStep={activeStep}
    //               style={{ padding: "10px 0px 10px 8px" }}
    //             >
    //               {steps.map((val, i) => (
    //                 <Step key={i}>
    //                   <StepLabel>{val}</StepLabel>
    //                 </Step>
    //               ))}
    //             </Stepper>
    //           </Grid>
    //         </Grid>
    //       </Grid>

    //       <Grid item xs={6}>
    //         <Grid container>
    //           {activeStep === 0 ? (
    //             <>
    //               <Grid
    //                 item
    //                 xs={6}
    //                 style={{ display: "flex", justifyContent: "end" }}
    //               >
    //                 <FormControlLabel
    //                   style={{ margin: "0px 16px 0px 0px" }}
    //                   control={
    //                     <Checkbox
    //                       checked={unBilledCheckBox}
    //                       color="primary"
    //                       size="small"
    //                       onChange={(e) => handleUnBilled(e)}
    //                     />
    //                   }
    //                   label={
    //                     <Typography className={classes.labelStyle}>
    //                       Unbilled charges Only?
    //                     </Typography>
    //                   }
    //                 />
    //               </Grid>

    //               <Grid item xs={6}>
    //                 <Div
    //                   id="sidemenu_search_input_sub_div"
    //                   style={{ display: "flex", position: "relative" }}
    //                 >
    //                   <TextInput
    //                     id="sidemenu_search_input_textinput"
    //                     value={codeSearch}
    //                     onChange={(evt) => {
    //                       handleCodeSearch(evt.target.value);
    //                     }}
    //                     style={{
    //                       margin: "6px 16px 0px 0px",

    //                       borderRadius: 8,

    //                       borderColor: UIColor.lineBorderFill.color,

    //                       padding: "0px 10px",

    //                       height: 30,
    //                     }}
    //                     // search

    //                     variant={"outlined"}
    //                     // id={"inputId"}

    //                     label=""
    //                     placeholder="Search Code & Description"
    //                     hoverColor="#0071F2"
    //                   />

    //                   <SearchIcon
    //                     id="sidemenu_search_searchicon"
    //                     className={classes.searchIcon}
    //                   />
    //                 </Div>
    //               </Grid>
    //             </>
    //           ) : (
    //             <Grid item xs={12} className={classes.addNewColumn}>
    //               <Button
    //                 onClick={handleAddnew}
    //                 variant="contained"
    //                 style={{
    //                   backgroundColor: "#EC6A49",

    //                   textTransform: "none",

    //                   color: "#fff",

    //                   fontSize: "12px",
    //                 }}
    //               >
    //                 + Add New
    //               </Button>
    //             </Grid>
    //           )}
    //         </Grid>
    //       </Grid>
    //     </Grid>

    //     <div style={{ overflowY: "auto", height: "87%", overflowX: "hidden" }}>
    //       {/*----------------------------------- Loading the Tables----------------------------------- */}

    //       {getStepperComponents(activeStep)}

    //       {/* ---------------------------------------------------------------------------------------------- */}
    //     </div>
    //   </div>

    //   <div
    //     className={classes.buttonAlign}
    //     style={{ justifyContent: activeStep !== 0 ? "space-between" : "end" }}
    //   >
    //     {activeStep !== 0 && (
    //       <>
    //         <Typography className={classes.calculationLabel}>
    //           Remarks :
    //         </Typography>

    //         <TextField
    //           id="standard-full-width"
    //           fullWidth
    //           margin="normal"
    //           onChange={(e) => handlePayementDetails(e.target.value, "remarks")}
    //           InputProps={{
    //             classes: {
    //               input: classes.resize,
    //             },
    //           }}
    //           className={classes.remarksTextField}
    //           inputProps={{ maxLength: 200 }}
    //           value={props?.paymentDetails?.remarks}
    //           size={"small"}
    //           variant={"outlined"}
    //         />
    //       </>
    //     )}

    //     <div style={{ display: "flex", gap: 10 }}>
    //       <Button
    //         style={{ color: "#6A7888" }}
    //         onClick={handleCancel}
    //         className={classes.forButton}
    //       >
    //         Cancel
    //       </Button>

    //       {activeStep !== 0 && (
    //         <>
    //           <Button
    //             variant="contained"
    //             style={{ backgroundColor: "#DEE5EC", color: "#2A3C50" }}
    //             onClick={handlePrevious}
    //             className={classes.forButton}
    //           >
    //             Previous
    //           </Button>
    //         </>
    //       )}

    //       <Button
    //         variant="contained"
    //         color="primary"
    //         onClick={activeStep === 0 ? handleProceed : handleSave}
    //         className={classes.forButton}
    //         disabled={loading ? true : false}
    //       >
    //         {activeStep === 0
    //           ? "Proceed for billing"
    //           : loading
    //           ? "Saving..."
    //           : "Save"}
    //       </Button>
    //     </div>
    //   </div>
    // </div>

    <Div
      id="patient_dashboard_parent_div"
      style={{ backgroundColor: "#F4F5F8" }}
    >
      <Div
        style={{
          display: "block",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 176px)",
        }}
      >
        <Grid
          id="patient_dashboard_patientCardGrid"
          container
          spacing={2}
          style={{
            padding: "10px 20px",
            backgroundImage: "linear-gradient(to right, #F7ECF4, #DAEAF8)",
          }}
        >
          <Grid item xs={6} id="patient_dashboard_grid_patientCard">
            <PatientCard
              permissionData={state?.permissionData}
              nameDetails={makeName(state.personDetails?.name?.[0] || {})}
              parent_id={"patient_dashboard"}
              patientDetails={state.patientDetails}
              newPatientDetails={state.newPatientDetails}
              mrnVal={state?.personDetails?.alias}
              notOpen
              info={state.loggedInState}
              age={
                getCalculatedAge(state?.newPatientDetails?.birthdate)
                // calculateAge(
                //   new Date(
                //     moment(state?.newPatientDetails?.birthdate, "DD/MM/YYYY")
                //   )
                // ) + " Yrs"
              }
              memberList={state.choosemember}
              active={state.personDetails?.active}
              activeData={
                state.personDetails?.isdeceased?.display &&
                state.personDetails.active &&
                state.personDetails?.isdeceased?.display
              }
              noedit={!(state.permission?.write?.indexOf("modifyPatient") > -1)}
              OutstandingData={state?.OutstandingData}
              walletData={state?.advanceAmount}
            />
          </Grid>
          <Grid item xs={6} id="patient_dashboard_grid_patientTypeCard">
            <PatientCareTypeCard
              parent_id={"Referral_Registration"}
              careList={[...(state?.fin_data || [])]}
              selected={state?.selectOption}
              padding
              isDetailed = {true}
            />
          </Grid>
        </Grid>
        <div
        // style={{
        //   height: "calc(100vh - 128px)",
        //   overflow: "auto",
        //   alignItems: "center",
        //   justifyContent: "center",
        // }}
        >
          <div
            id="referral_parent_sub_div"
            className="rv-list-view"
            style={{ overflowY: "auto" }}
          >
            <TableComponent
              handleCheckBox={handleCheckBox}
              handleHeaderCheckBox={handleHeaderCheckBox}
              handleDeleteSelected={handleDeleteSelected}
              handleTableTextChange={handleTableTextChange}
              tableData={selectedOrders}
              allChecked={allChecked}
              isAdvancePayment={location?.state?.isAdvancePayment}
            />
          </div>
        </div>
        <div>
          <CollectionSummary
            paymentOrders={paymentOrders}
            setCashierdatas={setCashierdatas}
            setCollectionsData={setCollectionsData}
            collectionsData={collectionsData}
            cashierdatas={cashierdatas}
            successfun={successfun}
            isAdvancePayment={location?.state?.isAdvancePayment}
            patientid={location?.state?.patientid}
            isSubmit={isSubmit}
            isPaymentEqual={isPaymentEqual}
            totCollectAmt={totCollectAmt}
            patientNames={patientNames}
          />
        </div>
      </Div>
    </Div>
  );
};

export default withAllContexts(withAppBar(PaymentCollectionDetails));
