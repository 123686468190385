import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core";
import AdditiveItem from "./component/additiveItem";
import withAllContexts from "../../../../../hoc/withAllContexts";
import CustomButton from "../components/customButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "12px 16px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    color: "#000",
    fontWeight: 600,
  },
  action: {
    display: "flex",
    gap: 12,
    alignItems: "center",
  },
  popper: {
    zIndex: theme.zIndex.modal,
    // width: "100%",
  },
  additiveItemContainer: {
    borderRadius: 8,
    border: "1px solid #E0E0E0",
    overflow: "auto",
    maxHeight: "94px",
  },
}));

function Additive(props) {
  const { data = [], handleDelete, handleEdit, handleAdd, pract } = props;

  const classes = useStyles(props);
  const theme = useTheme();

  const handleAddNew = () => {
    handleAdd();
  };

  const handleEditClick = (item, index) => {
    handleEdit(item, index);
  };

  const handleDeleteClick = (index) => {
    handleDelete(index);
  };

  return (
    <div>
      <div className={classes.header}>
        <Typography className={classes.title}>MEDICATIONS</Typography>
        <div className={classes.action}>
          <CustomButton
            color={theme.palette.primary.main}
            onClick={handleAddNew}
          >
            + Add New
          </CustomButton>
        </div>
      </div>
      <div className={classes.additiveItemContainer}>
        {data?.length > 0
          ? data?.map((item, index) => (
              <AdditiveItem
                index={index}
                data={item}
                onEditClick={() => handleEditClick(item, index)}
                onDeleteClick={() => handleDeleteClick(index)}
                // pract={pract}
                allData={data}
                practitionerId={props?.practitionerId ?? ""}
              />
            ))
          : "No Orders"}
      </div>
    </div>
  );
}

export default withAllContexts(Additive);

Additive.defaultProps = {};

Additive.propTypes = {};
