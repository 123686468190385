import * as React from "react";
// import { makeStyles } from "@material-ui/core";

// const useStyle = makeStyles((theme) => ({
//   cls1: (props) => ({
//     fill: props.htmlColor,
//   }),
// }));

export const CalenderEdit = (props) => {
  return (
    // <SvgIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14.001"
      viewBox="0 0 14 14.001"
    >
      <path
        fill={props.color ? props.color : "#6f6f6f"}
        class="a"
        d="M8.275,6A2.278,2.278,0,0,0,6,8.275v8.05A2.278,2.278,0,0,0,8.275,18.6H11.8c.064-.251.159-.589.288-1.04l-3.813-.01A1.226,1.226,0,0,1,7.05,16.326V9.84h10.5v1.968a2.161,2.161,0,0,1,.492-.159,1.889,1.889,0,0,1,.416-.038c.048,0,.094,0,.142.007V8.275A2.278,2.278,0,0,0,16.326,6Zm0,1.05h8.05a1.226,1.226,0,0,1,1.225,1.225V8.79H7.05V8.275A1.226,1.226,0,0,1,8.275,7.05Zm1.05,4.2a.875.875,0,1,0,.875.875.875.875,0,0,0-.875-.875Zm2.975,0a.875.875,0,1,0,.875.875A.875.875,0,0,0,12.3,11.25Zm2.975,0a.875.875,0,1,0,.875.875A.875.875,0,0,0,15.275,11.25Zm3.149,1.05a1.646,1.646,0,0,0-.291.027,1.623,1.623,0,0,0-.855.467l-4.13,4.13a1.053,1.053,0,0,0-.266.45l-.55,1.918a.532.532,0,0,0-.034.207.519.519,0,0,0,.5.5.533.533,0,0,0,.209-.034l1.918-.55a1.049,1.049,0,0,0,.449-.266l4.13-4.13a1.623,1.623,0,0,0,.467-.855A1.678,1.678,0,0,0,20,13.876,1.577,1.577,0,0,0,18.425,12.3Zm-9.1,2.1a.875.875,0,1,0,.875.875.875.875,0,0,0-.875-.875Zm2.975.01a.875.875,0,1,0,.875.875A.875.875,0,0,0,12.3,14.411Z"
        transform="translate(-6 -6)"
      />
    </svg>

    // </SvgIcon>
  );
};
