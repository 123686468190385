import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../../../../../themes/theme";

export const useStyles = makeStyles((theme) => ({
  body: {
    padding: "0px 10px",
  },
  indexStyle: (props) => ({
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: props?.isError ? "#fff" : "#101010",
    fontSize: 14,
    fontWeight: "600",
    backgroundColor: props?.isError
      ? "#FF4D4A"
      : UIColor.differentiationBackground.color,
    borderRadius: "0px 8px 8px 0px",
  }),
  flexRow: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #FF4D4A5E",
    borderRadius: "4px",
    marginBottom: "20px",
    padding: "10px",
    backgroundColor: "#FFEDEB",
    "& .flexColumn": {
      display: "flex",
      flexDirection: "column",
    },
  },
  infoIcon: {
    marginRight: 20,
    fontSize: 24,
    color: "#EA1601",
  },
}));
