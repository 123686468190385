import React from "react";

export const Lounge = (props) => {
  let width = props.width ? props.width : "19.765";
  let height = props.height ? props.height : "14";
  let color = props.color ? props.color : "#101010";
  return (
    <svg 
    {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19.765 14"
    >
        <defs>
            <style>{`.cls-7{fill:${color};}}`}</style>
        </defs>
      <path xmlns="http://www.w3.org/2000/svg" id="icons8-living-room" class="cls-7" d="M4.735,7A2.685,2.685,0,0,0,2.068,9.481,2.274,2.274,0,0,0,0,11.735a2.246,2.246,0,0,0,1.647,2.138v2.8A3.1,3.1,0,0,0,3.294,19.4v.979a.618.618,0,1,0,1.235,0v-.628c.068,0,.136.01.206.01h10.3c.067,0,.133-.006.2-.01v.628a.618.618,0,1,0,1.235,0v-.976a3.094,3.094,0,0,0,1.653-2.722s0,0,0,0l0-2.809a2.246,2.246,0,0,0,1.643-2.138A2.274,2.274,0,0,0,17.7,9.481,2.685,2.685,0,0,0,15.029,7Zm0,1.235H15.029a1.426,1.426,0,0,1,1.428,1.314,2.687,2.687,0,0,0-2.046,2.6v1.282a2.237,2.237,0,0,0-1.029-.253h-7a2.237,2.237,0,0,0-1.029.253V12.147a2.687,2.687,0,0,0-2.046-2.6A1.426,1.426,0,0,1,4.735,8.235Zm2.882,2.059a.618.618,0,1,0,.618.618A.618.618,0,0,0,7.618,10.294Zm4.529,0a.618.618,0,1,0,.618.618.618.618,0,0,0-.618-.618Zm-9.882.412h.31a.618.618,0,0,0,.159.006,1.429,1.429,0,0,1,1.383,1.436v4.529a.618.618,0,0,0,.618.618H15.029a.618.618,0,0,0,.618-.618V12.147a1.429,1.429,0,0,1,1.383-1.436.618.618,0,0,0,.157-.006H17.5a1.029,1.029,0,0,1,0,2.059.618.618,0,0,0-.616.618l0,3.3a1.843,1.843,0,0,1-1.853,1.847H4.735a1.843,1.843,0,0,1-1.853-1.853V13.382a.618.618,0,0,0-.618-.618,1.029,1.029,0,1,1,0-2.059Zm4.118,3.706h7a1.02,1.02,0,0,1,1.029,1.029v.618H5.353v-.618A1.02,1.02,0,0,1,6.382,14.412Z" transform="translate(0 -7)"/>
      </svg>
  );
};