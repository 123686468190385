import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../themes/theme";

export const useStyles = makeStyles((theme) => ({
  contents: {
    height: "calc(100vh - 186px)",
    margin: "8px 20px",
    border: "1px solid #DEE5EC",
    borderRadius: "8px",
    backgroundColor: "#fff",
    overflowY: "hidden",
  },
  stepper: {
    borderRadius: "8px",
    padding: "20px 0px 20px 8px",
  },
  header: {
    fontSize: "16px",
    color: "#001C3C",
    fontWeight: 600,
    borderRight: "1px solid #DFE5EB",
  },
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    fontFamily: `pc_regular !important`,
  },
  buttonAlign: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    margin: "0px 16px",
    gap: "10px",
    paddingBottom: "10px",
    // position: "absolute",
    // right: "20px",
    // bottom: "20px",
  },
  addNewColumn: {
    display: "flex",
    justifyContent: "end",
  },
  searchIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    color: UIColor.lineBorderFill.color,
  },
  tableContainer: {
    margin: "0px !important",
    boxShadow: "none !important",
    borderRadius: "0px !important",
    backgroundColor: "#ECF0F7",
  },
  headerCell: {
    background: "#ECF0F7",
    color: "#6A7888",
    padding: "10px",
  },
  childCell: {
    background: "#fff",
    color: "#2A3C50",
    fontFamily: `pc_regular !important`,
    padding: "10px",
  },
  popupIndicator: {
    transform: "rotate(0deg) !important",
    "& span": {
      "& svg": {
        "& path": {
          d: "path('M12.265,6a6.265,6.265,0,1,0,3.768,11.256l2.491,2.49a.864.864,0,1,0,1.222-1.222l-2.49-2.491A6.251,6.251,0,0,0,12.265,6Zm0,1.728a4.538,4.538,0,0,1,3.3,7.647.864.864,0,0,0-.193.193,4.537,4.537,0,1,1-3.112-7.84Z')", // your svg icon path here
        },
      },
    },
  },
  onAddButtons: {
    backgroundColor: "#eaeff8",
    padding: "4px 10px 6px",
    borderRadius: "50%",
    marginTop: "23px",
    width: "32px",
    cursor: "pointer",
  },
  tableCellTypo: {
    color: "#2A3C50",
    fontSize: "14px",
    fontWeight: 500,
  },
  customRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #DEE5EC",
    paddingLeft: "20px",
  },
  calculationLabel: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    color: "#2A3C50",
  },
  alignItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  claculationSpan: {
    marginLeft: "5px",
    fontWeight: 500,
  },
  calculationTextField: {
    marginTop: "6px",
    width: "10%",
    background: "#fff",
  },
  forButton: {
    textTransform: "none",
  },
  addNewIconsAlign: {
    justifyContent: "end",
    display: "flex",
    alignItems: "center",
  },
  remarksTextField:{
    margin:"0px 0px 4px",width:"70%",background:"#fff",fontSize:"12px"
  },
  resize:{
    fontSize:"12px"
  }
}));
