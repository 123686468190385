import moment from "moment";
import { getUtcTime, makeName, utcTOLocal } from "../../../utils";
import { dbName } from "../../../utils";
import jwtDecode from "jwt-decode";

//create json for save
export const genereateSaveJson = (data) => {
  let extraJson = {};
  let filter = {};
  if (data?.data?.editId) {
    extraJson = {
      _id: data?.data?.editId,
    };
    filter = { filter: { _id: data.data?.editId } };
  }
  const patient = data?.encounterData?.[0] ?? {};
  // const prac =
  //   data?.personDetails?.practitioner?.[0]?.PractitionerRole?.[0]?.id ?? 0;
  // const prac = data?.personDetails?.practitioner?.[0]?._id ?? "";
  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo =
    userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const roleid = decodedUserinfo?.roleid ?? "";
  const practitionerid = decodedUserinfo?.practitionerid ?? "";
  const details = data?.data;

  return [
    {
      db_name: dbName,
      entity: "CAPatientClinicalDocs",
      is_metadata: true,
      metadataId: process.env.REACT_APP_METADATAID,
      metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
      ...filter,
      doc: {
        ...extraJson,
        id: 0,
        organizationid: JSON.stringify(patient?.facilityid),
        patientid: patient?.Patient?.[0]?._id,
        encounterid: patient?._id,
        practitionerid: practitionerid,
        doccategory: details.editId
          ? details?.OldselectCatagory?._id
          : details?.selectCatagory?._id,
        docformid: data?.formID,
        formdata: data?.formdata ?? {},
        status: details?.saveMode === "Draft" ? false : true,
        DocStatusHistoryLog: [
          {
            startdate: 0,
            enddate: 0,
            statusreasoncode: "",
            statushistory: "",
          },
        ],
        encountertype: patient?.visit_type?._id ?? "",
        doctype: details.editId
          ? details?.OldselectDoccument._id
          : details?.selectDoccument?._id,
        docdate: details.editId ? data?.docdate : getUtcTime(),
        documents: [
          {
            _id: "",
            date: "",
            url: "",
            id: 0,
            fileName: "",
            fileid: "",
            filetype: "",
            objectid: "",
          },
        ],
        reason: details?.selectReason?._id ?? "",
        specialty: patient?.practitioner_details?.[0]?.speciality?._id || "",
        facilityid: patient?.facilityid,
        //  "#/0/doc: required key [facilityid] not found",
        tenantid: patient?.tenantid,
      },
    },
  ];
};

//genreate json for read

export const generateResJson = (dataList, state) => {
  let obj = {};
  dataList.forEach((element) => {
    if (element?.doccategory?.display) {
      let category = element?.doccategory?.display;

      if (category in obj) {
        obj[category].count = obj[category].count + 1;
      } else {
        obj[category] = {
          count: 1,
          data: {},
        };

        obj[category].data = element;
      }
    }
  });

  console.log(Object.values(obj));

  return Object.values(obj);
};

//generate json for single read
export const generatesingleRead = (val, state) => {
  return {
    OldselectCatagory: state?.docc_cat_read?.data?.DOCCATEGORY?.find(
      (sys) => val?.doccategory?._id === sys._id
    ),
    OldselectDoccument: val?.doctype
      ? {
          label: val?.doctype?.docname,
          value: val?.doctype?.doccategory,
          formrefid: val?.doctype?.formrefid,
          _id: val?.doctype?._id,
        }
      : null,
    formID: val?.docformid,
    createdOn: utcTOLocal(val.createddate, "DD MMM YYYY"),
    editId: val._id,
    formData: val.formdata,
    saveMode: val.status === true ? "Final" : "Draft",
    ...val,
  };
};

//generate doc against category
export const genreateDocAgnstCategory = (data) => {
  try {
    let orderedData = data;
    let obj = {};
    orderedData.forEach((element) => {
      if (element?.doccategory?.display) {
        let category = element?.doccategory?.display;

        if (category in obj) {
          obj[category].count = obj[category].count + 1;

          let userObj = {
            title:
              element.practitionerid.length > 0
                ? element?.practitionerid[0]?.practitioner_role?.[0]?.role
                    ?.display
                : "",
            //title: "Role",

            date: utcTOLocal(element.updatedate, "MMMM Do  h:mm a"),
            name:
              element.practitionerid.length > 0
                ? makeName(element.practitionerid[0]?.name[0] || {})
                : "Dummy",

            ...element,
          };

          var childObj = {
            title: element.doctype.docname,

            user: [
              {
                ...userObj,
              },
            ],
          };

          let findobj = obj[category].child.find(
            (c) => c.title === childObj.title
          );
          if (findobj) {
            findobj.user.push(userObj);
          } else {
            obj[category].child.push(childObj);
          }
        } else {
          obj[category] = {
            title: category,
            count: 1,
            child: [],
          };

          let userObj = {
            title:
              element.practitionerid.length > 0
                ? element?.practitionerid[0]?.practitioner_role?.[0]?.role
                    ?.display
                : "",
            date: utcTOLocal(element.updatedate, "MMMM Do  h:mm:ss a"),
            name:
              element.practitionerid.length > 0
                ? makeName(element.practitionerid[0]?.name[0] || {})
                : "Dummy",
            ...element,
          };

          var childObj = {
            title: element.doctype.docname,

            user: [{ ...userObj }],
          };
          obj[category].child.push(childObj);
        }
      }
    });

    console.log(Object.values(obj));

    return Object.values(obj);
  } catch (err) {
    return [];
  }
};

export const generatePractitoner = (data) => {
  let orderedData = data.sort((a, b) => b?.updatedate - a?.updatedate);
  let arr = [];

  orderedData.forEach((element) => {
    let userObj = {
      title:
        element.practitionerid.length > 0
          ? element?.practitionerid[0]?.practitioner_role[0]?.role?.coding[0]
              ?.display
          : "",

      date: utcTOLocal(element.updatedate, "MMMM Do  h:mm a"),
      name:
        element.practitionerid.length > 0
          ? makeName(element.practitionerid[0]?.name[0] || {})
          : "Dummy",

      ...element,
    };
    arr.push(userObj);
  });

  return arr;
};

export const getData = (date, time) => {
  let da = date ? new Date(date) : new Date();
  let d = new Date(da).toDateString().split(" ");
  return `${d[2]} ${d[1]}, ${d[3]} ${time ? moment(da).format("LT") : ""}`;
};
