import React from "react";

export const Head = (props) => {
  let width = props.width ? props.width : "13";
  let height = props.height ? props.height : "15";
  //   let color = props.color ? props.color : "#000000";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20.733 21.735"
    >
      <path
        id="icons8-head"
        d="M14.461,1.99H14.37c-.835.008-3.591.02-3.591.02l-.362,0L9.449,3.265A7.091,7.091,0,0,0,5.773,5.929c-1.55,2.3-.4,5.742-.347,5.888l.025.074a2.485,2.485,0,0,0-1.7,4.253,2.611,2.611,0,0,0,1.83.691c.675,3.816,4.554,6.89,7.791,6.89,3.4,0,7.5-3.381,7.87-7.453a2.451,2.451,0,1,0,.043-4.9l0-1.059a17.029,17.029,0,0,0-.241-3.568A6.635,6.635,0,0,0,14.461,1.99ZM10.333,9.5a5.725,5.725,0,0,0,3.548,1.635,22.93,22.93,0,0,0,3.229-.382,10.031,10.031,0,0,1,1.652-.207c.321.283.788.755,1.045,1.018L19.8,15.684c0,3.621-3.887,6.56-6.424,6.56s-6.424-2.938-6.424-6.556l.02-3.593A7.011,7.011,0,0,0,10.333,9.5Zm10.953,3.353a.968.968,0,1,1,0,1.936Zm-15.8.517-.012,1.979a1.114,1.114,0,0,1-.694-.273,1.006,1.006,0,0,1,.705-1.705Zm4.438.479a.988.988,0,1,0,.988.988A.988.988,0,0,0,9.922,13.849Zm6.915,0a.988.988,0,1,0,.988.988A.988.988,0,0,0,16.837,13.849ZM11.4,19.281v.988h3.951v-.988Z"
        transform="translate(-3.016 -1.99)"
        fill="#03a594"
      />
    </svg>
  );
};
