import React from "react";
import { Div, Select, Col, Row, Paper, H6 } from "qdm-component-library";
import "./style.css";
import  withAllContexts  from "../../../hoc/withAllContexts";

function ReasonDetails(props) {
	const styles = {
		labelStyle: {
			color: "#6F6F6F",
			fontSize: "12px",
			marginBottom: "6px",
			fontFamily: `pc_regular !important`,
		},
		selectBorderStyle: {
			borderRadius: 8,
			borderColor: "#E0E0E0",
		},
		errorBorder:{
			borderRadius: 8,
			borderColor: "#f334466",
		},
		inputBorderStyle: {
			borderRadius: 8,
			border: "1px solid #E0E0E0",
			background: "#F0F0F0",
		},
	};

	const { device, helthcare, encounter } = props.visitData;

	const setDetails = (name, value, saveto) => {
		let patientData = props.visitData;
		patientData[saveto][name] = value;
		if (patientData[saveto].error) {
			patientData[saveto].error[name] = false;
		}
		patientData.setVisitDetails({
			...patientData,
		});
	};
	const FlterOptions3 = (type) => {
		let allMasters = props?.allMasters;
		let filteredArray = allMasters.filter((obj) => obj.hasOwnProperty([type]));
		let list = [];
		let data = filteredArray[0]?.[type].Value;
		for (let index in data) {
		  var datatemp = {
			value: data[index]?.display,
			_id: data[index]?._id,
		  };
		  list.push(datatemp);
		}
		return list;
	  };
	const { labelStyle, selectBorderStyle, inputBorderStyle,errorBorder } = styles;
	const { walkin } = props;

	React.useEffect(() => {
		const { appointmentData, walkin } = props;
		if (walkin) {
			if (appointmentData?.Practioner) {
				let patientData = props.visitData;
				// patientData.device['category'] = { value: appointmentData?.Practioner?.[0]?.roles?.[0]?.healthcareservices?.[0]?.category?.[0]?.text };
				// patientData.device["category"] = { value: "computed tomography" };
				patientData.device["category"] = { value: "" };
				patientData.setVisitDetails({
					...patientData,
				});
			}
		}

		if (walkin) {
			if (appointmentData?.Practioner) {
				let patientData = props.visitData;
				// patientData.device['type'] = { value: appointmentData?.Practioner?.[0]?.roles?.[0]?.healthcareservices?.[0]?.type?.[0]?.text ?? '' };
				// patientData.device["type"] = { value: "Scanner" };
				patientData.device["type"] = { value: "" };
				patientData.setVisitDetails({
					...patientData,
				});
			}
		}

		if (walkin) {
			if (appointmentData?.Practioner) {
				let patientData = props.visitData;
				// patientData.device["name"] = { value: "CT" };
				patientData.device["name"] = { value: "" };
				patientData.setVisitDetails({
					...patientData,
				});
			}
		}
	}, [props.appointmentData]);
	const { parent_id } = props;
	return (
		<Div id={`${parent_id}_device_details_parent_div`} className="pv-root">
			<Paper
				id={`${parent_id}_device_details_accompanied_parent_paper`}
				className="pl-root vr-device-paper"
			>
				<Div id={`${parent_id}_device_details_accompanied_title_div`}>
					<H6
						id={`${parent_id}_device_details_accompanied_label_h6`}
						className="en-title space-padding-bottom"
					>
						REASON
					</H6>
				</Div>
				<Row className="en-actions-root">
					<Col
						id={`${parent_id}_device_details_accompanied_parent_col`}
						md={12}
						lg={12}
						sm={12}
						xs={12}
						className="no-padding-left"
					>
						<Row
							id={`${parent_id}_device_details_accompanied_sub_row`}
							className="en-actions-root"
						>
							<Col
								id={`${parent_id}_device_details_accompanied_Reason_col`}
								md={6}
								lg={6}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									disabled={props.view}
									id={`${parent_id}_device_details_accompanied_Reason_select`}
									options={FlterOptions3("REVISEVISITREASON")}
									value={encounter?.Revist_Reason?.value ?? null}
									onChangeValue={(e) => setDetails("Revist_Reason", e, "encounter")}
									// helperText={
									// 	accompained?.error && accompained?.error["Reason"]
									// 		? "Is required"
									// 		: ""
									// }sssssss
									error={
										encounter?.error && encounter?.error["Revist_Reason"] 
											? true
											: false
									}
									isRequired={true}
									label={props.label}
									placeholder="select..."
									showArrow
									labelStyle={labelStyle}
									inLineStyles={encounter?.error && encounter?.error["Revist_Reason"] ? errorBorder : selectBorderStyle}
									hoverColor="#0071F2"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Paper>
		</Div>
	);
}

export default withAllContexts(ReasonDetails);
