import React from "react";

export const Respiration = (props) => {
  let width = props.width ? props.width : "13";
  let height = props.height ? props.height : "15";
  //   let color = props.color ? props.color : "#000000";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21.87 21.794"
    >
      <g id="icons8-respiration" transform="translate(-0.633 -1)">
        <path
          id="Path_92866"
          data-name="Path 92866"
          d="M9.936,52.383a3.456,3.456,0,0,0,2.331-3.264V45.078c0-2.884-.535-4.473-1.382-5.164A1.838,1.838,0,0,0,9.677,39.5c-2.262,0-5.578,5.129-6.044,9.809a19.7,19.7,0,0,0,0,3.454,1.383,1.383,0,0,0,1.831,1.313Z"
          transform="translate(-2.418 -31.851)"
          fill="#f9d2d2"
        />
        <path
          id="Path_92867"
          data-name="Path 92867"
          d="M75.986,52.3A3.17,3.17,0,0,1,74,49.037V45a11.334,11.334,0,0,1,.518-3.834,2.939,2.939,0,0,1,.95-1.4A1.893,1.893,0,0,1,76.59,39.4c2.262,0,5.578,5.129,6.044,9.809a19.7,19.7,0,0,1,0,3.454A1.383,1.383,0,0,1,80.8,53.976Z"
          transform="translate(-60.697 -31.768)"
          fill="#f9d2d2"
        />
        <path
          id="Path_92868"
          data-name="Path 92868"
          d="M2.6,52.081a1.934,1.934,0,0,1-1.088-.345,1.868,1.868,0,0,1-.794-1.52,19.859,19.859,0,0,1,0-3.54c.5-4.887,3.92-10.275,6.562-10.275a2.411,2.411,0,0,1,1.537.535.515.515,0,1,1-.656.794,1.473,1.473,0,0,0-.9-.294c-1.9,0-5.077,4.836-5.526,9.343a18.662,18.662,0,0,0,0,3.35v.052a.9.9,0,0,0,.363.708.838.838,0,0,0,.777.121l4.473-1.658a2.937,2.937,0,0,0,1.986-2.78V43.55a.518.518,0,1,1,1.036,0v3A3.959,3.959,0,0,1,7.69,50.3L3.217,51.96A1.593,1.593,0,0,1,2.6,52.081Z"
          transform="translate(0 -29.287)"
          fill="#444b54"
        />
        <path
          id="Path_92869"
          data-name="Path 92869"
          d="M78.771,52.081a1.956,1.956,0,0,1-.622-.1L73.331,50.3A3.638,3.638,0,0,1,71,46.555v-3a.518.518,0,0,1,1.036,0v3a2.729,2.729,0,0,0,1.623,2.78l4.818,1.675a.838.838,0,0,0,.777-.121.875.875,0,0,0,.363-.708v-.052a18.662,18.662,0,0,0,0-3.35c-.449-4.507-3.627-9.343-5.526-9.343a1.39,1.39,0,0,0-.829.259.518.518,0,1,1-.622-.829,2.336,2.336,0,0,1,1.433-.466c2.642,0,6.062,5.371,6.563,10.275a19.961,19.961,0,0,1,0,3.54,1.915,1.915,0,0,1-.794,1.52A1.764,1.764,0,0,1,78.771,52.081Z"
          transform="translate(-58.215 -29.287)"
          fill="#444b54"
        />
        <path
          id="Path_92870"
          data-name="Path 92870"
          d="M61.518,3.763a.509.509,0,0,0,.518-.518V1.518a.518.518,0,0,0-1.036,0V3.245A.509.509,0,0,0,61.518,3.763Z"
          transform="translate(-49.942 0)"
          fill="#444b54"
        />
        <path
          id="Path_92871"
          data-name="Path 92871"
          d="M39.26,39.22a7.161,7.161,0,0,0-5.924-3.989V31.518a.518.518,0,1,0-1.036,0v3.713A7.158,7.158,0,0,0,26.359,39.2a.518.518,0,1,0,.933.449,6.057,6.057,0,0,1,5.526-3.419h0a6.043,6.043,0,0,1,5.526,3.437.509.509,0,0,0,.466.294.532.532,0,0,0,.225-.052A.526.526,0,0,0,39.26,39.22Z"
          transform="translate(-21.242 -24.819)"
          fill="#444b54"
        />
      </g>
    </svg>
  );
};
