import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../../../../../themes/theme";

export const useStyles = makeStyles((theme) => ({
  weekcalendar: {
    "& span": {
      fontSize: 14,
      "& i": {
        fontSize: "24px !important",
      },
    },
  },
  TapSelect: {
    "& div": {
      padding: "2px 6px",
      "& span": {
        fontSize: 13,
        fontWeight: "600",
        letterSpacing: "0.9px",
      },
    },
  },
  icons: {
    display: "flex",
    alignItems: "center",
    margin: "4% 0px",
    justifyContent: "space-around",
  },
  iconText: {
    marginLeft: 8,
    color: UIColor.tertiaryText.color,
    fontSize: 12,
    fontFamily: "pc_regular !important",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
}));
