import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import {
  CustomButton,
  FieldContainer,
  FieldLabel,
  InputBase,
} from "./components";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: 16,
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: 600,
  },
  buttonContainer: {
    display: "flex",
    gap: 6,
    alignItems: "center",
  },
}));

function RemarksForm(props) {
  const classes = useStyles();
  const { name, value, setValue, handleClose } = props;
  const [remarks, setRemarks] = React.useState("");

  useEffect(() => {
    setRemarks(value);
  }, [value]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <Typography className={classes.title}>Add Remarks</Typography>
        <div className={classes.buttonContainer}>
          <CustomButton
            variant={"outlined"}
            color="#01205c"
            onClick={() => handleClose()}
          >
            Cancel
          </CustomButton>
          <CustomButton
            variant={"contained"}
            color="#FFFFFF"
            backgroundColor="#01205c"
            onClick={() => {
              setValue(remarks);
              // handleClose();
            }}
          >
            Save
          </CustomButton>
        </div>
      </div>
      <div>
        <FieldContainer>
          <FieldLabel variant={"body1"}>Remarks</FieldLabel>
          <InputBase
            id={"remarks"}
            label={""}
            placeholder={""}
            type={"text"}
            variant={"outlined"}
            size={"small"}
            fullWidth={true}
            multiline
            maxRows={4}
            value={remarks}
            onChange={(event) => setRemarks(event.target.value)}
          ></InputBase>
        </FieldContainer>
      </div>
    </div>
  );
}

export default RemarksForm;
