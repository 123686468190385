import React, { useEffect, useState } from "react";
import { useStyles } from "../styles";
import { Select, TextInput } from "qdm-component-library";
import { AlertProps } from "../../../utils";
//import QuantityRangePicker from "../patientDetail/medicationFlows/components/quantity-range-picker";
import Cancel from "../../../assets/img/svg/icons8-multiply.svg";
import Tick from "../../../assets/img/svg/icons8-double-tick.svg";
import { Grid } from "@material-ui/core";
// import {
//   chargeCodeDropDown,
//   newOrder,
//   optionsuom,
//   optionsvolumuom,
// } from "./dummyData";
import { useSelector, useDispatch } from "react-redux";
import { constructNewOrder } from "./constructJson";
import withAllContexts from "../../../hoc/withAllContexts";
import { UIColor } from "../../../themes/theme";
import actions from "../../../redux/actions";

const AddNewBillingOrder = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [chargeDropDwn, setChargeDropDwn] = useState({});
  const [uomDropDwn, setUomDropDwn] = useState({});
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
  };
  useEffect(() => {
    ChangeCodeDropDown();
    UomCodeDropDown();
  }, []);
  const chargeCodeDropDowns = useSelector(
    (state) => state?.billSettlementSlice?.Change_Code_DropDown
  );
  const uomDropDowns = useSelector(
    (state) => state?.billSettlementSlice?.Uom_Dropdown
  );

  const ChangeCodeDropDown = async () => {
    const chargeDropDwn = await dispatch(actions.CHARGE_CODE_DROPDOWN());
    setChargeDropDwn(chargeDropDwn?.payload?.data);
  };
  console.log(
    "======================================================================="
  );
  console.log(chargeDropDwn, "chargeDropDwn");
  const UomCodeDropDown = async () => {
    const uomDropDown = await dispatch(actions.UOM_DROPDOWN());
    setUomDropDwn(uomDropDown?.payload?.data);
  };
  const handleRemoveAdd = () => {
    props.handleRemoveAddnew();
  };

  const addValidate = () => {
    if (
      props?.newOrderSelected?.chargeCode?.value &&
      props?.newOrderSelected?.qty &&
      props?.newOrderSelected?.uom?.value
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddNewOrder = () => {
    const additionValidate = addValidate();
    if (additionValidate) {
      const constructNewOrders = constructNewOrder(props?.newOrderSelected);
      const calculatedNewOrder = generateCalculations(constructNewOrders);
      props.setNewOrderSelected({
        ...props.newOrderSelected,
        chargeCode: {},
        uom: {},
        qty: "",
      });
      props.handleAddNewOrder(calculatedNewOrder);
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  const handleChargeCode = (e, type) => {
    console.log(e, "ee");
    props.handleChargeCode(e, type);
  };

  const handleInputChange = (selector, value) => {};

  const generateCalculations = (newOrder) => {
    if (
      typeof newOrder === "object" &&
      !Array.isArray(newOrder) &&
      newOrder !== null
    ) {
      const gross = newOrder?.qty * newOrder?.rate;
      const discount = (gross * props?.deductedAmnt?.discountAmnt) / 100;
      const net = gross - discount;
      const patientShare = props?.deductedAmnt?.patientShare;
      const patientShareDiscount = (patientShare * net) / 100;
      const append = {
        gross: gross.toFixed(2),
        dicount: discount,
        net: net.toFixed(2),
        patientShare: patientShareDiscount.toFixed(2),
        creditShare: (net - patientShareDiscount).toFixed(2),
        action: "delete",
      };
      return { ...newOrder, ...append };
    } else {
      return newOrder;
    }
  };
  const GetIdAndDisplay = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      var datatemp = {
        value: item.longdesc,
        _id: item._id,
        rate: item.rate,
        service: item.service,
        servicedesc: item.servicedesc,
      };

      list.push(datatemp);
    }

    return list;
  };
  return (
    <div>
      <Grid
        container
        style={{
          padding: "16px 16px 12px",
          borderBottom: "1px solid #DFE5EB",
        }}
        spacing={2}
      >
        <Grid item xs={6}>
          <Select
            showArrow
            label="Charge Code & Description"
            // options={chargeCodeDropDowns?.data ?? []}
            options={GetIdAndDisplay(chargeDropDwn) ?? []}
            value={props?.newOrderSelected?.chargeCode?.value ?? ""}
            onChangeValue={(e) => handleChargeCode(e, "chargeCode")}
            placeholder="select..."
            labelStyle={styles.labelStyle}
            inLineStyles={styles.borderStyle}
            hoverColor={UIColor.primaryColor.color}
          />
        </Grid>
        <Grid item xs={6} style={{ display: "flex" }}>
          <Grid container>
            <Grid item xs={4} style={{ display: "flex" }}>
              <div style={{ width: "30%" }}>
                <TextInput
                  className={"textInput"}
                  id={"patient-share"}
                  label={"Qty"}
                  placeholder=""
                  // isRequired
                  labelStyle={styles.labelStyle}
                  style={{
                    ...styles.borderStyle,
                    ...styles.inputStyle,
                    borderRadius: "8px 0px 0px 8px",
                  }}
                  type="number"
                  value={props?.newOrderSelected?.qty}
                  onChange={(e) => handleChargeCode(e?.target?.value, "qty")}
                  hoverColor={UIColor.lineBorderFill.color}
                  // disabled={true}
                />
              </div>
              <div style={{ width: "70%" }}>
                <Select
                  showArrow
                  label=""
                  // options={uomDropDowns?.data ?? []}
                  options={GetIdAndDisplay(uomDropDwn) ?? []}
                  value={props?.newOrderSelected?.uom?.value ?? ""}
                  onChangeValue={(e) => handleChargeCode(e, "uom")}
                  placeholder=""
                  labelStyle={styles.labelStyle}
                  inLineStyles={{
                    ...styles.borderStyle,
                    marginTop: "24px",
                    borderRadius: "0px 8px 8px 0px",
                  }}
                  hoverColor={UIColor.primaryColor.color}
                />
              </div>
            </Grid>
            <Grid item xs={1} className={classes.addNewIconsAlign}>
              <div
                className={classes.onAddButtons}
                onClick={handleRemoveAdd}
                style={{ backgroundColor: "#ffeeed" }}
              >
                <img src={Cancel} alt="Cancel" width="12px" height="10px" />
              </div>
            </Grid>
            <Grid item xs={1} className={classes.addNewIconsAlign}>
              <div className={classes.onAddButtons} onClick={handleAddNewOrder}>
                <img src={Tick} alt="Tick" width="12px" height="10px" />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withAllContexts(AddNewBillingOrder);
