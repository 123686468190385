import React, { useEffect, useState } from "react";
import { H6 } from "qdm-component-library";
import { makeStyles } from "@material-ui/core/styles";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { AlertProps } from "../../utils";
import { UIColor } from "../../themes/theme";
import  withAllContexts from "../../hoc/withAllContexts";
import SelectMu from "@material-ui/core/Select";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	subHead: {
		cursor: "pointer",
		color: "#6F6F6F",
	},
	removeFocus: {
		"&:focus": {
			background: "#fff"
		}
	}
}));

function HighAlertPopUp(props) {
	const { priorityTotalData } = props;
	const styles = {
		title: {
			fontSize: 14,
			color: "#101010",
		},
		labelStyle: {
			color: "#6F6F6F",
			fontSize: "12px",
			marginBottom: "6px",
			fontFamily: `pc_regular !important`,
		},
		selectBorderStyle: {
			borderRadius: 8,
			borderColor: "#E0E0E0",
		},
		errBorder: {
			borderRadius: 8,
			borderColor: "#f33446",	
		}
	};

	const [disable, setDisable] = useState(false);
	const [removed, setRemoved] = useState(false);
	const [state, setState] = useState({
		priority: "",
		reason: "",
	});

	const getData = async () => {
		const priorityDropDownData = await props.PRIORITY_DROPDOWN();
		const priorityReasonDropDown = await props.PRIORITY_REASON_DROPDOWN();
	};

	useEffect(() => {
		getData();
		if (priorityTotalData?.highPriority) {
			setDisable(true);
		}
		setState({
			...state,
			priority: priorityTotalData?.patientPriority?.priority,
			reason: priorityTotalData?.patientPriority?.reason,
		});
	}, []);

	const handleChange = (name, value) => {
		setState({ ...state, [name]: value });
	};

	const getValue = (value, source, key = "label") => {
		const data = source?.find((item) => item[key] === value);
		return data;
	};

	const [err,setErr] =  useState(false)
	const handleSave = async () => {
		if (disable) {
			setDisable(false);
		} else {
			const list = {
				priority: state.priority.label,
				priorityreason: state.reason.label,
			};
			let dropdownValue = {
        priority: state.priority,
        reason: state.reason,
      };
			if ((list.priority && list.priorityreason) || removed) {
				const _key = priorityTotalData?.key;
				const app = priorityTotalData?.appointmentId;
				if (removed) {
					props.handlePriority({
						priority: "",
						priorityreason: "",
					}, _key, app, {
						priority: "",
						priorityreason: "",
					});
				} else {
					props.handlePriority(list, _key, app, dropdownValue);
				}
				props.handleHighAlertPopClose();
			} else {
				setErr(true)
				props.alert.setSnack({
					open: true,
					severity: AlertProps.severity.error,
					msg: "Please fill all the mandatory fields",
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.right,
					tone: true,
				});
			}
		}
	};

	const handleRemove = (fun) => {
		if(fun === "Remove"){
			// setRemoved(true);
      // const removeData = {
      //   priority: "",
      //   reason: "",
      // };
      // setState({
      //   ...state,
      //   priority: removeData.priority,
      //   reason: removeData.reason,
      // });
			const _key = priorityTotalData?.key;
			const app = priorityTotalData?.appointmentId;
			props.handlePriority({
				priority: "",
				priorityreason: "",
			}, _key, app, {
				priority: "",
				priorityreason: "",
			});
			props.handleHighAlertPopClose();
      // setDisable(false);
    } else if (fun === "Cancel") {
			setRemoved(false);
      props.handleHighAlertPopClose();
    }
		// const _key = priorityTotalData?.key;
		// props.handlePriority(removeData, _key);
		// props.handleHighAlertPopClose();
	};

	const classes = useStyles();
	return (
		<div>
			<div style={{ display: "flex", alignItems: "center" }}>
				<H6 style={styles.title}>{disable ? "Reason" : removed ? "Remove Priority?" : "Add Reason"} </H6>
				<div style={{ flexGrow: 1 }}></div>
				<div
					style={{ marginRight: "10px" }}
					className={classes.subHead}
					onClick={()=>handleRemove(disable ? "Remove" : "Cancel")}
				>
					<H6 style={{ fontSize: 14 }}>{disable ? "Remove" : "Cancel"} </H6>
				</div>
				<div
					style={{ color: "#0071F2" }}
					className={classes.subHead}
					onClick={handleSave}
				>
					<H6 style={{ fontSize: 14 }}>{disable ? "Edit" : "Save"} </H6>{" "}
				</div>
			</div>
			<div style={{ marginTop: "10px" }}>
				{/* <Select
					label="Priority Type"
					isRequired
					showArrow
					placeholder={false}
					labelStyle={styles.labelStyle}
					disabled={disable ? true : false}
					inLineStyles={err && !state?.priority?.value ? styles.errBorder :  styles.selectBorderStyle}
					onChangeValue={(e) => handleChange("priority", e)}
					// value={priorityDropDown?.priority?.value ?? ""}
					value={state?.priority?.value ?? ""}
					options={props.highPriorityData.priority_dropdown.data}
				/> */}
				<Typography style={styles.labelStyle}>Priority Type <span style={{color: UIColor.error.color}}>*</span></Typography>
				<SelectMu
					native
					style={{
						height: "38px",
					}}
					error={err && !state?.priority?.value}
					disabled={removed || disable}
					inputProps={{
						name: "priority",
						id: "priority-type",
						className: classes.removeFocus,
					}}
					variant="outlined"
					fullWidth
					value={removed ? "" : (state?.priority?.label ?? "")}
					onChange={(e) => handleChange("priority", getValue(e.target.value, props.highPriorityData.priority_dropdown.data))}
				>
					{(!state?.priority?.label || removed) && (
						<option aria-label="None" value="" disabled label="--Select priority type--" />
					)}
					{
						(
							Array.isArray(props.highPriorityData.priority_dropdown.data)
								? props.highPriorityData.priority_dropdown.data
								: []
						).map((item, index) => (
							<option key={index} value={item?.label}>{item?.value}</option>
						))
					}
				</SelectMu>
			</div>
			<div style={{ marginTop: "10px" }}>
				{/* <Select
					label="Reason"
					isRequired
					// value={priorityDropDown?.reason?.value}
					value={state?.reason?.value ?? ""}
					showArrow
					placeholder={false}
					disabled={disable ? true : false}
					labelStyle={styles.labelStyle}
					inLineStyles={err && !state?.reason?.value ? styles.errBorder : styles.selectBorderStyle}
					onChangeValue={(e) => handleChange("reason", e)}
					options={props.highPriorityData.reason_dropdown.data}
				/> */}
				<Typography style={styles.labelStyle}>Reason <span style={{color: UIColor.error.color}}>*</span></Typography>
				<SelectMu
					native
					style={{
						height: "38px",
					}}
					error={err && !state?.reason?.value}
					disabled={removed || disable}
					inputProps={{
						name: "reason",
						id: "reason",
						className: classes.removeFocus
					}}
					variant="outlined"
					fullWidth
					value={removed ? "" : (state?.reason?.label ?? "")}
					onChange={(e) => handleChange("reason", getValue(e.target.value, props.highPriorityData.reason_dropdown.data))}
				>
					{(!state?.reason?.label || removed) && (
						<option aria-label="None" value="" disabled label="--Select reason--"/>
					)}
					{
						(
							Array.isArray(props.highPriorityData.reason_dropdown.data)
								? props.highPriorityData.reason_dropdown.data
								: []
						).map((item, index) => (
							<option key={index} value={item?.label}>{item?.value}</option>
						))
					}
				</SelectMu>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	highPriorityData: state.highPriorityApiSlice,
});

export default connect(
	mapStateToProps,
	actions
)(withAllContexts(HighAlertPopUp));
