import React from "react";
import { Div, H6, ListItem } from "qdm-component-library";

function CardList(props) {
  const { middleComponent = <></>, title,parent_id } = props;
  return (
    <Div
      id={`${parent_id}_cardlist_parent_col`}
    >
      <ListItem
        id={`${parent_id}_cardlist_listitem`}
        title={title}
        inLineStyles={{
          // marginTop: "3%",
          marginBottom: "5px",
          paddingLeft: 17,
          paddingTop: 17,
          fontSize: 12
        }}
        description={false}
        profile={false}
        action={
          <div
            id={`${parent_id}_cardlist_div`}
            style={{
              display: "flex",
              right: "-12px",
              position: "absolute",
              top: "-12px",
              cursor: "pointer",
            }}
          >
            
          </div>
        }
      />
     
      <div
        id={`${parent_id}_cardlist_middle_div`}
        style={{ padding:'0px 17px' }}
      >
        {middleComponent}
      </div>
    </Div>
  );
}

export default CardList;
