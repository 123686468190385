import React, { useState, useRef, useEffect } from "react";
import {
  Div,
  Paper,
  Select,
  TextInput,
  H6,
  Col,
  Row,
  Text,
  Avatar,
  Divider,
  Checkbox,
  Upload,
  Image,
  Popover,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../../hoc/withAllContexts";
import { UIColor } from "../../../themes/theme";
import moment from "moment";
import {
  FlterOptions2,
  FlterOptions3,
  FlterOptions4,
} from "../components/handlers";
import { AlertProps } from "../../../utils";
// import ModifyStatus from "./modifyStatus";
import { EditIcon } from "../../../components/svg";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";
import RegUploadIcon from "../../../assets/img/png/RegUploadIcon.png";
import CustomInputDatePicker from "../../../components/customDatePicker/inputDatePicker";
import { editStatusOpen, getUseOptions, FlterOptions } from "./handlers";
import styles from "./styles";
import trashIcon from "../../../assets/img/svg/icons8-trash.svg";
import calendarIcon from "../../../assets/img/svg/icons8-calendar-grey.svg";
import ModifyStatus from "./modifyStatus";
// import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const PatientNames = (props) => {
  const myRef = useRef(null);
  const [state, setState] = useState({
    Showfilter: props?.[props.saveto]?.names,
    limited: false,
    editStatus: null,
    statusValue: "Inactive",
  });
  //Mapping for prefix
  const salutation = {
    Mr: "MALE",
    Mrs: "FEMALE",
    Ms: "FEMALE",
    Dato: "MALE",
  };

  const {
    labelStyle,
    borderStyle,
    statusBackground,
    status,
    dot,
    cameraIcon,
    idSpace,
    onUpload,
    inactiveDot,
    errorborderStyle,
  } = styles;
  const [defaultNationality, setdefaultNationality] = useState({});
  const [defaultAgeType, setdefaultAgeType] = useState({});
  // useEffect(() => {
  //   setPatientData({ ...props?.patientData });
  // }, [props.clear]);
  // useEffect(() => {
  //   setPatientData({...props?.patientData})
  // }, [props?.cleared]);
  // useEffect(() => {
  //   setPatientData({ ...props?.patientData });
  // }, [props?.states]);
  // useEffect(() => {
  //   if (!props?.isModify && !props?.isGoBack) {
  //     let nationalityFltr = props?.Nationality;
  //     nationalityFltr.map((val) => {
  //       if (
  //         val?.value === "Malaysia" &&
  //         val?._id === "SMGeographicMaster/10055"
  //       ) {
  //         setdefaultNationality(val);
  //         setPatientData((pre) => {
  //           let patData = { ...pre };
  //           patData.details["nationality"] = val;
  //         });
  //         setPatientData({ ...patientData });
  //       }
  //     });

  //     let ageTypeFltr = FlterOptions3(props?.allMasters, "AGETYPUOM");
  //     ageTypeFltr.map((val) => {
  //       if (val?.value === "Years" && val?._id === "CodingMaster/11343") {
  //         setdefaultAgeType(val);
  //         setPatientData((pre) => {
  //           let patData = { ...pre };
  //           patData.details["ageType"] = val;
  //         });
  //         setPatientData({ ...patientData });
  //       }
  //     });
  //   }
  // }, [props]);

  const [patientData, setPatientData] = useState({ ...props?.patientData });

  const { parent_id } = props;
  const { names, details, statusDetails } = props.patientData; //props?.[props?.saveto];

  useEffect(() => {
    if (props?.patientData !== patientData) {
      setPatientData(props?.patientData);
    }
  }, [props?.patientData]);

  const handleLimit = () => {
    setState({
      ...state,
      limited: !state.limited,
    });
  };

  const newRowAdd = (props) => {
    let patientD = props;
    patientD?.names.push({});
    setPatientData({ ...patientD });
    props?.setPatientData({ ...patientD });
    // patientData[props.setTo]({
    //   ...patientData,
    // });
  };
  const handleDelete = (patientDataprops, i) => {
    let patientD = patientDataprops;
    if (patientD.names.length <= 1) {
      props.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Atleast one identification should be entered",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else {
      patientD.names.splice(i, 1);
      setPatientData({ ...patientD });
      props?.setPatientData({ ...patientD });
    }
  };

  const setData = (props, name, value, index, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }

    let patientData = props;
    patientData.names[index][name] = value;
    if (patientData.names[index].error) {
      patientData.names[index].error[name] = false;
    }
    // patientData[this.props.setTo]({
    //   ...patientData,
    // });
    setPatientData({ ...patientData });
    props?.setPatientData({ ...patientData });
  };

  const setDetails = (props, name, value, next, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let patientData = props;
    patientData.details[name] = value;
    if (patientData.details?.error) {
      patientData.details.error[name] = false;
    }
    if (next === "age") {
      const age = moment().diff(moment(value, "YYYY-MM-DD"), "years");
      if (age > 200 || moment().unix() < moment(value, "YYYY-MM-DD").unix()) {
        if (patientData.details?.error) {
          patientData.details.error[name] = true;
        } else {
          patientData.details.error = {};
          patientData.details.error[name] = true;
        }
      } else {
        let days = moment().diff(moment(value, "YYYY-MM-DD"), "days");
        let months = moment().diff(moment(value, "YYYY-MM-DD"), "months");
        let years = moment().diff(moment(value, "YYYY-MM-DD"), "years");
        if (days <= 90) {
          patientData.details[next] = days;
          patientData.details.ageType = {
            value: "Days",
            label: "Days",
            _id: "CodingMaster/11376",
          };
        } else if (days > 90 && days < 365) {
          patientData.details[next] = months;
          patientData.details.ageType = {
            value: "Months",
            label: "Months",
            _id: "CodingMaster/11375",
          };
        } else if (days >= 365) {
          patientData.details[next] = years;
          patientData.details.ageType = {
            value: "Years",
            label: "Years",
            _id: "CodingMaster/11343",
          };
        }
        // patientData.details[next] = age;
        // let ageTypeOption = this.FlterOptions('VITALSUOM')
        // let option = ageTypeOption.filter(val => val.label.toLocaleLowerCase() === "years")[0];
        // patientData.details.ageType = option ? option : { value: 'Years', label: 'Years' };
      }
    }
    if (name === "ageType" || name === "age" || name === "entered_age") {
      const ageType = patientData?.details?.ageType?.value;
      const age = patientData?.details?.age;
      const enteredAge = patientData?.details?.entered_age;
      if (ageType && age && enteredAge) {
        const newDate = moment()
          .subtract(Number(age), ageType.toLowerCase())
          .format("YYYY-MM-DD");
        patientData.details.dob = newDate;
      }
    }
    // patientData[props.setTo]({
    //   ...patientData,
    // });
    setPatientData({ ...patientData });
    props?.setPatientData({ ...patientData });
  };

  const setAgeDetails = (name, value, next, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    // let patientData = props;
    patientData.details[name] = value;
    if (patientData.details?.error) {
      patientData.details.error[name] = false;
    }
    if (next === "age") {
      const age = moment().diff(moment(value, "YYYY-MM-DD"), "years");
      if (age > 200 || moment().unix() < moment(value, "YYYY-MM-DD").unix()) {
        if (patientData.details?.error) {
          patientData.details.error[name] = true;
        } else {
          patientData.details.error = {};
          patientData.details.error[name] = true;
        }
      } else {
        let days = moment().diff(moment(value, "YYYY-MM-DD"), "days");
        let months = moment().diff(moment(value, "YYYY-MM-DD"), "months");
        let years = moment().diff(moment(value, "YYYY-MM-DD"), "years");
        if (days <= 90) {
          patientData.details[next] = days;
          patientData.details.ageType = {
            value: "Days",
            label: "Days",
            _id: "CodingMaster/11376",
          };
        } else if (days > 90 && days < 365) {
          patientData.details[next] = months;
          patientData.details.ageType = {
            value: "Months",
            label: "Months",
            _id: "CodingMaster/11375",
          };
        } else if (days >= 365) {
          patientData.details[next] = years;
          patientData.details.ageType = {
            value: "Years",
            label: "Years",
            _id: "CodingMaster/11343",
          };
        }
        // patientData.details[next] = age;
        // let ageTypeOption = this.FlterOptions('VITALSUOM')
        // let option = ageTypeOption.filter(val => val.label.toLocaleLowerCase() === "years")[0];
        // patientData.details.ageType = option ? option : { value: 'Years', label: 'Years' };
      }
    }
    if (name === "ageType" || name === "age" || name === "entered_age") {
      const ageType = patientData?.details?.ageType?.value;
      const age = patientData?.details?.age;
      const enteredAge = patientData?.details?.entered_age;
      if (ageType && age && enteredAge) {
        const newDate = moment()
          .subtract(Number(age), ageType.toLowerCase())
          .format("YYYY-MM-DD");
        patientData.details.dob = newDate;
      }
    }
    // patientData[props.setTo]({
    //   ...patientData,
    // });
    setPatientData({ ...patientData });
    // props?.setPatientData({ ...patientData });
  };
  const checkAge = (val) => {
    let years = moment().diff(val, "years");
    let days = moment().diff(val, "days");
    if (!(years < 200 && days > 0)) {
      return true;
    }
    return false;
  };

  const checkAgeError = (details) => {
    if (!details?.dob && details?.error && details?.error?.["dob"]) {
      return "";
    } else if (
      details?.error?.["dob"] &&
      details?.dob &&
      checkAge(details?.dob)
    ) {
      return "Age should be less than 200 years";
    } else {
      return "";
    }
  };

  const enterAge = (val) => {
    if (val && parseInt(val) <= 200) {
      setAgeDetails("age", val, null, 3);
    } else {
      setAgeDetails("age", "", null, 3);
    }
  };

  useEffect(() => {
    setPatientData({ ...props?.patientData });
  }, [props?.editClick]);
  useEffect(() => {
    setPatientData({ ...props?.patientData });
  }, [props?.clear]);
  useEffect(() => {
    setPatientData({ ...props?.patientData });
  }, [props?.states]);

  const handlepopoverclose = () => {
    setState({ editStatus: null });
  };

  const handlepopoversave = (statusDet, value) => {
    setPatientData((prevstate) => ({
      ...prevstate,
      statusDetails: value,
    }));
    props?.setPatientData((prevstate) => ({
      ...prevstate,
      statusDetails: value,
    }));
  };

  return (
    <Div id={`${parent_id}_patient_name_parent_div`} className="pi-root">
      <Row
        id={`${parent_id}_patient_name_parent_row`}
        className={"pn-row-root"}
      >
        <Col
          id={`${parent_id}_patient_name_upload_col`}
          className="pn-image-root"
        >
          <Paper
            id={`${parent_id}_patient_name_upload_paper`}
            className="pi-paper pn-image-paper-root"
            style={{ padding: 8 }}
          >
            <Div
              id={`${parent_id}_patient_name_upload_avatar_div`}
              style={{ position: "relative" }}
            >
              <Avatar
                id={`${parent_id}_patient_name_upload_avatar`}
                variant="square"
                src={details?.image?.url || props.personImg}
                className={"pn-image"}
              />
              {/* adding delete icon */}
              <Div
                id={`${parent_id}_delete_image_icon`}
                style={{
                  position: "absolute",
                  right: "5px",
                  bottom: "35px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!props.isViewMode) {
                  setDetails(patientData, "image", null);
                  }
                }}
              >
                <img src={trashIcon} alt="Delete" />
              </Div>
              {true && (
                <>
                  <div
                    ref={myRef}
                    id={`${parent_id}_edit_patient_name_upload_position`}
                    style={onUpload}
                  >
                    <Upload
                      id={`${parent_id}_edit_patient_name_upload`}
                      handleChange={(e, value) => {
                        setDetails(patientData, "image", {
                          url: e,
                          file: value,
                        });
                      }}
                      label=""
                      accept="image/*"
                      labelStyle={{
                        fontSize: 14,
                        fontFamily: "pc_medium !important",
                      }}
                      className={"pn-upload-actions-btn"}
                      // list={}
                      showIcon={false}
                    />
                  </div>
                  <Div
                    id={`${parent_id}_image_camera_image`}
                    style={cameraIcon}
                  >
                    <img
                      src={RegUploadIcon}
                      style={{ width: 22, height: 22, zIndex: 99 }}
                      alt="camera"
                      onClick={() => {
                        if (!props.isViewMode) {
                        const current = myRef?.current;
                        if (current) {
                          const btn =
                            current?.querySelectorAll?.(
                              "input[type='file']"
                            )?.[0];
                          if (btn) {
                            btn.click();
                          }
                        }
                      }
                      }}
                    />
                  </Div>
                  {props?.mrn && (
                    <>
                      <Div
                        id={`${parent_id}_background_status`}
                        style={statusBackground}
                      ></Div>
                      <div id={`${parent_id}_status_image`} style={status}>
                        <Div
                          id={`${parent_id}_status_dot`}
                          style={
                            statusDetails?.conditionstatus?.display ===
                              "diseased" ||
                            statusDetails?.conditionstatus?.value ===
                              "diseased" ||
                            "" ||
                            statusDetails?.conditionstatus?.display ===
                              "revoked" ||
                            statusDetails?.conditionstatus?.value ===
                              "revoked" ||
                            ""
                              ? inactiveDot
                              : statusDetails?.status?.display === "active" ||
                                patientData?.statusDetails?.status?.value ===
                                  "active"
                              ? dot
                              : inactiveDot
                          }
                        ></Div>
                        {/* {JSON.stringify(statusDetails?.reason1)} */}
                        <Tooltip
                          title={
                            statusDetails?.deceasedreason
                              ? statusDetails?.deceasedreason?.display ||
                                statusDetails?.deceasedreason?.value
                              : statusDetails?.statusreason
                              ? statusDetails?.statusreason?.display ||
                                statusDetails?.statusreason?.value
                              : ""
                          }
                        >
                          <Typography
                            id={`${parent_id}_status_text`}
                            className="pc_regular"
                          >
                            {statusDetails?.status?.value ||
                              statusDetails?.status?.display ||
                              statusDetails?.conditionstatus?.display ||
                              patientData?.statusDetails?.conditionstatus
                                ?.value}
                          </Typography>
                        </Tooltip>
                        <div style={{ flexGrow: 1 }}></div>
                        <div
                          id={`${parent_id}_status_text`}
                          style={{
                            cursor: "pointer",
                            width: "20px",
                            textAlign: "end",
                          }}
                          
                          //onClick={(e) => setState(editStatusOpen(e, state))}
                          onClick={(e) => {
                            if (!props.isViewMode) {
                              setState(editStatusOpen(e, state));
                            }
                          }}
                        >
                          <EditIcon color="#fff" width="18px" height="13px" />
                        </div>
                      </div>
                    </>
                  )}

                  {/* <ClickAwayListener onClickAway={(e)=>{handlepopoverclose()}}> */}
                  {/* <div> */}
                  <Popover
                    id={`${parent_id}_status_popOver`}
                    open={state.editStatus}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    style={{ zIndex: "100", width: "670px", top: "200px" }}
                  >
                    <ModifyStatus
                      parent_id={parent_id}
                      handlepopoverclose={() => handlepopoverclose()}
                      handleSave={(statusDet, value) =>
                        handlepopoversave(statusDet, value)
                      }
                      statusDetails={props?.patientData?.statusDetails}
                      allMasters={props?.allMasters}
                    />
                  </Popover>
                  {/* </div> */}
                  {/* </ClickAwayListener> */}
                </>
              )}
            </Div>

            <Div
              id={`${parent_id}_patient_name_upload_sub_div`}
              className="pn-upload-actions"
              style={{ padding: "9px 0px" }}
            >
              {/* <Button className="pn-upload-actions-btn" name="Upload Image" /> */}
              {
                props?.mrn ? (
                  <Div id={`${parent_id}_mrn_id_div`} style={idSpace}>
                    <H6
                      id={`${parent_id}_mrn_id_div`}
                      style={{
                        paddingBottom: "4px",
                        color: UIColor.secondaryButtonColor.color,
                      }}
                      className="pi-title"
                    >
                      {props?.mrn}
                    </H6>
                  </Div>
                ) : (
                  <Div
                    style={{
                      padding: 4,
                      lineHeight: 1,
                      textAlign: "center",
                      color: UIColor.secondaryButtonColor.color,
                      backgroundColor: UIColor.differentiationBackground.color,
                    }}
                  >
                    MRN
                  </Div>
                )
                // <Upload
                //     id={`${parent_id}_patient_name_upload`}
                //     handleChange={(e, value) => {
                //         if (value.type === "image/png" || value.type === "image/jpg" || value.type === "image/jpeg") {
                //             this.setDetails("image", { url: e, file: value })
                //         } else {
                //             this.props.alert.setSnack({
                //                 open: true,
                //                 severity: AlertProps.severity.error,
                //                 msg: "Please Select an Image file.",
                //                 vertical: AlertProps.vertical.top,
                //                 horizontal: AlertProps.horizontal.center,
                //                 tone: true,
                //             });
                //         }
                //     }}
                //     label="Upload Image"
                //     labelStyle={{ fontSize: 14, fontFamily: 'pc_medium !important' }}
                //     className={"pn-upload-actions-btn"}
                //     showIcon={false}
                //     accept="image/*"
                // />
              }
            </Div>
          </Paper>
        </Col>
        <Col id={`${parent_id}_patient_name_sub_col`} className="pn-names-root">
          <Paper
            id={`${parent_id}_patient_name_sub_paper`}
            className="pi-paper"
            inLineStyles={{ height: "96% !important" }}
          >
            <Div id={`${parent_id}_patient_name_label_div`}>
              <H6
                id={`${parent_id}_patient_name_label_paitient_h6`}
                className="pi-title"
              >
                {props.saveto === "nextOfKin"
                  ? "NEXT OF KIN NAME"
                  : "PATIENT NAMES"}
              </H6>
              {!props.isViewMode && props.saveto !== "nextOfKin" && (
                <Text
                  id={`${parent_id}_patient_name_label_addnew_text`}
                  onClick={() => newRowAdd(patientData)}
                  className="pi-addbtn"
                >
                  + Add New
                </Text>
              )}
            </Div>

            {names
              ?.slice(0, state.limited ? names.length : 1)
              ?.map((val, i) => (
                <Row
                  id={`${parent_id}_${i}_patient_name_map_row`}
                  className="pi-actions-root pi-names-root"
                >
                  <Col
                    id={`${parent_id}_${i}_patient_name_col`}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_${i}_patient_name_use_label_select`}
                      value={val?.use?.value ?? null}
                      // helperText={(val?.error && val?.error['use']) ? 'Is required' : ''}
                      isRequired={false}
                      options={getUseOptions(
                        props?.allMasters,
                        patientData,
                        val?.use?.value ?? null
                      )}
                      disabled={
                        props?.isViewMode ||
                        statusDetails?.status?.display === "inactive" ||
                        statusDetails?.status?.value === "inactive" ||
                        "" ||
                        statusDetails?.conditionstatus?.display ===
                          "diseased" ||
                        statusDetails?.conditionstatus?.value === "diseased" ||
                        ""
                          ? true
                          : false
                      }
                      // options={USE}
                      // options={[
                      //     { value: 'usual', label: 'US001' },
                      //     { value: 'official', label: 'US002' },
                      //     { value: 'temp', label: 'US003' },
                      //     { value: 'nickname', label: 'US004' },
                      //     { value: 'anonymous', label: 'US005' },
                      //     { value: 'old', label: 'US006' },
                      //     { value: 'maidan', label: 'US007' },
                      // ]}
                      error={val?.error && val?.error["use"] ? true : false}
                      onChangeValue={(e) => setData(patientData, "use", e, i)}
                      label="Use"
                      showArrow
                      placeholder="use"
                      labelStyle={labelStyle}
                      inLineStyles={
                        val?.error && val?.error["use"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_${i}_patient_name_prefix_col`}
                    className="no-padding-left"
                  >
                    <Select
                      //Mapping prefix with gender
                      id={`${parent_id}_${i}_patient_name_prefix_label_select`}
                      value={val?.prefix?.value ?? null}
                      onChangeValue={(e) => {
                        const selectedPrefix = e.value;
                        const preferedGender = salutation[selectedPrefix];
                        const genderData = FlterOptions3(
                          props?.allMasters,
                          "GENDER"
                        );
                        const selectedGender = genderData.find(
                          (g) => g.value == preferedGender
                        );
                        setDetails(patientData, "gender", selectedGender);
                        setData(patientData, "prefix", e, i);
                      }}
                      options={FlterOptions3(props?.allMasters, "NAMEPREFIX")}
                      // helperText={(val?.error && val?.error['prefix']) ? 'Is required' : ''}
                      disabled={
                        props?.isViewMode ||
                        statusDetails?.status?.display === "inactive" ||
                        statusDetails?.status?.value === "inactive" ||
                        "" ||
                        statusDetails?.conditionstatus?.display ===
                          "diseased" ||
                        statusDetails?.conditionstatus?.value === "diseased" ||
                        ""
                          ? true
                          : false
                      }
                      isRequired={false}
                      error={val?.error && val?.error["prefix"] ? true : false}
                      label="Prefix"
                      showArrow
                      placeholder="prefix"
                      labelStyle={labelStyle}
                      inLineStyles={
                        val?.error && val?.error["prefix"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_${i}_patient_name_firstname_col`}
                    className="no-padding-left"
                  >
                    <TextInput
                      autoFocus={true}
                      id={`${parent_id}_${i}_patient_name_firstname_label_textinput`}
                      // helperText={(val?.error && val?.error['first_name']) ? 'Is required' : ''}
                      value={val.first_name ? val.first_name : ""}
                      onChange={(e) => {
                        if (/^[a-zA-Z\s@\/]*$/.test(e.target.value)) {
                          setData(patientData, "first_name", e.target.value, i, 60);
                        }
                      }}
                      isRequired={true}
                      error={
                        val?.error && val?.error["first_name"] ? true : false
                      }
                      label="First Name"
                      labelStyle={labelStyle}
                      style={
                        val?.error && val?.error["first_name"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      placeholder="first name"
                      // onKeyPress={function (e) {
                      //     var key = e.which;
                      //     if ((key >= 65 && key <= 90) || (key >= 95 && key <= 122)) {
                      //         return
                      //     } else {
                      //         e.preventDefault()
                      //     }
                      // }}
                      hoverColor={UIColor.primaryColor.color}
                      disabled={
                        props?.isViewMode ||
                        statusDetails?.status?.display === "inactive" ||
                        statusDetails?.status?.value === "inactive" ||
                        "" ||
                        statusDetails?.conditionstatus?.display ===
                          "diseased" ||
                        statusDetails?.conditionstatus?.value === "diseased" ||
                        ""
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_${i}_patient_name_middlename_col`}
                    className="no-padding-left"
                  >
                    <TextInput
                      id={`${parent_id}_${i}_patient_name_middlename_textinput`}
                      value={val.middle_name ? val.middle_name : ""}
                      onChange={(e) => {
                        if (/^[a-zA-Z\s@\/]*$/.test(e.target.value)) {
                        setData(
                          patientData,
                          "middle_name",
                          e.target.value,
                          i,
                          60
                        )
                        }}
                      }
                      // helperText={(val?.error && val?.error['middle_name']) ? 'Is required' : ''}
                      disabled={
                        props?.isViewMode ||
                        statusDetails?.status?.display === "inactive" ||
                        statusDetails?.status?.value === "inactive" ||
                        "" ||
                        statusDetails?.conditionstatus?.display ===
                          "diseased" ||
                        statusDetails?.conditionstatus?.value === "diseased" ||
                        ""
                          ? true
                          : false
                      }
                      // isRequired={true}
                      error={
                        val?.error && val?.error["middle_name"] ? true : false
                      }
                      placeholder="middle name"
                     label="Middle Name"
                      labelStyle={labelStyle}
                      style={
                        val?.error && val?.error["middle_name"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_${i}_patient_name_lastname_col`}
                    className="no-padding-left"
                  >
                    <TextInput
                      id={`${parent_id}_${i}_patient_name_lastname_label_textinput`}
                      value={val.last_name ? val.last_name : ""}
                      onChange={(e) => {
                      //  if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                        if (/^[a-zA-Z\s@\/]*$/.test(e.target.value)) {
                       setData(patientData, "last_name", e.target.value, i, 60)
                      }
                    }}
                      // helperText={(val?.error && val?.error['last_name']) ? 'Is required' : ''}
                      disabled={
                        props?.isViewMode ||
                        statusDetails?.status?.display === "inactive" ||
                        statusDetails?.status?.value === "inactive" ||
                        "" ||
                        statusDetails?.conditionstatus?.display ===
                          "diseased" ||
                        statusDetails?.conditionstatus?.value === "diseased" ||
                        ""
                          ? true
                          : false
                      }
                      isRequired={false}
                      // onKeyPress={function (e) {
                      //     var key = e.which;
                      //     if ((key >= 65 && key <= 90) || (key >= 95 && key <= 122)) {
                      //         return
                      //     } else {
                      //         e.preventDefault()
                      //     }
                      // }}
                      error={
                        val?.error && val?.error["last_name"] ? true : false
                      }
                      placeholder="last name"
                      label="Last Name"
                      labelStyle={labelStyle}
                      style={
                        val?.error && val?.error["last_name"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                    />
                  </Col>
                  {/* <Col
                    id={`${parent_id}_${i}_patient_name_suffix_col`}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_${i}_patient_name_suffix_label_select`}
                      value={val?.suffix?.value ?? null}
                      onChangeValue={(e) =>
                        setData(patientData, "suffix", e, i)
                      }
                      options={FlterOptions3(props?.allMasters, "NAMESUFFIX")}
                      // helperText={(val?.error && val?.error['suffix']) ? 'Is required' : ''}
                      disabled={
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                      // isRequired={true}
                      error={val?.error && val?.error["suffix"] ? true : false}
                      label="Suffix"
                      showArrow
                      placeholder="suffix"
                      labelStyle={labelStyle}
                      inLineStyles={
                        val?.error && val?.error["suffix"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                    />
                  </Col> */}
                  {props?.saveto !== "nextOfKin" && (
                    <Col
                      id={`${parent_id}_${i}_patient_name_show_col`}
                      style={{
                        margin: "auto",
                        padding: 0,
                      }}
                    >
                      <Div
                        id={`${parent_id}_${i}_patient_name_show_div`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 18,
                          width: "max-content",
                        }}
                      >
                        {/* <i className="fa fa-trash-o" style={{color:'#FF4D4A',cursor:'pointer'}} aria-hidden="true"></i> */}
                        <Div
                          id={`${parent_id}_${i}_patient_name_delete_div`}
                          style={{
                            width: 15,
                            height: 18,
                            cursor: "pointer",
                          }}
                        >
                        {!props.isViewMode && (
                          <Image
                            id={`${parent_id}_${i}_patient_name_delete_trash_image`}
                            src={trashIcon}
                            alt="delete"
                            onClick={() => handleDelete(patientData, i)}
                          />
                          )}
                        </Div>
                        {names?.length > 1 && i === 0 && (
                          <Text
                            id={`${parent_id}_${i}_patient_name_show_showless_label_text`}
                            className="pc_medium"
                            style={{
                              color: "#F58B00",
                              marginLeft: 15,
                              cursor: "pointer",
                              fontSize: "14px",
                            }}
                            onClick={() => handleLimit()}
                          >
                            {state.limited
                              ? "Show Less"
                              : `Show +${names?.length - 1}`}
                          </Text>
                        )}
                      </Div>
                    </Col>
                  )}
                </Row>
              ))}
            <Divider
              id={`${parent_id}_patient_name_bottom_divider`}
              className="p-dashed-divider "
              inLineStyles={{ margin: "5px 0px" }}
            />
            <Row
              id={`${parent_id}_patient_name_parent_row`}
              className="pi-basic-details pi-actions-root"
            >
              <Col
                id={`${parent_id}_patient_name_gender_col`}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_patient_name_gender_select`}
                  isRequired={true}
                  showArrow
                  label="Gender"
                  options={FlterOptions3(props?.allMasters, "GENDER")}
                  // options={[
                  //     { value: 'Male', label: 'Male' },
                  //     { value: 'Female', label: 'Female' },
                  //     { value: 'Unknown', label: 'Unknown' },
                  // ]}
                  value={details?.gender?.value ?? null}
                  onChangeValue={(e) => setDetails(patientData, "gender", e)}
                  placeholder="select..."
                  labelStyle={labelStyle}
                  inLineStyles={
                    details?.error && details?.error["gender"]
                      ? errorborderStyle
                      : borderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  // helperText={(details?.error && details?.error['gender']) ? 'Is required' : ''}
                  disabled={
                    props?.isViewMode ||
                    statusDetails?.status?.display === "inactive" ||
                    statusDetails?.status?.value === "inactive" ||
                    "" ||
                    statusDetails?.conditionstatus?.display === "diseased" ||
                    statusDetails?.conditionstatus?.value === "diseased" ||
                    ""
                      ? true
                      : false
                  }
                  error={
                    details?.error && details?.error["gender"] ? true : false
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_patient_name_dateformat_col`}
                className="no-padding-left"
              >
                {/* <TextInput
                                    id={`${parent_id}_patient_name_dateformat_textinput`}
                                    value={details.dob ? details.dob : ''}
                                    helperText={this.checkAgeError(details)}
                                    helperTextType="error"
                                    error={(details?.error && details?.error['dob']) ? true : false}
                                    max={moment(new Date()).format('YYYY-MM-DD')}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                    // onKeyPress={function (event) {
                                    //     event.preventDefault();
                                    //     return
                                    // }}
                                    onChange={(e) => this.setDetails("dob", e.target.value, "age")}
                                    minDate={(moment().subtract(200, 'years')).format('YYYY-MM-DD')}
                                    isRequired={true}
                                    dateIcon="images/icons8-calendar-grey.svg"
                                    label="Date of Birth"
                                    type="date"
                                    labelStyle={labelStyle}
                                    style={(details?.error && details?.error['dob']) ? errorborderStyle : borderStyle}
                                    hoverColor={UIColor.primaryColor.color}
                                    className="dateInput"
                                /> */}
                <CustomInputDatePicker
                  disabled={
                    props?.isViewMode || 
                    (statusDetails?.status?.display === "inactive" ||
                      statusDetails?.status?.value === "inactive" ||
                      "" ||
                      statusDetails?.conditionstatus?.display === "diseased" ||
                      statusDetails?.conditionstatus?.value === "diseased" ||
                      "" ||
                      details?.entered_age) 
                  }
                  placeholderText="dd/mm/yy"
                  // minDate = {(moment().subtract(200, 'years')).format('YYYY-MM-DD')}
                  // maxDate = {moment(new Date()).format('YYYY-MM-DD')}
                  maxDate={new Date()}
                  minDate={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() - 200)
                    )
                  }
                  handleChange={(value) => {
                    if (!details?.entered_age) { 
                      setDetails(
                        patientData,
                        "dob",
                        value ? moment(value).format("YYYY-MM-DD") : null,
                        "age"
                      );
                    }
                  }}
                  selectedDate={details.dob ? new Date(details.dob) : null}
                  inputField={
                    <TextInput
                      id={`${parent_id}_patient_name_dateformat_textinput`}
                      label="Date of Birth"
                      placeholder="dd/mm/yyyy"
                      dateIcon={calendarIcon}
                      labelStyle={labelStyle}
                      isRequired={true}
                      // onChange={() => alert("Hi")}
                      value={
                        details.dob
                          ? moment(new Date(details.dob)).format("DD/MM/YYYY")
                          : ""
                      }
                      style={
                        details?.error && details?.error["dob"]
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                      helperText={"Invalid Date of Birth"}
                      disabled={
                        props?.isViewMode ||
                        statusDetails?.status?.display === "inactive" ||
                        statusDetails?.status?.value === "inactive" ||
                        "" ||
                        statusDetails?.conditionstatus?.display ===
                          "diseased" ||
                        statusDetails?.conditionstatus?.value === "diseased" ||
                        ""
                          ? true
                          : false
                      }
                    />
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_patient_name_age_col`}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_patient_name_age_textinput`}
                  value={details?.age ?? ""}
                  onChange={(e) => enterAge(e.target.value)}
                  onChangeValue={(e) => setAgeDetails(patientData, "age", e)}
                  label="Age"
                  labelStyle={labelStyle}
                  style={borderStyle}
                  hoverColor={UIColor.primaryColor.color}
                  placeholder="age"
                  type="number"
                  disabled={
                    props?.isViewMode ||
                    statusDetails?.status?.display === "inactive" ||
                    statusDetails?.status?.value === "inactive" ||
                    "" ||
                    statusDetails?.conditionstatus?.display === "diseased" ||
                    statusDetails?.conditionstatus?.value === "diseased" ||
                    ""
                      ? true
                      : false
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_patient_name_age_type_col`}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_patient_name_age_type_select`}
                  isRequired={true}
                  showArrow
                  label="Age Type"
                  placeholder="select..."
                  labelStyle={labelStyle}
                  inLineStyles={
                    details?.error && details?.error["ageType"]
                      ? errorborderStyle
                      : borderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                  // style={{width:"400px"}}
                  options={FlterOptions3(props?.allMasters, "AGETYPUOM")}
                  // options={[
                  //     { value: 'Years', label: 'Years' },
                  //     { value: 'Months', label: 'Months' },
                  //     { value: 'Days', label: 'Days' },
                  // ]}
                  value={details?.ageType?.value ?? defaultAgeType?.value}
                  onChangeValue={(e) => setDetails(patientData, "ageType", e)}
                  // helperText={(details?.error && details?.error['ageType']) ? 'Is required' : ''}
                  disabled={
                    props?.isViewMode ||
                    statusDetails?.status?.display === "inactive" ||
                    statusDetails?.status?.value === "inactive" ||
                    "" ||
                    statusDetails?.conditionstatus?.display === "diseased" ||
                    statusDetails?.conditionstatus?.value === "diseased" ||
                    ""
                      ? true
                      : false
                  }
                  error={
                    details?.error && details?.error["ageType"] ? true : false
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_patient_name_aged_check_col`}
                className="no-padding-left"
              >
                <H6
                  id={`${parent_id}_patient_name_entered_age_h6`}
                  className="default-size pc_regular"
                >
                  Entered Age
                </H6>
                <Checkbox
                  id={`${parent_id}_patient_name_entered_age_checkbox`}
                  checked={details?.entered_age}
                  onChange={(e) =>
                    setDetails(
                      patientData,
                      "entered_age",
                      !details?.entered_age
                    )
                  }
                  name=""
                  color={UIColor.secondaryButtonColor.color}
                  style={{ marginTop: "8%" }}
                  disabled={
                    props?.isViewMode ||
                    statusDetails?.status?.display === "inactive" ||
                    statusDetails?.status?.value === "inactive" ||
                    "" ||
                    statusDetails?.conditionstatus?.display === "diseased" ||
                    statusDetails?.conditionstatus?.value === "diseased" ||
                    ""
                      ? true
                      : false
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_patient_name_marital_col`}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_patient_name_maritalstatus_label_select`}
                  options={FlterOptions3(props?.allMasters, "MARITALSTATUS")}
                  disabled={
                    props?.isViewMode ||
                    statusDetails?.status?.display === "inactive" ||
                    statusDetails?.status?.value === "inactive" ||
                    "" ||
                    statusDetails?.conditionstatus?.display === "diseased" ||
                    statusDetails?.conditionstatus?.value === "diseased" ||
                    ""
                      ? true
                      : false
                  }
                  label="Marital Status"
                  value={details?.marital_status?.value ?? null}
                  onChangeValue={(e) =>
                    setDetails(patientData, "marital_status", e)
                  }
                  showArrow
                  placeholder="select..."
                  labelStyle={labelStyle}
                  inLineStyles={borderStyle}
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
              <Col
                id={`${parent_id}_patient_name_nationality_col`}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_patient_name_nationality_select`}
                  isRequired={true}
                  showArrow
                  label="Nationality"
                  options={props?.Nationality}
                  //options={props.Nas}
                  // options={[
                  //     { value: "Indian" },
                  //     { value: "Russian" },
                  //     { value: "German" },
                  // ]}
                  // helperText={(details?.error && details?.error['nationality']) ? 'Is required' : ''}
                  disabled={
                    props?.isViewMode ||
                    statusDetails?.status?.display === "inactive" ||
                    statusDetails?.status?.value === "inactive" ||
                    "" ||
                    statusDetails?.conditionstatus?.display === "diseased" ||
                    statusDetails?.conditionstatus?.value === "diseased" ||
                    ""
                      ? true
                      : false
                  }
                  error={
                    details?.error && details?.error["nationality"]
                      ? true
                      : false
                  }
                  value={
                    details?.nationality?.value ?? defaultNationality?.value
                  }
                  onChangeValue={(e) =>
                    setDetails(patientData, "nationality", e)
                  }
                  placeholder="select..."
                  labelStyle={labelStyle}
                  inLineStyles={
                    details?.error && details?.error["nationality"]
                      ? errorborderStyle
                      : borderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
              {/* <Col
                id={`${parent_id}_patient_name_nationality_col`}
                className="no-padding-left"
              >
                {props.saveto === "nextOfKin" && (
                  <Select
                    id={`${parent_id}_patient_name_relationship_select`}
                    isRequired={true}
                    showArrow
                    label="Relationship"
                    options={FlterOptions4(props?.allMasters, "RELATIONSHIP")}
                    disabled={
                      props?.isViewMode ||
                      statusDetails?.status?.display === "inactive" ||
                      statusDetails?.status?.value === "inactive" ||
                      "" ||
                      statusDetails?.conditionstatus?.display === "diseased" ||
                      statusDetails?.conditionstatus?.value === "diseased" ||
                      ""
                    }
                    error={details?.error && details?.error["relationship"]}
                    value={details?.relationship?.value ?? null}
                    onChangeValue={(e) =>
                      setDetails(patientData, "relationship", e)
                    }
                    placeholder="select..."
                    labelStyle={labelStyle}
                    inLineStyles={
                      details?.error && details?.error["relationship"]
                        ? errorborderStyle
                        : borderStyle
                    }
                    hoverColor={UIColor.primaryColor.color}
                  />
                )}
              </Col>
              <Col
                id={`${parent_id}_patient_name_nationality_col`}
                className="no-padding-left"
              >
                {props.saveto === "nextOfKin" && (
                  <Select
                    id={`${parent_id}_patient_name_occupation_select`}
                    isRequired={true}
                    showArrow
                    label="Occupation"
                    options={FlterOptions4(props?.allMasters, "OCCUPATION")}
                    disabled={
                      props?.isViewMode ||
                      statusDetails?.status?.display === "inactive" ||
                      statusDetails?.status?.value === "inactive" ||
                      "" ||
                      statusDetails?.conditionstatus?.display === "diseased" ||
                      statusDetails?.conditionstatus?.value === "diseased" ||
                      ""
                    }
                    error={details?.error && details?.error["occupation"]}
                    value={details?.occupation?.value ?? null}
                    onChangeValue={(e) =>
                      setDetails(patientData, "occupation", e)
                    }
                    placeholder="select..."
                    labelStyle={labelStyle}
                    inLineStyles={
                      details?.error && details?.error["occupation"]
                        ? errorborderStyle
                        : borderStyle
                    }
                    hoverColor={UIColor.primaryColor.color}
                  />
                )}
              </Col>
              <Col
                id={`${parent_id}_patient_name_aged_check_col`}
                className="no-padding-left"
              >
                {props.saveto === "nextOfKin" && (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <H6
                        id={`${parent_id}_patient_name_emergency_contact_person_h6`}
                        className="default-size pc_regular"
                        style={{
                          whiteSpace: "nowrap",
                          display: "inline-block",
                        }}
                      >
                        Emergency Contact Person
                      </H6>
                      <Checkbox
                        id={`${parent_id}_patient_name_emergency_contact_person_checkbox`}
                        checked={details?.emergency_contact_person}
                        onChange={(e) =>
                          setDetails(
                            patientData,
                            "emergency_contact_person",
                            !details?.emergency_contact_person
                          )
                        }
                        name=""
                        color={UIColor.secondaryButtonColor.color}
                        style={{ marginTop: "8%", display: "inline-block" }}
                        disabled={
                          props?.isViewMode ||
                          statusDetails?.status?.display === "inactive" ||
                          statusDetails?.status?.value === "inactive" ||
                          "" ||
                          statusDetails?.conditionstatus?.display ===
                            "diseased" ||
                          statusDetails?.conditionstatus?.value ===
                            "diseased" ||
                          ""
                            ? true
                            : false
                        }
                      />
                    </div>
                  </>
                )}
              </Col> */}
            </Row>
            {props.saveto === "nextOfKin" &&(
            <Divider
              id={`${parent_id}_patient_name_bottom_divider`}
              className="p-dashed-divider "
              inLineStyles={{ margin: "5px 0px" }}
            />
            )}
            <Row
              id={`${parent_id}_patient_name_parent_row`}
              className="pi-basic-details pi-actions-root"
            >
              <Col
                id={`${parent_id}_patient_name_nationality_col`}
                className="no-padding-left"
              >
                {props.saveto === "nextOfKin" && (
                  <Select
                    id={`${parent_id}_patient_name_relationship_select`}
                    isRequired={true}
                    showArrow
                    label="Relationship"
                    options={FlterOptions4(props?.allMasters, "RELATIONSHIP")}
                    disabled={
                      props?.isViewMode ||
                      statusDetails?.status?.display === "inactive" ||
                      statusDetails?.status?.value === "inactive" ||
                      "" ||
                      statusDetails?.conditionstatus?.display === "diseased" ||
                      statusDetails?.conditionstatus?.value === "diseased" ||
                      ""
                    }
                    error={details?.error && details?.error["relationship"]}
                    value={details?.relationship?.value ?? null}
                    onChangeValue={(e) =>
                      setDetails(patientData, "relationship", e)
                    }
                    placeholder="select..."
                    labelStyle={labelStyle}
                    inLineStyles={
                      details?.error && details?.error["relationship"]
                        ? errorborderStyle
                        : borderStyle
                    }
                    hoverColor={UIColor.primaryColor.color}
                  />
                )}
              </Col>
              <Col
                id={`${parent_id}_patient_name_nationality_col`}
                className="no-padding-left"
              >
                {props.saveto === "nextOfKin" && (
                  <Select
                    id={`${parent_id}_patient_name_occupation_select`}
                    isRequired={true}
                    showArrow
                    label="Occupation"
                    options={FlterOptions4(props?.allMasters, "OCCUPATION")}
                    disabled={
                      props?.isViewMode ||
                      statusDetails?.status?.display === "inactive" ||
                      statusDetails?.status?.value === "inactive" ||
                      "" ||
                      statusDetails?.conditionstatus?.display === "diseased" ||
                      statusDetails?.conditionstatus?.value === "diseased" ||
                      ""
                    }
                    error={details?.error && details?.error["occupation"]}
                    value={details?.occupation?.value ?? null}
                    onChangeValue={(e) =>
                      setDetails(patientData, "occupation", e)
                    }
                    placeholder="select..."
                    labelStyle={labelStyle}
                    inLineStyles={
                      details?.error && details?.error["occupation"]
                        ? errorborderStyle
                        : borderStyle
                    }
                    hoverColor={UIColor.primaryColor.color}
                  />
                )}
              </Col>
              <Col
                id={`${parent_id}_patient_name_aged_check_col`}
                className="no-padding-left"
              >
                {props.saveto === "nextOfKin" && (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <H6
                        id={`${parent_id}_patient_name_emergency_contact_person_h6`}
                        className="default-size pc_regular"
                        style={{
                          whiteSpace: "nowrap",
                          display: "inline-block",
                        }}
                      >
                        Emergency Contact Person
                      </H6>
                      <Checkbox
                        id={`${parent_id}_patient_name_emergency_contact_person_checkbox`}
                        checked={details?.emergency_contact_person}
                        onChange={(e) =>
                          setDetails(
                            patientData,
                            "emergency_contact_person",
                            !details?.emergency_contact_person
                          )
                        }
                        name=""
                        color={UIColor.secondaryButtonColor.color}
                        style={{ marginTop: "8%", display: "inline-block" }}
                        disabled={
                          props?.isViewMode ||
                          statusDetails?.status?.display === "inactive" ||
                          statusDetails?.status?.value === "inactive" ||
                          "" ||
                          statusDetails?.conditionstatus?.display ===
                            "diseased" ||
                          statusDetails?.conditionstatus?.value ===
                            "diseased" ||
                          ""
                            ? true
                            : false
                        }
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Paper>
        </Col>
      </Row>
    </Div>
  );
};

export default withAllContexts(PatientNames); //withAllContexts here