import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rowCenterBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px",
    paddingBottom: 4,
    cursor: "pointer",
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    "& .border": {
      // margin:'8px 16px',
      borderBottom: "1px solid #E0E0E0",
      "&:last-child": {
        borderBottom: 0,
      },
    },
  },
  children: {
    paddingLeft: 35,
    paddingRight: 16,
    "& .child": {
      marginBottom: 8,
    },
  },
  expand: {
    transform: "rotate(180deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(0deg)",
  },
}));

export default useStyles;
