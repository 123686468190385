import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UIColor } from "../../themes/theme";
import actions from "../../redux/actions";
import jwtDecode from "jwt-decode";
import { AlertProps, AxiosCall, urls } from "../../utils";
import axios from "axios";
import { isEmptyObject } from "../../utils";
import { AlertContext } from "../../context";
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import AppHeader from "../layout/appHeader";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { AppRoutes } from "../../router/routes";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
var debouncing;
const PanelReport = (props) => {
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const { parent_id } = props;
  const navigate = useNavigate();
  const [facilityNameData, setFacilityNameData] = useState([]);
  const [defaultFacilityValue, setDefaultFacilityValue] = useState([]);
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [payervalue, setPayerValue] = useState([]);
  const [practitionerNameData, setPractitionerNameData] = useState([]);
  const [patient, setPatient] = useState([]);
  const [error, setError] = useState({});
  const [open, setOpen] = useState(true);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [panel, setPanel] = useState({
    facility: {},
    payer: {},
    startdate: "",
    user: [],
    practId: {},
  });
  const setData = async (name, value) => {
    const updatedData = { ...panel };
    if (name === "startdate") {
      updatedData[name] = dayjs(value).format("YYYY-MM-01");
    } else {
      updatedData[name] = value;
    }
    setPanel(updatedData);

    if (
      updatedData?.facility?._id &&
      updatedData?.payer?._id &&
      updatedData?.startdate
    ) {
      const response = await dispatch(
        actions.PANEL_REPORT_PATIENT_DROPDOWN({
          facilityid: updatedData?.facility?._id,
          start: dayjs(updatedData?.startdate).unix(),
          payerid: updatedData?.payer?._id,
        })
      );
      setPatient(response?.payload?.data);
    }
  };

  const dailogclose = () => {
    setOpen(false);
    navigate(AppRoutes.dashboard);
  };
  const validation = () => {
    let data = {
      facility: {},
      payer: {},
      startdate: "",
    };
    let validation = {
      error: false,
    };
    Object.keys(data).forEach((v) => {
      if (typeof data[v] === "object") {
        if (isEmptyObject(panel?.[v])) {
          validation.error = true;
          validation[v] = true;
        } else {
          validation.error = false;
          validation[v] = false;
        }
      } else if (v === "startdate") {
        if (panel[v] == null && panel[v] == undefined) {
          validation.error = true;
          validation[v] = true;
        } else {
          if (!dayjs(panel[v]).isValid()) {
            validation.error = true;
            validation[v] = true;
          } else {
            validation[v] = false;
          }
        }
      }
    });
    setError(validation);
    return validation;
  };
 
  const GetPayerID = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        value: item.payerid,
        _id: item.id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const GetPractID = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        value: item.practname,
        _id: item.id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const GetPatientID = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        value: item.patientname,
        _id: item.id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const GetFacilityId = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        value: item.name,
        _id: item._id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const ServcieSearch = (value) => {
    if (value) {
      clearTimeout(debouncing);
      debouncing = setTimeout(() => {
        dispatch(actions.PANEL_REPORT_PATIENT_DROPDOWN(value));
      }, 700);
    }
  };
  const facilityName = async () => {
    const response = await dispatch(
      actions.REPORTS_FACILITY_DROPDOWN({
        facilityid: decodedUserinfo?.facilityid || "",
        tenantid: decodedUserinfo?.tenantid,
      })
    );
    setFacilityNameData(response?.payload?.data);
    const defaultFacility = response?.payload?.data.filter(
      (item) => item?._id === decodedUserinfo?.facilityid
    );
    setDefaultFacilityValue(defaultFacility);
    // setPanel((prevState) => ({
    //   ...prevState,
    //   facility: {
    //     value: defaultFacility?.[0]?.name,
    //     _id: defaultFacility?.[0]?._id,
    //   },
    //   startdate: dayjs().format("YYYY-MM-01"),
    // }));
  };

  const PayerIdandValue = async () => {
    const response = await dispatch(
      actions.PANEL_REPORT_PAYER_DROPDOWN({
        facilityid: decodedUserinfo?.facilityid,
      })
    );
    setPayerValue(response?.payload?.data);
  };

  const practitionerName = async () => {
    const response = await dispatch(
      actions.PANEL_REPORT_PRACTITIONER_DROPDOWN({
        facilityid: decodedUserinfo?.facilityid,
      })
    );
    setPractitionerNameData(response?.payload?.data);
  };

  useEffect(() => {
    facilityName();
    PayerIdandValue();
    practitionerName();
  }, []);

  const generateAndOpenJasperReport = async () => {
    const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };

    const startDate = new Date(panel?.startdate);
    startDate.setHours(0, 0, 0, 0);
    const startEpochTime = getEpochTime(startDate);

    let valid = validation();
    if (valid?.error) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Please fill mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    } else {
      const payload = {
        reportid: "d9e20765-43a6-461c-90e9-0753dbeb2058",
        inputparams: {
          "@payerid": panel?.payer?._id,
          "@start": startEpochTime,
          "@facilityid": panel?.facility?._id,
          "@patientid":
            panel?.user?.length > 0
              ? `['${panel?.user?.map((item) => item._id).join("','")}']`
              : "[]",
          "@loginpractid": panel?.practId?._id ?? "",
        },
        result: [],
      };
      AxiosCall("post", urls.jasperReport, payload, header).then(
        async (res) => {
          if (res?.downloadUrl) {
            const response = await axios.get(res?.downloadUrl, {
              method: "GET",
              responseType: "blob",
            });
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });
            const pdfDataUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfDataUrl, "_blank");
            props?.handleModalClose();
          } else {
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "There is no report available.",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
            props?.handleModalClose();
          }
        }
      );
    }
    setOpen(false);
    navigate(AppRoutes.dashboard);
  };
  useEffect(() => {
    if (downloadUrl) {
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.target = "_blank";
      downloadLink.download = "report.pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [downloadUrl]);

  const helperTextMsg = "This field is required!";
  const errorMessage = React.useMemo(() => {
    if (error?.startdate || error?.enddate) {
      return "Please select a date";
    }
  }, [error]);
  const getPractitionerById = (id) => {
    const practitionerById = practitionerNameData.find((item) => item.id === id);
    if (practitionerById) {
      return { _id: practitionerById?.id, value: practitionerById?.practname };
    }
  };
  const loadPractitionerById = 
  getPractitionerById(decodedUserinfo?.practitionerid) 
  ? getPractitionerById(decodedUserinfo?.practitionerid) 
  : practitionerNameData?.length === 1 
    ? {
      value: practitionerNameData?.[0]?.practname,
      _id: practitionerNameData?.[0]?.id,
    } : null;
  useEffect(() => {
    if (defaultFacilityValue||practitionerNameData&&initialLoad) {
        setPanel((prevState) => ({
            ...prevState,
            facility:
                {
                  value: defaultFacilityValue?.[0]?.name,
                  _id: defaultFacilityValue?.[0]?._id,
                },
            startdate: dayjs().format("YYYY-MM-01"),
            practId:loadPractitionerById ?
              {
              value: loadPractitionerById?.value,
              _id: loadPractitionerById?._id,
            } : "",
        }));
        setInitialLoad(false);
    }
}, [defaultFacilityValue,practitionerNameData,initialLoad]);
  return (
    <div>
      {/* <Grid>
        <AppHeader />
      </Grid>
      <Dialog
        maxWidth="xl"
        fullWidth
        open={open}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DialogTitle>
          <Typography style={{ fontSize: "18px" }}>Panel Report</Typography>
        </DialogTitle>
        <DialogContent dividers> */}
          <div
            style={{
              backgroundColor: "#fff",
              outline: "none",
              width: 500,
              padding: "20px",
            }}
          >
              <IconButton
            aria-label="close"
            onClick={props?.handleModalClose}
            style={{ position: "absolute", right: 0, top: 0 }}
          >
            <CloseIcon />
          </IconButton>
            <Grid container spacing={3}>
            <Grid
                item
                id={`${parent_id}referral_parent_Referring_from_Facility_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <Typography variant="h5">Panel Report</Typography>
              </Grid>
              <Grid
                item
                id={`${parent_id}referral_parent_Referring_from_Facility_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <div>
                  <Autocomplete
                    // multiple
                    id="tags-filled"
                    options={GetFacilityId(facilityNameData)}
                    getOptionLabel={(option) => option?.value ?? ""}
                    limitTags={2}
                    hoverColor={UIColor.primaryColor.color}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.value}
                          {...getTagProps({ index })}
                          size="small"
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        required={true}
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="search facility"
                        label="Facility"
                        hoverColor={UIColor.primaryColor.color}
                        sx={{
                          "& .MuiAutocomplete-inputRoot": {
                            borderRadius: "8px",
                            border: "1px  #E0E0E0",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={error?.facility}
                        helperText={error?.facility ? helperTextMsg : ""}
                      />
                    )}
                    onChange={(event, newValue) =>
                      setData("facility", newValue)
                    }
                    value={panel?.facility}
                    // onInputChange={(e) => {
                    //   ServcieSearch(e.target.value);
                    // }}
                  />
                </div>
              </Grid>
              <Grid
                item
                id={`${parent_id}referral_parent_Referring_from_Facility_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <div>
                  <Autocomplete
                    id="tags-filled"
                    options={GetPayerID(payervalue)}
                    getOptionLabel={(option) => option?.value ?? ""}
                    limitTags={2}
                    hoverColor={UIColor.primaryColor.color}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.value}
                          {...getTagProps({ index })}
                          size="small"
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        required={true}
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="search payer"
                        label="Payer"
                        hoverColor={UIColor.primaryColor.color}
                        sx={{
                          "& .MuiAutocomplete-inputRoot": {
                            borderRadius: "8px",
                            border: "1px  #E0E0E0",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={error?.payer}
                        helperText={error?.payer ? helperTextMsg : ""}
                      />
                    )}
                    onChange={(event, newValue) => setData("payer", newValue)}
                    value={panel?.payer?.value}
                    // onInputChange={(e) => {
                    //   ServcieSearch(e.target.value);
                    // }}
                  />
                </div>
              </Grid>
              <Grid
                item
                id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Month & Year"
                    value={panel?.startdate ? dayjs(panel?.startdate) : dayjs()}
                    views={["month", "year"]}
                    onChange={(date) => setData("startdate", date)}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px",
                        },
                      },
                    }}
                    slotProps={{
                      textField: {
                        helperText: errorMessage,
                        error: error?.startdate ? true : false,
                        size: "small",
                        fullWidth: true,
                        required: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                id={`${parent_id}referral_parent_Referring_from_Facility_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <div>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={GetPatientID(patient) ?? []}
                    getOptionLabel={(option) => option?.value ?? ""}
                    limitTags={2}
                    hoverColor={UIColor.primaryColor.color}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.value}
                          {...getTagProps({ index })}
                          size="small"
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="search patient"
                        label="Patient"
                        hoverColor={UIColor.primaryColor.color}
                        sx={{
                          "& .MuiAutocomplete-inputRoot": {
                            borderRadius: "8px",
                            border: "1px  #E0E0E0",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    onChange={(event, newValue) => setData("user", newValue)}
                    value={panel?.user || []}
                    onInputChange={(e) => {
                      ServcieSearch(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid
                item
                id={`${parent_id}referral_parent_Referring_from_Facility_col`}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <div>
                  <Autocomplete
                    id="tags-filled"
                    options={GetPractID(practitionerNameData)}
                    getOptionLabel={(option) => option?.value ?? ""}
                    limitTags={2}
                    hoverColor={UIColor.primaryColor.color}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.value}
                          {...getTagProps({ index })}
                          size="small"
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="search practitioner"
                        label="Practitioner"
                        hoverColor={UIColor.primaryColor.color}
                        sx={{
                          "& .MuiAutocomplete-inputRoot": {
                            borderRadius: "8px",
                            border: "1px  #E0E0E0",
                          },
                        }}
                        style={{ paddingBottom: "20px" }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    onChange={(event, newValue) => setData("practId", newValue)}
                    value={panel?.practId}
                    // onInputChange={(e) => {
                    //   ServcieSearch(e.target.value);
                    // }}
                  />
                </div>
              </Grid>
            </Grid>

            <Stack
              container
              md={12}
              lg={12}
              spacing={3}
              direction="row"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                size="medium"
                style={{
                  color: UIColor.primaryColor.color,
                  borderColor: UIColor.primaryColor.color,
                }}
                onClick={props?.handleModalClose}
              >
                Cancel
              </Button>

              <Button
                size="medium"
                style={{
                  color: "#fff",
                  background: UIColor.primaryColor.color,
                }}
                variant="contained"
                onClick={() => generateAndOpenJasperReport()}
              >
                Submit
              </Button>
            </Stack>
          </div>
        {/* </DialogContent>
      </Dialog> */}
    </div>
  );
};

export default PanelReport;
