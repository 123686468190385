import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "4px",
    height: 24,
    backgroundColor: "#DEE5EC",
  },
  deleteIcon: {
    height: 16,
    width: 16,
  },
}));
