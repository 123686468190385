/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/11/2020
 * @modify 12/11/2020
 * @desc Exporting all the components from /src/components
 */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Typography, InputAdornment } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import { UIColor } from "../../themes/theme"; 
import CloseIcon from '@material-ui/icons/Close';

const useStyles = (theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
        color: UIColor.secondaryButtonColor.color
    },
    '.MuiAutocomplete-option[data-focus="false"]': {
      color: UIColor.secondaryColor.color
  }
  },
  selectBox: {
    width: "100%",
    height: "35px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "7px",
      '& fieldset': {
        borderColor: UIColor.lineBorderFill.color,
      },
      '&:hover fieldset': {
        borderColor: UIColor.lineBorderFill.color,
      },
      '&.Mui-focused fieldset': {
        // borderColor: UIColor.lineBorderFill.color,
        border: `1px solid ${UIColor.lineBorderFill.color}`
      },
    },
    "& .MuiFormLabel-root.Mui-focused": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& .MuiFormLabel-root.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 9px) scale(1) !important",
    },
    "& .MuiAutocomplete-inputRoot": {
      height: "35px",
      "& input": {
        padding: "0.5px 4px !important",
        color: UIColor.secondaryText.color
      },
    },
    "& .MuiAutocomplete-tag": {
      margin: "3px -4px 3px 8px",
      height: "25px",
      fontSize: "0.8rem"
    }
    
  },
  MultiselectBox: {
    width: "100%",
    // height: "35px",
    "& .MuiAutocomplete-inputRoot": {
      "& input": {
        padding: "0.5px 4px !important",
        color: UIColor.secondaryText.color
      },
      '& fieldset': {
        borderColor: UIColor.lineBorderFill.color,
      },
      '&:hover fieldset': {
        borderColor: UIColor.lineBorderFill.color,
      },
      '&.Mui-focused fieldset': {
        // borderColor: UIColor.lineBorderFill.color,
        border: `1px solid ${UIColor.lineBorderFill.color}`
      },
    },
    "& .MuiAutocomplete-tag": {
      margin: "3px -4px 3px 8px",
      height: "25px",
      fontSize: "0.8rem"
    }
  },
  textColor: {
    color: UIColor.secondaryText.color,
    // padding: "10px"
  },
  chip:{
		backgroundColor:"#f1f6fc",
		color:"#001C3C",
		fontWeight: 500,
		borderRadius:"4px"
	}
});

class SelectBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let {
      serachIcon,
      Optionlabel = "label",
      placeholder,
      classes,
      onchange,
      classname,
      list,
      value,
      title,
      top_title,
      multi,
      disableClearable,
      requireStart,
      parent_id,
      error,
      disabled,
    } = this.props;
    console.log("errorSelect",error);
    return (
      <React.Fragment>
        {top_title && <Typography
          id={`${parent_id}_select_${top_title.replace(" ", "_")}_typography`}
          variant="caption" className="pc_regular" style={{color: UIColor.tertiaryText.color}}>{top_title}{requireStart && <span
            id={`${parent_id}_select_${top_title}_star_span`}
            className="pc_regular"
            style={{
              color: UIColor.error.color,
              marginLeft: 5
            }}>*</span>}</Typography>}
        {serachIcon ? (
          <Autocomplete
            id={`${parent_id}_select_autocomplete`}
            multiple={multi}
            options={list}
            className={`${classes.selectBox} ${value ? "Mui-focused" : ""} ${classname ? classname : ""
              }`}
            onChange={onchange}
            getOptionLabel={(option) => option[Optionlabel]}
            style={{ width: "100%", height: "35px", marginTop: "4px", color: "red" }}
            value={value}
            disabled={disabled}
            popupIcon={
              <InputAdornment
                id={`${parent_id}_select_InputAdornment`}
                position="end">
                <SearchIcon
                  id={`${parent_id}_select_SearchIcon`}
                  style={{ color: UIColor.secondaryText.color }} />
              </InputAdornment>
            }
            renderInput={(params) => (
              <TextField
                id={`${parent_id}_select_textfield`}
                style={{ height: "35px"}}
                placeholder={placeholder}
                {...params}
                label={top_title ? "" : title}
                error={error}
                variant="outlined"
                // helperText={error ? 'Is required' : ''}
              />
            )}
          />
        ) : (
          <Autocomplete
            id={`${parent_id}_select_disableClearable_autocomplete`}
            multiple={multi}
            disableClearable={disableClearable}
            options={list}
             ChipProps={{
              className: this.props.medForm && classes.chip,
              deleteIcon: this.props.medForm && <CloseIcon style={{color:"#6A7888",width:"12px",height:"12px"}}  />
            }}
            className={`${multi ? classes.MultiselectBox : classes.selectBox} ${value ? "Mui-focused" : ""} ${classname ? classname : ""
              }`}
            onChange={onchange}
            getOptionLabel={(option) => option[Optionlabel]}
            style={{ width: "100%", height: multi ? "" : "35px", marginTop: "4px" , background:"white", borderRadius: "7px"}}
            value={value}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                id={`${parent_id}_select_disableClearable_textfield`}
                style={{ height: multi ? "" : "35px" }}
                placeholder={placeholder}
                {...params}
                error={error}
                // helperText={error ? 'Is required' : ''}
                label={top_title ? "" : title}
                variant="outlined"
                // InputProps={{
                //   classes: {
                //       input: classes.textColor
                //   }
                // }}
              />
            )}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(SelectBox);
