import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { actions } from "primarycare-binder";
import {  useSelector, useDispatch,connect } from "react-redux";
import { AppRoutes } from "../../router/routes"
import useStyles from "./styles";
import { Button, Paper, H6, TextInput } from "qdm-component-library";
import { AlertContext } from "../../context";
import { checkError, AlertProps,fetchQueueData} from "../../utils";
import withAllContexts from "../../hoc/withAllContexts";
import { UIColor } from "../../themes/theme";
import WithUnsecurePage from "../../hoc/withUnsecurePage";


const ForgetPassword = (props) => {
  const [userId, setUserId] = useState({});
  const [error, setError] = useState("");
  //const { FORGOT_PASSWORD } = props;
  const navigate = useNavigate();
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const { setSnack } = useContext(AlertContext);


  const changeState = (key, value) => {
    const updatedUserId = { ...userId, [key]: value };
    console.log("updated", updatedUserId)
    setUserId(updatedUserId);
  };

  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
  const forgotPasswordData = useSelector(state => state?.authSlice?.forgotPassword);
//console.log('forgot password data',forgotPasswordData,'logged user info ',loggedUserInfo )
  const handleRecover = async () => {
    if (userId) {
      setError("");
      const payloadlogin = { username: userId };
      //await dispatch(forgotPassword(payloadlogin));
      //const status = checkError(props.forgotPassword);
      const status = checkError(forgotPasswordData);
      console.log(status.isError,"lllll");
       if (status.isError) {
        setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: status.errMsg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        setError(status.errMsg);
      } else {
        setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "An email has been sent for resetting your password.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        // await fetchQueueData();
        // localStorage.setItem('token', res?.payload?.data?.access_token);
        //navigate(AppRoutes.dashboard)
      }
    } else {
      const errorMessage = 'Please enter the Registered Email ID !';
      setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: errorMessage,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      setError(errorMessage);
    }
  };

  return (
    <Paper
      key={"0"}
      children="Paper"
      variant="elevation"
      elevation="1"
      style={{
        width: "350px",
        backgroundColor: "#ffffff",
        borderRadius: "16px",
        padding: "32px",
        boxShadow: "0px 8px 69px #0000001A",
        display: "",
      }}
    >
      <div>
        <>
          <H6
            key={"0"}
            name="Forgot Password?"
            style={{
              fontSize: "24px",
              fontWeight: "500",
              color: UIColor.primaryText.color,
            }}
            inLineStyles={{ fontFamily: "pc_semibold !important" }}
          />

          <H6
            className="pc_regular"
            key={"1"}
            name="Enter your email ID to receive your password reset instruction"
            style={{
              fontSize: "12px",
              color: UIColor.secondaryText.color,
              textAlign: "left",
              marginBottom: "30px",
              marginTop: "10px",
            }}
          />

          <TextInput
            isRequired={true}
            label="Email ID"
            placeholder=""
            labelStyle={classes.labelStyle}
            style={{
              borderRadius: 8,
              borderColor: "#E0E0E0",
              marginBottom: 10,
            }}
            hoverColor={UIColor.secondaryText.color}
            value={userId?.userId}
            onChange={(e) => changeState('userId',e.target.value)}
          />

          {error && (
            <H6
              name={error}
              style={{
                fontSize: "12px",
                color: "red",
                textAlign: "left",
              }}
            />
          )}
        </>
        <>
          <Button
            className="pc_medium"
            key={"3"}
            onClick={handleRecover}
            disabled={props.forgotPassword?.loading}
            type="button"
            variant="outlined"
            name={"Recover"}
            style={{
              margin: "",
              marginTop: "25px",
              width: "100%",
              backgroundColor: UIColor.primaryColor.color,
              borderColor: UIColor.primaryColor.color,
              borderRadius: "8px",
              fontSize: "14px",
              height: "40px",
            }}
          />
          <Button
            className="pc_medium"
            key={"3"}
            onClick={() => navigate(AppRoutes.signIn)}
            disabled={props.forgotPassword?.loading}
            type="button"
            variant="outlined"
            name={"Back to Sign In"}
            style={{
              margin: "",
              marginTop: "15px",
              width: "100%",
              backgroundColor: "#fff",
              borderColor: UIColor.primaryColor.color,
              borderRadius: "8px",
              fontSize: "14px",
              height: "40px",
              color: UIColor.primaryColor.color,
            }}
          />
        </>
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  forgotPassword: state?.authSlice?.forgotPassword,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
});

// export default connect(
//   mapStateToProps,
//   actions
// )(withAllContexts(ForgotPasswordCard));
// export default connect(mapStateToProps, actions)(ForgotPasswordCard);
export default withAllContexts(WithUnsecurePage(ForgetPassword));

