import * as React from "react";
import PropTypes from "prop-types";

const FreqIcon = (props) => (
    <svg id="Ferquency_types" data-name="Ferquency types" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 40 40">
        <rect id="Rectangle_46662" data-name="Rectangle 46662" width="40" height="40" rx="8" fill="#dee5ec" />
        <path id="icons8-add-property" d="M7.951,6A2.955,2.955,0,0,0,5,8.951V22.366a2.955,2.955,0,0,0,2.951,2.951h7.274a6.933,6.933,0,0,1-.763-1.611H7.951A1.343,1.343,0,0,1,6.61,22.365v-11H23.78v3.756a6.982,6.982,0,0,1,1.61.95V8.951A2.955,2.955,0,0,0,22.439,6Zm1.61,7.512a.8.8,0,1,0,0,1.61h1.073a.8.8,0,1,0,0-1.61Zm4.293,0a.8.8,0,0,0,0,1.61h4.56a6.949,6.949,0,0,1,2.684-.537c.162,0,.321.013.48.024a.8.8,0,0,0-.748-1.1ZM21.1,15.659a5.9,5.9,0,1,0,5.9,5.9A5.9,5.9,0,0,0,21.1,15.659ZM9.561,16.732a.8.8,0,1,0,0,1.61h1.073a.8.8,0,1,0,0-1.61Zm4.293,0a.8.8,0,1,0,0,1.61h1.06a6.986,6.986,0,0,1,1.158-1.61Zm7.244.537a.537.537,0,0,1,.537.537v3.22h3.22a.537.537,0,0,1,0,1.073h-3.22v3.22a.537.537,0,1,1-1.073,0V22.1h-3.22a.537.537,0,1,1,0-1.073h3.22V17.8A.537.537,0,0,1,21.1,17.268ZM9.561,19.951a.8.8,0,1,0,0,1.61h1.073a.8.8,0,1,0,0-1.61Zm4.293,0a.8.8,0,0,0,0,1.61h.268a6.984,6.984,0,0,1,.194-1.61Z" transform="translate(4 3.268)" />
    </svg>
);

export default FreqIcon;

FreqIcon.defaultProps = {
    color: "#ff4d4a",
};

FreqIcon.propTypes = {
    color: PropTypes.string,
};
