/**
 * @author PRAKASH P
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-08-17
 * @desc LAB ORDER COMPONENT
 */
import React, { useState } from "react";
import {
  Row,
  Col,
  Text,
  Div,
  Image,
  Icons,
  Divider,
  TextInput,
} from "qdm-component-library";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  TextField,
  Typography,
  Button,
  Tooltip,
  InputAdornment,
  Switch,
  makeStyles,
  Popover,
  Chip,
  Grid,
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import { CalendarIcon, InsightsIcon, Recurrent } from "../../../components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import {
  getCurrentMinTime,
  getData,
  getUserInfo,
  calculateAgeInDays,
  AlertProps,
  formatDateFromEpoch,
  __tenantid__,
  getUtcTime,
} from "../../../utils";
import { UIColor } from "../../../themes/theme";
import { DialogContext } from "../../../context";
import withAllContexts from "../../../hoc/withAllContexts";
import Tick from "../../../assets/img/icons8-double-tick.svg";
import Addicon from "../../../assets/img/icons8-add.svg";
import Cancel from "../../../assets/img/icons8-multiply.svg";
import AlertsRestriction from "../alertsRestriction";
import CustomDatePicker from "../../../components/customDatePicker";
import { setHours, setMinutes } from "date-fns";
import backIcon from "../../../assets/img/svg/icons8-left.svg";
import AddResults from "./addResults";
import { Pagination } from "@material-ui/lab";
import HistoryAccordion from "../historyAccordion";
import axios from "axios";

import { PackageList } from "./packageList";
import { InfoOutlined } from "@material-ui/icons";
//import { AlertProps } from "../../../utils";

const useStyles = makeStyles({
  // autocomplete: {
  //   width: 120,
  //   "& input": {
  //     fontFamily: "pc_regular",
  //     fontSize: 14,
  //     color: UIColor.secondaryText.color,
  //     "&::placeholder": {
  //       fontSize: 14,
  //       fontFamily: "pc_regular !important",
  //     },
  //   },
  // },
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "12px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
  input: {
    fontFamily: "pc_regular",
    color: UIColor.secondaryText.color,
    fontSize: 14,
    "&::placeholder": {
      fontSize: 14,
      fontFamily: "pc_regular !important",
    },
  },
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
  switch: {
    "& .Mui-checked": {
      color: "#fff",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: UIColor.primaryColor.color,
      opacity: 1,
    },
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: UIColor.primaryColor.color,
  },
});

const styles = {
  vitalsHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    padding: "0px 0px 0px 10px",
  },
  vitalsHead1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    padding: "10px 10px 12px 20px",
  },
  vitalsfieldHead: {
    padding: "6px 10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
  },
  vitailsCard: {
    //border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    //marginBottom: "14px !important",
    backgroundColor: "#fff",
  },
  vitailsCarderr: {
    fontSize: "12px",
    textAlign: "right",
    justifyContent: "right",
    float: "right",
    color: UIColor.error.color,
    marginBottom: "24px !important",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  infoBell: {
    width: "50px",
    display: "flex",
    padding: "12px",
    borderLeft: `1px solid ${UIColor.lineBorderFill.color}`,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
  },
  icons: {
    cursor: "pointer",
  },
  flexFlow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  floatingLable: {
    backgroundImage: `linear-gradient(#fff, ${UIColor.greyBackground.color})`,
    position: "absolute",
    top: "-8px",
    fontSize: 10,
  },
  filledBtn: {
    fontSize: 12,
    margin: 0,
    cursor: "pointer",
    backgroundColor: UIColor.primaryColor.color,
    borderColor: UIColor.primaryColor.color,
    borderRadius: 8,
    color: "#fff",
    padding: "4px 10px",
  },
  cancelBtn: {
    fontSize: 12,
    margin: 0,
    cursor: "pointer",
    marginRight: "15px",
    padding: "8px",
    borderRadius: 8,
    border: `1px solid ${UIColor.primaryColor.color}`,
    color: UIColor.primaryColor.color,
    padding: "4px 10px",
  },
  eye: {
    cursor: "pointer",
    marginRight: "10px",
  },
  uploadIcon: {
    width: "24px",
    height: "24px",
    border: "1px solid #E0E0E0",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 5px 0px auto",
    cursor: "pointer",
  },
  img: {
    width: "16px",
    height: "16px",
  },
  // flexRow: {
  //   display: "flex",
  //   alignItems: "center",
  // },
  textColor: {
      fontFamily: "pc_regular",
      fontSize: 12,
      paddingLeft: 6,
      color: UIColor.tertiaryText.color,
    },
  textColor1: {
      fontFamily: "pc_regular",
      fontSize: 12,
      color: UIColor.tertiaryText.color,
    },
};

const initialState = {
  orderCatalog: {},
  priority: {},
  nature: {},
  dateTime: "",
  headerShow: true,
  qty: 1,
  recurrent: false,
  recurrentData: {
    every: {
      value: "",
      periodicity: {},
    },
    startsOn: {
      dateTime: "",
    },
    endsAfter: {
      endsAfter: "",
      endsBy: "",
    },
    preferrence: {
      preferredDays: [],
      preferredWeeks: [],
    }
  },
  orders: [],
  error: false,
};

let HeadData = [
  // {
  //   field: 'id',
  //   headerName: 'SNo',
  //   width: 50,
  //   align: "center",

  // },
  {
    field: "Test",
    headerName: "Test",
    width: 250,
    editable: false,
    align: "left",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Result",
    headerName: "Result",
    width: 125,
    editable: true,
    type: "number",
    align: "center",
    headerAlign: "center",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Unit",
    headerName: "Unit",
    width: 125,
    editable: false,
    align: "center",
    headerAlign: "center",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "NormalRange",
    headerName: "Normal Range",
    width: 200,
    editable: false,
    align: "center",
    headerAlign: "center",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Notes",
    headerName: "Notes",
    width: 200,
    editable: true,
    align: "left",
    headerClassName: "super-app-theme--header",
  },
];

// let BodyData = [
//   {
//     "patentid": "Patient/12360",
//     "encounterid": "Encounter/11819",
//     "orderlineid": "CA_OrderLine/12321",
//     "ordercatlogid": "OrderCatalog/10693",
//     "ordercatlogname": "Full Blood Picture",
//     "parentordercatid": "OrderCatalog/10029",
//     "parentordercatname": "17 Hydroxy Progesterone",
//     "ordermax": 100,
//     "ordermin": 0,
//     "unit": "DrugUOM/11231",
//     "unitdesc": "mcg",
//     "flag": "",
//     "remarks": "",
//     "method": ""
//   },
//   {
//     "patentid": "Patient/12360",
//     "encounterid": "Encounter/11819",
//     "orderlineid": "CA_OrderLine/12321",
//     "ordercatlogid": "OrderCatalog/10694",
//     "ordercatlogname": "Total RBC",
//     "parentordercatid": "OrderCatalog/10693",
//     "parentordercatname": "Full Blood Picture",
//     "ordermax": 100,
//     "ordermin": 0,
//     "unit": "DrugUOM/11231",
//     "unitdesc": "mcg",
//     "flag": "",
//     "remarks": "",
//     "method": ""
//   },
//   {
//     "patentid": "Patient/12360",
//     "encounterid": "Encounter/11819",
//     "orderlineid": "CA_OrderLine/12321",
//     "ordercatlogid": "OrderCatalog/10695",
//     "ordercatlogname": "Total WBC",
//     "parentordercatid": "OrderCatalog/10693",
//     "parentordercatname": "Full Blood Picture",
//     "ordermax": 100,
//     "ordermin": 0,
//     "unit": "DrugUOM/11231",
//     "unitdesc": "mcg",
//     "flag": "",
//     "remarks": "",
//     "method": ""
//   },
//   {
//     "patentid": "Patient/12360",
//     "encounterid": "Encounter/11819",
//     "orderlineid": "CA_OrderLine/12321",
//     "ordercatlogid": "OrderCatalog/10696",
//     "ordercatlogname": "Haemoglobin(hb)",
//     "parentordercatid": "OrderCatalog/10693",
//     "parentordercatname": "Full Blood Picture",
//     "ordermax": 100,
//     "ordermin": 0,
//     "unit": "DrugUOM/11231",
//     "unitdesc": "mcg",
//     "flag": "",
//     "remarks": "",
//     "method": ""
//   },
//   {
//     "patentid": "Patient/12360",
//     "encounterid": "Encounter/11819",
//     "orderlineid": "CA_OrderLine/12322",
//     "ordercatlogid": "OrderCatalog/10697",
//     "ordercatlogname": "Lymphocytes",
//     "parentordercatid": "OrderCatalog/10029",
//     "parentordercatname": "17 Hydroxy Progesterone",
//     "ordermax": 100,
//     "ordermin": 0,
//     "unit": "DrugUOM/11231",
//     "unitdesc": "mcg",
//     "flag": "",
//     "remarks": "",
//     "method": ""
//   },
//   {
//     "patentid": "Patient/12360",
//     "encounterid": "Encounter/11819",
//     "orderlineid": "CA_OrderLine/12323",
//     "ordercatlogid": "OrderCatalog/10698",
//     "ordercatlogname": "Monocytes",
//     "parentordercatid": "OrderCatalog/10029",
//     "parentordercatname": "17 Hydroxy Progesterone",
//     "ordermax": 100,
//     "ordermin": 0,
//     "unit": "DrugUOM/11231",
//     "unitdesc": "mcg",
//     "flag": "",
//     "remarks": "",
//     "method": ""
//   }
// ]
const PackageOrder = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialog = React.useContext(DialogContext);
  const { list, setPackageOrder } = props.PackageOrder;//setNursingProcedure
  const [tableHeader, settableHeader] = React.useState(HeadData);
  const [tableBody, settableBody] = React.useState([]);
  const [compData, setcompData] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState();
  const [Result, setResult] = React.useState(false);
  const [Resulted, setResulted] = React.useState(false);
  const [labResult, setlabResult] = React.useState([]);
  const [BodyData, setBodyData] = React.useState([]);
  const info = getUserInfo();
  const [allmasters, setAllMasters] = useState([]);
  const [Dialogopen, setDialogopen] = useState(false);
  const storeData = useSelector((state) => state?.LabOrderApiSlice);
  const [buttonShow, setButtonShow] = useState(true);
  const [isEdit, setisEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [alertData, setAlertData] = useState({
    open: false,
    data: null,
  });
  const [isLoading, setisLoading] = React.useState(false);

  const [nurseHistory, setNurseHistory] = React.useState([]);

  const [reason, setReason] = useState([]);
  const [delReason, setDelReason] = useState({
    reason: "",
    comments: "",
  });
  const [modal, setModal] = useState({
    isModal:false,
    deleteIndex:null,
    deleteObj:{},
  });

    //pagination for history

  const [currentPage, setCurrentPage] = useState(1);
  
  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update current page when page is changed
  };

  // Assuming treatmentPlanHistory and pageSize are defined somewhere in your component's scope
  const pageSize = 2; // Number of items per page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleItems = nurseHistory.slice(startIndex, endIndex);

  //pagination for Current

  const [currentCurPage, setCurrentCurPage] = useState(1);
  
  const handleCurrentPageChange = (event, value) => {
    setCurrentCurPage(value); // Update current page when page is changed
  };

  // Assuming treatmentPlanHistory and pageSize are defined somewhere in your component's scope
  const currentPageSize = 5; // Number of items per page
  const startCurrentIndex = (currentCurPage - 1) * currentPageSize;
  const endCurrentIndex = startCurrentIndex + currentPageSize;
  const visibleCurrentItems = list.slice(startCurrentIndex, endCurrentIndex);
  if(visibleCurrentItems.length === 0&& list.length > 4){
    setCurrentCurPage(1)
  }
  const closeAlertModal = (data) => {
    setAlertData({
      open: false,
      data: null,
    });

    if (data.type === 1) {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        orderCatalog: {},
        priority: {},
        nature: {},
        dateTime: "",
        recurrent: false,
        headerShow: !treatmentProcedureEntry.headerShow,
        recurrentData: {
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: "",
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          }
        },
        orders: [],
      });
    } else if (data.type) {
      AddTreatmentProcedure(true, data.type);
    }
  };


  const getReasonMasters = async () => {
    const response = await dispatch(actions.GET_REASON_MASTERS({type:["ORDERCANCELREASON"]}));
    setReason(response?.payload?.data?.ORDERCANCELREASON);
  };
  const handleReason = (e, type) => {
    setDelReason({ ...delReason, [type]: e });
  };


  const FlterOptions3 = (type) => {
    let allMasters = allmasters;
    let filteredArray = allMasters.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?._id,
        label: data[index]?.display,
      };
      list.push(datatemp);
    }
    return list;
  };
  const [treatmentProcedureEntry, setTreatmentProcedureEntry] = useState({
    ...initialState,
    // headerShow: list?.filter((val) => val?.isNew)?.length > 0 ? false : true,
  });

  const getNurseHistory = async () => {
    const response = await dispatch(
      actions.PACKAGE_ORDER_HISTORY({
        patientId: props?.patientgetData?.patient_id,
        encounterId:  props?.encData?.encId ?  props?.encData?.encId : props?.patientgetData?._id,
        startdate: getUtcTime(moment().startOf("day")),
        // startdate: props?.encData?.encStartDate ?  props?.encData?.encStartDate : getUtcTime(moment().startOf("day")),
      })
    );
    setNurseHistory(response?.payload?.data);
  };

  const getAllMaster = async () => {
    const response = await dispatch(actions.GET_ALL_MASTERS(""));
    setAllMasters(response?.payload?.data);
  };
  const loadDefaultValues = async () => {
    let payload = {
      screenid: process.env.REACT_APP_NURSING_PROCEDURE_SCREEN_ID,
      tenantid: __tenantid__,
    };

    let token = localStorage.getItem("JwtToken");

    let register = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      data: JSON.stringify(payload),
    }).then((res) => {
      if (res?.data?.response?.screenId === process.env.REACT_APP_NURSING_PROCEDURE_SCREEN_ID) {
        const defaultValues = res?.data?.response?.defaultValues;
        const stateNames = ["nature", "dateTime", "priority"];
        const stateValues = {};
        defaultValues.forEach((s) => {
          if (stateNames.includes(s.stateName)) {
            stateValues[s.stateName] = s.value;
          }
        });
        let epochDate = stateValues?.dateTime * 1000;
        let defaultDate = props?.encData?.encStartDate ? new Date(props?.encData?.encStartDate * 1000) : new Date(epochDate);
        setTreatmentProcedureEntry({
          priority: {
            value: stateValues?.priority?._id,
            label: stateValues?.priority?.value,
          },
          nature: {
            value: stateValues?.nature?._id,
            label: stateValues?.nature?.value,
          },
          recurrentData:{
            every: {
              value: "",
              periodicity: {},
            },
            startsOn: {
              dateTime: defaultDate,
            },
            endsAfter: {
              endsAfter: "",
              endsBy: "",
            },
            preferrence: {
              preferredDays: [],
              preferredWeeks: [],
            }
          },
          dateTime: defaultDate,
          error: false,
          qty:1
        });
      }
    }).catch((error) => {
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please contact to admin",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    });
  };
  const loadData = async () =>{
    if (!isEdit) {
      await loadDefaultValues();
    }
  }
  React.useEffect(() => {
    loadData();
  }, [props?.encData]);

  React.useEffect(() => {
    getNurseHistory();
  }, [props?.patientgetData?._id, props?.patientgetData?.patient_id,props?.encData]);

  React.useEffect(() => {
    getReasonMasters();
    getAllMaster();
    getAllLabResult();
    // if (!isEdit) {
    //   loadDefaultValues();
    // }
    dispatch(actions.GET_PACKAGEORDER());
  }, []);

  const getAllLabResult = async () => {
    const data = await dispatch(
      actions.GET_ALL_LAB_RESULT({
        patientid: props?.patientgetData?.patient_id,
        encounterid: props?.patientgetData?._id,
      })
    );
    if (data?.payload?.data?.length > 0) {
      setlabResult(data?.payload?.data?.[0]?.components);
      setBodyData(data?.payload?.data);
      let HeaderData = [];
      let componentdata = [];
      data?.payload?.data?.[0]?.components.map((li) => {
        let obj = {
          id: HeaderData?.length + 1,
          Test: li?.ordercatlogid?.longdesc,
          Result: li?.ordervalue,
          Unit: li?.orderuom?.UOM,
          NormalRange: `${li?.ordermin}-${li?.ordermax}`,
          Notes: li?.remarks,
          ordercatlogid: li?.ordercatlogid?._id,
          parentordercatid: li?.parentordercatid?._id,
          parentordercatname: li?.parentordercatid?.longdesc,
        };
        HeaderData.push(obj);
        let Obj1 = {
          orderlineid: li?.orderlineid ? li?.orderlineid : "",
          ordervalue: li?.ordervalue ? li?.ordervalue : "",
          flag: li?.flag ? li?.flag : "",
          ordermax: li?.ordermax ? li?.ordermax : 0,
          method: li?.method ? li?.method : "",
          ordermin: li?.ordermin ? li?.ordermin : 0,
          orderuom: li?.orderuom?._id ? li?.orderuom?._id : "",
          parentordercatid: li?.parentordercatid?._id
            ? li?.parentordercatid?._id
            : "",
          ordercatlogid: li?.ordercatlogid?._id ? li?.ordercatlogid?._id : "",
          remarks: li?.remarks ? li?.remarks : "",
          type: "",
        };
        componentdata.push(Obj1);
      });
      setcompData(componentdata);
      settableBody(HeaderData);
      setResulted(true);
      setEditId(data?.payload?.data?.[0]?._id);
    }
  };

  React.useEffect(() => {
    setTreatmentProcedureEntry({
      ...treatmentProcedureEntry,
      headerShow: list.length > 0 ? false : true,
    });
  }, [storeData]);
  const handleResultClose = () => {
    getAllLabResult();
    setResult(false);
  };

  const handleViewOpen = async () => {
    const data = await dispatch(
      actions.GET_ALL_LAB_RESULT({
        patientid: props?.patientgetData?.patient_id,
        encounterid: props?.patientgetData?._id,
      })
    );
    if (data?.payload?.data?.length > 0) {
      setlabResult(data?.payload?.data?.[0]?.components);
      setBodyData(data?.payload?.data);
      let HeaderData = [];
      let componentdata = [];
      data?.payload?.data?.[0]?.components.map((li) => {
        let obj = {
          id: HeaderData?.length + 1,
          Test: li?.ordercatlogid?.longdesc,
          Result: li?.ordervalue,
          Unit: li?.orderuom?.UOM,
          NormalRange: `${li?.ordermin}-${li?.ordermax}`,
          Notes: li?.remarks,
          ordercatlogid: li?.ordercatlogid?._id,
          parentordercatid: li?.parentordercatid?._id,
          parentordercatname: li?.parentordercatid?.longdesc,
        };
        HeaderData.push(obj);
        let Obj1 = {
          orderlineid: li?.orderlineid ? li?.orderlineid : "",
          ordervalue: li?.ordervalue ? li?.ordervalue : "",
          flag: li?.flag ? li?.flag : "",
          ordermax: li?.ordermax ? li?.ordermax : 0,
          method: li?.method ? li?.method : "",
          ordermin: li?.ordermin ? li?.ordermin : 0,
          orderuom: li?.orderuom?._id ? li?.orderuom?._id : "",
          parentordercatid: li?.parentordercatid?._id
            ? li?.parentordercatid?._id
            : "",
          ordercatlogid: li?.ordercatlogid?._id ? li?.ordercatlogid?._id : "",
          remarks: li?.remarks ? li?.remarks : "",
          type: "",
        };
        componentdata.push(Obj1);
      });
      setcompData(componentdata);
      settableBody(HeaderData);
      let HeaderDataa = [
        // {
        //   field: 'id',
        //   headerName: 'SNo',
        //   width: 50,
        //   align: "center",

        // },
        {
          field: "Test",
          headerName: "Test",
          width: 250,
          editable: false,
          align: "left",
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Result",
          headerName: "Result",
          width: 125,
          editable: false,
          type: "number",
          align: "center",
          headerAlign: "center",
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Unit",
          headerName: "Unit",
          width: 125,
          editable: false,
          align: "center",
          headerAlign: "center",
          headerClassName: "super-app-theme--header",
        },
        {
          field: "NormalRange",
          headerName: "Normal Range",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          headerClassName: "super-app-theme--header",
        },
        {
          field: "Notes",
          headerName: "Notes",
          width: 200,
          editable: false,
          align: "left",
          headerClassName: "super-app-theme--header",
        },
      ];
      settableHeader(HeaderDataa);
      setResulted(false);
      setResult(true);
      setButtonShow(false);
    }
  };

  const handleResultOpen = async () => {
    if (!Resulted) {
      let { patientgetData } = props;
      let data = await dispatch(
        actions.GET_RESULT_DATA({
          patientid: patientgetData?.patient_id,
          encounterid: patientgetData?._id,
          gender: patientgetData?.Patient?.[0]?.Gender?._id,
          ageindays: calculateAgeInDays(
            moment(
              patientgetData?.Patient?.[0]?.personInfo?.[0]?.birthdate,
              "DD/MM/YYYY"
            ).format("MM/DD/YYYY")
          ),
        })
      );
      setBodyData(data?.payload?.data);
      let HeaderData = [];
      let componentdata = [];

      data?.payload?.data.map((li) => {
        let obj = {
          id: HeaderData?.length + 1,
          Test: li?.ordercatlogname,
          Result: null,
          Unit: li?.unitdesc,
          NormalRange: `${li?.ordermin}-${li?.ordermax}`,
          Notes: null,
          ordercatlogid: li?.ordercatlogid ? li?.ordercatlogid : "",
          parentordercatid: li?.parentordercatid ? li?.parentordercatid : "",
          parentordercatname: li?.parentordercatname
            ? li?.parentordercatname
            : "",
        };
        let Obj1 = {
          orderlineid: li?.orderlineid,
          ordervalue: null,
          flag: "",
          ordermax: li?.ordermax,
          method: "",
          ordermin: li?.ordermin,
          orderuom: li?.orderuom,
          parentordercatid: li?.parentordercatid,
          ordercatlogid: li?.ordercatlogid,
          remarks: "",
        };
        componentdata.push(Obj1);
        HeaderData.push(obj);
      });
      setcompData(componentdata);
      settableBody(HeaderData);
      settableHeader(HeadData);
      setResult(true);
      setResulted(false);
      setButtonShow(true);
    } else {
      const data = await dispatch(
        actions.GET_ALL_LAB_RESULT({
          patientid: props?.patientgetData?.patient_id,
          encounterid: props?.patientgetData?._id,
        })
      );
      if (data?.payload?.data?.length > 0) {
        setlabResult(data?.payload?.data?.[0]?.components);
        setBodyData(data?.payload?.data);
        let HeaderData = [];
        let componentdata = [];
        data?.payload?.data?.[0]?.components.map((li) => {
          let obj = {
            id: HeaderData?.length + 1,
            Test: li?.ordercatlogid?.longdesc,
            Result: li?.ordervalue,
            Unit: li?.orderuom?.UOM,
            NormalRange: `${li?.ordermin}-${li?.ordermax}`,
            Notes: li?.remarks,
            ordercatlogid: li?.ordercatlogid?._id,
            parentordercatid: li?.parentordercatid?._id,
            parentordercatname: li?.parentordercatid?.longdesc,
          };
          HeaderData.push(obj);
          let Obj1 = {
            orderlineid: li?.orderlineid ? li?.orderlineid : "",
            ordervalue: li?.ordervalue ? li?.ordervalue : "",
            flag: li?.flag ? li?.flag : "",
            ordermax: li?.ordermax ? li?.ordermax : 0,
            method: li?.method ? li?.method : "",
            ordermin: li?.ordermin ? li?.ordermin : 0,
            orderuom: li?.orderuom?._id ? li?.orderuom?._id : "",
            parentordercatid: li?.parentordercatid?._id
              ? li?.parentordercatid?._id
              : "",
            ordercatlogid: li?.ordercatlogid?._id ? li?.ordercatlogid?._id : "",
            remarks: li?.remarks ? li?.remarks : "",
            type: "",
          };
          componentdata.push(Obj1);
        });
        setcompData(componentdata);
        settableBody(HeaderData);
        settableHeader(HeadData);
        setButtonShow(true);
        setResult(true);
        setResulted(false);
        setisEdit(true);
      }
    }
  };

  const handleInputChange = (e, name) => {
    if (name === "dateTime") {
      let Data = {
        every: {
          value: "",
          periodicity: {},
        },
        startsOn: {
          dateTime: e,
        },
        endsAfter: {
          endsAfter: "",
          endsBy: "",
        },
      };
      const hours = e.getHours().toString().padStart(2, '0');
      const minutes = e.getMinutes().toString().padStart(2, '0');
      const seconds = e.getSeconds().toString().padStart(2, '0');
      const timePart = `${hours}:${minutes}:${seconds}`;
  
      if (timePart === "00:00:00" || timePart === "0:0:0") {
        const currentDateTime = new Date();
        const currentHours = currentDateTime.getHours().toString().padStart(2, '0');
        const currentMinutes = currentDateTime.getMinutes().toString().padStart(2, '0');
        const currentSeconds = currentDateTime.getSeconds().toString().padStart(2, '0');
        const currentTime = `${currentHours}:${currentMinutes}:${currentSeconds}`;
        
        setTreatmentProcedureEntry({
          ...treatmentProcedureEntry,
          [name]: props?.encData?.encStartDate ? new Date(props?.encData?.encStartDate * 1000) : new Date(e.toDateString() + " " + currentTime),
          recurrentData: Data,
          error: false,
        });
      } else {
        setTreatmentProcedureEntry({
          ...treatmentProcedureEntry,
          [name]: e ? e : new Date(props?.encData?.encStartDate * 1000),
          recurrentData: Data,
          error: false,
        });
      }
      // setIndex(null);
      // handleClose();
    } else if (name === "qty") {
      // if (Number(e) > 999 || Number(e) < 1) {
      //   return;
      // }\
      
      if (Number(e) > 999 ) {
        return;
      }
      if(Number(e) <= 0)
      {
        setTreatmentProcedureEntry({
          ...treatmentProcedureEntry,
          [name]: "",
          error: false,
        });
        setIndex(null);
        handleClose();
      }
      else{
        setTreatmentProcedureEntry({
          ...treatmentProcedureEntry,
          [name]: e,
          error: false,
        });
        setIndex(null);
        handleClose();
      }
      
    } else {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        [name]: e,
        error: false,
      });
      setIndex(null);
      handleClose();
    }
  };

  const checkDuplicateOrder = () => {
    let arr = [];
    if (list.length > 0) {
      list.map((v) => {
        arr.push(v?.orderCatalog?.value);
      });
    }
    if (arr.length > 0) {
      return arr.includes(treatmentProcedureEntry?.orderCatalog?.label);
    } else {
      return false;
    }
  };

  const AddTreatmentProcedure = async (ruleValidated, ruleType) => {
    if (true) {
      let datalist = {
        ...treatmentProcedureEntry,
        createdName: info.name,
        createdAt: getData(),
        currentStatus: "New",
      };
      let alertText = null;
      if (!ruleValidated) {
        if (checkDuplicateOrder()) {
          setAlertData({
            open: true,
            // data: "Duplicate order is entered. Do you still want to continue?"
            data: {
              type: 3,
              alertText:
                "Duplicate Procedure is entered. Do you still want to continue?",
            },
          });
          return false;
        } else {
          // let alertData = await dispatch(
          //   actions.GETALERTWITHLABORDER({
          //     orgId: props?.propsData?.loggedUserInfo?.data?.org_id,
          //     rulePayload: { ...datalist, Oltype: "LAB" },
          //     pracId: props?.propsData?.loggedUserInfo?.data?.practioner_id,
          //     patId: props?.patientgetData?.pID,
          //   })
          // );
          let alertData = "";
          let alertdetails = alertData?.payload?.data;
          if (
            alertdetails?.type &&
            typeof alertdetails?.type === "number" &&
            alertdetails?.type !== 2
          ) {
            setAlertData({
              open: true,
              data: alertdetails,
            });
            return false;
          } else if (alertdetails?.type === 2) {
            alertText = alertdetails?.alertText;
          }
        }
      }
      datalist.infoAlertText = alertText;
      await props.savePackageOrder([datalist], null);
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        orderCatalog: {},
        priority: {},
        nature: {},
        dateTime: "",
        recurrent: false,
        headerShow: !treatmentProcedureEntry.headerShow,
        recurrentData: {
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: "",
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          }
        },
        orders: [],
        qty: 1,
      });
    } else {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        recurrent: false,
        headerShow: true,
        error: true,
      });
      handleClose();
    }
  };

  const saveLabResult = async (components) => {
    let doc = {
      tenantid: process.env.REACT_APP_TENANTID,
      encounterid: props?.patientgetData?._id,
      facilityid: props?.patientgetData?.facilityid,
      patientid: props?.patientgetData?.patient_id,
      components: components,
    };
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: true,
      message: "Adding Package Order Results",
    });
    let data = await dispatch(
      actions.SAVE_RESULT_DATA({ doc: doc, _id: editId })
    );
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: false,
      message: "",
    });

    if (data?.payload?.error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Failed Added Package Order Result",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else if (!data?.payload?.error) {
      const datass = await dispatch(
        actions.GET_ALL_LAB_RESULT({
          patientid: props?.patientgetData?.patient_id,
          encounterid: props?.patientgetData?._id,
        })
      );

      if (datass?.payload?.data?.length > 0) {
        setlabResult(datass?.payload?.data?.[0]);
        setResulted(true);
        setBodyData(datass?.payload?.data);
      }
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        // msg: `Please fill the ${err[Object.keys(state?.error)?.[0]]} !`,
        msg: "Package Order Result Added Succesfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
    setisEdit(false);
    setResult(false);
    settableBody([]);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [index, setIndex] = useState(null);

  const handleClick = (index, event) => {
    setAnchorEl(event.currentTarget);
    setIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setTreatmentProcedureEntry({
      ...treatmentProcedureEntry,
      recurrent: false,
    });
    setAnchorEl(null);
  };

  const saveRecurrentData = (data) => {
    setTreatmentProcedureEntry({
      ...treatmentProcedureEntry,
      recurrentData: data,
      dateTime: data.startsOn.dateTime,
    });
    handleClose();
  };

  const deleteList = async (i, data, isReccDelete) => {
    setDelReason({ ...delReason, error: false });
  
    const reasonIsValid = delReason.reason !== "";
    const commentsAreRequired = delReason.reason === "Others";
    const commentsAreValid = commentsAreRequired ? delReason.comments !== "" : true;
    const canDelete = reasonIsValid && commentsAreValid;
  
    if (canDelete) {
      const commonActions =async () => {
        dialog.setDialog({ ...dialog, open: false });
        props?.backDrop?.setBackDrop({ ...props?.backDrop, open: true, message: "Deleting Package Order" });
        const deleted = await dispatch(actions.ORDER_DELETE({
          id: data.editData,
          isReccDel: isReccDelete,
          reason: delReason.reason,
          reasondesc: delReason.reason === "Others" ? delReason.comments : "",
        }));
        if (deleted?.payload?.data?.error == false) {
        props?.backDrop?.setBackDrop({ ...props?.backDrop, open: false, message: "" });
        list.splice(i, 1);
        if (list?.length > 0) {
          setTreatmentProcedureEntry({ ...treatmentProcedureEntry, headerShow: false });
        }
        let packageorderData = await dispatch(
          actions.LABORDER_READ({
            encounter_id: props?.encData?.encId ?? props?.patientgetData?._id,
            order_id: "PACK",
            patientid:props?.patientgetData?.patient_id,
            startdate: props?.encData?.encStartDate ? props?.encData?.encStartDate : getUtcTime(moment().startOf("day")),
          })
        );
    
        let packageorder = props?.PackageOrder;
        await packageorder.setPackageOrder({
          ...packageorder,
          list: JSON.parse(JSON.stringify(packageorderData?.payload?.data)),
        });
        // setPackageOrder({ ...props.PackageOrder });
      }else{
          props?.backDrop?.setBackDrop({ ...props?.backDrop, open: false, message: "" });
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Package Order Delete Failed!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        }
        setDelReason({ reason: "", comments: "" });
      };
      setModal({isModal:false,deleteIndex:null,deleteObj:{}});
      commonActions();
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the mandatory fields!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      setDelReason({ ...delReason, error: true });
    }
  };

  const editFun = (i, childIndex, parentOrderId, data, dates) => {
    setPackageOrder({
      ...props.NursingProcedure,
      editIndex: i,
      // editData: data.editData,
      child: {
        index: childIndex,
        parentOrderId: parentOrderId,
        IsChild: !parentOrderId ? true : false,
        dates: dates,
        editData: data?.editData,
      },
    });
  };

  const updatefuncy = (val) => {
    setPackageOrder({
      ...props.NursingProcedure,
      ...val,
    });
  };
  const handleCloseForm = () => {
    if (list.length > 0) {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        orderCatalog: {},
        priority: {},
        nature: {},
        dateTime: "",
        recurrent: false,
        headerShow: !treatmentProcedureEntry.headerShow,
        recurrentData: {
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: "",
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          }
        },
        orders: [],
      });
    } else {
      props.handleClose("lab_order");
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { parent_id } = props;
  return (
    <div
      id={`${parent_id}_laborder_parent_div`}
      style={{ width: "100%", marginBottom: "0px" }}
    >
      <Row
        id={`${parent_id}_laborder_parent_row`}
        inLineStyles={styles.vitailsCard}
      >
        {/* Add a new treatment procedures data's */}
        <Col
          id={`${parent_id}_laborder_parent_col`}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          {treatmentProcedureEntry.headerShow ? (
            <div>
              <Grid container justifyContent="space-between" direction="column">
                <Grid item container>
                  <Grid item style={{ marginTop: "15px" }}>
                    <Text
                      id={`${parent_id}_laborder_headerShow_text`}
                      inLineStyles={{
                        fontWeight: 600,
                        fontSize: "14px",
                        cursor: "pointer",
                        color: UIColor.primaryColor.color,
                        alignItems: "center",
                      }}
                    >
                      Package Order
                    </Text>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider orientation="horizontal" variant="fullWidth" />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                style={{ display: "flex", gap: "10px", padding: "15px" }}
              >
                <Grid item>
                  {" "}
                  <div
                    id={`${parent_id}_laborder_title_div`}
                    style={styles.vitalsfieldHead}
                  >
                    <div
                      id={`${parent_id}_laborder_title_sub_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      <Autocomplete
                        id={`${parent_id}_laborder_autocomplete`}
                        className={classes.autocomplete}
                        style={{ width: 160 }}
                        fullWidth
                        size="small"
                        options={storeData?.getpackageorder?.data ?? []}
                        popupIcon={
                          <ExpandMoreIcon
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            id={`${parent_id}_laborder_Search_textfield`}
                            {...params}
                            variant="standard"
                            placeholder="Search Package Order"
                            style={{ width: "100%", fontSize: 14 }}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${parent_id}_laborder_Search_${option.label.replaceAll(
                              " ",
                              "_"
                            )}_typography`}
                            className="pc_regular"
                            style={{
                              width: "100%",
                              fontSize: 14,
                              color: UIColor.secondaryText.color,
                            }}
                            noWrap
                          >
                            {option.label}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={treatmentProcedureEntry.orderCatalog}
                        onChange={(e, newValue) =>
                          handleInputChange(newValue, "orderCatalog")
                        }
                      />
                    </div>
                    <div
                      id={`${parent_id}_laborder_Priority_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      <Autocomplete
                        id={`${parent_id}_laborder_Priority_autocomplete`}
                        style={{ width: "100px" }}
                        className={classes.autocomplete}
                        size="small"
                        options={FlterOptions3("LABPRIORITY") ?? []}
                        popupIcon={
                          <ExpandMoreIcon
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            id={`${parent_id}_laborder_Priority_textfield`}
                            {...params}
                            variant="standard"
                            placeholder="Priority"
                            style={{ width: "100%", fontSize: 14 }}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${parent_id}_laborder_Priority_${option.label.replaceAll(
                              " ",
                              "_"
                            )}_typography`}
                            className="pc_regular"
                            style={{
                              width: "100%",
                              fontSize: 14,
                              color: UIColor.secondaryText.color,
                            }}
                            noWrap
                          >
                            {option.label}
                          </Typography>
                        )}
                        // classes={{popper: "muiPopperDropdown"}}
                        value={treatmentProcedureEntry.priority}
                        onChange={(e, newValue) =>
                          handleInputChange(newValue, "priority")
                        }
                      />
                    </div>
                    <div
                      id={`${parent_id}_laborder_Nature_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      <Autocomplete
                        className={classes.autocomplete}
                        style={{ width: "100px" }}
                        id={`${parent_id}_laborder_Nature_autocomplete`}
                        size="small"
                        options={FlterOptions3("ORDERNATURE") ?? []}
                        popupIcon={
                          <ExpandMoreIcon
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            id={`${parent_id}_laborder_Nature_textfield`}
                            {...params}
                            variant="standard"
                            placeholder="Nature"
                            style={{ width: "100%", fontSize: 14 }}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${parent_id}_laborder_Nature_${option.label.replaceAll(
                              " ",
                              "_"
                            )}_typography`}
                            className="pc_regular"
                            style={{
                              width: "100%",
                              fontSize: 14,
                              color: UIColor.secondaryText.color,
                            }}
                            noWrap
                          >
                            {option.label}
                          </Typography>
                        )}
                        // classes={{popper: "muiPopperDropdown"}}
                        value={treatmentProcedureEntry.nature}
                        onChange={(e, newValue) =>
                          handleInputChange(newValue, "nature")
                        }
                      />
                    </div>
                    <div
                      id={`${parent_id}_laborder_calender_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      {" "}
                      <TextField
                        id={`${parent_id}_laborder_calender_textfield`}
                        className={classes.autocomplete}
                        style={{ width: 170 }}
                        autoComplete={"off"}
                        value={
                          treatmentProcedureEntry?.dateTime
                            ? moment(treatmentProcedureEntry?.dateTime).format(
                                "YYYY-MM-DD, hh:mm A"
                              )
                            : ""
                        }
                        onChange={(e, newValue) =>
                          handleInputChange(newValue, "dateTime")
                        }
                        onClick={(event) => handleClick(1, event)}
                        placeholder={"Start Date"}
                        InputProps={{
                          classes: { input: classes.input },
                          endAdornment: (
                            <InputAdornment
                              id={`${parent_id}_laborder_calender_inputadornment`}
                              position="end"
                            >
                              <Div
                                id={`${parent_id}_laborder_calendericon_div`}
                                aria-describedby={id}
                                style={{ cursor: "pointer" }}
                                onClick={(event) => handleClick(1, event)}
                              >
                                <CalendarIcon
                                  id={`${parent_id}_laborder_calendericon`}
                                  color={UIColor.secondaryText.color}
                                  width={"16"}
                                  height={"16"}
                                />
                              </Div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div
                      id={`${parent_id}_laborder_Qty_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      <TextField
                        id={`${parent_id}_laborder_Qty_textinput`}
                        className={classes.autocomplete}
                        style={{ width: 70 }}
                        type={"number"}
                        value={treatmentProcedureEntry?.qty}
                        onChange={(e) =>
                          handleInputChange(e.target.value, "qty")
                        }
                        placeholder="Quantity"
                        // error={isErr && +state.qty < 1 ? true : false}
                        // label="Qty"
                        // labelStyle={styles.labelStyle}
                        // style={
                        // isErr && +state.qty < 1
                        //   ? { borderRadius: 8 }
                        //   :
                        // styles.borderStyle
                        // }
                        maxLength={3}
                      />
                    </div>
                    <div
                      id={`${parent_id}_laborder_Recurrent_div`}
                      style={{ marginRight: 16, width: "100%" }}
                    >
                      {/* {treatmentProcedureEntry.orderCatalog?.value && (
                        <Div
                          id={`${parent_id}_laborder_Recurrent_switch_div`}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Text
                            id={`${parent_id}_laborder_Recurrent_text`}
                            className="pc_regular"
                            inLineStyles={{
                              color: UIColor.secondaryText.color,
                              fontSize: 12,
                            }}
                          >
                            {"Recurrent"}
                          </Text>
                          <Switch
                            id={`${parent_id}_laborder_Recurrent_switch`}
                            aria-describedby={id}
                            checked={treatmentProcedureEntry.recurrent}
                            onChange={(event) => {
                              handleInputChange(
                                event.target.checked,
                                "recurrent"
                              );
                              event.target.checked && handleClick(0, event);
                            }}
                            className={classes.switch}
                          />
                        </Div>
                      )} */}
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  {" "}
                  <Button
                    id={`${parent_id}_laborder_add_button`}
                    onClick={() => {
                      if (
                        treatmentProcedureEntry.orderCatalog?.value &&
                        treatmentProcedureEntry.dateTime &&
                        treatmentProcedureEntry.nature.value &&
                        treatmentProcedureEntry.priority.label &&
                        treatmentProcedureEntry.qty
                      ) {
                        AddTreatmentProcedure();
                      } else {
                        let fld = "";
                        if (!treatmentProcedureEntry.orderCatalog?.value){
                          fld = fld + (fld == ""? "" : ",") + "\nNursing Procedure";
                        }
                        if (!treatmentProcedureEntry.priority.label){
                          fld = fld + (fld == ""? "" : ",") + "\nPriority";
                        }
                        if (!treatmentProcedureEntry.nature.value){
                          fld = fld + (fld == ""? "" : ",") + "\nNature";
                        }
                        if (!treatmentProcedureEntry.dateTime){
                          fld = fld + (fld == ""? "" : ",") + "\nDate";
                        }
                        if (!treatmentProcedureEntry.qty){
                          fld = fld + (fld == ""? "" : ",") + "\nQuantity";
                        }
                        props.alert.setSnack({
                          open: true,
                          severity: AlertProps.severity.error,
                          msg: "Please fill all the mandatory fields!" + fld,
                          vertical: AlertProps.vertical.top,
                          horizontal: AlertProps.horizontal.right,
                        });
                      }
                    }}
                    variant={"text"}
                    style={{
                      color: UIColor.primaryColor.color,
                      fontWeight: 600,
                      minWidth: "1%",
                      padding: "5px",
                      marginLeft: "3px",
                    }}
                    size={"small"}
                  >
                    <img src={Tick} alt="Add" />
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div
              id={`${parent_id}_laborder_headerShow_div`}
              style={{
                display: "flex",
                width: "99%",
                justifyContent: "space-between",
              }}
            >
              <div className="icon" style={{ display: "flex" }}>
                {Result && (
                  <div
                    style={styles.uploadIcon}
                    onClick={() => handleResultClose()}
                  >
                    <Image
                      id={`${parent_id}_topnavbar_log_image`}
                      style={styles.img}
                      src={backIcon}
                      alt="left"
                    />
                  </div>
                )}
                <Text
                  id={`${parent_id}_laborder_headerShow_text`}
                  onClick={() =>
                    handleInputChange(
                      !treatmentProcedureEntry.headerShow,
                      "headerShow"
                    )
                  }
                  inLineStyles={{
                    fontWeight: 600,
                    fontSize: "14px",
                    cursor: "pointer",
                    color: UIColor.primaryColor.color,
                    padding: "15px 0",
                  }}
                >
                  {Result ? "Package Order" : "Package Order"}
                </Text>
              </div>
              <div
                className="icon"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  paddingTop: "10px",
                }}
              >
                {/* {(!Result && !Resulted) && (
                  <div >
                    <Chip size="small" label="Enter Result" variant="outlined" style={{ borderRadius: "1%", backgroundColor: "#fde5e5", padding: "0px , 0px" }} onClick={() => handleResultOpen()} color="secondary" />
                  </div>
                )}
                {Resulted && (
                  <div style={{ marginRight: "10px" }}>
                    <Chip size="small" label="Resulted" variant="outlined" style={{ borderRadius: "1%", backgroundColor: "#d8fcd7", padding: "0px , 0px", color: "#00b007" }} />
                  </div>
                )}
                {Resulted && (
                  <div>
                    <Tooltip title={"View Result"} placement="top">
                      <Icons
                        id={`${parent_id}-view`}
                        fontIcon="eye"
                        ariaHidden="true"
                        size="small"
                        onClick={() => handleViewOpen()}
                        style={{ color: UIColor.secondaryButtonColor.color, marginRight: "10px" }}
                      />
                    </Tooltip>
                  </div>
                )}
                {Resulted && (
                  <div>
                    <Tooltip title={"View Result"} placement="top">
                      <Icons
                        id={`${parent_id}-view`}
                        fontIcon="pencil"
                        ariaHidden="true"
                        size="small"
                        onClick={() => handleResultOpen()}
                        style={{ color: UIColor.secondaryButtonColor.color, marginRight: "10px" }}
                      />
                    </Tooltip>
                  </div>
                )} */}
                {/* {Resulted && (
                  <div>
                    <ReactToPrint
                      onBeforeGetContent={handleOnBeforeGetContent}
                      onAfterPrint={handleAfterPrint}
                      trigger={() => (
                        <Div
                          id={`${parent_id}-print`}
                          style={{
                            ...styles.flexRow,
                            cursor: "pointer",
                            padding: "0px 0px",
                          }}
                          onClick={() => handlePrint()}
                        >
                          <Icons
                            id={`${parent_id}confirmbooking_print_image`}
                            fontIcon="print"
                            ariaHidden="true"
                            size="small"
                            style={{ color: UIColor.secondaryButtonColor.color }}
                          />
                          <Text
                            id={`${parent_id}confirmbooking_print_text`}
                            className="pc_regular"
                            style={styles.iconSpan}
                          >
                            
                          </Text>
                        </Div>
                      )}
                      content={() => componentRef}
                    />
                  </div>
                )} */}
                {!Result && (
                  <div
                    onClick={() =>
                      handleInputChange(
                        !treatmentProcedureEntry.headerShow,
                        "headerShow"
                      )
                    }
                    style={{ marginLeft: "10px", marginTop: "4px" }}
                  >
                    <img src={Addicon} alt="Add" />
                  </div>
                )}
              </div>
            </div>
          )}{" "}
          <div
            id={`${parent_id}_laborder_add_button_div`}
            style={styles.flexFlow}
          >
            {/* {treatmentProcedureEntry.orderCatalog?.value &&
            treatmentProcedureEntry.dateTime &&
            treatmentProcedureEntry.nature.label &&
            treatmentProcedureEntry.priority.label ? ( */}
            {treatmentProcedureEntry.headerShow && (
              <div
                id={`${parent_id}_laborder_add_button_sub_div`}
                style={{ width: "100%", display: "flex", marginRight: "10px" }}
              >
                {/* add button */}
                {/* <Button
                  id={`${parent_id}_laborder_add_button`}
                  onClick={() => {
                    if (
                      treatmentProcedureEntry.orderCatalog?.value &&
                      treatmentProcedureEntry.dateTime &&
                      treatmentProcedureEntry.nature.label &&
                      treatmentProcedureEntry.priority.label
                    ) {
                      AddTreatmentProcedure();
                    } else {
                      return;
                    }
                  }}
                  variant={"text"}
                  style={{
                    color: UIColor.primaryColor.color,
                    fontWeight: 600,
                    minWidth: "30%",
                    padding: "5px",
                    marginLeft: "3px",
                  }}
                  size={"small"}
                >
                  <img src={Tick} alt="Add" />
                </Button> */}
                {/* Removing close action as per the requirement*/}
                {/* <Button
                  id={`${parent_id}_allergy_add_button`}
                  onClick={() => handleCloseForm()}
                  variant={"text"}
                  style={{
                    color: UIColor.primaryColor.color,
                    fontWeight: 600,
                    minWidth: "30%",
                    padding: "5px",
                  }}
                  size={"small"}
                >
                  <img src={Cancel} alt="Cancel" />
                </Button> */}
              </div>
            )}
            {/* ) : ( */}
            {/* <div
                id={`${parent_id}_laborder_add_div`}
                style={{ width: "100%" }}
              >
                <Text
                  id={`${parent_id}_laborder_add_text`}
                  className="pc_medium"
                  inLineStyles={{ color: "#b6b6b6", fontSize: 14 }}
                >
                  {"+ ADD"}
                </Text>
              </div> */}
            {/* )} */}
            {/* <Tooltip
              id={`${parent_id}_laborder_Insights_icon_tooltip`}
              title={"Insights"}
            >
              <div style={{ ...styles.infoBell, ...styles.icons }}>
                <InsightsIcon
                  id={`${parent_id}_laborder_Insights_icon`}
                  style={{ marginLeft: 5 }}
                  // color={"#0071F2"}
                />
              </div>
            </Tooltip> */}
          </div>
        </Col>

        {/* Treatment procedure list */}
        <div style={{width:"100%"}}>
        {!Result ? (
          visibleCurrentItems.length > 0 &&
          visibleCurrentItems.map((val, i) => {
            return (
              <Col
                id={`${parent_id}_laborder_${i}_list_col`}
                key={i}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={{
                  // padding: "10px 8px",
                  borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
                  overflow: "hidden",
                }}
                className={"allergyListHover_"}
              >
                <PackageList
                  parent_id={"lab_order" + i}
                  treatment_order={val?.orderCatalog?.label}
                  priority={val?.priority?.label}
                  nature={val?.nature?.label}
                  dateTime={val?.dateTime}
                  ordersData={treatmentProcedureEntry?.orders}
                  onEditChart={props.onEditChart}
                  val={val}
                  infoAlertText={val.infoAlertText}
                  status={
                    val?.childRecurrent?.[0]?.statushistory?.length > 0
                      ? val?.childRecurrent?.[0]?.statushistory?.[
                          val?.childRecurrent?.[0]?.statushistory?.length - 1
                        ]?.new_status ?? "Draft"
                      : "Draft"
                  }
                  IndexValue={i}
                  deleteList={deleteList}
                  editFun={editFun}
                  data={val}
                  updatefuncy={updatefuncy}
                  info={info}
                  getData={getData}
                  patientgetData={props?.patientgetData}
                  recurrentData={val?.recurrentData}
                  saveNursingProcedure={props.saveNursingProcedure}
                  handleResultOpen={handleResultOpen}
                  handleResultClose={handleResultClose}
                  reason={reason}
                  delReason={delReason}
                  setDelReason={setDelReason}
                  handleReason={handleReason}
                  modal={modal}
                  setModal={setModal}
                />
              </Col>
            );
          })
        ) : (
          <Col
            id={`${parent_id}_laborder_list_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={{
              padding: "10px 8px",
              borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
              overflow: "hidden",
            }}
            className={"allergyListHover_"}
          >
            <AddResults
              TableBody={tableBody}
              TableHeader={tableHeader}
              BodyData={BodyData}
              parent_id={parent_id}
              selectedLab={selectedValue}
              handleResultClose={handleResultClose}
              saveLabResult={saveLabResult}
              componentdata={compData}
              Resulted={Resulted}
              Result={Result}
              ButtonShow={buttonShow}
              isEdit={isEdit}
              labResult={labResult}
            />
          </Col>
        )}
        {list.length > 5 && (
         <div
           style={{
             display: "flex",
             justifyContent: "flex-end",
             padding: "10px 10px",
             borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
           }}
         >
           <Pagination
             count={Math.ceil(list.length / currentPageSize)}
             page={currentCurPage}
             onChange={handleCurrentPageChange}
           />
         </div>
        )}
        </div>
        <HistoryAccordion>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            {visibleItems.length ? (
              visibleItems.map((order, i) => (
                <Grid
                  item
                  xs={12}
                  style={{
                    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
                  }}
                >
                   <Accordion elevation={0} >
                      <AccordionSummary
                        style={{padding:0}}
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                  <Grid container xs={12} justifyContent="space-between">
                    <Grid item xs={10} container direction="column" spacing={1}>
                      <Grid
                        item
                        style={{
                          color: UIColor.secondaryButtonColor.color,
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "pc_regular",
                        }}
                      >
                        {order.orderCatalog}{" "}&nbsp;&nbsp;
                        <InfoOutlined style={{height:"18px",width:"18px"}} color={UIColor.primaryColor.color} />
                      </Grid>
                      <Grid
                        item
                        style={{
                          fontFamily: "pc_regular",
                          fontSize: 12,
                          color: UIColor.tertiaryText.color,
                        }}
                      >
                        {order?.priority?.display} -{" "}
                        {order?.OrderNature?.display} - On{" "}
                        {formatDateFromEpoch(order?.occuranceStartDate)}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      container
                      alignItems="flex-end"
                      direction="column"
                      spacing={1}
                      style={{
                        fontFamily: "pc_regular",
                        fontSize: 12,
                        color: UIColor.tertiaryText.color,
                      }}
                    >
                      <Grid item>
                        On{" "}
                        {formatDateFromEpoch(order?.orderDateTime).slice(0, 12)}
                      </Grid>
                      <Grid item>{order?.updatedby?.name?.[0]?.fulname}</Grid>
                    </Grid>
                  </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Grid container direction="column" style={{borderRadius:5,margin:"0px 0px 0px 7px"}}>
                    <Text
                      inLineStyles={{
                        fontWeight: 600,
                        fontSize: "14px",
                        cursor: "pointer",
                        color: UIColor.primaryColor.color,
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      Package Items
                    </Text>
                      {order?.packdetails?.map((item)=>{
                        return(
                        <Grid container justifyContent="space-between" alignItems="center" style={{backgroundColor:UIColor.differentiationBackground.color,padding: "10px 12px",borderTop:`1px solid ${UIColor.lineBorderFill.color}`,borderRadius:5}}>
                          <Grid item>
                            <Grid container  direction="column">
                              <Grid item >
                                <Text
                                inLineStyles={{
                                  color: UIColor.secondaryButtonColor.color,
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  fontFamily: "pc_regular",
                                }}
                                >
                                  {item?.ordername}
                                </Text>
                              </Grid>
                              <Grid item >
                                <Text inLineStyles={styles.textColor1}>
                                    Package Qty - {item?.total}  &nbsp;&nbsp;&nbsp;&nbsp;
                                    Availed Qty - {item?.availqty} &nbsp;&nbsp;&nbsp;&nbsp;  
                                    Order Status - &nbsp;
                                    <Text inLineStyles={styles.textColor1} style={item?.packordstatus=="Completed"? {color:UIColor.success.color}:{color:UIColor.secondaryColor.color} } >{
                                      item?.packordstatus}
                                    </Text>
                                </Text>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item></Grid>
                        </Grid>
                        )
                      })}
                   </Grid>
                  </AccordionDetails>
                  </Accordion>
                </Grid>
              ))
            ) : (
              <Typography
                variant="caption"
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "5px",
                  display: "block",
                }}
              >
                No data available
              </Typography>
            )}
          </Grid>

          {visibleItems.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Pagination
                count={Math.ceil(nurseHistory.length / pageSize)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </div>
          )}
        </HistoryAccordion>
      </Row>
      {treatmentProcedureEntry.error && (
        <span
          id={`${parent_id}_laborder_filled_Recurrent_span`}
          style={styles.vitailsCarderr}
        >
          Please fill all the mandatory fields!
        </span>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={index === 1 ? handleClose : null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.muiPopovers }}
      >
        {index === 0 && (
          <Div
            id={`${parent_id}_laborder_Recurrent_div`}
            style={{ padding: 15 }}
          >
            <Recurrent
              parent_id={"lab_order"}
              recurrentData={treatmentProcedureEntry?.recurrentData}
              saveButtonAction={saveRecurrentData}
              deleteButtonAction={handleClose1}
              width={500}
            />
          </Div>
        )}
        {index === 1 && (
          // <DatePicker
          //   id={`${parent_id}_laborder_DatePicker`}
          //   timeInputLabel="Time:"
          //   dateFormat="MM/dd/yyyy h:mm aa"
          //   format="MM/dd/yyyy h:mm aa"
          //   showTimeInput
          //   // selected={this.state.dob.value}
          //   selected={new Date()}
          //   // showPopperArrow={false}
          //   // showYearDropdown
          //   minDate={new Date()}
          //   minTime={new Date()}
          //   inline
          //   onChange={(date) => {
          //     handleInputChange(
          //       moment(date).format("YYYY-MM-DD,hh:mm A"),
          //       "dateTime"
          //     );
          //     handleClose();
          //   }}
          //   // placeholderText="DD-MM-YYYY"
          //   // maxDate={new Date()}
          //   // className={"pc_regular"}
          // />
          <CustomDatePicker
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            selectedDate={
              treatmentProcedureEntry?.dateTime
                ? new Date(treatmentProcedureEntry?.dateTime)
                : null
            }
            //minDate={new Date()}
            minTime={getCurrentMinTime(
              treatmentProcedureEntry?.dateTime
                ? new Date(treatmentProcedureEntry?.dateTime)
                : null
            )}
            maxTime={setHours(setMinutes(new Date(), 59), 23)}
            handleChange={(date, e) => {
              handleInputChange(date, "dateTime");
              if (e?.target === undefined) {
                setIndex(null);
                handleClose();
              }
            }}
          />
        )}
      </Popover>
      {alertData.open && (
        <AlertsRestriction {...alertData} handleClose={closeAlertModal} />
      )}
    </div>
  );
};
export default withAllContexts(PackageOrder);
