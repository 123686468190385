import React from "react";
import { Card, Col, H6, Row, Text } from "qdm-component-library";
import appointment_scheduling from "../../../assets/img/appointment_scheduling.svg";
import user_group from "../../../assets/img/user_group.svg";
import group from "../../../assets/img/group.svg";
import business from "../../../assets/img/business.svg";
import walking from "../../../assets/img/walking.svg";
// import { useDispatch, useSelector } from "react-redux";
// import { actions } from "primarycare-binder";
// import moment from "moment";
// import { getUserInfo, getUtcTime, permission } from "../../../utils";
// import { useHistory } from "react-router-dom";
// import { Routes } from '../../../router/routes';
// const casbinjs = require("casbin.js");

function StatsCard(props) {
  const styles = {
    card: {
      height: "100%",
      padding: "12px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      border: "1px solid #E0E0E0",
      boxShadow: "0px 10px 25px #0000000A",
      borderRadius: 8,
      position: "relative",
      overflow: "hidden",
      cursor: 'pointer'
    },
    title: {
      marginBottom: "10px",
      padding: 0,
    },
    img: {
      bottom: "8px",
      position: "absolute",
      right: "-8px",
      opacity: 0.3,
    },
  };

  const { query, color, total, count, actionsName, parent_id } = props;

  // const dispatch = useDispatch();
  // const history = useHistory();

  const [state, setState] = React.useState({
    loading: query ? true : false,
    color: color,
    total: total,
    count: count,
  });
  // const loggedUserInfo = useSelector(
  //   (state) => state?.authSlice?.loggedUserInfo
  // );

  React.useEffect(() => {
    // if (actionsName) {
    //   getData(actionsName);
    // }
  }, []);

  // const authorizer = new casbinjs.Authorizer("manual", permission);
  // authorizer.setPermission(permission);

  // const fetchUserInfo = async (email) => {
  //   if (!loggedUserInfo?.data?.orgId) {
  //     if (!email) {
  //       const info = getUserInfo();
  //       email = info.email;
  //     }
  //     const userData = await dispatch(
  //       actions.SEARCH_PRACTIONER({
  //         email,
  //       })
  //     );
  //     const info = {
  //       orgId: userData?.payload?.data?.[0]?.OrgID?.[0],
  //       name: {
  //         given: userData?.payload?.data?.[0]?.name?.[0]?.given,
  //         family: userData?.payload?.data?.[0]?.name?.[0]?.family,
  //         text: userData?.payload?.data?.[0]?.name?.[0]?.text,
  //       },
  //       personId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
  //       practionerId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
  //       role: userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole[0]?.code[0]?.coding?.[0]?.display?.toLowerCase(),
  //       // org_id: userData?.payload?.data?.[0]?.org_id,
  //       org_id: userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole?.[0]?.OrgID
  //     };

  //     await dispatch(actions.LOGGED_USER_INFO(info));
  //     return info;
  //   }
    
  //   return loggedUserInfo?.data;
  // };

  // const getData = async (actionsName) => {
  //   const userData = await fetchUserInfo();
  //   let payload = {
  //     orgId: userData?.org_id || "",
  //     startDate: getUtcTime(moment().startOf("day")),
  //     endDate: getUtcTime(moment().endOf("day")),
  //     roleId: props.roleId,
  //     loginId: userData?.practionerId,
  //     orGId: userData?.orgId,
  //   };

  //   let data = await dispatch(actions[actionsName](payload));
  //   setState({
  //     loading: false,
  //     color: color,
  //     total: null,
  //     count: data.payload.data.count,
  //   });

  // };

  const onCardClick = async (props) => {
    // let userInfo = loggedUserInfo?.data || {};
    // if (!userInfo?.org_id) {
    //   userInfo = await fetchUserInfo();
    // }
    // // let data = await dispatch(actions.GET_ORGANIZATION({
    // //   id: loggedUserInfo?.data?.orgId
    // // }))
    // //data?.payload?.data?.[0]?._id,
    // history.push({
    //   pathname: Routes.appointmentStats,
    //   state: {
    //     organizationID: userInfo?.org_id,
    //     practionerID: userInfo?.practionerId,
    //     index: props?.index,
    //     roleId: props.roleId,
    //     organizationInt: userInfo?.orgId,
    //   },
    // });
  }

  return (
    <div
      style={{ height: "100%", padding: 10 }}
      id={`${parent_id}${props.title.replaceAll(" ", "_")}_parent_div`}
      onClick={() => onCardClick(props)}
    >
      <Card
        id={`${parent_id}${props.title.replaceAll(" ", "_")}_parent_card`}
        style={{ ...styles.card }}
      >
        <Row id={`${parent_id}${props.title.replaceAll(" ", "_")}_parent_row`}>
          <Col id={`${parent_id}${props.title.replaceAll(" ", "_")}_title_col`} style={styles.title}>
            <Text
              id={`${parent_id}${props.title.replaceAll(" ", "_")}_title_text`}
              className="pc_medium"
              style={{ fontSize: 14 }}
            >
              {props.title}
            </Text>
          </Col>
          <Col
            id={`${parent_id}${props.title.replaceAll(" ", "_")}_total_col`}
            inLineStyles={{ padding: 0 }}
          >
            <H6
              id={`${parent_id}${props.title.replaceAll(" ", "_")}_total_h6`}
              className="pc_semibold"
              style={{
                color: state.color,
                fontSize: 20,
              }}
            >
              {state.count}
              {state.total && `/${state.total}`}
            </H6>
          </Col>
        </Row>
        <img
          id={`${parent_id}${props.title.replaceAll(" ", "_")}_user_group_image`}
          src={
            actionsName === "APPOINTMENTS"
              ? appointment_scheduling
              : actionsName === "FIRST_VISIT_COUNT"
                ? group
                : actionsName === "OTHER_VISIT_COUNT"
                  ? business
                  : actionsName === "WALK_INS_COUNT"
                    ? walking
                    : props.title === "New Patients"
                      ? user_group
                      : user_group
          }
          alt="user_group"
          style={styles.img}
        />
      </Card>
    </div>
  );
}

StatsCard.defaultProps = {
  title: "Title",
  count: "50",
  color: "#000",
  total: "",
};

export default StatsCard;
