import React from "react";

export const OrderSetsIcon2 = (props) => {
  let width = props.width ? props.width : "41.026";
  let height = props.height ? props.height : "40";
  let color = props.color ? props.color : "#252525";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 35.412 33.661"
    >
      <g id="order_set1" data-name="order set1" transform="translate(-7.4 -10)">
        <g
          id="Group_93235"
          data-name="Group 93235"
          transform="translate(7.4 10)"
        >
          <path
            id="Path_93038"
            data-name="Path 93038"
            d="M79.678,10H66.012A1.252,1.252,0,0,0,64.8,11.212V24a1.252,1.252,0,0,0,1.212,1.212H79.678A1.252,1.252,0,0,0,80.89,24V11.212A1.229,1.229,0,0,0,79.678,10Zm-2.087,6.6H68.1V13.3h9.492Z"
            transform="translate(-45.479 -10)"
            fill="#ec6a49"
          />
          <path
            id="Path_93039"
            data-name="Path 93039"
            d="M22.278,10H8.612A1.252,1.252,0,0,0,7.4,11.212V24a1.252,1.252,0,0,0,1.212,1.212h13.7A1.252,1.252,0,0,0,23.524,24V11.212A1.308,1.308,0,0,0,22.278,10Zm-2.087,6.6H10.7V13.3h9.492Z"
            transform="translate(-7.4 -10)"
            fill="#2a60bc"
          />
          <path
            id="Path_93040"
            data-name="Path 93040"
            d="M79.678,64.8H66.012A1.252,1.252,0,0,0,64.8,66.012V78.8a1.252,1.252,0,0,0,1.212,1.212H79.678A1.252,1.252,0,0,0,80.89,78.8V66.012A1.229,1.229,0,0,0,79.678,64.8Zm-2.087,6.6H68.1V68.1h9.492Z"
            transform="translate(-45.479 -46.354)"
            fill="#2a60bc"
          />
          <path
            id="Path_93041"
            data-name="Path 93041"
            d="M22.278,64.8H8.612A1.252,1.252,0,0,0,7.4,66.012V78.8a1.252,1.252,0,0,0,1.212,1.212h13.7A1.252,1.252,0,0,0,23.524,78.8V66.012A1.308,1.308,0,0,0,22.278,64.8Zm-2.087,6.6H10.7V68.1h9.492Z"
            transform="translate(-7.4 -46.354)"
            fill="#ec6a49"
          />
        </g>
      </g>
    </svg>
  );
};
