import React, { useState } from "react";
import {
  Div,
  Select,
  Col,
  Row,
  Paper,
  H6,
  TextInput,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../../hoc/withAllContexts";
import { UIColor } from "../../../themes/theme";

function ApplicableCriteria() {
  let props ={
    "parent_id": "financial_applicable_criteria",
    "allMasters": {
        "NAMESUFFIX": [
            {
                "Type": "NAMESUFFIX",
                "_id": "CodeableConceptMaster/11380",
                "_key": "11380",
                "_rev": "_gea5zCS---",
                "activestatus": true,
                "coding": {
                    "_key": "11409",
                    "_id": "CodingMaster/11409",
                    "_rev": "_gea5zCO---",
                    "code": "-",
                    "display": "-",
                    "id": 1168,
                    "Type": "NAMESUFFIX",
                    "shortdesc": "-",
                    "gmconfigvalues": {},
                    "status": true,
                    "createdby": "",
                    "createddate": 1692338476,
                    "updatedby": "",
                    "updatedate": 1692338476,
                    "activestatus": true
                },
                "createdby": "",
                "createddate": 1692338476,
                "id": 1154,
                "status": true,
                "text": "-",
                "updatedate": 1692338476,
                "updatedby": ""
            },
            {
                "Type": "NAMESUFFIX",
                "_id": "CodeableConceptMaster/10512",
                "_key": "10512",
                "_rev": "_e8UT9IC--w",
                "activestatus": true,
                "coding": {
                    "_key": "10513",
                    "_id": "CodingMaster/10513",
                    "_rev": "_e8UTuZm--T",
                    "Type": "NAMESUFFIX",
                    "activestatus": true,
                    "code": "NS001",
                    "createdby": "",
                    "createddate": 1635954130,
                    "display": "Junior",
                    "gmconfigvalues": {},
                    "id": 513,
                    "shortdesc": "Junior",
                    "status": true,
                    "updatedate": 1635954130,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954130,
                "id": 513,
                "status": true,
                "text": "NS001",
                "updatedate": 1635954130,
                "updatedby": ""
            },
            {
                "Type": "NAMESUFFIX",
                "_id": "CodeableConceptMaster/10513",
                "_key": "10513",
                "_rev": "_e8UT9JO--m",
                "activestatus": true,
                "coding": {
                    "_key": "10514",
                    "_id": "CodingMaster/10514",
                    "_rev": "_e8UTuaW--O",
                    "Type": "NAMESUFFIX",
                    "activestatus": true,
                    "code": "NS002",
                    "createdby": "",
                    "createddate": 1635954139,
                    "display": "Senior",
                    "gmconfigvalues": {},
                    "id": 514,
                    "shortdesc": "Senior",
                    "status": true,
                    "updatedate": 1635954139,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954139,
                "id": 514,
                "status": true,
                "text": "NS002",
                "updatedate": 1635954139,
                "updatedby": ""
            }
        ],
        "NAMEPREFIX": [
            {
                "Type": "NAMEPREFIX",
                "_id": "CodeableConceptMaster/11379",
                "_key": "11379",
                "_rev": "_gea4QaK--C",
                "activestatus": true,
                "coding": {
                    "_key": "11408",
                    "_id": "CodingMaster/11408",
                    "_rev": "_gea4QaK---",
                    "code": ".",
                    "display": ".",
                    "id": 1167,
                    "Type": "NAMEPREFIX",
                    "shortdesc": ".",
                    "gmconfigvalues": {},
                    "status": true,
                    "createdby": "",
                    "createddate": 1692338375,
                    "updatedby": "",
                    "updatedate": 1692338375,
                    "activestatus": true
                },
                "createdby": "",
                "createddate": 1692338375,
                "id": 1153,
                "status": true,
                "text": ".",
                "updatedate": 1692338375,
                "updatedby": ""
            },
            {
                "Type": "NAMEPREFIX",
                "_id": "CodeableConceptMaster/10508",
                "_key": "10508",
                "_rev": "_e8UT9Ie--k",
                "activestatus": true,
                "coding": {
                    "_key": "10509",
                    "_id": "CodingMaster/10509",
                    "_rev": "_e8UTuae--M",
                    "Type": "NAMEPREFIX",
                    "activestatus": true,
                    "code": "NP001",
                    "createdby": "",
                    "createddate": 1635954007,
                    "display": "Dato",
                    "gmconfigvalues": {},
                    "id": 509,
                    "shortdesc": "Dato",
                    "status": true,
                    "updatedate": 1635954007,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954007,
                "id": 509,
                "status": true,
                "text": "NP001",
                "updatedate": 1635954007,
                "updatedby": ""
            },
            {
                "Type": "NAMEPREFIX",
                "_id": "CodeableConceptMaster/11405",
                "_key": "11405",
                "_rev": "_ggBU6dO--C",
                "activestatus": true,
                "coding": {
                    "_key": "11434",
                    "_id": "CodingMaster/11434",
                    "_rev": "_ggBU6dO---",
                    "code": "NP005",
                    "display": "Dr",
                    "id": 1193,
                    "Type": "NAMEPREFIX",
                    "shortdesc": "Dr",
                    "gmconfigvalues": {},
                    "status": true,
                    "createdby": "",
                    "createddate": 1692768072,
                    "updatedby": "",
                    "updatedate": 1692768072,
                    "activestatus": true
                },
                "createdby": "",
                "createddate": 1692768072,
                "id": 1179,
                "status": true,
                "text": "NP005",
                "updatedate": 1692768072,
                "updatedby": ""
            },
            {
                "Type": "NAMEPREFIX",
                "_id": "CodeableConceptMaster/10511",
                "_key": "10511",
                "_rev": "_e8UT9IC--O",
                "activestatus": true,
                "coding": {
                    "_key": "10512",
                    "_id": "CodingMaster/10512",
                    "_rev": "_e8UTuZm--_",
                    "Type": "NAMEPREFIX",
                    "activestatus": true,
                    "code": "NP004",
                    "createdby": "",
                    "createddate": 1635954055,
                    "display": "Mr",
                    "gmconfigvalues": {},
                    "id": 512,
                    "shortdesc": "Mr",
                    "status": true,
                    "updatedate": 1635954055,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954055,
                "id": 512,
                "status": true,
                "text": "NP004",
                "updatedate": 1635954055,
                "updatedby": ""
            },
            {
                "Type": "NAMEPREFIX",
                "_id": "CodeableConceptMaster/10509",
                "_key": "10509",
                "_rev": "_e8UT9JC--K",
                "activestatus": true,
                "coding": {
                    "_key": "10510",
                    "_id": "CodingMaster/10510",
                    "_rev": "_e8UTuaC-_O",
                    "Type": "NAMEPREFIX",
                    "activestatus": true,
                    "code": "NP002",
                    "createdby": "",
                    "createddate": 1635954022,
                    "display": "Mrs",
                    "gmconfigvalues": {},
                    "id": 510,
                    "shortdesc": "Mrs",
                    "status": true,
                    "updatedate": 1635954022,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954022,
                "id": 510,
                "status": true,
                "text": "NP002",
                "updatedate": 1635954022,
                "updatedby": ""
            },
            {
                "Type": "NAMEPREFIX",
                "_id": "CodeableConceptMaster/10510",
                "_key": "10510",
                "_rev": "_e8UT9I---8",
                "activestatus": true,
                "coding": {
                    "_key": "10511",
                    "_id": "CodingMaster/10511",
                    "_rev": "_e8UTuZi--k",
                    "Type": "NAMEPREFIX",
                    "activestatus": true,
                    "code": "NP003",
                    "createdby": "",
                    "createddate": 1635954038,
                    "display": "Ms",
                    "gmconfigvalues": {},
                    "id": 511,
                    "shortdesc": "Ms",
                    "status": true,
                    "updatedate": 1635954038,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954038,
                "id": 511,
                "status": true,
                "text": "NP003",
                "updatedate": 1635954038,
                "updatedby": ""
            }
        ],
        "Blood Group": [
            {
                "Type": "Blood Group",
                "_id": "CodeableConceptMaster/10089",
                "_key": "10089",
                "_rev": "_e8UT9J---U",
                "activestatus": true,
                "coding": {
                    "_key": "10090",
                    "_id": "CodingMaster/10090",
                    "_rev": "_e8UTuaW--6",
                    "Type": "BLOODGROUP",
                    "activestatus": true,
                    "code": "BG001",
                    "createdby": "",
                    "createddate": 1635940560,
                    "display": "A- (A Negative)",
                    "gmconfigvalues": {},
                    "id": 90,
                    "shortdesc": "A- (A Negative)",
                    "status": true,
                    "updatedate": 1635940560,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940560,
                "id": 90,
                "status": true,
                "text": "BG001",
                "updatedate": 1635940560,
                "updatedby": ""
            },
            {
                "Type": "Blood Group",
                "_id": "CodeableConceptMaster/10090",
                "_key": "10090",
                "_rev": "_e8UT9J---q",
                "activestatus": true,
                "coding": {
                    "_key": "10091",
                    "_id": "CodingMaster/10091",
                    "_rev": "_e8UTuZS--6",
                    "Type": "BLOODGROUP",
                    "activestatus": true,
                    "code": "BG002",
                    "createdby": "",
                    "createddate": 1635940578,
                    "display": "A+ (A Positive)",
                    "gmconfigvalues": {},
                    "id": 91,
                    "shortdesc": "A+ (A Positive)",
                    "status": true,
                    "updatedate": 1635940578,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940578,
                "id": 91,
                "status": true,
                "text": "BG002",
                "updatedate": 1635940578,
                "updatedby": ""
            },
            {
                "Type": "Blood Group",
                "_id": "CodeableConceptMaster/10091",
                "_key": "10091",
                "_rev": "_e8UT9JC--A",
                "activestatus": true,
                "coding": {
                    "_key": "10092",
                    "_id": "CodingMaster/10092",
                    "_rev": "_e8UTuZS-_A",
                    "Type": "BLOODGROUP",
                    "activestatus": true,
                    "code": "BG003",
                    "createdby": "",
                    "createddate": 1635940595,
                    "display": "AB- (AB Negative)",
                    "gmconfigvalues": {},
                    "id": 92,
                    "shortdesc": "AB- (AB Negative)",
                    "status": true,
                    "updatedate": 1635940595,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940595,
                "id": 92,
                "status": true,
                "text": "BG003",
                "updatedate": 1635940595,
                "updatedby": ""
            },
            {
                "Type": "Blood Group",
                "_id": "CodeableConceptMaster/10092",
                "_key": "10092",
                "_rev": "_e8UT9Iy-_H",
                "activestatus": true,
                "coding": {
                    "_key": "10093",
                    "_id": "CodingMaster/10093",
                    "_rev": "_e8UTuaS--Q",
                    "Type": "BLOODGROUP",
                    "activestatus": true,
                    "code": "BG004",
                    "createdby": "",
                    "createddate": 1635940618,
                    "display": "AB+ (AB Positive)",
                    "gmconfigvalues": {},
                    "id": 93,
                    "shortdesc": "AB+ (AB Positive)",
                    "status": true,
                    "updatedate": 1635940618,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940618,
                "id": 93,
                "status": true,
                "text": "BG004",
                "updatedate": 1635940618,
                "updatedby": ""
            },
            {
                "Type": "Blood Group",
                "_id": "CodeableConceptMaster/10093",
                "_key": "10093",
                "_rev": "_e8UT9Iy-_R",
                "activestatus": true,
                "coding": {
                    "_key": "10094",
                    "_id": "CodingMaster/10094",
                    "_rev": "_e8UTuZS-_C",
                    "Type": "BLOODGROUP",
                    "activestatus": true,
                    "code": "BG005",
                    "createdby": "",
                    "createddate": 1635940637,
                    "display": "B- (B Negative)",
                    "gmconfigvalues": {},
                    "id": 94,
                    "shortdesc": "B- (B Negative)",
                    "status": true,
                    "updatedate": 1635940637,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940637,
                "id": 94,
                "status": true,
                "text": "BG005",
                "updatedate": 1635940637,
                "updatedby": ""
            },
            {
                "Type": "Blood Group",
                "_id": "CodeableConceptMaster/10094",
                "_key": "10094",
                "_rev": "_e8UT9I2--k",
                "activestatus": true,
                "coding": {
                    "_key": "10095",
                    "_id": "CodingMaster/10095",
                    "_rev": "_e8UTuZS-_-",
                    "Type": "BLOODGROUP",
                    "activestatus": true,
                    "code": "BG006",
                    "createdby": "",
                    "createddate": 1635940657,
                    "display": "B+ (B Positive)",
                    "gmconfigvalues": {},
                    "id": 95,
                    "shortdesc": "B+ (B Positive)",
                    "status": true,
                    "updatedate": 1635940657,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940657,
                "id": 95,
                "status": true,
                "text": "BG006",
                "updatedate": 1635940657,
                "updatedby": ""
            },
            {
                "Type": "Blood Group",
                "_id": "CodeableConceptMaster/10095",
                "_key": "10095",
                "_rev": "_e8UT9I2--c",
                "activestatus": true,
                "coding": {
                    "_key": "10096",
                    "_id": "CodingMaster/10096",
                    "_rev": "_e8UTuZe--l",
                    "Type": "BLOODGROUP",
                    "activestatus": true,
                    "code": "BG007",
                    "createdby": "",
                    "createddate": 1635940674,
                    "display": "O- (O Negative)",
                    "gmconfigvalues": {},
                    "id": 96,
                    "shortdesc": "O- (O Negative)",
                    "status": true,
                    "updatedate": 1635940674,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940674,
                "id": 96,
                "status": true,
                "text": "BG007",
                "updatedate": 1635940674,
                "updatedby": ""
            },
            {
                "Type": "Blood Group",
                "_id": "CodeableConceptMaster/10098",
                "_key": "10098",
                "_rev": "_e8UT9JK-_C",
                "activestatus": true,
                "coding": {
                    "_key": "10099",
                    "_id": "CodingMaster/10099",
                    "_rev": "_fT0C7HG---",
                    "Type": "Blood Group",
                    "activestatus": true,
                    "code": "BG008",
                    "createdby": "",
                    "createddate": 1635940697,
                    "display": "O+ (O Positive)",
                    "gmconfigvalues": {
                        "_key": "10099"
                    },
                    "id": 99,
                    "shortdesc": "O+ (O Positive)",
                    "status": true,
                    "updatedate": 1672311272,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940697,
                "id": 99,
                "status": true,
                "text": "BG008",
                "updatedate": 1635940697,
                "updatedby": ""
            }
        ],
        "ALTERNATEIDTYPE": [
            {
                "Type": "ALTERNATEIDTYPE",
                "_id": "CodeableConceptMaster/10514",
                "_key": "10514",
                "_rev": "_e8UT9Hy--4",
                "activestatus": true,
                "coding": {
                    "_key": "10515",
                    "_id": "CodingMaster/10515",
                    "_rev": "_e8UTuZa--Y",
                    "Type": "ALTERNATEIDTYPE",
                    "activestatus": true,
                    "code": "AD001",
                    "collection_name": "CodingMaster",
                    "createdby": "",
                    "createddate": 1649730556,
                    "display": "Aadhaar Card",
                    "gmconfigvalues": {},
                    "id": 515,
                    "shortdesc": "Aadhaar Card",
                    "status": true,
                    "updatedate": 1649730556,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954193,
                "id": 515,
                "status": true,
                "text": "AD001",
                "updatedate": 1649730556,
                "updatedby": ""
            },
            {
                "Type": "ALTERNATEIDTYPE",
                "_id": "CodeableConceptMaster/10515",
                "_key": "10515",
                "_rev": "_e8UT9Ia--o",
                "activestatus": true,
                "coding": {
                    "_key": "10516",
                    "_id": "CodingMaster/10516",
                    "_rev": "_e8UTuZq--0",
                    "Type": "ALTERNATEIDTYPE",
                    "activestatus": true,
                    "code": "AD002",
                    "createdby": "",
                    "createddate": 1635954201,
                    "display": "Birth Certificate No",
                    "gmconfigvalues": {},
                    "id": 516,
                    "shortdesc": "Birth Certificate No",
                    "status": true,
                    "updatedate": 1635954201,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954201,
                "id": 516,
                "status": true,
                "text": "AD002",
                "updatedate": 1635954201,
                "updatedby": ""
            },
            {
                "Type": "ALTERNATEIDTYPE",
                "_id": "CodeableConceptMaster/10516",
                "_key": "10516",
                "_rev": "_e8UT9JK--S",
                "activestatus": true,
                "coding": {
                    "_key": "10517",
                    "_id": "CodingMaster/10517",
                    "_rev": "_e8UTuaK--U",
                    "Type": "ALTERNATEIDTYPE",
                    "activestatus": true,
                    "code": "AD003",
                    "createdby": "",
                    "createddate": 1635954211,
                    "display": "Driving Licence",
                    "gmconfigvalues": {},
                    "id": 517,
                    "shortdesc": "Driving Licence",
                    "status": true,
                    "updatedate": 1637738392,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954211,
                "id": 517,
                "status": true,
                "text": "AD003",
                "updatedate": 1635954211,
                "updatedby": ""
            },
            {
                "Type": "ALTERNATEIDTYPE",
                "_id": "CodeableConceptMaster/10517",
                "_key": "10517",
                "_rev": "_e8UT9JW--X",
                "activestatus": true,
                "coding": {
                    "_key": "10518",
                    "_id": "CodingMaster/10518",
                    "_rev": "_e8UTuaS--g",
                    "Type": "ALTERNATEIDTYPE",
                    "activestatus": true,
                    "code": "AD004",
                    "createdby": "",
                    "createddate": 1635954222,
                    "display": "Employer No",
                    "gmconfigvalues": {},
                    "id": 518,
                    "shortdesc": "Employer No",
                    "status": true,
                    "updatedate": 1635954222,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954222,
                "id": 518,
                "status": true,
                "text": "AD004",
                "updatedate": 1635954222,
                "updatedby": ""
            },
            {
                "Type": "ALTERNATEIDTYPE",
                "_id": "CodeableConceptMaster/11391",
                "_key": "11391",
                "_rev": "_geiWCcO--C",
                "activestatus": true,
                "coding": {
                    "_key": "11420",
                    "_id": "CodingMaster/11420",
                    "_rev": "_geiWCcO---",
                    "code": "DIALYSIS_004",
                    "display": "HD Machine No",
                    "id": 1179,
                    "Type": "ALTERNATEIDTYPE",
                    "shortdesc": "HD Machine No",
                    "gmconfigvalues": {},
                    "status": true,
                    "createdby": "",
                    "createddate": 1692369686,
                    "updatedby": "",
                    "updatedate": 1692369686,
                    "activestatus": true
                },
                "createdby": "",
                "createddate": 1692369686,
                "id": 1165,
                "status": true,
                "text": "DIALYSIS_004",
                "updatedate": 1692369686,
                "updatedby": ""
            },
            {
                "Type": "ALTERNATEIDTYPE",
                "_id": "CodeableConceptMaster/10518",
                "_key": "10518",
                "_rev": "_e8UT9J--_-",
                "activestatus": true,
                "coding": {
                    "_key": "10519",
                    "_id": "CodingMaster/10519",
                    "_rev": "_e8UTuaS--G",
                    "Type": "ALTERNATEIDTYPE",
                    "activestatus": true,
                    "code": "AD005",
                    "createdby": "",
                    "createddate": 1635954231,
                    "display": "Medical Licence No",
                    "gmconfigvalues": {},
                    "id": 519,
                    "shortdesc": "Medical Licence No",
                    "status": true,
                    "updatedate": 1635954231,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954231,
                "id": 519,
                "status": true,
                "text": "AD005",
                "updatedate": 1635954231,
                "updatedby": ""
            },
            {
                "Type": "ALTERNATEIDTYPE",
                "_id": "CodeableConceptMaster/11384",
                "_key": "11384",
                "_rev": "_gefpZMO---",
                "activestatus": true,
                "coding": {
                    "_key": "11413",
                    "_id": "CodingMaster/11413",
                    "_rev": "_gefpZMK---",
                    "code": "DIALYSIS_002",
                    "display": "Military ID No",
                    "id": 1172,
                    "Type": "ALTERNATEIDTYPE",
                    "shortdesc": "Military ID No",
                    "gmconfigvalues": {},
                    "status": true,
                    "createdby": "",
                    "createddate": 1692358372,
                    "updatedby": "",
                    "updatedate": 1692358372,
                    "activestatus": true
                },
                "createdby": "",
                "createddate": 1692358372,
                "id": 1158,
                "status": true,
                "text": "DIALYSIS_002",
                "updatedate": 1692358372,
                "updatedby": ""
            },
            {
                "Type": "ALTERNATEIDTYPE",
                "_id": "CodeableConceptMaster/10519",
                "_key": "10519",
                "_rev": "_e8UT9I2--0",
                "activestatus": true,
                "coding": {
                    "_key": "10520",
                    "_id": "CodingMaster/10520",
                    "_rev": "_e8UTuaO-_Y",
                    "Type": "ALTERNATEIDTYPE",
                    "activestatus": true,
                    "code": "AD006",
                    "createdby": "",
                    "createddate": 1635954244,
                    "display": "MyKad",
                    "gmconfigvalues": {},
                    "id": 520,
                    "shortdesc": "MyKad",
                    "status": true,
                    "updatedate": 1635954244,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954244,
                "id": 520,
                "status": true,
                "text": "AD006",
                "updatedate": 1635954244,
                "updatedby": ""
            },
            {
                "Type": "ALTERNATEIDTYPE",
                "_id": "CodeableConceptMaster/10521",
                "_key": "10521",
                "_rev": "_e8UT9Iu--W",
                "activestatus": true,
                "coding": {
                    "_key": "10522",
                    "_id": "CodingMaster/10522",
                    "_rev": "_e8UTuai--P",
                    "Type": "ALTERNATEIDTYPE",
                    "activestatus": true,
                    "code": "AD008",
                    "createdby": "",
                    "createddate": 1635954265,
                    "display": "Passport",
                    "gmconfigvalues": {},
                    "id": 522,
                    "shortdesc": "Passport",
                    "status": true,
                    "updatedate": 1635954265,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954265,
                "id": 522,
                "status": true,
                "text": "AD008",
                "updatedate": 1635954265,
                "updatedby": ""
            },
            {
                "Type": "ALTERNATEIDTYPE",
                "_id": "CodeableConceptMaster/11385",
                "_key": "11385",
                "_rev": "_gefp3_2--C",
                "activestatus": true,
                "coding": {
                    "_key": "11414",
                    "_id": "CodingMaster/11414",
                    "_rev": "_gefp3_2---",
                    "code": "DIALYSIS_003",
                    "display": "Pension Acc No",
                    "id": 1173,
                    "Type": "ALTERNATEIDTYPE",
                    "shortdesc": "Pension Acc No",
                    "gmconfigvalues": {},
                    "status": true,
                    "createdby": "",
                    "createddate": 1692358403,
                    "updatedby": "",
                    "updatedate": 1692358403,
                    "activestatus": true
                },
                "createdby": "",
                "createddate": 1692358403,
                "id": 1159,
                "status": true,
                "text": "DIALYSIS_003",
                "updatedate": 1692358403,
                "updatedby": ""
            },
            {
                "Type": "ALTERNATEIDTYPE",
                "_id": "CodeableConceptMaster/11383",
                "_key": "11383",
                "_rev": "_gefpDLS---",
                "activestatus": true,
                "coding": {
                    "_key": "11412",
                    "_id": "CodingMaster/11412",
                    "_rev": "_gefpDLO---",
                    "code": "DIALYSIS_001",
                    "display": "Police ID No",
                    "id": 1171,
                    "Type": "ALTERNATEIDTYPE",
                    "shortdesc": "Police ID No",
                    "gmconfigvalues": {},
                    "status": true,
                    "createdby": "",
                    "createddate": 1692358349,
                    "updatedby": "",
                    "updatedate": 1692358349,
                    "activestatus": true
                },
                "createdby": "",
                "createddate": 1692358350,
                "id": 1157,
                "status": true,
                "text": "DIALYSIS_001",
                "updatedate": 1692358350,
                "updatedby": ""
            },
            {
                "Type": "ALTERNATEIDTYPE",
                "_id": "CodeableConceptMaster/10522",
                "_key": "10522",
                "_rev": "_e8UT9JK--Q",
                "activestatus": true,
                "coding": {
                    "_key": "10523",
                    "_id": "CodingMaster/10523",
                    "_rev": "_e8UTuai--N",
                    "Type": "ALTERNATEIDTYPE",
                    "activestatus": true,
                    "code": "AD009",
                    "createdby": "",
                    "createddate": 1635954277,
                    "display": "Social Security No",
                    "gmconfigvalues": {},
                    "id": 523,
                    "shortdesc": "Social Security No",
                    "status": true,
                    "updatedate": 1635954277,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954277,
                "id": 523,
                "status": true,
                "text": "AD009",
                "updatedate": 1635954277,
                "updatedby": ""
            }
        ],
        "Race": [
            {
                "Type": "Race",
                "_id": "CodeableConceptMaster/10135",
                "_key": "10135",
                "_rev": "_e8UT9I6--2",
                "activestatus": true,
                "coding": {
                    "_key": "10136",
                    "_id": "CodingMaster/10136",
                    "_rev": "_e8UTuZO--2",
                    "Type": "Race",
                    "activestatus": true,
                    "code": "RC002",
                    "createdby": "",
                    "createddate": 1635941207,
                    "display": "Americans[o]",
                    "gmconfigvalues": {
                        "_key": "10136"
                    },
                    "id": 136,
                    "shortdesc": "Americans[o]",
                    "status": true,
                    "updatedate": 1652253634,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941207,
                "id": 136,
                "status": true,
                "text": "RC002",
                "updatedate": 1635941207,
                "updatedby": ""
            },
            {
                "Type": "Race",
                "_id": "CodeableConceptMaster/11386",
                "_key": "11386",
                "_rev": "_gefvyMi---",
                "activestatus": true,
                "coding": {
                    "_key": "11415",
                    "_id": "CodingMaster/11415",
                    "_rev": "_gefvyMe---",
                    "code": "RC007",
                    "display": "Bidayuh",
                    "id": 1174,
                    "Type": "Race",
                    "shortdesc": "Bidayuh",
                    "gmconfigvalues": {},
                    "status": true,
                    "createdby": "",
                    "createddate": 1692358791,
                    "updatedby": "",
                    "updatedate": 1692358791,
                    "activestatus": true
                },
                "createdby": "",
                "createddate": 1692358791,
                "id": 1160,
                "status": true,
                "text": "RC007",
                "updatedate": 1692358791,
                "updatedby": ""
            },
            {
                "Type": "Race",
                "_id": "CodeableConceptMaster/11388",
                "_key": "11388",
                "_rev": "_gefwk8q--C",
                "activestatus": true,
                "coding": {
                    "_key": "11417",
                    "_id": "CodingMaster/11417",
                    "_rev": "_gefwk8q---",
                    "code": "RC009",
                    "display": "Chinese ",
                    "id": 1176,
                    "Type": "Race",
                    "shortdesc": "Chinese ",
                    "gmconfigvalues": {},
                    "status": true,
                    "createdby": "",
                    "createddate": 1692358843,
                    "updatedby": "",
                    "updatedate": 1692358843,
                    "activestatus": true
                },
                "createdby": "",
                "createddate": 1692358843,
                "id": 1162,
                "status": true,
                "text": "RC009",
                "updatedate": 1692358843,
                "updatedby": ""
            },
            {
                "Type": "Race",
                "_id": "CodeableConceptMaster/10139",
                "_key": "10139",
                "_rev": "_e8UT9Ii--j",
                "activestatus": true,
                "coding": {
                    "_key": "10140",
                    "_id": "CodingMaster/10140",
                    "_rev": "_e8UTuay--K",
                    "Type": "Race",
                    "activestatus": true,
                    "code": "RC004",
                    "createdby": "",
                    "createddate": 1635941243,
                    "display": "Europeans[i]",
                    "gmconfigvalues": {
                        "_key": "10140"
                    },
                    "id": 140,
                    "shortdesc": "Europeans[i]",
                    "status": true,
                    "updatedate": 1652253576,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941243,
                "id": 140,
                "status": true,
                "text": "RC004",
                "updatedate": 1635941243,
                "updatedby": ""
            },
            {
                "Type": "Race",
                "_id": "CodeableConceptMaster/11387",
                "_key": "11387",
                "_rev": "_gefwOtq--A",
                "activestatus": true,
                "coding": {
                    "_key": "11416",
                    "_id": "CodingMaster/11416",
                    "_rev": "_gefwOtm---",
                    "code": "RC008",
                    "display": "Iban",
                    "id": 1175,
                    "Type": "Race",
                    "shortdesc": "Iban",
                    "gmconfigvalues": {},
                    "status": true,
                    "createdby": "",
                    "createddate": 1692358820,
                    "updatedby": "",
                    "updatedate": 1692358820,
                    "activestatus": true
                },
                "createdby": "",
                "createddate": 1692358820,
                "id": 1161,
                "status": true,
                "text": "RC008",
                "updatedate": 1692358820,
                "updatedby": ""
            },
            {
                "Type": "Race",
                "_id": "CodeableConceptMaster/10140",
                "_key": "10140",
                "_rev": "_e8UT9I6-_Q",
                "activestatus": true,
                "coding": {
                    "_key": "10141",
                    "_id": "CodingMaster/10141",
                    "_rev": "_e8UTuZO--8",
                    "Type": "Race",
                    "activestatus": true,
                    "code": "RC005",
                    "createdby": "",
                    "createddate": 1635941261,
                    "display": "Indians",
                    "gmconfigvalues": {
                        "_key": "10141"
                    },
                    "id": 141,
                    "shortdesc": "Indians",
                    "status": true,
                    "updatedate": 1652252952,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941261,
                "id": 141,
                "status": true,
                "text": "RC005",
                "updatedate": 1635941261,
                "updatedby": ""
            },
            {
                "Type": "Race",
                "_id": "CodeableConceptMaster/11389",
                "_key": "11389",
                "_rev": "_gefxHQm--C",
                "activestatus": true,
                "coding": {
                    "_key": "11418",
                    "_id": "CodingMaster/11418",
                    "_rev": "_gefxHQm---",
                    "code": "RC010",
                    "display": "Kadazan",
                    "id": 1177,
                    "Type": "Race",
                    "shortdesc": "Kadazan",
                    "gmconfigvalues": {},
                    "status": true,
                    "createdby": "",
                    "createddate": 1692358878,
                    "updatedby": "",
                    "updatedate": 1692358878,
                    "activestatus": true
                },
                "createdby": "",
                "createddate": 1692358878,
                "id": 1163,
                "status": true,
                "text": "RC010",
                "updatedate": 1692358878,
                "updatedby": ""
            },
            {
                "Type": "Race",
                "_id": "CodeableConceptMaster/10141",
                "_key": "10141",
                "_rev": "_e8UT9Iy--B",
                "activestatus": true,
                "coding": {
                    "_key": "10142",
                    "_id": "CodingMaster/10142",
                    "_rev": "_e8UTuZO--6",
                    "Type": "Race",
                    "activestatus": true,
                    "code": "RC006",
                    "createdby": "",
                    "createddate": 1635941281,
                    "display": "Malaysians",
                    "gmconfigvalues": {
                        "_key": "10142"
                    },
                    "id": 142,
                    "shortdesc": "Malaysians",
                    "status": true,
                    "updatedate": 1652253539,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941281,
                "id": 142,
                "status": true,
                "text": "RC006",
                "updatedate": 1635941281,
                "updatedby": ""
            },
            {
                "Type": "Race",
                "_id": "CodeableConceptMaster/10138",
                "_key": "10138",
                "_rev": "_e8UT9Iy--p",
                "activestatus": true,
                "coding": {
                    "_key": "10139",
                    "_id": "CodingMaster/10139",
                    "_rev": "_e8UTuZO--4",
                    "Type": "Race",
                    "activestatus": true,
                    "code": "RC003",
                    "createdby": "",
                    "createddate": 1635941225,
                    "display": "SaudisSaudi Arabians",
                    "gmconfigvalues": {
                        "_key": "10139"
                    },
                    "id": 139,
                    "shortdesc": "SaudisSaudi Arabians",
                    "status": true,
                    "updatedate": 1652253613,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941225,
                "id": 139,
                "status": true,
                "text": "RC003",
                "updatedate": 1635941225,
                "updatedby": ""
            },
            {
                "Type": "Race",
                "_id": "CodeableConceptMaster/10134",
                "_key": "10134",
                "_rev": "_e8UT9Ia--M",
                "activestatus": true,
                "coding": {
                    "_key": "10135",
                    "_id": "CodingMaster/10135",
                    "_rev": "_e8UTuZO--0",
                    "Type": "Race",
                    "activestatus": true,
                    "code": "RC001",
                    "createdby": "",
                    "createddate": 1635941188,
                    "display": "South Africans",
                    "gmconfigvalues": {
                        "_key": "10135"
                    },
                    "id": 135,
                    "shortdesc": "South Africans",
                    "status": true,
                    "updatedate": 1652253655,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941188,
                "id": 135,
                "status": true,
                "text": "RC001",
                "updatedate": 1635941188,
                "updatedby": ""
            }
        ],
        "LANGUAGE": [
            {
                "Type": "LANGUAGE",
                "_id": "CodeableConceptMaster/10543",
                "_key": "10543",
                "_rev": "_e8UT9JS--2",
                "activestatus": true,
                "coding": {
                    "_key": "10544",
                    "_id": "CodingMaster/10544",
                    "_rev": "_e8UTuay--G",
                    "Type": "LANGUAGE",
                    "activestatus": true,
                    "code": "LG001",
                    "createdby": "",
                    "createddate": 1635954883,
                    "display": "Arabic",
                    "gmconfigvalues": {},
                    "id": 544,
                    "shortdesc": "Arabic",
                    "status": true,
                    "updatedate": 1635954883,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954883,
                "id": 544,
                "status": true,
                "text": "LG001",
                "updatedate": 1635954883,
                "updatedby": ""
            },
            {
                "Type": "LANGUAGE",
                "_id": "CodeableConceptMaster/10544",
                "_key": "10544",
                "_rev": "_e8UT9I---c",
                "activestatus": true,
                "coding": {
                    "_key": "10545",
                    "_id": "CodingMaster/10545",
                    "_rev": "_e8UTuZq-_S",
                    "Type": "LANGUAGE",
                    "activestatus": true,
                    "code": "LG002",
                    "createdby": "",
                    "createddate": 1635954909,
                    "display": "English",
                    "gmconfigvalues": {},
                    "id": 545,
                    "shortdesc": "English",
                    "status": true,
                    "updatedate": 1635954909,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954909,
                "id": 545,
                "status": true,
                "text": "LG002",
                "updatedate": 1635954909,
                "updatedby": ""
            },
            {
                "Type": "LANGUAGE",
                "_id": "CodeableConceptMaster/10545",
                "_key": "10545",
                "_rev": "_e8UT9IK--y",
                "activestatus": true,
                "coding": {
                    "_key": "10546",
                    "_id": "CodingMaster/10546",
                    "_rev": "_e8UTuae--k",
                    "Type": "LANGUAGE",
                    "activestatus": true,
                    "code": "LG003",
                    "createdby": "",
                    "createddate": 1635954920,
                    "display": "French",
                    "gmconfigvalues": {},
                    "id": 546,
                    "shortdesc": "French",
                    "status": true,
                    "updatedate": 1635954920,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954920,
                "id": 546,
                "status": true,
                "text": "LG003",
                "updatedate": 1635954920,
                "updatedby": ""
            },
            {
                "Type": "LANGUAGE",
                "_id": "CodeableConceptMaster/10546",
                "_key": "10546",
                "_rev": "_e8UT9JC--o",
                "activestatus": true,
                "coding": {
                    "_key": "10547",
                    "_id": "CodingMaster/10547",
                    "_rev": "_e8UTuae-_-",
                    "Type": "LANGUAGE",
                    "activestatus": true,
                    "code": "LG004",
                    "createdby": "",
                    "createddate": 1635954928,
                    "display": "German",
                    "gmconfigvalues": {},
                    "id": 547,
                    "shortdesc": "German",
                    "status": true,
                    "updatedate": 1635954928,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954928,
                "id": 547,
                "status": true,
                "text": "LG004",
                "updatedate": 1635954928,
                "updatedby": ""
            },
            {
                "Type": "LANGUAGE",
                "_id": "CodeableConceptMaster/10547",
                "_key": "10547",
                "_rev": "_e8UT9JG-_C",
                "activestatus": true,
                "coding": {
                    "_key": "10548",
                    "_id": "CodingMaster/10548",
                    "_rev": "_e8UTuae--8",
                    "Type": "LANGUAGE",
                    "activestatus": true,
                    "code": "LG005",
                    "createdby": "",
                    "createddate": 1635954998,
                    "display": "Hindi",
                    "gmconfigvalues": {},
                    "id": 548,
                    "shortdesc": "Hindi",
                    "status": true,
                    "updatedate": 1635954998,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954998,
                "id": 548,
                "status": true,
                "text": "LG005",
                "updatedate": 1635954998,
                "updatedby": ""
            },
            {
                "Type": "LANGUAGE",
                "_id": "CodeableConceptMaster/10548",
                "_key": "10548",
                "_rev": "_e8UT9JG--6",
                "activestatus": true,
                "coding": {
                    "_key": "10549",
                    "_id": "CodingMaster/10549",
                    "_rev": "_e8UTuaS--y",
                    "Type": "LANGUAGE",
                    "activestatus": true,
                    "code": "LG006",
                    "createdby": "",
                    "createddate": 1635955022,
                    "display": "Kannada",
                    "gmconfigvalues": {},
                    "id": 549,
                    "shortdesc": "Kannada",
                    "status": true,
                    "updatedate": 1635955022,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635955022,
                "id": 549,
                "status": true,
                "text": "LG006",
                "updatedate": 1635955022,
                "updatedby": ""
            },
            {
                "Type": "LANGUAGE",
                "_id": "CodeableConceptMaster/10549",
                "_key": "10549",
                "_rev": "_e8UT9I2--E",
                "activestatus": true,
                "coding": {
                    "_key": "10550",
                    "_id": "CodingMaster/10550",
                    "_rev": "_e8UTuaO-_C",
                    "Type": "LANGUAGE",
                    "activestatus": true,
                    "code": "LG007",
                    "createdby": "",
                    "createddate": 1635955036,
                    "display": "Malay",
                    "gmconfigvalues": {},
                    "id": 550,
                    "shortdesc": "Malay",
                    "status": true,
                    "updatedate": 1635955036,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635955036,
                "id": 550,
                "status": true,
                "text": "LG007",
                "updatedate": 1635955036,
                "updatedby": ""
            },
            {
                "Type": "LANGUAGE",
                "_id": "CodeableConceptMaster/10550",
                "_key": "10550",
                "_rev": "_e8UT9I2--o",
                "activestatus": true,
                "coding": {
                    "_key": "10551",
                    "_id": "CodingMaster/10551",
                    "_rev": "_e8UTuai--H",
                    "Type": "LANGUAGE",
                    "activestatus": true,
                    "code": "LG008",
                    "createdby": "",
                    "createddate": 1635955045,
                    "display": "Malayalam",
                    "gmconfigvalues": {},
                    "id": 551,
                    "shortdesc": "Malayalam",
                    "status": true,
                    "updatedate": 1635955045,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635955045,
                "id": 551,
                "status": true,
                "text": "LG008",
                "updatedate": 1635955045,
                "updatedby": ""
            },
            {
                "Type": "LANGUAGE",
                "_id": "CodeableConceptMaster/10551",
                "_key": "10551",
                "_rev": "_e8UT9JK--I",
                "activestatus": true,
                "coding": {
                    "_key": "10552",
                    "_id": "CodingMaster/10552",
                    "_rev": "_e8UTuZm--r",
                    "Type": "LANGUAGE",
                    "activestatus": true,
                    "code": "LG009",
                    "createdby": "",
                    "createddate": 1635955061,
                    "display": "Mandarin",
                    "gmconfigvalues": {},
                    "id": 552,
                    "shortdesc": "Mandarin",
                    "status": true,
                    "updatedate": 1635955061,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635955061,
                "id": 552,
                "status": true,
                "text": "LG009",
                "updatedate": 1635955061,
                "updatedby": ""
            },
            {
                "Type": "LANGUAGE",
                "_id": "CodeableConceptMaster/10552",
                "_key": "10552",
                "_rev": "_e8UT9JG--O",
                "activestatus": true,
                "coding": {
                    "_key": "10553",
                    "_id": "CodingMaster/10553",
                    "_rev": "_e8UTuZy--a",
                    "Type": "LANGUAGE",
                    "activestatus": true,
                    "code": "LG010",
                    "createdby": "",
                    "createddate": 1635955081,
                    "display": "Spanish",
                    "gmconfigvalues": {},
                    "id": 553,
                    "shortdesc": "Spanish",
                    "status": true,
                    "updatedate": 1635955081,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635955081,
                "id": 553,
                "status": true,
                "text": "LG010",
                "updatedate": 1635955081,
                "updatedby": ""
            },
            {
                "Type": "LANGUAGE",
                "_id": "CodeableConceptMaster/10553",
                "_key": "10553",
                "_rev": "_e8UT9Iu--C",
                "activestatus": true,
                "coding": {
                    "_key": "10554",
                    "_id": "CodingMaster/10554",
                    "_rev": "_e8UTuZ6--i",
                    "Type": "LANGUAGE",
                    "activestatus": true,
                    "code": "LG011",
                    "createdby": "",
                    "createddate": 1635955094,
                    "display": "Tamil",
                    "gmconfigvalues": {},
                    "id": 554,
                    "shortdesc": "Tamil",
                    "status": true,
                    "updatedate": 1635955094,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635955094,
                "id": 554,
                "status": true,
                "text": "LG011",
                "updatedate": 1635955094,
                "updatedby": ""
            },
            {
                "Type": "LANGUAGE",
                "_id": "CodeableConceptMaster/10554",
                "_key": "10554",
                "_rev": "_e8UT9Im--V",
                "activestatus": true,
                "coding": {
                    "_key": "10555",
                    "_id": "CodingMaster/10555",
                    "_rev": "_e8UTuZq-_C",
                    "Type": "LANGUAGE",
                    "activestatus": true,
                    "code": "LG012",
                    "createdby": "",
                    "createddate": 1635955113,
                    "display": "Telugu",
                    "gmconfigvalues": {},
                    "id": 555,
                    "shortdesc": "Telugu",
                    "status": true,
                    "updatedate": 1635955113,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635955113,
                "id": 555,
                "status": true,
                "text": "LG012",
                "updatedate": 1635955113,
                "updatedby": ""
            }
        ],
        "RELATIONSHIP": [
            {
                "Type": "RELATIONSHIP",
                "_id": "CodeableConceptMaster/10524",
                "_key": "10524",
                "_rev": "_e8UT9JK--M",
                "activestatus": true,
                "coding": {
                    "_key": "10525",
                    "_id": "CodingMaster/10525",
                    "_rev": "_e8UTuaS--S",
                    "Type": "RELATIONSHIP",
                    "activestatus": true,
                    "code": "RE001",
                    "createdby": "",
                    "createddate": 1635954343,
                    "display": "Aunt",
                    "gmconfigvalues": {},
                    "id": 525,
                    "shortdesc": "Aunt",
                    "status": true,
                    "updatedate": 1635954343,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954343,
                "id": 525,
                "status": true,
                "text": "RE001",
                "updatedate": 1635954343,
                "updatedby": ""
            },
            {
                "Type": "RELATIONSHIP",
                "_id": "CodeableConceptMaster/10525",
                "_key": "10525",
                "_rev": "_e8UT9Iy-_P",
                "activestatus": true,
                "coding": {
                    "_key": "10526",
                    "_id": "CodingMaster/10526",
                    "_rev": "_e8UTuaW--g",
                    "Type": "RELATIONSHIP",
                    "activestatus": true,
                    "code": "RE002",
                    "createdby": "",
                    "createddate": 1635954415,
                    "display": "Brother",
                    "gmconfigvalues": {},
                    "id": 526,
                    "shortdesc": "Brother",
                    "status": true,
                    "updatedate": 1635954415,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954415,
                "id": 526,
                "status": true,
                "text": "RE002",
                "updatedate": 1635954415,
                "updatedby": ""
            },
            {
                "Type": "RELATIONSHIP",
                "_id": "CodeableConceptMaster/10526",
                "_key": "10526",
                "_rev": "_e8UT9Iq--8",
                "activestatus": true,
                "coding": {
                    "_key": "10527",
                    "_id": "CodingMaster/10527",
                    "_rev": "_e8UTuai--_",
                    "Type": "RELATIONSHIP",
                    "activestatus": true,
                    "code": "RE003",
                    "createdby": "",
                    "createddate": 1635954424,
                    "display": "Daughter",
                    "gmconfigvalues": {},
                    "id": 527,
                    "shortdesc": "Daughter",
                    "status": true,
                    "updatedate": 1635954424,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954424,
                "id": 527,
                "status": true,
                "text": "RE003",
                "updatedate": 1635954424,
                "updatedby": ""
            },
            {
                "Type": "RELATIONSHIP",
                "_id": "CodeableConceptMaster/10527",
                "_key": "10527",
                "_rev": "_e8UT9Ii--Z",
                "activestatus": true,
                "coding": {
                    "_key": "10528",
                    "_id": "CodingMaster/10528",
                    "_rev": "_e8UTuaW-_A",
                    "Type": "RELATIONSHIP",
                    "activestatus": true,
                    "code": "RE004",
                    "createdby": "",
                    "createddate": 1635954434,
                    "display": "Father",
                    "gmconfigvalues": {},
                    "id": 528,
                    "shortdesc": "Father",
                    "status": true,
                    "updatedate": 1635954434,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954434,
                "id": 528,
                "status": true,
                "text": "RE004",
                "updatedate": 1635954434,
                "updatedby": ""
            },
            {
                "Type": "RELATIONSHIP",
                "_id": "CodeableConceptMaster/10528",
                "_key": "10528",
                "_rev": "_e8UT9JC--8",
                "activestatus": true,
                "coding": {
                    "_key": "10529",
                    "_id": "CodingMaster/10529",
                    "_rev": "_e8UTuae-_c",
                    "Type": "RELATIONSHIP",
                    "activestatus": true,
                    "code": "RE005",
                    "createdby": "",
                    "createddate": 1635954443,
                    "display": "Mother",
                    "gmconfigvalues": {},
                    "id": 529,
                    "shortdesc": "Mother",
                    "status": true,
                    "updatedate": 1635954443,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954443,
                "id": 529,
                "status": true,
                "text": "RE005",
                "updatedate": 1635954443,
                "updatedby": ""
            },
            {
                "Type": "RELATIONSHIP",
                "_id": "CodeableConceptMaster/11190",
                "_key": "11190",
                "_rev": "_e8UT9Ja--S",
                "activestatus": true,
                "coding": {
                    "_key": "11218",
                    "_id": "CodingMaster/11218",
                    "_rev": "_e8UTua2-_I",
                    "Type": "RELATIONSHIP",
                    "activestatus": true,
                    "code": "RE011",
                    "collection_name": "CodingMaster",
                    "createdby": "",
                    "createddate": 1656496507,
                    "display": "Self",
                    "gmconfigvalues": {},
                    "id": 1204,
                    "shortdesc": "Self",
                    "status": true,
                    "updatedate": 1656496507,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1656496381,
                "id": 1183,
                "status": true,
                "text": "RE011",
                "updatedate": 1656496507,
                "updatedby": ""
            },
            {
                "Type": "RELATIONSHIP",
                "_id": "CodeableConceptMaster/10530",
                "_key": "10530",
                "_rev": "_e8UT9IG--Y",
                "activestatus": true,
                "coding": {
                    "_key": "10531",
                    "_id": "CodingMaster/10531",
                    "_rev": "_e8UTuZa-_A",
                    "Type": "RELATIONSHIP",
                    "activestatus": true,
                    "code": "RE007",
                    "collection_name": "CodingMaster",
                    "createdby": "",
                    "createddate": 1649243268,
                    "display": "Sister",
                    "gmconfigvalues": {},
                    "id": 531,
                    "shortdesc": "Sister",
                    "status": true,
                    "updatedate": 1649243268,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635954468,
                "id": 531,
                "status": true,
                "text": "RE007",
                "updatedate": 1649243268,
                "updatedby": ""
            }
        ],
        "Religion": [
            {
                "Type": "Religion",
                "_id": "CodeableConceptMaster/10116",
                "_key": "10116",
                "_rev": "_e8UT9Iq--Q",
                "activestatus": true,
                "coding": {
                    "_key": "10117",
                    "_id": "CodingMaster/10117",
                    "_rev": "_e8UTuae--q",
                    "Type": "RELIGION",
                    "activestatus": true,
                    "code": "RL001",
                    "createdby": "",
                    "createddate": 1635940772,
                    "display": "Buddhist",
                    "gmconfigvalues": {},
                    "id": 117,
                    "shortdesc": "Buddhist",
                    "status": true,
                    "updatedate": 1635940772,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940772,
                "id": 117,
                "status": true,
                "text": "RL001",
                "updatedate": 1635940772,
                "updatedby": ""
            },
            {
                "Type": "Religion",
                "_id": "CodeableConceptMaster/10123",
                "_key": "10123",
                "_rev": "_e8UT9JG--S",
                "activestatus": true,
                "coding": {
                    "_key": "10124",
                    "_id": "CodingMaster/10124",
                    "_rev": "_e8UTuZy-_C",
                    "Type": "RELIGION",
                    "activestatus": true,
                    "code": "RL002",
                    "createdby": "",
                    "createddate": 1635940798,
                    "display": "Christian",
                    "gmconfigvalues": {},
                    "id": 124,
                    "shortdesc": "Christian",
                    "status": true,
                    "updatedate": 1635940798,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940798,
                "id": 124,
                "status": true,
                "text": "RL002",
                "updatedate": 1635940798,
                "updatedby": ""
            },
            {
                "Type": "Religion",
                "_id": "CodeableConceptMaster/10124",
                "_key": "10124",
                "_rev": "_e8UT9JW--n",
                "activestatus": true,
                "coding": {
                    "_key": "10125",
                    "_id": "CodingMaster/10125",
                    "_rev": "_e8UTuZS--a",
                    "Type": "RELIGION",
                    "activestatus": true,
                    "code": "RL003",
                    "createdby": "",
                    "createddate": 1635940817,
                    "display": "Hindu",
                    "gmconfigvalues": {},
                    "id": 125,
                    "shortdesc": "Hindu",
                    "status": true,
                    "updatedate": 1635940817,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940817,
                "id": 125,
                "status": true,
                "text": "RL003",
                "updatedate": 1635940817,
                "updatedby": ""
            },
            {
                "Type": "Religion",
                "_id": "CodeableConceptMaster/10125",
                "_key": "10125",
                "_rev": "_e8UT9Iy--3",
                "activestatus": true,
                "coding": {
                    "_key": "10126",
                    "_id": "CodingMaster/10126",
                    "_rev": "_e8UTuZS--W",
                    "Type": "RELIGION",
                    "activestatus": true,
                    "code": "RL004",
                    "createdby": "",
                    "createddate": 1635940841,
                    "display": "Jain",
                    "gmconfigvalues": {},
                    "id": 126,
                    "shortdesc": "Jain",
                    "status": true,
                    "updatedate": 1635940841,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940841,
                "id": 126,
                "status": true,
                "text": "RL004",
                "updatedate": 1635940841,
                "updatedby": ""
            },
            {
                "Type": "Religion",
                "_id": "CodeableConceptMaster/10126",
                "_key": "10126",
                "_rev": "_e8UT9I2--4",
                "activestatus": true,
                "coding": {
                    "_key": "10127",
                    "_id": "CodingMaster/10127",
                    "_rev": "_e8UTuZS-_G",
                    "Type": "RELIGION",
                    "activestatus": true,
                    "code": "RL005",
                    "createdby": "",
                    "createddate": 1635940857,
                    "display": "Jew",
                    "gmconfigvalues": {},
                    "id": 127,
                    "shortdesc": "Jew",
                    "status": true,
                    "updatedate": 1635940857,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940857,
                "id": 127,
                "status": true,
                "text": "RL005",
                "updatedate": 1635940857,
                "updatedby": ""
            },
            {
                "Type": "Religion",
                "_id": "CodeableConceptMaster/10127",
                "_key": "10127",
                "_rev": "_e8UT9I6--I",
                "activestatus": true,
                "coding": {
                    "_key": "10128",
                    "_id": "CodingMaster/10128",
                    "_rev": "_e8UTuZS--U",
                    "Type": "RELIGION",
                    "activestatus": true,
                    "code": "RL006",
                    "createdby": "",
                    "createddate": 1635940875,
                    "display": "Muslim",
                    "gmconfigvalues": {},
                    "id": 128,
                    "shortdesc": "Muslim",
                    "status": true,
                    "updatedate": 1635940875,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940875,
                "id": 128,
                "status": true,
                "text": "RL006",
                "updatedate": 1635940875,
                "updatedby": ""
            },
            {
                "Type": "Religion",
                "_id": "CodeableConceptMaster/10128",
                "_key": "10128",
                "_rev": "_e8UT9Iq--k",
                "activestatus": true,
                "coding": {
                    "_key": "10129",
                    "_id": "CodingMaster/10129",
                    "_rev": "_e8UTuaq---",
                    "Type": "RELIGION",
                    "activestatus": true,
                    "code": "RL007",
                    "createdby": "",
                    "createddate": 1635940895,
                    "display": "Others",
                    "gmconfigvalues": {},
                    "id": 129,
                    "shortdesc": "Others",
                    "status": true,
                    "updatedate": 1635940895,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940895,
                "id": 129,
                "status": true,
                "text": "RL007",
                "updatedate": 1635940895,
                "updatedby": ""
            },
            {
                "Type": "Religion",
                "_id": "CodeableConceptMaster/10129",
                "_key": "10129",
                "_rev": "_e8UT9JO--Y",
                "activestatus": true,
                "coding": {
                    "_key": "10130",
                    "_id": "CodingMaster/10130",
                    "_rev": "_e8UTuZS--Y",
                    "Type": "RELIGION",
                    "activestatus": true,
                    "code": "RL008",
                    "createdby": "",
                    "createddate": 1635940913,
                    "display": "Sikh",
                    "gmconfigvalues": {},
                    "id": 130,
                    "shortdesc": "Sikh",
                    "status": true,
                    "updatedate": 1635940913,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635940913,
                "id": 130,
                "status": true,
                "text": "RL008",
                "updatedate": 1635940913,
                "updatedby": ""
            }
        ],
        "Ethnicity": [
            {
                "Type": "Ethnicity",
                "_id": "CodeableConceptMaster/11309",
                "_key": "11309",
                "_rev": "_e8UT9Jm--a",
                "activestatus": true,
                "coding": {
                    "_key": "11336",
                    "_id": "CodingMaster/11336",
                    "_rev": "_e8UTubC--0",
                    "Type": "Ethnicity",
                    "activestatus": true,
                    "code": "ET007",
                    "createdby": "",
                    "createddate": 1663045457,
                    "display": "Chinese",
                    "gmconfigvalues": {},
                    "id": 1323,
                    "shortdesc": "Chinese",
                    "status": true,
                    "updatedate": 1663045457,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1663045457,
                "id": 1302,
                "status": true,
                "text": "ET007",
                "updatedate": 1663045457,
                "updatedby": ""
            },
            {
                "Type": "Ethnicity",
                "_id": "CodeableConceptMaster/10132",
                "_key": "10132",
                "_rev": "_e8UT9J---O",
                "activestatus": true,
                "coding": {
                    "_key": "10133",
                    "_id": "CodingMaster/10133",
                    "_rev": "_e8UTuau--U",
                    "Type": "Ethnicity",
                    "activestatus": true,
                    "code": "ET003",
                    "createdby": "",
                    "createddate": 1635941128,
                    "display": "Dutch",
                    "gmconfigvalues": {},
                    "id": 133,
                    "shortdesc": "Dutch",
                    "status": true,
                    "updatedate": 1635941128,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941128,
                "id": 133,
                "status": true,
                "text": "ET003",
                "updatedate": 1635941128,
                "updatedby": ""
            },
            {
                "Type": "Ethnicity",
                "_id": "CodeableConceptMaster/10130",
                "_key": "10130",
                "_rev": "_e8UT9I6--o",
                "activestatus": true,
                "coding": {
                    "_key": "10131",
                    "_id": "CodingMaster/10131",
                    "_rev": "_e8UTuZu--4",
                    "Type": "Ethnicity",
                    "activestatus": true,
                    "code": "ET001",
                    "createdby": "",
                    "createddate": 1635941081,
                    "display": "English",
                    "gmconfigvalues": {},
                    "id": 131,
                    "shortdesc": "English",
                    "status": true,
                    "updatedate": 1635941081,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941081,
                "id": 131,
                "status": true,
                "text": "ET001",
                "updatedate": 1635941081,
                "updatedby": ""
            },
            {
                "Type": "Ethnicity",
                "_id": "CodeableConceptMaster/11308",
                "_key": "11308",
                "_rev": "_e8UT9Jm--Y",
                "activestatus": true,
                "coding": {
                    "_key": "11335",
                    "_id": "CodingMaster/11335",
                    "_rev": "_e8UTubC--y",
                    "Type": "Ethnicity",
                    "activestatus": true,
                    "code": "ET006",
                    "createdby": "",
                    "createddate": 1663045430,
                    "display": "Indian",
                    "gmconfigvalues": {},
                    "id": 1322,
                    "shortdesc": "Indian",
                    "status": true,
                    "updatedate": 1663045430,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1663045430,
                "id": 1301,
                "status": true,
                "text": "ET006",
                "updatedate": 1663045430,
                "updatedby": ""
            },
            {
                "Type": "Ethnicity",
                "_id": "CodeableConceptMaster/11307",
                "_key": "11307",
                "_rev": "_e8UT9Jm--W",
                "activestatus": true,
                "coding": {
                    "_key": "11334",
                    "_id": "CodingMaster/11334",
                    "_rev": "_e8UTubC--w",
                    "Type": "Ethnicity",
                    "activestatus": true,
                    "code": "ET005",
                    "createdby": "",
                    "createddate": 1663045415,
                    "display": "Malay",
                    "gmconfigvalues": {},
                    "id": 1321,
                    "shortdesc": "Malay",
                    "status": true,
                    "updatedate": 1663045415,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1663045415,
                "id": 1300,
                "status": true,
                "text": "ET005",
                "updatedate": 1663045415,
                "updatedby": ""
            },
            {
                "Type": "Ethnicity",
                "_id": "CodeableConceptMaster/11310",
                "_key": "11310",
                "_rev": "_e8UT9Jm--c",
                "activestatus": true,
                "coding": {
                    "_key": "11337",
                    "_id": "CodingMaster/11337",
                    "_rev": "_e8UTubC--2",
                    "Type": "Ethnicity",
                    "activestatus": true,
                    "code": "ET008",
                    "createdby": "",
                    "createddate": 1663045502,
                    "display": "Melanau",
                    "gmconfigvalues": {},
                    "id": 1324,
                    "shortdesc": "Melanau",
                    "status": true,
                    "updatedate": 1663045502,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1663045502,
                "id": 1303,
                "status": true,
                "text": "ET008",
                "updatedate": 1663045502,
                "updatedby": ""
            },
            {
                "Type": "Ethnicity",
                "_id": "CodeableConceptMaster/10133",
                "_key": "10133",
                "_rev": "_e8UT9JW--_",
                "activestatus": true,
                "coding": {
                    "_key": "10134",
                    "_id": "CodingMaster/10134",
                    "_rev": "_e8UTuay--6",
                    "Type": "Ethnicity",
                    "activestatus": true,
                    "code": "ET004",
                    "createdby": "",
                    "createddate": 1635941146,
                    "display": "Portuguese",
                    "gmconfigvalues": {},
                    "id": 134,
                    "shortdesc": "Portuguese",
                    "status": true,
                    "updatedate": 1635941146,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941146,
                "id": 134,
                "status": true,
                "text": "ET004",
                "updatedate": 1635941146,
                "updatedby": ""
            },
            {
                "Type": "Ethnicity",
                "_id": "CodeableConceptMaster/11312",
                "_key": "11312",
                "_rev": "_e8UT9Jm--g",
                "activestatus": true,
                "coding": {
                    "_key": "11339",
                    "_id": "CodingMaster/11339",
                    "_rev": "_e8UTubC--6",
                    "Type": "Ethnicity",
                    "activestatus": true,
                    "code": "ET010",
                    "createdby": "",
                    "createddate": 1663045583,
                    "display": "Punjabi",
                    "gmconfigvalues": {},
                    "id": 1326,
                    "shortdesc": "Punjabi",
                    "status": true,
                    "updatedate": 1663045583,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1663045583,
                "id": 1305,
                "status": true,
                "text": "ET010",
                "updatedate": 1663045583,
                "updatedby": ""
            },
            {
                "Type": "Ethnicity",
                "_id": "CodeableConceptMaster/11311",
                "_key": "11311",
                "_rev": "_e8UT9Jm--e",
                "activestatus": true,
                "coding": {
                    "_key": "11338",
                    "_id": "CodingMaster/11338",
                    "_rev": "_e8UTubC--4",
                    "Type": "Ethnicity",
                    "activestatus": true,
                    "code": "ET009",
                    "createdby": "",
                    "createddate": 1663045535,
                    "display": "Sarawak",
                    "gmconfigvalues": {},
                    "id": 1325,
                    "shortdesc": "Sarawak",
                    "status": true,
                    "updatedate": 1663045535,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1663045535,
                "id": 1304,
                "status": true,
                "text": "ET009",
                "updatedate": 1663045535,
                "updatedby": ""
            },
            {
                "Type": "Ethnicity",
                "_id": "CodeableConceptMaster/11313",
                "_key": "11313",
                "_rev": "_e8UT9Jm--i",
                "activestatus": true,
                "coding": {
                    "_key": "11340",
                    "_id": "CodingMaster/11340",
                    "_rev": "_e8UTubC--8",
                    "Type": "Ethnicity",
                    "activestatus": true,
                    "code": "ET011",
                    "createdby": "",
                    "createddate": 1663045603,
                    "display": "Sikh",
                    "gmconfigvalues": {},
                    "id": 1327,
                    "shortdesc": "Sikh",
                    "status": true,
                    "updatedate": 1663045603,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1663045603,
                "id": 1306,
                "status": true,
                "text": "ET011",
                "updatedate": 1663045603,
                "updatedby": ""
            },
            {
                "Type": "Ethnicity",
                "_id": "CodeableConceptMaster/10131",
                "_key": "10131",
                "_rev": "_e8UT9IK-_E",
                "activestatus": true,
                "coding": {
                    "_key": "10132",
                    "_id": "CodingMaster/10132",
                    "_rev": "_e8UTuaW-_C",
                    "Type": "Ethnicity",
                    "activestatus": true,
                    "code": "ET002",
                    "createdby": "",
                    "createddate": 1635941099,
                    "display": "Spanish",
                    "gmconfigvalues": {},
                    "id": 132,
                    "shortdesc": "Spanish",
                    "status": true,
                    "updatedate": 1635941099,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941099,
                "id": 132,
                "status": true,
                "text": "ET002",
                "updatedate": 1635941099,
                "updatedby": ""
            }
        ],
        "Patient Type": [
            {
                "Type": "Patient Type",
                "_id": "CodeableConceptMaster/10142",
                "_key": "10142",
                "_rev": "_e8UT9J---m",
                "activestatus": true,
                "coding": {
                    "_key": "10143",
                    "_id": "CodingMaster/10143",
                    "_rev": "_e8UTuZy--u",
                    "Type": "Patient Type",
                    "activestatus": true,
                    "code": "PT001",
                    "createdby": "",
                    "createddate": 1635941361,
                    "display": "Civilian",
                    "gmconfigvalues": {},
                    "id": 143,
                    "shortdesc": "Civilian",
                    "status": true,
                    "updatedate": 1635941361,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941361,
                "id": 143,
                "status": true,
                "text": "PT001",
                "updatedate": 1635941361,
                "updatedby": ""
            },
            {
                "Type": "Patient Type",
                "_id": "CodeableConceptMaster/10144",
                "_key": "10144",
                "_rev": "_e8UT9I6-_S",
                "activestatus": true,
                "coding": {
                    "_key": "10145",
                    "_id": "CodingMaster/10145",
                    "_rev": "_e8UTuZq--U",
                    "Type": "Patient Type",
                    "activestatus": true,
                    "code": "PT002",
                    "createdby": "",
                    "createddate": 1635941380,
                    "display": "Military Personnel",
                    "gmconfigvalues": {},
                    "id": 145,
                    "shortdesc": "Military Personnel",
                    "status": true,
                    "updatedate": 1635941380,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941380,
                "id": 145,
                "status": true,
                "text": "PT002",
                "updatedate": 1635941380,
                "updatedby": ""
            },
            {
                "Type": "Patient Type",
                "_id": "CodeableConceptMaster/10146",
                "_key": "10146",
                "_rev": "_e8UT9JC--q",
                "activestatus": true,
                "coding": {
                    "_key": "10147",
                    "_id": "CodingMaster/10147",
                    "_rev": "_e8UTuaG--v",
                    "Type": "Patient Type",
                    "activestatus": true,
                    "code": "PT003",
                    "createdby": "",
                    "createddate": 1635941402,
                    "display": "Police Personnel",
                    "gmconfigvalues": {},
                    "id": 147,
                    "shortdesc": "Police Personnel",
                    "status": true,
                    "updatedate": 1635941402,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941402,
                "id": 147,
                "status": true,
                "text": "PT003",
                "updatedate": 1635941402,
                "updatedby": ""
            },
            {
                "Type": "Patient Type",
                "_id": "CodeableConceptMaster/10149",
                "_key": "10149",
                "_rev": "_e8UT9IO-_G",
                "activestatus": true,
                "coding": {
                    "_key": "10150",
                    "_id": "CodingMaster/10150",
                    "_rev": "_e8UTuai-_P",
                    "Type": "Patient Type",
                    "activestatus": true,
                    "code": "PT004",
                    "createdby": "",
                    "createddate": 1635941420,
                    "display": "Staff",
                    "gmconfigvalues": {},
                    "id": 150,
                    "shortdesc": "Staff",
                    "status": true,
                    "updatedate": 1635941420,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941420,
                "id": 150,
                "status": true,
                "text": "PT004",
                "updatedate": 1635941420,
                "updatedby": ""
            },
            {
                "Type": "Patient Type",
                "_id": "CodeableConceptMaster/10150",
                "_key": "10150",
                "_rev": "_e8UT9JO--E",
                "activestatus": true,
                "coding": {
                    "_key": "10151",
                    "_id": "CodingMaster/10151",
                    "_rev": "_e8UTuaS--2",
                    "Type": "Patient Type",
                    "activestatus": true,
                    "code": "PT005",
                    "createdby": "",
                    "createddate": 1635941442,
                    "display": "VIP",
                    "gmconfigvalues": {},
                    "id": 151,
                    "shortdesc": "VIP",
                    "status": true,
                    "updatedate": 1635941442,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941442,
                "id": 151,
                "status": true,
                "text": "PT005",
                "updatedate": 1635941442,
                "updatedby": ""
            }
        ],
        "MARITALSTATUS": [
            {
                "Type": "MARITALSTATUS",
                "_id": "CodeableConceptMaster/10561",
                "_key": "10561",
                "_rev": "_e8UT9I2--i",
                "activestatus": true,
                "coding": {
                    "_key": "10562",
                    "_id": "CodingMaster/10562",
                    "_rev": "_e8UTuaS--K",
                    "Type": "MARITALSTATUS",
                    "activestatus": true,
                    "code": "MS001",
                    "createdby": "",
                    "createddate": 1635955561,
                    "display": "Divorced",
                    "gmconfigvalues": {},
                    "id": 562,
                    "shortdesc": "Divorced",
                    "status": true,
                    "updatedate": 1635955561,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635955561,
                "id": 562,
                "status": true,
                "text": "MS001",
                "updatedate": 1635955561,
                "updatedby": ""
            },
            {
                "Type": "MARITALSTATUS",
                "_id": "CodeableConceptMaster/10562",
                "_key": "10562",
                "_rev": "_e8UT9I2-_E",
                "activestatus": true,
                "coding": {
                    "_key": "10563",
                    "_id": "CodingMaster/10563",
                    "_rev": "_e8UTuZu--C",
                    "Type": "MARITALSTATUS",
                    "activestatus": true,
                    "code": "MS002",
                    "createdby": "",
                    "createddate": 1635955571,
                    "display": "Married",
                    "gmconfigvalues": {},
                    "id": 563,
                    "shortdesc": "Married",
                    "status": true,
                    "updatedate": 1635955571,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635955571,
                "id": 563,
                "status": true,
                "text": "MS002",
                "updatedate": 1635955571,
                "updatedby": ""
            },
            {
                "Type": "MARITALSTATUS",
                "_id": "CodeableConceptMaster/10792",
                "_key": "10792",
                "_rev": "_e8UT9Iq--W",
                "activestatus": true,
                "coding": {
                    "_key": "10793",
                    "_id": "CodingMaster/10793",
                    "_rev": "_e8UTuZm--z",
                    "Type": "MARITALSTATUS",
                    "activestatus": true,
                    "code": "MS005",
                    "createdby": "",
                    "createddate": 1636611534,
                    "display": "Single",
                    "gmconfigvalues": {
                        "_key": "10793"
                    },
                    "id": 793,
                    "shortdesc": "Single",
                    "status": true,
                    "updatedate": 1636611595,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1636611534,
                "id": 793,
                "status": true,
                "text": "MS005",
                "updatedate": 1636611534,
                "updatedby": ""
            },
            {
                "Type": "MARITALSTATUS",
                "_id": "CodeableConceptMaster/10563",
                "_key": "10563",
                "_rev": "_e8UT9IW--Q",
                "activestatus": true,
                "coding": {
                    "_key": "10564",
                    "_id": "CodingMaster/10564",
                    "_rev": "_e8UTuay-_G",
                    "Type": "MARITALSTATUS",
                    "activestatus": true,
                    "code": "MS003",
                    "createdby": "",
                    "createddate": 1635955581,
                    "display": "UnMarried",
                    "gmconfigvalues": {
                        "_key": "10564"
                    },
                    "id": 564,
                    "shortdesc": "UnMarried",
                    "status": true,
                    "updatedate": 1636611634,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635955581,
                "id": 564,
                "status": true,
                "text": "MS003",
                "updatedate": 1635955581,
                "updatedby": ""
            },
            {
                "Type": "MARITALSTATUS",
                "_id": "CodeableConceptMaster/10564",
                "_key": "10564",
                "_rev": "_e8UT9H6--e",
                "activestatus": true,
                "coding": {
                    "_key": "10565",
                    "_id": "CodingMaster/10565",
                    "_rev": "_e8UTuau--8",
                    "Type": "MARITALSTATUS",
                    "activestatus": true,
                    "code": "MS004",
                    "createdby": "",
                    "createddate": 1635955597,
                    "display": "Widowed",
                    "gmconfigvalues": {},
                    "id": 565,
                    "shortdesc": "Widowed",
                    "status": true,
                    "updatedate": 1635955597,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635955597,
                "id": 565,
                "status": true,
                "text": "MS004",
                "updatedate": 1635955597,
                "updatedby": ""
            }
        ],
        "VISITTYPE": [
            {
                "Type": "VISITTYPE",
                "_id": "CodeableConceptMaster/10160",
                "_key": "10160",
                "_rev": "_e8UT9Ii-_J",
                "activestatus": true,
                "coding": {
                    "_key": "10161",
                    "_id": "CodingMaster/10161",
                    "_rev": "_e8UTuau-_Q",
                    "Type": "VISITTYPE",
                    "activestatus": true,
                    "code": "VT002",
                    "collection_name": "CodingMaster",
                    "createdby": "",
                    "createddate": 1643380091,
                    "display": "First Visit",
                    "gmconfigvalues": {
                        "test": "",
                        "Visit Type Indicator": {
                            "label": "Test",
                            "value": "CodingMaster/10822"
                        },
                        "_key": "10161"
                    },
                    "id": 161,
                    "shortdesc": "First Visit",
                    "status": true,
                    "updatedate": 1644556653,
                    "updatedby": ""
                },
                "createdby": "",
                "createddate": 1635941707,
                "id": 161,
                "status": true,
                "text": "VT002",
                "updatedate": 1643380091,
                "updatedby": ""
            },
            {
                "Type": "VISITTYPE",
                "_id": "CodeableConceptMaster/11395",
                "_key": "11395",
                "_rev": "_gfgHFUm---",
                "activestatus": true,
                "coding": {
                    "_key": "11424",
                    "_id": "CodingMaster/11424",
                    "_rev": "_gfgHFUi---",
                    "code": "VT077",
                    "display": "Followup Visit ",
                    "id": 1183,
                    "Type": "VISITTYPE",
                    "shortdesc": "Followup Visit ",
                    "gmconfigvalues": {
                        "Visit Type Indicator": {
                            "label": "Test",
                            "value": "CodingMaster/10822",
                            "Type": "VISITTYPEINDICATOR",
                            "_id": "CodingMaster/10822",
                            "_key": "10822",
                            "code": "T100",
                            "display": "Test",
                            "id": 814,
                            "status": true
                        },
                        "test": ""
                    },
                    "status": true,
                    "createdby": "",
                    "createddate": 1692628753,
                    "updatedby": "",
                    "updatedate": 1692628753,
                    "activestatus": true,
                    "collection_name": "CodingMaster"
                },
                "createdby": "",
                "createddate": 1692628656,
                "id": 1169,
                "status": true,
                "text": "VT077",
                "updatedate": 1692628753,
                "updatedby": ""
            }
        ],
        "SPECIALTY": [
            {
                "Type": "SPECIALTY",
                "_id": "CodeableConceptMaster/10455",
                "_key": "10455",
                "_rev": "_gff1NSi--A",
                "activestatus": true,
                "coding": {
                    "_key": "10456",
                    "_id": "CodingMaster/10456",
                    "_rev": "_gff1NSi---",
                    "Type": "SPECIALTY",
                    "activestatus": true,
                    "code": "SP008",
                    "createdby": "",
                    "createddate": 1692627582,
                    "display": "Nephrology",
                    "gmconfigvalues": {},
                    "id": 456,
                    "shortdesc": "Nephrology",
                    "status": true,
                    "updatedate": 1692627582,
                    "updatedby": "",
                    "collection_name": "CodingMaster"
                },
                "createdby": "",
                "createddate": 1635950808,
                "id": 456,
                "status": true,
                "text": "SP008",
                "updatedate": 1692627582,
                "updatedby": ""
            }
        ]
    },
    "allergy": {
        "list": [],
        "editIndex": null
    },
    "Diagnosis": {
        "list": [],
        "editIndex": null
    },
    "cheifComplaints": {
        "list": [],
        "editIndex": null
    },
    "financialData": {
        "payer": {},
        "validity": {},
        "membership": {},
        "eligibility": {},
        "financial": {},
        "applicable": {},
        "savedata": [
            {
                "id": 0,
                "identifier": [],
                "status": "",
                "type": "",
                "name": "",
                "servicePeriod": "",
                "coverage": [
                    {
                        "coverage_id": 0,
                        "priority": ""
                    }
                ],
                "organization_id": 0,
                "description": "",
                "guarantor": {
                    "onHold": false,
                    "period": "",
                    "relatedperson_id": 0,
                    "patient_id": 1398,
                    "organization_id": 0
                },
                "account_id": [],
                "device_id": [],
                "payer": {
                    "priority": "",
                    "reciver_type": {
                        "value": "Self Pay"
                    },
                    "reciver_code": "",
                    "guarantor_type": "",
                    "guarantor_code": "",
                    "membershipId": "",
                    "policy": "",
                    "plan": ""
                },
                "validity": {
                    "policy_start": 0,
                    "policy_expiry": 0,
                    "service_start": 0,
                    "service_end": 0
                },
                "membership": {
                    "type": {},
                    "relationship": {},
                    "member_id": ""
                },
                "eligibility": {
                    "isverify": false,
                    "sponsor_id": "",
                    "sponsor_name": ""
                },
                "financial": {
                    "class_type": {},
                    "class_value": {}
                },
                "applicable": {
                    "encounter": {},
                    "encounter_type": "",
                    "speciality": {}
                },
                "patient_id": 0
            }
        ],
        "index": null
    },
    "referralData": {
        "letter": {},
        "from": {},
        "to": {},
        "details": {},
        "note": {}
    },
    "medicationsData": {
        "list": [],
        "editIndex": null
    },
    "visitData": {
        "encounter": {
            "mode": "direct",
            "type": {
                "value": "Outpatient",
                "_id": "CodingMaster/10264"
            },
            "class": {
                "value": "Ambulatory",
                "_id": "CodingMaster/10049"
            },
            "visit_type": {
                "value": "First Visit",
                "_id": "CodingMaster/10161"
            },
            "visit_reason": {
                "value": "For Consultation with Practitioner",
                "_id": "CodingMaster/10063"
            }
        },
        "practitioner": {
            "id_name": {
                "value": "..HDBedThree Machine Three -"
            },
            "clinic": {
                "value": "HEMODIALYSIS"
            },
            "role": {
                "value": "Physician "
            },
            "type": {
                "value": "Doctor"
            },
            "speciality": {
                "value": "Nephrology",
                "codeable_id": "CodeableConceptMaster/10455",
                "coding_id": "CodingMaster/10456"
            }
        },
        "device": {
            "category": {
                "value": ""
            },
            "type": {
                "value": ""
            },
            "name": {
                "value": ""
            }
        },
        "helthcare": {},
        "accompained": {},
        "uploadFiles": []
    },
    "nextOfKin": {
        "identification": [
            {}
        ],
        "names": [
            {}
        ],
        "details": {},
        "contact": {},
        "address": [],
        "addformdetails": [],
        "index": null
    },
    "patientData": {
        "identification": [
            {}
        ],
        "names": [
            {}
        ],
        "details": {},
        "communication": {},
        "contact": {},
        "address": []
    },
    "alert": {
        "open": false,
        "severity": "success",
        "msg": "",
        "vertical": "top",
        "horizontal": "center"
    },
    "dialog": {
        "open": false,
        "title": "",
        "body": "",
        "positiveBtn": "Ok",
        "negativeBtn": "Cancel",
        "negativeBtnDontShow": false
    },
    "backDrop": {
        "open": false,
        "message": ""
    },
    "drawer": {
        "open": true,
        "direction": "right",
        "variant": "temporary"
    },
    "dataPayload": {
        "additnaldata": {},
        "birthdetails": {}
    },
    "visitform": {
        "encounterInfo": {},
        "bookingdetails": {},
        "pactitioner": {},
        "deviceDetails": {},
        "reasonDetails": {}
    },
    "queueManagement": {
        "data": [
            {
                "appointmentType": "walkin",
                "consultationMode": "Direct Consultation",
                "token": 338,
                "comment": "Checked-In",
                "name": "Sundersan ",
                "isPatient": true,
                "personId": 1602,
                "patient_id": "Person/11608",
                "patientId": 1398,
                "pId": "Patient/11439",
                "mrn": "MRN00_273",
                "role": "Consultation",
                "encounter": "12268",
                "encounter_id": "Encounter/12268",
                "encounter_status": "Checked-in",
                "encounterId": 2279,
                "showBringtoTop": true,
                "profile_pic": "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
                "isEmergency": false,
                "start": "02:00 pm",
                "startTime": 1695889800,
                "info": {
                    "AppointmentNotification": [
                        {
                            "practitionerEmail": "",
                            "mobile": "+65870065431",
                            "patientEmail": ""
                        }
                    ],
                    "Description": "string",
                    "Healthservice": [],
                    "Person": [
                        {
                            "Id": 1602,
                            "OrgID": [],
                            "Patient": [
                                {
                                    "OrgID": "",
                                    "PractitionerID": [],
                                    "_id": "Patient/11439",
                                    "_key": "11439",
                                    "_rev": "_grR8CKK---",
                                    "active": true,
                                    "activereason": "",
                                    "activestatus": true,
                                    "address": [],
                                    "age": "49",
                                    "age_type": "",
                                    "alias": "MRN00_273",
                                    "approxAgeEntered": false,
                                    "attachment": [],
                                    "birthDate": "04/09/1974",
                                    "birthGendercode": "",
                                    "birthPlace": "",
                                    "birthTime": 0,
                                    "bloodGroup": "",
                                    "cadavericDonor": false,
                                    "citizenshipCode": "",
                                    "communicationLanguage": [],
                                    "conditionhistory": [],
                                    "createdby": "",
                                    "createddate": 1695790534,
                                    "deceaseddate": 0,
                                    "deceasedreason": "",
                                    "disabilityCode": "",
                                    "ethinicityCode": "",
                                    "gender": "CodingMaster/10267",
                                    "genderIdentitycode": "",
                                    "id": 1398,
                                    "identifier": [
                                        "IdentifierMaster/15958"
                                    ],
                                    "isdeceased": "",
                                    "link": [
                                        {
                                            "patient": [],
                                            "Type": ""
                                        }
                                    ],
                                    "maritalstatus": "",
                                    "name": [
                                        "HumanNameMaster/15530"
                                    ],
                                    "nationality": "SMGeographicMaster/10056",
                                    "patientImp_Code": "",
                                    "patientTypecode": "",
                                    "personID": 1602,
                                    "photo": [],
                                    "preferredLanguage": "",
                                    "primarycare_Prac": "",
                                    "raceCode": "",
                                    "religionCode": "",
                                    "residencyStatus": false,
                                    "specialArng_code": "",
                                    "specialCourtesy_code": "",
                                    "statushistory": [
                                        {
                                            "oldstatus": "",
                                            "oldstatusreason": "",
                                            "newstatus": "Active",
                                            "fromperiod": 1695790533,
                                            "toperiod": 0
                                        }
                                    ],
                                    "telecom": [
                                        "ContactPointMaster/20609",
                                        "ContactPointMaster/20610"
                                    ],
                                    "updatedate": 1695790534,
                                    "updatedby": ""
                                }
                            ],
                            "RelatedPersonID": [],
                            "_id": "Person/11608",
                            "_key": "11608",
                            "_rev": "_grR8AD2--K",
                            "active": true,
                            "activestatus": true,
                            "address": [],
                            "alias": "PER00_1266",
                            "birthDay": "04/09/1974",
                            "createdby": "",
                            "createddate": 1695790532,
                            "gender": "MALE",
                            "identifier": [
                                "IdentifierMaster/15957"
                            ],
                            "link": [],
                            "name": [
                                "HumanNameMaster/15529"
                            ],
                            "photo": [],
                            "telecom": [
                                {
                                    "_key": "20607",
                                    "_id": "ContactPointMaster/20607",
                                    "_rev": "_grR8AD2---",
                                    "system": "Phone",
                                    "value": "+65870065431",
                                    "use": "Mobile",
                                    "rank": "1",
                                    "period": [
                                        "periodMaster/72969"
                                    ],
                                    "id": 7971,
                                    "createdby": "",
                                    "createddate": 1695790532,
                                    "updatedby": "",
                                    "updatedate": 1695790532,
                                    "activestatus": true
                                },
                                {
                                    "_key": "20608",
                                    "_id": "ContactPointMaster/20608",
                                    "_rev": "_grR8AD2--A",
                                    "system": "Email",
                                    "value": "sundersan@gmail.com",
                                    "use": "Work",
                                    "rank": "2",
                                    "period": [
                                        "periodMaster/72970"
                                    ],
                                    "id": 7972,
                                    "createdby": "",
                                    "createddate": 1695790532,
                                    "updatedby": "",
                                    "updatedate": 1695790532,
                                    "activestatus": true
                                }
                            ],
                            "updatedate": 1695790532,
                            "updatedby": "",
                            "HumanName": [
                                {
                                    "_id": "HumanNameMaster/15529",
                                    "_key": "15529",
                                    "_rev": "_grR8ADy--E",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1695790532,
                                    "family": "",
                                    "given": "",
                                    "id": 4756,
                                    "period": [
                                        "periodMaster/72968"
                                    ],
                                    "prefix": null,
                                    "suffix": null,
                                    "text": "Sundersan",
                                    "updatedate": 1695790532,
                                    "updatedby": "",
                                    "use": null
                                }
                            ]
                        }
                    ],
                    "PersonID": [
                        1602
                    ],
                    "ServiceCategoryID": [],
                    "ServiceTypeID": [],
                    "SpecialtyID": [
                        456
                    ],
                    "_id": "Appointment/14016",
                    "_key": "14016",
                    "_rev": "_grlVXda---",
                    "activestatus": true,
                    "appno": "AP-338",
                    "appointmentReason": [],
                    "appointmentType": "walkin",
                    "appointmentTypeID": [],
                    "appstatus": "booked",
                    "avcpatientstatus": "YET TO LOGIN",
                    "basedOn": [],
                    "bookedby": "Practitioner/10008",
                    "cancelationReason": [],
                    "comment": "Checked-In",
                    "consultationMode": "Direct Consultation",
                    "createdby": "",
                    "createddate": 1695871886,
                    "encounter": [
                        {
                            "Organization_id": 138,
                            "ServiceRequest_id": 0,
                            "_id": "Encounter/12268",
                            "_key": "12268",
                            "_rev": "_grqCBG6---",
                            "accompanied_person": [
                                {
                                    "id_type": "",
                                    "id": "",
                                    "name": "",
                                    "relationship": ""
                                }
                            ],
                            "account": [
                                {
                                    "accountid": "",
                                    "ChangeSeqNum": 0
                                }
                            ],
                            "activestatus": true,
                            "appointmentId": [
                                338
                            ],
                            "basedOn": "",
                            "cancel_date": 0,
                            "class": "CodingMaster/10049",
                            "classhistory": [
                                {
                                    "class": "",
                                    "period": ""
                                }
                            ],
                            "createdby": "",
                            "createddate": 1695880522,
                            "device_details": [
                                {
                                    "category": "",
                                    "type": "",
                                    "name": ""
                                }
                            ],
                            "diagnosis": [
                                {
                                    "condition": "",
                                    "use": "",
                                    "rank": ""
                                }
                            ],
                            "eSignature": "",
                            "encounter_no": "",
                            "encounterdate": 1695891577,
                            "encounterenddate": 1695925799,
                            "end_type": "",
                            "episodeOfCare_id": 0,
                            "files": [],
                            "followupDur": 0,
                            "followupReqd": false,
                            "followupUOM": "",
                            "health_of_service": [
                                {
                                    "category": "",
                                    "name": "",
                                    "type": ""
                                }
                            ],
                            "hospitalization": [
                                {
                                    "preAdmissionIdentifier": "",
                                    "origin": "",
                                    "admitSource": "",
                                    "reAdmission": "",
                                    "dietPreference": [],
                                    "specialCourtesy": [],
                                    "specialArrangement": [],
                                    "destination": "",
                                    "dischargeDisposition": ""
                                }
                            ],
                            "id": 2279,
                            "identifier": [
                                "IdentifierMaster/15983"
                            ],
                            "length": [
                                {
                                    "value": 0,
                                    "comparator": "",
                                    "unit": "",
                                    "system": "",
                                    "code": ""
                                }
                            ],
                            "location": [
                                {
                                    "location_id": "",
                                    "status": "",
                                    "period": "",
                                    "physicalType": ""
                                }
                            ],
                            "mode": "direct",
                            "partOf": "",
                            "participant": [
                                {
                                    "type": [
                                        "CodingMaster/12338"
                                    ],
                                    "period": "",
                                    "Practitioner_id": 161,
                                    "PractitionerRole_id": 378,
                                    "RelatedPerson_id": [
                                        {
                                            "relatedpersonid": "",
                                            "relationship": ""
                                        }
                                    ],
                                    "specialty": ""
                                }
                            ],
                            "participantHistory": [
                                {
                                    "participantType": "",
                                    "practroleId": "",
                                    "practitionerId": "",
                                    "specialty": "",
                                    "relatedPersonId": "",
                                    "relationship": "",
                                    "userId": "",
                                    "startTime": 0,
                                    "endTime": 0,
                                    "reasonCode": ""
                                }
                            ],
                            "patient_id": 1398,
                            "period": "",
                            "plannedEndDate": 0,
                            "plannedStartDate": 0,
                            "practitioner_details": [
                                {
                                    "speciality": "CodingMaster/10456",
                                    "type": "Doctor",
                                    "id_name": "..HDBedThree Machine Three -",
                                    "clinic": "HEMODIALYSIS",
                                    "role": "Physician ",
                                    "period": ""
                                }
                            ],
                            "priority": null,
                            "priorityreason": null,
                            "reasonCode": "",
                            "reasonReference": "CodingMaster/10063",
                            "relationship": "",
                            "revision_date": 0,
                            "serviceType": "First Visit",
                            "source": "",
                            "status": {
                                "_key": "10725",
                                "_id": "CodingMaster/10725",
                                "_rev": "_e8UTuZi---",
                                "Type": "ENCSTATUS",
                                "activestatus": true,
                                "code": "ENCSTATUS003",
                                "createdby": "",
                                "createddate": 1636355434,
                                "display": "Checked-in",
                                "gmconfigvalues": {},
                                "id": 725,
                                "shortdesc": "Checked-in",
                                "status": true,
                                "updatedate": 1636355434,
                                "updatedby": ""
                            },
                            "statushistory": [
                                {
                                    "status": "CodingMaster/10725",
                                    "period": [
                                        "periodMaster/73107"
                                    ],
                                    "reasoncode": ""
                                }
                            ],
                            "subject": "",
                            "type": [
                                "CodingMaster/10264"
                            ],
                            "updatedate": 1695891589,
                            "updatedby": "",
                            "visit_type": "First Visit"
                        }
                    ],
                    "end": 1695897000,
                    "id": 338,
                    "identifier": [],
                    "minutesDuration": 0,
                    "orgid": "",
                    "orgtype": "CodingMaster/10010",
                    "participant": [],
                    "patientInstruction": "",
                    "priority": 1,
                    "reasonCode": [],
                    "reasonReference": [],
                    "report_docs": [],
                    "requestedPeriod": [
                        "periodMaster/73030"
                    ],
                    "resourcecode": "Practitioner/10160",
                    "resourcerole": "Physician ",
                    "resourcetype": "Practitioner",
                    "slotID": [
                        3821397
                    ],
                    "start": 1695889800,
                    "status": true,
                    "supportingInformation": [],
                    "updatedate": 1695871886,
                    "updatedby": "",
                    "Practitioner": []
                },
                "mobileNo": "+65870065431",
                "index": 3,
                "status": true,
                "type": "frontdesk"
            },
            {
                "appointmentType": "walkin",
                "consultationMode": "Direct Consultation",
                "token": 340,
                "comment": "Checked-In",
                "name": "kumari ",
                "isPatient": true,
                "personId": 1325,
                "patient_id": "Person/11331",
                "patientId": 1059,
                "pId": "Patient/11097",
                "mrn": "MRN00_943",
                "role": "Consultation",
                "encounter": "",
                "encounter_id": "",
                "encounter_status": "",
                "encounterId": "",
                "showBringtoTop": true,
                "profile_pic": "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
                "isEmergency": false,
                "start": "02:00 pm",
                "startTime": 1695889800,
                "info": {
                    "AppointmentNotification": [
                        {
                            "practitionerEmail": "",
                            "mobile": "+917878787875",
                            "patientEmail": ""
                        }
                    ],
                    "Description": "string",
                    "Healthservice": [],
                    "Person": [
                        {
                            "Id": 1325,
                            "OrgID": [],
                            "Patient": [
                                {
                                    "OrgID": "",
                                    "PractitionerID": [],
                                    "_id": "Patient/11097",
                                    "_key": "11097",
                                    "_rev": "_gkTRoya--_",
                                    "active": true,
                                    "activereason": "",
                                    "activestatus": true,
                                    "address": [],
                                    "age": "28",
                                    "age_type": "CodingMaster/10241",
                                    "alias": "MRN00_943",
                                    "approxAgeEntered": false,
                                    "attachment": [],
                                    "birthDate": "01/07/1995",
                                    "birthGendercode": "",
                                    "birthPlace": "",
                                    "birthTime": 0,
                                    "bloodGroup": "",
                                    "cadavericDonor": false,
                                    "citizenshipCode": "",
                                    "communicationLanguage": [],
                                    "conditionhistory": [],
                                    "createdby": "",
                                    "createddate": 1689741323,
                                    "deceaseddate": 0,
                                    "deceasedreason": "",
                                    "disabilityCode": "",
                                    "ethinicityCode": "",
                                    "gender": "CodingMaster/10266",
                                    "genderIdentitycode": "",
                                    "id": 1059,
                                    "identifier": [
                                        "IdentifierMaster/14763"
                                    ],
                                    "isdeceased": "",
                                    "link": [
                                        {
                                            "patient": [],
                                            "Type": ""
                                        }
                                    ],
                                    "maritalstatus": "CodingMaster/10563",
                                    "name": [
                                        "HumanNameMaster/14622"
                                    ],
                                    "nationality": "SMGeographicMaster/10000",
                                    "patientImp_Code": "",
                                    "patientTypecode": "",
                                    "personID": 1325,
                                    "photo": [],
                                    "preferredLanguage": "",
                                    "primarycare_Prac": "",
                                    "raceCode": "",
                                    "religionCode": "",
                                    "residencyStatus": false,
                                    "specialArng_code": "",
                                    "specialCourtesy_code": "",
                                    "statushistory": [
                                        {
                                            "oldstatus": "",
                                            "oldstatusreason": "",
                                            "newstatus": "Active",
                                            "fromperiod": 1689741321,
                                            "toperiod": 0
                                        }
                                    ],
                                    "telecom": [
                                        "ContactPointMaster/18902"
                                    ],
                                    "updatedate": 1689741626,
                                    "updatedby": ""
                                }
                            ],
                            "RelatedPersonID": [],
                            "_id": "Person/11331",
                            "_key": "11331",
                            "_rev": "_gkTSssa--O",
                            "active": true,
                            "activestatus": true,
                            "address": [],
                            "alias": "PER00_989",
                            "birthDay": "01/07/1995",
                            "createdby": "",
                            "createddate": 1689741276,
                            "gender": "FEMALE",
                            "identifier": [],
                            "link": [],
                            "name": [
                                "HumanNameMaster/14623"
                            ],
                            "photo": [],
                            "telecom": [
                                {
                                    "_key": "18904",
                                    "_id": "ContactPointMaster/18904",
                                    "_rev": "_gkTUQUq--2",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1689741626,
                                    "id": 6270,
                                    "period": [
                                        "periodMaster/55957"
                                    ],
                                    "rank": "2",
                                    "system": "Email",
                                    "updatedate": 1689741626,
                                    "updatedby": "",
                                    "use": "Work",
                                    "value": ""
                                },
                                {
                                    "_key": "18903",
                                    "_id": "ContactPointMaster/18903",
                                    "_rev": "_gkTUQUq--i",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1689741626,
                                    "id": 6269,
                                    "period": [
                                        "periodMaster/55955"
                                    ],
                                    "rank": "1",
                                    "system": "Phone",
                                    "updatedate": 1689741626,
                                    "updatedby": "",
                                    "use": "Mobile",
                                    "value": "+917878787875"
                                }
                            ],
                            "updatedate": 1689741626,
                            "updatedby": "",
                            "HumanName": [
                                {
                                    "_id": "HumanNameMaster/14623",
                                    "_key": "14623",
                                    "_rev": "_gUvxBia--A",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1689741626,
                                    "family": "",
                                    "given": "",
                                    "id": 3852,
                                    "period": [
                                        "periodMaster/55954"
                                    ],
                                    "prefix": null,
                                    "suffix": null,
                                    "text": "kumari",
                                    "updatedate": 1689741626,
                                    "updatedby": "",
                                    "use": null
                                }
                            ]
                        }
                    ],
                    "PersonID": [
                        1325
                    ],
                    "ServiceCategoryID": [],
                    "ServiceTypeID": [],
                    "SpecialtyID": [
                        456
                    ],
                    "_id": "Appointment/14018",
                    "_key": "14018",
                    "_rev": "_grlfUbi--G",
                    "activestatus": true,
                    "appno": "AP-340",
                    "appointmentReason": [],
                    "appointmentType": "walkin",
                    "appointmentTypeID": [],
                    "appstatus": "booked",
                    "avcpatientstatus": "YET TO LOGIN",
                    "basedOn": [],
                    "bookedby": "Practitioner/10008",
                    "cancelationReason": [],
                    "comment": "Checked-In",
                    "consultationMode": "Direct Consultation",
                    "createdby": "",
                    "createddate": 1695872538,
                    "encounter": [],
                    "end": 1695897000,
                    "id": 340,
                    "identifier": [],
                    "minutesDuration": 0,
                    "orgid": "",
                    "orgtype": "CodingMaster/10010",
                    "participant": [],
                    "patientInstruction": "",
                    "priority": 1,
                    "reasonCode": [],
                    "reasonReference": [],
                    "report_docs": [],
                    "requestedPeriod": [
                        "periodMaster/73040"
                    ],
                    "resourcecode": "Practitioner/10159",
                    "resourcerole": "Physician ",
                    "resourcetype": "Practitioner",
                    "slotID": [
                        3820237
                    ],
                    "start": 1695889800,
                    "status": true,
                    "supportingInformation": [],
                    "updatedate": 1695872538,
                    "updatedby": "",
                    "Practitioner": []
                },
                "mobileNo": "+917878787875",
                "index": 5,
                "status": true,
                "type": "frontdesk"
            }
        ],
        "allData": [
            {
                "appointmentType": "walkin",
                "consultationMode": "Direct Consultation",
                "token": 337,
                "comment": "completed_assemble",
                "name": "Helen ",
                "isPatient": true,
                "personId": 1603,
                "patient_id": "Person/11609",
                "patientId": 1399,
                "pId": "Patient/11440",
                "mrn": "MRN00_274",
                "role": "Consultation",
                "encounter": "12263",
                "encounter_id": "Encounter/12263",
                "encounter_status": "Checked-in",
                "encounterId": 2274,
                "showBringtoTop": false,
                "profile_pic": "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
                "isEmergency": false,
                "start": "12:00 pm",
                "startTime": 1695882600,
                "info": {
                    "AppointmentNotification": [
                        {
                            "practitionerEmail": "",
                            "mobile": "+655600987056",
                            "patientEmail": ""
                        }
                    ],
                    "Description": "string",
                    "Healthservice": [],
                    "Person": [
                        {
                            "Id": 1603,
                            "OrgID": [],
                            "Patient": [
                                {
                                    "OrgID": "",
                                    "PractitionerID": [],
                                    "_id": "Patient/11440",
                                    "_key": "11440",
                                    "_rev": "_grSFcl---Q",
                                    "active": true,
                                    "activereason": "",
                                    "activestatus": true,
                                    "address": [],
                                    "age": "51",
                                    "age_type": "",
                                    "alias": "MRN00_274",
                                    "approxAgeEntered": false,
                                    "attachment": [],
                                    "birthDate": "01/09/1972",
                                    "birthGendercode": "",
                                    "birthPlace": "",
                                    "birthTime": 0,
                                    "bloodGroup": "",
                                    "cadavericDonor": false,
                                    "citizenshipCode": "",
                                    "communicationLanguage": [],
                                    "conditionhistory": [],
                                    "createdby": "",
                                    "createddate": 1695791151,
                                    "deceaseddate": 0,
                                    "deceasedreason": "",
                                    "disabilityCode": "",
                                    "ethinicityCode": "",
                                    "gender": "CodingMaster/10266",
                                    "genderIdentitycode": "",
                                    "id": 1399,
                                    "identifier": [
                                        "IdentifierMaster/15960"
                                    ],
                                    "isdeceased": "",
                                    "link": [
                                        {
                                            "patient": [],
                                            "Type": ""
                                        }
                                    ],
                                    "maritalstatus": "",
                                    "name": [
                                        "HumanNameMaster/15532"
                                    ],
                                    "nationality": "SMGeographicMaster/10056",
                                    "patientImp_Code": "",
                                    "patientTypecode": "",
                                    "personID": 1603,
                                    "photo": [],
                                    "preferredLanguage": "",
                                    "primarycare_Prac": "",
                                    "raceCode": "",
                                    "religionCode": "",
                                    "residencyStatus": false,
                                    "specialArng_code": "",
                                    "specialCourtesy_code": "",
                                    "statushistory": [
                                        {
                                            "oldstatus": "",
                                            "oldstatusreason": "",
                                            "newstatus": "Active",
                                            "fromperiod": 1695791150,
                                            "toperiod": 0
                                        }
                                    ],
                                    "telecom": [
                                        "ContactPointMaster/20613",
                                        "ContactPointMaster/20614"
                                    ],
                                    "updatedate": 1695791151,
                                    "updatedby": ""
                                }
                            ],
                            "RelatedPersonID": [],
                            "_id": "Person/11609",
                            "_key": "11609",
                            "_rev": "_grSFaY---C",
                            "active": true,
                            "activestatus": true,
                            "address": [],
                            "alias": "PER00_1267",
                            "birthDay": "01/09/1972",
                            "createdby": "",
                            "createddate": 1695791149,
                            "gender": "FEMALE",
                            "identifier": [
                                "IdentifierMaster/15959"
                            ],
                            "link": [],
                            "name": [
                                "HumanNameMaster/15531"
                            ],
                            "photo": [],
                            "telecom": [
                                {
                                    "_key": "20611",
                                    "_id": "ContactPointMaster/20611",
                                    "_rev": "_grSFaX6--G",
                                    "system": "Phone",
                                    "value": "+655600987056",
                                    "use": "Mobile",
                                    "rank": "1",
                                    "period": [
                                        "periodMaster/72975"
                                    ],
                                    "id": 7975,
                                    "createdby": "",
                                    "createddate": 1695791149,
                                    "updatedby": "",
                                    "updatedate": 1695791149,
                                    "activestatus": true
                                },
                                {
                                    "_key": "20612",
                                    "_id": "ContactPointMaster/20612",
                                    "_rev": "_grSFaX6--I",
                                    "system": "Email",
                                    "value": "helen@mail.com",
                                    "use": "Work",
                                    "rank": "2",
                                    "period": [
                                        "periodMaster/72976"
                                    ],
                                    "id": 7976,
                                    "createdby": "",
                                    "createddate": 1695791149,
                                    "updatedby": "",
                                    "updatedate": 1695791149,
                                    "activestatus": true
                                }
                            ],
                            "updatedate": 1695791149,
                            "updatedby": "",
                            "HumanName": [
                                {
                                    "_id": "HumanNameMaster/15531",
                                    "_key": "15531",
                                    "_rev": "_grSFaX6--A",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1695791149,
                                    "family": "",
                                    "given": "",
                                    "id": 4758,
                                    "period": [
                                        "periodMaster/72974"
                                    ],
                                    "prefix": null,
                                    "suffix": null,
                                    "text": "Helen",
                                    "updatedate": 1695791149,
                                    "updatedby": "",
                                    "use": null
                                }
                            ]
                        }
                    ],
                    "PersonID": [
                        1603
                    ],
                    "ServiceCategoryID": [],
                    "ServiceTypeID": [],
                    "SpecialtyID": [
                        456
                    ],
                    "_id": "Appointment/14015",
                    "_key": "14015",
                    "_rev": "_grlW8jK---",
                    "activestatus": true,
                    "appno": "AP-337",
                    "appointmentReason": [],
                    "appointmentType": "walkin",
                    "appointmentTypeID": [],
                    "appstatus": "booked",
                    "avcpatientstatus": "YET TO LOGIN",
                    "basedOn": [],
                    "bookedby": "Practitioner/10008",
                    "cancelationReason": [],
                    "comment": "completed_assemble",
                    "consultationMode": "Direct Consultation",
                    "createdby": "",
                    "createddate": 1695871807,
                    "encounter": [
                        {
                            "Organization_id": 138,
                            "ServiceRequest_id": 0,
                            "_id": "Encounter/12263",
                            "_key": "12263",
                            "_rev": "_grlW8IO--A",
                            "accompanied_person": [
                                {
                                    "id_type": "",
                                    "id": "",
                                    "name": "",
                                    "relationship": ""
                                }
                            ],
                            "account": [
                                {
                                    "accountid": "",
                                    "ChangeSeqNum": 0
                                }
                            ],
                            "activestatus": true,
                            "appointmentId": [
                                337
                            ],
                            "basedOn": "",
                            "cancel_date": 0,
                            "class": "CodingMaster/10049",
                            "classhistory": [
                                {
                                    "class": "",
                                    "period": ""
                                }
                            ],
                            "createdby": "",
                            "createddate": 1695871980,
                            "device_details": [
                                {
                                    "category": "",
                                    "type": "",
                                    "name": ""
                                }
                            ],
                            "diagnosis": [
                                {
                                    "condition": "",
                                    "use": "",
                                    "rank": ""
                                }
                            ],
                            "eSignature": "",
                            "encounter_no": "",
                            "encounterdate": 1695871980,
                            "encounterenddate": 1695925799,
                            "end_type": "",
                            "episodeOfCare_id": 0,
                            "files": [],
                            "followupDur": 0,
                            "followupReqd": false,
                            "followupUOM": "",
                            "health_of_service": [
                                {
                                    "category": "",
                                    "name": "",
                                    "type": ""
                                }
                            ],
                            "hospitalization": [
                                {
                                    "preAdmissionIdentifier": "",
                                    "origin": "",
                                    "admitSource": "",
                                    "reAdmission": "",
                                    "dietPreference": [],
                                    "specialCourtesy": [],
                                    "specialArrangement": [],
                                    "destination": "",
                                    "dischargeDisposition": ""
                                }
                            ],
                            "id": 2274,
                            "identifier": [
                                "IdentifierMaster/15972"
                            ],
                            "length": [
                                {
                                    "value": 0,
                                    "comparator": "",
                                    "unit": "",
                                    "system": "",
                                    "code": ""
                                }
                            ],
                            "location": [
                                {
                                    "location_id": "",
                                    "status": "",
                                    "period": "",
                                    "physicalType": ""
                                }
                            ],
                            "mode": "direct",
                            "partOf": "",
                            "participant": [
                                {
                                    "type": [
                                        "CodingMaster/12338"
                                    ],
                                    "period": "",
                                    "Practitioner_id": 159,
                                    "PractitionerRole_id": 376,
                                    "RelatedPerson_id": [
                                        {
                                            "relatedpersonid": "",
                                            "relationship": ""
                                        }
                                    ],
                                    "specialty": ""
                                }
                            ],
                            "participantHistory": [
                                {
                                    "participantType": "",
                                    "practroleId": "",
                                    "practitionerId": "",
                                    "specialty": "",
                                    "relatedPersonId": "",
                                    "relationship": "",
                                    "userId": "",
                                    "startTime": 0,
                                    "endTime": 0,
                                    "reasonCode": ""
                                }
                            ],
                            "patient_id": 1399,
                            "period": "",
                            "plannedEndDate": 0,
                            "plannedStartDate": 0,
                            "practitioner_details": [
                                {
                                    "speciality": "CodingMaster/10456",
                                    "type": "Doctor",
                                    "id_name": "..HDBedOne Machine ONE -",
                                    "clinic": "HEMODIALYSIS",
                                    "role": "Physician ",
                                    "period": ""
                                }
                            ],
                            "priority": null,
                            "priorityreason": null,
                            "reasonCode": "",
                            "reasonReference": "CodingMaster/10063",
                            "relationship": "",
                            "revision_date": 0,
                            "serviceType": "Followup Visit ",
                            "source": "",
                            "start_type": "Elective/Scheduled",
                            "status": {
                                "_key": "10725",
                                "_id": "CodingMaster/10725",
                                "_rev": "_e8UTuZi---",
                                "Type": "ENCSTATUS",
                                "activestatus": true,
                                "code": "ENCSTATUS003",
                                "createdby": "",
                                "createddate": 1636355434,
                                "display": "Checked-in",
                                "gmconfigvalues": {},
                                "id": 725,
                                "shortdesc": "Checked-in",
                                "status": true,
                                "updatedate": 1636355434,
                                "updatedby": ""
                            },
                            "statushistory": [
                                {
                                    "period": [
                                        "periodMaster/73033"
                                    ],
                                    "status": "CodingMaster/10725"
                                },
                                {
                                    "status": "CodingMaster/10725",
                                    "period": [
                                        "periodMaster/73034"
                                    ]
                                }
                            ],
                            "subject": "",
                            "type": [
                                "CodingMaster/10264"
                            ],
                            "updatedate": 1695871989,
                            "updatedby": "",
                            "visit_type": "Followup Visit "
                        }
                    ],
                    "end": 1695889800,
                    "id": 337,
                    "identifier": [],
                    "minutesDuration": 0,
                    "orgid": "",
                    "orgtype": "CodingMaster/10010",
                    "participant": [],
                    "patientInstruction": "",
                    "priority": 1,
                    "reasonCode": [],
                    "reasonReference": [],
                    "report_docs": [],
                    "requestedPeriod": [
                        "periodMaster/73029"
                    ],
                    "resourcecode": "Practitioner/10158",
                    "resourcerole": "Physician ",
                    "resourcetype": "Practitioner",
                    "slotID": [
                        3819076
                    ],
                    "start": 1695882600,
                    "status": true,
                    "supportingInformation": [],
                    "updatedate": 1695871990,
                    "updatedby": "",
                    "Practitioner": []
                },
                "mobileNo": "+655600987056",
                "index": 2,
                "status": true,
                "type": "frontdesk"
            },
            {
                "appointmentType": "walkin",
                "consultationMode": "Direct Consultation",
                "token": 338,
                "comment": "Checked-In",
                "name": "Sundersan ",
                "isPatient": true,
                "personId": 1602,
                "patient_id": "Person/11608",
                "patientId": 1398,
                "pId": "Patient/11439",
                "mrn": "MRN00_273",
                "role": "Consultation",
                "encounter": "12268",
                "encounter_id": "Encounter/12268",
                "encounter_status": "Checked-in",
                "encounterId": 2279,
                "showBringtoTop": true,
                "profile_pic": "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
                "isEmergency": false,
                "start": "02:00 pm",
                "startTime": 1695889800,
                "info": {
                    "AppointmentNotification": [
                        {
                            "practitionerEmail": "",
                            "mobile": "+65870065431",
                            "patientEmail": ""
                        }
                    ],
                    "Description": "string",
                    "Healthservice": [],
                    "Person": [
                        {
                            "Id": 1602,
                            "OrgID": [],
                            "Patient": [
                                {
                                    "OrgID": "",
                                    "PractitionerID": [],
                                    "_id": "Patient/11439",
                                    "_key": "11439",
                                    "_rev": "_grR8CKK---",
                                    "active": true,
                                    "activereason": "",
                                    "activestatus": true,
                                    "address": [],
                                    "age": "49",
                                    "age_type": "",
                                    "alias": "MRN00_273",
                                    "approxAgeEntered": false,
                                    "attachment": [],
                                    "birthDate": "04/09/1974",
                                    "birthGendercode": "",
                                    "birthPlace": "",
                                    "birthTime": 0,
                                    "bloodGroup": "",
                                    "cadavericDonor": false,
                                    "citizenshipCode": "",
                                    "communicationLanguage": [],
                                    "conditionhistory": [],
                                    "createdby": "",
                                    "createddate": 1695790534,
                                    "deceaseddate": 0,
                                    "deceasedreason": "",
                                    "disabilityCode": "",
                                    "ethinicityCode": "",
                                    "gender": "CodingMaster/10267",
                                    "genderIdentitycode": "",
                                    "id": 1398,
                                    "identifier": [
                                        "IdentifierMaster/15958"
                                    ],
                                    "isdeceased": "",
                                    "link": [
                                        {
                                            "patient": [],
                                            "Type": ""
                                        }
                                    ],
                                    "maritalstatus": "",
                                    "name": [
                                        "HumanNameMaster/15530"
                                    ],
                                    "nationality": "SMGeographicMaster/10056",
                                    "patientImp_Code": "",
                                    "patientTypecode": "",
                                    "personID": 1602,
                                    "photo": [],
                                    "preferredLanguage": "",
                                    "primarycare_Prac": "",
                                    "raceCode": "",
                                    "religionCode": "",
                                    "residencyStatus": false,
                                    "specialArng_code": "",
                                    "specialCourtesy_code": "",
                                    "statushistory": [
                                        {
                                            "oldstatus": "",
                                            "oldstatusreason": "",
                                            "newstatus": "Active",
                                            "fromperiod": 1695790533,
                                            "toperiod": 0
                                        }
                                    ],
                                    "telecom": [
                                        "ContactPointMaster/20609",
                                        "ContactPointMaster/20610"
                                    ],
                                    "updatedate": 1695790534,
                                    "updatedby": ""
                                }
                            ],
                            "RelatedPersonID": [],
                            "_id": "Person/11608",
                            "_key": "11608",
                            "_rev": "_grR8AD2--K",
                            "active": true,
                            "activestatus": true,
                            "address": [],
                            "alias": "PER00_1266",
                            "birthDay": "04/09/1974",
                            "createdby": "",
                            "createddate": 1695790532,
                            "gender": "MALE",
                            "identifier": [
                                "IdentifierMaster/15957"
                            ],
                            "link": [],
                            "name": [
                                "HumanNameMaster/15529"
                            ],
                            "photo": [],
                            "telecom": [
                                {
                                    "_key": "20607",
                                    "_id": "ContactPointMaster/20607",
                                    "_rev": "_grR8AD2---",
                                    "system": "Phone",
                                    "value": "+65870065431",
                                    "use": "Mobile",
                                    "rank": "1",
                                    "period": [
                                        "periodMaster/72969"
                                    ],
                                    "id": 7971,
                                    "createdby": "",
                                    "createddate": 1695790532,
                                    "updatedby": "",
                                    "updatedate": 1695790532,
                                    "activestatus": true
                                },
                                {
                                    "_key": "20608",
                                    "_id": "ContactPointMaster/20608",
                                    "_rev": "_grR8AD2--A",
                                    "system": "Email",
                                    "value": "sundersan@gmail.com",
                                    "use": "Work",
                                    "rank": "2",
                                    "period": [
                                        "periodMaster/72970"
                                    ],
                                    "id": 7972,
                                    "createdby": "",
                                    "createddate": 1695790532,
                                    "updatedby": "",
                                    "updatedate": 1695790532,
                                    "activestatus": true
                                }
                            ],
                            "updatedate": 1695790532,
                            "updatedby": "",
                            "HumanName": [
                                {
                                    "_id": "HumanNameMaster/15529",
                                    "_key": "15529",
                                    "_rev": "_grR8ADy--E",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1695790532,
                                    "family": "",
                                    "given": "",
                                    "id": 4756,
                                    "period": [
                                        "periodMaster/72968"
                                    ],
                                    "prefix": null,
                                    "suffix": null,
                                    "text": "Sundersan",
                                    "updatedate": 1695790532,
                                    "updatedby": "",
                                    "use": null
                                }
                            ]
                        }
                    ],
                    "PersonID": [
                        1602
                    ],
                    "ServiceCategoryID": [],
                    "ServiceTypeID": [],
                    "SpecialtyID": [
                        456
                    ],
                    "_id": "Appointment/14016",
                    "_key": "14016",
                    "_rev": "_grlVXda---",
                    "activestatus": true,
                    "appno": "AP-338",
                    "appointmentReason": [],
                    "appointmentType": "walkin",
                    "appointmentTypeID": [],
                    "appstatus": "booked",
                    "avcpatientstatus": "YET TO LOGIN",
                    "basedOn": [],
                    "bookedby": "Practitioner/10008",
                    "cancelationReason": [],
                    "comment": "Checked-In",
                    "consultationMode": "Direct Consultation",
                    "createdby": "",
                    "createddate": 1695871886,
                    "encounter": [
                        {
                            "Organization_id": 138,
                            "ServiceRequest_id": 0,
                            "_id": "Encounter/12268",
                            "_key": "12268",
                            "_rev": "_grqCBG6---",
                            "accompanied_person": [
                                {
                                    "id_type": "",
                                    "id": "",
                                    "name": "",
                                    "relationship": ""
                                }
                            ],
                            "account": [
                                {
                                    "accountid": "",
                                    "ChangeSeqNum": 0
                                }
                            ],
                            "activestatus": true,
                            "appointmentId": [
                                338
                            ],
                            "basedOn": "",
                            "cancel_date": 0,
                            "class": "CodingMaster/10049",
                            "classhistory": [
                                {
                                    "class": "",
                                    "period": ""
                                }
                            ],
                            "createdby": "",
                            "createddate": 1695880522,
                            "device_details": [
                                {
                                    "category": "",
                                    "type": "",
                                    "name": ""
                                }
                            ],
                            "diagnosis": [
                                {
                                    "condition": "",
                                    "use": "",
                                    "rank": ""
                                }
                            ],
                            "eSignature": "",
                            "encounter_no": "",
                            "encounterdate": 1695891577,
                            "encounterenddate": 1695925799,
                            "end_type": "",
                            "episodeOfCare_id": 0,
                            "files": [],
                            "followupDur": 0,
                            "followupReqd": false,
                            "followupUOM": "",
                            "health_of_service": [
                                {
                                    "category": "",
                                    "name": "",
                                    "type": ""
                                }
                            ],
                            "hospitalization": [
                                {
                                    "preAdmissionIdentifier": "",
                                    "origin": "",
                                    "admitSource": "",
                                    "reAdmission": "",
                                    "dietPreference": [],
                                    "specialCourtesy": [],
                                    "specialArrangement": [],
                                    "destination": "",
                                    "dischargeDisposition": ""
                                }
                            ],
                            "id": 2279,
                            "identifier": [
                                "IdentifierMaster/15983"
                            ],
                            "length": [
                                {
                                    "value": 0,
                                    "comparator": "",
                                    "unit": "",
                                    "system": "",
                                    "code": ""
                                }
                            ],
                            "location": [
                                {
                                    "location_id": "",
                                    "status": "",
                                    "period": "",
                                    "physicalType": ""
                                }
                            ],
                            "mode": "direct",
                            "partOf": "",
                            "participant": [
                                {
                                    "type": [
                                        "CodingMaster/12338"
                                    ],
                                    "period": "",
                                    "Practitioner_id": 161,
                                    "PractitionerRole_id": 378,
                                    "RelatedPerson_id": [
                                        {
                                            "relatedpersonid": "",
                                            "relationship": ""
                                        }
                                    ],
                                    "specialty": ""
                                }
                            ],
                            "participantHistory": [
                                {
                                    "participantType": "",
                                    "practroleId": "",
                                    "practitionerId": "",
                                    "specialty": "",
                                    "relatedPersonId": "",
                                    "relationship": "",
                                    "userId": "",
                                    "startTime": 0,
                                    "endTime": 0,
                                    "reasonCode": ""
                                }
                            ],
                            "patient_id": 1398,
                            "period": "",
                            "plannedEndDate": 0,
                            "plannedStartDate": 0,
                            "practitioner_details": [
                                {
                                    "speciality": "CodingMaster/10456",
                                    "type": "Doctor",
                                    "id_name": "..HDBedThree Machine Three -",
                                    "clinic": "HEMODIALYSIS",
                                    "role": "Physician ",
                                    "period": ""
                                }
                            ],
                            "priority": null,
                            "priorityreason": null,
                            "reasonCode": "",
                            "reasonReference": "CodingMaster/10063",
                            "relationship": "",
                            "revision_date": 0,
                            "serviceType": "First Visit",
                            "source": "",
                            "status": {
                                "_key": "10725",
                                "_id": "CodingMaster/10725",
                                "_rev": "_e8UTuZi---",
                                "Type": "ENCSTATUS",
                                "activestatus": true,
                                "code": "ENCSTATUS003",
                                "createdby": "",
                                "createddate": 1636355434,
                                "display": "Checked-in",
                                "gmconfigvalues": {},
                                "id": 725,
                                "shortdesc": "Checked-in",
                                "status": true,
                                "updatedate": 1636355434,
                                "updatedby": ""
                            },
                            "statushistory": [
                                {
                                    "status": "CodingMaster/10725",
                                    "period": [
                                        "periodMaster/73107"
                                    ],
                                    "reasoncode": ""
                                }
                            ],
                            "subject": "",
                            "type": [
                                "CodingMaster/10264"
                            ],
                            "updatedate": 1695891589,
                            "updatedby": "",
                            "visit_type": "First Visit"
                        }
                    ],
                    "end": 1695897000,
                    "id": 338,
                    "identifier": [],
                    "minutesDuration": 0,
                    "orgid": "",
                    "orgtype": "CodingMaster/10010",
                    "participant": [],
                    "patientInstruction": "",
                    "priority": 1,
                    "reasonCode": [],
                    "reasonReference": [],
                    "report_docs": [],
                    "requestedPeriod": [
                        "periodMaster/73030"
                    ],
                    "resourcecode": "Practitioner/10160",
                    "resourcerole": "Physician ",
                    "resourcetype": "Practitioner",
                    "slotID": [
                        3821397
                    ],
                    "start": 1695889800,
                    "status": true,
                    "supportingInformation": [],
                    "updatedate": 1695871886,
                    "updatedby": "",
                    "Practitioner": []
                },
                "mobileNo": "+65870065431",
                "index": 3,
                "status": true,
                "type": "frontdesk"
            },
            {
                "appointmentType": "walkin",
                "consultationMode": "Direct Consultation",
                "token": 339,
                "comment": "completed_assemble",
                "name": "Sunitha ",
                "isPatient": true,
                "personId": 874,
                "patient_id": "Person/10880",
                "patientId": 659,
                "pId": "Patient/10696",
                "mrn": "MRN00_565",
                "role": "Consultation",
                "encounter": "12264",
                "encounter_id": "Encounter/12264",
                "encounter_status": "Checked-in",
                "encounterId": 2275,
                "showBringtoTop": false,
                "profile_pic": "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
                "isEmergency": false,
                "start": "02:00 pm",
                "startTime": 1695889800,
                "info": {
                    "AppointmentNotification": [
                        {
                            "practitionerEmail": "",
                            "mobile": "+919788655956",
                            "patientEmail": ""
                        }
                    ],
                    "Description": "string",
                    "Healthservice": [],
                    "Person": [
                        {
                            "Id": 874,
                            "OrgID": [],
                            "Patient": [
                                {
                                    "OrgID": "",
                                    "PractitionerID": [],
                                    "_id": "Patient/10696",
                                    "_key": "10696",
                                    "_rev": "_gkTRoz2--E",
                                    "active": true,
                                    "activereason": "",
                                    "activestatus": true,
                                    "address": [],
                                    "age": "10",
                                    "age_type": "",
                                    "alias": "MRN00_565",
                                    "approxAgeEntered": false,
                                    "attachment": [],
                                    "birthDate": "02/07/2022",
                                    "birthGendercode": "",
                                    "birthPlace": "",
                                    "birthTime": 0,
                                    "bloodGroup": "",
                                    "cadavericDonor": false,
                                    "citizenshipCode": "",
                                    "communicationLanguage": [],
                                    "conditionhistory": [],
                                    "createdby": "",
                                    "createddate": 1657613154,
                                    "deceaseddate": 0,
                                    "deceasedreason": "",
                                    "disabilityCode": "",
                                    "ethinicityCode": "",
                                    "gender": "CodingMaster/10266",
                                    "genderIdentitycode": "",
                                    "id": 659,
                                    "identifier": [
                                        "IdentifierMaster/12933"
                                    ],
                                    "isdeceased": "",
                                    "link": [
                                        {
                                            "patient": [],
                                            "Type": ""
                                        }
                                    ],
                                    "maritalstatus": "",
                                    "name": [
                                        "HumanNameMaster/13265"
                                    ],
                                    "nationality": "SMGeographicMaster/10000",
                                    "patientImp_Code": "",
                                    "patientTypecode": "",
                                    "personID": 874,
                                    "photo": [],
                                    "preferredLanguage": "",
                                    "primarycare_Prac": "",
                                    "raceCode": "",
                                    "religionCode": "",
                                    "residencyStatus": false,
                                    "specialArng_code": "",
                                    "specialCourtesy_code": "",
                                    "statushistory": [
                                        {
                                            "oldstatus": "",
                                            "oldstatusreason": "",
                                            "newstatus": "Active",
                                            "fromperiod": 1657613154,
                                            "toperiod": 0
                                        }
                                    ],
                                    "telecom": [
                                        "ContactPointMaster/16378"
                                    ],
                                    "updatedate": 1657613154,
                                    "updatedby": ""
                                }
                            ],
                            "RelatedPersonID": [],
                            "_id": "Person/10880",
                            "_key": "10880",
                            "_rev": "_gkTSsty-_B",
                            "active": true,
                            "activestatus": true,
                            "address": [],
                            "alias": "PER00_540",
                            "birthDay": "02/07/2022",
                            "createdby": "",
                            "createddate": 1657613153,
                            "gender": "FEMALE",
                            "identifier": [
                                "IdentifierMaster/12932"
                            ],
                            "link": [],
                            "name": [
                                "HumanNameMaster/13264"
                            ],
                            "photo": [],
                            "telecom": [
                                {
                                    "_key": "16376",
                                    "_id": "ContactPointMaster/16376",
                                    "_rev": "_gkTUQay-_l",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1657613153,
                                    "id": 3751,
                                    "period": [
                                        "periodMaster/39650"
                                    ],
                                    "rank": "1",
                                    "system": "Phone",
                                    "updatedate": 1657613153,
                                    "updatedby": "",
                                    "use": "Mobile",
                                    "value": "+919788655956"
                                },
                                {
                                    "_key": "16377",
                                    "_id": "ContactPointMaster/16377",
                                    "_rev": "_gkTUQbW--i",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1657613153,
                                    "id": 3752,
                                    "period": [
                                        "periodMaster/39651"
                                    ],
                                    "rank": "2",
                                    "system": "Email",
                                    "updatedate": 1657613153,
                                    "updatedby": "",
                                    "use": "Work",
                                    "value": ""
                                }
                            ],
                            "updatedate": 1657613153,
                            "updatedby": "",
                            "HumanName": [
                                {
                                    "_id": "HumanNameMaster/13264",
                                    "_key": "13264",
                                    "_rev": "_edDvbBy--B",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1657613153,
                                    "family": "",
                                    "given": "",
                                    "id": 2497,
                                    "period": [
                                        "periodMaster/39649"
                                    ],
                                    "prefix": null,
                                    "suffix": null,
                                    "text": "Sunitha",
                                    "updatedate": 1657613153,
                                    "updatedby": "",
                                    "use": null
                                }
                            ]
                        }
                    ],
                    "PersonID": [
                        874
                    ],
                    "ServiceCategoryID": [],
                    "ServiceTypeID": [],
                    "SpecialtyID": [
                        456
                    ],
                    "_id": "Appointment/14017",
                    "_key": "14017",
                    "_rev": "_grlY7Ay---",
                    "activestatus": true,
                    "appno": "AP-339",
                    "appointmentReason": [],
                    "appointmentType": "walkin",
                    "appointmentTypeID": [],
                    "appstatus": "booked",
                    "avcpatientstatus": "YET TO LOGIN",
                    "basedOn": [],
                    "bookedby": "Practitioner/10008",
                    "cancelationReason": [],
                    "comment": "completed_assemble",
                    "consultationMode": "Direct Consultation",
                    "createdby": "",
                    "createddate": 1695872075,
                    "encounter": [
                        {
                            "Organization_id": 138,
                            "ServiceRequest_id": 0,
                            "_id": "Encounter/12264",
                            "_key": "12264",
                            "_rev": "_grlY6km--B",
                            "accompanied_person": [
                                {
                                    "id_type": "",
                                    "id": "",
                                    "name": "",
                                    "relationship": ""
                                }
                            ],
                            "account": [
                                {
                                    "accountid": "",
                                    "ChangeSeqNum": 0
                                }
                            ],
                            "activestatus": true,
                            "appointmentId": [
                                339
                            ],
                            "basedOn": "",
                            "cancel_date": 0,
                            "class": "CodingMaster/10049",
                            "classhistory": [
                                {
                                    "class": "",
                                    "period": ""
                                }
                            ],
                            "createdby": "",
                            "createddate": 1695872108,
                            "device_details": [
                                {
                                    "category": "",
                                    "type": "",
                                    "name": ""
                                }
                            ],
                            "diagnosis": [
                                {
                                    "condition": "",
                                    "use": "",
                                    "rank": ""
                                }
                            ],
                            "eSignature": "",
                            "encounter_no": "",
                            "encounterdate": 1695872109,
                            "encounterenddate": 1695925799,
                            "end_type": "",
                            "episodeOfCare_id": 0,
                            "files": [],
                            "followupDur": 0,
                            "followupReqd": false,
                            "followupUOM": "",
                            "health_of_service": [
                                {
                                    "category": "",
                                    "name": "",
                                    "type": ""
                                }
                            ],
                            "hospitalization": [
                                {
                                    "preAdmissionIdentifier": "",
                                    "origin": "",
                                    "admitSource": "",
                                    "reAdmission": "",
                                    "dietPreference": [],
                                    "specialCourtesy": [],
                                    "specialArrangement": [],
                                    "destination": "",
                                    "dischargeDisposition": ""
                                }
                            ],
                            "id": 2275,
                            "identifier": [
                                "IdentifierMaster/15973"
                            ],
                            "length": [
                                {
                                    "value": 0,
                                    "comparator": "",
                                    "unit": "",
                                    "system": "",
                                    "code": ""
                                }
                            ],
                            "location": [
                                {
                                    "location_id": "",
                                    "status": "",
                                    "period": "",
                                    "physicalType": ""
                                }
                            ],
                            "mode": "direct",
                            "partOf": "",
                            "participant": [
                                {
                                    "type": [
                                        "CodingMaster/12338"
                                    ],
                                    "period": "",
                                    "Practitioner_id": 152,
                                    "PractitionerRole_id": 387,
                                    "RelatedPerson_id": [
                                        {
                                            "relatedpersonid": "",
                                            "relationship": ""
                                        }
                                    ],
                                    "specialty": ""
                                }
                            ],
                            "participantHistory": [
                                {
                                    "participantType": "",
                                    "practroleId": "",
                                    "practitionerId": "",
                                    "specialty": "",
                                    "relatedPersonId": "",
                                    "relationship": "",
                                    "userId": "",
                                    "startTime": 0,
                                    "endTime": 0,
                                    "reasonCode": ""
                                }
                            ],
                            "patient_id": 659,
                            "period": "",
                            "plannedEndDate": 0,
                            "plannedStartDate": 0,
                            "practitioner_details": [
                                {
                                    "speciality": "CodingMaster/10456",
                                    "type": "",
                                    "id_name": "NURSE USER PC ",
                                    "clinic": "HEMODIALYSIS",
                                    "role": "Attender",
                                    "period": ""
                                }
                            ],
                            "priority": null,
                            "priorityreason": null,
                            "reasonCode": "",
                            "reasonReference": "CodingMaster/10063",
                            "relationship": "",
                            "revision_date": 0,
                            "serviceType": "First Visit",
                            "source": "",
                            "start_type": "Elective/Scheduled",
                            "status": {
                                "_key": "10725",
                                "_id": "CodingMaster/10725",
                                "_rev": "_e8UTuZi---",
                                "Type": "ENCSTATUS",
                                "activestatus": true,
                                "code": "ENCSTATUS003",
                                "createdby": "",
                                "createddate": 1636355434,
                                "display": "Checked-in",
                                "gmconfigvalues": {},
                                "id": 725,
                                "shortdesc": "Checked-in",
                                "status": true,
                                "updatedate": 1636355434,
                                "updatedby": ""
                            },
                            "statushistory": [
                                {
                                    "period": [
                                        "periodMaster/73038"
                                    ],
                                    "status": "CodingMaster/10725"
                                },
                                {
                                    "status": "CodingMaster/10725",
                                    "period": [
                                        "periodMaster/73039"
                                    ]
                                }
                            ],
                            "subject": "",
                            "type": [
                                "CodingMaster/10264"
                            ],
                            "updatedate": 1695872119,
                            "updatedby": "",
                            "visit_type": "First Visit"
                        }
                    ],
                    "end": 1695897000,
                    "id": 339,
                    "identifier": [],
                    "minutesDuration": 0,
                    "orgid": "",
                    "orgtype": "CodingMaster/10010",
                    "participant": [],
                    "patientInstruction": "",
                    "priority": 1,
                    "reasonCode": [],
                    "reasonReference": [],
                    "report_docs": [],
                    "requestedPeriod": [
                        "periodMaster/73035"
                    ],
                    "resourcecode": "Practitioner/10151",
                    "resourcerole": "Physician ",
                    "resourcetype": "Practitioner",
                    "slotID": [
                        3816757
                    ],
                    "start": 1695889800,
                    "status": true,
                    "supportingInformation": [],
                    "updatedate": 1695872119,
                    "updatedby": "",
                    "Practitioner": []
                },
                "mobileNo": "+919788655956",
                "index": 4,
                "status": true,
                "type": "frontdesk"
            },
            {
                "appointmentType": "walkin",
                "consultationMode": "Direct Consultation",
                "token": 340,
                "comment": "Checked-In",
                "name": "kumari ",
                "isPatient": true,
                "personId": 1325,
                "patient_id": "Person/11331",
                "patientId": 1059,
                "pId": "Patient/11097",
                "mrn": "MRN00_943",
                "role": "Consultation",
                "encounter": "",
                "encounter_id": "",
                "encounter_status": "",
                "encounterId": "",
                "showBringtoTop": true,
                "profile_pic": "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
                "isEmergency": false,
                "start": "02:00 pm",
                "startTime": 1695889800,
                "info": {
                    "AppointmentNotification": [
                        {
                            "practitionerEmail": "",
                            "mobile": "+917878787875",
                            "patientEmail": ""
                        }
                    ],
                    "Description": "string",
                    "Healthservice": [],
                    "Person": [
                        {
                            "Id": 1325,
                            "OrgID": [],
                            "Patient": [
                                {
                                    "OrgID": "",
                                    "PractitionerID": [],
                                    "_id": "Patient/11097",
                                    "_key": "11097",
                                    "_rev": "_gkTRoya--_",
                                    "active": true,
                                    "activereason": "",
                                    "activestatus": true,
                                    "address": [],
                                    "age": "28",
                                    "age_type": "CodingMaster/10241",
                                    "alias": "MRN00_943",
                                    "approxAgeEntered": false,
                                    "attachment": [],
                                    "birthDate": "01/07/1995",
                                    "birthGendercode": "",
                                    "birthPlace": "",
                                    "birthTime": 0,
                                    "bloodGroup": "",
                                    "cadavericDonor": false,
                                    "citizenshipCode": "",
                                    "communicationLanguage": [],
                                    "conditionhistory": [],
                                    "createdby": "",
                                    "createddate": 1689741323,
                                    "deceaseddate": 0,
                                    "deceasedreason": "",
                                    "disabilityCode": "",
                                    "ethinicityCode": "",
                                    "gender": "CodingMaster/10266",
                                    "genderIdentitycode": "",
                                    "id": 1059,
                                    "identifier": [
                                        "IdentifierMaster/14763"
                                    ],
                                    "isdeceased": "",
                                    "link": [
                                        {
                                            "patient": [],
                                            "Type": ""
                                        }
                                    ],
                                    "maritalstatus": "CodingMaster/10563",
                                    "name": [
                                        "HumanNameMaster/14622"
                                    ],
                                    "nationality": "SMGeographicMaster/10000",
                                    "patientImp_Code": "",
                                    "patientTypecode": "",
                                    "personID": 1325,
                                    "photo": [],
                                    "preferredLanguage": "",
                                    "primarycare_Prac": "",
                                    "raceCode": "",
                                    "religionCode": "",
                                    "residencyStatus": false,
                                    "specialArng_code": "",
                                    "specialCourtesy_code": "",
                                    "statushistory": [
                                        {
                                            "oldstatus": "",
                                            "oldstatusreason": "",
                                            "newstatus": "Active",
                                            "fromperiod": 1689741321,
                                            "toperiod": 0
                                        }
                                    ],
                                    "telecom": [
                                        "ContactPointMaster/18902"
                                    ],
                                    "updatedate": 1689741626,
                                    "updatedby": ""
                                }
                            ],
                            "RelatedPersonID": [],
                            "_id": "Person/11331",
                            "_key": "11331",
                            "_rev": "_gkTSssa--O",
                            "active": true,
                            "activestatus": true,
                            "address": [],
                            "alias": "PER00_989",
                            "birthDay": "01/07/1995",
                            "createdby": "",
                            "createddate": 1689741276,
                            "gender": "FEMALE",
                            "identifier": [],
                            "link": [],
                            "name": [
                                "HumanNameMaster/14623"
                            ],
                            "photo": [],
                            "telecom": [
                                {
                                    "_key": "18904",
                                    "_id": "ContactPointMaster/18904",
                                    "_rev": "_gkTUQUq--2",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1689741626,
                                    "id": 6270,
                                    "period": [
                                        "periodMaster/55957"
                                    ],
                                    "rank": "2",
                                    "system": "Email",
                                    "updatedate": 1689741626,
                                    "updatedby": "",
                                    "use": "Work",
                                    "value": ""
                                },
                                {
                                    "_key": "18903",
                                    "_id": "ContactPointMaster/18903",
                                    "_rev": "_gkTUQUq--i",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1689741626,
                                    "id": 6269,
                                    "period": [
                                        "periodMaster/55955"
                                    ],
                                    "rank": "1",
                                    "system": "Phone",
                                    "updatedate": 1689741626,
                                    "updatedby": "",
                                    "use": "Mobile",
                                    "value": "+917878787875"
                                }
                            ],
                            "updatedate": 1689741626,
                            "updatedby": "",
                            "HumanName": [
                                {
                                    "_id": "HumanNameMaster/14623",
                                    "_key": "14623",
                                    "_rev": "_gUvxBia--A",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1689741626,
                                    "family": "",
                                    "given": "",
                                    "id": 3852,
                                    "period": [
                                        "periodMaster/55954"
                                    ],
                                    "prefix": null,
                                    "suffix": null,
                                    "text": "kumari",
                                    "updatedate": 1689741626,
                                    "updatedby": "",
                                    "use": null
                                }
                            ]
                        }
                    ],
                    "PersonID": [
                        1325
                    ],
                    "ServiceCategoryID": [],
                    "ServiceTypeID": [],
                    "SpecialtyID": [
                        456
                    ],
                    "_id": "Appointment/14018",
                    "_key": "14018",
                    "_rev": "_grlfUbi--G",
                    "activestatus": true,
                    "appno": "AP-340",
                    "appointmentReason": [],
                    "appointmentType": "walkin",
                    "appointmentTypeID": [],
                    "appstatus": "booked",
                    "avcpatientstatus": "YET TO LOGIN",
                    "basedOn": [],
                    "bookedby": "Practitioner/10008",
                    "cancelationReason": [],
                    "comment": "Checked-In",
                    "consultationMode": "Direct Consultation",
                    "createdby": "",
                    "createddate": 1695872538,
                    "encounter": [],
                    "end": 1695897000,
                    "id": 340,
                    "identifier": [],
                    "minutesDuration": 0,
                    "orgid": "",
                    "orgtype": "CodingMaster/10010",
                    "participant": [],
                    "patientInstruction": "",
                    "priority": 1,
                    "reasonCode": [],
                    "reasonReference": [],
                    "report_docs": [],
                    "requestedPeriod": [
                        "periodMaster/73040"
                    ],
                    "resourcecode": "Practitioner/10159",
                    "resourcerole": "Physician ",
                    "resourcetype": "Practitioner",
                    "slotID": [
                        3820237
                    ],
                    "start": 1695889800,
                    "status": true,
                    "supportingInformation": [],
                    "updatedate": 1695872538,
                    "updatedby": "",
                    "Practitioner": []
                },
                "mobileNo": "+917878787875",
                "index": 5,
                "status": true,
                "type": "frontdesk"
            }
        ],
        "onHoldData": [],
        "previousData": [
            {
                "appointmentType": "walkin",
                "consultationMode": "Direct Consultation",
                "token": 337,
                "comment": "completed_assemble",
                "name": "Helen ",
                "isPatient": true,
                "personId": 1603,
                "patient_id": "Person/11609",
                "patientId": 1399,
                "pId": "Patient/11440",
                "mrn": "MRN00_274",
                "role": "Consultation",
                "encounter": "12263",
                "encounter_id": "Encounter/12263",
                "encounter_status": "Checked-in",
                "encounterId": 2274,
                "showBringtoTop": false,
                "profile_pic": "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
                "isEmergency": false,
                "start": "12:00 pm",
                "startTime": 1695882600,
                "info": {
                    "AppointmentNotification": [
                        {
                            "practitionerEmail": "",
                            "mobile": "+655600987056",
                            "patientEmail": ""
                        }
                    ],
                    "Description": "string",
                    "Healthservice": [],
                    "Person": [
                        {
                            "Id": 1603,
                            "OrgID": [],
                            "Patient": [
                                {
                                    "OrgID": "",
                                    "PractitionerID": [],
                                    "_id": "Patient/11440",
                                    "_key": "11440",
                                    "_rev": "_grSFcl---Q",
                                    "active": true,
                                    "activereason": "",
                                    "activestatus": true,
                                    "address": [],
                                    "age": "51",
                                    "age_type": "",
                                    "alias": "MRN00_274",
                                    "approxAgeEntered": false,
                                    "attachment": [],
                                    "birthDate": "01/09/1972",
                                    "birthGendercode": "",
                                    "birthPlace": "",
                                    "birthTime": 0,
                                    "bloodGroup": "",
                                    "cadavericDonor": false,
                                    "citizenshipCode": "",
                                    "communicationLanguage": [],
                                    "conditionhistory": [],
                                    "createdby": "",
                                    "createddate": 1695791151,
                                    "deceaseddate": 0,
                                    "deceasedreason": "",
                                    "disabilityCode": "",
                                    "ethinicityCode": "",
                                    "gender": "CodingMaster/10266",
                                    "genderIdentitycode": "",
                                    "id": 1399,
                                    "identifier": [
                                        "IdentifierMaster/15960"
                                    ],
                                    "isdeceased": "",
                                    "link": [
                                        {
                                            "patient": [],
                                            "Type": ""
                                        }
                                    ],
                                    "maritalstatus": "",
                                    "name": [
                                        "HumanNameMaster/15532"
                                    ],
                                    "nationality": "SMGeographicMaster/10056",
                                    "patientImp_Code": "",
                                    "patientTypecode": "",
                                    "personID": 1603,
                                    "photo": [],
                                    "preferredLanguage": "",
                                    "primarycare_Prac": "",
                                    "raceCode": "",
                                    "religionCode": "",
                                    "residencyStatus": false,
                                    "specialArng_code": "",
                                    "specialCourtesy_code": "",
                                    "statushistory": [
                                        {
                                            "oldstatus": "",
                                            "oldstatusreason": "",
                                            "newstatus": "Active",
                                            "fromperiod": 1695791150,
                                            "toperiod": 0
                                        }
                                    ],
                                    "telecom": [
                                        "ContactPointMaster/20613",
                                        "ContactPointMaster/20614"
                                    ],
                                    "updatedate": 1695791151,
                                    "updatedby": ""
                                }
                            ],
                            "RelatedPersonID": [],
                            "_id": "Person/11609",
                            "_key": "11609",
                            "_rev": "_grSFaY---C",
                            "active": true,
                            "activestatus": true,
                            "address": [],
                            "alias": "PER00_1267",
                            "birthDay": "01/09/1972",
                            "createdby": "",
                            "createddate": 1695791149,
                            "gender": "FEMALE",
                            "identifier": [
                                "IdentifierMaster/15959"
                            ],
                            "link": [],
                            "name": [
                                "HumanNameMaster/15531"
                            ],
                            "photo": [],
                            "telecom": [
                                {
                                    "_key": "20611",
                                    "_id": "ContactPointMaster/20611",
                                    "_rev": "_grSFaX6--G",
                                    "system": "Phone",
                                    "value": "+655600987056",
                                    "use": "Mobile",
                                    "rank": "1",
                                    "period": [
                                        "periodMaster/72975"
                                    ],
                                    "id": 7975,
                                    "createdby": "",
                                    "createddate": 1695791149,
                                    "updatedby": "",
                                    "updatedate": 1695791149,
                                    "activestatus": true
                                },
                                {
                                    "_key": "20612",
                                    "_id": "ContactPointMaster/20612",
                                    "_rev": "_grSFaX6--I",
                                    "system": "Email",
                                    "value": "helen@mail.com",
                                    "use": "Work",
                                    "rank": "2",
                                    "period": [
                                        "periodMaster/72976"
                                    ],
                                    "id": 7976,
                                    "createdby": "",
                                    "createddate": 1695791149,
                                    "updatedby": "",
                                    "updatedate": 1695791149,
                                    "activestatus": true
                                }
                            ],
                            "updatedate": 1695791149,
                            "updatedby": "",
                            "HumanName": [
                                {
                                    "_id": "HumanNameMaster/15531",
                                    "_key": "15531",
                                    "_rev": "_grSFaX6--A",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1695791149,
                                    "family": "",
                                    "given": "",
                                    "id": 4758,
                                    "period": [
                                        "periodMaster/72974"
                                    ],
                                    "prefix": null,
                                    "suffix": null,
                                    "text": "Helen",
                                    "updatedate": 1695791149,
                                    "updatedby": "",
                                    "use": null
                                }
                            ]
                        }
                    ],
                    "PersonID": [
                        1603
                    ],
                    "ServiceCategoryID": [],
                    "ServiceTypeID": [],
                    "SpecialtyID": [
                        456
                    ],
                    "_id": "Appointment/14015",
                    "_key": "14015",
                    "_rev": "_grlW8jK---",
                    "activestatus": true,
                    "appno": "AP-337",
                    "appointmentReason": [],
                    "appointmentType": "walkin",
                    "appointmentTypeID": [],
                    "appstatus": "booked",
                    "avcpatientstatus": "YET TO LOGIN",
                    "basedOn": [],
                    "bookedby": "Practitioner/10008",
                    "cancelationReason": [],
                    "comment": "completed_assemble",
                    "consultationMode": "Direct Consultation",
                    "createdby": "",
                    "createddate": 1695871807,
                    "encounter": [
                        {
                            "Organization_id": 138,
                            "ServiceRequest_id": 0,
                            "_id": "Encounter/12263",
                            "_key": "12263",
                            "_rev": "_grlW8IO--A",
                            "accompanied_person": [
                                {
                                    "id_type": "",
                                    "id": "",
                                    "name": "",
                                    "relationship": ""
                                }
                            ],
                            "account": [
                                {
                                    "accountid": "",
                                    "ChangeSeqNum": 0
                                }
                            ],
                            "activestatus": true,
                            "appointmentId": [
                                337
                            ],
                            "basedOn": "",
                            "cancel_date": 0,
                            "class": "CodingMaster/10049",
                            "classhistory": [
                                {
                                    "class": "",
                                    "period": ""
                                }
                            ],
                            "createdby": "",
                            "createddate": 1695871980,
                            "device_details": [
                                {
                                    "category": "",
                                    "type": "",
                                    "name": ""
                                }
                            ],
                            "diagnosis": [
                                {
                                    "condition": "",
                                    "use": "",
                                    "rank": ""
                                }
                            ],
                            "eSignature": "",
                            "encounter_no": "",
                            "encounterdate": 1695871980,
                            "encounterenddate": 1695925799,
                            "end_type": "",
                            "episodeOfCare_id": 0,
                            "files": [],
                            "followupDur": 0,
                            "followupReqd": false,
                            "followupUOM": "",
                            "health_of_service": [
                                {
                                    "category": "",
                                    "name": "",
                                    "type": ""
                                }
                            ],
                            "hospitalization": [
                                {
                                    "preAdmissionIdentifier": "",
                                    "origin": "",
                                    "admitSource": "",
                                    "reAdmission": "",
                                    "dietPreference": [],
                                    "specialCourtesy": [],
                                    "specialArrangement": [],
                                    "destination": "",
                                    "dischargeDisposition": ""
                                }
                            ],
                            "id": 2274,
                            "identifier": [
                                "IdentifierMaster/15972"
                            ],
                            "length": [
                                {
                                    "value": 0,
                                    "comparator": "",
                                    "unit": "",
                                    "system": "",
                                    "code": ""
                                }
                            ],
                            "location": [
                                {
                                    "location_id": "",
                                    "status": "",
                                    "period": "",
                                    "physicalType": ""
                                }
                            ],
                            "mode": "direct",
                            "partOf": "",
                            "participant": [
                                {
                                    "type": [
                                        "CodingMaster/12338"
                                    ],
                                    "period": "",
                                    "Practitioner_id": 159,
                                    "PractitionerRole_id": 376,
                                    "RelatedPerson_id": [
                                        {
                                            "relatedpersonid": "",
                                            "relationship": ""
                                        }
                                    ],
                                    "specialty": ""
                                }
                            ],
                            "participantHistory": [
                                {
                                    "participantType": "",
                                    "practroleId": "",
                                    "practitionerId": "",
                                    "specialty": "",
                                    "relatedPersonId": "",
                                    "relationship": "",
                                    "userId": "",
                                    "startTime": 0,
                                    "endTime": 0,
                                    "reasonCode": ""
                                }
                            ],
                            "patient_id": 1399,
                            "period": "",
                            "plannedEndDate": 0,
                            "plannedStartDate": 0,
                            "practitioner_details": [
                                {
                                    "speciality": "CodingMaster/10456",
                                    "type": "Doctor",
                                    "id_name": "..HDBedOne Machine ONE -",
                                    "clinic": "HEMODIALYSIS",
                                    "role": "Physician ",
                                    "period": ""
                                }
                            ],
                            "priority": null,
                            "priorityreason": null,
                            "reasonCode": "",
                            "reasonReference": "CodingMaster/10063",
                            "relationship": "",
                            "revision_date": 0,
                            "serviceType": "Followup Visit ",
                            "source": "",
                            "start_type": "Elective/Scheduled",
                            "status": {
                                "_key": "10725",
                                "_id": "CodingMaster/10725",
                                "_rev": "_e8UTuZi---",
                                "Type": "ENCSTATUS",
                                "activestatus": true,
                                "code": "ENCSTATUS003",
                                "createdby": "",
                                "createddate": 1636355434,
                                "display": "Checked-in",
                                "gmconfigvalues": {},
                                "id": 725,
                                "shortdesc": "Checked-in",
                                "status": true,
                                "updatedate": 1636355434,
                                "updatedby": ""
                            },
                            "statushistory": [
                                {
                                    "period": [
                                        "periodMaster/73033"
                                    ],
                                    "status": "CodingMaster/10725"
                                },
                                {
                                    "status": "CodingMaster/10725",
                                    "period": [
                                        "periodMaster/73034"
                                    ]
                                }
                            ],
                            "subject": "",
                            "type": [
                                "CodingMaster/10264"
                            ],
                            "updatedate": 1695871989,
                            "updatedby": "",
                            "visit_type": "Followup Visit "
                        }
                    ],
                    "end": 1695889800,
                    "id": 337,
                    "identifier": [],
                    "minutesDuration": 0,
                    "orgid": "",
                    "orgtype": "CodingMaster/10010",
                    "participant": [],
                    "patientInstruction": "",
                    "priority": 1,
                    "reasonCode": [],
                    "reasonReference": [],
                    "report_docs": [],
                    "requestedPeriod": [
                        "periodMaster/73029"
                    ],
                    "resourcecode": "Practitioner/10158",
                    "resourcerole": "Physician ",
                    "resourcetype": "Practitioner",
                    "slotID": [
                        3819076
                    ],
                    "start": 1695882600,
                    "status": true,
                    "supportingInformation": [],
                    "updatedate": 1695871990,
                    "updatedby": "",
                    "Practitioner": []
                },
                "mobileNo": "+655600987056",
                "index": 2,
                "status": true,
                "type": "frontdesk"
            },
            {
                "appointmentType": "walkin",
                "consultationMode": "Direct Consultation",
                "token": 339,
                "comment": "completed_assemble",
                "name": "Sunitha ",
                "isPatient": true,
                "personId": 874,
                "patient_id": "Person/10880",
                "patientId": 659,
                "pId": "Patient/10696",
                "mrn": "MRN00_565",
                "role": "Consultation",
                "encounter": "12264",
                "encounter_id": "Encounter/12264",
                "encounter_status": "Checked-in",
                "encounterId": 2275,
                "showBringtoTop": false,
                "profile_pic": "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
                "isEmergency": false,
                "start": "02:00 pm",
                "startTime": 1695889800,
                "info": {
                    "AppointmentNotification": [
                        {
                            "practitionerEmail": "",
                            "mobile": "+919788655956",
                            "patientEmail": ""
                        }
                    ],
                    "Description": "string",
                    "Healthservice": [],
                    "Person": [
                        {
                            "Id": 874,
                            "OrgID": [],
                            "Patient": [
                                {
                                    "OrgID": "",
                                    "PractitionerID": [],
                                    "_id": "Patient/10696",
                                    "_key": "10696",
                                    "_rev": "_gkTRoz2--E",
                                    "active": true,
                                    "activereason": "",
                                    "activestatus": true,
                                    "address": [],
                                    "age": "10",
                                    "age_type": "",
                                    "alias": "MRN00_565",
                                    "approxAgeEntered": false,
                                    "attachment": [],
                                    "birthDate": "02/07/2022",
                                    "birthGendercode": "",
                                    "birthPlace": "",
                                    "birthTime": 0,
                                    "bloodGroup": "",
                                    "cadavericDonor": false,
                                    "citizenshipCode": "",
                                    "communicationLanguage": [],
                                    "conditionhistory": [],
                                    "createdby": "",
                                    "createddate": 1657613154,
                                    "deceaseddate": 0,
                                    "deceasedreason": "",
                                    "disabilityCode": "",
                                    "ethinicityCode": "",
                                    "gender": "CodingMaster/10266",
                                    "genderIdentitycode": "",
                                    "id": 659,
                                    "identifier": [
                                        "IdentifierMaster/12933"
                                    ],
                                    "isdeceased": "",
                                    "link": [
                                        {
                                            "patient": [],
                                            "Type": ""
                                        }
                                    ],
                                    "maritalstatus": "",
                                    "name": [
                                        "HumanNameMaster/13265"
                                    ],
                                    "nationality": "SMGeographicMaster/10000",
                                    "patientImp_Code": "",
                                    "patientTypecode": "",
                                    "personID": 874,
                                    "photo": [],
                                    "preferredLanguage": "",
                                    "primarycare_Prac": "",
                                    "raceCode": "",
                                    "religionCode": "",
                                    "residencyStatus": false,
                                    "specialArng_code": "",
                                    "specialCourtesy_code": "",
                                    "statushistory": [
                                        {
                                            "oldstatus": "",
                                            "oldstatusreason": "",
                                            "newstatus": "Active",
                                            "fromperiod": 1657613154,
                                            "toperiod": 0
                                        }
                                    ],
                                    "telecom": [
                                        "ContactPointMaster/16378"
                                    ],
                                    "updatedate": 1657613154,
                                    "updatedby": ""
                                }
                            ],
                            "RelatedPersonID": [],
                            "_id": "Person/10880",
                            "_key": "10880",
                            "_rev": "_gkTSsty-_B",
                            "active": true,
                            "activestatus": true,
                            "address": [],
                            "alias": "PER00_540",
                            "birthDay": "02/07/2022",
                            "createdby": "",
                            "createddate": 1657613153,
                            "gender": "FEMALE",
                            "identifier": [
                                "IdentifierMaster/12932"
                            ],
                            "link": [],
                            "name": [
                                "HumanNameMaster/13264"
                            ],
                            "photo": [],
                            "telecom": [
                                {
                                    "_key": "16376",
                                    "_id": "ContactPointMaster/16376",
                                    "_rev": "_gkTUQay-_l",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1657613153,
                                    "id": 3751,
                                    "period": [
                                        "periodMaster/39650"
                                    ],
                                    "rank": "1",
                                    "system": "Phone",
                                    "updatedate": 1657613153,
                                    "updatedby": "",
                                    "use": "Mobile",
                                    "value": "+919788655956"
                                },
                                {
                                    "_key": "16377",
                                    "_id": "ContactPointMaster/16377",
                                    "_rev": "_gkTUQbW--i",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1657613153,
                                    "id": 3752,
                                    "period": [
                                        "periodMaster/39651"
                                    ],
                                    "rank": "2",
                                    "system": "Email",
                                    "updatedate": 1657613153,
                                    "updatedby": "",
                                    "use": "Work",
                                    "value": ""
                                }
                            ],
                            "updatedate": 1657613153,
                            "updatedby": "",
                            "HumanName": [
                                {
                                    "_id": "HumanNameMaster/13264",
                                    "_key": "13264",
                                    "_rev": "_edDvbBy--B",
                                    "activestatus": true,
                                    "createdby": "",
                                    "createddate": 1657613153,
                                    "family": "",
                                    "given": "",
                                    "id": 2497,
                                    "period": [
                                        "periodMaster/39649"
                                    ],
                                    "prefix": null,
                                    "suffix": null,
                                    "text": "Sunitha",
                                    "updatedate": 1657613153,
                                    "updatedby": "",
                                    "use": null
                                }
                            ]
                        }
                    ],
                    "PersonID": [
                        874
                    ],
                    "ServiceCategoryID": [],
                    "ServiceTypeID": [],
                    "SpecialtyID": [
                        456
                    ],
                    "_id": "Appointment/14017",
                    "_key": "14017",
                    "_rev": "_grlY7Ay---",
                    "activestatus": true,
                    "appno": "AP-339",
                    "appointmentReason": [],
                    "appointmentType": "walkin",
                    "appointmentTypeID": [],
                    "appstatus": "booked",
                    "avcpatientstatus": "YET TO LOGIN",
                    "basedOn": [],
                    "bookedby": "Practitioner/10008",
                    "cancelationReason": [],
                    "comment": "completed_assemble",
                    "consultationMode": "Direct Consultation",
                    "createdby": "",
                    "createddate": 1695872075,
                    "encounter": [
                        {
                            "Organization_id": 138,
                            "ServiceRequest_id": 0,
                            "_id": "Encounter/12264",
                            "_key": "12264",
                            "_rev": "_grlY6km--B",
                            "accompanied_person": [
                                {
                                    "id_type": "",
                                    "id": "",
                                    "name": "",
                                    "relationship": ""
                                }
                            ],
                            "account": [
                                {
                                    "accountid": "",
                                    "ChangeSeqNum": 0
                                }
                            ],
                            "activestatus": true,
                            "appointmentId": [
                                339
                            ],
                            "basedOn": "",
                            "cancel_date": 0,
                            "class": "CodingMaster/10049",
                            "classhistory": [
                                {
                                    "class": "",
                                    "period": ""
                                }
                            ],
                            "createdby": "",
                            "createddate": 1695872108,
                            "device_details": [
                                {
                                    "category": "",
                                    "type": "",
                                    "name": ""
                                }
                            ],
                            "diagnosis": [
                                {
                                    "condition": "",
                                    "use": "",
                                    "rank": ""
                                }
                            ],
                            "eSignature": "",
                            "encounter_no": "",
                            "encounterdate": 1695872109,
                            "encounterenddate": 1695925799,
                            "end_type": "",
                            "episodeOfCare_id": 0,
                            "files": [],
                            "followupDur": 0,
                            "followupReqd": false,
                            "followupUOM": "",
                            "health_of_service": [
                                {
                                    "category": "",
                                    "name": "",
                                    "type": ""
                                }
                            ],
                            "hospitalization": [
                                {
                                    "preAdmissionIdentifier": "",
                                    "origin": "",
                                    "admitSource": "",
                                    "reAdmission": "",
                                    "dietPreference": [],
                                    "specialCourtesy": [],
                                    "specialArrangement": [],
                                    "destination": "",
                                    "dischargeDisposition": ""
                                }
                            ],
                            "id": 2275,
                            "identifier": [
                                "IdentifierMaster/15973"
                            ],
                            "length": [
                                {
                                    "value": 0,
                                    "comparator": "",
                                    "unit": "",
                                    "system": "",
                                    "code": ""
                                }
                            ],
                            "location": [
                                {
                                    "location_id": "",
                                    "status": "",
                                    "period": "",
                                    "physicalType": ""
                                }
                            ],
                            "mode": "direct",
                            "partOf": "",
                            "participant": [
                                {
                                    "type": [
                                        "CodingMaster/12338"
                                    ],
                                    "period": "",
                                    "Practitioner_id": 152,
                                    "PractitionerRole_id": 387,
                                    "RelatedPerson_id": [
                                        {
                                            "relatedpersonid": "",
                                            "relationship": ""
                                        }
                                    ],
                                    "specialty": ""
                                }
                            ],
                            "participantHistory": [
                                {
                                    "participantType": "",
                                    "practroleId": "",
                                    "practitionerId": "",
                                    "specialty": "",
                                    "relatedPersonId": "",
                                    "relationship": "",
                                    "userId": "",
                                    "startTime": 0,
                                    "endTime": 0,
                                    "reasonCode": ""
                                }
                            ],
                            "patient_id": 659,
                            "period": "",
                            "plannedEndDate": 0,
                            "plannedStartDate": 0,
                            "practitioner_details": [
                                {
                                    "speciality": "CodingMaster/10456",
                                    "type": "",
                                    "id_name": "NURSE USER PC ",
                                    "clinic": "HEMODIALYSIS",
                                    "role": "Attender",
                                    "period": ""
                                }
                            ],
                            "priority": null,
                            "priorityreason": null,
                            "reasonCode": "",
                            "reasonReference": "CodingMaster/10063",
                            "relationship": "",
                            "revision_date": 0,
                            "serviceType": "First Visit",
                            "source": "",
                            "start_type": "Elective/Scheduled",
                            "status": {
                                "_key": "10725",
                                "_id": "CodingMaster/10725",
                                "_rev": "_e8UTuZi---",
                                "Type": "ENCSTATUS",
                                "activestatus": true,
                                "code": "ENCSTATUS003",
                                "createdby": "",
                                "createddate": 1636355434,
                                "display": "Checked-in",
                                "gmconfigvalues": {},
                                "id": 725,
                                "shortdesc": "Checked-in",
                                "status": true,
                                "updatedate": 1636355434,
                                "updatedby": ""
                            },
                            "statushistory": [
                                {
                                    "period": [
                                        "periodMaster/73038"
                                    ],
                                    "status": "CodingMaster/10725"
                                },
                                {
                                    "status": "CodingMaster/10725",
                                    "period": [
                                        "periodMaster/73039"
                                    ]
                                }
                            ],
                            "subject": "",
                            "type": [
                                "CodingMaster/10264"
                            ],
                            "updatedate": 1695872119,
                            "updatedby": "",
                            "visit_type": "First Visit"
                        }
                    ],
                    "end": 1695897000,
                    "id": 339,
                    "identifier": [],
                    "minutesDuration": 0,
                    "orgid": "",
                    "orgtype": "CodingMaster/10010",
                    "participant": [],
                    "patientInstruction": "",
                    "priority": 1,
                    "reasonCode": [],
                    "reasonReference": [],
                    "report_docs": [],
                    "requestedPeriod": [
                        "periodMaster/73035"
                    ],
                    "resourcecode": "Practitioner/10151",
                    "resourcerole": "Physician ",
                    "resourcetype": "Practitioner",
                    "slotID": [
                        3816757
                    ],
                    "start": 1695889800,
                    "status": true,
                    "supportingInformation": [],
                    "updatedate": 1695872119,
                    "updatedby": "",
                    "Practitioner": []
                },
                "mobileNo": "+919788655956",
                "index": 4,
                "status": true,
                "type": "frontdesk"
            }
        ],
        "displayData": [],
        "displayPreviousData": []
    },
    "viatalsContext": {
        "list": [],
        "editIndex": null
    },
    "treatment": {
        "list": [],
        "child": {},
        "editIndex": null
    },
    "LabOrder": {
        "list": [],
        "child": {},
        "editIndex": null
    },
    "radiology": {
        "list": [],
        "editIndex": null
    },
    "role": {
        "roleName": "front desk",
        "data": {
            "front desk": {
                "access": [
                    "/avc_landing",
                    "/avc_meeting",
                    "/avc_support",
                    "/appointment_confirmation",
                    "/patient_dashboard",
                    "/appointmentStats",
                    "/avc_meeting",
                    "/avc_landing",
                    "/avc_login",
                    "/avc_support",
                    "/appointment_booking",
                    "/cancel_visit",
                    "/currentVisitStats",
                    "/dashboard",
                    "/day-end-process",
                    "/appointment_booking",
                    "/doctor_consultation",
                    "/financial_registration_form",
                    "/modify_visit",
                    "/profile",
                    "/patient_detail",
                    "/patient_dashboard",
                    "/patient_record",
                    "/pendingTasks",
                    "",
                    "/referral_registration_form",
                    "/assemble-patient-form",
                    "",
                    "/view_report",
                    "/visit_registration_form"
                ]
            }
        }
    },
    "Doccument": {
        "Doccument": {
            "list": [],
            "child": {},
            "editIndex": null
        }
    },
    "AVCShowList": {
        "avcShowList": {
            "avcList": {}
        }
    },
    "AVCMeeting": {
        "open": false,
        "direction": "right",
        "variant": "temporary",
        "user_name": "",
        "isLarge": null,
        "jwt": "",
        "RoomName": "",
        "component": {
            "key": null,
            "ref": null,
            "props": {
                "children": "Hai"
            },
            "_owner": null,
            "_store": {}
        },
        "pos": {
            "top": 250,
            "left": 150
        },
        "dragging": false,
        "rel": null,
        "domain": ""
    },
    "PushNotification": {
        "token": "",
        "isTokenFound": false,
        "messaging": null
    }
}
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    selectBorderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
  };
    const { applicable } = props?.financialData;

  const [financialDatas, setFinancialDatas] = useState({ ...props?.financialData });

  const setDetails = (saveTo,name, value) => {
    let patientData = saveTo
    patientData.applicable[name] = value;
    // patientData.setFinancialData({
    //   ...patientData,
    // });
    setFinancialDatas({ ...patientData });
    props?.setFinancialData({ ...patientData });
  };
  const FlterOptions = (type) => {
    const { allMasters } = props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
      };
      lista.push(datatemp);
    }
    return lista;
  };
  const { labelStyle, selectBorderStyle } = styles;
  const { parent_id } = props;

  return (
    <Div
      id={`${parent_id}_parent_div`}
      className="pv-root"
      style={{
        margin: "3px 15px",
      }}
    >
      <Paper id={`${parent_id}_parent_paper`} className="pl-root">
        <Row id={`${parent_id}_parent_row`} className="en-actions-root">
          <Col
            id={`${parent_id}_parent_col`}
            md={12}
            lg={12}
            sm={12}
            xs={12}
            className="no-padding-left"
          >
            <Div id={`${parent_id}_APPLICABLE_title_div`}>
              <H6
                id={`${parent_id}_APPLICABLE_label_h6`}
                className="en-title space-padding-bottom"
              >
                APPLICABLE CRITERIA
              </H6>
            </Div>
           <Row
              id={`${parent_id}_APPLICABLE_parent_row`}
              className="en-actions-root"
            >
              <Col
                id={`${parent_id}_APPLICABLE_parent_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                
                <Select
                  id={`${parent_id}_APPLICABLE_Encounter_select`}
                  value={applicable?.encounter?.value}
                  options={[
                    { value: "This Encounter Only" },
                    { value: "This Episode Of Care Only" },
                    { value: "Any" },
                  ]}
                  placeholder="Encounter"
                  onChangeValue={(e) => setDetails(financialDatas,"encounter", e)}
                  label="Encounter"
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={selectBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
              <Col
                id={`${parent_id}_APPLICABLE_Encounter_type_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <TextInput
                  id={`${parent_id}_APPLICABLE_Encounter_type_textinput`}
                  value={applicable.encounter_type}
                  placeholder="Encounter type"
                  onChange={(e) => setDetails(financialDatas,"encounter_type", e.target.value)}
                  label="Encounter Type"
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={selectBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
              <Col
                id={`${parent_id}_APPLICABLE_Speciality_col`}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Select
                  id={`${parent_id}_APPLICABLE_Speciality_select`}
                  value={applicable.speciality?.value}
                  options={FlterOptions("SPECIALTY")}
                  onChangeValue={(e) => setDetails(financialDatas,"speciality", e)}
                  label="Specialty"
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={selectBorderStyle}
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Paper>
    </Div>
  );
}

export default withAllContexts(ApplicableCriteria);
