import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    padding: 16,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  body: {
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
  label: {
    "& span": {
      fontSize: 12,
    },
    marginBottom: "16px",
  },
  guestTitle: {
    fontSize: 20,
    fontFamily: "pc_semibold",
    letterSpacing: 0,
    lineHeight: 1,
  },
  termsAndConditions: {
    "& .termsConditionsCheckbox": {
      borderRadius: 2,
    },
    "& .termsConditionsLabel": {
      flex: 1,
    },
  },
  monthCalendar: {
    "& strong": {
      fontSize: 13,
    },
    "& span": {
      fontSize: 13,
    },
    backgroundColor: "#fff",
  },
  clear: {
    backgroundColor: "transparent",
    border: "none",
    padding: "9px 12px",
    cursor: "pointer",
    color: "#6F6F6F",
    fontSize: 14,
  },
  apply: {
    backgroundColor: "transparent",
    border: "none",
    padding: "9px 12px",
    cursor: "pointer",
    color: "#0071F2",
    fontSize: 14,
  },
  divider: {
    minWidth: "0px",
    marginRight: "10px",
    marginLeft: "10px",
    color: "#B6B6B6",
    marginBottom: 0,
    marginTop: 0,
  },
  earliestInput: {
    fontFamily: "pc_regular",
    fontSize: 14,
    "&::placeholder": {
      fontFamily: "pc_regular",
      fontSize: 14,
      color: "#b6b6b6",
    },
  },
  textField: {
    "& input": {
      color: "#101010",
      fontSize: "14px",
      border: "1px solid #E0E0E0",
      fontFamily: "pc_medium",
      borderRadius: 8,
    },
    "& input::placeholder": {
      color: "#B6B6B6",
    },
  },
  tapSelect: {
    "& .selectionDiv": {
      height: 40,
      borderRadius: 8,
      minWidth: 40,
      margin: "8px 8px 0px 0px",
      borderColor: "#E0E0E0",
      "&:last-of-type": {
        marginRight: 0,
      },
      "& span": {
        fontFamily: "pc_regular",
        fontSize: 14,
      },
    },
    "& .active": {
      "& span": {
        fontFamily: "pc_medium !important",
      },
    },
  },
  mobile: {
    marginBottom: 16,
    "& .callingCodes,input": {
      fontSize: 14,
      color: "#101010",
      fontFamily: "pc_medium",
      letterSpacing: "-0.34px",
      fontWeight: "normal",
    },
    "& input::placeholder": {
      fontFamily: "pc_regular",
    },
    // '& #addmember_form_mobilenumberwithcode':{
    //   borderColor:'#E0E0E0'
    // },
    "& .mobileCountryDivider": {
      borderColor: "#E0E0E0",
    },
  },
}));
