import React from "react";
import { Div, Text, Icons, Divider, H6 } from "qdm-component-library";
import { makeStyles, Popover } from "@material-ui/core";
import { UIColor } from "../../../themes/theme";
const statusColors = {
  Active: UIColor.success.color,
  Recurrence: UIColor.primaryColor.color,
  Relapse: "#5B12BB",
  Inactive: UIColor.secondaryColor.color,
  Remission: UIColor.error.color,
  Resolved: UIColor.secondaryText.color,
};

const useStyles = makeStyles((theme) => ({
  bulletWrapper: (props) => ({
    width: 15,
    height: 15,
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: props?.hoverColor ?? "#CDF0C1",
    },
  }),
  bullet: (props) => ({
    width: 6.5,
    height: 6.5,
    borderRadius: "50%",
    backgroundColor: props?.bulletColor ?? "#38C20A",
  }),
  body: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: 5,
    "& .showWhenHover": {
      display: "none",
    },
    "&:hover": {
      backgroundColor: "#F9F9F9",
      "& .showWhenHover": {
        display: "flex",
      },
    },
  },
}));

export const Body = (props) => {
  const {
    value = "",
    isNew = false,
    isModified = false,
    showDivider = false,
    subData = [],
    showSubData = false,
    id = null,
    editList = () => {},
  } = props;
  const classes = useStyles(props);

  const styles = {
    rowFlex: {
      display: "flex",
      alignItems: "center",
    },
    valueText: {
      fontSize: 14,
    },
    newSection: {
      backgroundColor: "#CCE3FC",
      borderRadius: 4,
      marginLeft: 5,
      padding: "3px 5px",
      display: "flex",
      alignItems: "center",
    },
    divider: {
      margin: "2px 0px",
      height: "0.1px",
    },
    span: {
      fontSize: 12,
      marginLeft: 5,
    },
    changeStatusDiv: {
      display: "flex",
      alignItems: "center",
      borderRadius: 5,
      padding: "5px 10px",
      cursor: "pointer",
    },
    popoverStyle: {
      padding: 10,
    },
    h6: {
      fontSize: 14,
      margin: 0,
      marginBottom: 5,
    },
    bullet: {
      width: 6.5,
      height: 6.5,
      borderRadius: "50%",
    },
  };

  const statusColor = (status) => {
    switch (status) {
      case "mild":
        return "#0F79F3";
      case "severe":
        return "red";
      case "moderate":
        return "#F9B65F";
      case "inActive":
        return "#F58B00";
      case "Inactive":
        return "#F58B00";
      case "resolved":
        return "#6F6F6F";
      case "active":
        return "#38C20A";
      default:
        return "#000";
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openPopover = Boolean(anchorEl);
  const id_pop = openPopover ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const statusCode = [
    {
      color: "#F58B00",
      value: props?.old_status,
      backgroundColor: "#FEF3E5",
    },
    {
      color: "#6F6F6F",
      value: props?.new_status,
      backgroundColor: "#F0F0F0",
    },
  ];

  const { parent_id } = props;

  return (
    <Div id={`${parent_id}_body_parent_div`}>
      <Div id={`${parent_id}_body_parent_sub_div`} className={classes.body}>
        <Div id={`${parent_id}_body_new_status_div`} style={{ flex: 1 }}>
          <Div
            id={`${parent_id}_body_${value?.replaceAll(" ", "_")}_div`}
            style={{ ...styles.rowFlex }}
          >
            <Div
              id={`${parent_id}_body_${value?.replaceAll(" ", "_")}_sub_div`}
              style={{ ...styles.rowFlex, flex: 1 }}
            >
              <Div
                id={`${parent_id}_body_${value?.replaceAll(
                  " ",
                  "_"
                )}_new_status_div`}
                onClick={(e) => handleClick(e)}
                className={classes.bulletWrapper}
              >
                <Div
                  className={classes.bullet}
                  style={{ backgroundColor: statusColors[props.new_status] }}
                />
              </Div>
              <Text
                id={`${parent_id}_body_${value?.replaceAll(" ", "_")}_text`}
                style={styles.valueText}
              >
                {value}
              </Text>
              {isNew && (
                <Div id={`${parent_id}_body_New_div`} style={styles.newSection}>
                  <Text
                    id={`${parent_id}_body_New_text`}
                    style={{ fontSize: 10 }}
                  >
                    New
                  </Text>
                </Div>
              )}
              {isModified && (
                <Div
                  id={`${parent_id}_body_Modified_div`}
                  style={styles.newSection}
                >
                  <Text
                    id={`${parent_id}_body_Modified_text`}
                    style={{ fontSize: 10 }}
                  >
                    Modified
                  </Text>
                </Div>
              )}
            </Div>
          </Div>
          {showSubData && subData && subData?.length > 0 && (
            <Div
              id={`${parent_id}_body_subdata_div`}
              style={{ ...styles.rowFlex, marginTop: 5, marginLeft: 3 }}
            >
              {subData?.map((l, i) => {
                return (
                  <Div
                    id={`${parent_id}_body_${l?.value}_${i}_div`}
                    style={{
                      ...styles.rowFlex,
                      marginRight: l?.showDot ? 0 : 5,
                    }}
                  >
                    {l?.showDot && "•"}
                    {l?.icon && l?.icon}
                    {l?.value && (
                      <Text
                        id={`${parent_id}_body_${l?.value}_${i}_text`}
                        style={{
                          ...styles.span,
                          color: statusColor(l?.status),
                          marginRight: l?.showDot ? 5 : 0,
                        }}
                      >
                        {l?.value?.display || l?.value}
                      </Text>
                    )}
                  </Div>
                );
              })}
            </Div>
          )}
        </Div>
        <Div
          id={`${parent_id}_body_pencil_div`}
          className="showWhenHover"
          onClick={() => editList(id, props.index)}
        >
          <Icons id={`${parent_id}_body_pencil_edit_icons`} fontIcon="pencil" />
        </Div>
      </Div>
      {showDivider && (
        <Divider
          id={`${parent_id}_body_pencil_bottom_divider`}
          style={styles.divider}
        />
      )}
      <Popover
        id={id_pop}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Div
          id={`${parent_id}_body_Change_Status_div`}
          style={styles.popoverStyle}
        >
          <H6 id={`${parent_id}_body_Change_Status_h6`} style={styles.h6}>
            Change Status
          </H6>
          <Div
            id={`${parent_id}_body_Change_Status_sub_div`}
            style={styles.rowFlex}
          >
            {statusCode &&
              statusCode.map((l, i) => {
                return l?.value ? (
                  <Div
                    id={`${parent_id}_body_${l?.value?.replaceAll(
                      " ",
                      "_"
                    )}_${i}_div`}
                    style={{
                      ...styles.changeStatusDiv,
                      backgroundColor: l?.backgroundColor,
                      marginRight: i < statusCode.length - 1 ? 5 : 0,
                    }}
                    onClick={handleClose}
                  >
                    <Div
                      id={`${parent_id}_body_${l?.value?.replaceAll(
                        " ",
                        "_"
                      )}_${i}_sub_div`}
                      style={{ ...styles.bullet, backgroundColor: l?.color }}
                    />
                    <H6
                      id={`${parent_id}_body_${l?.value?.replaceAll(
                        " ",
                        "_"
                      )}_${i}_h6`}
                      style={styles.span}
                    >
                      {l?.value}
                    </H6>
                  </Div>
                ) : (
                  <React.Fragment />
                );
              })}
          </Div>
        </Div>
      </Popover>
    </Div>
  );
};
