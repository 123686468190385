import React from "react";

export const NoAllergyIcon = (props) => {
  let width = props.width ? props.width : "18";
  let height = props.height ? props.height : "18";
  let color = props.color ? props.color : "#707070";
  return (
    <svg
      id="No_allergy_svg"
      xmlns="http://www.w3.org/2000/svg"
      {...props} style={props?.style} width={width} height={height}
      viewBox="0 0 18.069 18.069"
    >
      <g
        id="No_allergy"
        data-name="No allergy"
        transform="translate(1.414 0.339)"
      >
        <path
          id="Path_92686"
          data-name="Path 92686"
          d="M24.847,12.373A7.446,7.446,0,0,0,23.36,7.336,6.891,6.891,0,0,0,17.975,5H17.9a6.963,6.963,0,0,0-5.4,2.336,7.446,7.446,0,0,0-1.486,5.038,2.089,2.089,0,0,0-.676,1.158,3.737,3.737,0,0,0,.135,1.853,2.554,2.554,0,0,0,1.409,1.756,9.717,9.717,0,0,0,3.667,4.536,4.368,4.368,0,0,0,4.787,0A9.717,9.717,0,0,0,24,17.141a2.6,2.6,0,0,0,1.409-1.756,3.727,3.727,0,0,0,.135-1.853A2.165,2.165,0,0,0,24.847,12.373ZM24.7,15.056c-.251.907-.811,1.081-1.119,1.081-.019,0-.058.019-.058.039a10.448,10.448,0,0,1-3.832,4.932,3.266,3.266,0,0,1-1.756.521,3.218,3.218,0,0,1-1.756-.521,10.448,10.448,0,0,1-3.832-4.932c0-.019-.039-.039-.058-.039-.29,0-.869-.193-1.12-1.081-.27-.984-.251-1.737.618-2.046.019,0,.039-.039.039-.058-.1-.579-.4-6.959,6.109-7.21,6.5.251,6.206,6.611,6.109,7.21,0,.019.019.058.039.058C24.953,13.319,24.973,14.072,24.7,15.056Z"
          transform="translate(-10.268 -5)"
          fill={color}
          stroke={color}
          stroke-width="0.2"
        />
        <path
          id="Path_92687"
          data-name="Path 92687"
          d="M44.326,65.025A3.067,3.067,0,0,0,42.7,64.6a2.873,2.873,0,0,0-1.718.5.173.173,0,0,0-.019.27l.174.193a.18.18,0,0,0,.251.019,2.358,2.358,0,0,1,1.332-.367,2.568,2.568,0,0,1,1.255.309.183.183,0,0,0,.251-.039l.154-.212A.172.172,0,0,0,44.326,65.025Z"
          transform="translate(-34.997 -53.096)"
          fill="#707070"
        />
        <path
          id="Path_92688"
          data-name="Path 92688"
          d="M32.218,41.473l-.154-.154a.151.151,0,0,0-.212,0,1.953,1.953,0,0,1-1.062.405,2.1,2.1,0,0,1-1.062-.154.134.134,0,0,0-.193.058l-.116.193a.155.155,0,0,0,.058.212,2.642,2.642,0,0,0,1.37.232,2.454,2.454,0,0,0,1.37-.54A.2.2,0,0,0,32.218,41.473Z"
          transform="translate(-25.707 -34.273)"
          fill="#707070"
        />
        <path
          id="Path_92689"
          data-name="Path 92689"
          d="M58.521,41.627a.156.156,0,0,0-.193-.058,2.1,2.1,0,0,1-1.062.154,1.953,1.953,0,0,1-1.062-.405.151.151,0,0,0-.212,0l-.174.154a.176.176,0,0,0,0,.232,2.376,2.376,0,0,0,1.37.54,2.461,2.461,0,0,0,1.37-.232.155.155,0,0,0,.058-.212Z"
          transform="translate(-46.991 -34.273)"
          fill="#707070"
        />
        <path
          id="Path_92690"
          data-name="Path 92690"
          d="M29,56.6a.5.5,0,1,0,.5.5A.487.487,0,0,0,29,56.6Z"
          transform="translate(-24.981 -46.64)"
          fill="#707070"
        />
        <path
          id="Path_92691"
          data-name="Path 92691"
          d="M60.5,26a.5.5,0,1,0-.5-.5A.5.5,0,0,0,60.5,26Z"
          transform="translate(-50.401 -21.14)"
          fill="#707070"
        />
        <circle
          id="Ellipse_128808"
          data-name="Ellipse 128808"
          cx="0.502"
          cy="0.502"
          r="0.502"
          transform="translate(5.7 2.644)"
          fill="#707070"
        />
        <path
          id="Path_92692"
          data-name="Path 92692"
          d="M63.1,54.3a.5.5,0,1,0,.5.5A.5.5,0,0,0,63.1,54.3Z"
          transform="translate(-52.499 -44.784)"
          fill="#707070"
        />
        <path
          id="Path_92693"
          data-name="Path 92693"
          d="M31.6,26a.5.5,0,1,0-.5-.5A.5.5,0,0,0,31.6,26Z"
          transform="translate(-27.079 -21.14)"
          fill="#707070"
        />
        <path
          id="Path_92694"
          data-name="Path 92694"
          d="M52.8,77.2a.5.5,0,1,0,.5.5A.511.511,0,0,0,52.8,77.2Z"
          transform="translate(-44.187 -63.264)"
          fill="#707070"
        />
        <g
          id="Rectangle_46504"
          data-name="Rectangle 46504"
          transform="translate(14.142 1.075) rotate(45)"
          fill="#707070"
          stroke="#fff"
          stroke-width="1"
        >
          <rect width="1.553" height="20" rx="0.776" stroke="none" />
          <rect
            x="-0.5"
            y="-0.5"
            width="2.553"
            height="21"
            rx="1.276"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
};
