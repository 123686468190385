import React , {useEffect} from "react";
import { Col, H6, Paper, Row, Div, Icons, Text, Image } from 'qdm-component-library'
import "./style.css"
import { Typography, makeStyles } from "@material-ui/core";
import withAllContexts from "../../hoc/withAllContexts";
import moment from "moment";
import qdmstyles from "./styles.json";
import FileViewer from 'react-file-viewer';
import { ToBase64 } from "../../utils";

const useStyles = makeStyles({
    listview: {
        background: "#f9f9f9",
        borderRadius: 6,
        padding: 20,
        backgroundImage: "url('/images/referral/Mask Group 46.svg')",
        backgroundRepeat: 'no-repeat',
    },
    title_list: {
        color: '#6f6f6f',
        fontSize: 13,
    }, title_bold: {
        fontSize: 12,
        marginLeft: 20,
    }, title_bold_chips: {
        padding: '6px',
        background: '#f0f0f0',
        borderRadius: '6px',
    }, underline: {
        width: '80%',
        height: '2px',
        // background: '#E0E0E0',
        marginTop: '7px',
        borderBottom: "1.7px dashed #E0E0E0"
    }
})
const ReferralBannerListView = (props) => {


    const classes = useStyles(props);

    const { ReferralData, parent_id } = props;

   
    
    return <Paper
        id={`${parent_id}_view_details_parent_paper`}
        className="rv-pl-root" style={{ padding: 20, width: "500px" }}>
        <Row
            id={`${parent_id}_view_details_parent_row`}
        >

            <Div
                id={`${parent_id}_view_details_reldetails_parent_div`}
                inLineStyles={{ padding: 10, width: "100%" }}>
                <Div
                    id={`${parent_id}_view_details_reldetails_title_div`}
                    style={{ display: "flex" }}>
                    <H6
                        id={`${parent_id}_view_details_reldetails_title_h6`}
                        className="en-title space-padding-bottom">REFERRAL DETAILS</H6>
                    <Div
                        id={`${parent_id}_view_details_reldetails_space_div`}
                        className={classes.underline} style={{ width: "70%" }}>&nbsp;</Div>  </Div>
                <Row
                    id={`${parent_id}_view_details_reldetails_row`}
                    style={{ display: "flex" }}>
          
                                    <FileViewer
                                        id="UMHit_16"
                                        fileType={props?.image?.[0]?.filetype}
                                        filePath={props?.image?.[0]?.url}
                                    />
                </Row>
            </Div>

        </Row>
    </Paper>
}



export default withAllContexts(ReferralBannerListView);