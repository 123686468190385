import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  authRequests,
  urls,
  dbName,
} from "../../utils";
import jwtDecode from "jwt-decode";

const {
  GetFacilityByUserIdRequest,
  GetIsNurseTriageRequiredByFacilityRequest,
  JWTTokenParamsRequest,
  LoginKeycloakUserRequest,
  PermissionRoleRequest,
  CountryCodeList,
} = authRequests;

const PRACTITIONER_LOGIN = createAsyncThunk(
  `AuthApiSlice/practitionerSignIn`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { username = "", password = "", tenantid = "" } = payload;

      const data = await fetchData(
        {
          body: JSON.stringify(
            LoginKeycloakUserRequest(username, password, tenantid)
          ),
        },
        urls.practitionerLogin
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const ORGANIZATION_SIGIN_IN = createAsyncThunk(
  `OrganizationAuthApiSlice/orgUserSignIn`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { username = "", password = "", tenantid } = payload;

      const data = await fetchData(
        {
          body: JSON.stringify(
            LoginKeycloakUserRequest(username, password, tenantid)
          ),
        },
        urls.practitionerLogin
        // __tenantid__
      );

      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const JWT_TOKEN_PARAMS = createAsyncThunk(
  `authSlice/jwtTokenParams`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { emailId = "" } = payload;
      const data = await fetchData(
        { body: JSON.stringify(JWTTokenParamsRequest(emailId)) },
        urls.readDocuments
      );

      let res = data?.result?.[0];
      return {
        ...defaultState.List,
        data: res,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_TOKEN = createAsyncThunk(
  `authSlice/getToken`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { params } = payload;
      const data = await fetchData(
        {
          body: JSON.stringify(params),
        },
        urls.tokenGeneration
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_FACILITY_BY_USERID = createAsyncThunk(
  `authSlice/getFacilityByUserId`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { username = "" } = payload;
      const data = await fetchData(
        { body: JSON.stringify(GetFacilityByUserIdRequest(username)) },
        urls.readDocuments
      );
      let dat = data?.result?.[0]?.facilityid ?? "";
      return {
        ...defaultState.List,
        data: dat,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_IS_NURSE_TRIAGE_REQUIRED_BY_FACILITY = createAsyncThunk(
  `authSlice/getIsNurseTriageRequiredByFacility`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { facilityId = "" } = payload;
      const data = await fetchData(
        {
          body: JSON.stringify(
            GetIsNurseTriageRequiredByFacilityRequest(facilityId)
          ),
        },
        urls.readDocuments
      );

      let res = data?.result?.[0]?.parametervalue === "N" ? false : true;

      return {
        ...defaultState.List,
        data: res,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_PERMISSION_ROLE_DATA = createAsyncThunk(
  `OrganizationAuthApiSlice/getpermissiondata`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { id } = payload;
      const data = await fetchData(
        {
          body: JSON.stringify(PermissionRoleRequest(id)),
        },
        urls.readDocuments
      );
      return {
        ...defaultState.List,
        data: data.result,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_COUNTRY_CODE_LIST = createAsyncThunk(
  `authSlice/country_code_list`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(CountryCodeList(), urls.readDocuments);
      let arry = [];
      data.result.forEach((value) => {
        arry.push({
          name: value.geogLevelName,
          flag: "",
          altSpellings: value._id,
          callingCodes: value?.GeoLocationISDCode?.replaceAll("+", ""),
          // label: value.GeoLocationISDCode,
          // value: value._id,
          // ...value
        });
      });
      return {
        ...defaultState.List,
        data: arry,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_QUEUE_TEMPLATE = createAsyncThunk(
  `AuthApiSlice/get_queuetemplate`,
  async (payload = {}, { rejectWithValue }) => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    try {
      const {
        queueIds = "",
        scenarioId = "",
        facilityid = "",
        locationid = "",
        practitionerid = "",
        practitioneroleid = "",
        startdate = 0,
        enddate = 0,
      } = payload;

      const body = {
        queueId: queueIds,
        filter: {
          queueid: queueIds,
          scenarioid: scenarioId,
          facilityid: facilityid,
          locationid: locationid,
          practitionerid: practitionerid,
          practitioneroleid: practitioneroleid,
          startdate: startdate,
          enddate: enddate,
          currencycode: decodedUserinfo?.currencyinfo?.currencycode
        },
      };

      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.queueTemplate
      );
      console.log("data", data);
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_PRACTITIONER_ROLE = createAsyncThunk(
  `AuthApiSlice/practitionerRole`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { userId } = payload;

      const body = {
        db_name: dbName,
        filter: {
          userid: userId,
        },
        queryid: "ab8977a2-10ad-4cfd-8b41-55c6b1918781",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const GET_ORGANIZATION_NAME = createAsyncThunk(
  `AuthApiSlice/organizationName`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { organizationId } = payload;

      const body = {
        db_name: dbName,
        filter: {
          orgid:organizationId,
        },
        queryid: "393e6b4d-ef5d-4e86-8844-7215c0e88dfc",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const GET_LOCATIONS_NAME = createAsyncThunk(
  `AuthApiSlice/locationName`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { organizationId } = payload;
console.log(organizationId,"008777777777986543234567890876543256789076543");
      const body = {
        db_name: dbName,
        filter: {
          orgid: organizationId,
          locid: "",
        },
        queryid: "fd98ce43-20bd-4718-9b64-02ace58984fe",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const GET_USER_FACILITY = createAsyncThunk(
  `AuthApiSlice/facilityName`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { emailId } = payload;
      const body = {
        db_name: dbName,
        filter: {
          emailid: emailId,
        },
        queryid: "658a98d3-25ac-483a-8c56-592955ac57d4",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const GET_ROLE_NAME = createAsyncThunk(
  `AuthApiSlice/roleName`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { practid,orgid } = payload;
      const body = {
        db_name: dbName,
        filter: {
          practid: practid,
          orgid: orgid,
        },
        queryid: "48cd968f-c590-40f6-a83a-5a441b64b8fd",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const initialAuthState = {
  oraganizationSignin: { ...defaultState.Info },
  jwtTokenParams: { ...defaultState.Info },
  getToken: { ...defaultState.Info },
  getFacilityByUserId: { ...defaultState.Info },
  getIsNurseTriageRequiredByFacility: { ...defaultState.Info },
  getPermission: { ...defaultState.Info },
  country_code_list: { ...defaultState.Info },
  get_queuetemplate: { ...defaultState.Info },
  practitionerRole: { ...defaultState.Info },
  organizationName: { ...defaultState.Info },
  locationName: { ...defaultState.Info },
  facilityName: { ...defaultState.Info },
  roleName: { ...defaultState.Info },
};

const authSlice = createSlice({
  name: "authentication",
  initialState: initialAuthState,
  extraReducers: {
    [ORGANIZATION_SIGIN_IN.fulfilled]: (state, action) => {
      state.oraganizationSignin = action?.payload ?? {};
    },
    [ORGANIZATION_SIGIN_IN.pending]: (state, action) => {
      state.oraganizationSignin.loading = true;
    },
    [ORGANIZATION_SIGIN_IN.rejected]: (state, action) => {
      state.oraganizationSignin = action?.payload ?? {};
    },
    [PRACTITIONER_LOGIN.fulfilled]: (state, action) => {
      state.practitionerLogin = action?.payload ?? {};
    },
    [PRACTITIONER_LOGIN.pending]: (state, action) => {
      state.practitionerLogin.loading = true;
    },
    [PRACTITIONER_LOGIN.rejected]: (state, action) => {
      state.practitionerLogin = action?.payload ?? {};
    },
    [JWT_TOKEN_PARAMS.fulfilled]: (state, action) => {
      state.jwtTokenParams = action?.payload ?? {};
    },
    [JWT_TOKEN_PARAMS.pending]: (state, action) => {
      state.jwtTokenParams.loading = true;
    },
    [JWT_TOKEN_PARAMS.rejected]: (state, action) => {
      state.jwtTokenParams = action?.payload ?? {};
    },
    [GET_TOKEN.fulfilled]: (state, action) => {
      state.getToken = action?.payload ?? {};
    },
    [GET_TOKEN.pending]: (state, action) => {
      state.getToken.loading = true;
    },
    [GET_TOKEN.rejected]: (state, action) => {
      state.getToken = action?.payload ?? {};
    },
    [GET_FACILITY_BY_USERID.fulfilled]: (state, action) => {
      state.getFacilityByUserId = action?.payload ?? {};
    },
    [GET_FACILITY_BY_USERID.pending]: (state, action) => {
      state.getFacilityByUserId.loading = true;
    },
    [GET_FACILITY_BY_USERID.rejected]: (state, action) => {
      state.getFacilityByUserId = action?.payload ?? {};
    },
    [GET_IS_NURSE_TRIAGE_REQUIRED_BY_FACILITY.fulfilled]: (state, action) => {
      state.getIsNurseTriageRequiredByFacility = action?.payload ?? {};
    },
    [GET_IS_NURSE_TRIAGE_REQUIRED_BY_FACILITY.pending]: (state, action) => {
      state.getIsNurseTriageRequiredByFacility.loading = true;
    },
    [GET_IS_NURSE_TRIAGE_REQUIRED_BY_FACILITY.rejected]: (state, action) => {
      state.getIsNurseTriageRequiredByFacility = action?.payload ?? {};
    },
    [GET_PERMISSION_ROLE_DATA.fulfilled]: (state, action) => {
      state.getPermission = action?.payload ?? {};
    },
    [GET_PERMISSION_ROLE_DATA.pending]: (state, action) => {
      state.getPermission.loading = true;
    },
    [GET_PERMISSION_ROLE_DATA.rejected]: (state, action) => {
      state.getPermission = action?.payload ?? {};
    },

    // GET_COUNTRY_CODE_LIST
    [GET_COUNTRY_CODE_LIST.fulfilled]: (state, action) => {
      state.country_code_list = action?.payload ?? {};
    },
    [GET_COUNTRY_CODE_LIST.pending]: (state, action) => {
      state.country_code_list.loading = true;
    },
    [GET_COUNTRY_CODE_LIST.rejected]: (state, action) => {
      state.country_code_list = action?.payload ?? {};
    },
    // GET_QUEUE_TEMPLATE
    [GET_QUEUE_TEMPLATE.fulfilled]: (state, action) => {
      state.get_queuetemplate = action?.payload ?? {};
    },
    [GET_QUEUE_TEMPLATE.pending]: (state, action) => {
      state.get_queuetemplate.loading = true;
    },
    [GET_QUEUE_TEMPLATE.rejected]: (state, action) => {
      state.get_queuetemplate = action?.payload ?? {};
    },
    // GET_PRACTITIONER_ROLE
    [GET_PRACTITIONER_ROLE.fulfilled]: (state, action) => {
      state.practitionerRole = action?.payload ?? {};
    },
    [GET_PRACTITIONER_ROLE.pending]: (state, action) => {
      state.practitionerRole.loading = true;
    },
    [GET_PRACTITIONER_ROLE.rejected]: (state, action) => {
      state.practitionerRole = action?.payload ?? {};
    },
    // GET_ORGANIZATION_NAME
    [GET_ORGANIZATION_NAME.fulfilled]: (state, action) => {
      state.organizationName = action?.payload ?? {};
    },
    [GET_ORGANIZATION_NAME.pending]: (state, action) => {
      state.organizationName.loading = true;
    },
    [GET_ORGANIZATION_NAME.rejected]: (state, action) => {
      state.organizationName = action?.payload ?? {};
    },
    // GET_LOCATIONS_NAME
    [GET_LOCATIONS_NAME.fulfilled]: (state, action) => {
      state.locationName = action?.payload ?? {};
    },
    [GET_LOCATIONS_NAME.pending]: (state, action) => {
      state.locationName.loading = true;
    },
    [GET_LOCATIONS_NAME.rejected]: (state, action) => {
      state.locationName = action?.payload ?? {};
    },
    // GET_USER_FACILITY
    [GET_USER_FACILITY.fulfilled]: (state, action) => {
      state.facilityName = action?.payload ?? {};
    },
    [GET_USER_FACILITY.pending]: (state, action) => {
      state.facilityName.loading = true;
    },
    [GET_USER_FACILITY.rejected]: (state, action) => {
      state.facilityName = action?.payload ?? {};
    },
     // GET_ROLE_NAME
     [GET_ROLE_NAME.fulfilled]: (state, action) => {
      state.roleName = action?.payload ?? {};
    },
    [GET_ROLE_NAME.pending]: (state, action) => {
      state.roleName.loading = true;
    },
    [GET_ROLE_NAME.rejected]: (state, action) => {
      state.roleName = action?.payload ?? {};
    },
  },
});

export const authActions = {
  ORGANIZATION_SIGIN_IN,
  PRACTITIONER_LOGIN,
  GET_PERMISSION_ROLE_DATA,
  JWT_TOKEN_PARAMS,
  GET_TOKEN,
  GET_FACILITY_BY_USERID,
  GET_IS_NURSE_TRIAGE_REQUIRED_BY_FACILITY,
  GET_COUNTRY_CODE_LIST,
  GET_QUEUE_TEMPLATE,
  GET_PRACTITIONER_ROLE,
  GET_ORGANIZATION_NAME,
  GET_LOCATIONS_NAME,
  GET_USER_FACILITY,
  GET_ROLE_NAME,
};

export default authSlice;
