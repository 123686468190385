import React, { useContext, useState } from "react";
import actions from "../../redux/actions";
import { connect } from "react-redux";
//import { withRouter } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { withStyles } from "@material-ui/core";
import { Div, Text, Row, Button, Col, Divider } from "qdm-component-library";
import withAppBar from "../../hoc/withAppBar";
import {
  //Allergies,
  PatientCareTypeCard,
  PatientStatusCard,
  TopHeader,
} from "../../components";
import { PatientInfoCard, ReferralListView } from "../../components";
import { ActionItems } from "./actionItems";
import { MenuList } from "./menuList";
import PatientChart from "./patientChart";
import WidgetsList from "./widgets";
import { AllergyForm } from "./allergyFlows";
import "./styles.css";
import { DiagnonsisForm } from "./diagnosisFlow";
import { CheifComplaintsForm } from "./cheifComplaintsFlow";
import { MedicationForm } from "./medicationFlows";
import { LabOrderForm } from "./labOrderFlow";
import SignOffCard from "./signOffComp/index";
import withAllContexts from "../../hoc/withAllContexts";
import {
  getUserInfo,
  CC_Json_Generator,
  _calculateAge,
  DIAGNOSIS_Json_Generator,
  Treatment_Json_Generator,
  Rad_Json_Generator,
  updateStatus,
  AlertProps,
  LabOrder_Json_Generator,
  fetchUserInfo,
  getReccuranceDates,
  utcTOLocal,
  getUtcTime,
  getCalculatedAge,
  makeName,
  baseURL,
  dateGiverForFollowUp,
  urls,
  __tenantid__,
  axiosCall,
  personRequests,
  getFacilityId,
  fetchData,
  getImgUrl,
} from "../../utils";
import { UIColor } from "../../themes/theme";
import { AlertContext as alert } from "../../context";
import { checkWithCasbin } from "../../utils/permissonHandling";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { RadiologyOrderForm } from "./radiologyFlow";
import { TreatmentProcedureForm } from "./treatmentProcedureFlow";
import { Footer } from "./footer";
import FollowupFotter from "./followupfooter";
import DoccForm from "./documentation/form";
import Miniwidget from "./miniwidget";
import Axios from "axios";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import GroupIcon from "../../assets/img/svg/Group 90506.svg";
import Group90507 from "../../assets/img/svg/Group 90507.svg";
import { generateJson } from "../patientDetail/medicationFlows/generateJson";
import jwtDecode from "jwt-decode";
import { NursingForm } from "./nursingProcedure/nursingForm";
import { PackageForm } from "./packageOrder/packageForm";
import Grid from "@mui/material/Grid";

const materialStyles = (theme) => ({});
const info = getUserInfo();

const PatientDetail = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const AlertContext = useContext(alert);
  const [followup, setfollowup] = React.useState(false);
  const [followup_number, setfollowup_number] = React.useState(null);
  const [followup_type, setfollowup_type] = React.useState({
    value: "",
    label: "",
  });
  const [selectOption, setselectOption] = React.useState({});
  const [ReferralData, setReferralData] = React.useState([]);
  const [followup_reason, setfollowup_reason] = React.useState({
    value: "",
    label: "",
  });
  const [fin_data, setfin_data] = React.useState([]);
  const [notes, setNotes] = React.useState([]);
  const [patientChartList, setpatientChartList] =
    React.useState("action_items");
  const [formsList, setformsList] = React.useState(null);
  const [encounterDataaaa, setencounterData] = React.useState({});
  const [permissions, setpermissions] = React.useState({});
  const [signOff, setsignOff] = React.useState(false);
  const [fetchUser, setfetchUser] = React.useState({});
  //const [followup , setfollowup] = React.useState([])
  const [dialysisData, setdialysisData] = React.useState([]);
  const [progressNotesData, setProgressNotesData] = React.useState([]);
  const [treatmentplan, setTreatmentplan] = React.useState({});
  const [formComponet, setformComponet] = React.useState({
    open: false,
    id: null,
    type: null,
  });
  const [treatmentprocedure, settreatmentprocedure] = React.useState([]);
  const [getVitals, setgetVitals] = React.useState([]);
  const [editIndex, seteditIndex] = React.useState();
  const [orderlineId, setOrderlineId] = React.useState(null);
  const [encounterDataList,setEncounterDataList] = React.useState();
  const [selectencounterDataList, setSelectEncounterDataList] = useState([]);
  const [Isdisableright, setIsdisableright] = useState(false);
  const [Isdisableleft, setIsdisableleft] = useState(false);
  const [encPageCount, setencPageCount] = useState(0);
  const [encId, setencId] = useState();
  const [count,setcount]= useState(1);

  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo =
    userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const _facility_ = decodedUserinfo?.facilityid ?? "";

  const [propsvalue, setpropsValue] = React.useState({
    patientId: location?.state?.patientId ? location?.state?.patientId : 4,
    encounter_id: location?.state?.encounter_id
      ? location?.state?.encounter_id
      : "Encounter/11664",
    personId: location?.state?.personId ? location?.state?.personId : 13,
    appointmentId: location?.state?.appointmentId
      ? location?.state?.appointmentId
      : 328,
    encounterId: location?.state?.encounterId
      ? location?.state?.encounterId
      : 2267,
    type: location?.state?.type ? location?.state?.type : "nurse",
    pId: location?.state?.pId ? location?.state?.pId : "Patient/11915",
    app_id: location?.state?.app_id
      ? location?.state?.app_id
      : "Appointment/12683",
    isProfileView: location?.state?.isProfileView
      ? location?.state?.isProfileView
      : false,
  });
  const [currentMedicationValue, setCurrentMedicationValue] = useState("");
  const [isEditMedic, setisEditMedic] = useState(false);

  const [footerDialsisData, setfooterDialsisData] = React.useState({});

  const searchPractioner = useSelector(
    (state) => state?.personSlice?.searchPractitioner
  );
  const readPerson = useSelector((state) => state?.personApiSlice?.readPerson);
  const teartment_ordertype = useSelector(
    (state) => state?.TreatmentSlice?.teartment_ordertype
  );
  const teartment_order_catalog = useSelector(
    (state) => state?.TreatmentSlice?.teartment_order_catalog
  );
  const teartment_priority = useSelector(
    (state) => state?.TreatmentSlice?.teartment_priority
  );
  const teartment_nature = useSelector(
    (state) => state?.TreatmentSlice?.teartment_nature
  );
  const teartment_mode = useSelector(
    (state) => state?.TreatmentSlice?.teartment_mode
  );
  const teartment_diagnosis = useSelector(
    (state) => state?.TreatmentSlice?.teartment_diagnosis
  );
  const teartment_indicate = useSelector(
    (state) => state?.TreatmentSlice?.teartment_indicate
  );
  const teartment_instuction = useSelector(
    (state) => state?.TreatmentSlice?.teartment_instuction
  );
  const treatment_organization = useSelector(
    (state) => state?.TreatmentSlice?.treatment_organization
  );
  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
  const treatment_pre_instrucation = useSelector(
    (state) => state?.TreatmentSlice?.treatment_pre_instrucation
  );
  const treatment_dep_instrucation = useSelector(
    (state) => state?.TreatmentSlice?.treatment_dep_instrucation
  );
  const recordStatus = useSelector(
    (state) =>
      state?.signOffSlice?.getAllMastersForSignoff?.data?.RECORDSTATUS ?? []
  );
  const stateList = useSelector((state) => state?.MedicationMastersSlice);

  const reason_ = useSelector(
    (state) => state?.signOffSlice?.getAllMastersForSignoff?.data?.REASONTYPE
  );
  const followupuom = useSelector(
    (state) => state?.signOffSlice?.getAllMastersForSignoff?.data?.FOLLOWUOM
  );

  // const userInfo = localStorage.getItem("UserInfo");
  // const decodedUserinfo = jwtDecode(userInfo);
  const roleName = decodedUserinfo?.rolename;
  const styles = {
    columnStyle: {
      padding: 10,
      backgroundColor: UIColor.greyBackground.color,
      //borderRadius: 5,
      height: "75vh",
    },
    divider: {
      margin: "5px 0px",
      border: "0.5px dashed #EEEEEE",
      borderRadius: 10,
    },
    infoHeader: {
      position: "sticky",
      top: 0,
      // background: "#f8f8f8",
      zIndex: 100,
      padding: "14px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "#fff",
      // marginBottom: "20px"
    },
    background: {
      background: UIColor.greyBackground.color,
      paddingTop: "20px",
    },
    rightSideCol: {
      height: "78vh",
      overflow: "auto",
      backgroundColor: "#F9F9F9",
      borderLeft: "1px solid #E0E0E0",
      padding: 0,
    },
  };

  const getData = async (encounterId,encStartDate) => {
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: true,
      message: "Fetching....",
    });

    let datas = await dispatch(
      actions.READ_DIALYSIS({
        encounterId: encId || encounterId||propsvalue?.encounter_id || location?.state?.encounter_id,
      })
    );
    settreatmentprocedure(datas?.payload?.data);
    let vitalsdata = await dispatch(
      actions.GET_VITALS_BASED_ENCOUNTER({
        PID: location?.state?.patientId,
      })
    );
    setgetVitals(vitalsdata?.payload?.data);
    const encounterData = await dispatch(
      actions.GET_ENCOUNTER({
        encounter_id: encounterId ?? encId ?? propsvalue?.encounter_id ?? location?.state?.encounter_id,
      })
    );

    setencounterData(encounterData?.payload?.data ?? {});
    const treatmentplan = await dispatch(
      actions.TRAEMENT_PLAN_READ({
        patientid:
          encounterData?.payload?.data?.[0]?.Patient?.[0]?._id ??
          location?.state?.patientId,
      })
    );

    setTreatmentplan(treatmentplan?.payload?.data?.[0]);
    let dataList = await checkWithCasbin(["patientChart"]);
    setpermissions(dataList);
    const followup = await dispatch(
      actions.FOLLOW_UP_READ({
        encounter_id:
        encId || encounterId || encounterData?.payload?.data?.[0]?._id || propsvalue?.encounter_id,
      })
    );
    //CALL REASON MASTER
    //await dispatch(actions.FOLLOWUP_REASON());
    //await dispatch(actions.FOLLOWUP_UOM());
    if (followup?.payload?.data?.[0]?.activestatus) {
      setfollowup_reason(
        reason_?.data?.find?.(
          (d) => d?.value === followup?.payload?.data?.[0]?.followupreason
        ) || { label: "", value: "" }
      );
      setfollowup_number(
        followup?.payload?.data?.[0]?.followupdurval
          ? JSON.stringify(followup?.payload?.data?.[0]?.followupdurval)
          : ""
      );
      setfollowup_type(
        followup?.payload?.data?.[0]?.followupduruom
          ? {
              value: followup?.payload?.data?.[0]?.followupduruom,
              label: followup?.payload?.data?.[0]?.followupduruom,
            }
          : {}
      );
      setfollowup(followup);
    }
     const fin_data = await dispatch(
      actions.GET_ENCOUNTER_FINANCIAL({encounterid:encounterId ?? encId ?? encounterData?.payload?.data?.[0]?._id ?? propsvalue?.encounter_id})
    );
    if (fin_data?.payload?.data) {
      const fin_DATA = fin_data?.payload?.data?.map((v) => {
        return {
          pic: Group90507,
          name: null,
          care_type: v?.payer?.longdesc,
          title_name: v?.payertype?.longdesc,
          priority: v?.priority,
          policyexpiry: v?.policyexpiry? utcTOLocal(v?.policyexpiry,"DD-MM-YYYY") : "",
          policy:v?.policyid?.[0]?.plan?.longdesc,
          payer: v?.payer?._id
        };
      });
      setselectOption(fin_DATA[0]);
      const findata = fin_DATA//fin_DATA?.reverse();
      setfin_data(findata);
    }

    const fetchUser = await fetchUserInfo();
    setfetchUser(fetchUser);
    // this.setState({
    //   ...this.state,
    //   fetchUser: fetchUser,
    // });
    await dispatch(
      actions.GET_ENCOUNTER({
        id: encId || encounterId || propsvalue?.encounter_id || location?.state?.encounter_id,
      })
    ); 
    setencounterData(encounterData?.payload?.data ?? {});
    //this.setState({ encounterData: encounterData?.payload?.data ?? {} });
    if (encounterData?.payload?.data?.[0]?.patient_id) {
      let d = await dispatch(
        actions.PATIENT_BANNER({
          id: encounterData?.payload?.data?.[0]?.patient_id,
        })
      );
    }
    if (encounterData?.payload?.data?.[0]?.patient_id) {
      let d_ = await dispatch(
        actions.ENCOUNTER_BANNER({
          patientId: encounterData?.payload?.data?.[0]?.patient_id,
          facilityId: encounterData?.payload?.data?.[0]?.facilityid,
        })
      );
    }

    //await getAllMasters();

    readDialysisWithEncouterid(encounterData?.payload?.data,"",encId);
    readProgressNotes(
      encounterData?.payload?.data,
      encounterData?.payload?.data?.[0]?.Patient?.[0]?._id,
      encId
    );
    // readTreatmentplanWithPatientid( encounterData?.payload?.data?.[0]?.Patient?.[0]?._id)
    await dispatch(
      actions.ENCOUNTER_BASED_CC({
        encounter_id: encId || encounterData?.payload?.data[0]?._id,
      })
    );

    let data_pay = await dispatch(
      actions.ENCOUNTER_BASED_A({
        patient_id:
          encounterData?.payload?.data?.[0]?.patient_id ||
          encounterData?.payload?.data?.[0]?.Patient?.[0]?._id ||
          location?.state?.patientId,
      })
    );
    let allergy = props.allergy;

    await allergy.setAllergy({
      ...allergy,
      list: JSON.parse(JSON.stringify(data_pay?.payload?.data)),
    });

    let datatreatment = await dispatch(
      actions.TRAEMENT_READ({
        encounter_id: encId || encounterData?.payload?.data?.[0]?._id,
        order_id: "TREAT",
        patientid:
            encounterData?.payload?.data?.[0]?.patient_id ||
            encounterData?.payload?.data?.[0]?.Patient?.[0]?._id ||
            location?.state?.patientId,
        startdate:encStartDate ? encStartDate : getUtcTime(moment().startOf("day")),

      })
    );
    let treatment = props.treatment;
    await treatment.setTreatment({
      ...treatment,
      list: JSON.parse(JSON.stringify(datatreatment?.payload?.data)),
    });
    let dataDiagnosis = await dispatch(
      actions.DIAGNOSIS_READ({
        patient_id:
          encounterData?.payload?.data?.[0]?.patient_id ||
          encounterData?.payload?.data?.[0]?.Patient?.[0]?._id ||
          location?.state?.patientId,
      })
    );
    let diagnosisData = props.Diagnosis;
    await diagnosisData.setDiagnosis({
      ...diagnosisData,
      list: JSON.parse(JSON.stringify(dataDiagnosis?.payload?.data)),
    });

    let labordersData = await dispatch(
      actions.LABORDER_READ({
        encounter_id: encId || encounterData?.payload?.data?.[0]?._id,
        order_id: "LAB",
        patientid:
            encounterData?.payload?.data?.[0]?.patient_id ||
            encounterData?.payload?.data?.[0]?.Patient?.[0]?._id ||
            location?.state?.patientId,
        startdate:encStartDate ? encStartDate : getUtcTime(moment().startOf("day")),
      })
    );

    let labOrder = props?.LabOrder;
    await labOrder.setLabOrder({
      ...labOrder,
      list: JSON.parse(JSON.stringify(labordersData?.payload?.data)),
    });

    let nursingprocedureData = await dispatch(
      actions.LABORDER_READ({
        encounter_id: encId || encounterData?.payload?.data?.[0]?._id,
        order_id: "NURSINGPROCEDURE",
        patientid:
            encounterData?.payload?.data?.[0]?.patient_id ||
            encounterData?.payload?.data?.[0]?.Patient?.[0]?._id ||
            location?.state?.patientId,
        startdate:encStartDate ? encStartDate : getUtcTime(moment().startOf("day")),
      })
    );

    let nursingprocedure = props?.NursingProcedure;
    await nursingprocedure.setNursingProcedure({
      ...nursingprocedure,
      list: JSON.parse(JSON.stringify(nursingprocedureData?.payload?.data)),
    });

    let packageorderData = await dispatch(
      actions.LABORDER_READ({
        encounter_id: encId || encounterData?.payload?.data?.[0]?._id,
        order_id: "PACK",
        patientid:
            encounterData?.payload?.data?.[0]?.patient_id ||
            encounterData?.payload?.data?.[0]?.Patient?.[0]?._id ||
            location?.state?.patientId,
        startdate:encStartDate ? encStartDate : getUtcTime(moment().startOf("day")),
      })
    );

    let packageorder = props?.PackageOrder;
    await packageorder.setPackageOrder({
      ...packageorder,
      list: JSON.parse(JSON.stringify(packageorderData?.payload?.data)),
    });

    let data = await dispatch(
      actions.DOCC_READ({
        // encounter_id: this.props.encounterData?.data?.eId,
        //patient_id: location?.state.pId,
        patient_id: propsvalue?.pId,
      })
    );

    let { Doccument, setDoccument } = props?.Doccument;
    setDoccument({
      ...Doccument,
      list: JSON.parse(JSON.stringify(data?.payload?.data)),
    });

    await dispatch(actions.TREATMENT_ORDER_TYPE());
    await dispatch(actions.TREATMENT_ORDER_CATALOG());
    //await dispatch(actions.TREATMENT_PROCEDURE_PRIORITY());
    //await dispatch(actions.TREATMENT_PROCEDURE_NATURE());
    //await dispatch(actions.TREATMENT_PROCEDURE_MODE());
    await dispatch(
      actions.TREATMENT_DIAGNOSIS({
        id: encId || encounterData?.payload?.data?.[0]?._id,
      })
    );
    //await dispatch(actions.TREATMENT_INDICATE());
    //await dispatch(actions.TREATMENT_INSTRUCTION());
    await dispatch(
      actions.RAD_ORGANIZATION({
        id: fetchUser?.orgId,
      })
    );
    //await dispatch(actions.TREATMENT_DEP_INSTRUCTION());
    //await dispatch(actions.TREATMENT_PRE_INSTRUCTION());

    // Radiology fetch
    let datatradiology = await dispatch(
      actions.RAD_READ({
        encounter_id: encId || encounterData?.payload?.data?.[0]?._id,
        order_id: "RAD",
        patientid:
            encounterData?.payload?.data?.[0]?.patient_id ||
            encounterData?.payload?.data?.[0]?.Patient?.[0]?._id ||
            location?.state?.patientId,
        startdate:encStartDate ? encStartDate : getUtcTime(moment().startOf("day")),
      })
    );
    let radiology = props.radiology;
    await radiology.setRadiology({
      ...radiology,
      list: JSON.parse(JSON.stringify(datatradiology?.payload?.data)),
    });

    await dispatch(actions.RAD_ORDER_TYPE());
    await dispatch(actions.RAD_ORDER_CATALOG());
    //await dispatch(actions.RAD_PRIORITY());
    //await dispatch(actions.RAD_NATURE());
    //await dispatch(actions.RAD_MODE());
    await dispatch(
      actions.TREATMENT_DIAGNOSIS({
        encounter_id:encId || encounterData?.payload?.data?.[0]?._id,
      })
    );
    //await dispatch(actions.RAD_INDICATE());
    //await dispatch(actions.RAD_INSTRUCTION());
    await dispatch(
      actions.RAD_ORGANIZATION({
        id: fetchUser?.orgId,
      })
    );
    let current_medication = await dispatch(
      actions.GET_CURRENT_MEDICATION_ORDER(
        // encounterData?.payload?.data?.[0]?.Patient?.[0]?._id
        location?.state?.patientId
      )
    );

    let cmdata = current_medication?.payload?.data;
    if (cmdata?.length > 0) {
      cmdata.forEach((v, i) => {
        if (v?.clicnicaldoctype === process.env.REACT_APP_CURRENT_MEDICATION) {
          setCurrentMedicationValue(v?.clicnicaldocdesc);
        }
        // if (v?.clicnicaldocdesc?.length > 0) {
        //   setisEditMedic(true);
        // } else {
        //   setisEditMedic(false);
        // }
      });
    }
    setNotes(current_medication?.payload?.data);
    dispatch(actions.GET_ALL_MASTERS_FOR_SIGN_OFF());
    getallFOrMedication(encounterData?.payload?.data?.[0]?._id);

    // setMedication({ ...props.medicationsData, list: data });

    //await dispatch(actions.RAD_PRE_INSTRUCTION());
    //await dispatch(actions.RAD_DEP_INSTRUCTION());
    // await readDialysisWithEncouterid();
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: false,
      message: "",
    });
  };
  const handleEncounterSelect =async (val) => {
    const patientId = location?.state?.patientId || location?.state?.pId;
    setSelectEncounterDataList(val)
    const encounterId =  val?.encounterId;
    setencId({... encId ,encId : encounterId,encStartDate:val?.encounterStart})
    getData(encounterId,val?.encounterStart)
    readDialysisWithEncouterid({},"",encounterId);
    readProgressNotes({},patientId,encounterId);
    // setEncounterNo(encounterId)
    // setEncounterDate(createDate(val?.encounterStart));
    //   const data = await dispatch(
    //     actions.GET_TABLE_DATA({ patientId, encounterId })
    //   );
      // const tableData = displayTable(data?.payload?.data ?? []);

      // setStandardTableorders(tableData);

      // setDisplayOrders(tableData);
      // getFinanceData(encounterId);
  };
  const getNextSet = async ()=>{
    let encCount = encPageCount + 10
    const queryData =
        personRequests.getDetailsAgainstPatientSearchByIsCashier(
          0,
          0,
          "",
          location?.state?.mrnId,
          //app?.field2,
          "",
          "",
          "",
          "",
          "",
          getFacilityId(),
          encCount,
          10
        );
        const SearchUserData = await fetchData(queryData, urls.readQdmQueries);
        if (!SearchUserData || SearchUserData.length === 0) {
          setIsdisableright(true);
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Encounter data not Found",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        } else {
          let lista = {}
          SearchUserData?.forEach((v, i) => {
            Object.assign(lista, {
              idnumber: v?.identifier[0]?.value,
              idsourcecode: v?.identifier[0]?.code,
              address: v?.address,
              age: v?.age,
              gender: v?.gender,
              email:
                v?.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
                  ?.value ?? "",
              name: makeName(v?.name?.[0] || {}) || "",
              id: v?.patmrn ?? "",
              dis: `${
                (v?.gender + ", " ?? "") +
                (v?.birthDate ? getCalculatedAge(v?.birthDate) : "")
              }
              ${v?.telecom[0]?.value ? " | " + v?.telecom[0]?.value : ""}`,
              encounter: v?.encounter,
              mobileNumber:
                v?.telecom?.find((tel) => tel?.system === "CodingMaster/10473")
                  ?.value ||
                v.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
                  ?.value ||
                "",
              patientId: v?._id,
              img:
                getImgUrl(v?.photo?.[0]?.fileid) ||
                "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
            });
            
          });
          const list = lista?.encounter?.map((val, i) => ({
            encstatus:val?.encstatus,
            encounterId:val?._id,
            encounterName: val?.encounter_no ?? "",
            type: val?.type?.shortDesc ?? "",
            speciality: val?.practitioner_details?.speciality?.display ?? "-",
            encounterStart: val?.encounterdate ?? "",
            encounterEnd: val?.encounterenddate ?? "",
            location: val?.location?.[i]?.physicalType ?? "",
            doctorName: val?.practitioner_details?.idname,
          }));
          setIsdisableleft(false)
          setencPageCount(encCount)
          setEncounterDataList(list)
          setcount(count+1)
        }
  }

  const getPreviousSet = async ()=>{
    let encPagecount = encPageCount===0 ? 0 : encPageCount - 10;
    const queryData =
        personRequests.getDetailsAgainstPatientSearchByIsCashier(
          0,
          0,
          "",
          location?.state?.mrnId,
          //app?.field2,
          "",
          "",
          "",
          "",
          "",
          getFacilityId(),
          encPagecount,
          10
        );
        const SearchUserData = await fetchData(queryData, urls.readQdmQueries);
        if (!SearchUserData || SearchUserData.length === 0) {
          encPagecount = encPageCount + 2
          setencPageCount(encPagecount === 0 ? 0 : encPagecount)
          setIsdisableleft(true)
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Encounter data not Found",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        } else {
          let lista = {}
          // encounterId:val?._id,
          // encounterName: val?._key ? `EN - ${val?._key}` : "",
          // type: val?.type?.shortDesc ?? "",
          // speciality: val?.practitioner_details?.speciality?.display ?? "-",
          // encounterStart: val?.encounterdate ?? "",
          // encounterEnd: val?.encounterenddate ?? "",
          // location: val?.location?.[i]?.physicalType ?? "",
          // doctorName: val?.practitioner_details?.idname,
          SearchUserData?.forEach((v, i) => {
            Object.assign(lista, {
              idnumber: v?.identifier[0]?.value,
              idsourcecode: v?.identifier[0]?.code,
              address: v?.address,
              age: v?.age,
              gender: v?.gender,
              email:
                v?.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
                  ?.value ?? "",
              name: makeName(v?.name?.[0] || {}) || "",
              id: v?.patmrn ?? "",
              dis: `${
                (v?.gender + ", " ?? "") +
                (v?.birthDate ? getCalculatedAge(v?.birthDate) : "")
              }
              ${v?.telecom[0]?.value ? " | " + v?.telecom[0]?.value : ""}`,
              encounter: v?.encounter,
              mobileNumber:
                v?.telecom?.find((tel) => tel?.system === "CodingMaster/10473")
                  ?.value ||
                v.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
                  ?.value ||
                "",
              patientId: v?._id,
              img:
                getImgUrl(v?.photo?.[0]?.fileid) ||
                "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
            });
            
          });
          const list = lista?.encounter?.map((val, i) => ({
            encstatus:val?.encstatus,
            encounterId:val?._id,
            encounterName: val?.encounter_no ?? "",
            type: val?.type?.shortDesc ?? "",
            speciality: val?.practitioner_details?.speciality?.display ?? "-",
            encounterStart: val?.encounterdate ?? "",
            encounterEnd: val?.encounterenddate ?? "",
            location: val?.location?.[i]?.physicalType ?? "",
            doctorName: val?.practitioner_details?.idname,
          }));
          setIsdisableright(false)
          setencPageCount(encPagecount === 0 ? 0 : encPagecount)
          setEncounterDataList(list)
          setcount(count === 1 ? 1 : count-1)
        }
  }
  const getEncList = async () =>{
    const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setHours(0, 0, 0, 0);
    const startEpochTime = getEpochTime(startDate);
    const endDate = new Date(currentDate);
    endDate.setHours(23, 59, 59, 999);
    const endEpochTime = getEpochTime(endDate);
    const queryData =
    personRequests.getDetailsAgainstPatientSearchByIsCashier(
      0,
      0,
      "",
      location?.state?.mrnId,
      //app?.field2,
      "",
      "",
      "",
      "",
      "",
      getFacilityId(),
      0,
      10,
      location?.state?.isViewEmr === true ? location?.state?.encounter_id : ""
    );
    const SearchUserData = await fetchData(queryData, urls.readQdmQueries);
    let lista
    // SearchUserData?.forEach((v, i) => {
    //   Object.assign(lista, {
    //     idnumber: v?.identifier[0]?.value,
    //     idsourcecode: v?.identifier[0]?.code,
    //     address: v?.address,
    //     age: v?.age,
    //     gender: v?.gender,
    //     email:
    //       v?.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
    //         ?.value ?? "",
    //     name: makeName(v?.name?.[0] || {}) || "",
    //     id: v?.patmrn ?? "",
    //     dis: `${
    //       (v?.gender + ", " ?? "") +
    //       (v?.birthDate ? getCalculatedAge(v?.birthDate) : "")
    //     }
    //         ${v?.telecom[0]?.value ? " | " + v?.telecom[0]?.value : ""}`,
    //     encounter: v?.encounter,
    //     mobileNumber:
    //       v?.telecom?.find((tel) => tel?.system === "CodingMaster/10473")
    //         ?.value ||
    //       v.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
    //         ?.value ||
    //       "",
    //     patientId: v?._id,
    //     img:
    //       getImgUrl(v?.photo?.[0]?.fileid) ||
    //       "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
    //   });
    // });
    const list = await SearchUserData?.[0]?.encounter?.map((val, i) => ({
      encstatus:val?.encstatus,
      encounterId:val?._id,
      encounterName: val?.encounter_no ?? "",
      type: val?.type?.shortDesc ?? "",
      speciality: val?.practitioner_details?.speciality?.display ?? "-",
      encounterStart: val?.encounterdate ?? "",
      encounterEnd: val?.encounterenddate ?? "",
      location: val?.location?.[i]?.physicalType ?? "",
      doctorName: val?.practitioner_details?.idname,
    }));
    await setEncounterDataList(list);
    await setSelectEncounterDataList(list?.[0])
    await setencId({
      ...encId,
      encId: list?.[0]?.encounterId,
      encStartDate: list?.[0]?.encounterStart
    })
    return await list
  }
  React.useEffect(() => {
    //const constructDataList = constructData(LoadpatientData?.state?.data?.encounter);
    getEncList();
    getData();
    getReferalData();
  }, []);
  const getReferalData = async () => {
    if (location?.state?.referralId) {
      const refData = await dispatch(
        actions.REFERRAL_READ({ referralId: location?.state?.referralId })
      );
      setReferralData(refData?.payload?.data);
    }
  };

  const getallFOrMedication = async (id) => {
    Promise.all([
      dispatch(actions.FREQUENCYMASTER_MASTER()),
      dispatch(actions.STRENGTHUOM_MASTER()),
      dispatch(actions.DURATIONTYPE_MASTER()),
    ]).then(async (res) => {
      let res_copy = res;
      res.forEach((v, i) => (res_copy[i]["key"] = v.type.split("/")?.[1]));
      let obj = {};
      res_copy.forEach((v) => {
        obj[v?.key] = { data: [] };
        obj["status_masters"] = { data: [] }; // we dont know the status master
        obj[v?.key]["data"] = v?.payload?.data ?? [];
      });
      let get_Medication_Details = await dispatch(
        actions.GET_MEDICATIONS({
          id: id,
          tenantid: "",
          facilityid: "",
        })
      );

      let get_Medication = props?.medicationsData;
      let getMedData = generateJson(get_Medication_Details?.payload?.data, obj);
      await get_Medication.setMedication({
        ...get_Medication,
        list: JSON.parse(JSON.stringify(getMedData)),
      });
    });
  };
  const getAllMasters = async () => {
    await dispatch(actions.USE_MASTERS());
    await dispatch(actions.VERIFICATIONS_MASTERS_DIAGNOSIS());
    //const { pId } = location?.state;
    await dispatch(actions.WORKFLOW_STATUS_MASTER());
    await Promise.all([
      dispatch(actions.GET_RECORD_STATUS()),
      dispatch(actions.GET_SERVICE_WF_STATUS()),
    ]);

    await dispatch(
      actions.GET_MEDICATIONS({ id: location?.state?.encounter_id })
    );
    // if (data.payload.data?.length > 0) {
    // this.setState({
    //   patientChartList: "patient_chart",
    //   formsList: ["medication"],
    // });
    // this.props.backDrop.setBackDrop({
    //   ...this.props.backDrop,
    //   open: true,
    //   message: "Fetching....",
    // });
    // }
  };

  const changeState = (key, val) => {
    setselectOption(val);
  };

  const readDialysisWithEncouterid = async (enc_dta, orderlineId,encId) => {
    // let data = await dispatch(
    //   actions.READ_DIALYSIS({
    //     encounterId: enc_dta?.[0]?._id ?? encounterDataaaa?.[0]?._id,
    //     orderlineid: orderlineId,
    //   })
    // );
    const read_dialysis = await dispatch(
      actions.TREATMENTPROCEDURE_READ({
        encounterId: encId ?? enc_dta?.[0]?._id ?? encounterDataaaa?.[0]?._id,
        patientId: encounterDataaaa[0]?.Patient[0]?._id,
      })
    );
    setdialysisData(read_dialysis?.payload?.data);
  };
  const readProgressNotes = async (enc_dta, patientid, encId) => {
    let data = await dispatch(
      actions.PROGRESSNOTES_READ({
        encounterId: encId?.encId ?? enc_dta?.[0]?._id ?? encounterDataaaa?.[0]?._id,
        patientId: patientid,
        // encounterid:"Encounter/11973",
        // patientid:"Patient/12576",
      })
    );
    setProgressNotesData(data?.payload?.data?.[0]);
  };
  // const readTreatmentplanWithPatientid = async (patientid) => {
  //   let data = await dispatch(
  //     actions.TRAEMENT_PLAN_READ({
  //       patientid: patientid //"Patient/12572"
  //     })
  //   );

  //   setTreatmentplan(data?.payload?.data?.[0]);
  // };
  const goBackInsignOff = (key, val) => {
    getData();
    if (propsvalue?.isProfileView) {
      let data = encounterDataaaa;
      navigate(AppRoutes.PatientDashBoard, {
        state: {
          userId: data[0]?.Patient[0]?.personid,
          //mobileNumber:location?.state?.mobileNo,
          mobileNumber: location?.state?.mobileNo,
          patientId: data[0]?.Patient[0]?._id,
          // appointmentId
        },
      });
    } else {
      if (
        formsList?.includes("follow_up") &&
        !followup_number &&
        !followup_type?.label
      ) {
        let form = formsList;
        form.splice(form.indexOf("follow_up"), 1);
        setformsList(form);
      }
      setsignOff(val);
    }
  };

  const onEditChart = (id, type) => {
    setformComponet({
      open: !formComponet.open,
      id: id,
      type: type,
    });
  };

  const onEditWidget = (id, type, index) => {
    // this.setState({
    //   ...this.state,
    //   editIndex: index,

    //   formComponet: {
    //     open: true,
    //     id: id,
    //     type: type,
    //   },
    // });
    seteditIndex(index);

    setformComponet({
      open: true,
      id: id,
      type: type,
    });
  };

  const handleClose = (id) => {
    let selectedFormId = [...formsList];
    let filterId = selectedFormId.filter((val) => val !== id);
    changeChartLayer(filterId);
  };

  const getCharts = (type) => {
    switch (type) {
      // case "action_items":
      // return (
      //   <>
      //     {/* Action Items contains */}
      //     <ActionItems
      //     saveLabOrders={saveLabOrders}
      //     onEditChart={onEditChart}
      //     handleClose={handleClose}
      //    getDialysisData={readDialysisWithEncouterid}

      //     />
      //   </>
      // );
      case "patient_chart":
        return (
          <PatientChart
            art
            parent_id={"patientdetails"}
            formsList={formsList}
            handleClose={handleClose}
            onEditChart={onEditChart}
            saveCheifComp={saveCheifComp}
            saveDiagnosis={saveDiagnosis}
            saveAllergy={saveAllergy}
            saveTreatment={saveTreatment}
            saveLabOrders={saveLabOrders}
            saveNursingProcedure={saveNursingProcedure}
            saveRadiology={saveRadiology}
            saveDoccumentation={saveDoccumentation}
            handleOrderSetClose={() => {
              if (formsList) {
                let formList = [...formsList];
                const index = formList.indexOf("orders_sets");
                if (index > -1) {
                  formList.splice(index, 1);
                  changeChartLayer(formList);
                }
              }
            }}
            {...props}
            patientgetData={encounterDataaaa?.[0]}
            dialysisData={dialysisData}
            getDialysisData={readDialysisWithEncouterid}
            orgdetails={fetchUser}
            progressNotesData={progressNotesData}
            treatmentplan={treatmentplan}
            setTreatmentplan={setTreatmentplan}
            locationdata={location}
            setOrderlineId={setOrderlineId}
            readDialysisWithEncouterid={readDialysisWithEncouterid}
            editEncData={encId}
          />
        );
      default:
        break;
    }
  };
  const handleInputChange = (e, name, limit) => {
    if (name === "followup_number") {
      if (e === "0") {
        setfollowup_number("");
        // this.setState({
        //   ...this.state,
        //   followup_number: "",
        // });
        return;
      }
    }
    if (limit) {
      if (limit < e.length) {
        return;
      }
    }
    setfollowup_number(e);
    // this.setState({
    //   ...this.state,
    //   [name]: e,
    // });
  };
  const tabHandle = (val) => {
    setfollowup_type(val);
  };
  const handleselectChange = (val) => {
    setfollowup_reason(val);
    // this.setState({
    //   ...this.state,
    //   [name]: val,
    // });
  };

  const changeChartLayer = async (forms) => {
    await dispatch(actions.VERIFICATIONS_MASTERS());
    await dispatch(actions.CLINIC_STATUS_MASTERS());
    await dispatch(actions.SYMPTOMS_MASTERS());
    await dispatch(actions.GET_ENCOUNTER({ id: encounterDataaaa?.[0]?._id }));
    await dispatch(actions.BODYSITE_MASTERS());
    await dispatch(actions.SEVERITY_MASTERS());
    await dispatch(actions.CC_MASTERS());
    await dispatch(actions.RELATIONSHIP_MASTERS());
    await dispatch(
      actions.ENC_BASED_CC({ encounter_id: encounterDataaaa?.[0]?._id })
    );
    setpatientChartList(forms?.length > 0 ? "patient_chart" : "action_items");
    setformsList(JSON.parse(JSON.stringify(forms)));
    setformComponet({
      open: false,
      id: null,
      type: null,
    });
    // this.setState({
    //   ...this.state,
    //   patientChartList: forms?.length > 0 ? "patient_chart" : "action_items",
    //   formsList: JSON.parse(JSON.stringify(forms)),
    //   formComponet: {
    //     open: false,
    //     id: null,
    //     type: null,
    //   },
    // });
  };

  const followupHnadle = () => {
    let arr = formsList;

    let index = arr.indexOf("follow_up");
    if (index > -1) {
      arr.splice(index, 1);
    }
    setformsList(arr);
    setfollowup_number(null);
    setfollowup_type({ value: "", label: "" });
    setfollowup_reason({ value: "", label: "" });
    // this.setState({
    //   ...this.state,
    //   formsList: arr,
    //   followup_number: null,
    //   followup_type: { value: "", label: "" },
    //   followup_reason: { value: "", label: "" },
    // });
    if (followup?.payload?.data?.length > 0) {
      dispatch(
        actions.SET_FOLLOWUP_DELETE({
          id: followup.payload.data[0]._key,
        })
      );
    }
  };

  const goBack = () => {
    navigate(AppRoutes.dashboard);
  };

  const onCancel = () => {
    setformComponet({
      open: false,
      id: null,
      type: null,
    });
  };
  const getFormTypeRender = (type) => {
    switch (type) {
      case "allergy_form":
        return (
          <AllergyForm
            parent_id={"patient_details"}
            onCancel={onCancel}
            saveAllergy={saveAllergy}
            encounterData={encounterDataaaa}
          />
        );
      case "diagnonsis_form":
        return (
          <DiagnonsisForm
            parent_id={"patient_details"}
            saveDiagnosis={saveDiagnosis}
            onCancel={onCancel}
            encounterData={encounterDataaaa}
          />
        );
      case "cheif_complaints_form":
        return (
          <CheifComplaintsForm
            parent_id={"patient_details"}
            editIndex={editIndex}
            saveCheifComp={saveCheifComp}
            onCancel={onCancel}
            formComponet={formComponet}
            encId={encounterDataaaa?.[0]?._id}
          />
        );
      case "lab_order_form":
        return (
          <LabOrderForm
            parent_id={"patient_details"}
            onCancel={onCancel}
            saveLabOrders={saveLabOrders}
            patientgetData={encounterDataaaa}
            info={info}
            fetchUser={fetchUser}
            {...props}
          />
        );
      case "nursing_procedure_form":
        return (
          <NursingForm
            parent_id={"patient_details"}
            onCancel={onCancel}
            saveNursingProcedure={saveNursingProcedure}
            patientgetData={encounterDataaaa}
            info={info}
            fetchUser={fetchUser}
            {...props}
          />
        );
      case "package_order_form":
        return (
          <PackageForm
            parent_id={"patient_details"}
            onCancel={onCancel}
            savePackageOrder={savePackageOrder}
            patientgetData={encounterDataaaa}
            info={info}
            fetchUser={fetchUser}
            {...props}
          />
        );
      case "medication_form":
        return (
          <MedicationForm
            parent_id={"patient_details"}
            onCancel={onCancel}
            fetchUser={fetchUser}
            patientgetData={encounterDataaaa}
          />
        );
      case "rad_order_form":
        return (
          <RadiologyOrderForm
            parent_id={"patient_details"}
            onCancel={onCancel}
            saveRadiology={saveRadiology}
            patientgetData={encounterDataaaa}
            fetchUser={fetchUser}
            {...props}
          />
        );
      case "treatment_procedure_form":
        return (
          <TreatmentProcedureForm
            parent_id={"patient_details"}
            {...props}
            onCancel={onCancel}
            saveTreatment={saveTreatment}
            patientgetData={encounterDataaaa}
            info={info}
            treatment={props.treatment}
            fetchUser={fetchUser}
          />
        );
      case "docc_form":
        return (
          <DoccForm
            parent_id={"patient_details"}
            {...props}
            onCancel={onCancel}
            saveDoccumentation={saveDoccumentation}
            patientgetData={encounterDataaaa}
            patientID={propsvalue?.patientId}
            encounterID={propsvalue?.encounter_id}
            info={info}
            pID={propsvalue?.pId}
            fetchUser={fetchUser}
            editData={formComponet}
            personDetails={searchPractioner?.data?.[0]}
          />
        );

      default:
        break;
    }
  };

  const saveCheifComp = async (list, editIndex) => {
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: true,
      message: !editIndex ? "Adding New Chief Complaints" : "Updating",
    });
    let personDetails = searchPractioner?.data?.[0];
    let search_Practioner = personDetails?.practitioner?.[0];
    let data = CC_Json_Generator(
      propsvalue,
      list,
      search_Practioner,
      personDetails,
      encounterDataaaa
    );
    if (data?.length > 0) {
      await dispatch(actions.SAVE_CHEIF_COMPAILANTS({ list: data }));
      let cheifComplaints = props.cheifComplaints;
      if (!editIndex && editIndex !== 0) {
        let data = await dispatch(
          actions.ENC_BASED_CC({
            encounter_id: encounterDataaaa?.[0]?._id,
          })
        );
        let dataList = JSON.parse(
          JSON.stringify({
            ...{
              isNew: true,
              createdName: list.createdName,
              createdAt: list.createdAt,
            },
            ...data?.payload?.data?.[data.payload?.data?.length - 1 ?? 0],
          })
        );
        cheifComplaints.list.push(dataList);
      } else {
        cheifComplaints.list[editIndex] = {
          ...list[0],
          isNew: false,
          isModified: true,
        };
      }
      cheifComplaints.setCheifComplaints({
        ...cheifComplaints,
      });
    }
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: false,
      message: "",
    });
  };
  const saveTreatment = async (
    list,
    editIndex,
    date,
    isupdate,
    childIds,
    deleteArr
  ) => {
    let status = recordStatus?.find((val) => val?.label === "Draft");

    let treatment = props.treatment;

    return new Promise(async (resolve, reject) => {
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message:
          editIndex === null
            ? "Adding New Treatment & Procedures"
            : "Updating Treatment & Procedures",
      });

      let personDetails = searchPractioner?.data?.[0];
      let searchPractioner1 = personDetails?.practitioner?.[0];
      let data = Treatment_Json_Generator(
        list,
        encounterDataaaa,
        personDetails,
        searchPractioner1,
        encId,
      );

      if (!editIndex) {
        data.forEach((d) => (d.status = status?.value || ""));
      }

      if (data?.length > 0) {
        let dateArr = [];
        if (list?.[0]?.recurrentList?.length > 0) {
          let listDataArr = list[0].recurrentList.map((val) =>
            getUtcTime(val.date)
          );
          dateArr = [listDataArr];
        } else {
          if (
            list?.[0]?.orderCatalog.label &&
            list?.[0]?.dateTime &&
            list?.[0]?.nature.label &&
            list?.[0]?.priority.label &&
            list?.[0]?.recurrentData?.every?.value &&
            list?.[0]?.recurrentData?.every?.periodicity?.value &&
            (list?.[0]?.recurrentData?.endsAfter?.endsAfter ||
              list?.[0]?.recurrentData?.endsAfter?.endsBy) &&
            list?.[0]?.recurrentData?.startsOn.dateTime
          ) {
            dateArr = getReccuranceDates(
              utcTOLocal(
                list?.[0]?.recurrentData?.startsOn?.dateTime,
                "DD-MM-YYYY"
              ), // DD-MM-YYYY
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? "NoOfDays"
                : "Calendar", // [by => Calendar, on => NoOfDays]
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? JSON.parse(list?.[0]?.recurrentData?.endsAfter?.endsAfter)
                : utcTOLocal(
                    list?.[0]?.recurrentData?.endsAfter?.endsBy,
                    "DD-MM-YYYY"
                  ), // // [by => Date(DD-MM-YYYY), on => number(5)]
              list?.[0]?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
              JSON.parse(list?.[0]?.recurrentData?.every?.value || "{}"), // number
              [], // []
              [] // []
            );
          } else {
            dateArr = [];
          }
        }

        var upsertdata;

        if (treatment?.child?.parentOrderId || date) {
          const vals = await dispatch(
            actions.READ_TREATMENT_CHILD({
              list: data,
              Isparent: date
                ? list?.[0]?.editData
                : treatment?.child?.parentOrderId,
              dates: date ? getUtcTime(date) : null,
            })
          );
        } else {
          upsertdata = await dispatch(
            actions.TREATMENT_PROCEDURE_SAVE({
              list: data,
              dateArr: dateArr?.[0],
              isupdate: isupdate ? true : false,
              childIds: childIds,
              deleteArr,
            })
          );
        }

        if (upsertdata?.payload?.data?.validation_error?.[0]?.Errormsg) {
          AlertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: upsertdata?.payload.data.error,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          props?.backDrop?.setBackDrop({
            ...props?.backDrop,
            open: false,
            message: "",
          });
          return;
        }
        let Treatmentdata = await dispatch(
          actions.TRAEMENT_READ({
            encounter_id: encId?.encId ?? encounterDataaaa?.[0]?._id,
            order_id: "TREAT",
            patientid:location?.state?.patientId,
            startdate: encId?.encStartDate ?? getUtcTime(moment().startOf("day")),
          })
        );

        if (!editIndex && editIndex !== 0) {
          // return
          let dataList = JSON.parse(
            JSON.stringify({
              ...{
                isNew: true,
              },
              infoAlertText: list[0]?.infoAlertText ?? "",
              ...Treatmentdata?.payload?.data?.[
                Treatmentdata?.payload?.data?.length - 1 ?? 0
              ],
            })
          );
          treatment.list.push(dataList);
        } else {
          let dataList = JSON.parse(
            JSON.stringify({
              ...{
                isNew: true,
              },
              infoAlertText: list[0]?.infoAlertText ?? "",
              ...Treatmentdata?.payload?.data?.[
                Treatmentdata?.payload?.data?.length - 1 ?? 0
              ],
            })
          );
          var dataedit;
          if (!treatment?.child?.index >= 0) {
            dataedit = editIndex >= 0 ? list[treatment.editIndex] : 0;
            treatment.list[treatment.editIndex] = dataList;
          } else {
            dataedit =
              treatment.editIndex >= 0
                ? treatment.child.index >= 0
                  ? treatment.list[treatment.editIndex].childRecurrent[
                      treatment.child.index
                    ]
                  : 0
                : 0;
            treatment.list[treatment.editIndex].childRecurrent[
              treatment.child.index
            ] = dataList;
          }
        }
        treatment.child = {};
        await treatment.setTreatment({
          ...treatment,
        });
      }

      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  const saveLabOrders = async (
    list,
    editIndex,
    date,
    isupdate,
    childIds,
    deleteArr
  ) => {
    let status = recordStatus?.find((val) => val?.label === "Draft");
    let treatment = props.LabOrder;

    return new Promise(async (resolve, reject) => {
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message:
          editIndex === null ? "Adding New LabOrder" : "Updating LabOrder",
      });
      let personDetails = searchPractioner?.data?.[0];
      let search_Practioner = personDetails?.practitioner?.[0];
      let data = LabOrder_Json_Generator(
        list,
        encounterDataaaa,
        personDetails,
        search_Practioner,
        encId?.encId,
      );

      if (!editIndex) {
        data.forEach((d) => (d.status = status?.value || ""));
      }
      if (data?.length > 0) {
        let dateArr = [];
        if (list?.[0]?.recurrentList?.length > 0) {
          let listDataArr = list[0].recurrentList.map((val) =>
            getUtcTime(val.date)
          );
          dateArr = [listDataArr];
        } else {
          if (
            list?.[0]?.orderCatalog?._id &&
            list?.[0]?.dateTime &&
            list?.[0]?.nature?.value &&
            list?.[0]?.priority?.value &&
            list?.[0]?.recurrentData?.every?.value &&
            list?.[0]?.recurrentData?.every?.periodicity?.value &&
            (list?.[0]?.recurrentData?.endsAfter?.endsAfter ||
              list?.[0]?.recurrentData?.endsAfter?.endsBy) &&
            list?.[0]?.recurrentData?.startsOn.dateTime
          ) {
            dateArr = getReccuranceDates(
              utcTOLocal(
                list?.[0]?.recurrentData?.startsOn?.dateTime,
                "DD-MM-YYYY"
              ), // DD-MM-YYYY
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? "NoOfDays"
                : "Calendar", // [by => Calendar, on => NoOfDays]
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? JSON.parse(list?.[0]?.recurrentData?.endsAfter?.endsAfter)
                : utcTOLocal(
                    list?.[0]?.recurrentData?.endsAfter?.endsBy,
                    "DD-MM-YYYY"
                  ), // // [by => Date(DD-MM-YYYY), on => number(5)]
              list?.[0]?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
              JSON.parse(list?.[0]?.recurrentData?.every?.value || "{}"), // number
              list?.[0]?.recurrentData?.preferrence?.preferredDays || [], // []
              list?.[0]?.recurrentData?.preferrence?.preferredWeeks || [] // []
            );
          } else {
            dateArr = [];
          }

          // dateArr?.[0]?.sort((a, b) => (b) - (a))
        }
        // if (treatment?.child?.parentOrderId === treatment?.child?.editData) {
        //   await this.props.ORDER_DELETE({
        //     id: list?.[0].editData
        //   })
        // }
        var upsertdata;
        if (
          // (treatment?.child?.parentOrderId) && (treatment?.child?.IsChild)
          treatment?.child?.parentOrderId ||
          date
        ) {
          const vals = await dispatch(
            actions.READ_LABORDER_CHILD({
              list: data,
              Isparent: date
                ? list?.[0]?.editData
                : treatment?.child?.parentOrderId,
              dates: date ? getUtcTime(date) : null,
            })
          );
        } else {
          let setLab = {
            list: data,
            dateArr: dateArr?.[0],
            isupdate: isupdate ? true : false,
            childIds: childIds,
            deleteArr,
          };
          upsertdata = await dispatch(
            actions.SET_LAB_ORDERS_SERVICE({
              list: data,
              dateArr: dateArr?.[0],
              isupdate: isupdate ? true : false,
              childIds: childIds,
              deleteArr,
            })
          );
        }

        if (upsertdata?.payload?.data?.[0]?.validation_error?.[0]?.Errormsg) {
          AlertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: upsertdata?.payload.data.error,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          props?.backDrop?.setBackDrop({
            ...props?.backDrop,
            open: false,
            message: "",
          });
          return;
        }
        let Treatmentdata = await dispatch(
          actions.LABORDER_READ({
            encounter_id: encId?.encId || encounterDataaaa?.[0]?._id,
            order_id: "LAB",
            patientid:location?.state?.patientId,
            startdate:encId?.encStartDate ? encId?.encStartDate : getUtcTime(moment().startOf("day")),
            //upsertdata?.payload?.data?.[0]?.response?.properties?.doc._id,
          })
        );
        // if (!editIndex && editIndex !== 0) {
        //   // return
        //   let dataList = JSON.parse(
        //     JSON.stringify({
        //       ...{
        //         isNew: true,
        //       },
        //       infoAlertText: list[0]?.infoAlertText ?? "",
        //       ...Treatmentdata?.payload?.data?.[
        //        Treatmentdata?.payload?.data?.length - 1 ?? 0
        //      ],
        //     })
        //   );
        //   treatment.list.push(dataList);
        // } else {
        //   let dataList = JSON.parse(
        //     JSON.stringify({
        //       ...{
        //         isNew: true,
        //       },
        //       infoAlertText: list[0]?.infoAlertText ?? "",
        //       ...Treatmentdata?.payload?.data?.[
        //        Treatmentdata?.payload?.data?.length - 1 ?? 0
        //      ],
        //     })
        //   );
        //   var dataedit;
        //   if (!treatment?.child?.index >= 0) {
        //     dataedit = editIndex >= 0 ? list[treatment.editIndex] : 0;
        //     treatment.list[treatment.editIndex] = dataList;
        //   } else {
        //     dataedit =
        //       treatment.editIndex >= 0
        //         ? treatment.child.index >= 0
        //           ? treatment.list[treatment.editIndex].childRecurrent[
        //               treatment.child.index
        //             ]
        //           : 0
        //         : 0;
        //     treatment.list[treatment.editIndex].childRecurrent[
        //       treatment.child.index
        //     ] = dataList;
        //   }
        // }
        // treatment.child = {};
        // await treatment.setLabOrder({
        //   ...treatment,
        // });
        let labordersData = await dispatch(
          actions.LABORDER_READ({
            encounter_id: encId?.encId ?? encounterDataaaa?.[0]?._id,
            order_id: "LAB",
            patientid:location?.state?.patientId,
            startdate: encId?.encStartDate ?? getUtcTime(moment().startOf("day")),
          })
        );
    
        let labOrder = props?.LabOrder;
        await labOrder.setLabOrder({
          ...labOrder,
          list: JSON.parse(JSON.stringify(labordersData?.payload?.data)),
        });
      }

      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };
  const saveNursingProcedure = async (
    list,
    editIndex,
    date,
    isupdate,
    childIds,
    deleteArr
  ) => {
    let status = recordStatus?.find((val) => val?.label === "Draft");
    let treatment = props.NursingProcedure;

    return new Promise(async (resolve, reject) => {
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message:
          editIndex === null
            ? "Adding New Nursing Procedure"
            : "Updating Nursing Procedure",
      });
      let personDetails = searchPractioner?.data?.[0];
      let search_Practioner = personDetails?.practitioner?.[0];
      let data = LabOrder_Json_Generator(
        list,
        encounterDataaaa,
        personDetails,
        search_Practioner,
        encId?.encId,
        encId?.encStartDate,
      );

      if (!editIndex) {
        data.forEach((d) => (d.status = status?.value || ""));
      }
      if (data?.length > 0) {
        let dateArr = [];
        if (list?.[0]?.recurrentList?.length > 0) {
          let listDataArr = list[0].recurrentList.map((val) =>
            getUtcTime(val.date)
          );
          dateArr = [listDataArr];
        } else {
          if (
            list?.[0]?.orderCatalog?._id &&
            list?.[0]?.dateTime &&
            list?.[0]?.nature?.value &&
            list?.[0]?.priority?.value &&
            list?.[0]?.recurrentData?.every?.value &&
            list?.[0]?.recurrentData?.every?.periodicity?.value &&
            (list?.[0]?.recurrentData?.endsAfter?.endsAfter ||
              list?.[0]?.recurrentData?.endsAfter?.endsBy) &&
            list?.[0]?.recurrentData?.startsOn.dateTime
          ) {
            dateArr = getReccuranceDates(
              utcTOLocal(
                list?.[0]?.recurrentData?.startsOn?.dateTime,
                "DD-MM-YYYY"
              ), // DD-MM-YYYY
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? "NoOfDays"
                : "Calendar", // [by => Calendar, on => NoOfDays]
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? JSON.parse(list?.[0]?.recurrentData?.endsAfter?.endsAfter)
                : utcTOLocal(
                    list?.[0]?.recurrentData?.endsAfter?.endsBy,
                    "DD-MM-YYYY"
                  ), // // [by => Date(DD-MM-YYYY), on => number(5)]
              list?.[0]?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
              JSON.parse(list?.[0]?.recurrentData?.every?.value || "{}"), // number
              list?.[0]?.recurrentData?.preferrence?.preferredDays || [], // []
              list?.[0]?.recurrentData?.preferrence?.preferredWeeks || [] // []
            );
          } else {
            dateArr = [];
          }

          // dateArr?.[0]?.sort((a, b) => (b) - (a))
        }
        // if (treatment?.child?.parentOrderId === treatment?.child?.editData) {
        //   await this.props.ORDER_DELETE({
        //     id: list?.[0].editData
        //   })
        // }
        var upsertdata;
        if (
          // (treatment?.child?.parentOrderId) && (treatment?.child?.IsChild)
          treatment?.child?.parentOrderId ||
          date
        ) {
          const vals = await dispatch(
            actions.READ_NURSINGPROCEDURE({
              list: data,
              Isparent: date
                ? list?.[0]?.editData
                : treatment?.child?.parentOrderId,
              dates: date ? getUtcTime(date) : null,
            })
          );
        } else {
          let setLab = {
            list: data,
            dateArr: dateArr?.[0],
            isupdate: isupdate ? true : false,
            childIds: childIds,
            deleteArr,
          };
          upsertdata = await dispatch(
            actions.SET_NURSINGPROCEDURE_SERVICE({
              list: data,
              dateArr: dateArr?.[0],
              isupdate: isupdate ? true : false,
              childIds: childIds,
              deleteArr,
            })
          );
        }

        if (upsertdata?.payload?.data?.[0]?.validation_error?.[0]?.Errormsg) {
          AlertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: upsertdata?.payload.data.error,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          props?.backDrop?.setBackDrop({
            ...props?.backDrop,
            open: false,
            message: "",
          });
          return;
        }
        let Treatmentdata = await dispatch(
          actions.LABORDER_READ({
            encounter_id: encId?.encId || encounterDataaaa?.[0]?._id,
            order_id: "NURSINGPROCEDURE",
            patientid:location?.state?.patientId,
            startdate:encId?.encStartDate ? encId?.encStartDate : getUtcTime(moment().startOf("day")),
            //upsertdata?.payload?.data?.[0]?.response?.properties?.doc._id,
          })
        );
        // if (!editIndex && editIndex !== 0) {
        //   // return
        //   let dataList = JSON.parse(
        //     JSON.stringify({
        //       ...{
        //         isNew: true,
        //       },
        //       infoAlertText: list[0]?.infoAlertText ?? "",
        //       ...Treatmentdata?.payload?.data?.[
        //         Treatmentdata?.payload?.data?.length - 1 ?? 0
        //       ],
        //     })
        //   );
        //   treatment.list.push(dataList);
        // } else {
        //   let dataList = JSON.parse(
        //     JSON.stringify({
        //       ...{
        //         isNew: true,
        //       },
        //       infoAlertText: list[0]?.infoAlertText ?? "",
        //       ...Treatmentdata?.payload?.data?.[
        //         Treatmentdata?.payload?.data?.length - 1 ?? 0
        //       ],
        //     })
        //   );
        //   var dataedit;
        //   if (!treatment?.child?.index >= 0) {
        //     dataedit = editIndex >= 0 ? list[treatment.editIndex] : 0;
        //     treatment.list[treatment.editIndex] = dataList;
        //   } else {
        //     dataedit =
        //       treatment.editIndex >= 0
        //         ? treatment.child.index >= 0
        //           ? treatment.list[treatment.editIndex].childRecurrent[
        //               treatment.child.index
        //             ]
        //           : 0
        //         : 0;
        //     treatment.list[treatment.editIndex].childRecurrent[
        //       treatment.child.index
        //     ] = dataList;
        //   }
        // }
        // treatment.child = {};
        // await treatment?.setNursingProcedure({
        //   ...treatment,
        // });
        let nursingprocedureData = await dispatch(
          actions.LABORDER_READ({
            encounter_id: encId?.encId || encounterDataaaa?.[0]?._id,
            order_id: "NURSINGPROCEDURE",
            patientid:location?.state?.patientId,
            startdate: encId?.encStartDate ? encId?.encStartDate : getUtcTime(moment().startOf("day")),
          })
        );
    
        let nursingprocedure = props?.NursingProcedure;
        await nursingprocedure.setNursingProcedure({
          ...nursingprocedure,
          list: JSON.parse(JSON.stringify(nursingprocedureData?.payload?.data)),
        });
      }

      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };
  const savePackageOrder = async (
    list,
    editIndex,
    date,
    isupdate,
    childIds,
    deleteArr
  ) => {
    let status = recordStatus?.find((val) => val?.label === "Draft");
    let treatment = props.PackageOrder;

    return new Promise(async (resolve, reject) => {
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message:
          editIndex === null
            ? "Adding New Package Order"
            : "Updating Package Order",
      });
      let personDetails = searchPractioner?.data?.[0];
      let search_Practioner = personDetails?.practitioner?.[0];
      let data = LabOrder_Json_Generator(
        list,
        encounterDataaaa,
        personDetails,
        search_Practioner,
        encId?.encId,
      );

      if (!editIndex) {
        data.forEach((d) => (d.status = status?.value || ""));
      }
      if (data?.length > 0) {
        let dateArr = [];
        if (list?.[0]?.recurrentList?.length > 0) {
          let listDataArr = list[0].recurrentList.map((val) =>
            getUtcTime(val.date)
          );
          dateArr = [listDataArr];
        } else {
          if (
            list?.[0]?.orderCatalog?._id &&
            list?.[0]?.dateTime &&
            list?.[0]?.nature?.value &&
            list?.[0]?.priority?.value &&
            list?.[0]?.recurrentData?.every?.value &&
            list?.[0]?.recurrentData?.every?.periodicity?.value &&
            (list?.[0]?.recurrentData?.endsAfter?.endsAfter ||
              list?.[0]?.recurrentData?.endsAfter?.endsBy) &&
            list?.[0]?.recurrentData?.startsOn.dateTime
          ) {
            dateArr = getReccuranceDates(
              utcTOLocal(
                list?.[0]?.recurrentData?.startsOn?.dateTime,
                "DD-MM-YYYY"
              ), // DD-MM-YYYY
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? "NoOfDays"
                : "Calendar", // [by => Calendar, on => NoOfDays]
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? JSON.parse(list?.[0]?.recurrentData?.endsAfter?.endsAfter)
                : utcTOLocal(
                    list?.[0]?.recurrentData?.endsAfter?.endsBy,
                    "DD-MM-YYYY"
                  ), // // [by => Date(DD-MM-YYYY), on => number(5)]
              list?.[0]?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
              JSON.parse(list?.[0]?.recurrentData?.every?.value || "{}"), // number
              list?.[0]?.recurrentData?.preferrence?.preferredDays || [],
              list?.[0]?.recurrentData?.preferrence?.preferredWeeks || []
            );
          } else {
            dateArr = [];
          }

          // dateArr?.[0]?.sort((a, b) => (b) - (a))
        }
        // if (treatment?.child?.parentOrderId === treatment?.child?.editData) {
        //   await this.props.ORDER_DELETE({
        //     id: list?.[0].editData
        //   })
        // }
        var upsertdata;
        if (
          // (treatment?.child?.parentOrderId) && (treatment?.child?.IsChild)
          treatment?.child?.parentOrderId ||
          date
        ) {
          // const vals = await dispatch(
          //   actions.READ_PACKAGEORDER({
          //     list: data,
          //     Isparent: date
          //       ? list?.[0]?.editData
          //       : treatment?.child?.parentOrderId,
          //     dates: date ? getUtcTime(date) : null,
          //   })
          // );
        } else {
          let setLab = {
            list: data,
            dateArr: dateArr?.[0],
            isupdate: isupdate ? true : false,
            childIds: childIds,
            deleteArr,
          };
          upsertdata = await dispatch(
            actions.SET_PACKAGEORDER_SERVICE({
              list: data,
              dateArr: dateArr?.[0],
              isupdate: isupdate ? true : false,
              childIds: childIds,
              deleteArr,
            })
          );
        }

        if (upsertdata?.payload?.data?.[0]?.validation_error?.[0]?.Errormsg) {
          AlertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: upsertdata?.payload.data.error,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          props?.backDrop?.setBackDrop({
            ...props?.backDrop,
            open: false,
            message: "",
          });
          return;
        }
        let Treatmentdata = await dispatch(
          actions.LABORDER_READ({
            encounter_id: encId?.encId ?? encounterDataaaa?.[0]?._id,
            order_id: "PACK",
            patientid:location?.state?.patientId,
            startdate: encId?.encStartDate ?? getUtcTime(moment().startOf("day")),
            //upsertdata?.payload?.data?.[0]?.response?.properties?.doc._id,
          })
        );
        // if (!editIndex && editIndex !== 0) {
        //   // return
        //   let dataList = JSON.parse(
        //     JSON.stringify({
        //       ...{
        //         isNew: true,
        //       },
        //       infoAlertText: list[0]?.infoAlertText ?? "",
        //       ...Treatmentdata?.payload?.data?.[
        //         Treatmentdata?.payload?.data?.length - 1 ?? 0
        //       ],
        //     })
        //   );
        //   treatment.list.push(dataList);
        // } else {
        //   let dataList = JSON.parse(
        //     JSON.stringify({
        //       ...{
        //         isNew: true,
        //       },
        //       infoAlertText: list[0]?.infoAlertText ?? "",
        //       ...Treatmentdata?.payload?.data?.[
        //         Treatmentdata?.payload?.data?.length - 1 ?? 0
        //       ],
        //     })
        //   );
        //   var dataedit;
        //   if (!treatment?.child?.index >= 0) {
        //     dataedit = editIndex >= 0 ? list[treatment.editIndex] : 0;
        //     treatment.list[treatment.editIndex] = dataList;
        //   } else {
        //     dataedit =
        //       treatment.editIndex >= 0
        //         ? treatment.child.index >= 0
        //           ? treatment.list[treatment.editIndex].childRecurrent[
        //               treatment.child.index
        //             ]
        //           : 0
        //         : 0;
        //     treatment.list[treatment.editIndex].childRecurrent[
        //       treatment.child.index
        //     ] = dataList;
        //   }
        // }
        // treatment.child = {};
        // await treatment?.setPackageOrder({
        //   ...treatment,
        // });
        let packageorderData = await dispatch(
          actions.LABORDER_READ({
            encounter_id: encId?.encId ?? encounterDataaaa?.[0]?._id,
            order_id: "PACK",
            patientid:location?.state?.patientId,
            startdate: encId?.encStartDate ?? getUtcTime(moment().startOf("day")),
          })
        );
    
        let packageorder = props?.PackageOrder;
        await packageorder.setPackageOrder({
          ...packageorder,
          list: JSON.parse(JSON.stringify(packageorderData?.payload?.data)),
        });
      }

      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  const saveRadiology = async (
    list,
    editIndex,
    date,
    isupdate,
    childIds,
    deleteArr
  ) => {
    let status = recordStatus?.find((val) => val?.label === "Draft");
    let radiology = props.radiology;
    return new Promise(async (resolve, reject) => {
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message:
          editIndex === null ? "Adding New Radiology" : "Updating Radiology",
      });
      let personDetails = searchPractioner?.data?.[0];
      let search_Practioner = personDetails?.practitioner?.[0];
      let data = Rad_Json_Generator(
        list,
        encounterDataaaa,
        personDetails,
        search_Practioner,
        encId?.encId,
      );
      if (!editIndex) {
        data.forEach((d) => (d.status = status?.value || ""));
      }
      if (data?.length > 0) {
        let dateArr = [];
        if (list?.[0]?.recurrentList?.length > 0) {
          let listDataArr = list[0].recurrentList.map((val) =>
            getUtcTime(val.date)
          );
          dateArr = [listDataArr];
        } else {
          if (
            list?.[0]?.orderCatalog.label &&
            list?.[0]?.dateTime &&
            list?.[0]?.nature.label &&
            list?.[0]?.priority.label &&
            list?.[0]?.recurrentData?.every?.value &&
            list?.[0]?.recurrentData?.every?.periodicity?.value &&
            (list?.[0]?.recurrentData?.endsAfter?.endsAfter ||
              list?.[0]?.recurrentData?.endsAfter?.endsBy) &&
            list?.[0]?.recurrentData?.startsOn.dateTime
          ) {
            dateArr = getReccuranceDates(
              utcTOLocal(
                list?.[0]?.recurrentData?.startsOn?.dateTime,
                "DD-MM-YYYY"
              ), // DD-MM-YYYY
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? "NoOfDays"
                : "Calendar", // [by => Calendar, on => NoOfDays]
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? JSON.parse(list?.[0]?.recurrentData?.endsAfter?.endsAfter)
                : utcTOLocal(
                    list?.[0]?.recurrentData?.endsAfter?.endsBy,
                    "DD-MM-YYYY"
                  ), // // [by => Date(DD-MM-YYYY), on => number(5)]
              list?.[0]?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
              JSON.parse(list?.[0]?.recurrentData?.every?.value || "{}"), // number
              [], // []
              [] // []
            );
          } else {
            dateArr = [];
          }
        }

        var upsertdata;
        if (radiology?.child?.parentOrderId || date) {
          const vals = await dispatch(
            actions.READ_RAD_CHILD({
              list: data,
              Isparent: date
                ? list?.[0]?.editData
                : radiology?.child?.parentOrderId,
              dates: date ? getUtcTime(date) : null,
            })
          );
        } else {
          upsertdata = await dispatch(
            actions.RAD_SAVE({
              list: data,
              dateArr: dateArr?.[0],
              isupdate: isupdate ? true : false,
              childIds: childIds,
              deleteArr,
            })
          );
        }

        if (upsertdata?.payload?.data?.validation_error?.[0]?.Errormsg) {
          AlertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: upsertdata.payload.data.error,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          props?.backDrop?.setBackDrop({
            ...props?.backDrop,
            open: false,
            message: "",
          });
          return;
        }

        let Treatmentdata = await dispatch(
          actions.RAD_READ({
            encounter_id: encId?.encId ?? encounterDataaaa?.[0]?._id,
            order_id: "RAD",
            patientid:location?.state?.patientId,
            startdate:encId?.encStartDate ?? getUtcTime(moment().startOf("day")),
          })
        );
        if (!editIndex && editIndex !== 0) {
          // return
          if (
            Array.isArray(Treatmentdata?.payload?.data) &&
            Treatmentdata?.payload?.data?.length > 0
          ) {
            Treatmentdata.payload.data.map((val) => {
              let dataList = JSON.parse(
                JSON.stringify({
                  ...{
                    isNew: true,
                  },
                  infoAlertText: list[0]?.infoAlertText ?? "",
                  ...val,
                })
              );
              radiology.list.push(dataList);
            });
          }
        } else {
          let dataList = JSON.parse(
            JSON.stringify({
              ...{
                isNew: true,
              },
              infoAlertText: list[0]?.infoAlertText ?? "",
              ...Treatmentdata?.payload?.data?.[
                Treatmentdata?.payload?.data?.length - 1 ?? 0
              ],
            })
          );
          var dataedit;
          if (!radiology?.child?.index >= 0) {
            dataedit = editIndex >= 0 ? list[radiology.editIndex] : 0;
            radiology.list[radiology.editIndex] = dataList;
          } else {
            dataedit =
              radiology.editIndex >= 0
                ? radiology.child.index >= 0
                  ? radiology.list[radiology.editIndex].childRecurrent[
                      radiology.child.index
                    ]
                  : 0
                : 0;
            radiology.list[radiology.editIndex].childRecurrent[
              radiology.child.index
            ] = dataList;
          }
        }
        radiology.child = {};
        await radiology.setRadiology({
          ...radiology,
        });
      }
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  const saveAllergy = async (list, editIndex, data) => {
    return new Promise(async (resolve, reject) => {
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message: editIndex === null ? "Adding New Allergy" : "Updating",
      });

      if (data) {
        let saveFun = await dispatch(actions.SAVE_ALLERGY({ list: data }));

        let allergy = props.allergy;

        if (!saveFun.payload.error) {
          if (!editIndex && editIndex !== 0) {
          } else {
            allergy.list[editIndex] = {
              ...list[0],
              isNew: false,
              isModified: true,
            };
          }
        } else {
          AlertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: saveFun.payload.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        }
        allergy.setAllergy({
          ...allergy,
        });
      }

      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  const saveDiagnosis = async (list, editIndex) => {
    return new Promise(async (resolve, reject) => {
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message: editIndex === null ? "Adding New Diagnosis" : "Updating",
      });
      let personDetails = searchPractioner?.data?.[0];
      let searchPractioner1 = personDetails?.practitioner?.[0];

      let data = DIAGNOSIS_Json_Generator(
        list,
        searchPractioner1,
        personDetails,
        encounterDataaaa
      );

      if (data?.length > 0) {
        let saveFun = await dispatch(actions.DIAGNOSIS_SAVE({ list: data }));
        // await this.props.DIAGNOSIS_MASTERS_VALUE({
        //   id: list[0]?.diagnosis?.value,
        // });
        let diagnosis = props.Diagnosis;
        let diaList = JSON.parse(JSON.stringify(diagnosis.list));
        if (!saveFun.payload.error) {
          if (!editIndex && editIndex !== 0) {
            let data = await dispatch(
              actions.DIAGNOSIS_READ({
                // encounter_id: encounterDataaaa?.data?.eId,
                patient_id: encounterDataaaa[0]?.Patient[0]?._id,
              })
            );

            let dataList = JSON.parse(
              JSON.stringify({
                ...{
                  isNew: true,
                  createdName: list[0].createdName,
                  createdAt: list[0].createdAt,
                  onset: list[0].onset,
                },
                ...data?.payload?.data?.[data.payload?.data?.length - 1 ?? 0],
              })
            );
            diaList.push(dataList);
          } else {
            if (diaList[editIndex]) {
              diaList[editIndex].isNew = false;
              diaList[editIndex].isModified = true;
            }
          }
        } else {
          AlertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: saveFun.payload.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        }
        diagnosis.setDiagnosis({
          ...diagnosis,
          list: diaList,
        });
      }
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  const saveDoccumentation = async (list, editIndex) => {
    return new Promise(async (resolve, reject) => {
      let { Doccument, setDoccument } = props.Doccument;
      let personDetails = searchPractioner?.data?.[0];

      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message: editIndex === "Add" ? "Adding New Documentation" : "Updating",
      });
      const data = await dispatch(
        actions.DOCC_SAVE_SERVICE({
          data: {
            data: list[0],
            encounterData: encounterDataaaa,
            personDetails,
            formID: list?.[0]?.selectDoccument?.formrefid,
            formdata: list[0]?.formData ?? {},
          },
        })
      );
      if (data?.payload?.data?.Code === 201 && editIndex === "Add") {
        let doc_key = data?.payload?.data?.Result[0]?.properties?.doc?._key;
        await dispatch(actions.DOCC_SINGLE_READ({ key: doc_key }));
      }
      let Readdata = await dispatch(
        actions.DOCC_READ({
          // encounter_id: this.props.encounterData?.data?.eId,
          patient_id: propsvalue?.pId,
        })
      );

      setDoccument({
        ...Doccument,
        list: JSON.parse(JSON.stringify(Readdata?.payload?.data)),
      });
      // if (!editIndex && editIndex !== 0) {
      //   let data = await this.props.DOCC_READ({
      //     encounter_id: this.props.encounterData?.data?.eId,
      //   });

      // // return
      // let dataList = JSON.parse(
      //   JSON.stringify({
      //     ...{
      //       isNew: true,
      //     },
      //     ...data?.payload?.data?.[data.payload?.data?.length - 1 ?? 0],
      //   })
      // );
      // Doccument.editIndex =
      //   data?.payload?.data?.[data.payload?.data?.length - 1]?._key;
      // Doccument.list.push(dataList);
      // } else {
      // Doccument.list[editIndex] = list[0];
      // }
      // Doccument.setDoccument({
      //   ...Doccument,
      // });

      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  const sign_OffFun = () => {
    setsignOff(true);
    //this.setState({ signOff: true });
    if (formsList?.includes("follow_up")) {
      if (!followup_number) {
        AlertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter followup after",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      } else if (!followup_type?.label) {
        AlertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please select followup type",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      } else {
        setsignOff(true);
      }
    } else {
      setsignOff(true);
    }
  };
  const signOffFunc = async () => {
    setsignOff(true);
    return;
  };
  const set_dialysisData = (data) => {
    setfooterDialsisData({ ...data });
    return;
  };

  const confirmButtonAction = async () => {
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "checking",
    });
    let action = "";
    if (roleName === "Doctor") {
      action = process.env.REACT_APP_DOCTOR_SIGN_OFF;
      if (
        followup_number?.length > 0 &&
        followup_reason?.value &&
        followup_type?.value
      ) {
        let payload = {
          eSignature: "",
          followupDur: Number(followup_number),
          followupreqd: true,
          followupUOM: followup_type?.value,
          reasonCode: followup_reason?.value,
          encounterid: location?.state?.encounter_id,
          appointmentid: location?.state?.allCurrentData?.info?._id,
          practitionerid: location?.state?.allCurrentData?.info?.resourcecode,
          orgid: location?.state?.allCurrentData?.info?.orgid,
          healthserviceid: "0",
          patientid: location?.state?.pId ?? location?.state?.patientId,
          specialtyid: "0",
          encounter_type:
            location?.state?.allCurrentData?.info?.consultationMode,
          orderid: "",
          followupdate: Number(
            (
              dateGiverForFollowUp(followup_number, followup_type) / 1000
            ).toFixed()
          ),
          encounterdate: moment().unix(),
        };
        props.backDrop.setBackDrop({
          ...props.backDrop,
          open: true,
          message: "signing off",
        });
        await dispatch(actions.DOCTOR_SIGN_OFF_FOLLOWUP_ACTION(payload));
      } else if (
        ![
          followup_number?.length > 0,
          followup_reason?.value,
          followup_type?.value,
        ].every((v) => v) &&
        [
          followup_number?.length > 0,
          followup_reason?.value,
          followup_type?.value,
        ].some((v) => v)
      ) {
        AlertContext.setSnack({
          open: true,
          severity: AlertProps.severity.warning,
          msg: "Please fill all follow up details",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return;
      }
    } else if (roleName === "Nurse") {
      action = process.env.REACT_APP_NURSE_SIGN_OFF;
    }

    let data = await dispatch(
      actions.SIGN_OFF_ACTION({
        userAction: action,
        ticketId: location?.state?.ticketId,
      })
    );
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
    // const laborders = props.LabOrder;
    // const radiologyData = props.radiology;
    // const treatmentData = props.treatment;
    // const ordersObj = [];
    // let personDetails = searchPractioner?.data?.[0];
    // //let encounterData = encounterData;
    // //const { appointmentId, app_id } = location?.state;

    // // if (this.state?.formsList?.includes("follow_up")) {
    // if (followup_number || followup_type?.label) {
    //   if (!followup_number) {
    //     AlertContext.setSnack({
    //       open: true,
    //       severity: AlertProps.severity.error,
    //       msg: "Please enter followup after",
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.right,
    //       tone: true,
    //     });
    //     return false;
    //   } else if (!followup_type?.label) {
    //     AlertContext.setSnack({
    //       open: true,
    //       severity: AlertProps.severity.error,
    //       msg: "Please select followup type",
    //       vertical: AlertProps.vertical.top,
    //       horizontal: AlertProps.horizontal.right,
    //       tone: true,
    //     });
    //     return false;
    //   } else {
    //     const encounter_key = encounterDataaaa?.data?.eId.split("/");
    //     //CALL ENCOUNTER UPSERT
    //     await dispatch(
    //       actions.ENCOUNTER_UPSERT({
    //         data: {
    //           _key: encounter_key[1],
    //           followup_number: followup_number ? parseInt(followup_number) : 0,
    //           followup_type: followup_type?.value ?? "",
    //           followup_reason: followup_reason?.value ?? "",
    //         },
    //       })
    //     );
    //     //CALL FOLLOWUP ADD
    //     await dispatch(
    //       actions.SET_FOLLOWUP_SERVICE({
    //         data: {
    //           date: moment().unix(),
    //           status: "pending",
    //           encounterId: encounterDataaaa?.data?.eId ?? "",
    //           practitionerId: personDetails?.practitioner?.[0]?._id
    //             ? personDetails?.practitioner?.[0]?._id
    //             : "",
    //           orgId: personDetails?.OrgID
    //             ? JSON.stringify(personDetails?.OrgID)
    //             : "",
    //           patientId: encounterDataaaa?.data?.pID ?? "",
    //           editId:
    //             followup?.payload?.data?.length > 0
    //               ? followup.payload.data[0]._id
    //               : "",
    //           followup_number: followup_number ? parseInt(followup_number) : 0,
    //           followup_type: followup_type?.value ?? "",
    //           followup_reason: followup_reason?.value ?? "",
    //           appointmentId:
    //             // app_id
    //             JSON.stringify(propsvalue?.appointmentId) ?? "",
    //         },
    //       })
    //     );
    //   }
    // }
    // if (radiologyData.list.length > 0) {
    //   ordersObj.push("radiologyData");
    // }
    // if (treatmentData.list.length > 0) {
    //   ordersObj.push("treatmentData");
    // }
    // if (laborders.list.length > 0) {
    //   ordersObj.push("laborders");
    // }
    // let status = "completed_nurse";
    // let isCompleted = false;
    // if (loggedUserInfo.data.internalRole === "doctor") {
    //   status = "completed";
    //   isCompleted = true;
    // }
    // await dispatch(
    //   actions.UPDATE_CA_WORKFLOW_STATUS({
    //     personId: personDetails._id,
    //     types: ordersObj,
    //   })
    // );
    // await dispatch(
    //   actions.UPDATE_ALL_WORKFLOW_STATUS({
    //     personId: personDetails._id,
    //   })
    // );
    // await updateStatus(
    //   status,
    //   propsvalue?.appointmentId,
    //   undefined,
    //   isCompleted
    // );
    navigate(AppRoutes.dashboard);
  };

  //const { isProfileView } = location?.state
  const getallattachments = (encounterDataaaa) => {
    let array = [];
    if (encounterDataaaa.length > 0) {
      let patientIdentifier =
        encounterDataaaa?.[0]?.Patient?.[0]?.personInfo?.[0]?.identifier?.[0]
          ?.attachments;
      if (patientIdentifier.length > 0) {
        //  array = [...patientIdentifier]
        patientIdentifier.map((li) => {
          array.push(li);
        });
      }
      let patientInfo =
        encounterDataaaa?.[0]?.Patient?.[0]?.personInfo?.[0]?.attachments;
      if (patientInfo.length > 0) {
        // array = [...patientInfo]
        patientInfo.map((li) => {
          array.push(li);
        });
      }
    }
    return array;
  };
  const getvisitalldocuments = (encounterDataaaa) => {
    let array1 = [];
    if (encounterDataaaa.length > 0) {
      let visitDoc = encounterDataaaa?.[0]?.attachment;
      if (visitDoc.length > 0) {
        visitDoc.map((li) => {
          array1.push(li);
        });
      }
    }
    return array1;
  };
  const getfindataalldoc = (fin_data) => {
    let array2 = [];
    if (fin_data?.length > 0) {
      let finDoc = fin_data?.[1]?.documentref;
      if (finDoc?.length > 0) {
        finDoc.map((li) => {
          array2.push(li);
        });
      }
    }
    return array2;
  };
  const getrefalldoc = (ReferralData) => {
    let array3 = [];
    if (ReferralData?.length > 0) {
      let refDoc = ReferralData?.[0]?.attachment;
      if (refDoc?.length > 0) {
        refDoc.map((li) => {
          array3.push(li);
        });
      }
    }
    return array3;
  };

  const handlecurrentmedicationval = (value) => {
    setCurrentMedicationValue(value);
  };
  const currentcancel = () => {
    setisEditMedic(false);
    setCurrentMedicationValue(notes?.[0]?.clicnicaldocdesc);
  };
  const handleSave = async () => {
    const now = Date.now();
    const epochTimeInSeconds = encId?.encStartDate ? encId?.encStartDate : Math.floor(now / 1000);
    let payloadList = [];
    if (currentMedicationValue?.length > 0) {
      const payload = {
        _id: notes?.[0]?._id ?? "",
        tenantid: __tenantid__,
        facilityid: _facility_,
        patientid: location?.state?.patientId,
        encounterid: encId?.encId ?? location?.state?.encounter_id,
        clicnicaldocdate: epochTimeInSeconds,
        clicnicaldoctype: process.env.REACT_APP_CURRENT_MEDICATION ?? "",
        clicnicaldocdesc: currentMedicationValue,
        practitionerid: jwtDecode(localStorage.getItem("UserInfo"))
          ?.practitionerid,
      };
      payloadList.push(payload);

      let jwtObj = { jwtToken: localStorage.getItem("JwtToken") };
      if (payloadList.length > 0) {
        const currentclinicalupsert = await axiosCall(
          payloadList,
          urls.saveClinicalNotes,
          jwtObj
        );

        if (!currentclinicalupsert?.error) {
          let current_medication = await dispatch(
            actions.GET_CURRENT_MEDICATION_ORDER(
              // encounterData?.payload?.data?.[0]?.Patient?.[0]?._id
              location?.state?.patientId
            )
          );
          setNotes(current_medication?.payload?.data);
          let cmdata = current_medication?.payload?.data;
          if (cmdata?.length > 0) {
            cmdata.forEach((v, i) => {
              if (
                v?.clicnicaldoctype === process.env.REACT_APP_CURRENT_MEDICATION
              ) {
                setCurrentMedicationValue(v?.clicnicaldocdesc);
                // if (v?.clicnicaldocdesc?.length > 0) {
                //   setisEditMedic(true);
                // } else {
                //   setisEditMedic(false);
                // }
              }
            });
          }
          setisEditMedic(false);
          const alertmsg = isEditMedic
            ? " Current Medication Updated successfully!"
            : " Current Medication Saved successfully!";
          AlertContext.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: alertmsg,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        } else {
          AlertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: currentclinicalupsert?.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }
      }
    } else {
      AlertContext.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the details",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  const oneditClick = () => {
    setisEditMedic(true);
  };
  return (
    <div id="patient_detail_parent_div">
      <div>
        {/* <TopHeader
            parent_id={"dashboard"}
            noHome={true}
            middleComponent={<></>}
          /> */}
        {/* Main patient details */}
        <Row
          id="patient_detail_parent_row"
          inLineStyles={{
            // background:
            //  "transparent linear-gradient(90deg, rgb(251 194 235 / 20%) 0%, hsl(237deg 78% 82% / 20%) 49%, #6bbbff33 100%) 0% 0% no-repeat padding-box",
            while: "white",
            padding: "5px 10px 5px 10px",
          }}
        >
          <Grid container xs={12}>
            <Grid
              item
              xs={4}
              inLineStyles={{ padding: 5, borderRight: "1px solid #DFE5EB" }}
            >
              <PatientInfoCard
                parent_id={"patient_detail"}
                pic={encounterDataaaa?.[0]?.Patient?.[0]?.photo?.[0]?.[0]?.url}
                name={makeName(encounterDataaaa?.[0]?.Patient?.[0]?.name[0])}
                age={getCalculatedAge(
                  encounterDataaaa?.[0]?.Patient?.[0]?.personInfo[0]?.birthdate
                )}
                gender={encounterDataaaa?.[0]?.Patient?.[0]?.Gender?.display}
                mrn_id={encounterDataaaa?.[0]?.Patient?.[0]?.patmrn}
                pi_id={
                  encounterDataaaa?.[0]?.Patient?.[0]?.personInfo?.[0]
                    ?.identifier?.[0]?.value
                }
                patientphonenum={
                  encounterDataaaa?.[0]?.Patient?.[0]?.personInfo?.[0]
                    ?.telecom?.[0]?.value
                }
                attachments={getallattachments(encounterDataaaa)}
              />
            </Grid>
            <Grid item xs={8} container>
              <Col
                id="patient_detail_statuscard_col"
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                inLineStyles={{ padding: 5, borderRight: "1px solid #DFE5EB" }}
              >
                <PatientStatusCard
                  // parent_id={"patient_detail"}
                  // pic={
                  //   GroupIcon
                  //   //  "images/Group 90506.svg"
                  //   // "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                  // }
                  // titles={[
                  //   encounterDataaaa?.[0]?.encounter_no,
                  //   "OP",
                  //   encounterDataaaa?.[0]?.type?.longDesc,
                  //   encounterDataaaa?.[0]?.practitioner_details?.[0]?.speciality
                  //     ?.display,
                  // ]}
                  // date={utcTOLocal(
                  //   encounterDataaaa?.[0]?.updatedate,
                  //   "DD MMM YYYY"
                  // )}
                  // languages={encounterDataaaa?.lang}
                  // attachments={getvisitalldocuments(encounterDataaaa)}
                  parent_id={"patient_detail"}
                  encounterList={encounterDataList}
                  selected={selectencounterDataList}
                  handleEncounterSelect={handleEncounterSelect}
                  getPreviousSet={getPreviousSet}
                  getNextSet={getNextSet}
                  Isdisableright={Isdisableright}
                  Isdisableleft={Isdisableleft}
                  count={count}
                />
              </Col>
              <Col
                id="patient_detail_care_typecard_col"
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                inLineStyles={{
                  padding: 5,
                  borderRight: "1px solid #DFE5EB",
                  paddingRight: 5,
                }}
              >
                <PatientCareTypeCard
                  parent_id={"patient_detail"}
                  careList={fin_data}
                  selected={selectOption}
                  onChange={(data) => changeState("selectOption", data)}
                  attachments={getfindataalldoc(fin_data)}
                  isDetailed = {true}
                />
              </Col>
              {location?.state?.referralId && (
                <Col
                  id="patient_detail_care_typecard_col"
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  inLineStyles={{ padding: 5 }}
                >
                  <ReferralListView
                    ReferralData={ReferralData}
                    parent_id={"patientdetails"}
                    attachments={getrefalldoc(ReferralData)}
                  />
                </Col>
              )}
            </Grid>
          </Grid>

          {/* <Miniwidget
              permissions={permissions}
              parent_id={"patent_details"}
              editWidget={this.onEditWidget}
            /> */}
        </Row>
      </div>
      <Row
        id="patient_detail_footer_row"
        inLineStyles={{ borderTop: "1px solid rgb(224, 224, 224)" }}
      >
        <Col
          id="patient_detail_footer_col"
          xl={signOff ? 12 : 9}
          // xl={12}
          lg={signOff ? 12 : 9}
          // lg={12}
          md={signOff ? 12 : 9}
          // md={12}
          sm={12}
          xs={12}
          inLineStyles={{ height: "calc( 100vh - 93px )", overflow: "auto" }}
          style={{ ...styles.columnStyle, padding: 0 }}
        >
          <div
            id="patient_detail_footer_buttons_div"
            style={{ display: "flex", overflowX: "hidden" }}
          >
            {/* Menus items */}
            {!signOff && !propsvalue?.isProfileView && (
              <Div
                id="patient_detail_menulist_div"
                inLineStyles={{
                  backgroundColor: "#fff",
                  //borderRight: "1px solid rgb(224, 224, 224)",
                  padding: "10px",
                  overflow: "auto",
                }}
              >
                <MenuList
                  permissions={permissions}
                  parent_id={"patient_detail"}
                  selectedFormList={changeChartLayer}
                  selectedForm={formsList ?? []}
                />
              </Div>
            )}

            {/* Mian Action items and Chart */}

            {formComponet?.open ? (
              //  Edit the patient_chart forms
              <Div
                id="patient_detail_formtype_div"
                inLineStyles={{
                  padding: "10px 10px 0px 10px",
                  width: "95%",
                  background: UIColor.greyBackground.color,
                }}
              >
                {getFormTypeRender(formComponet?.type)}
              </Div>
            ) : (
              <Div
                id="patient_detail_buttons_div"
                inLineStyles={{
                  width: "95%",
                  background: !signOff
                    ? UIColor.greyBackground.color
                    : "transparent",
                  height: !signOff ? "auto" : "65vh",
                }}
              >
                <div
                  id="patient_charts_div"
                  style={{
                    maxWidth: signOff ? "1140px" : "100%",
                    margin: signOff ? "auto" : "0",
                  }}
                >
                  {!signOff && !propsvalue?.isProfileView ? (
                    <>
                      {/* Action Items contains */}
                      <ActionItems
                        saveLabOrders={saveLabOrders}
                        saveNursingProcedure={saveNursingProcedure}
                        savePackageOrder={savePackageOrder}
                        onEditChart={onEditChart}
                        handleClose={handleClose}
                        treatmentplan={treatmentplan}
                        patientgetData={encounterDataaaa?.[0]}
                        dialysisData={dialysisData}
                        progressNotesData={progressNotesData}
                        getDialysisData={readDialysisWithEncouterid}
                        orgdetails={fetchUser}
                        saveTreatment={saveTreatment}
                        setTreatmentplan={setTreatmentplan}
                        locationdata={location}
                        saveAllergy={saveAllergy}
                        saveDiagnosis={saveDiagnosis}
                        handleSave={handleSave} //medication
                        currentcancel={currentcancel} //medication
                        handlecurrentmedicationval={handlecurrentmedicationval} //medication
                        currentMedicationValue={currentMedicationValue} //medication
                        allNotes={notes} //medication
                        isEdit={isEditMedic} //medication
                        oneditClick={oneditClick} //medication
                        referralId={location?.state?.referralId}
                        editEncData={encId}
                      />
                      {getCharts(patientChartList)}
                    </>
                  ) : (
                    <div style={{ margin: "20px" }}>
                      <SignOffCard
                        selectedFormList={changeChartLayer}
                        selectedForm={formsList ?? []}
                        permissions={permissions}
                        parent_id={"patient_detail"}
                        followup_number={followup_number}
                        followup_type={followup_type}
                        formsList={formsList}
                        followupHnadle={followupHnadle}
                        handleInputChange={handleInputChange}
                        tabHandle={tabHandle}
                        handleselectChange={handleselectChange}
                        followup={followup}
                        reasonData_={reason_}
                        followup_reason_={followup_reason}
                        followupuom={followupuom}
                        dialysisData={dialysisData?.component?.[0] ?? {}}
                        isProfileView={propsvalue?.isProfileView}
                        patientgetData={encounterDataaaa?.[0]}
                        set_dialysisData={set_dialysisData}
                      />
                    </div>
                  )}
                </div>
                {/* Footer  */}
                <>
                  <Row
                    id="patient_detail_footer_row"
                    inLineStyles={{
                      position: signOff ? "relative" : "fixed",
                      bottom: 0,
                      width: signOff ? "100%" : "71%",
                      backgroundColor: UIColor.greyBackground.color,
                    }}
                  >
                    {formsList?.includes("follow_up") &&
                      !signOff &&
                      !propsvalue?.isProfileView && (
                        <FollowupFotter
                          followup_number={followup_number}
                          followup_type={followup_type}
                          followup_reason_={followup_reason}
                          followupHnadle={followupHnadle}
                          handleInputChange={handleInputChange}
                          tabHandle={tabHandle}
                          handleselectChange={handleselectChange}
                          reasonData_={reason_}
                          followupuom={followupuom}
                        />
                      )}

                    {/* <Divider
                                                id="patient_detail_bottom_divider"
                                                variant={"fullWidth"}
                                                inLineStyles={{ backgroundColor: "#E0E0E0" }}
                                            /> */}
                    {!signOff && !propsvalue?.isProfileView ? (
                      <Col
                        id="patient_detail_footer_buttons_col"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        inLineStyles={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingBottom: "20px",
                          background: UIColor.greyBackground.color,
                          // paddingTop: "20px",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            background: "#fff",
                            padding: "8px 12px",
                            borderRadius: "0px 0px 10px 10px",
                            width: "100%",
                          }}
                        >
                          <Div id="patient_detail_goback_button_div">
                            <Button
                              id="patient_detail_goback_button"
                              onClick={() => goBack()}
                              variant={"text"}
                              inLineStyles={{
                                backgroundColor: UIColor.lineBorderFill.color,
                                borderColor: UIColor.lineBorderFill.color,
                                color: UIColor.primaryColor.color,
                                borderRadius: "8px",
                                fontSize: "14px",
                              }}
                            >
                              {"Go back"}
                            </Button>
                          </Div>
                          <Div id="patient_detail_hold-sign_button_div">
                            <Button
                              id="patient_detail_put_hold_button"
                              inLineStyles={{
                                backgroundColor: "#FFE1DA",
                                borderColor: "#FFE1DA",
                                color: UIColor.secondaryColor.color,
                                marginRight: "14px",
                                borderRadius: "8px",
                                fontSize: "14px",
                              }}
                              onClick={async () => {
                                await dispatch(
                                  actions.SIGN_OFF_ACTION({
                                    userAction: process.env.REACT_APP_HOLD_ON,
                                    ticketId: location?.state?.ticketId,
                                  })
                                );
                                navigate(AppRoutes.dashboard);
                              }}
                              style={{
                                display:
                                  location?.state?.allCurrentData?.queueMetaData
                                    ?.queuename?.queuename ===
                                  "Appoinment Queue"
                                    ? "flex"
                                    : "none",
                              }}
                            >
                              {"Put On-Hold"}
                            </Button>
                            <Button
                              id="patient_detail_signoff_button"
                              onClick={() => signOffFunc()}
                              inLineStyles={{
                                backgroundColor: UIColor.primaryColor.color,
                                borderColor: UIColor.primaryColor.color,
                                borderRadius: "8px",
                                fontSize: "14px",
                              }}
                              style={{
                                display:
                                  location?.state?.allCurrentData?.queueMetaData
                                    ?.queuename?.queuename ===
                                  "Appoinment Queue"
                                    ? "flex"
                                    : "none",
                              }}
                            >
                              {"Sign Off"}
                            </Button>
                          </Div>
                        </div>
                      </Col>
                    ) : (
                      <Footer
                        confirmButtonAction={confirmButtonAction}
                        parent_id={"patient_detail"}
                        goBackButtonAction={() =>
                          goBackInsignOff("signOff", false)
                        }
                        isProfileView={propsvalue?.isProfileView}
                        encounterData={encounterDataaaa}
                        footerData={footerDialsisData}
                      />
                    )}
                  </Row>
                </>
              </Div>
            )}
            {/* END Mian Action items and Chart */}
          </div>
        </Col>
        {!signOff && !propsvalue?.isProfileView && (
          <Col
            id="patient_detail_info_widget_col"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            inLineStyles={styles.rightSideCol}
          >
            <Row id="patient_detail_info_widget_row">
              <Col
                id="patient_detail_infoHeader_col"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={styles.infoHeader}
              >
                <Div id="patient_detail_info_div">
                  <Text
                    id="patient_detail_info_text"
                    inLineStyles={{
                      fontWeight: 600,
                      fontSize: "16px",
                      color: UIColor.primaryText.color,
                    }}
                  >
                    {"Info"}
                  </Text>
                </Div>
                <Div id="patient_detail_Add_Widget_div">
                  <Button
                    id="patient_detail_Add_Widget_button"
                    variant={"text"}
                    inLineStyles={{
                      color: UIColor.secondaryColor.color,
                      padding: "5px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {"+ Add Widget"}
                  </Button>
                </Div>
              </Col>

              {/* Widgets list  */}
              <Col
                id="patient_detail_WidgetList_col"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={styles.background}
              >
                <WidgetsList
                  permissions={permissions}
                  parent_id={"patent_details"}
                  editWidget={onEditWidget}
                  propsvalue={propsvalue}
                  encounterData={encounterDataaaa}
                  treatdata={treatmentprocedure}
                  vitalsdata={getVitals}
                  editEncData={encId}
                />
              </Col>
              {/* END Widgets list */}
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default withAppBar(withAllContexts(PatientDetail));
