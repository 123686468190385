import React from "react";
import { TapSelect } from "qdm-component-library";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Typography, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import { SelectBox } from "../../components";
import { isIntegerValue } from "../../utils";
import { UIColor } from "../../themes/theme";

const FollowupFotter = (props) => {
  const styles = {
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },

    icon: {
      color: UIColor?.primaryColor?.color,
      width: "50px",
      marginRight: "6px",
      cursor: "pointer",
    },
    text: {
      color: UIColor?.primaryColor?.color,
      width: "100px",
      fontSize: "14px",
    },
    tapSelectStyle: {
      padding: "4px 8px",
      borderColor: UIColor?.lineBorderFill.color,
      borderRadius: "8px",
      minWidth: "auto",
      margin: "0px 6px 6px 10px",
      fontSize: "14px !important",
    },
    ro: {
      background: "#f3f3f3",
      borderRadius: "10px 10px 0px 0px",
      backgroundColor: "white",
      margin: "15px",
      marginBottom: "0px",
      paddingTop: "10px",
      paddingBottom: "10px",
      borderBottom: "1px solid #DEE5EC",
    },
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
  };
  //const { parent_id, followup_number, followup_type } = props;
  return (
    <Grid container style={{ ...styles.ro, ...props?.style }}>
      <Grid item xs={6}>
        <div
          style={{
            justifyItems: "center",
            alignItems: "center",
            display: "flex",
            verticalAlign: "middle",
            margin: "auto",
          }}
        >
          <CheckCircleIcon
            style={styles.icon}
            onClick={!props.Isdisabled ? props.followupHnadle : null}
          />
          <Typography style={styles.text}>Follow-Up After</Typography>
          <TextField
            style={{ width: 20, marginLeft: "10px", marginTop: "-10px" }}
            value={props.followup_number}
            onChange={(e) =>
              isIntegerValue(e.target.value)
                ? props.handleInputChange(e.target.value, "followup_number", 2)
                : null
            }
            id="standard-basic"
            placeholder={"02"}
            inputProps={{
              maxLength: 2,
            }}
          />
          <TapSelect
            label={""}
            inLineStyles={styles.tapSelectStyle}
            selectedBorderColor={UIColor.primaryColor.color}
            selectedBackgroundColor={UIColor.primaryColor.color}
            selectedTextColor={"#fff"}
            //textSize={"small"}
            options={props?.followupuom}
            value={props?.followup_type}
            defaultSelected={props?.followup_type}
            onChange={(value) => props.tabHandle && props.tabHandle(value)}
          />
        </div>
      </Grid>

      <Grid
        item
        xs={6}
        style={{
          display: "flex",
          paddingRight: "15px",
        }}
      >
        <div
          style={{
            fontSize: "12px",
            color: "#2A3C50",
            margin: "auto",
            fontWeight: 400,
            paddingRight: "10px",
          }}
        >
          Reason
        </div>
        <div style={{ width: "100%", margin: "auto" }}>
          <SelectBox
            parent_id={"reason"}
            top_title={""}
            list={props.reasonData_ ?? []}
            placeholder={"Search here..."}
            value={props.followup_reason_ ?? ""}
            disableClearable
            onchange={(e, value) => props.handleselectChange(value)}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default FollowupFotter;

FollowupFotter.defaultProps = {
  goBackButton: "Go back",
  goBackButtonAction: null,
  confirmButton: "Confirm",
  confirmButtonAction: null,
  eSignature: true,
};
