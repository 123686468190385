/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React from "react";
import {
  Row,
  Col,
  Text,
  H6,
  Icons,
  Div,
  Card,
  Image,
} from "qdm-component-library";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import { Button, Tooltip, Dialog, DialogContent } from "@material-ui/core";
import { InsightsIcon } from "../../../components";
import Popover from "@material-ui/core/Popover";
import { DialogContext } from "../../../context";
import  withAllContexts  from "../../../hoc/withAllContexts";
import { useSelector } from "react-redux";
import { getData, getUserInfo, makeName, AlertProps } from "../../../utils";
import { UIColor } from "../../../themes/theme";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions"
import Tick from "../../../assets/img/icons8-double-tick.svg";
import Addicon from "../../../assets/img/icons8-add.svg";
import Cancel from "../../../assets/img/icons8-multiply.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import icons8edit from "../../../assets/img/svg/icons8-edit_.svg";
import icons8trash from "../../../assets/img/svg/icons8-trash_.svg";


const diagnosisOptionList = [
  {
    label: "Adverse effect of digestants",
    value: "Adverse effect of digestants",
  },
  {
    label: "Typhoid Fever",
    value: "Typhoid Fever",
  },
  {
    label: "Benign essential hypertention",
    value: "Benign essential hypertention",
  },
];

const card = [
  {
    line1: "Unspecified adverse effect",
    line2: "Admission_Secondary_01 day",
  },
  {
    line1: "Diagnostic and monitoring oto_",
    line2: "Admission_Secondary_01 day",
  },
  {
    line1: "Sprillary fever",
    line2: "Admission_Secondary_01 day",
  },
];

const monthOption = [
  { value: "Month", label: "Month" },
  { value: "Day", label: "Day" },
];

const statusVerificationOption = [
  {
    label: "Confirmed",
    value: "Confirmed",
  },
  {
    label: "Unconfirmed",
    value: "Unconfirmed",
  },
];
const filter = createFilterOptions();
const styles = {
  editAllergyIcon: {
    padding: "6px 10px",
    background: UIColor.greyBackground.color,
    borderRadius: "50px",
    cursor: "pointer",
  },
  deleteAllergyIcon: {
    padding: "6px 10px",
    background: UIColor.greyBackground.color,
    borderRadius: "50px",
    cursor: "pointer",
    color: UIColor.error.color,
    marginRight: 10,
  },
  vitalsHead: {
    padding: "0px 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 0px 0px 10px",
    background: "#fff",
    borderRadius: 8,
  },
  vitalsHead1: {
    padding: "0px 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 10px 12px 20px",
    background: "#fff",
    borderRadius: 8,
  },
  vitalsfieldHead: {
    padding: "6px 10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
  },
  vitailsCard: {
    // border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    marginBottom: "12px !important",
    backgroundColor: "#fff",
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  inputStyle: {
    padding: 5,
    marginBottom: 10,
  },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  listVitals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tagsStyle: {
    padding: "4px 12px",
    borderRadius: "50px",
    border: `1px solid ${UIColor.primaryColor.color}`,
    color: UIColor.primaryColor.color,
    background: UIColor.differentiationBackground.color,
    fontSize: "10px",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  borderStyle: {
    borderColor: UIColor.lineBorderFill.color,
    backgroundColor: UIColor.greyBackground.color,
    // backgroundColor: "#F9F9F9 !important",
    marginRight: 16,
  },
  borderNoneStyle: {
    borderColor: UIColor.greyBackground.color,
    backgroundColor: UIColor.greyBackground.color,
    marginRight: 16,
  },
  infoBell: {
    width: "50px",
    display: "flex",
    padding: "12px",
    borderLeft: `1px solid ${UIColor.lineBorderFill.color}`,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
  },
  icons: {
    cursor: "pointer",
  },
  flexFlow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noAccessTag: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 6px",
    borderRadius: "4px",
    fontWeight: 400,
    marginLeft: 12,
    backgroundColor: "#FAD7D6",
    color: UIColor.secondaryText.color,
    fontSize: "12px",
  },
  statusTag: {
    color: "#000",
    fontSize: 12,
    backgroundColor: UIColor.lineBorderFill.color,
    borderRadius: "4px",
    padding: "2px 6px",
    marginLeft: 6,
    width: "fit-content",
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  floatingLable: {
    backgroundImage: `linear-gradient(#fff, ${UIColor.greyBackground.color})`,
    position: "absolute",
    top: "-11px",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    margin: "10px",
  },
  flexStart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "left",
    marginLeft: "3px",
    flexDirection: "column",
    margin: "10px",
  },
  subText: {
    fontSize: 12,
    color: UIColor.tertiaryText.color,
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden !important",
    whiteSpace: "nowrap",
    width: "200px",
  },
  text: {
    fontSize: 14,
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden !important",
    whiteSpace: "nowrap",
    width: "200px",
  },

  userInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  textColor1: {
    fontSize: 12,
    color: UIColor.tertiaryText.color,
  },
  textColor: {
    fontSize: 12,
    paddingLeft: 6,
    color: UIColor.tertiaryText.color,
  },
  deleteHeader: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 1.2,
    marginBottom: "7px",
  },
  deleteButton: {
    padding: "6px",
    backgroundColor: UIColor.error.color,
    color: "#fff",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "none",
  },
  deleteButton2: {
    padding: "6px",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "none",
  },
  dialogContent: {
    textAlign: "center",
    padding: "24px",
  },
  userInfoIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
};

const initialState = {
  diagnosis: {},
  use: {},
  verification: {},
  numberOfDay: "",
  month: {},
  headerShow: true,
  onserOption: "",
  rank: {},
  codeSet: "",
  onsetcalenderShow: true,
  abatementcalenderShow: true,
  workRelated: true,
  confidential: false,
  notifiable: false,
  clinicalStatus: {},
  manifestation: {},
  evidenceReference: "",
  gradeCode: {},
  gradeType: {},
  assesmentReference: {},
  notes: "",
  onset: {
    day: "",
    freq: {},
    currentDate: "",
    isCalenderEnabled: false,
    calenderIconEnabled: true,
  },
  abatement: {
    day: "",
    freq: "",
    currentDate: "",
    isCalenderEnabled: false,
    calenderIconEnabled: true,
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "325px",
    margin: "auto",
  },
  autocomplete: {
    "& .MuiInputBase-input": {
        color: UIColor.secondaryText.color,
        fontSize: "12px !important",
         fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
        //height: 40,
        color: UIColor.secondaryText.color
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: `1px solid ${UIColor.lineBorderFill.color} !important`,
            borderRadius: 8
        },
        '&:hover fieldset': {
            border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        },
        '&.Mui-focused fieldset': {
            borderWidth: 1,
            border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        }
    },
    "& .MuiAutocomplete-popupIndicator": {
        transform: "none !important",
    }
  },
  input: {
    borderColor: "red",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "blue",
      },
      "&:hover fieldset": {
        borderColor: "green",
      },
      "&.Mui-focused fieldset": {
        borderColor: "yellow",
      },
    },
  },
}));
const Diagnosis = (props) => {
  const dispatch = useDispatch();
  //let diagnosis = props.diagnosis;
  const { list, setDiagnosis } = props.Diagnosis;
  const classes = useStyles();
  const [Selectopen, setSelectOpen] = React.useState(false);
  //const [diagnosisEntry, setDiagnosisEntry] = React.useState(initialState);
  const [inputValue, setInputValue] = React.useState("");
  const storeData = useSelector((state) => state?.DiagnosisMasterSlice);
  // const encounterData = useSelector(
  //   (state) => state?.chiefCompilenceMastersSlice?.encounter_Details
  // );
  const encounterData = props?.Encounterdata;
  const [diagnosisEntry, setDiagnosisEntry] = React.useState({
    ...initialState,
  });
  // const loading =
  //   Selectopen && storeData?.Diagnosis_masters?.data?.length === 0;

  // const [diagnosisList, setDiagnosis] = React.useState({
  //   list: [],
  // });
  const [masters, setMasters] = React.useState([]);
  const [allmasters, setAllMasters] = React.useState([]);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const dialog = React.useContext(DialogContext);

  const getDiagnosisData = async () => {
    const data = await dispatch(
      actions.DIAGNOSIS_READ({ patient_id: encounterData?.patient_id ?? encounterData?.patientId })
    );
    return data?.payload?.data || [];
  };
  const FlterOptions3 = (type) => {
    let allMasters = allmasters;
    let filteredArray = allMasters.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?._id,
        label: data[index]?.display,
      };
      list.push(datatemp);
    }
    return list;
  };

    const getAllMaster = async() => {
    const response = await dispatch(actions.GET_ALL_MASTERS(""));
    setAllMasters(response?.payload?.data)
    await dispatch(
      actions.DIAGNOSIS_MASTERS({ input_text: "" })
    );
  }
  React.useEffect(() => {
    getAllMaster();
    getDiagnosisData().then((d) => {
      setDiagnosis({
        ...props.Diagnosis,
        list: d,
      });
    });
    dialog.setDialog({
      title: "Are you sure want to remove this?",
      body: "Some content about the diagnosis",
      positiveBtn: "Yes remove",
    });
  }, []);

  React.useEffect(() => {
    setDiagnosisEntry({
      ...diagnosisEntry,
      headerShow: storeData?.diagnosisread?.data?.length > 0 ? false : true,
    });
  }, [encounterData]);

  const handleDiagnosis = async (v) => {
    if (v.length > 3) {
      setInputValue(v);
      let ccMaster = await dispatch(
        actions.DIAGNOSIS_MASTERS({ input_text: v })
      );
      let ccMasters = ccMaster?.payload?.data;
      let datalist = list.map((val) => val?.diagnosis?.label);
      let masterData = ccMasters.filter(
        (val) => datalist.indexOf(val?.label) === -1
      );
      setMasters(masterData);
    }
  };

  // for adding value not presented in diagnosis
  // const handleNewDiagnosis = (e) => {
  //   if (e.key === "Enter") {
  //     let obj = {
  //       label: inputValue,
  //       value: inputValue,
  //     };
  //     setDiagnosisEntry({
  //       ...diagnosisEntry,
  //       diagnosis: obj,
  //     });
  //   }
  // };
  const handleInputChange = async (e, name) => {
    if (name === "month") {
      diagnosisEntry.onset.freq = e;
      diagnosisEntry.calenderIconEnabled = false;
    } else if (name === "numberOfDay") {
      diagnosisEntry.onset.day = e;
    }
    setDiagnosisEntry({
      ...diagnosisEntry,
      [name]: e,
    });
  };

  const AddDiagnosis = async () => {
    let CS = storeData?.Clinical_masters?.data.filter(
      (val) => val.label === "Active"
    )?.[0];

    const info = getUserInfo();

    // list.push({
    //   ...diagnosisEntry,
    //   createdName: info.name,
    //   createdAt: getData(),
    //   diagnosis: diagnosisEntry.diagnosis,
    //   use: diagnosisEntry.use,
    //   type: diagnosisEntry.type,
    //   verification: diagnosisEntry.verification,
    //   numberOfDay: diagnosisEntry.numberOfDay,
    //   currentStatus: "New",
    //   clinicalStatus: "",
    // });
    //let List = list;
    let datalist = {
      ...diagnosisEntry,
      createdName: info.name,
      createdAt: getData(),
      diagnosis: diagnosisEntry.diagnosis,
      use: diagnosisEntry.use,
      type: diagnosisEntry.type,
      verification: diagnosisEntry.verification,
      numberOfDay: diagnosisEntry.numberOfDay,
      currentStatus: "New",
      clinicalStatus: CS,
    };

    await props.saveDiagnosis([datalist], null);
    //List.push(datalist);
    setDiagnosisEntry({
      ...diagnosisEntry,
      diagnosis: {},
      use: {},
      verification: {},
      numberOfDay: "",
      month: {},
      headerShow: !diagnosisEntry.headerShow,
    });
    // setDiagnosis({ ...props.Diagnosis, list: List });
  };

  // const deleteList = (i) => {
  //   dialog.setDialog({
  //     ...dialog,
  //     open: false,
  //   });
  //   list.splice(i, 1);
  //   setDiagnosis({ ...props.Diagnosis });
  // };
  const deleteList = async (i, data) => {
    ////create addable complaint if last list
    if (list?.length === 1) {
      setDiagnosisEntry({
        ...diagnosisEntry,
        headerShow: true,
      });
    }
    dialog.setDialog({
      ...dialog,
      open: false,
    });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Deleting Diagnosis",
    });
    await dispatch(actions.DELETE_DIAGNOSIS({ id: data.editData }));
    await dispatch(
      actions.DIAGNOSIS_READ({ patient_id: encounterData?.patient_id ?? encounterData?.patientId})
    );
    getDiagnosisData().then((d) => {
      setDiagnosis({
        ...props.Diagnosis,
        list: d,
      });
    });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
    ///remove list

    // list.splice(i, 1);
    // setDiagnosis({
    //   ...list,
    //   ...props.Diagnosis,
    // });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const editFun = (i, val) => {
    let getFilterData = list.filter((sys) => val.editData === sys.editData)[0];
    let editindex = list.indexOf(getFilterData);
    props.onEditChart(editindex, "diagnonsis_form");

    setDiagnosis({ ...props.Diagnosis, editIndex: editindex });
  };

  const handleCloseForm = () => {
    // if (storeData?.diagnosisread?.data?.length <= 0)
    //   return props.handleClose("diagnosis");
      
    if(props?.onRegistration === true){
      setDiagnosisEntry({
        ...diagnosisEntry,
        diagnosis: {},
        use: {},
        verification: {},
        numberOfDay: "",
        month: {},
        headerShow: !diagnosisEntry.headerShow,
      });
    }
    setDiagnosisEntry({
      ...diagnosisEntry,
      diagnosis: {},
      use: {},
      verification: {},
      numberOfDay: "",
      month: {},
      headerShow: !diagnosisEntry.headerShow,
    });
  };
  const { parent_id } = props;
  return (
    <div id={`${parent_id}_diagnosis_parent_div`} style={{ width: "100%" }}>
      <Row
        id={`${parent_id}_diagnosis_parent_row`}
        inLineStyles={styles.vitailsCard}
      >
        {/* Add a new allergy data's */}
        <Col
          id={`${parent_id}_diagnosis_parent_col`}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inLineStyles={
            diagnosisEntry.headerShow ? styles.vitalsHead : styles.vitalsHead1
          }
        >
          {diagnosisEntry.headerShow ? (
            <>
            <Grid container>
            <Grid item container style={{borderBottom: `1px solid ${UIColor.lineBorderFill.color}`, padding:"15px"}} justifyContent="space-between" alignItems="center">
         
            <Grid item>
            <Text
                id={`${parent_id}_diagnosis_headerShow_text`}
                onClick={() =>
                  handleInputChange(!diagnosisEntry.headerShow, "headerShow")
                }
                inLineStyles={{
                  fontWeight: 600,
                  fontSize: "14px",
                  cursor: "pointer",
                  color: UIColor.primaryColor.color,
                }}
              >
                {props?.onRegistration === true ? "Medical History" : "Diagnosis"}
              </Text>
            </Grid>
            {!encounterData?.IsViewMode && (
              <Grid item>
            <img src={Addicon} alt="Add" onClick={() =>
                  handleInputChange(!diagnosisEntry.headerShow, "headerShow")
                }/>
            </Grid>
            )}
            </Grid>
            <Grid item container justifyContent="space-between">
            <div
              id={`${parent_id}_diagnosis_title_div`}
              style={styles.vitalsfieldHead}
            >
              <>
              <div
                      id={`${parent_id}_allergy_div`}
                      style={{ marginRight: 14 }}
                    >
                    
                      <Autocomplete
                        style={{ width: 170, fontSize: "14px" }}
                        // className={"search"}
                        className={classes.autocomplete}
                        id="size-small-standard"
                        size="small"
                        //options={allergiesData?.allery_master?.data ?? []}
                        options={masters}
                        popupIcon={
                          <ExpandMoreIcon
                            id={`${parent_id}_allergy_expandmoreicon`}
                            fontSize="small"
                            style={{ color: "#b4aeae" }}
                          />
                        }
                        disableClearable
                        handleHomeEndKeys
                        selectOnFocus
                        clearOnBlur
                        //getOptionLabel={(option) => option.label}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.label;
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (params.inputValue !== "") {
                            filtered.push({
                              label: `${params.inputValue}`,
                              value: `${params.inputValue}`,
                            });
                          }

                          return filtered;
                        }}
                        renderInput={(params) => (
                          <TextField
                            id={`${parent_id}_allergy_search_textfield`}
                            {...params}
                            variant="standard"
                            placeholder="Search Diagnosis"
                           // inputProps={{
                            //   ...params.inputProps,
                            //   style: { fontSize: "14px" },
                            // }}
                          />
                        )}
                        renderOption={(option) => (
                          <Typography
                            id={`${parent_id}_allergy_${option.label}_typography`}
                            style={{ width: "100%" }}
                            noWrap
                          >
                            {option.label}
                          </Typography>
                        )}
                        classes={{ popper: "muiPopperDropdown" }}
                        value={diagnosisEntry.diagnosis}
                        // onChange={(e, newValue) =>
                        //   handleInputChange(newValue, "name")
                        // }
                        freeSolo
                        onInputChange={(e) => handleDiagnosis(e?.target?.value ?? "")}
                        onChange={(event, newValue) => {
                          if (typeof newValue === "string") {
                            setDiagnosisEntry({
                              ...diagnosisEntry,
                              diagnosis: {
                                label: newValue,
                              },
                            });
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setDiagnosisEntry({
                              ...diagnosisEntry,
                              diagnosis: {
                                label: newValue,
                              },
                            });
                          } else {
                            setDiagnosisEntry({
                              ...diagnosisEntry,
                              diagnosis: newValue,
                            });
                          }
                        }}
                      />
                    </div>
                <div
                  id={`${parent_id}_diagnosis_Use_div`}
                  style={{ marginRight: 16, width: "100%" }}
                >
                  <Autocomplete
                    style={{ width: 100 }}
                    id="size-small-standard"
                    size="small"
                    // options={storeData?.Use_masters?.data ?? []}
                    options={FlterOptions3("ENCOUNTERUSE") ?? []}
                    popupIcon={
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    disableClearable
                    getOptionLabel={(option) => option?.label}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}_diagnosis_Use_textfield`}
                        {...params}
                        variant="standard"
                        placeholder="Use"
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        id={`${parent_id}_diagnosis_Use_${option?.label}_typography`}
                        style={{ width: "100%" }}
                        noWrap
                      >
                        {option?.label}
                      </Typography>
                    )}
                    classes={{popper: "muiPopperDropdown"}}
                    value={diagnosisEntry?.use}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "use")
                    }
                  />
                </div>
                <div
                  id={`${parent_id}_diagnosis_Verification_div`}
                  style={{ marginRight: 16, width: "100%" }}
                >
                  <Autocomplete
                    style={{ width: 120 }}
                    id="size-small-standard"
                    size="small"
                    // options={
                    //   storeData?.Verification_masters_dianosis?.data ?? []
                    // }
                    options={FlterOptions3("VERIFICATIONSTATUS") ?? []}
                    popupIcon={
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    disableClearable
                    getOptionLabel={(option) => option?.label}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}_diagnosis_Verification_textfield`}
                        {...params}
                        variant="standard"
                        placeholder="Verification"
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        id={`${parent_id}_diagnosis_Verification_${option?.label}_typography`}
                        style={{ width: "100%" }}
                      >
                        {option?.label}
                      </Typography>
                    )}
                    classes={{popper: "muiPopperDropdown"}}
                    value={diagnosisEntry?.verification}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "verification")
                    }
                  />
                </div>
                <div style={{ marginRight: 16, width: "100%" }}>
                  <TextField
                    style={{ width: 20 }}
                    value={diagnosisEntry.numberOfDay}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "numberOfDay")
                    }
                    id="standard-basic"
                    placeholder={"01"}
                  />
                </div>
                <div
                  id={`${parent_id}_diagnosis_Month_div`}
                  style={{ marginRight: 16, width: "100%" }}
                >
                  <Autocomplete
                    style={{ width: 100 }}
                    id="size-small-standard"
                    size="small"
                    // options={monthOption}
                    options={FlterOptions3("DURATIONUOM") ?? []}
                    disableClearable
                    popupIcon={
                      <ExpandMoreIcon
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    getOptionLabel={(option) => option?.label}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}_diagnosis_Month_textfield`}
                        {...params}
                        variant="standard"
                        placeholder="Month"
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        id={`${parent_id}_diagnosis_Month_${option?.label}_typography`}
                        style={{ width: "100%" }}
                        noWrap
                      >
                        {option?.label}
                      </Typography>
                    )}
                    // classes={{popper: "muiPopperDropdown"}}
                    value={diagnosisEntry?.month}
                    onChange={(e, newValue) =>
                      handleInputChange(newValue, "month")
                    }
                  />
                </div>
              </>
            </div>
            {!encounterData?.IsViewMode && (
              <div
            id={`${parent_id}_diagnosis_diagnosisEntry_div`}
            style={styles.flexFlow}
          >
            {diagnosisEntry.headerShow && (
              <>
                {/* {diagnosisEntry.diagnosis?.value ? ( */}
                <div
                  id={`${parent_id}_diagnosis_add_button_div`}
                  style={{
                    width: "100%",
                    display: "flex",
                    marginRight: "10px",
                  }}
                >
                  <Button
                    id={`${parent_id}_diagnosis_add_button`}
                    onClick={() => {
                     // if (diagnosisEntry.diagnosis?.value) {
                      if (!diagnosisEntry.diagnosis || !diagnosisEntry.diagnosis.label) {
                        props.alert.setSnack({
                          open: true,
                          severity: AlertProps.severity.error,
                          msg: "Please Add The Diagnosis",
                          vertical: AlertProps.vertical.top,
                          horizontal: AlertProps.horizontal.center,
                          tone: true,
                        });
                        return;
                      }
                        AddDiagnosis();
                      } 
                    }
                    variant={"text"}
                    style={{
                      color: UIColor.primaryColor.color,
                      fontWeight: 600,
                      minWidth: "30%",
                      padding: "5px",
                      marginRight: "10px",
                    }}
                    size={"small"}
                  >
                    <img src={Tick} alt="Add" />
                  </Button>
                  <Button
                    id={`${parent_id}_allergy_add_button`}
                    onClick={() => handleCloseForm()}
                    variant={"text"}
                    style={{
                      color: UIColor.primaryColor.color,
                      fontWeight: 600,
                      minWidth: "30%",
                      padding: "5px",
                    }}
                    size={"small"}
                  >
                    <img src={Cancel} alt="Cancel" />
                  </Button>
                </div>
                {/* ) : (
                  <div
                    id={`${parent_id}_diagnosis_add_div`}
                    style={{ width: "100%" }}>
                    <Text
                      id={`${parent_id}_diagnosis_add_text`}
                      inLineStyles={{ color: "#b6b6b6" }}>{"+ ADD"}</Text>
                  </div>
                )} */}
              </>
            )}
            {/* <Tooltip
              id={`${parent_id}_diagnosis_Insights_title_tooltip`}
              title={"Insights"} onClick={handleClick}>
              <div
                id={`${parent_id}_diagnosis_Insights_div`}
                style={{ ...styles.infoBell, ...styles.icons }}>
                <InsightsIcon
                  id={`${parent_id}_diagnosis_Insights_icons`}
                  style={{ marginLeft: 5 }}
                // color={UIColor.primaryColor.color}
                />
              </div>
            </Tooltip> */}
            <div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div id={`${parent_id}_diagnosis_card_div`}>
                  <p
                    id={`${parent_id}_diagnosis_Suggestions_p`}
                    style={{ marginLeft: "16px", fontWeight: "bold" }}
                  >
                    Suggestions
                  </p>
                  <hr />
                  {card.length > 0 &&
                    card.map((val, i) => {
                      return (
                        <Card
                          id={`${parent_id}_diagnosis_${i}_Suggestions_card`}
                          style={{
                            width: "300px",
                            height: "60px",
                            margin: "10px",
                          }}
                        >
                          <div
                            id={`${parent_id}_diagnosis_${i}_Suggestions_div`}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              id={`${parent_id}_diagnosis_${i}_title_div`}
                              style={styles.flexStart}
                            >
                              <H6
                                id={`${parent_id}_diagnosis_${i}_${val.line1}_h6`}
                                inLineStyles={styles.text}
                              >
                                {val.line1}
                              </H6>
                              <Text
                                id={`${parent_id}_diagnosis_${i}_${val.line2}_text`}
                                inLineStyles={styles.subText}
                              >
                                <div
                                  id={`${parent_id}_diagnosis_${i}_${val.line2}_div`}
                                >
                                  <div
                                    id={`${parent_id}_diagnosis_${i}_${val.line2}_sub_div`}
                                  >
                                    {val.line2}
                                  </div>
                                </div>
                              </Text>
                            </div>
                            <div
                              id={`${parent_id}_diagnosis_${i}_select_button_div`}
                              style={styles.flexEnd}
                            >
                              <Button
                                id={`${parent_id}_diagnosis_${i}_select_button`}
                                color="primary"
                                style={{
                                  color: UIColor.primaryColor.color,
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                Select
                              </Button>
                            </div>
                          </div>
                        </Card>
                      );
                    })}

                  <div id={`${parent_id}_diagnosis_Add_button_div`}>
                    <Row id={`${parent_id}_diagnosis_Add_button_row`}>
                      <Col xs={12} sm={12} md={9} lg={9} xl={9}></Col>
                      <Col
                        id={`${parent_id}_diagnosis_Add_button_col`}
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        xl={3}
                      >
                        <div
                          id={`${parent_id}_diagnosis_Add_button_sub_div`}
                          style={{ marginBottom: "10px", marginLeft: "-10px" }}
                        >
                          <Button
                            id={`${parent_id}_diagnosis_Add_button`}
                            variant="contained"
                            color="primary"
                            style={{ background: UIColor.primaryColor.color }}
                          >
                            Add
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
            )}
            
            </Grid>

            </Grid>
         
          
           
            </>
        
          ) : (
            <div
              id={`${parent_id}_diagnosis_headerShow_div`}
              style={{
                display: "flex",
                width: "99%",
                justifyContent: "space-between",
              }}
            >
              <Text
                id={`${parent_id}_diagnosis_headerShow_text`}
                onClick={() =>
                  handleInputChange(!diagnosisEntry.headerShow, "headerShow")
                }
                inLineStyles={{
                  fontWeight: 600,
                  fontSize: "14px",
                  cursor: "pointer",
                  color: UIColor.primaryColor.color,
                }}
              >
                {props?.onRegistration === true ? "Medical History" : "Diagnosis"}
              </Text>
              {!encounterData?.IsViewMode && (
                <div
                onClick={() =>
                  handleInputChange(!diagnosisEntry.headerShow, "headerShow")
                }
                style={{ cursor: "pointer", display: "flex" }}
              >
                <img src={Addicon} alt="Add" />
              </div>
              )}
             
            </div>
          )}
        
        </Col>

        {/* allergy list */}
        {storeData?.diagnosisread?.data?.length > 0 &&
          storeData?.diagnosisread?.data?.map((val, i) => {
            // if (!val.isNew) {
            //   return <React.Fragment />;
            // }
            return (
              <Col
                id={`${parent_id}_diagnosis_${i}_${val.diagnosis}_col`}
                key={i}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={"allergyListHover"}
                inLineStyles={{
                  padding: "8px",
                  borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
                }}
              >
                <Row id={`${parent_id}_diagnosis_${i}_${val.diagnosis}_row`}>
                  <Col
                    id={`${parent_id}_diagnosis_${i}_${val.diagnosis}_sub_col`}
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                  >
                    {val?.diagnosis && (
                      <H6
                        id={`${parent_id}_diagnosis_${i}_${val?.diagnosis?.label}_h6`}
                        inLineStyles={{
                          fontSize: 14,
                          color: UIColor.secondaryButtonColor.color,
                          fontFamily: "pc_regular",
                        }}
                      >
                        {val?.diagnosis?.label}
                      </H6>
                    )}
                    {val?.use && (
                      <Text
                        id={`${parent_id}_diagnosis_${i}_${val?.use?.label}_text`}
                        inLineStyles={styles.textColor1}
                      >
                        {val?.use?.label}
                      </Text>
                    )}
                    {val?.verification && (
                      <Text
                        id={`${parent_id}_diagnosis_${i}_${val?.verification?.label}_text`}
                        inLineStyles={styles.textColor}
                      >
                        {" - "}
                        {val?.verification?.label}
                      </Text>
                    )}
                    {val?.onset?.day?.length > 0 && (
                      <Text
                        id={`${parent_id}_diagnosis_${i}_${
                          val?.onset && ", " + val?.onset?.freq?.label
                        }_text`}
                        inLineStyles={styles.textColor}
                      >
                        {" - Since "}
                        {val?.onset?.day}
                        {val?.onset && ", " + val?.onset?.freq?.label}
                      </Text>
                    )}
                    {val?.currentStatus && (
                      <Text
                        id={`${parent_id}_diagnosis_${i}_${val?.currentStatus}_text`}
                        inLineStyles={styles.statusTag}
                      >
                        {val?.currentStatus}
                      </Text>
                    )}
                  </Col>
                  <Col
                    id={`${parent_id}_diagnosis_${i}_userHide_col`}
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                  >
                    <Div
                      id={`${parent_id}_diagnosis_${i}_userHide_div`}
                      className={"userInfoEdit"}
                    >
                      <div
                        id={`${parent_id}_diagnosis_${i}_on_div`}
                        style={styles.userInfo}
                        className={"userHide"}
                      >
                        <Text
                          id={`${parent_id}_diagnosis_${i}_${
                            val?.createdAt ?? getData()
                          }_text`}
                          inLineStyles={styles.textColor}
                          style={{ marginBottom: 8 }}
                        >
                          {"On "}
                          {val?.createdAt ?? getData()}
                        </Text>
                        <Text
                          id={`${parent_id}_diagnosis_${i}_${
                            val?.createdName?.text ?? " "
                          }_text`}
                          inLineStyles={styles.textColor}
                        >
                          {makeName(val?.createdName || {}) + " (You)" ??
                            "(You)"}
                        </Text>
                      </div>
                      <div
                        id={`${parent_id}_diagnosis_${i}_delete_edit_div`}
                        style={{ ...styles.userInfoIcon, marginLeft: 24 }}

                      >
                        <Text
                          id={`${parent_id}_diagnosis_${i}_delete_text`}
                          inLineStyles={styles.deleteAllergyIcon}
                          onClick={() => {
                            if (!encounterData?.IsViewMode) {
                                dialog.setDialog({
                                  ...dialog,
                                  open: true,
                                  onOk: () => deleteList(i, val),
                                });
                              }}
                            }
                        >
                          {/* <Icons
                            id={`${parent_id}_diagnosis_${i}_trash_icons`}
                            fontIcon={"trash-o"} /> */}
                          <Image
                            src={icons8trash}
                            alt="delete_icon"
                            // style={{ width: 100, height: 35 }}
                          />
                        </Text>
                        <Text
                          id={`${parent_id}_diagnosis_${i}_edit_text`}
                          inLineStyles={styles.editAllergyIcon}
                          onClick={() => {
                            if (!encounterData?.IsViewMode) {
                              editFun(i, val)
                            }
                          }}
                        >
                          {/* <Icons
                            id={`${parent_id}_diagnosis_${i}_edit_icons`}
                            fontIcon={"pencil"} /> */}
                          <Image
                             src={icons8edit}
                            alt="edit_icon"
                            // style={{ width: 100, height: 35 }}
                          />
                        </Text>
                      </div>
                    </Div>
                    {/* <Div className={"userInfoEdit"}>
                      <div style={styles.userInfo} className={"userHide"}>
                        <Text
                          inLineStyles={styles.textColor}
                          style={{ marginBottom: 8 }}
                        >
                          {"On "}
                          {"24 Mar, 2021"}
                        </Text>
                        <Text inLineStyles={styles.textColor}>
                          {"Dr.Justin Brewer (You)"}
                        </Text>
                      </div>
                      <div style={{ ...styles.userInfoIcon, marginLeft: 24 }}>
                        <Text
                          inLineStyles={styles.editAllergyIcon}
                          onClick={handleDelete}
                        >
                          <Icons
                            style={{ color: UIColor.error.color }}
                            fontIcon={"trash"}
                          />
                        </Text>
                        <Text
                          inLineStyles={styles.editAllergyIcon}
                          onClick={() =>
                            props?.onEditChart(val, "diagnonsis_form")
                          }
                        >
                          <Icons fontIcon={"pencil"} />
                        </Text>
                      </div>
                    </Div> */}
                  </Col>
                </Row>
              </Col>
            );
          })}
      </Row>

      {/*delete dialog */}
      <Dialog
        id={`${parent_id}_diagnosis_parent_dialog`}
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="simple-dialog-title"
        classes={{ container: classes.container }}
      >
        <DialogContent
          id={`${parent_id}_diagnosis_remove_this?_dialohcontent`}
          style={styles.dialogContent}
        >
          <Typography
            id={`${parent_id}_diagnosis_remove_this?_typography`}
            style={styles.deleteHeader}
          >
            Are you sure you want to remove this?
          </Typography>
          <Typography
            id={`${parent_id}_diagnosis_the_order_typography`}
            style={{ fontSize: "14px", lineHeight: 1.2 }}
          >
            Some content about<br></br> the order
          </Typography>
        </DialogContent>
        <div
          id={`${parent_id}_diagnosis_Yes_Remove_div`}
          style={{ padding: "0px 24px" }}
        >
          <Button
            id={`${parent_id}_diagnosis_Yes_Remove_button`}
            onClick={handleDeleteClose}
            style={styles.deleteButton}
            fullWidth
            variant="contained"
          >
            Yes Remove
          </Button>
        </div>
        <div
          id={`${parent_id}_diagnosis_No_button_div`}
          style={{ padding: "8px 24px 24px" }}
        >
          <Button
            id={`${parent_id}_diagnosis_No_button`}
            onClick={handleDeleteClose}
            variant="outlined"
            style={styles.deleteButton2}
            fullWidth
          >
            No
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default withAllContexts(Diagnosis);
