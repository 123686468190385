import React from "react";
import { UIColor } from "../../../themes/theme";

export const History = (props) => {
  let width = props.width ? props.width : "13";
  let height = props.height ? props.height : "15";
  //   let color = props.color ? props.color : "#000000";
  return (
    <svg     
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 24">
    <path d="M6.865 6.88166C8.04945 5.69329 9.60941 4.95252 11.2789 4.78565C12.9485 4.61877 14.6242 5.03613 16.0204 5.96655C17.4166 6.89698 18.4469 8.28288 18.9356 9.88797C19.4243 11.4931 19.3412 13.218 18.7004 14.7686C18.0596 16.3193 16.9008 17.5997 15.4216 18.3915C13.9423 19.1834 12.2342 19.4376 10.5885 19.111C8.94277 18.7843 7.46128 17.897 6.39661 16.6002C5.33193 15.3034 4.74999 13.6775 4.75 11.9997C4.75 11.8008 4.67098 11.61 4.53033 11.4693C4.38968 11.3287 4.19891 11.2497 4 11.2497C3.80109 11.2497 3.61032 11.3287 3.46967 11.4693C3.32902 11.61 3.25 11.8008 3.25 11.9997C3.24992 14.0247 3.95222 15.9871 5.23718 17.5522C6.52213 19.1173 8.31018 20.1883 10.2965 20.5826C12.2827 20.9768 14.3443 20.6699 16.1296 19.7142C17.9149 18.7585 19.3135 17.2131 20.0868 15.3416C20.8602 13.47 20.9604 11.3882 20.3705 9.45099C19.7806 7.5138 18.537 5.84118 16.8518 4.71833C15.1665 3.59548 13.144 3.0919 11.1291 3.29347C9.11409 3.49503 7.23141 4.38925 5.802 5.82366C5.77682 5.84866 5.75343 5.87539 5.732 5.90366L4.475 4.64566C4.40778 4.57829 4.32272 4.5315 4.22982 4.51079C4.13692 4.49009 4.04005 4.49633 3.95057 4.52878C3.8611 4.56123 3.78275 4.61854 3.72472 4.69398C3.66669 4.76942 3.6314 4.85986 3.623 4.95466L3.27 8.84366C3.2634 8.91662 3.27292 8.99014 3.29787 9.05902C3.32283 9.1279 3.36261 9.19045 3.41441 9.24225C3.46622 9.29405 3.52877 9.33384 3.59764 9.35879C3.66652 9.38375 3.74004 9.39326 3.813 9.38666L7.703 9.03266C7.79743 9.02377 7.88739 8.98819 7.96236 8.93009C8.03734 8.87199 8.09424 8.79377 8.12643 8.70454C8.15862 8.61532 8.16476 8.51878 8.14415 8.4262C8.12353 8.33361 8.077 8.2488 8.01 8.18166L6.782 6.95366C6.81137 6.93171 6.83911 6.90764 6.865 6.88166Z" fill={UIColor.sidebarIconColor.color}/>
    <path d="M12.75 7C12.75 6.80109 12.671 6.61032 12.5303 6.46967C12.3897 6.32902 12.1989 6.25 12 6.25C11.8011 6.25 11.6103 6.32902 11.4697 6.46967C11.329 6.61032 11.25 6.80109 11.25 7V12C11.2499 12.1272 11.2822 12.2522 11.3438 12.3635C11.4054 12.4747 11.4942 12.5685 11.602 12.636L14.602 14.511C14.7707 14.6166 14.9744 14.6508 15.1683 14.6061C15.2643 14.584 15.355 14.5433 15.4353 14.4861C15.5155 14.4289 15.5837 14.3565 15.636 14.273C15.6883 14.1895 15.7236 14.0965 15.7399 13.9993C15.7562 13.9022 15.7532 13.8027 15.7311 13.7067C15.709 13.6107 15.6683 13.52 15.6111 13.4397C15.5539 13.3595 15.4815 13.2913 15.398 13.239L12.75 11.584V7Z" fill={UIColor.sidebarIconColor.color}/>
    </svg>
  );
};
