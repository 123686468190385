import React, { useState } from "react";
import {
  // makeStyles,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import actions from "../../../../../../redux/actions";
import { useDispatch } from "react-redux";

// const useStyles = makeStyles((theme) => ({
//     root: {}
// }));

function ReasonMaster(props) {
  // const classes = useStyles();

  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const getReason = async () => {
    const reason = await dispatch(
      actions.REASON_DROPDOWN_MASTER({
        type: props.type ?? "",
        reIssue: props?.reIssue ?? false,
        medication: props?.medication ?? false,
      })
    );
    setData(reason?.payload?.data ?? []);
  };

  React.useEffect(() => {
    getReason();
  }, []);

  const { action, onChange, optionLabel } = props;

  return (
    <div>
      {action !== "VERIFY" ? (
        <Autocomplete
          options={data ?? []}
          onChange={(e, v) => (optionLabel ? onChange(v) : onChange(v?.title))}
          disabled={props?.disabled ?? false}
          getOptionLabel={(option) =>
            optionLabel ? option[optionLabel] : option.title
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Here"
              variant="outlined"
              fullWidth
              size="small"
            />
          )}
          value={props?.value ?? {}}
        />
      ) : (
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          placeholder="Type Here"
          // value={drug?.verifyReason}
          disabled={props?.disabled ?? false}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </div>
  );
}

export default ReasonMaster;
