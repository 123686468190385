import * as React from "react";
// import { makeStyles } from "@material-ui/core";

// const useStyle = makeStyles((theme) => ({
//   cls1: (props) => ({
//     fill: props.htmlColor,
//   }),
// }));

export const Suggested = (props) => {
  return (
    // <SvgIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.526"
      height="10"
      viewBox="0 0 10.526 10"
    >
      <path
        fill={props.color ? props.color : "#6f6f6f"}
        id="icons8-star_1_"
        data-name="icons8-star (1)"
        class="cls-1"
        d="M9.266,5a.4.4,0,0,0-.356.218L7.555,7.93l-3.22.5a.395.395,0,0,0-.219.669l2.227,2.227-.5,3.223a.395.395,0,0,0,.573.41l2.844-1.484,2.844,1.484a.395.395,0,0,0,.573-.41l-.5-3.223,2.227-2.227a.395.395,0,0,0-.219-.669l-3.22-.5L9.617,5.218A.4.4,0,0,0,9.266,5Z"
        transform="translate(-4 -5)"
      />
    </svg>
    // </SvgIcon>
  );
};
