import React from "react";

export const MenuIcon = (props) => {
  let width = props.width ? props.width : "17.172";
  let height = props.height ? props.height : "15.884";
  let color = props.color ? props.color : "#101010";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17.172 15.884"
      {...props}
    >
      <path id="icons8-business" class="cls-1" d="M10.225,6a1.513,1.513,0,0,0-1.5,1.5V8.576H5.5a1.513,1.513,0,0,0-1.5,1.5v10.3a1.513,1.513,0,0,0,1.5,1.5H19.669a1.513,1.513,0,0,0,1.5-1.5v-10.3a1.513,1.513,0,0,0-1.5-1.5h-3.22V7.5a1.513,1.513,0,0,0-1.5-1.5Zm0,1.288h4.722a.205.205,0,0,1,.215.215V8.576H10.01V7.5A.205.205,0,0,1,10.225,7.288ZM5.5,11.849H19.669a.205.205,0,0,1,.215.215v3.81h-6.01v-.215a.644.644,0,0,0-.644-.644H11.942a.644.644,0,0,0-.644.644v.215H5.288v-3.81A.205.205,0,0,1,5.5,11.849Zm-.215,5.312H11.3v.876a.644.644,0,0,0,.644.644H13.23a.644.644,0,0,0,.644-.644v-.876h6.01v3.22a.205.205,0,0,1-.215.215H5.5a.205.205,0,0,1-.215-.215Z"
        transform="translate(-4 -6)"
        fill={color}    
    />
    </svg>
  );
};