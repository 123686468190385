import React from "react";

export const PillsIcon = (props) => {
  let width = props.width ? props.width : "18";
  let height = props.height ? props.height : "16";
  let color = props.color ? props.color : "#707070";
  return (
    <svg
      id="PillsIcon_svg"
      xmlns="http://www.w3.org/2000/svg" {...props} style={props?.style} width={width} height={height} viewBox="0 0 18 16.782"><path class="a" fill={color} d="M14.638,4.789a4.923,4.923,0,0,0-3.492,1.442L4.231,13.147a4.937,4.937,0,0,0,6.982,6.982l1.229-1.23A4.364,4.364,0,1,0,18.2,13.13a4.929,4.929,0,0,0-3.566-8.341ZM8.37,10.86l4.2,4.2a4.33,4.33,0,0,0-.516,2.052c0,.109.009.217.017.324a.655.655,0,0,0-.1.086L10.287,19.2a3.627,3.627,0,0,1-5.13-5.13Zm8.051,3.194a3.057,3.057,0,1,1-3.057,3.057A3.048,3.048,0,0,1,16.421,14.053Zm1.079,1.3a.655.655,0,0,0-.45.2L14.866,17.74a.655.655,0,1,0,.927.927l2.184-2.184a.655.655,0,0,0-.476-1.124Z" transform="translate(-2.789 -4.789)" /></svg>
  );
};