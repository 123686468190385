import React from "react";

export const InfoIcon = (props) => {
  let width = props.width ? props.width : "16";
  let height = props.height ? props.height : "16";
  let color = props.color ? props.color : "#0071f2";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={props?.style} width={width} height={height} viewBox="0 0 16 16">
        <path id="icons8-info" d="M12,4a8,8,0,1,0,8,8A8.009,8.009,0,0,0,12,4Zm0,1.2A6.8,6.8,0,1,1,5.2,12,6.791,6.791,0,0,1,12,5.2ZM12,8a.8.8,0,1,0,.8.8A.8.8,0,0,0,12,8Zm-.009,2.791a.6.6,0,0,0-.591.609v4.4a.6.6,0,1,0,1.2,0V11.4a.6.6,0,0,0-.609-.609Z" transform="translate(-4 -4)" fill={color}/>
    </svg>
  );
};