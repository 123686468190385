import React, { useState } from "react";
import { Div, Divider, Text, H6, Dropdown, TapSelect } from "qdm-component-library";
import expandarrow from "../../assets/img/expandarrow.svg";
import { CalendarIcon } from "../";
import { TextField, makeStyles, Popover } from "@material-ui/core";
import { qdmstyles, styles } from "./styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import SelectBox  from "../selectBox";
import { UIColor } from "../../themes/theme";
const useStyles = makeStyles({
  textField: {
    fontSize: 12,
    fontFamily: "pc_medium",
    padding: 0,
    border: "none",
    outline: "none",
  },
  everyTextField: {
    width: 20,
    border: "none",
    borderBottom: "1px solid #989898",
    outline: "none",
    fontSize: 14,
    fontFamily: "pc_regular",
  },
});

export const Recurrent = (props) => {
  const classes = useStyles(props);

  const [state, setState] = useState({
    endsAfter: 0,
    anchorEl: null,
    index: null,
  });

  const [recurrentData, setRecurrentData] = useState(
    props?.recurrentData ?? {}
  );

  const handleRecurrentData = (parentKey, key1, value, key2, keyword) => {
    
    const newRecurrentData = Object.keys(recurrentData).length > 0 ? { ...recurrentData } : {};

    // delete setting
    if (key1 === "endsBy") {
      newRecurrentData[parentKey]["endsAfter"] = "";
    }
    if (key1 === "endsAfter") {
      newRecurrentData[parentKey]["endsBy"] = "";
    }
    if (keyword === "deleteAllDays") {
      newRecurrentData.preferrence = {
        preferredDays: [],
        preferredWeeks: [],
      }
    }

    if (newRecurrentData["every"]["periodicity"]?.value === process.env.REACT_APP_FREQUENCY_UOM_DAYS) {
      newRecurrentData.preferrence = {
        preferredDays: [],
        preferredWeeks: [],
      }
    }

    if (newRecurrentData["every"]["periodicity"]?.value === process.env.REACT_APP_FREQUENCY_UOM_WEEKS) {
      newRecurrentData.preferrence.preferredWeeks = []
    }
    newRecurrentData[parentKey][key1] = value;
    
    setRecurrentData(newRecurrentData);


    setState({
      ...state,
      anchorEl: null,
      index: null,
    });
  };

  const handleClick = (index, event) => {
    setState({ ...state, index, anchorEl: event.currentTarget });
  };

  const saveRecurrentData = () => {
    props?.saveButtonAction && props.saveButtonAction(recurrentData);
  };

  const onChangeState = (key, value) => {
    if (value >= 0) {
      setState({ ...state, [key]: value, anchorEl: null });
    } else {
      setState({ ...state, anchorEl: null });
    }
  };
  const open = Boolean(state.anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { parent_id } = props;
  return (
    <div
      id={`${parent_id}_recurrent_parent_div`}
      style={{ width: "600px", margin:"10px" }}>
      <Div
        id={`${parent_id}_recurrent_parent_sub_div`}
        style={styles.flexCenterJustify}>
        {props?.title && (
          <H6
            id={`${parent_id}_recurrent_${props?.title?.replaceAll(" ", "_")}_title_h6`}
            className="pc_semibold" style={styles.title}>
            {props?.title}
          </H6>
        )}
        <Div
          id={`${parent_id}_recurrent_title_div`}
        >
          {props?.deleteButton && (
            <Text
              id={`${parent_id}_recurrent_Delete_text`}
              style={styles.deleteButton}
              onClick={() => props?.deleteButtonAction()}
              className="pc_medium"
            >
              DELETE
            </Text>
          )}
          {props?.saveButton && (
            <Text
              id={`${parent_id}_recurrent_Save_text`}
              className="pc_medium"
              onClick={() => saveRecurrentData()}
              style={styles.save}
            >
              SAVE
            </Text>
          )}
        </Div>
      </Div>
      <Div
        id={`${parent_id}_recurrent_date_format_div`}
        style={{ ...styles.flexRow, marginBottom: 15, marginTop:10 }}>
        <Text
          id={`${parent_id}_recurrent_Every_text`}
          style={{ ...styles.span, marginRight: 10 }}
          className="pc_regular"
        >
          Every<Text
            id={`${parent_id}_recurrent_Every_star_text`}
            style={styles.star}>*</Text>
        </Text>
        <input
          id={`${parent_id}_recurrent_Every_input`}
          value={recurrentData?.every?.value}
          onChange={(e) =>
            handleRecurrentData("every", "value", e.target.value)
          }
          maxLength={2}
          type="text"
          style={{ width: 20, color: UIColor.secondaryText.color }}
          className={classes.everyTextField}
        />
        <Div
          id={`${parent_id}_recurrent_dropdwon_div`}
          style={{ minWidth: 100, marginLeft: 10 }}>
          {/* <Dropdown
            id={`${parent_id}_recurrent_calender_format_dropdwon`}
            key={"0"}
            elevation={"1"}
            fullWidth={true}
            dropDownTextSize={"14"}
            dropDownTextColor={"#000"}
            activeTextColor={"#000"}
            dropdownListMargin={"0"}
            dropdownListPadding={"8"}
            value={recurrentData?.every?.periodicity}
            onChange={(data) =>
              handleRecurrentData("every", "periodicity", data)
            }
            options={[
              { label: "Days", value: "Days" },
              { label: "Week", value: "Week" },
              { label: "Month", value: "Month" },
            ]}
            inLineStyles={{
              fontSize: "14px",
              fontWeight: 500,
            }}
            className={"pc_regular"}
          /> */}
          <SelectBox
            id={`${parent_id}_recurrent_calender_format_dropdwon`}
            disableClearable
            list={[
              { label: "Days", value: process.env.REACT_APP_FREQUENCY_UOM_DAYS },
              { label: "Week", value:process.env.REACT_APP_FREQUENCY_UOM_WEEKS },
              { label: "Month", value: process.env.REACT_APP_FREQUENCY_UOM_MONTHS },
            ]}
            value={recurrentData?.every?.periodicity}
            onchange={(e, value) => handleRecurrentData("every", "periodicity", value)}
          />
        </Div>
      </Div>
      <Div
        id={`${parent_id}_recurrent_Starts_on_div`}
        style={{
          "display": "flex",
          "alignItems": "center",
          "padding": "10px 0px"
        }}>
        <Div
          id={`${parent_id}_recurrent_Starts_on_sub_div`}
          style={styles.flexRow}>
          <Text
            id={`${parent_id}_recurrent_Starts_on_text`}
            style={{ ...styles.span, marginRight: 10, minWidth: 60 }}
            className="pc_regular"
          >
            Starts on<Text
              id={`${parent_id}_recurrent_Starts_star_text`}
              style={styles.star}>*</Text>
          </Text>
          <Div
            id={`${parent_id}_recurrent_dateTime_div`}
            style={styles.startsOn}>
            <H6
              id={`${parent_id}_recurrent_dateTime_h6`}
              className="pc_medium"
              style={{ ...styles.span, minWidth: 100 }}
              name={false}
            >
              {moment(recurrentData?.startsOn?.dateTime).format("YYYY-MM-DD, hh:mm A")}
            </H6>
            <Text
              id={`${parent_id}_recurrent_dateTime_h6`}
              style={{ color: UIColor.secondaryText.color, margin: "0px 10px" }}>|</Text>
            <Text
              id={`${parent_id}_recurrent_calender_text`}
              style={{ cursor: "pointer", display: "flex" }}
              onClick={(e) => handleClick(0, e)}
              aria-describedby={id}
            >
              <CalendarIcon
                id={`${parent_id}_recurrent_calender_icon`}
                color={UIColor.primaryText.color} width={"16"} height={"16"} />
            </Text>
          </Div>
        </Div>
        <Div
          id={`${parent_id}_recurrent_Ends_after_div`}
          style={{ ...styles.flexRow, marginLeft: 10 }}>
          <Text
            id={`${parent_id}_recurrent_Ends_after_text`}
            style={{
              ...styles.span,
              marginRight: 10,
              minWidth: 90,
              cursor: "pointer",
            }}
            aria-describedby={id}
            onClick={(e) => handleClick(1, e)}
            className="pc_regular"
          >
            Ends after<Text
              id={`${parent_id}_recurrent_Ends_after_star_text`}
              style={styles.star}>*</Text>
            <img
              id={`${parent_id}_recurrent_Ends_after_image`}
              alt="img" src={expandarrow} style={{ marginLeft: 10 }} />
          </Text>
          {state.endsAfter === 0 && (
            <Div
              id={`${parent_id}_recurrent_occurence_div`}
              style={styles.occurence}>
              <input
                id={`${parent_id}_recurrent_occurence_input`}
                type="text"
                maxlength="2"
                value={recurrentData?.endsAfter?.endsAfter}
                onChange={(e) =>
                  handleRecurrentData("endsAfter", "endsAfter", e.target.value)
                }
                style={{ width: 20 }}
                className={classes.textField}
              />
              <Text
                id={`${parent_id}_recurrent_occurence_text`}
                style={styles.divider}>| occurence</Text>
            </Div>
          )}
          {state.endsAfter === 1 && (
            <Div
              id={`${parent_id}_recurrent_recurrentData_div`}
              style={styles.occurence}>
              <Text
                id={`${parent_id}_recurrent_${recurrentData?.endsAfter?.endsBy}`}
                className="pc_medium" style={styles.span} name={false}>
                {moment(recurrentData?.endsAfter?.endsBy).format("YYYY-MM-DD")} 
              </Text>
              <Text style={{ ...styles.divider, margin: "0px 10px" }}>|</Text>
              <Text
                id={`${parent_id}_recurrent_calendericon_text`}
                style={{ cursor: "pointer", display: "flex" }}
                onClick={(e) => handleClick(2, e)}
                aria-describedby={id}
              >
                <CalendarIcon
                  id={`${parent_id}_recurrent_calendericon`}
                  color={UIColor.primaryText.color} width={"16"} height={"16"} />
              </Text>
            </Div>
          )}
        </Div>
      </Div>
      {recurrentData?.every?.periodicity?.value ===
        process.env.REACT_APP_FREQUENCY_UOM_WEEKS ||
        recurrentData?.every?.periodicity?.value ===
        process.env.REACT_APP_FREQUENCY_UOM_MONTHS ? (
        <>
          <Div
            id={`${parent_id}_bookmultiple_description_div`}
            key={"4"}
            className={"qdm"}
            style={{ backgroundColor: UIColor.greyBackground.color }}
          >
            <Div
              id={`${parent_id}_bookmultiple_description_sub_div`}
              key={"0"}
              className={"qdm"}
              style={qdmstyles.aaf_c__aba_bdc}
            >
              <Div id={`${parent_id}_bookmultiple_PREFERENCE_div`} key={"0"}>
                <Text
                  id={`${parent_id}_bookmultiple_PREFERENCE_text`}
                  key={"0"}
                  name={"PREFERENCE"}
                  style={{
                    "fontSize": "14px",
                    "color": "#101010",
                    "fontWeight": "600",
                    "letterSpacing": "1px"
                }}
                  className={"pc_regular"}
                ></Text>
              </Div>

              <Div
                id={`${parent_id}_bookmultiple_CLEAR_PREFERENCE_div`}
                key={"1"}
                style={{ cursor: "pointer" }}
                onClick={() => { //FIXME - 
                  handleRecurrentData("preferrence", "preferredDays", [], "deleteAllDays")
                }}
              >
                <Text
                  id={`${parent_id}_bookmultiple_CLEAR_PREFERENCE_text`}
                  key={"0"}
                  name={"CLEAR PREFERENCE"}
                  style={{
                    fontSize: "14px",
                    color: UIColor.error.color,
                    fontWeight: "600",
                    letterSpacing: "1px",
                  }}
                  className={"pc_regular"}
                ></Text>
              </Div>
            </Div>
          </Div>
          <Div
            id={`${parent_id}_bookmultiple_tapselect_div`}
            key={"4"}
            className={"qdm"}
            style={{
              backgroundColor: UIColor.greyBackground.color,
              padding: "20px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          >
            <Text
              id={`${parent_id}_bookmultiple_PREFERRED_DAYS_div`}
              key={"1"}
              name={"CHOOSE YOUR PREFERRED DAYS"}
              style={{
                fontSize: "12px",
                color: UIColor.primaryText.color,
                fontWeight: "600",
              }}
              className={"pc_regular"}
            ></Text>
            <Div
              id={`${parent_id}_bookmultiple_DAYS_div`}
              style={{ paddingBottom: 10 }}
            >
              <TapSelect
                id={`${parent_id}_bookmultiple_DAYS_tapselect`}
                key={"2"}
                inLineStyles={{ minWidth: 30, padding: "5px 15px" }}
                type={"multiple"} //FIXME - 
                fullWidth={false}
                selectedBorderColor={UIColor.secondaryButtonColor.color}
                selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                textColor={UIColor.secondaryText.color}
                borderColor={UIColor.differentiationBackground.color}
                backgroundColor={UIColor.differentiationBackground.color}
                selectedTextColor="#fff"
                label=""
                textSize={"small"}
                iconHeight={"20"}
                iconWidth={"20"}
                options={[
                  { value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_SUNDAY, label: "SUN" },
                  { value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_MONDAY, label: "MON" },
                  { value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_TUESDAY, label: "TUE" },
                  { value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_WEDNESDAY, label: "WED" },
                  { value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_THURSDAY, label: "THU" },
                  { value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_FRIDAY, label: "FRI" },
                  { value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_SATURDAY, label: "SAT" },
                ]}
                defaultSelected={recurrentData?.preferrence?.preferredDays} //FIXME - 
                onChange={(data) => {//FIXME - 
                  handleRecurrentData("preferrence", "preferredDays", data)
                  // props.handleMultiAppointmentChange(
                  //   data,
                  //   "appointments",
                  //   "preferrence",
                  //   "preferredDays"
                  // );
                }}
                iconPosition={"left"}
                // inLineStyles={styles.tapSelectStyle}
                className={classes.tapselectcss}
              ></TapSelect>
            </Div>
          </Div>
        </>
      ) : recurrentData?.every?.periodicity?.value ===
        process.env.REACT_APP_FREQUENCY_UOM_DAYS ? null : null}

      {recurrentData?.every?.periodicity?.value ===
        process.env.REACT_APP_FREQUENCY_UOM_MONTHS ? (
        <Div
          id={`${parent_id}_bookmultiple_months_div`}
          key={"4"}
          className={"qdm"}
          style={{
            backgroundColor: UIColor.greyBackground.color,
            padding: "20px",
            paddingTop: "0px",
          }}
        >
          <Text
            id={`${parent_id}_bookmultiple_PREFERRED_WEEKS_text`}
            key={"3"}
            name={"CHOOSE YOUR PREFERRED WEEKS"}
            style={{
              color: UIColor.primaryText.color,
              fontWeight: "600",
              paddingTop: "30px",
              fontSize: "12px",
            }}
            className={"pc_regular"}
          ></Text>
          <Div
            id={`${parent_id}_bookmultiple_WEEKS_div`}
            style={{ marginBottom: 10 }}
          >
            <TapSelect
              id={`${parent_id}_bookmultiple_WEEKS_tapselect`}
              key={"4"}
              inLineStyles={{ minWidth: 30, padding: "5px 15px" }}
              type={"multiple"}
              fullWidth={false}
              // selectedBorderColor={"#0071F2"}
              label=""
              selectedBorderColor={UIColor.secondaryButtonColor.color}
              selectedBackgroundColor={UIColor.secondaryButtonColor.color}
              textColor={UIColor.secondaryButtonColor.color}
              selectedTextColor="#fff"
              textSize={"small"}
              iconHeight={"20"}
              iconWidth={"20"}
              options={[
                { value: process.env.REACT_APP_FREQUENCY_UOM_MONTH_WEEK1, label: "WEEK 1" },
                { value: process.env.REACT_APP_FREQUENCY_UOM_MONTH_WEEK2, label: "WEEK 2" },
                { value: process.env.REACT_APP_FREQUENCY_UOM_MONTH_WEEK3, label: "WEEK 3" },
                { value: process.env.REACT_APP_FREQUENCY_UOM_MONTH_WEEK4, label: "WEEK 4" },
                { value: process.env.REACT_APP_FREQUENCY_UOM_MONTH_WEEK5, label: "WEEK 5" },
              ]}
              defaultSelected={recurrentData?.preferrence?.preferredWeeks}
              onChange={(data) => {//FIXME -
                // props.handleMultiAppointmentChange(
                //   data,
                //   "appointments",
                //   "preferrence",
                //   "preferredWeeks"
                // );
                handleRecurrentData("preferrence", "preferredWeeks", data,)
              }}
              iconPosition={"left"}
              className={classes.tapselectcss}
            ></TapSelect>
          </Div>
        </Div>
      ) : null}
      <Popover
        id={id}
        open={open}
        anchorEl={state.anchorEl}
        onClose={() => onChangeState("anchorEl", null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {state.index === 0 && (
          <Div
            id={`${parent_id}_recurrent_dateformat_div`}
          >
            <DatePicker
              id={`${parent_id}_recurrent_dateformat_datepicker`}
              timeInputLabel="Time:"
              dateFormat="yyyy/MM/dd h:mm aa"
              showTimeInput
              // selected={this.state.dob.value}
              selected={new Date()}
              // showPopperArrow={false}
              // showYearDropdown
              inline
              minDate={new Date()}
              minTime={new Date()}
              onChange={(date) => {
                // handleRecurrentData('startsOn','dateTime',moment(date).format('DD-MM-YYYY,hh:mm A'))
                handleRecurrentData(
                  "startsOn",
                  "dateTime",
                  date
                );
              }}
            // placeholderText="DD-MM-YYYY"
            // maxDate={new Date()}
            // className={"pc_regular"}
            />
          </Div>
        )}
        {state.index === 1 && (
          <Div
            id={`${parent_id}_recurrent_Ends_after_div`}
            style={styles.endsAfter}>
            <Div
              id={`${parent_id}_recurrent_Ends_after_sub_div`}
              style={{ ...styles.flexCenterJustify, cursor: "pointer" }}
              onClick={() => onChangeState("endsAfter", 0)}
            >
              <Text
                id={`${parent_id}_recurrent_Ends_after_text`}
                style={{ ...styles.span, marginRight: 10 }}
                className="pc_regular"
              >
                Ends after
              </Text>
              <Div
                id={`${parent_id}_recurrent_number_div`}
                style={styles.popoverOccurence}>
                <Text
                  id={`${parent_id}_recurrent_number_text`}
                  style={{ fontSize: 12 }} className="pc_regular">
                  01
                </Text>
                <Text
                  id={`${parent_id}_recurrent_occurence_text`}
                  style={{ ...styles.divider, fontSize: 12 }}
                  className="pc_regular"
                >
                  &nbsp;|&nbsp;occurence
                </Text>
              </Div>
            </Div>
            <Divider
              id={`${parent_id}_recurrent_occurence_divider`}
              style={{ margin: "5px 0px" }} />
            <Div
              id={`${parent_id}_recurrent_Ends_by_div`}
              style={{ ...styles.flexCenterJustify, cursor: "pointer" }}
              onClick={() => onChangeState("endsAfter", 1)}
            >
              <Text
                id={`${parent_id}_recurrent_Ends_by_text`}
                style={{ ...styles.span, marginRight: 10 }}
                className="pc_regular"
              >
                Ends by
              </Text>
              <Div
                id={`${parent_id}_recurrent_date_format_div`}
                style={styles.popoverOccurence}>
                <Text
                  id={`${parent_id}_recurrent_date_format_text`}
                  style={{ fontSize: 12 }} className="pc_regular">
                  DD-MM-YY
                </Text>
                <Text
                  id={`${parent_id}_recurrent_date_format_sidedivider_text`}
                  style={{ ...styles.divider, margin: "0px 5px" }}>|</Text>
                <CalendarIcon
                  id={`${parent_id}_recurrent_calender_icon`}
                  color={UIColor.primaryText.color} width={"14"} height={"14"} />
              </Div>
            </Div>
          </Div>
        )}
        {state.index === 2 && (
          <Div
            id={`${parent_id}_recurrent_DatePicker_div`}
          >
            <DatePicker
              id={`${parent_id}_recurrent_DatePicker`}
              dateFormat="dd-MM-yyyy"
              // selected={this.state.dob.value}
              selected={new Date()}
              // showPopperArrow={false}
              // showYearDropdown
              inline
              minDate={new Date()}
              minTime={new Date()}
              onChange={(date) => {
                handleRecurrentData(
                  "endsAfter",
                  "endsBy",
                  date
                );
                onChangeState("anchorEl", null);
              }}
            // placeholderText="DD-MM-YYYY"
            // maxDate={new Date()}
            // className={"pc_regular"}
            />
          </Div>
        )}
      </Popover>
    </div>
  );
};

Recurrent.defaultProps = {
  title: "RECURRENT ORDER",
  deleteButton: "Delete",
  deleteButtonAction: null,
  saveButton: "Save",
  saveButtonAction: null,
  recurrentData: {},
  width: "100%",
};
