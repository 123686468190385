import React, { useState, useRef } from "react";
import {
  Div,
  Row,
  Col,
  TextInput,
  Text,
  Image,
  Icons,
  Button,
} from "qdm-component-library";
import {
  Switch,
  makeStyles,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  CalendarIcon,
  Recurrent,
  RecurrentList,
  PerformerDetails,
  RequestorDetailsForm,
  SingleSelectCard,
  FormHeader,
} from "../../../components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Attributes } from "./attributes";
import {
  getReccuranceDates,
  AlertProps,
  diffeenceBtArray,
  getOrderName,
  utcTOLocal,
  getStartEndMinTime,
} from "../../../utils";
import { UIColor } from "../../../themes/theme";
// import { actions } from "primarycare-binder";
import actions from "../../../redux/actions"
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import CustomDatePicker from "../../../components/customDatePicker";
import copy from "fast-copy";
import {setHours, setMinutes} from "date-fns";
import icons8eye from "../../../assets/img/svg/icons8-eye.svg"

const useStyles = makeStyles({
  switch: {
    "& .Mui-checked": {
      color: "#fff",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: UIColor.primaryColor.color,
      opacity: 1,
    },
  },
  dateWrapper: {
    border: `1px solid ${UIColor?.lineBorderFill?.color}`,
    borderRadius: 8,
    padding: 5,
    display: "flex",
    alignItems: "center",
    minHeight: 40,
    "&:hover": {
      borderColor: UIColor.primaryColor.color,
    },
  },
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor?.lineBorderFill?.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
  vitailsCarderr: {
    fontSize: "12px",
    color: UIColor?.error?.color,
    marginBottom: "24px !important",
  },
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,

      fontSize: "12px !important",

      fontFamily: "pc_regular!important",
    },

    "& .MuiInputBase-root": {
      height: 40,
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: UIColor.lineBorderFill.color,

        borderRadius: 8,
      },

      "&:hover fieldset": {
        borderColor: UIColor.lineBorderFill.color,
      },

      "&.Mui-focused fieldset": {
        borderWidth: 1,

        borderColor: UIColor.lineBorderFill.color,
      },
    },

    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
  chip: {
    backgroundColor: "#f1f6fc",
    color: "#001C3C",
    fontWeight: 500,
    borderRadius: "4px",
  },
});

export const TreatmentProcedureForm = (props) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
  const LabOrderApiSlice = useSelector((state) => state.LabOrderApiSlice);
  const storeData = useSelector((state) => state?.TreatmentSlice);
  const { list, child, editIndex } = props.treatment;

  const recurrentDiv = useRef(null);

  // style
  const styles = {
    labelStyle: {
      color: UIColor?.tertiaryText?.color,
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: UIColor?.lineBorderFill?.color,
    },
    colPadding: {
      padding: "7px",
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    star: {
      color: UIColor?.error?.color,
      marginLeft: 5,
    },
    dateText: {
      fontSize: 14,
      color: UIColor.secondaryText.color,
      flex: 1,
    },
    calenderText: {
      marginLeft: 5,
      cursor: "pointer",
      display: "flex",
    },
    recurrentText: {
      border: `1px solid ${UIColor.lineBorderFill.color}`,
      borderRadius: 8,
      backgroundColor: "#fff",
      display: "flex",
      alignItems: "center",
      padding: 10,
    },
    eye: {
      cursor: "pointer",
    },
    trash: {
      color: UIColor?.error?.color,
      marginRight: 10,
      cursor: "pointer",
    },
    calenderDiv: {
      cursor: "pointer",
      borderRadius: 8,
      padding: 10,
      width: 40,
      height: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
    },
    cancelBtn: {
      padding: "8px",

      fontSize: 14,

      marginRight: 10,

      borderColor: UIColor.lineBorderFill.color,

      color: UIColor.primaryColor.color,

      borderRadius: 8,
    },

    filledBtn: {
      backgroundColor: UIColor.primaryColor.color,

      borderColor: UIColor.primaryColor.color,

      padding: "8px",

      fontSize: 14,

      borderRadius: 8,
    },
    flexEnd: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    label: {
      color: UIColor?.tertiaryText?.color,
      fontSize: 12,
    },
    option: {
      width: "100%",
      fontSize: 14,
      color: UIColor?.secondaryText?.color,
    },
  };
  // end

  // state
  const [allmasters, setAllMasters] = useState([])
  const [state, setState] = useState({
    orderType: {},
    orderCatalog: {},
    qty: "",
    priority: {},
    nature: {},
    mode: {},
    recurrentMode: false,
    startDate: "",
    endDate: "",
    selectDiagnosis: [],
    indication: {},
    patientInstruction: {},
    notes: "",
    preparatoryInstruction: {},
    departmentalInstruction: {},
    requestorDetails: {
      organization: {},
      practitionerRole: {},
      orderBy: {},
      practitionerId: props.patientgetData?.pID,
    },
    recurrentData: {
      every: {
        value: "",
        periodicity: {},
      },
      startsOn: {
        dateTime: "",
      },
      endsAfter: {
        endsAfter: "",
        endsBy: "",
      },
    },
    recurrentList: [],
    performerDetails: [],
    attributes: {
      view: { label: "PA", value: "PA" },
      bedSide: true,
      contrast: "",
    },
    createdAt: "",
    error: {},
    editData: null,
    statusData: {},
    treatment_order: {},
    statusData: {},
    statushistory: [],
    parentOrderId: child?.parentOrderId ? child?.parentOrderId : null,
  });
  // end
console.log("TraetStart",state);
  // use Effects
  React.useEffect(() => {
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "",
    });
    const getApi = async () => {
      getAllMaster()
      await dispatch(actions.GET_ORDERTYPE());
      await dispatch(actions.GET_ORDERNATURE());
      await dispatch(actions.GET_ORDERMODE());
      await dispatch(
        actions.GET_DIAGNOSIS({ input_text:""})
      );
      await dispatch(
        actions.LABORDER_ORGANIZATION({ id: props?.fetchUser?.orgId })
      );
      await dispatch(actions.GET_LOCATION_ALL())
    };
    getApi();
    //dispatch(actions.TREATMENT_DIAGNOSIS({ id: props.encounterData.data.eId }));
    // let dataedit = editIndex >= 0 ? list[editIndex] : 0;
    let dataedit;

    if (child.IsChild) {
      dataedit = copy(editIndex >= 0 ? list[editIndex] : 0);
    } else {
      if (child.index || child.index === 0) {
        dataedit =
          copy(editIndex >= 0
            ? child.index >= 0
              ? list[editIndex].childRecurrent[child.index]
              : 0
            : 0);
      } else {
        dataedit = copy(editIndex >= 0 ? list[editIndex] : 0);
      }
    }

    if (dataedit?.recurrentData?.every?.value > 0) {
      const dateArr = getReccuranceDates(
        moment(dataedit?.recurrentData?.startsOn?.dateTime).format(
          "DD-MM-YYYY"
        ), // DD-MM-YYYY
        dataedit?.recurrentData?.endsAfter?.endsAfter !== "0"
          ? "NoOfDays"
          : "Calendar", // [by => Calendar, on => NoOfDays]
        dataedit?.recurrentData?.endsAfter?.endsAfter !== "0"
          ? JSON.parse(dataedit?.recurrentData?.endsAfter?.endsAfter)
          : moment(dataedit?.recurrentData?.endsAfter?.endsBy).format(
              "DD-MM-YYYY"
            ), // // [by => Date(DD-MM-YYYY), on => number(5)]
        dataedit?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
        JSON.parse(dataedit?.recurrentData?.every?.value), // number
        [], // []
        [] // []
      );
      if (dataedit?.recurrentList?.length > 0) {
        state.recurrentList = dataedit?.recurrentList?.map((v, i) => {
          return {
            date: v?.date,
            showid: i,
            id: v?.id,
            status: v?.status,
          };
        });
      } else {
        state.recurrentList = dataedit?.childRecurrent
          ?.reverse()
          ?.map((v, i) => {
            return {
              date: v.dateTime
                ? moment(v.dateTime).format("YYYY-MM-DD,hh:mm A")
                : moment().format("YYYY-MM-DD,hh:mm A"),
              showid: i,
              id: v?.editData,
              status: "Done",
            };
          });
      }
    }

    dataedit.requestorDetails = {
      ...dataedit?.requestorDetails,
      practitionerId: dataedit?.requestorDetails?.practitionerId
        ? dataedit?.requestorDetails?.practitionerId
        : props?.patientgetData?.pID,
    };

    dataedit.orderCatalog = {
      label: dataedit.orderCatalog.label
        ? dataedit.orderCatalog.label
        : dataedit?.treatment_order?.label,
      value: dataedit.orderCatalog.value
        ? dataedit.orderCatalog.value
        : dataedit?.treatment_order?.value,
      _id: dataedit.orderCatalog._id 
        ? dataedit.orderCatalog._id
        : dataedit?.treatment_order?._id,
    };
    const recurrentNewDate = () => {
      if(dataedit?.dateTime === dataedit?.recurrentData?.startsOn?.dateTime){
        return dataedit?.startDate?.slice(0,11) + dataedit?.dateTime?.split(",")[1]
      }else{
        return dataedit?.startDate
      }
    }
    setState({
      ...state,
      initailDate:dataedit?.recurrentData?.every?.value === "0" ? dataedit?.startDate : recurrentNewDate() ?? "",
      orderType: dataedit?.orderType,
      orderCatalog: dataedit?.orderCatalog,
      qty: dataedit?.qty,
      priority: dataedit?.priority,
      nature: dataedit?.nature,
      mode: dataedit?.mode,
      recurrentMode: dataedit?.recurrentData?.every?.value > 0,
      startDate: dataedit?.recurrentData?.every?.value === "0" ? dataedit?.startDate : recurrentNewDate() ?? "",
      endDate: dataedit?.endDate,
      selectDiagnosis: [...dataedit?.selectDiagnosis],
      indication: dataedit?.indication,
      patientInstruction: dataedit?.patientInstruction,
      notes: dataedit.notes,
      preparatoryInstruction: dataedit?.preparatoryInstruction,
      departmentalInstruction: dataedit?.departmentalInstruction,
      requestorDetails: dataedit?.requestorDetails,
      recurrentData: dataedit?.recurrentData,
      performerDetails: dataedit?.performerDetails,
      attributes: dataedit?.attributes,
      createdAt: dataedit?.createdAt,
      error: {},
      editData: dataedit?.editData,
      treatment_order: dataedit?.treatment_order,
      statusData: dataedit?.statusData,
      statushistory: dataedit?.statushistory,
      parentOrderId: dataedit?.parentOrderId
    });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
    setIndex(0);
  }, []);
  // end

  const FlterOptions3 = (type) => {
    let allMasters = allmasters;
    let filteredArray = allMasters.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?._id,
        label: data[index]?.display,
      };
      list.push(datatemp);
    }
    return list;
  };

  const FlterOptions2 = (type) => {
    let allMasters = allmasters;
    let filteredArray = allMasters.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        label: data[index]?._id,
        value: data[index]?.display,
      };
      list.push(datatemp);
    }
    return list;
  };
    const getAllMaster = async() => {
    const response = await dispatch(actions.GET_ALL_MASTERS(""));
    setAllMasters(response?.payload?.data)
  }


  // get Reccurent data
  const reFuncy = async () => {
    const dataedit = editIndex >= 0 ? list[editIndex] : 0;
    const dateArr = await getReccuranceDates(
      moment(dataedit?.recurrentData?.startsOn?.dateTime).format("DD-MM-YYYY"), // DD-MM-YYYY
      dataedit?.recurrentData?.endsAfter?.endsAfter !== "0"
        ? "NoOfDays"
        : "Calendar", // [by => Calendar, on => NoOfDays]
      dataedit?.recurrentData?.endsAfter?.endsAfter !== "0"
        ? JSON.parse(dataedit?.recurrentData?.endsAfter?.endsAfter)
        : moment(dataedit?.recurrentData?.endsAfter?.endsBy).format(
            "DD-MM-YYYY"
          ), // // [by => Date(DD-MM-YYYY), on => number(5)]
      dataedit?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
      JSON.parse(dataedit?.recurrentData?.every?.value), // number
      [], // []
      [] // []
    );
    state.recurrentList = dataedit?.childRecurrent?.reverse()?.map((v, i) => {
      return {
        date: v.apptDate
          ? moment.unix(v.apptDate).format("YYYY-MM-DD,hh:mm A")
          : moment().format("YYYY-MM-DD,hh:mm A"),
        showid: i,
        id: v?.editData,
        status: "Done",
      };
    });
    await setState({ ...state });
  };
  // end

  // set state
  const onChangeState = async (key, value, ts) => {
    delete state.error[key];
    if (ts === "content") {
      state[key] = {
        ...state[key],
        [ts]: value,
      };
      setState({ ...state });
      return;
    }
    if (key === "recurrentData") {
      await reFuncy();
    }
    if (key === "statusData") {
      // await dispatch(
      //   actions.UPDATE_LABORDER({
      //     list: value,
      //     id: props.encounterData.data.eId,
      //   })
      // );
    }
    if (key === "recurrentMode") {
      state.recurrentData = {
        every: {
          value: "",
          periodicity: {},
        },
        startsOn: {
          dateTime: "",
        },
        endsAfter: {
          endsAfter: "",
          endsBy: "",
        },
      };
    }
    setState({ ...state, [key]: value });
  };
  // end

  const changeAttributes = (parentKey, key, value) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState[parentKey][key] = value;
    setState(newState);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [index, setIndex] = useState(null);

  const handleClick = (index, event) => {
    setAnchorEl(event);
    setIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goBack = async () => {
    await props.treatment.setTreatment({
      ...props.treatment,
      child: {},
    });
    props.onCancel();
  };

  //validate
  const [isErr, setIsErr] = useState(false);
  const validate = () => {
    if (
      !state.orderCatalog?.label ||
      !state.orderType?.label ||
      !state.priority?.label ||
      !state.nature?.label ||
      !state.mode?.label ||
      !state.selectDiagnosis?.length > 0 ||
      !state.indication?.label ||
      !state.startDate ||
      !state.qty
    ) {
      setIsErr(true);
    } else {
      setIsErr(false);
    }
  };

  // handle update
  const handleUpdate = async () => {
    validate();
    if (!state.orderCatalog?.label) {
      state.error["orderCatalog"] = true;
    } else if (!state.orderType?.label) {
      state.error["orderType"] = true;
    } else if (!state.priority?.label) {
      state.error["priority"] = true;
    } else if (!state.nature?.label) {
      state.error["nature"] = true;
    } else if (!state.mode?.label) {
      state.error["mode"] = true;
    } else if (!state.selectDiagnosis?.length > 0) {
      state.error["selectDiagnosis"] = true;
    } else if (!state.indication?.label) {
      state.error["indication"] = true;
    } else if (!state.startDate) {
      state.error["startDate"] = true;
    } else if (
      // state.recurrentMode &&
      !state.recurrentData?.every?.value ||
      !state.recurrentData?.every?.periodicity?.value ||
      !(
        state.recurrentData?.endsAfter?.endsAfter ||
        state.recurrentData?.endsAfter?.endsBy
      ) ||
      !state.recurrentData?.startsOn.dateTime
    ) {
      // state.error["recurrentData"] = true;
    } else if (
      !state.requestorDetails?.organization?.label ||
      !state.requestorDetails?.practitionerRole?.label ||
      !state.requestorDetails?.orderBy?.label
    ) {
      //state.error["requestorDetails"] = true;
    } 
    // else if (!state.performerDetails?.length > 0) {
    //   state.error["performerDetails"] = true;
    // }
    setState({ ...state });

    // if (JSON.stringify(state.error) === "{}") {
    //   props.saveTreatment([state], state.editData);
    // }

    if (JSON.stringify(state.error) === "{}") {
      let dataedit;
      if (child.IsChild) {
        dataedit = editIndex >= 0 ? list[editIndex] : 0;
      } else {
        if (child.index || child.index === 0) {
          dataedit =
            editIndex >= 0
              ? child.index >= 0
                ? list[editIndex].childRecurrent[child.index]
                : 0
              : 0;
        } else {
          dataedit = editIndex >= 0 ? list[editIndex] : 0;
        }
      }
      let childArr = [];
      if (dataedit.childRecurrent) {
        childArr = dataedit.childRecurrent.map((val) => val.editData);
      }
      let recIds = state?.recurrentList?.map((val) => val.id);
      let deleteArr = diffeenceBtArray(childArr, recIds ? recIds : []);
      deleteArr = deleteArr?.filter((val) => val);
      childArr = childArr?.filter((val) => deleteArr.indexOf(val) === -1);
      await props.saveTreatment(
        [state],
        state.editData,
        null,
        true,
        childArr,
        deleteArr
      );
      await props.onCancel();
    } else {
      const err = {
        orderCatalog: "order catalog",
        orderType: "order type",
        priority: "priority",
        nature: "nature",
        mode: "mode",
        selectDiagnosis: "diagnosis",
        indication: "indication",
        startDate: "start date",
        recurrentData: "recurrent details",
        requestorDetails: "requestor details",
        performerDetails: "performer details",
      };
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        // msg: `Please fill the ${err[Object.keys(state?.error)?.[0]]} !`,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };
  // end

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { parent_id } = props;
  return (
    <div>
      <Div
        id={`${parent_id}_treatment_procedure_parent_div`}
        style={{ padding: "6px 0px", background: "#fff", borderRadius: "10px" }}
      >
        {/* header */}
        <FormHeader
          parent_id={"treatment_procedure"}
          cyclosporine={true}
          // statusData={"Draft"}
          // userGroup={true}
          stateData={state}
          statusDataList={state.statusData}
          status={true}
          user={true}
          onChangeState={onChangeState}
          statusData={
            state?.statushistory?.length > 0
              ? state?.statushistory?.[state?.statushistory?.length - 1]
                  ?.new_status ?? "Draft"
              : "Draft"
          }
          statushistory={state?.statushistory ?? []}
          patientDetails={{
            name: getOrderName(state, loggedUserInfo?.data?.completeName || ""),
            isYou: true,
            date: utcTOLocal(state.createdAt, "MMMM Do YYYY"),
            profile_pic:
              "https://thumbs.dreamstime.com/b/portrait-charming-imposing-kid-private-school-boy-feel-glad-ready-work-worker-want-be-cool-professional-look-hear-wear-blazer-152393107.jpg",
            status: "created",
          }}
          {...props}
        />
        {/* end */}
      </Div>
      {/* Main content */}
      <div
        id={`${parent_id}_treatment_procedure_parent_sub_div`}
        style={{
          height: "calc(100vh - 292px)",
          overflow: "auto",
          background: "#fff",
          borderRadius: "10px",
          margin: "10px 0px",
        }}
      >
        <Row
          id={`${parent_id}_treatment_procedure_Order_Type_row`}
          style={{ padding: 10 }}
        >
          <Col
            id={`${parent_id}_treatment_procedure_Order_Type_col`}
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            inLineStyles={styles.colPadding}
          >
            <Text
              id={`${parent_id}_treatment_procedure_Order_Type_text`}
              className="pc_regular"
              inLineStyles={styles?.label}
            >
              Order Type &nbsp;{" "}
              <span
                id={`${parent_id}_treatment_procedure_Order_Type_star_span`}
                style={{ color: UIColor?.error?.color }}
              >
                *
              </span>
            </Text>
            <Autocomplete
              id={`${parent_id}_treatment_procedure_Order_Type_autocomplete`}
              className={classes.autocomplete}
              size="small"
              // options={props?.teartment_ordertype?.data ?? []}
              options={LabOrderApiSlice?.getordertype?.data ?? []}
              disableClearable
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  id={`${parent_id}_treatment_procedure_Order_Type_textfield`}
                  {...params}
                  variant="outlined"
                  placeholder="select type"
                  error={
                    isErr &&
                    !state.orderType.label &&
                    JSON.stringify(state.orderType) !== "{}"
                      ? true
                      : false
                  }
                />
              )}
              renderOption={(option) => (
                <Typography
                  id={`${parent_id}_treatment_procedure_${option.label.replaceAll(
                    " ",
                    "_"
                  )}_typography`}
                  className="pc_regular"
                  style={{
                    width: "100%",
                    fontSize: 14,
                    color: UIColor?.secondaryText?.color,
                  }}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              // value={state.orderType}
              value={
                LabOrderApiSlice?.getordertype?.data?.filter(
                  (x) => x?._id === state.orderType?.label
                )?.[0] || state?.orderType
              }
              onChange={(e, newValue) => onChangeState("orderType", newValue)}
            />
          </Col>

          <Col
            id={`${parent_id}_treatment_procedure_order_catalog_col`}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            inLineStyles={styles.colPadding}
          >
            <Text
              id={`${parent_id}_treatment_procedure_order_catalog_text`}
              className="pc_regular"
              inLineStyles={styles?.label}
            >
              Order Catalog &nbsp;{" "}
              <span
                id={`${parent_id}_treatment_procedure_order_catalog_star_span`}
                style={{ color: UIColor?.error?.color }}
              >
                *
              </span>
            </Text>
            <Autocomplete
              id={`${parent_id}_treatment_procedure_order_catalog_autocomplete`}
              className={classes.autocomplete}
              size="small"
              // options={props?.teartment_order_catalog?.data ?? []}
              options={storeData?.teartment_getcategory?.data ?? []}
              disableClearable
              Icons="yes"
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  id={`${parent_id}_treatment_procedure_order_catalog_textfield`}
                  {...params}
                  variant="outlined"
                  placeholder="order catalog"
                  error={
                    isErr &&
                    !state.orderCatalog?.label &&
                    JSON.stringify(state.orderCatalog) !== "{}"
                      ? true
                      : false
                  }
                />
              )}
              renderOption={(option) => (
                <Typography
                  id={`${parent_id}_treatment_procedure_${option.label.replaceAll(
                    " ",
                    "_"
                  )}.typography`}
                  className="pc_regular"
                  style={styles?.option}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              value={state?.orderCatalog}
              onChange={(e, newValue) =>
                onChangeState("orderCatalog", newValue)
              }
            />
          </Col>

          <Col
            id={`${parent_id}_treatment_procedure_CalendarIcon_col`}
            xs={12}
            sm={12}
            md={1}
            lg={1}
            xl={1}
            inLineStyles={styles.colPadding}
          >
            <Div
              id={`${parent_id}_treatment_procedure_CalendarIcon_div`}
              style={{ marginBottom: 23 }}
            >
              {" "}
            </Div>
            <Div
              id={`${parent_id}_treatment_procedure_CalendarIcon_sub_div`}
              style={{
                ...styles.calenderDiv,
                backgroundColor:
                  index === 4 && open
                    ? UIColor?.primaryColor?.color
                    : UIColor?.lineBorderFill?.color,
              }}
              onClick={(event) => handleClick(4, event?.currentTarget)}
            >
              <CalendarIcon
                id={`${parent_id}_treatment_procedure_CalendarIcon`}
                color={index === 4 && open ? "#fff" : "#000000"}
                width={"16"}
                height={"16"}
              />
            </Div>
          </Col>

          <Col
            id={`${parent_id}_treatment_procedure_quantity_col`}
            xs={12}
            sm={12}
            md={1}
            lg={1}
            xl={1}
            inLineStyles={styles.colPadding}
          >
            <TextInput
              id={`${parent_id}_treatment_procedure_quantity_textinput`}
              value={state?.qty}
              onChange={(evt) => onChangeState("qty", evt.target.value)}
              variant={"outlined"}
              hoverColor={UIColor?.lineBorderFill?.color}
              placeholder="quantity"
              isRequired={true}
              maxLength={3}
              error={isErr && +state.qty < 1 ? true : false}
              label="Qty"
              labelStyle={styles.labelStyle}
              style={
                isErr && +state.qty < 1
                  ? { borderRadius: 8 }
                  : styles.borderStyle
              }
            />
          </Col>

          <Col
            id={`${parent_id}_treatment_procedure_priority_col`}
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            inLineStyles={styles.colPadding}
          >
            <Text
              id={`${parent_id}_treatment_procedure_priority_text`}
              className="pc_regular"
              inLineStyles={styles?.label}
            >
              Priority &nbsp;{" "}
              <span
                id={`${parent_id}_treatment_procedure_priority_star_span`}
                style={{ color: UIColor?.error?.color }}
              >
                *
              </span>
            </Text>
            <Autocomplete
              id={`${parent_id}_treatment_procedure_priority_autocomplete`}
              className={classes.autocomplete}
              size="small"
              // options={props?.teartment_priority?.data ?? []}
              options={storeData?.teartment_priority?.data ?? []}
              disableClearable
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  id={`${parent_id}_treatment_procedure_priority_textfield`}
                  {...params}
                  variant="outlined"
                  placeholder="priority"
                  error={
                    isErr &&
                    !state.priority?.label &&
                    JSON.stringify(state.priority) !== "{}"
                      ? true
                      : false
                  }
                />
              )}
              renderOption={(option) => (
                <Typography
                  id={`${parent_id}_treatment_procedure_${option.label.replaceAll(
                    " ",
                    "_"
                  )}_typography`}
                  className="pc_regular"
                  style={styles?.option}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              value={state?.priority}
              onChange={(e, newValue) => onChangeState("priority", newValue)}
            />
          </Col>

          <Col
            id={`${parent_id}_treatment_procedure_Nature_col`}
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            inLineStyles={styles.colPadding}
          >
            <Text
              id={`${parent_id}_treatment_procedure_Nature_text`}
              className="pc_regular"
              inLineStyles={styles?.label}
            >
              Nature &nbsp;{" "}
              <span
                id={`${parent_id}_treatment_procedure_Nature_star_span`}
                style={{ color: UIColor?.error?.color }}
              >
                *
              </span>
            </Text>
            <Autocomplete
              id={`${parent_id}_treatment_procedure_Nature_autocomplete`}
              className={classes.autocomplete}
              size="small"
              // options={props?.teartment_nature?.data ?? []}
              options={storeData?.teartment_nature?.data ?? []}
              disableClearable
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  id={`${parent_id}_treatment_procedure_Nature_textfield`}
                  {...params}
                  variant="outlined"
                  placeholder="nature"
                  error={
                    isErr &&
                    !state.nature?.label &&
                    JSON.stringify(state.nature) !== "{}"
                      ? true
                      : false
                  }
                />
              )}
              renderOption={(option) => (
                <Typography
                  id={`${parent_id}_treatment_procedure_${option.label.replaceAll(
                    " ",
                    "_"
                  )}_typography`}
                  className="pc_regular"
                  style={styles?.option}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              value={state?.nature}
              onChange={(e, newValue) => onChangeState("nature", newValue)}
            />
          </Col>

          <Col
            id={`${parent_id}_treatment_procedure_mode_col`}
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            inLineStyles={styles.colPadding}
          >
            <Text
              id={`${parent_id}_treatment_procedure_mode_text`}
              className="pc_regular"
              inLineStyles={styles?.label}
            >
              Mode &nbsp;{" "}
              <span
                id={`${parent_id}_treatment_procedure_mode_star_span`}
                style={{ color: UIColor?.error?.color }}
              >
                *
              </span>
            </Text>
            <Autocomplete
              id={`${parent_id}_treatment_procedure_mode_autocomplete`}
              className={classes.autocomplete}
              size="small"
              // options={props?.teartment_mode?.data ?? []}
              // options={LabOrderApiSlice?.getordermode?.data ?? []}
              options={FlterOptions3("ORDERMODE") ?? []}
              disableClearable
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  id={`${parent_id}_treatment_procedure_mode_textfield`}
                  {...params}
                  variant="outlined"
                  placeholder="mode"
                  error={
                    isErr &&
                    !state.mode.label &&
                    JSON.stringify(state.mode) !== "{}"
                      ? true
                      : false
                  }
                />
              )}
              renderOption={(option) => (
                <Typography
                  id={`${parent_id}_treatment_procedure_${option.label.replaceAll(
                    " ",
                    "_"
                  )}_typography`}
                  className="pc_regular"
                  style={styles?.option}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              value={state?.mode}
              onChange={(e, newValue) => onChangeState("mode", newValue)}
            />
          </Col>

          <Col
            id={`${parent_id}_laborder_Recurrent_col`}
            xs={12}
            sm={12}
            md={state.recurrentMode ? 8 : 2}
            lg={state.recurrentMode ? 8 : 2}
            xl={state.recurrentMode ? 8 : 2}
            inLineStyles={styles.colPadding}
          >
            {child.IsChild ? (
              state.recurrentMode ? (
                <div
                  id={`${parent_id}_laborder_recurrentMode_div`}
                  ref={recurrentDiv}
                >
                  <Div
                    id={`${parent_id}_laborder_Recurrent_div`}
                    className="pc_regular"
                    inLineStyles={styles.labelStyle}
                  >
                    {"Recurrent"}
                  </Div>
                  <Div
                    id={`${parent_id}_laborder_recurrentMode_sub_div`}
                    style={styles.recurrentText}
                  >
                    <Text
                      id={`${parent_id}_laborder_Every_calender_format_text`}
                      style={{
                        ...styles.dateText,
                        color: UIColor?.secondaryText?.color,
                      }}
                    >
                      {/* {state?.recurrentData?.every?.value &&
                        `Every ${state?.recurrentData?.every?.value}`}
                      &nbsp; */}
                      {state?.recurrentData?.every?.periodicity?.value &&
                        state?.recurrentData?.every?.periodicity?.value}
                      &nbsp;
                      {state?.recurrentData?.startsOn?.dateTime &&
                        `starting on ${moment(
                          state?.recurrentData?.startsOn?.dateTime
                        ).format("MMMM Do YYYY")}`}
                      {/* {state?.startDate &&
                        `starting on ${moment(
                          state?.recurrentList?.sort(
                            (a, b) =>
                              b?.date?.split(",")?.[0]?.slice(-2) -
                              a?.date?.split(",")?.[0]?.slice(-2)
                          )?.[state?.recurrentList?.length - 1]?.date
                        ).format("MMMM Do YYYY")}`} */}
                      &nbsp;
                      {state?.recurrentData?.endsAfter?.endsAfter &&
                      state?.recurrentData?.endsAfter?.endsAfter !== "0"
                      ? `ends after ${state?.recurrentData?.endsAfter?.endsAfter}`
                        // ? `ends after ${state?.recurrentList?.length}`
                        : // `ends after ${state?.recurrentData?.endsAfter?.endsAfter}`
                          state?.recurrentData?.endsAfter?.endsBy &&
                          `ends by ${moment(
                            state?.recurrentData?.endsAfter?.endsBy
                          ).format("MMMM Do YYYY")}`}
                      {/* {state?.startDate} */}
                    </Text>
                    <Div
                      id={`${parent_id}_laborder_trash_eye_div`}
                      style={styles.flexCenter}
                    >
                      <Icons
                        id={`${parent_id}_laborder_trash_icons`}
                        fontIcon="trash"
                        style={styles.trash}
                        onClick={async () => {
                          await onChangeState("recurrentMode", false);
                        }}
                      />

                      <Image
                        id={`${parent_id}_laborder_eye_Image`}
                        aria-describedby={id}
                        src={icons8eye}
                        style={styles.eye}
                        alt="view"
                        onClick={(event) =>
                          handleClick(3, event?.currentTarget)
                        }
                      />
                    </Div>
                  </Div>
                </div>
              ) : (
                <>
                  <Div
                    id={`${parent_id}_laborder_Recurrent_div`}
                    className="pc_regular"
                    inLineStyles={styles.labelStyle}
                  >
                    {"Recurrent"}
                  </Div>
                  <Switch
                    id={`${parent_id}_laborder_recurrentMode_switch`}
                    checked={state.recurrentMode}
                    onChange={(event) => {
                      // onChangeState("recurrentMode", event.target.checked);
                      if (event.target.checked) {
                        handleClick(0, event?.currentTarget);
                      }
                      // event.target.checked &&
                      //   handleClick(0, recurrentDiv?.current);
                    }}
                    className={classes.switch}
                  />
                </>
              )
            ) : (
              <Row>
                <Col
                  id={`${parent_id}_laborder_Start_Date_col`}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  inLineStyles={styles.colPadding}
                >
                  <Text
                    id={`${parent_id}_laborder_Start_Date_text`}
                    className="pc_regular"
                    inLineStyles={styles.labelStyle}
                  >
                    {"Start Date"} &nbsp;
                    <Text
                      id={`${parent_id}_laborder_Start_Date_star_text`}
                      style={styles.star}
                    >
                      *
                    </Text>
                  </Text>
                  <Div
                    id={`${parent_id}_laborder_Start_Date_div`}
                    className={classes.dateWrapper}
                  >
                    <Text
                      id={`${parent_id}_laborder_${state?.startDate}_text`}
                      className="pc_regular"
                      style={styles.dateText}
                      name={false}
                    >
                      {state?.startDate}
                    </Text>
                    <Text
                      id={`${parent_id}_laborder_CalendarIcon_text`}
                      name={false}
                      style={styles.calenderText}
                      aria-describedby={id}
                      onClick={(event) => handleClick(1, event?.currentTarget)}
                    >
                      <CalendarIcon
                        id={`${parent_id}_laborder_CalendarIcon`}
                        color={UIColor?.secondaryText?.color}
                        width={"16"}
                        height={"16"}
                      />
                    </Text>
                  </Div>
                  {/* {state?.error?.recurrentMode && (
                    <span
                      id={`${parent_id}_laborder_start_Is_required_span`}
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginBottom: "24px !important",
                      }}
                    >
                      Is required !
                    </span>
                  )} */}
                </Col>

                <Col
                  id={`${parent_id}_laborder_End_Date_col`}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  inLineStyles={styles.colPadding}
                >
                  <Text
                    id={`${parent_id}_laborder_End_Date_text`}
                    className="pc_regular"
                    inLineStyles={styles.labelStyle}
                  >
                    {"End Date"}
                  </Text>
                  <Div
                    id={`${parent_id}_laborder_End_Date_div`}
                    className={classes.dateWrapper}
                  >
                    <Text
                      id={`${parent_id}_laborder_${state?.endDate}_text`}
                      name={false}
                      className="pc_regular"
                      style={styles.dateText}
                    >
                      {state?.endDate}
                    </Text>
                    <Text
                      id={`${parent_id}_laborder_end_CalendarIcon_text`}
                      style={styles.calenderText}
                      aria-describedby={id}
                      onClick={(event) => handleClick(2, event?.currentTarget)}
                    >
                      <CalendarIcon
                        id={`${parent_id}_laborder_end_CalendarIcon`}
                        color={UIColor?.secondaryText?.color}
                        width={"16"}
                        height={"16"}
                      />
                    </Text>
                  </Div>
                </Col>
              </Row>
            )}
            {/* {state?.error?.recurrentData && (
              <span
                id={`${parent_id}_laborder_Recurrent_is_required_span`}
                style={{
                  fontSize: "12px",
                  color: "red",
                  marginBottom: "24px !important",
                }}
              >
                Recurrent is required !
              </span>
            )} */}
          </Col>

          {!state?.recurrentMode && (
            <>
              <Col
                id={`${parent_id}_treatment_procedure_Start_Date_col`}
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                inLineStyles={styles.colPadding}
              >
                <Text
                  id={`${parent_id}_treatment_procedure_Start_Date_text`}
                  className="pc_regular"
                  inLineStyles={styles.labelStyle}
                >
                  {"Start Date"} &nbsp;
                  <Text style={styles.star}>*</Text>
                </Text>
                <Div
                  id={`${parent_id}_treatment_procedure_Start_Date_div`}
                  className={classes.dateWrapper}
                  style={{
                    border:
                      isErr && !state?.startDate
                        ? `1px solid ${UIColor?.error?.color}`
                        : `1px solid ${UIColor?.lineBorderFill?.color}`,
                  }}
                >
                  <Text
                    id={`${parent_id}_treatment_procedure_Start_Date_text`}
                    className="pc_regular"
                    style={styles.dateText}
                    name={false}
                  >
                    {state?.startDate}
                  </Text>
                  <Text
                    id={`${parent_id}_treatment_procedure_CalendarIcon_text`}
                    name={false}
                    style={styles.calenderText}
                    aria-describedby={id}
                    onClick={(event) => handleClick(1, event?.currentTarget)}
                  >
                    <CalendarIcon
                      id={`${parent_id}_treatment_procedure_CalendarIcon`}
                      color={UIColor?.secondaryText?.color}
                      width={"16"}
                      height={"16"}
                    />
                  </Text>
                </Div>
              </Col>

              <Col
                id={`${parent_id}_treatment_procedure_End_Date_col`}
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                inLineStyles={styles.colPadding}
              >
                <Text
                  id={`${parent_id}_treatment_procedure_End_Date_text`}
                  className="pc_regular"
                  inLineStyles={styles.labelStyle}
                >
                  {"End Date"}
                </Text>
                <Div
                  id={`${parent_id}_treatment_procedure_End_Date_div`}
                  className={classes.dateWrapper}
                >
                  <Text
                    id={`${parent_id}_treatment_procedure_End_Date_text`}
                    name={false}
                    className="pc_regular"
                    style={styles.dateText}
                  >
                    {state?.endDate}
                  </Text>
                  <Text
                    id={`${parent_id}_treatment_procedure_End_Date_CalendarIcon_text`}
                    style={styles.calenderText}
                    aria-describedby={id}
                    onClick={(event) => handleClick(2, event?.currentTarget)}
                  >
                    <CalendarIcon
                      id={`${parent_id}_treatment_procedure_End_Date_CalendarIcon`}
                      color={UIColor?.secondaryText?.color}
                      width={"16"}
                      height={"16"}
                    />
                  </Text>
                </Div>
              </Col>
            </>
          )}

          <Col
            id={`${parent_id}_treatment_procedure_Select_Diagnosis_col`}
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            inLineStyles={styles.colPadding}
          >
            <Text
              id={`${parent_id}_treatment_procedure_Select_Diagnosis_text`}
              className="pc_regular"
              inLineStyles={styles?.label}
            >
              Select Diagnosis &nbsp;{" "}
              <span
                id={`${parent_id}_treatment_procedure_Select_Diagnosis_star_span`}
                style={{ color: UIColor?.error?.color }}
              >
                *
              </span>
            </Text>
            <Autocomplete
              id={`${parent_id}_treatment_procedure_Select_Diagnosis_autocomplete`}
              className={classes.autocomplete}
              size="small"
              ChipProps={{
                className: classes.chip,
                deleteIcon: (
                  <CloseIcon
                    style={{ color: "#6A7888", width: "12px", height: "12px" }}
                  />
                ),
              }}
              // options={props?.teartment_diagnosis?.data ?? []}
              options={LabOrderApiSlice?.getdiagnosis?.data ?? []}
              disableClearable
              multiple
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  id={`${parent_id}_treatment_procedure_Select_Diagnosis_textfield`}
                  {...params}
                  variant="outlined"
                  placeholder="select diagnosis"
                  error={
                    isErr && state.selectDiagnosis.length === 0 ? true : false
                  }
                />
              )}
              renderOption={(option) => (
                <Typography
                  id={`${parent_id}_treatment_procedure_${option.label.replaceAll(
                    " ",
                    "_"
                  )}_typography`}
                  className="pc_regular"
                  style={styles?.option}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              value={state?.selectDiagnosis ?? []}
              onChange={(e, newValue) =>
                onChangeState("selectDiagnosis", newValue)
              }
            />
          </Col>
          <Col
            id={`${parent_id}_treatment_procedure_Indication_Reason_col`}
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            inLineStyles={styles.colPadding}
          >
            <Text
              id={`${parent_id}_treatment_procedure_Indication_Reason_text`}
              className="pc_regular"
              inLineStyles={styles?.label}
            >
              Indication (Reason) &nbsp;{" "}
              <span
                id={`${parent_id}_treatment_procedure_Indication_Reason_star_span`}
                style={{ color: UIColor?.error?.color }}
              >
                *
              </span>
            </Text>
            <Autocomplete
              id={`${parent_id}_treatment_procedure_Indication_Reason_autocomplete`}
              className={classes.autocomplete}
              size="small"
              // options={props?.teartment_indicate?.data ?? []}
              options={FlterOptions3("INDICATIONREASON") ?? []}
              // disableClearable
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  id={`${parent_id}_treatment_procedure_Indication_Reason_textfield`}
                  {...params}
                  variant="outlined"
                  placeholder="select indication"
                  error={
                    isErr &&
                    !state.indication.label &&
                    JSON.stringify(state.indication) !== "{}"
                      ? true
                      : false
                  }
                />
              )}
              renderOption={(option) => (
                <Typography
                  id={`${parent_id}_treatment_procedure_${option.label.replaceAll(
                    " ",
                    "_"
                  )}_typography`}
                  className="pc_regular"
                  style={styles?.option}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              value={state.indication}
              onChange={(e, newValue) => onChangeState("indication", newValue)}
            />
          </Col>

          <Col
            id={`${parent_id}_treatment_procedure_patientInstruction_col`}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            inLineStyles={styles.colPadding}
          >
            <SingleSelectCard
              parent_id={"treatment_procedure"}
              label="Patient Instructions"
              placeholder="Select Patient instructions"
              contentPlaceHolder="Instructions"
              valuedata={state?.patientInstruction}
              value={state?.patientInstruction?.value}
              content={state?.patientInstruction?.content}
              //options={props?.teartment_instuction?.data ?? []}
              options={FlterOptions3("PATINSTRUCTION") ?? []}
              onChangeValue={(data, ts) =>
                onChangeState("patientInstruction", data, ts)
              }
              isRequired={false}
            />
          </Col>

          <Col
            id={`${parent_id}_treatment_procedure_Notes_col`}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            inLineStyles={styles.colPadding}
          >
            <TextInput
              id={`${parent_id}_treatment_procedure_Notes_textinput`}
              variant={"outlined"}
              hoverColor={UIColor?.lineBorderFill?.color}
              labelStyle={styles.labelStyle}
              style={styles.borderStyle}
              label="Notes"
              placeholder="Enter Notes"
              value={state?.notes}
              onChange={(data) => onChangeState("notes", data.target.value)}
            />
          </Col>

          <Col
            id={`${parent_id}_treatment_procedure_preparatory_col`}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            inLineStyles={styles.colPadding}
          >
            <SingleSelectCard
              parent_id={"treatment_procedure"}
              label="Preparatory instructions"
              placeholder="Select Preparatory instructions"
              contentPlaceHolder="Instructions"
              value={state?.preparatoryInstruction?.value}
              content={state?.preparatoryInstruction?.content}
              valuedata={state?.preparatoryInstruction}
              // options={props.treatment_pre_instrucation?.data ?? []}
              options={FlterOptions3("PREINSTRUCTION")  ?? []}
              onChangeValue={(data, ts) =>
                onChangeState("preparatoryInstruction", data, ts)
              }
              isRequired={false}
            />
          </Col>

          <Col
            id={`${parent_id}_treatment_procedure_departmental_col`}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            inLineStyles={styles.colPadding}
          >
            <SingleSelectCard
              parent_id={"treatment_procedure"}
              label="Departmental instructions"
              placeholder="Select Departmental instructions"
              contentPlaceHolder="Instructions"
              value={state?.departmentalInstruction?.value}
              content={state?.departmentalInstruction?.content}
              valuedata={state?.departmentalInstruction}
              // options={props.treatment_dep_instrucation?.data ?? []}
              options={FlterOptions3("DEPINSTRUCTION")  ?? []}
              onChangeValue={(data, ts) =>
                onChangeState("departmentalInstruction", data, ts)
              }
              isRequired={false}
            />
          </Col>
          <Col
            id={`${parent_id}_treatment_procedure_requestorDetails_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.colPadding}
          >
            <RequestorDetailsForm
              parent_id={"treatment_procedure"}
              requestorDetails={state?.requestorDetails}
              title="Requestor Details"
              action="+ Add Requestor Details"
              orgId={props?.fetchUser?.orgId}
              //organizationOption={props.treatment_organization?.data ?? []}
              organizationOption={
                LabOrderApiSlice?.laborderorgnnization?.data ?? []
              }
              actionButton={(data) => onChangeState("requestorDetails", data)}
            />
           
          </Col>

          <Col
            id={`${parent_id}_treatment_procedure_performerDetails_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.colPadding}
          >
            <PerformerDetails
              Oltype={"TREAT"}
              parent_id={"treatment_procedure"}
              performerDetails={state?.performerDetails}
              title="Performer Details"
              action="+ Add Performer"
              labDetails={[state]}
              patientgetData={props.patientgetData}
              fetchUser={props.fetchUser}
              // organizationOption={props.treatment_organization?.data ?? []}
              organizationOption={
                LabOrderApiSlice?.laborderorgnnization?.data ?? []
              }
              actionButton={(data) => onChangeState("performerDetails", data)}
              orgId={props?.fetchUser?.orgId}
              Status={FlterOptions2("RADSTATUS")}
            />
            {/* {(state?.error?.performerDetails) && <span
              style={{
                fontSize: '12px',
                color: 'red',
                marginBottom: "24px !important",
              }}>Is required !</span>} */}
          </Col>
        </Row>
      </div>
      {/* Footer */}
      <Row
        id={`${parent_id}_treatment_procedure_buttons_row`}
        inLineStyles={{ background: "#fff", borderRadius: "10px" }}
      >
        <Col
          id={`${parent_id}_treatment_procedure_buttons_col`}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ margin: "10px 0px" }}
        >
          <Div
            id={`${parent_id}_treatment_procedure_buttons_div`}
            inLineStyles={styles.flexEnd}
          >
            <Button
              id={`${parent_id}_laborder_Cancel_button`}
              onClick={() => goBack()}
              variant={"text"}
              inLineStyles={styles.cancelBtn}
            >
              Cancel
            </Button>
            <Button
              id={`${parent_id}_laborder_Update_button`}
              variant={"outlined"}
              inLineStyles={styles.filledBtn}
              onClick={() => handleUpdate()}
            >
              Update
            </Button>
          </Div>
        </Col>
      </Row>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.muiPopovers }}
      >
        {index === 0 && (
          <Div
            id={`${parent_id}_treatment_procedure_Recurrent_div`}
            style={{ padding: 15 }}
          >
            <Recurrent
              parent_id={"lab_order"}
              recurrentData={state?.recurrentData}
              saveButtonAction={(data) => {
                // onChangeState('recurrentData', data)
                // setState({
                //   ...state, recurrentMode: true, recurrentData: data,
                // });

                let dArr = getReccuranceDates(
                  moment(data?.startsOn?.dateTime).format("DD-MM-YYYY"), // DD-MM-YYYY
                  data?.endsAfter?.endsAfter ? "NoOfDays" : "Calendar", // [by => Calendar, on => NoOfDays]
                  data?.endsAfter?.endsAfter
                    ? JSON.parse(data?.endsAfter?.endsAfter)
                    : moment(data?.endsAfter?.endsBy).format("DD-MM-YYYY"), // // [by => Date(DD-MM-YYYY), on => number(5)]
                  data?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
                  JSON.parse(data?.every?.value), // number
                  [], // []
                  [] // []
                );
                let dataList = dArr?.[0]?.map((v, i) => {
                  return {
                    date: moment.unix(v).format("YYYY-MM-DD,hh:mm A"),
                    showid: i,
                    id: null,
                    status: "done",
                  };
                });
                delete state.error["recurrentData"];
                setState({
                  ...state,
                  recurrentMode: true,
                  recurrentData: data,
                  recurrentList: dataList,
                });
                handleClose();
              }}
              deleteButtonAction={() => {
                handleClose();
                onChangeState("recurrentMode", false);
              }}
              width={500}
            />
          </Div>
        )}

        {(index === 1 || index === 2) && (
          // <DatePicker
          //   id={`${parent_id}_laborder_Recurrent_DatePicker`}
          //   timeInputLabel="Time:"
          //   dateFormat="MM/dd/yyyy h:mm aa"
          //   showTimeInput
          //   // selected={this.state.dob.value}
          //   selected={new Date()}
          //   // showPopperArrow={false}
          //   // showYearDropdown
          //   inline
          //   onChange={(date) => {
          //     onChangeState(
          //       index === 1 ? "startDate" : "endDate",
          //       moment(date).format("YYYY-MM-DD,hh:mm A")
          //     );
          //     handleClose();
          //   }}
          // // placeholderText="DD-MM-YYYY"
          // // maxDate={new Date()}
          // // className={"pc_regular"}
          // />
          <CustomDatePicker
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            selectedDate={
              index === 1
                ? state["startDate"]
                  ? new Date(state["startDate"])
                  : null
                : state["endDate"]
                  ? new Date(state["endDate"])
                  : null
            }
            minDate={index === 1 ?new Date(state["initailDate"]) : new Date(state["startDate"])}
            minTime={index === 1 ? getStartEndMinTime(state.startDate,"startDate",state?.initailDate,state.startDate) : getStartEndMinTime(state.endDate,"endDate",state?.initailDate,state.startDate)}
            maxTime={setHours(setMinutes(new Date(), 59), 23)}
            handleChange={(date, e) => {
              onChangeState(
                index === 1 ? "startDate" : "endDate",
                moment(date).format("YYYY-MM-DD,hh:mm A")
              );
              if (e?.target === undefined) {
                handleClose();
              }
            }}
          />
        )}

        {index === 3 && (
          <RecurrentList
            parent_id={"treatment_procedure"}
            title="Recurrent"
            recurrentList={state?.recurrentData}
            padding={10}
            width={500}
            onChangeState={(data) => onChangeState("recurrentList", data)}
          />
        )}

        {index === 4 && (
          <div style={{ padding: 10 }}>
            <Attributes
              parent_id={"treatment_procedure"}
              attributes={state?.attributes}
              onChangeState={(key, value) =>
                changeAttributes("attributes", key, value)
              }
            />
          </div>
        )}
      </Popover>
    </div>
  );
};
