import React from "react";

export const WheelChair = (props) => {
  let width = props.width ? props.width : "14";
  let height = props.height ? props.height : "17.678";
  let color = props.color ? props.color : "#101010";
  return (
    <svg 
    {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 17.678"
    >
        <defs>
            <style>{`.cls-7{fill:${color};}}`}</style>
        </defs>
      <path xmlns="http://www.w3.org/2000/svg" id="icons8-wheelchair" class="cls-7" d="M9,0a1.841,1.841,0,1,0,1.841,1.841A1.843,1.843,0,0,0,9,0ZM8.192,4.419a1.792,1.792,0,0,0-1.772,1.7V10.45a2.124,2.124,0,0,0,2.072,2.072H9.6a23.7,23.7,0,0,0,3.269-.184l1.726,3.453a.741.741,0,1,0,1.335-.645l-2.21-4.419a.732.732,0,0,0-.668-.414L10.1,9.806V6.123a1.783,1.783,0,0,0-1.7-1.7ZM5.683,7.6a5.147,5.147,0,1,0,6.56,5.662H10.77A3.71,3.71,0,0,1,7.156,16.2a3.646,3.646,0,0,1-3.683-3.683,3.715,3.715,0,0,1,2.21-3.384Z" transform="translate(-2)"/>
      </svg>
  );
};