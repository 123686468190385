import React from "react";

export const Location = (props) => {
  let width = props.width ? props.width : "13.991";
  let height = props.height ? props.height : "14";
  let color = props.color ? props.color : "#707070";
  return (
    <svg 
    {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13.991 14"
    >
        <defs>
            <style>{`.cls-7{fill:${color};}}`}</style>
        </defs>
        <path id="icons8-target" class="cls-7" d="M8.986,1.973a.636.636,0,0,0-.627.645v.995A5.416,5.416,0,0,0,3.631,8.341H2.636a.636.636,0,1,0,0,1.272h.995a5.416,5.416,0,0,0,4.729,4.729v.995a.636.636,0,1,0,1.272,0v-.995A5.416,5.416,0,0,0,14.36,9.613h.995a.636.636,0,1,0,0-1.272H14.36A5.416,5.416,0,0,0,9.631,3.612V2.617a.636.636,0,0,0-.645-.645ZM8.92,4.847a.636.636,0,0,0,.155,0A4.12,4.12,0,0,1,13.125,8.9a.636.636,0,0,0,0,.155,4.12,4.12,0,0,1-4.057,4.051.561.561,0,0,0-.15,0A4.12,4.12,0,0,1,4.865,9.053a.636.636,0,0,0,0-.155A4.12,4.12,0,0,1,8.92,4.847ZM9,7.7a1.272,1.272,0,1,0,1.272,1.272A1.274,1.274,0,0,0,9,7.7Z" transform="translate(-2 -1.972)"/>
      </svg>
  );
};
