import {
  WorkList,
  CloseConsultation,
  DocumentIcon,
  DashboardIcon,
} from "../../svg";
import { AssignPractitioner } from "../../svg/components/assignPractitioner";
import { ReAssignPractitioner } from "../../svg/components/reassignPractitioner";
import { AppRoutes } from "../../../router/routes";
import { checkWithCasbin } from "../../../utils";

export const drawerData = {
  sideMenuDashboard: {
    icon: <DashboardIcon />,
    label: "Dashboard",
    // link: AppRoutes.dashboardLanding,
    children: [
      {
        icon: <DashboardIcon />,
        label: "Main Dashboard",
        backgroundColor: "#F6EFF8",
        color: "#AA62BA",
        // link:AppRoutes.dashboardLanding,
        key_: "mainMenu",
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "HQ Dashboard",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:process.env.REACT_APP_HQ_DASHBOARD,
        key_: "HqDashboard",
        style: {
          cursor: "auto",
        },
      },
    ],
  },
  worklist: {
    icon: <WorkList />,
    label: "Work List",
    children: [
      {
        icon: <AssignPractitioner />,
        label: "Assign Practitioner",
        backgroundColor: "#F6EFF8",
        color: "#AA62BA",
        link: AppRoutes.workList,
        key_: "assignPractitionerW",
      },
      {
        icon: <ReAssignPractitioner />,
        label: "Re-Assign Practitioner",
        backgroundColor: "#EFF2F9",
        color: "#5F84C0",
        link: AppRoutes.workList,
        key_: "reassignPractitionerW",
      },
      {
        icon: <CloseConsultation />,
        label: "Close Consultation",
        backgroundColor: "#F7EFEA",
        color: "#AD6338",
        link: AppRoutes.workList,
        key_: "closeConsultationW",
      },
      {
        icon: <DocumentIcon />,
        label: "Day End Process",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: AppRoutes.dayEndProcess,
        key_: "dayEndProcessW",
      },
    ],
  },
  billingCashier: {
    icon: <WorkList />,
    label: "Biller and Cashier",
  },
  bulkbooking: {
    icon: <WorkList />,
    label: "Bulk Booking",
    link: AppRoutes.bulkBooking,
  },
  
  // mainMenu: {
  //   icon: <MenuIcon />,
  //   label: 'Menu name',
  //   children: [

  //   ]
  // },
  reports: {
    icon: <WorkList />,
    label: "Reports",
    children: [
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Cash Collection",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        key_: "Cash Collection Summary report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "PIC Review Sheet Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        key_: "PIC Review Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Bill Register Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_BILL_REGISTER_REPORT,
        key_: "BillRegister",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Nursing Procedure Summary Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
         process.env.REACT_APP_NURSING_PROCEDURE_SUMMARY_REPORT,
        key_: "NursingProcedureSummary",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Patient Review Chart Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        key_: "Patient Review Chart Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Claim Summary Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_CLAIM_SUMMARY_REPORT,
        key_:"Claim Summary Report",
        style: {
          cursor: "auto",
        },
      },
      // {
      //   container: {
      //     display: "flex",
      //     alignItems: "center",
      //   },
      //   icon: "",
      //   label: "Active Patient Report",
      //   backgroundColor: "#FFF2E5",
      //   color: "#FF9F40",
      //   link: "",
      //   ilink:
      //     process.env.REACT_APP_ACTIVE_PATIENT_REPORT,
      //   key_: "activePatientReport",
      //   style: {
      //     cursor: "auto",
      //   },
      // },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Patient Attendance Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_ATTENDANCE_REPORT,
        key_: "attendanceReport1",
        style: {
          cursor: "auto",
        },
      },{
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Month wise Attendance Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_MONTHWISE_ATTENDANCE_REPORT,
        key_: "Monthwise Attendance Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Patient Attendance Summary Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_PATIENT_ATTENDANCE_SUMMARY_REPORT,
        key_: "PatientattendanceSummary",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Observation Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink: "",
        key_: "Obervation Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "EPO Consumption Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:"",
        key_: "EPO Consumption Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Panel Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_PANEL_REPORT,
        key_: "Panel Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Patient Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_PATIENT_REPORT,
        key_: "Patient Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Cancelled and No Show Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_CANCEL_NOSHOW_REPORT,
        key_: "Cancelled and No Show Report",
        style: {
          cursor: "auto",
        },
      },
    ],
  },
};

export async function getDrawerData(setState, props) {
  let permission = await checkWithCasbin(["sideMenu"]);
  let arr = [];

  var unique = [...permission.read, ...permission.write].filter(onlyUnique);
  unique.map((val) => {
    //WORK LIST MENU CONSTRUCT
    if (val === "worklist") {
      let obj = JSON.parse(JSON.stringify(drawerData[val]));
      obj.children = [];
      unique.map((perVal) => {
        drawerData[val].children.filter((v, i) => {
          if (v.key_ === perVal) {
            obj.children.push(drawerData[val].children[i]);
          }
        });
      });
      arr.push(obj);
    } else if (val === "reports") {
      let obj = JSON.parse(JSON.stringify(drawerData[val]));
      obj.children = [];
      unique.map((perVal) => {
        drawerData[val].children.filter((v, i) => {
          if (v.key_ === perVal) {
            obj.children.push(drawerData[val].children[i]);
          }
        });
      });
      arr.push(obj);
    } else if (val === "sideMenuDashboard") {
      let obj = JSON.parse(JSON.stringify(drawerData[val]));
      obj.children = [];
      unique.map((perVal) => {
        drawerData[val].children.filter((v, i) => {
          if (v.key_ === perVal) {
            obj.children.push(drawerData[val].children[i]);
          }
        });
      });
      arr.push(obj);
    }else {
      if (drawerData[val]) {
        arr.push(drawerData[val]);
      }
    }
    // arr.push(drawerData[val])
  });
  setState({
    permissons: permission,
    drawerDataList: arr,
    pic: props?.loggedUserInfo?.data?.photo ?? "",
  });
}

export const giveMeSelectedTab = (props) => {
  let id = "";
  const { navBarArr } = props;
  navBarArr.map((nav) => {
    if (window.location.pathname === nav.link) {
      id = nav.id;
    }
    return nav;
  });
  return id;
};

export const clickHome = (props, navigate) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  //props.history.push(AppRoutes.dashboard);

  //isFullScreen();
  navigate(AppRoutes.dashboard);
};
export const handleProfileClose = (state, setState) => {
  setState({
    ...state,
    anchorEl: null,
  });
};

const redirectLink = (data, props) => {
  if (data.id !== giveMeSelectedTab()) props.history.push(data.link);
};

export const changeState = (key, value, state, setState) => {
  setState({
    ...state,
    [key]: value,
  });
};

export const handleProfileClick = (event, state, setState) => {
  setState({
    ...state,
    anchorEl: event.currentTarget,
  });
};

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
