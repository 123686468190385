import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import TrashIcon from "../../../../../../assets/img/svg/trashIcon";
import CreateIcon from "../../../../../../assets/img/svg/createIcon";
const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #E0E0E0",
    background: theme.palette.background.default,
    position: "relative",
    display: "flex",
    gap: 16,
    alignItems: "center",
    padding: "12px 16px",
    borderRadius: 8,
  },
  number: {
    background: theme.palette.background.tableHeader,
    width: 23,
    minWidth: 23,
    height: 26,
    minHeight: 26,
    position: "absolute",
    left: 0,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 23,
  },
  doseContainer: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  dose: {
    color: "#6F6F6F",
    fontSize: 12,
  },
  actionButton: {
    display: "flex",
    gap: 12,
    alignItems: "center ",
  },
}));

function AdditiveItem(props) {
  // <-----------------Props---------------->
  const { index, data, onEditClick, onDeleteClick } = props;

  // <------------------Hooks--------------->
  const classes = useStyles(props);

  // <---------------Store States----------->

  // <-------------Component States--------->

  // <-----------------Handlers------------->

  // <-----------------Effects-------------->

  // <-----------------Others--------------->

  // <-----------------Render--------------->
  return (
    <div className={classes.root}>
      <div className={classes.number}>
        <Typography>{index + 1}</Typography>
      </div>
      <Typography className={classes.name}>
        {data?.addrugcode?.label}
      </Typography>
      <div className={classes.doseContainer}>
        <Typography className={classes.dose}>
          {[data?.addose, data?.addoseuom?.label]?.join(" ")}
        </Typography>
        {/* <Dot />
        <Typography className={classes.dose}>50ml</Typography> */}
      </div>
      <div style={{ flex: 1 }}></div>
      <div className={classes.actionButton}>
        <IconButton size="small" onClick={onDeleteClick}>
          <TrashIcon />
        </IconButton>
        <IconButton size="small" onClick={onEditClick}>
          <CreateIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default AdditiveItem;

AdditiveItem.defaultProps = {};

AdditiveItem.propTypes = {};
