import { Grid, Typography, withStyles } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { History } from "@material-ui/icons";
import { UIColor } from "../../themes/theme";

const Accordion = withStyles({
  root: {
    borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: "10px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    width: "100%", // Ensure full width
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: "0 0 10px 10px",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const HistoryAccordion = ({ children }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Accordion square elevation={0} >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} 
          style={{borderBottom: "1px solid",borderColor: UIColor.lineBorderFill.color,borderRadius: "0 0 5px 5px"}}>
            <History
              style={{ height: "21px", color: UIColor.tertiaryText.color }}
            />
            <Typography
              variant="subtitle2"
              style={{
                fontSize: 15,
                fontWeight: 600,
                color: UIColor.tertiaryText.color,
                marginLeft: "3px",
              }}
            >
              History
            </Typography>

          </AccordionSummary>

          <AccordionDetails >
           <div style={{ width: "100%" }}>
           
           {children}
           
           </div>
              
            
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default HistoryAccordion;
