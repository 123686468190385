import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  urls,
  defaultState,
  defaultReject,
  fetchData,
  query_ids,
  dbName,
  registrationRequests,
  queries,
  _lang_,
  __tenantid__,
  axiosCall,
  getFacilityId,
} from "../../utils";
const { GetAllMastersRequest, PayerTypeRequest } = registrationRequests;
const {
  GetPayerQID,
  GetGuarantorTypeQID,
  GetGuarantorIDQID,
  GetPlanQID,
  GetFacilityNameID,
  GetserviceRequestedID,
  GetPractitionerNameID,
  GetSponserNameID,
} = query_ids;

const GET_ALL_MASTERS = createAsyncThunk(
  `registrationSlice/GetAllMasters`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        GetAllMastersRequest(payload),
        urls.readQdmQueries
      );

      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const PAYER_TYPE = createAsyncThunk(
  `registrationSlice/payerType`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        PayerTypeRequest(payload),
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_PAYER = createAsyncThunk(
  `registrationSlice/payer`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { payerType = " " } = payload;
      const body = {
        db_name: dbName,
        filter: {
          tenantid: "",
          payertype: payerType,
          facilityid: "",
          lang: "",
        },
        queryid: GetPayerQID,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_GUARANTOR_TYPE = createAsyncThunk(
  `registrationSlice/guarantortype`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { payerType = " " } = payload;
      const body = {
        db_name: dbName,
        filter: {
          payertype: payerType,
          tenantid: "",
          facilityid: "",
          lang: "",
        },
        queryid: GetGuarantorTypeQID,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_GUARANTOR_ID = createAsyncThunk(
  `registrationSlice/guarantorid`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { payerType = " " } = payload;
      const body = {
        db_name: dbName,
        filter: {
          tenantid: "",
          payertype: payerType,
          facilityid: "",
          lang: "",
        },
        queryid: GetGuarantorIDQID,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const Get_Plan = createAsyncThunk(
  `registrationSlice/plan`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { payerId = " " } = payload;
      const body = {
        db_name: dbName,
        filter: {
          payerid: payerId,
          tenantid: "",
          facilityid: "",
          lang: "",
        },
        queryid: GetPlanQID,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_SPONSER_NAME = createAsyncThunk(
  `registrationSlice/sponsername`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patientId = " " } = payload;
      const body = {
        db_name: dbName,
        filter: {
          patientid: patientId,
        },
        queryid: GetSponserNameID,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const Get_Facility_Name = createAsyncThunk(
  `registrationSlice/facilityName`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      // const { payerId = " " } = payload;
      const body = {
        db_name: dbName,
        queryid: GetFacilityNameID,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const Get_Service_Requested = createAsyncThunk(
  `registrationSlice/serviceRequested  `,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: GetserviceRequestedID,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const Get_Practitioner_Name = createAsyncThunk(
  `registrationSlice/practitionerName  `,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantId = " ", facilityId = " " } = payload;
      const body = {
        db_name: dbName,
        filter: {
          tenantid: "",
          facilityid: "",
        },
        queryid: GetPractitionerNameID,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const PATIENT_CATEGORY_DIALYSIS = createAsyncThunk(
  `registrationSlice/patientcategorydialysis`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          type: [
            "REGISTRATIONTYPE",
            "CURRENCY",
            "DISTANCEUNIT",
            "PATIENTCATEGORY",
            "TRANSPORTREQD",
            "HEPATITIS",
            "OTHERHEALTHCONDITIONS",
          ],
          tenantid: __tenantid__,
          facilityid: getFacilityId(),
          lang: _lang_,
        },
        queryid: query_ids?.getPatientCategoryDialysis,
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const FETCH_MY_KAD_DETAILS = createAsyncThunk(
  "registrationSlice/fetch_mykad",
  async (payload = {}, { rejectWithValue }) => {
    try {
      let url = process.env.REACT_APP_MYKAD_URL;
      const res = await fetch(url);
      if (!res.ok) {
        return rejectWithValue({
          ...defaultReject,
          message: res.statusText,
        });
      }
      const data = await res.json();
      if (data.ERR_CODE !== "OK") {
        return rejectWithValue({
          ...defaultReject,
          message: "Something went wrong!",
        });
      }
      return {
        ...defaultState.Info,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const initialRegistrationState = {
  getAllMasters: {
    ...defaultState.Info,
  },

  payerType: {
    ...defaultState.Info,
  },
  payer: {
    ...defaultState.Info,
  },
  guarantortype: {
    ...defaultState.Info,
  },
  guarantorid: {
    ...defaultState.Info,
  },
  plan: {
    ...defaultState.Info,
  },
  sponsername: {
    ...defaultState.Info,
  },
  fetch_mykad: {
    ...defaultState.Info,
  },
};

const registrationSlice = createSlice({
  name: "registrationSlice",
  initialState: initialRegistrationState,
  extraReducers: {
    [GET_ALL_MASTERS.fulfilled]: (state, action) => {
      state.getAllMasters = action?.payload ?? [];
    },
    [GET_ALL_MASTERS.pending]: (state, action) => {
      state.getAllMasters.loading = true;
    },
    [GET_ALL_MASTERS.rejected]: (state, action) => {
      state.getAllMasters = action?.payload ?? [];
    },

    /* GET_PAYER TYPE */
    [PAYER_TYPE.fulfilled]: (state, action) => {
      state.payerType = action?.payload ?? {};
    },
    [PAYER_TYPE.pending]: (state, action) => {
      state.payerType.loading = true;
    },
    [PAYER_TYPE.rejected]: (state, action) => {
      state.payerType = action?.payload ?? {};
    },

    /* PATIENT_CATEGORY_DIALYSIS*/
    [PATIENT_CATEGORY_DIALYSIS.fulfilled]: (state, action) => {
      state.patientcategorydialysis = action?.payload ?? {};
    },
    [PATIENT_CATEGORY_DIALYSIS.pending]: (state, action) => {
      state.patientcategorydialysis.loading = true;
    },
    [PATIENT_CATEGORY_DIALYSIS.rejected]: (state, action) => {
      state.patientcategorydialysis = action?.payload ?? {};
    },

    /* GET_PAYER */
    [GET_PAYER.fulfilled]: (state, action) => {
      state.payer = action?.payload ?? {};
    },
    [GET_PAYER.pending]: (state, action) => {
      state.payer.loading = true;
    },
    [GET_PAYER.rejected]: (state, action) => {
      state.payer = action?.payload ?? {};
    },
    /* GUARANTOR TYPE */
    [GET_GUARANTOR_TYPE.fulfilled]: (state, action) => {
      state.guarantortype = action?.payload ?? {};
    },
    [GET_GUARANTOR_TYPE.pending]: (state, action) => {
      state.guarantortype.loading = true;
    },
    [GET_GUARANTOR_TYPE.rejected]: (state, action) => {
      state.guarantortype = action?.payload ?? {};
    },
    /* GUARANTOR ID */
    [GET_GUARANTOR_ID.fulfilled]: (state, action) => {
      state.guarantorid = action?.payload ?? {};
    },
    [GET_GUARANTOR_ID.pending]: (state, action) => {
      state.guarantorid.loading = true;
    },
    [GET_GUARANTOR_ID.rejected]: (state, action) => {
      state.guarantorid = action?.payload ?? {};
    },
    /*PLAN*/
    [Get_Plan.fulfilled]: (state, action) => {
      state.plan = action?.payload ?? {};
    },
    [Get_Plan.pending]: (state, action) => {
      state.plan.loading = true;
    },
    [Get_Plan.rejected]: (state, action) => {
      state.plan = action?.payload ?? {};
    },
    /*facilityName*/
    [Get_Facility_Name.fulfilled]: (state, action) => {
      state.facilityName = action?.payload ?? {};
    },
    [Get_Facility_Name.pending]: (state, action) => {
      state.facilityName.loading = true;
    },
    [Get_Facility_Name.rejected]: (state, action) => {
      state.facilityName = action?.payload ?? {};
    },
    /*serviceRequested*/
    [Get_Service_Requested.fulfilled]: (state, action) => {
      state.serviceRequested = action?.payload ?? {};
    },
    [Get_Service_Requested.pending]: (state, action) => {
      state.serviceRequested.loading = true;
    },
    [Get_Service_Requested.rejected]: (state, action) => {
      state.serviceRequested = action?.payload ?? {};
    },
    /*practitionerName*/
    [Get_Practitioner_Name.fulfilled]: (state, action) => {
      state.practitionerName = action?.payload ?? {};
    },
    [Get_Practitioner_Name.pending]: (state, action) => {
      state.practitionerName.loading = true;
    },
    [Get_Practitioner_Name.rejected]: (state, action) => {
      state.practitionerName = action?.payload ?? {};
    },
    /*sponsername*/
    [GET_SPONSER_NAME.fulfilled]: (state, action) => {
      state.sponsername = action?.payload ?? {};
    },
    [GET_SPONSER_NAME.pending]: (state, action) => {
      state.sponsername.loading = true;
    },
    [GET_SPONSER_NAME.rejected]: (state, action) => {
      state.sponsername = action?.payload ?? {};
    },
     /*FETCH_MY_KAD_DETAILS*/
     [FETCH_MY_KAD_DETAILS.fulfilled]: (state, action) => {
      state.fetch_mykad = action?.payload ?? {};
    },
    [FETCH_MY_KAD_DETAILS.pending]: (state, action) => {
      state.fetch_mykad.loading = true;
    },
    [FETCH_MY_KAD_DETAILS.rejected]: (state, action) => {
      state.fetch_mykad = action?.payload ?? {};
    },
  },
});

export const registrationActions = {
  GET_ALL_MASTERS,
  PAYER_TYPE,
  GET_PAYER,
  GET_GUARANTOR_TYPE,
  GET_GUARANTOR_ID,
  Get_Plan,
  Get_Facility_Name,
  Get_Service_Requested,
  Get_Practitioner_Name,
  PATIENT_CATEGORY_DIALYSIS,
  GET_SPONSER_NAME,
  FETCH_MY_KAD_DETAILS,
};

export default registrationSlice;