import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  defaultReject,
  defaultState,
  fetchData,
  query_ids,
  slotRequest,
  urls,
} from "../../utils";
import jwtDecode from "jwt-decode";

// const UPSERT_SLOT = createAsyncThunk(
//   `slotApiSlice/upsertSlot`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const { id = "", name = "", icon = "", filter = "" } = payload;
//       const body = [
//         {
//           db_name: dbName,
//           entity: "Slot",
//           doc: {
//             resourcetype: "Slot",
//             id: "example",
//             text: {
//               status: "generated",
//               div: '<div xmlns="http://www.w3.org/1999/xhtml">\n\t\t\t25 Dec 2013 9:15am - 9:30am: <b>Busy</b> Physiotherapy\n\t\t</div>',
//             },
//             rule: {
//               properties: {
//                 serviceCategory: [
//                   {
//                     coding: [
//                       {
//                         code: "17",
//                         display: "General Practice",
//                       },
//                     ],
//                   },
//                 ],
//                 serviceType: [
//                   {
//                     coding: [
//                       {
//                         code: "57",
//                         display: "Immunization",
//                       },
//                     ],
//                   },
//                 ],
//                 specialty: [
//                   {
//                     coding: [
//                       {
//                         code: "408480009",
//                         display: "Clinical immunology",
//                       },
//                     ],
//                   },
//                 ],
//                 appointmentType: {
//                   coding: [
//                     {
//                       system: "http://terminology.hl7.org/CodeSystem/v2-0276",
//                       code: "WALKIN",
//                       display: "A previously unscheduled walk-in visit",
//                     },
//                   ],
//                 },
//                 schedule: {
//                   reference: "Schedule/example",
//                 },
//                 status: "free",
//                 start: "2013-12-25T09:15:00Z",
//                 end: "2013-12-25T09:30:00Z",
//                 comment:
//                   "Assessments should be performed before requesting appointments in this slot.",
//               },
//             },
//           },
//         },
//       ];
//       if (filter) {
//         body[0]["filter"] = filter;
//       }
//       const data = await fetchData(urls.upsert_slot, {
//         body,
//       });
//       return {
//         ...defaultState.Info,
//         data,
//       };
//     } catch (err) {
//       return rejectWithValue({
//         ...defaultReject,
//         message: err.message,
//       });
//     }
//   }
// );

// const READ_SLOT = createAsyncThunk(
//   `slotApiSlice/readSlot`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const { id = "", startdate = "", enddate = "", type = "" } = payload;
//       const body = query_ids["slotApiSlice/readSlot"]["query"]({
//         start: startdate,
//         end: enddate,
//         id,
//       });
//       let isClinic = false;
//       if (type === "clinicid") {
//         isClinic = true;
//       }
//       const resp = await fetchData(
//         { body: JSON.stringify(body) },
//         __readDocumentUrl__
//       );
//       const data = resp?.result?.[0] || {};
//       const slots = data?.slots || [];
//       let healthCareId = data?.orgId || [];
//       const returnData = {
//         morning: [],
//         afternoone: [],
//         evening: [],
//         night: [],
//         allSession: [],
//         slots,
//         isClinic,
//         healthCareId,
//         resourceInfo: {
//           resourcecode: data?.resourcecode || "",
//           resourcerole: data?.resourcerole || "",
//           resourcetype: data?.resourcetype || "",
//         },
//       };
//       // filterSlots(
//       //     slots,
//       //     returnData.morning,
//       //     returnData.afternoone,
//       //     returnData.evening,
//       //     returnData.night,
//       //     returnData.allSession,
//       //     isClinic,
//       // );
//       return {
//         ...defaultState.Info,
//         data: returnData,
//       };
//     } catch (err) {
//       return rejectWithValue({
//         ...defaultReject,
//         message: err.message,
//       });
//     }
//   }
// );

// const FREE_SLOT = createAsyncThunk(
//   `slotApiSlice/freeSlot`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const { key = "" } = payload;
//       const body = {
//         db_name: dbName,
//         queryid: query_ids["slotApiSlice/freeSlot"],
//         filter: {
//           key,
//           updatedDt: Math.floor(Date.now() / 1000),
//         },
//       };
//       const data = await fetchData({ body: JSON.stringify(body) });
//       return {
//         ...defaultState.Info,
//         data,
//       };
//     } catch (err) {
//       return rejectWithValue({
//         ...defaultReject,
//         message: err.message,
//       });
//     }
//   }
// );

const SLOT_AVAILABILITY = createAsyncThunk(
  `slotApiSlice/slotAvailability`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { slotId = "" } = payload;

      const data = await fetchData(
        {
          body: JSON.stringify(slotRequest.slotAvailability(slotId)),
        },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const READ_SLOT = createAsyncThunk(
  `slotApiSlice/readSlot`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {
        id = "",
        startdate = "",
        enddate = "",
        type = "",
        practitionerid = "",
        facilityid = "",
      } = payload;
      let decodedToken = jwtDecode(localStorage.getItem("UserInfo"));
      let isfrontdesk = Boolean(decodedToken?.rolename);
      let isClinic = false;
      if (type === "clinicid") {
        isClinic = true;
      }
      const resp = await fetchData(
        {
          body: JSON.stringify(
            slotRequest.readSlot({
              startdate,
              enddate,
              isClinic,
              practitionerid,
              facilityid,
              isfrontdesk,
            })
          ),
        },
        urls.readQdmQueries
      );
      const data = resp?.[0] || {};
      const slots = data?.slot || [];
      let healthCareId = data?.orgId || [];
      const returnData = {
        morning: [],
        afternoone: [],
        evening: [],
        night: [],
        allSession: [],
        slots,
        isClinic,
        healthCareId,
        resourceInfo: {
          resourcecode: data?.resourcecode || "",
          resourcerole: data?.resourcerole || "",
          resourcetype: data?.resourcetype || "",
        },
      };
      // filterSlots(
      //     slots,
      //     returnData.morning,
      //     returnData.afternoone,
      //     returnData.evening,
      //     returnData.night,
      //     returnData.allSession,
      //     isClinic,
      // );
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const slotApiSlice = createSlice({
  name: "slotApiSlice",
  initialState: {
    // upsertSlot: { ...defaultState.Info },
    readSlot: { ...defaultState.Info },
    // deleteSlot: { ...defaultState.Info },
    slotAvailability: { ...defaultState.Info },
  },
  extraReducers: {
    /* UPSERT_SLOT */
    // [UPSERT_SLOT.fulfilled]: (state, action) => {
    //   state.upsertSlot = action?.payload ?? [];
    // },
    // [UPSERT_SLOT.pending]: (state, action) => {
    //   state.upsertSlot.loading = true;
    // },
    // [UPSERT_SLOT.rejected]: (state, action) => {
    //   state.upsertSlot = action?.payload ?? [];
    // },

    /* READ_SLOT */
    [READ_SLOT.fulfilled]: (state, action) => {
      state.readSlot = action?.payload ?? [];
    },
    [READ_SLOT.pending]: (state, action) => {
      state.readSlot.loading = true;
    },
    [READ_SLOT.rejected]: (state, action) => {
      state.readSlot = action?.payload ?? [];
    },

    /* FREE_SLOT */
    // [FREE_SLOT.fulfilled]: (state, action) => {
    //   state.deleteSlot = action?.payload ?? [];
    // },
    // [FREE_SLOT.pending]: (state, action) => {
    //   state.deleteSlot.loading = true;
    // },
    // [FREE_SLOT.rejected]: (state, action) => {
    //   state.deleteSlot = action?.payload ?? [];
    // },

    /* SLOT_AVAILABILITY */
    [SLOT_AVAILABILITY.fulfilled]: (state, action) => {
      state.slotAvailability = action?.payload ?? [];
    },
    [SLOT_AVAILABILITY.pending]: (state, action) => {
      state.slotAvailability.loading = true;
    },
    [SLOT_AVAILABILITY.rejected]: (state, action) => {
      state.slotAvailability = action?.payload ?? [];
    },
  },
});

export const slotApiActions = {
  //   UPSERT_SLOT,
  READ_SLOT,
  //   FREE_SLOT,
  SLOT_AVAILABILITY,
};

export default slotApiSlice;
