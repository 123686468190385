import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Grid,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Paper, TextInput } from "qdm-component-library";
// import { tableData, Header } from "./dummyData";
import { useStyles } from "../styles";
import Cancel from "../../../assets/img/svg/icons8-multiply.svg";
import Tick from "../../../assets/img/svg/icons8-double-tick.svg";
import Trash from "../../../assets/img/svg/icons8-trash.svg";
import { UIColor } from "../../../themes/theme";
import CollectionSummary from "./collections";
import jwtDecode from "jwt-decode";

const TableComponent = (props) => {
  const classes = useStyles();
  const [isDisable, setIsDisable] = useState(false);

  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      width: "90%",
      height: "30px",
    },
    inputStyle: {
      padding: 5,
      fontSize: "12px",
      fontWeight: 500,
    },
  };

  let Header = [];

  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  if (props?.isAdvancePayment) {
    Header = [
      "S.No",
      "MRN No",
      "Patient Name",
      "Advance No",
      "Advance Date",
      "Advance Amount "+ `(${decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode})`,
      "Remarks",
      "All",
      "Collection Amount "+ `(${decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode})`,
    ];
  } else {
    Header = [
      "S.No",
      "MRN No",
      "Patient Name",
      "Bill No",
      "Bill Date",
      "Patient Share",
      "Paid Amount "+ `(${decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode})`,
      "Balance Amount "+ `(${decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode})`,
      // "Status",
      // "Billed?",
      "All",
      "Collection Amount "+ `(${decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode})`,
      // "Payment Mode",
      // "View",
      // "Invoice",
      // "Payment",
      // "Collection",
    ];
  }
  
let dataType = [];
if(props?.isAdvancePayment){
  dataType=  [
    {
      name: "no",
      type: "INCREMENT",
      header: "S.No",
    },
    {
      name: "patmrn",
      type: "TEXT",
      header: "scroll",
    },
    {
      name: "patientname",
      type: "TEXT",
      header: "scroll",
      width: "280px",
    },
    {
      name: "advanceno",
      type: "TEXT",
      header: "scroll",
    //   width: "60px",
    },
    {
      name: "advdate",
      type: "TEXT",
      header: "scroll",
    },
    {
      name: "advamount",
      type: "TEXT",
      header: "scroll",
    },
    {
      name: "remarks",
      type: "TEXT",
      header: "scroll",
    },
    // {
    //   name: "balanceamount",
    //   type: "TEXT",
    //   header: "scroll",
    // },
    {
      name: "checked",
      type: "CHECKBOX",
      header: "All",
    },
    {
        name: "CollectionAmount",
        type: "TEXTFIELD",
        header: "scroll",
      },
  ]
}else{
  dataType= [
    {
      name: "no",
      type: "INCREMENT",
      header: "S.No",
    },
    {
      name: "patmrn",
      type: "TEXT",
      header: "scroll",
    },
    {
      name: "patientname",
      type: "TEXT",
      header: "scroll",
      width: "280px",
    },
    {
      name: "billno",
      type: "TEXT",
      header: "scroll",
    //   width: "60px",
    },
    {
      name: "billdate",
      type: "TEXT",
      header: "scroll",
    },
    {
      name: "billamount",
      type: "TEXT",
      header: "scroll",
    },
    {
      name: "paidamount",
      type: "TEXT",
      header: "scroll",
    },
    {
      name: "balanceamount",
      type: "TEXT",
      header: "scroll",
    },
    // {
    //     "name": "rate",
    //     "type": "TEXT",
    //     "header": "scroll"
    // },
    // {
    //     "name": "amt",
    //     "type": "TEXT",
    //     "header": "scroll"
    // },
    // {
    //   name: "status",
    //   type: "TEXT",
    //   header: "scroll",
    // },
    // {
    //   name: "billed",
    //   type: "CHECK",
    //   header: "scroll",
    // },
    {
      name: "checked",
      type: "CHECKBOX",
      header: "All",
    },
    // {
    //     name:"Payment Mode",
    //     type: "DROPDOWN",
    //     header:"Payment Mode",
    //   },
    {
        name: "CollectionAmount",
        type: "TEXTFIELD",
        header: "scroll",
      },
    // {
    //   name: "View",
    //   type: "VIEW",
    //   header: "View",
    // },
    // {
    //   name: "Invoice",
    //   type: "INVOICE",
    //   header: "Invoice",
    // },
    // {
    //   name: "Payment",
    //   type: "PAYMENT",
    //   header: "Payment",
    // },
    // {
    //   name: "Collection",
    //   type: "COLLECTION",
    //   header: "Collection",
    // },
  ]
}
  const generateComp = (type, row, rowIndex) => {
    switch (type.type) {
      case "CHECK": {
        if (row?.[type.name] === true) {
          return <img src={Tick} alt="Cancel" />;
        } else {
          return <img src={Cancel} alt="Cancel" />;
        }
      }
      case "INCREMENT": {
        return (
          <Typography className={classes.tableCellTypo}>
            {rowIndex + 1}
          </Typography>
        );
      }
      case "TEXT": {
        const returnData = row?.[type.name];
        if (returnData?.length > 45) {
          return (
            <Tooltip title={returnData} placement="top">
              <Typography className={classes.tableCellTypo}>
                {returnData.slice(0, 45) + "..."}
              </Typography>
            </Tooltip>
          );
        } else {
          return (
            <Typography className={classes.tableCellTypo}>
              {Number.isNaN(returnData) ? 0.0 : returnData}
            </Typography>
          );
        }
      }
      case "BILLTEXT": {
        return (
          <Typography
            className={classes.tableCellTypo}
            style={{ fontSize: "12px" }}
          >
            {row?.[type.name]}
          </Typography>
        );
      }
      case "VIEW": {
        return (
          <Button
            className={classes.tableCellTypo}
            style={{
              fontSize: "12px",
              border: "1px solid blue",
              borderRadius: "4px",
              backgroundColor: "white",
              color: "blue",
              padding: "5px 5px",
            }}
          >
            {row?.[type.name]}
          </Button>
        );
      }
      case "INVOICE": {
        return (
          <Button
            className={classes.tableCellTypo}
            style={{
              fontSize: "12px",
              border: "1px solid blue",
              borderRadius: "4px",
              backgroundColor: "white",
              color: "blue",
              padding: "5px 5px",
            }}
          >
            {row?.[type.name]}
          </Button>
        );
      }
      case "PAYMENT": {
        return (
          <Button
            className={classes.tableCellTypo}
            style={{
              fontSize: "12px",
              border: "1px solid blue",
              borderRadius: "4px",
              backgroundColor: "white",
              color: "blue",
              padding: "5px 5px",
            }}
          >
            {row?.[type.name]}
          </Button>
        );
      }
      case "COLLECTION": {
        return (
          <Button
            className={classes.tableCellTypo}
            style={{
              fontSize: "12px",
              border: "1px solid blue",
              borderRadius: "4px",
              backgroundColor: "white",
              color: "blue",
              padding: "5px 5px",
            }}
          >
            {row?.[type.name]}
          </Button>
        );
      }
      case "DROPDOWN":{
        return(
            <Select
              showArrow
              options={[
                { label: "Cash", value: "cash" },
                { label: "Card", value: "card" },
                { label: "Cheque", value: "cheque" }
            ]}            
            //   options={FlterOptions3(props?.allMasters, "RC_PAYMENTMODE")}
            //   onChangeValue={(e) =>
            //     props?.handlePayementDetails(e, "paymentType")
            //   }
            //   value={props?.paymentDetails?.paymentType?.value}
              placeholder="select..."
              labelStyle={styles.labelStyle}
              inLineStyles={{ ...styles.borderStyle }}
              hoverColor={UIColor.primaryColor.color}
            />
        )
      }
      case "CHECKBOX": {
        if (row?.billed) {
          return null;
        } else {
          return (
            <Checkbox
              style={{ padding: "0px" }}
              checked={row?.[type.name]}
              color="primary"
              size="small"
              onChange={(e) => props?.handleCheckBox(e, row, rowIndex)}
              disabled={row?.balanceamount === 0}
            />
          );
        }
      }
      case "DELETE": {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => props?.handleDeleteSelected(row, rowIndex)}
          >
            <img src={Trash} alt="delete" />
          </div>
        );
      }
      case "TEXTFIELD": {
        return (
          <div>
            <TextInput
              className={"textInput"}
              id={"discAmtTab"}
              label={""}
              placeholder=""
              type="number"
              labelStyle={styles.labelStyle}
              style={{ ...styles.borderStyle, ...styles.inputStyle }}
              value={row?.[type.name]}
              onChange={(value) =>
                props?.handleTableTextChange(value, row, rowIndex, type.name)
              }
              hoverColor={UIColor.lineBorderFill.color}
              disabled={!row?.checked || props?.isAdvancePayment}
            />
          </div>
        );
      }
      default: {
        return row?.[type.name];
      }
    }
  };

return (
<Grid container direction="column" spacing={2}>
  <Grid item style={{ flex: 1 }}>
    <TableContainer component={Paper} className={classes.tableContainer} style={{ minHeight: '200px' }}>
      <Table style={{ width: props?.tableWidth }}>
        <TableHead>
          <TableRow>
            {Header?.map((val, i) => {
              return (
                <TableCell
                  className={classes.headerCell}
                  style={{
                    position:
                      val === "S.No" || val === "All" ? "sticky" : "relative",
                    left: 0,
                    zIndex: val === "S.No" || val === "All" ? 2 : 0,
                    right: 0,
                    fontSize: props?.headerSize ? props?.headerSize : "14px",
                  }}
                >
                  {val === "All" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "3px",
                      }}
                    >
                      <Checkbox
                        style={{ padding: "0px" }}
                        checked={props?.allChecked}
                        color="primary"
                        size="small"
                        onChange={(e) => props?.handleHeaderCheckBox(e)}
                      />
                      {val}
                    </div>
                  ) : (
                    val
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody style={{ maxHeight: '300px', overflow: "auto" }}>
          {props?.tableData?.map((data, dataIndex) => { 
            return (
              <TableRow>
                {dataType?.map((type, index) => {
                  return (
                    <TableCell
                      className={classes.childCell}
                      style={{
                        position:
                          type?.header === "S.No" ||
                          type?.header === "All" ||
                          type?.header === "Button"
                            ? "sticky"
                            : "relative",
                        left: 0,
                        zIndex:
                          type?.header === "S.No" ||
                          type?.header === "All" ||
                          type?.header === "Button"
                            ? 2
                            : 0,
                        right: 0,
                        width:
                          type.type === "TEXTFIELD"
                            ? "140px"
                            : type?.width
                            ? type?.width
                            : "auto",
                        padding: type.type === "TEXTFIELD" ? "5px" : "10vpx",
                      }}
                    >
                      {generateComp(type, data, dataIndex)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {/* {totalCalculationRow && (
            <TableRow>
              {calculationResultData?.map((val, i) => (
                <TableCell className={classes.headerCell}>
                  <Typography className={classes.tableCellTypo}>
                    {val}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          )}
          {isPrintTotalData && (
            <TableRow>
              {printTotalData?.map((val, i) => (
                <TableCell className={classes.childCell}>
                  <Typography
                    className={classes.tableCellTypo}
                    style={{ fontSize: "12px" }}
                  >
                    {val}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          )} */}
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
</Grid>
  );
};

export default TableComponent;
