import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  docc,
  fetchData,
  urls,
} from "../../../utils";
import {
  generateResJson,
  generatesingleRead,
  genereateSaveJson,
  genreateDocAgnstCategory,
  generatePractitoner,
} from "./responseGenereate";

const DOCC_SAVE_SERVICE = createAsyncThunk(
  `DoccSlice/docc_save`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { data } = payload;
      let body = genereateSaveJson(data);
      const res = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.saveDoccs
      );
      return {
        ...defaultState.Info,
        data: res,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const DOCC_CATE_READ = createAsyncThunk(
  `DoccSlice/docc_cat_read`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(docc.docc_category_speciality_reason_read()),
        },
        urls.readQdmQueries
      );
      let result = {};

      //["DOCCATEGORY", "SPECIALTY", "REASON"]

      data.forEach((e, i) => {
        let key = Object.keys(e)?.[0];
        let arr = [];
        data?.[i]?.[key]?.Value?.forEach((v) => {
          arr.push({
            label: v?.display ?? "",
            value: v?._id ?? "",
            __id: v?._id ?? "",
            _id: v?._id ?? "",
          });
        });
        result[key] = arr;
      });
      return {
        ...defaultState.List,
        data: result,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const DOCC_DOCC_READ = createAsyncThunk(
  `DoccSlice/docc_docc_read`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      let { id } = payload;

      const data = await fetchData(
        {
          body: JSON.stringify(docc.docc_doctor_read(id)),
        },
        urls.readQdmQueries
      );

      let arr = [];

      // console.log( data.result)
      data.map((v) => {
        arr.push({
          label: v?.docname ?? "",
          value: v?.doccategory ?? "",
          formrefid: v?.formrefid ?? "",
          _id: v?._id ?? "",
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const DOCC_SPECIALITY = createAsyncThunk(
  `DoccSlice/docc_speciality`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(docc.docc_speciality()),
        },
        urls.readQdmQueries
      );
      let arr = [];
      data.map((v) => {
        arr.push({
          label: v?.coding?.[0]?.display ?? "",
          value: v?.coding?.[0]?.Type ?? "",
          _id: v?.coding?.[0]?._id ?? "",
          __id: v?._id ?? "",
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const DOCC_ENCOUNTER = createAsyncThunk(
  `DoccSlice/docc_encounter`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      let { id } = payload;
      const data = await fetchData(
        {
          body: JSON.stringify(docc.docc_encounter_details(id)),
        },
        urls.readQdmQueries
      );
      let arr = [];
      data.map((v) => {
        arr.push({
          label: v?._id ?? "label", //encounter_no
          value: v?._id ?? "",
          _id: v?._id ?? "",
          id: v?.id ?? "",
          encounterDate: v?.encounterdate ?? 0,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const DOCC_PRACTITIONER = createAsyncThunk(
  `DoccSlice/docc_practitioner`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      let { patient_id, encounter_id } = payload;
      const data = await fetchData(
        {
          body: JSON.stringify(docc.docc_practioner(patient_id, encounter_id)),
        },
        urls.readQdmQueries
      );

      let res = data?.[0]?.Practitioner_id;
      let arr = [];

      res?.forEach((v) => {
        arr.push({
          label: v?.name?.[0]?.fulname ?? "",
          value: v?._id ?? "",
          _id: v?._id ?? "",
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const DOCC_READ = createAsyncThunk(
  `DoccSlice/docc_read`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      let { patient_id } = payload;
      const data = await fetchData(
        {
          body: JSON.stringify(docc.docc_read(patient_id)),
        },
        urls.readQdmQueries
      );

      let res = generateResJson(data);

      return {
        ...defaultState.List,
        data: res,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const DOCC_SINGLE_READ = createAsyncThunk(
  `DoccSlice/docc_single_read`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { key } = payload;

      let state = getState();
      let ccState = JSON.parse(JSON.stringify(state.DoccSlice));
      const data = await fetchData(
        {
          body: JSON.stringify(docc.docc_single_read(key)),
        },
        urls.readQdmQueries
      );

      let res = generatesingleRead(data?.[0], ccState);

      return {
        ...defaultState.List,
        data: res,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const DOCC_AGAINST_CATEGORY = createAsyncThunk(
  `DoccSlice/docc_agnst_category`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      let { category, speciality, encoumter, practitioner, patient_id } =
        payload;

      const data = await fetchData(
        {
          body: JSON.stringify(
            docc.docc_against_category(
              category,
              speciality,
              encoumter,
              practitioner,
              patient_id
            )
          ),
        },
        urls.readQdmQueries
      );
      let res = genreateDocAgnstCategory(data);
      return {
        ...defaultState.List,
        data: res,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const PRACTITIONER_AGAINST_CATEGORY = createAsyncThunk(
  `DoccSlice/practioner_agnst_category`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      let { patient_id, doc_Category, doc_Type } = payload;

      const data = await fetchData(
        {
          body: JSON.stringify(
            docc.docc_pract_agaisnt_categ(patient_id, doc_Category, doc_Type)
          ),
        },
        urls.readQdmQueries
      );
      let res = generatePractitoner(data.result);
      return {
        ...defaultState.List,
        data: res,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const DoccSlice = createSlice({
  name: "DoccSlice",
  initialState: {
    docc_save: {
      ...defaultState.Info,
    },
    docc_cat_read: {
      ...defaultState.Info,
    },
    docc_docc_read: {
      ...defaultState.Info,
    },
    docc_read: {
      ...defaultState.Info,
    },
    docc_single_read: {
      ...defaultState.Info,
    },
    docc_speciality: {
      ...defaultState.List,
    },
    doc_encounter: {
      ...defaultState.List,
    },
    doc_practitioner: {
      ...defaultState.List,
    },
    doc_agnst_category: {
      ...defaultState.List,
    },
    doc_agnst_encounter: {
      ...defaultState.List,
    },
    doc_agnst_speciality: {
      ...defaultState.List,
    },
    doc_agnst_docType: {
      ...defaultState.List,
    },
    practioner_agnst_category: {
      ...defaultState.List,
    },
  },

  extraReducers: {
    // DOCC_SAVE_SERVICE
    [DOCC_SAVE_SERVICE.fulfilled]: (state, action) => {
      state.docc_save = action?.payload ?? {};
    },
    [DOCC_SAVE_SERVICE.pending]: (state, action) => {
      state.docc_save.loading = true;
    },
    [DOCC_SAVE_SERVICE.rejected]: (state, action) => {
      state.docc_save = action?.payload ?? {};
    },

    // DOCC_CATE_READ
    [DOCC_CATE_READ.fulfilled]: (state, action) => {
      state.docc_cat_read = action?.payload ?? {};
    },
    [DOCC_CATE_READ.pending]: (state, action) => {
      state.docc_cat_read.loading = true;
    },
    [DOCC_CATE_READ.rejected]: (state, action) => {
      state.docc_cat_read = action?.payload ?? {};
    },

    // DOCC_DOCC_READ
    [DOCC_DOCC_READ.fulfilled]: (state, action) => {
      state.docc_docc_read = action?.payload ?? {};
    },
    [DOCC_DOCC_READ.pending]: (state, action) => {
      state.docc_docc_read.loading = true;
    },
    [DOCC_DOCC_READ.rejected]: (state, action) => {
      state.docc_docc_read = action?.payload ?? {};
    },
    //DOC SPECIALITY
    [DOCC_SPECIALITY.fulfilled]: (state, action) => {
      state.docc_speciality = action?.payload ?? {};
    },
    [DOCC_SPECIALITY.pending]: (state, action) => {
      state.docc_speciality.loading = true;
    },
    [DOCC_SPECIALITY.rejected]: (state, action) => {
      state.docc_speciality = action?.payload ?? {};
    },

    //DOC encounter
    [DOCC_ENCOUNTER.fulfilled]: (state, action) => {
      state.doc_encounter = action?.payload ?? {};
    },
    [DOCC_ENCOUNTER.pending]: (state, action) => {
      state.doc_encounter.loading = true;
    },
    [DOCC_ENCOUNTER.rejected]: (state, action) => {
      state.doc_encounter = action?.payload ?? {};
    },

    //DOC PRACTITIONER
    [DOCC_PRACTITIONER.fulfilled]: (state, action) => {
      state.doc_practitioner = action?.payload ?? {};
    },
    [DOCC_PRACTITIONER.pending]: (state, action) => {
      state.doc_practitioner.loading = true;
    },
    [DOCC_PRACTITIONER.rejected]: (state, action) => {
      state.doc_practitioner = action?.payload ?? {};
    },
    // DOCC_READ
    [DOCC_READ.fulfilled]: (state, action) => {
      state.docc_read = action?.payload ?? {};
    },
    [DOCC_READ.pending]: (state, action) => {
      state.docc_read.loading = true;
    },
    [DOCC_READ.rejected]: (state, action) => {
      state.docc_read = action?.payload ?? {};
    },

    // DOCC_SINGLE_READ
    [DOCC_SINGLE_READ.fulfilled]: (state, action) => {
      state.docc_single_read = action?.payload ?? {};
    },
    [DOCC_SINGLE_READ.pending]: (state, action) => {
      state.docc_single_read.loading = true;
    },
    [DOCC_SINGLE_READ.rejected]: (state, action) => {
      state.docc_single_read = action?.payload ?? {};
    },

    // DOC AGAINST CATEGORY
    [DOCC_AGAINST_CATEGORY.fulfilled]: (state, action) => {
      state.doc_agnst_category = action?.payload ?? {};
    },
    [DOCC_AGAINST_CATEGORY.pending]: (state, action) => {
      state.doc_agnst_category.loading = true;
    },
    [DOCC_AGAINST_CATEGORY.rejected]: (state, action) => {
      state.doc_agnst_category = action?.payload ?? {};
    },

    // DOC AGAINST DOC TYPE
    [PRACTITIONER_AGAINST_CATEGORY.fulfilled]: (state, action) => {
      state.practioner_agnst_category = action?.payload ?? {};
    },
    [PRACTITIONER_AGAINST_CATEGORY.pending]: (state, action) => {
      state.practioner_agnst_category.loading = true;
    },
    [PRACTITIONER_AGAINST_CATEGORY.rejected]: (state, action) => {
      state.practioner_agnst_category = action?.payload ?? {};
    },
  },
});

const DoccActions = {
  DOCC_SAVE_SERVICE,
  DOCC_CATE_READ,
  DOCC_DOCC_READ,
  DOCC_SPECIALITY,
  DOCC_ENCOUNTER,
  DOCC_PRACTITIONER,
  DOCC_READ,
  DOCC_SINGLE_READ,
  DOCC_AGAINST_CATEGORY,
  PRACTITIONER_AGAINST_CATEGORY,
};

export { DoccActions };

export default DoccSlice;
