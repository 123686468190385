import moment from "moment";
import { getUtcTime, getUnixTime, utcTOLocal, __tenantid__ } from ".";
import { makeName } from "./helperFunction";
import jwtDecode from "jwt-decode";

export const CC_Json_Generator = (
  propsvalue,
  list,
  searchPractioner,
  personDetails,
  encounter_data
) => {
  const { pId } = propsvalue;
  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo =
    userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const roleid = decodedUserinfo?.roleid ?? "";
  const practitionerid = decodedUserinfo?.practitionerid ?? "";
  let data = list?.map((val) => {
    console.log(val,"period[0].start")
    let clinicalstatushistory = val?.clinicalstatushistory
      ? val?.clinicalstatushistory
      : [];
      clinicalstatushistory = clinicalstatushistory.map((val) => {
      val.period = [
        {
          start: val?.start,
          end: val?.end,
        },
      ];
   //   delete val.Person;
      delete val.createdAt;
   //   delete val.personName;
      return val;
    });
    if (
      val.clinicalStatus &&
      clinicalstatushistory[clinicalstatushistory.length - 1]?.new_status !==
        val.clinicalStatus?.label
    ) {
      clinicalstatushistory.push({
        PersonID:  encounter_data[0]?.Patient[0]?.personid ?? encounter_data?.personid,
        new_status: val?.clinicalStatus?.label,
        old_status:
        clinicalstatushistory[clinicalstatushistory.length - 1]
            ?.new_status ?? "",
        period: [
          {
            start: getUtcTime(),
            end: getUtcTime(moment().endOf("day")),
          },
        ],
      });
    }
    return {
      editId: val?.editData?._id ?? null,
      identifier: [],
      clinicalStatus: val?.clinicalStatus?.value ?? "a",
      verificationstatus: val?.status_verification?.value ?? "",
      category: "",
      severity: val?.severity?.value,
      bodysite: val?.bodySite?.value,
      assertedby: val?.assertedBy?.value,
      PersonID: encounter_data?.personid,
      patient_id: pId,
      encounter_id: encounter_data[0]?._id || encounter_data?.encounterID,
      // encounter_id: encounter_id,
 
      onsetdatetime: val?.onset.day
        ? parseInt(val?.onset.day)
        : val?.onset.currentDate
        ? getUtcTime(val?.onset.currentDate)
        : 0,
      onsetAge: "",
      onsetperiod: val?.onset.day ? val.onset.freq.value : "",
      onsetrange: "",
      onsetstring: "",
 
      abatementdatetime: val?.abatement.day
        ? parseInt(val?.abatement.day)
        : val?.abatement.currentDate
        ? getUtcTime(val?.abatement.currentDate)
        : 0,
      abatementage: "",
      abatementperiod: val?.abatement.day ? val?.abatement.freq.value : "",
      abatementrange: "",
      abatementstring: "",
 
      recordeddate: getUtcTime(),
 
      clinicalstatushistory: clinicalstatushistory,
 
      practitioner_id: practitionerid ?? "111",//encounter_data[0]?.practitioner_details[0]?.resouceid ?? "1",
      practitionerrole_id:roleid  ?? "111", //searchPractioner?.PractitionerRole[0]?._id ?? "1",
 
      relatedperson_id: val?.relationName?.value,
 
      note: val?.notes,
      patient_complaint: val?.complaint,
      code: val?.name,
      modifying_factor: val?.modifying_factor,
    };
  });
  return data;
};

export const DIAGNOSIS_Json_Generator = (
  list,
  searchPractioner,
  personDetails,
  encounter_data
) => {
  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo =
    userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const roleid = decodedUserinfo?.roleid ?? "";
  const practitionerid = decodedUserinfo?.practitionerid ?? "";
 

  let data = list?.map((val) => {
    return {
      editId: val?.editData ?? null,
      identifier: [],

      Organization_id: encounter_data?.[0]?.facilityid ?? encounter_data?.referraldetails?.facilityid,
      facilityid: encounter_data?.[0]?.facilityid ?? encounter_data?.referraldetails?.facilityid,

      patient_id: encounter_data?.[0]?.patient_id ?? encounter_data?.patientId,
      practitioner_id: practitionerid, //searchPractioner?._id ?? "1",
      practitionerrole_id: roleid, //searchPractioner?.practitioner_role[0] ?? "1",

      encounter_id: encounter_data?.[0]?._id ?? encounter_data?.encounterID,

      // newonsetperiod: val.onset?.day
      //   ? val.onset.day + " " + val.onset?.freq?.value
      //   : val.onset?.currentDate,

      onsetperiod: val.onset?.day
        ? Number(val.onset?.day)
        : Number(val.onset?.currentDate),
      onsetuom: val?.onset?.freq?.value,
      //  newabatementdatetime: val?.abatement?.day
      //     ? val.abatement?.day + " " + val?.abatement?.freq?.value
      //     : val.abatement?.currentDate,

      abatementdatetime: val.abatement?.day
        ? Number(val.abatement?.day)
        : Number(val.abatement?.currentDate),

      abatementuom: val?.abatement?.freq?.value,
      abatementperiod: val?.abatement?.day
        ? Number(val?.abatement?.day)
        : Number(val?.abatement?.currentDate),
      diagcode: val.diagnosis?.value ? val.diagnosis?.value : "",

      use: val.use?.value ? val.use?.value : "",
      rank: val.rank?.value ? val.rank?.value : "",
      codeset: val?.codeSet ? val?.codeSet : "",
      workrelated: val?.workRelated ? val?.workRelated : true,
      confidential: val?.confidential ? val?.confidential : true,
      Notifieable: val?.notifiable ? val?.notifiable : true,
      VerificationSTatus: val.verification?.value
        ? val.verification?.value
        : "1",
      clinicalstatus: val?.clinicalStatus?.value
        ? val?.clinicalStatus?.value
        : "1",

      recordeddate: getUtcTime(),
      manifestation: val.manifestation?.value ? val.manifestation?.value : "",
      EvidenceReference: val?.evidenceReference ? val?.evidenceReference : "",
      grade: [
        {
          gradecode: val?.gradeCode?.value ? val?.gradeCode?.value : "1",
          gradetype: val?.gradeType?.value ? val?.gradeType?.value : "1",
          assesmentref: val?.assesmentReference?.value
            ? val?.assesmentReference?.value
            : "1",
        },
      ],
      Notes: val?.notes ? val?.notes : "",
      tenantid: encounter_data?.[0]?.tenantid ?? __tenantid__,
    };
  });
  return data;
};

export const Treatment_Json_Generator = (
  list,
  encounter_data,
  personDetails,
  searchPractioner,
  encData,
) => {
  let data = list?.map((val) => {
    var statushistoryData = [];
    const CurrentcalStatus = val.statusData?.reason;
    let statushistory = val?.statushistory ? val?.statushistory : [];
    var statushistoryData = statushistory;

    if (!statushistory?.length > 0) {
      statushistoryData.push({
        PersonID: personDetails?.name?.[0]?.given
          ? personDetails?.name?.[0]?.given +
            " " +
            personDetails?.name?.[0]?.family +
            "/" +
            personDetails?.name?.[0]?.use
          : personDetails?.telecom?.[0]?.value +
            "/" +
            personDetails?.name?.[0]?.use,
        new_status: val?.statusData?.status?.label ?? "Draft",
        old_status: "Draft",
        status_reason: CurrentcalStatus,
        fromperiod:
          statushistory?.length > 0
            ? getUtcTime(statushistory[statushistory.length - 1].fromperiod)
            : getUtcTime(),
        toperiod: val.date ? getUtcTime(val.date) : getUtcTime(),
        // statushistory: statushistory,
      });
    }

    statushistory = statushistory.map((val) => {
      val.fromperiod = getUtcTime(val.date);
      val.toperiod = getUtcTime(val.date);
      return val;
    });
    if (
      CurrentcalStatus &&
      statushistory[statushistory.length - 1]?.new_status !== CurrentcalStatus
    ) {
      statushistoryData.push({
        PersonID: personDetails?.name?.[0]?.given
          ? personDetails?.name?.[0]?.given +
            " " +
            personDetails?.name?.[0]?.family +
            "/" +
            personDetails?.name?.[0]?.use
          : personDetails?.telecom?.[0]?.value +
            "/" +
            personDetails?.name?.[0]?.use,
        new_status: val?.statusData?.status?.label ?? "",
        old_status: statushistory[statushistory.length - 1]?.new_status ?? "",
        status_reason: CurrentcalStatus,
        fromperiod:
          statushistory?.length > 0
            ? getUtcTime(statushistory[statushistory.length - 1].fromperiod)
            : getUtcTime(),
        toperiod: val.date ? getUtcTime(val.date) : getUtcTime(),
        // statushistory: statushistory,
      });
    }

    const diagnosisData = val?.selectDiagnosis?.map((v) => {
      if (v?.label) {
        return {
          codeStandard: v?.label,
          code: v?.value,
        };
      }
    });
    const recurrentListData = val?.recurrentList?.map((v) => {
      if (v?.date || v?.dataTime) {
        return {
          date: getUtcTime(v?.date || v?.dataTime),
          status: v?.status,
        };
      }
    });
    const DataperformerDetails = val?.performerDetails?.map((v) => {
      return {
        organization: v?.organization?.label ?? "",
        actionType: v?.status?.label ?? "",
        practType: v?.performedBy?.value ?? "",
        practRole: v?.practitionerRole?._id ?? "",
        practId: v?.performedBy?.value ?? "",
        locationRoleType: v?.location?.label ?? "",
        locationCode: v?.locationCode ?? "",
      };
    });

    return {
      statushistory: statushistoryData,
      statusreason: val.statusData?.reason ?? "",
      //Organization_id: JSON.stringify(personDetails?.OrgID),
      Organization_id: encounter_data?.[0]?.facilityid,
      editId: val?.editData ?? null,
      patient_id: encounter_data?.[0]?.patient_id,
      encounter_Id: encData?.encId ?? encounter_data?.[0]?._id,
      encountertype: encounter_data?.[0]?.visit_type?._id,
      every_date: val?.recurrentData?.every?.value
        ? JSON.parse(val?.recurrentData?.every?.value)
        : 0,
      every_days: val?.recurrentData?.every?.periodicity?.value ?? "",
      starts_on: val?.recurrentData?.startsOn?.dateTime
        ? getUtcTime(val?.recurrentData?.startsOn?.dateTime)
        : 0,
      starts_on_days: moment(val?.recurrentData?.startsOn?.dateTime) ?? "",
      startes_on_occurance_date: val?.recurrentData?.endsAfter?.endsBy
        ? getUtcTime(val?.recurrentData?.endsAfter?.endsBy)
        : 0,
      startes_on_occurance: val?.recurrentData?.endsAfter?.endsAfter
        ? JSON.parse(val?.recurrentData?.endsAfter?.endsAfter)
        : 0,
      // OrderType: val?.orderType?._id ?? "",
      OrderType: val?.orderType?._id
        ? val?.orderType?._id
        : val?.orderType?.value ?? "",
      OrderNature: val?.nature?.value ?? "",
      orderMode: val?.mode?.value ?? "",
      priority: val?.priority?.value ?? "",
      // orderDateTime: val?.startDate ?  getUnixTime(val?.startDate) : getUtcTime(val?.dateTime) ?? 0,
      //orderDateTime: val?.recurrentData?.startsOn?.dateTime ?  getUnixTime( val?.recurrentData?.startsOn?.dateTime) : getUtcTime(val?.dateTime) ?? 0,
      // occuranceStartDate: val?.startDate ? getUnixTime(val?.startDate) : 0,
      orderDateTime: encounter_data?.encounterStart ?? (val?.startDate 
        ? moment(
            (val?.startDate || "").replace(",", " "),
            "YYYY-MM-DD hh:mm A"
          ).unix()
        : moment(val?.dateTime, "YYYY-MM-DD hh:mm A").unix()),
      // occuranceStartDate: val?.recurrentData?.startsOn?.dateTime ? getUnixTime(val?.recurrentData?.startsOn?.dateTime) : 0,
         occuranceStartDate: val?.startDate
         ? moment(
             (val?.startDate).replace(",", " "),
             "YYYY-MM-DD hh:mm A"
           ).unix()
         : moment(val?.dateTime, "YYYY-MM-DD hh:mm A").unix(),
      occuranceEndDate: val?.endDate ? getUnixTime(val?.endDate) : 0,
      orderCatalog: val?.orderCatalog?._id ?? "",
      orderQty: val?.qty ? parseInt(val?.qty) : 1,
      Requestor: [
        {
          organization: val?.requestorDetails?.organization?.label ?? "",
          practRole: val?.requestorDetails?.practitionerRole?._id ?? "",
          practId: val?.requestorDetails?.orderBy?.value ?? "",
        },
      ],
      indications: val?.indication?.label ?? "",
      reasonCode: val?.indication?.value ?? "",
      diagnosis: [...(diagnosisData || [])],
      performer: [...(DataperformerDetails || [])],
      notes: val?.notes ?? "",
      patientInstruction: [
        {
          code: val?.patientInstruction?.label ?? "",
          description: val?.patientInstruction?.content ?? "",
        },
      ],
      preparatoryInstruction: [
        {
          code: val?.preparatoryInstruction?.label ?? "",
          description: val?.preparatoryInstruction?.content ?? "",
        },
      ],
      departmentalInstruction: [
        {
          code: val?.departmentalInstruction?.label ?? "",
          description: val?.departmentalInstruction?.content ?? "",
        },
      ],
      specimenRelated: [
        {
          reqd: true,
          type: val?.specimenRelated?.specimenType?.label ?? "",
          volume: val?.specimenRelated?.volume ?? 0,
          volumeUOM: val?.specimenRelated?.volumeUom?.label ?? "",
          collectionMethod: val?.specimenRelated?.collectionMethod?.label ?? "",
          container: "",
          bodySite: val?.specimenRelated?.bodySite?.label ?? "",
          FastingPeriod: val?.specimenRelated?.fasting ?? 0,
          FastingPeriodUOM: val?.specimenRelated?.fastingUom?.label ?? "",
        },
      ],

      attribute: {
        view: val?.attributes?.view?.label ?? "",
        bedSide: val?.attributes?.bedSide ?? false,
        contrast: val?.attributes?.contrast ?? "",
      },
      recurrentList: [...(recurrentListData || [])],
      changeStatus: {
        changeToStatus: val?.statusData?.status?.label ?? "",
        dateTime: getUtcTime(val?.date) ?? 0,
        reason: val?.reason,
      },
      accessionNum: makeName(personDetails?.name?.[0]),
      parentOrderId: val?.parentOrderId ?? "",
    };
  });
  return data;
};

export const Rad_Json_Generator = (
  list,
  encounter_data,
  personDetails,
  searchPractioner,
  encId
) => {
  let data = list?.map((val) => {
    var statushistoryData = [];
    const CurrentcalStatus = val.statusData?.reason;
    let statushistory = val?.statushistory ? val?.statushistory : [];
    var statushistoryData = statushistory;

    if (!statushistory?.length > 0) {
      statushistoryData.push({
        PersonID: personDetails?.name?.[0]?.given
          ? personDetails?.name?.[0]?.given +
            " " +
            personDetails?.name?.[0]?.family +
            "/" +
            personDetails?.name?.[0]?.use
          : personDetails?.telecom[0]?.value +
              "/" +
              personDetails?.name?.[0]?.use ?? "",
        new_status: val?.statusData?.status?.label ?? "Draft",
        old_status: "Draft",
        status_reason: CurrentcalStatus,
        fromperiod:
          statushistory?.length > 0
            ? getUtcTime(statushistory[statushistory.length - 1].fromperiod)
            : getUtcTime(),
        toperiod: val.date ? getUtcTime(val.date) : getUtcTime(),
        // statushistory: statushistory,
      });
    }

    statushistory = statushistory.map((val) => {
      val.fromperiod = getUtcTime(val.date);
      val.toperiod = getUtcTime(val.date);
      return val;
    });
    if (
      CurrentcalStatus &&
      statushistory[statushistory.length - 1]?.new_status !== CurrentcalStatus
    ) {
      statushistoryData.push({
        PersonID: personDetails?.name?.[0]?.given
          ? personDetails?.name?.[0]?.given +
            " " +
            personDetails?.name?.[0]?.family +
            "/" +
            personDetails?.name?.[0]?.use
          : personDetails?.telecom[0]?.value +
              "/" +
              personDetails?.name?.[0]?.use ?? "",
        new_status: val?.statusData?.status?.label,
        old_status: statushistory[statushistory.length - 1]?.new_status ?? "",
        status_reason: CurrentcalStatus,
        fromperiod:
          statushistory?.length > 0
            ? getUtcTime(statushistory[statushistory.length - 1].fromperiod)
            : getUtcTime(),
        toperiod: val.date ? getUtcTime(val.date) : getUtcTime(),
        // statushistory: statushistory,
      });
    }

    const diagnosisData = val?.selectDiagnosis?.map((v) => {
      if (v?.label) {
        return {
          codeStandard: v?.label,
          code: v?.value,
        };
      }
    });
    const recurrentListData = val?.recurrentList?.map((v) => {
      if (v?.date || v?.dataTime) {
        return {
          date: getUtcTime(v?.date || v?.dataTime),
          status: v?.status,
        };
      }
    });
    const DataperformerDetails = val?.performerDetails?.map((v) => {
      return {
        organization: v?.organization?.label ?? "",
        actionType: v?.status?.label ?? "",
        practType: v?.performedBy?.label ?? "",
        practRole: v?.practitionerRole?.label ?? "",
        practId: v?.practitionerId,
        locationRoleType: v?.location?.label ?? "",
        locationCode: v?.locationCode ?? "",
      };
    });
    return {
      statushistory: statushistoryData,
      statusreason: val.statusData?.reason,
      Organization_id: encounter_data?.[0]?.facilityid, //JSON.stringify(personDetails?.OrgID),
      editId: val?.editData ?? null,
      patient_id: encounter_data?.[0]?.patient_id,
      encounter_Id: encId ? encId : encounter_data?.[0]?._id,
      encountertype: encounter_data?.[0]?.visit_type?._id,
      every_date: val?.recurrentData?.every?.value
        ? JSON.parse(val?.recurrentData?.every?.value)
        : 0,
      every_days: val?.recurrentData?.every?.periodicity?.value ?? "",
      starts_on: val?.recurrentData?.startsOn?.dateTime
        ? getUtcTime(val?.recurrentData?.startsOn?.dateTime)
        : 0,
      starts_on_days: moment(val?.recurrentData?.startsOn?.dateTime) ?? "",
      startes_on_occurance_date: val?.recurrentData?.endsAfter?.endsBy
        ? getUtcTime(val?.recurrentData?.endsAfter?.endsBy)
        : 0,
      startes_on_occurance: val?.recurrentData?.endsAfter?.endsAfter
        ? JSON.parse(val?.recurrentData?.endsAfter?.endsAfter)
        : 0,
      OrderType: val?.orderType?.value ?? "",
      OrderNature: val?.nature?.value ?? "",
      orderMode: val?.mode?.value ?? "",
      priority: val?.priority?.value ?? "",
      orderDateTime: val?.startDate
        ? moment(
            (val?.startDate).replace(",", " "),
            "YYYY-MM-DD hh:mm A"
          ).unix()
        : moment(val?.dateTime, "YYYY-MM-DD hh:mm A").unix(),
      // occuranceStartDate: val.startDate ? getUtcTime(val?.startDate) :Number((moment().unix() / 1000).toFixed(0)),
      occuranceStartDate: val?.startDate
      ? moment(
          (val?.startDate).replace(",", " "),
          "YYYY-MM-DD hh:mm A"
        ).unix()
      : moment(val?.dateTime, "YYYY-MM-DD hh:mm A").unix(),
      occuranceEndDate: val.endDate ? getUnixTime(val?.endDate) : 0,
      orderCatalog: val?.orderCatalog?._id ?? "",
      orderQty: val.qty ? parseInt(val?.qty) : 1,
      Requestor: [
        {
          organization: val?.requestorDetails?.organization?.label ?? "",
          practRole: val?.requestorDetails?.practitionerRole?._id ?? "",
          practId: val?.requestorDetails?.orderBy?.value ?? "",
        },
      ],
      indications: val?.indication?.label ?? "",
      reasonCode: val?.indication?.value ?? "",
      diagnosis: [...(diagnosisData || [])],
      performer: [...(DataperformerDetails || [])],
      notes: val?.notes ?? "",
      patientInstruction: [
        {
          code: val?.patientInstruction?.label ?? "",
          description: val?.patientInstruction?.content ?? "",
        },
      ],
      preparatoryInstruction: [
        {
          code: val?.preparatoryInstruction?.label ?? "",
          description: val?.preparatoryInstruction?.content ?? "",
        },
      ],
      departmentalInstruction: [
        {
          code: val?.departmentalInstruction?.label ?? "",
          description: val?.departmentalInstruction?.content ?? "",
        },
      ],
      specimenRelated: [
        {
          reqd: true,
          type: val?.specimenRelated?.specimenType?.label ?? "",
          volume: val?.specimenRelated?.volume ?? 0,
          volumeUOM: val?.specimenRelated?.volumeUom?.label ?? "",
          collectionMethod: val?.specimenRelated?.collectionMethod?.label ?? "",
          container: "",
          bodySite: val?.specimenRelated?.bodySite?.label ?? "",
          FastingPeriod: val?.specimenRelated?.fasting ?? 0,
          FastingPeriodUOM: val?.specimenRelated?.fastingUom?.label ?? "",
        },
      ],
      attribute: {
        view: val?.attributes?.view?.label ?? "",
        bedSide: val?.attributes?.bedSide ?? false,
        contrast: val?.attributes?.contrast ?? "",
      },
      recurrentList: [...(recurrentListData || [])],
      changeStatus: {
        changeToStatus: val?.statusData?.status?.label ?? "",
        dateTime: getUtcTime(val?.date) ?? 0,
        reason: val?.reason ?? "",
      },
      accessionNum: makeName(personDetails?.name?.[0]) ?? "",
      parentOrderId: val?.parentOrderId ?? "",
    };
  });
  return data;
};

export const LabOrder_Json_Generator = (
  list,
  encounter_data,
  personDetails,
  searchPractioner,
  encId,
  encstartdate,
) => {
  let data = list?.map((val) => {
    var statushistoryData = [];
    const CurrentcalStatus = val.statusData?.reason;
    let statushistory = val?.statushistory ? val?.statushistory : [];
    var statushistoryData = statushistory;
    if (!statushistory?.length > 0) {
      statushistoryData.push({
        PersonID: personDetails?.name?.[0]?.given
          ? personDetails?.name?.[0]?.given +
            " " +
            personDetails?.name?.[0]?.family +
            "/" +
            personDetails?.name?.[0]?.use
          : personDetails?.telecom?.[0]?.value +
            "/" +
            personDetails?.name?.[0]?.use,
        new_status: val?.statusData?.status?.label ?? "Draft",
        old_status: "Draft",
        status_reason: CurrentcalStatus,
        fromperiod:
          statushistory?.length > 0
            ? getUtcTime(statushistory[statushistory.length - 1].fromperiod)
            : getUtcTime(),
        toperiod: val.date ? getUtcTime(val.date) : getUtcTime(),
        // statushistory: statushistory,
      });
    }

    statushistory = statushistory.map((val) => {
      val.fromperiod = getUtcTime(val.date);
      val.toperiod = getUtcTime(val.date);
      return val;
    });
    if (
      CurrentcalStatus &&
      statushistory[statushistory.length - 1]?.new_status !== CurrentcalStatus
    ) {
      statushistoryData.push({
        PersonID: personDetails?.name?.[0]?.given
          ? personDetails?.name?.[0]?.given +
            " " +
            personDetails?.name?.[0]?.family +
            "/" +
            personDetails?.name?.[0]?.use
          : personDetails.telecom?.[0]?.value +
            "/" +
            personDetails?.name?.[0]?.use,
        new_status: val?.statusData?.status?.label,
        old_status: statushistory[statushistory.length - 1]?.new_status ?? "",
        status_reason: CurrentcalStatus,
        fromperiod:
          statushistory?.length > 0
            ? getUtcTime(statushistory[statushistory.length - 1].fromperiod)
            : getUtcTime(),
        toperiod: val.date ? getUtcTime(val.date) : getUtcTime(),
        // statushistory: statushistory,
      });
    }

    const diagnosisData = val?.selectDiagnosis?.map((v) => {
      if (v?.label) {
        return {
          codeStandard: v?.label,
          code: v?.value,
        };
      }
    });

    const recurrentListData = val?.recurrentList?.map((v) => {
      if (v?.date || v?.dataTime) {
        return {
          date: getUtcTime(v?.date || v?.dataTime),
          status: v?.status,
        };
      }
    });

    const DataperformerDetails = val?.performerDetails?.map((v) => {
      return {
        organization: v?.organization?.label ?? "",
        actionType: v?.status?.label ?? "",
        practType: v?.performedBy?.value ?? "",
        practRole: v?.practitionerRole?._id ?? "",
        practId: v?.performedBy?.value ?? "",
        locationRoleType: v?.location?.label ?? "",
        locationCode: v?.locationCode ?? "",
      };
    });

    return {
      statushistory: statushistoryData,
      statusreason: val.statusData?.reason ?? "",
      Organization_id: encounter_data?.[0]?.facilityid,
      //JSON.stringify(personDetails?.OrgID),
      editId: val?.editData ?? null,
      patient_id: encounter_data?.[0]?.patient_id,
      encounter_Id: encId ? encId : encounter_data?.[0]?._id,
      encountertype: encounter_data?.[0]?.visit_type?._id,
      every_date: val?.recurrentData?.every?.value
        ? JSON.parse(val?.recurrentData?.every?.value)
        : 0,
      every_days: val?.recurrentData?.every?.periodicity?.value ?? "",
      starts_on: val?.recurrentData?.startsOn?.dateTime
        ? getUtcTime(val?.recurrentData?.startsOn?.dateTime)
        : 0,
      starts_on_days: moment(val?.recurrentData?.startsOn?.dateTime) ?? "",
      startes_on_occurance_date: val?.recurrentData?.endsAfter?.endsBy
        ? getUtcTime(val?.recurrentData?.endsAfter?.endsBy)
        : 0,
      startes_on_occurance: val?.recurrentData?.endsAfter?.endsAfter
        ? JSON.parse(val?.recurrentData?.endsAfter?.endsAfter)
        : 0,
      preferredDays: val?.recurrentData?.preferrence?.preferredDays?.map(
        (v) => v?.value
      ),
      preferredWeeks: val?.recurrentData?.preferrence?.preferredWeeks?.map(
        (v) => v?.value
      ),

      OrderType: val?.orderType?._id
        ? val?.orderType?._id
        : val?.orderType?.value ?? "",
      OrderNature: val?.nature?.value ?? "",
      orderMode: val?.mode?.value ?? "",
      priority: val?.priority?.value ?? "",

      orderDateTime: encstartdate ? encstartdate : val?.startDate
        ? moment(
            (val?.startDate).replace(",", " "),
            "YYYY-MM-DD hh:mm A"
          ).unix()
        : moment(val?.dateTime, "YYYY-MM-DD hh:mm A").unix(),
      // occuranceStartDate: val.startDate ? getUtcTime(val?.startDate) : Number((moment().unix() / 1000).toFixed(0)),
      occuranceStartDate: val?.startDate
      ? moment(
          (val?.startDate).replace(",", " "),
          "YYYY-MM-DD hh:mm A"
        ).unix()
      : moment(val?.dateTime, "YYYY-MM-DD hh:mm A").unix(),
      occuranceEndDate: val.endDate ? getUnixTime(val?.endDate) : 0,
      orderCatalog: val?.orderCatalog?._id ?? "",
      orderQty: val.qty ? parseInt(val?.qty) : 1,
      Requestor: [
        {
          organization: val?.requestorDetails?.organization?.label ?? "",
          practRole: val?.requestorDetails?.practitionerRole?._id ?? "",
          practId: val?.requestorDetails?.orderBy?.value ?? "",
        },
      ],
      indications: val?.indication?.label ?? "",
      reasonCode: val?.indication?.value ?? "",
      diagnosis: [...(diagnosisData || [])],
      performer: [...(DataperformerDetails || [])],
      notes: val?.notes ?? "",
      patientInstruction: [
        {
          code: val?.patientInstruction?.label ?? "",
          description: val?.patientInstruction?.content ?? "",
        },
      ],
      preparatoryInstruction: [
        {
          code: val?.preparatoryInstruction?.label ?? "",
          description: val?.preparatoryInstruction?.content ?? "",
        },
      ],
      departmentalInstruction: [
        {
          code: val?.departmentalInstruction?.label ?? "",
          description: val?.departmentalInstruction?.content ?? "",
        },
      ],
      specimenRelated: [
        {
          reqd: true,
          type: val?.specimenRelated?.specimenType?.label ?? "",
          volume: val?.specimenRelated?.volume ?? 0,
          volumeUOM: val?.specimenRelated?.volumeUom?.label ?? "",
          collectionMethod: val?.specimenRelated?.collectionMethod?.label ?? "",
          container: "",
          bodySite: val?.specimenRelated?.bodySite?.label ?? "",
          FastingPeriod: val?.specimenRelated?.fasting ?? 0,
          FastingPeriodUOM: val?.specimenRelated?.fastingUom?.label ?? "",
        },
      ],
      attribute: {
        view: val?.attributes?.view?.label ?? "",
        bedSide: val?.attributes?.bedSide ?? false,
        contrast: val?.attributes?.contrast ?? "",
      },
      recurrentList: [...(recurrentListData || [])],
      changeStatus: {
        changeToStatus: val?.statusData?.status?.label ?? "",
        dateTime: getUtcTime(val?.date) ?? 0,
        reason: val?.reason ?? "",
      },
      accessionNum: makeName(personDetails?.name?.[0]) ?? "",
      // childRecurrent: LabOrder_Json_Generator1(
      //   val?.childRecurrent,
      //   encounter_data,
      //   personDetails,
      //   loginUser
      // ),
      parentOrderId: val?.parentOrderId ?? "",
    };
  });

  return data;
};

export const generateResponseJson = (dataList, state) => {
  const list = dataList.map((data) => {
    const createddate = data?.createddate ?? Date.now();
    const updatedate = data?.updatedate ?? Date.now();
    return {
      allergenCode: {
        label: data?.code?.longdesc ?? data?.code,
        value: data?.code?._id ?? data?.code,
      },
      asserted: data?.category ?? null,
      category: data?.category ?? null,
      clinicalStatus: data?.clinicalstatus ?? null,
      lastOccurance: {
        day: data?.lastoccurrence?.[0]?.lastoccurrenceperiod
          ? data?.lastoccurrence?.[0]?.lastoccurrencedatetime
          : "",
        freq: data?.lastoccurrence?.[0]?.lastoccurrenceperiod
          ? {
              label: data?.lastoccurrence?.[0]?.lastoccurrenceperiod,
              value: data?.lastoccurrence?.[0]?.lastoccurrenceperiod,
            }
          : {},
        currentDate:
          data?.lastoccurrence?.[0]?.lastoccurrencedatetime &&
          !data?.lastoccurrence?.[0]?.lastoccurrenceperiod
            ? utcTOLocal(
                data?.lastoccurrence?.[0]?.lastoccurrencedatetime,
                "DD MMM, YYYY"
              )
            : "",
        isCalenderEnabled: data?.lastoccurrence?.[0]?.lastoccurrenceperiod
          ? false
          : data?.lastoccurrence?.[0]?.lastoccurrencedatetime
          ? true
          : false,
        calenderIconEnabled: data?.lastoccurrence?.[0]?.lastoccurrenceperiod
          ? false
          : true,
      },
      // "lastOccurancecalenderShow": true,
      notes: data?.note ?? null,
      onset: {
        day: data?.onset?.[0]?.onsetperiod
          ? data?.onset?.[0]?.onsetdatetime
          : "",
        freq: data?.onset?.[0]?.onsetperiod
          ? {
              label: data?.onset?.[0]?.onsetperiod,
              value: data?.onset?.[0]?.onsetperiod,
            }
          : {},
        currentDate:
          data?.onset?.[0]?.onsetdatetime && !data?.onset?.[0]?.onsetperiod
            ? utcTOLocal(data?.onset?.[0]?.onsetdatetime, "DD MMM, YYYY")
            : "",
        isCalenderEnabled: data?.onset?.[0]?.onsetperiod
          ? false
          : data?.onset?.[0]?.onsetdatetime
          ? true
          : false,
        calenderIconEnabled: data?.onset?.[0]?.onsetperiod ? false : true,
      },
      // "onsetcalenderShow": true,
      relationName: data?.relationName ?? null,
      severity: data?.severity ?? null,
      type: data?.type ?? null,
      verify: data?.verificationstatus ?? null,
      reactions: data?.reaction ?? [],
      note: data?.note,
      editId: data,
      id: data?.id,
      relatedperson_id: data?.relatedperson_id,
      statushistory: data?.statushistory,
      isNew: createddate === updatedate,
      isModified: createddate !== updatedate,
      createddate: createddate,
      updatedate: updatedate,
      practitioner_id: data?.practitioner_id,
    };
  });
  return list;
};
