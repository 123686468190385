import React from "react";

export const RiceBowl = (props) => {
  let width = props.width ? props.width : "18";
  let height = props.height ? props.height : "18";
  let color = props.color ? props.color : "#323232";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={props?.style}
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <path
        class="a" 
        fill={color}
        d="M14.9,2.98a.543.543,0,0,0-.474.3L12.356,7.268a2,2,0,0,0-1.81-1.234A1.975,1.975,0,0,0,8.79,7.181c-.022,0-.041-.005-.062-.005A2.753,2.753,0,0,0,6.447,8.339,2.92,2.92,0,0,0,4.013,9.458a3.434,3.434,0,0,0-.54,1.907h-.2A1.312,1.312,0,0,0,2,12.7,6.168,6.168,0,0,0,8,18.98h4A6.155,6.155,0,0,0,18,12.7a1.312,1.312,0,0,0-1.273-1.333h-.238A4.021,4.021,0,0,0,15,8.423a3.651,3.651,0,0,0-.513-.239l3.39-4.26a.59.59,0,0,0-.07-.8.529.529,0,0,0-.768.073L13.427,7.727c-.022-.005-.043,0-.065-.007l2.025-3.887a.6.6,0,0,0-.006-.573A.541.541,0,0,0,14.9,2.98Zm-4.359,4.2a.923.923,0,0,1,.909.952A.559.559,0,0,0,12,8.7a4.057,4.057,0,0,1,2.367.657A2.689,2.689,0,0,1,15.4,11.365H10.7a.594.594,0,0,0-.069-.52.531.531,0,0,0-.89,0,.594.594,0,0,0-.069.52H7.423a.594.594,0,0,0-.069-.52.531.531,0,0,0-.89,0,.594.594,0,0,0-.069.52H4.565A2.4,2.4,0,0,1,4.9,10.13a1.767,1.767,0,0,1,1.65-.669c.034,0,.077,0,.134.007a.541.541,0,0,0,.528-.306,1.691,1.691,0,0,1,1.52-.843,2.007,2.007,0,0,1,.322.028.547.547,0,0,0,.614-.435A.912.912,0,0,1,10.545,7.176ZM8.364,9.08a.572.572,0,1,0,.545.571A.559.559,0,0,0,8.364,9.08Zm4.364.381a.572.572,0,1,0,.545.571A.559.559,0,0,0,12.727,9.461ZM3.273,12.507H15.91a.521.521,0,0,0,.177,0h.64a.178.178,0,0,1,.182.19A5.02,5.02,0,0,1,12,17.838H8A5.04,5.04,0,0,1,3.091,12.7.178.178,0,0,1,3.273,12.507Z"
        transform="translate(-2 -2.98)"
      />
    </svg>
  );
};
