import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../../../themes/theme";

const useStyles = makeStyles(() => ({
  flexRow:{
    display: "flex",
    alignItems: "center",
    padding: 8,
    backgroundColor:"transparent",
    borderRadius:UIColor.primaryColor.color ? 4 : 0,
    cursor: "pointer",
  },
  label:{
    fontSize: 12,
    fontFamily: "pc_medium",
    color: "#101010",
    flex: 1,
    color:"#000",
  },
  icon: {
    paddingRight: 8,
    display: "flex",
  },
}));

export default useStyles;