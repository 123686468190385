import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  paper: ({ minWidth, maxWidth }) => ({
    borderRadius: 8,
    border: `1px solid #DEE5EC`,
    boxShadow: `#00000026 0px 0px 20px`,
    background: `${theme.palette.background.table} !important`,
    color: theme.palette.text.primary,
    minWidth: minWidth ?? "auto",
    maxWidth: maxWidth ?? "auto",
  }),
}));
