import React, { useEffect, useState } from "react";
import {
  Div,
  Paper,
  Select,
  TextInput,
  H6,
  Col,
  Row,
  Text,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../../hoc/withAllContexts";
import { FlterOptions2, FlterOptions3 } from "./handlers";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles,Grid } from "@material-ui/core"
import TextfieldWithSelect from "../../../screens/patientDetail/medicationFlows/medicationInfoForm/components/inputs/textfieldWithSelect";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";

const useStyles = makeStyles((theme)=>({
  chips: {
    "& .MuiChip-root": {

      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
  },
}))

const PatientCategory = (props) => {
  const classes = useStyles();
  let styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
  };
  const dispatch = useDispatch();

  const [patientDatas, setPatientDatas] = useState({
    ...props?.patientData,
  });
  const [patientCategoryLists, setpatientCategoryLists] = useState([]);
  const getData =  async ()=>{
    const patientCategoryDialysis= await dispatch(actions.PATIENT_CATEGORY_DIALYSIS(""));
    setpatientCategoryLists(patientCategoryDialysis?.payload?.data);
  }
  useEffect(()=>{
    getData()
  },[])

  const setData = (props, name, value, index) => {
    let patientData = props;
    if (name === "patient_importance") {
      patientData.patientInfo["special_courtesy"] = "";
      patientData.patientInfo["special_arrangement"] = "";
    }
    patientData.patientInfo[name] = value;
    // patientData[this.props.setTo]({
    //   ...patientData,
    // });
    setPatientDatas({ ...patientData });
    props?.setPatientData({ ...patientData });
  };

  const setData2 = (props, name, event, index) => {
    let patientData = props;
    
    patientData.patientInfo[name] = event;
    // patientData[this.props.setTo]({
    //   ...patientData,
    // });
    setPatientDatas({ ...patientData });
    props?.setPatientData({ ...patientData });
  };

  const FlterOptionsDialysis = (type) => {
    let list = [];
    const patientCategoryList = patientCategoryLists ?? props?.patientCategoryList;

    patientCategoryList?.forEach((val) => {
        val?.[type]?.Value?.forEach((v) => {
            let obj = {
                label: v?.display,
                value: v?._id,
                _id: v?._id
            };
            list.push(obj);
        });
    });
    return list;
};

  const FlterOptions = (type) => {
    // const { allMasters } = props;
    // var lista = []
    // const data = allMasters?.[type]
    // for (let index in data) {
    //     var datatemp = {
    //         ...data[index],
    //         "value": data[index]?.coding?.display,
    //         "_id": data[index]?.coding?._id,
    //     };
    //     lista.push(datatemp);
    // }
    // return lista
  };

  const setPatientCategory = (props, name, value) => {
    let patientData = props;
    if (patientData.patientInfo?.patientcategory?.length > 0) {
      const seen = new Set();
      const filteredArr = value.filter((el) => {
        const duplicate = seen.has(el.value);
        seen.add(el.value);
        return !duplicate;
      });
      setPatientDatas({ ...patientData });
      patientData.patientInfo[name] = filteredArr;
      // patientData.setPatientData({
      //   ...patientData,
      // });
    //  setPatientData({ ...patientData });
      props?.setPatientData({ ...patientData });
      return;
    }

    patientData.patientInfo[name] = value;
    // patientData.setPatientData({
    //   ...patientData,
    // });
   // setPatientData({ ...patientData });
   setPatientDatas({ ...patientData });
    props?.setPatientData({ ...patientData });
  };
  const setIncomeandDistance = (props, name, value) => {
    let patientData = props;
    if(value==="true"){
      let bool ;
      bool = true;
      patientData.patientInfo[name] = bool;
      setPatientDatas({ ...patientData });
      props?.setPatientData({ ...patientData });
      return;
    } else if (value==="false"){
      let bool ;
      bool = false;
      patientData.patientInfo[name] = bool;
      setPatientDatas({ ...patientData });
      props?.setPatientData({ ...patientData });
      return;
    }
    patientData.patientInfo[name] = value;
    setPatientDatas({ ...patientData });
    props?.setPatientData({ ...patientData });
  };

  let patientData = patientDatas.patientInfo;

  const { labelStyle, borderStyle } = styles;
  const { parent_id } = props;

  return (
    <Div id={`${parent_id}_patientcategory_parent_div`} className="pi-root">
      <Paper
        id={`${parent_id}_patientcategory_parent_paper`}
        className="pi-paper"
      >
        <Div id={`${parent_id}_patientcategory_label_div`}>
          <H6 id={`${parent_id}_patientcategory_label_h6`} className="pi-title">
            PATIENT CATEGORY
          </H6>
        </Div>
        <Row id={`${parent_id}_patientcategory_parent_row`}>
          {/* <Col
            id={`${parent_id}_patientcategory_patienttype_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}_patientcategory_patienttype_select`}
              options={FlterOptions3(props?.allMasters, "PATIENTTYPE")}
              onChangeValue={(e) => setData(patientDatas, "patient_type", e)}
              value={patientData?.patient_type?.value}
              label="Patient Type"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.isViewMode}
            />
          </Col> */}
          {/* <Col
            id={`${parent_id}_patientcategory_patient_importance_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}_patientcategory_patient_importance_select`}
              options={FlterOptions3(
                props?.allMasters,
                "PATIENTIMPORTANCETYPE"
              )}
              onChangeValue={(e) =>
                setData(patientDatas, "patient_importance", e)
              }
              value={patientData?.patient_importance?.value}
              label="Patient's Importance"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.isViewMode}
            />
          </Col> */}
          {patientData?.patient_importance?.coding?.gmconfigvalues?.[
            "Spl Courtesy"
          ] && (
            <Col
              id={`${parent_id}_patientcategory_specialcourtesy_col`}
              md={4}
              lg={4}
              sm={6}
              xs={12}
              className="no-padding-left"
              inLineStyles={{ padding: 10 }}
            >
              {/* <TextInput
                            disabled={patientData?.patient_importance?.coding?.gmconfigvalues?.["Spl Courtesy"]?false:true}
                            id={`${parent_id}_patientcategory_specialcourtesy_label_textinput`}
                            onChange={(e) => this.setData('special_courtesy', e.target.value)}
                            value={patientData?.special_courtesy}
                            label="Special Courtesy" 
                            placeholder="special courtesy" 
                            labelStyle={labelStyle} 
                            style={borderStyle} 
                            hoverColor="#0071F2" 
                        /> */}
              <Select
                id={`${parent_id}_patientcategory_special_courtesy_select`}
                options={FlterOptions("SPLCOURTESY")}
                onChangeValue={(e) =>
                  setData(patientDatas, "special_courtesy", e)
                }
                value={patientData?.special_courtesy?.value}
                label="Special Courtesy"
                placeholder="select..."
                showArrow
                labelStyle={labelStyle}
                inLineStyles={borderStyle}
                hoverColor="#0071F2"
                disabled={props?.isViewMode}
              />
            </Col>
          )}
          {patientData?.patient_importance?.coding?.gmconfigvalues?.[
            "Spl Arrangement"
          ] && (
            <Col
              id={`${parent_id}_patientcategory_specialarrangement_col`}
              md={4}
              lg={4}
              sm={6}
              xs={12}
              className="no-padding-left"
              inLineStyles={{ padding: 10 }}
            >
              {/* <TextInput
                            disabled={patientData?.patient_importance?.coding?.gmconfigvalues?.["Spl Arrangement"]?false:true}
                            id={`${parent_id}_patientcategory_specialarrangement_label_textinput`}
                            onChange={(e) => this.setData('special_arrangement', e.target.value)}
                            value={patientData?.special_arrangement}
                            label="Special Arrangement" placeholder="special arrangement" labelStyle={labelStyle} style={borderStyle} hoverColor="#0071F2" 
                        /> */}
              <Select
                id={`${parent_id}_patientcategory_specialarrangement_label_textinput`}
                options={FlterOptions("SPLARRANGEMENTS")}
                onChangeValue={(e) =>
                  setData(patientDatas, "special_arrangement", e)
                }
                value={patientData?.special_arrangement?.value}
                label="Special Arrangement"
                placeholder="select..."
                showArrow
                labelStyle={labelStyle}
                inLineStyles={borderStyle}
                hoverColor="#0071F2"
                disabled={props?.isViewMode}
              />
            </Col>
          )}
          <Col
            id={`${parent_id}_patientcategory_race_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}_patientcategory_race_select`}
              onChangeValue={(e) => setData(patientDatas, "race", e)}
              value={patientData?.race?.value}
              options={FlterOptions3(props?.allMasters, "RACE")}
              label="Race"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.isViewMode}
            />
          </Col>
          {/* <Col
            id={`${parent_id}_patientcategory_ethnicity_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}_patientcategory_ethnicity_select`}
              onChangeValue={(e) => setData(patientDatas, "ethnicity", e)}
              value={patientData?.ethnicity?.value}
              options={FlterOptions3(props?.allMasters, "ETHNICITY")}
              label="Ethnicity"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.isViewMode}
            />
          </Col> */}
          <Col
            id={`${parent_id}_patientcategory_religion_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}_patientcategory_religion_select`}
              onChangeValue={(e) => setData(patientDatas, "religion", e)}
              value={patientData?.religion?.value}
              options={FlterOptions3(props?.allMasters, "RELIGION")}
              label="Religion"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.isViewMode}
            />
          </Col>
          <Col
            id={`${parent_id}_patientcategory_bloodgroup_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ padding: 10 }}
          >
            <Select
              id={`${parent_id}_patientcategory_bloodgroup_select`}
              onChangeValue={(e) => setData(patientDatas, "blood_group", e)}
              value={patientData?.blood_group?.value}
              options={FlterOptions3(props?.allMasters, "BLOODGROUP")}
              label="Blood Group"
              placeholder="select..."
              showArrow
              labelStyle={labelStyle}
              inLineStyles={borderStyle}
              hoverColor="#0071F2"
              disabled={props?.isViewMode}
              isRequired
            />
          </Col>

          <Col
            id={`${parent_id}_patientcategory_patienttype_col`}
            md={patientDatas?.patientInfo?.patientcategory?.length > 2 ? 6 : 4}
            lg={patientDatas?.patientInfo?.patientcategory?.length > 2 ? 6 : 4}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ paddingBottom: 10,paddingTop:10 }}
          >
             <Text
              id={`${parent_id}_communication_language_label_text`}
              style={{
                color: "#6F6F6F",
                fontSize: "12px",
                marginBottom: "6px",
                fontFamily: "pc_regular",
              }}
            >
            Patient Category
            </Text>
            <Autocomplete
              id={`${parent_id}_communication_language_autocomplete`}
              multiple
              fullWidth
              options={FlterOptionsDialysis("PATIENTCATEGORY")}
              style={{ marginLeft: "3px",paddingTop : "5px" ,}}
              size="small"
              getOptionLabel={(option) => option.label}
              value={patientDatas?.patientInfo?.patientcategory ?? []}
              onChange={(e, v) =>
                setPatientCategory(props?.patientData, "patientcategory", v)
              }
              
              className={classes.chips}
              hoverColor="#0071F2"
              // labelStyle={labelStyle}
              // inLineStyles={borderStyle}
              renderInput={(params) => (
                <TextField
                  id={`${parent_id}_communication_select_label_textfield`}
                  style={{ height: "20px" }}
                  {...params}
                  variant="outlined"
                  placeholder="select..."
                  size="small"
                />
              )}
              disabled={props?.isViewMode}
            />
          </Col>
          <Col
            id={`${parent_id}_patientcategory_patient_importance_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ paddingBottom: 10,paddingTop:10 }}
          >
          <Text
              id={`${parent_id}_communication_language_label_text`}
              style={{
                color: "#6F6F6F",
                fontSize: "12px",
                marginBottom: "6px",
                fontFamily: "pc_regular",
              }}
            >
              Income (per month)
            </Text>
            <Grid  style={{marginLeft: "3px",marginTop : "5px", borderRadius: 8,border: "1px solid #E0E0E0" }}>
            <TextfieldWithSelect
               
                InputProps={{
                  id: `durationValue`,
                  type: "text",
                  inputProps: {
                    maxlength: 10,
                    min: 0,
                    max: 9999999999,
                  },
                  disabled:props?.isViewMode,
                  // fullWidth: true,
                  value: patientDatas?.patientInfo?.income,
                  onChange: (e) => {
                    let pattern = /^[0-9]+$/i;
                    let result = pattern.test(e.target.value);
                    if (result || e.target.value === "") {
                      setData2(props?.patientData, "income", e.target.value)
                    } 
                    
                  },
                }}
                AutocompleteProps={{
                  id: `durationUOM`,
                  onLoad: () => {},
                  getOptionLabel: (option) => option?.["label"] ?? "",
                  options: FlterOptionsDialysis("CURRENCY") ?? [],
                  value: patientDatas?.patientInfo?.currency  ?? {},
                  onChange: (event, newValue) => {
                    setIncomeandDistance(props?.patientData, "currency", newValue)
                  },
                  disabled:props?.isViewMode,
                 
                }}
              />
              </Grid>
            
          </Col>
          <Col
            id={`${parent_id}_patientcategory_race_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ paddingBottom: 10,paddingTop:10 }}
          >
          <Text
              id={`${parent_id}_communication_language_label_text`}
              style={{
                color: "#6F6F6F",
                fontSize: "12px",
                marginBottom: "6px",
                fontFamily: "pc_regular",
              }}

            >
              Distance (km)
            </Text>
            <Grid  style={{marginLeft: "3px",marginTop : "5px", borderRadius: 8,border: "1px solid #E0E0E0" }}>
            <TextfieldWithSelect
               
                InputProps={{
                  id: `durationValue`,
                  type: "text",
                  inputProps: {
                    maxlength: 10,
                    min: 0,
                    max: 9999999999,
                  },
                  disabled:props?.isViewMode,
                  // fullWidth: true,
                  value:  patientDatas?.patientInfo?.distance,
                  onChange: (e) => {
                    let pattern = /^[0-9]+$/i;
                    let result = pattern.test(e.target.value);
                    if (result || e.target.value === "")  {
                      setData2(props?.patientData, "distance", e.target.value)
                    } 
                  },
                }}
                AutocompleteProps={{
                  id: `durationUOM`,
                  onLoad: () => {},
                  getOptionLabel: (option) => option?.["label"] ?? "",
                  options: FlterOptionsDialysis("DISTANCEUNIT") ?? [],
                  value: patientDatas?.patientInfo?.distanceunit  ?? {},
                  onChange: (event, newValue) => {
                    setIncomeandDistance(props?.patientData, "distanceunit", newValue)
                  },
                  disabled:props?.isViewMode,
                 
                }}
              />
              </Grid>
          </Col>
          <Col
            id={`${parent_id}_patientcategory_ethnicity_col`}
            md={2}
            lg={2}
            sm={6}
            xs={12}
            className="no-padding-left"
            inLineStyles={{ paddingBottom: 10 ,paddingTop:10}}
          >
          <Text
              id={`${parent_id}_communication_language_label_text`}
              style={{
                color: "#6F6F6F",
                fontSize: "12px",
                marginBottom: "6px",
                fontFamily: "pc_regular",
              }}
            >
             Transport Requirement
            </Text>
           <RadioGroup
          id={`${parent_id}_status_radioButtons_group`}
          name="status1"
          value={patientDatas?.patientInfo?.transportreqd == true ? "true" : patientDatas?.patientInfo?.transportreqd == null ? "false" : "false"}
          onChange= {(event,newValue)=>{
            setIncomeandDistance(props?.patientData, "transportreqd", newValue)
          }}
          style={{marginTop : "5px" }}
          disabled={props?.isViewMode}
        >
          <Div
            id={`${parent_id}_status_radioButtons_div`}
          >
            <FormControlLabel
              id={`${parent_id}_active_status_formLabel`}
              value="true"
              control={
                <Radio
                  id={`${parent_id}_active_status_radio`}
                  color={"primary"}
                />
              }
              label="Yes"
              disabled={props?.isViewMode}
            />
            <FormControlLabel
              id={`${parent_id}_inactive_status_FormLabel`}
              value="false"
              control={
                <Radio
                  id={`${parent_id}_inactive_status_radio`}
                  color={"primary"}
                />
              }
              label="No"
              disabled={props?.isViewMode}
            />
          </Div>
        </RadioGroup>
          </Col>

        </Row>
      </Paper>
    </Div>
  );
};

export default withAllContexts(PatientCategory);
