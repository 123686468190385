import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  tapSelect: {
    "& .selectionDiv": {
      borderRadius: 8,
      margin: "0px 8px 8px 0px",
      borderColor: "#F0F0F0",
      "&:last-of-type": {
        marginRight: 0,
      },
      "& span": {
        fontFamily: "pc_regular",
        fontSize: 14,
        letterSpacing: -0.011,
      },
    },
    "& .active": {
      "& span": {
        fontFamily: "pc_medium !important",
      },
    },
  },
}));
