import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    width: "100%",
  },
});

function FieldContainer({ children, ...props }) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

export default FieldContainer;

FieldContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

FieldContainer.defaultProps = {
  children: null,
};
