import React, { useEffect, useState } from "react";
import withAppBar from "../../hoc/withAppBar";
import withAllContexts from "../../hoc/withAllContexts";
import { Div, Iframe } from "qdm-component-library";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { TopNavbar } from "../../components";

const IFrames = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    console.log(location,"location()")

    const goBackFn = () => {
        console.log(location?.state?.isModify,"location?.state?.isModify")
        navigate(AppRoutes.dashboard);
      };

  return (
   <Div>
   <div>
   <TopNavbar
        goBack={goBackFn}
        iframe={true}
        title={location?.state?.name}
      />
   </div>
   <div style={{
     height: "calc(100vh - 150px)", // 128px = 72px + 56px // 72 - topheader , 56 - topnav
     overflow: "auto",
     display: "block",
     alignItems: "center",
     justifyContent: "center",
   }} >
   <Iframe src={location?.state?.link} allowfullscreen />
   </div>
    
   </Div>
  );
};
export default withAllContexts(withAppBar(IFrames));
