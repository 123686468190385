import React, { useState, useEffect, useContext } from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import useStyles from "./styles";
import {
  Paper,
  H6,
  TextInput,
  Button,
  Container,
  Col,
  BackgroundImage,
} from "qdm-component-library";
import { AlertContext } from "../../context";
import { checkError, AlertProps, fetchQueueData } from "../../utils";
import withAllContexts from "../../hoc/withAllContexts";
import { UIColor } from "../../themes/theme";
import WithUnsecurePage from "../../hoc/withUnsecurePage";


const ChangePassword = (props) => {
  const [userId, setUserId] = useState("");
  const [error, setError] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const classes = useStyles(props);

  const changeState = (key, value) => {
    const newValue = { ...userId, [key]: value };
    setNewPassword(newValue);
  };
  const changeStates = (key, value) => {
    const updatedValue = { ...userId, [key]: value };
    setConfirmPassword(updatedValue);
  };
  const context = useContext(AlertContext);

  // useEffect(() => {
  //   props.backDrop.setBackDrop({
  //     ...props.backDrop,
  //     open: true,
  //     message: "loading...",
  //   });
  //   // let client_id = new URLSearchParams(props.location.search).get("client_id");
  //   // let value = new URLSearchParams(props.location.search).get("value");
  //   let token = new URLSearchParams(props.location.search).get("key");
  //   const fetchUserInfo = async () => {
  //     const userInfo = await props.GET_ORG_USER_INFO({
  //       token: btoa(JSON.stringify({ access_token: token })),
  //       callRefresh: false,
  //     });
  //     if (userInfo.payload.data.email) {
  //       props.backDrop.setBackDrop({
  //         ...props.backDrop,
  //         open: false,
  //         message: "",
  //       });
  //     } else {
  //       //navigate(AppRoutes.expired)
  //       // props.backDrop.setBackDrop({
  //       //   ...props.backDrop,
  //       //   open: false,
  //       //   message: "",
  //       // });
  //     }
  //   };
  //   fetchUserInfo();
  // }, [props]);

  // const handleRecover = async () => {
  //   props.backDrop.setBackDrop({
  //     ...props.backDrop,
  //     open: true,
  //     message: "Updating...",
  //   });
  //   let value = JSON.parse(
  //     atob(new URLSearchParams(props.location.search).get("value"))
  //   );

  //   await props.CHANGE_PASSWORD({
  //     token: btoa(
  //       JSON.stringify({
  //         username: value.email,
  //         updatePassword: newPassword,
  //       })
  //     ),
  //   });

  //   props.backDrop.setBackDrop({
  //     ...props.backDrop,
  //     open: false,
  //     message: "",
  //   });
  //   props.history.push("/");
  //   props.alert.setSnack({
  //     open: true,
  //     severity: AlertProps.severity.success,
  //     msg: "Your Password Updated",
  //     vertical: AlertProps.vertical.top,
  //     horizontal: AlertProps.horizontal.center,
  //     tone: true,
  //   });
  // };

  return (
    <Paper
      key={"0"}
      children="Paper"
      variant="elevation"
      elevation="1"
      style={{
        width: "350px",
        // height: "400px",
        backgroundColor: "#ffffff",
        borderRadius: "16px",
        padding: "32px",
        boxShadow: "0px 8px 69px #0000001A",
        display: "",
      }}
    >
      <H6
        key={"0"}
        name="Change Password"
        style={{
          fontSize: "24px",
          fontWeight: "500",
          color: "#101010",
          marginBottom: 20,
        }}
        inLineStyles={{ fontFamily: "pc_semibold !important" }}
      />
      <TextInput
        isRequired={true}
        label="New Password"
        placeholder=""
        autoComplete="off"
        type="text"
        labelStyle={classes.labelStyle}
        style={{
          borderRadius: 8,
          borderColor: "#E0E0E0",
          marginBottom: 10,
        }}
        hoverColor="#0071F2"
        value={newPassword}
        onChange={(e) => changeState("newPassword", e.target.value)}
      />
      <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
        <li
          style={{
            fontSize: 13,
            marginBottom: 20,
            color: `${newPassword.length > 7 ? "#31b198" : "#9297a5"}`,
          }}
        >
          <div style={{ display: "inline" }}>
            {newPassword.length > 7 ? <>&#10003;</> : <>&bull;</>}{" "}
          </div>
          Contains at least 8 characters
        </li>
      </ul>
      <TextInput
        isRequired={true}
        label="Confirm Password"
        placeholder=""
        type="password"
        autoComplete="c-pass"
        labelStyle={classes.labelStyle}
        style={{
          borderRadius: 8,
          borderColor: "#E0E0E0",
          marginBottom: 10,
        }}
        hoverColor="#0071F2"
        value={confirmPassword}
        onChange={(e) => changeStates("confirmPassword", e.target.value)}
      />

      {error && (
        <H6
          name={error}
          style={{
            fontSize: "12px",
            color: "red",
            textAlign: "left",
          }}
        />
      )}

      <Button
        className="pc_medium"
        key={"3"}
        // onClick={() => {
        //   handleRecover();
        //   navigate(AppRoutes.resetPassword)
        // }}
        disabled={!(newPassword.length > 7 && confirmPassword === newPassword)}
        type="button"
        variant="outlined"
        name={"Change Password"}
        style={{
          margin: "",
          marginTop: "25px",
          width: "100%",
          backgroundColor: btnDisabled ? "" : "#0071F2",
          borderColor: btnDisabled ? "" : "#0071F2",
          borderRadius: "8px",
          fontSize: "14px",
          height: "40px",
        }}
      />
    </Paper>
  );
};

export default withAllContexts(WithUnsecurePage(ChangePassword));
