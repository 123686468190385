import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Dot } from "../../components";
import DrugOrderDetails from "../../components/drugOrderDetails";
import TrashIcon from "../../../../../../assets/img/svg/trashIcon";
import CreateIcon from "../../../../../../assets/img/svg/createIcon";
import InfoIcon from "../../../../../../assets/img/svg/InfoIcon";
import { Divider, Dialog, Popover, Tooltip } from "@material-ui/core";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import FreqIcon from "../../../../../../assets/img/svg/req";
import ScheduleLine from "../../scheduleLine";
import { UIColor } from "../../../../../../themes/theme";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    // border: "1px solid #E0E0E0",
    // borderBottom: '1px solid #E0E0E0',
    // background: theme.palette.background.default,
    position: "relative",
    display: "flex",
    gap: 4,
    alignItems: "center",
    padding: "8px 10px",
    // borderRadius: 8,
  },
  number: {
    background: theme.palette.background.tableHeader,
    width: 23,
    minWidth: 23,
    height: 26,
    minHeight: 26,
    left: 0,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  name: {
    fontSize: 11,
    fontWeight: 500,
    marginRight: 6,
  },
  nametitle: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 6,
  },
  doseContainer: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  dose: {
    color: "#6F6F6F",
    fontSize: 12,
  },
  actionButton: {
    display: "flex",
    alignItems: "center ",
  },
  claicon: {
    width: "14px",
    height: "auto",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid #eee",
    minHeight: "40px",
    alignItems: "center",
    padding: "10px",
    fontSize: "16px",
  },
  paper: { minWidth: "1000px" },
}));

const scheduleLineStyles = {
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  cancelBtn: {
    padding: "8px",
    fontSize: 14,
    marginRight: 10,
    borderColor: UIColor.lineBorderFill.color,
    color: UIColor.primaryColor.color,
    borderRadius: 8,
  },
  filledBtn: {
    backgroundColor: UIColor.primaryColor.color,
    borderColor: UIColor.primaryColor.color,
    padding: "8px",
    fontSize: 14,
    borderRadius: 8,
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "4px",
    fontFamily: `pc_regular !important`,
  },
  labelStyleScheduleLine: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "12px",
    fontFamily: `pc_regular !important`,
  },
  labelStyle_: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "4px",
    fontFamily: `pc_regular !important`,
    margin: "6px 5px",
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: UIColor.error.color,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  colPadding: {
    padding: "7px",
  },
  label: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px !important",
    fontFamily: "pc_regular!important",
    marginBottom: "6px",
    fontWeight: 400,
  },
  required: {
    color: UIColor.error.color,
    marginLeft: 5,
  },
  switch: {
    "& .App1-Mui-checked": {
      color: "#fff",
    },
    "& .App1-Mui-checked + .App1-MuiSwitch-track": {
      backgroundColor: UIColor.primaryColor.color,
      opacity: 1,
    },
  },
  dateWrapper: {
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    padding: 5,
    display: "flex",
    alignItems: "center",
    minHeight: 40,
    "&:hover": {
      borderColor: UIColor.lineBorderFill.color,
    },
  },
  calenderText: {
    marginLeft: 5,
    cursor: "pointer",
    display: "flex",
  },
  dateText: {
    fontSize: 14,
    color: UIColor.secondaryText.color,
    flex: 1,
  },
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
  iconWrapper: {
    borderRadius: 8,
    width: 40,
    height: 40,
    backgroundColor: UIColor.lineBorderFill.color,
    margin: 0,
    position: "absolute",
    marginTop: 22,
  },
  patientInstructionDiv: {
    height: "82px",
    padding: "5px",
    border: "1px solid #c3c3c3",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
  },
};
function AdditiveItem(props) {
  // <-----------------Props---------------->
  const { index, practitionerId, data, onEditClick, onDeleteClick, allData } =
    props;

  // <------------------Hooks--------------->
  const classes = useStyles(props);

  const [info, setInfo] = useState(false);

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });

  const handleClose = () => {
    setInfo(!info);
  };

  return (
    <div
      className={classes.root}
      style={{
        borderBottom: index !== allData?.length - 1 && "1px solid #E0E0E0",
      }}
    >
      <div className={classes.number}>
        <Typography>{index + 1}</Typography>
      </div>

      <Typography className={classes.nametitle} noWrap>
        {data?.drugCode?.label ?? ""}
      </Typography>

      <Tooltip title="info" arrow>
        <InfoIcon
          onClick={(e) => {
            setInfo(!info);
          }}
        />
      </Tooltip>

      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        style={{ margin: 4 }}
      />

      <Typography className={classes.name}>
        {data?.dosageValue ?? data?.ivVolumeValue ?? ""}&nbsp;
        {data?.dosageUOM?.UOM ?? data?.ivVolumeUOM?.UOM ?? ""}
      </Typography>
      <Dot marginLeft={8} />

      <Typography className={classes.name}>
        {data?.route?.label ?? ""}{" "}
      </Typography>
      <Dot marginLeft={8} />

      <Typography className={classes.name}>
        {data?.frequency?.label ?? ""}
      </Typography>

      {/* <img
                {...bindTrigger(popupState)}
                style={{ width: '24px', margin: '-3px' }} src={FreqIcon} /> */}
      <Typography {...bindTrigger(popupState)}>
        <FreqIcon
          style={{
            width: "28px",
            height: "auto",
          }}
        />
      </Typography>
      <Dot marginLeft={8} />

      <Typography className={classes.name}>
        {data?.durationValue ?? ""}&nbsp;{data?.durationUOM?.label ?? ""}
      </Typography>
      <Dot marginLeft={8} />

      <Typography className={classes.name} noWrap>
        {data?.startDate ? moment(data?.startDate).format("DD/MM/YYYY") : ""} -{" "}
        {data?.endDate ? moment(data?.endDate).format("DD/MM/YYYY") : ""}
      </Typography>
      <Dot marginLeft={8} />

      <Typography className={classes.name}>
        {data?.requestorDetail?.orderBy?.label +
          (practitionerId === data?.requestorDetail?.orderBy?.value
            ? " (You)"
            : "") ?? ""}
      </Typography>

      <div style={{ flex: 1 }}></div>

      <div className={classes.actionButton}>
        <IconButton size="small" onClick={onDeleteClick}>
          <TrashIcon />
        </IconButton>
        <IconButton size="small" onClick={onEditClick}>
          <CreateIcon />
        </IconButton>
      </div>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.prnPopoverPaper,
        }}
      >
        <div style={{ display: "flex", padding: "12px" }}>
          <ScheduleLine
            type={data?.frequency?.type}
            data={data?.frequency}
            styles={scheduleLineStyles}
            allDisabled={true}
          />
        </div>
      </Popover>

      <Dialog
        open={info}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.header}>
          <span>ORDER DETAILS</span> <CloseIcon onClick={() => handleClose()} />
        </div>

        {/* ORDER DETAILS */}
        <DrugOrderDetails
          id={data?._id ?? ""}
          showPractitionerEditButton={false}
          handleEditClick={() => null}
        />
      </Dialog>
    </div>
  );
}

export default AdditiveItem;

AdditiveItem.defaultProps = {};

AdditiveItem.propTypes = {};
