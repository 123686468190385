import React, { useEffect } from "react";
import {
  Div,
  Select,
  Col,
  Row,
  Paper,
  H6,
  Radio,
  TextInput,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../../hoc/withAllContexts";
import moment from "moment";
import { UIColor, utcTOLocal } from "../../../utils";

function EncounterInfo(props) {
  //;
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    selectBorderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    inputBorderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
      background: "#F0F0F0",
    },
    errorborderStyle: {
      borderRadius: 8,
      borderColor: "red",
    },
    borderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
      // background: '#F0F0F0'
    },
    errorBorder: {
      borderRadius: 8,
      border: "1px solid #f334466",
    },
  };
  useEffect(() => {
    if (!props?.isEdit && !props?.isRevise) {
      // let patientData = props.visitData;
      // const { allMasters } = props;
      // let filteredArray = allMasters.filter((obj) => obj.hasOwnProperty(["REASON"]));
      // const data = filteredArray[0]?.["REASON"].Value;
      //   if(data){
      //   data.find((item)=>{
      //       if(item?._id === props?.reasonID){
      //           patientData.encounter["visit_reason"] = {
      //             value: item?.display,
      //             _id: item?._id,
      //           };
      //           if (patientData.encounter.error) {
      //             patientData.encounter.error["visit_reason"] = false;
      //           }
      //           patientData.setVisitDetails({
      //             ...patientData,
      //           });
      //           return;
      //         // }
      //       }
      //   })}
      //   //PATCH START TYPE
      //   let filteredArray1 = allMasters.filter((obj) => obj.hasOwnProperty(["STARTTYPE"]));
      //   const data_ = filteredArray1[0]?.["STARTTYPE"].Value;
      //    if(data_){
      //     data_.find((item)=>{
      //       let codingData = item?.coding;
      //       if(item?.display === "Elective"){
      //         // if(!patientData.encounter["start_type"]?.value){
      //           patientData.encounter["start_type"] = {
      //             value: item?.display,
      //             _id: item?._id,
      //           };
      //           if (patientData.encounter.error) {
      //             patientData.encounter.error["start_type"] = false;
      //           }
      //           patientData.setVisitDetails({
      //             ...patientData,
      //           });
      //           return;
      //         // }
      //       }
      //   })
      //   }
      //   // let filteredArray2 = allMasters.filter((obj) => obj.hasOwnProperty(["VISITTYPE"]));
      //   // const data3 = filteredArray2[0]?.["VISITTYPE"].Value;
      //   // if(data3){
      //   //   data3.find((item)=>{
      //   //     let codingData = item?.display;
      //   //     if(item?.display === props?.encount?.visit_type?.display){
      //   //       // if(!patientData.encounter["start_type"]?.value){
      //   //         patientData.encounter["visit_type"] = {
      //   //           value: props?.encount?.visit_type?.display,
      //   //           _id: props?.encount?.visit_type?._id,
      //   //         };
      //   //         if (patientData.encounter.error) {
      //   //           patientData.encounter.error["visit_type"] = false;
      //   //         }
      //   //         patientData.encounter["class"] = {
      //   //           value: props?.encount?.class?.display,
      //   //           _id: props?.encount?.class?._id,
      //   //         };
      //   //         patientData.encounter["type"] = {
      //   //           value: props?.encount?.type?.shortDesc,
      //   //           _id: props?.encount?.type?._id,
      //   //         };
      //   //         patientData.setVisitDetails({
      //   //           ...patientData,
      //   //         });
      //   //         return;
      //   //       // }
      //   //     }
      //   // })
      //   // }
    } else {
      let patientData = props.visitData;
      const { allMasters } = props;
      let filteredArray = allMasters.filter((obj) =>
        obj.hasOwnProperty(["REASON"])
      );
      const data = filteredArray[0]?.["REASON"].Value;
      if (data) {
        data.find((item) => {
          if (item?._id === props?.encount?.reason?._id) {
            patientData.encounter["visit_reason"] = {
              value: props?.encount?.reason?.display,
              _id: props?.encount?.reason?._id,
            };
            if (patientData.encounter.error) {
              patientData.encounter.error["visit_reason"] = false;
            }
            // patientData.setVisitDetails({
            //   ...patientData,
            // });
            // patientData.setVisitDetails({
            //   ...patientData,
            // });
            return;
            // }
          }
        });
      }

      //PATCH START TYPE
      let filteredArray1 = allMasters.filter((obj) =>
        obj.hasOwnProperty(["STARTTYPE"])
      );
      const data_ = filteredArray1[0]?.["STARTTYPE"].Value;
      if (data_) {
        data_.find((item) => {
          if (item?.display === props?.encount?.start_type?.display) {
            // if(!patientData.encounter["start_type"]?.value){
            patientData.encounter["start_type"] = {
              value: props?.encount?.start_type?.display,
              _id: props?.encount?.start_type?._id,
            };
            if (patientData.encounter.error) {
              patientData.encounter.error["start_type"] = false;
            }
            // patientData.setVisitDetails({
            //   ...patientData,
            // });
            // patientData.setVisitDetails({
            //   ...patientData,
            // });
            return;
            // }
          }
        });
      }

      let filteredArray2 = allMasters.filter((obj) =>
        obj.hasOwnProperty(["VISITTYPE"])
      );
      const data3 = filteredArray2[0]?.["VISITTYPE"].Value;
      if (data3) {
        data3.find((item) => {
          let codingData = item?.display;
          if (item?.display === props?.encount?.visit_type?.display) {
            // if(!patientData.encounter["start_type"]?.value){
            patientData.encounter["visit_type"] = {
              value: props?.encount?.visit_type?.display,
              _id: props?.encount?.visit_type?._id,
            };
            if (patientData.encounter.error) {
              patientData.encounter.error["visit_type"] = false;
            }
            patientData.encounter["class"] = {
              value: props?.encount?.class?.display,
              _id: props?.encount?.class?._id,
            };
            patientData.encounter["type"] = {
              value: props?.encount?.type?.shortDesc,
              _id: props?.encount?.type?._id,
            };
            // patientData.setVisitDetails({
            //   ...patientData,
            // });
            // patientData.setVisitDetails({
            //   ...patientData,
            // });
            return;
            // }
          }
        });
      }
    }
  }, []);

  //   React.useEffect(() => {
  // console.log("")
  //       },[props.visitData])

  React.useEffect(() => {
    try {
      if (!props?.isEdit && !props?.isEdited) {
        setDetails("visit_type", props?.loadDefaultData?.visit_type);
        setDetails("visit_reason", props?.loadDefaultData?.visit_reason);
        setDetails("start_type", props?.loadDefaultData?.start_type);
        setDetails("class", props?.loadDefaultData?.class);
        setDetails("type", props?.loadDefaultData?.type);
        setDetails(
          "mode",
          props?.loadDefaultData?.consult_mode?.value.toLowerCase()
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  React.useEffect(() => {
    try {
      if (props?.isEdited && props?.locationData?.encounter && !props?.isEdit) {
        setDetails("visit_type", props?.locationData?.encounter?.visit_type);
        setDetails(
          "visit_reason",
          props?.locationData?.encounter?.visit_reason
        );
        setDetails("start_type", props?.locationData?.encounter?.start_type);
        setDetails("class", props?.locationData?.encounter?.class);
        setDetails("type", props?.locationData?.encounter?.type);
        setDetails("mode", props?.locationData?.encounter?.mode);
      }
    } catch (error) {
      console.log(error);
    }
  }, [props?.isEdited, props?.locationData?.encounter]);

  // React.useEffect(()=>{
  //   try {
  //     if(!props?.isEdit)
  //     {
  //     setDetails("visit_type",FlterOptions3("VISITTYPE")?.[0])
  //     setDetails("visit_reason",FlterOptions3("REASON")?.[0])
  //   }

  //   } catch (error) {
  //     console.log(error)
  //   }
  // },[])
  const { encounter } = props.visitData;

  const setDetails = (name, value) => {
    let patientData = props.visitData;
    if (name === "type") {
      const classId = patientData?.encounter?.class?._id;
      if (classId) {
        const includes = props.encounterType?.some((e) => e._id === classId);
        // if (!includes) {
        //   patientData.encounter["class"] = undefined;
        // }
      }
      // setDetails("class", '')
      props.getEncounterClass(value); //FIXME - it will not work
    }
    patientData.encounter[name] = value;
    if (patientData.encounter.error) {
      patientData.encounter.error[name] = false;
    }
    patientData.setVisitDetails({
      ...patientData,
    });
  };

  const FlterOptions = (type, parent) => {
    let patientData = props.visitData;
    const { allMasters } = props;
    var lista = [];

    if (type === "ENCOUNTERCLASS") {
      if (parent?._id) {
        lista = allMasters.ENCOUNTERCLASS[parent._id];
      }
    } else {
      const data = allMasters?.[type];
      for (let index in data) {
        var datatemp = {
          value: data[index]?.coding?.display,
          _id: data[index]?.coding?._id,
        };
        lista.push(datatemp);
      }
    }

    return lista;
  };

  const FlterOptions3 = (type) => {
    let allMasters = props?.allMasters;
    let filteredArray = allMasters.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?.display,
        _id: data[index]?._id,
      };
      list.push(datatemp);
    }
    return list;
  };

  const { parent_id, encounterType = [], encounterClass } = props;
  const {
    labelStyle,
    selectBorderStyle,
    inputBorderStyle,
    errorborderStyle,
    errorBorder,
    borderStyle,
  } = styles;
  const { Persondata, walkin } = props;
  return (
    <Div id={`${parent_id}_encounterinfo_parent_div`} className="pv-root">
      <Paper id={`${parent_id}_encounterinfo_parent_paper`} className="pl-root">
        <Row
          id={`${parent_id}_encounterinfo_parent_row`}
          className="en-actions-root"
        >
          {!props.isEdit && (
            <Col
              id={`${parent_id}_encounterinfo_parent_col`}
              md={12}
              lg={12}
              sm={12}
              xs={12}
              className="no-padding-left"
            >
              <Div id={`${parent_id}_encounterinfo_label_div`}>
                <H6
                  id={`${parent_id}_encounterinfo_label_h6`}
                  className="en-title space-padding-bottom"
                >
                  ENCOUNTER INFO
                </H6>
              </Div>
              <Row
                id={`${parent_id}_encounterinfo_sub_row`}
                className="en-actions-root"
              >
                <Col
                  id={`${parent_id}_encounderinfo_encountertype_col`}
                  md={3}
                  lg={3}
                  sm={12}
                  xs={12}
                  className="no-padding-left"
                >
                  <Select
                    id={`${parent_id}_encounderinfo_encountertype_select`}
                    error={
                      encounter?.error && encounter?.error["type"]
                        ? true
                        : false
                    }
                    options={encounterType}
                    //options={encounterType.map(it => ({label: it?.encType, value: it?.shortDesc, _id: it?.encType }))}
                    value={encounter?.type?.value ?? null}
                    onChangeValue={(e) => setDetails("type", e)}
                    label="Type"
                    placeholder="select..."
                    showArrow
                    disabled={!walkin}
                    labelStyle={labelStyle}
                    inLineStyles={
                      encounter?.error && encounter?.error["type"]
                        ? errorBorder
                        : borderStyle
                    }
                    hoverColor="#0071F2"
                    isRequired={true}
                  />
                </Col>
                <Col
                  id={`${parent_id}_encounderinfo_encounterclass_col`}
                  md={4}
                  lg={4}
                  sm={12}
                  xs={12}
                  className="no-padding-left"
                >
                  <Select
                    id={`${parent_id}_encounderinfo_encounterclass_select`}
                    error={
                      encounter?.error && encounter?.error["class"]
                        ? true
                        : false
                    }
                    // options={FlterOptions("ENCOUNTERCLASS", encounter?.type)}
                    // options={encounterClass.map(it => ({label: it?.encClsType ? it?.encClsType : it?._id ? it?._id : "", value: it?.shortDesc }))}
                    options={FlterOptions3("ENCOUNTERCLASS", encounter?.type)}
                    value={encounter?.class?.value ?? null}
                    onChangeValue={(e) => setDetails("class", e)}
                    label="Class"
                    placeholder="select..."
                    showArrow
                    disabled={!walkin || !encounter?.type?._id}
                    labelStyle={labelStyle}
                    inLineStyles={
                      encounter?.error && encounter?.error["class"]
                        ? errorBorder
                        : borderStyle
                    }
                    hoverColor="#0071F2"
                    isRequired={true}
                  />
                </Col>
                <Col
                  id={`${parent_id}_encounderinfo_mode_col`}
                  md={2}
                  lg={2}
                  sm={12}
                  xs={12}
                  className="no-padding-left"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <H6
                    id={`${parent_id}_encounderinfo_mode_label_h6`}
                    className="default-size pc_regular"
                    style={{
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    Mode
                  </H6>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <Radio
                      id={`${parent_id}_encounderinfo_mode_radio`}
                      checked={
                        encounter?.mode ===
                        process.env.REACT_APP_CODING_APMNT_DIRECT_CONSULT_MODE
                      }
                      style={{
                        textAlign: "center",
                        display: "inline-flex",
                      }}
                      label="Direct"
                      name="radio-button-demo"
                      color="primary"
                      // disabled={!walkin}

                      onChange={(e) =>
                        setDetails(
                          "mode",
                          process.env.REACT_APP_CODING_APMNT_DIRECT_CONSULT_MODE
                        )
                      }
                    />
                    <Radio
                      id={`${parent_id}_encounderinfo_mode_radio`}
                      checked={
                        encounter?.mode ===
                        process.env.REACT_APP_CODING_APMNT_VIDEO_CONSULT_MODE
                      }
                      style={{
                        textAlign: "center",
                        display: "inline-flex",
                      }}
                      label="Video"
                      name="radio-button-demo"
                      color="primary"
                      // disabled={!walkin}
                      onChange={(e) =>
                        setDetails(
                          "mode",
                          process.env.REACT_APP_CODING_APMNT_VIDEO_CONSULT_MODE
                        )
                      }
                    />
                  </div>
                  {/* <Select value={encounter?.mode?.value ?? null} onChangeValue={(e) => setDetails("mode", e)} label="Mode" placeholder="select..." showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor="#0071F2" /> */}
                </Col>
                <Col
                  id={`${parent_id}_encounderinfo_starttype_col`}
                  md={3}
                  lg={3}
                  sm={12}
                  xs={12}
                  className="no-padding-left"
                >
                  <Select
                    error={
                      encounter?.error && encounter?.error["start_type"]
                        ? true
                        : false
                    }
                    id={`${parent_id}_encounderinfo_starttype_select`}
                    options={FlterOptions3("STARTTYPE")}
                    value={encounter?.start_type?.value ?? null}
                    onChangeValue={(e) => setDetails("start_type", e)}
                    label="Start Type"
                    placeholder="select..."
                    showArrow
                    labelStyle={labelStyle}
                    inLineStyles={
                      encounter?.error && encounter?.error["start_type"]
                        ? errorBorder
                        : borderStyle
                    }
                    hoverColor="#0071F2"
                    disabled={!walkin}
                    isRequired={true}
                  />
                </Col>
                <Col
                  id={`${parent_id}_encounderinfo_visittype_col`}
                  md={3}
                  lg={3}
                  sm={12}
                  xs={12}
                  className="no-padding-left space-padding-top"
                >
                  <Select
                    id={`${parent_id}_encounderinfo_visittype_select`}
                    value={encounter?.visit_type?.value}
                    error={
                      encounter?.error && encounter?.error["visit_type"]
                        ? true
                        : false
                    }
                    options={FlterOptions3("VISITTYPE")}
                    disabled={!walkin}
                    onChangeValue={(e) => setDetails("visit_type", e)}
                    isRequired={true}
                    label="Visit Type"
                    //placeholder="select..."
                    showArrow
                    labelStyle={labelStyle}
                    inLineStyles={
                      encounter?.error && encounter?.error["visit_type"]
                        ? errorborderStyle
                        : selectBorderStyle
                    }
                    hoverColor="#0071F2"
                  />
                </Col>
                <Col
                  id={`${parent_id}_encounderinfo_visitreason_col`}
                  md={9}
                  lg={9}
                  sm={12}
                  xs={12}
                  className="no-padding-left space-padding-top"
                >
                  <Select
                    disabled={!walkin}
                    id={`${parent_id}_encounderinfo_visitreason_select`}
                    options={FlterOptions3("REASON")}
                    // helperText={
                    //   encounter?.error && encounter?.error["visit_reason"]
                    //     ? "Is required"
                    //     : ""
                    // }
                    error={
                      encounter?.error && encounter?.error["visit_reason"]
                        ? true
                        : false
                    }
                    value={encounter?.visit_reason?.value}
                    onChangeValue={(e) => setDetails("visit_reason", e)}
                    isRequired={true}
                    label="Reason for Visit"
                    // placeholder="select1..."
                    showArrow
                    labelStyle={labelStyle}
                    inLineStyles={
                      encounter?.error && encounter?.error["visit_reason"]
                        ? errorborderStyle
                        : selectBorderStyle
                    }
                    hoverColor="#0071F2"
                  />
                </Col>
              </Row>
            </Col>
          )}
          {props.isEdit && (
            <>
              <Col
                id={`${parent_id}_encounterinfo_parent_col`}
                md={8}
                lg={8}
                sm={12}
                xs={12}
                className="no-padding-left"
              >
                <Div id={`${parent_id}_encounterinfo_label_div`}>
                  <H6
                    id={`${parent_id}_encounterinfo_label_h6`}
                    className="en-title space-padding-bottom"
                  >
                    ENCOUNTER INFO
                  </H6>
                </Div>
                <Row
                  id={`${parent_id}_encounterinfo_sub_row`}
                  className="en-actions-root"
                >
                  <Col
                    id={`${parent_id}_encounderinfo_encountertype_col`}
                    md={3}
                    lg={3}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_encounderinfo_encountertype_select`}
                      // options={FlterOptions("ENCOUNTERTYPE")}
                      options={encounterType.map((it) => ({
                        label: it?.encType,
                        value: it?.shortDesc,
                        _id: it?.encType,
                      }))}
                      value={encounter?.type?.value ?? null}
                      onChangeValue={(e) => setDetails("type", e)}
                      label="Type"
                      placeholder="select..."
                      showArrow
                      labelStyle={labelStyle}
                      inLineStyles={selectBorderStyle}
                      hoverColor="#0071F2"
                      disabled
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_encounderinfo_encounterclass_col`}
                    md={4}
                    lg={4}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_encounderinfo_encounterclass_select`}
                      options={FlterOptions3("ENCOUNTERCLASS", encounter?.type)}
                      value={encounter?.class?.value ?? null}
                      onChangeValue={(e) => setDetails("class", e)}
                      label="Class"
                      placeholder="select..."
                      showArrow
                      labelStyle={labelStyle}
                      inLineStyles={selectBorderStyle}
                      hoverColor="#0071F2"
                      disabled
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_encounderinfo_mode_col`}
                    md={3}
                    lg={3}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <H6
                      id={`${parent_id}_encounderinfo_mode_label_h6`}
                      className="default-size pc_regular"
                      style={{
                        textAlign: "left",
                        paddingLeft: "10px",
                      }}
                    >
                      Mode
                    </H6>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Radio
                        id={`${parent_id}_encounderinfo_mode_radio`}
                        checked={
                          encounter?.mode ===
                          process.env.REACT_APP_CODING_APMNT_DIRECT_CONSULT_MODE
                        }
                        style={{
                          textAlign: "center",
                          display: "inline-flex",
                        }}
                        label="Direct"
                        name="radio-button-demo"
                        color="default"
                        disabled
                        onChange={(e) =>
                          setDetails(
                            "mode",
                            process.env
                              .REACT_APP_CODING_APMNT_DIRECT_CONSULT_MODE
                          )
                        }
                      />
                      <Radio
                        id={`${parent_id}_encounderinfo_mode_radio`}
                        checked={
                          encounter?.mode ===
                          process.env.REACT_APP_CODING_APMNT_VIDEO_CONSULT_MODE
                        }
                        style={{
                          textAlign: "center",
                          display: "inline-flex",
                        }}
                        label="Video"
                        name="radio-button-demo"
                        color="default"
                        disabled
                        onChange={(e) =>
                          setDetails(
                            "mode",
                            process.env
                              .REACT_APP_CODING_APMNT_VIDEO_CONSULT_MODE
                          )
                        }
                      />
                    </div>
                    {/* <Select value={encounter?.mode?.value ?? null} onChangeValue={(e) => setDetails("mode", e)} label="Mode" placeholder="select..." showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor="#0071F2" /> */}
                  </Col>
                  <Col
                    id={`${parent_id}_encounderinfo_starttype_col`}
                    md={2}
                    lg={2}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_encounderinfo_starttype_select`}
                      options={FlterOptions3("STARTTYPE")}
                      value={encounter?.start_type?.value ?? null}
                      onChangeValue={(e) => setDetails("start_type", e)}
                      label="Start Type"
                      placeholder="select..."
                      showArrow
                      labelStyle={labelStyle}
                      inLineStyles={selectBorderStyle}
                      hoverColor="#0071F2"
                      disabled
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_encounderinfo_visittype_col`}
                    md={3}
                    lg={3}
                    sm={12}
                    xs={12}
                    className="no-padding-left space-padding-top"
                  >
                    <Select
                      id={`${parent_id}_encounderinfo_visittype_select`}
                      value={encounter?.visit_type?.value}
                      // helperText={
                      //   encounter?.error && encounter?.error["visit_type"]
                      //     ? "Is required"
                      //     : ""
                      // }
                      error={
                        encounter?.error && encounter?.error["visit_type"]
                          ? true
                          : false
                      }
                      options={FlterOptions3("VISITTYPE")}
                      // options={
                      //     [
                      //         { "value": "First Visit" },
                      //         { "value": "Other Visits" },
                      //         { "value": "Walkin" },
                      //         { "value": "New Patient" },
                      //         { "value": "Follow-up Visit" },
                      //         { "value": "Recurring Visit" },
                      //         { "value": "Emergency Visit" },
                      //     ]
                      // }
                      onChangeValue={(e) => setDetails("visit_type", e)}
                      isRequired={true}
                      label="Visit type"
                      // placeholder="select..."
                      showArrow
                      labelStyle={labelStyle}
                      inLineStyles={selectBorderStyle}
                      hoverColor="#0071F2"
                      disabled
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_encounderinfo_visitreason_col`}
                    md={9}
                    lg={9}
                    sm={12}
                    xs={12}
                    className="no-padding-left space-padding-top"
                  >
                    <Select
                      id={`${parent_id}_encounderinfo_visitreason_select`}
                      options={FlterOptions3("REASON")}
                      // helperText={
                      //   encounter?.error && encounter?.error["visit_reason"]
                      //     ? "Is required"
                      //     : ""
                      // }
                      error={
                        encounter?.error && encounter?.error["visit_reason"]
                          ? true
                          : false
                      }
                      value={
                        encounter?.visit_reason?.value ??
                        FlterOptions3("REASON")[0]?.value
                      }
                      onChangeValue={(e) => setDetails("visit_reason", e)}
                      isRequired={true}
                      label="Reason for Visit"
                      // placeholder="select..."
                      showArrow
                      labelStyle={labelStyle}
                      inLineStyles={selectBorderStyle}
                      hoverColor="#0071F2"
                      disabled
                    />
                  </Col>
                </Row>
              </Col>

              <Col lg={4} md={4} sm={12} xs={12} className="no-padding-left">
                <Row className="en-encounter-right">
                  <Col
                    md={6}
                    lg={6}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                  >
                    <TextInput
                      placeholder=""
                      label="Encounter No."
                      disabled={true}
                      value={props.encount?._id}
                      labelStyle={labelStyle}
                      style={inputBorderStyle}
                    />
                  </Col>
                  <Col
                    md={6}
                    lg={6}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                  >
                    <TextInput
                      placeholder=""
                      value={utcTOLocal(
                        props?.encount?.encounterdate,
                        "DD/MM/YYYY, h:mm a"
                      )}
                      label="Date/Time"
                      disabled={true}
                      labelStyle={labelStyle}
                      style={inputBorderStyle}
                    />
                  </Col>
                  <Col
                    md={6}
                    lg={6}
                    sm={12}
                    xs={12}
                    className="no-padding-left space-padding-top"
                  >
                    <TextInput
                      placeholder=""
                      label="Status"
                      value={
                        props?.encount?.activestatus ? "Active" : "Inactive"
                      }
                      disabled={true}
                      labelStyle={labelStyle}
                      style={inputBorderStyle}
                    />
                  </Col>
                  <Col
                    md={6}
                    lg={6}
                    sm={12}
                    xs={12}
                    className="no-padding-left space-padding-top"
                  >
                    <TextInput
                      placeholder=""
                      label="Episode of Care"
                      disabled={true}
                      labelStyle={labelStyle}
                      style={inputBorderStyle}
                      value={props?.encount?.episodeOfCare_id}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Paper>
    </Div>
  );
}

export default withAllContexts(EncounterInfo);
