import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  urls,
  dbName,
  query_ids,
  queries,
} from "../../utils";
import { childJSON } from "./orders/child";
import { generateJSON } from "./orders/saveJson";
import { generateResJson } from "./orders/generateresponse";

const TREATMENT_PROCEDURE_PRIORITY = createAsyncThunk(
  `DiagnosisMasterSlice/getorderpriority`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["LABPRIORITY"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.LABPRIORITY?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const TREATMENT_PROCEDURE_GETCATEGORY = createAsyncThunk(
  `DiagnosisMasterSlice/getordercategory`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: query_ids["gettreatmentordercategory"],
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let arr = [];

      data.map((val) => {
        arr.push({
          value: val?._id,
          label: val?.shortdesc ?? "",
          _id: val?._id,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const TREATMENT_PROCEDURE_NATURE = createAsyncThunk(
  `DiagnosisMasterSlice/getordernature`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["ORDERNATURE"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.ORDERNATURE?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const TREATMENT_PROCEDURE_SAVE = createAsyncThunk(
	`DiagnosisMasterSlice/savetreatment`,
	async (payload = {}, { rejectWithValue }) => {
		try {
      const {
        list,
        dateArr = [],
        isupdate,
        childIds,
        deleteArr = [],
      } = payload;
      let body = generateJSON(list, "TREAT");

      const data = await fetchData(
        { body: JSON.stringify(body) },
        // __uspsertUrl__
        // __insertRCMurl__
        urls.insertOrder
      );

      await Promise.all(
        deleteArr?.map(async (val) => {
          return await fetchData(
            { body: queries.laborder_delete(val) },
            urls.deleteOrder
          );
        })
      );

      // var recurrentData;
      await Promise.all(
        dateArr?.reverse()?.map(async (v, i) => {
          const datas = childJSON(
            list,
            isupdate ? childIds[i] : data.Result[0].properties.doc._id,
            isupdate,
            v,
            "TREAT"
          );
          return await fetchData(
            { body: JSON.stringify(datas) },
            // __uspsertUrl__
            // __insertRCMurl__
            urls.insertOrder
          );
        })
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
	}
);

const TREATMENT_PROCEDURE_DELETE = createAsyncThunk(
	`DiagnosisMasterSlice/treatmentdelete`,
	async (payload = {}, { rejectWithValue }) => {
		try {
      const { id,isReccDel } = payload;
      let body = {
        orderlineId: id,
        isRecurrentDelete:isReccDel ,
      };

      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.deleteOrder
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
	}
);

const TRAEMENT_READ = createAsyncThunk(
	`DiagnosisMasterSlice/treatmentread`,
	async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { encounter_id, order_id, patientid, startdate } = payload;
      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounter_id,
          ord_Category: order_id,
          facilityid: "",
          tenantid: "",
          // CA_OrderLine: "",
          patientid:patientid,
          startdate:startdate,
        },
        queryid: "bd4de695-ff18-4054-9bf0-8b26d3ad0525",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      let res = generateResJson(data);

      return {
        ...defaultState.List,
        data: res,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
	}
);


const TreatmentSlice = createSlice({
  name: "DiagnosisMasterApiSlice",
  initialState: {
    teartment_priority: {
      ...defaultState.List,
    },
    teartment_getcategory: {
      ...defaultState.List,
    },
    teartment_nature: {
      ...defaultState.List,
    },
    teartment_save: {
			...defaultState.List,
		},
    teartment_read: {
      ...defaultState.List,
    },
    teartment_delete: {
			...defaultState.List,
		},
  },
  extraReducers: {
    [TREATMENT_PROCEDURE_PRIORITY.fulfilled]: (state, action) => {
      state.teartment_priority = action?.payload ?? {};
    },
    [TREATMENT_PROCEDURE_PRIORITY.pending]: (state, action) => {
      state.teartment_priority.loading = true;
    },
    [TREATMENT_PROCEDURE_PRIORITY.rejected]: (state, action) => {
      state.teartment_priority = action?.payload ?? {};
    },

    [TREATMENT_PROCEDURE_GETCATEGORY.fulfilled]: (state, action) => {
      state.teartment_getcategory = action?.payload ?? {};
    },
    [TREATMENT_PROCEDURE_GETCATEGORY.pending]: (state, action) => {
      state.teartment_getcategory.loading = true;
    },
    [TREATMENT_PROCEDURE_GETCATEGORY.rejected]: (state, action) => {
      state.teartment_getcategory = action?.payload ?? {};
    },

    [TREATMENT_PROCEDURE_NATURE.fulfilled]: (state, action) => {
      state.teartment_nature = action?.payload ?? {};
    },
    [TREATMENT_PROCEDURE_NATURE.pending]: (state, action) => {
      state.teartment_nature.loading = true;
    },
    [TREATMENT_PROCEDURE_NATURE.rejected]: (state, action) => {
      state.teartment_nature = action?.payload ?? {};
    },
    // TREATMENT_PROCEDURE_SAVE
		[TREATMENT_PROCEDURE_SAVE.fulfilled]: (state, action) => {
			state.teartment_save = action?.payload ?? {};
		},
		[TREATMENT_PROCEDURE_SAVE.pending]: (state, action) => {
			state.teartment_save.loading = true;
		},
		[TREATMENT_PROCEDURE_SAVE.rejected]: (state, action) => {
			state.teartment_save = action?.payload ?? {};
		},
    // TRAEMENT_READ
		[TRAEMENT_READ.fulfilled]: (state, action) => {
			state.teartment_read = action?.payload ?? {};
		},
		[TRAEMENT_READ.pending]: (state, action) => {
			state.teartment_read.loading = true;
		},
		[TRAEMENT_READ.rejected]: (state, action) => {
			state.teartment_read = action?.payload ?? {};
		},
    // TREATMENT_PROCEDURE_DELETE
		[TREATMENT_PROCEDURE_DELETE.fulfilled]: (state, action) => {
			state.teartment_delete = action?.payload ?? {};
		},
		[TREATMENT_PROCEDURE_DELETE.pending]: (state, action) => {
			state.teartment_delete.loading = true;
		},
		[TREATMENT_PROCEDURE_DELETE.rejected]: (state, action) => {
			state.teartment_delete = action?.payload ?? {};
		},
  },
});



export const TreatmentMastersActions = {
  TREATMENT_PROCEDURE_PRIORITY,
  TREATMENT_PROCEDURE_GETCATEGORY,
  TREATMENT_PROCEDURE_NATURE,
  TREATMENT_PROCEDURE_SAVE,
  TRAEMENT_READ,
  TREATMENT_PROCEDURE_DELETE
};

export default TreatmentSlice;
