import React from 'react';
import { Div, Text } from 'qdm-component-library';
import { makeStyles } from '@material-ui/core';
import { UIColor } from '../../../../themes/theme';
const useStyles = makeStyles({
    statusCard: {
        borderRadius: 8,
        padding: '5px 10px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginRight: 5,
        fontFamily: 'pc_regular',
        fontSize: 12,
        '&:last-of-type': {
            marginRight: 0
        }
    }
});

const StatusCard = (props) => {

    const classes = useStyles(props);

    const styles = {
        dot: {
            width: 6,
            height: 6,
            borderRadius: '50%',
            marginRight: '5px',
        },
        label: {
            fontSize: 12
        },

    };
    const { parent_id } = props;
    return (
        <div
            id={`${parent_id}_status_card_parent_div`}
            className={classes.statusCard}
            style={{
                backgroundColor: props?.activeStatus?.value === props?.value?.value ? props?.activebackgroundColor : props?.backgroundColor,
            }}
            onClick={() => props?.changeStatus(props?.value)}
        >
            <Div
                id={`${parent_id}_status_card_sub_div`}
                style={{
                    ...styles.dot,
                    backgroundColor: props?.activeStatus?.value === props?.value?.value ? "#fff" : props?.color,
                }} />
            {
                props?.value?.label &&
                <Text
                    id={`${parent_id}_status_card_${props?.value?.label.replaceAll(" ", "_")}_text`}
                    className="pc_regular"
                    style={{
                        ...styles.label,
                        color: props?.activeStatus?.value === props?.value?.value ? props?.activeColor : props?.color,
                    }}
                >
                    {props?.value?.label}
                </Text>}
        </div>
    )
};

export default StatusCard;

StatusCard.defaultProps = {
    backgroundColor: '#D2F1C7',
    color: UIColor.success.color,
    value: {},
    index: null,
    activeColor: '#fff',
    activebackgroundColor: UIColor.secondaryColor.color,
    activeStatus: {},
    changeStatus: null,
}