import React from "react";
import { Div, Col, Row, Paper, H6, Text, Image } from 'qdm-component-library'
import "./style.css"
import { utcTOLocal, makeName } from "../../../utils";
import CalendarBlueIcon from "../../../assets/img/svg/icons8-calendar-blue.svg";
import ClockIcon from "../../../assets/img/svg/icons8-clock-black.svg"
import UserIcon from "../../../assets/img/svg/icons8-user.svg"


function BookingDetails(props) {
    const { appointmentData, parent_id } = props

    const nameData = appointmentData?.[0]?.bookedby;
    const userName = nameData?.length > 0 ? `${makeName(nameData?.[0])}` : null;

    return <Div
        id={`${parent_id}_bookingdetails_parent_div`}
        className="pv-root">
        <Paper
            id={`${parent_id}_bookingdetails_parent_paper`}
            className="pl-root">
            <Row
                id={`${parent_id}_bookingdetails_parent_row`}
                className="en-actions-root">
                <Col
                    id={`${parent_id}_bookingdetails_parent_col`}
                    md={12} lg={12} sm={12} xs={12} className="no-padding-left">
                    <Div
                        id={`${parent_id}_bookingdetails_sub_parent_div`}
                        className="flexCenter" style={{ height: '100%', flexWrap: 'wrap' }}>
                        <Div
                            id={`${parent_id}_bookingdetails_calendar_div`}
                            className="flexCenter">
                            <Div
                                id={`${parent_id}_bookingdetails_calendar_sub_div`}
                                className="iconWrapper">
                                <Image
                                    id={`${parent_id}_bookingdetails_calendar_image`}
                                    src={CalendarBlueIcon} style={{ width: 16, height: 16 }} />
                                {/* <svg style={{
                                    fill:'#0071F2'
                                }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <path id="icons8-calendar" class="cls-1" d="M8.889,6A2.9,2.9,0,0,0,6,8.889V19.111A2.9,2.9,0,0,0,8.889,22H19.111A2.9,2.9,0,0,0,22,19.111V8.889A2.9,2.9,0,0,0,19.111,6Zm0,1.333H19.111a1.546,1.546,0,0,1,1.556,1.556v.667H7.333V8.889A1.546,1.546,0,0,1,8.889,7.333ZM7.333,10.889H20.667v8.222a1.546,1.546,0,0,1-1.556,1.556H8.889a1.546,1.546,0,0,1-1.556-1.556Zm2.889,1.778a1.111,1.111,0,1,0,1.111,1.111,1.111,1.111,0,0,0-1.111-1.111Zm3.778,0a1.111,1.111,0,1,0,1.111,1.111A1.111,1.111,0,0,0,14,12.667Zm3.778,0a1.111,1.111,0,1,0,1.111,1.111A1.111,1.111,0,0,0,17.778,12.667Zm-7.556,4a1.111,1.111,0,1,0,1.111,1.111A1.111,1.111,0,0,0,10.222,16.667Zm3.778,0a1.111,1.111,0,1,0,1.111,1.111A1.111,1.111,0,0,0,14,16.667Z" transform="translate(-6 -6)"/>
                            </svg> */}
                            </Div>
                            <Div 
                            id={`${parent_id}_bookingdetails_calendar_format_div`}
                            style={{ marginRight: 20 }}>
                                <H6 
                                    id={`${parent_id}_bookingdetails_calendar_data_h6`}
                                    className="pc_medium" style={{
                                    color: '#101010',
                                    // fontWeight:'600',
                                    fontSize: 14
                                }}>{appointmentData?.[0]?.appno}</H6>
                                <Text 
                                    id={`${parent_id}_bookingdetails_calendar_format_text`}
                                    className="textStyle">
                                    {
                                        typeof appointmentData?.[0]?.start === 'number' ?
                                            utcTOLocal(appointmentData?.[0]?.start ,"MMMM Do YYYY")
                                            : utcTOLocal(appointmentData?.[0]?.start,'MMMM Do YYYY')
                                    }
                                </Text>
                            </Div>
                        </Div>
                        <Div
                            id={`${parent_id}_bookingdetails_format_div`}
                            style={{ marginRight: 20, height: '100%' }}>
                            <Div
                                id={`${parent_id}_bookingdetails_format_divider`}
                                className="divider" />
                        </Div>
                        <Div
                            id={`${parent_id}_bookingdetails_clock_div`}
                            className="flexCenter">
                            <Div
                                id={`${parent_id}_bookingdetails_clock_sub_div`}
                                className="iconWrapper">
                                {/* <Icons fontIcon="clock-o" /> */}
                                <Image
                                    id={`${parent_id}_bookingdetails_clock_image`}
                                    src={ClockIcon} style={{ width: 16, height: 16 }} />
                            </Div>

                            <Div 
                            id={`${parent_id}_bookingdetails_bookedon_div`}
                            style={{ marginRight: 20 }}>
                                <Text 
                                    id={`${parent_id}_bookingdetails_bookedon_label_text`}
                                    className="textStyle">Booked On  {
                                        typeof appointmentData?.[0]?.start === 'number' ?
                                            utcTOLocal(appointmentData?.[0]?.start ,"MMMM Do YYYY")
                                            : utcTOLocal(appointmentData?.[0]?.start,'MMMM Do YYYY')
                                    }</Text>
                            </Div>
                        </Div>
                        <Div
                            id={`${parent_id}_bookingdetails_sublevel_div`}
                            style={{ marginRight: 20, height: '100%' }}>
                            <Div
                                id={`${parent_id}_bookingdetails_book_divider`}
                                className="divider" />
                        </Div>
                        <Div
                            id={`${parent_id}_bookingdetails_bookedby_div`}
                            className="flexCenter">
                            <Div
                                id={`${parent_id}_bookingdetails_bookedby_user_div`}
                                className="iconWrapper">
                                {/* <Icons fontIcon="user" /> */}
                                <Image
                                    id={`${parent_id}_bookingdetails_bookedby_user_image`}
                                    src={UserIcon} style={{ width: 16, height: 16 }} />
                            </Div>
                            <Div>
                                <Text
                                    id={`${parent_id}_bookingdetails_bookedby_text`}
                                    className="textStyle">Booked By&nbsp;{userName || props.name}
                                    {/* {(props.appointmentData?.Person?.[0]?.name?.[0]?.given ?? "") + ' ' + (props.appointmentData?.Person?.[0]?.name?.[0]?.family ?? "") + ' ' +
                                        (props.appointmentData?.Person?.[0]?.name?.[0]?.text ?? "")} */}
                                    {/* {((props.Persondata?.Person?.[0].name?.[0]?.text ?? "") + ' ' + (props.Persondata?.Person?.[0].name?.[0]?.family ?? ""))} */}
                                </Text>
                            </Div>
                        </Div>
                    </Div>
                </Col>
            </Row>
        </Paper>
    </Div>
}

export default BookingDetails;