import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./style.css";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  tableContainer: {
    "& .App1-MuiTableCell-sizeSmall": {
      padding: "6px 10px 6px 15px",
      fontSize: "12px",
      fontWeight: 500,
    },
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0px 6px",
    borderRadius: "8px",
    paddingTop: "0px",
    padding: "10px",
    textAlign: "center",
    maxWidth: 650,
  },
  tablecell: {
    fontSize: "12px",
  },
});

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "0px",
    // textAlign: "center",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function BasicTable(props) {
  const classes = useStyles();

  const headerFilter = (v) => {
    return props.header?.includes(v) ? true : false;
  };

  const data = {
    DBA: "Day Based",
    DET: "Detail",
    INT: "Interval",
    SEQ: "Sequential",
    UDE: "User Defined",
    TID: "Three times daily",
  };

  return (
    <div>
      <Typography style={{ fontSize: 14, fontWeight: 600 }}>
        Frequency Schedule
      </Typography>
      <Typography style={{ fontSize: 12, marginTop: 4 }}>
        Schedule By {data[props?.dosageFreqCode]} ({props?.dosageFreqCode})
      </Typography>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} aria-label="simple table" size="small">
          <TableHead style={{ padding: "6px 17px 6px 13px" }}>
            <TableRow style={{ padding: "6px 17px 6px 13px" }}>
              {props?.header?.map((val, i) => {
                return (
                  <StyledTableCell className={classes.tableCell}>
                    {val}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.dataTabs?.map((val, i) => {
              return (
                <StyledTableRow>
                  {headerFilter("Day") && (
                    <StyledTableCell className={classes.tableCell}>
                      {val["Day"]}
                    </StyledTableCell>
                  )}
                  {headerFilter("Time") && (
                    <StyledTableCell className={classes.tableCell}>
                      {val["Time"]}
                    </StyledTableCell>
                  )}

                  {headerFilter("Base schedule") && (
                    <StyledTableCell className={classes.tableCell}>
                      {val["Base schedule"]}
                    </StyledTableCell>
                  )}
                  {headerFilter("From Day") && (
                    <StyledTableCell className={classes.tableCell}>
                      {val["From Day"]}
                    </StyledTableCell>
                  )}
                  {headerFilter("To Day") && (
                    <StyledTableCell className={classes.tableCell}>
                      {val["To Day"]}
                    </StyledTableCell>
                  )}
                  {headerFilter("Start date") && (
                    <StyledTableCell className={classes.tableCell}>
                      {val["Start date"]}
                    </StyledTableCell>
                  )}
                  {headerFilter("End date") && (
                    <StyledTableCell className={classes.tableCell}>
                      {val["End date"]}
                    </StyledTableCell>
                  )}

                  {headerFilter("Dosage") && (
                    <StyledTableCell className={classes.tableCell}>
                      {val["Dosage"]}
                    </StyledTableCell>
                  )}
                  {headerFilter("Remarks") && (
                    <StyledTableCell className={classes.tableCell}>
                      {val["Remarks"]}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
