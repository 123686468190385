import { Div, Divider } from "qdm-component-library";
import moment from "moment";
import Popup from "@material-ui/core/Popover";
import { useStyles } from "../styles";
import { FilterIcon, Calendar } from "../../../components/svg";
import CustomDatePicker from "../../../components/customDatePicker";
import { useState } from "react";
import { actions } from "primarycare-binder";
import { useDispatch } from "react-redux";

const AppointmentBookingFilter = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    openCloseCalendar: false,
    selectedDate: {
      unix: moment().startOf("date").unix(),
      val: moment().startOf("date").format("DD-MM-YYYY"),
    },
  });

  const open = Boolean(state.openCloseCalendar);
  const id = open ? "simple-popover" : undefined;

  // Functions

  const changeState = (key, value) => {
    if (key === "data") {
      setState({
        ...state,
        [key]: value,
        loading: false,
      });
    } else {
      setState({
        ...state,
        [key]: value,
      });
    }
  };

  const handleClose = (val) => {
    setState({
      ...state,
      openCloseCalendar: val,
    });
  };

  const mainfilter = () => {
    setState({
      ...state,
      filter: !state.filter,
    });
  };
  const onKeyDown = (e) => {
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        e.keyCode === 8 ||
        e.keyCode === 46 ||
        e.keyCode === 37 ||
        e.keyCode === 39
      )
    ) {
      e.preventDefault();
    }
  };

  // const getDocterListingDetails = async ({
  //   hospital,
  //   specialities,
  //   latitude,
  //   longtitude,
  //   date,
  //   doctorId,
  // }) => {
  //   const promArr = [
  //     dispatch(
  //       actions.DOCTER_LISTING({
  //         hospital: hospital,
  //         specialities,
  //         latitude,
  //         longtitude,
  //         date,
  //         doctorId,
  //       })
  //     ),
  //     dispatch(actions.GET_ALL_LANGS()),
  //   ];
  //   await Promise.all(promArr);
  //   setState(
  //     {
  //       ...state,
  //       data: [],
  //       Tempdata: [],
  //     },
  //     () => {
  //       changeState("allLang", props?.allLanguages?.data || []);
  //       changeState("data", props?.docterListing?.data || []);
  //       changeState("Tempdata", props?.docterListing?.data || []);
  //     }
  //   );
  // };

  async function handleApply() {
    
    let { monthCalendarInputValue, selectedDate } = state;

    let date = moment(selectedDate.val, "DD-MM-YYYY");
    if (monthCalendarInputValue) {
      date = moment(
        new Date(
          moment(date, "MM-DD-YYYY").add(
            parseInt(monthCalendarInputValue),
            "days"
          )
        )
      ).format("MM-DD-YYYY");
    }
    setState({ ...state, applyedDate: date }, () => {
      // getDocterListingDetails({
      //   hospital: hospital?.map((l) => l?.value),
      //   specialities: specialities.map((spec) => spec.value),
      //   latitude: location?.latitude || 0,
      //   longtitude: location?.longitude || 0,
      //   date: date,
      //   doctorId: doctors?.[0]?.value,
      // });
      handleClose(false);
    });

    props?.getDoctrlistDataFromFilter(date);
  }

  const filterCalendar = (e) => {
    setState({
      ...state,
      openCloseCalendar: e.currentTarget,
    });
  };

  return (
    <Div
      id="doctor_listing_filter_calender_div"
      style={{
        display: "flex",
      }}
    >
      <Popup
        id={id}
        open={open}
        anchorEl={state.openCloseCalendar}
        onClose={() => handleClose(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ marginTop: "1%", marginLeft: "2%" }}
      >
        <div
          id="doctor_listing_filter_calender_sub_div"
          style={{
            width: 300,
            backgroundColor: "#F9F9F9",
            padding: "10px",
          }}
        >
          <CustomDatePicker
            selectedDate={new Date(state.selectedDate.unix * 1000)}
            minDate={new Date()}
            handleChange={(value) => {
              const date = {
                val: moment(value).format("DD-MM-YYYY"),
                unix: moment(value).startOf("date").unix(),
              };
              changeState("monthCalendarInputValue", "");
              changeState("selectedDate", date);
            }}
          />
        </div>
        <div
          id="doctor_listing_filter_div"
          style={{
            backgroundColor: "#F9F9F9",
          }}
        >
          <Divider
            id="doctor_listing_or_divider"
            variant="fullWidth"
            textWidth={0}
            label={"or"}
            textColor="#B6B6B6"
            borderColor="#B6B6B6"
            className={classes.divider}
          />
        </div>
        <div
          id="doctor_listing_ Earliest_div"
          style={{
            backgroundColor: "#F9F9F9",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "3%",
          }}
        >
          <div
            id="doctor_listing_ Earliest_sub_div"
            style={{
              backgroundColor: "#fff",
              padding: "4px",
              border: "1px solid #E0E0E0",
              borderRadius: 4,
              width: "94%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              height: "40px",
            }}
          >
            <span id="doctor_listing_ Earliest_span" style={{ fontSize: 14 }}>
              Earliest available after
            </span>{" "}
            <input
              id="doctor_listing_ Earliest_input"
              style={{
                width: 40,
                height: 24,
                border: "1px solid #e0e0e0",
                outline: "none",
                borderRadius: 3,
              }}
              placeholder="000"
              className={classes.earliestInput}
              value={state.monthCalendarInputValue}
              maxLength={3}
              onChange={(e) => {
                const date = {
                  val: moment().format("DD-MM-YYYY"),
                  unix: moment().startOf("date").unix(),
                };
                if (date.unix !== state.selectedDate.unix) {
                  changeState("selectedDate", date);
                }
                changeState("monthCalendarInputValue", e.target.value);
              }}
              onKeyDown={(e) => onKeyDown(e)}
            />{" "}
            <span
              id="doctor_listing_days_span"
              style={{ fontSize: 14, fontWeight: 600 }}
            >
              Days
            </span>
          </div>
        </div>
        <div
          id="doctor_listing_description_div"
          style={{ backgroundColor: "#F9F9F9" }}
        >
          <div
            id="doctor_listing_description_sub_div"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              id="doctor_listing_clear_button"
              className={classes.clear}
              onClick={() => {
                changeState("monthCalendarInputValue", "");
              }}
            >
              Clear
            </button>
            <button
              id="doctor_listing_apply_button"
              className={classes.apply}
              onClick={() => handleApply()}
            >
              Apply
            </button>
          </div>
        </div>
      </Popup>
      <Div
        id="doctor_listing_filtercalendar_div"
        style={{
          backgroundColor: "#ffffff",
          borderColor: "rgb(217 217 217)",
          padding: "9px",
          border: "1px solid rgb(217 217 217)",
          borderRadius: "4px",
          cursor: "pointer",
          width: "32px",
          height: "32px",
          marginRight: 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        //  onClick={(e) => filterCalendar(e)}
      >
        <Calendar id="doctor_listing_filtercalendar_calendar" color="#2A3C50" />
      </Div>
      <Div
        id="doctor_listing_mainfilter_div"
        style={{
          backgroundColor: "#ffffff",
          borderColor: "rgb(217 217 217)",
          padding: "9px",
          border: "1px solid rgb(217 217 217)",
          borderRadius: "4px",
          cursor: "pointer",
          width: "32px",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        //onClick={() => mainfilter()}
      >
        <FilterIcon id="doctor_listing_funnel_img" color="#2A3C50" />
      </Div>
    </Div>
  );
};

export default AppointmentBookingFilter;
