import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {
  StyledPaper,
  Autocomplete,
  FieldLabel,
  InputBase,
  FieldContainer,
  Dot,
} from "./components";
import CreateIcon from "../../../../assets/img/svg/createIcon";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../../../redux/actions";

const usePaperStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    // border: `1px solid #DEE5EC`,
    // boxShadow: `#0000000A 0px 10px 25px`,
    boxShadow: "none",
    // padding: 10,
    // background: "#DEE5EC",
    background: `${theme.palette.background.table}`,
    color: theme.palette.text.primary,
    position: "relative",
  },
}));

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: 10,
    // background: `${theme.palette.background.table}`,
    color: theme.palette.text.primary,
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  editRowContainer: {
    display: "flex",
    justifyContent: "space-between",
    // background: "#DEE5EC",
  },
  dropdown: {
    background: `${theme.palette.background.table} !important`,
    "&:input": {
      color: theme.palette.text.primary,
    },
  },
}));

const { ORDER_TYPE_REQ_DETAILS_ROLE, ORDER_TYPE_REQ_DETAILS_LAB } = actions;

function RequestorDetailsForm({
  isAdd,
  requestorDetail,
  // organization,
  // practitionerRole,
  // orderBy,
  // practitionerId,
  // requestorDetailsData,
  ...props
}) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const options = useSelector((state) => state?.MedicationMastersSlice);
  const practioner_role_options = useSelector(
    (state) => state.LabOrderApiSlice?.practioner_role?.data || []
  );
  const paperClasses = usePaperStyles();
  const [state, setState] = useState({
    organization: null,
    practitionerRole: null,
    orderBy: null,
    practitionerId: "",
  });
  const [isEditStateActive, setIsEditStateActive] = useState(true);

  const changeState = (key, value) => {
    props.onSave(key, value);
    return;
    if (
      key === "organization" &&
      value?.id
      // &&
      // value?.id !== state?.organization?.id
    ) {
      dispatch(
        ORDER_TYPE_REQ_DETAILS_ROLE({
          id: value?.id,
        })
      );

      setState((prev) => ({
        ...prev,
        [key]: value,
        practitionerRole: null,
        orderBy: null,
        practitionerId: "",
      }));
      return;
    }
    if (key === "practitionerRole") {
      dispatch(
        ORDER_TYPE_REQ_DETAILS_LAB({
          id: value?.id,
          _id: value?._id,
          orgId: state?.organization?.id,
        })
      );

      setState((prev) => ({
        ...prev,
        [key]: value,
        orderBy: null,
        practitionerId: "",
      }));
      return;
    }
    if (key === "orderBy") {
      setState((prev) => ({
        ...prev,
        [key]: value,
        practitionerId: value?.practitioner_id,
      }));
      return; // Must return to avoid state update clash
    }
  };

  const handleSave = () => {
    if (
      !state?.organization ||
      !state?.practitionerRole ||
      !state?.orderBy ||
      !state?.practitionerId
    ) {
      return;
    }
    const detail = [{ ...state }];
    setState({
      organization: null,
      practitionerRole: null,
      orderBy: null,
      practitionerId: "",
    });
    setIsEditStateActive(false);

    props.onSave(detail?.[0]);
  };

  // useEffect(() => {
  //   setIsEditStateActive(true);
  // }, [isAdd]);

  // useEffect(() => {
  //   if (
  //     requestorDetail?.organization &&
  //     requestorDetail?.practitionerRole &&
  //     requestorDetail?.orderBy &&
  //     requestorDetail?.practitionerId
  //   ) {
  //     setIsEditStateActive(false);
  //   } else {
  //     setIsEditStateActive(true);
  //   }
  //   // setIsEditStateActive(isAdd ? true : false);

  // }, [props.ismulti]);

  const handleEditClick = () => {
    setState((prev) => ({
      ...prev,
      organization: requestorDetail?.organization,
      practitionerRole: requestorDetail?.practitionerRole,
      orderBy: requestorDetail?.orderBy,
      practitionerId: requestorDetail?.practitionerId,
    }));
    setIsEditStateActive(true);
  };

  useEffect(() => {
    // if (
    //   requestorDetail?.organization &&
    //   requestorDetail?.practitionerRole &&
    //   requestorDetail?.orderBy &&
    //   requestorDetail?.practitionerId
    // ) {
    //   setIsEditStateActive(false);
    // };
    //   if (requestorDetail?.organization || requestorDetail?.practitionerRole || requestorDetail?.orderBy || requestorDetail?.practitionerId) {
    //     setState((prev) => ({
    //       ...prev,
    //       organization: requestorDetail?.organization,
    //       practitionerRole: requestorDetail?.practitionerRole,
    //       orderBy: requestorDetail?.orderBy,
    //       practitionerId: requestorDetail?.practitionerId,
    //     }));

    //     props.updatesetState({
    //       ...props.updateState,
    //       requestorDetail: {
    //         organization: requestorDetail?.organization ?? null,
    //         practitionerRole: requestorDetail?.practitionerRole ?? null,
    //         orderBy: requestorDetail?.orderBy ?? null,
    //         practitionerId: requestorDetail?.practitionerId ?? '',
    //       }
    //     })
    //   }
    //   setIsEditStateActive(true);

    setState((prev) => ({
      ...prev,
      organization: requestorDetail?.organization,
      practitionerRole: requestorDetail?.practitionerRole,
      orderBy: requestorDetail?.orderBy,
      practitionerId: requestorDetail?.practitionerId,
    }));
  }, []);

  useEffect(() => {
    // if (requestorDetail?.organization && requestorDetail?.practitionerRole && requestorDetail?.orderBy && requestorDetail?.practitionerId) {
    //   setIsEditStateActive(false);
    // };

    if (requestorDetail?.organization) {
      dispatch(
        ORDER_TYPE_REQ_DETAILS_ROLE({
          id: requestorDetail?.organization?.id,
        })
      );
    } else if (requestorDetail?.practitionerRole) {
      dispatch(
        ORDER_TYPE_REQ_DETAILS_LAB({
          id: requestorDetail?.practitionerRole?.id,
          _id: requestorDetail?.practitionerRole?.value,
          orgId: requestorDetail?.organization?.id,
        })
      );
    }

    setState((prev) => ({
      ...prev,
      organization: requestorDetail?.organization,
      practitionerRole: requestorDetail?.practitionerRole,
      orderBy: requestorDetail?.orderBy,
      practitionerId: requestorDetail?.practitionerId,
    }));
  }, [requestorDetail]);

  return (
    <StyledPaper
      classes={paperClasses}
      style={{
        background: !isEditStateActive && "#dee5ecb5",
        padding: !isEditStateActive && 10,
      }}
    >
      {/* <div className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          Requestor Details
        </Typography>
        {isEditStateActive && (
          <CustomButton
            variant={"contained"}
            color="#FFFFFF"
            backgroundColor="#01205c"
            style={{ padding: 2 }}
            onClick={() => handleSave()}
          >
            Save
          </CustomButton>
        )}
      </div> */}
      {isEditStateActive ? (
        <Grid container spacing={2}>
          {/* Organization */}
          <Grid item xs={3}>
            <FieldContainer>
              <FieldLabel variant={"body1"} required>
                Organization
              </FieldLabel>
              <Autocomplete
                //FIXME -
                disabled
                //disabled
                id={"organization"}
                // onLoad={() =>
                //   changeState("drugType", this.state?.drugType ?? "")
                // }
                getOptionLabel={(option) => option?.["label"] ?? ""}
                options={options?.Organization_masters?.data ?? []}
                size={"small"}
                placeholder=""
                value={state?.organization ?? {}}
                onChange={(event, newValue) =>
                  changeState("organization", newValue)
                }
              />
            </FieldContainer>
          </Grid>
          {/* Practitioner Role */}
          <Grid item xs={3}>
            <FieldContainer>
              <FieldLabel variant={"body1"} required>
                Practitioner Role
              </FieldLabel>
              <Autocomplete
                //FIXME -
                disabled
                //disabled
                id={"practitionerRole"}
                // onLoad={() =>
                //   changeState("drugType", this.state?.drugType ?? "")
                // }
                getOptionLabel={(option) => option?.["label"] ?? ""}
                // options={options?.laborder_pract_role?.data ?? []}
                options={practioner_role_options ?? []}
                size={"small"}
                placeholder=""
                value={state?.practitionerRole ?? {}}
                onChange={(event, newValue) =>
                  changeState("practitionerRole", newValue)
                }
              />
            </FieldContainer>
          </Grid>
          {/* Order By */}
          <Grid item xs={3}>
            <FieldContainer>
              <FieldLabel variant={"body1"} required>
                Order By
              </FieldLabel>
              <Autocomplete
                //FIXME -
                disabled
                //disabled
                id={"orderBy"}
                // onLoad={() =>
                //   changeState("drugType", this.state?.drugType ?? "")
                // }
                getOptionLabel={(option) => option?.["label"] ?? ""}
                options={options?.order_type_req_details?.data ?? []}
                size={"small"}
                placeholder=""
                value={state?.orderBy ?? {}}
                onChange={(event, newValue) => changeState("orderBy", newValue)}
              />
            </FieldContainer>
          </Grid>
          {/* Practitioner ID */}
          <Grid item xs={3}>
            <FieldContainer>
              <FieldLabel variant={"body1"} required>
                Practitioner ID
              </FieldLabel>
              <InputBase
                disabled
                id={"practitionerId"}
                label={""}
                placeholder={""}
                type={"text"}
                variant={"outlined"}
                size={"small"}
                fullWidth={true}
                value={props?.practitionerCode ?? state?.practitionerId ?? ""}
                onChange={(event) =>
                  changeState("practitionerId", event.target.value)
                }
                className={classes.dropdown}
              ></InputBase>
            </FieldContainer>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.editRowContainer}>
          <Typography
            variant="body1"
            style={{ fontSize: 11, color: "#000000eb" }}
          >
            {requestorDetail?.organization && (
              <>{requestorDetail?.organization?.label}</>
            )}
            {requestorDetail?.practitionerRole && (
              <>
                &nbsp;
                <Dot space={4} size={8} />
                &nbsp;{requestorDetail?.practitionerRole?.label}
              </>
            )}
            {requestorDetail?.orderBy && (
              <>
                &nbsp;
                <Dot space={4} size={8} />
                &nbsp;{requestorDetail?.orderBy?.value}
              </>
            )}
            {requestorDetail?.orderBy && (
              <>
                &nbsp;
                <Dot space={4} size={8} />
                &nbsp;{requestorDetail?.orderBy?.label}
              </>
            )}
          </Typography>
          <IconButton
            size="small"
            onClick={handleEditClick}
            style={{
              position: "absolute",
              right: "6px",
              top: "14px",
            }}
          >
            <CreateIcon color={theme.palette.primary.main} />
          </IconButton>
        </div>
      )}
    </StyledPaper>
  );
}

export default RequestorDetailsForm;
