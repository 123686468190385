import React from 'react';
import { Div, H6, Text } from 'qdm-component-library';
import { UIColor } from '../../themes/theme';
export const UnBilled = (props) => {

    const styles = {
        h6: {
            fontSize: 12,
            color: '#EB1915',
            margin: 0,
        },
        wrapper: {
            backgroundColor: '#FFEDED',
            padding: '8px 13px',
        },
        flexCenterBetween: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        span: {
            fontSize: 10,
            color: UIColor.primaryText.color
        },
        button: {
            fontSize: 10,
            color: UIColor.primaryColor.color,
            cursor: 'pointer',
            margin: 0
        }
    }
    const { parent_id } = props;
    return (
        <div
            id={`${parent_id}_unbilled_parent_div`}
        >
            <Div
                id={`${parent_id}_unbilled_parent_sub_div`}
                style={styles.wrapper}>
                <Div
                    id={`${parent_id}_unbilled_parent_title_div`}
                    style={styles.flexCenterBetween}>
                    <Div
                        id={`${parent_id}_unbilled_parent_title_sub_div`}
                    >
                        <H6
                            id={`${parent_id}_unbilled_parent_${props?.title}_h6`}
                            style={styles.h6} className="pc_semibold">{props?.title}</H6>
                        {props?.subTitle && <Text
                            id={`${parent_id}_unbilled_parent_${props?.subTitle}_text`}
                            className="pc_regular" style={styles.span}>{props?.subTitle}</Text>}
                    </Div>
                    {props?.button && <H6
                        id={`${parent_id}_unbilled_parent_${props?.button}_button`}
                        style={styles.button} onClick={props?.buttonAction} className="pc_semibold">{props?.button}</H6>}
                </Div>
            </Div>
        </div>
    )
}

UnBilled.defaultProps = {
    title: 'Unbilled',
    subTitle: '',
    button: '',
    buttonAction: null
}