import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../themes/theme";

let styles = {
  outlinedButton: {
    border: `1px solid #01205c`,
    color: UIColor.primaryColor.color,
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: "8px 15px",
    minWidth: 100,
    fontSize: 14,
    fontFamily: "pc_medium !important",
    marginRight: "10px",
  },
  containedButton: {
    color: "#fff",
    backgroundColor: UIColor.primaryColor.color,
    borderColor: UIColor.primaryColor.color,
    borderRadius: 8,
    padding: "8px 15px",
    minWidth: 100,
    fontSize: 14,
    fontFamily: "pc_medium !important",
  },
  nextKingBtn: {
    marginRight: "15px",
  },
  containedButton2: {
    color: "#fff",
    backgroundColor: UIColor.primaryColor.color,
    borderColor: UIColor.primaryColor.color,
    borderRadius: 8,
    padding: "8px 15px",
    minWidth: 100,
    fontSize: 14,
    fontFamily: "pc_medium !important",
    marginRight:"10px"
  },
  nextBtn:{
    marginRight: "30px"
  }
};

export default styles;
