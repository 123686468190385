import * as React from "react";
import PropTypes from "prop-types";

const Speciality = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_44524" data-name="Rectangle 44524" width="12" height="12" fill="#fff" stroke="#707070" stroke-width="1"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_26" data-name="Mask Group 26" clip-path="url(#clip-path)">
    <path id="icons8-health-book" d="M4.1,1.7A1.209,1.209,0,0,0,2.9,2.9v.6H2.3a.6.6,0,1,0,0,1.2h.6V5.9H2.3a.6.6,0,1,0,0,1.2h.6V8.3H2.3a.6.6,0,1,0,0,1.2h.6v1.2H2.3a.6.6,0,1,0,0,1.2h.6v.6a1.209,1.209,0,0,0,1.2,1.2h7.2a1.209,1.209,0,0,0,1.2-1.2V2.9a1.209,1.209,0,0,0-1.2-1.2Zm0,1.2h7.2v9.6H4.1Zm3,3V7.1H5.9V8.3H7.1V9.5H8.3V8.3H9.5V7.1H8.3V5.9Z" transform="translate(-1.1 -1.7)"
     fill={props?.color}/>
  </g>
</svg>
);

export default Speciality;

Speciality.defaultProps = {
  color: "black",
};

Speciality.propTypes = {
  color: PropTypes.string,
};