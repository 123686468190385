import moment from "moment";

function cuurentNextTimeslot() {
  const currentTime = moment();
  const startTime = moment();
  let endTime = moment();
  if (currentTime.minutes() > 30) {
    startTime.set({ minute: 0, second: 0 });
    startTime.add(1, "hour");
    endTime.set({ minute: 30, second: 0 });
    endTime.add(1, "hour");
  } else {
    startTime.set({ minute: 30, second: 0 });
    endTime.add(1, "hour");
    endTime.set({ minute: 0, second: 0 });
  }
  console.log(`${startTime.format("hh:mm A")} - ${endTime.format("hh:mm A")}`);
  return `${startTime.format("hh:mm A")} - ${endTime.format("hh:mm A")}`;
}

function startTime() {
  const currentTime = moment();
  const startTime = moment();
  if (currentTime.minutes() > 30) {
    startTime.set({ minute: 0, second: 0 });
    startTime.add(1, "hour");
  } else {
    startTime.set({ minute: 30, second: 0 });
  }
  return startTime.valueOf();
}
export let mbdata = {
  info: (data) => {
    return {};
  },
  hospitalInfo: () => {
    return {
      isClinic: true,
    };
  },
  multiApppointmentInfo: () => {
    return {
      appointments: {
        startsOn: {
          date: moment().format("DD-MM-YYYY"),
          slotSelect: {
            label: cuurentNextTimeslot(),
          },
          milli: startTime(),
        },
        preferrence: {
          preferredDays: [],
          preferredWeeks: [],
        },
        occurence: {
          occurrenceHandle: "NoOfDays",
          occurrenceValue: 1,
        },
        reccurance: {
          reccuranceValue: 1,
          reccuranceType: {
            label: "Days",
            value: process.env.REACT_APP_FREQUENCY_UOM_DAYS,
          },
        },
      },
      info: {
        dates: [],
        appointments: {},
        practData: {},
      },
      data: [],
      slotObj: {},
      filterData: {},
      uploadFiles: [],
    };
  },
};
