import React from "react";

export const BmiIcon = (props) => {
  let width = props.width ? props.width : "8";
  let height = props.height ? props.height : "19";
//   let color = props.color ? props.color : "#000000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15 15">
        <g id="icons8-bmi" transform="translate(-6 -6)">
            <path id="Path_92860" data-name="Path 92860" d="M6,7.667A1.645,1.645,0,0,1,7.622,6H18.973a1.645,1.645,0,0,1,1.622,1.667V19.333A1.645,1.645,0,0,1,18.973,21H7.622A1.645,1.645,0,0,1,6,19.333Z" fill="#26a69a"/>
            <path id="Path_92861" data-name="Path 92861" d="M18.381,15.833l2.781-3.513A9.131,9.131,0,0,0,9,12.319l2.783,3.515Z" transform="translate(-1.784 -2.333)" fill="#eee"/>
            <path id="Path_92862" data-name="Path 92862" d="M13.426,8.833a9.182,9.182,0,0,1,6.082,2.32l-2.781,3.513h-6.6L7.346,11.152a9.179,9.179,0,0,1,6.08-2.319m0-.833a10.008,10.008,0,0,0-6.607,2.519l-.6.529.5.63L9.5,15.193l.243.307h7.369l.244-.307,2.781-3.513.5-.63-.6-.529A10.011,10.011,0,0,0,13.426,8Z" transform="translate(-0.13 -1.167)" fill="#00796b"/>
            <path id="Path_92863" data-name="Path 92863" d="M16.9,10.027h-.024c-.25-.008-.5-.005-.749.006l-.045,0v1.641H16.9S16.9,10.027,16.9,10.027ZM12.258,12.9l.71-.416-.756-1.343q-.359.2-.7.426Zm1.817-.877.791-.217-.4-1.532q-.4.1-.789.227Zm4.448-1.785-.416,1.593.791.215.418-1.595C19.053,10.364,18.79,10.293,18.523,10.234Zm2.993,1.25q-.348-.224-.711-.414l-.8,1.426.711.412Z" transform="translate(-3.274 -2.346)" fill="#90a4ae"/>
            <path id="Path_92864" data-name="Path 92864" d="M24.94,18.656l.789-3.818L23.5,18.656Z" transform="translate(-10.404 -5.156)" fill="#263238"/>
            <path id="Path_92865" data-name="Path 92865" d="M12,33.5H24.162a.411.411,0,0,0,.405-.417V31.417A.411.411,0,0,0,24.162,31H12Z" transform="translate(-3.568 -14.583)" fill="#ffca28"/>
            <rect id="Rectangle_46185" data-name="Rectangle 46185" width="0.811" height="1.667" transform="translate(9.649 16.417)" fill="#ed8f29"/>
            <rect id="Rectangle_46186" data-name="Rectangle 46186" width="0.811" height="0.833" transform="translate(11.27 16.417)" fill="#ed8f29"/>
            <rect id="Rectangle_46187" data-name="Rectangle 46187" width="0.811" height="1.667" transform="translate(12.892 16.417)" fill="#ed8f29"/>
            <rect id="Rectangle_46188" data-name="Rectangle 46188" width="0.811" height="0.833" transform="translate(14.514 16.417)" fill="#ed8f29"/>
            <rect id="Rectangle_46189" data-name="Rectangle 46189" width="0.811" height="1.667" transform="translate(16.135 16.417)" fill="#ed8f29"/>
            <rect id="Rectangle_46190" data-name="Rectangle 46190" width="0.811" height="1.667" transform="translate(19.378 16.417)" fill="#ed8f29"/>
            <rect id="Rectangle_46191" data-name="Rectangle 46191" width="0.811" height="0.833" transform="translate(17.757 16.417)" fill="#ed8f29"/>
        </g>
    </svg>
    );
};