import React, { useState } from "react";
import {
  H6,
  Div,
  Select,
  Button,
  TextInput,
  Row,
  Col,
} from "qdm-component-library";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import moment from "moment";
import { AlertProps } from "../../../utils";
import  withAllContexts  from "../../../hoc/withAllContexts";
import { personRequests, fetchData, urls } from "../../../utils";
import jwtDecode from "jwt-decode";

const userInfo = localStorage?.getItem("UserInfo");
const decodedUserinfo = userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
const facilityId = decodedUserinfo?.facilityid ?? "";

const styles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "14px",
    marginBottom: "6px",
    fontFamily: `pc_medium !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
    marginBottom: "10px",
  },
  errorBorder: {
    borderRadius: 8,
    borderColor: "#f33446",
    marginBottom: "10px",
  },
  rowAlign: {
    display: "flex",
    alignItems: "center",
    // margin: "15px 0px",
  },
  modelHeader: {
    fontFamily: "pc_medium !important",
    fontSize: 14,
    color: "#6f6f6f",
    marginTop: "15px",
  },
  tabBorder: {
    border: "1px solid",
  },
};

const useStyles = makeStyles((theme) => ({
  radioButton: {
    color: "#0071F2 !important",
  },
  radioButtonDisable: {
    color: "#9e9e9e !important",
  },
  label: {
    fontFamily: "pc_semibold !important",
    fontSize: "14px",
    textTransform: "none",
  },
  indicator: {
    backgroundColor: "transparent",
    border: "1px solid #0071F2",
    height: "48px",
  },
  selected: {
    color: "#0071F2",
  },
}));

const inactiveReason = [
  { value: "away" },
  { value: "recovered" },
  { value: "non-treatable" },
];
const deceasedReason = [
  { value: "Heart Attack" },
  { value: "Brain Tumor" },
  { value: "Non-Treatable" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography className={classes.label}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function ModifyStatus(props) {
const [reason, setReason] = useState([]);
const [deceasedstatus, setDeceasedstatus] = useState([]);
const [statusValue, setStatusValue]=useState([]);
  const classes = useStyles();
  const {
    labelStyle,
    borderStyle,
    rowAlign,
    modelHeader,
    tabBorder,
    errorBorder,
  } = styles;
  const FlterOptions = async (type) => {

    const typeData = [type];
    const queryData = personRequests.masterSearch(typeData, facilityId);
    const queryDataResp = await fetchData(queryData, urls.readQdmQueries);
    var lista = [];
    let finalArry =[];
    const data = queryDataResp;
    for (let index in data) {
        lista=(data[index][type]?.Value);
    }
    lista.map((val,i)=>{
       let datatemp={
            value: val?.display,
            _id: val?._id
        }
        finalArry.push(datatemp);
    });
    setDeceasedstatus(finalArry)
    return finalArry;
  };

  const filterOption2 = async (type,dat) => {

    const typeData = [type];
    const queryData = personRequests.masterSearch(typeData, facilityId);
    const queryDataResp = await fetchData(queryData, urls.readQdmQueries);
    var lista = [];
    let finalArry =[];
    const data = queryDataResp;
    for (let index in data) {
        lista=(data[index][type]?.Value);
    }
    lista.map((val,i)=>{
       let datatemp={
            value: val?.display,
            _id: val?._id
        }
        finalArry.push(datatemp);
    });
    setReason(finalArry);
    return finalArry;
  };

  const filterOption3 = async (type,dat) => {

    const typeData = [type];
    const queryData = personRequests.masterSearch(typeData, facilityId);
    const queryDataResp = await fetchData(queryData, urls.readQdmQueries);
    var lista = [];
    let finalArry =[];
    const data = queryDataResp;
    for (let index in data) {
        lista=(data[index][type]?.Value);
    }
    lista.map((val,i)=>{
       let datatemp={
            value: val?.display,
            _id: val?._id
        }
        finalArry.push(datatemp);
    });
    setStatusValue(finalArry);
    return finalArry;
  };

  const [state, setState] = useState({
    status: "",
    reason: "",
    time: "",

    status1: "",
    reason1: "",
    date: false,
  });
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event) => {
    setState({
      ...state,
      status1: event.target.value,
      reason1: "",
      status: "",
    });
  };

  const handleChange1 = (event) => {
    if (!state.date) {
      state.time = "";
    }
    setState({
      ...state,
      status: event.target.value,
      reason: "",
    });
  };

  const handleClose = () => {
    props.handlepopoverclose();
  };
  const [err, setErr] = useState(false);
  const handleSave = async () => {
    if (state.time) {
      var now = new Date();
      if (moment(state.time).unix() > moment(now).unix()) {
        setErr(true);
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill the mandatory fields",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return
      }
    } else if (state.status) {
      setErr(true);
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return
    }
    if (
      !state.status &&
      !state.reason &&
      !state.time &&
      !state.status1 &&
      !state.reason1
    ) {
      setErr(true);
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please enter the status and condition details!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    }
    // if (state?.status1 === "Active" && (!state.time || !state?.reason?.value || !state?.status)) {
    //   props.alert.setSnack({
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Please enter the condition details!",
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center,
    //     tone: true,
    //   });
    //   return
    // }
    else if (
      state?.status1 === "Inactive" &&
      !state?.reason1?.value &&
      value === 0
    ) {
      setErr(true);
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    } else if (
      state?.status &&
      (!state?.reason?.value || state?.time.length === 0) &&
      value === 1
    ) {
      setErr(true);
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please select the appropriate reason and date!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    }
    const gettime = new Date(state?.time).getTime() / 1000
    let status1 ="";
    let condition ="";
    statusValue.map((v,i)=>{
        if(state?.status1.toLowerCase() === v?.value.toLowerCase()){
            status1 = v;
        }
    });
    deceasedstatus.map((r,i)=>{
        if(state?.status.toLowerCase() === r?.value.toLowerCase()){
            condition = r;
        }
    });
     props.handleSave("statusDetails", {
    //   status: condition,
    //   isRevoked:
    //     value === 0 ? false : state?.status === "Revoke" ? true : false,
    //   reason: state?.reason ? state?.reason : "",
    //   time: gettime ? gettime : 0,

    //   status1: status,
    //   reason1: state?.reason1 ? state?.reason1 : "",
    conditionstatus : condition,
    isdeceased : value === 0 ? false : state?.status === "Revoke" ? true : false,
    deceasedreason : state?.reason ? state?.reason : "",
    deceaseddate :  gettime ? gettime : 0,
    
    status : status1,
    statusreason : state?.reason1 ? state?.reason1 : "",

    });
    handleClose();
  };
  const handleReasonChange = (value) => {
    setState({ ...state, reason: value });
  };
  const handleReasonChangeYes = (value) => {
    setState({ ...state, reason1: value });
  };

  const setTimeDetails = (value) => {
    setState({ ...state, time: value });
  };
  React.useEffect(() => {
    filterOption3("PATIENTRECORDSTATUS")
    filterOption2("REASON")
    FlterOptions("CONDITIONSTATUS")
    // setState({ ...state, ...props.statusDetails })
    let incomingState = { ...props.statusDetails };
    const status1Assigned = (props?.statusDetails?.status?.display||props?.statusDetails?.status?.value || "");
    let statusreasonObj = {
        _id : props?.statusDetails?.statusreason?._id || "",
        value : props?.statusDetails?.statusreason?.display || props?.statusDetails?.statusreason?.value || ""
    };
    let deceasedreasonObj =  {
        _id : props?.statusDetails?.deceasedreason?._id || "",
        value : props?.statusDetails?.deceasedreason?.display || props?.statusDetails?.deceasedreason?.value || ""
    };
    // let statusreasonObj="";
    // let deceasedreasonObj="";
    if (status1Assigned === "active" && (props?.statusDetails?.conditionstatus?.display === "" || props?.statusDetails?.conditionstatus?.value === "" 
    || props?.statusDetails?.conditionstatus === "")) {
        
    //   let dataAssign = {
    //     status1: "Inactive",
    //     status: "Deceased",
    //     reason1: {
    //       label: "",
    //       value: "",
    //       _id: "",
    //     },
    //     date: false,
    //     time: "",
    //     reason: {
    //       label: "",
    //       value: "",
    //       _id: "",
    //     },
    //   };
      setState({
        ...state,
        status: "",
        reason: deceasedreasonObj,
        time: "",

        status1: "Active",//props?.statusDetails?.status,
        reason1: statusreasonObj,
        date: false,
     })
      //status1Data.push(dataAssign);
    } else if (
      status1Assigned === "inactive" &&
      (props?.statusDetails?.conditionstatus?.display === "" || props?.statusDetails?.conditionstatus?.value === ""  || props?.statusDetails?.conditionstatus === "")
    ) {
    //   let dataAssign = {
    //     status1: "Active",
    //     reason1: {
    //       label: "",
    //       value: "",
    //       _id: "",
    //     },
    //   };
      setState({
        ...state,
        status: props?.statusDetails?.conditionstatus?.display || props?.statusDetails?.conditionstatus?.value || "",
        reason: deceasedreasonObj,
        time:  new Date(props?.statusDetails?.deceaseddate * 1000).toISOString().split('T')[0],

        status1: "Inactive",//props?.statusDetails?.status,
        reason1: statusreasonObj,
        date: false,
     })
      //status1Data.push(dataAssign);
    } else if (
      status1Assigned === "inactive" &&
      (props?.statusDetails?.conditionstatus?.display === "diseased" || props?.statusDetails?.conditionstatus?.value === "diseased")
    ) {
    //   let dataAssign = {
    //     status: "Revoke",
    //     status1: "Active",
    //     date: props?.statusDetails?.date,
    //     time: props?.statusDetails?.time,
    //     reason: props?.statusDetails?.deceasedreason,
    //   };
      setState({
        ...state,
        status: props?.statusDetails?.conditionstatus?.display || props?.statusDetails?.conditionstatus?.value|| "",
        reason: deceasedreasonObj,
        time: new Date(props?.statusDetails?.deceaseddate * 1000).toISOString().split('T')[0],//new Date(props?.statusDetails?.deceaseddate).format("DD-MM-YYYY"),
        status1: "Inactive",//props?.statusDetails?.status,
        reason1: statusreasonObj,
        date: false,
     })
      //status1Data.push(dataAssign);
    }
    else if (
        status1Assigned === "inactive" &&
        (props?.statusDetails?.conditionstatus?.display === "revoked" || props?.statusDetails?.conditionstatus?.value === "revoked")
      ) {
      //   let dataAssign = {
      //     status: "Revoke",
      //     status1: "Active",
      //     date: props?.statusDetails?.date,
      //     time: props?.statusDetails?.time,
      //     reason: props?.statusDetails?.deceasedreason,
      //   };
        setState({
          ...state,
          status: props?.statusDetails?.conditionstatus?.display || props?.statusDetails?.conditionstatus?.value|| "",
          reason: deceasedreasonObj,
          time: new Date(props?.statusDetails?.deceaseddate * 1000).toISOString().split('T')[0],//new Date(props?.statusDetails?.deceaseddate).format("DD-MM-YYYY"),
          status1: "Inactive",//props?.statusDetails?.status,
          reason1: statusreasonObj,
          date: false,
       })
        //status1Data.push(dataAssign);
      }
    // -----------------------------------------------------------------
    // console.log("status1Data",status1Data);
    // setState({
    //   ...state,
    //   reason:
    //     status1Data.length > 0
    //       ? status1Data?.[0]?.reason
    //       : props?.statusDetails?.reason,
    //   status1:
    //     status1Data.length > 0
    //       ? status1Data?.[0]?.status1
    //       : props?.statusDetails?.status1,
    //   time:
    //     status1Data.length > 0
    //       ? status1Data?.[0]?.time
    //       : props?.statusDetails?.time,
    //   status:
    //     status1Data.length > 0
    //       ? status1Data?.[0]?.status
    //       : props?.statusDetails?.status,
    //   reason1:
    //     status1Data.length > 0
    //       ? status1Data?.[0]?.reason1
    //       : props?.statusDetails?.reason1,
    //   date:
    //     status1Data.length > 0
    //       ? status1Data?.[0]?.date
    //       : props?.statusDetails?.date,
    // });
  }, [props]);

//
  return (
    <Div id={`${props.parent_id}_modify_status`} className={classes.root}>
      {/* {JSON.stringify(state)} */}
      <Tabs
        id={`${props.parent_id}_modify_status_headerTabs`}
        value={value}
        onChange={handleTabChange}
        classes={{ indicator: classes.indicator }}
        variant="fullWidth"
        aria-label="simple tabs example"
      >
        <Tab
          id={`${props.parent_id}_modify_status_headerSubTab_status`}
          classes={{ root: classes.label, selected: classes.selected }}
          style={{ border: "1px solid #B6B6B6" }}
          label="Status"
          {...a11yProps(0)}
        />
        <Tab
          id={`${props.parent_id}_modify_status_headerSubTabs_condition`}
          classes={{ root: classes.label, selected: classes.selected }}
          style={{ border: "1px solid #B6B6B6" }}
          label="Condition"
        //   disabled={
        //     props?.statusDetails?.status1 === "Inactive" &&
        //     props?.statusDetails?.status === ""
        //       ? true
        //       : false
        //   }
          {...a11yProps(1)}
        />
      </Tabs>

      <TabPanel
        id={`${props.parent_id}_modify_status_tabPanel_status`}
        value={value}
        index={0}
      >
        <H6
          id={`${props.parent_id}_status_radioButtons_header`}
          style={modelHeader}
        >
          Status
        </H6>
        <RadioGroup
          id={`${props.parent_id}_status_radioButtons_group`}
          aria-label="status"
          name="status1"
          value={state.status1}
          onChange={handleChange}
        >
          <Div
            id={`${props.parent_id}_status_radioButtons_div`}
            style={rowAlign}
          >
            <FormControlLabel
              id={`${props.parent_id}_active_status_formLabel`}
              value="Active"
              classes={{ label: classes.label }}
            //   disabled={state.status1 === "Inactive"}
              control={
                <Radio
                  id={`${props.parent_id}_active_status_radio`}
                  classes={{
                    colorSecondary:
                      state.status1 === "inactive"
                        ? classes.radioButtonDisable
                        : classes.radioButton,
                  }}
                />
              }
              label="Active"
            />
            <FormControlLabel
              id={`${props.parent_id}_inactive_status_FormLabel`}
              value="Inactive"
              classes={{ label: classes.label }}
            //   disabled={state.status1 === "Active"}
              control={
                <Radio
                  id={`${props.parent_id}_inactive_status_radio`}
                  classes={{
                    colorSecondary:
                      state.status1 === "active"
                        ? classes.radioButtonDisable
                        : classes.radioButton,
                  }}
                />
              }
              label="Inactive"
            />
          </Div>
        </RadioGroup>

        <Select
          id={`${props.parent_id}_status_reason`}
          value={state.reason1?.value}
          onChangeValue={(e) => handleReasonChangeYes(e)}
          options={reason}
          isRequired={true}
          disabled={state.status1 === "Active" ? true : false}
          label="Reason"
          showArrow
          search={true}
          placeholder="Reason"
          labelStyle={labelStyle}
          inLineStyles={
            err && !state.reason1?.value ? errorBorder : borderStyle
          }
          hoverColor="#0071F2"
        />
      </TabPanel>

      <TabPanel
        id={`${props.parent_id}_modify_status_tabPanel_condition`}
        value={value}
        index={1}
      >
        <H6 id={`${props.parent_id}_condition_header`} style={modelHeader}>
          Condition
        </H6>
        <Div
          id={`${props.parent_id}_condition_radioButtons_div`}
          style={rowAlign}
        >
          <RadioGroup
            id={`${props.parent_id}_radio_group_condition`}
            aria-label="status"
            name="status"
            value={state.status}
            onChange={handleChange1}
          >
            <Div
              id={`${props.parent_id}_row_align_condition_radio`}
              style={rowAlign}
            >
               {deceasedstatus.length > 0 &&
                deceasedstatus.map((val) => {
                  return (
                    <FormControlLabel
                      id={`${props.parent_id}_deceased_status`}
                      value={val?.value ?? ""}
                      classes={{ label: classes.label }}
                    //   disabled={
                    //     (val.value === "Revoke" &&
                    //       state.status === "Deceased") ||
                    //     (val.value === "Deceased" && state.status === "Revoke")
                    //   }
                      control={
                        <Radio
                          classes={{
                            colorSecondary:
                              (val.value === "Revoke" &&
                                state.status === "Deceased") ||
                              (val.value === "Deceased" &&
                                state.status === "Revoke")
                                ? classes.radioButtonDisable
                                : classes.radioButton,
                          }}
                        />
                      }
                      label={`Mark as ${val?.value}`}
                    />
                  );
                })}
              {/* <FormControlLabel
                id={`${props.parent_id}_deceased_status`}
                value="Deceased"
                classes={{ label: classes.label }}
                control={
                  <Radio classes={{ colorSecondary: classes.radioButton }} />
                }
                label="Mark as Deceased"
              />
              <FormControlLabel
                id={`${props.parent_id}_revoked_status`}
                value="Revoke"
                classes={{ label: classes.label }}
                control={
                  <Radio id={`${props.parent_id}_revoked_radio`} classes={{ colorSecondary: classes.radioButton }} />}
                label="Revoke Deceased"
              /> */}
            </Div>
          </RadioGroup>
        </Div>
        <Row id={`${props.parent_id}_row_condition`} spacing={0}>
          <Col
            id={`${props.parent_id}_col_dateInput`}
            xs={4}
            md={4}
            sm={4}
            lg={4}
            style={{ padding: 0 }}
          >
            <TextInput
              id={`${props.parent_id}_patient_name_dateformat_textinput`}
              value={state.time}
              max={moment(new Date()).format("YYYY-MM-DD")}
              maxDate={moment(new Date()).format("YYYY-MM-DD")}
              minDate={moment().subtract(200, "years").format("YYYY-MM-DD")}
              onChange={(e) => setTimeDetails(e.target.value)}
              // disabled={state.status === "Revoked" ? true : false}
              disabled={state.time && state.date ? true : false}
              isRequired={true}
              dateIcon="images/icons8-calendar-grey.svg"
              label="Date of Death"
              type="date"
              labelStyle={labelStyle}
              style={err && !state.time ? errorBorder : borderStyle}
              hoverColor="#0071F2"
              className="dateInput"
            />
          </Col>
          <Col
            id={`${props.parent_id}_col_condition_reason`}
            xs={8}
            md={8}
            sm={8}
            lg={8}
            style={{ paddingRight: "0px" }}
          >
            <Select
              id={`${props.parent_id}_condition_reason`}
              value={state.reason?.value}
              onChangeValue={(e) => handleReasonChange(e)}
              options={reason}
              // options={deceasedReason}
              disabled={state.status === "Revoked" ? true : false}
              isRequired={true}
              label="Reason"
              showArrow
              placeholder="Reason"
              labelStyle={labelStyle}
              inLineStyles={
                err && !state.reason?.value ? errorBorder : borderStyle
              }
              hoverColor="#0071F2"
            />
          </Col>
        </Row>
      </TabPanel>
      <Button
        id={`${props.parent_id}_save_status`}
        className={"topNav-actions successBtn"}
        onClick={() => {
          if (value === 0 && props?.statusDetails?.status === "Deceased") {
            props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Please revoke the patient to change the status!",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
          } else {
            handleSave();
          }
        }}
        // disabled={(value === 0 && props?.statusDetails?.status === "Deceased") ? true : false}
      >
        Save
      </Button>
      <Button
        id={`${props.parent_id}_cancel_status`}
        className={"topNav-actions "}
        style={{
          backgroundColor: "#fff",
          color: "#0071F2",
          borderColor: "#0071F2",
        }}
        onClick={handleClose}
      >
        Cancel
      </Button>
    </Div>
  );
}
export default withAllContexts(ModifyStatus);
