import React, { useState } from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import AppointmentAddMemberSuccessPage from "../../../components/global/appointmentAddMemberSuccessPage";
import {
  Div,
  Text,
  Avatar,
  H6,
  Container,
  SideMenu,
} from "qdm-component-library";
// import { Routes } from "../../router/routes";
import { getImgUrl, makeName } from "../../../utils";
import Addmemberform from "../../../components/global/addmemberform";
import Addmemberotp from "../../../components/global/addmemberotp";
import AddMember from "../../../components/global/addMember";
// import Plus from '../../assets/img/+.svg'

function ChoosememberSearchResult(props) {
  const [state, setState] = useState({
    sidebar: false,
    chooseSearch: null,
    memberForm: false,
    searchMember: false,
    memberOtp: false,
  });

  const changeSideBar = () => {
    setState(
      {
        ...state,
        sidebar: false,
        memberForm: false,
        searchMenber: false,
        memberOtp: false,
      },
      () => {
        // window.location.reload();
      }
    );
  };

  const changeState = (key, value) => {
    setState({
      [key]: value,
    });
  };
  const handelChangeSelect = (v) => {
    setState({
      ...state,
      chooseSearch: v,
    });
  };

  const toggleSideBar = (boolean) => {
    setState({
      ...state,
      sidebar: boolean,
      memberForm: false,
      searchMember: true,
      addmmber: false,
      memberSuccessPage: false,
    });
  };

  const nextSection = (member, signUpData, l) => {
    if (signUpData === "addMember") {
      setState({
        ...state,
        signUpData: l ? l : signUpData,
        searchMember: false,
        memberForm: false,
        memberOtp: true,
        memberSuccessPage: false,
        addmmber: true,
      });
      return;
    } else if (member === "addmemberform") {
      setState({
        ...state,
        searchMember: false,
        memberForm: true,
        memberOtp: false,
        addmmber: false,
        signUpData,
      });
    } else if (member === "addmemberotp") {
      if (signUpData) {
        setState({
          ...state,
          signUpData,
          searchMember: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false,
          addmmber: false,
        });
      } else {
        setState({
          ...state,
          searchMember: false,
          memberForm: false,
          memberOtp: true,
          memberSuccessPage: false,
        });
      }
    } else if (member === "back") {
      setState({
        ...state,
        searchMember: true,
        memberForm: false,
        memberOtp: false,
        memberSuccessPage: false,
      });
    } else if (member === "member_succss") {
      setState({
        ...state,
        searchMember: false,
        memberForm: false,
        memberOtp: false,
        sidebar: true,
        memberSuccessPage: true,
        signUpData,
      });
    } else if (member === "movenextscreen") {
      setState({
        ...state,
        searchMember: true,
        memberForm: false,
        memberOtp: false,
        sidebar: false,
        memberSuccessPage: false,
      });
    }
  };

  const getAge = (date) => {
    return (
      Math.floor(
        moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
      ) + "Yrs"
    );
  };

  const { parent_id } = props;

  return (
    <div id={`${parent_id}_choosemember_parent_div`}>
      <Container
        id={`${parent_id}_choosemember_parent_container`}
        key={"0"}
        name="container"
        fluid={false}
        style={{
          padding: "0px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          width: "792px",
          boxShadow: "0px 8px 23px #0000006e",
        }}
      >
        <div
          id={`${parent_id}_choosemember_parent_sub_div`}
          key={"0"}
          inLineStyles="[object Object]"
          className="qdm"
          ref={props?.refs}
          style={{
            width: "auto",
            height: "",
            padding: "16px 16px 6px",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
          }}
        >
          <H6
            id={`${parent_id}_choosemember_label_text`}
            className="pc_regular"
            key={"0"}
            name="CHOOSE MEMBER"
            style={{
              fontSize: "12px",
              color: "#6F6F6F",
              letterSpacing: 0,
              lineHeight: 1,
              margin: 0,
            }}
          ></H6>

          <Div
            id={`${parent_id}_choosemember_image_div`}
            inLineStyles="[object Object]"
            className="qdm"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "8px",
              flexWrap: "wrap",
              maxHeight: 250,
              overflow: "auto",
            }}
          >
            {props?.choosemember?.map((member, key) => (
              <Div
                id={`${parent_id}_${key}_choosemember_description_div`}
                key={key}
                inLineStyles="[object Object]"
                className="qdm"
                style={{
                  backgroundColor: "#ffffff",
                  // borderColor:
                  //   values.select_city === city.title ? '#007aff' : '#ffffff',
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderRadius: "8px",
                  marginLeft: "",
                  marginRight: "24px",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                  border: `1px solid ${
                    props.selectedId === member?.PersonID
                      ? "#0071F2"
                      : "#F0F0F0"
                  }`,
                  padding: "15px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  props.handlememberClick(e, {
                    name: makeName(member?.name?.[0]),
                    email:
                      member?.telecom?.find(
                        (val) =>
                          val?.system?.display === "Email" &&
                          val?.use?.display === "Home"
                      )?.value || "",
                    userId: member?.PersonID,
                    birthDay: member?.birthDate,
                    gender: member?.gender,
                    fileid: member?.photo?.[0]?.fileid,
                    mobile:
                      member?.telecom?.find(
                        (val) =>
                          val?.system?.display === "Phone" &&
                          val?.use?.display === "Billing"
                      )?.value || "",
                  });
                }}
              >
                <Avatar
                  id={`${parent_id}_${key}_choosemember_user_avatar_image`}
                  variant="rounded"
                  letter={member?.name?.[0]?.text || member?.name?.[0]?.given}
                  src={getImgUrl(member?.person?.[0]?.photo?.[0]?.fileid)}
                  alt="Image"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#ffffff",
                    borderColor: "",
                    borderWidth: "",
                    borderRadius: "50%",
                    margin: "0px",
                    marginRight: "10px",
                  }}
                />

                <Div id={`${parent_id}_${key}_choosemember_name_gender_div`}>
                  <H6
                    id={`${parent_id}_${key}_choosemember_name_h6`}
                    className="pc_medium"
                    name={makeName(member.name?.[0])}
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      color: "#000000",
                      letterSpacing: 0,
                    }}
                  />
                  <Text
                    id={`${parent_id}_${key}_choosemember_gender_text`}
                    className="pc_regular"
                    name={
                      member.birthDate || member?.gender
                        ? (getAge(member?.birthDate) ?? "") +
                          " | " +
                          (member?.gender ?? "")
                        : ""
                    }
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      color: "#6F6F6F",
                      letterSpacing: 0,
                    }}
                  />
                </Div>
              </Div>
            ))}
          </Div>
          <SideMenu
            id={`${parent_id}_choosemember_sidemenu`}
            open={state.sidebar}
            direction="right"
            width={435}
            color="default"
            onClose={() => toggleSideBar(false)}
          >
            {state.searchMember ? (
              <AddMember
                parent_id={"choosemember"}
                name={"Add Member"}
                nextSection={(member, v, l) => nextSection(member, v, l)}
                signUpData={state.signUpData}
                handelChangeSelect={(v) => handelChangeSelect(v)}
                chooseSearch={state.chooseSearch}
              />
            ) : state.memberForm ? (
              <Addmemberform
                parent_id={"choosemember"}
                addNewMember
                changeSideBar={changeSideBar}
                signUpData={state.signUpData}
                nextSection={(member, signUpData) =>
                  nextSection(member, signUpData)
                }
              />
            ) : state.memberOtp ? (
              <Addmemberotp
                parent_id={"choosemember"}
                addmmber={state.addmmber}
                signUpData={state.signUpData}
                nextSection={(member, v) => nextSection(member, v)}
              />
            ) : null}
            {state.memberSuccessPage ? (
              <AppointmentAddMemberSuccessPage
                parent_id={"choosemember"}
                addmmber={state.addmmber}
                insideSubmit={true}
                signUpData={state.signUpData}
                nextSection={(member, v, l) => nextSection(member, v, l)}
                toggleSideBar={(member) => toggleSideBar(member)}
              />
            ) : null}
          </SideMenu>
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(ChoosememberSearchResult);
