import React from "react";
import { Div } from "qdm-component-library";
import { Chip } from "@material-ui/core";
import jwtDecode from "jwt-decode";

const Outstanding = (props) => {
  const { OutstandingData = "", OutstandingClick = () => null } = props;
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);

  return (
    <React.Fragment>
      <Div>
        {OutstandingData ? (
          <Chip
            variant="outlined"
            size="small"
            label={`Outstanding : ${decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode} ${OutstandingData}`}
            onClick={OutstandingClick}
            style={{
              borderRadius: 4,
              color: "#EC6A49",
              borderColor: "#EC6A49",
              backgroundColor: "#FFF2F2",
              width: "170px",
            }}
          />
        ) : null}
      </Div>
    </React.Fragment>
  );
};

Outstanding.defaultProps = {
  careList: [],
};

export default Outstanding;
