import {
    createSlice,
    createAsyncThunk
} from "@reduxjs/toolkit";
import {
    urls,
    defaultState,
    defaultReject,
    fetchData,
    query_ids,
    dbName,
    personRequests,
  } from "../../utils";
  


const GET_ENCOUNTER_MASTER_TYPE = createAsyncThunk(
    'searchApiSlice/masterEncounterType', 
    async (payload = {}, { rejectWithValue }) => {
    try {
        let returnData = [];
        // const encounterTypeBody = {
        //         db_name: dbName,
        //         entity: "CodingMaster",
        //         filter: "CodingMaster.Type == 'ENCOUNTERTYPE' && CodingMaster.status == true && CodingMaster.activestatus==true ",
        //         return_fields: "KEEP(CodingMaster,'_id','id','_key','Type','status','activestatus','shortdesc','display','code')",
        //         sort: "document(AMPatientClass.encTypeDesc).display"
        //     }
            const encounterTypeBody = {
                "db_name": dbName,
                "entity": "AMPatientClass",
                "filter": "AMPatientClass.status==true && AMPatientClass.activestatus==true",
                "return_fields": "keep(AMPatientClass,'_id','_key','id', 'encType', 'shortDesc','longDesc','status')",
                "sort": "AMPatientClass.shortDesc"
                }
        const encounterType = await fetchData({ body: JSON.stringify(encounterTypeBody)}, urls.readDocuments);
        returnData = encounterType?.result
        return {
            ...defaultState.List,
            data: returnData,
        }
    } catch (error) {
            return rejectWithValue({
                ...defaultReject,
                message: error.message,
            });
    }
})
const MASTER_SEARCH = createAsyncThunk(
    `searhcApiSlice/masterSearch`,
    async (payload = {}, { rejectWithValue }) => {
        try {
            const { type = [] } = payload;
            let newType = type;
            let fetchEncounter = false;
            if (type.includes('ENCOUNTERTYPE')) {
                newType = newType.filter(item => item !== 'ENCOUNTERTYPE');
                fetchEncounter = true;
            }
            const body = { 
                db_name: dbName, 
                queryid: query_ids.masterSearch,
                filter: {
                    type: newType,
                },
            };
            const encounterQuery = {
                db_name: dbName,
                entity: "AMPatientClass",
                filter: "AMPatientClass.activestatus==true and AMPatientClass.status==true",
                return_fields: "merge(AMPatientClass,{encType:document(AMPatientClass.encType).code},{encTypeDesc:document(AMPatientClass.encTypeDesc).display},{encClsType:document(AMPatientClass.encClsType).code},{encClsDesc:document(AMPatientClass.encClsDesc).display})",
                sort: "document(AMPatientClass.encTypeDesc).display"
            };
            const promArr = [
                fetchData({ body: JSON.stringify(body) }),
            ];
            if (fetchEncounter) {
                promArr.push(
                    fetchData({
                        body: JSON.stringify(encounterQuery)
                    }, urls.readDocuments)
                );
            }
            const [data, encounterData = { result: [] }] = await Promise.all(promArr);
            const returnData = {};
            if (Array.isArray(data)) {
                data.forEach(spec => {
                    if (!(spec.Type in returnData)) {
                        returnData[spec.Type] = [];
                    }
                    returnData[spec.Type].push(spec)
                });
            }
            if (fetchEncounter) {
                const encounterClass = {};
                const encounterRes = encounterData?.result?.map(enc => {
                    encounterClass[enc._id] = enc?.encClsByTypes.map(cls => ({
                        _id: cls?.encClsType,
                        value: cls?.shortDesc,
                    }));
                    return {
                        data: enc,
                        coding: {
                            _id: enc._id,
                            display: enc.shortDesc,
                        }
                    }
                });
                returnData['ENCOUNTERCLASS'] = encounterClass;
                returnData['ENCOUNTERTYPE'] = encounterRes;
            }
            return {
                ...defaultState.List,
                data: returnData,
            }
        } catch (err) {
            return rejectWithValue({
                ...defaultReject,
                message: err.message,
            });
        }
    },
);

const GET_ENCOUNTER_MASTER_CLASS = createAsyncThunk(
    'searhcApiSlice/masterEncounterClass', 
    async (payload = {}, { rejectWithValue }) => {
    try {
        const { encType = "" } = payload;
        let returnData = [];
       
        const encounterClassBody = {
            "db_name": dbName,
            "entity": "AMPatientClass",
            "filter": `AMPatientClass.encType=='${encType}' && AMPatientClass.status==true && AMPatientClass.activestatus==true`,
            "return_fields": "{encClsByTypes:AMPatientClass.encClsByTypes}"
        }
        const encounterClass = await fetchData({ body: JSON.stringify(encounterClassBody)}, urls.readDocuments);
        returnData = encounterClass?.result?.[0]?.encClsByTypes
        return {
            ...defaultState.List,
            data: returnData,
        }
    } catch (error) {
            return rejectWithValue({
                ...defaultReject,
                message: error.message,
            });
    }
})


const searchapiSlice = createSlice({
    name: "searchapiSlice",
    initialState: {
        getEncounterType: { ...defaultState.Info },
        masterSearch: { ...defaultState.Info },
        getEncounterClass: { ...defaultState.Info },
    },
    extraReducers: {
        /* GET ENCOUNTER MASTER TYPE */
        [GET_ENCOUNTER_MASTER_TYPE.fulfilled]: (state, action) => {
            state.getEncounterType = action?.payload ?? [];
        },
        [GET_ENCOUNTER_MASTER_TYPE.pending]: (state, action) => {
            state.getEncounterType.loading = true;
        },
        [GET_ENCOUNTER_MASTER_TYPE.rejected]: (state, action) => {
            state.getEncounterType = action?.payload ?? [];
        },
        /* MASTER_SEARCH */
        [MASTER_SEARCH.fulfilled]: (state, action) => {
            state.masterSearch = action?.payload ?? {};
        },
        [MASTER_SEARCH.pending]: (state, action) => {
            state.masterSearch.loading = true;
        },
        [MASTER_SEARCH.rejected]: (state, action) => {
            state.masterSearch = action?.payload ?? {};
        },

        /* GET ENCOUNTER MASTER TYPE */
        [GET_ENCOUNTER_MASTER_CLASS.fulfilled]: (state, action) => {
            state.getEncounterClass = action?.payload ?? [];
        },
        [GET_ENCOUNTER_MASTER_CLASS.pending]: (state, action) => {
            state.getEncounterClass.loading = true;
        },
        [GET_ENCOUNTER_MASTER_CLASS.rejected]: (state, action) => {
            state.getEncounterClass = action?.payload ?? [];
        },
    }
})

export const searchApiActions = {

    GET_ENCOUNTER_MASTER_TYPE,
    MASTER_SEARCH,
    GET_ENCOUNTER_MASTER_CLASS
}

export default searchapiSlice;