import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  defaultReject,
  defaultState,
  dialysis,
  fetchData,
  urls,
  dbName,
} from "../../utils";

const UPSERT_DIALYSIS = createAsyncThunk(
  `dialysisApiSlie/upsertDailysis`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls?.cpoeSaveDialysis
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const READ_DIALYSIS = createAsyncThunk(
  `dialysisApiSlie/readDialysis`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { encounterId = "" , orderlineid=""} = payload;

      const data = await fetchData(
        {
          body: JSON.stringify(dialysis.readDialysis(encounterId)),
        },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const HD_Machine_No = createAsyncThunk(
  `dialysisApiSlie/hdMachineNo`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { encounterId } = payload;
      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounterId,
          tenantid: "",
          facilityid: "",
        },
        queryid: "cfa659b8-f616-4661-8524-14082d6281c4",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const HeparinDropDown = createAsyncThunk(
  `dialysisApiSlie/heparinDropDown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          type: ["HEPARINTYPE", "DIALYZERHIGHFLUX", "FLOWRATEAVFNEEDLESIZE"],
          tenantid: "1234",
          facilityid: "",
          lang: "en",
        },
        queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const ShiftTypeDropDown = createAsyncThunk(
  `dialysisApiSlie/shiftTypeDropdown`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        filter: {
          type: [
            "DIALYSATE",
            "DIALYZERHIGHFLUX",
            "FLOWRATEAVFNEEDLESIZE",
            "HEPARINTYPE",
            "SHIFTTYPE",
            "DAYTYPE",
            "NURSINGNOTES"
          ],
          tenantid: "1234",
          facilityid: "",
          lang: "en",
        },
        queryid: "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const Practioner_Name = createAsyncThunk(
  `dialysisApiSlie/practioner_Name`,
  async (payload = {}, { rejectWithValue }) => {
    const { PractitionerId = "" } = payload;
    try {
      const body = {
        db_name: dbName,
        filter: {
          practid: PractitionerId,
          tenantid: "",
          facilityid: "",
        },
        queryid: "d7178cd4-0e0a-43f3-90db-9235131342a7",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const DialysisApiSlice = createSlice({
  name: "dialysisApiSlie",
  initialState: {
    readDialysis: { ...defaultState.Info },
    upsertDailysis: { ...defaultState.Info },
    hdMachineNo: { ...defaultState.Info },
    heparinDropDown: { ...defaultState.Info },
    practioner_Name: { ...defaultState.Info },
    shiftTypeDropdown: { ...defaultState.Info },
  },
  extraReducers: {
    /* UPSERT_DIALYSIS */
    [UPSERT_DIALYSIS.fulfilled]: (state, action) => {
      state.upsertDailysis = action?.payload ?? [];
    },
    [UPSERT_DIALYSIS.pending]: (state, action) => {
      state.upsertDailysis.loading = true;
    },
    [UPSERT_DIALYSIS.rejected]: (state, action) => {
      state.upsertDailysis = action?.payload ?? [];
    },

    /* READ_DIALYSIS  */
    [READ_DIALYSIS.fulfilled]: (state, action) => {
      state.readDialysis = action?.payload ?? [];
    },
    [READ_DIALYSIS.pending]: (state, action) => {
      state.readDialysis.loading = true;
    },
    [READ_DIALYSIS.rejected]: (state, action) => {
      state.readDialysis = action?.payload ?? [];
    },
    /* HD_Machine_No  */
    [HD_Machine_No.fulfilled]: (state, action) => {
      state.hdMachineNo = action?.payload ?? [];
    },
    [HD_Machine_No.pending]: (state, action) => {
      state.hdMachineNo.loading = true;
    },
    [HD_Machine_No.rejected]: (state, action) => {
      state.hdMachineNo = action?.payload ?? [];
    },
    /* HeparinDropDown  */
    [HeparinDropDown.fulfilled]: (state, action) => {
      state.heparinDropDown = action?.payload ?? [];
    },
    [HeparinDropDown.pending]: (state, action) => {
      state.heparinDropDown.loading = true;
    },
    [HeparinDropDown.rejected]: (state, action) => {
      state.heparinDropDown = action?.payload ?? [];
    },
    /* ShiftTypeDropdown  */
    [ShiftTypeDropDown.fulfilled]: (state, action) => {
      state.shiftTypeDropdown = action?.payload ?? [];
    },
    [ShiftTypeDropDown.pending]: (state, action) => {
      state.shiftTypeDropdown.loading = true;
    },
    [ShiftTypeDropDown.rejected]: (state, action) => {
      state.shiftTypeDropdown = action?.payload ?? [];
    },
    /* Practioner_Name  */
    [Practioner_Name.fulfilled]: (state, action) => {
      state.practioner_Name = action?.payload ?? [];
    },
    [Practioner_Name.pending]: (state, action) => {
      state.practioner_Name.loading = true;
    },
    [Practioner_Name.rejected]: (state, action) => {
      state.practioner_Name = action?.payload ?? [];
    },
  },
});

export const DialysisApiActions = {
  READ_DIALYSIS,
  UPSERT_DIALYSIS,
  HD_Machine_No,
  HeparinDropDown,
  Practioner_Name,
  ShiftTypeDropDown,
};

export default DialysisApiSlice;
