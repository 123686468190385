export const handleCollapse = (index, state, setState) => {
  if (index === state?.index) {
    index = null;
  }
  setState({
    ...state,
    index,
  });
};

export const listClick = (value, index, state, setState , navigate) => {
  if (value?.children?.length) {
    handleCollapse(index, state, setState);
  } 
  else if(value?.link)
  {
    navigate({
      pathname: value?.link,
      state: value?.label,
    });
  }
};

export const onClickListing = (data, index, parentIndex, navigate, props) => {
  if (data?.link) {
    navigate({
      pathname: data?.link,
      state: data.label,
    });
  }
  props?.onClose && props.onClose();
};
