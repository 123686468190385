import { Container, Col, BackgroundImage } from "qdm-component-library";
import UnsecuredAppBar from "../components/layout/unseuredAppBar";
import login_bg from "../assets/img/svg/login_bg.svg"

const WithUnsecurePage = (WrappedComponent) => {
  return (props) => {
    return (
      <div id={"change_password_parent_div"}>
        <Container
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: "#e8e8e8",
            height: "100vh",
            padding: "0px",
          }}
          id={"change_password-Container"}
        >
          <UnsecuredAppBar />
          <Col
            key={"1"}
            name="qdm-col-12"
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            textAlign="left"
            lg="12"
            xs="12"
            md="12"
            sm="12"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 55px)",
              backgroundColor: "#F0F7FF",
            }}
            id={"change_password-Col1"}
          >
            <BackgroundImage
              key={"0"}
              url={login_bg}
              alt="Mask-Group-71-2x"
              backgroundPosition="0% 0%"
              backgroundPositionX="left"
              backgroundPositionY="top"
              backgroundRepeat="no-repeat"
              backgroundRepeatX="no-repeat"
              backgroundRepeatY="no-repeat"
              backgroundAttachment="scroll"
              backgroundSize="contain"
              backgroundOrigin="padding-box"
              inLineStyles="[object Object]"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100vh - 55px)",
              }}
              id={"change_password-BackgroundImage"}
            >
              <WrappedComponent {...props} />
            </BackgroundImage>
          </Col>
        </Container>
      </div>
    );
  };
};

export default WithUnsecurePage;
