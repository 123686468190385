import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    // width: "70%",
    // margin: "auto",
    border: "1px solid #dee5ec",
  },
  noPadding:{

  },
  topSection: {
    padding: "6px 16px 16px",
    backgroundColor: "#E6EFFF",
  },
  topSectionContents: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "3px",
  },
  topSectionHeader: {
    color: "#001C3C",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "4px",
  },
  topSectionSub: {
    color: "#2A3C50",
    fontSize: "12px",
    marginBottom: "4px",
  },
  topSectionLowerContents: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    marginBottom: "4px",
  },
  contactDetailsFont: {
    color: "#6A7888",
    fontSize: "10px",
  },
  fontCopy: {
    color: "#2A3C50",
    fontSize: "6px",
  },
  patientInfoSection: {
    borderBottom: "1px solid #dee5ec",
    padding: "16px 18px",
  },
  dot: {
    padding: "2px",
    backgroundColor: "#6A7888",
    borderRadius: "50%",
  },
  button: {
    backgroundColor: "#EC6A49",
    padding: "3px 8px",
    borderRadius:"4px",
    display:"flex",
    alignItems:"center",width:"85%"
  },
  buttonText:{
    fontSize:"12px",
    color:"#fff",

  }, 
  topSectionRightContents: {
    padding: "9px 12px",
    fontSize: "12px",
    color: "#2A3C50",
  },
  label:{
    fontSize: "12px",
    color: "#2A3C50",
  },
  labelValue:{
    fontSize: "12px",
    color: "#001C3C",
    fontWeight: 600
  },
  alignSpacebetween:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  alignItems:{
    display:"flex",
    alignItems:"center",
    gap:"6px"
  },
  queryText:{
    color:"#001C3C",
    fontSize:"10px"
  }
}));
