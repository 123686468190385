import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Loading = ({ loading, showChildOnLoading = false, children,style ={}, loaderStyle={} }) => {
  if (loading) {
    return (
      <>
        {/* {showChildOnLoading && children} */}
        <div style={{ display: "flex", justifyContent: "center", ...style }}>
        <Lottie options={defaultOptions} height={150} width={150} />
        </div>
      </>
    );
  }
  return <>{children}</>;
};

export default Loading;