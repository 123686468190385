import React from "react";
import { Div, Avatar, H6, Text, Image } from "qdm-component-library";
import { withStyles } from "@material-ui/core";
import { UIColor } from "../../themes/theme";
import upArrow from "../../assets/img/svg/Group 3873.svg"
import icons8privacypolicy from "../../assets/img/svg/icons8-privacy-policy.svg"
import { Grid, Typography, makeStyles } from "@material-ui/core";
import privacyPolicyIcon from "../../assets/img/svg/icons8-privacy-policy.svg";
import expiryDateIcon from "../../assets/img/svg/expirydateicon.svg";
import arrowIcon from "../../assets/img/svg/Group 3873.svg";
import dollorIcon from "../../assets/img/svg/Group 90507.svg"
import policyPlanIcon from "../../assets/img/svg/policyPlanIcon.svg";
const materialStyles = (theme) => ({
  avatarCls: {
    "& img": {
      width: "25px !important",
      height: "25px !important",
      objectFit: "contain !important",
    },
  },
});

const SelectCard = (props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     open: null,
  //   };
  // }

  const [open , setopen] = React.useState(null)
  const styles = {
    rowFlex: {
      display: "flex",
      alignItems: "center",
    },
    columnFlex: {
      display: "flex",
      flexDirection: "column",
    },
    h6: {
      fontSize: 14,
      color: UIColor.primaryColor.color,
      margin: 0,
    },
    h61: {
      fontSize: 14,
      color: UIColor.secondaryButtonColor.color,
      margin: 0,
    },
    span: {
      fontSize: 12,
      color: UIColor.tertiaryText.color,
      margin: 0,
    },
    avatar: {
      margin: 0,
      marginRight: 15,
      width: 48,
      height: 48,
      borderRadius: 8,
      backgroundColor: "#F0F0FA",
    },
    img: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
  };

    const { classes, showUpArrow, parent_id } = props;
    return (
      <React.Fragment>
       <Div
        id={`${props.parent_id}_selectcard_parent_div`}
        style={{ ...styles.rowFlex, flex: 1 }}>
        <Div
          id={`${props.parent_id}_selectcard_parent_sub_div`}
          style={{ ...styles.rowFlex, flex: 1 }}>
            {props?.isDetailed && props?.priority ? (
               <Avatar
               className={classes.avatarCls}
               inLineStyles={styles.avatar}
               >
                <Typography>{props?.priority}</Typography>
                </Avatar>
            ):(
              <>
              {props?.pic  ? (
                <Avatar
                  id={`${props.parent_id}_selectcard_${props?.name?.[0]}_avatar`}
                //src={this.props?.pic}
                  src={dollorIcon}
                  variant="rounded"
                  className={classes.avatarCls}
                  inLineStyles={styles.avatar}
                />
              ) : (
                <Avatar
                  id={`${props.parent_id}_selectcard_${props?.name?.[0]}_avatar`}
                  variant="rounded"
                  inLineStyles={styles.avatar}
                  className={classes.avatarCls}
                >
                  {props?.name?.[0]}
                </Avatar>
              )}
              </>
            )}
          <Div
            id={`${props.parent_id}_selectcard_title_div`}
            style={{ flex: 1 }}>
            <Div
              id={`${props.parent_id}_selectcard_title_sub_div`}
              style={{ ...styles.rowFlex, flex: 1 }}>
              <Div
                id={`${props.parent_id}_selectcard_title_name_sub_div`}
                style={{ flex: 1 }}>
                {props?.title_name && (
                  <Text
                    id={`${props.parent_id}_selectcard_${props?.title_name}_text`}
                    style={styles.rowFlex}>
                    <H6
                      id={`${props.parent_id}_selectcard_${props?.title_name}_h6`.replaceAll(" ","_")}
                      className="pc_semibold" style={styles.h6}>
                      {props?.title_name}
                    </H6>
                    &nbsp;
                    {props.noOfIteams === 1 ? '' : (!props.moreopen) ? null : (
                      <H6
                        id={`${props.parent_id}_selectcard_${props.noOfIteams}_h6`.replaceAll(" ","_")}
                        className="pc_semibold" style={styles.h61}>
                        {props.noOfIteams ? (
                          `& ${props.noOfIteams - 1}+ more`
                        ) : (
                          <div />
                        )}
                      </H6>
                    )}
                  </Text>
                )}
                <Grid container style={{gap: 10}}>
                  {props?.care_type && (
                    <Div
                      id={`${props.parent_id}_selectcard_${props?.care_type}_div`}
                      style={{ ...styles.rowFlex, marginTop: 7 }}>
                      <Div
                        id={`${props.parent_id}_selectcard_${props?.care_type}_sub_div`}
                        style={{ ...styles.rowFlex, marginRight: 10 }}>
                        {/* <Icons fontIcon="address-card-o" /> */}
                        <Image
                          id={`${props.parent_id}_selectcard_${props?.care_type}_image`}
                          style={styles.img}
                          src={privacyPolicyIcon}
                          alt="id"
                        />
                        <Text
                          id={`${props.parent_id}_selectcard_${props?.care_type}_text`}
                          className="pc_regular" style={styles.span}>
                          {props?.care_type}
                        </Text>
                      </Div>
                    </Div>
                  )}
                  {(props?.isDetailed && props?.policy ) && (
                  
                    <Div
                    id={`${props.parent_id}_selectcard_${props?.care_type}_div`}
                    style={{ ...styles.rowFlex, marginTop: 7 }}>
                    <Div
                      id={`${props.parent_id}_selectcard_${props?.care_type}_sub_div`}
                      style={{ ...styles.rowFlex, marginRight: 10 }}>
                      {/* <Icons fontIcon="address-card-o" /> */}
                      <Image
                        id={`${props.parent_id}_selectcard_${props?.care_type}_image`}
                        style={styles.img}
                        src={policyPlanIcon}
                        alt="id"
                      />
                      <Text
                        id={`${props.parent_id}_selectcard_${props?.care_type}_text`}
                        className="pc_regular" style={styles.span}>
                          {props?.policy}
                      </Text>
                    </Div>
                  </Div>
                    )}
                     {(props?.isDetailed && props?.policyexpiry ) && (
                  <Div
                  id={`${props.parent_id}_selectcard_${props?.care_type}_div`}
                  style={{ ...styles.rowFlex, marginTop: 7 }}>
                  <Div
                    id={`${props.parent_id}_selectcard_${props?.care_type}_sub_div`}
                    style={{ ...styles.rowFlex, marginRight: 10 }}>
                    {/* <Icons fontIcon="address-card-o" /> */}
                    <Image
                      id={`${props.parent_id}_selectcard_${props?.care_type}_image`}
                      style={styles.img}
                      src={expiryDateIcon}
                      alt="id"
                    />
                    <Text
                      id={`${props.parent_id}_selectcard_${props?.care_type}_text`}
                      className="pc_regular" style={styles.span}>
                        {props?.policyexpiry}
                    </Text>
                  </Div>
                </Div>
                  )}
                
                </Grid>
              </Div>
              {props?.showUpArrow && (
                <Div
                  id={`${props.parent_id}_selectcard_uparrow_div`}
                  style={{ ...styles.rowFlex, width: 24, height: 24 }}
                >
                  <Image
                    id={`${props.parent_id}_selectcard_uparrow_image`}
                    src={arrowIcon} alt="upArrow" />
                </Div>
              )}
            </Div>
            {props.showDivider && (
              <Div
                id={`${props.parent_id}_selectcard_showdivider_div`}
                style={{ borderTop: `1px dashed ${UIColor.lineBorderFill.color}`, marginTop: 10 }}
              />
            )}
          </Div>
        </Div>
      </Div>
      </React.Fragment>
    );
  }


SelectCard.defaultProps = {
  pic: "",
  title_name: "",
  care_type: "",
  showUpArrow: false,
  showDivider: false,
};

export default withStyles(materialStyles)(SelectCard);
