import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  urls,
  defaultState,
  defaultReject,
  fetchData,
  personRequests,
  makeName,
  dbName,
  query_ids,
} from "../../utils";

const { SearchPractitionerRequest, GetOrganisationRequest, ReadPersonRequest } =
  personRequests;

// const SEARCH_PRACTITIONER = createAsyncThunk(
//   `personSlice/SearchPractitioner`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const data = await fetchData(
//         SearchPractitionerRequest(payload),
//         urls.readQdmQueries
//       );

//       const orgID = data?.[0]?.OrgID?.[0];

//       const data__ = await fetchData(
//         GetOrganisationRequest(orgID),
//         urls.readDocuments
//       );
//       // const returnData = [data];
//       if (data[0]) {
//         data[0]["org_id"] = data__?.result?.[0]?._id;
//       }

//       return {
//         ...defaultState.List,
//         data,
//       };
//     } catch (err) {
//       return rejectWithValue({
//         ...defaultReject,
//         message: err.message,
//       });
//     }
//   }
// );
const SEARCH_PRACTITIONER = createAsyncThunk(
  `personApiSlice/SearchPractioner`,
  async (payload = {}, { rejectWithValue }) => {
    
    try {
      const { email = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["SearchPractitioner_QID"],
        filter: {
          emailId: email,
        },
      };
      const data = await fetchData({
        body: JSON.stringify(body)
      }, urls.readQdmQueries);
      const body__ = {
        db_name: dbName,
        entity: "Organization",
        filter: `Organization.id==${data?.[0]?.OrgID?.[0]}`,
        return_fields:
          "{_id:Organization._id,OrgID:Organization.id,name:Organization.name}",
      };
      const data__ = await fetchData(
        { body: JSON.stringify(body__) },
        urls.readDocuments
      );
      // const returnData = [data];
      if (data[0]) {
        data[0]["org_id"] = data__?.result?.[0]?._id;
      }

      return {
        ...defaultState.List,
        data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
// const SEARCH_PRACTITIONER = createAsyncThunk(
//   `personApiSlice/SearchPractioner`,
//   async (payload = {}, { rejectWithValue }) => {
    
//     try {
//       const { email = "" } = payload;
//       const body = {
//         db_name: dbName,
//         queryid: query_ids["SearchPractitioner_QID"],
//         filter: {
//           emailId: email,
//         },
//       };
//       const data = await fetchData({
//         body: JSON.stringify(body)
//       }, urls.readQdmQueries);
//       const body__ = {
//         db_name: dbName,
//         entity: "Organization",
//         filter: `Organization.id==${data?.[0]?.OrgID?.[0]}`,
//         return_fields:
//           "{_id:Organization._id,OrgID:Organization.id,name:Organization.name}",
//       };
//       const data__ = await fetchData(
//         { body: JSON.stringify(body__) },
//         urls.readDocuments
//       );
//       // const returnData = [data];
//       if (data[0]) {
//         data[0]["org_id"] = data__?.result?.[0]?._id;
//       }

//       return {
//         ...defaultState.List,
//         data,
//       };
//     } catch (err) {
//       return rejectWithValue({
//         ...defaultReject,
//         message: err.message,
//       });
//     }
//   }
// );
const READ_PERSON = createAsyncThunk(
  `personApiSlice/readPerson`,
  async (payload = {}, { rejectWithValue }) => {
    
    try {
      const { username = "", tenantid ="", facilityid="", allData = false } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["personApiSlice/readPerson"],
        filter: { 
          username: username,
          tenantid: tenantid,
          facilityid: facilityid 
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
     
      let returnData = {
        id: data?.[0]?.Id || 0,
        birthDay: data?.[0]?.birthdate || "",
        gender: data?.[0]?.gender || "",
        name: data?.[0]?.names?.[0]?.fulname || "",
        pid: data?.[0]?.Patientname?.[0]?._id || "",
        mrn: data?.[0]?.Patientname?.[0]?.patmrn || "",
        username:username,
        mobileno: data?.[0]?.telecom?.find(
          (val) => val.system === "CodingMaster/10473"
        )?.value || "",
        email:
          data?.[0]?.telecom?.find(
            (val) => val.system === "CodingMaster/10469" 
          )?.value || "",
        allData: data?.[0],
        imgUrl:
          data?.[0]?.photo?.[0]?.fileid ||
          data?.[0]?.Patientname?.[0]?.photo?.[0]?.fileid ||
          "",
        data: data,
      };
      if (allData) {
        returnData = data;
      }
      return {
        ...defaultState.List,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_RELATED_PERSON = createAsyncThunk(
  `personApiSlice/getRelatedPerson`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { userid = "", tenantid = "", facilityid="" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["personApiSlice/getRelatedPerson"],
        filter: { 
          userid: userid,
          tenantid: tenantid,
          facilityid: facilityid 
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );

      let returnData = [];
      if (Array.isArray(data)) {
        returnData = data[0]?.data || [];
      }
      return {
        ...defaultState.List,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);


const initialPersonState = {
  searchPractitioner: {
    ...defaultState.Info,
  },
  readPerson: {
    ...defaultState.Info,
  },
  getRelatedPerson: {
    ...defaultState.List,
  },
};

const personSlice = createSlice({
  name: "personSlice",
  initialState: initialPersonState,
  extraReducers: {
    [SEARCH_PRACTITIONER.fulfilled]: (state, action) => {
      state.searchPractitioner = action?.payload ?? [];
    },
    [SEARCH_PRACTITIONER.pending]: (state, action) => {
      state.searchPractitioner.loading = true;
    },
    [SEARCH_PRACTITIONER.rejected]: (state, action) => {
      state.searchPractitioner = action?.payload ?? [];
    },
    /* READ_PERSON */
    [READ_PERSON.fulfilled]: (state, action) => {
      state.readPerson = action?.payload ?? [];
    },
    [READ_PERSON.pending]: (state, action) => {
      state.readPerson.loading = true;
    },
    [READ_PERSON.rejected]: (state, action) => {
      state.readPerson = action?.payload ?? [];
    },
    /* GET_RELATED_PERSON */
    [GET_RELATED_PERSON.fulfilled]: (state, action) => {
      state.getRelatedPerson = action?.payload ?? [];
    },
    [GET_RELATED_PERSON.pending]: (state, action) => {
      state.getRelatedPerson.loading = true;
    },
    [GET_RELATED_PERSON.rejected]: (state, action) => {
      state.getRelatedPerson = action?.payload ?? [];
    },
  },
});

export const personActions = {
  SEARCH_PRACTITIONER,
  READ_PERSON,
  GET_RELATED_PERSON,
};

export default personSlice;
