import React from "react";
import { Div, Avatar, H6, Card, Text, Image } from 'qdm-component-library';
import { UIColor } from "../../../themes/theme";
import identificationDocumentsIcon from "../../../assets/img/svg/icons8-identification-documents.svg";
import groupIcon from "../../../assets/img/svg/Group 90503.svg"
import phoneIcon from "../../../assets/img/svg/icons8-phone.svg"
import Wallet from "../../../components/wallet";


const PatientInfoCard = (props) => {
    const styles = {
        rowFlex: {
            display: 'flex',
            alignItems: 'center'
        },
        columnFlex: {
            display: 'flex',
            flexDirection: 'column'
        },
        h6: {
            fontSize: 14,
            color: UIColor.primaryColor.color,
            margin: 0
        },
        span: {
            fontSize: 12,
            color: UIColor.tertiaryText.color,
            margin: 0
        },
        cardStyle: {
            boxShadow: "none",
            borderRadius: 8,
            padding: props.padding ? 10 : 0,
            position:"relative"
        },
        avatar: {
            margin: 0,
            marginRight: 15,
            width: 48,
            height: 48,
            borderRadius: 8,
            background: "#F0F0FA"
        },
        img: {
            width: 16,
            height: 16,
            marginRight: 10
        }
    };

    const { rowFlex, columnFlex, h6, span, cardStyle, avatar, img } = styles;
    const { parent_id ,walletData = ""} = props;

    return (
        <Card
            id={`${parent_id}_patientinfocard_parent_card`}
            style={cardStyle}>
            <Div
                id={`${parent_id}_patientinfocard_parent_div`}
                style={rowFlex}>
                {props.pic ? (
                    <Avatar
                        id={`${parent_id}_patientinfocard_avatar_one`}
                        src={props.pic} variant="rounded" inLineStyles={avatar}
                    />
                ) : (
                    <Avatar
                        id={`${parent_id}_${props.name?.[0]}_patientinfocard_avatar`}
                        variant="rounded" inLineStyles={avatar}
                    >
                        {props.name?.[0]}
                    </Avatar>
                )}
                <Div
                    id={`${parent_id}_patientinfocard_sub_div`}
                    style={columnFlex}>
                    <Text
                        id={`${parent_id}_patientinfocard_text`}
                        style={rowFlex}>
                        <H6
                            id={`${parent_id}_${props.name}_patientinfocard_name_h6`}
                            className="pc_semibold" style={h6}>
                            {props.name}
                        </H6>
                        <Text
                            id={`${parent_id}_patientinfocard_dot_text`}
                            className="pc_regular" style={{ ...span, margin: '0px 5px' }}>
                            •
                        </Text>
                        <Text
                            id={`${parent_id}_patientinfocard_gender_text`}
                            className="pc_regular" style={span}>
                            {props.age + ", " + props.gender}
                        </Text>
                    </Text>
                    <Div
                        id={`${parent_id}_patientinfocard_doc_div`}
                        style={{ ...rowFlex, marginTop: 7 }}>
                        <Div
                            id={`${parent_id}_patientinfocard_doc_sub_div`}
                            style={{ ...rowFlex, marginRight: 15 }}>
                            <Image
                                id={`${parent_id}_patientinfocard_identification_doc_image`}
                                style={img} src={identificationDocumentsIcon} alt="id" />
                            <Text
                                id={`${parent_id}_${props.mrn_id}_patientinfocard_text`}
                                className="pc_regular" style={span}>
                                {props.mrn_id}
                            </Text>
                        </Div>
                        <Div
                            id={`${parent_id}_patientinfocard_group_div`}
                            style={rowFlex}>
                            <Image
                                id={`${parent_id}_patientinfocard_group_image`}
                                style={img} src={groupIcon} alt="group" />
                            <Text
                                id={`${parent_id}_${props.pi_id}_patientinfocard_text`}
                                className="pc_regular" style={span}>
                                {props.pi_id}
                            </Text>
                        </Div>
                        <Div
                            id={`${parent_id}_patientinfocard_group_div`}
                            style={rowFlex}>
                            <Image
                                id={`${parent_id}_patientinfocard_group_image`}
                                style={img} src={phoneIcon} alt="group" />
                            <Text
                                id={`${parent_id}_${props.patientphonenum}_patientinfocard_text`}
                                className="pc_regular" style={span}>
                                {props.patientphonenum}
                            </Text>
                        </Div>
                    </Div>
                </Div>
                <div style={{ position: "absolute", top: 0, right: 0 }}>
                  <Wallet walletData={walletData} />
                </div>
            </Div>
        </Card>
    );
}

PatientInfoCard.defaultProps = {
    pic: null,
    name: "",
    age: null,
    gender: null,
    mrn_id: null,
    pi_id: null
};

export default PatientInfoCard;
