import { Div, Avatar, Text } from "qdm-component-library";
import { getCalculatedAge, getImgUrl } from "../../../../utils";
import { UIColor } from "../../../../themes/theme";
import moment from "moment";
import { useLocation } from "react-router-dom";

const PatientDetailCard = (props) => {
  const location = useLocation();
  const { state, setState } = props;
  return (
    <Div
      id="doctor_listing_avatar_parent_sub_div"
      style={{
        padding: 10,
        marginBottom: 8,
        backgroundColor: "white",
        boxShadow: "none",
        border: "1.5px solid #E0E0E0",
        borderRadius: 8,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Avatar
        id="doctor_listing_avatar"
        key={"0"}
        variant="circle"
        src={getImgUrl(location?.state?.selectedUser?.fileid)}
        letter={
          location?.state?.selectedUser?.name ||
          location?.state?.unregister?.patientName
        }
        alt="Image"
        style={{
          width: "32px",
          height: "32px",
          backgroundColor: UIColor.differentiationBackground.color,
          fontSize: "14px",
          color: "#101010",
          marginRight: "16px",
          marginLeft: "0",
          marginTop: "0",
          marginBottom: "0",
        }}
        className="pc_medium"
      ></Avatar>
      <Text
        id="doctor_listing_select_text"
        style={{
          fontSize: 16,
          marginRight: 8,
          color: UIColor.primaryText.color,
        }}
        className={"pc_semibold"}
      >
        {location?.state?.selectedUser?.name ||
          location?.state?.unregister?.patientName}
        {/* &nbsp; &nbsp; */}
      </Text>
      <span
        id="doctor_listing_and_span"
        className="pc_regular"
        style={{
          color: "#B6B6B6",
          marginRight: 8,
          color: UIColor.tertiaryText.color,
        }}
      >
        |
      </span>{" "}
      {/* &nbsp;&nbsp; */}
      <Text
        id="doctor_listing_Walk-in_text"
        style={{
          fontSize: 14,
          color: UIColor.tertiaryText.color,
        }}
        className={"pc_semibold"}
      >
        {/* {location?.state?.unregister?.is_Unregister
          ? location?.state?.unregister?.age
          : state?.sideInfo?.age || state?.sideInfo?.age === 0
          ? `${state?.sideInfo?.age} Years, `
          : location?.state?.age + " Years" ?? ""}{" "}
        {location?.state?.unregister?.is_Unregister
          ? location?.state?.unregister?.gender?.label
          : state?.sideInfo?.gender} */}
        {getCalculatedAge(location?.state?.selectedUser?.birthdate)} &nbsp;&nbsp;
        {location?.state?.selectedUser?.gender}
        {state?.selectedSlot?.info?.dateStart &&
        (moment()
          .startOf("day")
          .diff(moment(state?.selectedSlot?.info?.dateStart * 1000), "d") ===
          0 && state.selectedSlot?.info?.maxwalkin > 0) === true &&
        !location?.state?.unregister?.is_Unregister ? (
          <span
            id="doctor_listing_Walk-in_span"
            className="pc_regular"
            style={{ color: "#F58B00" }}
          >
            {location?.state?.unregister?.is_Unregister ||
            !state?.permissionDa?.write?.indexOf("downloadInvoice") > -1
              ? ""
              : "Walk-in"}
            {/* {"Walk-in"} */}
          </span>
        ) : (
          ""
        )}
      </Text>
    </Div>
  );
};

export default PatientDetailCard;
