import { useState } from "react";
import {
  Div,
  Row,
  Icons,
  TextArea,
  Text,
  Divider,
  TapSelect,
  // Upload
} from "qdm-component-library";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import FileViewer from "react-file-viewer";
import { withRouter } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import { Collapse, Modal } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { ToBase64 } from "../../../../utils";
import clsx from "clsx";
import { UIColor } from "../../../../themes/theme";
import Reportandadditionalservices from "../../../../assets/img/svg/report_and_additional_services.svg";
import {
  PickUpDrop,
  WheelChair,
  ValetParking,
  Lounge,
} from "../../../../components/svg";
import { useStyles } from "./styles";

const ReportAndAdditionalServices = (props) => {
  // State
  const [state, setState] = useState({
    expanded: true,
    isActiveservice: [],
    uploading: [],
    openFile: null,
    fileType: null,
  });

  const { parent_id, is_Unregister, permissionData, upload } = props;
  const classes = useStyles();
  // Functions

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const handleExpandClick = () => {
    setState({
      expanded: !state.expanded,
    });
  };

  const handleUpload = async (e) => {
    const { upload } = props;
    upload.push(e.target.files[0]);
    props.uploadFun(upload);
    setState({
      uploading: [],
    });
  };

  const handleClose = () => {
    setState({
      openFile: null,
      fileType: null,
      open: false,
    });
  };

  const viewFile = async (file) => {
    let data = "";
    if (file.fileUrl) {
      const { file: fileData } = await createFile(
        file.fileUrl,
        file.name,
        file.type
      );
      data = await ToBase64(fileData);
    } else {
      data = await ToBase64(file);
    }
    setState({
      openFile: data,
      viewFileName: file.name,
      fileType: file.type.split("/")[1],
      open: true,
    });
  };

  const createFile = async (url, name, type) => {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type,
    };
    const file = new File([data], name, metadata);
    return {
      file,
      size: data.size,
    };
  };

  const deleteFile = (index) => {
    const { upload } = props;
    upload.splice(index, 1);
    props.uploadFun(upload);
    setState({
      uploading: [],
    });
  };

  return (
    <Div
      id="doctor_listing_ReasonAdditionalServices_div"
      style={{ marginBottom: "8px" }}
    >
      <Card
        id={`${parent_id}_report_parent_card`}
        style={{
          boxShadow: "none",
          border: "1.5px solid #E0E0E0",
          borderRadius: 8,
        }}
      >
        <CardActions
          id={`${parent_id}_report_parent_cardactions`}
          onClick={handleExpandClick}
          disableSpacing
          style={{
            padding: 0,
            paddingLeft: 16,
            paddingBottom: 0,
            cursor: "pointer",
          }}
        >
          <img
            id={`${parent_id}_report_additionalservices_img`}
            alt="Reportandadditionalservices"
            src={Reportandadditionalservices}
            style={{ marginRight: 12 }}
          />
          <Text
            id={`${parent_id}_report_additional_services_text`}
            name={
              permissionData?.write?.indexOf("additionalServices") > -1
                ? "Additional Services"
                : "Report and Additional Services"
            }
            style={{ fontSize: "16px", color: "#001C3C", letterSpacing: 0 }}
            className={"pc_semibold"}
          />

          <IconButton
            id={`${parent_id}_report_expandmore_iconbutton`}
            className={clsx(classes.expand, {
              [classes.expandOpen]: state.expanded,
            })}
            aria-expanded={state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon id={`${parent_id}_report_expandmoricon`} />
          </IconButton>
        </CardActions>
        <Collapse
          id={`${parent_id}_report_collapse`}
          className={classes.body}
          in={state.expanded}
          timeout="auto"
          unmountOnExit
        >
          <Div id={`${parent_id}_report_parent_div`} key={"0"}>
            {permissionData?.write?.indexOf("additionalServices") > -1 ===
            false ? (
              <Row
                id={`${parent_id}_report_parent_row`}
                key={"0"}
                alignItems="center"
                direction="row"
                justify="space-between"
                spacing="2"
              >
                <Text
                  id={`${parent_id}_report_documents_text`}
                  key={"0"}
                  name="Reports / documents If any"
                  style={{
                    color: "#2A3C50",
                    fontSize: "14px",
                    letterSpacing: 0,
                  }}
                  className={"pc_regular"}
                />

                <div
                  id={`${parent_id}_report_documents_div`}
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    display: "inline-block",
                  }}
                >
                  <input
                    id={`${parent_id}_report_myfile_input`}
                    type="file"
                    name="myfile"
                    onChange={(e) => handleUpload(e)}
                    style={{
                      fontSize: "100px",
                      position: "absolute",
                      left: 0,
                      top: 0,
                      opacity: 0,
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                  />
                  <button
                    id={`${parent_id}_report_ Upload_button`}
                    style={{
                      border: "1px solid #F1F6FC",
                      color: "#2A60BC",
                      background: "#F1F6FC",
                      padding: "5px 20px",
                      borderRadius: "8px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    className={"pc_regular"}
                  >
                    Upload
                  </button>
                </div>
              </Row>
            ) : null}
            <Row id={`${parent_id}_report_viewfile_row`}>
              {upload.map((v, i) => {
                return (
                  <div
                    id={`${parent_id}_${i}_report_viewfile_div`}
                    className={classes.picker}
                    style={{
                      color: "#0071f2",
                      fontSize: 12,
                      fontFamily: "pc_medium",
                    }}
                  >
                    <span
                      id={`${parent_id}_${i}_report_${v.name}_span`}
                      style={{ paddingRight: 10 }}
                    >
                      {i < 9 ? `0${i + 1}` : i + 1}
                    </span>
                    {v.name}
                    {/* <img alt={"Eye"} src={Eye} onClick={() => viewFile(v)} style={{ cursor: 'pointer', marginLeft: 10 }} /> */}
                    <Icons
                      // key={"1"}
                      id={`${parent_id}_${i}_report_eye_icons`}
                      onClick={() => viewFile(v)}
                      className="pi-upload-doc-close"
                      fontIcon={"eye"}
                      ariaHidden={"true"}
                      inLineStyles={{ marginLeft: "10px", cursor: "pointer" }}
                      // onClick={() => deleteFile(i)}
                      size={14}
                    />
                    <Icons
                      id={`${parent_id}_${i}_report_close_icons`}
                      // key={"1"}
                      inLineStyles={{ marginLeft: "10px", cursor: "pointer" }}
                      className="pi-upload-doc-close"
                      fontIcon={"close"}
                      ariaHidden={"true"}
                      onClick={() => deleteFile(i)}
                      size={14}
                    />
                  </div>
                );
              })}
            </Row>

            <Div id={`${parent_id}_report_description_div`} key={"1"}>
              {permissionData?.write?.indexOf("additionalServices") > -1 ===
              false ? (
                <Divider
                  id={`${parent_id}_report_description_divider`}
                  key={"0"}
                  className=""
                  label=""
                  borderColor=""
                  textColor=""
                  orientation="horizontal"
                  variant="middle"
                  style={{ margin: "0px", marginTop: "16px" }}
                />
              ) : null}

              <Div id={`${parent_id}_report_Additional_Service_div`} key={"1"}>
                <Row
                  id={`${parent_id}_report_Additional_Service_row`}
                  key={"0"}
                  alignItems="stretch"
                  direction="row"
                  justify="flex-start"
                  spacing="2"
                  style={{
                    marginTop: "",
                    padding: "",
                    paddingTop: "16px",
                    margin: "",
                  }}
                >
                  <Text
                    id={`${parent_id}_report_Additional_Service_text`}
                    key={"0"}
                    name="Additional Service"
                    style={{
                      fontSize: "14px",
                      color: UIColor.primaryText.color,
                      letterSpacing: "0px",
                    }}
                    className={"pc_regular"}
                  />
                </Row>

                <TapSelect
                  id={`${parent_id}_report_tapselect`}
                  label=""
                  type="multiple"
                  fullWidth={true}
                  textSize="medium"
                  iconHeight="20"
                  iconWidth="20"
                  selectedBorderColor={UIColor.secondaryButtonColor.color}
                  selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                  selectedTextColor="#fff"
                  borderColor={"#ECF0F7"}
                  backgroundColor={"#ECF0F7"}
                  textColor={UIColor.secondaryText.color}
                  options={[
                    {
                      value: "Pick & Drop",
                      label: "Pick & Drop",
                      icon: (
                        <PickUpDrop
                          id={`${parent_id}_report_and_additional_pick_drop_svg`}
                        />
                      ),
                    },
                    {
                      value: "Avail Lounge",
                      label: "Avail Lounge",
                      icon: (
                        <Lounge
                          id={`${parent_id}_report_and_additional_avail_svg`}
                        />
                      ),
                    },
                    {
                      value: "Wheel Chair",
                      label: "Wheel Chair",
                      icon: (
                        <WheelChair
                          id={`${parent_id}_report_and_additional_wheel_svg`}
                        />
                      ),
                    },
                    {
                      value: "Valet Parking",
                      label: "Valet Parking",
                      icon: (
                        <ValetParking
                          id={`${parent_id}_report_and_additional_valet_svg`}
                        />
                      ),
                    },
                  ]}
                  inLineStyles={{
                    color: "#001C3C",
                    borderColor: "#e0e0e0",
                    padding: "6px 24px",
                    borderRadius: 12,
                    margin: "8px 8px 0px 0px",
                  }}
                  onChange={(data) => changeState("isActiveservice", data)}
                  defaultSelected={state.isActiveservice}
                  className={classes.TapSelect}
                />
              </Div>

              <Div
                id={`${parent_id}_report_message_div`}
                key={"2"}
                className="qdm"
                style={{ marginTop: "6px" }}
              >
                <Text
                  id={`${parent_id}_report_specific_message_text`}
                  key={"0"}
                  name="Would you like to share any specific message ?"
                  style={{
                    fontSize: "14px",
                    letterSpacing: 0,
                    color: "#2A3C50",
                  }}
                  className={"pc_regular"}
                />

                <Div
                  id={`${parent_id}_report_message_here_text`}
                  key={"1"}
                  className="qdm"
                  style={{ marginTop: "8px" }}
                >
                  <TextArea
                    id={`${parent_id}_report_message_here_textarea`}
                    key={"0"}
                    name="Type your message here..."
                    borderColor="#9b9b9b"
                    placeholder="Type your message here..."
                    style={{
                      borderColor: "#E0E0E0",
                      borderWidth: "1px",
                      borderRadius: "8px",
                      padding: 18,
                      resize: "none",
                      height: "auto",
                      background: "#F1F6FC",
                      color: "#6A7888",
                    }}
                    maxLength={500}
                    value={props.patientInstruction}
                    onChange={(e) => {
                      props.changeState("patientInstruction", e.target.value);
                    }}
                    className={"pc_regular"}
                  />
                </Div>
              </Div>
            </Div>
          </Div>
        </Collapse>
        {/* <Modal
          open={state.open}
          onClose={handleClose}
          className='docc-modal'
        >
          <div style={{ width: "100%", height: "100vh", display: "flex" }}>
            <FileViewer onError={onError} fileType={state.fileType} filePath={state.openFile} />
          </div>
        </Modal> */}
        <Modal
          open={state.open}
          onClose={handleClose}
          className="docc-modal"
          id={`${parent_id}_report_modal`}
        >
          <div
            id={`${parent_id}_report_documenthed_div`}
            style={{ width: "100%", height: "100vh" }}
          >
            <Grid
              id={`${parent_id}_report_documenthed_grid`}
              container
              style={{ background: "#d3d3d3e0" }}
            >
              <Grid
                id={`${parent_id}_report_documenthed_sub_grid`}
                item
                style={{ width: "50%" }}
                className={"documenthead"}
              >
                <Grid>
                  <Typography
                    id={`${parent_id}_report_${state.viewFileName}_typography`}
                    style={{ color: "black", marginTop: "9px" }}
                  >
                    {state.viewFileName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                id={`${parent_id}_report_upload_grid`}
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "50%",
                  paddingRight: "30px",
                }}
              >
                <Typography
                  id={`${parent_id}_report_upload_typography`}
                  variant="h6"
                >
                  <Button id={`${parent_id}_report_uploadclose_button`}>
                    <Icons
                      id={`${parent_id}_report_uploadclose_icons`}
                      inLineStyles={{
                        color: "black",
                        fontSize: "20px ​!importan",
                      }}
                      className="pi-upload-doc-close"
                      fontIcon={"close"}
                      ariaHidden={"true"}
                      onClick={() => handleClose()}
                      // onClick={() => deleteFile(i)}
                      size={14}
                    />
                  </Button>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              id={`${parent_id}_report_openfile_grid`}
              className="openFile-parent"
            >
              <FileViewer
                id={`${parent_id}_report_${state.filetype}_fileviewer`}
                //   onError={onError}
                fileType={state.fileType}
                filePath={state.openFile}
              />
            </Grid>
          </div>
        </Modal>
      </Card>
    </Div>
  );
};

export default ReportAndAdditionalServices;
