import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  appoinmentRequest,
  urls,
  makeName,
  getImgUrl,
  getCurrentPosition,
  calculationExp,
  dbName,
  getLocationInfo,
  getUtcTime,
  query_ids,
  utcTOLocal,
  timeLine_,
  slotRequest,
} from "../../utils";
import moment from "moment";
import jwtDecode from "jwt-decode";

const RAD_PRIORITY = createAsyncThunk(
  `RadiologySlice/getorderpriority`,
  async (payload = {}, { rejectWithValue }) => {
    try {
        const { tenantid = "", facilityid = "", lang = "" } = payload;
        const body = {
          db_name: dbName,
          queryid: query_ids["GetAllMasters"],
          filter: {
            type: ["LABPRIORITY"],
            tenantid: tenantid,
            facilityid: facilityid,
            lang: lang,
          },
        };
        const data = await fetchData(
          {
            body: JSON.stringify(body),
          },
          urls.readQdmQueries
        );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.LABPRIORITY?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const RAD_GETCATEGORY = createAsyncThunk(
  `RadiologySlice/getordercategory`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: query_ids["getordercategory"],
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let arr = [];

      data.map((val) => {
        arr.push({
          value: val?._id,
          label: val?.shortdesc ?? "",
          _id: val?._id,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const RAD_NATURE = createAsyncThunk(
  `RadiologySlice/getordernature`,
  async (payload = {}, { rejectWithValue }) => {
    try {
        const { tenantid = "", facilityid = "", lang = "" } = payload;
        const body = {
          db_name: dbName,
          queryid: query_ids["GetAllMasters"],
          filter: {
            type: ["ORDERNATURE"],
            tenantid: tenantid,
            facilityid: facilityid,
            lang: lang,
          },
        };
        const data = await fetchData(
          {
            body: JSON.stringify(body),
          },
          urls.readQdmQueries
        );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.ORDERNATURE?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const RadiologySlice = createSlice({
  name: "DiagnosisMasterApiSlice",
  initialState: {
    radiology_priority: {
      ...defaultState.List,
    },
    radiology_getcategory: {
      ...defaultState.List,
    },
    radiology_nature: {
      ...defaultState.List,
    },
  },
  extraReducers: {
    // RAD_PRIORITY
    [RAD_PRIORITY.fulfilled]: (state, action) => {
      state.radiology_priority = action?.payload ?? {};
    },
    [RAD_PRIORITY.pending]: (state, action) => {
      state.radiology_priority.loading = true;
    },
    [RAD_PRIORITY.rejected]: (state, action) => {
      state.radiology_priority = action?.payload ?? {};
    },
    // TREATMENT_RAD_GETCATEGORY
    [RAD_GETCATEGORY.fulfilled]: (state, action) => {
      state.radiology_getcategory = action?.payload ?? {};
    },
    [RAD_GETCATEGORY.pending]: (state, action) => {
      state.radiology_getcategory.loading = true;
    },
    [RAD_GETCATEGORY.rejected]: (state, action) => {
      state.radiology_getcategory = action?.payload ?? {};
    },
    // RAD_NATURE
    [RAD_NATURE.fulfilled]: (state, action) => {
      state.radiology_nature = action?.payload ?? {};
    },
    [RAD_NATURE.pending]: (state, action) => {
      state.radiology_nature.loading = true;
    },
    [RAD_NATURE.rejected]: (state, action) => {
      state.radiology_nature = action?.payload ?? {};
    },
  },
});

export const RadiologyMastersActions = {
  RAD_PRIORITY,
  RAD_GETCATEGORY,
  RAD_NATURE,
};

export default RadiologySlice;
