import React, { useState } from "react";
import { Div, H6, Icons, Text, Select, Row, Col } from "qdm-component-library";
import { TextField, makeStyles } from "@material-ui/core";
import { trimText,AlertProps } from "../../utils";
import  withAllContexts  from "../../hoc/withAllContexts";
import { UIColor } from "../../themes/theme";
const useStyles = makeStyles({
  autocomplete: {
    width: 100,
    marginRight: 10,
    "& input": {
      fontFamily: "pc_regular",
      fontSize: 14,
      color: UIColor.secondaryText.color,
      "&::placeholder": {
        fontSize: 14,
        fontFamily: "pc_regular !important",
      },
    },
  },
});

const SpecimenRequired = (props) => {
  const classes = useStyles(props);

  const styles = {
    flexCenterJustify: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    title: {
      color: UIColor.primaryText.color,
      fontSize: 12,
      margin: 0,
    },
    wrapper: {
      border: `1px solid ${UIColor.lineBorderFill.color}`,
      borderRadius: 8,
      padding: 10,
      backgroundColor: UIColor.greyBackground.color,
    },
    actionText: {
      fontSize: 12,
			color: UIColor.secondaryButtonColor.color,
			margin: 0,
			cursor: "pointer",
    },
    actionText_: {
      fontSize: 12,
      color: UIColor.primaryColor.color,
      margin: 0,
      cursor: "pointer",
      backgroundColor: UIColor.primaryColor.color,
			borderColor: UIColor.primaryColor.color,
			borderRadius: 8,
      color: "#fff",
      padding: "4px 10px"
    },
    cancelText:{
      fontSize: 12,
      margin: 0,
      cursor: "pointer",
      marginRight:"15px",
      padding: "8px",
			borderRadius: 8,
      border: `1px solid ${UIColor.primaryColor.color}`,
      color: UIColor.primaryColor.color,
      padding: "4px 10px"
    },
    specimenText: {
      color: UIColor.tertiaryText.color,
      fontSize: 10,
    },
    flexRow: {
      display: "flex",
      alignItems: "center",
    },
    trash: {
      color: UIColor.error.color,
      marginRight: 10,
      cursor: "pointer",
    },
    edit: {
      color: UIColor.primaryColor.color,
      cursor: "pointer",
    },
    labelStyle: {
      color: UIColor.tertiaryText.color,
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: UIColor.lineBorderFill.color,
    },
    errorBorder:{
      borderRadius: 8,
      borderColor: UIColor.error.color,
    },
    colPadding: {
      padding: "7px",
    },
    star: {
      color: UIColor.error.color,
      marginLeft: "5px",
      fontSize: 12,
    },
  };

  const [show, hide] = useState(false);
  const [state, setState] = useState({
    specimenType: props?.specimenData?.specimenType,
    volume: props?.specimenData?.volume,
    volumeUom: props?.specimenData?.volumeUom,
    bodySite: props?.specimenData?.bodySite,
    fasting: props?.specimenData?.FastingPeriod
      ? props?.specimenData?.FastingPeriod
      : props?.specimenData?.fasting,
    fastingUom: props?.specimenData?.fastingUom,
    collectionMethod: props?.specimenData?.collectionMethod,
  });
  React.useEffect(() => {
    setState({
      specimenType: props?.specimenData?.specimenType,
      volume: props?.specimenData?.volume,
      volumeUom: props?.specimenData?.volumeUom,
      bodySite: props?.specimenData?.bodySite,
      fasting: props?.specimenData?.FastingPeriod
        ? props?.specimenData?.FastingPeriod
        : props?.specimenData?.fasting,
      fastingUom: props?.specimenData?.fastingUom,
      collectionMethod: props?.specimenData?.collectionMethod,
    });
  }, [props?.specimenData]);
  const onChangeState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const addSpecimen = () => {
    hide(!show);
  };

  const [isEdit,setEdit] = useState(false)
  const addSpecimenEdit = () => {
    setEdit(true)
    hide(!show);
  }

  const cancel = () => {
    if(!isEdit){
      setState({
        specimenType: {},
        volume:"",
        volumeUom:{},
        bodySite:{},
        fasting:"",
        fastingUom:{},
        collectionMethod: {},
      })
    }
    hide(!show);
    setIsErr(false)
  }

  const [isErr,setIsErr] = useState(false)
  const validate = () => {
    if(
      // !state?.bodySite.label 
      // || !state.collectionMethod.label 
      // || !state.fasting 
      // || !state.fastingUom.label 
       !state.specimenType.value
      || !state.volume
      || !state.volumeUom.label){
        return true
    }else{
      return false
    }
  }

  const save = () => {
    const checkErr = validate()
    if(checkErr){
      setIsErr(true)
      props.alert.setSnack({
				open: true,
				severity: AlertProps.severity.error,
				msg: "Please fill all the mandatory fields",
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
    }else{
      hide(!show);
      props?.actionButton && props.actionButton(state);
    }
  };
  const removeSpecimen = () => {
    const newState = {
      specimenType: {},
      volume:"",
      volumeUom:{},
      bodySite:{},
      fasting:"",
      fastingUom:{},
      collectionMethod: {},
    }
    setState(newState)
    props?.actionButton && props.actionButton(newState);
  }
  const { parent_id } = props;
  return (
    <Div
      id={`${parent_id}_specimenreq_parent_div`}
      style={styles.wrapper}>
      <Div
        id={`${parent_id}_specimenreq_parent_sub_div`}
        style={styles.flexCenterJustify}>
        <Div
          id={`${parent_id}_specimenreq_title_div`}
          style={{ width: "100%" }}>
          <Div
            id={`${parent_id}_specimenreq_title_sub_div`}
            style={styles.flexCenterJustify}>
            <H6
              id={`${parent_id}_specimenreq_${props?.title}_h6`}
              className="pc_regular" style={styles.title}>
              {props?.title}
            </H6>
            {!show && !state?.specimenType?.value && (
              <H6
                id={`${parent_id}_specimenreq_${props?.action}_h6`}
                className="pc_regular"
                style={styles.actionText}
                onClick={addSpecimen}
              >
                {props?.action}
              </H6>
            )}
            {show && (
              <div style={{display:"flex"}}>
              <H6
                id={`${parent_id}_specimenreq_Cancel_h6`}
                className="pc_regular"
                style={styles.cancelText}
                onClick={cancel}
              >
                Cancel
              </H6>
              <H6
                id={`${parent_id}_specimenreq_Save_h6`}
                className="pc_regular"
                style={styles.actionText_}
                onClick={save}
              >
                Save
              </H6>
              </div>
            )}
          </Div>
          {/* specimen data after filled text will be appear */}
          {!show && (isErr === false) && (
            <Text
              id={`${parent_id}_specimenreq_show_parent_text`}
            >
              {state?.specimenType?.value && (
                <Text
                  id={`${parent_id}_specimenreq_${state?.specimenType?.value}_text`}
                  className="pc_medium" style={styles.specimenText}>
                    
                  {/* {state?.specimenType?.value} */}
                  {props?.getspecimentype?.data?.map((x) => { if(x.label === state?.specimenType?.label){ return x.value }})}
                </Text>
              )}
              {state?.volume && (
                <Text
                  id={`${parent_id}_specimenreq_${state?.volumeUom?.value}_text`}
                  className="pc_medium" style={styles.specimenText}>
                  &nbsp;•&nbsp;{state?.volume}&nbsp;{state?.volumeUom?.value}
                </Text>
              )}
              {state?.bodySite?.value && (
                <Text
                  id={`${parent_id}_specimenreq_${state?.bodySite?.value}_text`}
                  className="pc_medium" style={styles.specimenText}>
                  &nbsp;•&nbsp;{state?.bodySite?.value}
                </Text>
              )}
              {state?.fasting && (
                <Text
                  id={`${parent_id}_specimenreq_${state?.fastingUom?.value}_text`}
                  className="pc_medium" style={styles.specimenText}>
                  &nbsp;•&nbsp;{state?.fasting}&nbsp;{state?.fastingUom?.value}
                </Text>
              )}
              {state?.collectionMethod?.value && (
                <Text
                  id={`${parent_id}_specimenreq_${state?.collectionMethod?.value}_text`}
                  className="pc_medium" style={styles.specimenText}>
                  &nbsp;•&nbsp;{state?.collectionMethod?.value}
                </Text>
              )}
            </Text>
          )}
             {/* specimen form type data */}
          {show && (
            <Row
              id={`${parent_id}_specimenreq_specimenType_row`}
            >
              <Col
                id={`${parent_id}_specimenreq_specimenType_col`}
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                inLineStyles={styles.colPadding}
              >
                 
                <Select
                  id={`${parent_id}_specimenreq_specimenType_select`}
                  showArrow
                  label={"Specimen Type"}
                  isRequired
                  placeholder={"Select Type"}
                  labelStyle={styles.labelStyle}
                  inLineStyles={isErr && !state.specimenType.value ? styles.errorBorder :styles.borderStyle}
                  hoverColor={UIColor.lineBorderFill.color}
                  value={state?.specimenType?.value}
                  onChangeValue={(e) => onChangeState("specimenType", e)}
                  options={props?.getspecimentype} 
                />
              </Col>
              <Col
                id={`${parent_id}_specimenreq_Volume-UOM_col`}
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                inLineStyles={styles.colPadding}
              >
                <Text
                  id={`${parent_id}_specimenreq_Volume-UOM_text`}
                  className="pc_regular"
                  style={styles.labelStyle}>
                  Volume & UOM<Text
                    id={`${parent_id}_specimenreq_Volume-UOM_star_text`}
                    style={styles.star}>*</Text>
                </Text>
                <Div
                  id={`${parent_id}_specimenreq_Volume_input_div`}
                  style={styles.flexRow}>
                  <input
                    id={`${parent_id}_specimenreq_Volume_input`}
                    maxLength={5}
                    className={classes.autocomplete}
                    value={state?.volume}
                    onChange={(e) => onChangeState("volume", e.target.value)}
                    // placeholder={"Start Date"}
                    InputProps={{
                      classes: { input: classes.input },
                    }}
                    style={{
                      outline: "none",
                      border: "none",
                      borderBottom: isErr && !state?.volume ? `1px solid ${UIColor.error.color}` : `1px solid ${UIColor.primaryText.color}`,
                      backgroundColor: UIColor.greyBackground.color,
                    }}
                  />
                  <Select
                    id={`${parent_id}_specimenreq_Select-UOM_select`}
                    showArrow
                    placeholder={"Select UOM"}
                    inLineStyles={isErr && !state?.volumeUom?.label ? styles.errorBorder :styles.borderStyle}
                    hoverColor={UIColor.lineBorderFill.color}
                    value={trimText(state?.volumeUom?.value, 18)}
                    onChangeValue={(e) => onChangeState("volumeUom", e)}
                    options={props?.uom}
                  />
                </Div>
              </Col>
              <Col
                id={`${parent_id}_specimenreq_Body_Site_col`}
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                inLineStyles={styles.colPadding}
              >
                <Select
                  id={`${parent_id}_specimenreq_Body_Site_select`}
                  showArrow
                  label={"Body Site"}
                  // isRequired
                  placeholder={"Select body site"}
                  labelStyle={styles.labelStyle}
                  inLineStyles={
                    // isErr && !state?.bodySite?.value ? styles.errorBorder :
                     styles.borderStyle}
                  hoverColor={UIColor.lineBorderFill.color}
                  value={state?.bodySite?.value}
                  onChangeValue={(e) => onChangeState("bodySite", e)}
                  options={props?.getbodysite}
                />
              </Col>
              <Col
                id={`${parent_id}_specimenreq_Body_Site_select`}
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                inLineStyles={styles.colPadding}
              >
                <Text
                  id={`${parent_id}_specimenreq_Fasting-UOM_text`}
                  className="pc_regular"
                  style={styles.labelStyle}>
                  Fasting UOM
                  {/* <Text
                    id={`${parent_id}_specimenreq_Fasting-UOM_star_text`}
                    style={styles.star}>*</Text> */}
                </Text>
                <Div
                  id={`${parent_id}_specimenreq_Fasting_div`}
                  style={styles.flexRow}>
                  <input
                    id={`${parent_id}_specimenreq_Fasting_input`}
                    maxLength={3}
                    className={classes.autocomplete}
                    value={state?.fasting}
                    onChange={(e) => onChangeState("fasting", e.target.value)}
                    // placeholder={"Start Date"}
                    style={{
                      outline: "none",
                      border: "none",
                      borderBottom: 
                      // isErr && !state?.fasting ? `1px solid ${UIColor.error.color}` : 
                       `1px solid ${UIColor.primaryText.color}`,
                      backgroundColor: UIColor.greyBackground.color,
                    }}
                    InputProps={{
                      classes: { input: classes.input },
                    }}
                  />
                  <Select
                    id={`${parent_id}_specimenreq_Fasting-Uom_select`}
                    showArrow
                    placeholder={"Select UOM"}
                    inLineStyles={
                      // isErr && !state?.fastingUom?.value ? styles.errorBorder : 
                      styles.borderStyle}
                    hoverColor={UIColor.lineBorderFill.color}
                    value={trimText(state?.fastingUom?.value, 18)}
                    onChangeValue={(e) => onChangeState("fastingUom", e)}
                    options={props?.uom}
                  />
                </Div>
              </Col>
              <Col
                id={`${parent_id}_specimenreq_collectionMethod_col`}
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                inLineStyles={styles.colPadding}
              >
                <Select
                  id={`${parent_id}_specimenreq_collectionMethod_select`}
                  showArrow
                  label={"Collection Method"}
                  // isRequired
                  placeholder={"Select Method"}
                  labelStyle={styles.labelStyle}
                  inLineStyles={
                    // isErr && !state?.collectionMethod?.value ? styles.errorBorder :
                    styles.borderStyle}
                  hoverColor={UIColor.lineBorderFill.color}
                  value={state?.collectionMethod?.value}
                  onChangeValue={(e) => onChangeState("collectionMethod", e)}
                  options={props?.getspeccollmethod}
                />
              </Col>
            </Row>
          )}
        </Div>
        {!show && state?.specimenType?.value && (
          <Div
            id={`${parent_id}_specimenreq_trash_div`}
            style={styles.flexRow}>
            <Icons
              id={`${parent_id}_specimenreq_trash_icons`}
              fontIcon="trash" style={styles.trash}  onClick={removeSpecimen} />
            <Icons
              id={`${parent_id}_specimenreq_edit_icons`}
              fontIcon={"pencil"}
              onClick={addSpecimenEdit}
              style={styles.edit}
            />
          </Div>
        )}
      </Div>
    </Div>
  );
};

SpecimenRequired.defaultProps = {
  title: "",
  action: "",
  actionButton: null,
  specimenData: {},
  onChangeState: null,
};

export default withAllContexts(SpecimenRequired);

