import React from "react";

export const Bsa = (props) => {
  let width = props.width ? props.width : "13";
  let height = props.height ? props.height : "15";
  //   let color = props.color ? props.color : "#000000";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20.539"
    >
      <path
        id="icons8-bsa"
        d="M8.423,4.978a.81.81,0,0,0-.565.249L6.237,6.848a.81.81,0,0,0,0,1.146L7.858,9.614A.81.81,0,1,0,9,8.468l-.237-.237h8.512l-.237.237a.81.81,0,1,0,1.146,1.146l1.621-1.621a.81.81,0,0,0,0-1.146L18.187,5.227a.81.81,0,1,0-1.146,1.146l.237.237H8.766L9,6.373a.81.81,0,0,0-.58-1.395Zm15.121,5.954a.81.81,0,0,0-.561.237L21.363,12.79a.81.81,0,1,0,1.146,1.146l.237-.237v9.052l-.237-.237a.81.81,0,1,0-1.146,1.146l1.621,1.621a.81.81,0,0,0,1.146,0l1.621-1.621A.81.81,0,1,0,24.6,22.513l-.237.237V13.7l.237.237A.81.81,0,1,0,25.75,12.79l-1.621-1.621A.81.81,0,0,0,23.545,10.932Zm-14.574.54A2.975,2.975,0,0,0,6,14.443v7.563a2.975,2.975,0,0,0,2.971,2.971h7.563A2.975,2.975,0,0,0,19.5,22.006V14.443a2.975,2.975,0,0,0-2.971-2.971Z"
        transform="translate(-6 -4.978)"
        fill="#ff8989"
      />
    </svg>
  );
};
