import { Checkbox, Table, TableContainer, TableHead, TableBody, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    table: {
        '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root': {
            borderBottom: 0
        },
        '& .MuiTableRow-head': {
            backgroundColor: '#F0F0F0',
            '& .MuiTableCell-head': {
                padding: 0,
                fontWeight: '600',
                fontSize: '14px'
            }
        }
    }
})

function TableList(props) {
    const { tableHeader = [], tableData = [], isSerialNo = false, isCheckBox = false, tableDataList = [], loading = false, isempty = false, parent_id } = props;
    const classes = useStyles();
    return <TableContainer
        id={`${parent_id}_billtable_parent_tablecontainer`}
    >
        <Table
            id={`${parent_id}_billtable_parent_table`}
            className={classes.table} aria-label="simple table">
            {tableHeader.length > 0 && <TableHead>
                <TableRow
                    id={`${parent_id}_billtable_tablerow`}
                >
                    {isCheckBox && <TableCell
                        id={`${parent_id}_billtable_tablecell`}
                        align="left"><Checkbox
                            id={`${parent_id}_billtable_checkbox`}
                        /></TableCell>}

                    {
                        tableHeader.map((val, i) => {
                            return <TableCell
                                id={`${parent_id}_${i}_billtable_tablecell`}
                            >{val}</TableCell>
                        })
                    }
                </TableRow>
            </TableHead>}
            <TableBody>

                {
                    ((tableData && tableData.length > 0) && !loading) &&
                    tableData.map((data, i) => {

                        return <TableRow
                            id={`${parent_id}_${i}_billtable_data_tablerow`}
                            key={i}>
                            {isCheckBox && <TableCell
                                id={`${parent_id}_${i}_billtable_data_tablecell`}
                                align="left"><Checkbox
                                    id={`${parent_id}_${i}_billtable_data_checkbox`}
                                /></TableCell>}
                            {isSerialNo && <TableCell
                                id={`${parent_id}_${i}_billtable_data1_tablecell`}
                                align="left">{i + 1}</TableCell>}
                            {
                                tableDataList.map(val => <TableCell
                                    id={`${parent_id}_${i}_billtable_N/A_tablecell`}
                                    align="left">{data[val] ? data[val] : isempty ? "" : "N/A"}</TableCell>)
                            }
                        </TableRow>
                    })
                }
            </TableBody>
        </Table>
        {
            !((tableData && tableData.length > 0) || loading) &&
            <div
                id={`${parent_id}_billtable_data_found_div`}
                style={{ textAlign: "center", marginTop: "10px" }}> No Data Found!</div>
        }
        {
            loading && <div
                id={`${parent_id}_billtable_loading_div`}
                style={{ textAlign: "center" }}>Loading...</div>
        }
    </TableContainer>
}

export default TableList;