import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../../../../../themes/theme";

export const useStyles = makeStyles((theme) => ({
  selectedTab: {
    backgroundColor: `${UIColor.primaryColor.color} !important`,
    color: "#fff !important",
    fontSize: 14,
    fontWeight: 600,
    marginTop: "8%",
    textTransform: "none",
  },
  selecteTab: {
    marginTop: "8%",
    fontSize: 14,
    fontWeight: 600,
    color: UIColor.primaryText.color,

    textTransform: "none",
  },
  typography: {
    fontSize: "14px !important",
    fontWeight: "bold !important",
    display: "flex",
    cursor: "pointer",
  },
  endafterdiv: {
    padding: 8,
    backgroundColor: UIColor.greyBackground.color,
    border: `1px solid ${UIColor.lineBorderFill.color}`,
  },
  endafterdiv1: {
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 6px",
    cursor: "pointer",
  },
  Popover1: {
    "& .MuiPopover-paper": {
      width: "20%",
    },
  },
  tapselectcss: {
    "& span": {
      fontSize: 14,
      // color: "#101010",
    },
  },
  monthCalendar: {
    padding: 5,
    margin: 5,
    // width:300,
    "& strong": {
      fontSize: 13,
    },
    "& span": {
      fontSize: 13,
    },
    backgroundColor: "#fff",
  },
  tapSelectStyle: {
    //padding: "4px 8px",
    //borderColor: "#E0E0E0",
    // margin: "4px 10px 6px 0px",
    borderRadius: "8px",
    // minWidth: "auto",
    margin: "0px 6px 6px 10px",
    minWidth: 30,
    padding: "5px 15px",
  },
}));
