import {
  Divider,
  Text,
  Div,
  Button,
  Card,
  Image,
  H2,
} from "qdm-component-library";

import { Grid } from "@material-ui/core";

import { UIColor } from "../../../themes/theme";

import classes from "./styles.json";

import iconHigh from "../../../../src/assets/img/svg/icons8-high priority red.svg";

import iconHighAlert from "../../../../src/assets/img/svg/icons8-high priority white.svg";

import { AlertProps } from "../../../utils";

import { Alerts } from "../../../components/global/alerts";

import { AlertContext } from "../../../context";

import React from "react";

const ToAttend = (props) => {
  const { setSnack } = React.useContext(AlertContext);

  const { parent_id, markHighPriority, assignReassignPractitioner } = props;

  return (
    <Div
      id={`${parent_id}${props.index}_queue_attend_parent_div`}
      style={classes.wrapper}
      key={props.index}
    >
      <Div
        id={`${parent_id}${props.index}_queue_attend_parent_sub_div`}
        style={classes.header}
      >
        <Grid container>
          {props.item?.title && (
            <H2
              id={`${parent_id}${props.index}_queue_attend_title_h2`}
              className="pc_semibold"
              style={classes.headerTitle}
            >
              {props.item?.title}
            </H2>
          )}
        </Grid>

        <Grid container justifyContent="space-around">
          <Grid item>
            {props.item?.token && (
              <Div
                id={`${parent_id}${props.index}_queue_attend_token_number_div`}
                className="pc_medium"
                style={classes.tokenNumber}
              >
                {props.item?.token}
              </Div>
            )}
          </Grid>

          <Grid item>
            {props?.item?.totalData?.info?.appno && (
              <Div
                id={`${parent_id}${props.index}_queue_attend_token_number_div`}
                className="pc_medium"
                style={classes.appNumber}
              >
                {props?.item?.totalData?.info?.appno}
              </Div>
            )}
          </Grid>
        </Grid>
      </Div>

      <Card
        id={`${parent_id}${props.index}_queue_attend_parent_card`}
        style={classes.cardStyle}
      >
        {!markHighPriority && (
          <Div style={classes.highAlertPosition}>
            <Div
              style={{
                backgroundColor:
                  props.item?.patientPriority?.priority?.value === "High"
                    ? "#FF4D4A"
                    : "#F9E6E6",

                padding: "5px 8px 3px",

                borderRadius: "50%",

                cursor: "pointer",
              }}

              // onClick={(event) =>

              //   props.handleHighAlertPop(

              //     event,

              //     props.item?.title,

              //     props.item?.encounter,

              //     props.item?.patientPriority,

              //     props.item?.totalData,

              //     props.item?.role_

              //   )

              // }
            >
              <Image
                inLineStyles={{ height: "17px", width: "16px" }}
                src={
                  props.item?.patientPriority?.priority?.value === "High"
                    ? iconHighAlert
                    : iconHigh
                }
              />
            </Div>
          </Div>
        )}

        <Div
          id={`${parent_id}${props.index}_queue_attend_attendbody_div`}
          style={classes.attendBody}
        >
          {props.item?.profile_pic && (
            <Div
              id={`${parent_id}${props.index}_queue_attend_profile_div`}
              style={{ ...classes.attendBodyAvatar, marginTop: 20 }}
            >
              <Image
                id={`${parent_id}${props.index}_queue_attend_profilepic_image`}
                src={props.item?.profile_pic}
                alt={props.item?.name ?? "profilePic"}
                style={{
                  border: props.item?.isEmergency ? "1px dashed #FF4D4A" : "0",
                }}
              />

              {props.item?.isEmergency && (
                <Div
                  id={`${parent_id}${props.index}_queue_attend_emergency_div`}
                  style={classes.emergencyDiv}
                >
                  <Text
                    id={`${parent_id}${props.index}_queue_attend_emergency_text`}
                    className="pc_medium"
                    style={{
                      ...classes.emergencyText,

                      fontSize: 10,
                    }}
                  >
                    EMERGENCY
                  </Text>
                </Div>
              )}
            </Div>
          )}

          {props.item?.name && (
            <H2
              id={`${parent_id}${props.index}_queue_attend_previousNextBodyName_h2`}
              className="pc_semibold"
              style={classes.previousNextBodyName}
            >
              {props.item?.name}
            </H2>
          )}

          {props.item?.role && (
            <Text
              id={`${parent_id}${props.index}_queue_attend_previousNextBodyRole_text`}
              className="pc_regular"
              style={{
                ...classes.previousNextBodyRole,

                margin: "5px 0px",
              }}
            >
              {props.item?.role}

              {props.item?.isRegister && ` • ${props.item?.isRegister}`}
            </Text>
          )}

          {props.item?.start && (
            <Text
              id={`${parent_id}${props.index}_queue_attend_previousNextBodyRole_start_text`}
              className="pc_regular"
              style={{
                ...classes.previousNextBodyRole,

                margin: "5px 0px",
              }}
            >
              {props.item?.start}
            </Text>
          )}

          {props.item?.primaryButtonName && !props.queueWrite && (
            <Button
              id={`${parent_id}${props.index}_queue_attend_primarybutton_button`}
              className="pc_medium"
              type="button"
              // disabled={true}

              name={
                // props.item?.consultationMode !== "Direct Consultation"

                //   ? "Join Now"

                //   :

                props.item?.primaryButtonName
              }
              variant="outlined"
              size={14}
              backgroundColor={UIColor.secondaryButtonColor.color}
              onClick={() => props.item?.primaryButtonAction(props.item)}
              inLineStyles={{
                marginTop: "10px",

                width: "100%",

                borderRadius: 8,

                fontSize: 13,
              }}
            />
          )}
        </Div>

        <Divider
          id={`${parent_id}${props.index}_queue_attend_divider`}
          style={classes.divider}
        />

        {!props.queueWrite && (
          <Grid
            id={`${parent_id}${props.index}_queue_secondarybutton_div`}
            spacing={2}
            container
          >
            <Grid item xs={!assignReassignPractitioner ? 6 : 12}>
              <Div
                id={`${parent_id}${props.index}_queue_attend_secondarybutton_div`}
                style={classes.secondaryButton}
              >
                {props.item?.secondaryButtonName && (
                  <Button
                    id={`${parent_id}${props.index}_queue_attend_secondarybutton_button`}
                    type="button"
                    name={""}
                    variant="text"
                    size={12}
                    onClick={
                      props.item?.secondaryButtonAction
                        ? () => props.item?.secondaryButtonAction()
                        : null
                    }
                    inLineStyles={{
                      color: "#F58B00",

                      fontWeight: "600",

                      display: "flex",

                      alignItems: "center",

                      margin: "auto",
                    }}
                  >
                    {props.item?.secondaryButtonIcon && (
                      <Image
                        style={{ width: 16, height: 16 }}
                        src={props.item?.secondaryButtonIcon}
                        alt={"image"}
                      />
                    )}

                    <Text
                      className="pc_medium"
                      style={classes.secondaryButtonName}
                    >
                      {props.item?.secondaryButtonName}
                    </Text>
                  </Button>
                )}
              </Div>
            </Grid>

            {!assignReassignPractitioner && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "10px 0px" }}
                />

                <Grid item xs={5}>
                  <Div>
                    {/* {props.item?.assignButtonName && ( */}

                    <>
                      <Button
                        id={`${parent_id}${props.index}_queue_attend_secondarybutton_button`}
                        type="button"
                        name={""}
                        variant="text"
                        size={12}
                        // onClick={

                        //   props.item?.assignButtonAction

                        //     ? () => props.item?.assignButtonAction()

                        //     : null

                        // }

                        inLineStyles={{
                          color: "#F58B00",

                          fontWeight: "600",

                          display: "flex",

                          alignItems: "center",

                          margin: "auto",
                        }}
                      >
                        {props.item?.assignButtonIcon && (
                          <Image
                            style={{ width: 16, height: 16 }}
                            src={props.item?.assignButtonIcon}
                            alt={"image"}
                          />
                        )}

                        <Text
                          className="pc_medium"
                          style={classes.assignButtonName}
                        >
                          {props.item?.assigned ? "Re Assign" : "Assign"}
                        </Text>
                      </Button>
                    </>

                    {/* )} */}
                  </Div>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Card>
    </Div>
  );
};

export default ToAttend;
