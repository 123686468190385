import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import useStyles from "./styles";
import {
  DashboardIcon,
  CloseConsultation,
  DocumentIcon,
  MenuIcon,
  WorkList,
} from "../../../svg";
import { AssignPractitioner } from "../../../svg/components/assignPractitioner";
import { ReAssignPractitioner } from "../../../svg/components/reassignPractitioner";

// const useStyles = makeStyles(() => ({
//   flexRow: (props) => ({
//     display: "flex",
//     alignItems: "center",
//     padding: 8,
//     backgroundColor: props?.backgroundColor ?? "transparent",
//     borderRadius: props?.backgroundColor ? 4 : 0,
//     cursor: "pointer",
//   }),
//   label: (props) => ({
//     fontSize: 12,
//     fontFamily: "pc_medium",
//     color: "#101010",
//     flex: 1,
//     color: props?.color ?? "#000",
//   }),
//   icon: {
//     paddingRight: 8,
//     display: "flex",
//   },
// }));

const CustomListing = (props) => {
  const classes = useStyles();

  const icon = (label) => {
    if (label === "Dashboard") {
      return <DashboardIcon />;
    }else if (label ==="Main Dashboard") {
      return <WorkList />;;
    }else if (label ==="HQ Dashboard") {
      return <WorkList />;;
    }else if (label === "Bulk Booking") {
      return <MenuIcon />;;
    } else if (label === "Work List") {
      return <WorkList />;
    } else if (label === "Assign Practitioner") {
      return <AssignPractitioner />;
    } else if (label === "Re-Assign Practitioner") {
      return <ReAssignPractitioner />;
    } else if (label === "Close Consultation") {
      return <CloseConsultation />;
    } else if (label === "Day End Process") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Menu name") {
      return <MenuIcon />;
    } else if (label === "Biller and Cashier") {
      return <WorkList />;
    } else if (label === "Reports") {
      return <MenuIcon />;
    } else if (label === "Cash Collection") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Bill Register Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Claim Summary Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Active Patient Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Patient Attendance Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Month wise Attendance Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Observation Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "EPO Consumption Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Panel Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Patient Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Cancelled and No Show Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Nursing Procedure Summary Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Patient Attendance Summary Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Patient Review Chart Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "PIC Review Sheet Report") {
      return <DocumentIcon color={"#FF9F40"} />;
    }
  };

  return (
    <div className={classes?.flexRow} onClick={props?.onClick ?? null}>
      <div className={classes?.icon}>{icon(props.label)}</div>
      <Typography variant="body1" className={classes?.label}>
        {props?.label}
      </Typography>
    </div>
  );
};

export default CustomListing;
