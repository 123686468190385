import { makeStyles } from "@material-ui/core/styles";

export const useDialogStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 300,
    borderRadius: 8,
  },
}));

export const useDialogContentStyles = makeStyles((theme) => ({
  dividers: {
    padding: 0,
    borderColor: "rgba(222, 229, 236, 1)",
  },
}));

export const useDividerStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#DEE5EC",
  },
  flexItem: {
    height: "1px",
    marginTop: "8px",
    alignSelf: "center",
  },
}));