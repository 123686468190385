import React, { useState, useEffect } from "react";
// import { withRouter, useLocation } from "react-router-dom";
import withRouter from "../../hoc/withRouter";
import withQueue from "../../hoc/withQueue";
import withAppBar from "../../hoc/withAppBar";
import { Col, Container, Row, Modal } from "qdm-component-library";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { getLayout } from "./functions";
import { useSelector, useDispatch } from "react-redux";
import PatientQueue from "./patientQueue";
import {
  checkWithCasbin,
  dashboardWidgets,
  actionButtonClick,
  GetQueueData,
} from "../../utils";
// import Layout from "./layout";
import withAllContexts from "../../hoc/withAllContexts";
import { AppRoutes } from "../../router/routes";
import "./styles.css";
import { fetchQueueData, fetchUserInfo, putOnHold, AlertProps } from "../../utils";
import { UIColor } from "../../themes/theme";
import SearchPatient from "./searchPatient/searchPatient.js";
import ChangeUser from "../../assets/img/svg/icons8-change-user.svg";
import PauseButton from "../../assets/img/svg/icons8-pause-button.svg";
import RepeatIcon from "../../assets/img/svg/icons8-repeat.svg";
import { QueueManagementContext, VisitDetails, AlertContext, DrawerMeetingContext, BackdropContext } from "../../context";
import Loading from "../../components/global/loading";
//import AssignPractitioner from "./assignPractitioner/assignPractitioner";
import { useNavigate, useLocation } from "react-router-dom";
import QueueDrawer from "../../components/queueDrawer";
import { Box, Button, Typography, IconButton } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore.js";
import CloseIcon from "@material-ui/icons/Close"
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore.js"
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded.js"
const Dashboard = (props) => {
  let style = {
    BoxStyle: {
      display:"flex",
      justifyContent:"center",
      marginTop:"-0.6px"
    },
    StyledButton: {
      outline: "none",
      width: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#EC6A49",
      padding: "8px 24px",
      border: "1px solid #fff",
      borderRadius: "0 0 20px 20px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.14)",
      cursor: "pointer"
    },
    spanStyle: {
      fontSize: "12px",
      width: "20px",
      height: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "8px",
      borderRadius: "50%",
      color: "#fff",
      background: "#01205c",
      margin: '8px',
    },
    modalStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 600,
      height: 500,
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      boxShadow: 24,
  
    },
    Header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "52px",
      paddingLeft: "8px",
      paddingRight: "8px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      borderBottom: "1px solid #bdbdbd",
      borderRadius: "8px 8px 0 0",
    },
    closeIcon: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      color: "#000",
      position: "fixed",
      // left: "50%",
      // transform: "translate(-50%)",
      // bottom: -60,
      cursor: "pointer",
      backgroundColor: "#FEFEFE",
      top: -20,
      right: -20,
      "&:hover": {
        backgroundColor: "#01205c",
      },

    },

    queueCard: {
      padding: "12px 12px",
      background: "#ffffff",
      border: "1px solid #e0e0e0",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      /* min-height: 100px; */
      cursor: "pointer",
      margin: "8px 0px",
      borderRadius: "12px",
      "&:hover": {
        background: "#01205c",
        borderColor: "#e0e0e0"
      },
      " &:hover .title": {
        color: "#fff"
      }
    }
  }
  const navigate = useNavigate();
  const {
    allData,
    displayData,
    previousData,
    loader,
    setAllData,
    setDisplayData,
    setPreviousData,
    setloader,
    previousQueue,
    setpreviousQueue

  } = React.useContext(QueueManagementContext);
  const { setVisitDetails } = React.useContext(VisitDetails);
  const backdrop = React.useContext(BackdropContext);
  const [permission1, setPermission] = React.useState({});
  const [queueList, setAllqueueList] = React.useState([]);
  const [queueName, setqueueName] = React.useState("")
  const { setSnack } = React.useContext(AlertContext);
  const dispatch = useDispatch();
  let data = [];
  let onHoldData = [];
  const [open, setOpen] = React.useState(false);
  const [queueListClick, setqueueListClick] = React.useState(false)
  const [selctValue, setSelectedQueue] = React.useState(previousQueue ? previousQueue :
    queueList?.[0]?.repodetails?._id)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [state, setState] = useState({
    layout: {
      right: dashboardWidgets,
      left: [],
    },
    roleName: "",
    drawer: false,
    initialLayout: null,
    newCounter: 0,
    cols: { lg: 8, md: 10, sm: 6, xs: 4, xxs: 2 },
    componetsIndexList: {
      0: {
        widgetName: "stats",
      },
      // 1: {
      //   widgetName: "statsCard",
      // },
      2: {
        widgetName: "profileList",
      },
      3: {
        widgetName: "patientStatus",
      },
      // "3": {
      //   widgetName: "queue"
      // },
      // "4": {
      //   widgetName: "appoinmentList",
      // },
      // 5: {
      //   widgetName: "statsCard",
      // },
      // 6: {
      //   widgetName: "statsCard",
      // },
      7: {
        widgetName: "pendingTask",
      },

      8: {
        widgetName: "currentVsitStats",
      },
      9: {
        widgetName: "billingStatus",
      },
      10: {
        widgetName: "financialClass",
      },
      11: {
        widgetName: "averageWaitingTime",
      },
      12: {
        widgetName: "schedule",
      },
      13: {
        widgetName: "patientwaitingtime",
      },
    },
    permission: [],
    appointmentStart: null,
    appointmentEnd: null,
    appID: null,
    //  loader: true,
  });
  const location = useLocation();
  const drawerMeetingContext = React.useContext(DrawerMeetingContext);
  useEffect(() => {
    if (location?.state && location?.state?.userRoleChange) {
      GetAllQueueData();
      getFetchData();  
      location.state.userRoleChange = false;
    }
  }, [location?.state?.userRoleChange]);
  useEffect(() => {
    const initFun = async () => {
      let permission = await checkWithCasbin(["queueSideNav"]);
      let dataList = await checkWithCasbin(["dashboard"]);
      let layouts = await getLayout(dataList);
      setState({
        permission: dataList,
        initialLayout: layouts,
      });
      setPermission(permission)
    };
    initFun();
    // setTimeout(() => {
    //   navigate(AppRoutes.searchFacility, {
    //     state: {
    //       userId: 1400,
    //       mobileNumber: "+911188667788",
    //       patientId: 1114,
    //       mobileNo: "+911188667788",
    //     },
    //   });
    // }, 5000);
  }, []);

  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
  const assignData = useSelector((state) => state?.dashboardApiSlice);
  const practitionerType = useSelector(
    (state) => state?.dashboardApiSlice?.assignPractitionerType
  );
  const practitionerRole = useSelector(
    (state) => state?.dashboardApiSlice?.assignPractitionerRole
  );
  const datas = useSelector((state) => state?.dashboardApiSlice);

  const getFetchData = async () => {
    let setQueueData = await GetQueueData();
    if (setQueueData?.isErrorResp) {
      setSnack(setQueueData.isErrorResp);
    } else {
      setAllData(setQueueData?.everyData);
      setDisplayData(setQueueData?.getDisplayData);
      setPreviousData(setQueueData?.getPreviousData);
      //   let actionData = displayData[0].info.action.find((x) => x.action === "On Hold");
      // setState((prevstate)=>({
      //   // ...prevstate,
      //   // action:actionData[0].action,
      //   // apiendpoint:actionData[0].apiendpoint,
      //   // backEndCall:actionData[0].backEndCall,
      // }));
    }
  };
  const assignPractitioner = async (type, data_) => {
    let d = await dispatch(
      actions.ASSIGN_LIST({
        start: data_?.info?.start ?? "",
        end: data_?.info?.end,
        encounter_key: data_?.info?.encounter?.[0]?._key ?? "",
        type: type,
      })
    );

    //setOpen(false);
    setState({
      ...state,
      assignModal: true,
      assignModalType: type,
      encounter_id: data_?.info?.encounter?.[0]?._key,
      appointmentStart: data_?.info?.start,
      appointmentEnd: data_?.info?.end,
      appID: data_?.info?._key,
    });
  };
  useEffect(() => {
    GetAllQueueData();
    getFetchData();

  }, []);
  const GetAllQueueData = async () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "loading the queue",
    });
    setloader(true);
    var myHeaders = new Headers();
    myHeaders.append("jwtToken", localStorage.getItem("JwtToken"));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_QUEUE_LIST, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllqueueList(result?.result);
        setqueueName(result?.result?.[0]?.repodetails?.queuename)
        //setPermissionData(result?.result);
      })
      .catch((error) => console.log("error", error));
    setloader(false);
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "",
    });
  };
  const registerPatient = (
    personId,
    patientId,
    appointmentId,
    isPatient,
    mobileNo,
    action,
    ticketid
  ) => {
    setVisitDetails({
      encounter: {
        mode: "direct",
      },
      practitioner: {},
      device: {},
      helthcare: {},
      accompained: {},
    });
    if (isPatient) {
      navigate(AppRoutes.visitRegistration, {
        state: {
          personId,
          from: 1,
          patientId,
          appointmentId,
          Ispatient: true,
          isedit: false,
          isRevise: false,
          patientData: mobileNo,
          stateAction: action?.action,
          stateEndPoint: action?.apiendpoint,
          stateBackEndCall: action?.backEndCall,
          stateTicketid: ticketid,
        },
      });
    } else {
      navigate(AppRoutes.assemblePatioentRegister, {
        state: {
          nextRoute: AppRoutes.visitRegistration,
          personId,
          patientId,
          from: 1,
          appointmentId,
          patientData: displayData[0]?.mobileNo,
          Ispatient: false,
          idedit: false,
          fromQueue: true,
          stateAction: action?.action,
          stateEndPoint: action?.apiendpoint,
          stateBackEndCall: action?.backEndCall,
          stateTicketid: ticketid,
        },
      });
    }
  };

  const attendPatient = async (
    personId,
    patientId,
    appointmentId,
    encounterId,
    type,
    pId,
    encounter_id,
    ticketId,
    currentDisplayData,
    app
  ) => {
    let status;
    let isCompleted;
    if (app[0]?.type === "nurse") {
      status = "inprogress_nurse";
      isCompleted = false;
    } else if (app[0]?.type === "doctor") {
      status = "doctor_inprogress";
      isCompleted = false;
    }
    // await props.UPDATE_APPOINTMENT_STATUS({
    //   appointmentId,
    //   status,
    //   isCompleted,
    // });

    navigate(AppRoutes.patientDetail, {
      state: {
        encounter_id,
        personId,
        patientId,
        appointmentId,
        encounterId,
        type,
        pId,
        ticketId,
        app_id: app?.[0]?.info?._id,
        allCurrentData: currentDisplayData,
      },
    });
  };
  const closeConsultation = async (type, data_) => {
    if (data_?.encounter_status?.toLowerCase() === "finished") {
      setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Close Consultation Done Already.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
    let payload = {
      start: data_?.info?.start ?? "",
      end: data_?.info?.end,
      encounter_key: data_?.info?.encounter?.[0]?._key ?? "",
    };
    await dispatch(actions.CLOSE_CONSULTATION(payload));

    //setOpen(false);
    setState({
      ...state,
      assignModal: true,
      assignModalType: "close",
      encounter_id: data_?.info?.encounter?.[0]?._key,
      appointmentStart: data_?.info?.start,
      appointmentEnd: data_?.info?.end,
    });
  };
  //const { data, previousData, displayData, allData } = props.queueManagement;
  const { permission } = state;
  let queueRead = permission?.read?.indexOf("queue") > -1;
  let queueWrite = permission?.write?.indexOf("queue") > -1;
  let markHighPriority = permission?.write?.indexOf("markHighPriority") > -1;
  let assignReassignPractitioner =
    permission?.write?.indexOf("assignReassignPractitionerD") > -1;

  const actionButtonClickEvent = async (
    action,
    apiendpoint,
    backEndCall,
    ticketid
  ) => {
    setloader(true);
    let fetchVal = await actionButtonClick(
      action,
      apiendpoint,
      backEndCall,
      ticketid
    );

    if (!fetchVal.error) {
      let data = await GetQueueData();
      setAllData(data.everyData);
      setDisplayData(data.getDisplayData);
      setPreviousData(data.getPreviousData);
    }
    setloader(false);
  };

  const SelectedQueue = (li) => {
    setqueueListClick(true)
    setOpen(false)
    setSelectedQueue(li?.repodetails?._id)
    setpreviousQueue(li?.repodetails?._id)
    setqueueName(li?.repodetails?.queuename)
  }

  return (
    <Loading
      loading={loader}
      style={{
        margin: "auto",
        position: "absolute",
        left: "50%",
        top: "35%",
      }}
    >
      <div
        id="dashboard_parent_div"
        style={{
          overflow: "hidden",
          height: "100vh",
        }}
      >
       <Box style={style.BoxStyle}>
      <Button style={style.StyledButton} onClick={() => handleOpen()}>
        {/* <Typography variant="body1">{"Queue"}</Typography>
        <span style={style.spanStyle} count={queueList?.length} color={"#01205c"}>
          {queueList?.length}
        </span> */}
        <Typography variant="button" style={{color:"#fff",fontSize:16}}>
          {queueName}
        </Typography>
       
       
        <ExpandMoreIcon fontSize="small" style={{color:"#fff" ,marginLeft:"10px",marginBottom:"3px"}} />
      </Button>
    </Box>
        <Container
          id="dashboard_parent_container"
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: !props.hideBackground ? "#e8e8e8" : "transparent",
            minHeight: "100vh",
            padding: "0px",
            overflow: "hidden",
          }}
        >
          {permission?.write?.indexOf("searchPatient") > -1 && (
            <SearchPatient
              noNewPatient={!(permission?.write?.indexOf("newPatient") > -1)}
              parent_id={"dashboard_search_pid"}
            />
          )}
          <Row
            id="dashboard_parent_row"
            style={{
              display: "flex",
              height: "auto",
              alignItems: "center",
              backgroundColor: UIColor.differentiationBackground.color,
            }}
          >
            {/* <LoadingComp loading={this.state.loader}> */}
            <Col
              id="dashboard_parent_layout_col"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              inLineStyles={{ padding: 0 }}
            >
              <div className="queue-style">
                <QueueDrawer
                  permission={permission1}
                  previous={
                    previousData.length > 0
                      ? [
                        {
                          ...previousData[0],
                          title: "Previous",
                          role_: displayData[0]?.type,
                          assignButtonAction: () => {
                            assignPractitioner("reassign", previousData[0]);
                          },
                          appno: previousData[0]?.info?.appno,
                        },
                      ]
                      : []
                  }
                  attend={
                    displayData[0]
                      ? [
                        {
                          title: "To Attend ",
                          token: displayData[0]["token"],
                          profile_pic: displayData[0]["profile_pic"],
                          name: displayData[0]["name"],
                          role: displayData[0]["role"],
                          role_: displayData[0]?.type,
                          encounter: displayData[0].encounter,
                          patientPriority: displayData[0].patientPriority,
                          consultationMode: displayData[0]?.consultationMode,
                          start:
                            displayData[0]?.type === "nurse" ||
                              displayData[0]?.type === "doctor"
                              ? ""
                              : displayData[0].start,
                          primaryButtonName:
                            displayData[0]?.type === "nurse" ||
                              displayData[0]?.type === "doctor"
                              ? "Attend"
                              : !displayData[0]?.isPatient
                                ? "Register Patient"
                                : "Register Visit",
                          isRegister:
                            displayData[0]?.type === "nurse" ||
                              displayData[0]?.type === "doctor"
                              ? `EN-${displayData[0]?.encounter} • New Patient`
                              : displayData[0]?.isPatient
                                ? "Registered"
                                : "Unregistered",
                          primaryButtonAction: () => {
                            if (
                              displayData[0]?.consultationMode ===
                              "Video Consultation" &&
                              !drawerMeetingContext?.open
                            ) {
                              navigate(AppRoutes.videoCall, {
                                state: {
                                  appointmentId: displayData[0].totalData.info?._key,
                                  name: loggedUserInfo?.data?.name?.text,
                                  role: loggedUserInfo?.data?.role,
                                  data: displayData[0],
                                },
                              });
                            } else {
                              if (
                                displayData[0]?.type === "nurse" ||
                                displayData[0]?.type === "doctor"
                              ) {
                                attendPatient(
                                  displayData[0].personId,
                                  displayData[0].patientId,
                                  displayData[0].token,
                                  displayData[0].encounterId,
                                  displayData[0]?.type,
                                  displayData[0].pId,
                                  displayData[0].encounter_id,
                                  displayData[0]?.info?.ticketid,
                                  displayData
                                );
                              } else {
                                registerPatient(
                                  displayData[0].personId,
                                  displayData[0].patientId,
                                  displayData[0]?.info?._id,
                                  displayData[0]?.isPatient,
                                  displayData[0]?.mobileNo,
                                  displayData[0]?.info.action.find(
                                    (x) => x.action === "VISIT REGISTER"
                                  ),
                                  displayData[0]?.info?.ticketid
                                );
                              }
                            }
                          },

                          secondaryButtonName: "Put on Hold",
                          secondaryButtonAction: () =>
                            actionButtonClickEvent(
                              displayData[0]?.onHoldAction?.action,
                              displayData[0]?.onHoldAction?.apiendpoint,
                              displayData[0]?.onHoldAction?.backEndCall,
                              displayData[0]?.info?.ticketid
                            ),
                          secondaryButtonIcon: PauseButton,
                          isEmergency: false,
                          assignButtonAction: () => {
                            let assigned =
                              displayData[0]?.info?.encounter?.[0]?.participant
                                ?.length > 0
                                ? true
                                : false;
                            assignPractitioner(
                              assigned ? "reassign" : "assign",
                              displayData[0]
                            );
                          },
                          assignButtonIcon:
                            displayData[0]?.info?.encounter?.[0]?.participant
                              ?.length > 0
                              ? RepeatIcon
                              : ChangeUser,
                          assigned:
                            displayData[0]?.info?.encounter?.[0]?.participant
                              ?.length > 0
                              ? true
                              : false,
                          //assignButtonName: "Assign",

                          totalData: displayData[0],
                        },
                      ]
                      : []
                  }
                  next={[...displayData].splice(1, 1).map((n) => {
                    return {
                      title: "Up Next",
                      token: n.token,
                      profile_pic: n?.["profile_pic"],
                      name: n.name,
                      role: n.role + (n.type === "nurse" ? " • New Patient" : ""),
                      isEmergency: n.isEmergency,
                      start: n.type === "nurse" ? "" : n.start,
                      encounter: n.encounter,
                      patientPriority: n.patientPriority,
                      assignButtonAction: () => {
                        let assigned =
                          n.info?.encounter?.[0]?.participant?.length > 0
                            ? true
                            : false;
                        assignPractitioner(assigned ? "reassign" : "assign", n);
                      },
                      assignButtonIcon:
                        n.info?.encounter?.[0]?.participant?.length > 0
                          ? RepeatIcon
                          : ChangeUser,
                      assigned:
                        n.info?.encounter?.[0]?.participant?.length > 0 ? true : false,
                      role_: n.type,
                      totalData: n,
                      // isclinic: displayData[0]?.info?.resourcetype === "Practitioner" ? false : true,
                      action: n.info.action,
                      ticketId: n.info.ticketid,
                    };
                  })}
                  AllData={allData}
                  pendingData={data}
                  onHoldData={onHoldData}
                  pastData={previousData}
                  //open={isOpen}
                  //onClose={() => OpenSideBar(false)}
                  assignPractitioner={assignPractitioner}
                  closeConsultation={(t, v) => closeConsultation(t, v)}
                  // setMultiBookOpen={handleMultiBookData}
                  // multiBookOpen={multiBookOpen}
                  queueList={queueList}
                  selctValue={selctValue}
                  queueListClick={queueListClick}
                />
              </div>
            </Col>

            {/* {(queueRead || queueWrite) && (
            <Col
              id="dashboard_parent_col"
              xl={3}
              lg={3}
              md={3}
              sm={12}
              xs={12}
              inLineStyles={{
                padding: 14,
                backgroundColor: UIColor.differentiationBackground.color,
                borderLeft: "1px solid #E0E0E0",
                overflow: "auto",
                height: "90vh",
              }}
            >
              <Loading loading={loader}>
                <PatientQueue
                  queueWrite={!queueWrite}
                  parent_id={"dashboard"}
                  markHighPriority={!markHighPriority}
                  assignReassignPractitioner={!assignReassignPractitioner}
                  previous={
                    previousData.length > 0
                      ? [
                          {
                            title: "Previous",
                            token: `${previousData?.[0]?.token || 0}`,
                            profile_pic: previousData?.[0]?.profile_pic,
                            name: previousData?.[0]?.name,
                            consultationMode:
                              previousData?.[0]?.consultationMode,
                            role_: previousData?.[0]?.type,
                            role:
                              previousData?.[0]?.role +
                              (previousData?.[0]?.type === "nurse" ||
                              previousData?.[0]?.type === "doctor"
                                ? " • New Patient"
                                : ""),
                            start:
                              previousData?.[0]?.type === "nurse" ||
                              previousData?.[0]?.type === "doctor"
                                ? ""
                                : previousData?.[0]?.start,
                            assignButtonAction: () => {
                              this.assignPractitioner(
                                "reassign",
                                previousData?.[0]
                              );
                            },
                            appno:previousData?.[0]?.info?.appno,
                          },
                        ]
                      : []
                  }
                  attend={
                    displayData.length > 0
                      ? [
                          {
                            title: "To Attend",
                            token: `${displayData?.[0]?.token || 0}`,
                            profile_pic: displayData?.[0]?.profile_pic,
                            name: displayData?.[0]?.name,
                            role: displayData?.[0]?.role,
                            role_: displayData?.[0]?.type,
                            encounter: displayData?.[0]?.encounter,
                            consultationMode:
                              displayData?.[0]?.consultationMode,
                            patientPriority: displayData?.[0]?.patientPriority,
                            start:
                              displayData?.[0]?.type === "nurse" ||
                              displayData?.[0]?.type === "doctor"
                                ? ""
                                : displayData?.[0]?.start,
                            primaryButtonName:
                              displayData?.[0]?.type === "nurse" ||
                              displayData?.[0]?.type === "doctor"
                                ? "Attend"
                                : !displayData?.[0]?.isPatient
                                ? "Register Patient"
                                : "Register Visit",
                            isRegister:
                              displayData?.[0]?.type === "nurse" ||
                              displayData?.[0]?.type === "doctor"
                                ? `EN-${displayData?.[0]?.encounter} • New Patient`
                                : displayData?.[0]?.isPatient
                                ? "Registered"
                                : "Unregistered",
                            primaryButtonAction: () => {
                              if (
                                displayData?.[0]?.info?.consultationMode ===
                                  "Video Consultation" &&
                                !props.AVCMeeting.open
                              ) {
                                props.history.push({
                                  pathname: AppRoutes.videoCall,
                                  state: {
                                    appointmentId: displayData[0].info?._key,
                                    name: props?.loggedUserInfo?.data?.name
                                      ?.text,
                                    role: props?.loggedUserInfo?.data?.role,
                                    data: displayData[0],
                                  },
                                });
                              } else {
                                if (
                                  displayData[0]?.type === "nurse" ||
                                  displayData[0]?.type === "doctor"
                                ) {
                                  attendPatient(
                                    displayData[0].personId,
                                    displayData[0].patientId,
                                    displayData[0].token,
                                    displayData[0].encounterId,
                                    displayData[0]?.type,
                                    displayData[0]?.pId,
                                    displayData[0]?.encounter_id,
                                    displayData[0]?.info?.ticketid,
                                    displayData[0],
                                    displayData
                                  );
                                } else {
                                  registerPatient(
                                    displayData[0].personId,
                                    displayData[0].patientId,
                                    displayData[0].info?._id,
                                    displayData[0]?.isPatient,
                                    displayData[0]?.mobileNo,
                                    displayData[0]?.info.action.find(
                                      (x) => x.action === "VISIT REGISTER"
                                    ),
                                    displayData[0]?.info?.ticketid
                                  );
                                }
                              }
                            },
                            secondaryButtonName: "Put on Hold",
                            secondaryButtonAction: () =>
                              actionButtonClickEvent(
                                displayData[0]?.onHoldAction?.action,
                                displayData[0]?.onHoldAction?.apiendpoint,
                                displayData[0]?.onHoldAction?.backEndCall,
                                displayData[0]?.info?.ticketid
                              ),
                            secondaryButtonIcon: PauseButton,
                            isEmergency: false,
                            assignButtonAction: () => {
                              let assigned =
                                displayData[0]?.info?.encounter?.[0]
                                  ?.participant?.length > 0
                                  ? true
                                  : false;

                              this.assignPractitioner(
                                assigned ? "reassign" : "assign",
                                displayData[0]
                              );
                            },
                            assignButtonIcon:
                              displayData[0]?.info?.encounter?.[0]?.participant
                                ?.length > 0
                                ? RepeatIcon
                                : ChangeUser,
                            assigned:
                              displayData[0]?.info?.encounter?.[0]?.participant
                                ?.length > 0
                                ? true
                                : false,
                            //assignButtonName: displayData[0]?.info?.resourcetype === "Practitioner" ?  "Re Assign": "Assign",
                            // isEmergency: false,
                            totalData: displayData[0],
                          },
                        ]
                      : []
                  }
                  next={[...displayData].splice(1, 1).map((d) => ({
                    title: "Up Next",
                    token: `${d?.token || 0}`,
                    profile_pic: d?.profile_pic,
                    name: d?.name,
                    role:
                      d?.role + (d.type === "nurse" ? " • New Patient" : ""),
                    encounter: d?.encounter,
                    consultationMode: d?.consultationMode,
                    patientPriority: d?.patientPriority,
                    isEmergency: false,
                    start: d.type === "nurse" ? "" : d.start,
                    assignButtonAction: () => {
                      let assigned =
                        d?.info?.encounter?.[0]?.participant?.length > 0
                          ? true
                          : false;
                      this.assignPractitioner(
                        assigned ? "reassign" : "assign",
                        d
                      );
                    },
                    assignButtonIcon:
                      d?.info?.encounter?.[0]?.participant?.length > 0
                        ? RepeatIcon
                        : ChangeUser,
                    assigned:
                      d?.info?.encounter?.[0]?.participant?.length > 0
                        ? true
                        : false,
                    role_: d?.type,
                    totalData: d,
                    action: d.info.action,
                    ticketId: d.info.ticketid,
                  }))}
                />
              </Loading>
            </Col>
          )} */}

            {/* </LoadingComp> */}
          </Row>
        </Container>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={style.modalStyle}>
            <div style={{ height: "100%", position: "relative" }}>
              <Box style={style.Header}>
                <Typography style={{ padding: "8px 0", marginLeft: "15px" }}>Queue Item's</Typography>
              </Box>
              <div
                style={{
                  height: "calc(100% - 52px)",
                  padding: "12px 16px",
                  overflowY:"scroll"
                }}
              >

                {queueList?.map((li) => {
                  let bcColor = li?.repodetails?.queuename == queueName ? "#FFECE8" : "#fff";
                  let color = li?.repodetails?.queuename == queueName ? "#EC6A49" : "#000";
                  return (
                    <div style={{
                      padding: "12px 12px",
                      background: bcColor,
                      color:color,
                      border: "1px solid #e0e0e0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      /* min-height: 100px; */
                      cursor: "pointer",
                      margin: "8px 0px",
                      borderRadius: "12px",
                      "&:hover": {
                        background: "#01205c",
                        borderColor: "#e0e0e0"
                      },
                      " &:hover .title": {
                        color: "#fff"
                      }
                    }} onClick={() => SelectedQueue(li)}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <SettingsBackupRestoreIcon size="1.3rem" />

                        <Typography
                          className="title"
                          variant="body1"
                          style={{ margin: "8px" }}
                        >
                          {li?.repodetails?.queuename}
                        </Typography>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ChevronRightRoundedIcon className="title" />
                      </div>
                    </div>
                  )
                })}

                <IconButton style={style.closeIcon} onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </Box>

        </Modal>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => ({
  appointmentInfo: state?.appointmentReducer?.readAppointment,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  assignData: state?.dashboardApiSlice?.assignList,
  practitionerType: state?.dashboardApiSlice?.assignPractitionerType,
  practitionerRole: state?.dashboardApiSlice?.assignPractitionerRole,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withQueue(withAllContexts(withAppBar(Dashboard)))));

