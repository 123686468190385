import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  signOff,
  urls,
} from "../../utils";

const SIGN_OFF_ACTION = createAsyncThunk(
  `signoffslice/signOff`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { ticketId = "", userAction = "" } = payload;
      const data = await fetchData(
        {
          body: JSON.stringify({
            ticketId: ticketId,
            userAction: userAction,
          }),
        },
        process.env.REACT_APP_PERFORMUSERACTION_API
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const DOCTOR_SIGN_OFF_FOLLOWUP_ACTION = createAsyncThunk(
  `signoffslice/doctorSignOffFollowUp`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        { body: JSON.stringify({ ...payload }) },
        urls.followUpInDoctorSignOff
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_ALL_MASTERS_FOR_SIGN_OFF = createAsyncThunk(
  `signoffslice/getAllMastersForSignoff`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        signOff.getAllMasterSignOff(),
        urls.readQdmQueries
      );
      let obj = {};
      data?.forEach((v) => {
        let key = Object.keys(v)?.[0];
        obj[key] = v?.[key]?.Value?.map((v) => ({
          label: v?.display,
          value: v?._id,
        }));
      });
      return {
        ...defaultState.List,
        data: obj,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const signOffSlice = createSlice({
  name: "signoffslice",
  initialState: {
    signOff: {
      ...defaultState.List,
    },
    doctorSignOffFollowUp: {
      ...defaultState.List,
    },
    getAllMastersForSignoff: {
      ...defaultState.List,
    },
  },
  extraReducers: {
    /* SIGN_OFF_ACTION  */
    [SIGN_OFF_ACTION.fulfilled]: (state, action) => {
      state.signOff = action?.payload ?? {};
    },
    [SIGN_OFF_ACTION.pending]: (state, action) => {
      state.signOff.loading = true;
    },
    [SIGN_OFF_ACTION.rejected]: (state, action) => {
      state.signOff = action?.payload ?? {};
    },
    /* DOCTOR_SIGN_OFF_FOLLOWUP_ACTION  */
    [DOCTOR_SIGN_OFF_FOLLOWUP_ACTION.fulfilled]: (state, action) => {
      state.doctorSignOffFollowUp = action?.payload ?? {};
    },
    [DOCTOR_SIGN_OFF_FOLLOWUP_ACTION.pending]: (state, action) => {
      state.doctorSignOffFollowUp.loading = true;
    },
    [DOCTOR_SIGN_OFF_FOLLOWUP_ACTION.rejected]: (state, action) => {
      state.doctorSignOffFollowUp = action?.payload ?? {};
    },
    /* DOCTOR_SIGN_OFF_FOLLOWUP_ACTION  */
    [GET_ALL_MASTERS_FOR_SIGN_OFF.fulfilled]: (state, action) => {
      state.getAllMastersForSignoff = action?.payload ?? {};
    },
    [GET_ALL_MASTERS_FOR_SIGN_OFF.pending]: (state, action) => {
      state.getAllMastersForSignoff.loading = true;
    },
    [GET_ALL_MASTERS_FOR_SIGN_OFF.rejected]: (state, action) => {
      state.getAllMastersForSignoff = action?.payload ?? {};
    },
  },
});

// APPOINTMENT ACTION LISTS
export const signOffActions = {
  SIGN_OFF_ACTION,
  DOCTOR_SIGN_OFF_FOLLOWUP_ACTION,
  GET_ALL_MASTERS_FOR_SIGN_OFF,
};

export default signOffSlice;
