import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  dbName,
  defaultReject,
  defaultState,
  fetchData,
  query_ids,
  reasonmasterquery,
  urls,
} from "../../utils";
const { getreasonMasterRequest } = reasonmasterquery;

const PERFORM_USER_ACTION = createAsyncThunk(
  `CommonSlice/performUseraction`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { ticketId = "", userAction = "" } = payload;
      const data = await fetchData(
        {
          body: JSON.stringify({
            ticketId: ticketId,
            userAction: userAction,
          }),
        },
        process.env.REACT_APP_PERFORMUSERACTION_API
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const Masters = createAsyncThunk(
  "CommonSlice/masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "", type = [] } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: type,
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let obj = {};
      data?.forEach((v) => {
        let key = Object.keys(v)?.[0];
        obj[key] = v?.[key]?.Value?.map((v) => ({
          label: v?.display,
          value: v?._id,
          _id: v?._id ?? "",
        }));
      });
      return {
        ...defaultState.Info,
        data: obj,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_SOME_MASTER = createAsyncThunk(
  "CommonSlice/someMasters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "", type = [] } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: type,
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let allMastersresult = {};
      data?.forEach((e, i) => {
        let key = Object.keys(e)?.[0];
        let arr = [];
        data?.[i]?.[key]?.Value?.forEach((v) => {
          arr.push({
            value: v?.display ?? "",
            _id: v?._id ?? "",
          });
        });
        allMastersresult[key] = arr;
      });
      return {
        ...defaultState.Info,
        data: allMastersresult,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_REASON_MASTERS = createAsyncThunk(
  `registrationSlice/GetAllreasonMasters`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        getreasonMasterRequest(payload),
        urls.readQdmQueries
      );
      let result = [];
      data.forEach((e, i) => {
        let key = Object.keys(e)?.[0];
        let arr = [];
        data?.[i]?.[key]?.Value?.forEach((v) => {
          arr.push({
            label: v?.ReasonDesc ?? "",
            title: v?.ReasonDesc,
            value: v?._id ?? "",
            __id: v?._id ?? "",
            _id: v?._id ?? "",
            id: v?.id,
            code: v?.code,
          });
        });
        result[key] = arr;
      });
      return {
        ...defaultState.List,
        data: result,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_SCREEN_DEFAULT_VALUE = createAsyncThunk(
  `CommonSlice/screenDefault`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.getScreenDefaultValue
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const CommonSlice = createSlice({
  name: "CommonSlice",
  initialState: {
    performUseraction: {
      ...defaultState.info,
    },
    masters: {
      ...defaultState.info,
    },
    someMasters: {
      ...defaultState.info,
    },
    GetAllreasonMasters: {
      ...defaultState.Info,
    },
    screenDefault: {
      ...defaultState.info,
    },
  },
  extraReducers: {
    /* PERFORM_USER_ACTION  */
    [PERFORM_USER_ACTION.fulfilled]: (state, action) => {
      state.performUseraction = action?.payload ?? {};
    },
    [PERFORM_USER_ACTION.pending]: (state, action) => {
      state.performUseraction.loading = true;
    },
    [PERFORM_USER_ACTION.rejected]: (state, action) => {
      state.performUseraction = action?.payload ?? {};
    },
    [Masters.fulfilled]: (state, action) => {
      state.masters = action?.payload ?? {};
    },
    [Masters.pending]: (state, action) => {
      state.masters.loading = true;
    },
    [Masters.rejected]: (state, action) => {
      state.masters = action?.payload ?? {};
    },
    [GET_SOME_MASTER.fulfilled]: (state, action) => {
      state.someMasters = action?.payload ?? {};
    },
    [GET_SOME_MASTER.pending]: (state, action) => {
      state.someMasters.loading = true;
    },
    [GET_SOME_MASTER.rejected]: (state, action) => {
      state.someMasters = action?.payload ?? {};
    },
    /* GET_REASON_MASTER */
    [GET_REASON_MASTERS.fulfilled]: (state, action) => {
      state.GetAllreasonMasters = action?.payload ?? [];
    },
    [GET_REASON_MASTERS.pending]: (state, action) => {
      state.GetAllreasonMasters.loading = true;
    },
    [GET_REASON_MASTERS.rejected]: (state, action) => {
      state.GetAllreasonMasters = action?.payload ?? [];
    },
    /* GET_SCREEN_DEFAULT_VALUE  */
    [GET_SCREEN_DEFAULT_VALUE.fulfilled]: (state, action) => {
      state.screenDefault = action?.payload ?? {};
    },
    [GET_SCREEN_DEFAULT_VALUE.pending]: (state, action) => {
      state.screenDefault.loading = true;
    },
    [GET_SCREEN_DEFAULT_VALUE.rejected]: (state, action) => {
      state.screenDefault = action?.payload ?? {};
    },
  },
});

export const commonActions = {
  PERFORM_USER_ACTION,
  Masters,
  GET_SOME_MASTER,
  GET_REASON_MASTERS,
  GET_SCREEN_DEFAULT_VALUE,
};

export default CommonSlice;
