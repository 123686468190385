import React from "react";

export const AssignPractitioner = (props) => {
  let width = props.width ? props.width : "18";
  let height = props.height ? props.height : "17.789";
  let color = props.color ? props.color : "#aa62ba";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 17.789"
      {...props}
    >
      <path id="icons8-change-user_1_" data-name="icons8-change-user (1)" d="M12.658,4.918A10.3,10.3,0,0,1,20.6,8.654V6.124a.7.7,0,1,1,1.4,0v4.2a.7.7,0,0,1-.7.7H17.1a.7.7,0,1,1,0-1.4h2.493a8.915,8.915,0,0,0-14.308.6.7.7,0,0,1-1.16-.787A10.312,10.312,0,0,1,12.658,4.918Zm0,4.358A3.2,3.2,0,0,1,15.087,10.3a3.419,3.419,0,0,1,0,4.424,3.2,3.2,0,0,1-2.428,1.029,3.241,3.241,0,0,1,0-6.482Zm0,1.4a1.84,1.84,0,0,0,0,3.679,1.714,1.714,0,0,0,1.381-.558,2.02,2.02,0,0,0,0-2.562A1.714,1.714,0,0,0,12.658,10.677ZM16.2,16.633a1.582,1.582,0,0,1,1.625,1.523V19.6a2.75,2.75,0,0,1-1.592,2.287,7.279,7.279,0,0,1-3.574.824,7.283,7.283,0,0,1-3.575-.824A2.751,2.751,0,0,1,7.491,19.6v-1.44a1.582,1.582,0,0,1,1.625-1.523Zm0,1.4H9.116c-.175,0-.224.094-.224.121V19.6c0,.3.233.7.882,1.068a6.01,6.01,0,0,0,2.885.641,6,6,0,0,0,2.883-.641c.649-.368.882-.773.882-1.068v-1.44C16.424,18.128,16.374,18.035,16.2,18.035Z" 
        transform="translate(-4 -4.918)"
        fill={color}
    />
    </svg>
  );
};