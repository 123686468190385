import React from "react";
import {
  Upload,
  Div,
  H6,
  Text,
  Divider,
  Image,
  Icons,
  Container,
} from "qdm-component-library";
import { ToBase64 } from "../../utils";
import FileViewer from "react-file-viewer";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FileUpload from "../../assets/img/svg/icons8-file.svg";
import viewIcon from "../../assets/img/svg/icons8-eye.svg";
import trashIcon from "../../assets/img/svg/icons8-trash1.svg";
import UploadIcon from "../../assets/img/svg/icons8-upload.svg";
import LinearProgress from "@material-ui/core/LinearProgress";
import "./style.css"
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundColor: "transparent",
  },
  dialogRoot: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
    zIndex: "999999 !important",
  },
}));

function UploadFiles(props) {
  const classes = useStyles();
  const { uploadDocument, parent_id } = props;
  const [images, setImages] = React.useState([]);
  const [progress, setProgress] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  const [state, setState] = React.useState({
    openFile: null,
    viewFileName: null,
    fileType: null,
    open: false,
  });

  React.useEffect(() => {
    setImages(props.files);
  }, [props.files]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (uploading) {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            setUploading(false);
            return 0;
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [uploading]);

  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const uploadFun = async (e, data) => {
    let list = images ? images : [];
    list.push(data);
    setImages(list);
    uploadDocument(list);
    forceUpdate();
  };

  const getSize = (size) => {
    let fileSize = size / 1024 / 1024;
    if (fileSize < 1) {
      return `${parseInt(size / 1024) + 1} KB`;
    } else {
      return `${parseInt(fileSize)} MB`;
    }
  };
  const deleteFile = (index) => {
    let list = images;
    list.splice(index, 1);
    setImages(list);
    uploadDocument(list);
    forceUpdate();
  };
  const handleClose = () => {
    setState({
      openFile: null,
      fileType: null,
      open: false,
    });
    forceUpdate();
  };

  const createFile = async (url, name, type) => {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type,
    };
    const file = new File([data], name, metadata);
    return {
      file,
      size: data.size,
    };
  };

  const viewFile = async (file, idx) => {
    let data = "";
    if (file.fileUrl) {
      const { file: fileData, size } = await createFile(
        file.fileUrl,
        file.name,
        file.type
      );
      const img = [...images];
      img.forEach((file, i) => {
        if (i === idx) {
          file.size = size;
        }
      });
      setImages(img);
      data = await ToBase64(fileData);
    } else {
      data = await ToBase64(file);
    }
    setState({
      openFile: data,
      viewFileName: file.name,
      fileType: file.type.split("/")[1],
      open: true,
    });
    forceUpdate();
  };

  // const uploadFile = async (file, idx) => {

  //     let data = "";
  //     let base64String = "";
  //     if (file.fileUrl) {
  //         const {
  //             file: fileData,
  //             size,
  //         } = await createFile(file.fileUrl, file.name, file.type);
  //         const img = [...images];
  //         img.forEach((file, i) => {
  //             if (i === idx) {
  //                 file.size = size;
  //             }
  //         })

  //         setImages(img);
  //         data = await ToBase64(fileData);
  //         base64String = data.replace("data:", "").replace(/^.+,/, "");
  //     } else {

  //         data = await ToBase64(file);
  //         base64String = data.replace("data:", "").replace(/^.+,/, "");

  //     }
  //  let filevalue = {
  //         openFile: base64String,
  //         viewFileName: file.name,
  //         fileType: file.type.split('/')[1],
  //     }
  //     props?.uploadImage(filevalue)

  //     forceUpdate()
  // }
  const uploadFile = async (file, idx) => {
    try {
      setUploading(true);
      let data = "";
      let base64String = "";
      let progress = 0;

      if (file.fileUrl) {
        const { file: fileData, size } = await createFile(
          file.fileUrl,
          file.name,
          file.type
        );
        const img = [...images];
        img.forEach((file, i) => {
          if (i === idx) {
            file.size = size;
          }
        });

        setImages(img);

        const onProgress = (event) => {
          progress = (event.loaded / event.total) * 100;
          setProgress(progress);
        };

        data = await ToBase64(fileData, onProgress);
        base64String = data.replace("data:", "").replace(/^.+,/, "");
      } else {
        const onProgress = (event) => {
          progress = (event.loaded / event.total) * 100;
          setProgress(progress);
        };

        data = await ToBase64(file, onProgress);
        base64String = data.replace("data:", "").replace(/^.+,/, "");
      }

      let filevalue = {
        openFile: base64String,
        viewFileName: file.name,
        fileType: file.type.split("/")[1],
      };

      props?.uploadImage(filevalue);
    } finally {
      setProgress(0);
      forceUpdate();
    }
  };

  return (
    <React.Fragment>
      <Container
        id={`${parent_id}_cancelbook_parent_container`}
        key={"0"}
        name="container"
        fluid={false}
        style={{
          padding: "20px",
        }}
      >
        <Text
          id={`${parent_id}_cancelbook_appointment_text`}
          key={"0"}
          className="pc_semibold"
          style={{ fontSize: 20, color: "#101010" }}
          name={`${
            props?.uploadData?.action?.charAt(0)?.toUpperCase() +
            props?.uploadData?.action?.substr(1)?.toLowerCase()
          } ${props?.uploadData?.queue?.replace(/ .*/, "")}`}
        ></Text>
        <Divider
          id={`${parent_id}_cancelbook_bottom_divider`}
          key={"1"}
          textWidth={"50"}
          textColor={"#000"}
          borderColor={"#f0f0f0"}
          borderWidth={"1"}
          orientation={"horizontal"}
          variant={"middle"}
          style={{ marginLeft: 0, marginRight: 0 }}
          //   style={qdmstyles.BKv}
        ></Divider>
        <Div className={"uplo-parent"}>
          <Upload
            handleChange={uploadFun}
            label="Upload Consent Document"
            labelStyle={{ fontSize: 14, fontFamily: "pc_medium !important" }}
            className={"up-btn"}
            showIcon={false}
            disabled={images?.length > 0 ? true : false}
          />
        </Div>
        {images?.map((val, i) => (
          <React.Fragment>
            {i !== 0 && <Divider style={{ margin: "0px 10px" }} />}
            <Div className="up-list-parent">
              <Div style={{ width: 22, height: 22 }}>
                <Image src={FileUpload} alt="file" />
              </Div>
              <Div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginLeft: 10,
                }}
              >
                <H6
                  className="up-title"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                  }}
                >
                  {val.name}
                </H6>
                {val.size !== 0 && (
                  <Text className="up-size">{getSize(val.size)}</Text>
                )}
              </Div>
              <Div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Image
                  onClick={() => uploadFile(val, i)}
                  src={UploadIcon}
                  alt="view"
                  style={{ cursor: "pointer", marginRight: 16 }}
                />
                <Image
                  onClick={() => viewFile(val, i)}
                  src={viewIcon}
                  alt="view"
                  style={{ cursor: "pointer", marginRight: 16 }}
                />
                <Image
                  src={trashIcon}
                  onClick={() => deleteFile(i)}
                  alt="view"
                  style={{ cursor: "pointer" }}
                />
              </Div>
            </Div>
            {state?.open && (
              <div style={{ width: "100%", height: "100vh", margin: "auto" }}>
                <Grid container style={{ background: "transparent" }}>
                  <Grid item style={{ width: "50%" }}>
                    <Grid>
                      <Typography
                        id="UMHit_14"
                        style={{
                          color: "black",
                          marginTop: "9px",
                          visibility: "hidden",
                        }}
                      >
                        {state.viewFileName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      width: "50%",
                      paddingRight: "30px",
                      paddingTop: "16px",
                    }}
                  >
                    <div onClick={() => handleClose()}>
                      <Typography
                        style={{
                          color: "#000",
                          cursor: "pointer",
                          fontSize: "10px",
                          fontWeight: 600,
                        }}
                      >
                        CLOSE X
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid id="UMHit_17" className="openFile-parent">
                  <FileViewer
                    id="UMHit_16"
                    fileType={state.fileType}
                    filePath={state.openFile}
                  />
                </Grid>
              </div>
            )}
            {uploading && (
              <LinearProgress variant="determinate" value={progress} />
            )}
          </React.Fragment>
        ))}
      </Container>
    </React.Fragment>
  );
}
export default UploadFiles;