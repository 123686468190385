import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { defaultReject, defaultState, fetchData, urls } from "../../utils";

const SAVE_FINANCE = createAsyncThunk(
  `FinanceSlice/savefinance`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      
      let data = await fetchData(
        {
          body: JSON.stringify(payload),
        },
        urls.saveFianance
      );

      console.log(data);

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const Finance_slice = createSlice({
  name: "FinanceSlice",
  initialState: {
    savefinance: {
      ...defaultState.List,
    },
  },
  extraReducers: {
    //Save Finance
    [SAVE_FINANCE.fulfilled]: (state, action) => {
      state.savefinance = action?.payload ?? {};
    },
    [SAVE_FINANCE.pending]: (state, action) => {
      state.savefinance.loading = true;
    },
    [SAVE_FINANCE.rejected]: (state, action) => {
      state.savefinance = action?.payload ?? {};
    },
  },
});
export const FinanceActions = {
  SAVE_FINANCE,
};
export default Finance_slice;
