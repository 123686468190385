import React from "react";
// import { Header } from "./dummyData";
import TableComponent from "./tableComponent";

const dataType = [
  { name: "no", type: "INCREMENT", header: "S.No" },
  { name: "service", type: "TEXT", header: "scroll" },
  { name: "description", type: "TEXT", header: "scroll",width:"280px" },
  { name: "orderRef", type: "TEXT", header: "scroll" },
  { name: "date", type: "TEXT", header: "scroll" },
  { name: "category", type: "TEXT", header: "scroll" },
  { name: "qty", type: "TEXT", header: "scroll" },
  { name: "uom", type: "TEXT", header: "scroll" },
  // { name: "rate", type: "TEXT", header: "scroll" },
  // { name: "amt", type: "TEXT", header: "scroll" },
  { name: "status", type: "TEXT", header: "scroll" },
  { name: "billed", type: "CHECK", header: "scroll" },
  { name: "checked", type: "CHECKBOX", header: "All" },
];

const Header = [
  "S.No",
  "Service",
  "Service Description",
  "Order Ref",
  "Order Date & Time",
  "Ord Catg/Drug Class",
  "Qty",
  "UoM",
  // "Rate",
  // "Charge Amt",
  "Status",
  "Billed?",
  "All",
];

const ViewBilling = (props) => {
  return (
    <div>
      <TableComponent
        tableData={props?.codeSearch?.length > 0 ? props?.displayOrders : props?.standardTableorders}
        Header={Header}
        dataType={dataType}
        handleCheckBox={props?.handleCheckBox}
        handleHeaderCheckBox={props?.handleHeaderCheckBox}
        allChecked={props?.allChecked}
      />
    </div>
  );
};

export default ViewBilling;
