import React, { useEffect, useState } from "react";
import {
  Div,
  Paper,
  Select,
  H6,
  Col,
  Row,
  TextInput,
  Text,
  Image,
} from "qdm-component-library";
// import "./style.css";
import withAllContexts from "../../../hoc/withAllContexts";
import { ValidateEmail } from "../../../utils";
import { UIColor } from "../../../themes/theme";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Typography } from "@material-ui/core";
import { FlterOptions2, FlterOptions3, FlterOptions5 } from "./handlers";
import { contactModeHelperText, label } from "./handlers";
import trashIcon from "../../../assets/img/svg/icons8-trash.svg";
import { AlertProps } from "../../../utils";

const ContactPoint = (props) => {
  let styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorborderStyle: {
      borderRadius: 8,
      borderColor: "red",
    },
  };
  const { parent_id } = props;
  // useEffect(() => {
  //   setPatientData({...props?.patientData})
  // }, [props.clear]);
  // useEffect(() => {
  //   setPatientData({...props?.patientData})
  // }, [props?.cleared]);
  // useEffect(()=>{
  //   setPatientData({...props?.patientData})
  // },[props?.states])
  // let { contact, statusDetails } = props?.[props?.saveto];

  const [state, setState] = useState({});
  const { labelStyle, borderStyle, errorborderStyle } = styles;

  const [patientData, setPatientData] = useState({ ...props?.patientData });
  const { contact, statusDetails } = props?.patientData;
  const [ccDefaultVal, setccDefaultVal] = useState({});
  useEffect(() => {
    if (props?.patientData !== patientData) {
      setPatientData(props?.patientData);
    }
  }, [props?.patientData]);
  // useEffect(() => {
  //   if (props?.LoadContact || (!props?.isModify && !props?.isGoBack)) {
  //     props?.Sbi.map((val) => {
  //       if (val?.value === "+60" && val?.label === "Malaysia") {
  //         setccDefaultVal(val);
  //         setPatientData((pre) => {
  //           let patData = { ...pre };
  //           try {
  //             //emergency Fix
  //             //used trycatch because some time mobile_code is not comng in patientData
  //             patData.contact[0]["mobile_code"] = val;
  //           } catch (error) {
  //             patData.contact[0] = {
  //               mobile_code: val,
  //             };
  //           }

  //           return patData;
  //         });
  //       }
  //     });
  //   }
  // }, [props?.LoadContact]);
  // useEffect(()=>{
  //   if(!props?.isModify){
  //     props?.Sbi.map((val)=>{
  //       if(val?.value==="+60"&&val?.label==="Malaysia"){
  //         setPatientData((pre)=>{
  //           let patData = {...pre}
  //           patData.contact[0]["mobile_code"]=val;
  //         })
  //         setPatientData({...patientData})
  //       }
  //       })
  //     }
  // },[props])
  const newRowAdd = (props) => {
    let patientD = props;

    let value;

    if (patientD?.contact?.length > 0) {
      value = {
        mode: {
          value: "",

          _id: "",
        },
      };
    } else {
      value = {
        mode: {
          value: "Phone",

          _id: "CodingMaster/10473",
        },
      };
    }

    patientD?.contact.push(value);
    setPatientData({ ...patientD });
    props?.setPatientData({ ...patientD });
  };

  const handleDeleteContactPoint = (patientDataprops, i) => {
    let patientC = patientDataprops;
    if (patientC.contact.length <= 1) {
      props.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Atleast one identification should be entered",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else {
      patientC.contact.splice(i, 1);
      setPatientData({ ...patientC });
      props?.setPatientData({ ...patientC });
    }
  };
  const FlterOptions = (type, sort, i) => {};

  const setDetails = (props, name, value, index, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let patientData = props;
    let contactss = patientData.contact;

    if (name === "mode") {
      contactss[index]["mobile"] = "";
    }

    if (patientData.contact?.error) {
      if (name === "mobile") {
        if (label(index) === "Phone") {
          contactss[index].error[name] = value.length === 10 ? false : true;
        } else if (label(index) === "E-mail" || label(index) === "Email") {
          ValidateEmail(value)
            ? (contactss[index].error[name] = false)
            : (contactss[index].error[name] = true);
        } else {
          contactss[index].error[name] = false;
        }
      } else {
        contactss[index].error[name] = false;
      }
    }
    //contactss[index][name] = value;
    patientData.contact[index][name] = value;
    // patientData[this.props.setTo]({
    //   ...patientData,
    // });
    setPatientData({ ...patientData });
    props?.setPatientData({ ...patientData });
  };

  const labelType = (patientData, i) => {
    let labelVal = label(patientData, i);
    if (labelVal === "Mobile" || labelVal === "Phone") {
      return "number";
    } else if (label(patientData, i) === "Email") {
      return "email";
    } else {
      return "type";
    }
  };

  return (
    <Div id={`${parent_id}_contact_point_parent_div`} className="pi-root">
      <Paper
        id={`${parent_id}_contact_point_parent_paper`}
        className="pi-paper"
      >
        <Div id={`${parent_id}_contact_point_title_label_div`}>
          <H6
            id={`${parent_id}_contact_point_title_label_h6`}
            className="pi-title"
          >
            CONTACT POINT
          </H6>
          {!props.isViewMode && (
            <Text
              id={`${parent_id}_patient_name_label_addnew_text`}
              onClick={() => newRowAdd(patientData)}
              className="pi-addbtn"
            >
              + Add New
            </Text>
          )}
        </Div>
        {patientData?.contact?.length > 0 &&
          patientData?.contact?.map((val, i) => {
            return (
              <div>
                <Row
                  id={`${parent_id}_contact_point_parent_row`}
                  className="pi-actions-root"
                >
                  <Col
                    id={`${parent_id}_contact_point_contact_mode_col`}
                    md={3}
                    lg={3}
                    sm={6}
                    xs={12}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_contact_point_contact_mode_label_select`}
                      showArrow
                      label="Contact Mode"
                      placeholder="select..."
                      labelStyle={labelStyle}
                      inLineStyles={
                        val?.error && contact?.error?.mode
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                      isRequired
                      options={FlterOptions3(
                        props?.allMasters,
                        "CONTACTSYSTEM",
                        false,
                        i
                      )}
                      // disabled={i === 0 ? true : false}
                      disabled={props?.isViewMode}
                      // options={[
                      //     { value: 'Phone', label: 'Phone' },
                      //     { value: 'Fax', label: 'Fax' },
                      //     { value: 'E-mail', label: 'E-mail' },
                      //     { value: 'url', label: 'url' },
                      //     { value: 'SMS', label: 'SMS' },
                      //     { value: 'other', label: 'other' },
                      // ]}
                      value={val?.mode?.value ?? null}
                      onChangeValue={(e) =>
                        setDetails(patientData, "mode", e, i)
                      }
                      // helperText={
                      //  contact?.error && contact?.error["mode"] ? "Is required" : ""
                      // }
                      error={
                        contact?.error && contact?.error["mode"] ? true : false
                      }
                    />
                  </Col>
                  {label(patientData, i) === "Phone" ? (
                    <Col
                      id={`${parent_id}_contact_point_contact_number_col`}
                      md={2}
                      lg={2}
                      sm={6}
                      xs={12}
                      className="no-padding-left"
                    >
                      <Typography
                        className="pc_regular"
                        style={{ ...labelStyle, margin: "0px 0px 5px 0px" }}
                      >
                        Country Code <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <Autocomplete
                        id="combo-box-demo"
                        options={props?.Sbi}
                        getOptionLabel={(option) => option.value}
                        size={"small"}
                        isRequired={true}
                        disabled={
                          props?.isViewMode ||
                          statusDetails?.status1 === "Inactive" ||
                          statusDetails?.status === "Deceased"
                            ? true
                            : false
                        }
                        defaultValue={val?.mobile_code?.value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              val?.error &&
                              (val?.error["mobile_code"] ||
                                val?.error["code_err"])
                                ? true
                                : false
                            }
                            style={
                              val?.error &&
                              (val?.error["mobile_code"] ||
                                val?.error["code_err"])
                                ? errorborderStyle
                                : borderStyle
                            }
                            variant="outlined"
                          />
                        )}
                        onChange={(e, value) =>
                          setDetails(patientData, "mobile_code", value, i)
                        }
                        //value={patientData?.contact[i]?.mobile_code?.value}
                        value={
                          val?.mobile_code ? val.mobile_code : ccDefaultVal
                        }
                      />
                      {/* <Select
                id={`${parent_id}_contact_point_contact_number_select`}
                value={contact?.mobilecode?.value ?? null}
                options={props?.Sbi}
                // options={[
                //     { value: '+91', label: '+91' },
                // ]}
                onChangeValue={(e) => setDetails(patientData,"mobilecode", e)}
                placeholder={label(patientData)}
                abelStyle={labelStyle} style={borderStyle}
                showArrow
                label="Country Code"
                placeholder="select..."
                labelStyle={labelStyle}
                inLineStyles={borderStyle}
                hoverColor={UIColor.primaryColor.color}
              />
            </Col>}
            <Col
              id={`${parent_id}_contact_point_mobile_number_col`}
              md={3} lg={3} sm={6} xs={12} className="no-padding-left">
              <TextInput
                id={`${parent_id}_contact_point_mobile_number_textinput`}
                type={((label(patientData) === 'Mobile') || (label(patientData) === 'Phone')) ? 'number' : 'type'}
                // helperText={(contact?.error && contact?.error['mobile']) ? 'Is required' : ''}
                helperText={contactModeHelperText(contact)}
                value={contact.mobile ? contact.mobile : ""}
                onChange={(e) => setDetails(patientData,"mobile", e.target.value, ((label(patientData) === 'Mobile') || (label(patientData) === 'Phone')) ? 10 : 15)}
                error={(contact?.error && contact?.error['mobile']) ? true : false}
                label={label(patientData)} labelStyle={labelStyle} style={borderStyle}
                placeholder={label(patientData)}
                isRequired={true}
                // disabled={contact.mode ? false : true}
                hoverColor={UIColor.primaryColor.color} />
  
            </Col>
            <Col
              id={`${parent_id}_contact_point_use_label_col`}
              md={1} lg={1} sm={6} xs={12} className="no-padding-left">
              <Select
                id={`${parent_id}_contact_point_use_label_select`}
                showArrow
                label="Use"
                placeholder="select..."
                labelStyle={labelStyle}
                inLineStyles={borderStyle}
                hoverColor={UIColor.primaryColor.color}
                options={FlterOptions('ADDRESSUSE')}
                // options={[
                //     { value: 'Home', label: 'Home' },
                //     { value: 'Work', label: 'Work' },
                //     { value: 'Temp', label: 'Temp' },
                //     { value: 'Old', label: 'Old' },
                // ]}
                value={contact?.use?.value ?? null}
                onChangeValue={(e) => setDetails(patientData,"use", e)}
              />
            </Col>
            <Col
              id={`${parent_id}_contact_point_priority_label_col`}
              md={1} lg={1} sm={6} xs={12} className="no-padding-left">
              <Select
                id={`${parent_id}_contact_point_priority_label_select`}
                showArrow
                label="Priority"
                placeholder="select..."
                labelStyle={labelStyle}
                inLineStyles={borderStyle}
                hoverColor={UIColor.primaryColor.color}
                options={FlterOptions('PRIORITY')}
                // options={[
                //     { value: '1', label: '1' },
                //     { value: '2', label: '2' },
                //     { value: '3', label: '3' },
                //     { value: '4', label: '4' },
                //     { value: '5', label: '5' },
                //     { value: '6', label: '6' },
                // ]}
                value={contact?.priority?.value ?? null}
                onChangeValue={(e) => setDetails(patientData,"priority", e)}
              />
            </Col>
          </Row>
        </Paper>
      </Div>
  
    }
              /> */}
                    </Col>
                  ) : null}

                  <Col
                    id={`${parent_id}_contact_point_mobile_number_col`}
                    md={3}
                    lg={3}
                    sm={6}
                    xs={12}
                    className="no-padding-left"
                  >
                    <TextInput
                      autoFocus={true}
                      id={`${parent_id}_contact_point_mobile_number_textinput`}
                      type={labelType(patientData, i)}
                      // helperText={(contact?.error && contact?.error['mobile']) ? 'Is required' : ''}
                      disabled={
                        props?.isViewMode ||
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                      helperText={contactModeHelperText(contact, i)}
                      value={val?.mobile ? val.mobile : ""}
                      onChange={(e) =>
                        setDetails(
                          patientData,
                          "mobile",
                          e.target.value,
                          i,
                          label(patientData, i) === "Mobile" ||
                            label(patientData, i) === "Phone"
                            ? 20
                            : 40
                        )
                      }
                      error={val?.error && val?.error["mobile"] ? true : false}
                      label={label(patientData, i)}
                      labelStyle={labelStyle}
                      style={
                        val?.error &&
                        (val?.error["mobile"] || val?.error["number_err"])
                          ? errorborderStyle
                          : borderStyle
                      }
                      placeholder={label(patientData, i)}
                      isRequired={true}
                      // disabled={contact.mode ? false : true}
                      hoverColor={UIColor.primaryColor.color}
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_contact_point_use_label_col`}
                    md={1}
                    lg={1}
                    sm={6}
                    xs={12}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_contact_point_use_label_select`}
                      showArrow
                      label="Use"
                      placeholder="select..."
                      labelStyle={labelStyle}
                      inLineStyles={borderStyle}
                      hoverColor={UIColor.primaryColor.color}
                      options={FlterOptions3(props?.allMasters, "ADDRESSUSE")}
                      // options={[
                      //     { value: 'Home', label: 'Home' },
                      //     { value: 'Work', label: 'Work' },
                      //     { value: 'Temp', label: 'Temp' },
                      //     { value: 'Old', label: 'Old' },
                      // ]}
                      value={val?.use?.value ?? null}
                      onChangeValue={(e) =>
                        setDetails(patientData, "use", e, i)
                      }
                      disabled={
                        props?.isViewMode ||
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col
                    id={`${parent_id}_contact_point_priority_label_col`}
                    md={1}
                    lg={1}
                    sm={6}
                    xs={12}
                    className="no-padding-left"
                  >
                    <Select
                      id={`${parent_id}_contact_point_priority_label_select`}
                      showArrow
                      label="Priority"
                      placeholder="select..."
                      labelStyle={labelStyle}
                      inLineStyles={borderStyle}
                      hoverColor={UIColor.primaryColor.color}
                      options={FlterOptions5(
                        props?.allMasters,
                        "PRIORITY",
                        patientData
                      )}
                      // options={[
                      //     { value: '1', label: '1' },
                      //     { value: '2', label: '2' },
                      //     { value: '3', label: '3' },
                      //     { value: '4', label: '4' },
                      //     { value: '5', label: '5' },
                      //     { value: '6', label: '6' },
                      // ]}
                      value={val?.priority?.value ?? null}
                      onChangeValue={(e) =>
                        setDetails(patientData, "priority", e, i)
                      }
                      disabled={
                        props?.isViewMode ||
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                    />

                    <Div
                      id={`${parent_id}_${i}_patientidentification_trash_div`}
                      style={{
                        width: 15,
                        cursor: "pointer",
                        marginLeft: "120px",
                        marginTop: -30,
                        position: "relative,",
                      }}
                    >
                      {!props.isViewMode && (
                        <Image
                          id={`${parent_id}_${i}_patientidentification_trash_image`}
                          src={trashIcon}
                          alt="delete"
                          onClick={() =>
                            handleDeleteContactPoint(patientData, i)
                          }
                        />
                      )}
                    </Div>
                  </Col>
                </Row>
              </div>
            );
          })}
      </Paper>
    </Div>
  );
};

export default withAllContexts(ContactPoint); // withallcontext here

// return isValidate;