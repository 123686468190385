import React from "react";

export const Hamburger = (props) => {
  let width = props.width ? props.width : "18";
  let height = props.height ? props.height : "18";
  let color = props.color ? props.color : "#fff";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      {...props}
    >
      <path id="icons8-circled-menu" d="M5,3A2,2,0,1,0,7,5,2,2,0,0,0,5,3Zm7,0a2,2,0,1,0,2,2A2,2,0,0,0,12,3Zm7,0a2,2,0,1,0,2,2A2,2,0,0,0,19,3ZM5,10a2,2,0,1,0,2,2A2,2,0,0,0,5,10Zm7,0a2,2,0,1,0,2,2A2,2,0,0,0,12,10Zm7,0a2,2,0,1,0,2,2A2,2,0,0,0,19,10ZM5,17a2,2,0,1,0,2,2A2,2,0,0,0,5,17Zm7,0a2,2,0,1,0,2,2A2,2,0,0,0,12,17Zm7,0a2,2,0,1,0,2,2A2,2,0,0,0,19,17Z" 
        transform="translate(-3 -3)"
        fill={color}
    />
    </svg>
  );
};
