import React from "react";
import Chip from "@material-ui/core/Chip";
import { useStyles } from "./style";

function StyledChip(props) {
  const classes = useStyles();
  return <Chip classes={classes} {...props} />;
}

export default StyledChip;
