import React from "react";

export const CloseConsultation = (props) => {
  let width = props.width ? props.width : "16";
  let height = props.height ? props.height : "16";
  let color = props.color ? props.color : "#ad6338";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      {...props}
    >
      <path id="icons8-sign-out" d="M12,4a8,8,0,1,0,6.27,12.96.6.6,0,1,0-.941-.745,6.8,6.8,0,1,1,0-8.43.6.6,0,1,0,.941-.745A7.986,7.986,0,0,0,12,4Zm4.994,4.994a.6.6,0,0,0-.418,1.03L17.951,11.4,9.8,11.392a.6.6,0,1,0,0,1.2l8.153.007-1.377,1.377a.6.6,0,1,0,.849.849l2.4-2.4a.6.6,0,0,0,0-.848l-2.4-2.4A.6.6,0,0,0,16.994,8.994Z" 
        transform="translate(-4 -4)"
        fill={color}
    />
    </svg>
  );
};

