import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { Container, H6, List, ListItem, Div } from "qdm-component-library";
import { UIColor } from "../../../themes/theme";
import { useStyles } from "./style";
import { useSelector } from "react-redux";

export const handleSelectHospital = (val, props) => {
  let data = props?.values?.select_hospitsl;
  if (data?.filter((l) => l?.value === val?.value)?.length > 0) {
    data = data?.filter((l) => l?.value !== val?.value);
  } else {
    data = [...data, val];
  }
  props?.handlesearchActives &&
    props.handlesearchActives("select_hospitsl", data);
};

function SearchHospitalResult(props) {
  const classes = useStyles();
  const selector = useSelector((state) => {
    return { hospital: state?.searchHospital?.hospital_data };
  });

  const checkTrue = (stateVal, val) => {
    return stateVal?.filter((l) => l?.value === val)?.length > 0 ? true : false;
  };

  const { values, parent_id } = props;

  const hospitalSelection = selector?.hospital;
  console.log({ hospitalSelection });

  return (
    <div id={`${parent_id}_hospital_result_parent_div`}>
      <Container
        id={`${parent_id}_hospital_result_parent_div`}
        key={"0"}
        name="container"
        fluid={false}
        style={{ maxWidth: 992 }}
      >
        <div
          id={`${parent_id}_hospital_result_parent_sub_div`}
          style={{
            padding: "16px 0px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            width: 557,
            marginLeft: "auto",
          }}
          ref={props?.refs}
        >
          <H6
            id={`${parent_id}_hospital_result_HOSPITAL_h6`}
            className="pc_regular"
            key={"0"}
            name="HOSPITAL"
            style={{
              color: "#6F6F6F",
              fontSize: "12px",
              lineHeight: 1,
              letterSpacing: 0,
              padding: "0px 16px",
            }}
          ></H6>

          <List
            id={`${parent_id}_hospital_result_HOSPITAL_list`}
            className="hospital_listing"
            key={"1"}
            children="List"
            orientation="vertical"
            listItemWidth="100%"
            marginVertical="0"
            marginHorizontal="0"
            paddingVertical="8"
            paddingHorizontal="8"
            style={{
              padding: "0px",
              marginTop: "4px",
              maxHeight: 400,
              overflow: "auto",
            }}
          >
            {!hospitalSelection?.loading &&
            hospitalSelection?.data?.length > 0 &&
            hospitalSelection?.data?.filter((m) =>
              m?.label
                ?.toLowerCase()
                ?.includes(values?.search_hospital_value?.toLowerCase() ?? "")
            )?.length > 0 ? (
              hospitalSelection?.data
                ?.filter((m) =>
                  m?.label
                    ?.toLowerCase()
                    ?.includes(
                      values?.search_hospital_value?.toLowerCase() ?? ""
                    )
                )
                ?.map((hospital, i) => {
                  let label = hospital?.name ? hospital.name : hospital.label;
                  let value = hospital.ID ? hospital.ID : hospital.value;
                  let distance = hospital.distance || " ";
                  return (
                    <ListItem
                      className={`hospital_listing_options`}
                      id={`${parent_id}_${i}_hospital_result_HOSPITAL_km_listitem`}
                      key={i}
                      title={label}
                      description=""
                      profile=""
                      onClick={() =>
                        handleSelectHospital({ label, value }, props)
                      }
                      action={distance}
                      marginVertical="0"
                      marginHorizontal="0"
                      paddingVertical="8"
                      paddingHorizontal="16"
                      style={{
                        marginLeft: "",
                        paddingTop: "4px",
                        paddingBottom: "8px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        color: "#6F6F6F",
                        cursor: "pointer",
                      }}
                    >
                      <Div
                        id={`${parent_id}_${i}_hospital_result_HOSPITAL_div`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Div
                          id={`${parent_id}_${i}_hospital_result_HOSPITAL_checkboc_div`}
                          style={{ flex: 1 }}
                          className={classes.checkBoxStyle}
                        >
                          <FormControlLabel
                            id={`${parent_id}_${i}_hospital_result_HOSPITAL_formcontrollabel`}
                            control={
                              <Checkbox
                                id={`${parent_id}_${i}_hospital_result_HOSPITAL_checkbox`}
                                checked={checkTrue(
                                  values?.select_hospitsl,
                                  value
                                )}
                                style={{ color: UIColor.primaryColor.color }}
                              />
                            }
                            label={
                              <H6
                                id={`${parent_id}_${i}_hospital_result_HOSPITAL_name_h6`}
                                className="pc_medium"
                                style={{
                                  fontSize: 14,
                                  color: "#101010",
                                  marginLeft: 5,
                                  letterSpacing: 0,
                                  lineHeight: 1,
                                }}
                              >
                                {label ? label : " "}
                              </H6>
                            }
                          />
                        </Div>
                        <H6
                          id={`${parent_id}_${i}_hospital_result_HOSPITAL_km_h6`}
                          className="pc_medium"
                          style={{
                            fontSize: 12,
                            color: "#101010",
                            letterSpacing: 0,
                            lineHeight: 1,
                          }}
                        >
                          {distance}
                        </H6>
                      </Div>
                    </ListItem>
                  );
                })
            ) : (
              <Div id={`${parent_id}_hospital_resultno_hospitals_div`}>
                <H6
                  id={`${parent_id}_hospital_resultno_hospitals_h6`}
                  style={{
                    margin: 0,
                    marginBottom: 15,
                    textAlign: "center",
                    color: "#000000",
                    fontSize: 14,
                    fontWeight: "600",
                  }}
                >
                  No Hospitals Found
                </H6>
              </Div>
            )}
            {/* <div
              style={{
                display: "flex",
                maxWidth: "100%"
              }}
              onClick={() =>
                handlesearchActives(
                  'select_hospitsl',
                  'Apollo Hospitals - Greams Road Chennai'
                )
              }
            >
              <Checkbox
                key={'0'}
                size={'medium'}
                color={'primary'}
                name={''}
                style={{ marginLeft: 0 }}
              ></Checkbox>
              <ListItem
                key={'0'}
                title='Apollo Hospitals - Greams Road Chennai'
                description=''
                profile=''
                action='2.2kms'
                marginVertical='0'
                marginHorizontal='0'
                paddingVertical='8'
                paddingHorizontal='16'
                style={{
                  marginLeft: '',
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: '#101010',
                  padding: '0px',
                  paddingBottom: '8px',
                  minWidth: "auto",
                  cursor: 'pointer'
                }}
              ></ListItem>
            </div>
            <div
              style={{
                display: "flex",
                maxWidth: "100%"
              }}
              onClick={() =>
                handlesearchActives(
                  'select_hospitsl',
                  'pollo Hospitals - Teynampet Chennai'
                )
              }
            >
              <Checkbox
                key={'0'}
                size={'medium'}
                color={'primary'}
                name={''}
                style={{ marginLeft: 0 }}
              ></Checkbox>
              <ListItem
                key={'1'}
                title='Apollo Hospitals - Teynampet, Chennai'
                description=''
                profile=''
                action='3.2 kms'
                marginVertical='0'
                marginHorizontal='0'
                paddingVertical='8'
                paddingHorizontal='16'
                style={{
                  marginLeft: '',
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: '#101010',
                  padding: '0px',
                  paddingBottom: '8px',
                  minWidth: "auto",
                  cursor: 'pointer'
                }}
              ></ListItem>
            </div> */}
          </List>
          {/* 
          <H6
            key={'2'}
            name='CLINIC'
            style={{ color: '#6F6F6F', fontSize: '12PX', marginTop: '16PX' }}
          ></H6>

          <List
            key={'3'}
            children='List'
            orientation='vertical'
            listItemWidth='100%'
            marginVertical='0'
            marginHorizontal='0'
            paddingVertical='8'
            paddingHorizontal='8'
            style={{ marginTop: '4PX', padding: '0px' }}
          >
            <div
              style={{
                display: "flex",
                maxWidth: "100%"
              }}
              onClick={() =>
                handlesearchActives(
                  'select_hospitsl',
                  'Apollo Clinic - Saidapet Chennai'
                )
              }
            >
              <Checkbox
                key={'0'}
                size={'medium'}
                color={'primary'}
                name={''}
                style={{ marginLeft: 0 }}
              ></Checkbox>
              <ListItem
                key={'0'}
                title='Apollo Clinic - Saidapet
 Chennai'
                description=''
                profile=''
                action='4.6kms'
                marginVertical='0'
                marginHorizontal='0'
                paddingVertical='8'
                paddingHorizontal='16'
                style={{
                  marginLeft: '',
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: '#101010',
                  padding: '0px',
                  paddingBottom: '8px',
                  minWidth: "auto",
                  cursor: 'pointer'
                }}
              ></ListItem>
            </div>
            <div
              style={{
                display: "flex",
                maxWidth: "100%"
              }}
              onClick={() =>
                handlesearchActives(
                  'select_hospitsl',
                  'Apollo Medical Center - Gunidy Chennai'
                )
              }
            >
              <Checkbox
                key={'0'}
                size={'medium'}
                color={'primary'}
                name={''}
                style={{ marginLeft: 0 }}
              ></Checkbox>
              <ListItem
                key={'1'}
                title='Apollo Medical Center - Gunidy
 Chennai'
                description=''
                profile=''
                action='6.6kms'
                marginVertical='0'
                marginHorizontal='0'
                paddingVertical='8'
                paddingHorizontal='16'
                style={{
                  marginLeft: '',
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: '#101010',
                  padding: '0px',
                  paddingBottom: '8px',
                  minWidth: "auto",
                  cursor: 'pointer'
                }}
              ></ListItem>
            </div>
          </List>
       
        */}
        </div>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  hospitals: state?.searchHospital?.hospital_data,
  getAllHospitalsAgainstDoctor:
    state?.searhcApiSlice?.getPractitionerAgainstOrganization,
});

export default connect(mapStateToProps, actions)(SearchHospitalResult);
