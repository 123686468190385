import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../../../themes/theme";

export const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: 16,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  body: {
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
  TapSelect: {
    "& div": {
      minWidth: "40%",
      // backgroundColor: "yellow",
      "& span": {
        fontSize: 12,
        fontFamily: "pc_medium !important",
      },
    },
  },
  TapSelectBillSummary: {
    "& div": {
      "& span": {
        fontSize: 12,
        fontFamily: "pc_medium !important",
      },
    },
  },
  picker: {
    fontSize: "14px",
    color: UIColor.primaryText.color,
    margin: "10px 10px 0px 0px",
    border: "1px solid #e0e0e0",
    borderRadius: "12px",
    padding: "8px",
  },
  activepicker: {
    fontSize: "14px",
    color: "#fff",
    margin: "10px 10px 10px 0px",
    border: "1px solid #e0e0e0",
    borderRadius: "12px",
    padding: "8px",
    background: "#0071F2",
  },
}));
