const ComponentJson = {
    "statsWidgetN": {
        index: "0",
        height: 3.4,
        label: "Stats",
        name: "stats",
        width: 12,
        x: 0,
        y:0
    },
    "statsWidgetD": {
        index: "0",
        height: 3.4,
        label: "Stats",
        name: "stats",
        width: 12,
        x: 0,
        y:0
    },
    "statsWidgetF": {
        index: "0",
        height: 3.4,
        label: "Stats",
        name: "stats",
        width: 10,
        x: 0,
        y:0
    },
    "HighPriorityPatientsN": {
        index: "1",
        height: 4,
        label: "Profile List",
        name: "profileList",
        width: 6,
        x: 0,
        y: 3.4
    },
    "HighPriorityPatientsF": {
        index: "1",
        height: 4,
        label: "Profile List",
        name: "profileList",
        width: 6,
        x: 0,
        y:0
    },
    "HighPriorityPatientsD": {
        index: "1",
        height: 4,
        label: "Profile List",
        name: "profileList",
        width: 12,
        x: 0,
        y: 3.4
    },
    "PatientStatusN": {
        index: "2",
        // height: 10,
        height: 11.3,
        label: "Patient Status",
        name: "patientStatus",
        width: 4,
        x: 7,
        y: 3.4
    },
    "TodayScheduleD": {
        index: "3",
        height: 11.3,
        label: "Today's Schedule",
        name: "schedule",
        width: 6,
        x: 0,
        y: 3.4
    },
    "TodayScheduleN": {
        index: "3",
        height: 10,
        label: "Today's Schedule",
        name: "schedule",
        width: 6,
        x: 0,
        y:0
    },
    "TodayScheduleF": {
        index: "3",
        height: 10,
        label: "Today's Schedule",
        name: "schedule",
        width: 6,
        x: 0,
        y:0
    },
    "pendingTasksN": {
        index: "4",
        height: 7.3,
        label: "Pending Task",
        name: "pendingTask",
        width: 6,
        x: 0,
        y: 7.4
    },
    "pendingTasksD": {
        index: "4",
        height: 9,
        label: "Pending Task",
        name: "pendingTask",
        width: 4,
        x: 7,
        y: 7.4
    },
    "pendingTasksF": {
        index: "4",
        height: 8,
        label: "Pending Task",
        name: "pendingTask",
        width: 5,
        x: 6,
        y: 3.4
    },
    "patientwaitingtimeN": {
        index: "5",
        height: 8,
        label: "Patient Waiting Time",
        name: "patientwaitingtime",
        width: 5,
        x: 0,
        y: 14.7
    },
    "patientwaitingtimeD": {
        index: "5",
        height: 8,
        label: "Patient Waiting Time",
        name: "patientwaitingtime",
        width: 5 ,
        x: 0,
        y: 14.7
    },
    "currentVisitStatsN": {
        index: "6",
        height: 8,
        label: "Current Visit Stats",
        name: "currentVsitStats",
        width: 5,
        x: 7,
        y: 14.7
    },
    "currentVisitStatsD": {
        index: "6",
        height: 8,
        label: "Current Visit Stats",
        name: "currentVsitStats",
        width: 5,
        x: 7,
        y: 14.7
    },
    "currentVisitStatsF": {
        index: "6",
        height: 8,
        label: "Current Visit Stats",
        name: "currentVsitStats",
        width: 5,
        x: 0,
        y: 3.4
    },
    "averagewaitingtimeN": {
        index: "7",
        height: 8,
        label: "Average Waiting Time",
        name: "averageWaitingTime",
        width: 6,
        x: 0,
        y:0
    },
    "averagewaitingtimeD": {
        index: "7",
        height: 8,
        label: "Average Waiting Time",
        name: "averageWaitingTime",
        width: 6,
        x: 0,
        y:0
    },
    "averagewaitingtimeF": {
        index: "7",
        height: 8,
        label: "Average Waiting Time",
        name: "averageWaitingTime",
        width: 10,
        x: 0,
        y: 11.4
    },
    "financialClass": {
        index: "8",
        height: 8 ,
        label: "Financial Class",
        name: "financialClass",
        width: 5,
        x: 0,
        y: 19.4
    },
    "billingStatus": {
        index: "9",
        height: 8,
        label: "Billing Status",
        name: "billingStatus",
        width: 5,
        x: 6,
        y: 19.4
    },
}

export const getLayout = (permission) => {
    console.log("window.screen.width",window.screen.width);
    let arr = [];
    permission?.write?.map(val => {
        if (ComponentJson[val]) {
            arr.push({
                w: ComponentJson[val]?.width,
                h: ComponentJson[val].height,
                x: ComponentJson[val]?.x,
                y: ComponentJson[val]?.y,
                i: ComponentJson[val].index,
                minW: 12,
                minH: 3,
                moved: false,
                static: false
            })
        }
    })
    arr.sort(function (a, b) {
        return a.i - b.i;
      });
    return arr
}