import React from "react";

export const WorkList = (props) => {
  let width = props.width ? props.width : "18.833";
  let height = props.height ? props.height : "16";
  let color = props.color ? props.color : "#101010";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18.833 16"
      {...props}
    >
      <path id="icons8-read-message" class="cls-1" d="M8.941,7A1.658,1.658,0,0,0,7.294,8.647V9.58L5.036,10.924A2.12,2.12,0,0,0,4,12.744v7.2A3.07,3.07,0,0,0,7.059,23H19.765a3.07,3.07,0,0,0,3.059-3.059V12.992a.706.706,0,0,0,0-.231v-.017a2.12,2.12,0,0,0-1.035-1.82h0L19.529,9.581V8.647A1.658,1.658,0,0,0,17.882,7Zm0,1.412h8.941a.225.225,0,0,1,.235.235v5.4l-4.706,2.29-4.706-2.29V10.091a.706.706,0,0,0,0-.229V8.647A.225.225,0,0,1,8.941,8.412Zm1.882,1.412a.706.706,0,1,0,0,1.412H16a.706.706,0,1,0,0-1.412Zm-3.529,1.4v2.133l-1.822-.886a.7.7,0,0,1,.285-.334Zm12.235,0,1.537.914a.7.7,0,0,1,.284.333l-1.821.886Zm-8.706,1.423a.706.706,0,1,0,0,1.412h3.294a.706.706,0,1,0,0-1.412ZM5.412,14.011,13.1,17.753a.706.706,0,0,0,.618,0l7.691-3.742v5.93a1.637,1.637,0,0,1-1.647,1.647H7.059a1.637,1.637,0,0,1-1.647-1.647Z"
       transform="translate(-4 -7)"
       fill={color}
       />
    </svg>
  );
};
