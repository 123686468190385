import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  // const labels = ['Self Pay', 'Ins-1', 'Ins-2', 'Ins-3'];
  
  export const data = {
    title: 'Data Not Loaded',
    labels:['', '', '', ''],
    datasets: [
      {
        data: [0,0,0,0],
        backgroundColor: ['rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',]
      },
    ],
  };
  
  
  
const VerticalBarChart = (props) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
      title: {
        display: true,
        text: props?.data?.title ?? data?.title,
        position: 'bottom',
      },
    },
  };
  
    return <Bar options={options} data={props?.data ?? data} />;
};

export default VerticalBarChart;