import React from "react";

export const BloodSamplesIcon = (props) => {
  let width = props.width ? props.width : "15";
  let height = props.height ? props.height : "13";
//   let color = props.color ? props.color : "#000000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16.371 13.959">
        <g id="icons8-blood-sample" transform="translate(-14.058 -16.886)">
            <path id="Path_92644" data-name="Path 92644" d="M17.461,36.067h0a2.07,2.07,0,0,1,.262-2.949L27.945,24.6l2.7,3.211L20.426,36.329A2.12,2.12,0,0,1,17.461,36.067Z" transform="translate(-2.43 -6.45)" fill="#fff"/>
            <path id="Path_92645" data-name="Path 92645" d="M25.238,38.7l-7.453,6.192a2.1,2.1,0,0,0-.262,2.949h0a2.12,2.12,0,0,0,2.965.262l10.222-8.518" transform="translate(-2.492 -18.241)" fill="#f1c0c3"/>
            <path id="Path_92646" data-name="Path 92646" d="M45.3,50.068l-2.7-3.211L45.4,44.4l2.7,3.211Z" transform="translate(-23.867 -23.007)" fill="#fff"/>
            <path id="Path_92647" data-name="Path 92647" d="M16.653,34.189a2.581,2.581,0,0,1-1.982-.917,2.56,2.56,0,0,1,.311-3.637L25.318,21a.489.489,0,0,1,.622.754L15.6,30.389a1.643,1.643,0,0,0-.573,1.081,1.588,1.588,0,0,0,.377,1.163,1.627,1.627,0,0,0,2.277.2L28.021,24.2a.489.489,0,0,1,.622.754L18.307,33.583A2.52,2.52,0,0,1,16.653,34.189Z" transform="translate(0 -3.345)" fill="#454b54"/>
            <path id="Path_92648" data-name="Path 92648" d="M82.749,22.245a.49.49,0,0,1-.377-.18L78.7,17.691a.489.489,0,1,1,.754-.622l3.669,4.374a.476.476,0,0,1-.066.688A.432.432,0,0,1,82.749,22.245Z" transform="translate(-53.958)" fill="#454b54"/>
            <path id="Path_92649" data-name="Path 92649" d="M0,0H.655V1.769H0Z" transform="matrix(0.641, 0.768, -0.768, 0.641, 21.951, 23.41)" fill="#ed97a0"/>
            <path id="Path_92650" data-name="Path 92650" d="M0,0H1.769V.655H0Z" transform="translate(21.162 23.341) rotate(49.851)" fill="#ed97a0"/>
            <g id="Group_90444" data-name="Group 90444" transform="translate(28.332 24.145)">
            <path id="Path_92651" data-name="Path 92651" d="M102.248,61.2c-.77,1.081-1.048,1.425-1.048,2.031a1.048,1.048,0,1,0,2.1,0C103.3,62.625,103.018,62.281,102.248,61.2Z" transform="translate(-101.2 -61.2)" fill="#f37c87"/>
            </g>
        </g>
    </svg>
    );
};