import React from "react";

export const NoAccessIcon = (props) => {
  let width = props.width ? props.width : "18";
  let height = props.height ? props.height : "18";
  let color = props.color ? props.color : "#707070";
  return (
    <svg 
    id="NoAccessIcon_svg"
    xmlns="http://www.w3.org/2000/svg" {...props} style={props?.style} width={width} height={height} viewBox="0 0 18 18"><path class="a" fill={color} d="M13,4a9,9,0,1,0,9,9A9.01,9.01,0,0,0,13,4Zm0,1.35A7.65,7.65,0,1,1,5.35,13,7.64,7.64,0,0,1,13,5.35Zm4.805,1.513L6.851,17.646l1.225,1.317L19.134,8.136Z" transform="translate(-4 -4)"/></svg>
  );
};