import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { Typography, Popover } from "@material-ui/core";
import DoctorConsultBg from "../../assets/img/png/doctorConsultBg.png";

import {
  Container,
  Row,
  Div,
  Avatar,
  Text,
  Divider,
  Icons,
  BackgroundImage,
} from "qdm-component-library";
import "./index.css";
import { getUserInfo, Logout } from "../../utils";
import Logo from "../../assets/img/svg/Logo_W.svg";

import { useState } from "react";
import { UIColor } from "../../themes/theme";
import AppointmentBookingSearch from "./appointmentBookingSearch";


function SearchFacility(props) {
  const [state, setState] = useState({
    anchorEl: null,
  });

  //detect outside click

  const handleProfileClick = (event) => {
    setState({
      ...state,
      anchorEl: event.currentTarget,
    });
  };

  const handleProfileClose = () => {
    setState({
      ...state,
      anchorEl: null,
    });
  };

  const { name } = getUserInfo();
  const open = Boolean(state.anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div id="doctor_consultation_parent_div" style={{ width: "100%" }}>
      <Container
        id="doctor_consultation_parent_container"
        key={"0"}
        name="container"
        fluid={true}
        style={{
          backgroundColor: !props.hideBackground ? "#e8e8e8" : "transparent",
          height: props.hideBackground ? "100%" : "100vh",
          padding: "0px",
        }}
      >
        <Row
          id="doctor_consultation_parent_row"
          key={"0"}
          alignItems="stretch"
          direction="row"
          justify="flex-start"
          spacing="2"
          style={{
            backgroundColor: UIColor.primaryColor.color,
            height: "131px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 32px",
          }}
        >
          <Div
            id="doctor_consultation_logo_div"
            key={"0"}
            inLineStyles="[object Object]"
            className="qdm"
            style={{
              display: "flex",
              alignSelf: "flex-start",
            }}
          >
            <img
              id="doctor_consultation_logo_image"
              key={"0"}
              variant="rounded"
              letter="Logo"
              src={Logo}
              alt="Logo"
              style={{
                width: "100%",
                height: "100%",
              }}
              className={"pc_regular"}
            />
          </Div>

          <Div
            id="doctor_consultation_title_div"
            key={"1"}
            inLineStyles="[object Object]"
            className="qdm"
            style={{
              display: "flex",
              alignSelf: "flex-start",
              marginTop: "24px",
              marginLeft: "6%",
            }}
          >
            <Div
              id="doctor_consultation_title_sub_div"
              key={"0"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "",
                justifyContent: "center",
                alignItems: "center",
                width: "",
              }}
            >
              <Text
                id="doctor_consultation_label_text"
                className="pc_semibold"
                key={"0"}
                name="Doctor Consultation"
                style={{
                  color: "#ffffff",
                  fontSize: "16px",
                }}
              ></Text>

              <Divider
                id="doctor_consultation_bottom_divider"
                key={"1"}
                className=""
                label=""
                borderColor=""
                textColor=""
                orientation="horizontal"
                variant="middle"
                style={{
                  width: "40px",
                  height: "3px",
                  borderColor: "#ffffff",
                  backgroundColor: "#ffffff",
                  margin: "4px auto",
                }}
              ></Divider>
            </Div>

            {/* <Div
                id="doctor_consultation_diagnostics_title_div"
                key={"1"}
                inLineStyles="[object Object]"
                className="qdm"
                style={{ marginLeft: "48px", marginRight: "48px" }}
              >
                <Text
                  id="doctor_consultation_diagnostics_label_text"
                  key={"0"}
                  className="pc_regular"
                  name="Diagnostics Lab"
                  style={{ color: "#ffffff", fontSize: "16px" }}
                ></Text>
              </Div> */}

            {/* <Div
                id="doctor_consultation_radiology_title_div"
                key={"2"}>
                <Text
                  id="doctor_consultation_radiology_label_text"
                  key={"0"}
                  className="pc_regular"
                  name="Radiology Scan"
                  style={{ color: "#ffffff", fontSize: "16px" }}
                ></Text>
              </Div> */}
          </Div>

          <Div
            id="doctor_consultation_guest_parent_div"
            key={"2"}
            inLineStyles="[object Object]"
            className="qdm"
            style={{
              display: "flex",
              alignSelf: "flex-start",
              marginTop: "10px",
              alignItems: "center",
            }}
          >
            <div id="doctor_consultation_guest_sub_div">
              <Text
                id="doctor_consultation_guest_text"
                className="pc_regular"
                key={"0"}
                name={name || "Guest"}
                style={{ color: "#FFFFFF", fontSize: 14 }}
              ></Text>
              <br />
              <Text
                id="doctor_consultation_version"
                className="pc_regular"
                key={"0"}
                name={"version : " + localStorage.getItem("version")}
                style={{ color: "#FFFFFF", fontSize: 12, marginTop: -2 }}
              ></Text>
            </div>

            <Avatar
              id="doctor_consultation_guest_name_avatar"
              key={"1"}
              variant="rounded"
              letter={
                name ||
                state?.loggedInState?.name ||
                props?.guestInfo?.data?.name ||
                ""
              }
              src={props?.loggedUserInfo?.data?.photo}
              alt="G"
              style={{
                width: "40px",
                height: "40px",
                padding: "",
                marginLeft: "16px",
                marginRight: "10px",
                backgroundColor: "#E0E0E0",
                fontSize: "14px",
                fontFamily: "pc_medium",
              }}
            ></Avatar>

            <Icons
              id="doctor_consultation_guest_name_down_icon"
              key={"2"}
              fontIcon="angle-down"
              ariaHidden="true"
              className=""
              size={18}
              style={{ color: "#ffffff", cursor: "pointer" }}
              onClick={(e) => handleProfileClick(e)}
            ></Icons>
          </Div>
        </Row>

        {
          <BackgroundImage
            id="doctor_consultation_body_backgroundimage"
            key={"1"}
            url={!props.hideBackground ? DoctorConsultBg : ""}
            backgroundPosition="0% 0%"
            backgroundPositionX="left"
            backgroundPositionY="top"
            backgroundRepeat="no-repeat"
            backgroundRepeatX="no-repeat"
            backgroundRepeatY="no-repeat"
            backgroundAttachment="scroll"
            backgroundSize="cover"
            backgroundOrigin="padding-box"
            inLineStyles="[object Object]"
            style={{
              height: props.hideBackground ? "100%" : "calc(100vh - 130px)",
              backgroundColor: "transparent",
              opacity: "100",
            }}
          >
            <AppointmentBookingSearch />
          </BackgroundImage>
        }
      </Container>
      <Popover
        id={id}
        open={open}
        anchorEl={state.anchorEl}
        onClose={() => handleProfileClose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography
          id={`doctor_consultation_log_out_h6`}
          onClick={() => Logout()}
          style={{ padding: "10px", cursor: "pointer" }}
        >
          Log Out
        </Typography>
      </Popover>
    </div>
  );
}

const mapStateToProps = (state) => ({
  cities: state?.searhcApiSlice?.cities,
  hospitals: state?.searhcApiSlice?.hospitals,
  guestInfo: state?.authSlice?.guestInfo,
  relatedPerson: state?.personApiSlice?.getRelatedPerson,
  getAllHospitalsAgainstDoctor:
    state?.searhcApiSlice?.getPractitionerAgainstOrganization,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
});

export default connect(mapStateToProps, actions)(SearchFacility);
