import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MoodIcon from "@material-ui/icons/Mood";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateAreas: `
        "icon label"
        "filler value"`,
    gridTemplateColumns: "24px 1fr",
    gridTemplateRows: "24px 1fr",
    columnGap: 8,
    rowGap: 4,
  },
  iconContainer: {
    gridArea: "icon",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  labelContainer: {
    gridArea: "label",
    marginBlock: "auto",
    color: theme.palette.text.primary,
  },
  valueContainer: {
    gridArea: "value",
  },
  label: {
    // 
    color: theme.palette.text.primary,
    fontSize: 12,
  },
  value: {
    // color: "#2A3C50",
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
  },
}));

function InfoDisplay({ icon, label = "", value = "", valueComponent, ...props }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>{icon}</div>
      <div></div>
      <div className={classes.labelContainer}>
        <Typography className={classes.label}>{label}</Typography>
      </div>
      <div className={classes.valueContainer}>
        {valueComponent ? React.cloneElement(valueComponent, { className: classes.value }) : value ? <Typography className={classes.value}>{value}</Typography> : ''}
      </div>
    </div>
  );
}

export default InfoDisplay;

InfoDisplay.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  value: PropTypes.string,
};

InfoDisplay.defaultProps = {
  icon: null,
  label: "label",
  value: "value",
};