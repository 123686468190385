import React from "react";
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Checkbox,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useStyles } from "./style";
import Cancel from "../../../assets/img/svg/icons8-multiply.svg";
import Tick from "../../../assets/img/svg/icons8-double-tick.svg";
import Trash from "../../../assets/img/svg/icons8-trash.svg";
import printIcon from "../../../assets/img/svg/print-icon-noframe.svg";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"; 
import { TextInput } from "qdm-component-library";

const DataGridComponent = ({
    tableData = [],
    Header = [],
    dataType = [],
    tableWidth = "100%",
    totalCalculationRow = false,
    calculationResultData = [],
    customRow = false,
    handleCheckBox = () => null,
    handleHeaderCheckBox = () => null,
    handleDeleteSelected = () => null,
    handleSelectedPrint = () => null,
    handleTableTextChange = () => null,
    handleOpenPopup = () => null,
    allChecked = false,
    headerSize = "14px",
    isPrintTotalData = false,
    printTotalData = [],
    payerValue = false,
    openSplitPayer,
  }) => {
    const classes = useStyles();
  
    const generateComp = (type, row, rowIndex) => {
      switch (type.type) {
        case "CHECK":
          return row?.[type.name] ? (
            <img src={Tick} alt="Tick" />
          ) : (
            <img src={Cancel} alt="Cancel" />
          );
        case "INCREMENT":
          return <Typography className={classes.tableCellTypo}>{rowIndex + 1}</Typography>;
        case "TEXT":
          const returnData = row?.[type.name];
          if (type.name === "creditShare" && Number(returnData) > 0) {
            return (
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <Typography className={classes.tableCellTypo}>
                    {Number.isNaN(returnData) ? 0.0 : returnData}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={(e) => handleOpenPopup(e, row, rowIndex)}>
                    {openSplitPayer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Grid>
              </Grid>
            );
          } else if (returnData?.length > 45) {
            return (
              <Tooltip title={returnData} placement="top">
                <Typography className={classes.tableCellTypo}>{returnData.slice(0, 45) + "..."}</Typography>
              </Tooltip>
            );
          } else {
            return <Typography className={classes.tableCellTypo}>{Number.isNaN(returnData) ? 0.0 : returnData}</Typography>;
          }
        case "BILLTEXT":
          return <Typography className={classes.tableCellTypo} style={{ fontSize: "12px" }}>{row?.[type.name]}</Typography>;
        case "CHECKBOX":
          return row?.billed ? null : (
            <Checkbox
              style={{ padding: "0px" }}
              checked={row?.[type.name]}
              color="primary"
              size="small"
              onChange={(e) => handleCheckBox(e, row, rowIndex)}
            />
          );
        case "DELETE":
          return (
            <div style={{ cursor: "pointer" }} onClick={() => handleDeleteSelected(row, rowIndex)}>
              <img src={Trash} alt="delete" />
            </div>
          );
        case "ACTION":
          return (
            <div style={{ cursor: "pointer" }} onClick={(e) => handleSelectedPrint(e, row, rowIndex)}>
              <img src={printIcon} alt="print" />
            </div>
          );
        case "TEXTFIELD":
          return (
            <TextInput
              className={"textInput"}
              id={"discAmtTab"}
              label={""}
              placeholder=""
              type="number"
              disabled={((type.name === "patientShare" && (!payerValue || payerValue === "Self Pay")) || (type.name === "rate" && !row.chargeedit)) ? true : false}
              value={row?.[type.name]}
              onChange={(value) => handleTableTextChange(value, row, rowIndex, type.name)}
            />
          );
        case "EPOCH_DATE_DISPLAY":
          return <Typography className={classes.tableCellTypo}>{moment(row?.[type.name] * 1000).format("DD/MM/YYYY")}</Typography>;
        default:
          return row?.[type.name];
      }
    };
  
    const columns = [
    //   {
    //     field: 'checkbox',
    //     headerName: 'Select',
    //     width: 100,
    //     renderCell: (params) => (
    //       <Checkbox
    //         style={{ padding: "0px" }}
    //         checked={params.row.checked}
    //         color="primary"
    //         size="small"
    //         onChange={(e) => handleCheckBox(e, params.row, params.rowIndex)}
    //       />
    //     ),
    //   },
      {
        field: 's.no',
        headerName: 'S.No',
        width: 100,
        renderCell: (params) => (
          <Typography className={classes.tableCellTypo}>{params.row.id}</Typography>
        ),
      },
      ...dataType.map((type, index) => ({
        field: type.name,
        headerName: Header[index],
        width: type.width || 150,
        renderCell: (params) => generateComp(type, params.row, params.rowIndex),
      }))
    ];
    return (
      <div style={{ height: 330, width: tableWidth }}>
        <DataGrid
        sx={{ m: 2 }}
        rows={tableData}
        rowHeight={45}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        pagination
        onSelectionModelChange={(newSelection) => handleHeaderCheckBox(newSelection)}
        disableSelectionOnClick
      />
        {totalCalculationRow && (
          <Grid container justifyContent="flex-end">
            {calculationResultData.map((val, i) => (
              <Typography key={i} className={classes.tableCellTypo}>{val}</Typography>
            ))}
          </Grid>
        )}
        {isPrintTotalData && (
          <Grid container justifyContent="flex-end">
            {printTotalData.map((val, i) => (
              <Typography key={i} className={classes.tableCellTypo} style={{ fontSize: "12px" }}>{val}</Typography>
            ))}
          </Grid>
        )}
        {customRow && (
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography className={classes.tableCellTypo}>{tableData.length < 10 ? "0" + tableData.length : tableData.length} {tableData.length === 1 ? "Item" : "Items"}</Typography>
            <Typography className={classes.headerCell} style={{ padding: "6px 6px 5px" }}>Total</Typography>
          </Grid>
        )}
      </div>
    );
  };
export default DataGridComponent;
