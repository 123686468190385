import React from "react";
import { Card } from "qdm-component-library";
import BarChart from "./barChart";

function FinancialClass(props) {
  const styles = {
    card: {
      boxShadow: "0px 10px 25px #0000000A",
      border: "1px solid #E0E0E0",
      borderRadius: 8,
      marginTop:"10px",
      padding: 12,
      height: "280px"
    },
  };
  const data = {
    labels: ["Self Pay", "Insured"],
    datasets: [
      {
        label: "Pending Collection",
        data: [65, 80],
        barPercentage: 0.7,
        backgroundColor: ["#FF9F40", "#FF9F40"],
        borderWidth: 1,
      },
      {
        label: "Collected",
        data: [55, 40],
        barPercentage: 0.7,
        backgroundColor: ["#4BC0C0", "#4BC0C0"],
        borderWidth: 1,
      },
      {
        label: "Pending Bills",
        data: [45, 50],
        barPercentage: 0.7,
        backgroundColor: ["#FF6384", "#FF6384"],
        borderWidth: 1,
      },
      {
        label: "Cancelled",
        data: [95, 60],
        barPercentage: 0.7,
        backgroundColor: ["#36A2EB", "#36A2EB"],
        borderWidth: 1,
      },
    ],
  };
  const { parent_id } = props;
  return (
    <Card
      id={`layout_financial_parent_card`}
      style={styles.card}>
      <BarChart
        parent_id={'dashboard_layout'}
        data={props?.data} label="Financial Class" />
    </Card>
  );
}

export default FinancialClass;
