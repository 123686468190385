import React, { useEffect } from "react";
import { Div, H6, Col, Row, Text, Avatar, Image } from "qdm-component-library";
import "./style.css";
import moment from "moment";
import { utcTOLocal } from "../../../utils";
import editIcon from "../../../assets/img/svg/icons8-edit1.svg";
import trashIcon from "../../../assets/img/svg/icons8-trash1.svg";

const PaymentDetailCard = (props) => {
  const {
    imgUrl,
    letter,
    data,
    ondelete,
    onedit,
    parent_id,
    isDelete,
    isEdit,
  } = props;

  return (
    <Row
      id={`${parent_id}_addresslist_parent_row`}
      className="sl-root"
      style={{ marginBottom: "18px !important" }}
    >
      <Col
        id={`${parent_id}_addresslist_parent_col`}
        md={11}
        lg={9}
        sm={6}
        xs={12}
        className="sl-detail-root"
      >
        <Div id={`${parent_id}_addresslist_sub_div`} className="sl-count">
          <Text
            id={`${parent_id}_addresslist_text`}
            className="pc_medium"
            style={{
              color: "#101010",
            }}
          >
            {props?.index}
          </Text>
        </Div>
        <Div
          id={`${parent_id}_addresslist_sub_parent_div`}
          className={imgUrl || letter ? "sl-details" : "sl-detail"}
          style={{ display: "flex" }}
        >
          {(imgUrl || letter) && (
            <Avatar
              id={`${parent_id}_addresslist_letter_avatar`}
              className="sl-details-avatar"
              src={imgUrl}
              letter={letter}
            />
          )}
          <Div style={{ display: "flex", alignItems: "center" }}>
            <Div
              id={`${parent_id}_addresslist_name_div`}
              className="sl-details-names"
            >
              <H6 id={`${parent_id}_addresslist_reciver_h6`}>
                {data?.online_transfer?.value ??
                  data?.cash?.value ??
                  data?.cheque?.value ??
                  data?.credit_card?.value ??
                  data?.debit_card?.value??
                  data?.advance?.value}
                &nbsp;&nbsp;&nbsp;:
              </H6>
            </Div>
            <Div
              id={`${parent_id}_addresslist_date_format_div`}
              style={{
                marginLeft: "30px",
              }}
            >
              <Text
                id={`${parent_id}_addresslist_membership_text`}
                style={{
                  color: "#6F6F6F",
                  fontSize: 16,
                }}
              >
                {data?.totalPayAmount ?? " "}
              </Text>
            </Div>
          </Div>
        </Div>
      </Col>
      <Col
        id={`${parent_id}_addresslist_image_div`}
        md={1}
        lg={3}
        sm={6}
        xs={12}
        className="sl-action-root"
      >
        <Div
          id={`${parent_id}_addresslist_edit_delete_div`}
          style={{ display: "flex", alignItems: "center" }}
        >
          {/* {!props?.IsViewMode && isEdit && ( */}
          <Image
            id={`${parent_id}_addresslist_edit_image`}
            onClick={(e) => onedit(data, props.index)}
            style={{ cursor: "pointer" }}
            src={editIcon}
            alt="edit"
          />
          {/* )} */}
          {/* {!props?.IsViewMode&&isDelete && ( */}
          <Image
            id={`${parent_id}_addresslist_trash_image`}
            onClick={() => ondelete(data, props.index)}
            style={{ cursor: "pointer", marginLeft: 15 }}
            src={trashIcon}
            alt="delete"
          />
          {/* )} */}
        </Div>
      </Col>
    </Row>
  );
};

export default PaymentDetailCard;