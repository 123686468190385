import React from 'react';
import { H6, Div } from "qdm-component-library";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const styles = {
  rowCenter: {
    display: "flex",
    alignItems: "center",
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    marginRight: 15,
  },
  h6: {
    fontSize: 12,
    color: "#101010",
    margin: 0,
  },
  card: {
    boxShadow: "0px 10px 25px #0000000A",
    border: "1px solid #E0E0E0",
    borderRadius: 8,
    padding: 12,
    width: "100%",
  },
};
export const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

const PieChart = (props) => {
  return (
    <Div>
      {props?.label ? (
        <H6
          // id={`${parent_id}_doughnuchart_label_h6`}
          className="pc_medium"
          style={{ marginBottom: 20, fontSize: 14 }}
        >
          {props?.label}
        </H6>
      ) : null}
      <Div style={styles.rowCenter}>
        <div
          // id={`${parent_id}_doughnuchart_date_options_div`}
          style={{ position: "relative" }}
        >
          <Pie data={data} />
        </div>
        <Div
          // id={`${parent_id}_doughnuchart_map_div`}
          style={{ padding: "0px 20px", width: "100%" }}
        >
          {data &&
            data?.labels?.map((l, i) => {
              return (
                <Div
                  // id={`${parent_id}_${i}_doughnuchart_label_div`}
                  style={{
                    ...styles.rowCenter,
                    marginBottom: i < data?.labels?.length - 1 ? 10 : 0,
                    cursor:'pointer',
                  }}
                  // onClick={()=>onClickLables(l,i)}
                >
                  <Div
                    // id={`${parent_id}_${i}_doughnuchart_sub_div`}
                    style={{ ...styles.rowCenter, flex: 1, marginRight: 15 }}
                  >
                    <Div
                      // id={`${parent_id}_${i}_doughnuchart_sublevel_div`}
                      style={{
                        ...styles.dot,
                        backgroundColor:
                          data?.datasets[0]?.backgroundColor?.[i] ??
                          "#000",
                      }}
                    />
                    <H6
                      // id={`${parent_id}_${i}_doughnuchart_label_h6`}
                      className="pc_regular"
                      style={{ ...styles.h6, flex: 1 }}
                    >
                      {l}
                    </H6>
                  </Div>
                  <H6
                    // id={`${parent_id}_${i}_doughnuchart_label_data_h6`}
                    className="pc_semibold" style={styles.h6}>
                    {data?.datasets[0]?.data?.[i] + "" ?? " "}
                  </H6>
                </Div>
              );
            })}
        </Div>
      </Div>
    </Div>
  );
}
export default PieChart;
