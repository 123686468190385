import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Table,
  Paper,
  TableBody,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Text,
  Select,
  TextInput,
  Checkbox,
  Button,
} from "qdm-component-library";
import React, { useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
// import { actions } from "primarycare-binder";
import actions from "../../../../redux/actions"
import { Alerts } from "../../../../components";
import { UIColor } from "../../../../themes/theme";

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  tableHeader: {
    fontSize: "14px",
    color: UIColor.primaryText.color,
  },
  tableBody: {
    padding: "12px",
    fontFamily: "poppinsemibold",
    fontSize: "12px",
    padding: 10,
  },
  outerMargin: {
    margin: "15px 20px",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: "8px",
  },
  innerHeading: { backgroundColor: UIColor.lineBorderFill.color, padding: "12px 15px" },
  tableContainer: {
    border: "1px solid #e4e4e4",
    boxShadow: "none",
    margin: "15px 0",
  },
});

const styles = {
  textStyles: {
    fontWeight: 600,
    fontSize: "12px",
  },
  headerStyles: {
    fontWeight: 600,
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
    background: "#fff",
  },
};

const header = ["", "Age Range", "Dose range / Day check"];
const dataTabs = [
  { SNo: "Default Range", age: "12 Years - 110 Years", dose: "250-2000mg" },
  { SNo: "Prescribed", age: "25 Years", dose: "15000mg" },
];

const alert = [
  " Penicillin V Potassium Tab contains Penicillin V Potassium.",
  "This patient’s profile indicates Penicillin V Potassium as an allergen.",
  " Penicillin V Potassium Tab poses the risk of causing an ingredient-based allergic reaction in this patient.",
];

function AlleryDrugModel(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addForm, data } = props;

  const allergyAlerts = props.interationsAlert;
  const alerts = allergyAlerts?.alertMessage?.["Drug-AllergyIngredient-Interaction"]
  const alertReasonCode = allergyAlerts?.reasonCode
  const reasonList = useSelector(
    (state) => state?.MedicationMastersSlice.Indication_Reason_Code_masters.data
  );
  const alertDataProps = { ...data, addForm,alerts,alertReasonCode };

  const handleContinue = async () => {
    const uploadAlerts = await dispatch(
      actions.ALERT_INTERACTIONS_UPERT({ list: alertDataProps })
    );
    props.AddMedication();
    props.closeSecondary();
  };

  return (
    <div>
      <div style={{ borderBottom: `1px solid ${UIColor.lineBorderFill.color}`, padding: "15px 20px" }}>
        <Text inLineStyles={styles.headerStyles}>
          Instructions and Intraction Checking
        </Text>
      </div>
      <div className={classes.outerMargin}>
        <div className={classes.innerHeading}>
          <Text inLineStyles={styles.textStyles}>
            DRUG TO ALLERGY INTERACTIONS
          </Text>
        </div>
        <div style={{ padding: "10px" }}>
          <Text inLineStyles={{ lineHeight: 1.5, color: UIColor.primaryColor.color }}>
            Incredient Incredient Check (Severity: Severe)
          </Text>
          <br />
          <Text inLineStyles={{ lineHeight: 1.5, fontWeight: "600" }}>
            Alert
          </Text>
          <br />
          <div style={{paddingBottom:"10px"}}>
            {allergyAlerts?.alertMessage?.["Drug-AllergyIngredient-Interaction"].map(
              (val, i) => (
                <div style={{paddingBottom:'5px'}}>
                  <Text inLineStyles={{ color: UIColor.secondaryText.color }}>
                    {i + 1}. {val.Message}
                  </Text>
                  <br />
                </div>
              )
            )}
          </div>
          {/* <TableContainer component={Paper} className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-label="simple table"
              size="small"
            >
              <TableHead style={{ background: "#F9F9F9" }}>
                <TableRow>
                  {header.map((val, i) => {
                    return (
                      <TableCell className={classes.tableHeader}>
                        {val}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTabs.map((val, i) => {
                  return (
                    <TableRow>
                      <TableCell>{val.SNo}</TableCell>
                      <TableCell>{val.age}</TableCell>
                      <TableCell>{val.dose}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer> */}

          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Select
                value={addForm?.reason?.value ?? ""}
                onChangeValue={(e) => props.handleAddForm("reason", e)}
                options={reasonList.map((val) => {
                  return {
                    label: val.value,
                    value: val.label,
                  };
                })}
                label="Suggested Reason"
                showArrow
                labelStyle={styles.labelStyle}
                placeholder={false}
                inLineStyles={styles.borderStyle}
                hoverColor={UIColor.lineBorderFill.color}
              />
            </Grid>
            <Grid item xs={7}>
              <TextInput
                value={addForm?.Others ?? ""}
                onChange={(e) => props.handleAddForm("Others", e.target.value)}
                label="Others"
                placeholder={false}
                labelStyle={styles.labelStyle}
                style={styles.borderStyle}
                hoverColor={UIColor.lineBorderFill.color}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ display: "flex", padding: "0 15px 15px" }}>
        <div>
          <Checkbox
            color={UIColor.primaryColor.color}
            inLineStyles={{ marginTop: 10 }}
            checked={addForm?.agree}
            label={"Yes, I read the above messages"}
            name={"Yes, I read the above messages"}
            size="small"
            value={addForm?.agree}
            onChange={(value) => props.handleAddForm("agree", !addForm?.agree)}
          />
        </div>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ display: "flex" }}>
          <Button
            className="pc_medium"
            type="button"
            name={"Cancel"}
            variant="outlined"
            color={UIColor.primaryColor.color}
            size={14}
            backgroundColor="#fff"
            onClick={() => props.closeSecondary()}
            inLineStyles={{
              width: "100%",
              borderRadius: 8,
              fontSize: 13,
              border: `1px solid ${UIColor.primaryColor.color}`,
              marginRight: "10px",
            }}
          />
          <Button
            className="pc_medium"
            type="button"
            name={"Continue"}
            variant="outlined"
            size={14}
            color={"#fff"}
            backgroundColor={UIColor.primaryColor.color}
            onClick={handleContinue}
            inLineStyles={{
              width: "100%",
              borderRadius: 8,
              border: `1px solid #fff`,
              fontSize: 13,
            }}
            disabled={addForm?.agree === false ? true : false}
          />
        </div>
      </div>
    </div>
  );
}

export default AlleryDrugModel;
