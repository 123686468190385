/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React from "react";
import { Row, Col, Text, Div } from "qdm-component-library";
import NoActions from "../../assets/img/noActionsItems.svg";
import TreatmentProcedure from "./treatmentProcedureFlow/treatmentProcedure";
import LabOrder from "./labOrderFlow/labOrder";
import { Grid } from "@material-ui/core";
import MedicationOrderFrom from "./medicationFlows/medicationOrderFrom";
import { Dialysis } from "./dialysis/dialysis";
import ProgressNotes from "./progressNotes";
import TreatmentPlan from "./treatmentPlan/treatmentPlan";
import NursingProcedure from "./nursingProcedure/nursingProcedure";
import VitalSigns from "./vitalSigns";
import Allergy from "./allergyFlows/allergy";
import Diagnosis from "./diagnosisFlow/diagnosis";
import CurrentMedication from "../../components/currentMedication";
import PackageOrder from "./packageOrder/packageOrder";
import ReferalRegOut from "./referalRegOut/referalRegOut";

export const ActionItems = (props) => {
  console.log("action item props", props);
  return (
    <Row inLineStyles={{ paddingLeft: "20px 20px 20px 20px" }}>
      {!props.showTitle && (
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ padding: "12px" }}
        >
          <Grid container style={{ gap: "10px" }}>
            <Grid>
              <Text inLineStyles={{ fontWeight: 600, fontSize: "18px" }}>
                ACTION ITEMS
              </Text>
            </Grid>
            <Grid container style={{ gap: "10px" }}>
            <ReferalRegOut
                dialysisData={props?.dialysisData}
                patientgetData={props?.patientgetData}
                getDialysisData={props?.getDialysisData}
                orgdetails={props?.orgdetails}
                treatmentplan={props?.treatmentplan}
                location={props?.locationdata}
                setTreatmentplan={props?.setTreatmentplan}
                history={true}
                referralId={props?.referralId}
                encData={props?.editEncData}
              />
              <TreatmentPlan
                dialysisData={props?.dialysisData}
                patientgetData={props?.patientgetData}
                getDialysisData={props?.getDialysisData}
                orgdetails={props?.orgdetails}
                treatmentplan={props?.treatmentplan}
                location={props?.locationdata}
                setTreatmentplan={props?.setTreatmentplan}
                history={true}
                encData={props?.editEncData}
              />
              <CurrentMedication
                isEdit={props?.isEdit}
                oneditClick={props?.oneditClick}
                handleSave={props?.handleSave}
                currentcancel={props?.currentcancel}
                handlecurrentmedicationval={props?.handlecurrentmedicationval}
                currentMedicationValue={props?.currentMedicationValue}
                allNotes={props?.allNotes}
                encData={props?.editEncData}
              />
              <ProgressNotes
                patientgetData={props.patientgetData}
                progressNotesData={props?.progressNotesData}
                encData={props?.editEncData}
              />
              <Dialysis
                dialysisData={props?.dialysisData}
                patientgetData={props.patientgetData}
                getDialysisData={props?.getDialysisData}
                orgdetails={props?.orgdetails}
                saveTreatment={props?.saveTreatment}
                treatmentPlan={props?.treatmentplan}
                setTreatmentplan={props?.setTreatmentplan}
                location={props?.locationdata}
                encData={props?.editEncData}
              />

              <LabOrder
                saveLabOrders={props?.saveLabOrders}
                onEditChart={props?.onEditChart}
                patientgetData={props.patientgetData}
                encData={props?.editEncData}
              />
              <NursingProcedure
                saveNursingProcedure={props?.saveNursingProcedure}
                onEditChart={props?.onEditChart}
                patientgetData={props.patientgetData}
                encData={props?.editEncData}
              />
              <PackageOrder
                savePackageOrder={props?.savePackageOrder}
                onEditChart={props?.onEditChart}
                patientgetData={props.patientgetData}
                encData={props?.editEncData}
              />

             <VitalSigns Encounterdata={props?.patientgetData}  onRegistration={false}/>

             <Allergy
                saveAllergy={props?.saveAllergy}
                onEditChart={props?.onEditChart}
                handleClose={"props.handleClose"}
                Encounterdata={props?.patientgetData}
                onRegistration={false}
              />

              <Diagnosis
                parent_id={"patientchart"}
                saveDiagnosis={props?.saveDiagnosis}
                onEditChart={props?.onEditChart}
                handleClose={"props.handleClose"}
                Encounterdata={props?.patientgetData}
                onRegistration={false}
              />

            </Grid>
          </Grid>
        </Col>
      )}
    </Row>
  );
};
