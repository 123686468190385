import React from "react";
import Paper from "@material-ui/core/Paper";
import { useStyles } from "./style";

function StyledPaper(props) {
  const classes = useStyles();
  return <Paper classes={classes} {...props} />;
}

export default StyledPaper;
