import React from "react";

export const BloodGroup = (props) => {
  let width = props.width ? props.width : "13";
  let height = props.height ? props.height : "15";
  //   let color = props.color ? props.color : "#000000";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17.07 22.312"
    >
      <path
        id="icons8-blood_group"
        data-name="icons8-blood group"
        d="M17.21,4a.837.837,0,0,0-.667.364S14.9,6.772,13.124,9.627,9.462,15.682,8.9,17.721a6.089,6.089,0,0,0,2.029,6.107,9.517,9.517,0,0,0,6.308,2.483,9.517,9.517,0,0,0,6.308-2.483,6.089,6.089,0,0,0,2.029-6.107c-.565-2.039-2.455-5.24-4.227-8.095s-3.419-5.263-3.419-5.263A.837.837,0,0,0,17.21,4Zm.024,2.373c.48.71,1.3,1.895,2.688,4.137a43.443,43.443,0,0,1,4.036,7.659A4.329,4.329,0,0,1,22.409,22.6a8,8,0,0,1-5.175,2.042A8,8,0,0,1,12.059,22.6a4.329,4.329,0,0,1-1.549-4.429,43.444,43.444,0,0,1,4.036-7.659C15.938,8.267,16.754,7.083,17.234,6.373Zm-4.2,11a.837.837,0,0,0-.808.684,3.825,3.825,0,0,0,1.2,3.81,5.525,5.525,0,0,0,3.528,1.1.837.837,0,1,0,0-1.673,4.064,4.064,0,0,1-2.469-.717,1.955,1.955,0,0,1-.616-2.187.837.837,0,0,0-.832-1.013Z"
        transform="translate(-8.699 -3.999)"
        fill="#ff4d4a"
      />
    </svg>
  );
};
