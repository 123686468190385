import React from "react";

export const TreatmentProcedure1 = (props) => {
  let width = props.width ? props.width : "17.172";
  let height = props.height ? props.height : "15.884";
  let color = props.color ? props.color : "#101010";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38.56 31.703"
    >
      <g id="treatment" transform="translate(-5 -13.125)">
        <path
          id="Path_92924"
          data-name="Path 92924"
          d="M41.827,55.138H19.353a4.238,4.238,0,0,1-1.031-.129L9.34,51.829a1.946,1.946,0,0,0-.688-.129H5.559v.043A.634.634,0,0,0,5,52.431V66.1a.724.724,0,0,0,.731.731h1.59a.724.724,0,0,0,.731-.731V63.131a.339.339,0,0,1,.344-.344H40.152a.339.339,0,0,1,.344.344V66.1a.724.724,0,0,0,.731.731h1.59a.724.724,0,0,0,.731-.731v-9.11A1.659,1.659,0,0,0,41.827,55.138Z"
          transform="translate(0 -21.998)"
          fill="#ec6a49"
        />
        <circle
          id="Ellipse_129197"
          data-name="Ellipse 129197"
          cx="3.223"
          cy="3.223"
          r="3.223"
          transform="translate(7.492 22.053)"
          fill="#001c3c"
        />
        <path
          id="Path_92925"
          data-name="Path 92925"
          d="M27.514,42.517l-2.535,2.063h-.043a1.908,1.908,0,0,0-.473.344l-1.2.988a.271.271,0,0,0,.086.473l6.016,2.235.129.043H50.461a.294.294,0,0,0,.3-.3V44.794a.294.294,0,0,0-.3-.3H33.916a.158.158,0,0,1-.129-.043l-5.329-2.106A1.07,1.07,0,0,0,27.514,42.517Z"
          transform="translate(-10.352 -16.64)"
          fill="#001c3c"
        />
        <path
          id="Path_92926"
          data-name="Path 92926"
          d="M57.343,14.146l-2.02-.988a.471.471,0,0,0-.344,0L53,14.146a.34.34,0,0,0-.215.344v.258a3.921,3.921,0,0,0-.988,2.879v2.063a.314.314,0,0,0,.258.3,9.942,9.942,0,0,0,2.149.258,3.717,3.717,0,0,0,.43.3,1.1,1.1,0,0,0,1.031,0,2.609,2.609,0,0,0,.43-.3,9.942,9.942,0,0,0,2.149-.258.369.369,0,0,0,.258-.3V17.626a3.75,3.75,0,0,0-.988-2.879v-.258C57.515,14.317,57.472,14.189,57.343,14.146Zm-4.082.344.816-.387,1.117-.559,1.117.559.816.387v1.289a11.782,11.782,0,0,0-1.375-.3,3.653,3.653,0,0,0-1.117,0,7.445,7.445,0,0,0-1.375.3V14.489Zm2.492,5.586-.043.043c-.043.043-.129.086-.172.129H55.5a.734.734,0,0,1-.6,0h-.043c-.086-.043-.129-.086-.215-.129l-.043-.043a5.012,5.012,0,0,1-1.719-2.664l1.719-1.074.559-.387.559.387,1.719,1.074A4.752,4.752,0,0,1,55.753,20.076Z"
          transform="translate(-26.689 0)"
          fill="#001c3c"
        />
        <path
          id="Path_92927"
          data-name="Path 92927"
          d="M58.573,15.8v.387a.042.042,0,0,0,.043.043h.3a.042.042,0,0,0,.043-.043V15.8A.042.042,0,0,1,59,15.759h.387a.042.042,0,0,0,.043-.043v-.3a.042.042,0,0,0-.043-.043H59a.042.042,0,0,1-.043-.043v-.387a.042.042,0,0,0-.043-.043h-.3a.042.042,0,0,0-.043.043v.43a.042.042,0,0,1-.043.043h-.387a.042.042,0,0,0-.043.043v.3a.042.042,0,0,0,.043.043Z"
          transform="translate(-30.282 -1.012)"
        />
        <path
          id="Path_92928"
          data-name="Path 92928"
          d="M47.5,34.9v2.278a.816.816,0,0,0,.816.816h8.809a.816.816,0,0,0,.816-.816V34.9a1.783,1.783,0,0,0-1.117-1.633l-2.707-.988c-.086-.043-.172,0-.172.086l-1.2,3.481c0,.043-.086.043-.086,0l-1.117-3.481c-.043-.086-.129-.129-.172-.086l-2.707.988A1.664,1.664,0,0,0,47.5,34.9Z"
          transform="translate(-24.237 -10.913)"
          fill="#001c3c"
        />
      </g>
    </svg>
  );
};
