import moment from "moment";
import { makeName, utcTOLocal, getUtcTime } from "../../../utils";
//from "../../../helpers";
export const generateResJson = (dataList, arr) => {
  const list = dataList.map((val) => {
    
    const diagnosisData = val?.diagnosis?.map((v) => {
      return {
        label: v?.codeStandard,
        value: v?.code,
      };
    });

    const getData = (date) => {
      let da = date ? new Date(date) : new Date();
      let d = new Date(da).toDateString().split(" ");
      return `${d[2]} ${d[1]}, ${d[3]}`;
    };
    const performerDetailsData = val?.performer?.map((v) => {
      if (v?.organization && v?.locationRoleType) {
        return {
          practitionerId: v?.practId,
          organization: {
            value: v?.organization?.name,
            label: v?.organization?._id,
          },
          status: { value: v?.actionType?.display, label: v?.actionType?._id },
          performedBy: {
            label: v?.practId?.[0]?.name,
            value: v?.practId?.[0]?._id,
            practitioner_id: v?.practId?.[0]?._id,
          },
          practitionerRole: {
            label: v?.practRole?.display,
            value: v?.practRole?.display,
            _id: v?.practRole?._id,
          },
          location: {
            value: v?.locationRoleType?.longdesc,
            label: v?.locationRoleType?._id,
          },
          locationCode: v?.locationCode,
        };
      }
    });
    var a = val?.recurrent?.[0]?.startes_on_occurance_date;

    return {
      priority: {
        label: val?.priority?.display ?? "",
        value: val?.priority?._id ?? "",
      },
      nature: {
        label: val?.OrderNature?.display ?? "",
        value: val?.OrderNature?._id ?? "",
      },
      dateTime: utcTOLocal(val?.occuranceStartDate, "YYYY-MM-DD,hh:mm A") ?? "",
      adminDateTime: val?.admindate ? utcTOLocal(val?.admindate, "YYYY-MM-DD,hh:mm A") ?? "" : "",
      endDate: val?.recurrent?.[0]?.startes_on_occurance_date
        ? utcTOLocal(
            val?.recurrent?.[0]?.startes_on_occurance_date,
            "YYYY-MM-DD,hh:mm A"
          ) ?? ""
        : val?.occuranceEndDate > 0
        ? utcTOLocal(val?.occuranceEndDate, "YYYY-MM-DD,hh:mm A")
        : "",
      childRecurrent: val?.childRecurrent
        ? RecurrentgenerateResJson(val?.childRecurrent)
        : null,
      recurrent: false,
      headerShow: true,
      recurrentData: {
        every: {
          value: JSON.stringify(val?.recurrent?.[0]?.every_date) ?? "",
          periodicity: {
            value: val?.recurrent?.[0]?.every_days ?? "",
            label: val?.recurrent?.[0]?.every_days ?? "",
          },
        },
        startsOn: {
          dateTime:
            utcTOLocal(val?.recurrent?.[0]?.starts_on, "YYYY-MM-DD,hh:mm A") ??
            "",
        },
        endsAfter: {
          endsAfter:
            JSON.stringify(val?.recurrent?.[0]?.end_on_occurance) ?? "",
          endsBy: val?.recurrent?.[0]?.end_on_occurance_date
            ? utcTOLocal(
                val?.recurrent?.[0]?.end_on_occurance_date,
                "YYYY-MM-DD,hh:mm A"
              ) ?? ""
            : "",
        },
      },

      orderType: {
        label: val?.OrderType?.longdesc ?? "",
        value: val?.OrderType?._id ?? "",
      },
      mode: {
        label: val?.orderMode?.display ?? "",
        value: val?.orderMode?._id ?? "",
      },
      startDate: utcTOLocal(val?.orderDateTime, "YYYY-MM-DD,hh:mm A") ?? "",
      orderCatalog: {
        label: val?.orderCatalog ?? "",
        value: val?.orderCatalog ?? "",
        _id: val?.orderCatalogId ?? "",
      },
      qty: JSON.stringify(val?.orderQty) ?? "",
      requestorDetails: {
        organization: {
          value: val?.Requestor?.[0]?.organization?.name ?? "",
          label: val?.Requestor?.[0]?.organization?._id ?? "",
          id: val?.Requestor?.[0]?.organization?.id ?? "",
        },
        practitionerRole: {
          value: val?.Requestor?.[0]?.practRole?.display ?? "",
          label: val?.Requestor?.[0]?.practRole?._id ?? "",
          id: val?.Requestor?.[0]?.practRole?.id ?? "",
          _id: val?.Requestor?.[0]?.practRole?._id ?? "",
        },
        practitionerId: val?.Requestor?.[0]?.practId?.[0]?.name?.[0] ?? "",
        orderBy: {
          value: val?.Requestor?.[0]?.practId?.[0]?.id ?? "",
          practitioner_id:
            val?.Requestor?.[0]?.practId?.[0]?.practitioner_id ?? "",
          label:
            makeName(val?.Requestor?.[0]?.practId?.[0]?.name?.[0] || {}) ?? "",
        },
      },
      indication: {
        label: val?.indications ?? "",
        value: val?.reasonCode?._id ?? "",
      },
      selectDiagnosis: diagnosisData
        ? [...diagnosisData]
        : { label: "", value: "" },
      performerDetails: [...(performerDetailsData || [])],
      specimenRelated: {
        reqd: true,
        specimenType: {
          value: val?.specimenRelated[0]?.type?.display ?? "",
          label: val?.specimenRelated[0]?.type?._id ?? "",
        },
        volume: val?.specimenRelated[0]?.volume ?? "",
        volumeUOM: {
          value: val?.specimenRelated[0]?.volumeUOM?.display ?? "",
          label: val?.specimenRelated[0]?.volumeUOM?._id ?? "",
        },
        collectionMethod: {
          value: val?.specimenRelated[0]?.collectionMethod?.display ?? "",
          label: val?.specimenRelated[0]?.collectionMethod?._id ?? "",
        },
        container: "string",
        bodySite: {
          value: val?.specimenRelated[0]?.bodySite?.display ?? "",
          label: val?.specimenRelated[0]?.bodySite?._id ?? "",
        },
        FastingPeriod: val.specimenRelated[0]?.FastingPeriod ?? 0,
        fastingUom: {
          value: val?.specimenRelated[0]?.FastingPeriodUOM?.display ?? "",
          label: val?.specimenRelated[0]?.FastingPeriodUOM?._id ?? "",
        },
      },
      notes: val?.notes ?? "",
      patientInstruction: {
        label: val?.patientInstruction?.[0]?.code ?? "",
        value: val?.patientInstruction?.[0]?.code ?? "",
        content: val?.patientInstruction?.[0]?.description ?? "",
      },
      preparatoryInstruction: {
        label: val?.preparatoryInstruction?.[0]?.code ?? "",
        value: val?.preparatoryInstruction?.[0]?.code ?? "",
        content: val?.preparatoryInstruction?.[0]?.description ?? "",
      },
      departmentalInstruction: {
        label: val?.departmentalInstruction?.[0]?.code ?? "",
        value: val?.departmentalInstruction?.[0]?.code ?? "",
        content: val?.departmentalInstruction?.[0]?.description ?? "",
      },
      attributes: {
        view: {
          label: val?.attribute?.[0]?.view ?? "",
          value: val?.attribute?.[0]?.view ?? "",
        },
        bedSide: val.attribute?.[0]?.bedSide ?? false,
        contrast: val.attribute?.[0]?.contrast ?? "",
      },
      // createdAt: getData(),
      createdAt: val?.orderDateTime ?? "",

      editData: val._id ?? "",
      statusData: {
        data: val?.changeStatus?.dateTime ?? "",
        status: {
          value: val?.changeStatus?.reason ?? "",
          label: val?.changeStatus?.reason ?? "",
        },
        reason: val?.reason ?? "",
      },
      statushistory: val?.statushistory ?? [],
      statusType: val?.statusreason ?? "",
      userName: val?.updatedby?.name?.[0]?.fulname ?? "",
      resulturl: val?.resulturl ?? "",
      queuestatus: val?.queuestatus,
      billstatus : val?.billstatus,
      recurrance: val?.recurrance,
      packdetails: val?.packdetails,
      supply: val?.supply,
    };
  });

  return list;
};

export const RecurrentgenerateResJson = (dataList, arr) => {
  dataList = dataList.filter(function (el) {
    return el != null;
  });
  const list = dataList.map((val) => {
    const diagnosisData = val?.diagnosis?.map((v) => {
      return {
        label: v?.codeStandard,
        value: v?.code,
      };
    });
    const getData = (date) => {
      let da = date ? new Date(date) : new Date();
      let d = new Date(da).toDateString().split(" ");
      return `${d[2]} ${d[1]}, ${d[3]}`;
    };
    const performerDetailsData = val?.performer?.map((v) => {
      if (v?.organization && v?.locationRoleType) {
        return {
          practitionerId: v?.practId,
          organization: { label: v?.organization, value: v?.organization },
          status: { label: v?.actionType, value: v?.actionType },
          performedBy: { label: v?.practType, value: v?.practType },
          practitionerRole: { label: v?.practRole, value: v?.practRole },
          location: { label: v?.locationRoleType, value: v?.locationRoleType },
          locationCode: v?.locationCode,
        };
      }
    });
    var a = val?.recurrent?.[0]?.startes_on_occurance_date;
    return {
      priority: { label: val?.priority ?? "" },
      nature: { label: val?.OrderNature ?? "" },
      dateTime: utcTOLocal(val?.occuranceStartDate, "YYYY-MM-DD,hh:mm A") ?? "",
      endDate:
        val?.occuranceEndDate > 0
          ? utcTOLocal(val?.occuranceEndDate, "YYYY-MM-DD,hh:mm A")
          : "",
      recurrent: false,
      headerShow: true,
      recurrentData: {
        every: {
          value: JSON.stringify(val?.recurrent?.[0]?.every_date) ?? "",
          periodicity: {
            value: val?.recurrent?.[0]?.every_days ?? "",
            label: val?.recurrent?.[0]?.every_days ?? "",
          },
        },
        startsOn: {
          dateTime:
            utcTOLocal(val?.recurrent?.[0]?.starts_on, "YYYY-MM-DD,hh:mm A") ??
            "",
        },
        endsAfter: {
          endsAfter:
            JSON.stringify(val?.recurrent?.[0]?.startes_on_occurance) ?? "",
          endsBy: val?.recurrent?.[0]?.startes_on_occurance_date
            ? utcTOLocal(
                val?.recurrent?.[0]?.startes_on_occurance_date,
                "YYYY-MM-DD,hh:mm A"
              ) ?? ""
            : "",
        },
      },

      orderType: {
        label: val?.OrderType,
      },
      mode: {
        label: val?.orderMode,
      },
      startDate:
        utcTOLocal(val?.occuranceStartDate, "YYYY-MM-DD,hh:mm A") ?? "",
      orderCatalog: {
        label: val?.orderCatalog,
        value: val?.orderCatalog,
      },
      qty: JSON.stringify(val?.orderQty),
      requestorDetails: {
        organization: {
          label: val?.Requestor?.[0]?.organization?._id,
          value: val?.Requestor?.[0]?.organization?.name,
          id: val?.Requestor?.[0]?.organization?.id,
        },
        practitionerRole: {
          label: val?.Requestor?.[0]?.practRole?.display ?? "",
          value: val?.Requestor?.[0]?.practRole?.display ?? "",
          id: val?.Requestor?.[0]?.practRole?.id,
          _id: val?.Requestor?.[0]?.practRole?._id,
        },
        practitionerId: val?.Requestor?.[0]?.practId?.[0]?.name?.[0],
        orderBy: {
          value: val?.Requestor?.[0]?.practId?.[0]?.id,
          practitioner_id: val?.Requestor?.[0]?.practId?.[0]?.practitioner_id,
          label: makeName(val?.Requestor?.[0]?.practId?.[0]?.name?.[0] || {}),
        },
      },
      indication: {
        label: val?.indications,
        value: val?.reasonCode,
      },
      selectDiagnosis: [...diagnosisData],
      performerDetails: [...(performerDetailsData || [])],
      specimenRelated: {
        reqd: true,
        specimenType: {
          label: val?.specimenRelated?.[0]?.type ?? "",
          value: val?.specimenRelated?.[0]?.type ?? "",
        },
        volume: val?.specimenRelated?.[0]?.volume ?? "",
        volumeUOM: {
          label: val?.specimenRelated?.[0]?.volumeUOM ?? "",
          value: val?.specimenRelated?.[0]?.volumeUOM ?? "",
        },
        collectionMethod: {
          label: val?.specimenRelated?.[0]?.collectionMethod ?? "",
          value: val?.specimenRelated?.[0]?.collectionMethod ?? "",
        },
        container: "string",
        bodySite: {
          label: val?.specimenRelated?.[0]?.bodySite ?? "",
          value: val?.specimenRelated?.[0]?.bodySite ?? "",
        },
        FastingPeriod: val.specimenRelated?.[0]?.FastingPeriod ?? 0,
        fastingUom: {
          label: val?.specimenRelated?.[0]?.FastingPeriodUOM ?? "",
          value: val?.specimenRelated?.[0]?.FastingPeriodUOM ?? "",
        },
      },
      notes: val?.notes ?? "",
      patientInstruction: {
        label: val?.patientInstruction?.[0]?.code ?? "",
        value: val?.patientInstruction?.[0]?.code ?? "",
        content: val?.patientInstruction?.[0]?.description ?? "",
      },
      preparatoryInstruction: {
        label: val?.preparatoryInstruction?.[0]?.code ?? "",
        value: val?.preparatoryInstruction?.[0]?.code ?? "",
        content: val?.preparatoryInstruction?.[0]?.description ?? "",
      },
      departmentalInstruction: {
        label: val?.departmentalInstruction?.[0]?.code ?? "",
        value: val?.departmentalInstruction?.[0]?.code ?? "",
        content: val?.departmentalInstruction?.[0]?.description ?? "",
      },
      attributes: {
        view: {
          label: val?.attribute?.[0]?.view ?? "",
          value: val?.attribute?.[0]?.view ?? "",
        },
        bedSide: val.attribute?.[0]?.bedSide ?? false,
        contrast: val.attribute?.[0]?.contrast ?? "",
      },
      createdAt: val?.orderDateTime ?? "",
      // createdAt: getData(),
      editData: val._id ?? "",
      statusData: {
        data: val?.changeStatus?.dateTime ?? "",
        status: {
          value: val?.changeStatus?.reason ?? "",
          label: val?.changeStatus?.reason ?? "",
        },
        reason: val?.reason ?? "",
      },
      statushistory: val?.statushistory ?? [],
      statusType: val?.statusreason ?? "",
      status: val?.status ?? "",
      parentOrderId: val?.parentOrderId ?? "",
      apptDate: val?.apptDate ?? "",
      userName: val?.accessionNum ?? "",
    };
  });
  return list;
};

export const ccgenerateResJson = (dataList, state) => {
  const list = dataList.map((val) => {
    console.log(val, "ccgeneratejson");
    let clinicalstatushistory = val?.clinicalstatushistory?.map((val) => {
      return {
        ...val,
        createdAt: getUtcTime(val?.start ? val?.start : new Date()),
        personName: val?.Person?.[0]?.name?.text ?? "",
      };
    });
    const createddateInt = val?.createddate ?? Date.now();
    const updatedateInt = val?.updatedate ?? Date.now();
    return {
      complaint: val.patient_complaint,
      clinicalstatushistory: clinicalstatushistory,
      name: val?.code,
      bodySite: val?.bodysite,
      severity: val?.severity,
      assertedBy: {
        value: val?.assertedby,
        label: val?.assertedby,
      },
      relationName: val?.relatedperson_id,
      onset: {
        day: val?.onset?.[0]?.onsetperiod ? val?.onset?.[0]?.onsetdatetime : "",
        freq: val?.onset?.[0]?.onsetperiod
          ? {
              label: val?.onset?.[0]?.onsetperiod,
              value: val?.onset?.[0]?.onsetperiod,
            }
          : {},
        currentDate:
          val?.onset?.[0]?.onsetdatetime && !val?.onset?.[0]?.onsetperiod
            ? utcTOLocal(val?.onset?.[0]?.onsetdatetime, "DD MMM, YYYY")
            : "",
        isCalenderEnabled: val?.onset?.[0]?.onsetperiod
          ? false
          : val?.onset?.[0]?.onsetdatetime
          ? true
          : false,
        calenderIconEnabled: val?.onset?.[0]?.onsetperiod ? false : true,
      },
      abatement: {
        day: val?.abatement?.[0]?.abatementperiod
          ? val?.abatement?.[0]?.abatementdatetime
          : "",
        freq: val?.abatement?.[0]?.abatementperiod
          ? {
              label: val?.abatement?.[0]?.abatementperiod,
              value: val?.abatement?.[0]?.abatementperiod,
            }
          : {},
        currentDate:
          !val?.abatement?.[0]?.abatementperiod &&
          val?.abatement?.[0]?.abatementdatetime
            ? utcTOLocal(val?.abatement?.[0]?.abatementdatetime, "DD MMM, YYYY")
            : "",
        isCalenderEnabled:
          !val?.abatement?.[0]?.abatementperiod &&
          val?.abatement?.[0]?.abatementdatetime
            ? true
            : false,
        calenderIconEnabled: !val?.abatement?.[0]?.abatementperiod
          ? true
          : false,
      },
      modifying_factor: val?.modifying_factor,
      status_verification: val?.verificationstatus,
      clinicalStatus: val?.clinicalStatus,
      notes: val?.note,
      remarks: [],
      createdName: val?.Practitioner?.[0]?.Name?.[0]?.fulname ?? "", //Practitioner[0].Name[0].fulname
      createdAt: utcTOLocal(val?.recordeddate, "DD MMM, YYYY"),
      statusType: "Inactive",
      headerShow: false,
      editData: val,
      isNew: createddateInt === updatedateInt,
      isModified: createddateInt !== updatedateInt,
      createddate: createddateInt,
      updatedate: updatedateInt,
    };
  });
  return list;
};
