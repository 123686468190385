import React from "react";
import { Location } from "../../../components/svg/components/location";

export const TextFieldAdornment = (props) => {
  const { inputLabel, value, handleChange, inputRef, parent_id } = props;

  return (
    <div
      id={`${parent_id}_textfiald_parent_div`}
      style={{
        display: "flex",
        justifyontent: "flex-start",
        alignItems: "center",
        height: "60px",
        borderRadius: "30px",
      }}
    >
      <div id={`${parent_id}_textfiald_sub_div`} variant="body1">
        {inputLabel}
      </div>
      <input
        className={props.active ? "input_placeholder_color" : ""}
        style={{
          border: "none",
          outline: "none",
          background: props.active ? "#fff" : "unset",
          fontFamily: "pc_regular",
          fontSize: 14,
        }}
        ref={inputRef}
        {...props}
        // size={'small'}
        label=""
        autoComplete={"no"}
        value={value}
        onChange={handleChange}
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          "aria-label": "wesdfsdfight",
          "aria-autocomplete": "none",
        }}
        // labelWidth={0}
      />
      {props.error && (
        <div
          id={`${parent_id}_textfiald_error_div`}
          variant="body1"
          component="div"
          // className={classes.errorText}
        >
          {props.errorText}
        </div>
      )}
      <Location
        id={`${parent_id}_textfield_detectlocation_icon`}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};
