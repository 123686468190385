import { makeStyles } from "@material-ui/core";
import { UIColor } from "../../themes/theme";

const useStyles = makeStyles({
    root: {
      minWidth: 240,
      minHeight: 300,
    },
    normal: {
      minWidth: 80,
      backgroundColor: "#ffff",
      marginLeft: "13px",
      boxShadow: "none",
      borderRadius: "8px",
      border: "1px solid #DFE1E6",
    },
    selected: {
      minWidth: 80,
      backgroundColor: "#01205C",
      color: "white !important",
      marginLeft: "13px",
      boxShadow: "none",
      borderRadius: "8px",
      border: "1px solid #DFE1E6",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    div: {
      marginTop: "20px",
      marginLeft: "23px",
    },
    align: {
      marginTop: "19px",
      marginLeft: "23px",
    },
    heading: {
      fontSize: "12px",
      fontWeight: 500,
      marginBottom: "10px",
    },
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
  });

  
  export default useStyles;
