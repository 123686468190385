import React from "react";
import { UIColor } from "../../../themes/theme";
export const CheifComplaintsIcon1 = (props) => {
  let width = props.width ? props.width : "26.145";
  let height = props.height ? props.height : "40.1";
  let color = props.color ? props.color : UIColor.primaryColor.color;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26.145 40.1"
    >
      <g
        id="cheif_complaints"
        data-name="cheif complaints"
        transform="translate(-19.34 -2.79)"
      >
        <g id="Group" transform="translate(19.44 2.89)">
          <g id="Group-2" data-name="Group" transform="translate(5.947 12.988)">
            <path
              id="Path"
              d="M46.881,33.489H33.968a.488.488,0,0,0-.518.45v.654a.488.488,0,0,0,.518.45H46.881a.488.488,0,0,0,.518-.45v-.654A.488.488,0,0,0,46.881,33.489Z"
              transform="translate(-33.45 -33.488)"
              fill="#ec6a49"
            />
            <path
              id="Path-2"
              data-name="Path"
              d="M46.881,45.3H33.968a.488.488,0,0,0-.518.45V46.4a.492.492,0,0,0,.518.471H46.881a.492.492,0,0,0,.518-.454v-.666a.488.488,0,0,0-.518-.45Z"
              transform="translate(-33.45 -40.284)"
              fill="#ec6a49"
            />
            <path
              id="Path-3"
              data-name="Path"
              d="M46.881,57.069H33.968a.492.492,0,0,0-.518.454v.649a.492.492,0,0,0,.518.454H46.881a.492.492,0,0,0,.518-.454v-.649A.492.492,0,0,0,46.881,57.069Z"
              transform="translate(-33.45 -47.058)"
              fill="#ec6a49"
            />
          </g>
          <g id="Group-3" data-name="Group">
            <path
              id="Compound_Path"
              data-name="Compound Path"
              d="M40.86,12.75h-.679a.34.34,0,0,0-.34.34v1.273a.335.335,0,0,0,.34.335h.683a2.581,2.581,0,0,1,2.585,2.589V39.951a2.865,2.865,0,0,1-2.857,2.874h-.025a.289.289,0,0,0-.284.263,8.184,8.184,0,0,1-.229,1.333.28.28,0,0,0,.272.357h.56a4.529,4.529,0,0,0,4.5-4.538V17.288A4.529,4.529,0,0,0,40.86,12.75Zm-16.211,0h-.683a4.534,4.534,0,0,0-4.525,4.542V39.951a4.818,4.818,0,0,0,4.809,4.826h.272a.284.284,0,0,0,.276-.357,7.76,7.76,0,0,1-.242-1.333.284.284,0,0,0-.284-.263h-.021a2.87,2.87,0,0,1-2.874-2.874V17.288A2.581,2.581,0,0,1,23.952,14.7h.683a.335.335,0,0,0,.335-.335V13.094a.335.335,0,0,0-.323-.344Z"
              transform="translate(-19.44 -8.565)"
              fill="#01205c"
              stroke="#01205c"
              stroke-width="0.2"
            />
            <path
              id="Compound_Path-2"
              data-name="Compound Path"
              d="M47.348,5.254h-2.5a2.607,2.607,0,0,0-5.192,0h-2.5a.62.62,0,0,0-.62.624V9.189a.62.62,0,0,0,.62.624H47.348a.624.624,0,0,0,.624-.624V5.878a.624.624,0,0,0-.624-.624ZM43.2,6.46a1.333,1.333,0,0,1-1.9,0,1.35,1.35,0,0,1-.395-.955,1.222,1.222,0,0,1,.025-.25,1.346,1.346,0,0,1,2.645,0,1.617,1.617,0,0,1,.021.25,1.333,1.333,0,0,1-.395.955Z"
              transform="translate(-29.281 -2.89)"
              fill="#01205c"
              stroke="#01205c"
              stroke-width="0.2"
            />
          </g>
          <path
            id="Compound_Path-3"
            data-name="Compound Path"
            d="M41.534,67.8a6.223,6.223,0,1,0,6.2,6.223,6.223,6.223,0,0,0-6.2-6.223Zm4.126,4.754L40.473,76.68a.824.824,0,0,1-.5.174h-.089a.794.794,0,0,1-.531-.3l-2.1-2.653a.31.31,0,0,1,.047-.424l.768-.611a.31.31,0,0,1,.424.051l1.414,1.791a.31.31,0,0,0,.424.051l4.338-3.481a.3.3,0,0,1,.424.051l.607.8a.306.306,0,0,1-.047.424Z"
            transform="translate(-28.562 -40.246)"
            fill="#ec6a49"
          />
        </g>
      </g>
    </svg>
  );
};
