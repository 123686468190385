import React, { useContext, useState } from "react";
import {
  Div,
  Paper,
  Select,
  TextInput,
  Upload,
  H6,
  Col,
  Row,
  Text,
  Icons,
  Image,
} from "qdm-component-library";
import "./style.css";
import withAllContexts from "../../hoc/withAllContexts";
// import { uploadImage } from "../../../utils";
// import { AlertProps } from "../../../utils";
import moment from "moment";
import { UIColor } from "../../themes/theme";
import CustomInputDatePicker from "../customDatePicker";
import {
  setData,
  setDataUpload,
  newRowAdd,
  getSize,
  handleDelete,
  FlterOptions,
} from "./handlers";

const PatientIdentification = (props) => {
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorborderStyle: {
      borderRadius: 8,
      borderColor: "red",
    },
    borderStyleDisable: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
      background: "#F0F0F0",
      // paddingBottom: 10,
    },
    denseBottom: {
      paddingBottom: 10,
    },
  };

  const {
    labelStyle,
    borderStyle,
    errorborderStyle,
    borderStyleDisable,
    denseBottom,
  } = styles;

  const [limited, setLimited] = useState(false);
  const [parent_id, SetParent_id] = useState(props);
  console.log("propsTYU", props);
  const { identification, statusDetails } = props?.[props?.saveto];

  const handleLimit = () => {
    setLimited(!limited);
  };

  return (
    <Div
      id={`${parent_id}_patientidentification_parent_div`}
      className="pi-root"
    >
      <Paper
        id={`${parent_id}_patientidentification_parent_paper`}
        className="pi-paper"
      >
        <Div id={`${parent_id}_patientidentification_header_label_div`}>
          <H6
            id={`${parent_id}_patientidentification_label_h6`}
            className="pi-title"
          >
            {props.saveto === "nextOfKin"
              ? "NEXT OF KIN IDENTIFICATION"
              : "PATIENT IDENTIFICATION"}
          </H6>
          <Text
            id={`${parent_id}_patientidentification_addnew_label_text`}
            onClick={() => newRowAdd(props)}
            className="pi-addbtn"
          >
            + Add New
          </Text>
        </Div>
        {identification
          ?.slice(0, limited ? identification.length : 1)
          ?.map((val, i) => (
            <Row id={`${parent_id}_${i}_patientidentification_map_row`}>
              <Col
                id={`${parent_id}_${i}_patientidentification_ip_type_col`}
                md={2}
                lg={2}
                sm={6}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <Select
                  id={`${parent_id}_${i}_patientidentification_id_type_select`}
                  value={val?.id_type?.value ?? null}
                  onChangeValue={(e) => setData(props, "id_type", e, i)}
                  isRequired={true}
                  // helperText={(val?.error && val?.error['id_type']) ? 'Is required' : ''}
                  disabled={
                    statusDetails?.status1 === "Inactive" ||
                    statusDetails?.status === "Deceased"
                      ? true
                      : false
                  }
                  error={val?.error && val?.error["id_type"] ? true : false}
                  options={FlterOptions(props, "ALTERNATEIDTYPE")}
                  label="ID Type"
                  placeholder="select..."
                  showArrow
                  labelStyle={labelStyle}
                  inLineStyles={
                    val?.error && val?.error["id_type"]
                      ? errorborderStyle
                      : borderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
              <Col
                id={`${parent_id}_${i}_patientidentification_id_number_col`}
                md={2}
                lg={2}
                sm={6}
                xs={12}
                inLineStyles={{ padding: 10 }}
              >
                <TextInput
                  id={`${parent_id}_${i}_patientidentification_id_number_textinput`}
                  // helperText={(val?.error && val?.error['id_number']) ? 'Is required' : ''}
                  error={val?.error && val?.error["id_number"] ? true : false}
                  value={val.id_number ? val.id_number : ""}
                  onChange={(e) => {
                    let pattern = /^[a-z0-9]+$/i;
                    let result = pattern.test(e.target.value);
                    if (result) {
                      setData(props, "id_number", e.target.value, i, 20);
                    } else if (e.target.value === "") {
                      setData(props, "id_number", "", i, 20);
                    }
                  }}
                  label="ID Number"
                  isRequired={true}
                  disabled={
                    !val?.id_type?.value ||
                    statusDetails?.status1 === "Inactive" ||
                    statusDetails?.status === "Deceased"
                  }
                  // isRequired={val?.id_type ? true : false}
                  placeholder="Id number"
                  labelStyle={labelStyle}
                  style={
                    !val?.id_type?.value
                      ? borderStyleDisable
                      : val?.error && val?.error["id_number"]
                      ? errorborderStyle
                      : borderStyle
                  }
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
              <Col
                id={`${parent_id}_${i}_patientidentification_expirydate_col`}
                md={2}
                lg={2}
                sm={6}
                xs={12}
                inLineStyles={{ padding: 6 }}
              >
                {/* <TextInput
                            id={`${parent_id}_${i}_patientidentification_expirydate_calender_textinput`}
                            max={moment().startOf("day").add(100, "years").format("YYYY-MM-DD")}
                            min={moment().startOf("day").format("YYYY-MM-DD")}
                            // onKeyPress={function (event) {
                            //     event.preventDefault();
                            //     return
                            // }} 
                            helperText={'Invalid Expiry Date'}
                            disabled={(!val?.id_type?.value || (statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased"))}
                            error={val.expiry_date && ((moment(val.expiry_date, "YYYY-MM-DD").diff(moment().startOf("day"), 'years') > 100) || (moment().startOf("day").unix() > moment(val.expiry_date, "YYYY-MM-DD").unix()))}
                            value={val.expiry_date ? val.expiry_date : ""}
                            onChange={(e) => this.setData("expiry_date", e.target.value, i)}
                            label="Expiry Date"
                            type="date"
                            dateIcon="images/icons8-calendar-grey.svg"
                            labelStyle={labelStyle} 
                            style={ !val?.id_type?.value ? borderStyleDisable : borderStyle }
                            hoverColor={UIColor.primaryColor.color}
                        />  */}
                <CustomInputDatePicker
                  disabled={
                    !val?.id_type?.value ||
                    statusDetails?.status1 === "Inactive" ||
                    statusDetails?.status === "Deceased"
                  }
                  placeholderText="dd/mm/yyyy"
                  // minDate={moment().startOf("day").format("YYYY-MM-DD")}
                  // maxDate={moment().startOf("day").add(100, "years").format("YYYY-MM-DD")}
                  minDate={new Date()}
                  maxDate={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() + 100)
                    )
                  }
                  handleChange={(value) =>
                    setData(props, "expiry_date", value, i)
                  }
                  selectedDate={
                    val.expiry_date ? new Date(val.expiry_date) : ""
                  }
                  // className={denseBottom}
                  inputField={
                    <TextInput
                      id={`${parent_id}_${i}_patientidentification_expirydate_calender_textinput`}
                      label="Expiry Date"
                      isRequired={false}
                      placeholder="dd/mm/yyyy"
                      dateIcon="images/icons8-calendar-grey.svg"
                      labelStyle={labelStyle}
                      value={
                        val.expiry_date
                          ? moment(new Date(val.expiry_date)).format(
                              "DD/MM/YYYY"
                            )
                          : ""
                      }
                      style={
                        !val?.id_type?.value
                          ? borderStyleDisable
                          : val?.error && val?.error?.expiry_date
                          ? errorborderStyle
                          : borderStyle
                      }
                      hoverColor={UIColor.primaryColor.color}
                      helperText={"Invalid Expiry Date"}
                      error={
                        val?.error && val?.error?.expiry_date ? true : false
                      }
                      // error={true}
                      disabled={
                        !val?.id_type?.value ||
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                      }
                    />
                  }
                />
              </Col>

              {props.saveto !== "nextOfKin" && (
                <Col
                  id={`${parent_id}_${i}_patientidentification_id_source_col`}
                  md={2}
                  lg={2}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <Select
                    id={`${parent_id}_${i}_patientidentification_id_source_select`}
                    value={val?.id_source?.value ?? null}
                    // helperText={(val?.error && val?.error['id_source']) ? 'Is required' : ''}
                    error={val?.error && val?.error["id_source"] ? true : false}
                    onChangeValue={(e) => setData(props, "id_source", e, i)}
                    options={FlterOptions(props, "RELATIONSHIP")}
                    showArrow
                    isRequired={true}
                    disabled={
                      !val?.id_type?.value ||
                      statusDetails?.status1 === "Inactive" ||
                      statusDetails?.status === "Deceased"
                    }
                    //  isRequired={val?.id_type ? true : false}
                    label="ID Source"
                    placeholder="select..."
                    labelStyle={labelStyle}
                    inLineStyles={
                      val?.error && val?.error["id_source"]
                        ? errorborderStyle
                        : borderStyle
                    }
                    hoverColor={UIColor.primaryColor.color}
                  />
                </Col>
              )}
              <Col
                id={`${parent_id}_${i}_patientidentification_sub_parent_col`}
                md={4}
                lg={4}
                sm={12}
                xs={12}
                className="pi-upload-root"
                inLineStyles={{
                  padding: 10,
                  alignItems: !val.doc ? null : "baseline",
                }}
              >
                {!val.doc ? (
                  <Div
                    id={`${parent_id}_${i}_patientidentification_document_div`}
                    style={{
                      display: "flex",
                      alignItems: "end",
                      marginBottom: "1%",
                    }}
                  >
                    <Upload
                      id={`${parent_id}_${i}_patientidentification_document_upload`}
                      label="Upload Document"
                      className="pi-upload-btn"
                      handleChange={(e, value) =>
                        setDataUpload(props, "doc", value, i)
                      }
                      labelColor={UIColor.secondaryButtonColor.color}
                      iconColor={UIColor.secondaryButtonColor.color}
                      iconSize={14}
                      imgUrl="images/uploadBlue.svg"
                      labelStyle={{
                        fontSize: 14,
                        fontFamily: "pc_medium !important",
                      }}
                      disabled={
                        statusDetails?.status1 === "Inactive" ||
                        statusDetails?.status === "Deceased"
                          ? true
                          : false
                      }
                    />
                    <Div
                      id={`${parent_id}_${i}_patientidentification_document_upload_div`}
                      style={{
                        width: 15,
                        cursor: "pointer",
                      }}
                    >
                      <Image
                        id={`${parent_id}_${i}_patientidentification_delete_image`}
                        src="images/icons8-trash1.svg"
                        alt="delete"
                        onClick={() => handleDelete(props, i)}
                      />
                    </Div>
                  </Div>
                ) : (
                  <Div
                    id={`${parent_id}_${i}_patientidentification_upload_doc_div`}
                    className="pi-upload-doc"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <Image
                        id={`${parent_id}_${i}_patientidentification_upload_file_image`}
                        className="pi-upload-doc-img"
                        src={"images/icons8-file.svg"}
                        alt="file"
                      />

                      <Text
                        id={`${parent_id}_${i}_patientidentification_upload_doc_name_text`}
                        className="pi-upload-doc-name"
                      >
                        {(val?.doc?.filename?.length > 15
                          ? `${val?.doc?.filename?.slice(0, 15)}...`
                          : val?.doc?.filename) ?? ""}
                      </Text>
                    </div>
                    <div style={{ display: "flex" }}>
                      &nbsp;
                      {/* <Text className="pi-upload-doc-size">{this.getSize(val?.doc?.size)}</Text> */}
                      <Icons
                        id={`${parent_id}_${i}_patientidentification_upload_doc_close_icons`}
                        key={"1"}
                        className="pi-upload-doc-close"
                        fontIcon={"close"}
                        ariaHidden={"true"}
                        style={{
                          marginLeft: "2px",
                          marginRight: "4px",
                        }}
                        onClick={() => setData(props, "doc", null, i)}
                        size={"small"}
                      ></Icons>
                    </div>
                  </Div>
                )}
                {!val.doc ? null : (
                  <Div
                    id={`${parent_id}_${i}_patientidentification_trash_div`}
                    style={{
                      width: 15,
                      cursor: "pointer",
                      marginLeft: "10px",
                    }}
                  >
                    <Image
                      id={`${parent_id}_${i}_patientidentification_trash_image`}
                      src="images/icons8-trash1.svg"
                      alt="delete"
                      onClick={() => handleDelete(props, i)}
                    />
                  </Div>
                )}

                {identification?.length > 1 && i === 0 && (
                  <Text
                    id={`${parent_id}_${i}_patientidentification_show_showless_label_text`}
                    className="pc_medium"
                    style={{
                      color: "#F58B00",
                      marginLeft: 15,
                      cursor: "pointer",
                      fontSize: "14px",
                      marginTop: 20,
                    }}
                    onClick={() => handleLimit()}
                  >
                    {limited ? "Show Less" : `Show +${identification?.length}`}
                  </Text>
                )}
              </Col>
            </Row>
          ))}
      </Paper>
    </Div>
  );
};


export default withAllContexts(PatientIdentification); //here withAllContexts

