import React from "react";
// import { FormRendererParent } from "form-configurator";
import PropTypes from "prop-types";

export const FormRender = (props) => {
  return (
    <div>
      {props.formId &&
        {
          /* <FormRendererParent
          onSaveForm={(data) => {
            props?.saveForm && props.saveForm(data);
          }}
          id={props?.formId}
          answer={props?.answer}
          btnName="save"
          submitBtnBottom="Right"
          negativeBtn="Cancel"
          cancelPresent={false}
          headerPresent={true}
          formTitle={true}
          alertRequired
          divider
          // theme={json}
        /> */
        }}
    </div>
  );
};

FormRender.propTypes = {
  answer: PropTypes.object,
  saveForm: PropTypes.func,
  formId: PropTypes.string,
};

FormRender.defaultProps = {
  answer: {},
  saveForm: null,
  formId: "",
};
