//import { baseURL , urls } from "./urls";
import jwtDecode from "jwt-decode";
import { AppRoutes } from "../router/routes";
import Config from "./configurations";
import { getUtcTime } from ".";
import { checkWithCasbin } from "./permissionHandling";
import { utcTOLocal } from "./validations";
import moment from "moment";
import { setHours, setMinutes } from "date-fns";
import { AlertProps } from "../utils/constants";
import { actions } from "primarycare-binder";
import axios from "axios";
import { personRequests } from "./qdmQueries";
import { urls } from "./urls";
import { fromUnixTime, format } from "date-fns";

export let checkError = (data) => {
  const isError = data?.error || data?.data?.error;
  let errMsg = "Something went wrong! Try Again.";
  if (
    data?.data?.error ||
    data?.data?.errorMessage ||
    data?.data?.error_description
  ) {
    if (typeof data?.data?.error === "string") {
      errMsg =
        data?.data?.error ||
        data?.data?.errorMessage ||
        data?.data?.error_description;
    } else {
      errMsg =
        data?.data?.errorMessage ||
        data?.data?.error_description ||
        data?.data?.message ||
        errMsg;
    }
  }
  if (errMsg === "invalid_grant" || errMsg === "Invalid user credentials") {
    errMsg = "Invalid Password / User ID";
  }
  return {
    isError: !!isError,
    errMsg,
  };
};

export const fetchData = async (input, url, tenantId, header) => {
  const __options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      jwtToken: localStorage.getItem("JwtToken"),
      ...(header && { ...header }),
    },
    body: {},
  };
  if (tenantId) {
    __options.headers["Tenantid"] = tenantId;
  }
  const res = await fetch(url, {
    ...__options,
    ...input,
  }).catch((error) => {
    console.error("Fetch error:", error);
  });

  let data = await res.json();
  // Handle the response as an object

  //const data = await res.json();
  return data;
};

export const axiosCall = async (payload, url, header) => {
  let Response = await axios({
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
      ...(header && { ...header }),
    },
    data: JSON.stringify(payload),
  });

  return Response.data;
};

export const convertTime = (time, inputFormat, outputFormat, type = "date") => {
  if (type === "date") {
    if (inputFormat !== "unix") {
      return moment(time, inputFormat).format(outputFormat);
    }
    const date = new Date(time * 1000);
    return moment(date).format(outputFormat);
  }
  if (type === "diff") {
    if (inputFormat === "unix") {
      return moment().diff(moment.unix(time), outputFormat);
    }
    return moment().diff(moment(time, inputFormat), outputFormat);
  }
};
export let getUserInfo = () => {
  if (localStorage.getItem("Token")) {
    try {
      const info = jwtDecode(
        jwtDecode(localStorage.getItem("Token")).access_token
      );
      // const info = jwtDecode(
      //   JSON.parse(atob(localStorage.getItem("Token"))).access_token
      // );
      return {
        name: info?.given_name,
        email: info?.email,
        mobile: info?.preferred_username,
        type: "Token",
      };
    } catch (err) {
      localStorage.removeItem("Token");
      window.location = AppRoutes.signIn;
    }
  }
  if (localStorage.getItem("guestInfo")) {
    const info = JSON.parse(localStorage.getItem("guestInfo"));
    return {
      name: info.name,
      info: info,
      type: "guest",
    };
  }
  return {
    name: "",
    type: null,
  };
};

export const Logout = () => {
  localStorage.clear();
  window.location.reload();
};

/**
 * The below function capitalize the given string.
 */
export let CapitalizeString = (string) => {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getImgUrl = (fileId) =>
  fileId ? `${Config.downloadUrl}${fileId}` : "";

export const UploadFun = async (file) => {
  return new Promise(async (resolve, reject) => {
    if (file.originalData) {
      return resolve({
        fileid: file.originalData.fileid,
        fileName: file.originalData.fileName,
        filetype: file.originalData.filetype,
        objectid: file.originalData.objectid,
      });
    }
    let formData = new FormData();
    formData.append("files", file);
    await fetch(Config.uploadUrl, {
      method: "POST",
      body: formData,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((res) => {
        resolve({
          fileid: res.fileid,
          fileName: file.name,
          filetype: file.type,
          objectid: res.objectid,
        });
      })
      .catch((err) => {
        resolve({});
      });
  });
};

export const uploadImage = (fileList) => {
  return new Promise(async (resolve, reject) => {
    if (!fileList && !Array.isArray(fileList)) {
      resolve([]);
      return;
    }
    fileList = fileList.filter((file) => !!file);
    if (fileList.length === 0) {
      resolve([]);
      return;
    }
    let calls = fileList.map(async (file) => {
      return await UploadFun(file);
    });
    Promise.all(calls)
      .then((data) => {
        let dataList = [];
        data.map((val) => {
          if (val.fileid) {
            dataList.push({
              fileid: val.fileid,
              filetype: val.filetype,
              objectid: val.objectid,
              fileName: val.fileName,
            });
          }
          return false;
        });
        resolve(dataList);
      })
      .catch((err) => {
        resolve([]);
      });
  });
  // fetch("http://164.52.210.54:5000/primarycare/11", requestOptions)
};

/**
 * The below function convert the uploaded file to base64 file.
 */
export let ToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export async function fetchQueueData(dispatch, props, alertContext) {
  const permission = await checkWithCasbin(["queueSideNav"]);
  const permission_ = await checkWithCasbin(["dashboard"]);
  const permissionData = permission.write.find(
    (d) =>
      d === "appointmentQueueDataN" ||
      d === "appointmentQueueDataD" ||
      d === "appointmentQueueDataF"
  );
  const info = getUserInfo();
  const userData = await fetchUserInfo(dispatch, info.email, props);
  const specialtyId = await dispatch(
    actions.GET_SPECIALITY_ID_BY_PRCTITIONER_ID({
      practitionerId: userData?.practionerId,
    })
  );
  const isDoctor = (await permission_?.write?.indexOf("doctorQueue")) > -1;
  const isNurse = (await permission_?.write?.indexOf("nurseQueue")) > -1;
  const isFrontDesk =
    (await permission_?.write?.indexOf("frontDeskQueue")) > -1;

  const data = await await dispatch(
    actions.QUEUE_MANAGEMENT({
      start: getUtcTime(moment().startOf("day")),
      end: getUtcTime(moment().endOf("day")),
      OrgID: userData?.orgId,
      login: userData?.practionerId,
      specialtyId: specialtyId?.payload?.data?.result ?? 0,
      isDoctor: isDoctor,
      isNurse: isNurse,
      isFrontDesk: isFrontDesk,
      nameOfOrg: localStorage.getItem("nameOfOrg"),
    })
  );
  const { isError } = checkError(data.payload);
  if (isError) {
    alertContext.setSnack({
      open: true,
      severity: AlertProps.severity.error,
      msg: "Unable to fetch the Queue's.",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
  } else {
    if (Array.isArray(data?.payload?.data)) {
      const appInfo = JSON.parse(JSON.stringify(data.payload.data));
      const [returnData, displayData, onHoldData, completedData, everyData] =
        makeQueueData(permissionData, appInfo, userData);

      props.queueManagement.setAllData(everyData);
      props.queueManagement.setData(returnData);
      props.queueManagement.setDisplayData(displayData);
      props.queueManagement.setOnHoldData(onHoldData);
      props.queueManagement.setPreviousData(completedData);
    }
  }
  return;
}

export async function fetchUserInfo(dispatch, email, props) {
  try {
    if (!this?.props?.loggedUserInfo?.data?.orgId) {
      const token = localStorage.getItem("Token");
      const userInfo = await dispatch(
        actions.GET_ORG_USER_INFO({
          token,
          callRefresh: false,
        })
      );
      const { isError, errMsg } = checkError(userInfo.payload);

      if (isError) {
        if (errMsg === "invalid_token") {
          localStorage.removeItem("role_data");
          localStorage.removeItem("role_name");
          localStorage.removeItem("Token");
          window.location = AppRoutes.signIn;
        }
        return;
      }
      localStorage.setItem("Token", userInfo.payload?.data?.newToken || token);
      if (!email) {
        email = userInfo.payload?.data?.email;
      }
      const userData = await dispatch(
        actions.SEARCH_PRACTIONER({
          email,
        })
      );
      const permissionA = await checkWithCasbin(["queueSideNav"]);
      const permissionData = permissionA.write.find(
        (d) =>
          d === "appointmentQueueDataN" ||
          d === "appointmentQueueDataD" ||
          d === "appointmentQueueDataF"
      );
      let role = "frontdesk";
      if (permissionData === "appointmentQueueDataN") {
        role = "nurse";
      }
      if (permissionData === "appointmentQueueDataD") {
        role = "doctor";
      }
      const info = {
        orgId: userData?.payload?.data?.[0]?.OrgID?.[0],
        org_ID: userData?.payload?.data?.[0]?.orgDtls?._id ?? "",
        org_name: userData?.payload?.data?.[0]?.orgDtls?.name ?? "",
        name: {
          given: userData?.payload?.data?.[0]?.name?.[0]?.given,
          family: userData?.payload?.data?.[0]?.name?.[0]?.family,
          text: userData?.payload?.data?.[0]?.name?.[0]?.text,
        },
        photo:
          getImgUrl(
            userData?.payload?.data?.[0]?.practitioner?.[0]?.photo?.[0]?.fileid
          ) ||
          getImgUrl(userData?.payload?.data?.[0]?.photo?.[0]?.fileid) ||
          "",
        completeName: makeName(userData?.payload?.data?.[0]?.name?.[0] || {}),
        personId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
        practionerId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
        practioner_id: userData?.payload?.data?.[0]?.practitioner?.[0]?._id,
        n_practitioner_id:
          userData?.payload?.data?.[0]?.practitioner?.[0]?.practitioner_id,
        role: userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole[0]?.code[0]?.coding?.[0]?.display?.toLowerCase(),
        internalRole: role,
        org_id: userData?.payload?.data?.[0]?.org_id,
        practitionerRoleLabel:
          userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole?.[0]
            ?.code[0]?.coding?.[0]?.display ?? "",
        practitionerRoleValue:
          userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole?.[0]
            ?._id ?? "",
        practitionerRoleId:
          userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole?.[0]
            ?.id ?? 0,
        orderByLabel:
          userData?.payload?.data?.[0]?.practitioner?.[0]?.name?.[0]?.text +
          " " +
          userData?.payload?.data?.[0]?.practitioner?.[0]?.name?.[0]?.given +
          " " +
          userData?.payload?.data?.[0]?.practitioner?.[0]?.name?.[0]?.family,
      };
      await dispatch(actions.LOGGED_USER_INFO(info));
      return info;
    }
    return props?.loggedUserInfo?.data;
  } catch (err) {
    console.error(err?.message);
  }
}

export const makeQueueData = (data) => {
  //const nurseTriagerequired = localStorage.getItem("nurseTriagerequired");
  const nurseTriagerequired = true;
  const { startTime, endTime } = getStartEndTime();
  // const displayData = [];
  // const onHoldData = [];
  // const completedData = [];
  let count = 0;
  const allData = [];
  let returnData = data.map((app, idx) => {
    const {
      id,
      start,
      person,
      encounterid,
      appstatus,
      appointmentType,
      consultationMode,
    } = app;
    const info = {
      appointmentType,
      consultationMode,
      token: id,
      appstatus,
      name: makeName(person[0]?.HumanName[0]),
      isPatient: person[0]?.Patient?._id !== null,
      personId: person[0]?.Id || 0,
      patient_id: person[0]?.Patient._id || "",
      //patientId: Person?.[0]?.Patient?.[0]?.id || 0,
      pId: person[0]?.Patient._id || "",
      mrn: person[0]?.Patient?.patmrn || "",
      role: "Consultation",
      encounter: encounterid[0]?._key || "",
      encounter_id: encounterid[0]?._id || "",
      encounter_status: encounterid[0]?.status?.display || "",
      encounterId: encounterid[0]?.id || "",
      showBringtoTop:
        appstatus === "fdonhold" || appstatus === "NurseSignoff`"
          ? false
          : true,
      profile_pic:
        getImgUrl(person[0]?.photo[0]?.fileid) ||
        getImgUrl(person[0]?.Patient[0]?.photo[0]?.fileid) ||
        "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
      isEmergency: false,
      start: utcTOLocal(start, "hh:mm a"),
      startTime: start,
      info: app,
      mobileNo:
        person[0]?.telecom?.find(
          (tel) => tel.system === "Phone" && tel.use === "Mobile"
        )?.value || "",
      index: idx,
      status: true,
      type:
        appstatus == "booked"
          ? "frontdesk"
          : appstatus == "checked-in"
          ? "nurse"
          : appstatus == "NurseSignoff"
          ? "doctor"
          : "",
    };

    allData.push(info);
  });
  // returnData = returnData.filter((d) => !!d);
  return allData;
};
const getStartEndTime = () => {
  // const time = moment("02-07-2021 02:00 PM", "DD-MM-YYYY hh:mm a");
  const time = moment();
  const startTime = round(time, moment.duration(30, "minutes"), "round").unix();
  const endTime = round(time, moment.duration(30, "minutes"), "round")
    .add(90, "m")
    .unix();
  return {
    startTime,
    endTime,
  };
};

export const makeName = (nameObj = {}) => {
  const {
    prefix = null,
    given = "",
    // text = "",
    fulname = "",
    suffix = null,
    family = "",
  } = nameObj;
  let prefixVal =
    prefix?.[0]?.coding?.[0]?.display ||
    prefix?.coding?.[0]?.display ||
    prefix?.[0]?.display ||
    prefix?.display ||
    prefix ||
    "";
  let suffixVal =
    suffix?.[0]?.coding?.[0]?.display ||
    suffix?.coding?.[0]?.display ||
    suffix?.[0]?.display ||
    suffix?.display ||
    suffix ||
    "";
  return `${prefixVal && prefixVal + "."}${fulname && fulname + " "}${
    given && given + " "
  }${family && family + " "}${suffixVal && suffixVal}`;
};

export const actionButtonClick = async (
  action,
  apiendpoint,
  backEndCall,
  ticketid,
  cancelbookingreason,
  queue,
  reason,
  comments,
  checkedin,
  reasondesc,
  cacellationType,
  appointmentid

) => {
  let JwtToken = localStorage.getItem("JwtToken");
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const performUserActionApi = process.env.REACT_APP_PERFORMUSERACTION_API;

  let payload = {};
  if (queue === process.env.REACT_APP_REFERRAL_QUEUE) {
    if (action === process.env.REACT_APP_REFERRAL_ON_HOLD || action === process.env.REACT_APP_REFERRAL_REJECT) {
      payload = {
        ticketId: ticketid,
        userAction: action,
        data: {
          ticketId: ticketid,
          practitionerId: decodedUserinfo?.practitionerid,
          practitionerRoleId: decodedUserinfo?.practitionerid,
          reason: cancelbookingreason ? cancelbookingreason : "",
        },
      };
    } else {
      payload = {
        ticketId: ticketid,
        userAction: action,
        data: {
          ticketId: ticketid,
          practitionerId: decodedUserinfo?.practitionerid,
          practitionerRoleId: decodedUserinfo?.practitionerid,
        },
      };
    }
  } else if (queue === process.env.REACT_APP_DIALYSIS_QUEUE) {
    if (action === process.env.REACT_APP_DIALYSIS_PAUSE) {
      payload = {
        ticketId: ticketid,
        userAction: action,
        data: {
          ticketId: ticketid,
          reason: cancelbookingreason ? cancelbookingreason : "",
        },
      };
    }
    else if (action === process.env.REACT_APP_DIALYSIS_NO_SHOW) {
      payload = {
        ticketId: ticketid,
        userAction: action,
        data: {
          ticketid: ticketid,
          reason: reason ? reason : "",
          othersfield: comments ? comments : ""
        }
      };
    }
    else if (action === process.env.REACT_APP_DIALYSIS_CHECKED_IN_CANCEL) {
      payload = {
        ticketId: ticketid,
        userAction: action,
        data: {
          ticketid: ticketid,
          reasoncode: checkedin?._id ?  checkedin?. _id : "",
          reasondesc: reasondesc ? reasondesc : ""
        }
      };
    }
    else if (action === process.env.REACT_APP_DIALYSIS_CANCEL) {
      payload = {
        ticketId: ticketid,
        userAction: action,
        data: {
          appId: appointmentid,
          ticketId: ticketid,
          cancelationReason: cancelbookingreason ?? "",
          cancelationReasondesc: comments || "",
          cacellationType
        },
      };
    }
    else {
      payload = {
        ticketId: ticketid,
        userAction: action,
        // data: {
        //   ticketId: ticketid,
        //   practitionerId: decodedUserinfo?.practitionerid,
        //   practitionerRoleId: decodedUserinfo?.practitionerid,
        // },
      };
    }
  } else {
    if (action === "CANCEL") {
      payload = {
        ticketId: ticketid,
        userAction: action,
        data: {
          appId: appointmentid,
          ticketId: ticketid,
          cancelationReason: cancelbookingreason ?? "",
          cancelationReasondesc: comments
        },
      };
    } else {
      payload = {
        ticketId: ticketid,
        userAction: action,
      };
    }
  }

  let header = { JwtToken: JwtToken };
 let respData = await axiosCall(payload, performUserActionApi, header);

  if (!backEndCall && apiendpoint !== "" && !respData.error) {
    let logEventPayload = {
      eventcode: "EVN_015",
      payload: {
        ticketId: ticketid,
      },
    };
    const apicall = await axiosCall(logEventPayload, apiendpoint, header);
    return apicall;
  }
 return respData;
};

export const GetQueueData = async () => {
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);

  const info = {
    orgId: 138,
    name: "sathiya",
    practionerId: decodedUserinfo?.practitionerid,
    role: decodedUserinfo?.rolename.toLowerCase(),
    org_id: decodedUserinfo?.facilityid,
    idmroleid: decodedUserinfo.idmroleid,
  };

  const queueDisplayMap = await personRequests.QueueDisplayMap(info?.idmroleid, getFacilityId());
  const getQueueDisplayMap = await fetchData(
    queueDisplayMap,
    urls.readQdmQueries
  );

  const getfetchData = await personRequests.queueManagement(
    info?.org_id,
    info?.idmroleid,
    getUtcTime(moment().startOf("day")),
    getUtcTime(moment().endOf("day"))
  );
  const fetchDatas = await fetchData(getfetchData, urls.readQdmQueries);
  const { isError } = checkError(fetchDatas);
  let getPreviousData = [];
  let getDisplayData = [];
  let everyData = [];
  if (isError) {
    // setSnack({
    //   open: true,
    //   severity: AlertProps.severity.error,
    //   msg: "Unable to fetch the Queue's.",
    //   vertical: AlertProps.vertical.top,
    //   horizontal: AlertProps.horizontal.right,
    // });
    let isErrorResp = {
      open: true,
      severity: AlertProps.severity.error,
      msg: "Unable to fetch the Queue's.",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    };
    return {
      everyData: everyData,
      getPreviousData: getPreviousData,
      getDisplayData: getDisplayData,
      isErrorResp: isErrorResp,
    };
  } else {
    if (Array.isArray(fetchDatas)) {
      const appInfo = JSON.parse(JSON.stringify(fetchDatas));
      everyData = makeQueueData(appInfo);

      //setAllData(everyData);

      getQueueDisplayMap.map((l, i) => {
        if (l.display === "PASTAPP") {
          const prevData = everyData.filter(
            (x) => x.info.statesid === l.gmconfigvalues.statesid
          );
          if (prevData.length > 0) {
            getPreviousData.push(prevData[prevData.length - 1]);
          }
          // if (getPreviousData.length > 0) {
          //   getPreviousData.sort((a, b) => {
          //     return b.info.updatedate - a.info.updatedate;
          //   });
          // }
          //setPreviousData(getPreviousData);
        }

        if (l.display === "TOATTEND") {
          getDisplayData = everyData.filter(
            (x) => x.info.statesid === l.gmconfigvalues.statesid
          );
          getDisplayData.map((l, i) => {
            l.onHoldAction = l.info.action.find((x) => x.action === "ON HOLD");
          });
          if (getDisplayData.length > 0) {
            getDisplayData.sort((a, b) => {
              return a.info.updatedate - b.info.updatedate;
            });
          }
          //setDisplayData(getDisplayData);
        }
      });

      return {
        everyData: everyData,
        getPreviousData: getPreviousData,
        getDisplayData: getDisplayData,
      };
    }
  }
};

export const round = (date, duration, method) => {
  return moment(Math[method](+date / +duration) * +duration);
};

export const getCalculatedAge = (date) => {
  const today = moment();
  const birthDate = moment(date, "DD/MM/YYYY");

  const years = today.diff(birthDate, "years");
  const months = today.diff(birthDate, "months");
  const days = today.diff(birthDate, "days");

  if (years > 0) {
    return years === 1 ? `${years} Yr` : `${years} Yrs`;
  } else if (months > 0) {
    return months === 1 ? `${months} Month` : `${months} Months`;
  } else {
    return days === 1 ? `${days} Day` : `${days} Days`;
  }
};
export const getCalculatedAge2 = (date) => {
  const today = moment();
  const birthDate = moment(date, "DD/MM/YYYY");

  const years = today.diff(birthDate, "years");
  const months = today.diff(birthDate, "months");
  const days = today.diff(birthDate, "days");

  if (years > 0) {
    return years === 1 ?{value :years , ageType : {value: "Years",label: "Years",_id: "CodingMaster/11343",}} : 
    {value :years , ageType : {value: "Years",label: "Years",_id: "CodingMaster/11343",}};
  } else if (months > 0) {
    return months === 1 ? {value :months , ageType : {value: "Months",label: "Months",_id: "CodingMaster/11375",}} : 
    {value :months , ageType : {value: "Months",label: "Months",_id: "CodingMaster/11375",}};
  } else {
    return days === 1 ? {value :days , ageType : {value: "Days",label: "Days",_id: "CodingMaster/11376",}} : 
    {value :days , ageType : {value: "Days",label: "Days",_id: "CodingMaster/11376",}};
  }
};

export async function putOnHold(index = 0, token, status = "fdonhold", props) {
  if (isNaN(index)) {
    await updateStatus(status, token, props.UPDATE_APPOINTMENT_STATUS);
  } else {
    const masterData = [props.queueManagement.data];
    const newData = [props.queueManagement.displayData];
    const onHoldVal = newData.splice(index, 1);

    await updateStatus(
      status,
      onHoldVal[0]?.token
      //props.UPDATE_APPOINTMENT_STATUS
    );
    const statusChnage = masterData.find((d) => d.token === onHoldVal[0].token);
    statusChnage.appstatus = status;
    const newOnHoldData = [props.queueManagement.onHoldData, ...onHoldVal];
    if (newData[0]) {
      const changeData = masterData.find((d) => d.token === newData[0].token);
      changeData.showBringtoTop = false;
      newData.splice(1);
      if (newData.length <= 1) {
        const newQueueData = masterData.find(
          (d) =>
            d.index > newData[0].index &&
            !(d.appstatus === "fdonhold" || d.appstatus === "NurseSignoff")
        );
        if (newQueueData) {
          newData.push(newQueueData);
        }
      }
    }
    props.queueManagement.setData(masterData);
    props.queueManagement.setDisplayData(newData);
    props.queueManagement.setOnHoldData(newOnHoldData);
  }
}

export async function updateStatus(
  status,
  appointmentId,
  updateFn,
  isCompleted,
  dispatch
) {
  let data;
  if (updateFn) {
    data = await updateFn({
      appointmentId,
      status,
      isCompleted,
    });
  } else {
    data = await dispatch(
      actions.UPDATE_APPOINTMENT_STATUS({
        appointmentId,
        status,
        isCompleted,
      })
    );
  }
  const { isError } = checkError(data?.payload);
  if (isError) {
    return false;
  }
  return true;
}

export async function removeFromHold(
  index = 0,
  status = "string",
  role,
  roleID = 2,
  loggedUserInfo
) {
  const newOnHoldData = [...this.props.queueManagement.onHoldData];
  const onHoldVal = newOnHoldData.splice(index, 1);
  await updateStatus(
    status,
    onHoldVal[0].token,
    this.props.UPDATE_APPOINTMENT_STATUS
  );
  const data = [...this.props.queueManagement.data];
  data.find((d) => d.token === onHoldVal[0].token).comment = status;
  if (this.props.queueManagement.displayData.length > 0) {
    const mainData = data.find(
      (d) => d.token === this.props.queueManagement.displayData[0]?.token
    );
    if (mainData) {
      mainData.showBringtoTop = true;
    }
  }
  const newDisplayData = [...this.props.queueManagement.displayData];
  newDisplayData.splice(1);
  const newData = [...onHoldVal, ...newDisplayData];
  newData.slice(2);
  this.props.queueManagement.setData(data);
  this.props.queueManagement.setDisplayData(newData);
  this.props.queueManagement.setOnHoldData(newOnHoldData);

  if (role === "nurse" || role === "doctor") {
    await this.props.HIGH_PRIORITY_PATIENT({
      _role: roleID,
      practionerId: loggedUserInfo.data.practionerId,
      org_id: loggedUserInfo.data.org_id,
      startDate: getUtcTime(moment().startOf("day")),
      endDate: getUtcTime(moment().endOf("day")),
    });
  }
}

export function pushToTop(listToken, props) {
  if (listToken) {
    const data = [...props.queueManagement.data];
    let index = -1;
    data.map((val, i) => {
      if (val.token === listToken) {
        index = i;
      }
    });
    if (index !== -1) {
      data[index].showBringtoTop = false;
      const mainData = data.find(
        (d) => d.token === props.queueManagement.displayData[0]?.token
      );
      if (mainData) {
        mainData.showBringtoTop = true;
      }
      const newDisplayData = [[...data][index]];
      let displayData = [...props.queueManagement.displayData];
      displayData = displayData.filter(
        (d) => d.token !== newDisplayData[0].token
      );
      displayData.splice(1);
      const newData = [...newDisplayData, ...displayData];
      newData.slice(2).forEach((d) => {
        const findData = data.find((da) => da.token === d.token);
        findData.showBringtoTop = false;
      });
      props.queueManagement.setData(data);
      props.queueManagement.setDisplayData(newData);
    }
  }
}

//mobile no check

export const getMobileNumber = (input) => {
  return `+${input?.countryData?.callingCodes ?? "00"}${input.mobileNo}`;
};

//time
const getDayString = (type, start, end) => {
  let string = "";

  // var a = moment();
  // var b = moment.unix(end);
  var b = moment(moment().format("DD MMM YYYY"));
  var a = moment(moment.unix(end).format("DD MMM YYYY"));

  var years = a.diff(b, "year");
  b.add(years, "years");

  var months = a.diff(b, "months");
  b.add(months, "months");

  if (type === "weeks") {
    var weeks = a.diff(b, "weeks");
    b.add(weeks, "weeks");
  }

  var days = a.diff(b, "days");

  if (type === "weeks") {
    string =
      Math.abs(weeks) +
      " Week " +
      (Math.abs(days) ? Math.abs(days) + " Day" : "");
  }
  if (type === "months") {
    string =
      Math.abs(months) +
      " Month " +
      (Math.abs(days) ? Math.abs(days) + " Day" : "");
  }

  return string;
};

export const TimeStampToMnthandDays = (val) => {
  let day_ = val?.followupdurval ?? 0;
  let type_ = val?.followupduruom?.display?.toLocaleLowerCase() ?? "days";
  // let date = moment
  //   .unix(val.followupdate)
  //   .startOf("day")
  //   .add(day_, type_)
  //   .unix();
  let date = val?.followupdate;
  const CuuDate = moment().startOf("day");
  const para = moment.unix(date).startOf("day");

  const daysDiff = Math.abs(CuuDate.diff(para, "days"));
  const weeksDiff = Math.abs(CuuDate.diff(para, "weeks"));
  const monthDiff = Math.abs(CuuDate.diff(para, "months"));

  // const daysDiff = para.diff(CuuDate, "days");
  // const weeksDiff = para.diff(CuuDate, "weeks");
  // const monthDiff = para.diff(CuuDate, "months");

  // if(daysDiff < 0 || weeksDiff < 0 || monthDiff < 0){
  //   return "Date Expired"
  // }

  if (daysDiff >= 7) {
    if (weeksDiff > 4) {
      return getDayString("months", CuuDate, date);
    }
    return getDayString("weeks", CuuDate, date);
  } else if (daysDiff === 0) {
    return "Today";
  } else {
    return daysDiff + " Days";
  }
};
//user check fun
export const upsertPerson = async (getfn, upsertfn, data) => {
  try {
    if (getfn) {
      const persondata = await getfn({
        phone: data.phone ? data.phone : data.email,
      });
      const { isError, errMsg } = checkError(persondata?.payload);
      if (
        isError ||
        (persondata?.payload?.data?.id && persondata?.payload?.data?.id !== 0)
      ) {
        if (isError) {
          return {
            error: true,
            data: errMsg,
            getData: null,
          };
        }
        return {
          error: true,
          data: `User Already Exists for ${
            data.phone ? data.phone : data.email
          }`,
          getData: persondata,
        };
      }
    }
    const upsertData = await upsertfn(data);
    const { isError: isErr, errMsg: msg } = checkError(upsertData?.payload);
    if (isErr) {
      return {
        error: true,
        data: msg,
        getData: null,
      };
    }
    return {
      error: false,
      data: upsertData,
      getData: null,
    };
  } catch (err) {
    return {
      error: true,
      data: "Something went wrong",
      getData: null,
    };
  }
};

const weekOfMonth = (m) => {
  return m.week() - m.startOf("month").week() + 1;
};
export const sortNumbers = (arr, overwrite = true) => {
  let returnArr = arr;
  if (!overwrite) {
    returnArr = [...arr];
  }
  return returnArr.sort((a, b) => Number(a) - Number(b));
};
export const getReccuranceDates = (
  startDate, // DD-MM-YYYY
  endType, // [by => Calendar, on => NoOfDays]
  endAfter, // // [by => Date(DD-MM-YYYY), on => number(5)]
  repeatType, // weeks, month, days
  repeatVal, // number
  preferredDays, // []
  preferredWeeks, // []
  dates = []
) => {
  let endDate;
  const preferrdDaysArr = sortNumbers(preferredDays.map((day) => day.value));
  const preferrdWeeksArr = sortNumbers(preferredWeeks.map((day) => day.value));
  const currentDay = moment(startDate, "DD-MM-YYYY").day();
  const currentWeek = weekOfMonth(moment(startDate, "DD-MM-YYYY"));
  const allDates = [...dates];
  let prevDate = "";
  let previousDay = -1;
  let previousWeek = -1;
  let foundMonthDay = false;
  const isTypeDay = repeatType?.toLowerCase() === "days";
  const isTypeWeek =
    repeatType?.toLowerCase() === "weeks" ||
    repeatType?.toLowerCase() === "week";
  const isTypeMonth =
    repeatType?.toLowerCase() === "months" ||
    repeatType?.toLowerCase() === "month";
  if (
    isTypeDay ||
    (isTypeWeek &&
      (preferrdDaysArr.includes(currentDay) || preferrdDaysArr.length === 0)) ||
    (isTypeMonth &&
      (preferrdWeeksArr.includes(currentWeek) || preferrdWeeksArr.length === 0))
  ) {
    if (isTypeMonth && preferrdDaysArr.length > 0) {
      if (preferrdDaysArr.includes(currentDay)) {
        foundMonthDay = true;
        previousDay = currentDay;
        previousWeek = currentWeek;
        prevDate = startDate;
        dates.push(
          Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)
        );
        allDates.push(
          Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)
        );
      } else {
        for (let i = 0; i < preferrdDaysArr.length; i++) {
          const day = preferrdDaysArr[i];
          if (day > currentDay) {
            foundMonthDay = true;
            previousDay = currentDay;
            previousWeek = currentWeek;
            prevDate = moment(startDate, "DD-MM-YYYY")
              .day(day)
              .format("DD-MM-YYYY");
            dates.push(
              Math.floor(
                moment(startDate, "DD-MM-YYYY").day(day).toDate().getTime() /
                  1000
              )
            );
            allDates.push(
              Math.floor(
                moment(startDate, "DD-MM-YYYY").day(day).toDate().getTime() /
                  1000
              )
            );
          }
        }
      }
    } else {
      foundMonthDay = true;
      previousDay = currentDay;
      previousWeek = currentWeek;
      prevDate = startDate;
      dates.push(
        Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)
      );
      allDates.push(
        Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)
      );
    }
  } else {
    if (isTypeWeek && !preferrdDaysArr.includes(currentDay)) {
      let isfound = false;
      let tempDay = -1;
      let tempDate;
      for (let i = 0; i < preferrdDaysArr.length; i++) {
        const nextDay = preferrdDaysArr[i];
        if (nextDay > currentDay) {
          isfound = true;
          const newFirstDate = moment(startDate, "DD-MM-YYYY").day(nextDay);
          previousDay = nextDay;
          prevDate = newFirstDate.format("DD-MM-YYYY");
          dates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
          allDates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
        } else {
          if (tempDay === -1 || currentDay - tempDay < currentDay - nextDay) {
            const newFirstDate = moment(startDate, "DD-MM-YYYY").day(nextDay);
            tempDay = nextDay;
            tempDate = newFirstDate;
          }
        }
      }
      if (!isfound) {
        if (tempDate) {
          previousDay = tempDay;
          prevDate = tempDate.format("DD-MM-YYYY");
        }
      }
    } else if (isTypeMonth && !preferrdWeeksArr.includes(currentWeek)) {
      let isfound = false;
      let tempWeek = -1;
      let tempDay2 = -1;
      let previousDay2 = -1;
      let tempDate2;
      foundMonthDay = true;
      if (preferrdDaysArr.length > 0) {
        previousDay = preferrdDaysArr[0];
      }
      let tempDate;
      for (let i = 0; i < preferrdWeeksArr.length; i++) {
        const nextWeek = preferrdWeeksArr[i];
        for (let i = 0; i < preferrdDaysArr.length; i++) {
          const nextDay = preferrdDaysArr[i];
          if (nextDay > currentDay) {
            isfound = true;
            const newFirstDate = moment(startDate, "DD-MM-YYYY").day(nextDay);
            previousDay2 = nextDay;
            prevDate = newFirstDate.format("DD-MM-YYYY");
            let st_date = moment(startDate, "DD-MM-YYYY").day();
            let cur_month = moment(startDate, "DD-MM-YYYY")
              .startOf("month")
              .week(
                moment(startDate, "DD-MM-YYYY").startOf("month").week() +
                  (nextWeek - 1)
              )
              .day(nextDay);
            const secondFirstDate = moment(startDate, "DD-MM-YYYY").add(
              Number(repeatVal),
              repeatType
            );
            let next_month = secondFirstDate
              .startOf("month")
              .week(secondFirstDate.startOf("month").week() + (nextWeek - 1))
              .day(nextDay);
            let cur_month_ts = Math.floor(cur_month.toDate().getTime() / 1000);
            let next_month_ts = Math.floor(
              next_month.toDate().getTime() / 1000
            );
            let newFirstDate_ts = Math.floor(
              newFirstDate.toDate().getTime() / 1000
            );
            if (
              (cur_month_ts == newFirstDate_ts && next_month >= st_date) ||
              (next_month_ts == newFirstDate_ts && next_month_ts >= st_date)
            ) {
              dates.push(newFirstDate_ts);
              allDates.push(newFirstDate_ts);
            }

            //dates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
            //allDates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
          } else {
            if (
              tempDay2 === -1 ||
              currentDay - tempDay2 < currentDay - nextDay
            ) {
              const newFirstDate2 = moment(startDate, "DD-MM-YYYY").day(
                nextDay
              );
              tempDay2 = nextDay;
              tempDate2 = newFirstDate2;
            }
          }
        }
        if (nextWeek > currentWeek) {
          foundMonthDay = true;
          isfound = true;
          let newFirstDate = moment(startDate, "DD-MM-YYYY")
            .startOf("week")
            .week(
              moment(startDate, "DD-MM-YYYY").startOf("week").week() +
                (nextWeek - currentWeek)
            );
          if (preferrdDaysArr.length > 0) {
            newFirstDate = newFirstDate.startOf("week").day(previousDay);
          }
          previousWeek = nextWeek;
          prevDate = newFirstDate.format("DD-MM-YYYY");
          dates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
          allDates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
          // break;
        } else {
          if (
            tempWeek === -1 ||
            currentWeek - tempWeek < currentWeek - nextWeek
          ) {
            let newFirstDate = moment(startDate, "DD-MM-YYYY")
              .startOf("month")
              .add(1, "month")
              .startOf("month")
              .week(
                moment(startDate, "DD-MM-YYYY")
                  .startOf("month")
                  .add(1, "month")
                  .startOf("month")
                  .week() +
                  (nextWeek - 1)
              );
            if (preferrdDaysArr.length > 0) {
              newFirstDate = newFirstDate.startOf("week").day(previousDay);
            }
            tempWeek = nextWeek;
            tempDate = newFirstDate;
          }
        }
      }
      if (!isfound) {
        if (tempDate) {
          foundMonthDay = true;
          previousWeek = tempWeek;
          prevDate = tempDate.format("DD-MM-YYYY");
          dates.push(Math.floor(tempDate.toDate().getTime() / 1000));
          allDates.push(Math.floor(tempDate.toDate().getTime() / 1000));
        }
      }
    }
  }
  if (isTypeMonth && !foundMonthDay) {
    previousDay = preferrdDaysArr[0];
    let newFirstDate = moment(startDate, "DD-MM-YYYY")
      .startOf("month")
      .add(1, "month")
      .startOf("month")
      .week(
        moment(startDate, "DD-MM-YYYY")
          .startOf("month")
          .add(1, "month")
          .startOf("month")
          .week() +
          (currentWeek - 1)
      );
    if (preferrdDaysArr.length > 0) {
      newFirstDate = newFirstDate.startOf("week").day(previousDay);
    }
    previousWeek = currentWeek;
    prevDate = newFirstDate.format("DD-MM-YYYY");
    dates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
    allDates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
  }
  const isEndTypeDate = endType === "Calendar";
  if (isTypeWeek || isTypeMonth) {
    if (Number(repeatVal) > 1 && dates.length === 0) {
      const date = moment(prevDate, "DD-MM-YYYY").add(Number(1), repeatType);
      if (isTypeWeek) {
        if (preferrdDaysArr.length > 0) {
          preferrdDaysArr.forEach((day) => {
            const weekDay = moment(date).day(day);
            const timeStamp = Math.floor(weekDay.toDate().getTime() / 1000);
            // if (day === previousDay) {
            dates.push(timeStamp);
            // }
            allDates.push(timeStamp);
          });
        } else {
          const timeStamp = Math.floor(date.toDate().getTime() / 1000);
          dates.push(timeStamp);
          allDates.push(timeStamp);
        }
        prevDate = date.format("DD-MM-YYYY");
      } else {
      }
    }
  }
  if (isEndTypeDate) {
    endDate = moment(endAfter, "DD-MM-YYYY");
    let tilldate = 0;
    let date;
    let isexit = 0;
    let isFirst = 0;
    while (true) {
      if (dates.length > 1 || tilldate == 1 || repeatType == "months") {
        date = moment(prevDate, "DD-MM-YYYY").add(
          Number(repeatVal),
          repeatType
        );
      }
      if (
        ((dates.length == 1 || dates.length == 0) &&
          tilldate == 0 &&
          repeatType != "months") ||
        (repeatType == "months" && isFirst == 0)
      ) {
        isFirst = 1;
        date = moment(startDate, "DD-MM-YYYY");
      }
      if (endDate < date && isexit == 0) {
        date = endDate;
        isexit = 1;
      }
      if (endDate.diff(date, "days") < 0) {
        isexit = 0;
        // let newdates=[]
        // dates.forEach(newdatevalue => {
        //   if(newdatevalue>= Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)){
        //     newdates.push(newdatevalue)
        //   }
        // });
        // dates=newdates
        const startdate_timeStamp = Math.floor(
          moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
        );
        if (endDate !== undefined) {
          let enddate_timeStamp = Math.floor(endDate.toDate().getTime() / 1000);
          dates = dates.filter(
            (dt) => startdate_timeStamp <= dt && enddate_timeStamp >= dt
          );
        }
        return [dates, allDates];
      }
      if (isTypeDay) {
        const timeStamp = Math.floor(date.toDate().getTime() / 1000);
        prevDate = date.format("DD-MM-YYYY");
        allDates.push(timeStamp);
        dates.push(timeStamp);
      } else if (isTypeWeek) {
        if (preferrdDaysArr.length > 0) {
          preferrdDaysArr.forEach((day) => {
            const weekDay = moment(date).day(day);
            const timeStamp = Math.floor(weekDay.toDate().getTime() / 1000);
            const startdate_timeStamp = Math.floor(
              moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
            );
            endDate = moment(endAfter, "DD-MM-YYYY");
            const enddate_timeStamp = Math.floor(
              endDate.toDate().getTime() / 1000
            );
            if (
              startdate_timeStamp <= timeStamp &&
              enddate_timeStamp >= timeStamp
            ) {
              dates.push(timeStamp);
            }
            if (
              dates.length == 1 ||
              preferrdDaysArr.filter((x) => x > date.day()).length == 0
            ) {
              tilldate = 1;
            }
            allDates.push(timeStamp);
          });
        } else {
          const timeStamp = Math.floor(date.toDate().getTime() / 1000);
          dates.push(timeStamp);
          allDates.push(timeStamp);
        }
        prevDate = date.format("DD-MM-YYYY");
      } else {
        // if (preferrdWeeksArr.length > 0) {
        //   if (preferrdDaysArr.length > 0) {
        //     const newFirstDate = moment(date, "DD-MM-YYYY")
        //       .startOf("month")
        //       .week(
        //         moment(date, "DD-MM-YYYY").startOf("month").week() +
        //           (previousWeek - 1)
        //       )
        //       .day(previousDay);
        //     const timeStamp = Math.floor(
        //       newFirstDate.toDate().getTime() / 1000
        //     );
        //     dates.push(timeStamp);
        //     allDates.push(timeStamp);
        //   }
        //   else {
        //     let newFirstDate = moment(date, "DD-MM-YYYY")
        //       .startOf("month")
        //       .week(
        //         moment(date, "DD-MM-YYYY").startOf("month").week() +
        //           (previousWeek - 1)
        //       );
        //     const timeStamp = Math.floor(
        //       newFirstDate.toDate().getTime() / 1000
        //     );
        //     dates.push(timeStamp);
        //     allDates.push(timeStamp);
        //   }
        // }
        if (preferrdWeeksArr.length > 0) {
          preferrdWeeksArr.forEach((w) => {
            if (preferrdDaysArr.length > 0) {
              preferrdDaysArr.forEach((d) => {
                const newFirstDate = moment(date, "DD-MM-YYYY")
                  .startOf("month")
                  .week(
                    moment(date, "DD-MM-YYYY").startOf("month").week() + (w - 1)
                  )
                  .day(d);
                const timeStamp = Math.floor(
                  newFirstDate.toDate().getTime() / 1000
                );
                const startdate_timeStamp = Math.floor(
                  moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
                );
                endDate = moment(endAfter, "DD-MM-YYYY");
                const enddate_timeStamp = Math.floor(
                  endDate.toDate().getTime() / 1000
                );
                if (
                  startdate_timeStamp <= timeStamp &&
                  enddate_timeStamp >= timeStamp
                ) {
                  dates.push(timeStamp);
                }
                allDates.push(timeStamp);
              });
            } else {
              let newFirstDate = moment(date, "DD-MM-YYYY")
                .startOf("month")
                .week(
                  moment(date, "DD-MM-YYYY").startOf("month").week() + (w - 1)
                );
              const timeStamp = Math.floor(
                newFirstDate.toDate().getTime() / 1000
              );
              const startdate_timeStamp = Math.floor(
                moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
              );
              endDate = moment(startDate, "DD-MM-YYYY").add(
                Number(endAfter),
                repeatType
              );
              const enddate_timeStamp = Math.floor(
                endDate.toDate().getTime() / 1000
              );
              if (
                startdate_timeStamp <= timeStamp &&
                enddate_timeStamp >= timeStamp
              ) {
                dates.push(timeStamp);
              }
              allDates.push(timeStamp);
            }
          });
        } else {
          const timeStamp = Math.floor(date.toDate().getTime() / 1000);
          dates.push(timeStamp);
          allDates.push(timeStamp);
        }
        prevDate = date.format("DD-MM-YYYY");
      }
    }
  }
  let tilldate = 0;

  for (let i = 0; i <= Number(endAfter); i++) {
    let date;
    if (dates.length > 1 || tilldate == 1 || repeatType == "months") {
      date = moment(prevDate, "DD-MM-YYYY").add(Number(repeatVal), repeatType);
    }
    if (
      ((dates.length == 1 || dates.length == 0) &&
        tilldate == 0 &&
        repeatType != "months") ||
      (repeatType == "months" && i == 0)
    ) {
      date = moment(startDate, "DD-MM-YYYY");
    }
    if (isTypeDay) {
      const timeStamp = Math.floor(date.toDate().getTime() / 1000);
      prevDate = date.format("DD-MM-YYYY");
      allDates.push(timeStamp);
      dates.push(timeStamp);
    } else if (isTypeWeek) {
      if (preferrdDaysArr.length > 0) {
        preferrdDaysArr.forEach((day) => {
          const weekDay = moment(date).day(day);
          const timeStamp = Math.floor(weekDay.toDate().getTime() / 1000);
          const startdate_timeStamp = Math.floor(
            moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
          );
          endDate = moment(
            moment(startDate, "DD-MM-YYYY").add(Number(endAfter), repeatType),
            "DD-MM-YYYY"
          );
          const enddate_timeStamp = Math.floor(
            endDate.toDate().getTime() / 1000
          );
          if (
            startdate_timeStamp <= timeStamp &&
            enddate_timeStamp >= timeStamp &&
            !(
              enddate_timeStamp == timeStamp &&
              dates.filter((x) => x == startdate_timeStamp).length > 0
            )
          ) {
            dates.push(timeStamp);
          }
          if (
            dates.length == 1 ||
            preferrdDaysArr.filter((x) => x > date.day()).length == 0
          ) {
            tilldate = 1;
          }
          allDates.push(timeStamp);
        });
      } else {
        const timeStamp = Math.floor(date.toDate().getTime() / 1000);
        endDate = moment(startDate, "DD-MM-YYYY").add(
          Number(endAfter),
          repeatType
        );
        const startdate_timeStamp = Math.floor(
          moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
        );
        const enddate_timeStamp = Math.floor(endDate.toDate().getTime() / 1000);
        if (
          startdate_timeStamp <= timeStamp &&
          enddate_timeStamp >= timeStamp &&
          !(
            enddate_timeStamp == timeStamp &&
            dates.filter((x) => x == startdate_timeStamp).length > 0
          )
        ) {
          dates.push(timeStamp);
        }

        allDates.push(timeStamp);
      }
      prevDate = date.format("DD-MM-YYYY");
    } else {
      if (preferrdWeeksArr.length > 0) {
        preferrdWeeksArr.forEach((w) => {
          if (preferrdDaysArr.length > 0) {
            preferrdDaysArr.forEach((d) => {
              const newFirstDate = moment(date, "DD-MM-YYYY")
                .startOf("month")
                .week(
                  moment(date, "DD-MM-YYYY").startOf("month").week() + (w - 1)
                )
                .day(d);
              const timeStamp = Math.floor(
                newFirstDate.toDate().getTime() / 1000
              );
              const startdate_timeStamp = Math.floor(
                moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
              );
              endDate = moment(startDate, "DD-MM-YYYY").add(
                Number(endAfter),
                repeatType
              );
              const enddate_timeStamp = Math.floor(
                endDate.toDate().getTime() / 1000
              );
              if (
                startdate_timeStamp <= timeStamp &&
                enddate_timeStamp >= timeStamp &&
                !(
                  enddate_timeStamp == timeStamp &&
                  dates.filter((x) => x == startdate_timeStamp).length > 0
                )
              ) {
                dates.push(timeStamp);
              }
              allDates.push(timeStamp);
            });

            // const newFirstDate = moment(date, "DD-MM-YYYY")
            //   .startOf("month")
            //   .week(
            //     moment(date, "DD-MM-YYYY").startOf("month").week() +
            //       (previousWeek - 1)
            //   )
            //   .day(previousDay);
            // const timeStamp = Math.floor(newFirstDate.toDate().getTime() / 1000);
            // const startdate_timeStamp = Math.floor((moment(startDate, "DD-MM-YYYY")).toDate().getTime() / 1000);
            // endDate= moment(startDate, "DD-MM-YYYY").add(
            //   Number(endAfter),
            //   repeatType
            // );
            // const enddate_timeStamp	=Math.floor(endDate.toDate().getTime() / 1000);
            // if(startdate_timeStamp<= timeStamp && enddate_timeStamp>=timeStamp
            //   && !(enddate_timeStamp==timeStamp && dates.filter(x => x == startdate_timeStamp).length>0)){
            //   dates.push(timeStamp);
            // }
            // allDates.push(timeStamp);
          } else {
            let newFirstDate = moment(date, "DD-MM-YYYY")
              .startOf("month")
              .week(
                moment(date, "DD-MM-YYYY").startOf("month").week() + (w - 1)
              );
            const timeStamp = Math.floor(
              newFirstDate.toDate().getTime() / 1000
            );
            const startdate_timeStamp = Math.floor(
              moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
            );
            endDate = moment(startDate, "DD-MM-YYYY").add(
              Number(endAfter),
              repeatType
            );
            const enddate_timeStamp = Math.floor(
              endDate.toDate().getTime() / 1000
            );
            if (
              startdate_timeStamp <= timeStamp &&
              enddate_timeStamp >= timeStamp &&
              !(
                enddate_timeStamp == timeStamp &&
                dates.filter((x) => x == startdate_timeStamp).length > 0
              )
            ) {
              dates.push(timeStamp);
            }
            allDates.push(timeStamp);
          }
        });
      } else {
        const timeStamp = Math.floor(date.toDate().getTime() / 1000);
        endDate = moment(startDate, "DD-MM-YYYY").add(
          Number(endAfter),
          repeatType
        );
        const startdate_timeStamp = Math.floor(
          moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
        );
        const enddate_timeStamp = Math.floor(endDate.toDate().getTime() / 1000);
        if (
          startdate_timeStamp <= timeStamp &&
          enddate_timeStamp >= timeStamp &&
          !(
            enddate_timeStamp == timeStamp &&
            dates.filter((x) => x == startdate_timeStamp).length > 0
          )
        ) {
          dates.push(timeStamp);
        }
        //dates.push(timeStamp);
        allDates.push(timeStamp);
      }
      prevDate = date.format("DD-MM-YYYY");
    }
  }
  const startdate_timeStamp = Math.floor(
    moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
  );
  if (endDate !== undefined) {
    let enddate_timeStamp = Math.floor(endDate.toDate().getTime() / 1000);
    dates = dates.filter(
      (dt) => startdate_timeStamp <= dt && enddate_timeStamp >= dt
    );
  }
  const a = dates?.sort((a, b) => b - a);
  return [a, allDates];
};

export const diffeenceBtArray = (arr1, arr2) => {
  return arr1
    .filter((x) => !arr2.includes(x))
    .concat(arr2.filter((x) => !arr1.includes(x)));
};

export function getRole(db_role) {
  const infos = {
    frontdesk: "frontdesk",
    receptionist: "frontdesk",
    physician: "doctor",
    surgeon: "doctor",
    consultant: "doctor",
    assistant: "doctor",
    paediatrician: "doctor",
    nephrologist: "doctor",
    neurologist: "doctor",
    cardiologist: "doctor",
    medicaladministrator: "admin",
    admin: "admin",
    midwife: "doctor",
    matron: "doctor",
    radiologist: "doctor",
    nursingofficer: "nurse",
    nurse: "nurse",
    studentnurse: "nurse",
    intern: "doctor",
    dermatologist: "doctor",
    anaesthetist: "doctor",
    labtechnician: "technician",
    orthopaedician: "doctor",
    urologist: "doctor",
    doctor: "doctor",
  };
  return infos[db_role?.toLowerCase()?.split(" ")?.join("")] || "doctor";
}

//order
export function getOrderName(val = {}, loggedName) {
  const { accessionNum, userName } = val;
  if (accessionNum) {
    if (accessionNum === loggedName) {
      return accessionNum + " (You)";
    }
    return accessionNum;
  }

  if (userName) {
    if (userName === loggedName) {
      return userName + " (You)";
    }
    return userName;
  }
  return loggedName + " (You)";
}

export async function createFile(url, type, name) {
  let response = await fetch(url);
  let data = await response.blob();
  let metadata = {
    type: type,
  };
  let file = new File([data], `${name}.${type.split("/")[1]}`, metadata);
  let Url = ToBase64(file);
  return Url;
}

export const getTimeSlot = (date, type = "date", isUpdate = false) => {
  const currentTime = new Date();
  let comparedate = date;
  if (type === "unix") {
    comparedate = convertTime(date, type, "DD-MM-YYYY");
  }
  let time = 0;
  // if (!date) {
  //   comparedate = this.state.date;
  // }
  if (moment().format("DD-MM-YYYY") === comparedate || isUpdate) {
    let currentHour = currentTime.getHours();
    if (isUpdate) {
      currentHour = new Date(date * 1000).getHours();
    }
    if (currentHour <= 12) {
      time = 0;
    } else if (currentHour > 12 && currentHour <= 15) {
      time = 1;
    } else if (currentHour > 15 && currentHour <= 18) {
      time = 2;
    } else {
      time = 3;
    }
  }
  return time;
};

export const getTimeString = (hour, minutes) => {
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (hour === 0) {
    return `12:${minutes} AM`;
  }
  if (hour === 12) {
    return `12:${minutes} PM`;
  }
  if (hour <= 11) {
    if (hour < 10) {
      hour = `0${hour}`;
    }
    return `${hour}:${minutes} AM`;
  }
  return `${hour - 12 < 10 ? "0" : ""}${hour - 12}:${minutes} PM`;
};
//book appointment
export const findAge = (dates) => {
  dates = moment(dates, "DD-MM-YYYY").format("MM-DD-YYYY");
  const days = moment().diff(dates, "days");
  const months = moment().diff(dates, "months");
  const years = moment().diff(dates, "years");
  if (days <= 90) {
    return days + ` ${days <= 1 ? "Day" : "Days"}`;
  } else if (days > 90 && days < 365) {
    return months + " months";
  } else if (days >= 365) {
    return years + ` ${years <= 1 ? "Yr" : "Yrs"}`;
  }
};

//up arrow and down arrow to select
export const keySelection = (divWrap, childName, selectionName, classlist) => {
  if (divWrap?.getElementsByClassName(selectionName)?.length === 0) {
    divWrap.getElementsByClassName(classlist)[0].classList.add(selectionName);
  } else {
    let child =
      childName === "next"
        ? divWrap?.getElementsByClassName(selectionName)[0]?.nextElementSibling
        : divWrap.getElementsByClassName(selectionName)[0]
            .previousElementSibling;
    if (child) {
      child.classList.add(selectionName);
      child.scrollIntoViewIfNeeded(false);
      divWrap
        .getElementsByClassName(selectionName)
        [childName === "next" ? 0 : 1].classList.remove(selectionName);
    } else {
      divWrap.scrollTo({
        top: childName === "next" ? divWrap.scrollHeight : 0,
      });
    }
  }
};
//app auth
// Check the versions
export let semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA ? versionA.split(/\./g) : ["0", "0", "0"];
  const versionsB = versionB ? versionB.split(/\./g) : ["0", "0", "0"];

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};
// Refresh the cache by clearing the cache and reload
export const refreshCacheAndReload = async () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    const names = await caches.keys();
    const promArr = [];
    for (const name of names) {
      promArr.push(caches.delete(name));
    }
    await Promise.all(promArr);
  }
  // delete browser cache and hard reload
  window.location.reload(true);
};

// Vitala view json
export const processData = (data = {}) => {
  const component = data?.component || [];
  let obj = {};
  component.forEach((c) => {
    const newObj = {
      [c.obscode]: `${c.obsvalue}`,
      [`${c.obscode}error`]: "true",
      [`${c.obscode}uom`]: c.abnormalmsg ? JSON.parse(c.abnormalmsg) : "",
      [`${c.obscode}normalorabnormal`]: c.isabnormal ? "true" : "false",
    };
    obj = { ...newObj, ...obj };
  });
  return obj;
};
//src\components\specimenRequired\index.js
export const trimText = (text = "", length = 20) => {
  if (typeof text !== "string") {
    return "";
  }
  return text.length > length ? text.slice(0, length - 3) + "..." : text;
};
//src\screens\dashboard\components\profileCard.js
export const profileFirstName = (profileName) => {
  const myArray = profileName.split(".");
  return myArray[1];
};
//src\screens\patientDetail\followupfooter.js
//src\screens\patientDetail\cheifComplaintsFlow\cheifComplaintsForm.js
//src\screens\patientDetail\diagnosisFlow\diagnosisForm.js
export const isIntegerValue = (value) => {
  const integerPattern = /^[0-9]*$/;
  return integerPattern.test(value);
};

//src\screens\assembleRegistration\patientDetails\index.js
// for generating a file on uploading a url in bas64
export const dataUrlToFile = async (dataUrl, fileName, mimeType) => {
  const res = await fetch(dataUrl);
  const blob = res.data;
  return new File([blob], fileName, { type: mimeType });
};
export const getCurrentMinTime = (date) => {
  if (date === null || date?.getDate() === new Date().getDate()) {
    return setHours(
      setMinutes(new Date(), new Date().getMinutes()),
      new Date().getHours()
    );
  } else {
    return setHours(setMinutes(new Date(), 0), 0);
  }
};
export const getStartEndMinTime = (date, type, baseStartDate, baseEndDate) => {
  if (type === "startDate") {
    if (date.substring(0, 10) === baseStartDate.substring(0, 10)) {
      const newDate = moment(new Date()).format("YYYY-MM-DD,hh:mm A");
      const setDates = newDate.replace(",", " ");
      const hours = new Date(setDates).getHours();
      const minutes = new Date(setDates).getMinutes();
      return setHours(setMinutes(new Date(), minutes), hours);
    } else {
      return setHours(setMinutes(new Date(), 0), 0);
    }
  } else {
    if (
      baseEndDate.substring(0, 10) ===
      (date.length > 0 ? date.substring(0, 10) : baseEndDate.substring(0, 10))
    ) {
      const newDate = moment(new Date())
        .format("YYYY-MM-DD,hh:mm A")
        .replace(",", " ");
      const newDateUnix = moment(newDate, "YYYY-MM-DD hh:mm A").unix();

      const minEndTime = baseEndDate.replace(",", " ");
      const minEndTimeUnix = moment(minEndTime, "YYYY-MM-DD hh:mm A").unix();
      const hours = new Date(
        newDateUnix > minEndTimeUnix ? newDate : minEndTime
      ).getHours();
      const minutes = new Date(
        newDateUnix > minEndTimeUnix ? newDate : minEndTime
      ).getMinutes();
      return setHours(setMinutes(new Date(minEndTime), minutes), hours);
    } else {
      return setHours(setMinutes(new Date(), 0), 0);
    }
  }
};

export const getDateAndTime = (date, noDate, monthType) => {
  if (date && !noDate && !monthType) {
    return moment.unix(date).format("DD/MM/YYYY HH:mm");
  } else if (date && noDate && !monthType) {
    return moment.unix(date).format("DD/MM/YYYY");
  } else if (date && noDate && monthType) {
    return moment.unix(date).format("MMM/YYYY");
  } else {
    return "";
  }
};

export const getUnixCodeDate = (date) => {
  if (date) {
    return moment(`${date}`, "DD/MM/YYYY hh:mm").unix();
  } else {
    return "";
  }
};

export const AxiosCall = async (method, url, params, header) => {
  try {
    let config = {
      method: method,
      url: `${url}`,
      headers: {
        "Content-Type": "application/json",
        ...(header && { ...header }),
      },
      data: params,
    };
    let response = await axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to read a file and convert it to a base64 string
export const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const base64string = event.target.result.split(",")[1]; // Extract base64 part
      resolve(base64string);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file); // Read file as data URL
  });
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const filterListingData = (filterList) => {
  let queryObj = {
    consultmode: ["Direct", "Video", "Both"],
    gender: "",
    lang: "",
    speciality: [],
  };
  if (filterList.consultationMode) {
    const capitalizedConsultationMode = capitalizeFirstLetter(
      filterList.consultationMode
    );
    queryObj["consultmode"] = [capitalizedConsultationMode];
  }
  if (filterList.practitionerGender) {
    const genderVal = filterList.practitionerGender.toUpperCase();
    if (genderVal === "BOTH") {
      queryObj["gender"] = ["MALE", "FEMALE"];
    } else {
      queryObj["gender"] = [genderVal];
    }
  }
  if (
    filterList.specialties &&
    Object.keys(filterList.specialties).length > 0
  ) {
    queryObj["speciality"] = Object.keys(filterList.specialties)
      .filter((k) => {
        return filterList.specialties[k];
      })
      .map((s) => Number(s));
  }
  if (
    filterList.practitionerPreferredLanguage &&
    filterList.practitionerPreferredLanguage.length > 0
  ) {
    queryObj["lang"] = filterList.practitionerPreferredLanguage.map(
      (i) => i.val
    );
  }
  return queryObj;
};

const deg2rad = (deg) => deg * (Math.PI / 180);
const getLocation = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        resolve([false, { lat, long }]);
      },
      (error) => {
        resolve([true, error]);
      }
    );
  });
};
export const getCurrentPosition = async () => {
  const permission = await navigator.permissions
    .query({ name: "geolocation" })
    .catch((err) => ({ state: "denied" }));
  let lat;
  let long;
  if (permission.state !== "denied") {
    const [err, currLocation] = await getLocation();
    if (!err) {
      lat = currLocation.lat;
      long = currLocation.long;
    }
  }
  return {
    lat,
    long,
  };
};
export const getDisctanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return parseFloat(d).toFixed(2);
};

export const getLatLong = async (city) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${city}&key=AIzaSyBqeACJgp12OLU6EkHeUtFgya2naH1LhrU`;
  const data = await fetch(url);
  const resp = await data.json();
  let lat;
  let long;
  if (
    resp?.results?.[0]?.geometry?.location?.lat &&
    resp?.results?.[0]?.geometry?.location?.lng
  ) {
    lat = resp?.results?.[0]?.geometry?.location?.lat;
    long = resp?.results?.[0]?.geometry?.location?.lng;
  }
  return {
    lat,
    long,
  };
};

export const calculationExp = (jexp) => {
  let expInMs = 0;
  jexp.forEach((exp = {}) => {
    const { start_date, end_date } = exp;
    if (typeof start_date === "number") {
      if (typeof end_date === "number") {
        if (start_date < end_date) {
          expInMs += (end_date - start_date) * 1000;
        }
      } else {
        expInMs += (Date.now() - start_date) * 1000;
      }
    }
  });
  const expInDays = moment(expInMs).diff(moment(0), "days");
  const expInMonths = moment(expInMs).diff(moment(0), "months");
  const expInYears = moment(expInMs).diff(moment(0), "years");
  if (expInYears >= 1) {
    const value = Math.floor(expInYears);
    return `${value} year` + (value > 1 ? "s" : "");
  }
  if (expInMonths >= 1) {
    const value = Math.floor(expInMonths);
    return `${value} month` + (value > 1 ? "s" : "");
  }
  if (expInDays >= 1) {
    const value = Math.floor(expInDays);
    return `${value} day` + (value > 1 ? "s" : "");
  }
  return `0 days`;
};

export const getLocationInfo = async (myLocation, city, obj = {}) => {
  if (!obj[city]) {
    const { lat, long } = myLocation;
    const info = await getLatLong(city);
    if (info.lat && info.long) {
      obj[city] = getDisctanceFromLatLonInKm(info.lat, info.long, lat, long);
    }
  }
};
export const getNumberOfDays = (start, end) => {
  var date1 = new Date(start);
  var date2 = new Date(end);
  // One day in milliseconds
  var oneDay = 1000 * 60 * 60 * 24;
  // Calculating the time difference between two dates
  var diffInTime = date2.getTime() - date1.getTime();
  // Calculating the no. of days between two dates
  let diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
};

export const weekCalculation = (date_) => {
  var d = new Date(date_);
  var date = d.getDate();
  var day = d.getDay();
  var weekOfMonth = Math.ceil((date - 1 - day) / 7);
  return weekOfMonth;
};

export const timeLine_ = (appointmentDate) => {
  //DAY CALCULATION
  var appDate = (appointmentDate, "MM/DD/YYY");
  var currentDate = utcTOLocal(new Date(), "MM/DD/YYY");
  //DAYS DIFFERENCE CALCULATION
  var diffDays = getNumberOfDays(currentDate, appDate);
  //MONTH CALCULATION
  var appointmentMonth = utcTOLocal(appointmentDate, "MM");
  var currentMonth = utcTOLocal(new Date(), "MM");
  //WEEK CALCULATION
  var appointmentWeek = weekCalculation(utcTOLocal(appointmentDate));
  var currentWeek = weekCalculation(new Date());
  let type = "";
  //TODAY
  if (diffDays === 0) {
    type = "TODAY";
  }
  //THIS MONTH
  else if (currentMonth === appointmentMonth) {
    //
    //THIS WEEK
    if (appointmentWeek === currentWeek) {
      type = "THIS_WEEK";
    }
    //LAST WEEK
    else if (appointmentWeek + 1 === currentWeek) {
      type = "LAST_WEEK";
    }
    //NEXT WEEK
    else if (appointmentWeek - 1 === currentWeek) {
      type = "NEXT_WEEK";
    }
    //THIS MONTH
    else {
      type = "THIS_MONTH";
    }
  }
  //LAST MONTH
  else if (appointmentMonth < currentMonth) {
    type = "LAST_MONTH";
  }
  //NEXT MONTH
  else if (appointmentMonth > currentMonth) {
    type = "NEXT_MONTH";
  } else {
    type = "TODAY";
  }
  //
  return type;
};

export function nameDetailsGiver(data) {
  if (!Array.isArray(data)) {
    data = [data];
  }
  let array = data.map((v) => {
    // console.log(v);
    return {
      name: [
        {
          given: v?.name?.[0].fulname,
        },
      ],
      birthDate: v?.birthdate,
      gender: v?.gender?.display,

      telecom: v?.telecom,
      photo: v?.photo,
      PersonID: v?.personid || v?._id,
      age: v?.age,
    };
  });

  return array;
}

export const _calculateAge = (birthday) => {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export function YYYYMMDD_sep_by_hyphen_HHmm_to_epoch(YYYYMMDD, hhmm) {
  const dateStr = YYYYMMDD; // Example date in "dd/mm/yyyy" format
  const timeStr = hhmm; // 11:59 PM

  const dateFormat = "YYYY-MM-DD"; // Define the input date format
  const timeFormat = "HH:mm"; // Define the input time format

  // Parse the date and time strings using Moment.js with the specified formats
  const dateTimeMoment = moment(
    `${dateStr} ${timeStr}`,
    `${dateFormat} ${timeFormat}`
  );

  // Get the epoch timestamp (in seconds) using the .unix() method
  return dateTimeMoment.unix();
}

export function MMDDYYYY_sep_by_hyphen_HHmm_to_epoch(MMDDYYYY, hhmm) {
  const dateStr = MMDDYYYY; // Example date in "dd/mm/yyyy" format
  const timeStr = hhmm; // 11:59 PM

  const dateFormat = "MM-DD-YYYY"; // Define the input date format
  const timeFormat = "HH:mm"; // Define the input time format

  // Parse the date and time strings using Moment.js with the specified formats
  const dateTimeMoment = moment(
    `${dateStr} ${timeStr}`,
    `${dateFormat} ${timeFormat}`
  );

  // Get the epoch timestamp (in seconds) using the .unix() method
  return dateTimeMoment.unix();
}

export function dateGiverForFollowUp(no, type, type_coding_date) {
  return moment().add(no, type?.label?.toLowerCase()).startOf("day").valueOf();
}


export function epochToDatetime(epochTime, isWithTime = true) {
  if (epochTime) {
    // Leverage Date object's built-in time zone handling
    let dateObject = new Date(epochTime * 1000);
  
    let day = dateObject.getDate();
    let month = dateObject.getMonth() + 1;
    let year = dateObject.getFullYear();
    let hours = dateObject.getHours();
    let minutes = dateObject.getMinutes();
  
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
  
    let formattedDatetime = "";
    if (isWithTime) {
      formattedDatetime = `${day}/${month}/${year} ${hours}:${minutes}`;
    } else {
      formattedDatetime = `${day}/${month}/${year}`;
    }
    return formattedDatetime;
  } else {
    return "";
  }
}

export function formatDateFromEpoch(epochTime) {
  // Convert epoch time to milliseconds
  const milliseconds = epochTime * 1000;

  // Create a new Date object from milliseconds
  const date = new Date(milliseconds);

  // Use the same formatting code as before
  const formattedDate = date.toLocaleString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // Use 12-hour clock format
  });

  return formattedDate;
}




export function epochToDDMonthYY(epochTimestamp) {
  //epoch to DD-MON(name)-YY
  const date = new Date(epochTimestamp * 1000);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear().toString().slice(-2);

  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

export const getDurationWithGranularity = (duration, granularity) => {
  switch (granularity?.toLowerCase()) {
    case "days":
      return duration.asDays();
    case "months":
      return duration.asMonths();
    case "weeks":
      return duration.asWeeks();
    case "years":
      return duration.asYears();
    case "hours":
      return duration.asHours();
    default:
      return duration.asDays();
  }
};
export const getFilteredData = (value, stateName, result, stateList) => {
  let data = null;
  if (result?.[value]) {
    data = stateList?.[stateName]?.data?.filter(
      (val) => val.value === result[value]
    )[0];
  }
  if (
    !data &&
    (value === "ivInfusionRateUOM" || value === "ivInfusionTimeUom")
  ) {
    data = stateList.DurationType_masters.data.filter(
      (val) => val.label === "min"
    )[0];
  }
  if (!data && value === "priority") {
    data = stateList.Priority_masters.data.filter(
      (val) => val.label === "1"
    )[0];
  }
  if (!data && value === "presMode") {
    data = stateList.OrderMode_masters.data.filter(
      (val) => val.label === "Regular"
    )[0];
  }
  return data;
};

export const getFormatForDateTimePicker = (formatType) => {
  let orgFormat = "";
  if (localStorage.getItem("orgFormat") !== "undefined") {
    orgFormat = JSON.parse(localStorage.getItem("orgFormat"));
  } else {
    orgFormat = "";
  }
  //const orgFormat = JSON.parse(localStorage.getItem("orgFormat"));

  switch (formatType) {
    case "date":
      return (
        orgFormat?.orgdateformat?.shortdesc
          ?.replace(new RegExp("Y", "g"), "y")
          ?.replace(new RegExp("D", "g"), "d") ?? "dd/MM/yyyy"
      );

    case "time":
      return (
        orgFormat?.orgtimeformat?.shortdesc
          ?.replace(new RegExp("Y", "g"), "y")
          ?.replace(new RegExp("D", "g"), "d") ?? "HH:mm"
      );

    case "datetime":
      return (
        orgFormat?.orgdatetimeformat?.shortdesc
          ?.replace(new RegExp("Y", "g"), "y")
          ?.replace(new RegExp("D", "g"), "d") ?? "dd/MM/yyyy HH:mm"
      );

    default:
      return "dd/MM/yyyy";
  }
};

export const makeNameForPract = (nameObj = {}) => {
  const {
    prefix = "",
    given = "",
    text = "",
    suffix = "",
    family = "",
  } = nameObj;
  let prefixVal = prefix?.[0]?.display || "";
  let suffixVal = suffix?.[0]?.display || "";
  return `${prefixVal && prefixVal + "."}${text && text + " "}${
    given && given + " "
  }${family && family + " "}${suffixVal && suffixVal}`;
};
export const TimeFormatConversion = (date, formatPattern) => {
  if (!date) {
    return;
  }
  let Ndate = new Date();
  if (typeof date === "number") {
    // Ndate = moment.unix(date);
    Ndate = fromUnixTime(date);
  } else {
    Ndate = Ndate;
  }

  let fmt = formatPattern ? formatPattern : "dd/MM/yyyy";
  if (formatPattern) {
    return format(new Date(Ndate), fmt);
  } else {
    return format(new Date(Ndate), fmt);
  }
};

export const isFullScreen = (val) => {
  const isFullScreen = () => {
    return (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    );
  };
  if (isFullScreen()) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  } else if (val) {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }
};

export const cuurentNextTimeslot = () => {
  const currentTime = moment();
  const startTime = moment();
  let endTime = moment();
  if (currentTime.minutes() > 30) {
    startTime.set({ minute: 0, second: 0 });
    startTime.add(1, "hour");
    endTime.set({ minute: 30, second: 0 });
    endTime.add(1, "hour");
  } else {
    startTime.set({ minute: 30, second: 0 });
    endTime.add(1, "hour");
    endTime.set({ minute: 0, second: 0 });
  }

  return `${startTime.format("hh:mm A")} - ${endTime.format("hh:mm A")}`;

  // /`${startTime.format("hh:mm A")}`;
};

export function startTime() {
  const currentTime = moment();
  const startTime = moment();
  if (currentTime.minutes() > 30) {
    startTime.set({ minute: 0, second: 0 });
    startTime.add(1, "hour");
  } else {
    startTime.set({ minute: 30, second: 0 });
  }
  return startTime.valueOf();
}

export function getDaysInMonth(year, month) {
  // Use month numbers from 0 to 11 (January is 0, December is 11)
  const lastDay = new Date(year, month + 1, 0).getDate();
  return lastDay;
}

export function getFacilityId () {
  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo = userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const facilityId = decodedUserinfo?.facilityid ?? "";
  return facilityId;
}
export function getTenantId () {
  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo = userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const facilityId = decodedUserinfo?.tenantid ?? "";
  return facilityId;
}
export function epochToYYYMMDD(epochTimestamp) {
  const date = new Date(epochTimestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds

  // Extract the year, month, and day from the Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1 and pad with leading zero
  const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero

  // Format the date string to YYYY-MM-DD
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

