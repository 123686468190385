import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import qdmstyles from "./styles2.json";
import moment from "moment";

import {
  Container,
  H6,
  Divider,
  Div,
  Avatar,
  Text,
  Icons,
  Card,
  OtpInput,
  Button,
  TapSelect,
  TermsAndConditions,
} from "qdm-component-library";
import {
  checkError,
  AlertProps,
  getMobileNumber,
  upsertPerson,
} from "../../../utils";
import { AlertContext } from "../../../context";
import { useState } from "react";
import { UIColor } from "../../../themes/theme";

function AppointmentPageDrawerOtpSection(props) {
  const [state, setState] = useState({
    otp: "",
    genderValue: "",
    firstNameValue: "",
    resend: "",
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    const { otp } = state;
    const addmmber = props;
    if (addmmber) {
      const checkmmr = await props.SIGN_IN({
        mobileNo: getMobileNumber(props.signUpData?.mobileNo),
        otp,
      });
      if (checkmmr.payload.data.error === "invalid_grant") {
        // context.setSnack({
        //   open: true,
        //   severity: AlertProps.severity.error,
        //   msg: checkmmr.payload.data.error,
        //   vertical: AlertProps.vertical.top,
        //   horizontal: AlertProps.horizontal.center,
        //   tone: true,
        // });
        return false;
      } else {
        let userId = isNaN(Number(localStorage.getItem("idval")))
          ? 0
          : Number(localStorage.getItem("idval"));
        const relatedInput = {
          phone: getMobileNumber(props.signUpData?.mobileNo),
          dob: props.signUpData?.dob,
          firstName: props.signUpData?.firstName,
          lastName: props.signUpData?.lastName,
          gender: props.signUpData?.gender,
          email: props.signUpData?.emailId || "",
          relationType: state.genderValue,
          relationPersonId: [userId],
          personId: props.signUpData?.id,
        };

        await props.ADD_RELATED_PERSON(relatedInput);
        // if(addmmrss){
        //   console.log('p')
        // }
        props.nextSection("member_succss", props.signUpData);
      }

      return false;
    }

    await props.SIGN_IN({
      mobileNo: `+${
        props.signUpData?.mobileNo?.countryData?.callingCodes ?? "00"
      }${props.signUpData?.mobileNo.mobileNo}`,
      otp,
    });
    const status = checkError(props.signIn);
    if (!status.isError) {
      const { mobileNo, firstName, lastName, dob, gender, emailId } =
        props?.signUpData;
      const guestBookerAdd = {
        phone: getMobileNumber(mobileNo),
        dob,
        firstName,
        lastName,
        gender,
        email: emailId,
      };
      if (props?.signIn?.data?.access_token) {
        localStorage.removeItem("guestInfo");
        localStorage.setItem("token", props?.signIn?.data?.access_token);
      }
      const { error, data } = await upsertPerson(
        props.READ_PERSON,
        props.UPSERT_PERSON,
        guestBookerAdd
      );
      if (error && data.includes("User Already Exists ")) {
        // context.setSnack({
        //   open: true,
        //   severity: AlertProps.severity.error,
        //   msg: "Member Already Exists. Please try Sign Up using existing member",
        //   vertical: AlertProps.vertical.top,
        //   horizontal: AlertProps.horizontal.center,
        //   tone: true,
        // });
      } else if (error) {
        // context.setSnack({
        //   open: true,
        //   severity: AlertProps.severity.error,
        //   msg: data,
        //   vertical: AlertProps.vertical.top,
        //   horizontal: AlertProps.horizontal.center,
        //   tone: true
        // })
        setState({
          ...state,
          error: status.errMsg,
        });
      } else {
        const personId = data.payload?.data?.id || 0;
        localStorage.setItem("idval", personId);
        props.nextSection("movenextscreen");
      }
    } else {
      setState({
        ...state,
        error: status.errMsg,
      });
    }
  };

  const getAge = (date) => {
    return (
      Math.floor(
        moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
      ) + "Yrs"
    );
  };
  const resendOTP = async () => {
    const res = await props.RESEND_OTP({
      mobileNo: `+${
        props.signUpData?.mobileNo?.countryData?.callingCodes ?? "00"
      }${props.signUpData?.mobileNo.mobileNo}`,
    });
    const status = checkError(props.resendOTP);
    if (status.isError) {
      // context.setSnack({
      //   open: true,
      //   severity: AlertProps.severity.error,
      //   msg: status.errMsg,
      //   vertical: AlertProps.vertical.top,
      //   horizontal: AlertProps.horizontal.center,
      //   tone: true
      // })
    } else {
      setState({
        ...state,
        resend: res?.payload?.data?.message,
      });
    }
  };

  const { appointmentDetails, addmmber, parent_id } = props;
  return (
    <div id={`${parent_id}_addmember_otp_parent_div`}>
      <Container
        id={`${parent_id}_addmember_otp_parent_container`}
        key={"0"}
        style={{ marginTop: "6%" }}
      >
        <Div
          id={`${parent_id}_addmember_otp_newmember_div`}
          style={addmmber ? qdmstyles.de_da__c_cd : {}}
        >
          <Text
            id={`${parent_id}_addmember_otp_newmember_text`}
            key={"0"}
            name={appointmentDetails ? "Add Member" : "Sign Up as New Member"}
            style={!addmmber ? qdmstyles.dNMp : qdmstyles.dNMp1}
          ></Text>
          {addmmber && (
            <Div id={`${parent_id}_addmember_otp_back_div`} key={"1"}>
              <Button
                id={`${parent_id}_addmember_otp_back_buttom`}
                key={"0"}
                name={"Back"}
                style={qdmstyles.b__ae_ba_fcfdfc}
                onClick={() => props.nextSection("back")}
              ></Button>
            </Div>
          )}
        </Div>
        <Divider
          id={`${parent_id}_addmember_otp_back_bottom_divider`}
          key={"1"}
          style={{ marginLeft: 0, marginRight: 0 }}
        ></Divider>

        <Div
          id={`${parent_id}_addmember_otp_mobile_div`}
          key={"2"}
          className={"qdm"}
          style={qdmstyles.vGaal}
        >
          <Div
            id={`${parent_id}_addmember_otp_mobile_sub_div`}
            key={"0"}
            className={"qdm"}
            style={qdmstyles.nilbd}
          >
            <Avatar
              id={`${parent_id}_addmember_otp_image`}
              key={"0"}
              variant={"circle"}
              letter={"Avatar"}
              src={"https://i.pravatar.cc/150?img=65"}
              alt={"Image"}
              // style={qdmstyles.ddd_dc_c_ee_dbb}
              style={{
                margin: "0px",
                marginRight: "12px",
                height: "40px",
                width: "40px",
              }}
            ></Avatar>

            <Div id={`${parent_id}_addmember_otp_signup_div`} key={"1"}>
              <H6
                id={`${parent_id}_addmember_otp_signup_h6`}
                key={"0"}
                name={`${props.signUpData?.firstName} ${props?.signUpData?.middleName} ${props.signUpData?.lastName}`}
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#101010",
                }}
              />

              <Text
                id={`${parent_id}_addmember_otp_signup_text`}
                key={"1"}
                name={`${getAge(props.signUpData?.dob)} ${
                  props.signUpData?.gender
                }, +${
                  props.signUpData?.mobileNo?.countryData?.callingCodes ?? "00"
                }${props.signUpData?.mobileNo?.mobileNo ?? "XXXXXXXXX"}, ${
                  props.signUpData?.emailId
                }`}
                style={qdmstyles.dlAv}
              ></Text>
            </Div>
          </Div>

          {!addmmber && (
            <Icons
              id={`${parent_id}_addmember_otp_pencil_icon`}
              key={"1"}
              fontIcon={"pencil"}
              ariaHidden={"true"}
              size={"large"}
              style={qdmstyles.fecea_ab__d_deed}
              onClick={() =>
                props.nextSection("addmemberform", props.signUpData)
              }
            ></Icons>
          )}
        </Div>
        {addmmber && (
          <>
            <div
              id={`${parent_id}_addmember_otp_relationship_div`}
              style={qdmstyles.dda_e_dc_a_afcccbd}
            >
              What's your relationship with {props.signUpData?.firstName} ?{" "}
              <span
                id={`${parent_id}_addmember_otp_relationship_start_span`}
                style={{ color: "red" }}
              >
                *
              </span>
            </div>
            <TapSelect
              id={`${parent_id}_addmember_otp_relationship_tapselect`}
              key={"4"}
              fullWidth={false}
              label={false}
              selectedBorderColor="#0071F2"
              selectedBackgroundColor="#0071F2"
              selectedTextColor="#fff"
              textSize="16"
              iconHeight="20"
              iconWidth="20"
              textColor="#000"
              options={[
                { value: "Father", label: "Father" },
                { value: "Mother", label: "Mother" },
                { value: "Brother", label: "Brother" },
                { value: "Sister", label: "Sister" },
                { value: "Others", label: "Others" },
              ]}
              value={{
                value: state.genderValue,
                label: state.genderValue,
              }}
              onChange={(e) => changeState("genderValue", e.value)}
              style={{ marginTop: "", paddingTop: "" }}
              defaultSelected={{
                value: state.genderValue,
                label: state.genderValue,
              }}
              inLineStyles={{
                borderColor: "#E0E0E0",
                fontSize: "14px",
                fontFamily: "'pc_regular' !important",
              }}
              // style={qdmstyles.SREcn}
            ></TapSelect>

            {/* <TextInput
                key={'0'}
                type={'text'}
                name={'text'}
                variant={'outlined'}
                helperTextType={'error'}
                label={
                  <div style={qdmstyles.dda_e_dc_a_afcccbd1}>
                    Mail Id (optional)
                  </div>
                }
                autoFocus={false}
                autoComplete={'off'}
                search={false}
                borderColor={'#E0E0E0'}
                elevation={'0'}
                placeholder={''}
                size={'medium'}
                value={state.firstNameValue}
                onChange={e =>
                  changeState('firstNameValue', e.target.value)
                }
                error={state?.error?.firstNameValue}
              ></TextInput> */}
            <br />
            <TermsAndConditions
              id={`${parent_id}_addmember_otp_send_appointment_terms`}
              value={true}
              checkboxTickColor={"#fff"}
              activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
              checkboxBorderColor={UIColor.lineBorderFill.color}
              checkboxBackgroundColor={UIColor.differentiationBackground.color}
              activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
              disabled
              inLineStyles={{
                fontSize: "1px",
                marginTop: "-10px",
                marginBottom: "28px",
              }}
              lable={
                <span
                  id={`${parent_id}_addmember_otp_send_appointment_span`}
                  style={{ fontSize: "12px", color: "#6F6F6F" }}
                >
                  Send appointment update and notification to this mail id
                </span>
              }
            />
          </>
        )}
        <Div
          id={`${parent_id}_addmember_otp_verification_div`}
          key={"3"}
          className={"qdm"}
          style={qdmstyles.adff___ada_bfbec}
        >
          <Card
            id={`${parent_id}_addmember_otp_verification_card`}
            key={"0"}
            children={"Card"}
            style={qdmstyles.eca__f_f_fabe}
          >
            <Text
              id={`${parent_id}_addmember_otp_verification_text`}
              key={"0"}
              name={"OTP Verification"}
              style={qdmstyles.ffac_b__d_fc}
            ></Text>
            <Divider
              id={`${parent_id}_addmember_otp_verification_bottom_divider`}
              style={{ backgroundColor: "#F0F0F0" }}
            ></Divider>

            <Text
              id={`${parent_id}_addmember_otp_verification_otpsend_text`}
              key={"1"}
              name={"Please enter the OTP sent to"}
              style={qdmstyles.ee_eea_c_a_eaffe}
            ></Text>

            <H6
              id={`${parent_id}_addmember_otp_mobileno_h6`}
              key={"2"}
              name={props.signUpData?.mobileNo?.mobileNo ?? "99******66"}
              style={qdmstyles.cbfd_a_f_d_ecaf}
            ></H6>

            <Div
              id={`${parent_id}_addmember_otp_div`}
              key={"3"}
              className={"qdm"}
              style={qdmstyles.fabbd_b___dfb}
            >
              <OtpInput
                id={`${parent_id}_addmember_otp_otpinput`}
                numberOfInputs={6}
                key={"0"}
                style={{ backgroundColor: "#f5faff" }}
                value={state.otp}
                onChange={(e) => changeState("otp", e)}
              ></OtpInput>
            </Div>

            <Text
              id={`${parent_id}_addmember_otp_didn't_text`}
              key={"4"}
              name={
                <span id={`${parent_id}_addmember_otp_didn't_span`}>
                  Didn't receive OTP ?{" "}
                  <span
                    id={`${parent_id}_addmember_otp_resend_span`}
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => resendOTP()}
                  >
                    Resend OTP
                  </span>
                </span>
              }
              style={qdmstyles.bfa_d_b_d_bbfd}
            ></Text>
            {state.resend && (
              <Text
                id={`${parent_id}_addmember_otp_resend_text`}
                name={
                  <span style={qdmstyles.bfa_d_b_d_bbfd1}>{state.resend}</span>
                }
              ></Text>
            )}
          </Card>
          <Button
            id={`${parent_id}_addmember_otp_Confirm_Sign_Up'_buttom`}
            key={"5"}
            type={"button"}
            variant={"outlined"}
            name={
              appointmentDetails ? "Confirm & Add Member" : "Confirm & Sign Up"
            }
            style={qdmstyles.eab_cb_ba_e_eedc}
            onClick={handleSubmit}
            // onClick={() => props.nextSection("movenextscreen")}
          ></Button>
        </Div>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  registerUsers: state?.authSlice?.registerUsers,
  signIn: state?.authSlice?.signIn,
  resendOTP: state?.authSlice?.resendOTP,
});

export default connect(
  mapStateToProps,
  actions
)(AppointmentPageDrawerOtpSection);
