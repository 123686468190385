import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, makeStyles, InputAdornment } from "@material-ui/core";
import { Text } from 'qdm-component-library'
import SearchIcon from '@material-ui/icons/Search';
import { UIColor } from '../../themes/theme';
const useStyles = makeStyles((props) => ({
    autocomplete_label: {
        color: "#6F6F6F",
        fontSize: "12px !important",
        fontFamily: "pc_regular!important",
        marginBottom: '6px',
        fontWeight: 400
    },
    autocomplete: {
        "& .MuiInputBase-input": {
            color: UIColor.secondaryText.color,
            fontSize: "12px !important",
            fontFamily: "pc_regular!important",
        },
        "& .MuiInputBase-root": {
            height: 40
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: UIColor.lineBorderFill.color,
                borderRadius: 8
            },
            '&:hover fieldset': {
                borderColor: UIColor.lineBorderFill.color,
            },
            '&.Mui-focused fieldset': {
                borderWidth: 1,
                borderColor: UIColor.lineBorderFill.color,
            }
        },
        "& .MuiAutocomplete-popupIndicator": {
            transform: "none !important",
        }
    }
}))


const MaterialAutoComplete = (props) => {
    const classes = useStyles();
    return (
        <>
            <Text className={classes.autocomplete_label}>
                {props?.title}{props?.isRequired ? <span style={{ color: 'red', marginLeft: 5 }}>*</span> : null}
            </Text>
            <Autocomplete
                onInputChange={(e) =>
                    props?.handleMedication(e?.target?.value ?? "")
                }
                className={classes.autocomplete}
                options={props?.optionsList}
                onChange={(e, v) => props?.handleInputChange(props?.keyCode, v)}
                value={props?.value ? props.value: null}
                size={"small"}
                popupIcon={<InputAdornment position="end"><SearchIcon style={{ color: '#6F6F6F' }} /></InputAdornment>}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField
                    {...params}
                    placeholder={props?.placeholder}
                    variant="outlined"
                    error={props?.error}
                />}
            />
        </>
    )
}

export default MaterialAutoComplete;
