import { Button, Grid, TextareaAutosize } from "@material-ui/core";
import { Col, Row, Text } from "qdm-component-library";
import React, { useEffect } from "react";
import { UIColor } from "../themes/theme";
import { useLocation } from "react-router-dom";
import actions from "../redux/actions";
import { useDispatch } from "react-redux";

const styles = {
  vitalsHead: {
    padding: "6px 14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
    paddind: 10,
    width:"200vh"
  },
  vitailsCard: {
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    marginBottom: 0,
    background: "#fff",
  },
};
function CurrentMedication({
  isEdit = false,
  oneditClick = () => null,
  handleSave = () => null,
  currentcancel = () => null,
  handlecurrentmedicationval = () => null,
  currentMedicationValue = "",
  allNotes = [],
}) {
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    const getCM = async () => {
      let current_medication = await dispatch(
        actions.GET_CURRENT_MEDICATION_ORDER(
          // encounterData?.payload?.data?.[0]?.Patient?.[0]?._id
          location?.state?.patientId
        )
      );
      handlecurrentmedicationval(
        current_medication?.payload?.data?.[0]?.clicnicaldocdesc
      );
    };
    getCM();
  }, []);

  return (
    <Grid item lg={12}>
      <Row inLineStyles={styles.vitailsCard}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inLineStyles={styles.vitalsHead}
        >
          <Text
            inLineStyles={{
              fontWeight: 600,
              fontSize: "14px",
              color: UIColor.primaryColor.color,
              margin: "10px 5px 10px 5px",
            }}
          >
            {"Current Medication"}
          </Text>
          <Grid>
            {isEdit && (
              <Button
                variant={"text"}
                size={"small"}
                style={{
                  color: "#fff",
                  background: UIColor.primaryColor.color,
                  fontWeight: 500,
                  padding: "2px",
                  margin: "4px",
                }}
                onClick={currentcancel}
              >
                Cancel
              </Button>
            )}
            {allNotes?.length > 0 ? (
              <Button
                variant={"text"}
                size={"small"}
                style={{
                  color: "#fff",
                  background: UIColor.primaryColor.color,
                  fontWeight: 500,
                  padding: "2px",
                  margin: "4px",
                }}
                onClick={isEdit ? handleSave : oneditClick}
              >
                {isEdit ? "Update" : "Edit"}
              </Button>
            ) : (
              <Button
                variant={"text"}
                size={"small"}
                style={{
                  color: "#fff",
                  background: UIColor.primaryColor.color,
                  fontWeight: 500,
                  padding: "2px",
                  margin: "4px",
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            )}
          </Grid>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inLineStyles={{ padding: "30px" }}
        >
          <Grid item lg={12}>
            <TextareaAutosize
              onChange={(e) => handlecurrentmedicationval(e.target.value)}
              maxRows={4}
              placeholder="Enter text here"
              style={{
                width: "100%",
                height: "80px",
                outline: "1px solid rgb(222, 229, 236) ",
                border: "0px",
                borderRadius: "8px",
                padding: "5px",
              }}
              disabled={allNotes?.length > 0 && !isEdit ? true : false}
              value={currentMedicationValue}
            />
          </Grid>
        </Col>
      </Row>
    </Grid>
  );
}

export default CurrentMedication;
