import { Text, Div, Card, Image, H2 } from "qdm-component-library";
import classes from "./styles.json";
import { Grid } from "@material-ui/core";
 
const Previous = (props) => {
  const { parent_id, markHighPriority, assignReassignPractitioner } = props;
 
 
  return (
    <Div
      style={classes.wrapper}
      key={props.index}
      id={`${parent_id}${props.index}_queue_previous_parent_div`}
    >
      <Div
        style={classes.header}
        id={`${parent_id}${props.index}_queue_previous_sub_div`}
      >
        <Grid container>
          {props.item?.title && (
            <H2
              id={`${parent_id}${props.index}_queue_previous_title_h2`}
              className="pc_semibold"
              style={classes.headerTitle}
            >
              {props.item?.title}
            </H2>
          )}
        </Grid>
        <Grid container justifyContent="space-around">
          <Grid item>
            {props.item?.token && (
              <Div
                id={`${parent_id}${props.index}_queue_previous_token_div`}
                className="pc_medium"
                style={classes.tokenNumber}
              >
                {props.item?.token}
              </Div>
            )}
          </Grid>
          <Grid item>
            {props.item?.appno && (
              <Div
                id={`${parent_id}${props.index}_queue_previous_token_div`}
                className="pc_medium"
                style={classes.appNumber}
              >
                {props.item?.appno}
              </Div>
            )}
          </Grid>
        </Grid>
      </Div>
      <Card
        style={classes.cardStyle}
        id={`${parent_id}${props.index}_queue_previous_card`}
      >
        <Div
          style={classes.previousNextBody}
          id={`${parent_id}${props.index}_queue_previous_previousNextBody_div`}
        >
          {props.item?.profile_pic && (
            <Div
              style={classes.previousNextBodyAvatar}
              id={`${parent_id}${props.index}_queue_previous_previousNextBody_avatar_div`}
            >
              <Image
                id={`${parent_id}${props.index}_queue_previous_previousNextBody_image`}
                src={props.item?.profile_pic}
                alt={props.item?.name ?? "profilePic"}
                style={{
                  border: props.item?.isEmergency ? "1px dashed #FF4D4A" : "0",
                }}
              />
              {props.item?.isEmergency && (
                <Div
                  id={`${parent_id}${props.index}_queue_previous_emergencyDiv_div`}
                  style={classes.emergencyDiv}
                >
                  <Text
                    id={`${parent_id}${props.index}_queue_previous_emergencyText_text`}
                    className="pc_medium"
                    style={classes.emergencyText}
                  >
                    EMERGENCY
                  </Text>
                </Div>
              )}
            </Div>
          )}
          <Div
            style={{ textAlign: "left" }}
            id={`${parent_id}${props.index}_queue_previous_previousNextBodyName_div`}
          >
            {props.item?.name && (
              <H2
                id={`${parent_id}${props.index}_queue_previous_previousNextBodyName_h2`}
                className="pc_semibold"
                style={classes.previousNextBodyName}
              >
                {props.item?.name}
              </H2>
            )}
            {props.item?.role && (
              <Text
                id={`${parent_id}${props.index}_queue_previous_previousNextBodyRole_text`}
                className="pc_regular"
                style={classes.previousNextBodyRole}
              >
                {props.item?.role}
              </Text>
            )}
          </Div>
        </Div>
      </Card>
    </Div>
  );
};
 
export default Previous;
 