/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 
 * @modify date 
 * @desc All the environment variables are taken from process.env and exported 
 * via Config variable.
 */


let Config = {};

Config.TokenApikey = process.env.REACT_APP_LOGIN_API_KEY;
Config.db_name = process.env.REACT_APP_DB;
Config.Image_upload_protocal = process.env.REACT_APP_IMAGE_UPLOAD_PROTOCAL ? process.env.REACT_APP_IMAGE_UPLOAD_PROTOCAL : "https";
Config.Image_upload_host = process.env.REACT_APP_IMAGE_UPLOAD_HOST ? process.env.REACT_APP_IMAGE_UPLOAD_HOST : "fileupload.dev.ainqaplatform.in";
Config.Image_upload_port = process.env.REACT_APP_IMAGE_UPLOAD_PORT ? process.env.REACT_APP_IMAGE_UPLOAD_PORT : 443;
Config.downloadUrl = `${Config.Image_upload_protocal}://${Config.Image_upload_host}:${Config.Image_upload_port}/${Config.db_name}/`;
Config.api_url = process.env.REACT_APP_API_ENDPOINT;
Config.graphql = process.env.REACT_APP_GQL_ENDPOINT;
Config.socket = process.env.REACT_APP_SOCKET;
Config.qdm_api_url = process.env.REACT_APP_QDM_ENDPOINT;
Config.qdm_db_name = process.env.REACT_APP_QDM_DB_NAME;
Config.avc_url = process.env.REACT_APP_AVC;
Config.portal_call = process.env.REACT_APP_BASE_URL_PROTOCAL;
Config.configration_number = process.env.REACT_APP_CONFIG_NO ? process.env.REACT_APP_CONFIG_NO : 11;
Config.uploadUrl = `${Config.Image_upload_protocal}://${Config.Image_upload_host}:${Config.Image_upload_port}/${Config.db_name}/${Config.configration_number}`;

export default Config;
