import React, { useEffect } from "react";
import { H6, Row } from "qdm-component-library";
import TaskCard from "./taskCard";
// import { useDispatch } from "react-redux";
// import { actions } from "primarycare-binder";
import Grid from '@material-ui/core/Grid';

function Task(props) {
  // const dispatch = useDispatch();

  
  const FrontDesk_DATA = [
    {
      title: "Specimen Collection",
      subtitle: "Patient waiting for sample collection",
      count: "0",
      color: "#FF9F40",
      backgroundColor: "#FFF7F0",
    },
    {
      title: "Billing",
      subtitle: "Patient need to pay bill",
      count: "0",
      color: "#FF6384",
      backgroundColor: "#FFF3F5",
    },
    {
      title: "Scheduling",
      subtitle: "Patient waiting for scheduling",
      count: "0",
      color: "#36A2EB",
      backgroundColor: "#F1FAFA",
    },
    {
      title: "Nursing Procedures",
      subtitle: "Patient waiting for administration",
      count: "0",
      color: "#4BC0C0",
      backgroundColor: "#F1FAFA",
    }
  ];

  // const FrontDesk_DATA = [
  //   {
  //     title: "Bill Collection",
  //     subtitle: "Patient need to pay bill",
  //     count: "0",
  //     color: "#FF9F40",
  //     backgroundColor: "#FFF7F0",
  //   },
  //   {
  //     title: "Confirmed Referrals",
  //     subtitle: "Sit back and relax",
  //     count: "0",
  //     color: "#FF6384",
  //     backgroundColor: "#FFF3F5",
  //   },
  //   {
  //     title: "Follow-Up Appointments",
  //     subtitle: "Give some importance",
  //     count: "0",
  //     color: "#36A2EB",
  //     backgroundColor: "#F1FAFA",
  //     binderName: "FOLLOW_UP_COUNT",
  //     selectorName: "followUpCount",
  //   },
  //   {
  //     title: "Appointment Referrals",
  //     subtitle: "Some another patients here",
  //     count: "0",
  //     color: "#4BC0C0",
  //     backgroundColor: "#F1FAFA",
  //     binderName: "REFERRAL_COUNT",
  //     selectorName: "referralCount",
  //   }
  // ];

  // const NURSE_DATA = [
  //   {
  //     title: "Blood Collection",
  //     subtitle: "10 patients needs blood by today",
  //     count: "10",
  //     color: "#FF9F40",
  //     backgroundColor: "#FFF7F0",
  //   },
  //   {
  //     title: "Vaccination",
  //     subtitle: "Need your authentication",
  //     count: "03",
  //     color: "#4BC0C0",
  //     backgroundColor: "#F1FAFA",
  //   },
  //   {
  //     title: "Medication Administration",
  //     subtitle: "Patients needs your review",
  //     count: "05",
  //     color: "#FF6384",
  //     backgroundColor: "#FFF3F5",
  //   },
  //   {
  //     title: "Blood Transfusion",
  //     subtitle: "Some another patients here",
  //     count: "05",
  //     color: "#36A2EB",
  //     backgroundColor: "#F1FAFA",
  //   },
  //   {
  //     title: "Diagnostic Orders",
  //     subtitle: "Patients needs your review",
  //     count: "05",
  //     color: "#FF6384",
  //     backgroundColor: "#FFF3F5",
  //   },
  //   {
  //     title: "Nursing Procedures",
  //     subtitle: "Some another patients here",
  //     count: "03",
  //     color: "#4BC0C0",
  //     backgroundColor: "#F1FAFA",
  //   },
  // ];

  // const DOCTOR_DATA = [
  //   {
  //     title: "Order Result Review",
  //     subtitle: "5 orders needs review by today",
  //     count: "10",
  //     color: "#FF9F40",
  //     backgroundColor: "#FFF7F0",
  //   },
  //   {
  //     title: "Order Authorization",
  //     subtitle: "Need your authentication",
  //     count: "06",
  //     color: "#4BC0C0",
  //     backgroundColor: "#F1FAFA",
  //   },
  //   {
  //     title: "Order Result Authorization",
  //     subtitle: "Need your authentication",
  //     count: "06",
  //     color: "#4BC0C0",
  //     backgroundColor: "#F1FAFA",
  //   },
  //   {
  //     title: "Referal Review",
  //     subtitle: "Some another patients here",
  //     count: "05",
  //     color: "#36A2EB",
  //     backgroundColor: "#F1FAFA",
  //   },
  //   {
  //     title: "Notes Review",
  //     subtitle: "Patients needs your review",
  //     count: "03",
  //     color: "#4BC0C0",
  //     backgroundColor: "#F1FAFA",
  //   }
  // ];

  // const getData = async () => {
  //   const promArr = getRoleData()?.filter(d => !!d.binderName).map(d => {
  //     return dispatch(actions[d.binderName](d.payload || {}));
  //   });
  //   await Promise.all(promArr);
  // };

  // useEffect(() => {
  //   getData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const { parent_id } = props;

  const getRoleData = () => {
    // const list = props?.Permission?.write ?? [];
    // let roleId = list.indexOf("TasksD") > -1 ? 1 : list.indexOf("TasksN") > -1 ? 2 : 3
    // if (roleId === 1) {
    //   return DOCTOR_DATA
    // }
    // else if (roleId === 2) {
    //   return NURSE_DATA
    // }
    // else if (roleId === 3) {
    // }
      return props?.data ?? FrontDesk_DATA;
  }

  return (
    <div
      id={`${parent_id}_layout_Task_parent_div`}
      style={{ height: "100%", padding: "10px 0px", paddingLeft: "10px" }}
    >
      <H6
        id={`${parent_id}_layout_Task_h6`}
        className="pc_medium"
        style={{ marginBottom: 10, fontSize: 14 }}
      >
        Pending Task{" "}
      </H6>
      <Grid container spacing={0}>
      
        {getRoleData()?.map((task, i) => {
          return (
            <Grid item xs={6}>
            <div
              id={`${parent_id + "_layout"}_${i}_Task_div`}
              style={{ minWidth: "100%" }}
            >
              <TaskCard
                parent_id={`dashboard_layout_${i}`}
                title={task?.title}
                subTitle={task?.subtitle}
                count={task?.count}
                color={task?.color}
                backgroundColor={task?.backgroundColor}
                selectorName={task?.selectorName}
                index={i}
                tabData={getRoleData()}
              />
            </div>
            </Grid>
          );
        })}
        
        </Grid>
    </div>
  );
}

export default Task;
