import React from "react";
import { UIColor } from "../../../themes/theme";
export const AllergyIcon = (props) => {
  let width = props.width ? props.width : "40.8";
  let height = props.height ? props.height : "40.8";
  let color = props.color ? props.color : UIColor.primaryColor.color;
  return (
    <svg id="allergy_svg" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 35.761 40.5">
        <g id="allergy" transform="translate(-10.028 -4.75)">
            <path id="Path_92686" data-name="Path 92686" d="M43.8,21.959c.133-2.708-.178-7.636-3.418-11.587C37.674,7,33.5,5.222,28,5h-.178c-5.5.222-9.678,2-12.431,5.372-3.241,3.951-3.552,8.879-3.418,11.587a4.806,4.806,0,0,0-1.554,2.664,8.6,8.6,0,0,0,.311,4.262,5.874,5.874,0,0,0,3.241,4.04A22.35,22.35,0,0,0,22.4,43.357a10.046,10.046,0,0,0,11.01,0,22.35,22.35,0,0,0,8.435-10.433,5.975,5.975,0,0,0,3.241-4.04,8.572,8.572,0,0,0,.311-4.262A4.979,4.979,0,0,0,43.8,21.959Zm-.332,6.171c-.577,2.087-1.865,2.486-2.575,2.486-.044,0-.133.044-.133.089a24.032,24.032,0,0,1-8.814,11.344,7.511,7.511,0,0,1-4.04,1.2,7.4,7.4,0,0,1-4.04-1.2A24.032,24.032,0,0,1,15.053,30.7c0-.044-.089-.089-.133-.089-.666,0-2-.444-2.575-2.486-.622-2.264-.577-4,1.421-4.706.044,0,.089-.089.089-.133-.222-1.332-.909-16.006,14.052-16.583,14.961.577,14.274,15.206,14.052,16.583,0,.044.044.133.089.133C44.046,24.134,44.09,25.866,43.468,28.13Z" fill={color} stroke={UIColor.secondaryColor.color} stroke-width="0.5"/>
            <path id="Path_92687" data-name="Path 92687" d="M48.764,65.577a7.056,7.056,0,0,0-3.729-.977,6.609,6.609,0,0,0-3.951,1.154.4.4,0,0,0-.044.622l.4.444a.415.415,0,0,0,.577.044,5.424,5.424,0,0,1,3.063-.844,5.906,5.906,0,0,1,2.886.71.421.421,0,0,0,.577-.089l.355-.488A.4.4,0,0,0,48.764,65.577Z" transform="translate(-17.039 -33.141)" fill="#262626"/>
            <path id="Path_92688" data-name="Path 92688" d="M35.882,41.73l-.355-.355a.348.348,0,0,0-.488,0,4.493,4.493,0,0,1-2.442.932,4.818,4.818,0,0,1-2.442-.355.308.308,0,0,0-.444.133l-.266.444a.357.357,0,0,0,.133.488,6.077,6.077,0,0,0,3.152.533,5.644,5.644,0,0,0,3.152-1.243A.466.466,0,0,0,35.882,41.73Z" transform="translate(-10.639 -20.171)" fill="#262626"/>
            <path id="Path_92689" data-name="Path 92689" d="M62.09,42.085a.36.36,0,0,0-.444-.133,4.818,4.818,0,0,1-2.442.355,4.493,4.493,0,0,1-2.442-.932.348.348,0,0,0-.488,0l-.4.355a.405.405,0,0,0,0,.533,5.464,5.464,0,0,0,3.152,1.243,5.66,5.66,0,0,0,3.152-.533.357.357,0,0,0,.133-.488Z" transform="translate(-25.304 -20.171)" fill="#262626"/>
            <path id="Path_92690" data-name="Path 92690" d="M29.654,56.6a1.154,1.154,0,1,0,1.154,1.154A1.12,1.12,0,0,0,29.654,56.6Z" transform="translate(-10.138 -28.692)" fill="#f58b00"/>
            <path id="Path_92691" data-name="Path 92691" d="M61.154,27.309A1.154,1.154,0,1,0,60,26.154,1.146,1.146,0,0,0,61.154,27.309Z" transform="translate(-27.654 -11.121)" fill="#f58b00"/>
            <circle id="Ellipse_128808" data-name="Ellipse 128808" cx="1.154" cy="1.154" r="1.154" transform="translate(23.379 11.082)" fill="#f58b00"/>
            <path id="Path_92692" data-name="Path 92692" d="M63.754,54.3a1.154,1.154,0,1,0,1.154,1.154A1.146,1.146,0,0,0,63.754,54.3Z" transform="translate(-29.099 -27.413)" fill="#f58b00"/>
            <path id="Path_92693" data-name="Path 92693" d="M32.254,27.309A1.154,1.154,0,1,0,31.1,26.154,1.146,1.146,0,0,0,32.254,27.309Z" transform="translate(-11.584 -11.121)" fill="#f58b00"/>
            <path id="Path_92694" data-name="Path 92694" d="M53.454,77.2a1.154,1.154,0,1,0,1.154,1.154A1.175,1.175,0,0,0,53.454,77.2Z" transform="translate(-23.372 -40.147)" fill="#f58b00"/>
        </g>
    </svg>
  );
};