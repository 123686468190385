import { connect } from "react-redux";
import { actions } from "primarycare-binder";
import withRouter from "../../../hoc/withRouter";
import withAllContexts from "../../../hoc/withAllContexts";
import Previous from "./previous";
import ToAttend from "./toAttend";
import UpNext from "./upNext";
import PauseButton from "../../../assets/img/svg/icons8-pause-button.svg";
import RepeatIcon from "../../../assets/img/svg/icons8-repeat.svg";
import ChangeUser from "../../../assets/img/svg/icons8-change-user.svg";

const PatientQueue = (props) => {
  const { parent_id, markHighPriority, assignReassignPractitioner } = props;

  let previousPatientList = props.previous;
  let toAttendPatientList = props.attend;
  let upNextPatientList = props.next;

  // previousPatientList = [
  //   {
  //     title: "Previous",
  //     token: "238",
  //     profile_pic:
  //       "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
  //     name: "Mohamed Idhris ",
  //     consultationMode: "Direct Consultation",
  //     role_: "frontdesk",
  //     role: "Consultation",
  //     start: "08:00 am",
  //   },
  // ];

  // toAttendPatientList = [
  //   {
  //     title: "To Attend",
  //     token: "246",
  //     profile_pic:
  //       "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
  //     name: "Samantha ",
  //     role: "Consultation",
  //     role_: "frontdesk",
  //     encounter: "12185",
  //     consultationMode: "Direct Consultation",
  //     start: "02:00 pm",
  //     primaryButtonName: "Register Visit",
  //     isRegister: "Registered",
  //     C: "Put on Hold",
  //     secondaryButtonIcon: PauseButton,
  //     isEmergency: false,
  //     assignButtonIcon: RepeatIcon,
  //     assigned: true,
  //     totalData: {
  //       appointmentType: "walkin",
  //       consultationMode: "Direct Consultation",
  //       token: 246,
  //       comment: "Checked-In",
  //       name: "Samantha ",
  //       isPatient: true,
  //       personId: 1168,
  //       patient_id: "Person/11174",
  //       patientId: 932,
  //       pId: "Patient/10969",
  //       mrn: "MRN00_816",
  //       role: "Consultation",
  //       encounter: "12185",
  //       encounter_id: "Encounter/12185",
  //       encounter_status: "Checked-in",
  //       encounterId: 2196,
  //       showBringtoTop: false,
  //       profile_pic:
  //         "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
  //       isEmergency: false,
  //       start: "02:00 pm",
  //       startTime: 1693989000,
  //       info: {
  //         AppointmentNotification: [
  //           {
  //             practitionerEmail: "",
  //             mobile: "+919995045518",
  //             patientEmail: "",
  //           },
  //         ],
  //         Description: "string",
  //         Healthservice: [],
  //         Person: [
  //           {
  //             Id: 1168,
  //             OrgID: [],
  //             Patient: [
  //               {
  //                 OrgID: "",
  //                 PractitionerID: [],
  //                 _id: "Patient/10969",
  //                 _key: "10969",
  //                 _rev: "_gkTRo1---R",
  //                 active: true,
  //                 activereason: "",
  //                 activestatus: true,
  //                 address: [],
  //                 age: "20",
  //                 age_type: "CodingMaster/10241",
  //                 alias: "MRN00_816",
  //                 approxAgeEntered: false,
  //                 attachment: [],
  //                 birthDate: "01/09/2002",
  //                 birthGendercode: "",
  //                 birthPlace: "",
  //                 birthTime: 0,
  //                 bloodGroup: "",
  //                 cadavericDonor: false,
  //                 citizenshipCode: "",
  //                 communicationLanguage: [],
  //                 conditionhistory: [],
  //                 createdby: "",
  //                 createddate: 1663057278,
  //                 deceaseddate: 0,
  //                 deceasedreason: "",
  //                 disabilityCode: "",
  //                 ethinicityCode: "",
  //                 gender: "CodingMaster/10266",
  //                 genderIdentitycode: "",
  //                 id: 932,
  //                 identifier: ["IdentifierMaster/14158"],
  //                 isdeceased: "",
  //                 link: [
  //                   {
  //                     patient: [],
  //                     Type: "",
  //                   },
  //                 ],
  //                 maritalstatus: "",
  //                 name: ["HumanNameMaster/14195"],
  //                 nationality: "SMGeographicMaster/10000",
  //                 patientImp_Code: "",
  //                 patientTypecode: "",
  //                 personID: 1168,
  //                 photo: [],
  //                 preferredLanguage: "",
  //                 primarycare_Prac: "",
  //                 raceCode: "",
  //                 religionCode: "",
  //                 residencyStatus: false,
  //                 specialArng_code: "",
  //                 specialCourtesy_code: "",
  //                 statushistory: [
  //                   {
  //                     oldstatus: "",
  //                     oldstatusreason: "",
  //                     newstatus: "Active",
  //                     fromperiod: 1663057278,
  //                     toperiod: 0,
  //                   },
  //                 ],
  //                 telecom: ["ContactPointMaster/18162"],
  //                 updatedate: 1663057278,
  //                 updatedby: "",
  //               },
  //             ],
  //             RelatedPersonID: [],
  //             _id: "Person/11174",
  //             _key: "11174",
  //             _rev: "_gkTSsta--0",
  //             active: true,
  //             activestatus: true,
  //             address: [],
  //             alias: "PER00_834",
  //             birthDay: "01/09/2002",
  //             createdby: "",
  //             createddate: 1663056659,
  //             gender: "FEMALE",
  //             identifier: [],
  //             link: [],
  //             name: ["HumanNameMaster/14194"],
  //             photo: [],
  //             telecom: [
  //               {
  //                 _key: "18161",
  //                 _id: "ContactPointMaster/18161",
  //                 _rev: "_gkTUQYe--S",
  //                 activestatus: true,
  //                 createdby: "",
  //                 createddate: 1663057278,
  //                 id: 5536,
  //                 period: ["periodMaster/47781"],
  //                 rank: "2",
  //                 system: "Email",
  //                 updatedate: 1663057278,
  //                 updatedby: "",
  //                 use: "Work",
  //                 value: "",
  //               },
  //               {
  //                 _key: "18160",
  //                 _id: "ContactPointMaster/18160",
  //                 _rev: "_gkTUQWO--j",
  //                 activestatus: true,
  //                 createdby: "",
  //                 createddate: 1663057278,
  //                 id: 5535,
  //                 period: ["periodMaster/47780"],
  //                 rank: "1",
  //                 system: "Phone",
  //                 updatedate: 1663057278,
  //                 updatedby: "",
  //                 use: "Mobile",
  //                 value: "+919995045518",
  //               },
  //             ],
  //             updatedate: 1663057278,
  //             updatedby: "",
  //             HumanName: [
  //               {
  //                 _id: "HumanNameMaster/14194",
  //                 _key: "14194",
  //                 _rev: "_exVuLdO---",
  //                 activestatus: true,
  //                 createdby: "",
  //                 createddate: 1663057278,
  //                 family: "",
  //                 given: "",
  //                 id: 3427,
  //                 period: ["periodMaster/47779"],
  //                 prefix: null,
  //                 suffix: null,
  //                 text: "Samantha",
  //                 updatedate: 1663057278,
  //                 updatedby: "",
  //                 use: null,
  //               },
  //             ],
  //           },
  //         ],
  //         PersonID: [1168],
  //         ServiceCategoryID: [],
  //         ServiceTypeID: [],
  //         SpecialtyID: [456],
  //         _id: "Appointment/13924",
  //         _key: "13924",
  //         _rev: "_gkhJy-S---",
  //         activestatus: true,
  //         appno: "AP-246",
  //         appointmentReason: [],
  //         appointmentType: "walkin",
  //         appointmentTypeID: [],
  //         appstatus: "booked",
  //         avcpatientstatus: "YET TO LOGIN",
  //         basedOn: [],
  //         bookedby: "Practitioner/10008",
  //         cancelationReason: [],
  //         comment: "Checked-In",
  //         consultationMode: "Direct Consultation",
  //         createdby: "",
  //         createddate: 1693975301,
  //         encounter: [
  //           {
  //             Organization_id: 138,
  //             ServiceRequest_id: 0,
  //             _id: "Encounter/12185",
  //             _key: "12185",
  //             _rev: "_gkikUYy---",
  //             accompanied_person: [
  //               {
  //                 id_type: "",
  //                 id: "",
  //                 name: "",
  //                 relationship: "",
  //               },
  //             ],
  //             account: [
  //               {
  //                 accountid: "",
  //                 ChangeSeqNum: 0,
  //               },
  //             ],
  //             activestatus: true,
  //             appointmentId: [246],
  //             basedOn: "",
  //             cancel_date: 0,
  //             class: "CodingMaster/10049",
  //             classhistory: [
  //               {
  //                 class: "",
  //                 period: "",
  //               },
  //             ],
  //             createdby: "",
  //             createddate: 1693977254,
  //             device_details: [
  //               {
  //                 category: "",
  //                 type: "",
  //                 name: "",
  //               },
  //             ],
  //             diagnosis: [
  //               {
  //                 condition: "",
  //                 use: "",
  //                 rank: "",
  //               },
  //             ],
  //             eSignature: "",
  //             encounter_no: "",
  //             encounterdate: 1693981230,
  //             encounterenddate: 1694024999,
  //             end_type: "",
  //             episodeOfCare_id: 0,
  //             files: [],
  //             followupDur: 0,
  //             followupReqd: false,
  //             followupUOM: "",
  //             health_of_service: [
  //               {
  //                 category: "",
  //                 name: "",
  //                 type: "",
  //               },
  //             ],
  //             hospitalization: [
  //               {
  //                 preAdmissionIdentifier: "",
  //                 origin: "",
  //                 admitSource: "",
  //                 reAdmission: "",
  //                 dietPreference: [],
  //                 specialCourtesy: [],
  //                 specialArrangement: [],
  //                 destination: "",
  //                 dischargeDisposition: "",
  //               },
  //             ],
  //             id: 2196,
  //             identifier: ["IdentifierMaster/15674"],
  //             length: [
  //               {
  //                 value: 0,
  //                 comparator: "",
  //                 unit: "",
  //                 system: "",
  //                 code: "",
  //               },
  //             ],
  //             location: [
  //               {
  //                 location_id: "",
  //                 status: "",
  //                 period: "",
  //                 physicalType: "",
  //               },
  //             ],
  //             mode: "direct",
  //             partOf: "",
  //             participant: [
  //               {
  //                 type: ["CodingMaster/12338"],
  //                 period: "",
  //                 Practitioner_id: 9,
  //                 PractitionerRole_id: 386,
  //                 RelatedPerson_id: [
  //                   {
  //                     relatedpersonid: "",
  //                     relationship: "",
  //                   },
  //                 ],
  //                 specialty: "",
  //               },
  //             ],
  //             participantHistory: [
  //               {
  //                 participantType: "",
  //                 practroleId: "",
  //                 practitionerId: "",
  //                 specialty: "",
  //                 relatedPersonId: "",
  //                 relationship: "",
  //                 userId: "",
  //                 startTime: 0,
  //                 endTime: 0,
  //                 reasonCode: "",
  //               },
  //             ],
  //             patient_id: 932,
  //             period: "",
  //             plannedEndDate: 0,
  //             plannedStartDate: 0,
  //             practitioner_details: [
  //               {
  //                 speciality: "CodingMaster/10456",
  //                 type: "",
  //                 id_name: "FrontDesk SIT Primarycare ",
  //                 clinic: "HEMODIALYSIS",
  //                 role: "Front desk",
  //                 period: "",
  //               },
  //             ],
  //             priority: null,
  //             priorityreason: null,
  //             reasonCode: "",
  //             reasonReference: "CodingMaster/10063",
  //             relationship: "",
  //             revision_date: 0,
  //             serviceType: "First Visit",
  //             source: "",
  //             status: {
  //               _key: "10725",
  //               _id: "CodingMaster/10725",
  //               _rev: "_e8UTuZi---",
  //               Type: "ENCSTATUS",
  //               activestatus: true,
  //               code: "ENCSTATUS003",
  //               createdby: "",
  //               createddate: 1636355434,
  //               display: "Checked-in",
  //               gmconfigvalues: {},
  //               id: 725,
  //               shortdesc: "Checked-in",
  //               status: true,
  //               updatedate: 1636355434,
  //               updatedby: "",
  //             },
  //             statushistory: [
  //               {
  //                 status: "CodingMaster/10725",
  //                 period: ["periodMaster/71590"],
  //                 reasoncode: "",
  //               },
  //             ],
  //             subject: "",
  //             type: ["CodingMaster/10264"],
  //             updatedate: 1693981235,
  //             updatedby: "",
  //             visit_type: "First Visit",
  //           },
  //         ],
  //         end: 1693996200,
  //         id: 246,
  //         identifier: [],
  //         minutesDuration: 0,
  //         orgid: "",
  //         orgtype: "CodingMaster/10010",
  //         participant: [],
  //         patientInstruction: "",
  //         priority: 1,
  //         reasonCode: [],
  //         reasonReference: [],
  //         report_docs: [],
  //         requestedPeriod: ["periodMaster/71555"],
  //         resourcecode: "Practitioner/10008",
  //         resourcerole: "Physician ",
  //         resourcetype: "Practitioner",
  //         slotID: [3814717],
  //         start: 1693989000,
  //         status: true,
  //         supportingInformation: [],
  //         updatedate: 1693975301,
  //         updatedby: "",
  //         Practitioner: [],
  //       },
  //       mobileNo: "+919995045518",
  //       index: 9,
  //       status: true,
  //       type: "frontdesk",
  //     },
  //   },
  // ];

  // upNextPatientList = [
  //   {
  //     title: "Up Next",
  //     token: "253",
  //     profile_pic:
  //       "https://fileupload.uat.ainqaplatform.in:443/pc_dialysis/12219",
  //     name: "AAliya Theoder ",
  //     role: "Consultation",
  //     encounter: "",
  //     consultationMode: "Direct Consultation",
  //     isEmergency: false,
  //     start: "08:00 pm",
  //     assignButtonIcon: ChangeUser,
  //     assigned: false,
  //     role_: "frontdesk",
  //     totalData: {
  //       appointmentType: "walkin",
  //       consultationMode: "Direct Consultation",
  //       token: 253,
  //       comment: "Checked-In",
  //       name: "AAliya Theoder ",
  //       isPatient: true,
  //       personId: 1563,
  //       patient_id: "Person/11569",
  //       patientId: 1358,
  //       pId: "Patient/11399",
  //       mrn: "MRN00_233",
  //       role: "Consultation",
  //       encounter: "",
  //       encounter_id: "",
  //       encounter_status: "",
  //       encounterId: "",
  //       showBringtoTop: true,
  //       profile_pic:
  //         "https://fileupload.uat.ainqaplatform.in:443/pc_dialysis/12219",
  //       isEmergency: false,
  //       start: "08:00 pm",
  //       startTime: 1694097000,
  //       info: {
  //         AppointmentNotification: [
  //           {
  //             practitionerEmail: "",
  //             mobile: "+91908763678",
  //             patientEmail: "",
  //           },
  //         ],
  //         Description: "string",
  //         Healthservice: [],
  //         Person: [
  //           {
  //             Id: 1563,
  //             OrgID: [],
  //             Patient: [
  //               {
  //                 OrgID: "",
  //                 PractitionerID: [],
  //                 _id: "Patient/11399",
  //                 _key: "11399",
  //                 _rev: "_gk7MMpC--I",
  //                 active: true,
  //                 activereason: "",
  //                 activestatus: true,
  //                 address: [],
  //                 age: "18",
  //                 age_type: "CodingMaster/10241",
  //                 alias: "MRN00_233",
  //                 approxAgeEntered: false,
  //                 attachment: [],
  //                 birthDate: "01/09/2005",
  //                 birthGendercode: "",
  //                 birthPlace: "",
  //                 birthTime: 0,
  //                 bloodGroup: "",
  //                 cadavericDonor: false,
  //                 citizenshipCode: "",
  //                 communicationLanguage: [],
  //                 conditionhistory: [],
  //                 createdby: "",
  //                 createddate: 1694084292,
  //                 deceaseddate: 0,
  //                 deceasedreason: "",
  //                 disabilityCode: "",
  //                 ethinicityCode: "",
  //                 gender: "CodingMaster/10267",
  //                 genderIdentitycode: "",
  //                 id: 1358,
  //                 identifier: ["IdentifierMaster/15730"],
  //                 isdeceased: "",
  //                 link: [
  //                   {
  //                     patient: [],
  //                     Type: "",
  //                   },
  //                 ],
  //                 maritalstatus: "",
  //                 name: ["HumanNameMaster/15400"],
  //                 nationality: "SMGeographicMaster/10035",
  //                 patientImp_Code: "",
  //                 patientTypecode: "",
  //                 personID: 1563,
  //                 photo: [
  //                   {
  //                     _key: "11818",
  //                     _id: "AttachmentMaster/11818",
  //                     _rev: "_gk7MMpC--C",
  //                     fileid: "12219",
  //                     filetype: "image/jpeg",
  //                     objectid: "11",
  //                     fileName:
  //                       "customer-service-cute-girl-white-shirt-with-red-lipstick-laptop-smiling-holding-pen.jpg",
  //                     id: 1329,
  //                     createdby: "",
  //                     createddate: 1694084292,
  //                     updatedby: "",
  //                     updatedate: 1694084292,
  //                     activestatus: true,
  //                     collection_name: "AttachmentMaster",
  //                   },
  //                 ],
  //                 preferredLanguage: "",
  //                 primarycare_Prac: "",
  //                 raceCode: "",
  //                 religionCode: "",
  //                 residencyStatus: false,
  //                 specialArng_code: "",
  //                 specialCourtesy_code: "",
  //                 statushistory: [
  //                   {
  //                     oldstatus: "",
  //                     oldstatusreason: "",
  //                     newstatus: "Active",
  //                     fromperiod: 1694084286,
  //                     toperiod: 0,
  //                   },
  //                 ],
  //                 telecom: [
  //                   "ContactPointMaster/20335",
  //                   "ContactPointMaster/20336",
  //                 ],
  //                 updatedate: 1694084512,
  //                 updatedby: "",
  //               },
  //             ],
  //             RelatedPersonID: [],
  //             _id: "Person/11569",
  //             _key: "11569",
  //             _rev: "_gk7MMfC---",
  //             active: true,
  //             activestatus: true,
  //             address: [],
  //             alias: "PER00_1227",
  //             birthDay: "01/09/2005",
  //             createdby: "",
  //             createddate: 1694084290,
  //             gender: "MALE",
  //             identifier: [],
  //             link: [],
  //             name: ["HumanNameMaster/15401"],
  //             photo: [
  //               {
  //                 _key: "11819",
  //                 _id: "AttachmentMaster/11819",
  //                 _rev: "_gk7MMf---K",
  //                 fileName:
  //                   "customer-service-cute-girl-white-shirt-with-red-lipstick-laptop-smiling-holding-pen.jpg",
  //                 fileid: "12219",
  //                 filetype: "image/jpeg",
  //                 objectid: "11",
  //                 id: 1330,
  //                 createdby: "",
  //                 createddate: 1694084512,
  //                 updatedby: "",
  //                 updatedate: 1694084512,
  //                 activestatus: true,
  //               },
  //             ],
  //             telecom: [
  //               {
  //                 _key: "20337",
  //                 _id: "ContactPointMaster/20337",
  //                 _rev: "_gk7MMf---G",
  //                 system: "Phone",
  //                 value: "+91908763678",
  //                 use: "Mobile",
  //                 rank: "1",
  //                 period: ["periodMaster/71777"],
  //                 id: 7701,
  //                 createdby: "",
  //                 createddate: 1694084512,
  //                 updatedby: "",
  //                 updatedate: 1694084512,
  //                 activestatus: true,
  //               },
  //               {
  //                 _key: "20338",
  //                 _id: "ContactPointMaster/20338",
  //                 _rev: "_gk7MMf---I",
  //                 system: "Email",
  //                 value: "n@yopmail.com",
  //                 use: "Work",
  //                 rank: "2",
  //                 period: ["periodMaster/71778"],
  //                 id: 7702,
  //                 createdby: "",
  //                 createddate: 1694084512,
  //                 updatedby: "",
  //                 updatedate: 1694084512,
  //                 activestatus: true,
  //               },
  //             ],
  //             updatedate: 1694084512,
  //             updatedby: "",
  //             HumanName: [
  //               {
  //                 _id: "HumanNameMaster/15401",
  //                 _key: "15401",
  //                 _rev: "_gk7MMf---A",
  //                 activestatus: true,
  //                 createdby: "",
  //                 createddate: 1694084512,
  //                 family: "Theoder",
  //                 given: "",
  //                 id: 4628,
  //                 period: ["periodMaster/71776"],
  //                 prefix: "Mr",
  //                 suffix: null,
  //                 text: "AAliya",
  //                 updatedate: 1694084512,
  //                 updatedby: "",
  //                 use: null,
  //               },
  //             ],
  //           },
  //         ],
  //         PersonID: [1563],
  //         ServiceCategoryID: [],
  //         ServiceTypeID: [],
  //         SpecialtyID: [456],
  //         _id: "Appointment/13931",
  //         _key: "13931",
  //         _rev: "_gk7JgTW--E",
  //         activestatus: true,
  //         appno: "AP-253",
  //         appointmentReason: [],
  //         appointmentType: "walkin",
  //         appointmentTypeID: [],
  //         appstatus: "booked",
  //         avcpatientstatus: "YET TO LOGIN",
  //         basedOn: [],
  //         bookedby: "Practitioner/10008",
  //         cancelationReason: [],
  //         comment: "Checked-In",
  //         consultationMode: "Direct Consultation",
  //         createdby: "",
  //         createddate: 1694084335,
  //         encounter: [],
  //         end: 1694104200,
  //         id: 253,
  //         identifier: [],
  //         minutesDuration: 0,
  //         orgid: "",
  //         orgtype: "CodingMaster/10010",
  //         participant: [],
  //         patientInstruction: "",
  //         priority: 1,
  //         reasonCode: [],
  //         reasonReference: [],
  //         report_docs: [],
  //         requestedPeriod: ["periodMaster/71770"],
  //         resourcecode: "Practitioner/10008",
  //         resourcerole: "Physician ",
  //         resourcetype: "Practitioner",
  //         slotID: [3814760],
  //         start: 1694097000,
  //         status: true,
  //         supportingInformation: [],
  //         updatedate: 1694084335,
  //         updatedby: "",
  //         Practitioner: [],
  //       },
  //       mobileNo: "+91908763678",
  //       index: 5,
  //       status: true,
  //       type: "frontdesk",
  //     },
  //   },
  // ];

  return (
    <div
      id={`${parent_id}_queue_parent_div`}
      style={
        {
          // height: "100vh",
          // overflow: "hidden auto",
        }
      }
    >
      {previousPatientList && previousPatientList.map((item, i) => (
        <Previous parentid={parent_id} item={item} index={i} />
      ))}
      {toAttendPatientList &&
        toAttendPatientList.map((item, i) => (
          <ToAttend item={item} index={i}></ToAttend>
        ))}
      {upNextPatientList &&
        upNextPatientList.map((item, i) => (
          <UpNext item={item} index={i}></UpNext>
        ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAllContexts(PatientQueue)));
