import { Typography } from "@material-ui/core";
import React from "react";
import { patientInfoJson } from "./dummyJson";
import { useStyles } from "./styles";
import {epochToDatetime} from "../../utils"

const PatientBillingDetails = (props) => {
  const classes = useStyles();
  const {dataPass} = props;
  console.log(dataPass,"PatientBillingDetails");




  return (
    <div>
      <div className={classes.alignSpacebetween} style={{paddingBottom:"12px"}}>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Name :</Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.[0]?.name}
          </Typography>
          <div className={classes.dot}></div>
          <Typography className={classes.label}>
            {dataPass?.[0]?.age}, {dataPass?.[0]?.gender?.display}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>MRN : </Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.[0]?.mrn}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Encounter :</Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.[0]?.encounterno}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Specialty :</Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.[0]?.speciality?.display }
          </Typography>
        </div>
      </div>

      <div className={classes.alignSpacebetween}>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Location :</Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.[0]?.location}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Start Date : </Typography>
          <Typography className={classes.labelValue}>
            {epochToDatetime(dataPass?.[0]?.startdate)}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>End Date :</Typography>
          <Typography className={classes.labelValue}>
            {epochToDatetime(dataPass?.[0]?.enddate)}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Doctor :</Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.[0]?.doctor}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PatientBillingDetails;
