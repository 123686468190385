import React from "react";
import AppHeader from "../components/layout/appHeader";

const withAppBar = (WrappedComponent) => {
  return (props) => {
    return (
      <div>
        <AppHeader></AppHeader>
        <WrappedComponent {...props} />
      </div>
    );
  };
};

export default withAppBar;
