import { makeStyles } from "@material-ui/core/styles";

export const useDialogStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 905,
    borderRadius: 8,
  },
}));

export const useDialogContentStyles = makeStyles((theme) => ({
  dividers: {
    padding: 0,
    borderColor: "rgba(222, 229, 236, 1)",
  },
}));
