import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Div,
  Paper,
  H6,
  Divider,
  Row,
  Col,
  Button,
  Image,
  SideMenu,
} from "qdm-component-library";
import "./navBar.css";
//import { withRouter } from "react-router-dom";
import { Popover, makeStyles, Typography, Modal } from "@material-ui/core";
import UploadFiles from "./uploadFiles";
import { AppRoutes } from "../../router/routes";
import { useNavigate } from "react-router-dom";
import { DrawerMeetingContext } from "../../context";
import Loading from "../global/loading";
import uploadIcon from "../../assets/img/svg/icons8-upload.svg";
import backIcon from "../../assets/img/svg/icons8-left.svg";
import { isFullScreen } from "../../utils";
import rightArrowIcon from "../../assets/img/svg/icons8-right.svg";
import CancelBookingPage from "../../components/queueCard/cancelBooking";
import FileIcon from "../../assets/img/svg/icons8-file.svg";
import { CircularProgress } from "@mui/material";
import { UIColor } from "../../themes/theme";

const useStyles = makeStyles((theme) => ({
  muiPopovers: {
    boxShadow: "0px 25px 50px #00000029",
    borderRadius: 8,
    marginTop: 10,
    padding: 10,
  },
  mykadbtn: {
    fontFamily: "pc_medium",
    fontSize: "14px !important",
    color: "#6F6F6F !important",
    backgroundColor: "#f2f2f2 !important",
  },
}));

export const TopNavbar = (props) => {
  const {
    sucessBtnName,
    navBarArr = [],
    title,
    btn = "Register & continue",
    noUpload,
    successfun,
    activeId = 1,
    navClick = () => null,
    putOnHold,
    parent_id,
    isRegisterFromMyKad,
    fetchMyKad = () => {},
    successBtnLoading = false,
  } = props;
  const navigate = useNavigate();

  const [isUpload, setIsUpload] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [viewfiles, setviewFiles] = React.useState([]);
  const AVCMeeting = React.useContext(DrawerMeetingContext);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

  useEffect(() => {
    setviewFiles(props?.initialFiles);
  }, [props?.initialFiles]);

  const redirectLink = (data) => {
    navClick(data.id);
  };

  const uploadFun = (e) => {
    setIsUpload(e?.currentTarget);
  };
  const goback = () => {
    //isFullScreen();
    if (props.isAVC && !AVCMeeting.open) {
      window.location.href = "/dashboard";
    } else {
      if (props.goBack) {
        props.goBack();
      } else {
        navigate(AppRoutes.dashboard);
      }
    }
  };
  const uploadDocument = (files, viewfiles) => {
    setFiles(files);
    if (viewfiles !== undefined) {
      setviewFiles(viewfiles);
    }
    if (props?.setFiles) {
      props?.setFiles(files);
      props?.setviewFiles(viewfiles);
    }
  };
  const classes = useStyles();
  const handleKadOpen = () => {
    props.handleKad();
    fetchMyKad();
  };

  const handleClick = () => {
    setIsButtonDisabled(true);
    successfun(files);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 5000);
  };
  return (
    <div id={`${parent_id}_topnavbar_parent_div`} style={{ height: "56px" }}>
      <Paper
        id={`${parent_id}_topnavbar_parent_paper`}
        style={{
          margin: "0px",
          // position: "fixed",
          zIndex: "999",
          background: "white",
          boxShadow: "0px 3px 6px #0000000A",
        }}
      >
        <Row
          id={`${parent_id}_topnavbar_parent_row`}
          className={
            navBarArr.length > 0 ? "topNav-top-Parent" : "topNav-top-Parent1"
          }
        >
          <Col id={`${parent_id}_topnavbar_parent_log_col`}>
            <Div
              id={`${parent_id}_topnavbar_parent_log_div`}
              className={"topNav-parent"}
            >
              {/* <i onClick={() => goback()} className="fa fa-angle-left topNav-backIcon" aria-hidden="true"></i> */}
              {!props.billView && (
                <Div
                  id={`${parent_id}_topnavbar_log_sub_div`}
                  className="uploadIcon"
                  onClick={() => goback()}
                >
                  <Image
                    id={`${parent_id}_topnavbar_log_image`}
                    src={backIcon}
                    alt="left"
                  />
                </Div>
              )}
              <H6
                id={`${parent_id}_topnavbar_title_h6`}
                className={"topNav-title"}
                style={{
                  width: props.billView
                    ? "180px"
                    : props?.iframe
                    ? "350px"
                    : "auto",
                }}
              >
                {/* {props.parent_edit_id ? "Modify Patient Details" : title} */}
                {props.parent_edit_id
                  ? "Modify Patient Details"
                  : props.parent_View_id
                  ? title //"Patient Details"
                  : title}
              </H6>
              {navBarArr.length > 0 ? (
                <Divider
                  id={`${parent_id}_topnavbar_title_rightside_divider`}
                  className={"topNav-divider"}
                  orientation="vertical"
                />
              ) : (
                <></>
              )}
            </Div>
          </Col>
          <Col id={`${parent_id}_topnavbar_title_col`}>
            {navBarArr.map((val, i) => {
              return (
                <p
                  id={`${parent_id}_${i}_topnavbar_title_label_p`}
                  //onClick={() => redirectLink(val)}
                  className={`${
                    activeId === val.id ? "topNav-active" : ""
                  } topNav-tabs`}
                >
                  {val.label}
                  {activeId === val.id && (
                    <Div
                      id={`${parent_id}_${i}_topnavbar_title_bottomdivider_div`}
                      className="bottomDivider"
                    ></Div>
                  )}
                </p>
              );
            })}
          </Col>
          {props?.parent_edit_id === true || props?.isModify === true ? (
            <Col
              id={`${parent_id}_topnavbar_buttons_div`}
              className={`topNav-actions-parent`}
            >
              <Button
                id={`${parent_id}_topnavbar_Register_continue_button`}
                className={"topNav-actions successBtn"}
                disabled={isButtonDisabled || successBtnLoading}
                onClick={handleClick}
                //disabled={props.view}
                // onClick = {() => {props.handleEditPatient()}}
                // onClick={
                //   successfun
                //     ? () => {
                //         successfun(files);
                //       }
                //     : () => null
                // }
              >
                {btn === "Registering..." ? "Saving..." : "Save Changes"}
              </Button>

              <Button
                id={`${parent_id}_topnavbar_cancel_button`}
                variant="text"
                className={"topNav-actions cancelBtn"}
                onClick={async () => {
                  await goback();
                }}
                disabled={props.view}
              >
                Cancel
              </Button>

              <Div
                id={`${parent_id}_topnavbar_upload_div`}
                className="uploadIcon"
                style={{ margin: "0px" }}
              >
                <Image
                  id={`${parent_id}_topnavbar_upload_image`}
                  src={viewfiles?.length > 0 ? FileIcon : uploadIcon}
                  alt="upload"
                  onClick={(e) => uploadFun(e)}
                  className=""
                />
              </Div>
            </Col>
          ) : (
            <>
              {!props?.iframe && (
                <Col
                  id={`${parent_id}_topnavbar_buttons_div`}
                  className={`topNav-actions-parent`}
                >
                  {successBtnLoading ? (
                    <div style={{ display: "inline-flex" }}>
                      <Typography style={{ color: "#EC6A49" }}>
                        {" "}
                        &nbsp;&nbsp; Registering...
                      </Typography>{" "}
                      <CircularProgress
                        style={{ color: "#EC6A49" }}
                        size={20}
                      />
                    </div>
                  ) : !props.isViewMode && !props?.isVisit ? (
                    <Button
                      id={`${parent_id}_topnavbar_Register_continue_button`}
                      className={"topNav-actions successBtn_"}
                      // disabled={btn === "Cancelling" ? true : false}
                      // onClick={successfun ? () => successfun(files) : () => null}
                      disabled={isButtonDisabled}
                      onClick={handleClick}
                    >
                      {sucessBtnName ? sucessBtnName : btn}
                    </Button>
                  ) : (
                    <>
                      {props?.isVisit === undefined && (
                        <Button
                          id={`${parent_id}_topnavbar_Register_continue_button`}
                          className={"topNav-actions successBtn_"}
                          disabled={btn === "Cancelling" ? true : false}
                          onClick={props?.approveStatus}
                        >
                          {"Approve"}
                        </Button>
                      )}
                      {props.isViewMode && props?.isVisit === undefined && (
                        <Button
                          id={`${parent_id}_topnavbar_put_on_hold_button`}
                          variant="outlined"
                          // disabled={props.view}
                          className={"topNav-actions topNav-actions-empty"}
                          // onClick={props?.handleSideMenu}
                          onClick={() => {
                            props?.setActionName("ON HOLD");
                            props?.handleSideMenu();
                          }}
                        >
                          ON Hold
                        </Button>
                      )}
                      {props.isViewMode && props?.isVisit === undefined && (
                        <Button
                          id={`${parent_id}_topnavbar_put_on_hold_button`}
                          variant="outlined"
                          className={"topNav-actions topNav-actions-empty"}
                          onClick={() => {
                            props?.setActionName("REJECT");
                            props?.handleSideMenu();
                          }}
                          // disabled={props.view}
                        >
                          Reject
                        </Button>
                      )}
                      {props?.isNextButton === false &&
                      props?.isVisit === undefined ? null : (
                        <>
                          {props?.isVisit === undefined && (
                            <Div
                              id={`${parent_id}_topnavbar_log_sub_div`}
                              className="uploadIcon"
                              onClick={props?.navigateToNextScreen}
                            >
                              {/* <InputOutlinedIcon/> */}
                              <Image
                                id={`${parent_id}_topnavbar_log_image`}
                                src={rightArrowIcon}
                                alt="left"
                              />
                            </Div>
                          )}
                        </>
                      )}
                      {props.isViewMode && viewfiles?.length > 0 && (
                        <Div
                          id={`${parent_id}_topnavbar_upload_div`}
                          className="uploadIcon"
                          style={{ margin: "10px" }}
                        >
                          <Image
                            id={`${parent_id}_topnavbar_upload_image`}
                            src={viewfiles?.length > 0 ? FileIcon : null}
                            alt="upload"
                            onClick={(e) => uploadFun(e)}
                            className=""
                          />
                        </Div>
                      )}
                    </>
                  )}
                  {!props.isViewMode && !props.billView && (
                    <Button
                      id={`${parent_id}_topnavbar_cancel_button`}
                      variant="text"
                      className={"topNav-actions cancelBtn"}
                      onClick={async () => {
                        await goback();
                      }}
                      disabled={props.view}
                    >
                      Cancel
                    </Button>
                  )}
                  {(!props?.isModify || props?.isBackMode) &&
                    !props.isFileUpload &&
                    !props.isViewMode &&
                    !props?.isVisit &&
                    !props.billView && (
                      <>
                        <Divider
                          id={`${parent_id}_topnavbar_leftside_divider`}
                          className={"topNav-divider topNav-divider-actions"}
                          orientation="vertical"
                        />
                        <Div
                          id={`${parent_id}_topnavbar_upload_div`}
                          className="uploadIcon"
                          inLineStyles={
                            !props?.newUser
                              ? {
                                  minWidth: "7%",
                                  marginLeft: "18px",
                                  height: "32px",
                                  marginTop: "1%",
                                }
                              : {
                                  marginLeft: 18,
                                }
                          }
                        >
                          <Image
                            id={`${parent_id}_topnavbar_upload_image`}
                            src={viewfiles?.length > 0 ? FileIcon : uploadIcon}
                            alt="upload"
                            onClick={(e) => uploadFun(e)}
                            className=""
                          />
                        </Div>
                      </>
                    )}
                  {props?.isMyKad === true ? (
                    <>
                      <Divider
                        id={`${parent_id}_topnavbar_leftside_divider`}
                        className={"topNav-divider topNav-divider-actions"}
                        orientation="vertical"
                      />
                      <Div>
                        <Button
                          id={`${parent_id}_topnavbar_mykad_button`}
                          variant="text"
                          className={`${classes.mykadbtn} topNav-actions`}
                          onClick={handleKadOpen}
                          disabled={props.view}
                        >
                          MyKad
                        </Button>
                      </Div>
                    </>
                  ) : null}
                  {/* {((!noUpload &&  viewfiles?.length >0 )|| props?.isVisit)  &&(
                
                <Div
                  id={`${parent_id}_topnavbar_upload_div`}
                  className="uploadIcon"
                  style={{ margin: "7px" }}
                >
                  <Image
                    id={`${parent_id}_topnavbar_upload_image`}
                    src={viewfiles?.length>0 ? FileIcon : uploadIcon}
                    alt="upload"
                    onClick={(e) => uploadFun(e)}
                    className=""
                  />
                </Div>
              )} */}
                </Col>
              )}
            </>
          )}
        </Row>
        <Popover
          anchorEl={isUpload}
          open={Boolean(isUpload)}
          onClose={() => uploadFun(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          style={{ zIndex: 99999 }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: classes.muiPopovers }}
        >
          <UploadFiles
            id={`${parent_id}_topnavbar_upload_uploadfiles`}
            uploadDocument={uploadDocument}
            files={files}
            viewfiles={viewfiles}
            isViewMode={props.isViewMode}
          />
        </Popover>
        <Modal
          id={`${parent_id}_topnavbar_mykad_modal`}
          width={1000}
          open={props.kadOpen}
          className="docc-modal"
          onOpen
          // onClose={() => setMyKadModalOpen(false)}
        >
          <Div
            style={{
              borderRadius: 16,
              background: "#fff",
              marginTop: 30,
              paddingTop: 75,
              paddingBottom: 75,
              paddingLeft: 25,
              paddingRight: 25,
              width: 1000,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Loading
              loaderStyle={{ width: 60, height: 60 }}
              style={{ marginBottom: 40 }}
              loading={true}
            />
            <Div>
              <Typography style={{ fontSize: 20, color: "#001C3C" }}>
                Fetching data from MyKad
              </Typography>
            </Div>
          </Div>
        </Modal>
        <SideMenu
          id="patient_dashboard_cancel_sidemenu"
          open={props?.isSideMenuOpen}
          direction="right"
          width={440}
          color="default"
          onClose={props?.handleSideMenuclose}
          style={{ padding: 0 }}
        >
          <CancelBookingPage
            parent_id={parent_id}
            cancelbookingreason={props?.cancelbookingreason?.value}
            appointmentId={props?.totalData?.inputdoc?.appointmentid}
            handleSelectCancelbooking={props?.handleSelectCancelbooking}
            handlecahngeCancelbooking={props?.handlecahngeCancelbooking}
            cancelData={props?.cancelData}
          />
        </SideMenu>
      </Paper>
    </div>
  );
};

TopNavbar.propTypes = {
  btn: PropTypes.string,
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  navBarArr: PropTypes.array,
  titleIcon: PropTypes.element,
  onTitleIconClicked: PropTypes.func,
};

TopNavbar.defaultProps = {
  withBackButton: false,
};

export default TopNavbar;
