import React from 'react';
import { Row, Col, H6, Select, TextInput } from 'qdm-component-library';
import { Checkbox, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    checkbox: {
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#0071F2'
        }
    }
});

export const Attributes = (props) => {

    const classes = useStyles(props);

    const styles = {
        h6: {
            color: '#101010',
            fontSize: 14
        },
        labelStyle: {
            color: "#6F6F6F",
            fontSize: "12px",
            marginBottom: "6px",
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: "#E0E0E0",
        },
        colPadding: {
            padding: "7px",
        },
    };
    const { parent_id } = props;
    return (
        <div
            id={`${parent_id}_attribute_parent_div`}
        >
            <H6
                id={`${parent_id}_attribute_${props?.title.replaceAll(" ", "_")}_h6`}
                className="pc_semibold" style={styles.h6}>{props?.title}</H6>
            <Row
                id={`${parent_id}_attribute_View_parent_row`}
            >
                <Col
                    id={`${parent_id}_attribute_View_col`}
                    xs={12} sm={12} md={4} lg={4} xl={4} inLineStyles={styles.colPadding}>
                    <Select
                        id={`${parent_id}_attribute_View_select`}
                        showArrow
                        label={"View"}
                        isRequired
                        placeholder={"Select View"}
                        labelStyle={styles.labelStyle}
                        inLineStyles={styles.borderStyle}
                        hoverColor="#0071F2"
                        value={props?.attributes?.view?.value}
                        onChangeValue={(e) => props?.onChangeState("view", e)}
                        options={[
                            { label: 'PA', value: 'PA' },
                            { label: 'SA', value: 'SA' },
                        ]}
                    />
                </Col>
                <Col
                    id={`${parent_id}_attribute_Bed_Side_col`}
                    xs={12} sm={12} md={4} lg={4} xl={4} className={classes.checkbox} inLineStyles={styles.colPadding}>
                    <H6
                        id={`${parent_id}_attribute_Bed_Side_h6`}
                        className="pc_regular" style={{ margin: 0, ...styles.labelStyle }}>Bed Side</H6>
                    <Checkbox
                        id={`${parent_id}_attribute_Bed_Side_checkbox`}
                        checked={props?.attributes?.bedSide}
                        onChange={(e) => props?.onChangeState("bedSide", e.target.checked)}
                    />
                </Col>
                <Col
                    id={`${parent_id}_attribute_contrast_col`}
                    xs={12} sm={12} md={4} lg={4} xl={4} inLineStyles={styles.colPadding}>
                    <TextInput
                        id={`${parent_id}_attribute_contrast_textinput`}
                        value={props?.attributes?.contrast}
                        onChange={(evt) => props?.onChangeState("contrast", evt.target.value)}
                        variant={"outlined"}
                        hoverColor="#0071F2"
                        placeholder="Contrast"
                        label="Contrast"
                        labelStyle={styles.labelStyle}
                        style={styles.borderStyle}
                    />
                </Col>
            </Row>
        </div>
    )
}

Attributes.defaultProps = {
    title: 'Attributes'
}