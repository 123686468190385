import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import {
  Humburger,
  DashboardIcon,
  CloseConsultation,
  WorkList,
  MenuIcon,
  DocumentIcon,
} from "../svg";
import {AssignPractitioner} from "../svg/components/assignPractitioner";
import {ReAssignPractitioner} from "../svg/components/reassignPractitioner";
const useStyles = makeStyles(() => ({
  flexRow: (props) => ({
    display: "flex",
    alignItems: "center",
    padding: 8,
    backgroundColor: props?.backgroundColor ?? "transparent",
    borderRadius: props?.backgroundColor ? 4 : 0,
    cursor: "pointer",
  }),
  label: (props) => ({
    fontSize: 12,
    fontFamily: "pc_medium",
    color: "#101010",
    flex: 1,
    color: props?.color ?? "#000",
  }),
  icon: {
    paddingRight: 8,
    display: "flex",
  },
}));

export const CustomListing = (props) => {
  const classes = useStyles(props);

  const icon = (label) => {
    if (label === "Dashboard") {
      return <DashboardIcon />;
    } else if (label === "Work List") {
      return <WorkList />;
    } else if (label === "Assign Practitioner") {
      return <AssignPractitioner />;
    } else if (label === "Re-Assign Practitioner") {
      return <ReAssignPractitioner />;
    } else if (label === "Close Consultation") {
      return <CloseConsultation />;
    } else if (label === "Day End Process") {
      return <DocumentIcon color={"#FF9F40"} />;
    } else if (label === "Menu name") {
      return <MenuIcon />;
    } else if (label === "Biller and Cashier") {
      return <WorkList />;
    }
  };

  return (
    <div className={classes.flexRow} onClick={props?.onClick ?? null}>
      {/* <div className={classes.icon}>{props.icon}</div> */}
      <div className={classes.icon}>{icon(props.label)}</div>
      <Typography variant="body1" className={classes.label}>
        {props?.label}
      </Typography>
    </div>
  );
};
