import React from "react";
import {
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Paper, TextInput } from "qdm-component-library";
// import { tableData, Header } from "./dummyData";
import { useStyles } from "../styles";
import Cancel from "../../../assets/img/svg/icons8-multiply.svg";
import Tick from "../../../assets/img/svg/icons8-double-tick.svg";
import Trash from "../../../assets/img/svg/icons8-trash.svg";
import printIcon from "../../../assets/img/svg/print-icon-noframe.svg"
import { UIColor } from "../../../themes/theme";
import moment from "moment";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const TableComponent = ({
  tableData = [],
  Header = [],
  dataType = [],
  tableWidth = "100%",
  totalCalculationRow = false,
  calculationResultData = [],
  customRow = false,
  handleCheckBox = () => null,
  handleHeaderCheckBox = () => null,
  handleDeleteSelected = () => null,
  handleSelectedPrint = () => null,
  handleTableTextChange = () => null,
  handleOpenPopup = () => null,
  allChecked = false,
  headerSize = "14px",
  isPrintTotalData = false,
  printTotalData = [],
  payerValue = false,
  openSplitPayer
}) => {
  const classes = useStyles();
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      width: "90%",
      height: "30px",
    },
    inputStyle: {
      padding: 5,
      fontSize: "12px",
      fontWeight: 500,
    },
  };
  //   const tableData =[
  //     {
  //         "service": "IVEPO",
  //         "description": "IV EPO ",
  //         "orderRef": "",
  //         "date": "18/09/2023 10:16",
  //         "category": "REGULAR",
  //         "qty": 2,
  //         "uom": "AMP",
  //         "uomId": "DrugUOM/10002",
  //         "rate": 5,
  //         "amt": 0,
  //         "status": "ordered",
  //         "billed": false,
  //         "checked": false,
  //         "id": "RCM_PatientChargeTrx/10954",
  //         "orderdispenseid": "",
  //         "orderrefid": "PH_OrderLine/12663",
  //         "chargecodeid": "RC_ChargeCode/10022"
  //     }
  // ]
  const generateComp = (type, row, rowIndex) => {
    switch (type.type) {
      case "CHECK": {
        if (row?.[type.name] === true) {
          return <img src={Tick} alt="Cancel" />;
        } else {
          return <img src={Cancel} alt="Cancel" />;
        }
      }
      case "INCREMENT": {
        return (
          <Typography className={classes.tableCellTypo}>
            {rowIndex + 1}
          </Typography>
        );
      }
      case "TEXT": {
        const returnData = row?.[type.name];
        if(type.name === "creditShare" && Number(returnData) > 0){
          return (
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item spacing={1}>
                  <Typography className={classes.tableCellTypo} >
                    {Number.isNaN(returnData) ? 0.0 : returnData}
                  </Typography>
              </Grid>
              <Grid item>
                  <IconButton size="small"
                    onClick={(e) =>handleOpenPopup(e, row, rowIndex, payerValue)}>
                  {openSplitPayer ?<ExpandLessIcon/>:<ExpandMoreIcon/>}
                  </IconButton>
              </Grid>
            </Grid>
            
          );
        }
        else if (returnData?.length > 45) {
          return (
            <Tooltip title={returnData} placement="top">
              <Typography className={classes.tableCellTypo}>
                {returnData.slice(0, 45) + "..."}
              </Typography>
            </Tooltip>
          );
        } else {
          return (
            <Typography className={classes.tableCellTypo}>
              {Number.isNaN(returnData) ? 0.0 : returnData}
            </Typography>
          );
        }
      }
      case "BILLTEXT": {
        return (
          <Typography
            className={classes.tableCellTypo}
            style={{ fontSize: "12px" }}
          >
            {row?.[type.name]}
          </Typography>
        );
      }
      case "CHECKBOX": {
        // console.log("checked", row?.[type.name]);
        if (row?.billed) {
          return null;
        } else {
          return (
            <Checkbox
              style={{ padding: "0px" }}
              checked={row?.[type.name]}
              color="primary"
              size="small"
              onChange={(e) => handleCheckBox(e, row, rowIndex)}
            />
          );
        }
      }
      case "DELETE": {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteSelected(row, rowIndex)}
          >
            <img src={Trash} alt="delete" />
          </div>
        );
      }
      case "ACTION": {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={(e) => handleSelectedPrint(e, row, rowIndex)}
          >
            <img src={printIcon} alt="print" />
          </div>
        );
      }
      case "TEXTFIELD": {
        return (
          <div>
            <TextInput
              className={"textInput"}
              id={"discAmtTab"}
              label={""}
              placeholder=""
              type="number"
              disabled={
                ((type.name === "patientShare" && (!payerValue || payerValue === "Self Pay")) || (type.name === "rate" && !row.chargeedit )) ? true : false
              }
              labelStyle={styles.labelStyle}
              style={{ ...styles.borderStyle, ...styles.inputStyle }}
              value={row?.[type.name]}
              onChange={(value) =>
                handleTableTextChange(value, row, rowIndex, type.name)
              }
              hoverColor={UIColor.lineBorderFill.color}
            />
          </div>
        );
      }
      case "EPOCH_DATE_DISPLAY": {
        return (
          <Typography className={classes.tableCellTypo}>
            {moment(row?.[type.name] * 1000).format("DD/MM/YYYY")}
          </Typography>
        );
      }
      default: {
        return row?.[type.name];
      }
    }
  };

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table style={{ width: tableWidth }}>
          <TableHead>
            <TableRow>
              {Header?.map((val, i) => {
                return (
                  <TableCell
                    className={classes.headerCell}
                    style={{
                      position:
                        val === "S.No" || val === "All" ? "sticky" : "relative",
                      left: 0,
                      zIndex: val === "S.No" || val === "All" ? 2 : 0,
                      right: 0,
                      fontSize: headerSize ? headerSize : "14px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {val === "All" ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "3px",
                        }}
                      >
                        <Checkbox
                          style={{ padding: "0px" }}
                          checked={allChecked}
                          color="primary"
                          size="small"
                          onChange={(e) => handleHeaderCheckBox(e)}
                        />
                        {val}
                      </div>
                    ) : (
                      val
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody style={{ overflow: "auto" }}>
            {tableData?.map((data, dataIndex) => {
              return (
                <TableRow  style={{height:"50px"}}>
                  {dataType?.map((type, index) => {
                    return (
                      <TableCell
                        className={classes.childCell}
                        style={{
                          position:
                            type?.header === "S.No" || type?.header === "All"
                              ? "sticky"
                              : "relative",
                          left: 0,
                          zIndex:
                            type?.header === "S.No" || type?.header === "All"
                              ? 2
                              : 0,
                          right: 0,
                          width:
                            type.type === "TEXTFIELD"
                              ? "140px"
                              : type?.width
                              ? type?.width
                              : "auto",
                          padding: type.type === "TEXTFIELD" ? "5px" : "10px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {generateComp(type, data, dataIndex)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {totalCalculationRow && (
              <TableRow>
                {calculationResultData?.map((val, i) => (
                  <TableCell className={classes.headerCell}>
                    <Typography className={classes.tableCellTypo}>
                      {val}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            )}
            {isPrintTotalData && (
              <TableRow>
                {printTotalData?.map((val, i) => (
                  <TableCell className={classes.childCell}>
                    <Typography
                      className={classes.tableCellTypo}
                      style={{ fontSize: "12px" }}
                    >
                      {val}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {customRow && (
        <div className={classes.customRow}>
          <Typography className={classes.tableCellTypo}>
            {tableData?.length < 10
              ? "0" + tableData?.length
              : tableData?.length}{" "}
            {tableData?.length === 1 ? "Item" : "Items"}
          </Typography>
          <Typography
            className={classes.headerCell}
            style={{ padding: "6px 6px 5px" }}
          >
            Total
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TableComponent;
