const ChiefComplaintsJson = [
    // {
    //     "onset": {
    //         "day": "12",
    //         "freq": {},
    //         "currentDate": "03 Jul, 2021",
    //         "isCalenderEnabled": true,
    //         "calenderIconEnabled": true
    //     },
    //     "abatement": {
    //         "day": "12",
    //         "freq": {
    //             "value": "Month",
    //             "label": "Month"
    //         },
    //         "currentDate": "",
    //         "isCalenderEnabled": false,
    //         "calenderIconEnabled": false
    //     },
    //     "complaint": "fever",
    //     "name": "87803-Swelling",
    //     "bodySite": "Left Hand",
    //     "severity": "Severe",
    //     "assertedBy": "Others",
    //     "relationName": "mother",
    //     "modifying_factor": "1.4",
    //     "status_verification": "Unconfirmed",
    //     "clinicalStatus": "Active",
    //     "notes": "sample check",
    //     statusType: "inactive",
    //     remarks: []
    // }
]
export default ChiefComplaintsJson;