import React, { useContext, useEffect, useState } from "react";
import MultiBook from ".";
import { mbdata } from "./demo";
import moment from "moment";
import {
  AlertProps,
  cuurentNextTimeslot,
  startTime,
  GetQueueData,
} from "../../utils";
import jwtDecode from "jwt-decode";
import { AlertContext } from "../../context";
import { useDispatch } from "react-redux";
import actions from "../../redux/actions";
import { QueueManagementContext } from "../../context";

function MultiBookComp(props) {
  const {
    allData,
    displayData,
    previousData,
    previousQueue,
    loader,
    setAllData,
    setDisplayData,
    setPreviousData,
    setpreviousQueue,
    setloader,
  } = React.useContext(QueueManagementContext);
  const [state, setState] = useState({
    expanded: false,
    bookAppoinment: false,
    time: props?.time || "",
    info: props?.info || {},
    date: props?.date || "",
    multiApppointmentInfo: {
      appointments: {
        startsOn: {
          date: moment().format("DD-MM-YYYY"),
          slotSelect: {
            label: "",
            value: "",
          },
          milli: startTime(),
        },
        preferrence: {
          preferredDays: [],
          preferredWeeks: [],
        },
        occurence: {
          occurrenceHandle: "NoOfDays",
          occurrenceValue: 1,
        },
        reccurance: {
          reccuranceValue: 1,
          reccuranceType: {
            label: "Weeks",
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS,
          },
        },
      },
      info: {
        dates: [],
        appointments: {},
        practData: {},
      },
      data: [],
      slotObj: {},
      filterData: {},
      uploadFiles: [],
    },
    hospitalInfo: props?.hospitalInfo,
  });
  const [loading, setLoading] = useState(false);
  const [showHide, setShowHide] = useState(0);
  useEffect(() => {
    setShowHide(0);
  }, [props?.multiBookOpen]);
  const alert = useContext(AlertContext);
  const dispatch = useDispatch();
  const handleMultiAppointmentChange = (val, key1, key2, key3, key4) => {
    const statedata = { ...state };
    console.log(val, key1, key2, key3, key4, "MultiP90");
    if (key4) {
      if (key4 === "deleteAllDays") {
        statedata["multiApppointmentInfo"][key1][key2][key3] = [];
      }
      if (key4 === "s1") {
        statedata["multiApppointmentInfo"]["appointments"]["preferrence"][
          "preferredDays"
        ] = [
          {
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_FRIDAY,
            label: "Fri",
          },

          {
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_WEDNESDAY,
            label: "WED",
          },

          {
            label: "MON",
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_MONDAY,
          },
        ];
      }
      if (key4 === "s2") {
        statedata["multiApppointmentInfo"]["appointments"]["preferrence"][
          "preferredDays"
        ] = [
          {
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_SATURDAY,
            label: "Sat",
          },
          {
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_THURSDAY,
            label: "Thu",
          },

          {
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_TUESDAY,
            label: "Thu",
          },
        ];
      }
    }
    if (key2) {
      if (key3) {
        statedata["multiApppointmentInfo"][key1][key2][key3] = val;
      } else {
        statedata["multiApppointmentInfo"][key1][key2] = val;
      }
    } else {
      statedata["multiApppointmentInfo"][key1] = val;
      if (key1 === "info") {
        statedata["multiApppointmentInfo"]["slotObj"] = {};
      }
    }

    setState({
      ...statedata,
    });
  };

  const multiBooking = async () => {
    setLoading(true);
    let token = jwtDecode(localStorage.getItem("UserInfo"));
    //let { uploadFiles } = state;
    //const { info = {}, hospitalInfo = {} } = state.selectedSlot;
    //UploadedFiles = await uploadImage(uploadFiles);

    var appointMentInputArr = [];
    state?.multiApppointmentInfo?.data.forEach((singleSlot) => {
      let appointMentInput = {
        consultationMode: props?.mBData?.inputdoc?.consultationmode,
        status: true,
        cancelationReason: [],
        reasonCode: [],
        priority: 1,
        start: singleSlot.start || "",
        end: singleSlot.end || "",
        minutesDuration: 15,
        comment: "comments",
        appointmentReason: Array.isArray(
          props?.mBData?.inputdoc?.appointmentreason
        )
          ? props?.mBData?.inputdoc?.appointmentreason
          : [props?.mBData?.inputdoc?.appointmentreason],
        slotID: [singleSlot._id || 0],
        ServiceCategoryID: [],
        ServiceTypeID: [],
        SpecialtyID: Array.isArray(props?.mBData?.inputdoc?.specialtyid)
          ? props?.mBData?.inputdoc?.specialtyid
          : [props?.mBData?.inputdoc?.specialtyid],
        resourcetype: singleSlot?.resourceInfo?.resourcetype || "",
        resourcerole: singleSlot?.resourceInfo?.resourcerole || "",
        resourcecode: Array.isArray(singleSlot?.resourceInfo?.resourcecode?.[0])
          ? singleSlot?.resourceInfo?.resourcecode?.[0]
          : singleSlot?.resourceInfo?.resourcecode,
        orgid: token?.facilityid,
        PersonID: props?.mBData?.inputdoc?.personid,
        //props?.mBData?.inputdoc?.personId.toString(),
        appno: "",
        patient_id: props?.mBData?.inputdoc?.patientId,
        AppointmentNotification:
          props?.mBData?.inputdoc?.AppointmentNotification,
        bookedby: token?.practitionerid,
        appointmentTypeID: singleSlot?.appointmentType || [],
        facilityid: token?.facilityid,
        tenantid: token?.tenantid,
        transappid: props?.mBData?.isTransit ? props?.mBData?.appid : "",
        modeofappointment: process.env.REACT_APP_MODE_OF_APPOINTMENT_WALKIN, //FIXME -
        //          props?.mBData?.inputdoc?.appointmenttype,
      };
      appointMentInputArr.push(appointMentInput);
    });

    const appInfo = await dispatch(
      actions.APPOINTMENT_CREATE({ doc: appointMentInputArr })
    );

    let ticketId = props?.mBData?.ticketId;

    if (!appInfo?.payload?.data?.[0]?.error) {
      if (ticketId) {
        setloader(true);
        const schedule = await dispatch(
          actions.SCHEDULE_APPOINTMENT({
            ticketId: ticketId,
            practitionerId: token?.practitionerid, //userinfo
            practitionerRoleId: token?.roleid, //roleid
          })
        );

        if (schedule?.payload?.error || schedule?.payload?.data?.error) {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg:
              schedule?.payload?.data?.Response?.[0]?.errorMessage ||
              "something went wrong !",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        } else {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Appointment Booked successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        }
      } else if (!ticketId && appInfo?.payload?.data?.[0]?.statusCode === 201) {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: appInfo?.payload?.data?.[0]?.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      } else {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg:
            appInfo?.payload?.data?.[0]?.response?.errorMessage ||
            appInfo?.payload?.data?.[0]?.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg:
          appInfo?.payload?.data?.[0]?.response?.errorMessage ||
          appInfo?.payload?.data?.[0]?.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
    if (props?.callback) {
      props?.callback();
    } else {
      let data = await GetQueueData();
      setAllData(data.everyData);
      setDisplayData(data.getDisplayData);
      setPreviousData(data.getPreviousData);
      setpreviousQueue(props?.selectedQueueid);
    }
    setloader(false);
    setLoading(false);
    props.setMultiBookOpen(false);
  };
  return (
    <div>
      <MultiBook
        bookAppoinment={
          props?.multiBookOpen
          //|| true || state?.bookAppoinment
        }
        time={moment().format("Do MMM YYYY")}
        multiApppointmentInfo={state?.multiApppointmentInfo}
        info={mbdata.info()}
        date={cuurentNextTimeslot()}
        handleMultiAppointmentChange={handleMultiAppointmentChange}
        setMultiBookOpen={props?.setMultiBookOpen}
        multiBookOpen={props?.multiBookOpen}
        hospitalInfo={{ isClinic: true }}
        mBData={props?.mBData}
        shortcut={true}
        multiBooking={multiBooking}
        showHide={showHide}
        setShowHide={setShowHide}
        loading={loading}
      />
    </div>
  );
}

export default MultiBookComp;
