import React from "react";
import { actions } from "primarycare-binder";
import HighAlertPopUp from "../highAlertPopUp";
import { Popover, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import qdmstyles from "./styles.json";
//import { withRouter } from "react-router-dom";
import iconHigh from "../../assets/img/icons8-high priority red.svg";
import iconHighAlert from "../../assets/img/icons8-high priority white.svg";
import { withStyles } from "@material-ui/core/styles";
import {
	Divider,
	Text,
	Div,
	Button,
	Card,
	Image,
	H2,
} from "qdm-component-library";
import withAllContexts from "../../hoc/withAllContexts";
import moment from "moment";
import { getUtcTime } from "../../utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { UIColor } from "../../themes/theme";



const styles = (theme) => ({
	paper: {
		padding: theme.spacing(2),
		width: "290px",
    height:"250px"
	},
});
const Queue = (props) => {
  const { parent_id, markHighPriority, assignReassignPractitioner , classes } = props;

  const dispatch = useDispatch();

  const [state , setState] = React.useState({
    anchorEl: null,
        priorityTotalData: {
          key: "",
          patientPriority: "",
        },
  })

  const loggedUserInfo = useSelector((state) => (state?.authSlice?.loggedUserInfo));

  const handlePriority = async (list, _key, app, dropdownValue) => {
    const upsertdata = await dispatch(actions.UPSERT_PRIORITIES({ list, _key }));

    const masterData = [...props.queueManagement.data];
    const newData = [...props.queueManagement.displayData];
    const newOnHoldData = [...props.queueManagement.onHoldData];
    let masterDatavariable = masterData.find((d) => d.token === app[0].id);
    let newDataVariable = newData.find((d) => d.token === app[0].id);
    let newOnHoldDataVariable = newOnHoldData.find(
      (d) => d.token === app[0].id
    );
    if (masterDatavariable) {
      const patientPriority = {
        priority: dropdownValue?.priority,
        reason: dropdownValue?.reason,
      };
      masterDatavariable["patientPriority"] = patientPriority;
    }
    if (newDataVariable) {
      const patientPriority = {
        priority: dropdownValue?.priority,
        reason: dropdownValue?.reason,
      };
      newDataVariable["patientPriority"] = patientPriority;
    }
    if (newOnHoldDataVariable) {
      const patientPriority = {
        priority: dropdownValue?.priority,
        reason: dropdownValue?.reason,
      };
      newOnHoldDataVariable["patientPriority"] = patientPriority;
    }
    props.queueManagement.setData(masterData);
    props.queueManagement.setDisplayData(newData);
    props.queueManagement.setOnHoldData(newOnHoldData);
    
    //window.location.href = "/";
	 const patientData =  await dispatch(actions.HIGH_PRIORITY_PATIENT({
     _role: state?.priorityTotalData?.role_ === "nurse" ? 2 : 1,
     practionerId: loggedUserInfo?.data?.practionerId,
     org_id: loggedUserInfo?.data?.org_id,
     startDate: getUtcTime(moment().startOf("day")),
     endDate: getUtcTime(moment().endOf("day")),
   }));
  };

  const handleHighAlertPop = (event, flow, encounter, patientPriority, totalData,role_) => {
    const priorityTotalData = {
      key: encounter,
      patientPriority: patientPriority,
      highPriority: patientPriority?.priority?.value?.length > 0 ? true : false,
      appointmentId: [totalData?.info],
      role_: role_
    };
    setState({
      ...state,
      anchorEl: event.target,
      priorityTotalData: priorityTotalData,
    });
  };

  const handleHighAlertPopClose = () => {
    setState({ ...state, anchorEl: null });
  };

 

  // shouldComponentUpdate(props, state) {
  //   return true;
  // }

  
   
    const open = Boolean(state.anchorEl);
  
    return (
      <div
        id={`${parent_id}_queue_parent_div`}
        style={{
          // height: "100vh",
          // overflow: "hidden auto",
        }}
      >
        {props?.previous &&
          props?.previous?.map((p, i) => {
            return (
              <Div
                style={qdmstyles.wrapper}
                key={i}
                id={`${parent_id}${i}_queue_previous_parent_div`}
              >
                <Div
                  style={qdmstyles.header}
                  id={`${parent_id}${i}_queue_previous_sub_div`}
                >
                  {p?.title && (
                    <H2
                      id={`${parent_id}${i}_queue_previous_title_h2`}
                      className="pc_semibold"
                      style={qdmstyles.headerTitle}
                    >
                      {p?.title}
                    </H2>
                  )}
                  {p?.token && (
                    <Div
                      id={`${parent_id}${i}_queue_previous_token_div`}
                      className="pc_medium"
                      style={qdmstyles.tokenNumber}
                    >
                      {p?.token}
                    </Div>
                  )}
                </Div>
                <Card
                  style={qdmstyles.cardStyle}
                  id={`${parent_id}${i}_queue_previous_card`}
                >
                  <Div
                    style={qdmstyles.previousNextBody}
                    id={`${parent_id}${i}_queue_previous_previousNextBody_div`}
                  >
                    {p?.profile_pic && (
                      <Div
                        style={qdmstyles.previousNextBodyAvatar}
                        id={`${parent_id}${i}_queue_previous_previousNextBody_avatar_div`}
                      >
                        <Image
                          id={`${parent_id}${i}_queue_previous_previousNextBody_image`}
                          src={p?.profile_pic}
                          alt={p?.name ?? "profilePic"}
                          style={{
                            border: p?.isEmergency ? "1px dashed #FF4D4A" : "0",
                          }}
                        />
                        {p?.isEmergency && (
                          <Div
                            id={`${parent_id}${i}_queue_previous_emergencyDiv_div`}
                            style={qdmstyles.emergencyDiv}
                          >
                            <Text
                              id={`${parent_id}${i}_queue_previous_emergencyText_text`}
                              className="pc_medium"
                              style={qdmstyles.emergencyText}
                            >
                              EMERGENCY
                            </Text>
                          </Div>
                        )}
                      </Div>
                    )}
                    <Div
                      style={{ textAlign: "left" }}
                      id={`${parent_id}${i}_queue_previous_previousNextBodyName_div`}
                    >
                      {p?.name && (
                        <H2
                          id={`${parent_id}${i}_queue_previous_previousNextBodyName_h2`}
                          className="pc_semibold"
                          style={qdmstyles.previousNextBodyName}
                        >
                          {p?.name}
                        </H2>
                      )}
                      {p?.role && (
                        <Text
                          id={`${parent_id}${i}_queue_previous_previousNextBodyRole_text`}
                          className="pc_regular"
                          style={qdmstyles.previousNextBodyRole}
                        >
                          {p?.role}
                        </Text>
                      )}
                    </Div>
                    {/* {!assignReassignPractitioner && (
                      <Div
                        style={{ marginLeft: "auto" }}
                        onClick={
                          p?.assignButtonAction
                            ? () => p.assignButtonAction()
                            : null
                        }
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            borderRadius: "50%",
                            background: "#ebebeb",
                            height: "35px",
                            width: "35px",
                            margin: "auto",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            inLineStyles={{
                              height: "17px",
                              width: "16px",
                              marginLeft: "10px",
                            }}
                            src={"images/icons8-repeat.svg"}
                          />
                        </div>
                      </Div>
                    )} */}
                  </Div>
                </Card>
              </Div>
            );
          })}
        {props?.attend &&
          props?.attend?.map((a, i) => {
            return (
              <Div
                id={`${parent_id}${i}_queue_attend_parent_div`}
                style={qdmstyles.wrapper}
                key={i}
              >
                <Div
                  id={`${parent_id}${i}_queue_attend_parent_sub_div`}
                  style={qdmstyles.header}
                >
                  {a?.title && (
                    <H2
                      id={`${parent_id}${i}_queue_attend_title_h2`}
                      className="pc_semibold"
                      style={qdmstyles.headerTitle}
                    >
                      {a?.title}
                    </H2>
                  )}
                  {a?.token && (
                    <Div
                      id={`${parent_id}${i}_queue_attend_token_number_div`}
                      className="pc_medium"
                      style={qdmstyles.tokenNumber}
                    >
                      {a?.token}
                    </Div>
                  )}
                </Div>
                <Card
                  id={`${parent_id}${i}_queue_attend_parent_card`}
                  style={qdmstyles.cardStyle}
                >
                  {!markHighPriority && (
                    <Div style={qdmstyles.highAlertPosition}>
                      <Div
                        style={{
                          backgroundColor:
                            a?.patientPriority?.priority?.value === "High"
                              ? "#FF4D4A"
                              : "#F9E6E6",
                          padding: "5px 8px 3px",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                        onClick={(event) =>
                          handleHighAlertPop(
                            event,
                            a?.title,
                            a?.encounter,
                            a?.patientPriority,
							a?.totalData,
							a?.role_
                          )
                        }
                      >
                        <Image
                          inLineStyles={{ height: "17px", width: "16px" }}
                          src={
                            a?.patientPriority?.priority?.value === "High"
                              ? iconHighAlert
                              : iconHigh
                          }
                        />
                      </Div>
                    </Div>
                  )}
                  <Div
                    id={`${parent_id}${i}_queue_attend_attendbody_div`}
                    style={qdmstyles.attendBody}
                  >
                    {a?.profile_pic && (
                      <Div
                        id={`${parent_id}${i}_queue_attend_profile_div`}
                        style={{ ...qdmstyles.attendBodyAvatar, marginTop: 20 }}
                      >
                        <Image
                          id={`${parent_id}${i}_queue_attend_profilepic_image`}
                          src={a?.profile_pic}
                          alt={a?.name ?? "profilePic"}
                          style={{
                            border: a?.isEmergency ? "1px dashed #FF4D4A" : "0",
                          }}
                        />
                        {a?.isEmergency && (
                          <Div
                            id={`${parent_id}${i}_queue_attend_emergency_div`}
                            style={qdmstyles.emergencyDiv}
                          >
                            <Text
                              id={`${parent_id}${i}_queue_attend_emergency_text`}
                              className="pc_medium"
                              style={{
                                ...qdmstyles.emergencyText,
                                fontSize: 10,
                              }}
                            >
                              EMERGENCY
                            </Text>
                          </Div>
                        )}
                      </Div>
                    )}
                    {a?.name && (
                      <H2
                        id={`${parent_id}${i}_queue_attend_previousNextBodyName_h2`}
                        className="pc_semibold"
                        style={qdmstyles.previousNextBodyName}
                      >
                        {a?.name}
                      </H2>
                    )}

                    {a?.role && (
                      <Text
                        id={`${parent_id}${i}_queue_attend_previousNextBodyRole_text`}
                        className="pc_regular"
                        style={{
                          ...qdmstyles.previousNextBodyRole,
                          margin: "5px 0px",
                        }}
                      >
                        {a?.role}
                        {a?.isRegister && ` • ${a?.isRegister}`}
                      </Text>
                    )}

                    {a?.start && (
                      <Text
                        id={`${parent_id}${i}_queue_attend_previousNextBodyRole_start_text`}
                        className="pc_regular"
                        style={{
                          ...qdmstyles.previousNextBodyRole,
                          margin: "5px 0px",
                        }}
                      >
                        {a?.start}
                      </Text>
                    )}

                    {a?.primaryButtonName && !props.queueWrite && (
                      <Button
                        id={`${parent_id}${i}_queue_attend_primarybutton_button`}
                        className="pc_medium"
                        type="button"
                        name={a?.consultationMode !== "Direct Consultation" ? "Join Now" : a?.primaryButtonName}
                        variant="outlined"
                        size={14}
                        backgroundColor={UIColor.secondaryButtonColor.color}
                        onClick={() => a?.primaryButtonAction(a)}
                        inLineStyles={{
                          marginTop: "10px",
                          width: "100%",
                          borderRadius: 8,
                          fontSize: 13,
                        }}
                      />
                    )}
                  </Div>
                  <Divider
                    id={`${parent_id}${i}_queue_attend_divider`}
                    style={qdmstyles.divider}
                  />
                  {!props.queueWrite && (
                    <Grid
                      id={`${parent_id}${i}_queue_secondarybutton_div`}
                      spacing={2}
                      container
                    >
                      <Grid item xs={!assignReassignPractitioner ? 6 : 12}>
                        <Div
                          id={`${parent_id}${i}_queue_attend_secondarybutton_div`}
                          style={qdmstyles.secondaryButton}
                        >
                          {a?.secondaryButtonName && (
                            <Button
                              id={`${parent_id}${i}_queue_attend_secondarybutton_button`}
                              type="button"
                              name={""}
                              variant="text"
                              size={12}
                              onClick={
                                a?.secondaryButtonAction
                                  ? () => a.secondaryButtonAction()
                                  : null
                              }
                              inLineStyles={{
                                color: "#F58B00",
                                fontWeight: "600",
                                display: "flex",
                                alignItems: "center",
                                margin: "auto",
                              }}
                            >
                              {a?.secondaryButtonIcon && (
                                <Image
                                  style={{ width: 16, height: 16 }}
                                  src={a?.secondaryButtonIcon}
                                  alt={"image"}
                                />
                              )}
                              <Text
                                className="pc_medium"
                                style={qdmstyles.secondaryButtonName}
                              >
                                {a?.secondaryButtonName}
                              </Text>
                            </Button>
                          )}
                        </Div>
                      </Grid>
                      {!assignReassignPractitioner && (
                        <>
                          <Divider
                            orientation="vertical"
                            flexItem
                            style={{ margin: "10px 0px" }}
                          />

                          <Grid item xs={5}>
                            <Div>
                              {/* {a?.assignButtonName && ( */}
                              <>
                                <Button
                                  id={`${parent_id}${i}_queue_attend_secondarybutton_button`}
                                  type="button"
                                  name={""}
                                  variant="text"
                                  size={12}
                                  onClick={
                                    a?.assignButtonAction
                                      ? () => a.assignButtonAction()
                                      : null
                                  }
                                  inLineStyles={{
                                    color: "#F58B00",
                                    fontWeight: "600",
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "auto",
                                  }}
                                >
                                  {a?.assignButtonIcon && (
                                    <Image
                                      style={{ width: 16, height: 16 }}
                                      src={a?.assignButtonIcon}
                                      alt={"image"}
                                    />
                                  )}
                                  <Text
                                    className="pc_medium"
                                    style={qdmstyles.assignButtonName}
                                  >
                                    {a.assigned ? "Re Assign" : "Assign"}
                                  </Text>
                                </Button>
                              </>
                              {/* )} */}
                            </Div>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                </Card>
              </Div>
            );
          })}
        {props?.next &&
          props?.next?.map((n, i) => {
            return (
              <Div
                id={`${parent_id}${i}_queue_next_parent_div`}
                style={qdmstyles.wrapper}
                key={i}
              >
                <Div
                  id={`${parent_id}${i}_queue_next_parent_sub_div`}
                  style={qdmstyles.header}
                >
                  {n?.title && (
                    <H2
                      id={`${parent_id}${i}_queue_next_title_h2`}
                      className="pc_semibold"
                      style={qdmstyles.headerTitle}
                    >
                      {n?.title}
                    </H2>
                  )}

                  {n?.token && (
                    <Div
                      id={`${parent_id}${i}_queue_next_token_number_div`}
                      className="pc_medium"
                      style={qdmstyles.tokenNumber}
                    >
                      {n?.token}
                    </Div>
                  )}
                </Div>
                <Card
                  id={`${parent_id}${i}_queue_next_parent_card`}
                  style={qdmstyles.cardStyle}
                >
                  <Div
                    id={`${parent_id}${i}_queue_next_previousnextbody_div`}
                    style={qdmstyles.previousNextBody}
                  >
                    {n?.profile_pic && (
                      <Div
                        id={`${parent_id}${i}_queue_next_profile_div`}
                        style={qdmstyles.previousNextBodyAvatar}
                      >
                        <Image
                          id={`${parent_id}${i}_queue_next_profile_image`}
                          src={n?.profile_pic}
                          alt={n?.name ?? "profilePic"}
                          style={{
                            border: n?.isEmergency ? "1px dashed #FF4D4A" : "0",
                          }}
                        />
                        {n?.isEmergency && (
                          <Div
                            id={`${parent_id}${i}_queue_next_emergency_div`}
                            style={qdmstyles.emergencyDiv}
                          >
                            <Text
                              id={`${parent_id}${i}_queue_next_emergency_text`}
                              className="pc_medium"
                              style={qdmstyles.emergencyText}
                            >
                              EMERGENCY
                            </Text>
                          </Div>
                        )}
                      </Div>
                    )}
                    <Div
                      id={`${parent_id}${i}_queue_next_sub_div`}
                      style={{ textAlign: "left" }}
                    >
                      {n?.name && (
                        <H2
                          id={`${parent_id}${i}_queue_next_previousNextBodyName_h2`}
                          className="pc_semibold"
                          style={qdmstyles.previousNextBodyName}
                        >
                          {n?.name}
                        </H2>
                      )}

                      {n?.role && (
                        <Text
                          id={`${parent_id}${i}_queue_next_previousNextBodyRole_h2`}
                          className="pc_regular"
                          style={qdmstyles.previousNextBodyRole}
                        >
                          {n?.role}
                        </Text>
                      )}

                      <br />
                      {n?.start && (
                        <Text
                          id={`${parent_id}${i}_queue_next_previousNextBodyRole_start_h2`}
                          className="pc_regular"
                          style={qdmstyles.previousNextBodyRole}
                        >
                          {n?.start}
                        </Text>
                      )}
                    </Div>
                    {!assignReassignPractitioner && (
                      <Div
                        style={{ marginLeft: "auto" }}
                        onClick={
                          n?.assignButtonAction
                            ? () => n.assignButtonAction()
                            : null
                        }
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            borderRadius: "50%",
                            background: "#ebebeb",
                            height: "35px",
                            width: "35px",
                            margin: "auto",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            inLineStyles={{
                              height: "17px",
                              width: "16px",
                              marginLeft: "10px",
                            }}
                            src={n.assignButtonIcon}
                          />
                        </div>
                      </Div>
                    )}
                    {!markHighPriority && (
                      <Div
                        style={{
                          backgroundColor:
                            n?.patientPriority?.priority?.value === "High"
                              ? "#FF4D4A"
                              : "#F9E6E6",
                          padding: "5px 8px 3px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          marginLeft: "30px",
                        }}
                        onClick={(event) =>
                          handleHighAlertPop(
                            event,
                            n?.title,
                            n?.encounter,
                            n?.patientPriority,
                            n?.totalData,
                            n?.role_
                          )
                        }
                      >
                        <Image
                          inLineStyles={{ height: "17px", width: "16px" }}
                          src={
                            n?.patientPriority?.priority?.value === "High"
                              ? iconHighAlert
                              : iconHigh
                          }
                        />
                      </Div>
                    )}
                  </Div>
                </Card>
              </Div>
            );
          })}
        <Popover
          id="high_priority_pop_over"
          style={{ zIndex: "10000000" }}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleHighAlertPopClose}
          disableRestoreFocus
        >
          <HighAlertPopUp
            handleHighAlertPopClose={handleHighAlertPopClose}
            handlePriority={handlePriority}
            priorityTotalData={state.priorityTotalData}
          />
        </Popover>
      </div>
    );
  
}



export default withStyles(styles)(
(withAllContexts(Queue))
);
