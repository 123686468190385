import React from "react";
import { Div, Select, Col, Row, Paper, H6 } from 'qdm-component-library'
import "./style.css"
import withAllContexts  from "../../../hoc/withAllContexts";
import withQueue  from "../../../hoc/withQueue";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { AlertContext } from '../../../context'
//import { withRouter } from "react-router-dom";
import { makeName } from "../../../utils";
import { UIColor } from "../../../themes/theme";
// import SearchLocationInput from "../../components/location/index"

function RefTo(props) {

    const styles = {
        labelStyle: {
            color: '#6F6F6F',
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        selectBorderStyle: {
            borderRadius: 8,
            borderColor: '#E0E0E0'
        },
        errorselectBorderStyle: {
            borderRadius: 8,
        },
    }

    const { to } = props.referralData;
    const [practitioner_name, setpractitioner_name] = React.useState([])
    const [practitioner_role, setpractitioner_role] = React.useState([])
    const setDetails = async (name, value) => {

        let patientData = props.referralData
        if (name === 'specialty') {
            delete to.practitioner_type;
            delete to.practitioner;
            // if (props.GET_PRACTITIONER) {
            //     const practitioner_name = await props.GET_PRACTITIONER({ OrgID: value?.codeableId })
            //     const practitioner_role_Data = practitioner_name?.payload?.data?.map(v => {
            //         return { value: v?.practitioner_type }
            //     })
            //     const practitioner_name_Data = practitioner_name?.payload?.data?.map(v => {
            //         return { value: makeName(v?.name?.[0]) }
            //     })
            //     setpractitioner_name(practitioner_name_Data)
            //     setpractitioner_role(practitioner_role_Data)
            // }
            if(props?.GET_PRACTITIONER_TYPE){
                const practitioner_type = await props.GET_PRACTITIONER_TYPE({ OrgID: value?.codeableId });
                
                setpractitioner_role(practitioner_type?.payload?.data ?? [])
            }
        } else if(name === 'practitioner_type'){
            delete to.practitioner;
            if(props?.GET_PRACTITIONER_NAME){
                const practitioner_name_Data = await props.GET_PRACTITIONER_NAME({ specialityID: to.specialty?.codingId, practitionerType: value?.label});
                
                setpractitioner_name(practitioner_name_Data?.payload?.data ?? [])
            }
        }
        patientData.to[name] = value;
        if (patientData.to.error) {
            patientData.to.error[name] = false;
        }
        patientData.setReferralDetails({
            ...patientData,
        })
    }

    const FlterOptions = (type) => {
        const { allMasters } = props;
        var lista = []
        const data = allMasters?.[type]
        for (let index in data) {
            var datatemp = {
                "value": data[index]?.coding?.display,
                "label": data[index]?.coding?.id,
                "codingId": data[index]?.coding?._id,
                "codeableId": data[index]?.coding?._id,
            };
            lista.push(datatemp);
        }
        return lista
    }

    // React.useEffect(() => {
    //     
    //     const { appointmentData } = props
    //     if (appointmentData?.healthservice) {
    //         let patientData = props.referralData
    //         patientData.to['location'] = { value: appointmentData?.healthservice?.[0]?.Organization?.[0]?.alias };
    //         patientData.setReferralDetails({
    //             ...patientData,
    //         })
    //     }
    // }, [props.appointmentData])


    const { labelStyle, selectBorderStyle, errorselectBorderStyle } = styles;

    const { appointmentData, parent_id } = props;

    return <Div
        id={`${parent_id}_refto_parent_div`}
        className="pv-root">
        <Paper
            id={`${parent_id}_refto_parent_paper`}
            className="pl-root">
            <Row
                id={`${parent_id}_refto_parent_row`}
                className="en-actions-root">
                <Col
                    id={`${parent_id}_refto_parent_col`}
                    md={12} lg={12} sm={12} xs={12} className="no-padding-left">
                    <Div
                        id={`${parent_id}_refto_title_h6`}
                    >
                        <H6
                            id={`${parent_id}_refto_h6`}
                            className="en-title space-padding-bottom">REFERRED TO</H6>
                    </Div>
                    <Row
                        id={`${parent_id}_refto_sub_parent_row`}
                        className="en-actions-root">
                        <Col
                            id={`${parent_id}_refto_specialty_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_refto_specialty_select`}
                                // helperText={(to?.error && to?.error['specialty']) ? 'Is required' : ''}
                                error={(to?.error && to?.error['specialty']) ? true : false}
                                isRequired
                                options={FlterOptions('SPECIALTY')} placeholder='select...'
                                value={to.specialty?.value} onChangeValue={e => setDetails("specialty", e)} label="Specialty" showArrow labelStyle={labelStyle}
                                inLineStyles={(to?.error && to?.error['specialty']) ? errorselectBorderStyle : selectBorderStyle}
                                hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_refto_location_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            {/* <H6 className="default-size pc_regular">Location</H6> */}
                            {/* <div style={{
                                fontSize: '14px',
                                width: '100%',
                                fontWeight: 400,
                                textOverflow: 'ellipsis',
                                padding: '7.5px 14px',
                                color: '#aba9a9',
                                border: "1px solid #E0E0E0",
                                borderRadius: 8
                            }}>
                                <SearchLocationInput
                                    isinput={true}
                                    label=''
                                    handleChange={(v) => setDetails('location', v)}
                                    values={{
                                        address: to.location?.value
                                    }}
                                />
                            </div> */}
                            <Select
                                id={`${parent_id}_refto_location_select`}
                                placeholder='select...'
                                options={props.locations?.data}
                                value={to.location?.value} onChangeValue={e => setDetails("location", e)} label="Location" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_refto_practitoner_role_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_refto_practitoner_type_select`}
                                options={practitioner_role}
                                // options={[
                                //     { label: "PR001", value: "Physician " },
                                //     { label: "PR002", value: "Surgeon" },
                                //     { label: "PR003", value: "Consultant" },
                                //     { label: "PR004", value: "Assistant" },
                                //     { label: "PR005", value: "Paediatrician" },
                                //     { label: "PR006", value: "Nephrologist" },
                                //     { label: "PR007", value: "Neurologist" },
                                //     { label: "PR008", value: "Cardiologist" },
                                //     { label: "PR009", value: "Medical Administrator" },
                                //     { label: "PR010", value: "Midwife" },
                                //     { label: "PR011", value: "Matron" },
                                //     { label: "PR012", value: "Radiologist" },
                                //     { label: "PR013", value: "Nursing Officer" },
                                //     { label: "PR014", value: "Student Nurse" },
                                //     { label: "PR015", value: "Intern" },
                                //     { label: "PR016", value: "Dermatologist" },
                                //     { label: "PR017", value: "Anaesthetist" },
                                //     { label: "PR018", value: "LAB Technician" },
                                //     { label: "PR019", value: "Orthopaedician" },
                                //     { label: "PR020", value: "Urologist" },
                                // ]}
                                disabled={!(to.specialty?.value)}
                                placeholder='select...' value={to.practitioner_type?.value} onChangeValue={e => setDetails("practitioner_type", e)} label="Practitioner Type" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>

                        <Col
                            id={`${parent_id}_refto_practitoner_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            <Select
                                disabled={!(to.specialty?.value)}
                                id={`${parent_id}_refto_practitoner_select`}
                                options={practitioner_name} placeholder='select...' value={to.practitioner?.value} onChangeValue={e => setDetails("practitioner", e)} label="Practitioner" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Paper>
    </Div>
}

RefTo.contextType = AlertContext
const mapStateToProps = (state) => ({
    oraganizationRegisterPatient: state?.OrganizationRegisterPatientApiSlice?.oraganizationRegisterPatient,
    locations: state?.appointmentReducer?.getReferralLocation,
});
export default connect(mapStateToProps, actions)(withQueue(withAllContexts(RefTo)));