export const UIColor = {
  primaryColor: {
    color: "#01205C",
  },
  secondaryColor: {
    color: "#EC6A49",
  },
  secondaryButtonColor: {
    color: "#2A60BC",
  },
  primaryText: {
    color: "#001C3C",
  },
  secondaryText: {
    color: "#2A3C50",
  },
  tertiaryText: {
    color: "#6A7888",
  },
  greyBackground: {
    color: "#F4F5F8",
  },
  differentiationBackground: {
    color: "#F1F6FC",
  },
  lineBorderFill: {
    color: "#DEE5EC",
  },
  error: {
    color: "#FF4D4A",
  },
  success: {
    color: "#3BB213",
  },
  sidebarIconColor: {
    color: "#305396",
  },
};
