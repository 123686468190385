import * as React from "react";
import PropTypes from "prop-types";

const ClockIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path id="icons8-clock" d="M10,4a6,6,0,1,0,6,6A6.007,6.007,0,0,0,10,4Zm0,.9A5.1,5.1,0,1,1,4.9,10,5.093,5.093,0,0,1,10,4.9ZM9.843,6.394A.45.45,0,0,0,9.4,6.85v3.6a.45.45,0,0,0,.45.45h2.4a.45.45,0,1,0,0-.9H10.3V6.85a.45.45,0,0,0-.457-.456Z" transform="translate(-4 -4)" fill="#fff"/>
  </svg>
);

export default ClockIcon;

ClockIcon.defaultProps = {
  color: "black",
};

ClockIcon.propTypes = {
  color: PropTypes.string,
};